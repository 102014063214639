import {ApolloError, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Calendar, Popover, Radio, Select} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Slider,
  Spacer,
  Stack,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import {StyleSheet} from 'react-native';
import {BUTTON_TYPE, DATE_FORMATS, GROUP_MEMBER_TYPE, SCREEN_CONTEXT} from '../../../constants';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
} from '../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
} from '../../../constants/MlovConst';
import {IEhrCapability, IUser} from '../../../Interfaces';
import {
  AppointmentQueries,
  FormsQueries,
  MlovQueries,
  ScheduleEventQueries,
  UserQueries,
} from '../../../services';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {ITimezone} from '../../../services/Location/interfaces';
import {IUserSettingsByCode} from '../../../services/UserSettings/interfaces';
import * as UserSettingQueries from '../../../services/UserSettings/UserSettingQueries';
import { Colors } from '../../../styles';
import { getConfigDataFromCode, getInitialsFromFullName, isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability, skipSecondaryUsersForCareTeamTypeAppointmentType } from '../../../utils/commonUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import {
  addDaysInDate,
  convertToTimezone,
  currentMonth,
  currentYear,
  getCurrentTimeZone,
  getCustomDateRangeForAppointments,
  getDateObject,
  getDateObjectFromStringAndFormat,
  getDateRangeForAppointmentSlots,
  getDateStrFromFormat,
  getDateStrFromMomentObj,
  getDefaultEndDate,
  getDefaultStartDate,
  getFirstDateByMonthAndYear,
  getFormattedDate,
  getMomentObj,
  isCurrentDateInFutureComparedToOther,
  isDateBetweenRange,
  isDateInNextMonth,
  isDisabledMonth,
  isSameDate,
  isSameMonth,
} from '../../../utils/DateUtils';
import {
  getMlovByCategory,
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import BrandingLogoWrapper from '../../common/BrandingLogoWrapper/BrandingLogoWrapper';
import {filterSlotsByAppointentType, formatAppointmentTypes, getBookingErrorTextMessage, getMaxDateForBooking} from '../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {FormStatus} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingEnums';
import {
  CONSENT_FORMS,
  getAccountLocationListFromUsersList,
  getApplicableContactLocations,
  getCompletePracticeLocation,
  isContactAndUserPracticeLocationSame,
  isFutureDateAllowed,
} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {
  IAppointmentUserDetail,
  ISlot,
  IUserPracticeLocation,
  IContact,
} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import {DisplayCardAvatar} from '../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../common/ModalActionBtn/ModalActionBtn';
import {TimezoneSelect} from '../../common/TimezoneSelect/TimezoneSelect';
import {IAppointmentType} from '../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {FHForm} from '../../RightSideContainer/Forms/FHFormio';
import {forEachExtensiveFormComponent} from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IContactPracticeLocations} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import AppointmentBookingSuccessPage from './AppointmentBookingSuccessPage';
import {
  APPOINTMENT_FORM_CODE,
  getConsentFormsFormAPIResponse,
  getDataForAppointmentBooking,
  getLocationFromId,
  getLocationTypeValue,
  getSelectedLocationValue,
  getSelectedUserValue,
  getUserFromId,
  isVirtualLocationType,
} from './AppointmentBookingWidgetHelper';
import './AppointmentDatePicker.css';
import ReasonForVisitFreeText from './ReasonForVisitFreeText';
import { cloneDeep } from 'lodash';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { AppointmentAvailableSlots } from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentAvailableSlots';
import { IDefaultFormTheme } from '../../RightSideContainer/Forms/interfaces';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import { getEhrCapabilitiesWithResource } from '../../../services/CommonService/AidBoxService';
import { CapabilityResource } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { IAccountLocation } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { AppointmentAvailabilityCode } from '../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import { GET_CARE_TEAM } from '../../../services/CareTeam/CareTeamQueries';
import TeamQueries from '../../../services/Team/TeamQueries';
import moment from 'moment';
import SlotsGroup from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/SlotsGroup';
import { usePractitionerIdentifierFilter } from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/usePractitionerIdentifierFilter/usePractitionerIdentifierFilter';
import { APPOINTMENT_TYPE_DATA_TYPE_CODES } from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';

export interface IAppointmentBookingParams {
  accountId: string;
  contactId?: string;
  roleId?: string;
  appointmentTypeId?: string;
  appointmentUsers?: string;
  [key: string]: any;
}
export interface IBookingWidgetData {
  appointmentType?: IAppointmentType;
  user?: IUser;
  slot?: ISlot;
  location?: IUserPracticeLocation;
  selectedDate: Date;
  userList: IUser[];
  secondaryUserList: IUser[];
  slotList: ISlot[];
  locationList: IUserPracticeLocation[];
  error: string;
  slotError: string;
  bookingAPILoading: boolean;
  reasonForVisit?: {
    code: string;
    displayName: string;
  };
  inviteeContact?: {
    name: string;
    id: string;
    uuid: string;
    email?: string;
    contactPracticeLocations?: IContactPracticeLocations[];
    personAddress?: {
      cityId?: string;
      stateId?: string;
      countryId?: string;
      zipcodeId?: string;
      zipcodes?: {
        cityId?: string;
        stateId?: string;
      };
      states?: {
        abbr?: string;
        id?: string;
      }
    }[];
  };
  consentForms: {
    id: string;
    name: string;
    isSelected: boolean;
    status: FormStatus;
  }[];
  appointmentCapability?: IEhrCapability;
  minDate: string;
  maxDate: string | undefined;
  slotSelectedDate: string;
  dayWiseSlotCountMap: Map<string, number>;
  dayWiseSlotMap: Map<string, ISlot[]>;
  slotLoading: boolean;
  isAtClinicScheduleDisabled: boolean;
  disallowToScheduleForOtherLocation: boolean;
  accountSettings?: IUserSettingsByCode;
  isAccountSettingsApiCompleted: boolean;
  isAccountLocationApiCompleted: boolean;
  isCapabilityApiCompleted: boolean;
  skipSecondaryUsersForCareTeamType: boolean;
  locationTypeId: any;
  isShowPopover: boolean;
  selectedTimezone?: ITimezone;
  disAllowVirtualLocation?: boolean;
  displayContactLocation?: boolean;
  accountLocations: IAccountLocation[];
  allAccountLocations: IAccountLocation[];
}

enum BookingFlow {
  withContact,
  withoutContact,
}

enum BookingScreen {
  slotBooking,
  contactForm,
  successScreen,
}

const AppointmentBookingWidget = (props: {
  params: IAppointmentBookingParams;
}) => {
  const toast = useToast();
  const intl = useIntl();
  const apiErrorMessage = intl.formatMessage({id: 'apiErrorMsg'});
  const bookingFlow = props.params.contactId
    ? BookingFlow.withContact
    : BookingFlow.withoutContact;
  const [optionData] = useState({
    accountUUID: props.params.accountId
  });
  const [bookingData, setBookingData] = useState<IBookingWidgetData>({
    error: '',
    slotError: '',
    userList: [],
    slotList: [],
    selectedDate: new Date(),
    consentForms: [],
    inviteeContact: undefined,
    bookingAPILoading: false,
    reasonForVisit: undefined,
    locationList: [],
    slotSelectedDate: '',
    maxDate: undefined,
    minDate: '',
    dayWiseSlotCountMap: new Map<string, number>(),
    dayWiseSlotMap: new Map<string, ISlot[]>(),
    slotLoading: true,
    isAtClinicScheduleDisabled: false,
    disallowToScheduleForOtherLocation: false,
    isAccountSettingsApiCompleted: false,
    isAccountLocationApiCompleted: false,
    isCapabilityApiCompleted: false,
    skipSecondaryUsersForCareTeamType: false,
    locationTypeId: '',
    isShowPopover: false,
    disAllowVirtualLocation: false,
    displayContactLocation: false,
    accountLocations: [],
    secondaryUserList: [],
    allAccountLocations: [],
  });

  const [slotRange, setSlotRange] = useState({
    slotStartDate: '' as string,
    slotEndDate: '' as string,
    selectedMonth: currentMonth(),
    selectedYear: currentYear()
  });
  const [bookingScreen, setBookingScreen] = useState<BookingScreen>(
    BookingScreen.slotBooking
  );
  const [bookingFormDetails, setBookingFormDetails] = useState<{
    bookingFormComponents: any[];
    formId: string;
    formValues?: any;
    isValid: boolean;
  }>({
    bookingFormComponents: [],
    formId: '',
    formValues: undefined,
    isValid: bookingFlow === BookingFlow.withContact,
  });
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
    let configJson = undefined;
    let formThemeConfig: IDefaultFormTheme | undefined = {} as IDefaultFormTheme;
    if (
      defaultThemeConfig?.accountConfigurations &&
      defaultThemeConfig?.accountConfigurations?.length
    ) {
      configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
      formThemeConfig = configJson?.form || undefined;
    } else if (defaultThemeConfig?.defaultValue) {
      configJson = JSON.parse(defaultThemeConfig?.defaultValue);
      formThemeConfig = configJson?.form || undefined;
  }

  const numberAccountId = props.params.numberAccountId;
  const accountIdHeaders = {
    'account-uuid': props.params.accountId,
    ...(numberAccountId && { 'x-hasura-account-id': numberAccountId })
  }
  const headers = {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      headers: {
        ...accountIdHeaders
      },
    },
  };
  const { practitionerAPILoading, getFilteredUsersBasedOnLicensedState } = usePractitionerIdentifierFilter({
    useProxy: true,
    headers: accountIdHeaders,
    userSettings: bookingData.accountSettings,
  });

  const careStudioMlovData = useQuery(MlovQueries.GetAllCareMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getCareTeam, careTeamQuery] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    ...headers,
  });

  const crmMlovData = useQuery(MlovQueries.GetAllMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_PROXY_TO_CRM_CONTEXT},
  });

  const getAccountLocations = useQuery(TeamQueries.GetLocations, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      headers: {
        ...accountIdHeaders
      },
    },
    variables: {
      accountUuid: props.params.accountId,
    },
    onCompleted: (data: any) => {
      if (data?.accountLocations?.length) {
        setBookingData((prev) => ({
          ...prev,
          allAccountLocations: data?.accountLocations,
          isAccountLocationApiCompleted: true,
        }));
      } else {
        setBookingData((prev) => ({
          ...prev,
          isAccountLocationApiCompleted: true,
        }));
      }
    },
    onError: (error) => {
      setBookingData((prev) => ({
        ...prev,
        isAccountLocationApiCompleted: false,
      }));
    },
  });

  const accountSettingsApi = useQuery(UserSettingQueries.GetAccountSettings, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    variables: {
      tenantId: props.params.accountId,
    },
    onCompleted: (response) => {
      const accountSettings: IUserSettingsByCode = {};

      let isAtClinicScheduleDisabled = false;
      let disallowToScheduleForOtherLocation = false;
      let displayContactLocation = false;

      (response?.defaultUserSettings || []).forEach(
        (defaultUserSetting: any) => {
          if (!defaultUserSetting) return;

          const id: string = defaultUserSetting.id;
          const code: string = defaultUserSetting.code;
          let value: string = defaultUserSetting.value;

          if (defaultUserSetting.userSettings?.[0]) {
            value = defaultUserSetting.userSettings[0].value;
          }

          accountSettings[code] = {id, code, value};

          isAtClinicScheduleDisabled =
            accountSettings['is_at_clinic_schedule_disabled']?.value ===
              'True' || false;
          disallowToScheduleForOtherLocation =
            accountSettings['disallow_to_schedule_for_other_location']
              ?.value === 'True' || false;
          displayContactLocation = accountSettings['is_allow_virtual_location_for_schedule_availability']?.value === 'True' || false;


          setBookingData((prev) => {
            return {
              ...prev,
              accountSettings,
              isAccountSettingsApiCompleted: true,
              isAtClinicScheduleDisabled,
              disallowToScheduleForOtherLocation,
              displayContactLocation,
              skipSecondaryUsersForCareTeamType: skipSecondaryUsersForCareTeamTypeAppointmentType(accountSettings),
            };
          });
        }
      );
    },
    onError: () => {
      handleError('Something went wrong!!!');
    },
  });

  const fetchCapability = (capabilityList: string[]) => {
    getEhrCapabilitiesWithResource(
      capabilityList[0],
      true,
      (response) => {
          setBookingData((prev) => ({
            ...prev,
            appointmentCapability: response?.data?.length ? response.data[0] : {},
            isCapabilityApiCompleted: true,
          }));
      },
      () => {
        handleError('Something went wrong!!!');
      },
      {
        ...accountIdHeaders
      }
    );
  };

  const formattedCareStudioMlovData = getMlovByCategory(
    careStudioMlovData.data
  );

  const formattedCrmMlovData = getMlovByCategory(crmMlovData.data);

  const appointmentParticipantStatusList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];
  const appointmentStatusList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const userRoles =
    getMlovListFromCategory(
      formattedCrmMlovData || {},
      MLOV_CATEGORY.USER_ROLES
    ) || [];

  const appointmentFieldTypeMlovs =
    getMlovListFromCategory(
      formattedCareStudioMlovData || {},
      MLOV_CATEGORY.APPOINTMENT_CUSTOM_FIELD_TYPE
    ) || [];

  const durationMlovs =
    getMlovListFromCategory(
      formattedCareStudioMlovData || {},
      MLOV_CATEGORY.DATE_TIME_DURATION
    ) || [];


  const scheduleLocationTypeList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const appointmentParticipantType =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = {
    patient: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
    ),
    primaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
    ),
    secondaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.SECONDARY_USER
    ),
  };

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT);
  const [submitForm] = useMutation(FormsQueries.SUBMIT_FORM_RESPONSE);

  const MAX_FUTURE_DATE_RANGE = 90;

  const getSlotDate = ()=> {
    const defaultStartDate = getDefaultStartDate();
    const defaultEndDate = getDefaultEndDate();
    const slotStartTime = bookingData?.appointmentType?.slotStartTime;
    const slotEndTime = bookingData?.appointmentType?.slotEndTime;

    if (slotStartTime || slotEndTime) {
      const customSlotRange = getDateRangeForAppointmentSlots(
        slotStartTime,
        slotEndTime
      );
      return {
        slotStartDate: customSlotRange?.startDate || slotRange?.slotStartDate || defaultStartDate,
        slotEndDate: customSlotRange?.endDate || slotRange?.slotEndDate || defaultEndDate,
      }
    } else {
      return {
        slotStartDate: slotRange?.slotStartDate || defaultStartDate,
        slotEndDate: slotRange?.slotEndDate || defaultEndDate,
      }
    }
  }

  const getSlotQueryParams = (
    selectedLocationId: string | undefined,
    isVirtualLocation: boolean
  ) => {
    let appointmentData = undefined;
    if (
      bookingData?.appointmentType?.appointmentTypeGroup &&
      bookingData?.appointmentType?.appointmentTypeGroup.length > 0
    ) {
      appointmentData = bookingData?.appointmentType.appointmentTypeGroup;
    }
    let userIds = [bookingData.user?.uuid];
    if (
      bookingData?.appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM ||
      bookingData?.appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER
    ) {
    const otherSecondaryUsers = bookingData.userList.filter(item => item.uuid !== bookingData.user?.uuid);
     const secondaryUsers = [
       ...otherSecondaryUsers,
       ...bookingData?.secondaryUserList,
     ];
     if (secondaryUsers?.length && !bookingData.skipSecondaryUsersForCareTeamType) {
       const secondaryUserIds = (secondaryUsers || []).map((item) => item.uuid);
       userIds = userIds.concat(secondaryUserIds);
     }
    }

    return {
      locationId: selectedLocationId,
      ...(bookingData?.appointmentType?.id && { appointmentTypeId: bookingData?.appointmentType?.id }),
      userIds: userIds,
      ...(props?.params?.contactId && {contactIds: [props.params.contactId]}),
      slotStartDate: getSlotDate()?.slotStartDate,
      slotEndDate: getSlotDate()?.slotEndDate,
      duration: bookingData?.appointmentType?.duration || 30,
      isVirtualLocation: isVirtualLocation,
      timezone: bookingData.selectedTimezone?.timezone || getCurrentTimeZone(),
    };
  };

  const getAvailableTimeSlotsDate = (
    dayWiseSlots: Map<string, ISlot[]>,
    dayKey: string | undefined
  ) => {
    if (dayWiseSlots?.size) {
      if (dayKey) {
        const slots: ISlot[] = dayWiseSlots.get(dayKey) || [];
        if (slots?.length) {
          return dayKey;
        }
      }
      let availableSlotDayKey: string | undefined = undefined;
      dayWiseSlots.forEach((slotCount, key) => {
        if (slotCount?.length > 0 && !availableSlotDayKey) {
          availableSlotDayKey = key;
        }
      });
      return availableSlotDayKey;
    }
    return undefined;
  };

  const [getAvailableTimeSlotsForDateRange, getAvailableTimeSlotsQuery] =
    useLazyQuery(AppointmentQueries.GET_AVAILABLE_SLOTS_FOR_DATE_RANGE, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        let availableDaySlots: ISlot[] = [];
        const dayWiseSlots: Map<string, ISlot[]> = new Map<string, ISlot[]>();
        const dayWiseSlotCount: Map<string, number> = new Map<string, number>();
        const responseSlot =
          data?.getAvailableTimeSlotsForDateRange?.slots || {};
        if (Object.keys(responseSlot)?.length) {
          const appointmentDateKey = Object.keys(responseSlot).sort(
            (prev: string, next: string) => {
              const date1 = new Date(prev);
              const date2 = new Date(next);
              return date1.getTime() - date2.getTime();
            }
          );
          appointmentDateKey.forEach((key: string) => {
            const dayKey = key.split('/').join('-');
            const slotList = getDayWiseSlotsFromResponse(
              responseSlot[key] || []
            );
            dayWiseSlots.set(dayKey, slotList);
            dayWiseSlotCount.set(dayKey, slotList.length);
          });
        }

        if (dayWiseSlots?.size) {
          const currentSelectDateStr = getDateStrFromFormat(
            bookingData.selectedDate,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          const availableSlotDayKey = getAvailableTimeSlotsDate(
            dayWiseSlots,
            currentSelectDateStr
          );
          if (availableSlotDayKey) {
            availableDaySlots = dayWiseSlots?.get(availableSlotDayKey) || [];
            const selectedDate = getDateObject(availableSlotDayKey);
            const slotSelectDate = getDateObject(currentSelectDateStr);
            if (!isSameDate(selectedDate, slotSelectDate)) {
              showToast(
                toast,
                'Taking you to next available slot',
                ToastType.info
              );
            }
            const filteredSlotList = filterSlotsByAppointentType(availableDaySlots, bookingData.appointmentType);
            setBookingData((prev) => ({
              ...prev,
              dayWiseSlotCountMap: dayWiseSlotCount,
              dayWiseSlotMap: dayWiseSlots,
              selectedDate: availableSlotDayKey
                ? new Date(availableSlotDayKey)
                : new Date(),
              slotList: filteredSlotList,
              slot: filteredSlotList?.length
                ? filteredSlotList[0]
                : undefined,
              slotLoading: false,
            }));
          } else {
            setBookingData((prev) => ({
              ...prev,
              dayWiseSlotCountMap: dayWiseSlotCount,
              dayWiseSlotMap: dayWiseSlots,
              selectedDate: availableSlotDayKey
                ? new Date(availableSlotDayKey)
                : new Date(),
              slotList: filterSlotsByAppointentType(availableDaySlots, bookingData.appointmentType),
              slot: undefined,
              slotLoading: false,
            }));
          }
        } else {
          setBookingData((prev) => ({
            ...prev,
            dayWiseSlotCountMap: new Map<string, number>(),
            dayWiseSlotMap: new Map<string, ISlot[]>(),
            slotList: [],
            slot: undefined,
            slotLoading: false,
          }));
        }
      },
      onError: (error) => {

        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
      },
    });

  const getDayWiseSlotsFromResponse = (slotList: any[]): ISlot[] => {
    if (slotList && slotList?.length) {
      return slotList.filter((slot: ISlot) => {
        return isCurrentDateInFutureComparedToOther(
          slot.startDateTime,
          new Date()
        );
      });
    }
    return [] as ISlot[];
  };

  const [getContactUUID] = useLazyQuery(
    ContactsQueries.GET_CONTACT_UUID_BY_EMAIL,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders
        },
      },
    }
  );

  const [getUserDetails, usersAPIQuery] = useLazyQuery(
    UserQueries.GET_USERS_BY_ROLE_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders
        },
      },
    }
  );

  const [getUserDetailsById, usersByIdAPIQuery] = useLazyQuery(
    UserQueries.GET_USERS_WITH_LOCATION_AND_ROLES_FROM_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders
        },
      },
    }
  );

  let appointmentTypeQueryCode =
    ScheduleEventQueries.GET_DEFAULT_APPOINTMENT_TYPE;
  if (props.params.roleId) {
    appointmentTypeQueryCode =
      ScheduleEventQueries.GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_ROLE_ID;
  } else if (props.params.appointmentTypeId) {
    appointmentTypeQueryCode =
      ScheduleEventQueries.GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_APPOINTMENT_TYPE_ID;
  }

  const [getAppointmentTypeAPI, appointmentTypeAPIQuery] = useLazyQuery(
    appointmentTypeQueryCode,
    {
      variables: {
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
        ...(props.params.roleId && {roleId: props.params.roleId}),
        ...(props.params.appointmentTypeId && {
          id: props.params.appointmentTypeId,
        }),
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data: any) => {
        if (data && data.appointmentTypes && data.appointmentTypes.length > 0) {
          data.appointmentTypes = data.appointmentTypes.filter(
            (appointmentType: IAppointmentType) => {
              if (
                bookingData.isAtClinicScheduleDisabled &&
                appointmentType?.locationType?.code ===
                  LOCATION_TYPE_CODES.AT_CLINIC
              ) {
                return false;
              }
              if (
                appointmentType?.appointmentTypeGroup?.length > 0 ||
                appointmentType.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM ||
                appointmentType.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER
              ) {
                return true;
              }
              return false;
            }
          );

          if (props.params.appointmentTypeId) {
            const appointmentType = data.appointmentTypes.find(
              (appointmentType: IAppointmentType) => {
                if (props.params.appointmentTypeId === appointmentType?.id) {
                  return true;
                }
                return false;
              }
            );

            if (appointmentType) {
              data.appointmentTypes = [appointmentType];
            } else {
              return handleError(
                'Your practice do not have the selected appointment type'
              );
            }
          }

          const formattedAppointmentTypes = formatAppointmentTypes(data.appointmentTypes, [...durationMlovs, ...appointmentFieldTypeMlovs]);
          const selectedAppointmentType = formattedAppointmentTypes?.[0] as IAppointmentType;
          const todayDateString = getFormattedDate(
            new Date(),
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          setBookingData((prev) => ({
            ...prev,
            appointmentType: selectedAppointmentType,
            locationTypeId: selectedAppointmentType.locationTypeId,
            minDate: todayDateString,
            maxDate: getMaxDateForBooking(selectedAppointmentType),
          }));
          fetchUserData(selectedAppointmentType);
        } else {
          handleError(
            props.params.roleId
              ? 'There is no staff available for this service in the practice, please call your practice to book an appointment'
              : 'Your practice has not set up any appointments to be booked directly, please call your practice to book an appointment'
          );
          setBookingData((prev) => ({
            ...prev,
            slotLoading: false,
          }));
        }
      },
      onError: (error: ApolloError) => {
        handleError(apiErrorMessage, error);
        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
      },
    }
  );

  useQuery(FormsQueries.GET_FORM_BY_CODE, {
    skip: bookingFlow === BookingFlow.withContact,
    variables: {
      code: APPOINTMENT_FORM_CODE,
    },
    fetchPolicy: 'no-cache',
    ...headers,
    onCompleted: (data) => {
      if (data?.form?.components) {
        const components = data.form.components.filter(
          (component: any) => component.action !== 'submit'
        );
        setBookingFormDetails((prev) => ({
          ...prev,
          formId: data.form.id,
          bookingFormComponents: components || [],
        }));
      }
    },
  });

  const consentFormData = useQuery(
    bookingFlow === BookingFlow.withContact
      ? FormsQueries.GET_FORMS_BY_NAMES_WITH_AGGREGATE
      : FormsQueries.GET_FORMS_WITH_NAMES,
    {
      variables: {
        formNames: CONSENT_FORMS,
        contactId: props.params.contactId,
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data) => {
        if (data.forms && data.forms.length > 0) {
          const formData = getConsentFormsFormAPIResponse(data);
          setBookingData((prev) => ({
            ...prev,
            consentForms: formData,
          }));
        }
      },
    }
  );

  // const findContactLocationFromLocationList = (
  //   contactPracticeLocations?: IContactPracticeLocations[],
  //   locationList?: IUserPracticeLocation[]
  // ) => {
  //   if (
  //     !contactPracticeLocations ||
  //     !contactPracticeLocations.length ||
  //     !locationList ||
  //     !locationList.length
  //   ) {
  //     return undefined;
  //   }

  //   const practiceLocationUuids = contactPracticeLocations
  //     ?.map?.((location: IUserPracticeLocation) => {
  //       return (
  //         location?.practiceLocationUuid ||
  //         location?.accountLocation?.practiceLocation?.uuid
  //       );
  //     })
  //     .filter((locationUuid: any) => !!locationUuid);

  //   if (!practiceLocationUuids || !practiceLocationUuids.length)
  //     return undefined;

  //   const location = locationList.find((location) => {
  //     const practiceLocationUuid =
  //       location?.practiceLocationUuid ||
  //       location?.accountLocation?.practiceLocation?.uuid;
  //     if (
  //       practiceLocationUuid &&
  //       practiceLocationUuids.includes(practiceLocationUuid)
  //     )
  //       return true;
  //   });

  //   return location;
  // };

  const [getContactApi, contactAPIQuery] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      // skip: bookingFlow === BookingFlow.withoutContact,
      variables: {
        contactId: props.params.contactId,
      },
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders
        },
      },
      onCompleted: (data: any) => {
        if (data && data.contacts && data.contacts.length > 0) {
          setBookingData((prev) => {
            // let location = findContactLocationFromLocationList(
            //   data.contacts?.[0]?.contactPracticeLocations,
            //   bookingData.locationList
            // );
            // const contactLocation =
            //   data.contacts?.[0]?.contactPracticeLocations?.[0];
            // if (!location) {
            //   location = contactLocation || prev.location;
            // }
            return {
              ...prev,
              // location: location,
              // contactLocation,
              inviteeContact: data.contacts[0],
            };
          });
          getAppointmentTypeAPI();
        } else {
          handleError('This link is not valid, please contact support');
        }
      },
      onError: (error) => {
        handleError(apiErrorMessage, error);
      },
    }
  );

  const isUserExist = (userId: string, userIds: string[]) => {
    return userIds.some((id) => {
      return userId === id;
    });
  };

  const setWidgetUsers = (userList: any[]) => {
    const userIds = props.params?.appointmentUsers || undefined;
    if (userIds?.length && userList?.length) {
      const userIdList = userIds.split(',');
      return userList.filter((user: any) => {
        return isUserExist(user.uuid, userIdList);
      });
    }
    return userList;
  };

  const fetchUserData = (type: IAppointmentType) => {
    switch (type.availabilityTypeCode) {
      case AppointmentAvailabilityCode.CARE_TEAM:
        setCareTeamBasedUsers(type);
        break;
      case AppointmentAvailabilityCode.PROVIDER:
        setProviderBasedUsers(type);
        break;
      case AppointmentAvailabilityCode.ROLE:
      default:
        setRoleBasedUsers(type);
        break;
    }
  };

  const setProviderBasedUsers = async (type: IAppointmentType) => {
    const primaryUserIds: string[] = [];
    const secondaryUserIds: string[] = [];
    type.userPool?.userPoolUsers?.forEach((item) => {
      if (item.userId) {
        if (item.isDefault) {
          primaryUserIds.push(item.userId);
        } else {
          secondaryUserIds.push(item.userId);
        }
      }
    })
    try {
      const userData = await getUserDetailsById({
        variables: {
          userIds: [...primaryUserIds, ...secondaryUserIds],
          accountId: props.params.accountId,
        }
      });
      const filteredUsers: IUser[] = getFilteredUsers(userData) || [];
      const primaryFilteredUsers: IUser[] = [];
      const secondaryFilteredUsers: IUser[] = [];
      filteredUsers?.forEach((item) => {
        const isPrimary = primaryUserIds.includes(item.uuid);
        if (isPrimary) {
          primaryFilteredUsers.push(item);
        } else {
          secondaryFilteredUsers.push(item);
        }
      });
      setPrimaryAndSecondaryUsers(type, primaryFilteredUsers, secondaryFilteredUsers);
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    } catch (error) {

      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  }

  const setPrimaryAndSecondaryUsers = (type: IAppointmentType, primaryFilteredUsers: IUser[], secondaryFilteredUsers: IUser[]) => {
    if (primaryFilteredUsers && primaryFilteredUsers.length > 0) {
      const primaryUsers = getFilteredUsersBasedOnLicensedState(primaryFilteredUsers, bookingData.inviteeContact);
      const secondaryUsers = getFilteredUsersBasedOnLicensedState(secondaryFilteredUsers, bookingData.inviteeContact);
      const accountLocations = getAccountLocationListFromUsersList(primaryUsers);
      const isVirtualVisit = type.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
      const selectedUser = primaryUsers[0];
      const userLocationList: IUserPracticeLocation[] = getSelectedUserLocations(selectedUser?.uuid, primaryUsers);
      const isVirtualLocation = isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;

      const locationList = getLocationList(isVirtualLocation, bookingData.disallowToScheduleForOtherLocation, userLocationList, bookingData.inviteeContact as any);
      const selectedLocation = locationList?.length ? locationList[0] : undefined;

      setBookingData((prev) => ({
        ...prev,
        user: selectedUser,
        userList: primaryUsers,
        secondaryUserList: secondaryUsers,
        location: selectedLocation,
        locationList: locationList,
        accountLocations: accountLocations,
      }));
    } else {
      handleError(
        'Your Practice does not have an available staff to complete this appointment'
      );
    }
  }

  const setCareTeamBasedUsers = async (type: IAppointmentType) => {
    try {
      const contactId = props?.params?.contactId;
      if (contactId) {
        const careTeamResponse = await getCareTeam({variables: {
          contactId: contactId
        }});
        const careTeamUsers = careTeamResponse?.data?.careTeams?.[0]?.userPool?.userPoolUsers?.map((user: any) => user.userId) || [];
        const userData = await getUserDetailsById({
          variables: {
            userIds: careTeamUsers,
            accountId: props.params.accountId,
          }
        });
        const primaryRoleIds: string[] = ((type?.appointmentTypeGroup || []).map((item) => item.roleId).filter(item => !!item) || []) as string[];
        const filteredUsers: IUser[] = getFilteredUsers(userData) || [];
        const primaryFilteredUsers: IUser[] = [];
        const secondaryFilteredUsers: IUser[] = [];
        filteredUsers?.forEach((item) => {
          if (item.userRoles?.length) {
            const isPrimary = item.userRoles.some((role) => {
              return role.userRole?.userRole?.id && primaryRoleIds.includes(role.userRole.userRole.id)
            });
            if (isPrimary) {
              primaryFilteredUsers.push(item);
            } else {
              secondaryFilteredUsers.push(item);
            }
          } else {
            secondaryFilteredUsers.push(item);
          }
        });
        setPrimaryAndSecondaryUsers(type, primaryFilteredUsers, bookingData.skipSecondaryUsersForCareTeamType ? [] : secondaryFilteredUsers);
      }
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    } catch (error) {

      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  }

  const getFilteredUsers = (response: any) => {
    if (response?.data?.users?.length > 0) {
      let users = setWidgetUsers(response.data.users);
      const accountLocations = getAccountLocationListFromUsersList(users);
      const contactAvailableLocations = getApplicableContactLocations(
        bookingData.inviteeContact,
        accountLocations
      );
      if (
        (bookingData.disallowToScheduleForOtherLocation ||
          bookingData.displayContactLocation) &&
        users?.length &&
        contactAvailableLocations.length
      ) {
        users = users.filter((user: IUser) => {
          let locations = user.userPracticeLocations;
          locations = locations?.filter((location) => {
            if (isContactAndUserPracticeLocationSame(contactAvailableLocations, location as any)) {
              return true;
            }
          });
          if (locations?.length) {
            user.userPracticeLocations = locations;
            return true;
          }
        });
      }
      if (bookingData.disAllowVirtualLocation) {
        users = users.filter((user: IUser) => {
          return user?.userPracticeLocations?.length ? true : false;
        });
      }
      return users;
    }
  };

  const setRoleBasedUsers = (type: IAppointmentType) => {
    const roleIds = (type?.appointmentTypeGroup || [])
      .map((group) => group.roleId)
      .filter(role => !!role);

    if (roleIds.length > 0) {
      getUserDetails({
        variables: {
          roleIds,
          accountId: props.params.accountId,
        },
      })
        .then((response) => {
          setBookingData((prev) => ({
            ...prev,
            slotLoading: false,
          }));
          if (response?.data?.users?.length > 0) {
            let users = setWidgetUsers(response.data.users);
            const accountLocations = getAccountLocationListFromUsersList(users);
            const contactAvailableLocations = getApplicableContactLocations(bookingData.inviteeContact, bookingData.allAccountLocations);
            users = getFilteredUsersBasedOnLicensedState(users, bookingData.inviteeContact);
            if (
              (bookingData.disallowToScheduleForOtherLocation || bookingData.displayContactLocation) &&
              users?.length &&
              contactAvailableLocations.length
            ) {
              users = users.filter((user: IUser) => {
                let locations = user.userPracticeLocations;

                locations = locations?.filter((location) => {
                  if (isContactAndUserPracticeLocationSame(contactAvailableLocations, location as any)) {
                    return true;
                  }
                });

                if (locations?.length) {
                  user.userPracticeLocations = locations;
                  return true;
                }
              });
            }

            if (bookingData.disAllowVirtualLocation) {
              users = users.filter((user: IUser) => {
                return user?.userPracticeLocations?.length ? true : false;
              });
            }

            if (users && users.length > 0) {
              // let location: IUserPracticeLocation | undefined;
              // if (users[0]?.userPracticeLocations.length > 0) {
              //   const locationList: IUserPracticeLocation[] =
              //     users[0].userPracticeLocations.filter((location: any) => {
              //       return !!location?.accountLocation?.practiceLocation;
              //     });
              //   const matchedLocation = locationList.find((location) => {
              //     return location.uuid === bookingData.location?.uuid;
              //   });
              //   location = matchedLocation || locationList[0];
              // }
              // const userLocationList: IUserPracticeLocation[] = getSelectedUserLocations(users[0]?.uuid, users);

              //if (isVirtualVisit) {
              //   location = undefined;
              // }
              // if (
              //   bookingData.disallowToScheduleForOtherLocation &&
              //   bookingData.contactLocation?.accountLocation?.uuid
              // ) {
              //   location = bookingData.contactLocation;
              // }
              const isVirtualVisit =
                type.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
              const selectedUser = users[0];
              const userLocationList: IUserPracticeLocation[] = getSelectedUserLocations(selectedUser?.uuid, users);
              const isVirtualLocation = isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;

              const locationList = getLocationList(isVirtualLocation, bookingData.disallowToScheduleForOtherLocation, userLocationList, bookingData.inviteeContact as any);
              const selectedLocation = locationList?.length ? locationList[0] : undefined;

              setBookingData((prev) => ({
                ...prev,
                user: selectedUser,
                userList: users,
                location: selectedLocation,
                locationList: locationList,
                accountLocations: accountLocations,
              }));
            } else {
              handleError(
                'Your Practice does not have an available staff to complete this appointment'
              );
            }
          } else {
            handleError(
              'This link is not valid as practice does not have any staff registered'
            );
          }
        })
        .catch((error) => {
          handleError(apiErrorMessage, error);
          setBookingData((prev) => ({
            ...prev,
            slotLoading: false,
          }));
        });
    }
  }

  const getSelectedUserLocations = (
    selectedUserId: string,
    userList: any[]
  ): any[] => {
    const selectedUser = (userList || []).find((singleUser) => {
      return singleUser?.uuid === selectedUserId;
    });

    const locations = selectedUser?.userPracticeLocations || [];
    return locations.filter(
      (location: any) => !!location.accountLocation?.practiceLocation
    );
  };

  const getShortLocationName = () => {
    const isVirtualVisit =  isVirtualLocationType(scheduleLocationTypeList, bookingData.locationTypeId);
    if (isVirtualVisit &&
      bookingData.location?.accountLocation?.practiceLocation?.name &&
      isVirtualLocationEnabledInAvailability(bookingData.accountSettings)) {
        return 'Virtual Appointment';
    }
    if (bookingData.location?.accountLocation?.practiceLocation?.name) {
      return bookingData.location.accountLocation.practiceLocation?.name;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const getLocationNameForDropdown = (location?: IUserPracticeLocation) => {
    if (location?.accountLocation?.practiceLocation?.name) {
      return location.accountLocation.practiceLocation.name;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const getLocationName = (): string => {
    if (bookingData.locationTypeId) {
      const locationType = scheduleLocationTypeList.find(locationType => {
        return bookingData.locationTypeId && locationType.id === bookingData.locationTypeId && locationType.code === 'VIRTUAL';
      });
      if (locationType?.value) {
        return locationType?.value;
      }
    }
    if (bookingData.location?.accountLocation?.practiceLocation?.name) {
      const userPracticeLocation =
        bookingData.location.accountLocation.practiceLocation;
      let location = getCompletePracticeLocation(userPracticeLocation);
      location = location
        ? `${location} (${userPracticeLocation.name})`
        : userPracticeLocation.name;
      return location;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const bookBtnClicked = () => {
    // if (bookingFlow === BookingFlow.withContact) {
    //   scheduleAppointment(bookingData.inviteeContact?.uuid || '');
    // }
    setBookingScreen(BookingScreen.contactForm);
  };

  const scheduleAppointment = (contactId: string) => {
    setBookingData((prev) => ({...prev, bookingAPILoading: true}));
    const pendingAppointmentStatusId = getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    );
    bookAppointment({
      variables: {
        data: getDataForAppointmentBooking(
          bookingData,
          pendingAppointmentStatusId,
          contactId,
          appointmentParticipantStatusList,
          APPOINTMENT_PARTICIPANT_TYPE_IDS,
        ),
      },
      ...headers,
      onCompleted: (response) => {
        if (response?.createBookedAppointments?.length) {
          const appointmentData = response.createBookedAppointments[0];
          setBookingScreen(BookingScreen.successScreen);
        }
        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
      },
      onError: (error: ApolloError) => {

        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        showToast(toast, apiErrorMessage, ToastType.error);
      },
    });
  };

  const isValidContactAppointmentForm = () => {
    return (
      bookingFormDetails.isValid &&
      (bookingFormDetails.formValues?.email ||
        bookingFormDetails.formValues?.phoneNumber)
    );
  };

  const confirmButtonClicked = () => {
    if (bookingFlow === BookingFlow.withContact) {
      scheduleAppointment(bookingData.inviteeContact?.uuid || '');
      return;
    }
    const formChanges = bookingFormDetails.formValues;
    const updatedComponents = bookingFormDetails.bookingFormComponents;
    if (isValidContactAppointmentForm()) {
      forEachExtensiveFormComponent(updatedComponents, (component) => {
        if (formChanges?.hasOwnProperty(component.key)) {
          component.selectedValue = formChanges[component.key];
        }
      });
      submitFormData(updatedComponents);

      setBookingData((prev) => ({...prev, bookingAPILoading: true}));
      /*
      Commented below code as it was fetching contact by email as it was unique before, now email is not unique anymore thus removed this
      Now every time new lead will be created

      getContactUUID({
        variables: {
          email: bookingFormDetails.formValues?.email,
        },
      })
        .then((response) => {
          let contactId: string | undefined = undefined;
          if (response?.data?.contacts?.length) {
            contactId = response.data.contacts[0].uuid;
          }
          forEachExtensiveFormComponent(updatedComponents, (component) => {
            if (formChanges?.hasOwnProperty(component.key)) {
              component.selectedValue = formChanges[component.key];
            }
          });
          submitFormData(updatedComponents, contactId);
        })
        .catch(() => {
          setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        });
        */
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'emailOrPhoneNumberRequired'}),
        ToastType.error
      );
    }
  };

  const submitFormData = (updatedComponents: any, contactId?: string) => {
    const data: any = {
      formId: bookingFormDetails.formId,
      formResponse: {components: updatedComponents},
      contactId,
    };
    submitForm({
      variables: {
        data,
      },
      ...headers,
      onCompleted: (response) => {
        const contactUUID = contactId || response.submitFormResponse?.contactId;
        if (contactUUID) {
          scheduleAppointment(contactUUID);
        } else {
          setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        }
      },
      onError: () => {
        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
      },
    });
  };

  const handleSlotError = (message: string, error?: ApolloError) => {

    setBookingData((prev) => ({
      ...prev,
      slot: undefined,
      slotError: message,
    }));
  };

  const handleError = (message: string, error?: ApolloError) => {

    setBookingData((prev) => ({
      ...prev,
      error: message,
    }));
  };

  const fetchAvailableSlots = (
    selectedLocation?: IUserPracticeLocation,
    isUserChange?: boolean
  ) => {
    const selectedLocationId = isUserChange
      ? selectedLocation?.accountLocation?.uuid
      : bookingData.location?.accountLocation?.uuid;

    const isVirtualVisit =  isVirtualLocationType(scheduleLocationTypeList, bookingData.locationTypeId);
    let isVirtualLocation = isVirtualVisit && isVirtualLocationEnabledInAvailability(bookingData.accountSettings) || isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;
    let locationId = selectedLocationId;

    if (bookingData.disallowToScheduleForOtherLocation) {
      if (selectedLocationId) {
        locationId = selectedLocationId;
      } else if (isVirtualLocation) {
        locationId = undefined;
      }
    } else if (isVirtualLocation) {
      locationId = undefined;
    }

    if (isVirtualVisit && isVirtualLocationEnabledInAvailability(bookingData.accountSettings)) {
      isVirtualLocation = true;
      locationId = undefined;
    }
    if (isVirtualLocation || locationId) {
      getAvailableTimeSlotsForDateRange({
        context: {
          service: CARESTUDIO_APOLLO_CONTEXT,
          headers: {
            ...accountIdHeaders
          },
        },
        variables: {
          data: getSlotQueryParams(locationId, isVirtualLocation),
        },
      });
    } else {
      setBookingData((prev) => ({
        ...prev,
        dayWiseSlotCountMap: new Map<string, number>(),
        dayWiseSlotMap: new Map<string, ISlot[]>(),
        slotList: [],
        slot: undefined,
        slotLoading: false
      }));
    }
  };
  useEffect(() => {
    if (bookingData.user) {
      fetchAvailableSlots();
    } else {
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  }, [
    bookingData.location,
    bookingData.selectedTimezone,
  ]);

  useEffect(() => {
    if (bookingData.user) {
      fetchAvailableSlots();
    } else {
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  }, [
    slotRange?.selectedMonth,
    slotRange?.selectedYear
  ]);

  useEffect(() => {
    if (bookingData.user) {
      const isVirtualVisit = getMlovCodeFromId(scheduleLocationTypeList, bookingData.locationTypeId) === LOCATION_TYPE_CODES.VIRTUAL;
      const isVirtualLocation = isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;
      const userLocationList: IUserPracticeLocation[] = getSelectedUserLocations(bookingData.user?.uuid, bookingData.userList || []);
      const locationList = getLocationList(isVirtualLocation, bookingData.disallowToScheduleForOtherLocation, userLocationList, bookingData.inviteeContact as any);
      const selectedLocation = locationList?.length ? locationList[0] : undefined;

      setBookingData((prev) => ({
        ...prev,
        location: selectedLocation,
        locationList: locationList,
      }));
      fetchAvailableSlots(selectedLocation, true);
    }
  }, [bookingData.user, bookingData.disAllowVirtualLocation, bookingData.disallowToScheduleForOtherLocation, bookingData.locationTypeId]);

  useEffect(() => {
    fetchCapability([CapabilityResource.appointment]);
  }, []);

  useEffect(() => {
    if (!bookingData.isAccountSettingsApiCompleted || !bookingData.isCapabilityApiCompleted || !bookingData.isAccountLocationApiCompleted) {
      return;
    }
    const disAllowVirtualLocation = isVirtualLocationDisabled(bookingData.accountSettings || {}, [ bookingData.appointmentCapability || {} ]);

    setBookingData(prev => ({ ...prev, disAllowVirtualLocation: disAllowVirtualLocation }));

    if (bookingFlow === BookingFlow.withoutContact) {
      getAppointmentTypeAPI();
    } else {
      getContactApi();
    }
  }, [bookingData.isAccountSettingsApiCompleted, bookingData.isCapabilityApiCompleted, bookingData.isAccountLocationApiCompleted]);

  const handleFormOnChange = useCallback((data) => {
    setBookingFormDetails((prev) => ({
      ...prev,
      isValid: data.isValid,
      formValues: data.data,
    }));
  }, []);

  const getUserRole = () => {
    if (bookingData?.appointmentType?.appointmentTypeGroup?.length) {
      const roleId =
        bookingData.appointmentType?.appointmentTypeGroup[0].roleId;
      if (roleId) return getMlovValueFromId(userRoles, roleId);
    } else if (
      bookingData.user?.userRoles?.length &&
      bookingData.user.userRoles[0]?.userRole?.userRole?.value
    ) {
      return bookingData.user.userRoles[0]?.userRole?.userRole?.value;
    }
  };

  const getMinAndMaxDate = () => {
    const min = getDateObjectFromStringAndFormat(
      bookingData.minDate || '',
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    );
    const max = bookingData.maxDate
      ? getDateObjectFromStringAndFormat(
          bookingData.maxDate || '',
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        )
      : addDaysInDate(new Date(), 90);
    return {min, max};
  };

  const getSliderTrackPercentage = (slotCount: number) => {
    if (slotCount > 0 && slotCount <= 5) {
      return 25;
    }
    if (slotCount > 5 && slotCount <= 10) {
      return 50;
    }
    if (slotCount > 10 && slotCount <= 15) {
      return 75;
    }
    return 100;
  };

  const isSelectedDate = (date: any) => {
    if (
      date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT) ===
      getDateStrFromFormat(
        bookingData?.selectedDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      )
    ) {
      return true;
    }
    return false;
  };

  const isDisabledDate = (date: any) => {
    const minMaxDateObj = getMinAndMaxDate();
    const renderDate = date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
    const count = bookingData?.dayWiseSlotCountMap?.get(renderDate) || 0;
    const isDisabled = !isDateBetweenRange(
      date,
      getSlotDate()?.slotStartDate,
      getSlotDate()?.slotEndDate,
    );
    if (isDisabled) {
      return isDisabled;
    }
    return count === 0;
  };

  const getAdditionalStyle = (isDisabled: boolean, isSelected: boolean) => {
    if (isDisabled) {
      return {
        borderColor: Colors.Custom.Gray100,
        fontSize: 12,
      };
    } else {
      return {
        borderColor: Colors.primary[100],
        fontSize: 14,
        backgroundColor: isSelected ? Colors.primary[300] : '',
        color: isSelected ? Colors.Custom.FontColorWhite : '',
      };
    }
  };

  const selectedTimezone = bookingData.selectedTimezone?.timezone || getCurrentTimeZone();

  const renderTimeSlotErrors = (): JSX.Element => {
    if (!getAvailableTimeSlotsQuery.loading && !bookingData?.slotList?.length) {
      return (
        <DisplayText
          textLocalId={'noSlotsAvailable'}
          extraStyles={{
            color: Colors.Custom.Gray700,
            fontWeight: 500,
            fontSize: 14,
          }}
        />
      );
    }
    return <></>;
  };

  const handleSlotSelection = (slot: ISlot) => {
    setSlotRange((prev) => ({
      ...prev,
      startDateTime: slotRange.slotStartDate,
      endDateTime: slotRange.slotEndDate,
    }));
    setBookingData((prev) => ({
      ...prev,
      slot: slot,
    }));
  };

  const getLocationList = (isVirtualLocation :boolean, disallowToScheduleForOtherLocation: boolean, locations: IUserPracticeLocation[], inviteeContact?: IContact) => {
    const contactAvailableLocations = getApplicableContactLocations(bookingData.inviteeContact, bookingData.allAccountLocations);
    if ((disallowToScheduleForOtherLocation || bookingData.displayContactLocation) && contactAvailableLocations.length && inviteeContact) {
      return filterContactLocations(inviteeContact, locations);
    } else if (bookingData.displayContactLocation && (!contactAvailableLocations.length || !locations?.length)) {
      return [];
    } else if (disallowToScheduleForOtherLocation && !contactAvailableLocations.length && locations?.length) {
      return locations;
    } else if (disallowToScheduleForOtherLocation && !contactAvailableLocations.length && !locations?.length) {
      return [];
    } else if (isVirtualLocation) {
      return [];
    } else {
      return locations;
    }
  };

  const filterContactLocations = (inviteeContact: IContact, userPracticeLocations: IUserPracticeLocation[]) => {
    if (userPracticeLocations?.length) {
      const contactAvailableLocations = getApplicableContactLocations(bookingData.inviteeContact, bookingData.allAccountLocations);
      return userPracticeLocations.filter(userPracticeLocation => {
        return isContactAndUserPracticeLocationSame(contactAvailableLocations, userPracticeLocation);
      });
    }
    return [];
  }

  const dateFullCellRender = (date: any) => {
    const renderDate = date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
    const count = bookingData?.dayWiseSlotCountMap?.get(renderDate) || 0;
    const isDisabled = isDisabledDate(date);
    const isSelected = isSelectedDate(date);
    const slotStartDate = slotRange?.selectedMonth && slotRange.selectedYear ? getFirstDateByMonthAndYear(slotRange?.selectedMonth, slotRange.selectedYear) :  getSlotDate().slotStartDate;
    const isSameMonthDate = isSameMonth(slotStartDate, date.toISOString());

    return (
      <Content
        className="slot-day"
        key={date?.date()}
        style={getAdditionalStyle(isDisabled || !isSameMonthDate, isSelected)}
      >
        <VStack flex={1} space={2}>
          <VStack flex={0.5}>{isSameMonthDate && (date?.date())}</VStack>
          <VStack flex={0.5}>
            {!isDisabled && isSameMonthDate && (
              <Slider
                w={8}
                isDisabled={false}
                value={getSliderTrackPercentage(count)}
                defaultValue={getSliderTrackPercentage(count)}
                colorScheme="green"
                onChange={() => {
                  setBookingData((prev) => ({
                    ...prev,
                    selectedDate: date?.toDate() || new Date(),
                    slotList: prev.dayWiseSlotMap.get(renderDate) || [],
                  }));
                }}
              >
                <Slider.Track bg={Colors.Custom.Gray200}>
                  <Slider.FilledTrack bg="green.700" />
                </Slider.Track>
              </Slider>
            )}
          </VStack>
        </VStack>
      </Content>
    );
  };

  const headerRender = (props: any) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = props.value.clone();
    const localeData = props.value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {months[index]}
        </Select.Option>
      );
    }
    const month = props.value.month();

    const year = props.value.year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return (
      <div style={{padding: 8}}>
        <HStack space={4} alignItems="center">
          <View>
            <Select
              style={{width: '75px', backgroundColor: 'transparent'}}
              size="small"
              className="my-year-select"
              onChange={(newYear) => {
                const now = props.value.clone().year(parseInt(newYear));
                props.onChange(now);
              }}
              value={String(year)}
            >
              {options}
            </Select>
          </View>
          <View>
            <Select
              style={{width: '75px', backgroundColor: 'transparent'}}
              size="small"
              value={String(month)}
              onChange={(selectedMonth) => {
                const newValue = props.value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                props.onChange(newValue);
              }}
            >
              {monthOptions}
            </Select>
          </View>
          <View>
            <Radio.Group
              size="large"
              onChange={(e) => props.onTypeChange(e.target.value)}
              value={props.type}
            >
              <Radio.Button value="month">Month</Radio.Button>
              <Radio.Button value="year">Year</Radio.Button>
            </Radio.Group>
          </View>
        </HStack>
      </div>
    );
  };

  const isVirtualVisit = isVirtualLocationType(scheduleLocationTypeList, bookingData.locationTypeId)
  const isVirtualLocation = isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;

  const loading =
    usersAPIQuery.loading ||
    appointmentTypeAPIQuery.loading ||
    consentFormData.loading ||
    contactAPIQuery.loading ||
    bookingData.slotLoading ||
    // getAvailableTimeSlotsQuery.loading ||
    accountSettingsApi.loading ||
    usersByIdAPIQuery.loading ||
    careTeamQuery.loading ||
    getAccountLocations.loading ||
    practitionerAPILoading;

  const bgColor = props.params.bg ? `#${props.params.bg}` : 'white';
  const shortLocationName = getShortLocationName();

  const allowToRenderLocation = () => {
    if (!isVirtualVisit) {
      return !!bookingData?.locationList?.length;
    }
    if (bookingData.disallowToScheduleForOtherLocation || bookingData.displayContactLocation){
      return bookingData.locationList.length > 1;
    }
    return bookingData.locationList.length > 1
  }

  const calendarHeaderText = () => {
    const availableSlotDate = getAvailableTimeSlotsDate(bookingData.dayWiseSlotMap, undefined);
    if (loading || getAvailableTimeSlotsQuery.loading) {
      return <></>;
    }
    if (!availableSlotDate) {
      const slotDate = getSlotDate();
      const dateFormat = 'MM/DD/YYYY';
      return (
        <View flex={['none', 'none', 1]} alignItems={'center'}>
          <Text>
            {`There is no slot available for ${bookingData.user?.name} from ${getFormattedDate(slotDate?.slotStartDate, dateFormat)} - ${getFormattedDate(slotDate?.slotEndDate, dateFormat)}. \n Please select another month/year to see available slots.`}
          </Text>
        </View>
      );
    } else if (availableSlotDate && isDisabledDate(getMomentObj(bookingData.selectedDate))) {
      return (<Text>Please select date</Text>);
    } else {
      return (<Text>{ getDateStrFromFormat(bookingData.selectedDate,'dddd, DD MMMM YYYY')} </Text>)
    }
  };
  return (
    <Box
      borderColor={Colors.Custom.BorderColor}
      rounded="lg"
      borderWidth={1}
      backgroundColor={bgColor}
      marginX={{
        base: 0,
        lg: '5%',
        xl: '15%',
      }}
      marginY={
        props.params.isEmbedded === 'true'
          ? 0
          : {
              base: 0,
              lg: 8,
            }
      }
    >
      <View
        backgroundColor={formThemeConfig?.topBar?.backgroundColor || 'white'}
        height={100}
        rounded={'xs'}
      >
        <View marginY={2}>
          <BrandingLogoWrapper />
        </View>
      </View>
      <Divider orientation="horizontal" />
      <View
        padding={{
          base: 4,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
        }}
      >
        {loading && (
          <View>
            <Skeleton />
          </View>
        )}
        {!loading && !!bookingData.error && (
          <Heading>{bookingData.error}</Heading>
        )}
        {!loading &&
          !bookingData.error &&
          bookingScreen === BookingScreen.slotBooking && (
            <VStack space={4}>
              <Stack direction={['column', 'column', 'row']} space={2}>
                <VStack space={4} flex={['none', 'none', 0.45]}>
                  {bookingData.inviteeContact && (
                    <HStack space={2} alignItems="center">
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: '12',
                        }}
                        isLetterAvatarShow
                        userData={{
                          userId: bookingData.inviteeContact?.id,
                          userType: GROUP_MEMBER_TYPE.CONTACT,
                          userName: bookingData.inviteeContact.name
                        }}
                      />
                      <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                        {bookingData.inviteeContact.name}
                      </Text>
                    </HStack>
                  )}
                  <Box
                    backgroundColor={'#f9f9f954'}
                    borderColor={Colors.Custom.BorderColor}
                    rounded="lg"
                    borderWidth={1}
                    padding={2}
                  >
                    {bookingData?.user && (
                      <HStack space={2} alignItems="start">
                        <Avatar
                          borderRadius="50%"
                          size="8"
                          backgroundColor={Colors.Custom.mainPrimaryPurple}
                          marginTop={1}
                        >
                          <Text color="white" size="xsNormal">
                            {getInitialsFromFullName(
                              bookingData.user?.name || '-'
                            )}
                          </Text>
                        </Avatar>
                        <VStack flex={1}>
                          <Text size="smNormal">{bookingData.user?.name}</Text>
                          {getUserRole() && (
                            <Text size="xsLight" color={Colors.Custom.Gray500}>
                              {getUserRole()}
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                    )}
                    {bookingData?.userList?.length > 1 && (
                      <HStack marginTop={2}>
                        <Spacer />
                        <Popover
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <View minWidth={300} padding={4}>
                              <View marginBottom={4}>
                                <DisplayText
                                  textType={'Heading'}
                                  textLocalId={'selectUser'}
                                  size="xlMedium"
                                />
                              </View>
                              <Select
                                size="large"
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select team member"
                                value={getSelectedUserValue(bookingData.user)}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value: any[], data: any) => {
                                  if (data && data.value) {
                                    setBookingData((prev) => ({
                                      ...prev,
                                      user: getUserFromId(
                                        bookingData.userList,
                                        data.value
                                      ),
                                    }));
                                  }
                                }}
                                style={{height: '40px'}}
                              >
                                {bookingData.userList.map((user) => {
                                  return (
                                    <Select.Option
                                      key={user.id}
                                      value={user.id}
                                    >
                                      {user.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </View>
                          }
                          title=""
                          trigger="click"
                          placement={'bottom'}
                        >
                          <View
                            backgroundColor="primary.100"
                            cursor="pointer"
                            height={6}
                            paddingX={2}
                            rounded="3xl"
                            justifyContent="center"
                          >
                            <Text
                              color="primary.500"
                              size="xsNormal"
                              textAlign="center"
                            >
                              Change
                            </Text>
                          </View>
                        </Popover>
                      </HStack>
                    )}
                    {!bookingData?.userList?.length &&
                      bookingData.disallowToScheduleForOtherLocation &&
                      bookingData.inviteeContact && (
                        <Text size="smNormal" color="error.500">
                          No team member available
                        </Text>
                      )}
                  </Box>
                  <VStack
                    space={1}
                    borderColor={Colors.Custom.BorderColor}
                    rounded="lg"
                    borderWidth={1}
                    backgroundColor={'#f9f9f954'}
                    padding={2}
                  >
                    {bookingData.appointmentType &&
                      bookingData.appointmentType.eventName && (
                        <HStack space={2} alignItems="flex-start">
                          <View marginTop={1}>
                            <Icon
                              as={AntIcon}
                              name="infocirlceo"
                              size="4"
                              color={Colors.Custom.Gray500}
                            />
                          </View>
                          <View flex={1}>
                            <Text size="smNormal">
                              {bookingData.appointmentType.eventName}
                            </Text>
                          </View>
                        </HStack>
                      )}
                    {bookingData.appointmentType?.duration && (
                      <HStack space={2} alignItems="flex-start">
                        <View marginTop={1}>
                          <Icon
                            as={AntIcon}
                            name="clockcircleo"
                            color={Colors.Custom.Gray500}
                            size="4"
                          />
                        </View>
                        <Text size="smNormal">
                          {bookingData.appointmentType?.duration} min
                        </Text>
                      </HStack>
                    )}
                    {(bookingData?.locationTypeId ||
                      bookingData?.appointmentType?.locationTypeId) && (
                      <HStack marginTop={1} space={2} flex={1}>
                        <HStack flex={0.5}>
                          <Icon
                            as={MaterialIcons}
                            name="location-on"
                            color={Colors.Custom.Gray500}
                            size="4"
                          />
                          <Text size="smNormal" marginLeft={2}>
                            {getLocationTypeValue(
                              scheduleLocationTypeList,
                              bookingData?.locationTypeId ||
                                bookingData?.appointmentType?.locationTypeId
                            )}
                          </Text>
                        </HStack>
                        {bookingData.appointmentType
                          ?.isPatientFacingLocationType && (
                          <HStack
                            flex={0.5}
                            alignContent={'flex-end'}
                            justifyContent={'flex-end'}
                          >
                            {scheduleLocationTypeList?.length > 0 && (
                              <Popover
                                overlayInnerStyle={{
                                  bottom: 3,
                                  borderRadius: 16,
                                  padding: 0,
                                }}
                                content={
                                  <View minWidth={300} padding={4}>
                                    <View marginBottom={4}>
                                      <DisplayText
                                        textType={'Heading'}
                                        textLocalId={'locationType'}
                                        size="xlMedium"
                                      />
                                    </View>
                                    <Select
                                      size="large"
                                      optionFilterProp="children"
                                      placeholder="Select Mode of Appointment"
                                      value={bookingData.locationTypeId}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onChange={(value: any[], data: any) => {
                                        if (data?.value) {
                                          setBookingData((prev) => {
                                            return {
                                              ...prev,
                                              locationTypeId: data.value,
                                              isShowPopover:
                                                !prev.isShowPopover,
                                            };
                                          });
                                        }
                                      }}
                                      style={{height: '40px'}}
                                    >
                                      {scheduleLocationTypeList.map(
                                        (locationType, index) => {
                                          return (
                                            <Select.Option
                                              key={`${locationType.id}_${index}`}
                                              value={locationType.id}
                                            >
                                              {locationType.value}
                                            </Select.Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </View>
                                }
                                title=""
                                trigger="click"
                                placement={'bottom'}
                              >
                                <View
                                  backgroundColor="primary.100"
                                  cursor="pointer"
                                  height={6}
                                  paddingX={2}
                                  rounded="3xl"
                                  justifyContent="center"
                                >
                                  <Text
                                    color="primary.500"
                                    size="xsNormal"
                                    textAlign="center"
                                  >
                                    Change
                                  </Text>
                                </View>
                              </Popover>
                            )}
                          </HStack>
                        )}
                      </HStack>
                    )}

                    {/* {bookingData.appointmentType?.locationType?.code ===
                      LOCATION_TYPE_CODES.VIRTUAL &&
                      !bookingData.locationList.length &&
                      !bookingData.location?.accountLocation?.practiceLocation
                        ?.name &&
                      bookingData.appointmentType?.locationType?.value && (
                        <HStack space={2} alignItems="flex-start">
                          <View marginTop={1}>
                            <Icon
                              as={AntIcon}
                              name="enviromento"
                              color={Colors.Custom.Gray500}
                              size="4"
                            />
                          </View>
                          <View flex={1}>
                            {bookingData.disallowToScheduleForOtherLocation &&
                              bookingData.contactLocation && (
                                <Text size="smNormal">
                                  {
                                    bookingData.appointmentType?.locationType
                                      ?.value
                                  }
                                </Text>
                              )}
                            {!(
                              bookingData.disallowToScheduleForOtherLocation &&
                              bookingData.contactLocation
                            ) && (
                              <Text size="smNormal">
                                {getLocationNameForDropdown(
                                  bookingData.contactLocation
                                )}
                              </Text>
                            )}
                          </View>
                        </HStack>
                      )} */}

                    {!isVirtualLocationType(scheduleLocationTypeList, bookingData.locationTypeId) && !(bookingData?.locationList?.length > 0) && (
                      <Text size="smNormal" color="error.500">
                        No Locations available
                      </Text>
                    )}
                    {
                      allowToRenderLocation() && (
                      <HStack space={2} alignItems="flex-start">
                        <View marginTop={1}>
                          <Icon
                            as={AntIcon}
                            name="enviromento"
                            color={Colors.Custom.Gray500}
                            size="4"
                          />
                        </View>
                        <View flex={1}>
                          <VStack>
                            {bookingData.location?.accountLocation
                              ?.practiceLocation?.name && (
                              <Text size="smNormal">
                                {getLocationNameForDropdown(
                                  bookingData.location
                                )}
                              </Text>
                            )}
                            <HStack marginTop={2}>
                              <Spacer />
                              {bookingData?.locationList?.length > 1 && (
                                <Popover
                                  overlayInnerStyle={{
                                    bottom: 3,
                                    borderRadius: 16,
                                    padding: 0,
                                  }}
                                  content={
                                    <View minWidth={300} padding={4}>
                                      <View marginBottom={4}>
                                        <DisplayText
                                          textType={'Heading'}
                                          textLocalId={'selectLocation'}
                                          size="xlMedium"
                                        />
                                      </View>
                                      <Select
                                        size="large"
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select Location"
                                        value={getSelectedLocationValue(
                                          bookingData.location
                                        )}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value: any[], data: any) => {
                                          if (data?.value) {
                                            setBookingData((prev) => ({
                                              ...prev,
                                              location: getLocationFromId(
                                                bookingData.locationList,
                                                data.value
                                              ),
                                            }));
                                          }
                                        }}
                                        style={{height: '40px'}}
                                      >
                                        {bookingData.locationList.map(
                                          (location, index) => {
                                            const locationName =
                                              getLocationNameForDropdown(
                                                location
                                              );
                                            return (
                                              <Select.Option
                                                key={`${location.uuid}_${index}`}
                                                value={location.uuid}
                                              >
                                                {locationName}
                                              </Select.Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </View>
                                  }
                                  title=""
                                  trigger="click"
                                  placement={'bottom'}
                                >
                                  <View
                                    backgroundColor="primary.100"
                                    cursor="pointer"
                                    height={6}
                                    paddingX={2}
                                    rounded="3xl"
                                    justifyContent="center"
                                  >
                                    <Text
                                      color="primary.500"
                                      size="xsNormal"
                                      textAlign="center"
                                    >
                                      Choose location
                                    </Text>
                                  </View>
                                </Popover>
                              )}
                            </HStack>
                          </VStack>
                        </View>
                      </HStack>
                    )}
                  </VStack>

                  <View width={'100%'} marginBottom={2}>
                    <TimezoneSelect
                      isRequired={false}
                      showLabel={true}
                      label={'selectTimezoneAppointmentWidget'}
                      headers={{
                        ...accountIdHeaders
                      }}
                      selectedTimezoneId={bookingData.selectedTimezone?.uuid}
                      onChange={(timezone?: ITimezone) => {
                        setBookingData((prev: any) => ({
                          ...prev,
                          selectedTimezone: timezone,
                        }));
                      }}
                    />
                  </View>
                </VStack>
                  <>
                    <View flex={['none', 'none', 1]} alignItems={'center'}>
                      {loading ||
                        (getAvailableTimeSlotsQuery.loading && (
                          <View width="full">
                            <Skeleton />
                          </View>
                        ))}
                      {!loading && !getAvailableTimeSlotsQuery.loading && (
                        <>
                          {bookingData?.appointmentType?.bookWithinDays && <Text
                            fontSize="xs"
                            color="gray.500"
                            mb={2}
                            ml={6}
                            fontWeight="700">
                            {getBookingErrorTextMessage(bookingData?.appointmentType, intl)}
                          </Text>}
                          <Calendar
                            style={{
                              width: '350px',
                              backgroundColor: 'transparent',
                            }}
                            fullscreen={false}
                            value={getMomentObj(bookingData.selectedDate)}
                            disabledDate={(current: any) => {
                              return current && isDisabledMonth(current);
                            }}
                            onChange={(momentDate) => {
                              const date = momentDate.format(
                                DATE_FORMATS.DISPLAY_DATE_FORMAT
                              );
                              const selectedMonth = moment(date).month() + 1;
                              const selectedYear = moment(date).year();
                              const bookWithinDays = bookingData?.appointmentType?.bookWithinDays
                              const startDate = getDateStrFromMomentObj(
                                moment(date).startOf('month'),
                                DATE_FORMATS.DISPLAY_DATE_FORMAT
                              );
                              const endDate = getDateStrFromMomentObj(
                                moment(date).endOf('month'),
                                DATE_FORMATS.DISPLAY_DATE_FORMAT
                              );
                              const isPastDate = moment(endDate).isBefore(moment(), 'day');
                              if (isPastDate) {
                                showToast(
                                  toast,
                                  'Can not select date in past',
                                  ToastType.info
                                );
                                return;
                              }
                              if (isFutureDateAllowed(startDate,bookWithinDays)) {
                                showToast(
                                  toast,
                                  getBookingErrorTextMessage(bookingData?.appointmentType, intl),
                                  ToastType.info,
                                  5000
                                );
                                return;
                              }
                              if (selectedMonth !== slotRange?.selectedMonth || selectedYear !== slotRange?.selectedYear) {
                                setSlotRange((prev)=>{
                                  return {
                                    ...prev,
                                    slotStartDate: startDate,
                                    slotEndDate: endDate,
                                    selectedMonth: selectedMonth,
                                    selectedYear: selectedYear
                                  }
                                })
                              }
                              const sameMonth = isSameMonth(startDate)
                              const isMonthYearChange = (selectedMonth !== slotRange?.selectedMonth || selectedYear !== slotRange?.selectedYear)
                              // selectedDate: isSameMonth ? new Date() : moment(startDate),
                              setBookingData((prev:any) => {
                                const slotList =
                                  prev.dayWiseSlotMap.get(date) || [];
                                const filteredSlotList = filterSlotsByAppointentType(slotList, bookingData.appointmentType);

                                return {
                                  ...prev,
                                  selectedDate: isMonthYearChange && !sameMonth ? moment(startDate) : isMonthYearChange && sameMonth ? moment(new Date()) : momentDate.toDate(),
                                  slotList: filteredSlotList,
                                  slot: filteredSlotList?.length
                                    ? filteredSlotList[0]
                                    : undefined,
                                };
                              });
                            }}
                            dateFullCellRender={dateFullCellRender}
                            headerRender={headerRender}
                          />
                        </>
                      )}
                    </View>
                    <VStack
                      flex={['none', 'none', 0.6]}
                      space={2}
                      alignItems="center"
                    >
                      <Heading paddingX={1}>
                        {calendarHeaderText()}
                      </Heading>

                      <View>
                        {!bookingData.slotError &&
                          bookingData.slotList.length > 0 &&
                          shortLocationName && (
                            <Text
                              fontStyle="italic"
                              fontSize="xs"
                              color="gray.500"
                            >{`Showing slots for ${shortLocationName}`}</Text>
                          )}
                      </View>

                      {getAvailableTimeSlotsQuery.loading && (
                        <View width="full">
                          <Skeleton />
                        </View>
                      )}

                      <div className="slots" >
                        {bookingData.slotLoading && (
                          <View width="full">
                            <Skeleton />
                          </View>
                        )}
                        <VStack paddingLeft={ isMobile ? 30 : 4}>
                        {!getAvailableTimeSlotsQuery.loading &&
                            !bookingData.slotError &&
                            bookingData?.slotList?.length > 0 && (
                              <SlotsGroup
                                selectedSlotDate={bookingData.selectedDate}
                                selectedSlot={bookingData.slot}
                                selectedTimezone={selectedTimezone}
                                userSlots={bookingData.slotList}
                                handleSlotSelection={handleSlotSelection}
                                screenContext={SCREEN_CONTEXT.APPOINTMENT_WIDGET}
                              />
                            )}
                            </VStack>
                          {!bookingData.slotLoading &&
                          renderTimeSlotErrors()}
                      </div>
                      </VStack>
                  </>
              </Stack>
              <HStack>
                <Spacer />
          {isMobile ?
          <View
            paddingX={4}
            backgroundColor="white"
            marginBottom={2}
            width={'100%'}
            height={'10%'}
          >
            <Button
              backgroundColor={'#825AC7'}
              isDisabled={!bookingData.slot}
              onPress={() => {
                bookBtnClicked();
              }}
              rounded={'full'}
              height={'51'}
              _text={{
                fontSize: 18,
                fontWeight: 700,
                color: 'white',
              }}
            >
              Next
            </Button>
          </View>
          :
          <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      bookBtnClicked();
                    },
                    isDisabled: !bookingData.slot,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: 'next'}),
                    withRightBorder: false,
                  }}
          />
          }
          </HStack>
            </VStack>
          )}
        {!loading && bookingScreen === BookingScreen.contactForm && (
          <Stack direction={['column', 'column', 'row']} space={4}>
            <VStack space={4} flex={['none', 'none', 0.35]}>
              {bookingData.inviteeContact && (
                <HStack space={2} alignItems="center">
                  <DisplayCardAvatar
                    avatarStyle={{
                      avatarSize: '12',
                    }}
                    isLetterAvatarShow
                    userData={{
                      userId: bookingData.inviteeContact?.id,
                      userType: GROUP_MEMBER_TYPE.CONTACT,
                      userName: bookingData.inviteeContact.name
                    }}
                  />
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    {bookingData.inviteeContact.name}
                  </Text>
                </HStack>
              )}
              <Box
                backgroundColor={'#f9f9f954'}
                borderColor={Colors.Custom.BorderColor}
                rounded="lg"
                borderWidth={1}
                padding={2}
              >
                <HStack space={2} alignItems="start">
                  <Avatar
                    borderRadius="50%"
                    size="8"
                    backgroundColor={Colors.Custom.mainPrimaryPurple}
                    marginTop={1}
                  >
                    <Text color="white" size="xsNormal">
                      {getInitialsFromFullName(bookingData.user?.name || '-')}
                    </Text>
                  </Avatar>
                  <VStack flex={1}>
                    <Text size="smNormal">{bookingData.user?.name}</Text>
                    {getUserRole() && (
                      <Text size="xsLight" color={Colors.Custom.Gray500}>
                        {getUserRole()}
                      </Text>
                    )}
                  </VStack>
                </HStack>
              </Box>
              <VStack
                space={2}
                borderColor={Colors.Custom.BorderColor}
                rounded="lg"
                borderWidth={1}
                backgroundColor={'#f9f9f954'}
                padding={2}
              >
                {bookingData.appointmentType &&
                  bookingData.appointmentType.eventName && (
                    <HStack space={2} alignItems="flex-start">
                      <View marginTop={1}>
                        <Icon
                          as={AntIcon}
                          name="infocirlceo"
                          size="4"
                          color={Colors.Custom.Gray500}
                        />
                      </View>
                      <View flex={1}>
                        <Text size="smNormal">
                          {bookingData.appointmentType.eventName}
                        </Text>
                      </View>
                    </HStack>
                  )}
                {bookingData.appointmentType?.duration && (
                  <HStack space={2} alignItems="flex-start">
                    <View marginTop={1}>
                      <Icon
                        as={AntIcon}
                        name="clockcircleo"
                        color={Colors.Custom.Gray500}
                        size="4"
                      />
                    </View>
                    <VStack>
                      <Text size="smNormal">
                        {getDateStrFromFormat(
                          bookingData.slot?.startDateTime || new Date(),
                          'dddd, MMM DD'
                        )}
                        {'   '}
                      </Text>
                      <Text size="smNormal">
                        {getDateStrFromFormat(
                          bookingData.slot?.startDateTime || new Date(),
                          DATE_FORMATS.DISPLAY_TIME_FORMAT
                        )}{' '}
                        -{' '}
                        {getDateStrFromFormat(
                          bookingData.slot?.endDateTime || new Date(),
                          DATE_FORMATS.DISPLAY_TIME_FORMAT
                        )}
                      </Text>
                    </VStack>
                  </HStack>
                )}
                {(bookingData?.locationTypeId || bookingData?.appointmentType?.locationTypeId)  && (
                  <HStack space={2} alignItems="flex-start">
                    <View marginTop={1}>
                      <Icon
                        as={AntIcon}
                        name="enviromento"
                        color={Colors.Custom.Gray500}
                        size="4"
                      />
                    </View>
                    <View flex={1}>
                      <Text size="smNormal">
                        {getLocationTypeValue(
                          scheduleLocationTypeList,
                          bookingData?.locationTypeId || bookingData?.appointmentType?.locationTypeId
                        )}
                        </Text>
                    </View>
                  </HStack>
                )}
              </VStack>
            </VStack>
            <View flex={['none', 'none', 0.05]} />
            <VStack flex={['none', 'none', 0.6]} space={2}>
              {bookingFlow === BookingFlow.withoutContact && (
                <FHForm
                  optionData={optionData}
                  components={bookingFormDetails.bookingFormComponents}
                  onChange={handleFormOnChange}
                />
              )}

              <View minWidth={350}>
                <ReasonForVisitFreeText
                  customPlaceHolder='Enter Reason for Visit'
                  isShowError={true}
                  value={bookingData.reasonForVisit?.displayName}
                  onChange={(reasonForVisit) => {
                    setBookingData((prev) => ({
                      ...prev,
                      reasonForVisit: reasonForVisit,
                    }));
                  }}
                />
              </View>
              <Spacer />
              <View flex={1}>
              <HStack space={2}>
                {!isMobile ? <Spacer /> : <></>}
                <Button
                  flex={isMobile ? 0.5 : null}
                  rounded="3xl"
                  variant="outline"
                  colorScheme="muted"
                  isDisabled={bookingData.bookingAPILoading}
                  onPress={() => {
                    setBookingScreen(BookingScreen.slotBooking);
                  }}
                >
                  <DisplayText textLocalId="back" />
                </Button>
                <Button
                  flex={isMobile ? 0.5 : null}
                  background={Colors.Custom.mainPrimaryPurple}
                  rounded="3xl"
                  isLoading={bookingData.bookingAPILoading}
                  isDisabled={!bookingFormDetails.isValid || bookingData.bookingAPILoading}
                  _loading={{
                    bg: 'primary.400',
                    _text: {
                      color: 'white',
                    },
                  }}
                  onPress={() => {
                    confirmButtonClicked();
                  }}
                >
                  <DisplayText
                    textLocalId="confirm"
                    extraStyles={{color: 'white'}}
                  />
                </Button>
              </HStack>
              </View>
            </VStack>
          </Stack>
        )}
        {bookingScreen === BookingScreen.successScreen && (
          <AppointmentBookingSuccessPage
            appointmentName={bookingData.appointmentType?.eventName || ''}
            appointmentStartDate={bookingData.slot?.startDateTime || ''}
            appointmentEndDate={bookingData.slot?.endDateTime || ''}
            appointmentLocation={getLocationName()}
            isRSVPAppointment={bookingData.appointmentType?.isRsvpEnabled || false}
            customWhereMessage={
              bookingData.appointmentType?.customFields?.find(
                (field) =>
                  field.valueDataTypeCode ===
                  APPOINTMENT_TYPE_DATA_TYPE_CODES.DISPLAY_CONFIG
              )?.value?.locationCustomMessage
            }
          />
        )}
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  scrollBar: {},
});

export default AppointmentBookingWidget;
