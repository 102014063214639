import { StringValueNode } from 'graphql';
import {
  ILocation,
  IMlov,
  IUser,
} from '../../../../../../Interfaces/CommonInterfaces';
import { IAppointmentTask } from '../../../../../../services/Appointment/AppointmentInterface';
import {ITimezone} from '../../../../../../services/Location/interfaces';
import {IAppointmentType} from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {
  IAppointmentDetail,
  ICalendarWidgetMetaData,
} from '../../../CalendarWidgetInterfaces';
import {IParticipantSearch} from '../../../ParticipantAutoComplete/ParticipantInterfaces';
import {AppointmentAction, FormStatus} from './AppointmentBookingEnums';

interface IPatient {
  contactId?: number;
  patientId?: any;
  patientUuid?: string;
}

export interface IContact {
  phoneNumber?: string;
  pubsubToken?: any;
  name: string;
  email?: string;
  gender?: string;
  id: number;
  uuid: string;
  patient?: IPatient;
  personAddress?: {
    cityId?: string;
    stateId?: string;
    countryId?: string;
    zipcodeId?: string;
    zipcodes?: {
      cityId?: string;
      stateId?: string;
    };
  }[];
  contactType?: {
    contactType?: {
      code?: string;
    };
  };
  person?: {
    birthDate?: string;
    gender?: {
      value?: string;
    };
    birthSexId?: string;
    sexAtBirth?: {
      value?: string;
      code?: string;
      id?: string;
    };
  };
}

export interface IParticipantData {
  isStatusUpdated?: boolean;
  patientId?: string;
  userId?: string;
  contactId?: string;
  slot?: ISlot;
  id?: string;
  statusId?: string | null;
  isInitiator?: boolean;
  user?: {
    id: number;
    name: string;
    uuid: string;
  };
  contact?: IContact;
  participantTypeId?: string;
  isDeleted?: boolean;
  groupId?: string;
  groupType?: string;
  reference?: any;
}
export interface IBookingData {
  appointmentTypeId: string;
  participants: IParticipantSearch[];
  startDateTime: string;
  endDateTime: string;
  selectedDate: Date;
  selectedEndDate?: Date;
  name: string;
  rsvpExpiryDateTime?: string;
  rsvpExpiryTimeOnly?: string;
}

export interface ISlot {
  startDateTime: string;
  endDateTime: string;
}

export interface IUserSlots {
  userId: string;
  duration: number;
  slots: ISlot[];
  user?: IAppointmentUserDetail;
  selectedSlot?: ISlot;
}

export interface IAppointmentUserDetail {
  id: string;
  name: string;
  uuid: string;
  userPracticeLocations: IUserPracticeLocation[] | any[];
  userRoles?: {
    userRole?: {
      userRole?: {
        id: string;
        code: string;
        value: string;
      };
    };
  }[];
}

export interface IUserPracticeLocation {
  practiceLocationUuid: string;
  uuid: string;
  practiceLocation: IPracticeLocation;
  accountLocation?: {
    uuid?: string;
    practiceLocation?: IPracticeLocation;
  };
}

export interface IPracticeLocation {
  uuid?: string;
  name: string;
  addressLine1?: string;
  addressLine2?: string;
  cityUuid?: string;
  stateUuid?: string;
  countryUuid?: string;
  practiceZipcode?: {code: string; stateId?: string; cityId?: string;};
  practiceState?: {name: string};
  practiceCountry?: {name: string};
  practiceCity?: {name: string};
}

export interface IGroupBookingData {
  selectedDate: Date;
  name: string;
  note: string;
  participants: IParticipantSearch[];
}

export interface IAppointmentNote {
  content: string;
  id?: string,
  subjectTypeId?: string
}

export interface ExpiryDateTime {
  unit: string;
  value: string;
}
export interface IAppointmentBookingState extends IBookingData {
  slots: ISlot[];
  hideSlots: boolean;
  errors: any;
  primaryUserList: IAppointmentUserDetail[];
  secondaryUserList: IAppointmentUserDetail[];
  selectedAppointmentType?: IAppointmentType;
  appointmentTypeList: IAppointmentType[];
  allAppointmentTypeList: IAppointmentType[];
  selectedSlot?: ISlot;
  selectedPrimaryUserId: string;
  selectedSecondaryUserIds: string[];
  consentForms: {
    id: string;
    name: string;
    isSelected: boolean;
    status: FormStatus;
  }[];
  primaryContactId?: string;
  slotLoading: boolean;
  selectedPracticeLocationId?: string | undefined;
  selectedUserLocations: IUserPracticeLocation[];
  reasonForVisit?: {
    code: string;
    displayName: string;
    type?: string;
  };
  workflowList: any;
  // contactAccountLocation?: IUserPracticeLocation;
  selectedContact?: IContact;
  bookAppointmentMeta?: {
    selectedUser: IUser;
    selectedLocation: ILocation;
  };
  selectedAccountLocationId?: string | undefined;
  scheduleLocationTypeList: IMlov[];
  selectedLocationType: any;
  selectedLocationTypeId: string | undefined;
  fetchedSlotsForNextDay?: boolean;
  calendarWidgetMetaData: ICalendarWidgetMetaData;
  isLocationLoading: boolean;
  isPracticeAvailabilitySchedulePresent: boolean;
  isRsvpEnabled: boolean;
  showPatientInstructionInput?:boolean;
  duration: number;
  isShowTimeZonePopover: boolean;
  selectedPatientLocation: string | undefined;
  selectedTimezone?: ITimezone;
  dayWiseSlotMap: Map<string, ISlot[]>;
  dayWiseAvailability: Map<string, ISlot[]>;
  // userWiseAppointmentType: Map<string, IUserAndAppointmentTypeMap>;
  appointmentTypeWiseUsers: Map<string, IAppointmentTypeWiseUsersMap>;
  isVisible: boolean;
  isBookedAppointmentLoading?: boolean;
  dateFilterChange?: boolean;
  appointmentFilterChange?: boolean;
  appointmentNote?: IAppointmentNote;
  patientInstruction?: IAppointmentNote;
  enabledAppointmentNote?: boolean;
  note?:any;
  tasks?: IAppointmentTask[];
  isSpecificTimeView: boolean;
  accountLocationList?: any[];
  userListProcessing: boolean;
  isRsvpExpiryEnabled?: boolean;
  description?: string;
  isLocationNotMatchWithAppointmentType?: boolean
  eventName?: {
    displayName: string;
    code?: string;
  };
  expiryDateTimeUnit: ExpiryDateTime,
  disAllowToRedirectAvailableSlot?: boolean,
  visitTypeId?: string;
}

export interface IAppointmentAvailableSlotsProps {
  date: string | undefined;
  duration: number;
  userId: string;
  showFormErrors: boolean | undefined;
  isVirtualLocation: boolean;
  locationIds: any;
  rescheduleAppointmentId?: string;
  appointmentTypeId?: string;
  updateSlotLoading?: (isLoading: boolean) => void;
  onSlotSelection: (selectedSlot: ISlot | undefined) => void;
  headers?: any;
}

export interface IAppointmentAvailableSlotsState {
  selectedSlot?: ISlot;
  availableSlots?: ISlot[];
  isSlotLoading?: boolean;
}

export interface IAppointmentDetailAction {
  name: JSX.Element;
  theme: string;
  isLoading: boolean;
  variant?: string;
  onClick: () => void;
}
export interface IAppointmentDetailState {
  primary: any;
  otherParticipants: any[];
  detail?: IAppointmentDetail;
  isReschedule: boolean;
  appointmentStatusId?: string;
  location?: IUserPracticeLocation;
  loggedInParticipantId?: string;
  appointmentTypeRole?: string;
  accountPracticeLocation?: IAccountPracticeLocation;
  participant?: IParticipantData;
  isInitiator?: boolean;
  isParticipantLoggedInUser?: boolean;
  showProposedTimeBanner?: boolean;
  individual?: any[];
  groupParticipantsMap?: {
    [key : string]: string[]
  };
  groupDataMap?: {
    [key : string]: string
  }
  userList?: any[];
  primaryUsers?: any[];
  secondaryUsers?: any[];
  contactList?: any[];
  rescheduleType?: AppointmentAction;
  prevContactList?: any[];
  isZoomMeeting?: boolean;
}

export interface IUserAndAppointmentTypeMap {
  userDetail: IUser;
  appointmentTypes: IAppointmentType[];
}

export interface IAppointmentTypeWiseUsersMap {
  availabilityTypeCode: string;
  appointmentType: IAppointmentType;
  availablePrimaryUsers: IUser[];
  availableSecondaryUsers: IUser[];
}

export interface IAccountPracticeLocation {
  uuid?: string;
  practiceLocation?: IPracticeLocation;
}

export interface IRestrictedUserAppointmentTypesList {
  id: string;
  restrictedUserPoolId: string;
  eventName:string;
}

export interface IRestrictedUserAppointmentTypes {
  list: IRestrictedUserAppointmentTypesList[];
  show: boolean;
}

export interface IBookingLinkParams {
  meetingId: number;
  joineeEmail: string;
  appointmentId: string;
  accountUUID: string;
  userUuid?: string;
  name: string;
  contactUuid?: string;
  isZoomEnabled?: boolean;
}


export enum GroupAppointmentParticipantActions {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
  DELETE_INVITATION = 'DELETE_INVITATION',
  COPY_RSVP_LINK = 'COPY_RSVP_LINK',
  SEND_RSVP_LINK = 'SEND_RSVP_LINK',
  COPY_MEETING_LINK = 'COPY_MEETING_LINK',
  SEND_MEETING_LINK = 'SEND_MEETING_LINK',
}

export interface IRecordingLink {
  id: string;
  meetingOrWebinarId: string;
  link: string;
  type: string;
  createdOn: string;
  recordingInfo?: {
    recording_end?: string;
    recording_start?: string;
    id?: string;
  };
}
