import { Dimensions } from 'react-native'
import React, { useRef, useState } from 'react'
import { isEqual } from 'lodash';
import { List, Modal, Skeleton, Tooltip, notification, Checkbox } from 'antd';
import { Draggable, DraggableProvided, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { BUTTON_TYPE } from '../../../../constants';
import { Badge, HStack, View, Text, Spacer, Spinner, Divider, VStack, Image } from 'native-base';
import { Colors } from '../../../../styles';
import { ITaskCategory, ITaskMetaData, MultiSelectAction, MultiSelectState } from '../../TaskInterfaces';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../utils/commonViewUtils';
import useTaskManager from '../../CustomHooks/useTaskManager';
import { TASK_DEFAULT_PAGE_SIZE } from '../../TaskModuleHelper';
import { FetchTaskState } from '../../../common/CareDashboard/CareDashboardConstants';
import { BottomViewAction, canHideCheckBox, descriptionDisplayModes, getBottomView, getCardTag, getDescription } from '../../../TaskCard/TaskCardHelper';
import TaskCard from '../../../TaskCard/TaskCard';
import { TASK_STATUS } from '../../../../constants/MlovConst';
import { getVitalListFromCapability } from '../../../../utils/capabilityUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from '../../../TaskCard/Card.native';
import { getMomentObj, isPastDay } from '../../../../utils/DateUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import useTaskCountManager from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import { getTodayDueTaskTextLabel } from '../../../TaskCard/TaskHelper';
import { BoardType } from '../../../common/CareDashboard/CareDashboardTopBar/interfaces';

interface ITaskKanbanColumnProps {
  category: ITaskCategory;
  metaData: ITaskMetaData;
  noOfColumns: number;
  isDropDisabled: boolean;
  taskCountData: {taskCount: {code: string, count: number}[]; todaysTaskCount: {code: string, count: number}[];}
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
}

const TaskKanbanColumn = (props: ITaskKanbanColumnProps) => {
  // Constants
  const { noOfColumns, category, metaData, isDropDisabled, taskCountData, onTaskDetail, onMemberClick } = props;
  const intl = useIntl();
  const taskScrollContainer = useRef(null);
  const {height} = Dimensions.get('window');
  const width = noOfColumns > 1 ? `calc(100% / ${noOfColumns})` : '50%';
  const patientId = metaData.personData?.patientId || metaData.personData?.patientUuid;
  const accountLocationUuid = metaData.personData?.accountLocationUuid || '';
  const vitals = getVitalListFromCapability();
  const isPatientTask = false;
  const selectedTaskIds = metaData.selectedTasks?.map(item => item.id) || [];


  const onShowToast = (message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }

  // Task data manager
  const {
    loading,
    tasks,
    pagination,
    taskActionsInProgress,
    fetchTasks,
    handleTaskActions,
    handleMultiSelection,
    getMultiselectState,
  } = useTaskManager({
    filters: category.filters,
    categoryCode: category.code,
    boardType: metaData.boardType,
    multiSelectData: {
      bulkActionEnabled: metaData.bulkTaskEnabled,
      selectedTasks: metaData.selectedTasks,
    },
    pagination: {
      pageNumber: 1,
      pageSize: TASK_DEFAULT_PAGE_SIZE,
    },
    showToast: onShowToast,
    onSelectedTaskChange: metaData.onSelectedTaskChange,
  });

  const countData = taskCountData?.taskCount?.filter(item => item.code === category.code)?.[0];
  const todaysCountData = taskCountData?.todaysTaskCount?.filter(item => item.code === category.code)?.[0];
  const isTaskPoolBoard = metaData.boardType === BoardType.taskPool;

  // States
  const [ componentState, setComponentState ] = useState<{loaderInColumns: boolean}>({
    loaderInColumns: false,
  });
  const [deleteTaskData, setDeleteTaskData] = useState<{ showConfirmation: boolean, task?: ITask }>({
    showConfirmation: false,
    task: undefined,
  });

  // Other methods
  const getColumnHeader = () => {
    return (
      <Text>{category.name} </Text>
    );
  }

  const callTaskAPI = async (refresh: boolean) => {
    const pageSize = pagination.pageSize;
    let pageNumber = 1;
    let taskState: FetchTaskState = FetchTaskState.resetAndFetchFromPagination;
    if (!refresh) {
      if (!hasMoreData()) {
        setComponentState((prev: any) => {
          return {
            ...prev,
            loaderInColumns: false
          }
        })
        return;
      }
      pageNumber = pagination.pageNumber + 1;
      taskState = FetchTaskState.fetchAndAppendToList;
    }
    await fetchTasks({ pageNumber, pageSize }, category.filters, { state: taskState });
    if (refresh) {
      setComponentState((prev: any) => {
        return {
          ...prev,
          loaderInColumns: false
        }
      });
    }
    if (refresh && taskScrollContainer?.current) {
      (taskScrollContainer.current as any).scrollTop = 0;
    }
  }

  const fetchNextPage = () => {
    if (loading) {
      setComponentState((prev: any) => {
        return {
          ...prev,
          loaderInColumns: false
        }
      });
      return;
    }
    callTaskAPI(false);
  }

  const hasMoreData = () => {
    const offset = pagination.pageNumber * pagination.pageSize;
    return pagination.total == -1 || offset < pagination.total;
  }

  const getTaskCardLoading = (task: ITask) => {
    return taskActionsInProgress.filter(item => item.taskId === task.id).length > 0;
  }

  const onBottomViewAction = async (task: ITask, action: BottomViewAction, data?: any) => {
    if (metaData.bulkTaskEnabled) {
      return;
    }
    switch (action) {
      case BottomViewAction.deleteTask:
        setDeleteTaskData((prev) => ({...prev, showConfirmation: true, task}));
        break;
      case BottomViewAction.captureLabTest:
      case BottomViewAction.captureVital:
        handleTaskActions(task, action, data, { patientId, accountLocationUuid });
        break;

      default:
        await handleTaskActions(task, action, data);
        break;
    }
  }

  const onDeleteConfirm = async () => {
    const task = deleteTaskData.task;
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
    if (task) {
      await handleTaskActions(task, BottomViewAction.deleteTask, undefined);
    }
  }
  const onDeleteCancel = () => {
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
  }

  const getNoDataText = () => {
    if (category.filters.searchString) {
      return 'No tasks found in search';
    }
    return 'No tasks to show';
  };

  const getColumnHeight = () => {
    if (isPatientTask) {
      return height - 360;
    } else {
      return height - 290;
    }
  };

  const getDisplayItem = (task: ITask) => {
    const isLoading = getTaskCardLoading(task);
    const isMultiSelectEnabled = multiSelectState !== MultiSelectState.disabled;
    let isTaskSelected = task.isCompleted || task?.status?.code === TASK_STATUS.COMPLETED || false;
    if (isMultiSelectEnabled) {
      isTaskSelected = selectedTaskIds?.includes(task.id) || false;
    }
    return (
      <>
        <View
          width={'full'}
          key={`${task.id}_${isLoading}_${isTaskSelected}_${metaData.bulkTaskEnabled}`}
        >
          <TaskCard
            task={task}
            isPatientTask={isPatientTask || false}
            key={task.id}
            title={task.title}
            disable={false}
            isChecked={isTaskSelected}
            data={task}
            handleDeleteOnCallback
            description={getDescription(
              task,
              task.patientName || task.contact?.name,
              descriptionDisplayModes.SHORT,
              vitals,
            )}
            tag={getCardTag(task)}
            assigneeName={isPatientTask ?
              (task.assigneeUser?.name || task.patientName || task.contact?.name) :
              (task.patientName || task.contact?.name)
            }
            onPress={() => {
              if (metaData.bulkTaskEnabled) {
                return;
              }
              onTaskDetail?.(task);
            }}
            loading={isLoading}
            attachments={
              task.patientEducations?.map((education: any) => {
                return education.patientEducation;
              }) || []
            }
            onCheckBoxSelect={
              metaData.bulkTaskEnabled ?
              (isSelected: boolean) => {
                handleMultiSelection?.(isSelected ? MultiSelectAction.selectTask : MultiSelectAction.unSelectTask, [task]);
              } : undefined
            }
            onCheckBoxChange={(isSelected) => {
              const currentTaskDuedate = task?.endDateTime
              const isPastDueDate = currentTaskDuedate ? isPastDay(getMomentObj(currentTaskDuedate)) : false
              const incompleteAction = isPastDueDate ? BottomViewAction.markAsMissed : BottomViewAction.markAsIncomplete;
              onBottomViewAction(task, isSelected ? BottomViewAction.markAsComplete : incompleteAction, {});
            }}
            onAttachmentClick={(patientEducation) => {
              window.open(patientEducation.url, '_blank');
            }}
            onAssigneeClick={() => {
              onMemberClick?.(task);
            }}
            bottomView={
              task.isCompleted
                ? undefined
                : getBottomView(
                    task,
                    isLoading,
                    vitals,
                    onBottomViewAction,
                    false,
                    false,
                    true
                  )
            }
            hideCheckBox={canHideCheckBox(task) && !metaData.bulkTaskEnabled}
            onDeleteTaskHandler={onBottomViewAction}
            hideDeleteAction={true}
            hideAssigneeNavigation={isPatientTask}
            showDeleteField={true}
          />
        </View>
      </>
    );
  }

  const multiSelectState = getMultiselectState();

  return (
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: Colors.Custom.Gray200,
        flex: 1,
        borderRadius: 12,
        width: width,
      }}
    >
      <View marginY={4} marginX={2}>
        <HStack space={1}>
          <View paddingX={2}>
            {getColumnHeader()}
          </View>
          <Badge
            fontSize={12}
            fontWeight={500}
            backgroundColor="gray.100"
            alignSelf="center"
            rounded="full"
            justifyContent={'center'}
            paddingY={1}
            paddingX={2}
          >
            {getTodayDueTaskTextLabel(todaysCountData?.count, countData?.count, category.code, isTaskPoolBoard)}
          </Badge>
          <Spacer />
          {loading &&
            <View
              width={'25px'}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size={'sm'} />
            </View>
          }
          {multiSelectState !== MultiSelectState.disabled && (
            <View marginRight={2}>
              <Checkbox
                checked={multiSelectState === MultiSelectState.all}
                indeterminate={multiSelectState === MultiSelectState.some}
                value="multiselect"
                onChange={async (event) => {
                  const value = event.target.checked;
                  handleMultiSelection?.(value ? MultiSelectAction.selectAllTasks : MultiSelectAction.unSelectAllTask);
                }}
              />
            </View>
          )}
        </HStack>
      </View>

      <Divider bgColor={'gray.200'} />

      <div
        id={category.code}
        style={{height: getColumnHeight(), overflowY: 'scroll', backgroundColor: Colors.FoldPixel.GRAY100}}
        ref={taskScrollContainer}
      >
        <View>
          <Droppable
            droppableId={category.code}
            isDropDisabled={isDropDisabled}
          >
            {(
              provided: DroppableProvided,
              snapshot: DroppableStateSnapshot
            ) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{height: getColumnHeight()}}>
                  {
                    componentState?.loaderInColumns &&
                    <View marginTop={25}>
                      <Spinner size={'lg'} />
                    </View>
                  }
                  {
                    !componentState?.loaderInColumns && !!tasks?.length &&
                    <InfiniteScroll
                      dataLength={tasks.length}
                      next={fetchNextPage}
                      hasMore={hasMoreData()}
                      loader={
                        <VStack paddingX={4} space={2}>
                          <View width={'full'} >
                            <Card
                              bgColor={'#FFF'}
                            >
                              <Skeleton paragraph={{ rows: 2 }} active />
                            </Card>
                          </View>
                          <Text
                            height={8}
                            alignItems={'center'}
                            justifyContent={'center'}
                            textAlign={'center'}
                            color={Colors.Custom.Gray500}
                          >
                            {`Fetching tasks...`}
                          </Text>
                        </VStack>
                      }
                      scrollableTarget={category.code}
                    >
                      <InnerList
                        taskList={tasks}
                        getDisplayItem={getDisplayItem}
                        loading={taskActionsInProgress.length > 0}
                        total={pagination.total}
                        selectedTaskIds={selectedTaskIds}
                        bulkActionEnabled={metaData.bulkTaskEnabled || false}
                      />
                      {provided.placeholder}
                    </InfiniteScroll>
                  }
                  {!componentState?.loaderInColumns && tasks?.length === 0 && !snapshot.isDraggingOver && (
                    <VStack
                      justifyContent={'center'}
                      height={height - (isPatientTask ? 360 : 310)}
                      alignItems={'center'}
                      padding={5}
                    >
                      <Image
                        width={'100px'}
                        height={'110px'}
                        resizeMode={'contain'}
                        source={require('../../../../assets/images/PNGImages/TabsPngIcons/EmptyState.png')}
                      />
                      <Text textAlign={'center'}>{getNoDataText()}</Text>
                    </VStack>
                  )}
                </div>
              );
            }}
          </Droppable>
        </View>
      </div>
      {deleteTaskData.showConfirmation && (
        <Modal
          open={deleteTaskData.showConfirmation}
          footer={null}
          closable={false}
        >
          <VStack>
            <Text
              mb={1}
              fontSize={'xl'}
              fontWeight={'bold'}
            >{`Delete Task?`}</Text>
            <Text my={1} fontSize={'md'}>
              {
                deleteTaskData?.task?.resourceMap?.notes ? intl.formatMessage({id: 'deleteNoteLinkTaskConfirmation'}) :  (deleteTaskData?.task?.parentId ? intl.formatMessage({ id: 'deleteSubTaskConfirmation' }) : intl.formatMessage({ id: 'deleteTaskConfirmation' }))
              }
            </Text>
            <HStack mt={5} justifyContent={'flex-end'}>
              {[
                {
                  textLocalId: 'cancel',
                  buttonProps: {
                    variant: BUTTON_TYPE.SECONDARY,
                  },
                  onPress: onDeleteCancel,
                },
                {
                  textLocalId: 'delete',
                  buttonProps: {
                    variant: BUTTON_TYPE.PRIMARY,
                  },
                  onPress: onDeleteConfirm,
                },
              ].map((button: any, index: number) => {
                  return (
                    <FoldButton
                      key={index + button.btnText}
                      nativeProps={{
                        variant: button?.buttonProps?.variant,
                        onPress: () => {
                          button?.onPress();
                        },
                        ml: 2,
                        isLoading: button?.loading,
                        isDisabled: button?.loading
                      }}
                      customProps={{
                        btnText: intl.formatMessage({id: button.textLocalId}),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  );
              })}
            </HStack>
          </VStack>
        </Modal>
      )}
    </div>
  );
}

class InnerList extends React.Component<{
  taskList: any[],
  getDisplayItem: any,
  loading?: boolean,
  total?: number,
  selectedTaskIds: string[],
  bulkActionEnabled: boolean;
}> {
  shouldComponentUpdate(nextProps: Readonly<{ taskList: any[]; getDisplayItem: any; loading?:boolean, total?: number, selectedTaskIds: string[], bulkActionEnabled: boolean }>, nextState: Readonly<{}>, nextContext: any): boolean {
    if(nextProps.loading !== this.props.loading){
      return true
    }
    if(nextProps.total !== this.props.total){
      return true
    }
    if (!isEqual(nextProps.taskList, this.props.taskList)) {
      return true;
    }
    if (nextProps.selectedTaskIds.length !== this.props.selectedTaskIds.length) {
      return true;
    }
    if(nextProps.bulkActionEnabled !== this.props.bulkActionEnabled){
      return true;
    }
    if (nextProps.taskList === this.props.taskList) {
      return false;
    }
    return true;
  }

  render() {
    const { taskList, getDisplayItem } = this.props;

    return (
      <List
        dataSource={taskList}
        size="small"
        style={{width: '100%', height: '100%'}}
        rowKey={(item: any): string => item?.id}
        renderItem={(item, index) => {
          return (
            <Draggable
              key={`Draggable_${item?.id}`}
              draggableId={item?.id}
              index={index}
            >
              {(provide: DraggableProvided) => (
                <div
                  {...provide.draggableProps}
                  {...provide.dragHandleProps}
                  ref={provide.innerRef}
                >
                  <List.Item style={{width: '100%'}}>
                    {getDisplayItem(item)}
                  </List.Item>
                </div>
              )}
            </Draggable>
          );
        }}
      />
    );
  }
}

export default TaskKanbanColumn
