const ClockHistorySvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6.99999H6.5V7.2071L6.64645 7.35354L7 6.99999ZM7.5 4.33332C7.5 4.05718 7.27614 3.83332 7 3.83332C6.72386 3.83332 6.5 4.05718 6.5 4.33332H7.5ZM8.31311 9.02021C8.50838 9.21547 8.82496 9.21547 9.02022 9.02021C9.21548 8.82495 9.21548 8.50837 9.02022 8.3131L8.31311 9.02021ZM11.2213 2.77876L10.8677 3.13232L11.2213 2.77876ZM2.73609 2.73612L2.38254 2.38257L2.38254 2.38257L2.73609 2.73612ZM2.77873 11.2213L3.13229 10.8677L2.77873 11.2213ZM11.2639 11.2639L11.6175 11.6175L11.2639 11.2639ZM1.89184 3.58038L1.39185 3.58289C1.39322 3.85707 1.61515 4.07899 1.88933 4.08037L1.89184 3.58038ZM3.58636 4.0889C3.8625 4.09029 4.08748 3.86756 4.08887 3.59142C4.09026 3.31528 3.86753 3.0903 3.59139 3.08891L3.58636 4.0889ZM2.38331 1.88083C2.38192 1.60469 2.15694 1.38196 1.8808 1.38335C1.60466 1.38474 1.38193 1.60972 1.38332 1.88586L2.38331 1.88083ZM1.55107 6.18998C1.58857 5.91639 1.39719 5.66421 1.1236 5.62671C0.850018 5.58921 0.597835 5.78059 0.560335 6.05418L1.55107 6.18998ZM7.5 6.99999V4.33332H6.5V6.99999H7.5ZM6.64645 7.35354L8.31311 9.02021L9.02022 8.3131L7.35355 6.64644L6.64645 7.35354ZM11.5748 2.42521C9.02803 -0.121584 4.91249 -0.147375 2.38254 2.38257L3.08965 3.08968C5.22239 0.95694 8.70473 0.96933 10.8677 3.13232L11.5748 2.42521ZM2.42518 11.5749C4.97197 14.1216 9.08751 14.1474 11.6175 11.6175L10.9104 10.9104C8.77762 13.0431 5.29527 13.0307 3.13229 10.8677L2.42518 11.5749ZM11.6175 11.6175C14.1474 9.08754 14.1216 4.972 11.5748 2.42521L10.8677 3.13232C13.0307 5.2953 13.0431 8.77765 10.9104 10.9104L11.6175 11.6175ZM2.38254 2.38257L1.53829 3.22682L2.24539 3.93393L3.08965 3.08968L2.38254 2.38257ZM1.88933 4.08037L3.58636 4.0889L3.59139 3.08891L1.89435 3.08038L1.88933 4.08037ZM2.39183 3.57786L2.38331 1.88083L1.38332 1.88586L1.39185 3.58289L2.39183 3.57786ZM0.560335 6.05418C0.292713 8.00664 0.915946 10.0656 2.42518 11.5749L3.13229 10.8677C1.8519 9.58736 1.32442 7.84352 1.55107 6.18998L0.560335 6.05418Z"
        fill="#6F7A90"
      />
    </svg>
  );
};
export default ClockHistorySvg;
