import React from 'react';
import { ICommonSvgProps } from './interfaces';
import Svg, { Path } from 'react-native-svg';

const FileIconSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor ? props.customStrokeColor : '#825AC7';
  return (
    <Svg
      width={props?.width ||  "20"}
      height={props?.height || "20"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.667 1.667H5.001a1.667 1.667 0 00-1.667 1.667v13.333a1.667 1.667 0 001.667 1.667h10a1.667 1.667 0 001.666-1.667v-10l-5-5z"
      ></Path>
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.666 1.667v5h5M13.333 10.833H6.666M13.333 14.167H6.666M8.333 7.5H6.666"
      ></Path>
    </Svg>
  );
};

export default FileIconSvg;
