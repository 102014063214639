import React from 'react';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import NoPinnedWidgetsIconSvg from '../../../../../../common/Svg/NoPinnedWidgetsIconSvg';
import NoDataFoundWithCustomIcon from '../../../../../../common/NoDataFound/NoDataFoundWithCustomIcon';
import GraphIconSvg from '../../../../../../common/Svg/GraphIconSvg';

export const NoDataFoundView = () => {
  return (
    <Stack
      direction="column"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 40,
      }}
    >
      <NoDataFoundWithCustomIcon
        icon={<GraphIconSvg />}
        displayString="noDataAvailable"
      />
    </Stack>
  );
};
