import React from 'react';
import {ICommonSvgProps} from './interfaces';
import {Colors} from '../../../styles/Colors';
import {Image} from 'native-base';
import { isWeb } from '../../../utils/platformCheckUtils';

const ArrowDownSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  const customColor = props.customStrokeColor
    ? props.customStrokeColor
    : strokeColor;
  return (
    <>
    {isWeb() ? (
    <svg
      width="inherit"
      height="inherit"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6.75L9 11.25L13.5 6.75"
        stroke={customColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    ) : (
      <Image
        resizeMode="contain"
        size={'24px'}
        source={require('../../../assets/images/PNGImages/arrowDownPng.png')}
        alt="image"
      />
    )}
  </>
  );
};

export default ArrowDownSvg;
