import {Modal} from "antd"
import {Button, Text} from 'native-base';
import {BUTTON_TYPE} from "../../../constants"
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton"
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {useIntl} from "react-intl";
import {Colors} from "../../../styles";
import {IRemoveMemberConfirmation} from "./interface";
import {replaceWordInSentence} from "../../../utils/commonUtils";
import { testID } from "../../../testUtils";

const RemoveMemberConfirmation = (props: IRemoveMemberConfirmation) => {
  const intl = useIntl()
  const userName = props.modalData?.name || 'user'
  return (
    <Modal
      title="Confirmation"
      open={true}
      onOk={() => {
        props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
      }}
      onCancel={()=> {
        props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
      }}
      footer={null}
    >
      <Text {...testID('removeAssignUserConfirmationText')} style={{color: Colors.Custom.Gray600, fontSize: 16}}>
      {replaceWordInSentence(
          intl.formatMessage({id: 'removeAssignUserConfirmationText'}),
          'username',
          userName
        )}
      </Text>
      <Button.Group style={{ marginTop: 12, justifyContent: 'flex-end' }}>
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.SECONDARY,
          style: {margin: 3},
          onPress: () => {
           props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
          },
        }}
        customProps={{
          btnText: 'Cancel',
          withRightBorder: false,
        }}
      ></FoldButton>
        <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          style: {margin: 3},
          onPress: () => {
           props.onActionPerformed(COMMON_ACTION_CODES.SAVE)
          },
        }}
        customProps={{
          btnText: 'Confirm',
          withRightBorder: false,
        }}
      ></FoldButton>
      </Button.Group>
    </Modal>
  )
}

export default RemoveMemberConfirmation;
