import React from 'react'
import { Button, Text } from 'native-base'
import { Colors } from '../../../../../../styles'
import Stack from '../../../../../common/LayoutComponents/Stack';

const CustomViewButton = (props:{
    onPress: () => void,
    title?: string,
    icon?: JSX.Element,
}) => {
  return (
    <Button
      onPress={() => props.onPress()}
      background={Colors.Custom.Base50}
      borderWidth={1}
      borderColor={Colors.Custom.primaryLight}
      px={3}
      py={1}
    >
      <Stack 
        direction='row' 
        style={{
          alignItems: 'center'
        }}
      >
      {props?.icon}
      <Text
        color={Colors.Custom.PurpleColor}
        ml={props?.icon ? 1 : undefined}
        fontSize={14}
        fontWeight="700"
      >
        {props?.title || 'View'}
      </Text>
      </Stack>
    </Button>
  );
}

export default CustomViewButton