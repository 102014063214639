import {gql} from '@apollo/client';

export const GetProblem = gql`
  query GetProblem($patientId: string!) {
    ConditionList(patient: $patientId) {
      id
      recordedDate
      code {
        text
      }
      clinicalStatus {
        text
        coding {
          display
          version
          code
          id
          system
          userSelected
        }
      }
    }
  }
`;

export const GetAllergyListByPatientId = gql`
  query GetAllergyListByPatientId($patientId: string!) {
    AllergyIntoleranceList(patient: $patientId) {
      id
      recordedDate
      code {
        id
        text
      }
      patient {
        id
      }
      clinicalStatus {
        text
        coding {
          display
          version
          code
          id
          system
          userSelected
        }
      }
    }
  }
`;

export const GetImmunizationById = gql`
  query GetImmunizationById(
    $patientId: string = "096724e0-4f46-4af9-8d25-2e5d6ec47526"
  ) {
    ImmunizationList(patient: $patientId) {
      id
      patient {
        id
        type
        display
      }
      status
      vaccineCode {
        id
        text
        coding {
          code
          userSelected
          system
          version
          id
          display
        }
      }
      occurrence {
        dateTime
      }
    }
  }
`;

export const GetMadicationById = gql`
  query GetMadicationById($patientId: string, $status: string) {
    MedicationRequestList(patient: $patientId, status: $status) {
      id
      authoredOn
      medication {
        CodeableConcept {
          text
          coding {
            code
            system
          }
        }
      }
      status
    }
  }
`;

export default {
  GetProblem,
  GetAllergyListByPatientId,
  GetImmunizationById,
  GetMadicationById,
};
