import React from 'react';
import { Empty, Select, Tag } from 'antd';
import { HStack, VStack, View, Text, Pressable } from 'native-base';
import { DisplayCardAvatar } from '../../../../common/DisplayCard/DisplayCardAvatar';
import { GROUP_MEMBER_TYPE } from '../../../../../constants';
import { IUser } from '../../../../../Interfaces';
import { Colors } from '../../../../../styles';
const { Option } = Select;
import './styles.scss';
import Feather from 'react-native-vector-icons/Feather';

interface IAppointmentUserSelectProps {
  value?: any;
  userList: IUser[];
  isMultiSelect: boolean;
  showError?: boolean;
  placeholder?: string;
  userProfileImageMap?: {[index: string]: string};
  showViewSchedule?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange?: (userIds: string[]) => void;
  onViewSchedule?: (user: IUser) => void;
  noDataMessage?: string
}

export const AppointmentUserSelect = (props: IAppointmentUserSelectProps) => {
  const {
    value,
    onChange,
    userList,
    placeholder,
    isMultiSelect,
    userProfileImageMap,
    onViewSchedule,
    showError,
    showViewSchedule,
    isLoading,
    isDisabled
  } = props;

  const handleUsersChange = (selectedData?: any[]) => {
    let userIds: string[] = [];
    const userSelectedIds = selectedData?.map((item: any) => {
        return item.key;
    }) || [];
    userIds = userSelectedIds;
    onChange && onChange(userIds);
  };

  const getRoles = (user: IUser) => {
    const roleNames =
      user.userRoles?.map((role: any) => role?.userRole?.userRole?.value) || [];
    return roleNames.join(' • ');
  };

  const getUsersName = (ids: string[]) => {
    return userList.filter((item) => ids.includes(item.uuid)).map((item) => item.name);
  }

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label = tagProps.value && getUsersName([tagProps.value])?.[0];
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className='custom-tag'
        icon={
          <Feather
            name="user"
            size={12}
            style={{marginRight: 5}}
            color={Colors.Custom.Gray400}
          />
        }
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      notFoundContent={
        props?.noDataMessage ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<Text color={Colors.Custom.Gray400}>{props?.noDataMessage}</Text>}
          />
        ) : undefined
      }
      mode={isMultiSelect ? 'multiple' : undefined}
      disabled={isDisabled}
      loading={isLoading}
      size="large"
      allowClear
      showSearch
      value={isMultiSelect ? value : getUsersName(value)?.[0]}
      placeholder={placeholder}
      tagRender={isMultiSelect ? tagRender : undefined}
      onChange={(value: any, options: any) => {
        handleUsersChange(
          isMultiSelect ? options : options ? [options] : undefined
        );
      }}
      status={showError ? 'error' : undefined}
      filterOption={(input: string, option: any) => {
        return (option?.label || '')
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
    >
      {userList.map((user: IUser) => {
        const name = user.name;
        const uuid = user.uuid;
        const profileUrl = userProfileImageMap?.[uuid];
        const roles = getRoles(user);
        return (
          <Option key={uuid} value={uuid} label={name} className='appointment-user-select'>
            <HStack
              width={'100%'}
              alignItems="flex-start"
              paddingY={2}
              paddingX={4}
            >
              <View>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                    width: 48,
                    height: 48,
                  }}
                  userData={{
                    userType: GROUP_MEMBER_TYPE.USER,
                    userId: uuid,
                    name: name,
                    userName: name,
                    imgSrc: profileUrl || '',
                  }}
                  isLetterAvatarShow
                />
              </View>
              <VStack
                alignSelf="stretch"
                flex={1}
                marginLeft={2}
                justifyContent={'flex-start'}
              >
                <Text fontWeight={'bold'} fontSize={16}>
                  {name}
                </Text>
                <Text color={Colors?.Custom?.Gray500}>{roles}</Text>
              </VStack>
              {showViewSchedule && (
                <VStack marginTop={2}>
                  <Pressable
                    onPress={() => {
                      onViewSchedule?.(user);
                    }}
                  >
                    <HStack alignItems={'center'} space={1}>
                      <Feather
                        name="eye"
                        size={14}
                        color={Colors.primary[400]}
                      />
                      <Text color={Colors.primary[400]}>View Schedule</Text>
                    </HStack>
                  </Pressable>
                </VStack>
              )}
            </HStack>
          </Option>
        );
      })}
    </Select>
  );
};
