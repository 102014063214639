import React, {useState} from 'react';
import {Pressable, View} from 'react-native';
import {Text} from 'native-base';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles';
import SendButtonSvg from '../../../../common/Svg/SendButtonSvg';
import LockSvg from '../../../../common/Svg/PersonActionSvgIcons/LockSvg';
import CreateEFaxConversationDrawer from '../ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import MsgReplyDrawer from './MsgReplyDrawer/MsgReplyDrawer';
import {IEFaxMessagingWindowFooter} from './interface';
import {getDateToMomentISOString} from '../../../../../utils/DateUtils';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';

const EFaxMessagingWindowFooter = (props: IEFaxMessagingWindowFooter) => {
  const [state, setState] = useState({
    showEFaxDrawer: false,
    showInternalNoteDrawer: false,
  });

  return (
    <Stack
      direction="row"
      style={{
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 20,
        // justifyContent: 'center'
      }}
    >
      <Stack direction="row">
        <Pressable
          onPress={() => {
            setState((prev) => {
              return {
                ...prev,
                showEFaxDrawer: true,
              };
            });
          }}
        >
          <Stack
            direction="row"
            style={{
              backgroundColor: Colors.Custom.mainPrimaryPurple,
              alignItems: 'center',
              padding: 10,
              borderRadius: 10,
            }}
          >
            <SendButtonSvg customStrokeColor={Colors.Custom.White} />
            <Text
              style={{color: Colors.Custom.White, paddingLeft: 6, fontSize: 16}}
            >
              {'Send Another e-Fax'}
            </Text>
          </Stack>
        </Pressable>
        {/* <Pressable              //This code is required for internal note in eFax in future
          style={{marginLeft: 30}}
          onPress={() => {
            setState((prev) => {
              return {
                ...prev,
                showInternalNoteDrawer: true,
              };
            });
          }}
        >
          <Stack
            direction="row"
            style={{
              backgroundColor: Colors.Custom.ContainerBGColor,
              alignItems: 'center',
              padding: 10,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray200,
            }}
          >
            <LockSvg />
            <Text
              style={{
                color: Colors.Custom.Gray500,
                paddingLeft: 6,
                fontSize: 16,
              }}
            >
              Internal Note
            </Text>
          </Stack>
        </Pressable> */}
      </Stack>
      {state.showEFaxDrawer && (
        <CreateEFaxConversationDrawer
          selectedConversation={props?.selectedConversation}
          visible={state.showEFaxDrawer}
          onMsgSend={(
            msgText: string,
            msgData?: any,
            parentMessage?: IReplyMessageObject | undefined
          ) => {
            const currentTime = getDateToMomentISOString();
            const responseData = {...msgData, currentTime};
            props.onMsgSend(msgText, responseData, parentMessage);
          }}
          onActionPerformed={(actionCode: string, actionData?: any) => {
            setState((prev) => {
              return {
                ...prev,
                showEFaxDrawer: false,
              };
            });
          }}
        />
      )}
      {state.showInternalNoteDrawer && (
        <MsgReplyDrawer
          isDrawerVisible={state.showInternalNoteDrawer}
          onMsgReplyActionPerformed={(actionCode: string) => {
            setState((prev) => {
              return {
                ...prev,
                showInternalNoteDrawer: false,
              };
            });
          }}
          conversationData={props?.selectedConversation}
          onActionMsgFooterPerformed={props?.onActionMsgFooterPerformed}
          messageType="internal"
          isInternalNote={true}
          onMsgSend={(
            msgText: string,
            msgData?: any,
            parentMessage?: IReplyMessageObject | undefined
          ) => {
            const currentTime = getDateToMomentISOString();
            const responseData = {...msgData, currentTime};
            props.onMsgSend(msgText, responseData, parentMessage);
            setState((prev) => {
              return {
                ...prev,
                showInternalNoteDrawer: false,
              };
            });
          }}
          showMessageTypeTab={false}
        />
      )}
    </Stack>
  );
};

export default EFaxMessagingWindowFooter;
