import {Drawer, Select} from 'antd';
import {useMediaQuery, Text, Center, Spinner, useToast} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
} from '../../../../../../constants';
import {IAttachPatientProfileToEFaxDrawer} from './interface';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {Pressable, View} from 'react-native';
import {Colors} from '../../../../../../styles/Colors';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import PatientSearchAndSelect from '../../../../../common/PatientSearch/PatientSearchAndSelect';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  getCareStudioMlovListByCategoryCode,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import * as DocumentQueries from '../../../../../../services/Document/DocumentQueries';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import {IContact} from '../../../../../common/CreateChannelView/interface';
import {getAccountId} from '../../../../../../utils/commonUtils';
import {
  getUpdatedErrorMessageForAttachPatientDrawer,
  uploadToPatientProfile,
} from './CreateEFaxConversationDrawerUtils';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {CREATE_MULTIPLE_CONTACT_DOCUMENT} from '../../../../../../services/Document/DocumentQueries';
import {DOCUMENT_SUBJECT_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {useToast as useCustomToast} from '../../../../../../components/Toast/ToastProvider';
import {DOCUMENT_CATEGORY_CODES} from '../ConversationChannelTabConst';
import OverrideAntdModal from '../../../../ContentManagement/OverrideModal/OverrideAntdModal';

const AttachPatientProfileToEFaxDrawer = (
  props: IAttachPatientProfileToEFaxDrawer
) => {
  const intl = useIntl();
  const toast = useToast();
  const accountId = getAccountId();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const drawerWidth = isSideCarContext
    ? '100%'
    : isIPadMiniScreen || isIPadScreen
    ? '35vw'
    : '30%';
  const [state, setState] = useState({
    selectedPatient: {} as IContact | any,
    selectedDocumentCategory: {} as any,
    contentAttribute: {} as any,
    loading: false,
    errors: {} as any,
    showConfirmationModal: false,
  });
  const mlovData = useContext(CommonDataContext);
  const customToast = useCustomToast();

  const mlovCategoryId = getCareStudioMlovListByCategoryCode(
    MLOV_CATEGORY.DOCUMENT_TYPE
  )?.[0]?.categoryId;

  const [getDocumentCategories] = useLazyQuery(
    DocumentQueries.GET_DOCUMENT_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateEFaxMessage] = useMutation(
    ConversationsQueries.UpdateEFaxMessage
  );

  const [createDocumentQuery] = useMutation(CREATE_MULTIPLE_CONTACT_DOCUMENT, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [deleteDocumentQuery] = useMutation(
    DocumentQueries.DELETE_MULTIPLE_CONTACT_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const documentSubjectTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.DOCUMENT_SUBJECT_TYPE
    ) || [];

  const contactDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CONTACT
  );

  const handleUploadToPatientProfile = async (attachmentIds: string[]) => {
    try {
      const documentIdList =
        props?.conversationMessage?.contentAttributes?.documentIds ||
        (props?.conversationMessage?.contentAttributes?.documentId
          ? [props?.conversationMessage?.contentAttributes?.documentId]
          : []);

      const documentCategoryList = await getDocumentsCategories();
      const eFaxCategoryCode = documentCategoryList?.find(
        (item: any) => item?.code === DOCUMENT_CATEGORY_CODES.E_FAX
      );
      const documentData: any = [];
      if (documentIdList?.length) {
        const deletedDocument = await deleteDocumentQuery({
          variables: {
            documentIds: documentIdList,
          },
        });
        const deletedAttachmentId =
          deletedDocument?.data?.updateDocuments?.returning?.map(
            (item: any) => {
              return item?.documentAttachments?.[0]?.attachment?.id;
            }
          );
        if (
          deletedAttachmentId?.length > 0
        ) {
          deletedAttachmentId?.forEach(
            (item: any) => {
              documentData.push({
                caption: '',
                documentTypeId: eFaxCategoryCode?.id,
                subjectId: state.selectedPatient?.uuid,
                subjectTypeId: contactDocumentSubjectTypeId,
                documentAttachments: {
                  data: [
                    {
                      attachmentId: item,
                    },
                  ],
                },
              });
            }
          );
        }
      } else if (attachmentIds && attachmentIds?.length) {
        const uploadedAttachment = await uploadToPatientProfile(attachmentIds);

        if (eFaxCategoryCode && uploadedAttachment?.length) {
          uploadedAttachment?.forEach((item: any) => {
            documentData.push({
              caption: '',
              documentTypeId: eFaxCategoryCode?.id,
              subjectId: state.selectedPatient?.uuid,
              subjectTypeId: contactDocumentSubjectTypeId,
              documentAttachments: {
                data: [
                  {
                    attachmentId: item?.id,
                  },
                ],
              },
            });
          });
        }
      }
      if (documentData?.length) {
        const response = await createDocumentQuery({
          variables: {
            data: documentData,
          },
        });
        return response;
      } else {
        showToast(
          customToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      closeModal();
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const updatePatientRegarding = async () => {
    const errorData = getUpdatedErrorMessageForAttachPatientDrawer(state);
    if (errorData?.isErrorPresent) {
      setState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
          loading: false,
        };
      });
    } else {
      try {
        setState((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });
        const uploadDocument = await handleUploadToPatientProfile(
          props?.attachmentIds
        );
        const uploadedDocumentIds =
          uploadDocument?.data?.document?.returning?.map((item: any) => {
            return item?.id;
          });
        const updatedContentAttribute = {
          ...props?.conversationMessage?.contentAttributes,
          patientRegarding: state.selectedPatient?.uuid,
          documentIds: uploadedDocumentIds,
        };

        const response = await updateEFaxMessage({
          variables: {
            accountId: accountId,
            messageUuid: props?.messageUuid,
            contentAttribute: updatedContentAttribute,
          },
        });
        props?.onActionPerformed(
          CONVERSATION_ACTION_CODES.PATIENT_REGARDING_UPDATE,
          response?.data?.updateMessages?.returning[0]?.contentAttributes
        );
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        closeModal();
        showToast(
          toast,
          intl.formatMessage({id: 'addedPatientContext'}),
          ToastType.success
        );
      } catch (e) {
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        closeModal();
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    }
  };

  const handleAddToPatientContext = () => {
    const errorData = getUpdatedErrorMessageForAttachPatientDrawer(state);
    if (errorData?.isErrorPresent) {
      setState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
          loading: false,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          showConfirmationModal: true,
        }
      })
    }
  }

  const getDocumentsCategories = async () => {
    try {
      const response = await getDocumentCategories({
        variables: {
          params: {
            categoryId: mlovCategoryId,
          },
        },
      });
      return response?.data?.getDocumentCategories;
    } catch (e) {
      return [];
    }
  };

  const getInitData = async () => {
    setState((prev) => {
      return {
        ...prev,
        selectedPatient: props?.contactData || undefined,
        loading: false,
      };
    });
  };

  useEffect(() => {
    getInitData();
  }, []);

  const closeModal = () => {
    props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const getTitleView = (): JSX.Element => {
    return (
      <Stack direction="row" style={{alignItems: 'center'}}>
        <ModalActionTitle
          title={'addPatientContext'}
          titleColor=""
          titleSize={24}
        />
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
          }}
        >
          <View style={{paddingRight: 16}}>
            <Pressable
              style={{
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                paddingVertical: 8,
                paddingHorizontal: 16,
                borderRadius: 4,
              }}
              onPress={handleAddToPatientContext}
            >
              <Text color={'white'} fontSize={'md'} fontWeight={600}>
                {intl.formatMessage({id: 'add'})}
              </Text>
            </Pressable>
          </View>
          <View
            style={{
              paddingLeft: 12,
              borderLeftWidth: 1,
              borderLeftColor: Colors.Custom.Gray300,
              marginVertical: 5,
            }}
          >
            <Pressable onPress={closeModal}>
              <AntIcon name="close" size={20} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </Stack>
      </Stack>
    );
  };

  const getBodyElement = (): JSX.Element => {
    return (
      <Stack
        direction="column"
        style={{
          paddingHorizontal: 24,
          paddingVertical: 24,
          opacity: state?.loading ? 0.5 : 1,
          backgroundColor: Colors.Custom.White,
        }}
      >
        <View style={{paddingVertical: 16, flex: 1}}>
          <PatientSearchAndSelect
            label={'selectPatient'}
            value={state?.selectedPatient?.name}
            isProspect={false}
            isRequired={true}
            placeholder={intl.formatMessage({id: 'searchPatients'})}
            showOnlyLoggedInPatients={false}
            showError={(state?.errors?.patient && !state.selectedPatient?.id) ? true : false}
            errors={(state?.errors?.patient && !state.selectedPatient?.id) && state?.errors}
            labelColor={Colors.Custom.Gray500}
            onChange={(participant: any) => {
              const contactData = participant?.label?.props?.contactData;
              setState((prev) => {
                return {
                  ...prev,
                  selectedPatient: contactData,
                };
              });
            }}
          />
        </View>
      </Stack>
    );
  };

  const getConfirmationModalElement = () => {
    return (
      <OverrideAntdModal
        isOpen={state.showConfirmationModal}
        onClose={() => {
          setState((prev) => {
            return {
              ...prev,
              showConfirmationModal: false,
            };
          });
        }}
        onConfirm={() => {
          updatePatientRegarding();
        }}
        confirmButtonText="confirm"
        confirmButtonType={BUTTON_TYPE.PRIMARY}
        closeButtonText="cancel"
        closeButtonType={BUTTON_TYPE.SECONDARY}
        textContent={{
          headerText: intl.formatMessage({id: 'addPatientContext'}),
          message: `This action will link the document to the ${state?.selectedPatient?.name} profile under the E-Fax folder`,
        }}
      />
    );
  };

  return (
    <Drawer
      className="custom-drawer-styles"
      visible={props?.visible}
      width={drawerWidth}
      mask={isSideCarContext ? false : true}
      closable
      onClose={() => closeModal()}
      title={getTitleView()}
    >
      {state.loading && (
        <View
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 10,
            backgroundColor: 'coolGray.50:alpha.70',
          }}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {getBodyElement()}
      {state.showConfirmationModal && (getConfirmationModalElement())}
    </Drawer>
  );
};

export default AttachPatientProfileToEFaxDrawer;
