import { LOCALES } from '../locales/locales';

export default {
  [LOCALES.ENGLISH]: {
    appInfoTitle: 'Practice Summary ',
    appInfoTitleSubtitle: 'Your key stats',
    selectDate: 'Date',
    home: 'Home',
    hello: 'Hello ',
    inbox: 'Inbox',
    conversations: 'Conversations',
    conversationFilter: 'Conversation Filter',
    channels: 'Channels',
    integrations: 'Integrations',
    skills: 'Skills',
    responses: 'Responses',
    contacts: 'Contacts',
    visitors: 'Visitors',
    leads: 'Leads &\nContacts',
    prospect: 'Prospect',
    customers: 'Patients',
    lead: 'Leads',
    teamMembers: 'Team Members',
    companies: 'Companies',
    segments: 'Segments',
    tags: 'Tags',
    marketing: 'Marketing',
    forms: 'Forms',
    Forms: 'Forms',
    campaigns: 'Campaigns',
    sales: 'Sales',
    salesPipeline: 'Sales Pipeline',
    productsAndServices: 'Products & Services',
    support: 'Support',
    tickets: 'Tickets',
    knowledgebase: 'Knowledge Base',
    management: 'Management',
    task: 'Task',
    noTask: 'No tasks to show',
    noTaskFound: 'No Tasks Found',
    view: 'View',
    reports: 'Reports',
    calendar: 'Calendar',
    settings: 'Settings',
    name: 'Name',
    email: 'Email',
    mobileNumber: 'Mobile Number',
    selectEmployer: 'Select Employer',
    selectGender: 'Gender identity',
    isWeeklyCheckin: 'Weekly Check In',
    age: 'Age',
    zipCode: 'Zipcode',
    addLead: 'Add Prospect',
    addTeamMember: 'Add Team Member',
    updateLead: 'Update Prospect',
    updateContact: 'Update Contact',
    addCustomer: 'Add Member',
    updateCustomer: 'Update Member',
    submit: 'Submit',
    cancel: 'Cancel',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date Of Birth',
    annualVisitDate: 'Annual Wellness Visit',
    phoneNumber: 'Mobile No.',
    fromNumber: 'From Number',
    internalNote: 'Internal Note',
    contactNote: 'Contact Note',
    updateVisitor: 'Update Visitor',
    role: 'Role',
    phone: 'Phone',
    status: 'Status',
    open: 'Open',
    closed: 'Closed',
    resolved: 'Resolved',
    openChat: 'Open Chat',
    closeChat: 'Close Chat',
    pending: 'Pending',
    snoozed: 'Snoozed',
    muted: 'Muted',
    conversationFilters: 'Conversation Filters',
    loadMoreConversations: 'Load More Conversations',
    loadMoreCallLogs: 'View more call logs',
    loadMoreContacts: 'View more contacts',
    noCallLogs: 'No call logs found',
    accountSettings: 'Account Settings',
    practiceSchedule: 'Practice Availability',
    appointmentTypes: 'Appointment Types',
    schedule: 'Schedule',
    campaign: 'Campaign',
    yourSchedule: 'Your Schedule',
    workbenches: 'Workbenches',
    clinical: 'Clinical',
    emailSubject: 'Email Subject',
    emailTemplate: 'Email Template',
    from: 'From',
    replyTo: 'Reply To',
    sender: 'Sender',
    sms: 'SMS',
    messenger: 'Messenger',
    title: 'Title',
    saveAndDraft: 'Save & Draft',
    sendLive: 'Send Live',
    inboxType: 'Inbox Type',
    actions: 'Actions',
    inboxSettings: 'Inbox Settings',
    agents: 'Agents',
    chooseChannel: 'Choose Channel',
    createInbox: 'Create Inbox',
    newInbox: 'New Inbox',
    noInboxFound: 'No Inbox found',
    noDataFound: 'No data found',
    nameNotFound: 'Name not found',
    noConversations: 'No conversations available',
    noMessages: 'No Messages',
    noConversationSelected: 'Please select a conversation from contacts list',
    noConversationFound: 'No conversation found',
    noChatFound: 'No chat found',
    tagsNotFound: 'No Tags',
    inboxesNotFound: 'No Inboxes',
    teamsNotFound: 'Teams are not configured',
    api: 'api',
    back: 'Back',
    close: 'Close',
    addInbox: 'Add Inbox',
    updateInbox: 'Update Inbox',
    businessAvailability: 'Set your availability',
    users: 'Users',
    businessHours: 'Business Hours',
    preChatForm: 'Pre-chat Form',
    configuration: 'Configuration',
    addResponse: 'Add Template Response',
    shortCode: 'Short Code',
    content: 'Content',
    createdAt: 'Created At',
    inboxes: 'Inboxes',
    addContactRelation: 'Add family member',
    alreadyOnAnotherCall: 'We cannot place another call since you are on an active call',
    contactImportSuccess:
      'Contacts imported successfully! It takes few seconds to reflect, please refresh page after sometime.',
    contactImportError: 'Contacts Import Failed',
    bulkImport: 'Import',
    journeys: 'Journeys',
    journeysOfCare: 'Journeys of Care',
    manageForms: 'Manage Forms',
    all: 'All',
    downloadCSV: 'Download CSV',
    download: 'Download',
    newJourney: 'New Journey',
    assignJourney: 'Assign Journey',
    cohortSize: 'Cohort Size',
    createForm: 'Create Form',
    updateForm: 'Update Form',
    resources: 'Resources',
    formPreview: 'Form Preview',
    resource: 'Resource',
    save: 'Save',
    preview: 'Preview',
    update: 'Update',
    practiceAvailability: 'Practice Availability',
    userAvailability: 'User Availability',
    configureSchedule: 'Configure Schedule',
    outOfOfficeMessage: 'Unavailable message for visitors',
    add: 'Add',
    addScheduleOverride: 'Add Schedule Override',
    edit: 'Edit',
    duration: 'Duration',
    locationType: 'Mode of Appointment',
    bookingSpan: 'Booking Span',
    patientFacing: 'Member Self Schedule',
    rsvpEnabled: 'RSVP Enabled',
    addNew: 'Add New',
    addExisting: 'Import From Existing',
    addAppointmentType: 'Add Appointment Type',
    description: 'Description',
    inviteesCanSchedule: 'Invitees can schedule',
    indefinitelyIntoTheFuture: 'Indefinitely into the future',
    within: 'Within',
    daysIntoTheFuture: 'days into the future',
    dayView: 'Day',
    monthView: 'Month',
    weekView: 'Week',
    today: 'Today',
    next: 'Next',
    unreadMessage: 'Unread Message',
    unreadMessages: 'Unread Messages',
    Inboxes: 'Inboxes',
    Campaign: 'Campaign',
    Conversation: 'Conversation',
    Contact: 'Contact',
    tagAdd: 'Add a Tag',
    editTag: 'Edit Tag',
    addCampaignTag: 'Add tag - Campaign',
    addConversationTag: 'Add tag - Conversation',
    fillFieldError: 'Please fill field',
    tagColor: 'Tag Color',
    automation: 'Automation',
    blank: ' ',
    tagName: 'Tag Name',
    tagDescription: 'Tag Description',
    tagsType: 'Tags Type',
    responsesDescription:
      'Responses are saved reply templates which can be used to quickly send out reply to conversation.',
    editResponse: 'Edit Response - ',
    start: 'Start',
    end: 'End',
    new: 'New',
    appointment: 'Appointment',
    blockOutTime: 'Block Time',
    bookAppointment: 'Schedule Appointment',
    reasonForVisit: 'Reason for visit',
    appointmentType: 'Appointment Type',
    appointmentTypeInfoMsg:
      'Event types that you want to schedule e.g. Routine visit, Follow up visit. You can create these under Settings -> Calendar',
    participants: 'Participants',
    selectAppointmentPatients: 'Patient/prospect',
    appointmentDate: 'Appointment date',
    slots: 'Slots',
    selectSlot: 'Select Slot',
    noSlotsAvailable: 'No slots available',
    notes: 'Notes',
    singleNote: 'Note',
    enterTextHere: 'Enter text here',
    book: 'Schedule',
    blocked: 'Blocked',
    searchPeople: 'Search People',
    selectTeamMember: 'Select Team Member',
    noRelativeContact: 'No Related Contacts',
    noSupportingMembers: 'No supporting members added',
    noSubscription: 'No subscription found',
    searchZipcode: 'Search Zipcode',
    importContacts: 'Import Contacts',
    deleteContactRelation: 'Are you sure you want to delete?',
    delete: 'Delete',
    Delete: 'Delete',
    noConversation: 'No Conversation',
    deleteRelation: 'Delete Relatives',
    birthDay: 'Birthdate',
    browser: 'Browser',
    platform: 'Platform',
    hostname: 'Host Name',
    origin: 'Origin',
    initiatedAt: 'Initiated At',
    addMore: 'Add More',
    searchContacts: 'Search contacts',
    addContact: 'Add Contact',
    lastOnline: 'Last Online',
    assigneeLastSeen: 'Assignee Last Seen',
    lastActivity: 'Last Activity',
    gender: 'Gender',
    tag: 'Tag',
    deviceInfo: 'Device Info',
    trackData: 'Track Data',
    deal: 'Deal',
    conversationDetails: 'Conversation Details',
    ticket: 'Ticket',
    mergeContact: 'Merge Contact',
    convertTo: 'Convert To',
    addRelation: 'Add Relation',
    widgetColor: 'Widget Color',
    color: 'Color',
    addContactTag: 'Add tag - Contact',
    updateBusinessHour: 'Update business hours settings',
    singleAppointment: 'Appointment',
    groupAppointment: 'Group Appointment',
    deleteInbox: 'Delete Inbox',
    duplicateJourneyNameError: 'The journey name you entered already exists. Please enter a different journey name',
    deleteInboxDescription: 'Are you sure you want to delete this inbox?',
    deleteResponse: 'Delete Response',
    deleteResponseDescription: 'Are you sure you want to delete this response?',
    setTag: 'Set Tag',
    patientTask: "Patient's Tasks",
    internalTask: 'Internal Tasks',
    problem: 'Problem',
    noProblemItems: 'No Problems',
    noActiveProblemItems: 'No Active Problems',
    noCamapigns: 'No Campaigns',
    noAllergyItems: 'No Allergies',
    noActiveAllergyItems: 'No Active Allergies',
    noImmunizationList: 'No Immunizations',
    noUpcomingImmunizationList: 'No Immunization History',
    noMedicationList: 'No Medications',
    noActiveMedicationList: 'No Active Medication',
    selectTagsFor: 'Select the tag(s) for this ',
    markAsComplete: 'Mark as Complete',
    convertToLead: 'Convert To Lead',
    confirmTextForLead: 'Are you sure to convert contact to Lead?',
    confirmTextForCustomer: 'Are you sure to convert contact to Member?',
    convertToCustomer: 'Convert To Member',
    relatedContacts: 'Related Contacts',
    previousConversations: 'Previous Conversations',
    chatsAsAProspect: 'Chats as a Prospect',
    allergies: 'Allergies',
    immunizations: 'Immunizations',
    medications: 'Medications',
    problems: 'Problems',
    structure: 'Structure',
    team: 'Team',
    branch: 'Branch',
    related: 'Related',
    activity: 'Activity',
    details: 'Details',
    patientDetails: 'Member Details',
    city: 'City',
    addNote: 'Add Note',
    addContactNote: 'Add Contact Note',
    addDefaultNote: 'Add Default Note',
    dashboard: 'Dashboard',
    date: 'Date',
    removeTag: 'Are you sure you want to remove this tag?',
    removeTagTitle: 'Remove Tag',
    removeMember: 'Are you sure you want to remove this member?',
    removeMemberTitle: 'Remove Member',
    block: 'Block',
    appointmentDetail: 'Appointment Details',
    blockDetails: 'Block Details',
    conversationAction: 'Conversation Action',
    assignedAgent: 'Assigned Agent',
    assignToMe: 'Assign to me',
    assignTo: 'Assign To',
    selectAgent: 'Select agent',
    confirmDeletion: 'Confirm delete?',
    deleteConfirmation: 'Are you sure? This cannot be undone.',
    note: 'Notes View',
    addnote: 'Notes',
    editForm: 'Edit Form',
    riskScore: 'Score',
    enterScoreName: 'Enter score name',
    formula: 'Formula',
    sum: 'Sum',
    states: 'States',
    riskScoreFields: 'Score Fields',
    riskScoreDetails: 'Score Details',
    addScoreState: 'Add Score State',
    to: 'To',
    checked: 'Checked',
    unchecked: 'Unchecked',
    field: 'Field',
    selectRiskScoreFieldMessage:
      'Select a field for which you need to configure score',
    selectField: 'Select a field',

    addFieldInFormToConfigureRiskScoreMessage:
      'Please add fields in form against which scores can be configured',
    addFieldToConfigureRiskScoreMessage: 'Add fields here to configure scores',
    addFields: 'Add Fields',
    yes: 'Yes',
    no: 'No',
    scoreInterpretations: 'Score Interpretations',
    fieldName: 'Field Name',
    myTasks: 'My tasks',
    patientsTasks: "Member's tasks",
    kanBanNoDataMsg: 'No items in this list',
    dueNow: 'Due Now',
    dueDate: 'Due Date',
    completed: 'Completed',
    notCompleted: 'Not Completed',
    apiErrorMsg: 'Something went wrong. Please try again later',
    taskIncompleteMsg: 'Task marked as incomplete',
    taskCompletedMsg: 'Task marked as completed',
    taskStatusUpdatedMsg: 'Task status updated successfully',
    deleteInsurance: 'Delete Insurance',
    deleteInsuranceMessage: 'Are you sure you want to delete entired insurance details ?',
    taskIncompletedMsg: 'Task marked as incomplete',
    taskChangedCategoryMsg: 'Task category has been changed',
    updatingTaskCategoryMsg: 'Changing task category',
    taskChangedDisplayCategoryNameMsg: 'Category name has been changed',
    userAccessUpdatedMsg: 'User Access Updated',
    taskAssigneeChangeMsg: 'Task assignee successfully updated',
    patient: 'Patient',
    privateMessage: 'Internal Message',
    noprivateMessage: 'No Messages',
    noContactNotes: 'No Notes',
    contactNotes: 'Contact Notes',
    errorOnMsgSend: 'Error while sending message',
    errorOnFetchTasks: 'Error while fetching tags',
    onAttachmentSend: 'Attachment sent successfully',
    audioMessage: 'Audio message',
    videoMessage: 'Video message',
    pictureMessage: 'Picture message',
    fileMessage: 'File Attachment',
    pdfMessage: 'PDF Attachment',
    deleteTemplate: 'Delete Template',
    deleteConfirmationDrawerTitle: 'Delete Record',
    deleteCampaign: 'Delete Campaign',
    deleteTemplateConfirmation:
      'Are you sure you want to delete this template?',
    deleteCampaignConfirmation:
      'Are you sure you want to delete this Campaign?',
    addTemplate: 'Add Template',
    editTemplate: 'Edit a Template',
    contentManagement: 'Content Management',
    emailTemplates: 'Email Templates',
    smsTemplates: 'SMS Templates',
    body: 'Body',
    templateName: 'Name',
    contentSuggestions: 'Suggestions',
    suggestContent: 'Suggest Email Content',
    suggestEduContent: 'Suggest Educational Content',
    suggestImageContent: 'Suggest Image Content',
    viewSuggestedContent: 'Suggested Content',
    viewSuggestions: 'View Suggestion(s)',
    templateCategory: 'Category',
    setDefault: 'Set as Default',
    pushNotifications: 'Push Notifications',
    templateBody: 'Body',
    templateSubject: 'Subject',
    searchResults: 'Search Results',
    confirmTextForCreatePatient: 'Are you sure to convert contact to Member?',
    privateNote: 'Internal Message',
    reply: 'Reply',
    number: 'Number',
    completedAt: 'Completed At',
    risky: 'Risky',
    state: 'State',
    paymentState: 'Payment State',
    shipmentState: 'Shipment State',
    total: 'Total',
    action: 'Action',
    newProducts: 'New Product',
    newServices: 'New Service',
    products: 'Products',
    price: 'Price (USD)',
    sellingPrice: 'Offer Price (USD)',
    categories: 'Categories',
    orderAt: 'Order At',
    lineItems: 'Line Items',
    createPatient: 'Create a Member',
    variants: 'Variants',
    properties: 'Properties',
    stock: 'Stock',
    prices: 'Prices',
    slug: 'Slug',
    sku: 'SKU',
    taxons: 'Categories',
    optionTypes: 'Option Types',
    property: 'Property',
    value: 'Value',
    showProperty: 'Show Property',
    productProperties: 'Product Properties',
    addProductProperties: 'Add Product Properties',
    onlyCompleteOrder: 'Only Completed Orders',
    newTaxonomy: 'New Category',
    taxonomies: 'Categories',
    addTaxonomy: 'Add Category',
    mediaLibrary: 'Media Library',
    addMedia: 'Add Media',
    participant: 'Participant',
    taxonomyConfirmation: 'Are you sure?',
    addNewTaxon: 'Add Category',
    selectCategory: 'Parent Category',
    addtaxon: 'Add Category',
    backToJourneys: 'Back to Journeys',
    journeyTitle: 'Journey Name',
    journeyTitleSub: 'Name of the journey',
    journeyDescription: 'Journey Description',
    journeyDescriptionSub: 'Short description of journey (max 300 characters)',
    memberOutcome: 'Member Outcome',
    businessOutcome: 'Business Outcome',
    journeyWillStartOn: 'Allow Journey',
    journeyDurationInfo: 'You can change duration while assigning the journey',
    journeyWillStartOnSub: 'Trigger point of journey',
    allowJourneyInfo: 'This journey will be purchasable from member app',
    updateTaxon: 'Update Category',
    permaLinkL: 'Permalink',
    metaTitle: 'Meta Title',
    metaKeywords: 'Meta Keywords',
    metaDescription: 'Meta Description',
    uploadBanner: 'Upload Banner',
    tasks: 'Tasks',
    members: 'Members',
    patients: 'Patients',
    supportingMembers: 'Supporting Members',
    patientGroups: 'Member Groups',
    patientChats: 'Member Chats',
    payments: 'Payments',
    designer: 'Admin Panel',
    services: 'Services',
    campaignManager: 'Campaign Manager',
    tagManagement: 'Tags Management',
    tagManagementSubtitle: 'Setup tags',
    messagingTitle: 'Messages',
    messagingSubtitle: 'Manage your conversations',
    communication: 'Communication',
    communicationSubtitle: 'Setup communication and CMS',
    createInboxTitle: 'Create Inbox',
    createInboxSubtitle: 'Setup web chat widget',
    scheduling: 'Scheduling',
    careTeam: 'Primary Care Team',
    careTeamRoles: 'Care Team Roles',
    careJourneys: 'Care Journeys',
    journeyCareTeamDescription:
      'Select the required care team for this care journey',
    journeyCareTeamRoleSuggestion:
      'We have selected suggested roles for this journey based on the journey design. Please modify above list as needed.',
    journeyDuration: 'Journey Duration',
    journeyStartDate: 'Journey Start Date',
    vitalsTracking: 'Vitals Tracking',
    labTestTracking: 'Lab Analyte Tracking',
    journeyDurationDescription: 'Duration of care journey',

    media: 'Media',
    text: 'Text',
    blockTimeDrawerTitle: 'Block Time',
    editBlockTime: 'Edit Block Time',
    blockTimeSelectLocationInfo:
      'Select locations for which you want to block time.',
    blockTimeSelectedTimezoneInfo:
      'All times below will be blocked based on the selected time zone.',
    selectBlockStartAndEndTime: 'Select start and end time',
    blockTimeSelectLocations: 'Select Locations',
    addMoreBlockTime: 'Add more block time',
    patientEducation: 'Member Education',
    search: 'Search',
    orderLineItems: 'Order Line Items',
    quantity: 'Quantity',
    totalPrice: 'Total Price',
    address1: 'Address1',
    address2: 'Address2',
    company: 'Company',
    dateTime: 'Date/Time',
    time: 'Time',
    amount: 'Amount',
    paymentMethod: 'Payment Method',
    transactionId: 'Transaction Id',
    sort: 'Sort',
    orderSummary: 'Order Summary',
    paymentDetail: 'Payment Details',
    customerDetail: 'Member Detail',
    filter: 'Filter',
    filters: 'Filters',
    filterByTag: 'Filter by tag',
    servicesManagement: 'Services Management',
    servicesManagementSubtitle: 'Manage products, services, and categories',
    memberManagement: 'Patients & Customers',
    memberManagementSubtitle: 'Manage your patients and prospects',
    noTags: 'No Tags',
    filterByInbox: 'Filter By Inbox',
    convertToCustomers: 'Convert to Member',
    addRelative: 'Add Relative',
    send: 'Send',
    filterByDate: 'Filter By Date',
    completedOrder: 'Completed Order',
    confirm: 'Confirm',
    Confirm: 'Confirm',
    inboxVisitorCheckBoxTitle:
      'Visitors should provide their name and email address before starting the chat',
    inboxScripSuccessMessage:
      'You have successfully finished creating a website channel. Copy the code shown below and paste it on your website. Next time a customer uses the live chat, the conversation will automatically appear on your inbox.',
    preChatMessage: 'Pre Chat Message',
    assignNewJourney: 'Assign a new journey',
    continue: 'Continue',
    selectCareJourney: 'Select a care journey',
    createArticle: 'Create Article',
    updateArticle: 'Update Article',
    copyUrl: 'Copy URL',
    deleteMediaConfirmation: 'Are you sure you want to delete this media?',
    editInboxTitle: 'Edit Inbox',
    productDetail: 'Product Detail',
    productDetailSubtitle: 'You can edit product',
    digital: 'Digital',
    options: 'Options',
    assign: 'Assign',
    customer: 'Customer',
    purchaser: 'Purchaser',
    latestAmount: 'Latest Amount Due',
    upcomingAmount: 'Upcoming Amount Due',
    subscriptionName: 'Subscription Name',
    careJourney: 'Care Journey',
    productDetailFormError: 'Error while saving product.Please try again.',
    productDetailFormSuccess: 'Product saved successfully',
    newCareJourney: 'New Care Journey',
    newJourneys: 'New Care Journey',
    selectCareTeam: 'Select Care Team',
    journeySpan: 'Journey Span',
    schedulingSubtitle:
      'Setup your practice availability and appointment types',
    userManagementTitle: 'Team Management',
    taskManagementTitle: 'Task Management',
    taskManagementTitleSubTitle: 'Manage your task',
    userSettingsTitle: 'User Access',
    userSettingsSubTitle: 'Manage User Access',
    userManagementTitleSubtitle: 'Manage your team and account',
    titleLabelId: 'Workflows',
    subtitleLabelId: 'Build your own intelligent workflows',
    updateTemplate: 'Update Template',
    createTemplate: 'Create Template',
    createArticleSubtitle: 'Here you can create a new Educational Article',
    updateArticleSubtitle:
      'Here you can update an existing Educational Article',
    shortSummary: 'Short Summary',
    readTime: 'Read Time',
    submitting: 'Submitting',
    manageCalendar: 'Manage your schedule',
    manageTasks: 'Manage your tasks',
    automagicTitle: 'Workflows',
    automagicTitleSubtitle: 'Manage your intelligent workflows here',
    dummyTaskCompleteMsg:
      'Doing this for an actual task will complete the task and show it in the completed section',
    lastActivityAt: 'Last Activity At',
    messages: 'Messages',
    message: 'Message',
    addStock: 'Add Stock',
    addPrices: 'Add Prices',
    stockLocation: 'Stock Location',
    variant: 'Variant',
    stockLocationInfo: 'Stock Location Info',
    productImages: 'Product Images',
    pendingTasks: 'Pending',
    mime: 'Mime',
    lastUpdated: 'Last Updated',
    category: 'Category',
    subject: 'Subject',
    default: 'Default',
    widget: 'Widget',
    size: 'Size',
    dailyTasks: 'Tasks',
    manageDailyTasks: 'Manage your tasks',
    active: 'Active',
    toDay: 'Today',
    yesterday: 'Yesterday',
    lastWeek: 'Last week',
    lastMonth: 'Last month',
    relationWithMembers: 'Relation with members',
    help: 'Help',
    formsIntro: 'Forms Intro',
    autoMagicIntro: 'Automation Intro',
    termsAndCondition: 'Terms & Condition',
    privacyPolicy: 'Privacy Policy',
    iAgreeTo: 'I agree to',
    formsDescription: 'Manage your forms here',
    noVitalsData: 'No Vitals Data',
    noVisitHistory: 'No Visit History',
    noSurgicalHistory: 'No Surgical History',
    upDateNote: 'Update Note',
    chooseANote: 'Choose a note',
    recent: 'Recent',
    noRecentItems: 'No recent items',
    reason: 'Reason',
    noSocialHistory: 'No Social History',
    socialHistory: 'Social History',
    visitHistory: 'Visit History',
    surgicalHistory: 'Surgical History',
    vitals: 'Vitals',
    other: 'Other',
    allConversations: 'All Conversations',
    mentions: '@ Mentions',
    selectedSurveyForm: 'Selected survey form will be sent after 24 hours',
    selectUser: 'Team member',
    selectUserLabelInfo:
      'List of team members whose calendar you have access to. If you see someone missing in this list, please get access to their calendars in Settings → Calendar',
    dashboardCalendarRefreshButtonInfo: 'Refresh calendar',
    selectTimezone: 'Select Timezone',
    selectLocation: 'Select Location',
    contentManagementSubtitle: 'Manage all your content right from here',
    defaultAlertDialogMessage: 'Are you sure, you want to continue?',
    defaultAlertDialogHeader: 'Alert',
    deleteForm: 'Delete Form',
    deleteFormAlertMessage: 'Are you sure, you want to delete this form?',
    deleteAvailability: 'Delete availability',
    deleteAvailabilityAlertMessage:
      'Are you sure, you want to delete this availability?',
    deleteCareJourneyProduct: 'Delete Care Journey',
    deleteProduct: 'Delete Product',
    deleteCareJourneyProductAlertMessage:
      'Are you sure, you want to delete this care journey?',
    deleteProductAlertMessage: 'Are you sure, you want to delete this Product?',
    formDeletedMessage: 'Form deleted',
    deleteAppointmentType: 'Delete Appointment Type',
    deleteAppointmentTypeAlertMessage:
      'Are you sure, you want to delete this appointment type?',
    appointmentTypeDeletedMessage: 'Appointment type deleted',
    login: 'Login',
    labResults: 'Lab Results',
    activeProblems: 'Active Problems',
    activeMedications: 'Active Medications',
    activeAllergies: 'Active Allergies',
    activeCampaigns: 'Active Campaigns',
    noLabResults: 'No Lab Results',
    Patients: 'Convert To Member',
    filterByType: 'Filter By Type',
    filterByStatus: 'Filter By Status',
    filterByDueDate: 'Filter By Due Date',
    filterByPriority: 'Filter By Priority',
    sortBy: 'Sort By',
    openInEHR: 'Open in EHR',
    timeline: 'Timeline',
    startConversation: 'Start Conversation',
    startInternalChat: 'Start Internal Chat',
    startNewChat: 'Start New Chat',
    startGroupChat: 'Start Group Chat',
    markTaskImportant: 'Mark as important',
    addTask: 'Add Task',
    addTaskPool: 'Add Task Pool',
    addNewTask: 'Add New Task',
    viewTask: 'View Tasks',
    taskAddedMsg: 'Task added successfully',
    taskUpdatedMsg: 'Task updated successfully',
    taskDeletedMsg: 'Task deleted successfully',
    providerRequestSend: 'Provider Request Form Sent Successfully',
    taskSendMsg: 'Task sent to member successfully',
    missed: 'Missed',
    important: 'Important',
    userTaskAccessMsg:'User task access updated successfully.',
    noPatientFound: 'No Member Found',
    createInEhr: 'Create in EHR',
    location: 'Location',
    frequency: 'Frequency',
    assignee: 'Assignee',
    Assignee: 'Assignee',
    assignedBy: 'Assigned By',
    hasWorkflow: 'Has workflow',
    carejourney: 'Care Journeys',
    filterByLocation: 'Filter By Location',
    userRole: 'User Role',
    service: 'Service',
    appointmentService: 'Appointment Service',
    startDate: 'Start Date',
    selectUserForRole: 'Select users for following roles',
    yourTodaysTasks: "Your today's tasks",
    checkInPatient: 'Check in member',
    practiceLocation: 'Practice Location',
    preferredLocation: 'Preferred Location',
    practiceLocations: 'Practice Locations',
    roles: 'Roles',
    editUser: 'Edit User',
    attachments: 'Attachments',
    sendAttachments: 'Send Attachments',
    sendEmail: 'Send Email',
    sendSms: 'Send SMS',
    allEmail: 'All Email',
    noClaimsData: 'No Claims Data',
    claims: 'Claims',
    doctor: 'Doctor',
    noPatientNotesMsg: 'It seems like there are no notes for this member',
    noFilteredNotesMsg: 'There are no documented notes for applied filters',
    allDay: 'All-Day',
    sendEmailTo: 'Send invite email to participants',
    startMeet: 'Start Meet',
    startMeeting: 'Start Meeting',
    topic: 'Topic',
    addUsers: 'Add Users',
    sendFormLink: 'Send Form Link',
    addFormLink: 'Add Form Link',
    formList: 'Form List',
    sendPatientInvite: 'Send Invitation For Mobile App',
    reschedule: 'Reschedule',
    cancelAppointment: 'Cancel Appointment',
    unblock: 'Unblock',
    rescheduleAppointment: 'Reschedule Appointment',
    newGroupChat: 'New Chat',
    newMessage: 'New Message',
    newInternalChat: 'New Internal Chat',
    type: 'Type',
    user: 'Users',
    internalUsers: 'Internal users',
    addPatient: 'Add Member',
    searchPatients: 'Search Patients',
    startChat: 'Start Chat',
    sendMessage: 'Send Message',
    updateChat: 'Update Chat',
    channelPatient: 'Member',
    channelPatientOrUser: 'Patient or Staff',
    channelPatientOrMember: 'Member or Staff',
    channelUser: 'User',
    toMember: 'To Member',
    familyMember: 'Family Member',
    clearAll: 'Clear all',
    cancelAppointmentConfirmationMsg:
      'Are you sure, you want to cancel this appointment?',
    mine: 'Mine',
    unassigned: 'Unassigned',
    messageType: 'Message type',
    membersFilter: 'Member Filter',
    member: 'Members',
    groups: 'Groups',
    noActivity:
      'Looks like there is no data available or synced yet from EHR and network. Please check back again later.',
    noContactActivity: 'No activities',
    joinMeeting: 'Join Appointment',
    Join: 'Join',
    joinAnyway: 'Join Anyway',
    joinFutureAppointment: 'Join Future Meeting',
    joinPastAppointment: 'Join Past Meeting',
    joinFutureAppointmentMsg:
      'This meeting is in future. Are you sure, you want to join the meeting now?',
    joinPastAppointmentMsg:
      'This meeting is in past. Are you sure, you want to join the meeting now?',
    groupCreatedMsg: 'Chat created successfully',
    groupUpdatedMsg: 'Chat updated successfully',
    appointmentScheduledHeader: 'Your appointment has been scheduled',
    appointmentScheduledRSVPHeader: 'Your appointment request has been sent',
    thankYou: 'Thank you',
    submittedForms: 'Submitted Forms',
    sendArticleLink: 'Send Article Link',
    addArticleLink: 'Add Article Link',
    markAllAsRead: 'Mark all as Read',
    notifications: 'Notifications',
    noNotifications: 'No Notifications',
    notification: 'Notification',
    noAppointments: 'No appointments to show',
    formResponses: 'Form Responses',
    formResponse: 'Form Response',
    campaignManagement: 'Campaign Management',
    createCampaign: 'Create Campaign',
    createGroup: 'Create Group',
    newCampaign: 'New Campaign',
    updateCampaign: 'Update Campaign',
    patientPortal: 'Member Portal',
    appointmentWidget: 'Appointment Widget',
    signUpWidget: 'Sign Up Widget',
    webWidgets: 'Web Widgets',
    integrationConfig: 'Integrations',
    addPracticeLocation: 'Add Practice Location',
    editPracticeLocation: 'Edit Practice Location',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    cms: 'Content',
    chatWithPatient: 'Chat With Member',
    chatWithInternalUsers: 'Chat With Internal Users',
    existingGroups: 'Existing Groups',
    campaignType: 'Campaign Type',
    campaignName: 'Campaign Name',
    reminders: 'reminders',
    campaignReminders: 'Campaign Reminders',
    campaignNamePlaceholder: 'Enter Campaign Name',
    noOfReminders: 'No. of Reminders',
    intervalTime: 'Interval Time',
    timeZone: 'Time zone',
    campaignBasicInfo: 'Campaign Basic Info',
    campaignPreview: 'Campaign Preview',
    templateInfo: 'Campaign Template Info',
    selectOrCreateEmailTemplate: 'Select or Create Email Template',
    selectOrCreateSmsTemplate: 'Select or Create SMS Template',
    campaignTime: 'Campaign Time',
    immediate: 'Immediate',
    scheduleOnDate: 'Schedule on date',
    days: 'Days',
    times: 'Times',
    setReminder: 'Send Reminder',
    patientAge: 'Member/Prospect Age',
    fromYears: 'From Years',
    toYears: 'To Years',
    years: 'Years',
    ageRange: 'to',
    pasteInCode: 'Paste In Code',
    uploadZipFolder: 'Import Zip File',
    createUsingUIDesigner: 'Create Using UI Designer',
    addImages: 'Add Images',
    addVariables: 'Add Variables',
    replaceImages: 'Replace Images',
    sendForms: 'Send Forms',
    createNewTemplate: 'Create New Template',
    useExistingTemplate: `Use Exisiting Template`,
    changeFromDraft: 'Change from Draft to Active',
    LT: 'Less than',
    GT: 'Greater than',
    BTW: 'Between',
    selectAllPatientProspects: 'Select all member and prospects',
    targetGender: 'Target Gender',
    campaignLocation: 'Campaign Location',
    campaignAge: 'Campaign Age',
    campaignSegment: 'Campaign Segment',
    patientProspectSegment: 'Member / Lead Segment',
    locationZipcode: 'Location (Zipcode)',
    ageGroup: 'age group',
    patientTag: 'Member Tag',
    campaignDetails: 'Campaign Details',
    channel: 'Channel',
    segmentDetails: 'Segment Details',
    template: 'Template',
    required: 'Required',
    atleastOneFilterReq:
      'atleast one of filter required or please check select all member',
    audience: 'Audience',
    summary: 'Summary',
    customSelectedSegment: 'Custom Selected Segment',
    employers: 'Employers',
    newEmployer: 'New Employer',
    editEmployer: 'Edit Employer',
    manageDeal: 'Manage Deal',
    activeDeals: 'Active Deals',
    ongoingDeals: 'Ongoing Deals',
    inactiveDeals: 'Inactive Deals',
    quickCreate: 'Quick Create',
    create: 'Create',
    createEmployer: 'Create Employer',
    updateEmployerTitle: 'Update Employer',
    companyName: 'Company Name',
    contactName: 'Contact Name',
    contactEmail: 'Contact Email Address',
    contactPhone: 'Contact Phone Number',
    selectDealStatus: 'Select Deal Status',
    selectAccountManager: 'Select Account Manager',
    questionnaire: 'Questionnaire',
    genderAtBirth: 'Sex at birth',
    address: 'Address',
    virtualCallCenter: 'Phone Management',
    appointmentNotFound: 'No Appointment',
    futureAppointment: 'Upcoming Appointments',
    theCompany: 'The Company',
    bulkActions: 'Bulk Actions',
    importMembers: 'Import Members',
    membershipType: 'Membership Type',
    carrier: 'Carrier',
    dependent: 'Dependent',
    runAutomation: 'Run Automation',
    activeCampaign: 'Active Campaign',
    campaignStatus: 'Campaign Status',
    consentStatus: 'Consent Status',
    contact: 'Contact',
    bulkImportText: 'Bulk Import',
    addManually: 'Add Manually',
    connectToHint: 'Connect To Hint',
    requestConsent: 'Request Consent',
    createOpportunity: 'Create Opportunity',
    manageDealText:
      'Manage the Deal Status and the Account Manager for employer ',
    accountDashboardSavings:
      'The data here is aggregated across all employers in the account currently and does not show self-sponsored or one-off members',
    employerAdded: 'Employer Added',
    createEmployerTitle: 'Create Employer',
    industry: 'Industry',
    selectSIC: 'Select SIC Code',
    companyNamePlaceHolder: 'Enter company name',
    companyEmailLabel: 'Company Email Address (optional)',
    companyEmailPlaceHolder: 'email@company.com',
    companyPhoneLabel: 'Company Phone Number(optional)',
    companyPhonePlaceHolder: '+1 (555) 000-000',
    createNewAccountManager: 'Create New Account Manager',
    noShow: 'No Show',
    noShowNoShowConfirmationMessage:
      'Are you sure, you want to set the status to No Show?',
    deleteEmployerConfirmation: 'Are you sure, you want to delete employer?',
    deleteEmployer: 'Delete Employer',
    deleteEmployeeConfirmation: 'Are you sure, you want to delete employees?',
    deleteEmployee: 'Delete Employee',
    deleteMembersConfirmation: 'Are you sure, you want to delete member?',
    deleteMember: 'Delete Member',
    activeNumbers: 'Active Numbers',
    purchaseNewNumber: 'Purchase New Number',
    NewIVRFlow: 'New IVR Flow',
    more: 'More',
    buyNow: 'Buy Now',
    buyVirtualNumber: 'Buy Virtual Number',
    setupEmailInbox: 'Setup Email Inbox',
    setupSmsInbox: 'Setup Sms Inbox',
    deleteNumber: 'Delete Number',
    changeNumber: 'Change Member',
    assignNumber: 'Assign Number',
    problemName: 'Problem Name',
    sinceDate: 'Since Date',
    problemStatus: 'Problem Status',
    noProblemsAdded: 'No problems were added.',
    immunizationName: 'Immunization Name',
    immunizationStatus: 'Immunization Status',
    dateOccurred: 'Date Administered',
    noImmunizationsAdded: 'No immunizations were added.',
    language: 'Language',
    possibleMatches: 'Possible Matches',
    patientName: 'Member Name',
    addTaskAddUnderCategory: 'Add Under Category',
    addTaskAddUnderCategoryInfoMsg:
      'Category in which the task should be visible',
    addTaskTaskPoolLabel: 'Task Pool',
    addTaskTaskPoolPlaceholder: 'Select Task Pool',
    addTaskTaskPoolInfoMsg:
      'Group of tasks on which multiple team members can collaborate e.g. Patient onboarding tasks',
    memberContextInfoMsg:
      'Please use this field in case this task is being added in context of a member. Note that this is an internal task',
    memberFieldContext: 'Add member context for this task',
    taskPoolName: 'Task Pool Name',
    taskPoolUsers: 'Select Users',
    Name: 'Name',
    Users: 'Users',
    TaskPool: 'Task Pool',
    doLater: 'Do Later',
    doTomorrow: 'Do Tomorrow',
    doToday: 'Do Today',
    allAssigned: 'All Assigned',
    inviteUser: 'Invite User',
    noCareTeam: 'No Care Team',
    submitForm: 'Submit Form',
    userScheduleManagementTitle: 'Manage Calendar',
    scheduleAccessUser: 'Configure which users can manage multiple calendars',
    editScheduleAccessUser: 'Edit User Schedule Access',
    editConfigureAccess: 'Configure access to tasks',
    canScheduleFor: 'Can schedule for',
    relationType: 'Relation Type',
    birthDate: 'Date of birth',
    marriageAnniversaryDate: 'Marriage Anniversary Date',
    noAllergiesAdded: 'No allergies were added.',
    allergyName: 'Allergy Name',
    allergyStatus: 'Allergy Status',
    severity: 'Severity',
    practitioner: 'Practitioner',
    noFamilyHXAdded: 'No family history were added.',
    relationship: 'Relationship',
    conditions: 'Conditions',
    overview: 'Overview',
    lastInteraction: 'Last Interaction',
    accountManager: 'Account Manager',
    lastBilling: 'Last Billing',
    consent: 'Consent',
    doseQuantity: 'Dose Quantity',
    doseQuantityUnit: 'Dose Quantity Unit',
    bulkImportSubtitleEmployee:
      'Import the Employer Members in bulk by uploading a spreadsheet.',
    bulkImportSubtitleProspect:
      'Import the Prospects in bulk by uploading a spreadsheet.',
    getSpreadSheet: 'Get spreadsheet template',
    requestConsentMsg:
      ' members imported successfully. Would you like to request consent now or later?',
    automationSubtitle: 'Create automation flows for member',
    createAutomation: 'Create Automation',
    employees: 'Employees',
    employeeGroup: 'Employee Group',
    smsInbox: 'SMS Inbox',
    emailInbox: 'Email Inbox',
    inboxName: 'Inbox Name',
    smsInboxCreateSuccess: 'SMS Inbox created successfully',
    smsInboxUpdatedSuccess: 'SMS Inbox updated successfully',
    noVirtualNumberAvailable: 'No virtual number available',
    analytics: 'Analytics',
    kpi: 'KPI',
    opportunities: 'Opportunities',
    showCareTeam: `Show Care Team`,
    DueDate: 'Due Date',
    Priority: 'Priority',
    applyFilter: 'Apply Filter',
    apply: 'Apply',
    clear: 'Clear',
    fromDate: 'From Date',
    toDate: 'To Date',
    selectChat: 'Select Chat',
    selectInbox: 'Select Inbox',
    noProceduresAdded: 'No procedures were added',
    noMedicationsAdded: 'No medications were added',
    medicationName: 'Medication Name',
    effectiveDate: 'Started On',
    issueDate: 'Issue Date',
    expirationDate: 'Expiration Date',
    groupID: 'Group ID',
    memberID: 'Member ID',
    country: 'Country',
    zipcode: 'Zip Code',
    address_1: 'Address 1',
    policyHolderID: 'Policy Holder ID',
    copayAmountType: 'Copay Type',
    copayAmount: 'Copay Amount',
    patientRelation: 'Patient’s relationship to policyholder',
    coInsurance: 'Coinsurance',
    suffix: 'Suffix',
    insuranceCarrierName: 'Insurance Carrier Name',
    addAdditionalDetails: 'Add Additional Details',
    dob: 'Date of Birth',
    addCopayType: 'Add Copay Type',
    deductible: 'Deductible',
    performedDate: 'Performed Date',
    procedureName: 'Procedure Name',
    noAppointment: 'No Appointments',
    noSlotsToShow: 'No slots to show',
    reset: 'Reset',
    Reset: 'Reset',
    ConfigureTaskPool:
      ' Configure which user can see task for other team members',
    editConfigureTaskPool: 'Edit Task Pool Configuration',
    Status: 'Status',
    showSuggestion: 'Show Suggestion',
    taskPoolAssignee: 'Task Pool Assignee',
    taskPoolDefaultAssignee: 'Default Assignee',
    newChat: 'New Chat',
    newSMS: 'New SMS',
    newConversations: 'New Conversation',
    newEmail: 'New Email',
    audiencePreview: 'Audience Preview',
    createAudienceGroup: 'Create Audience Group',
    updateAudienceGroup: 'Update Audience Group',
    selectPreviousGroup: 'Select Previous Group',
    viewMedlineSuggestions: 'Member Education Suggestions',
    selectGroup: 'Select Group',
    segmentName: 'Create Segment Name',
    familyHistory: 'Family History',
    noFamilyHistory: 'No Family History',
    conversationCreatedSuccessfully: 'Conversation created successfully',
    existingConversation: 'Existing Conversation',
    smsInboxExist: 'Another SMS Inbox Exists',
    continueWithTheConversation:
      'Conversation already exist with selected inbox. Do you want to continue with the conversation?',
    continueWithSmsConversation:
      'Sms inbox already exist with this number. Do you want to continue with the conversation?',
    emailInboxCreateSuccess: 'Email Inbox created successfully',
    emailInboxUpdatedSuccess: 'Email Inbox updated successfully',
    imapAddress: 'Imap address',
    imapPort: 'Imap port',
    imapEmail: 'Imap email',
    imapPassword: 'Imap password',
    smtpAddress: 'SMTP address',
    smtpPort: 'SMTP port',
    smtpEmail: 'SMTP email',
    smtpPassword: 'SMTP password',
    customersFacingEmail: 'Email',
    sinceYear: 'Since Year',
    phoneNumberAndName: 'Phone Number and Name',
    assignedFor: 'Assigned For',
    flowType: 'Flow Type',
    lastActivityOn: 'Last Activity On',
    callLog: 'Call Log',
    newNumber: 'New Number',
    setupNumber: 'Setup Number',
    formName: 'Form Name',
    selectFormCategory: 'Form Categories',
    selectForm: 'Select Form',
    importFrom: 'Import From',
    formCategory: 'Category',
    isDefaultForm: 'Default Form',
    selectTemplate: 'Select Category',
    sexAtBirth: 'Sex at Birth',
    freeTextPlanNameMsg: "Free text is not allowed.Please select 'Plan Name' from dropdown.",
    freeTextPlanNameMsgSecondary: "Free text is not allowed.Please select 'Plan Name' from dropdown for secondary insurance.",
    freeTextPlanNameMsgTertairy: "Free text is not allowed.Please select 'Plan Name' from dropdown for tertiary insurance.",
    freeTextInsuranceCarrierNameMsg: "Free text is not allowed.Please select 'Insurance Carrier Name' from dropdown.",
    freeTextInsuranceCarrierNameMsgSecondary: "Free text is not allowed.Please select 'Insurance Carrier Name' from dropdown for secondary insurance.",
    freeTextInsuranceCarrierNameMsgTertiary: "Free text is not allowed.Please select 'Insurance Carrier Name' from dropdown for tertiary insurance.",
    insuranceAddSuccessMsg: 'Insurance added successfully',
    insuranceUpdateSuccessMsg: 'Insurance updated successfully',
    createWorkflow: 'Create Automation',
    noFormCategory: 'No form categories available',
    changeMobileNumber: 'Change Mobile Number',
    sendOtp: 'Send OTP',
    setIvrFlow: 'Set Phone Tree',
    resetForwardNumber: 'Reset Forward Number',
    createNewIVR: 'Create New Phone Tree',
    sexualOrientation: 'Sexual Orientation',
    race: 'Race',
    ethnicity: 'Ethnicity',
    createdOn: 'Created On',
    taskDetail: 'Task Details',
    clearFilter: 'Clear Filter',
    MarkAsCompleted: 'Mark As Completed',
    NoDataSelect: 'Type something to start searching',
    select_tags: 'Select Tags',
    tags_not_available: 'tags not available',
    add_tag: 'Add Tag',
    source: 'Source',
    print: 'Print',
    printPreview: 'Print Preview',
    selectOneItemError: 'Please select at least one item',
    openInHint: 'Open in Hint',
    AddTask: 'Add Task',
    'Add Task': 'Add Task',
    NoData: 'Type to search',
    notFound: 'City not found',
    confirmDeactivate: 'Confirm Set Inactive?',
    deactivate: 'Deactivate',
    deactivateConfirmation: 'Add information',
    confirmActivate: 'Confirm Activate?',
    activate: 'Activate',
    activationConfirmation: 'Add information',
    onDeleteDefaultFormMessage:
      'There is no default form in this category, hence you cannot delete this form',
    careJourneyDetail: 'Care Journey Detail',
    newPatientAddedMsg: 'Member added successfully',
    newContactAddedMsg: 'Contact added successfully',
    deleteMessageConfirmation: 'Are you sure you want to delete this message?',
    addTeam: 'Add Team',
    selectTwoUser: 'Select at least two user',
    teamCreated: 'Team created successfully',
    errorCreatingTeam: 'Error while creating team',
    createNewForm: 'Create New Form',
    basicInfo: 'Basic Info',
    sendLink: 'Send Link',
    addLink: 'Add Link',
    requestResponse: 'Request response for this appointment',
    deactivateMessageTxt: 'This contact is deactivated, you can’t reply on it',
    seenInfo: 'Message Info',
    CreateNewForm: 'Create New Form',
    membership: 'Membership',
    memberships: 'Memberships',
    billing: 'Billing',
    logs: 'Logs',
    userInfo: 'User Unique Id',
    auditUrl: 'URL accessed',
    clientIp: 'Client Ip',
    userAgent: 'User Agent',
    operation: 'Operation Performed',
    newMembership: 'New Membership',
    memberShipDetail: 'Membership Detail',
    pricing: 'Pricing',
    oneTimeRegistrationFee: 'One time registration fee (USD)',
    monthlyCharges: 'Monthly Charges (USD)',
    quarterlyCharges: 'Quarterly Charges (USD)',
    annuallyCharges: 'Annually Charges (USD)',
    oneTimeCharges: 'One Time Charges (USD)',
    quarterlyPrice: 'Quarterly',
    monthlyPrice: 'Monthly',
    oneTimePrice: 'One Time',
    tieredPrice: 'Tiered Price',
    forEach: 'For Each',
    firstUnit: 'First Unit',
    lastUnit: 'Last Unit',
    perUnit: 'Per Unit',
    flatFee: 'Flat Fee',
    annuallyPrice: 'Annually',
    usageType: 'Usage Type',
    accountLimit: 'Plan Limits',
    usageCount: 'Usage Count',
    usageFrom: 'Usage From',
    usageTo: 'Usage To',
    paymentSetup: 'Payment Setup',
    publishableKey: 'Publishable Key',
    secretKey: 'Secret Key',
    saveSettings: 'Save Settings',
    testMode: 'Test Mode',
    paymentDetailUpdated: 'Payment detail saved successfully',
    errorPaymentUpdated: 'Error on saving payment detail',
    cancelSubscription: 'Cancel Subscription',
    appointmentInstruction: 'Instructions for member',
    enterPatientInstructionsHere: 'Enter instructions for member here',
    noMembers: 'No Members in this group',
    activeAutomations: 'Active automations',
    createNewWidget: 'Create New Widget',
    arrangeCardDetails: 'Arrange Card Details',
    arrangeCardDescription: 'Make changes in layout details as per your need',
    updateSettings: 'Update Settings',
    patientDocumentListViewTitle: 'Documents',
    patientDrive: 'Documents',
    patientDocumentListViewUploadViewTitle: 'Upload Documents',
    patientDocumentListViewUploadViewUploadBtn: 'Upload',
    patientDocumentListViewSelectDocumentType: 'Select document type',
    documentUploadedSuccessMsg: 'Document uploaded successfully',
    patientDocumentListViewSelectDocumentSubCategory: 'Select document sub category',
    patientDocumentListViewAddCaptionTitle: 'Caption',
    patientDocumentListViewAddNotesTitle: 'Notes',
    patientDocumentsNoDocumentsFound: 'No Documents',
    patientTaskUploadDocumentSupportAllFiles:
    'Supports PDF, PNG, JPG, DOC, DOCX. Max file size 5 MB',
    patientDocumentsUploadSupportAllFiles:
      'Supports PDF, PNG, JPG, JPEG, DOC, DOCX. Max file size 20 MB',
    patientDocumentsUploadSupportImagingReportFiles:
      'Supports PDF, PNG, JPG, JPEG, Max file size 20 MB',
    patientDocumentsUploadSupportImageFiles:
      'Supports PNG, JPG. Max file size 20 MB',
    profileUploadSupportedImageFiles:
      'Supports PNG, JPG.',
    legalSex: 'Legal Sex',
    done: 'Done',
    canPatientChangeLocationTypeOfAppointment: 'Allow Virtual',
    dependents: 'Dependents',
    retail: 'Retail',
    oneOff: 'One-off',
    viewAnalytics: 'View Analytics',
    viewKpi: 'View KPI',
    teamUpdated: 'Team updated successfully',
    journeyName: 'Journey Name',
    pauseJourney: 'Pause Journey',
    pause: 'Pause',
    restartJourney: 'Restart Journey',
    stopJourney: 'Stop Journey',
    deleteJourney: 'Delete Journey',
    smartActions: 'Action',
    startJourney: 'Start Journey',
    comment: 'Comment',
    selectJourneyRestartDate: 'Journey Restart Date',
    deleteJourneyMsg: 'Are you sure, you want to delete this journey?',
    journeyFlow: 'Journey Flow',
    aboutJourney: 'About Journey',
    startJourneyNow: 'Start the journey now?',
    journeyInfoBelow: 'Enter the informations below.',
    employer: 'Employer',
    emailPhone: 'Mobile Number/Email',
    executeWorkflow: 'Run Automation',
    cloneWorkflow: 'Clone Workflow',
    stripIdValidationMsg: `Please setup stripe keys from 'Payment Setup' tab to create a memberships!`,
    selectTimezoneAppointmentWidget: 'Timezone',
    subscriptions: 'Subscriptions',
    numberAlreadyExist: 'Inbox with this number already exist',
    contactInfo: 'Contact Info',
    searchAndSelectTeamMembers: 'Search and Select Team Members',
    addCareTeam: 'Add Care Team',
    setAsPrimaryCarePhysician: 'Mark as PCP',
    phiWarning:
      "Please don't send PHI information over email/SMS unless and until it is verified and you have consent from a member to send it",
    emailInboxSyncWarning:
      "Email inbox sync's with your real email inbox in every 3mins, it's not a real time.",
    createEmail: 'Start email',
    emailOrPhoneNumberRequired: 'Email or phone number is required',
    secondaryEmail: 'Secondary Email',
    secondaryPhoneNumber: 'Secondary Mobile No.',
    secondaryPhone: 'Secondary Phone',
    selectLocations: 'Select Locations',
    purpose: 'Purpose',
    selectDateRange: 'Select Date Range',
    selectTimeRange: 'Select Time Range',
    timezone: 'Timezone',
    createNew: 'Create New',
    tryUnityAI: 'Try Unity AI',
    journey: 'Journeys',
    roleAndPracticeLocation: 'Roles & Practice Location',
    consents:
      "We have member's consent to send a communication via email, SMS, voice or any other electronic media.",
    memberFacingConsents:
      "I give my consent to send a communication via email, SMS, voice or any other electronic media.",
    memberFacingCCMConsent: "I give my consent to enroll for CCM care program.",
    memberFacingTCMConsent: "I give my consent to enroll for TCM care program.",
    memberFacingECMConsent: "I give my consent to enroll for ECM care program.",
    memberConsent: "We have member's consent for treatment",
    insurance: 'Insurance',
    addInsurance: 'Add Insurance',
    widgets: 'Widgets',
    scheduledImmediateCampaign: 'Scheduled/Immediate Campaign',
    familyMembers: 'Family & Caregivers',
    journeyStartMsg: 'Journey started successfully',
    journeyStartDateUpdatedMsg:'Journey start date updated successfully.',
    startFormCreation: 'Start',
    addScoreFields: 'Select fields',
    journeyAlreadyAssignMsg:
      'Selected journey already assigned to this member.',
    activities: 'Activities',
    noActivitiesData: 'No Activities Data',
    lastThreeWeeks: 'Last 3 weeks',
    lastThreeMonths: 'Last 3 months',
    lastSixMonths: 'Last 6 months',
    criticality: 'Criticality',
    reaction: 'Reaction',
    reactions: 'Reactions',
    voiceMail: 'Voice Mail',
    editPracSchedule: 'Add Practice Schedule',
    editUserSchedule: 'Edit User Schedule',
    practiceAvailabilityAddSlots: 'Add Slots',
    practiceAvailabilityScheduleOverride: 'Schedule Override',
    saveEdits: 'Save Edits',
    addSlots: 'Add Slots',
    scheduleOrride: 'Schedule Override',
    configPracticeSchedule: 'Configure Practice Schedule',
    configUserSchedule: 'Configure User Schedule',
    addPracSchedule: 'Add Practice Schedule',
    saveInboxBusinessHours: 'Save',
    addUserSchedule: 'Add User Schedule',
    notAvailable: 'Not Available',
    deleteScheduleForLocation:
      'Are you sure you want to delete all schedules for this Location?',
    deleteScheduleForUser:
      'Are you sure you want to delete all schedules for this user?',
    enrollmentStatus: 'Enrollment Status',
    planName: 'Plan Name',
    endDate: 'End Date',
    noSubscriptionsMsg: 'No Active Subscriptions',
    groupName: 'Group Name',
    groupDescription: 'Group Description',
    accept: 'Accept',
    acceptAll: 'Accept All',
    decline: 'Decline',
    declineAll: 'Decline All',
    declineReasonHeader: 'Decline Reason',
    declineReasonMessage:
      'Are you sure you want to decline this appointment request?',
    proposeNewSlot: 'Propose new slot',
    allergy: 'Allergy',
    medication: 'Medication',
    immunization: 'Immunization',
    procedure: 'Procedure',
    noComponentsAvailable: 'No Components Available',
    globalTheme: 'Form Theme',
    createGlobalTheme: 'Create Global Form Theme',
    headerColor: 'Header Background Color',
    fontColor: 'Font Color',
    logoPosition: 'Logo Position',
    desktop: 'Desktop',
    tablet: 'Tablet',
    memberGroups: 'Member Groups',
    noGroupsAvailable: 'No groups available',
    relationShipWith: 'Relationship With',
    mobileAndEmail: 'Mobile & Email',
    backToGroups: 'Back to Groups',
    skipJourneyDetailMsg:
      'You can skip this to start the journey at a later time.',
    addEmployer: 'Add Employer',
    selectAll: 'Select All',
    itemsNotFoundInTheCard: 'Items not found in the card',
    membershipWidget: 'Membership widget',
    sendInvitation: 'Send Invitation',
    importHtmlTemplate: 'Import HTML Template',
    editAndUse: 'Edit & Use',
    templateRequired: 'Template required',
    use: 'Use',
    publish: 'Publish',
    adminViewHintTxt: 'We have moved admin settings here',
    standardViewHintTxt: "You've switched to admin view. Click home to go back",
    yesSetDefault: 'Yes, Set as default',
    sentForms: 'Sent Forms',
    stickyNotes: 'Sticky Notes',
    MediaAndDoc: 'Media and docs',
    files: 'Files',
    loadMoreActivity: 'Load more activity',
    upDateStickyNote: 'Update Sticky Note',
    addStickNote: 'Add Sticky Note',
    emptyStickNotes: 'Let’s add sticky notes of patient’s quick update',
    defaultPreference: 'Default preference',
    sendmembershiplink: 'Send Membership Link',
    orderTaskDetail: 'Order Task Details',
    openInEhr: 'Open In EHR',
    journeyDetail: 'Journey Details',
    drugName: 'Drug',
    labTestName: 'Lab Test',
    vaccineName: 'Vaccine',
    orderType: 'Order Type',
    primaryContactUpdated: 'Primary Contact Changed',
    primaryContactUpdatedForAllConversation: 'Primary Contact Changing for all conversation will update once all conversation is moved',
    smtpUpdatedSuccess: 'Smtp setting updated successfully',
    imapUpdatedSuccess: 'Imap setting updated successfully',
    reAssignNumberTo: 'Re-assign Number to',
    assignNumberTo: 'Assign Number to',
    chooseSmsManagers: 'Choose SMS managers',
    additionalSettings: 'Phone Tree',
    outOfOfficePhoneTree: 'Out Of Office Phone Tree',
    appDownloadTitle: 'Download App',
    appDownloadSubtitle:
      'Working on smaller screen sizes? Download our app to work on the go',
    appTitle: 'Same efficiency,\nnow on mobile app',
    appSubtitle:
      'Manage appointments, have virtual visits, event reminders, track business performance, task management. Its all in here.',
    consumers: 'Consumers',
    communicationInfo: 'Messages',
    addZipCode: 'Add Zipcode',
    createNote: 'Create Note',
    addNewNote: 'Create New Note',
    newNoteTemplate: 'New Note Template',
    account: 'Account',
    subtitle: 'Subtitle',
    googleMapLink: 'Google Map Link',
    about: 'About',
    profile: 'Profile',
    titlePosition: 'Title Position',
    headerTitlePosition: 'Header Title Position',
    formGlobalThemePreviewTitle: 'Preview',
    adminPanel: 'Admin Panel',
    newEmployee: 'New Employee',
    employee: 'Employee',
    employeeName: 'Employee Name',
    employerName: 'Employer Name',
    contactPerson: 'Contact Person',
    imapEmailError: 'Error while setting the IMAP email setting',
    smtpEmailError: 'Error while setting the SMTP email setting',
    linkToLatestAppointment: 'Link to Latest Appointment',
    library: 'Library',
    practice: 'My Practice',
    automationLibrary: 'Automation Pool',
    orderNote: 'Order Note',
    taskTitle: 'Task Title',
    taskDescription: 'Task Description',
    additionalDetail: 'Additional Details',
    clinicalTitle: 'Employer Dashboard',
    hint: 'Hint',
    elation: 'Elation',
    spruce: 'Spruce',

    consumerSetting: 'Settings',
    conversationTitle: 'Conversations',
    communicationSettingTitle: 'Settings',
    scheduleSetting: 'Settings',
    taskSettingTitle: 'Settings',
    contentTitle: 'Content',
    formsTitle: 'Forms',
    addNewFormBtnLabel: 'Form',
    journeysTitle: 'Journeys',
    productTitle: 'Products',
    integrationTitle: 'Integration',
    widgetTitle: 'Widgets',
    teamTitle: 'Team',
    logsTitle: 'Audit Logs',
    userAccess: 'User Access',
    previous: 'Previous',
    noCareGaps: 'No care gaps',
    savingModalTitle: 'eRx savings',
    careGapsIdentified: 'Care gaps identified',
    appointmentNote: 'Set Appointment Note',
    staffInstructionsAppointmentBookDrawerTitle: 'Instructions for staff',
    userListDrawerTitle: 'Users',
    enterAppointmentNoteHere: 'Enter appointment note here',
    lastMonthProfile: 'Last Month',
    newReports: 'New Reports',
    noDiagnosticReport: 'No reports',
    npiNumber: 'NPI number',
    licenseDetail: 'License Detail',
    deaNumber: 'DEA number',
    faxNumber: 'Fax Number',
    useDefaultTemplate: 'Use Default Template',
    showAllTemplates: 'Show All Templates',
    chooseATemplate: 'Choose a Template',
    newTemplate: 'New Template',
    saveAndSign: 'Save & Sign',
    noRecordFound: 'No records found to update',
    scheduleUpdated: 'Schedule Updated',
    enterValidName: 'Please enter valid name',
    addScoreGroup: 'Add score groups',
    result: 'Result',
    instantChat: 'Instant Chat',
    instantMeet: 'Instant Meet',
    report: 'Report',
    selectNoteTemplate: 'Please select a template to start documentation',
    createCustomTemplate: 'Create Custom Template',
    saveDashboard: 'Save Dashboard',
    updateDashboardView: 'Update Dashboard View',
    updateDashboard: 'Update Dashboard',
    Cancel: 'Cancel',
    dashBoardConfig: 'Configure Dashboard',
    marketCost: 'Market Cost',
    dpcCost: 'DPC Cost',
    ourCost: 'Our Cost',
    ourServiceCost: 'Our service cost',
    customerSatisfactionTitle: 'Member Satisfaction',
    customerSatisfactionSubtitle: 'Average patient & client satisfaction',
    bloodGroup: 'Blood Group',
    noteCreatedSuccessfully: 'Note Created Successfully',
    call: 'Calls',
    templates: 'Templates',
    create_new_template: 'Create New Template',
    recentClinicEvents: 'Recent clinical events',
    qty: 'Qty',
    spending: 'Spendings',
    discount: 'Discount',
    totalSaving: 'Total Savings',
    emloyerLoginError:
      "You don't have the appropriate access to login. Please contact practice admin to get the access.",
    patientsLeads: 'Member/Leads',
    sorryForInconvenience:
      'Please try after some time. Sorry for inconvenience!',
    employerLoginNotSupportedOnMobile: 'Employer login is not supported on mobile apps, please use web portal to access the employer details.',
    invalidCredential: 'Invalid credentials!',
    TOTAL_SAVINGS: 'Total Savings',
    ERX_SAVINGS: 'eRx savings',
    LAB_SAVINGS: 'Lab savings',
    IMAGING_SAVINGS: 'Imaging savings',
    VISITS_SAVINGS: 'Visits savings',
    PROCEDURES_SAVINGS: 'Procedure savings',
    CLAIMS_AVOIDED: 'Claims avoided',
    pendingOrders: 'Pending Reports',
    noPendingOrders: 'No pending orders',
    orders: 'Orders',
    previewReport: 'Preview Report',
    printPreviewDespPlaceholder:
      'You can describe the above indicators / trends here. ',
    reportTitlePlaceholder: 'Give a title to the report',
    addReportTitle: 'Add Report Title',
    addReportIntroductionHere: 'Add report introduction here',
    reportIntroductionPlaceholder: 'Write your report introduction here',
    upload: 'Upload',
    updateContactDisplay: 'Update Contact Display',
    addToPatientDrive: 'Add To Patient Drive',
    takePhoto: 'Take a Photo',
    enterSomeContent: 'Please enter some content',
    updateTheStickyNote : 'Please update the sticky note before saving changes',
    addForms: 'Add Forms',
    updateForms: 'Update Forms',
    sendReportCommunication: 'Send Communication',
    deleteTaskConfirmation: 'Are you sure, you want to delete this task?',
    deleteSubTaskConfirmation: 'Are you sure, you want to delete this subtask?',
    dummyTemplateMessage:
      'This email template contains dummy data to help you visualize how it looks to recipient. You can use this template in Campaign to share it with actual users. Do not share PHI or financial information',
    previewEmail: 'Preview Email',
    saveAndNext: 'Save and Next',
    labOrder: 'Lab',
    medOrder: 'Medication',
    radOrder: 'Imaging',
    prescriberNotificationDetail: 'Prescriber Notification Detail',
    autoReply: 'Auto Reply',
    alreadyDeletedTask:
      'This task has been already deleted. refreshing task list again',
    recentErx: 'Recent eRx',
    noRecentErx: 'No recent eRx',
    eRx: 'eRx',
    viewNote: 'View Note',
    inProgress: 'In Progress',
    failed: 'Failed',
    chosenName: 'Chosen Name',
    pronoun: 'Pronoun',
    pleaseAgreeToTermsAndCondition: 'Please agree to the Terms & Conditions',
    draftOrders: 'Draft Orders',
    noDraftOrders: 'No draft orders',
    resetFilter: 'Reset Filter',
    reactionType: 'Reaction Type',
    invite: 'INVITE',
    startNew: 'START NEW',
    buildNew: 'BUILD NEW',
    addNews: 'ADD NEW',
    dateAndTimeTitle: 'Date & Time',
    mentionedDateTime : 'Mention Date',
    filterRemoveMsg:
      'Are you sure you want to close the drawer? It will not apply the newly added filters.',
    internalMessageInfo:
      'Internal conversation messages will be visible only to you and your team.',
    mandatoryFieldError: 'Please fill the required details.',
    stopDateError: 'Medication stop date cannot be less than start date',
    TaskSubtaskDueDateError: 'Parent task due date cannot be less than subtask due date.',
    editTaskErrorMsg: 'You cannot edit this task',
    discontinuedDate: 'Discontinued Date',
    markAsReviewed: 'Mark As Reviewed',
    declineAppointment: 'Decline Appointment',
    optionalDeclinedReason: 'Reason (Optional)',
    results: 'Results',
    patientProposedNewTime: 'Patient proposed new time',
    todayInprogressNoteMsg:
      'There is already a note in progress for this patient for today. Do you want to create a new note?',
    createNewButtonLabel: 'Create new',
    openProgressNoteLabel: 'Open in progress note',
    trailPeriodErrorMsg: 'Trial period must be between 0 to 730',
    addScore: 'Add Score',
    refresh: 'Refresh',
    signAndPrint: 'Sign & Print',
    labOrders: 'Lab Orders',
    medOrders: 'Medication Orders',
    radOrders: 'Imaging Orders',
    contactUpdateConfirmationMessage: 'Do note that editing member details here will also update EHR and membership apps that Fold is integrated with.\nDo you want to proceed?',
    group: 'Group',
    credentials: 'Credentials',
    No: 'No',
    Yes: 'Yes',
    unlock: 'Unlock',
    ok: 'Ok',
    Ok: 'Ok',
    deleteNoteConfirmation: 'Are you sure, you want to delete this note? This action cannot be undone.',
    noteDeletedSuccessfully: 'Note Deleted Successfully',
    appointmentRequested: 'Appointment requested',
    appointmentScheduled: 'Appointment scheduled',
    appointmentRescheduled: 'Appointment rescheduled',
    appointmentRequestMsg: 'Your appointment request has been sent',
    activeMembers: 'Active Members',
    passMembersNew: 'members added on',
    inactiveMembers: 'Inactive Members',
    failMembersNew: 'members removed on',
    addVisitNote: 'Add Visit Note',
    filterPatientNotesDrawerTitle: 'Note Filters',
    filterPatientNotesDrawerCreatedByTitle: 'Created by',
    filterPatientNotesDrawerCreatedByPlaceholder: 'Select created By',
    filterPatientNotesDrawerSignedByTitle: 'Signed by',
    filterPatientNotesDrawerLastAmendedAndSignedByTitle: 'Last amended and signed by',
    filterPatientNotesDrawerSignedByPlaceholder: 'Select signed by',
    filterPatientNotesDrawerLastAmendedAndSignedByPlaceholder: 'Select last amended and signed by',
    filterPatientNotesDrawerCreatedOnStartDateTitle: 'Created from date',
    filterPatientNotesDrawerCreatedOnStartDatePlaceholder: 'Select created from date',
    filterPatientNotesDrawerCreatedOnEndDateTitle: 'Created end date',
    filterPatientNotesDrawerCreatedOnEndDatePlaceholder: 'Select created end date',
    filterPatientNotesDrawerSignedOnStartDateTitle: 'Signed from date',
    filterPatientNotesDrawerSignedOnStartDatePlaceholder: 'Select signed from date',
    filterPatientNotesDrawerSignedOnEndDateTitle: 'Signed end date',
    filterPatientNotesDrawerSignedOnEndDatePlaceholder: 'Select signed end date',
    filterPatientNotesDrawerLastAmendedAndSignedOnStartDateTitle: 'Last amended and signed from date',
    filterPatientNotesDrawerLastAmendedAndSignedOnStartDatePlaceholder: 'Select last amended and signed from date',
    filterPatientNotesDrawerLastAmendedAndSignedOnEndDateTitle: 'Last amended and signed end date',
    filterPatientNotesDrawerLastAmendedAndSignedOnEndDatePlaceholder: 'Select last amended and signed end date',
    filterPatientNotesDrawerDiagnosisTitle: 'Diagnosis',
    filterPatientNotesDrawerDiagnosisPlaceholder: 'Search diagnosis',
    filterPatientNotesDrawerChiefComplaintTitle: 'Chief complaint',
    filterPatientNotesDrawerChiefComplaintPlaceholder: 'Search chief complaint',
    filterPatientNotesDrawerNoteTemplateTitle: 'Note template',
    filterPatientNotesDrawerNoteTemplatePlaceholder: 'Select note template',
    filterPatientNotesDrawerFilterBy: 'Order status',
    Apply: 'Apply',
    clearAllFiltersBtnLabel: 'Clear All',
    labReports: 'Lab Reports',
    newLabReports: 'New Lab Reports',
    radReports: 'Imaging Reports',
    newRadReports: 'New Imaging Reports',
    sign: 'Sign',
    noTimelineForMemberMsg: 'It seems like there are no timeline event for this member',
    addendum: 'Amend',
    addendumAndPrint: 'Amend & Print',
    addendumAndPrintShareable: 'Amend & Print Shareable',
    filterOrderTimelineDrawerTitle: 'Order Filters',
    amend: 'Amended',
    goBackDashboardMessage: 'Back to notes',
    viewSignedMessage: 'Refresh note',
    remove: 'Remove',
    collapse: 'Collapse',
    expand: 'Expand',
    unlocked: 'Unlocked',
    phoneNumberLabel: 'Phone No.',
    amendedNote: 'Amended',
    amendedAndSignedNote: 'Amended And Signed',
    signedNote: 'Signed',
    openNote: 'In Progress',
    addendumAndSign: 'Amend & Sign',
    addendumAndSignAndPrint: 'Sign & Print',
    addendumAndSignAndPrintShareable: 'Sign & Print Shareable',
    userName: 'Username',
    noMatchToSearch: 'No Matching Searches',
    userAssignSuccessfully: 'User Assign Successfully',
    userUnAssignSuccessfully: 'User Unassign Successfully',
    userRemoveSuccessfully: 'User Remove Successfully',
    assignConversation: 'Assign Conversation',
    updateEmployerPayerConfirmMessage: 'The market price of labs, visits and procedures will be pulled as per the payer selected. Do you want to select the payer?',
    cptCode: 'CPT code',
    marketCostSuccess: 'Market cost updated successfully.',
    marketCostError: 'Error while updating market cost.',
    stopDate: 'Stop Date',
    statusReason: 'Status Reason',
    chat: 'Chat',
    deleteCareTeamAlert: 'Delete Care Team Member',
    deleteCareTeamMessage: 'Are you sure you want to delete this care team member?',
    shortName: 'Short Name',
    shortNameInfo: 'The short name code of the appointment type. Maximum length of 4 characters. Used for making schedule templates. Note, this value cannot be changed after creation, so please choose carefully.',
    noMentions: 'No mentions conversations available',
    noAssigned: 'No assigned conversations available',
    noUnassigned: 'No unassigned conversations available',
    noMissedCall: 'No missed calls available',
    noCalls: 'No calls available',
    noArchived: 'No archived conversations available',
    externalLabOrder: 'External Lab',
    externalRadOrder: 'External Imaging',
    saveAsDraft: 'Save As Draft',
    orderDate: 'Do test on (date)',
    printAndSubmit: 'Print & Submit',
    printAndSign: 'Print & Sign',
    selectRole: 'Select a role to add in care team',
    selectRoleToAddMembers: 'Please select a role above to add members in care team for this journey',
    careTeamMembers: 'Care Team Members',
    sig: 'Sig',
    searchLocation: 'Search Location',
    selectDocumentType: 'Select document Type',
    cancelReason: 'Cancel Reason',
    scheduleSend: 'Schedule Send',
    scheduleSuccess: 'Scheduled successfully',
    reScheduleSuccess: 'Rescheduled successfully',
    deleteSuccess: 'Deleted Successfully',
    sendSuccess: 'Send Successfully',
    overDue: 'Over Due',
    healthComponentsInfo: 'Allows you to smartly build custom components in your forms with the ability to auto-fill patient data from Fold and integrated EHRs.',
    patientNotLoggedInError: 'Patient doesn’t seem to have download or logged in to patient app. May not be able to see your messages.',
    maritalStatus: 'Marital Status',
    formSentOn: 'Form Sent On',
    responseReceivedOn: 'Response Received On',
    onlyEmployees: 'Only Employees',
    defaultCommunicationNumber: 'Default Communication Number',
    communicationNumber: 'Communication Number',
    specificTime: 'Specific Time',
    timeSlots: 'Time Slots',
    convertPatientSuccessMsg: 'Convert to Patient Successfully',
    networkConnectionFailed: 'Please check your internet connection',
    selectTimeInFuture: 'Select time in future at least 2 min ahead in current time',
    callDisconnectedHeader: 'Call disconnected',
    callDisconnectedMessage: 'Your call was disconnected due to a network problem',
    mapUserToEhr: 'Map User to EHR',
    specificTimeOverlapMsg: 'User is either busy or not available at this time',
    checkingSlotAvailabilityMsg: 'Checking availability of the selected time',
    checkingJourneyNameAvailability: 'Checking journey name availability',
    checkingLabelNameAvailability: 'Checking label name availability',
    noPoolsExist: 'No pools exist',
    usersInSelectedTaskPool: 'Users in selected task pool',
    defaultAssigneeInSelectedTaskPool: 'Default assignee in selected task pool',
    noPoolFound: 'No Pool Found',
    mapLocationToEhr: 'Map Location to EHR',
    appUpdateHeader: 'Update Required',
    appUpdateAlert: 'We have launched a new and improved version. Please update the app for a better experience.',
    addWidget: 'Add Widget',
    primaryValue: 'Primary Value',
    metrics: 'Metrics',
    dimensions: 'Dimensions',
    section: 'Section',
    createWidgetError: 'Something went wrong in create widget',
    createWidgetSuccess: 'New widget successfully create',
    archivedSuccessfully: 'Conversation Archived Successfully',
    unarchivedSuccessfully: 'Conversation Unarchived Successfully',
    enterPhone: 'Enter Phone',
    accountName: 'Account Name',
    usersExistInAccount: 'Email already exists in another practice',
    existingUserInfo: 'Choose a different email and try again.',
    okGotIt: 'Ok, Got it!',
    populationGroupNameExists: 'A group with the same name already exists. Please choose a different name for the group and try again.',
    officeHoursPhoneTree: 'Office Hours Phone Tree',
    enableRotationalAssignment: 'Enable Rotational Assignment',
    holidayHoursPhoneTree: 'Holiday Hours Phone Tree',
    setDefaultLocationInfo: 'Set the location default communication phone number to enable automation to send SMS to patients based on their assigned location.',
    markAsUnreadSuccess: 'Mark as Unread Successfully',
    loginAsADifferentUser: 'Login as a different user',
    offlineMessage: 'No internet connection',
    backOnlineMessage: 'Back online',
    noInternetMessageSubTitle:
      'You’ll be redirected to the website once your internet is reconnected.',
    fetchingDetails: 'Fetching Details...',
    loginToTakeActionOnCall: 'Please login/unlock to view the member details.',
    widgetName: 'Widget name',
    sectionLabel: 'Section where widget will reside',
    dimensionsForXAxis: 'Select X-axis Dimensions',
    configure: 'Configure',
    noUserSelectedForArole: 'No user selected for a role',
    noUserWithARole: 'No user with a role',
    healthComponentFormInfoMessageForLead: 'You are sending a form with health components to a lead/prospect. Health components will not work for leads',
    genericHealthComponentFormInfoMessageForLead: 'Selected form contains health components which are not applicable to leads/prospects',
    defaultEmailError: 'Please set the default email template for the general category to send the membership link',
    invoices: 'Invoices',
    invoiceNumber: 'Invoice Number',
    invoiceCreated: 'Invoice Created',
    invoiceDue: 'Invoice Due',
    plan: 'Plan',
    unitPrice: 'Unit Price',
    userUpdated: 'User Updated',
    noTranscriptionAvailable: 'No transcription available',
    codeGroupName: 'Code Group Name',
    codeGroupDescription: 'Code Group Description',
    codeGroupNameAlreadyexists: 'A code group with the same name already exists. Please try with a different name.',
    contactTypeAlreadyexists: 'A contact type with the same name already exists. Please try with a different name.',
    numberOfContacts: 'Number of Contacts',
    contactTypeStatusTooltip: 'This status indicates that the contact type is active and can be selected when creating a new contact',
    chooseSMSAutoReply: 'Add auto reply for this number',
    codeGroupNameAlreadyExists: 'A code group with the same name already exists. Please try with a different name.',
    isDeleteHistoricalMessage: 'Please note that underlying EHR will mark this record as Historical on delete.',
    pleaseSelectMemberToSendForm: 'Please select a member to send form',
    pleaseSelectMemberToAssignJourney: 'Please select active members to assign journey',
    noActiveMemberInGroupToSendForm: 'Selected group do not have any active member to send form',
    noMemberInGroupToSendForm: 'Selected group does not have any member to send form',
    selectedGroupsContainInactiveMembers: 'Selected group has inactive members. Form will only be sent to active members of the group, do you wish to continue?',
    noActiveMemberInGroupToAssignJourney: 'Selected group do not have any active member to assign journey',
    noMemberInGroupToAssignJourney: 'Selected group does not have any member to assign journey',
    selectedGroupsContainInactiveMembersToAssignJourney: 'Selected group has inactive members. Care journey will only be assigned to active members of the group, do you wish to continue?',
    formSentNotification: 'Do you want to be notified once form have been successfully sent?',
    popGroupCareJourneyAssignInactiveMemberNotification: 'Selected group has inactive members. Care journey will only be assigned to active members of the group, do you wish to continue?',
    primaryAppointmentUser: 'Primary User',
    secondaryAppointmentUsers: 'Secondary Users',
    primaryAppointmentUserLabelInfo:
      'List of team members whose calendar you have access to. If you see someone missing in this list, please get access to their calendars in Settings → Calendar',
    secondaryAppointmentUserLabelInfo:
      'List of team members whose calendar you have access to. If you see someone missing in this list, please get access to their calendars in Settings → Calendar',
    invalidURL: 'Invalid URL',
    linkTextCannotBeEmpty: 'Link text cannot be empty',
    pleaseEnterLinkAndUrl: 'Please enter Link text and URL',
    enterLinkText: 'Enter Link Text',
    enterURL: 'Enter URL',
    holidays: 'Holidays',
    addNewHolidays: 'New Holiday',
    addHoliday: 'Add Holiday',
    updateHoliday: 'Update Holiday',
    setAutoMessageReply: 'Set Auto Reply Message',
    holidayName: 'Holiday Name',
    dateAndTime: 'Date And Time',
    deleteHoliday: 'Delete Holiday',
    deleteHolidayAlertMessage:
      'Are you sure, you want to delete this holiday?',
    holidayDeletedMessage: 'Holiday deleted',
    holidayUpdatedMessage: 'Holiday updated',
    holidayDeleteMessage: 'Holiday delete',
    holidayCreatedMessage: 'Holiday created',
    sendForm: 'Send Form',
    backToRules: 'Back to rules',
    createRule: 'Create rule',
    viewRules: 'View Rules',
    dismiss: 'Dismiss',
    sendAppointmentLink: 'Send Appointment Link',
    noSuggestionsForNow: 'No Suggestions for now',
    labels: 'Labels',
    holidaySubtitle: 'Configure holidays to manage calls and messages during specific holiday periods in the phone tree and auto replies',
    noData: 'No Data',
    All: 'All',
    addSubTasks: 'Add Subtasks',
    subTasks: 'Sub Tasks',
    subTaskAddErrorMsg: 'You cannot add subtasks if you assign them to task members.',
    subtaskDeletedSuccess: 'Subtask Deleted Successfully',
    subtaskStatusUpdated: 'Subtask Status Updated Successfully',
    subtaskTitleUpdated: 'Subtask Title Updated Successfully',
    subtaskNewAdded: 'New Subtask Added Successfully',
    subtaskAssignedUpdated: 'Subtask Assignee Updated Successfully',
    subtaskDueDateUpdated: 'Subtask Due Date Updated Successfully',
    addCommunicationType: 'Add Communication Type',
    deleteCommunicationType: 'Delete Communication Type',
    createNewCategories: 'Create new category',
    categoryName: 'Category Name',
    categoryAddedSuccessfully: 'Category added successfully',
    addNewCategory: 'Add New +',
    select_labels: 'Select Labels',
    addNewLabels: 'Add New Label',
    noLabelsAvail: 'No labels available',
    searchLabels: 'Search labels',
    pleaseNoteThatOnlySelectedLocationsWillBeAvailableForBookingWithThisAppointmentType: 'Please note that only selected locations will be available for booking with this appointment type.',
    autoReplySubtitle: 'Auto-replies will be triggered during designated holidays and will respect the holiday configuration specific to location.',
    stopCareJourneyMessage: 'Journey once stopped, cannot be resumed. Are you sure you want to stop this care journey?',
    stopCareJourneyHeader: 'Stop Journey',
    applicableOn: 'applicable on',
    selectDays: 'Select days',
    addNewSchedule: 'Add new schedule',
    addNewTime: 'Add new time',
    fromTime: 'From time',
    toTime: 'To time',
    applicableTo: 'Applicable To',
    primaryProvider: 'Primary Provider',
    secondaryProvider: 'Secondary Provider',
    appointmentTypeCareTeam: 'Care Team',
    canPatientBookThisTypeOfAppointment: 'Allow patients to self schedule this Appointment Type',
    isRsvpEnabledForThisTypeOfAppointment: 'Require confirmation/RSVP for this Appointment Type',
    Signed: 'Signed',
    applicableFor: 'Applicable For',
    listOfProvidersInfoMsg: 'Select how Fold should show the list of providers when this appointment type is being scheduled',
    primaryProviderInfoMsg: 'Appointment will reflect for this provider in EHR',
    updateNowText: 'Update Now',
    autoReplyInfo: 'Allows you to set an automatic reply message when the practice location is out of business hours \nand triggers for once in 1 hour if a member/patient is trying to contact the practice location.',
    viewBy: 'View by',
    foldActiveMembers: 'Fold Active Members',
    insuranceExpiredMsg: 'Insurance is cancelled or expired.  Please reactivate this insurance before attempting to update it using the /patients/{patientid}/insurances/{insuranceid}/reactivate API call.',
    insuranceExpiredDeleteMsg: 'No insurance package is active for the ID given.',
    insuranceCancelledOrExpiredMsg: 'Cannot delete/update insurance as it is expired. Please proceed to your EHR to deactivate the policy.',
    foldInactiveMembers: 'Fold Inactive Members',
    errorInFetchingHintStatus: 'Error in fetching hint status',
    membershipStatus: 'Membership status',
    subTaskPendingMsg: 'Please complete all subtasks before marking the parent task as complete',
    TaskNotDeletedMsgForTaskHasSubtask : 'Cannot delete the parent task because it has associated subtasks',
    fetchingTaskDetails: 'Fetching task details',
    appointmentCategory: 'Appointment Category',
    memberLimit: 'Member Limit',
    selectAppointmentPatientsAndGroups: 'Patients, Prospects or Groups',
    setExpiryForRsvp: 'Set Expiry for the RSVP Link',
    selectNumber: 'Select Number',
    voiceCall: 'Voice Call',
    importRule: 'Import Rule',
    searchRule: 'Search Rule',
    ruleName: 'Rule Name',
    restrictUsers: 'Restrict Users',
    restrictUsersFeildTitle: 'Reserve users to this appointment type.',
    providerBlockedWithAnotherType: 'Selected provider/s have already been reserved with one or more appointment type.',
    executionDueIn: 'Execution due in:',
    lastExecutionCompletedOn: 'Last updated on:',
    assignType: 'Assign Type',
    assignTypeAlert: 'Please select at least one message to assign message type',
    assignTypeAssociatedAlert: 'One or more selected messages are already associated with the communication type. Please select the correct message.',
    assignTypeSubtitle: 'Set the type of communication done with member for selected messages/calls.',
    chooseLocationWithTaggedAppointmentType: 'Please choose a location that is tagged to selected appointment type.',
    goals:'Goals',
    taskAdherence:'Task Adherence',
    adherence:'Adherence',
    journeyProgress:'Journey Duration Progress',
    goalNote: 'Goal Note',
    goalStatus: 'Goal Status',
    completeJourney: 'Complete Journey',
    completionDate: 'Completion Date',
    updateGoalStatus: 'Update Goal Status',
    completeJourneyConfirmation: 'Journey once marked as completed, cannot be resumed. Do you want to proceed?',
    updateCostAlert: 'Are you sure you want to update the market cost for existing records?',
    updateCostAlertDescription: 'If Yes, this action will modify the market cost values for the records currently in the system.',
    officeHoursSavings: 'Office Hours Savings',
    outOfOfficeHoursSavings: 'OOO Savings',
    populationGroup: 'Population Group',
    contractName: 'Contract Name',
    newContract: 'New Contract',
    memberName: 'Member Name',
    newMember: 'New Member',
    orderName: 'Order Name',
    taskOrderDate: 'Order Date',
    subOrderType: 'Type',
    prescriptionOrderDetail: 'Prescription Order Detail',
    labelName: 'Label Name',
    labelDescription: 'Label Description',
    labelColor: 'Label Color',
    pendingFormLogCount: 'Not started',
    draftFormLogCount : 'In progress',
    submittedFormLogCount : 'Completed',
    totalFormLogCount : 'Total Forms Sent',
    ssoAccessError: 'Looks like you don’t have access',
    mobileConsentMessage: 'The patient has not provided consent for communication. Please log in to the Fold web dashboard to set consent.',
    mobileConsentMessagePatient: 'The patient has not provided consent for communication. Please log in to the Fold web dashboard to set consent.',
    mobileConsentMessageLeads: 'The contact has not provided consent for communication. Please log in to the Fold web dashboard to set consent.',
    addStickyNotesText: 'You can add sticky notes here',
    stickyNotesUpdated: 'Sticky note updated successfully',
    finished: 'Finished',
    errorOnUpdateSchedule: 'Error occurred while updating the scheduled message.',
    successScheduleMessage: 'scheduled message has been successfully updated.',
    packages: 'Packages',
    createPackage: 'Create Package',
    saveAndPublish: 'Save & Publish',
    packageName: 'Package Name',
    packagePrice: 'Package Price',
    setLocation: 'Set Location',
    expiryDuration: 'Expiry Duration',
    setThumbnail: 'Set Thumbnail',
    linkComponentPlaceholder: 'Please paste a video link (YouTube/Vimeo) to play video inline within an article as an embedded experience',
    expiryDate: 'Expiry Date',
    enrolledMembers: 'Enrolled Members',
    priceTextOnly: 'Price',
    AVOIDED_WORKDAY_ABSENCE_SAVING: 'Avoided Workplace Absence Savings',
    AVOIDABLE_VISIT_SAVING: 'Avoidable Visit Savings',
    noPastMedicalHistory: 'No Medical History',
    pastMedicalHistory: 'Past Medical History',
    medicalHistory: 'Medical History',
    additionalDetails: 'Additional Details',
    forMember: 'FOR MEMBER',
    form: 'Form',
    preferredCommunicationMode: 'Preferred communication mode',
    blockTimeEditMsg: 'Block time edited successfully',
    filterByCategories: 'Filter by categories',
    createdDate: 'Created Date',
    updatedDate: 'Updated Date',
    staticSearchAndAddMembers: 'Static (Search and Add Members)',
    frequency3h: '3 hours',
    frequency6h: '6 hours',
    frequency12h: '12 hours',
    frequency1d: '1 day',
    frequency1w: '1 week',
    frequency1M: '1 month',
    dynamicAddByPatientChars: 'Dynamic (Add by Patient Characteristics)',
    searchTasks: 'Search task title or description',
    locationNotMatch: `Patient's location does not match with location tagged to the selected journey. Are you sure you want to assign?`,
    bulkAssignLocationNotMatch: `One or more patients' location does not match with location tagged to the selected journey. Are you sure you want to assign?`,
    cloneJourney: 'Clone journey',
    cloneJourneyAlertMessage: 'Are you sure you want to clone this journey?',
    clone: 'Clone',
    recommendedCareJourneys: 'Recommended Care Journeys',
    locations: 'Locations',
    selectRecommendedJourneys: 'Select Recommended Journeys',
    PATIENT_DETAILS: 'Patient Details',
    SIGN_AMEND_EHR_NOTE: 'Sign And Amend EHR notes',
    notificationSettingApiErrorMsg: "Sorry inconvenience, we are facing an issue updating notification settings. Please try again later",
    setRecurringAppointment: 'Set recurring appointment',
    repeatsEvery: 'Repeats Every',
    setReccuring: 'Set Recurring',
    editSeries: 'Edit Series',
    removeOccurence: 'Remove Occurrence',
    removeSeries: 'Remove Series',
    providerSummary: 'Provider Summary',
    draft:'Draft',
    published: 'Published',
    updateAndPublish: 'Update and publish',
    cancelOccurence: 'Cancel Occurrence',
    cancelSeries: 'Cancel Series',
    seriesCreationInProgress: 'Appointment series creation is in progress. Please check back in some time.',
    documents: 'Documents',
    noDocuments: 'No Documents',
    CourseCollateral: 'Course collateral',
    checkOutMember: 'Check out member',
    optedOut: 'Opted Out',
    execute: 'Execute',
    executeRule: 'Execute Rule',
    executeRuleConfirmMessage: 'Are you sure you want to execute this rule now?',
    executeRuleSuccessMessage: 'Rule has been added to execution queue successfully.',
    deleteGroup: 'Delete Group',
    deleteGroupSuccessMessage: 'Group Deleted Successfully',
    deleteGroupErrorMessage: 'Error while deleting campaign',
    labelIdFetchingErrorMessage: 'Error while fetching labels',
    linkCopied: 'Link Copied',
    packageStatusUpdated: 'Package status updated successfully',
    linkCopyOnlyPublished: 'Draft and Expired Package link cannot be copied',
    journeyStartDateErrorTitle: 'Journey already started',
    journeyStartDateDurationErrorTitle: 'Journey cannot be assigned',
    journeyStartDateErrorMessage: 'The selected fixed start journey has already commenced. Are you sure you want to assign it to the member?',
    journeyStartDateDurationErrorMessage: 'The selected fixed start journey has already started and exceeded its duration. It cannot be assigned at this point.',
    rolling: 'Rolling',
    rollingTollTipMessage: 'Journey will commence for members when they enroll into the package',
    fixedTooltipMessage: 'Journey will commence for all enrolled members at the shared designated date',
    fixedDate: 'Fixed date',
    startType: 'Start Type',
    noEventFound: 'No Event found',
    deleteInvite: 'Delete Invite',
    updatePackage: 'Update Package',
    sendRsvpLink: 'Re-send Invite',
    sendMeetingLink: 'Send meeting link',
    copyRsvpLink: 'Copy invite link',
    copyMeetingLink: 'Copy meeting link',
    recommendedPackages: 'Recommended Package',
    selectRecommendedPackage: 'Select Recommended Package',
    deleteDocument: 'Delete document',
    deleteDocumentMsg: 'Are you sure, you want to delete this document?',
    supportingDocuments: 'Supporting Documents',
    dontUpdate: "Don't Update",
    eventName: 'Event Name',
    noAppointmentFound: 'No appointment found',
    foldMicrophonePermission: 'Fold needs permission to access your microphone. Select "Browser" and go to "Settings for this website" to grant access.',
    searchPatientInfo: 'Search will only show patients who have downloaded or logged in to the patient app.',
    microphoneIsBlocked: 'Microphone is blocked',
    viewAll: 'View All',
    missedCallNotificationTooltipText:
      'Any staff member who is part of an SMS inbox or has a call directly forwarded to their Fold Grow device will receive a notification when there is a missed call to this number.',
    notificationSettingSubtitle:
      'Notification setting are applied to the Web App and Fold Grow App.',
    completesIn: 'Completes In',
    viewRecording: 'Show Recording',
    viewRecordings: 'Show Recordings',
    switchToReplyAlert: "You are replying on internal message",
    removeAssignUserConfirmationText: 'The user have been assigned the conversation, by removing username from the conversation, the conversation would be unassigned',
    emptyNoteMessage: 'Note template selected does not have any content. Please select another template',
    createGroupSuccessMessage: "Group Created Successfully",
    recordingsError: "Couldn’t Load Recordings",
    changed: "changed",
    added: "added",
    automations: 'Auomations',
    memberStatus: 'Member Status',
    acceptAllPAMIConfirmMessage: "Accepting all records will not accept unstructured and unmatched records. Do you wish to continue?",
    bulkActionSucessMessage: 'Accepted records sync in progress. Updates will reflect shortly.',
    conversationUnarchived: 'Conversation unarchived',
    costTemplate: 'Cost Template',
    ctemplateName: 'Template Name',
    costType: 'Types',
    cpt: 'CPT',
    cptAndModifier: 'CPT and Modifier',
    addCpts: "Add CPT",
    addCostTemplate: 'Add Cost Template',
    updateCpts: "Update CPT",
    updateCostTemplate: 'Update Cost Template',
    deleteCpt: 'Delete CPT',
    deleteCptConfirmation: 'Are you sure you want to delete this CPT ?',
    deleteAssignedTemplateConfirmation: 'This cost template is already associated with ',
    savings: 'Savings',
    identifier: 'Identifier',
    stickyNote: 'Sticky Note',
    changeFromActive: 'Change from Active to Draft',
    mp3FileErrorMsg: 'Only MP3 format is supported',
    audioUrlErrorMsg: 'Please enter URL/Upload audio file',
    userInvited: 'User Invited!',
    blockNumberActionMessage: `#PATIENT_NAME# has sent a STOP SMS to #PHONE_NUMBER#.  The carrier will no longer allow SMS's to #PATIENT_NAME#. To reopen this conversation, #PATIENT_NAME# will need to send a SMS with the word 'START' to #PHONE_NUMBER#.`,
    populationGroupDeleted: 'The population group linked to this campaign has been deleted',
    savedSuccessfully: 'saved successfully',
    chooseOption: 'Choose an Option',
    assignedToMeTab: 'Assigned to me',
    assignedToOthersTab: 'Assigned to others',
    unassignedTab: 'Unassigned',
    mentionsTab: 'Mentions',
    missedCallsTab: 'Missed Calls',
    archivedTab: 'Archived',
    allTab: 'All',
    chatsTab: 'Chats',
    smsTab: 'SMS',
    emailTab: 'Email',
    callsTab: 'Calls',
    internalChatsTab: 'Internal Chats',
    inboxChannelHeader: 'Inbox',
    channelHeader: 'Channels',
    addCommunicationTypeSuccess: "Message type added successfully!",
    noVirtualNumberAssigned: 'No Virtual number assigned to the logged in user',
    deleteContactNote: 'Delete Contact Note',
    deleteContactNoteMessage: 'Are you sure you want to delete?',
    removeCommunicationTypeSuccess: 'Communication type removed successfully!',
    removeCommunicationTypeError: 'Something went wrong in removing communication type',
    conversationThreadWarningMsg: 'You cannot reply and receive real-time messages in this thread, as you are not a participant in the conversation.',
    selectPatientOrGroupAlert: 'Please select a patient or a group',
    selectPatientAlert: 'Please select a patient',
    createChatGroupErrMsg: 'Apologies for the inconvenience, conversation was not created successfully. Please try again.',
    modifier: 'Modifier',
    CPT: 'CPT',
    NAME: 'NAME',
    COST: 'COST',
    MODIFIER: 'MODIFIER',
    DEFAULT_COST: 'DEFAULT COST',
    employerReport: 'Employer Report',
    on: 'On',
    off: 'Off',
    usersNotAvailable: 'Users are not available',
    assigneeNotSelectedInfo: 'Logged-in user has been selected as assignee.',
    mentionNotSelectedInfo: 'Logged-in user has been selected as mention.',
    createdByNotSelectedInfo: 'Logged-in user has been selected as created by.',
    taskFilterInfoOne: "Can't find a specific task? Change filters and try again.",
    taskFilterInfoTwo: "Task filters applied are carried across all tabs.",
    errorMsg: 'Something went wrong!!!',
    virtualNUmberAssignErrorMsg: 'No Virtual number assigned to the logged in user',
    phoneNumberNotFound: 'PhoneNumber Not Found',
    userAccessUpdateSuccess: 'Successfully update user schedule access.',
    messageUpdateSuccess: 'Message updated successfully',
    updateMessageError: 'Facing error in updating message',
    selectMsgErrorCreateNote: 'Please select atleast one message to create a note',
    messageDeleteSuccess: 'Message deleted successfully',
    messageDeleteError: 'Facing error in deleting message',
    submissionSentmsg: 'Thank you, your submission has been sent!',
    campaignCreateSuccess: 'Campaign created successfully',
    appointmentPastTimeInfo: 'Cannot schedule appointment in the past time!',
    appointmentBookingSuccess: 'Appointment Booked Successfully',
    patientAppInvitationError: 'Something went wrong while sending patient app invitation',
    emailSentSuccess: 'Email sent successfully',
    practiceLocationDeleteSuccess: 'Practice location deleted successfully',
    copyClickboardMsg: 'Copied to Clipboard',
    updateConsent: 'Update consent',
    continueToSMS: 'Continue to SMS',
    accessDenied: 'Access Denied',
    taskAccessDeniedMessage: 'You may not have the necessary access to edit the task.',
    taskFromMessage: 'Task From Message',
    leadContacts: 'Leads/Contacts',
    assignedTo: 'Assigned To',
    saveOnBehalfOf : 'Save on behalf of',
    noUsersFound: 'No users found',
    elationNonVisitNoteInfoMessage: 'This note doesn’t sync with the integrated EHR',
    theSelectedProviderWillBeTheAuthorOfTheCurrentNote: 'The selected provider will be the author of the current note.',
    deletedTaskByOtherMemberMsg: 'These tasks has been deleted by another team member.',
    groupCreationInProgress: 'The group will be created within next 12 hours and then you can run campaign. We are working on making groups creation faster/instantaneous in next releases.',
    mapUserToEhrText: 'Link this user created on Fold to an existing user from your underlying EHR for bidirectional sync of appointments, visit notes, and more.',
    mapLocationToEhrText: 'Link this location with an existing department/location in your underlying EHR to seamlessly sync patients, appointments, clinical data, and other resources.',
    deleteDefaultTemplateConfirmation: 'Unable to delete the default template. Please assign another template as the default before deleting the current default template.',
    careJourneyAssigned: 'CARE JOURNEY ASSIGNED',
    nameCapital: 'NAME',
    noUnassignedMembers: 'No Unassigned Members',
    showAllMembers: 'Show All Members',
    viewMore: 'View More',
    syncAgain: 'Sync Again',
    syncErrorDetails: 'Sync Error Details',
    patientHieVerificationMessage: 'Verification of an import item failed with an error.',
    invalidDate: 'Please enter a valid date of birth.',
    costTemplateOurCostInfo: 'If Our Cost is Not Added , we consider it as zero(0) for calculation',
    assignedJourneys: 'Assigned Care Journeys',
    okay: 'Okay',
    inviteVia: 'Invite via',
    linkedWith: 'Linked with',
    callTrail: 'Call Trail',
    openPhoneTree: 'View Phone Tree',
    incomingCall: 'Incoming Call',
    callAnswered: 'Call Answered',
    dialNodeExecuted: 'Dial Node Executed',
    patientType: 'Membership Status Node Executed',
    menuNodeExecuted: 'Menu Node Executed',
    sayNodeTriggered: 'Say Node Triggered',
    abandonedCall: 'Abandoned Call',
    transcription: 'Transcription',
    recording: 'Recording',
    searchMsgPlaceholder: 'Search in message thread',
    loadMoreMessage: 'Load More Messages',
    noMsgFound: 'No Message Found',
    searchMessage: 'Search in message thread',
    noConversationFoundInThread: 'Looks like there are no conversation found',
    startSearchInMsgThread: 'Start typing in the search box to find relevant messages in this thread',
    searchInThread: 'Search in thread',
    aviodDrawerForSameConversation: 'Drawer view unavailable: Chat already open',
    recurenceType: 'Recurrence type',
    invalidEmailError: 'Please enter a valid email address',
    resourceBlockedError: 'The requested action has been disabled by your practice.',
    totalInvited: 'Total Invited',
    totalMembers: 'Total Members',
    membersWillbeAddedToSeries: 'Members will be added to the series',
    inviteMembers: 'Invite Members',
    addMembers: 'Add Members',
    appointmentUpdateInProgress: 'Appointment update in progress. Please check back in some time.',
    inboxNameNotValid: 'The inbox name is not valid',
    emailNotValid: 'The email ID is not valid',
    authCodeNotReceived: 'Authentication code is not received',
    agentsNotSelected: 'The inbox agents are not selected',
    inboxEmail: 'Inbox Email',
    addAgents: 'Add Agents',
    cc: 'Cc',
    bcc: 'Bcc',
    templateCategories: 'Template Categories',
    templateList: 'Template List',
    recipientSelectPlaceholder: 'Search or enter recipient',
    emailInboxNotSelected: 'Inbox is not selected',
    invalidSubject: 'Subject is not present',
    recipientNotSelected: 'Recipient is not selected',
    templateNotSelected: 'Template is not selected',
    searchRecipient: 'Search Recipients',
    replyAll: 'Reply All',
    forward: 'Forward',
    composeEmail: 'Compose Email',
    addNewContact:'+ Add new contact',
    reLoginToOutlook: 'Re-login to Outlook',
    doItLater: 'Do it later',
    expiredInboxAlertText: 'One or more inboxes have expired tokens. Please refresh your tokens to continue accessing your inboxes.',
    expiredInboxAlertTitle: 'Inbox Token Expiry Notification',
    checkNow: 'Check Now',
    filterByNoteStatus: 'Filter by Status',
    filterByUser: 'Filter by User',
    filterByNoteFields: 'Filter by Note Fields',
    filterByNoteDate: 'Filter by Date',
    noteActionCompletedButNotVisible: 'Note action successful. Note might not appear due to applied filters',
    noteCreatedButNotVisible: 'Note has been created but is not visible. Please check applied filters.',
    noteUpdatedButNotVisible: 'Note has been updated but is not visible. Please check applied filters.',
    inboxPhoneNumber: 'Phone Number',
    inboxDetails: 'Inbox Details',
    searchLabel: 'Search Label',
    noUserFound: 'No user found',
    play: 'Play Node Triggered',
    addCustomField: 'Add Custom Field',
    visibleIn: 'Visible In',
    showInPatientApp: 'Show in patient app',
    customFieldAddedMsg: 'Custom field added successfully',
    customFieldUpdateMsg: 'Custom field updated successfully',
    statusUpdatedSuccessfully: 'Status updated successfully',
    updateCustomField: 'Update Custom Field',
    deletedSuccessfully: 'Deleted Successfully',
    phoneTreeClonedSuccessfully: 'Phone Tree Cloned Successfully',
    phoneTreeDeletedSuccessfully: 'Phone Tree Deleted Successfully',
    phoneTreeCreatedSuccessfully: 'Phone Tree Created Successfully',
    updateSomeContent: 'Please update some content',
    userPreferencesSaveError: 'Error in saving user preferences',
    userPreferenceFetchFailed: 'Fetching user preferences failed',
    errorFetchingAppointmentTypes: 'Fetching appointment types failed',
    selectCustomField: 'Select Custom Field',
    contactTypes: 'Contact Types',
    createCustomField: 'Create Custom Field',
    createFirstCustomField: 'Create First Custom Field',
    onCreateFirstCustomFieldMessage: 'Message',
    searchUser: 'Search User',
    searchTaskPool: 'Search Task Pool',
    turnOnNotification: 'Turn on Notification',
    turnOnNotificationSubTitle: 'Get notifications regarding clinical communications, appointment notifications & stay in loop with clinical updates',
    mayBeLater: 'Maybe Later',
    encounterNotesFilterMessage: 'Encounter notes can only be filtered based on created date, signed date and created status',
    linkNote: 'Linked Note',
    linkNoteAdditionalDetail: 'Case Note Details',
    mandatoryNoteTitleFieldError: 'Title is mandatory.',
    deleteNoteLinkTaskConfirmation: 'Deleting this task will unlink it from associated note, but the notes will still persist. Do you wish to continue?',
    deleteLinkTaskNote: 'Deleting this note will also remove the linked task associated with it. Are you sure?',
    completeTaskLinkNoteMessage: 'Linked note has not been signed yet. Please sign the note in order to close this task.',
    writeBackInfoMessage: "Any data added or updated in the note health components will automatically sync to the patient record once the note is signed.",
    importFromChart: 'Import from Patient Record',
    importFromChartOverrideInfo: 'Importing from chart will override the manually entered data in below fields',
    addNewRowToIntervention: 'Add New Row To Intervention',
    signedOrAmmended: 'Signed/Amended',
    created: 'Created',
    responded: 'Responded',
    onlyActiveFieldMsg: ' (only active field will be searched)',
    filterByCustomField: 'Filter by Custom Field',
    customField: 'Custom Field: ',
    customFiledFilterMsg: 'Your filters will only show results that meet ALL the condition you set',
    taskIsDeleted: 'This task does not exist',
    inAdvance: 'in advance',
    intoTheFuture: 'into the future',
    inTheFuture: 'in the future',
    atLeast: 'at least',
    and: 'and',
    externalId: 'Member Id',
    sendProviderRequestForm: 'Send Provider Request Form',
    identifyingThePatientMessage: 'We are identifying the patient, please wait',
    patientIdentified: 'Patient Identified',
    patientNotFoundError: 'No matches found. Please check the information and try again.',
    patientIdentifierMessage: 'Information will not be saved if you close/refresh the form.',
    formPatientIdentifierComponentError: 'Patient Identifier custom component can only be added to Provider Request Form category.',
    consentRequired: 'consent required',
    notCreatingTaskForLeads: 'Tasks will only be created for Members and not Leads/Prospects',
    tagAdded: 'Tag added successfully',
    tagUpdated: 'Tag updated successfully',
    tagDeleted: 'Tag deleted successfully',
    tagDeleteMsg: 'This action will untag all package(s) associated with this tag and permanently remove the tag. This action cannot be undone.',
    tagDeleteConfirmation: 'Are you sure you want to proceed?',
    confirmationRequired: 'Confirmation Required',
    labelDeleteMsg: 'This action will delete the label and remove it from all existing task(s) that it has been added to. This action cannot be undone.',
    conversationDeleteMsg: 'This action will untag all conversation(s) with this tag and remove the tag. This action cannot be undone.',
    selectContactType: 'Please select contact type',
    fileNameShouldNotContainAnySpecialCharacters:"File name should not contain any special characters.",
    clickHereToUploadFile:"Click here to upload file",
    maxContactNoteError: 'Count cannot exceed the limit of 8000 characters.',
    consentToEmail: 'Consent To Email',
    consentToText: 'Consent To Text',
    consentToCall: 'Consent To Call',
    billingPractitioner: 'Billing Practitioner',
    eligibleFor: 'Care Programs',
    scheduleF2F: 'CCM Visit',
    careProgramConsent: 'Get Consent',
    careProgramAssessment: 'Send  Assessment',
    createPlan: 'Create Plan',
    ccmEnabled: 'CCM Eligible',
    selectFromMedia: 'Select From Media',
    uploadNew: 'Upload New',
    attachFiles: 'ATTACH FILES',
    createPackageTag: 'Create Tag',
    createFirstPackageTag: 'Create First Packages Tag',
    onCreateFirstPackageTagMessage: 'Message',
    addPackageTag: '+ Add As New',
    signing: 'Signing',
    displayToMember: 'Display to Member',
    displayToMemberInfoMessage: 'Enabling this option will make this note accessible to this member on their apps. Please note that this option will override the note template display setting if configured otherwise.',
    displayToMemberOnMessage: 'Notes will be displayed to member after signing',
    shareWithPatient: 'Share with Patient',
    displayedToMember: 'Displayed to Member',
    patientIdentifierHealthComponentError: 'Health components cannot be added to Provider Request Form category.',
    faxTab: 'eFax',
    sendFax: 'Send e-Fax',
    addPatientContext: 'Add Patient Context',
    phiWarningAddToPatientProfile: 'Files may contain PHI data. Add to patient carefully',
    selectPatient: 'Select Patient',
    selectFolder: 'Select Folder',
    eFaxNumber: 'E-Fax Number',
    updateDetails: 'Update Details',
    assignedCollaborators: 'Assigned Collaborators',
    purchasedNumber:'Purchased Number',
    getEFax: 'Get e-Fax',
    newFax: 'New Fax',
    commentSaveOrCancelMsg: 'Please comment or cancel the unsaved comment',
    limitCancelOrRescheduleTime: 'Limit cancel or reschedule time',
    inviteesCanCancel: 'Invitees can cancel',
    inviteesCanReschedule: 'Invitees can reschedule',
    tillTheTimeOfAppointment: 'Till the time of appointment',
    before: 'Before',
    beforeAppointmentTime: 'before appointment time',
    taskAttachmentUploadSupportAndSize: 'Supports PDF, PNG, JPG, DOC, DOCX. Max file size 5 MB through device upload',
    documentAttachmentUploadSupportAndSize: 'Supports PDF, PNG, JPG, JPEG, DOC, DOCX. Max file size 20 MB through device upload',
    documentAttachmentUploadImagingReportSupportAndSize: 'Supports PDF, PNG, JPG, JPEG, Max file size 20 MB through device upload',
    selectSlotError: 'You can select a slot within the next $bookWithinLimit but not before $bookWithinOffset from now.',
    upto: 'Upto',
    attributes: 'Attributes',
    efaxDocumentsUploadSupportAllFiles:'You can add up to 10 documents or up to 20MB',
    addedPatientContext: 'Added Patient Context',
    eFaxNotSupportedText: 'Sending e-fax is currently not supported on mobile devices.',
    sendAnotherEFax: 'Send Another e-Fax',
    invited: 'Invited',
    removeMemberConfirmation: 'Member will be removed from all appointments in the series. Do you wish to continue',
    deleteInviteConfirmation: 'Invite will be deleted from all appointments in the series. Do you wish to continue?',
    memberActionsWillApplyToWholeSeriesMessage: 'All member actions performed will be applicable to all appointments in the series.',
    eFaxSentMessage: 'eFax sent successfully',
    noConversationError: 'You can start the conversation with patient',
    insights: 'Insights',
    assessment: 'Assessment',
    sentOn: 'Sent On',
    trend: 'Trend',
    versionHistory: 'VERSION HISTORY',
    currentVersion: 'Current Version',
    taskAnalyticsIntroMessage:
      'Optimize task tracking and workload management with insights for enhanced efficiency.',
    supportedMediaText: 'We support all image, video, audio, and PDF formats.',
    supportImageText: "We support all image formats.",
    forwarded: 'Forwarded',
    leaveGroupSuccessMsg: 'Left Group Chat Successfully',
    oneInternalUserPresentErrorForUpdate: `Group cannot be updated as there's only one internal user present`,
    oneInternalUserPresentError: `Group cannot be exited as there's only one internal user present`,
    twoInternalUserPresentError: `Interal chat group cannot be exited as there's only two internal user present`,
    eFaxFromPatientProfile: 'This eFax was sent from patient profile. Patient context cannot be updated.',
    userAddedSuccessfully: 'User Added Successfully',
    assessments: 'Assessments',
    activeCareJourneys: 'Active Care Journeys',
    lastSentOn: 'Last Sent',
    lastSubmittedOn: 'Last Submitted',
    clinicalFormCategoryNote: 'Only forms belonging to the "Clinical Assessment" category on Fold are considered under assessments',
    leaveChat: 'Leave Chat',
    error: 'Error',
    sendNoteCategoryFormError: 'You cannot send a Note category form to patients/leads.',
    sendProviderRequestFormError: 'You cannot send a ‘Provider Request Form’ category form to patients/leads.',
    enroll: 'Enroll',
    unMarkAsPCP: 'Unmark as PCP',
    markAsPCP: 'Mark as PCP',
    markAsBillingPractioner: 'Mark as Billing Practitioner',
    unMarkAsBillingPractioner: 'Unmark as Billing Practitioner',
    markAsCareManager: 'Mark as Care Manager',
    unMarkAsCareManager: 'Unmark as Care Manager',
    filterByAssignedWith: 'Filter by Assigned To',
    CareProgramBilling: 'Billing',
    totalUnbilledAmount: 'Total Unbilled Amount',
    totalBilledAmount: 'Total Billed Amount',
    unbilledAmountInfo: 'Total Unbilled Amount is the sum total of all the individual bills of all the listed patients that could be sent to EHR for billing.',
    ehrId: 'EHR ID',
    dateOfService: 'DATE OF SERVICE',
    programType: 'PROGRAM TYPE',
    timeSpent: 'TIME SPENT',
    bill: 'BILL',
    provider: 'PROVIDER',
    chronicCondition: 'CHRONIC CONDITION',
    memberNameCapsOn: 'MEMBER NAME',
    chronicConditionSmall: 'Chronic Condition',
    dateOfServiceSmall: 'Date of Service',
    providerSmall: 'Provider',
    programTypeSmall: 'Program Type',
    billingDetails: 'Billing Details',
    billedUnder: 'Billed Under',
    medicalDecisionMaking: 'Medical Decision Making',
    F2FVisitDate: 'F2F Visit Date',
    applicableCptCode: 'APPLICABLE CPT CODE',
    amountCapsOn: 'AMOUNT',
    monthlyReport: 'MONTHLY REPORT ',
    estBillingAmount: 'EST. BILLING AMOUNT',
    totalPatients: 'TOTAL PATIENTS',
    integratedEhr: 'INTEGRATED EHR',
    actionCapsOn: 'ACTION',
    apiErrorBillableActvities: 'Error in fetching billable activities',
    apiErrorBilledActivitiesByMonth: 'Error in fetching billed activities by month',
    apiErrorInFetchingAmountSum: 'Error in fetching amount sum',
    viewBillingReport: 'View Billing Report',
    ccmEnrollment: 'CCM Enrollment',
    ccmOutreach: 'CCM Outreach',
    apiErrorAttributesAndCodes: 'Error in fetching attributes, code and duration',
    selectedBillableAmount: 'Selected Billable Amount',
    sendClaimTOEhr: 'Send Claim To EHR',
    errorInProcesssingClaims: 'Error in processing claims. Please try again later',
    claimSentSuccessfully: 'Claim Sent Successfully',
    loadMore: 'Load More',
    notBefore: 'not before',
    noGoalList: 'No Goals',
    assignToPatient: 'Assign to Patient',
    comprehensiveCarePlan: 'Comprehensive Care Plan',
    lastFormScore: 'Last Score',
    carePlan: 'Care Plan',
    carePlanTemplates: 'Care Plan Templates',
    entityType: 'Entity Type',
    relevant: 'Relevant',
    labTrends: 'Lab Trends',
    surveyAssessments: 'Surveys/Assessments',
    searchTags: 'Search Tags',
    visibleOnlyToYourTeam: 'Visible only to your team',
    visibleOnlyToYourTeamShiftEnterToSendTheMessage: 'Visible only to your team • Shift + Enter to send the message',
    visibleOnlyToYourTeamShiftEnterToStartANewLine: 'Visible only to your team • Shift + Enter to start a new line',
    shiftEnterToSendTheMessage: 'Shift + Enter to send the message',
    shiftEnterToStartANewLine: 'Shift + Enter to start a new line',
    visibleToEveryone: 'Visible to everyone',
    visibleToEveryoneShiftEnterToSendTheMessage: 'Visible to everyone • Shift + Enter to send the message',
    visibleToEveryoneShiftEnterToStartANewLine: 'Visible to everyone • Shift + Enter to start a new line',
    pressEnterToStartANewLine: 'Press Enter to start a new line',
    pressEnterToSendTheMessage: 'Press Enter to send the message',
    sendTheMessage: 'Send the message',
    startANewLine: 'Start a new line',
    change: 'Change',
    scoreGroupLabel: 'Score Group:',
    scoreInterpretationLabel: 'Score Interpretation:',
    scoreLabel: 'Score:',
    enrollmentDate: 'Enrollment Date',
    dischargeTo: 'Discharge To',
    dischargeFrom: 'Discharge From',
    facility: 'Facility',
    facilityType: 'Facility Type',
    admittedDate: 'Admitted Date',
    careTeamManager: 'Care Manager',
    carePlanStatus: 'Care Plan Status',
    healthPlan: 'Health Plan',
    dischargeDate: 'Discharge Date',
    awvDate: 'AWV Date',
    fromTheCurrent: 'from the current time',
    allConversationsChat: 'All Conversations/Chats',
    addUpdateLeadCancelMsg: `Are you sure you want to cancel? Your changes won't be saved.`,
    automationClonedSuccessfully: 'Automation Cloned Successfully',
    automationDeletedSuccessfully: 'Automation Deleted Successfully',
    automationCreatedSuccessfully: 'Automation Created Successfully',
    insert: 'Insert',
    emailDiscardAlert: 'Are you sure you want to discard this email?',
    runCampaign: 'Run Campaign',
    abort: 'Abort Campaign',
    aborted: 'Aborted',
    successfullyExecuted: 'Successfully executed',
    runTheCampaign: 'Run the Campaign',
    abortTheCampaign: 'Abort this Campaign?',
    runCampaignAlertMessage: 'Are you sure you want to start this process? Once started, it cannot be paused. If you proceed and then decide to stop, the entire process will be aborted and cannot be resumed or undone.',
    abortCampaignAlertMessage: 'Caution: If you proceed to abort this campaign, it will be permanently halted, and this action cannot be reversed or resumed',
    logout: 'Logout',
    noBarrierList: 'No Barriers',
    noIntervention: 'No Intervention',
    isShownToPatientTooltip: 'Patient Visibility: Enabled',
    groupAppointmentMemberLimitExceeded:
      'Member limit for this group appointment exceeded',
    editLabel: 'Edit Label',
    deleteLabel: 'Delete Label',
    deleteTag: 'Delete Tag',
    noPreviewAvailable: 'No Preview Available',
    errorOpeningFile: 'Error while opening file',
    totalAppointmentCount: 'Total Appointment Count',
    newlyAdded: 'Newly Added',
    droppedOff: 'Dropped Off',
    showLess: 'Show less',
    noHistoryCreated: 'No History Created',
    noCarePlan: 'No care plan assigned.',
    carePlanSavedSuccessMsg: 'Care plan saved successfully',
    carePlanAssignedSuccessMsg: 'Care plan assigned successfully',
    last24hrs: 'Last 24 hrs',
    groupAuditMessage: 'This is footer of population group audit',
    printShareable: 'Print Shareable',
    signAndPrintShareable: 'Sign & Print Shareable',
    startDateNotAssigned : 'Start Date not Assigned',
    composeWithUnityAI: 'Compose with Unity AI',
    composeEmailWithUnityAI: 'Compose email with Unity AI',
    alerts: 'Alerts',
    alert: 'Alert',
    errorInFetchingData: 'Oops! Unable to Fetch the Data',
    poweredByUnity: 'Powered by Unity',
    alpha: 'ALPHA',
    beta: 'BETA',
    patientSynopsis: 'Patient Synopsis',
    healthMap: 'Health Map',
    analyzingHealth:'Analyzing Health',
    analyzingReports:'Analyzing Reports',
    synthesizingLearnings:'Synthesizing Learnings',
    creatingHealthMap:'Creating Health Map',
    analyzingMedications:'Analyzing Medications',
    analyzingProcedures:'Analyzing Procedures',
    curatingSynopsis:'Curating Synopsis',
    monitoringVitalSigns:'Monitoring Vital Signs',
    analyzingImagingLabResults:'Analyzing Imaging & Lab Results',
    detectingAlerts:'Detecting Alerts',
    seeMore: 'See More',
    seeLess: 'See Less',
    leaveGroupPopoverTitle: 'Change Group Owner and Leave',
    unassignAndLeftGroup: 'Unassigned and Left Group Chat Successfully',
    reportName: 'Report Name',
    createNewReport: 'Create New Report',
    outlookPermissionError: 'It seems we do not have the necessary permission. Please try again and allow permissions in Outlook to set up the Inbox in Fold.',
    visitSummary: 'Care Utilization Summary',
    careActivityFeed: 'Care Activity Feed',
    introducingBulkTaskUpdate: 'Introducing Task Bulk Update',
    bulkTaskIntroMsg: 'Now you can perform bulk operations by selecting multiple tasks at once.',
    activityTimer: 'Activity Timer',
    logTimeManually: 'Log Time Manually',
    logTime: 'Log Time',
    timeSpentOnPatient: 'Time Spent',
    performedBy: 'Performed By',
    performedOn: 'Performed On',
    startTime: 'Start Time',
    ccmActivity: 'CCM Activity',
    newLocation: 'New Location',
    ehrUser: 'EHR User',
    practiceLabel: 'Practice',
    homeMonitoring: 'Home Monitoring',
    lastRecordedOn: 'Last Recorded',
    noDataAvailable: 'No Data Available',
    convertibleToPatient: 'Convertible to patient',
    convertibleToPatientTooltipMsg: 'Changing this will not be effective to patients who have already been converted from this contact type',
    noConversationForPatient: ' No conversation found with this patient',
    joinDefaultGroup: 'Join Default Group Chat',
    postPrescriptionOfCareJourney: 'Post Prescription of Care Journey',
    smsLengthInfoText: 'SMS body length is limited to 480 characters',
    noCarePlanTemplatesSelected: 'No care plan template selected',
    carePlanDetails: 'Care Plan Details',
    modifyCarePlan: 'Modify Care Plan',
    addNewCarePlan: 'Create Care Plan',
    assignedToPatient: 'Assigned to patient',
    filterPackage: 'Filter Package',
    tagSyncMessage: 'This tag is linked to multiple packages. Changing its name/color will update it across all associated packages',
    labelSyncMessage: 'This label is linked to multiple packages. Changing its name/color will update it across all associated packages',
    sidecarUserMismatchMessageLine1: 'There is a mismatch between the EHR user and the Fold Sidecar user.',
    sidecarUserMismatchMessageLine2: 'Please ensure you log in with the same user credentials for both.',
    loginMismatchTitle: 'Login Mismatch',
    understood: 'Understood',
    messageCopiedToClipboard: 'Message copied to clipboard',
    messageCopiedToClipboardForClinicalNote: 'Message copied to clipboard without emojis, as they are not supported for the clinical notes',
    replyEmailWithUnityAI: 'Reply with Unity AI',
    sendForReview: 'Send for Review',
    inReview: 'In Review',
    linkedCarePlan: 'Linked Care Plan',
    carePlanInReviewMsg: 'Associated Care Plan is In Review',
    startFrom: 'Start From',
    dosage: 'Dosage',
    stop: 'Stop',
    costTemplateUpdatedSuccessfully: 'Cost template updated successfully',
    costTemplateCreatedSuccessfully: 'Cost template created successfully',
    costTemplateUpdatedError: 'Error in Cost template update',
    costTemplateCreatedError: 'Error in Cost template create',
    analyticsSyncMessage: 'The analytical data sync happens every 4 hours',
    savingCareTeam: 'Saving Care Team...',
    searchTemplate: 'Search Template',
    accountDoNotHaveDomain: 'Account Domain not exits',
    visitType: 'Visit Type',
    enterValidSubdomain: 'Subdomain not found. Please enter a valid subdomain.',
    subdomainAccountNotAvail: 'This subdomain account is not associated with this email.',
    ssoMultipleSubdomainAlert: `We have detected multiple accounts associated with this email. To proceed, please append the account subdomain name with a '#' at the end of this email. For example,\nabc@fold.health#xyz.foldhealth.io`,
    showMore: 'Show More',
    noTemplateFound: 'No care plan template found',
    consentMessage:'Patient consent',
    consentMessageCheck:'The patient must consent to and agree to the following terms',
    carePlanSentForReviewSuccessMsg: 'Care plan sent for review successfully',
    licensedStates: 'Licensed States',
    clinicalNoteTemplateChanged: 'Template changed. Message content is copied to your clipboard.',
    selectProviderForReview: 'Select Providers for Care Plan Review',
    preferenceSuccess: 'User preferences saved successfully',
    userPreference: 'User Preferences',
    emailInboxView: 'EMAIL INBOX VIEW',
    emailInboxPreferenceMsg: 'Choose the default view for your email inbox to display specific types of messages.',
    pinned: 'Pinned',
    contactPhoneNumberDeleted: `The contact's phone number associated with the log has been deleted.`,
    assignedWith: 'Assigned To',
    carePlanAssessmentFormFetchError: 'Error in fetching care plan assessment form',
    recordingTimeRoundedOffInstruction: 'Recording time is rounded to the nearest minute. eg. 15 sec -> 1 min',
    fetchDataError: 'Error while fetching data',
    completeTaskError: 'Error while marking task complete',
    missedTaskError: 'Error while marking task missed',
    incompleteTaskError: 'Error while marking task incomplete',
    editNote: 'Edit Note',
    familyHxDataNotRetrieved: 'Some data could not be retrieved from the EHR',
    bioMarker: 'Biomarkers',
    phiWarningForEmail: 'Do not send PHI via email or text without verifying and obtaining consent.',
    noPinnedWidgets: 'No Pinned Widgets',
    patientOutReachAttempts: 'Patient Outreach Attempt',
    wearables: 'Wearables',
    otherDocLoading: 'Unable to open this document as another is currently loading.',
    patientCaseDetail: 'Patient Case Detail',
    referralDetail: 'Referral Detail',
    assigned: 'Assigned',
    carePlanHistory: 'Care Plan History',
    biomarkers: 'Biomarkers',
    enableVitalsForIngestion: 'Enable the Vitals that should be ingested for the Account',
    supportedDevices: 'Supported Devices',
    wearableDevicesList: 'Apple Health, Google Fit and more ...',
    chosseBiomarkerAndActivityData: 'Choose the biomarker and activity data that will show up in the patient chart.',
    assessmentFormSubmittedInstruction: 'Patient self-assessment submitted, review for creating or updating care plan.',
    templateCreatedMsg : 'Patient Synopsis Template Created Successfully',
    templateUpdatedMsg : 'Patient Synopsis Template Updated Successfully',
    disableBulkArchive: 'You can archive up to 500 conversations at once',
    messageForTaskPage : 'You are already in patient profile',
    selectAtleasetOneInternalUserError: 'Select at leaset one internal user',
    lastRead: 'Last Read',
    videoMeeting: 'Video Meeting',
    matchToData: 'Match To Data',
    patientDeclined: 'Patient Declined',
    useThisForANameForEmailRecipients: 'Use this for a name for Email recipients',
    collaborators: 'Collaborators',
    fileDownloadPhiAlert: 'This file may contain Protected Health Information (PHI). Are you sure you want to proceed with the download?',
    like : 'Like',
    dislike : 'Dislike',
    syncWithEhr: 'Sync with EHR',
    noteSentToEhr: "This note will be sent to the EHR",
    initialPopulationInfoMsg: 'Choose initial population to refine patient criteria and enhance rule precision',
    disabledMemberStatusMsg: 'Membership status will be same as that of the initial population',
    disabledExecutionFrequencyMsg: 'Execution Frequency will be same as that of the initial population',
    initialPopulation: 'Initial Population',
    pendingTaskMsg: 'Pending Tasks comprise Missed and Pending.',
    endDateTime: 'End Date Time',
    sortBywithcolon: 'Sorted By:',
    ascending: 'Ascending',
    descending: 'Descending',
    changesNotSavedMsg: 'Changes will not be saved',
    linkedAutomation: 'Linked Automation',
    notSavedMsg: 'Not Saved',
    outreachCompletedByAutomationMsg: 'Outreach completed by automation cannot be marked as incomplete',
    noLinkedReports: 'No Linked Reports',
    dismissAlert: 'Dismiss Alert',
    selectReasonToDismissALert: 'Please select a reason and add a corresponding note for dismissing the alert.',
    selectReasonToResolveALert: 'Please add a reason for resolving the alert. You can select one of the below reasons or add one of your own.',
    alertDismissed: 'Alert Dismissed',
    select: 'Select',
    alertResolved: 'Alert Resolved',
    formSentSuccessully: 'Form Sent Successfully',
    markAsResolved: 'Mark as Resolved',
    contentSentSuccessfully: 'Content Sent Successfully',
    sessionExpiredReLogin: 'Your session has expired. Please re-Login to continue.',
    popGroupFrequencyMessage: 'We are scanning through your population as per conditions selected for your group. Please check back within next',
    convertToPatient: 'Convert To Patient',
    requiresAction: 'Requires Action',
    pickAgentsForTheInbox:'Pick agents for the inbox',
    addOrRemoveAgents: 'Add or remove agents',
    noInboxMessage: 'Inbox number is not assigned to this user. Please go to the fold account and assign the inbox to the appropriate users.',
    foundInEhr: 'Found In EHR',
    imagingDocSupportingFilesMsg:'Please upload supporting files only (PNG, JPG, JPEG, PDF)',
    allDocumentsSupportingFilesMsg:'Please upload supporting files only (PNG, JPG, JPEG, PDF, DOC, DOCX)',
    noTemplatesFound: 'No template found',
    setAsDefault: 'Set as default',
    locationAndRoles: 'Location and Roles',
    noLocationMappedToUserProfile: "No location is mapped to your profile",
    gloabalAdmin: 'Gloabal Admin',
    globalRoles: 'Global Roles',
    sleepStages: 'Sleep Stages'
  },
};
