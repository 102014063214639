import React, {useContext, useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {
  GET_SINGLE_USER,
  SEARCH_USERS_BY_NAME_AND_ROLE_CODES,
} from '../../../../../services/User/UserQueries';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
} from '../../../../../constants/MlovConst';
import {getProviderSearchWhereCondition} from './utils';
import {debounce} from 'lodash';
import {SearchBar} from '../../../../common/SearchBar';
import {
  Divider,
  FlatList,
  Pressable,
  Skeleton,
  Text,
  VStack,
  View,
  useToast,
} from 'native-base';
import Stack from '../../../../common/LayoutComponents/Stack';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {IUser} from '../../../../../Interfaces';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {Colors} from '../../../../../styles';
import {NoDataFound} from '../../../../common/NoDataFound';
import {useIntl} from 'react-intl';
import {GET_CARE_TEAM_MEMBER_TYPE} from '../../../../../services/CareTeam/CareTeamQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { ICareTeamMemberType } from '../../../../common/MemebersView/interface';

interface IProviderSelectForReviewProps {
  onChange: (value: string) => void;
  selectedProviderId: string;
  onCancel: () => void;
  contactId: string;
}

const ProviderSelectForReview = (props: IProviderSelectForReviewProps) => {
  const intl = useIntl();
  const {onChange, onCancel, contactId} = props;
  const [users, setUsers] = useState<IUser[]>([]);
  const [pcpUser, setPcpUser] = useState<IUser | undefined>(undefined);
  const [componentLoading, setComponentLoading] = useState(false);
  const toast = useToast();

  const commonContextData = useContext(CommonDataContext);

  const [getUsersByRoles, {loading: searchLoading}] = useLazyQuery(
    SEARCH_USERS_BY_NAME_AND_ROLE_CODES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setUsers(data?.users || []);
      },
    }
  );

  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  const careTeamMlovList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const primaryCareTeamTypeId = getMlovIdFromCode(careTeamMlovList, CARE_TEAM.CODE);

  const fetchInitData = async () => {
    try {
      setComponentLoading(true);
      const careTeamMemberTypeResponse = await getCareTeamMemberByMemberType({
        variables: {
          contactIds: contactId,
          careTeamTypeId: primaryCareTeamTypeId,
        },
      });
      const userUUID =
        careTeamMemberTypeResponse?.data?.careTeams[0]?.careTeamMemberType?.find(
          (careTeamMember: ICareTeamMemberType) => {
            return (
              careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP
            );
          }
        )?.userId;

      const promiseList: Promise<any>[] = [];
      promiseList.push(
        getUsersByRoles({
          variables: {
            where: getProviderSearchWhereCondition('', userUUID),
          },
        })
      );

      if (userUUID) {
        promiseList.push(
          getSingleUserById({
            variables: {
              userId: userUUID,
            },
          })
        );
      }

      const [userByRolesResponse, pcpUserDataResponse] =
        await Promise.all(promiseList);

      if (userUUID) {
        const pcpUser = pcpUserDataResponse?.data?.users?.[0];
        setPcpUser(pcpUser);
      }

      const users = userByRolesResponse?.data?.users;
      setUsers(users);
      setComponentLoading(false);
    } catch (error) {
      onCancel();
      setComponentLoading(false);
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error
      );
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  const fetchUsers = (value: string, uuid: string | undefined) => {
    getUsersByRoles({
      variables: {
        where: getProviderSearchWhereCondition(value, uuid),
      },
    });
  };

  const renderUserItem = ({item}: {item: IUser}) => {
    const roleString = item?.userRoles
      ?.map((role) => role?.userRole?.userRole?.value)
      .filter((role) => !!role?.length)
      .join(', ');
    return (
      <Pressable
        onPress={() => onChange(item?.uuid || '')}
        flexDirection="row"
        style={{
          marginVertical: 8,
        }}
      >
        <DisplayCardAvatar
          avatarStyle={{
            width: 36,
            height: 36,
            disableTop: true,
            textStyle: {
              fontSize: 16,
            },
          }}
          userData={{
            userType: GROUP_MEMBER_TYPE.USER,
            userId: item?.userId || '',
            name: item?.name,
            userName: item?.name,
            imgSrc: '',
          }}
          isLetterAvatarShow
        />
        <Stack
          direction="column"
          style={{
            marginLeft: isWeb() ? 10 : 8,
            width: isWeb() ? '80%' : '100%',
          }}
        >
          <Text color={Colors.Custom.Gray700} fontWeight={'400'}>
            {item?.name}
          </Text>
          <Text
            fontSize={12}
            fontWeight={'400'}
            color={Colors.Custom.Gray500}
            maxW={'90%'}
            isTruncated
          >
            {roleString}
          </Text>
        </Stack>
      </Pressable>
    );
  };

  const renderMainListView = () => {
    if (searchLoading) {
      return (
        <View style={{marginVertical: 12}}>
          <Stack direction="column" space={4}>
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
          </Stack>
        </View>
      );
    }

    return (
      <FlatList
        mt={2}
        maxH={400}
        data={users}
        keyExtractor={(item) => item?.uuid || ''}
        ListEmptyComponent={
          <View m={10}>
            <NoDataFound displayString="noUsersFound" />
          </View>
        }
        renderItem={renderUserItem}
      />
    );
  };

  return (
    <View style={{maxHeight: '65vh'}}>
      <Text fontSize={16} color={Colors.Custom.Gray500} mb={2}>
        {intl.formatMessage({id: 'selectProviderForReview'})}
      </Text>
      <SearchBar
        placeholderText="Search Users"
        onChange={debounce((value) => fetchUsers(value, pcpUser?.uuid), 200)}
      />
      {componentLoading ? (
        <Stack direction="column" space={4} style={{
          marginTop: 4
        }}>
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
        </Stack>
      ) : (
        <VStack mt={2} flex={1}>
          {!!pcpUser && (
            <>
              <View mb={2}>
              <Text color={Colors.Custom.Gray500} fontWeight={'400'}>
                {'PRIMARY CARE PHYSICIAN'}
              </Text>
              {renderUserItem({item: pcpUser})}
              <Divider />
              </View>
              <Text color={Colors.Custom.Gray500} fontWeight={'400'}>
                {'OTHER PROVIDERS'}
              </Text>
            </>
          )}
            {renderMainListView()}
        </VStack>
      )}
      <View marginLeft={4} style={{minHeight: 50}}>
        <Stack
          direction="row"
          space={4}
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <FoldButton
            customProps={{
              btnText: 'Cancel',
            }}
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: onCancel,
            }}
          />
        </Stack>
      </View>
    </View>
  );
};

export default ProviderSelectForReview;
