import {View, Text, Pressable} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../../styles';
import {IBarrier} from '../Barriers/interfaces';
import {Divider} from 'react-native-paper';
import { getMlovValueFromId } from '../../../../../../utils/mlovUtils';
import { IMlov } from '../../../../../../Interfaces';

const CustomListViewHealthComponent = (props: {
  dataSource: IBarrier[];
  getDeleteFunction: (item: IBarrier) => void;
  isHideDelete?: boolean;
  isHideBorder?: boolean;
  isShowSubtitle?: boolean;
  carePlanCategoryList: IMlov[]
}) => {
  const {dataSource, getDeleteFunction, isHideBorder, isShowSubtitle, carePlanCategoryList} = props;

  return (
    <View
      style={{
        borderRadius: 10,
        borderColor: Colors?.Custom?.Gray200,
        borderWidth: isHideBorder? 0 : 1,
        marginHorizontal: isHideBorder ? 0 : 10,
        paddingVertical: 2,
        marginBottom: 10,
      }}
    >
      {dataSource?.map((item, dataSourceIndex) => {
        const key = `${
          item.barrierId || item.uniqueId || item.title
        }_${dataSourceIndex}`;


        const categoryName =
          isShowSubtitle && item.categoryId
            ? getMlovValueFromId(carePlanCategoryList, item.categoryId)
            : '';

        return (
          <>
            <Stack
              direction="row"
              key={key}
              style={{
                flex: 1,
                borderBottomWidth:
                  dataSourceIndex !== dataSource.length - 1 ? 1 : 0,
                padding: 12,
                alignItems: 'center',
                borderColor: Colors?.Custom?.Gray200,
              }}
            >
              <Stack direction='column' style={{flex: 1}}>
                <Text
                  style={{flex: 1, alignItems: 'flex-start', flexWrap: 'wrap', color: Colors.Custom.Gray700}}
                >
                  {item?.title}
                </Text>
                {categoryName ? (
                   <Text
                    style={{
                      fontSize: 12,
                      width: 'fit-content',
                      paddingHorizontal: 6,
                      paddingTop: 2,
                      paddingBottom: 4,
                      color: Colors.FoldPixel.GRAY300,
                      backgroundColor: Colors.FoldPixel.GRAY50,
                      borderRadius: 4,
                      marginTop: 6
                    }}
                 >
                   {categoryName}
                 </Text>                 
                ) : (
                  <></>
                )}
              </Stack>

              {!props.isHideDelete && (
                <Pressable onPress={() => getDeleteFunction(item)}>
                  <Feather
                    name="trash-2"
                    color={Colors.Custom.Gray500}
                    size={18}
                  />
                </Pressable>
              )}
            </Stack>
          </>
        );
      })}
    </View>
  );
};

export default CustomListViewHealthComponent;
