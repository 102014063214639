import { Colors } from "../../../../../styles/Colors";
import { Box, Center, FlatList, Flex, HStack, Text, VStack, Button, Pressable, Spinner, } from 'native-base';
import { useIntl } from "react-intl";
import { Tooltip, Collapse } from 'antd';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { tooltipInnerOverlayStyle, tooltipOverlayStyle, tooltipPlacement } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import { getVisitSummary } from "../../../../../services/CommonService/AidBoxService";
import { VisitType, visitTypesWithCodes } from "./VisitSummaryConstants";
import { IVisitData, IVisitElement, IVisitInfo } from "./interfaces";
import { addMissingMonths, getVisitCode } from "./VisitSummaryHelper";
import { getCurrentYearEndtDate, getCurrentYearStartDate, getNextYearEndDate, getNextYearStartDate, getPreviousYearEndDate, getPreviousYearStartDate } from "../../../../../utils/DateUtils";

interface IVisitSummaryProps {
    personData: any
}

export const VisitSummary = (props: IVisitSummaryProps) => {
    const intl = useIntl()
    const { Panel } = Collapse;

    const [responseSummaryData, setResponseSummaryData] = useState<IVisitData[]>([]);
    const [visitSummaryData, setVisitSummaryData] = useState<IVisitElement[]>([]);
    const [filteredVisitSummaryData, setFilteredVisitSummaryData] = useState<IVisitElement[]>([]);
    const [graphHeight, setGraphHeight] = useState<number>(180);
    const [selectedVisitCode, setSelectedVisitCode] = useState<string>('all');
    const [fromDate, setFromDate] = useState(getCurrentYearStartDate())
    const [toDate, setToDate] = useState(getCurrentYearEndtDate())
    const [componentState, setComponentState] = useState<{
        activePanel: string[];
    }>({
        activePanel: [],
    });
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [loading, setLoading] = useState(false);
    const contactId = props.personData?.contactUUID

    const VISIT_SUMMARY = 'VISIT_SUMMARY';

    const getHeaderWithSubText = (header: string, dateText: string) => {
        return <VStack flexWrap={'wrap'} style={{ borderWidth: 0 }} flex={0.5}>
            <HStack flexWrap={'wrap'}>
                <Text style={{ fontSize: 16 }}>{header}{' : '}</Text>
                <Text style={{ fontSize: 16, color: Colors.FoldPixel.GRAY300 }}>{dateText}</Text>
            </HStack>
        </VStack>
    }

    const getBackgroundAndBorderColor = (visitType: string) => {
        let backgroundColor: string;
        let borderColor: string;

        switch (visitType) {
            case VisitType.OP:
                backgroundColor = '#D3FFF4';
                borderColor = '#00C595';
                break;
            case VisitType.ER:
                backgroundColor = '#E6DBFF';
                borderColor = '#6941C7';
                break;
            case VisitType.Speciality:
                backgroundColor = '#FFF1CB';
                borderColor = '#DD9F00';
                break;
            case VisitType.IP:
                backgroundColor = '#FFE4F8';
                borderColor = '#A60A7A';
                break;
            default:
                backgroundColor = '#F3F9ED';
                borderColor = '#8BC34A';
        }
        return { backgroundColor, borderColor }
    }

    useEffect(() => {
        getData()
    }, [fromDate])

    const getData = async () => {
        setLoading(true);
        const bodyData = {
            resourceCode: "VISITS_SUMMARY",
            contactId: contactId,
            appliedFilter: [
                {
                    filterCode: "DATE_RANGE",
                    filterValue: {
                        fromDate: fromDate,
                        toDate: toDate
                    }
                }
            ]
        };

        const summaryDataResponse = await getVisitSummary(bodyData)
        const summaryData = summaryDataResponse.data.result;
        if (summaryData.length !== 0) {
            setResponseSummaryData(summaryData)
            setLoading(false)
            const visitsGroupedByDate = getVisitsGroupedByMonthAndYear(summaryData)
            const chartDataWithMissingMonths: IVisitElement[] = addMissingMonths(visitsGroupedByDate, selectedYear.toString())
            setVisitSummaryData(chartDataWithMissingMonths)
            setFilteredVisitSummaryData(chartDataWithMissingMonths)
            const hasMoreThanThreeVisits = chartDataWithMissingMonths.some(item => item.visits.length > 3);
            if (hasMoreThanThreeVisits) {
                setGraphHeight(250)
            }
        }
        setLoading(false)
    };

    const getVisitsGroupedByMonthAndYear = (visits: any[]) => {
        const groupedVisits: any = [];

        visits.forEach(visit => {
            const date = new Date(visit.visitDate.value);
            const year = date.getFullYear();
            const month = date.toLocaleString('default', { month: 'long' });

            const key = `${month} ${year}`;
            const existingGroup = groupedVisits.find((group: any) => group.monthYear === key && group.monthYear.includes(selectedYear));

            if (existingGroup) {
                existingGroup.visits.push(visit);
            } else {
                // Create a new group object
                groupedVisits.push({
                    monthYear: key,
                    visits: [visit]
                });
            }
        });
        return groupedVisits;
    }

    const getUniqueVisitCodesWithTotalCount = (visits: IVisitData[]) => {
        const uniqueVisitCodes: IVisitInfo[] = [];

        visits.forEach((visit: any) => {
            const { visitCount } = visit;
            const visitCode = getVisitCode(visit.visitType);
            const existingVisitIndex = uniqueVisitCodes.findIndex(item => item.visitCode === visitCode);
            if (existingVisitIndex === -1) {
                // If not found, add a new object
                uniqueVisitCodes.push({
                    visitType: visit.visitType,
                    visitCode: visitCode,
                    totalVisitCount: visitCount
                });
            } else {
                uniqueVisitCodes[existingVisitIndex].totalVisitCount += visitCount;
            }
        });

        visitTypesWithCodes.forEach(code => {
            const existingVisitIndex = uniqueVisitCodes.findIndex(item => item.visitCode === code.visitCode);
            if (existingVisitIndex === -1) {
                uniqueVisitCodes.push({
                    visitType: code.visitType,
                    visitCode: code.visitCode,
                    totalVisitCount: 0
                });
            }
        });

        return uniqueVisitCodes;
    }

    const getVisitCountButtons = (visits: IVisitData[]) => {
        const visitCodes = new Set();
        const visitCountButtons: JSX.Element[] = [];
        const visitsGroupedByCode = getVisitsGroupedByVisitCode(visits)
        visitsGroupedByCode.forEach(visit => {
            visitCodes.add(visit.visitCode);
        });

        // Create buttons for each unique visitCode
        visitsGroupedByCode.forEach(visit => {
            const visitColors = getBackgroundAndBorderColor(visit.visitType)
            visitCountButtons.push(
                <Button onPress={() => {
                }} style={[style.visitTypeCount, { marginTop: 10, backgroundColor: visitColors.backgroundColor, borderColor: visitColors.borderColor }]}>
                    <Text style={{ color: visitColors.borderColor }}>{visit.monthsTotalVisitCount}</Text>
                </Button>
            );
        });
        return visitCountButtons;
    }

    const getVisitsGroupedByVisitCode = (visits: IVisitData[]) => {
        // Group visits by visitCode
        const groupedVisits = visits.reduce((acc: any, visit: any) => {
            const { visitCode } = visit;

            if (!acc[visitCode]) {
                acc[visitCode] = [];
            }

            acc[visitCode].push(visit);

            return acc;
        }, {});

        // Create new objects with aggregated data
        const result = [];
        for (const visitCode in groupedVisits) {
            if (Object.hasOwnProperty.call(groupedVisits, visitCode)) {
                const group = groupedVisits[visitCode];
                const monthsTotalVisitCount = group?.reduce((total: number, visit: any) => total + visit.visitCount, 0);
                const visitDates = group.map((visit: any) => visit.visitDate.value);

                // Create a new object without the visitCount parameter
                const newObj = {
                    visitType: group[0].visitType,
                    visitCode: group[0].visitCode,
                    visitMonth: group[0].visitMonth,
                    monthsTotalVisitCount,
                    visitDates,
                };

                result.push(newObj);
            }
        }
        return result;
    }

    const getFilteredChartWithVisitCode = (newVisitCode: string) => {
        setSelectedVisitCode(newVisitCode)
        if (selectedVisitCode !== newVisitCode) {
            const filteredData = visitSummaryData.map(item => {
                // If the visits array is empty, keep the object
                if (item.visits.length === 0) {
                    return { ...item };
                }
                // Filter the visits array to include only the objects with the specified visitCode
                const filteredVisits = item.visits.filter(visit => visit.visitType.includes(newVisitCode));
                // Return a new object with the filtered visits array
                return { ...item, visits: filteredVisits };
            });
            setFilteredVisitSummaryData(filteredData);
        } else {
            setSelectedVisitCode('all')
            setFilteredVisitSummaryData(visitSummaryData)
        }
    }


    return (
        <Box
            style={{marginBottom: 10}}
            shadow="0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f"
            borderRadius={8}
            backgroundColor={Colors.Custom.ContainerBGColor}
        >
            <Collapse
                activeKey={componentState.activePanel}
                expandIconPosition="end"
                expandIcon={({ isActive }) => {
                    const tooltipText = isActive ? intl.formatMessage({ id: 'collapse' }) : intl.formatMessage({ id: 'expand' });
                    return (
                        <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                            <RightOutlined rotate={isActive ? 90 : 0} />
                        </Tooltip>
                    )
                }}
                onChange={(activeKeys) => {
                    setComponentState((prev) => ({ ...prev, activePanel: activeKeys as string[] }));
                }}
                style={{
                    borderRadius: 16,
                    borderWidth: 0,
                    background: '#f2f4f7'
                }}>
                <Panel forceRender key={VISIT_SUMMARY} header={
                    <HStack alignItems={'center'} bgColor={Colors.Custom.BackgroundColor} borderTopRadius={16}>
                        <Text
                            fontSize={20}
                            color={Colors.Custom.ProfileViewTextColor}
                            fontWeight={600}
                        >
                            {intl.formatMessage({ id: 'visitSummary' })}
                        </Text>
                    </HStack>}>
                    {loading ? <Spinner /> :
                        <Box style={{ padding: 15 }}  >
                            <Box>
                                <VStack space={5}>
                                    <Box style={{ marginTop: 10, flexDirection: 'row' }}>
                                        <Box flexDirection={'row'} style={{ marginRight: 50 }}>
                                            <LeftOutlined
                                                onClick={() => {
                                                    setFromDate((prev) => {
                                                        return getPreviousYearStartDate(prev)
                                                    })
                                                    setToDate((prev) => {
                                                        return getPreviousYearEndDate(prev)
                                                    })
                                                    setSelectedYear(selectedYear - 1)
                                                }}
                                                style={{ padding: 5, marginRight: 10 }} />
                                            <Text fontWeight={500} style={{ fontSize: 14, width: 37, }}>{selectedYear}</Text>
                                            <RightOutlined
                                                onClick={() => {
                                                    if (selectedYear === currentYear) {
                                                        return
                                                    } else {
                                                        setSelectedYear(selectedYear + 1)
                                                        setFromDate((prev) => {
                                                            return getNextYearStartDate(prev);
                                                        })
                                                        setToDate((prev) => {
                                                            return getNextYearEndDate(prev)
                                                        })
                                                    }
                                                }}
                                                style={{ padding: 5, marginLeft: 5, opacity: selectedYear === currentYear ? 0.5 : 1 }} />
                                        </Box>
                                        <FlatList
                                            horizontal
                                            data={getUniqueVisitCodesWithTotalCount(responseSummaryData)}
                                            renderItem={({ item }) => {
                                                const visitColors = getBackgroundAndBorderColor(item.visitType)
                                                return (
                                                    <Pressable
                                                        onPress={() => {
                                                            if (item.totalVisitCount) {
                                                                getFilteredChartWithVisitCode(item.visitCode)
                                                            }
                                                        }}
                                                        opacity={selectedVisitCode === 'all' ? 1 : item.visitCode === selectedVisitCode ? 1 : 0.5}
                                                    >
                                                        <HStack space={1} style={{ alignItems: 'center', paddingRight: 10 }}>
                                                            <Box style={[style.colorCode, { backgroundColor: visitColors.borderColor, borderColor: visitColors.backgroundColor }]} />
                                                            <Text>{item.visitCode}</Text>
                                                            <Text>({item.totalVisitCount})</Text>
                                                        </HStack>
                                                    </Pressable>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <VStack style={{ borderLeftWidth: 0, borderBottomColor: '#DEDEDE', marginLeft: -15, marginRight: -15 }}>
                                        <FlatList
                                            horizontal
                                            keyExtractor={(item, index) => index.toString()}
                                            data={filteredVisitSummaryData}
                                            renderItem={({ item }) => {
                                                return (
                                                    <Flex h={graphHeight} alignItems={'flex-end'} style={{ borderRightWidth: 1, borderColor: '#EDEDED', justifyContent: 'flex-end', backgroundColor: '#FCFAFF' }}>
                                                        <Center h={graphHeight} w={20} justifyContent={'flex-end'} style={{ borderBottomWidth: 1, borderTopWidth: 1, borderColor: '#EDEDED' }}>
                                                            {getVisitCountButtons(item.visits)}
                                                            <Center h={'40px'} width={'100%'} style={{ borderTopWidth: 1, borderColor: '#EDEDED', marginTop: 10 }}>
                                                                <Text style={{ color: '#6F7A90' }} fontWeight={400}>
                                                                    {item.monthYear.substring(0, 3)}
                                                                </Text>
                                                            </Center>
                                                        </Center>
                                                    </Flex>
                                                )
                                            }}
                                        />
                                    </VStack>
                                </VStack>
                            </Box>
                            <Box style={{ display: 'flex', flex: 1, marginTop: 10, }}>
                                {getHeaderWithSubText('Last Annual Visit', '')}
                            </Box>
                        </Box>
                    }
                </Panel>
            </Collapse>
        </Box>
    );
}

const style = StyleSheet.create({
    colorCode: {
        height: 10, width: 10, borderWidth: 1, borderRadius: 10,
    },
    visitTypeCount: {
        height: 25, width: 25, borderWidth: 1, borderRadius: 5
    }
})