import {ICommonData} from '../../context/CommonDataContext';

export interface ICommonDataProviderProps {
  allCommonData: ICommonData;
  children: React.ReactNode;
}

export interface ICommonDataProviderState {
  allCommonData: ICommonData;
  isLoadingData: boolean;
}


export enum CommonDataAction {
  UPDATE_EHR_CAPABILITES
}