import { HStack, VStack, Text } from "native-base";
import { capitalizeText } from "../../../../ContentManagement/CampaignManagement/Helper";



const UserWithRoleItem = (props: any) => {
    const { agentRecord } = props;

    const getRoles = (agentRecord: any) => {
        const roleNames = (agentRecord?.user?.userRoles || agentRecord?.userRoles)?.map((role:any) => role?.userRole?.userRole?.value);
        const roleString = roleNames.join(", ")
        return (
            <Text mr={1} fontSize={11} fontWeight={400} color={'gray.500'}>{roleString}</Text>
        );
    };

    return (
        <VStack>
            <Text fontSize={14} color={'gray.700'}>
                {capitalizeText(
                    agentRecord?.user?.name || agentRecord?.name || agentRecord?.userName || agentRecord?.label
                )}
            </Text>
            <HStack flex={1}>{getRoles(agentRecord)}</HStack>
        </VStack>
    );
};

export default UserWithRoleItem;