import { IntlShape } from "react-intl";
import { BoardType } from "../common/CareDashboard/CareDashboardTopBar/interfaces";
import { IUserPool } from "../common/CareDashboard/CareDashboardWidget/UserAutoComplete";
import { IMlov } from "../../Interfaces";
import { BottomViewAction, getMissedTaskDaysMessage, getPriorityIcon, getTaskTitle, isTaskTableCheckBoxDisabled, isTaskWithType, renderResourceMapCountAndDeleteField } from "../TaskCard/TaskCardHelper";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { ITask, ITaskActionParams } from "../common/CareDashboard/CareDashboardInterfaces";
import { ToastType } from "../../utils/commonViewUtils";
import { getCompletedTaskStatusId } from "../common/CareDashboard/CareDashboardUtils/CareDashboardUtils";
import { ColumnsType } from "antd/lib/table";
import { Button, HStack, Icon, Spinner, Text, VStack, View, Divider } from "native-base";
import { Checkbox, Tooltip, notification } from "antd";
import { TASK_STATUS_FILTER_VALUES, getTaskAssignee, isTaskCompleted } from "./TaskModuleHelper";
import { Colors } from "../../styles";
import SubtasksSvg from "../common/Svg/SubtasksSvg";
import AntDesign from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";
import { TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from "../../constants/MlovConst";
import { LabelDataListView } from "../common/CareDashboard/CareDashboardWidget/LabelDataListView";
import moment from "moment";
import { getDateStrFromFormat, getMomentObj, isPastDay } from "../../utils/DateUtils";
import { DATE_FORMATS, GROUP_MEMBER_TYPE, getInActiveContactError } from "../../constants";
import { filterActiveUsersAndDefaultAssigneeOfPool, getAssignedByType, isAssigneePartOfPool } from "../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper";
import { Pressable } from "react-native";
import UserAutoComplete from "../common/CalendarWidget/UserAutoComplete/UserAutoComplete";
import { ParticipantType } from "../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum";
import PatientSearchAndSelect from "../common/PatientSearch/PatientSearchAndSelect";
import { isAllowToDeleteTask } from "../TaskCard/TaskHelper";
import CareDashboardTableActions from "../common/CareDashboard/CareDashboardTable/CareDashboardTableActions";
import { ITaskFilter, ITaskOrderBy, MultiSelectAction, MultiSelectState, TaskViewType } from "./TaskInterfaces";
import { IBatchedAccountUsersInterface } from "../CustomHooks/useGetBatchedAccountUsers";
import { ISidecarContext } from "../SideCar/interfaces";
import { EntityType, TaskPanelType } from "../TaskCard/TaskEnum";
import FeatherIcon from 'react-native-vector-icons/Feather';
import { getPatientName, isString } from "../../utils/commonUtils";
import { DisplayCardAvatar } from "../common/DisplayCard/DisplayCardAvatar";
import UserSvgV2 from "../common/Svg/UserSvgV2";
import CheckBox from "../common/V2/Checkbox";
import { useState } from "react";
import { ITaskColumn } from "./TaskModuleComponents/ListView/TaskListInterface";

export const getTaskTableViewColumns = (
  filters: ITaskFilter,
  metaData: {
    intl: IntlShape,
    userList: IBatchedAccountUsersInterface[];
    multiSelectState: MultiSelectState;
    boardType?: BoardType;
    userPool?: IUserPool[];
    isEmployer?: boolean;
    taskStatusMlov?: IMlov[];
    taskPriorityMlov?: IMlov[];
    taskActionsInProgress?: {taskId: string, action: BottomViewAction}[];
    selectedTasks?: string[];
    bulkTaskEnabled?: boolean;
    sidecarContext?: ISidecarContext;
    panelType?: TaskPanelType
  },
  callbacks: {
    getSubTaskByIds?: LazyQueryExecFunction<any, OperationVariables>,
    onBottomViewAction?: (task: ITask, action: BottomViewAction, data?: any) => void,
    onTaskDetail?: (data: ITask) => void,
    onMemberClick?: (task: ITask) => void,
    onShowToast?: (message: string, type: ToastType) => void,
    handleMultiSelection?: (action: MultiSelectAction, tasks?: ITask[]) => void,
  }
): any[] => {
  const {orderBy, statusIds, priorityIds} = filters;
  const {intl, userList, boardType, userPool, isEmployer, taskStatusMlov, taskPriorityMlov, taskActionsInProgress, selectedTasks, multiSelectState, bulkTaskEnabled} = metaData;
  const {getSubTaskByIds, onBottomViewAction, onTaskDetail, onMemberClick, onShowToast, handleMultiSelection} = callbacks;
  const selectedSortUuid = Object.keys(orderBy || {})?.[0];
  const sortOrder = (orderBy as any)?.[selectedSortUuid] === 'asc' ? 'ascend' : 'descend';
  const statusFilteredValue = taskStatusMlov?.filter((item) => statusIds?.includes(item.id))?.map(item => item.code);
  const priorityFilteredValue = taskPriorityMlov?.filter((item) => priorityIds?.includes(item.id))?.map(item => item.code);
  const showFilterForPriority = boardType !== BoardType.Priority
  const showFilterForStatus = boardType !== BoardType.Status
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov || []);
  const isPatientSidecarContext = metaData.sidecarContext?.isPatient;
  const isSidecarContext = metaData.sidecarContext?.isSidecar;
  const hideAssigneeColumn = isPatientSidecarContext && metaData.panelType === TaskPanelType.PATIENT;

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if (!task.subTasks || task.subTasks?.length === 0) {
      onBottomViewAction?.(task, BottomViewAction.markAsComplete);
      return;
    }
    const response = await getSubTaskByIds?.({
      variables: {
        ids: task.subTasks?.map((task) => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.some(
          (subTask: any) => subTask.statusId !== completedStatusId
        )
      ) {
        onShowToast?.(intl.formatMessage({ id: 'subTaskPendingMsg' }), ToastType.error);
      } else {
        onBottomViewAction?.(task, BottomViewAction.markAsComplete);
      }
    } else {
      onShowToast?.(intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error);
    }
    return;
  };

  const handleNotification = (message: string) => {
    notification.destroy();
    notification.info({
      message: message,
      duration: 3.0,
      placement: 'top',
    });
  };
  
  const handleTaskChangeFromCheckbox = async (event: any, task: ITask, taskAction: any) => {
    const isFormTask = isTaskWithType(task || ({} as ITask), EntityType.FORM);
    const isVitalTask = isTaskWithType(task || ({} as ITask), EntityType.VITAL);
    if (isFormTask || isVitalTask) {
      if (task?.status?.code !== 'completed') {
        const message = isFormTask 
          ? 'Please fill the form to complete the task' 
          : 'Please enter requested values to complete the task';
        handleNotification(message);
        return;
      } else if (isFormTask && task?.status?.code === 'completed') {
        handleNotification('Form already filled cannot change status of completed task');
        return;
      }
    }
    if (event) {
      await checkIfAllSubTasksAreCompleted(task);
    } else {
      onBottomViewAction?.(task, taskAction);
    }
  };

  const columns: ColumnsType<ITask> = [
    {
      key: 'completionStatus',
      ...(!isSidecarContext && { width: '3%' }),
      ...(isSidecarContext && { width: 24}),
      title: (
        multiSelectState === MultiSelectState.disabled ?
        (<></>) :
        (
        <View marginLeft={1}>
          <Checkbox
            style={{marginTop: 1, color: Colors.Custom.SuccessColor}}
            indeterminate={multiSelectState === MultiSelectState.some}
            checked={multiSelectState === MultiSelectState.all}
            onChange={async (event) => {
              handleMultiSelection?.(event.target.checked ? MultiSelectAction.selectAllTasks : MultiSelectAction.unSelectAllTask);
            }}
          />
        </View>
        )
      ),
      render: (task: ITask) => {
        // if task not completed any one can mark it as complete
        const canMarkAsComplete = true;
        const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;
        const currentTaskDuedate = task?.endDateTime
        const isPastDueDate = currentTaskDuedate ? isPastDay(getMomentObj(currentTaskDuedate)) : false
        const taskAction = isPastDueDate ? BottomViewAction.markAsMissed : BottomViewAction.markAsIncomplete;
        let tooltipTitle: string | undefined = isTaskCompleted(task) ? 'Mark As Incomplete' : 'Mark As Complete';
        let isTaskSelected = isTaskCompleted(task);
        const isMultiSelectEnabled = multiSelectState !== MultiSelectState.disabled;
        if (isMultiSelectEnabled) {
          tooltipTitle = undefined;
          isTaskSelected = selectedTasks?.includes(task.id) || false;
        }

        return (
          <View flexDirection="row" wordBreak={'break-word'} marginLeft={2}>
            {isTaskActionInProgress && <Spinner size={'sm'} />}
            {!isTaskActionInProgress && canMarkAsComplete && (
              <Tooltip
                title={tooltipTitle}
                placement='topLeft'
              >
                {isMultiSelectEnabled ?
                  <Checkbox
                    disabled={isTaskTableCheckBoxDisabled(task)}
                    style={{ marginTop: 1, color: Colors.Custom.mainPrimaryPurple }}
                    checked={isTaskSelected}
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleMultiSelection?.(MultiSelectAction.selectTask, [task]);
                      } else {
                        handleMultiSelection?.(MultiSelectAction.unSelectTask, [task]);
                      }
                    }}
                  />
                  :
                  <View>
                    <CheckBox
                      isDisabled={isTaskTableCheckBoxDisabled(task)}
                      isChecked={isTaskSelected}
                      onChange={(event) => handleTaskChangeFromCheckbox(event, task, taskAction)}
                    />
                  </View>
                }
              </Tooltip>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500}}
          cursor="pointer"
        >
          <Tooltip title={intl.formatMessage({id: 'Priority'})}>P</Tooltip>
        </Text>
      ),
      key: 'priority',
      filteredValue: priorityFilteredValue,
      ...(!isSidecarContext && { width: '4%' }),
      ...(isSidecarContext && { width: 24}),
      filterIcon: showFilterForPriority ?  (filtered: boolean) => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      filters: showFilterForPriority ? [
        {
          text: 'High',
          value: TASK_PRIORITY_CODES.HIGH,
        },
        {
          text: 'Medium',
          value: TASK_PRIORITY_CODES.MEDIUM,
        },
        {
          text: 'Low',
          value: TASK_PRIORITY_CODES.LOW,
        },
      ] : undefined,
      onFilter: (value, record) => {
        return record.priority?.code?.includes(value as string) || false;
      },
      filterSearch: true,
      render: (task: ITask) => {
        return (
          <View flexDirection="row" justifyContent={'center'}>
            <View flex={1}>{getPriorityIcon(task?.priority, true)}</View>
          </View>
        );
      },
    },
    {
      onCell: (record: ITask) => {
        return {
          onClick: () => {
            const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === record.id)?.length;
            if (!isTaskActionInProgress && !bulkTaskEnabled) {
              onTaskDetail?.(record)
            }
          },
        };
      },
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'title'})}
        </Text>
      ),
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'title' ? sortOrder : null,
      ...(!isSidecarContext && { width: '22%' }),
      ...(isSidecarContext && { width: isPatientSidecarContext ? 114 : 154}),
      render: (task: ITask) => {
        const renderFieldParam: ITaskActionParams = {
          task: task,
          showDeleteField: false,
          intl: intl
        };
        const {icon, name} = getAssignedByType(task,intl);
        renderFieldParam.taskViewType = TaskViewType.list;
        return (
          <HStack justifyContent={'space-between'} alignItems={'center'} flex={1}>
            <VStack flex={1}>
              <View flexDirection="row" wordBreak={'break-word'} alignItems={'center'}>
                {task?.parentId && (
                  <View size={18} marginRight={1}>
                    <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                  </View>
                )}
                <Text flex={9} size={'smMedium'} color={Colors.Custom.Gray900}>
                  {getTaskTitle(task)}
                </Text>
              </View>
              {isSidecarContext && (
                <Text flex={9} fontSize={12} color={Colors.Custom.Gray500}>
                  {`By : ${name}`}
                </Text>
              )}
              <View flexDirection="row" wordBreak={'break-word'} alignItems={'center'}>
              {task?.subTasks?.length ? (
                <View flexDirection="row" alignSelf={'center'}>
                  <View size={18}>
                    <SubtasksSvg customStrokeColor={Colors.FoldPixel.GRAY300} />
                  </View>
                  <Text
                    ml={1}
                    size={'smMedium'}
                    color={Colors.FoldPixel.GRAY300}
                  >
                    {`${task?.subTasks?.length}`}
                  </Text>
                </View>
              ) : (
                <></>
              )}
              {renderResourceMapCountAndDeleteField(renderFieldParam)}
              </View>
              {isSidecarContext && (
                <LabelDataListView
                  key={task.id}
                  hideAddMoreButton={true}
                  tagList={task?.labels || []}
                />
              )}
            </VStack>
          </HStack>

        );
      },
    },
    ...isSidecarContext ? [] : [{
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'labels'})}
        </Text>
      ),
      key: 'labels',
      width: '15%',
      render: (task: ITask) => {
        return (
          <LabelDataListView
            key={task.id}
            hideAddMoreButton={true}
            tagList={task?.labels || []}
          />
        );
      },
    }],
    ...!isPatientSidecarContext ? [{
      title: (
        isSidecarContext ?
        (
          <Tooltip title={intl.formatMessage({id: 'patientName'})}>
            <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
              {'M'}
            </Text>
          </Tooltip>
        )
        :
        (
          <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
            {intl.formatMessage({id: 'patientName'})}
          </Text>
        )
      ),
      key: 'patient',
      ...(!isSidecarContext && { width: '13%' }),
      ...(isSidecarContext && { width: 32}),
      render: (task: ITask) => {
        if (isSidecarContext) {
          const contactData = task.contact;
          if (!contactData?.uuid) {
            return <></>;
          }
          const name = contactData ? (isString(contactData.name)
          ? contactData.name
          : getPatientName(contactData)) : '';
          return (
            <Tooltip title={name}>
              <Pressable onPress={() => {
                onMemberClick?.(task);
              }}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                  }
                }}
                isLetterAvatarShow
                userData={{
                  userId: contactData?.uuid,
                  userType: contactData?.patient?.patientUuid
                  ? GROUP_MEMBER_TYPE.PATIENT
                  : GROUP_MEMBER_TYPE.CONTACT,
                  genderCode: contactData?.person?.gender?.code ||
                  contactData?.person?.sexAtBirth?.code,
                  // imgSrc: profilePhoto,
                  userName: name,
                }}
                // groupType={props?.groupType as any}
              />
              </Pressable>
            </Tooltip>
          );
        }
        return (
          <View flexDirection="row" alignItems="center">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {task.contact && task.contact.name
                ? task.contact.name
                : task.patientName
                ? task.patientName
                : '-'}
            </Text>
            {task?.contact?.uuid && (
              <Icon
                onPress={() => {
                  onMemberClick?.(task);
                }}
                as={Feather}
                name="external-link"
                size="xs"
                ml={1}
                color={Colors.Custom.Gray500}
              />
            )}
          </View>
        );
      },
    }] : [],
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'DueDate'})}
        </Text>
      ),
      key: 'endDateTime',
      sorter: (a: any, b: any) =>
        moment(a.endDateTime).unix() - moment(b.endDateTime).unix(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'endDateTime' ? sortOrder : null,
      ...(!isSidecarContext && { width: '10%' }),
      ...(isSidecarContext && { width: 76}),
      render: (task: ITask) => {
        const isTaskMissed = task?.status?.code === TASK_STATUS_CODES.MISSED;
        const missedTaskDaysMessage = getMissedTaskDaysMessage(task);

        return (
          <View flexDirection="column">
            <Text size={'smMedium'} color={isTaskMissed ? Colors.Custom.overdueTaskIconColor : Colors.Custom.Gray900}>
              {isTaskMissed && (
                  <FeatherIcon
                    name="clock"
                    size={14}
                    style={{
                      marginRight: 5,
                    }}
                    color={Colors.Custom.overdueTaskIconColor}
                  />
              )}
              {getDateStrFromFormat(task.endDateTime || new Date(), DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)}
            </Text>
            {missedTaskDaysMessage && (
              <Text
                size={'smMedium'}
                color={Colors.Custom.Gray900}
              >
                {missedTaskDaysMessage}
              </Text>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Status'})}
        </Text>
      ),
      key: 'status',
      dataIndex: 'status',
      filters: showFilterForStatus ? [
        {
          text: TASK_STATUS_FILTER_VALUES.COMPLETED,
          value: TASK_STATUS.COMPLETED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.PENDING,
          value: TASK_STATUS.ACCEPTED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.MISSED,
          value: TASK_STATUS.MISSED,
        },
      ] : undefined,
      onFilter: (value, record) => {
        if (value === TASK_STATUS.ACCEPTED) {
          return record.status?.code !== TASK_STATUS.COMPLETED;
        } else {
          return record.status?.code.includes(value as string) || false;
        }
      },
      filterSearch: true,
      filteredValue: statusFilteredValue,
      filterIcon: showFilterForStatus ? () => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      ...(!isSidecarContext && { width: '8%' }),
      ...(isSidecarContext && { width: 64}),
      render: (status: any) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {status?.value ? status.value : '-'}
          </Text>
        );
      },
    },
    ...!hideAssigneeColumn ? [{
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Assignee'})}
        </Text>
      ),
      key: 'Assignee',
      ...(!isSidecarContext && { width: '13%' }),
      ...(isSidecarContext && { width: 76 }),
      render: (task: ITask) => {
        const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;
        const assigneeValue = getTaskAssignee(task, userList);
        const isNoteContextTask = task?.resourceMap?.notes ? task?.resourceMap?.notes > 0 : false;
        let poolUsers: any[] = [];
        let poolDefaultUser: any = undefined;
        if (userPool?.length && task?.userPoolId) {
          const selectedPool = (userPool || []).find(pool => {
            return pool?.id === task?.userPoolId;
          });
          if (selectedPool?.id) {
            const {selectedPoolUsers, defaultAssignee} = filterActiveUsersAndDefaultAssigneeOfPool(selectedPool, userList);
            poolUsers = selectedPoolUsers || [];
            poolDefaultUser = defaultAssignee || undefined;
          }
        }
        const isDisabled = task?.status?.value === 'Completed' || isTaskActionInProgress || bulkTaskEnabled;

        return (
          <View style={{marginTop: 7, marginLeft: 4, width: '100%'}}>
            <Pressable>
              {isEmployer && (
                <UserAutoComplete
                  labelText={''}
                  isShowError={false}
                  allowClear={false}
                  isDisabled={isDisabled}
                  selectedValue={assigneeValue || undefined}
                  excludeOtherEmployers={true}
                  onChange={(selectedUser: any) => {
                    onBottomViewAction?.(
                      task,
                      BottomViewAction.changeAssignee,
                      {
                        ...selectedUser,
                        type: ParticipantType.staff,
                      }
                    );
                  }}
                  usersData={userList}
                  minWidth={'100%'}
                />
              )}
              {!isEmployer && (
                <PatientSearchAndSelect
                  showCurrentUserAtTop={task?.userPoolId ? false : true}
                  showUserPendingTasksCount={true}
                  dropdownPopupMinWidth={450}
                  isProspect={true}
                  showEmail
                  placement={isSidecarContext? 'bottomRight': 'bottomLeft'}
                  isDisabled={isDisabled}
                  showError={false}
                  placeholder="Search Staff or Member"
                  value={assigneeValue ? assigneeValue : undefined}
                  onChange={(participants: any, data: any) => {
                    const contactIdValue =
                      participants?.label?.props?.contactData?.uuid ||
                      participants?.value;
                    const contactIdKey = contactIdValue || participants?.key;
                    const assigneeData = {
                      label: participants?.label,
                      key: contactIdKey,
                      value: contactIdValue,
                      type: data?.type,
                    };

                    if (task?.userPoolId && !isAssigneePartOfPool(contactIdKey, task?.userPoolId, userPool)) {
                      task.userPoolId = undefined;
                    }
                    onBottomViewAction?.(
                      task,
                      BottomViewAction.changeAssignee,
                      assigneeData
                    );
                  }}
                  allowClear={false}
                  selectedPoolUsers={poolUsers}
                  defaultAssigneeInSelectedPool={poolDefaultUser}
                  disablePatientSearch={isNoteContextTask}
                  disableLeadsSearch={isNoteContextTask}
                />
              )}
            </Pressable>
          </View>
        );
      },
    }] : [] ,
    ...!isSidecarContext ? [{
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'assignedBy'})}
        </Text>
      ),
      key: 'assignedBy',
      width: '13%',
      render: (task: ITask) => {
        const {icon, name} = getAssignedByType(task,intl);
        return (
          <View flexDirection="row" alignItems="center">
            <HStack alignItems={'center'} maxW={'100%'}>
              <View>
                {icon}
              </View>
              <Tooltip title={name}>
                <Text
                  ml={1.5}
                  size={'smMedium'}
                  color={Colors.Custom.Gray900}
                  isTruncated
                >
                  {name}
                </Text>
              </Tooltip>
            </HStack>
          </View>
        );
      },
    }] : [],
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {isSidecarContext ? '' : intl.formatMessage({id: 'actions'})}
        </Text>
      ),
      key: 'action',
      ...(!isSidecarContext && { width: '7%' }),
      ...(isSidecarContext && { width: 42}),
      render: (task: ITask) => {
        const isDisabled = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length || bulkTaskEnabled;
        const currentBoardType = boardType === BoardType.taskPool ? undefined : boardType;
        return (
          <View flexDirection="row" alignItems={'center'}>
            {!isDisabled && currentBoardType && currentBoardType !== BoardType.dueDate &&
              <View mx={2}>
                <CareDashboardTableActions
                  completedStatusId={completedStatusId}
                  intl={intl}
                  task={task}
                  boardType={currentBoardType}
                  checkIfAllSubTasksAreCompleted={checkIfAllSubTasksAreCompleted}
                  onTaskAction={(code: string, task: ITask, action: BottomViewAction) => {
                    onBottomViewAction?.(task, action, { code })
                  }}
                />
            </View>
            }

          </View>
        );
      },
    },
  ];

  const sideCarTaskColumns: ColumnsType<ITask> = [
    {
      key: 'completionStatus',
      width: '6%',
      title: (
        multiSelectState === MultiSelectState.disabled ?
        (<></>) :
        (
        <View marginLeft={1}>
          <Checkbox
            style={{marginTop: 1, color: Colors.Custom.SuccessColor}}
            // indeterminate={multiSelectState === MultiSelectState.some}
            checked={multiSelectState === MultiSelectState.all}
            onChange={async (event) => {
              handleMultiSelection?.(event.target.checked ? MultiSelectAction.selectAllTasks : MultiSelectAction.unSelectAllTask);
            }}
          />
        </View>
        )
      ),
      render: (task: ITask) => {
        // if task not completed any one can mark it as complete
        const canMarkAsComplete = true;
        const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;
        const currentTaskDuedate = task?.endDateTime
        const isPastDueDate = currentTaskDuedate ? isPastDay(getMomentObj(currentTaskDuedate)) : false
        const taskAction = isPastDueDate ? BottomViewAction.markAsMissed : BottomViewAction.markAsIncomplete;
        let tooltipTitle: string | undefined = isTaskCompleted(task) ? 'Mark As Incomplete' : 'Mark As Complete';
        let isTaskSelected = isTaskCompleted(task);
        const isMultiSelectEnabled = multiSelectState !== MultiSelectState.disabled;
        if (isMultiSelectEnabled) {
          tooltipTitle = undefined;
          isTaskSelected = selectedTasks?.includes(task.id) || false;
        }

        return (
          <View flexDirection="row" wordBreak={'break-word'} marginLeft={1}>
            {isTaskActionInProgress && <Spinner size={'sm'} />}
            {!isTaskActionInProgress && canMarkAsComplete && (
              <Tooltip
                title={tooltipTitle}
                placement='topLeft'
              >
                {isMultiSelectEnabled ?
                  <Checkbox
                    disabled={isTaskTableCheckBoxDisabled(task)}
                    style={{ marginTop: 1, color: Colors.Custom.mainPrimaryPurple, borderRadius: 8 }}
                    checked={isTaskSelected}
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleMultiSelection?.(MultiSelectAction.selectTask, [task]);
                      } else {
                        handleMultiSelection?.(MultiSelectAction.unSelectTask, [task]);
                      }
                    }}
                  />
                  :
                  <View>
                    <CheckBox
                      isDisabled={false}
                      isChecked={isTaskSelected}
                      onChange={(event) => handleTaskChangeFromCheckbox(event, task, taskAction)}
                    />
                  </View>
                }
              </Tooltip>
            )}
          </View>
        );
      },
    },
    {
      onCell: (record: ITask) => {
        return {
          onClick: () => {
            const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === record.id)?.length;
            if (!isTaskActionInProgress && !bulkTaskEnabled) {
              onTaskDetail?.(record)
            }
          },
        };
      },
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'title'})}
        </Text>
      ),
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'title' ? sortOrder : null,
      width: '63%',
      render: (task: ITask) => {
        const renderFieldParam: ITaskActionParams = {
          task: task,
          showDeleteField: false,
          intl: intl,
          isSidecarContext
        };
        renderFieldParam.taskViewType = TaskViewType.list;
        return (
          <HStack justifyContent={'space-between'} alignItems={'center'} flex={1} marginLeft={1}>
            <View style={{marginRight: 12}}>{getPriorityIcon(task?.priority, true)}</View>
            <VStack flex={1}>
              <View flexDirection="row" wordBreak={'break-word'} alignItems={'center'}>
                {task?.parentId && (
                  <View style={{width: 16, height: 16, marginRight: 4}}>
                    <SubtasksSvg customStrokeColor={Colors.FoldPixel.GRAY300} />
                  </View>
                )}
                <Text flex={9} size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                  {getTaskTitle(task)}
                </Text>
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {!!task?.subTasks?.length && (
                <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 4 }}>
                  <View style={{width: 16, height: 16}}>
                    <SubtasksSvg customStrokeColor={Colors.FoldPixel.GRAY300} />
                  </View>
                  <Text marginLeft={1} size='smMedium' style={{color: '#8A94A8'}}>{task?.subTasks?.length}</Text>
                </View>
              )}
               {renderResourceMapCountAndDeleteField(renderFieldParam)}
               {!isPatientSidecarContext && task.contact && (task.contact.name || task.patientName) && (<View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <UserSvgV2 customStrokeColor={Colors.FoldPixel.PRIMARY300} />
                  <Text
                    size={'smMedium'}
                    ml={.5}
                    color={Colors.FoldPixel.PRIMARY300}>{task.contact && task.contact.name
                      ? task.contact.name
                      : task.patientName
                        ? task.patientName
                        : '-'}
                  </Text>
                </View>)
            }
              </View>
            </VStack>
          </HStack>

        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'DueDate'})}
        </Text>
      ),
      key: 'endDateTime',
      sorter: (a: any, b: any) =>
        moment(a.endDateTime).unix() - moment(b.endDateTime).unix(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'endDateTime' ? sortOrder : null,
      width: '20%',
      render: (task: ITask) => {
        const isTaskMissed = task?.status?.code === TASK_STATUS_CODES.MISSED;
        const missedTaskDaysMessage = getMissedTaskDaysMessage(task);

        return (
          <View flexDirection="column">
            <Text size={'smMedium'} color={isTaskMissed ? Colors.Custom.overdueTaskIconColor : Colors.Custom.Gray900}>
              {/* {isTaskMissed && (
                  <FeatherIcon
                    name="clock"
                    size={14}
                    style={{
                      marginRight: 5,
                    }}
                    color={Colors.Custom.overdueTaskIconColor}
                  />
              )} */}
              {getDateStrFromFormat(task.endDateTime || new Date(), DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)}
            </Text>
            {!isSidecarContext && missedTaskDaysMessage && (
              <Text
                size={'smMedium'}
                color={Colors.Custom.Gray900}
              >
                {missedTaskDaysMessage}
              </Text>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {isSidecarContext ? '' : intl.formatMessage({id: 'actions'})}
        </Text>
      ),
      key: 'action',
      width: '8%',
      render: (task: ITask) => {
        const isDisabled = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length || bulkTaskEnabled;
        const currentBoardType = boardType === BoardType.taskPool ? undefined : boardType;
        return (
          <View flexDirection="row" alignItems={'center'}>
            {!isDisabled && currentBoardType && currentBoardType !== BoardType.dueDate &&
              <View mx={2}>
                <CareDashboardTableActions
                  completedStatusId={completedStatusId}
                  intl={intl}
                  task={task}
                  boardType={currentBoardType}
                  checkIfAllSubTasksAreCompleted={checkIfAllSubTasksAreCompleted}
                  onTaskAction={(code: string, task: ITask, action: BottomViewAction) => {
                    onBottomViewAction?.(task, action, { code })
                  }}
                />
            </View>
            }
          </View>
        );
      },
    },
  ]

  return isSidecarContext ? sideCarTaskColumns : columns;
};


export const getSidecarTaskListHeader = (params: {
  orderBy?: ITaskOrderBy;
}): ITaskColumn[] => {
  const selectedSortUuid = Object.keys(params?.orderBy || {})?.[0];
  const sortOrder = selectedSortUuid ? (params?.orderBy as any)?.[selectedSortUuid] || 'none' : 'none';
  return [
    {
      width: 65,
      title: 'Task Title',
      code: 'title',
      sortOrder: selectedSortUuid === 'title' ? sortOrder : 'none',
    },
    {
      width: 20,
      title: 'Due Date',
      code: 'endDateTime',
      sortOrder: selectedSortUuid === 'endDateTime' ? sortOrder : 'none',
    },
    {
      width: 7,
      code: 'endDateTime',
    },
  ]
}
