import { IInternalUsers, IUserOptionCheck } from "./interface";

export const filterInternalUsers = (users: IInternalUsers[], disabledUsers: string[], selectedUsers: string[]): IUserOptionCheck[] => {
    const filteredUsers: IUserOptionCheck[] = [];
    for (let userIndex = 0; userIndex < users?.length; userIndex++) {
        const singleUser: any = users[userIndex];
        singleUser.user.isDisabled = disabledUsers.includes(
            singleUser?.user?.uuid
        );
        singleUser.user.checked = selectedUsers.includes(
            singleUser?.user?.uuid
        )
        filteredUsers.push(singleUser.user);
    }
    return filteredUsers;
}

export const getSelectedUsersUUIDs = (
    internalUsersData: IInternalUsers[]
): string[] => {
    return internalUsersData.map((user) => {
        return user.user.uuid;
    });
};

export const filterLoggedInUserFromInternalUser = (
  userList: IInternalUsers[],
  userUuid: string
) => {
  const filteredUsers = userList?.filter(
    (item) => item?.user?.uuid !== userUuid
  );
  return filteredUsers;
};