import React, {useContext, useEffect, useState} from 'react';
import {View, FlatList, ScrollView, Center, Text} from 'native-base';
import {useCallback} from 'react';
import useHomeMonitoring from '../useHomeMonitoring';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {ListRenderItem, Switch} from 'react-native';
import {FilterTypes, getLastRecordedDataMapKey, getFilteredGraphList, checkWearableSettingExist} from '../utils';

import VitalGraphCard from './VitalGraphCard';
import VitalDrillDownFilterView from './VitalDrillDownFilterView';
import {isAndroid, isWeb} from '../../../../../../../utils/platformCheckUtils';
import EmptyStateSvg from '../../../../../../common/Svg/EmptyStateSvg.native';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { ClinicalSectionFilterTabs } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import { CINICAL_SECTIONS_FILTER_TAB_CODES, HOME_MONITORING_FILTER_TABS } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import {NoDataFound} from '../../../../../../common/NoDataFound';
import {useIntl} from 'react-intl';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {getMeasure} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {GET_CARE_PLAN_GOALS_BY_CONTACT_ID} from '../../../../../../../services/CarePlan/CarePlanQueries';
import {Colors} from '../../../../../../../styles';
import { NoPinnedWidgetView } from '../NoPinnedWidgetView';
import { useCarePlanGoalsApi } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/CarePlanGoalsHook';
import { DataType, IWearableDataFilterParams, SubFilterTypes, VitalDataSubFilterMap, VitalGraphData } from '../interface';
import { cloneDeep } from 'lodash';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import { CustomToggleButton } from '../../../../../../common/CustomToggleButton/CustomToggleButton';
import { Dimensions } from 'react-native';
import { SMALL_WINDOW_1500 } from '../../../../../../../constants';

interface IHomeMonitoringDrillDownViewProps {
  patientId?: string;
  locationId?: string;
  ccmDate?: string;
  contactId?: string;
  goals?: any;
}

const HomeMonitoringDrillDownView = (
  props: IHomeMonitoringDrillDownViewProps
) => {
  const {patientId, locationId, contactId} = props;
  const {
    componentState,
    isNextDisabled,
    isPrevDisabled,
    onChangeComponentState,
    onNextDate,
    onPrevDate,
    allowedWearableList
  } = useHomeMonitoring({
    isTrendsView: false,
    patientId,
    locationId,
    ccmDate: props.ccmDate,
  });

  const screenDimensions = Dimensions.get('window');
  const isSmallWidth = screenDimensions?.width < SMALL_WINDOW_1500;

  const renderLoadingView = useCallback(
    () => (
      <View padding={4}>
        <FormComponentLoader />
        <FormComponentLoader />
        <FormComponentLoader />
        <FormComponentLoader />
        <FormComponentLoader />
        <FormComponentLoader />
      </View>
    ),
    []
  );


  const intl = useIntl();

  const [fetchedGoals, goalsLoading, fetchGoals] = useCarePlanGoalsApi();

  useEffect(() => {
    if (!props?.goals) {
      fetchGoals(contactId);  
    }
  }, [])

  const renderFilterTabs = () => {
    return (
      <View>
        <ClinicalSectionFilterTabs
          tabs={HOME_MONITORING_FILTER_TABS}
          selectedTabCode={componentState.selectedFilterTab}
          onTabClick={(tab: string) => {
            onChangeComponentState({
              selectedFilterTab: tab,
              showSettingsView: false,
            });
          }}
        />
      </View>
    );
  };

  const onToggleChange = (isToggled: boolean, item: VitalGraphData) => {
    const subFilters = componentState?.subFilter || {} as VitalDataSubFilterMap
    if (isToggled) {
      subFilters[item.loinc] = [SubFilterTypes.STAGE];
    } else {
      delete subFilters[item.loinc]
    }

    onChangeComponentState({
      subFilter: cloneDeep(subFilters)
    });
  }

  const renderExtraFilter = (item: VitalGraphData) => {
    if (item.config?.showStageFilterSwitch) {
      const isToggled = componentState?.subFilter?.[item.loinc]?.includes(SubFilterTypes.STAGE)
        ? true
        : false;
        
      const direction = isWeb() && !isSmallWidth ? 'row' : 'column';
      return (
        <Stack direction={direction} style={{alignItems:'center'}}>
          {isWeb() ? (
            <CustomToggleButton
              size={isWeb() ? 'small' : 'large'}
              value={isToggled}
              onChange={(isToggled) => {
                onToggleChange(isToggled, item);
              }}
            />
          ) : (
            <Switch
              value={isToggled}
              trackColor={{false: Colors.Custom.Gray300, true: Colors.Custom.Primary300}}
              thumbColor={Colors.Custom.White}
              onValueChange={(isToggled) => {
                onToggleChange(isToggled, item);
              }}
            />
          )}
          <Text style={{fontSize: 12, marginLeft: isWeb() ? 12 : 0, color: Colors.Custom.Gray500, marginTop: direction === 'column' ? 4 : 0 }}>{'Sleep Stages'}</Text>        
      </Stack>      
      );
    }
    return <></>;
  };  


  const renderItem = ({item}: {item: VitalGraphData}) => {
    const measure = getMeasure(item?.loinc, props?.goals || fetchedGoals);
    const key = getLastRecordedDataMapKey({
      code: item.code,
      dataOperation: item.dataOperation,
    });
    const lastRecordedData = componentState.lastRecordedDateMap.get(key);
    return (
      <VitalGraphCard
        key={key}
        vital={{
          ...item,
          filterType: componentState.selectedTabCode,
        }}
        ccmDate={props.ccmDate}
        measure={measure}
        lastRecorded={lastRecordedData}
        extraFilter={renderExtraFilter(item)}
      />
    );
  };

  const filterParams: IWearableDataFilterParams = {
    graphList: componentState.graphList,
    filterDataType: DataType.VITAL,
    selectedFilterTab: componentState.selectedFilterTab,
    wearableSetting: componentState.wearableSetting
  }

  const bioMarkers = getFilteredGraphList(filterParams)
    
  const activity = getFilteredGraphList({...filterParams, filterDataType: DataType.ACTIVITY})

  return (
    <View>
      <View
        position={isWeb() ? 'sticky' : undefined}
        top={isWeb() ? -24 : undefined}
        backgroundColor={Colors.Custom.White}
        zIndex={isWeb() ? 9999999 : undefined}
        flexDir={!isWeb() ? 'column' : 'row'}
        justifyContent={'space-between'}
      >
        <VitalDrillDownFilterView
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
          selectedDateRange={componentState.dateRange}
          onNextClick={() => onNextDate()}
          onPrevClick={() => onPrevDate()}
          activeTab={componentState.selectedTabCode}
          onTabChange={(tabCode: string) =>
            onChangeComponentState({
              selectedTabCode: tabCode as keyof typeof FilterTypes,
            })
          }
          rightView={
            isWeb() ? (
              renderFilterTabs()              
            ) : (
              <></>
            )
          }
        />
        {!isWeb() ? renderFilterTabs() : null}
      </View>
      {componentState.loading ? (
        renderLoadingView()
      ) : (
        <ScrollView
          mb={isAndroid() ? 220 : undefined}
          contentInset={
            !isAndroid()
              ? {
                  bottom: 250,
                }
              : undefined
          }
        >
          {componentState.selectedFilterTab ===
            CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT &&
          !checkWearableSettingExist(
            componentState.wearableSetting,
            allowedWearableList
          ) ? (
            <NoPinnedWidgetView />
          ) : (
            <></>
          )}

          {bioMarkers.length > 0 && (
            <>
              <Text
                color={Colors.Custom.Gray500}
                fontSize={14}
                fontWeight={400}
                paddingY={2}
              >
                {intl.formatMessage({id: 'bioMarker'}).toUpperCase()}
              </Text>
              {bioMarkers.map((item) => renderItem({item}))}
            </>
          )}
          {activity.length > 0 && (
            <>
              <Text
                color={Colors.Custom.Gray500}
                fontSize={14}
                fontWeight={400}
                paddingY={2}
              >
                {intl.formatMessage({ id: 'activity' }).toUpperCase()}
              </Text>
              {activity.map((item) => renderItem({ item }))}
            </>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default HomeMonitoringDrillDownView;
