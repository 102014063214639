import {View} from 'native-base';
import ReactFlow, { Controls, NodeTypes, ReactFlowProvider} from 'reactflow';
import CareTeamNodeType from './CareTeamNodeType';
import { ICustomFlow } from './interfaces';

const CustomComponentFlow = (props: ICustomFlow) => {


  const proOption = {
    account: 'paid-pro',
    hideAttribution: true,
  };

  const nodeTypes: NodeTypes = {
    CareTeam: CareTeamNodeType,
  };



  return (
      <View height="full">
        <ReactFlowProvider>
          <View flex={2} flexDirection="row" height="full">
            <View flex={6}>
              <ReactFlow
                fitView={props.fitView}
                proOptions={proOption}
                nodeTypes={nodeTypes}
                nodes={props.nodes}
                edges={props.edges}
                style={{
                  background: '#fafafa',
                }}>
                <Controls
                  showInteractive={true}
                />
              </ReactFlow>
            </View>
          </View>
        </ReactFlowProvider>
      </View>
  );
};

export default CustomComponentFlow;
