import { Spacer, View } from 'native-base';
import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './PrintStyle.scss'


type ComponentToPrintProps = {
  children: React.ReactNode;
  header?: string | JSX.Element;
  headerComponents?: JSX.Element[];
  footer: string;
};

export const ComponentToPrint = React.forwardRef((props: ComponentToPrintProps, ref) => {
  const headerRef = useRef<HTMLTableDataCellElement>();

  const renderHeader = (header: any) => {
    if (header && typeof header === 'string') {
      return ReactHtmlParser(header);
    } else {
      return header;
    }
  };

  const renderFooter = () => {
    if (props.footer && typeof props.footer === 'string') {
      return ReactHtmlParser(props.footer);
    } else {
      return props.footer;
    }
  };

  useEffect(() => {
    if (headerRef?.current) {
      headerRef.current.height = `${headerRef.current.offsetHeight}px`;
    }
  }, [headerRef?.current]);

  return (
    <View ref={ref}>
      {/* <div className="page-header" style={{display: 'block'}}>{renderHeader()}</div> */}
      <table width={'100%'}>
        <thead>
          <tr>
            <td className="page-header-space" ref={headerRef as any}>
              {
                (props.header && typeof props.header === 'string') &&
                !props.headerComponents?.length &&
                <View ref={headerRef}>
                  {renderHeader(props.header)}
                </View>
              }
              {
                Array.isArray(props.header) &&
                !props.headerComponents?.length &&
                <View ref={headerRef}>
                  {props.header.map((header, index) => {
                    return renderHeader(header);
                  })}
                </View>
              }
              {
                !!props.headerComponents?.length &&
                <View ref={headerRef}>
                  {props.headerComponents.map((header, index) => {
                    return renderHeader(header);
                  })}
                </View>
              }
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* {renderHeader()} */}
              <div className="content">{props.children}</div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space">
                {renderFooter()}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      {/* <div className="page-header" style={{display: 'block'}}>{renderHeader()}</div>
      <div className="page-footer" style={{display: 'block'}}>{renderFooter()}</div> */}
    </View>
  );

});
