import { Moment } from "moment";

export enum ReccurenceType {
    DAILY = 'days',
    WEEKLY = 'weeks',
    MONTHLY = 'months',
    YEARLY = 'years'
}

export enum Month {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December'
}

export declare type EventValue<DateType> = DateType | null;
export declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
export interface IUseReccuringEvent {
  frequency: ReccurenceType;
  repeatEvery: number;
  endDate: string;
  startDate: string;
  viewType: string;
  repeatsOnWeekDays?: number[];
  repeatsOnMonthDay: number;
  repeatsOnMonth: Month;
  startAndEndTime?: RangeValue<Moment>;
  maxDate?: Moment;
  seriesStartDate?: string;
}

export enum ReccuringEventFieldType {
  reccurenceType,
  repeatEvery,
  endDate,
}

export type PickSpecificFields<T> = Pick<T, Exclude<keyof T, 'viewType' | 'maxDate'>>;

export interface ReccuringEventViewConfig {
    showRepeatsOn: boolean
}

export interface IReccuringEventConfig {
  data: IUseReccuringEvent;
  onChangeData: <K extends keyof PickSpecificFields<IUseReccuringEvent>>(
    fieldType: K,
    value: IUseReccuringEvent[K]
  ) => void;
  onResetData: () => void;
  maxDate: Moment;
  formatDataForSave: (timezone: string) => {
    data: IReccuringEventAPI;
    error: boolean;
  };
  displaySummaryText: string,
  maxRepeatsEveryLimit: number,
  disabledEndDates: (current: Moment) => boolean,
  eventStartDate: Date,
  isUpdate: boolean
}

export interface IReccuringEventAPI {
  version: '1.0';
  timezone: string;
  repeatOnField: string;
  repeatOnValue: number;
  endDate: string;
  frequency: ReccurenceType;
  daysOfWeek?: number[];
  repeatOnMonthDays?: number[];
  repeatOnMonth?: string;
  startDateTime?: string;
  endDateTime?: string;
  startAndEndTime?: RangeValue<Moment>,
  seriesStartDate: string;
}

export interface IRenderDateParams {
  isRequired: boolean;
  leftMargin?: number;
  label: string;
  format: string;
  onChange: any;
  disabledDate: any;
  value: any;
  customStyle: any;
  extraStyle?: any;
  error: boolean;
  disabled: boolean;
}