import {AutoComplete, Select} from 'antd';
import {FormControl, useToast, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {getLanguages} from '../../../services/CommonService/AidBoxService';
import {Colors} from '../../../styles';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {Lang, Language, LanguageOptionProps} from './intefaces';

const {Option} = AutoComplete;

const LanguageOptions = (props: LanguageOptionProps) => {
  const {code, onSelect, customStyle} = props;
  const [languageOptionState, setLanguageOptionState] = React.useState({
    languageList: [] as Lang[],
    searchedLanguages: [] as Lang[],
    selectedLanguage: '',
  });
  const [inputValue, setInputValue] = useState('');
  const toast = useToast();

  useEffect(() => {
    getLanguages()
      .then((response) => {
        const list = getFormattedLanguageArray(response.data);
        const selectedLanguage = getLanguageFromCode(code, list);
        setLanguageOptionState((prev) => {
          return {
            ...prev,
            languageList: list,
            searchedLanguages: list,
            selectedLanguage: selectedLanguage,
          };
        });
        setInputValue(selectedLanguage || '');
      })
      .catch((error) => {
        showToast(
          toast,
          'Unable to fetch languages at the moment',
          ToastType.error
        );

      });
  }, []);
  const searchLanguage = (searchString: string) => {
    if (searchString) {
      const results = languageOptionState.languageList.filter((item) =>
        item.text.toLowerCase().includes(searchString.toLowerCase())
      );
      setLanguageOptionState((prev) => {
        return {
          ...prev,
          searchedLanguages: results,
        };
      });
    } else {
      setLanguageOptionState((prev) => {
        return {
          ...prev,
          searchedLanguages: languageOptionState.languageList,
        };
      });
    }
  };

  const getWidth = () => {
    if (customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  return (
    <View flex={1}>
      <FormControl.Label>
        <DisplayText
          extraStyles={{color: Colors.Custom.Gray500,marginLeft: 10,fontWeigth:400}}
          textLocalId="language"
        />
      </FormControl.Label>
      {/* <AutoComplete
        allowClear
        size="large"
        style={{
          height: 36,
          width: customStyle?.width ? customStyle.width : getWidth(),
          fontSize: 12,
        }}
        value={inputValue}
        onChange={(value, options) => {
          setInputValue(value);
        }}
        onSelect={(value: any) => {
          const selectedLanguage = getSelectedLanguageObject(
            value,
            languageOptionState.languageList
          );
          setLanguageOptionState((prev) => {
            return {
              ...prev,
              selectedLanguage: selectedLanguage?.text || '',
            };
          });
          onSelect(selectedLanguage?.code || '');
          setInputValue(selectedLanguage?.text || '');
        }}
        placeholder="Select Language"
        onSearch={searchLanguage}
      >
        {languageOptionState.searchedLanguages.map((option) => {
          return (
            <Option key={option.text} value={option.text}>
              {option.text}
            </Option>
          );
        })}
      </AutoComplete> */}

      <Select
        size="large"
        showSearch
        allowClear
        filterOption={false}
        value={languageOptionState.selectedLanguage}
        onSearch={searchLanguage}
        onChange={(value) => {
          const selectedLanguage = getSelectedLanguageObject(
            value,
            languageOptionState.languageList
          );
          setLanguageOptionState((prev) => {
            return {
              ...prev,
              selectedLanguage: selectedLanguage?.text || '',
            };
          });
          onSelect(selectedLanguage?.code || '');
        }}
        placeholder="Select Language"
      >
        {languageOptionState.searchedLanguages.map((item) => {
          return (
            <Select.Option key={item.text} value={item.text}>
              {item.text}
            </Select.Option>
          );
        })}
      </Select>
    </View>
  );
};
export default LanguageOptions;
export const getSelectedLanguageObject = (
  text: string,
  languageList: Lang[]
): Lang | undefined => {
  return languageList.find((item) => item.text === text);
};
export const getLanguageFromCode = (
  code: string,
  languageList: Lang[]
): string => {
  const language = languageList.find((item) => item.code === code);
  return language?.text || '';
};
export const getFormattedLanguageArray = (data: Language[]): Lang[] => {
  const result: Lang[] = [];
  data.forEach((item) => {
    result.push({
      code: item.coding[0].code,
      description: item.coding[0].description,
      text: item.text,
    });
  });
  return result;
};
