import {useLazyQuery} from '@apollo/client';
import {Select as AntSelect, Tooltip} from 'antd';
import {
  Divider,
  FormControl,
  HStack,
  Icon,
  Pressable,
  Text,
  View,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  ACCOUNTS_AND_ROLES_ACTION_CONST,
  MLOV_CATEGORY,
} from '../../../../../constants';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../Interfaces';
import {
  getPractitionersData,
  getPractitionersDataByAccount,
} from '../../../../../services/CommonService/AidBoxService';
import {getEhrList} from '../../../../../services/CommonService/IntegrationService';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../styles';
import {
  getEHRName,
  getResourceAbilities,
} from '../../../../../utils/capabilityUtils';
import {getAccountId, getAccountUUID, getFoldAccessToken} from '../../../../../utils/commonUtils';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import CardForLocationAndUser from '../AddEditUser/components/CardForLocationAndUser';
import {
  ehrCodeDisplay,
  PractitionerData,
  SingleEmployer,
} from '../InviteUserDrawer/interfaces';
import {styles} from '../InviteUserDrawer/InviteUserDrawerStyles';
import {
  getFinalPractitionerData,
  getFormattedPractitionerData,
} from '../InviteUserDrawer/InviteUserDrawerUtils';
import '../InviteUserDrawer/inviteUserDrawer.css';
import {IAddAccountRoleView} from './interface';
import {getEhrDisplayName, getEhrPracticeNameName} from './utils';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';

const {Label} = FormControl;

const AddAccountRoleView = (props: IAddAccountRoleView) => {
  const {
    singleAccountRoleObj,
    addUpdateFieldValue,
    associatedAccountList,
    isDisabled,
    accountUserRoleList,
    userRoles,
    isEditMode
  } = props;
  const accountUuid = getAccountUUID()
  const intl = useIntl();
  const [cleared, setCleared] = useState(false)
  const practitionerAbilities = getResourceAbilities(
    FHIR_RESOURCE.PRACTITIONER
  );
  const showPractitionerDropdown = practitionerAbilities?.isEnabled || false;
  const [errors, setErrors] = useState<any>({
    practice: '',
  });
  const [stateData, setStateData] = useState({
    userRoles: [] as IMlov[],
    employerList: [] as SingleEmployer[],
    ehrList: [] as ehrCodeDisplay[],
    practitionerData: [] as PractitionerData[],
    loading: false,
  });

  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: singleAccountRoleObj.accountUuid,
      query: '%%',
    },
  });
  const [getAccountUserExternalUserId] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountUserExternalUserIdByAccount
  );
  const isAccountIsMaster = () => {
    return singleAccountRoleObj.accountUuid === accountUuid
  }
  const currentCodeEHR = getEHRName();
  const ehrDisplayName = getEhrDisplayName(stateData.ehrList, currentCodeEHR);
  const ehrPracticeName = getEhrPracticeNameName(
    singleAccountRoleObj,
    stateData.practitionerData,
    stateData.ehrList,
    currentCodeEHR
  );
  const getEhrUserData = () => {
    let userRoleList = userRoles
    if (!isAccountIsMaster()) {
      userRoleList = userRoleList.filter((role) => {
        return role.code !== USER_ROLE_CODES.EMPLOYER
      })
    }
    setStateData((prev) => {
      return {
        ...prev,
        userRoles: [
          ...userRoleList,
          {
            categoryId: '',
            code: '',
            id: '',
            value: '',
          },
        ],
      };
    });
    if (showPractitionerDropdown && singleAccountRoleObj.accountId && !isAccountIsMaster()) {
      try {
        setStateData((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });
        const promiseList = [
          getEmployers(),
          ...(showPractitionerDropdown
            ? [
              getEhrList(),
              getPractitionersDataByAccount(
                singleAccountRoleObj.accountUuid),
              getAccountUserExternalUserId({
                variables: {accountId: singleAccountRoleObj.accountId},
              }),
            ]
            : []),
        ];
        Promise.all(promiseList)
          .then((response) => {
            setStateData((prev) => {
              return {
                ...prev,
                employerList: response?.[0]?.data?.employers || [],
              };
            });
            if (showPractitionerDropdown) {
              if (response?.[1]?.data?.expansion?.contains?.length > 0) {
                setStateData((prev) => {
                  return {
                    ...prev,
                    ehrList: response?.[1]?.data?.expansion?.contains,
                  };
                });
              }
              const data = getFormattedPractitionerData(response[2].data);
              const accountUsers = response[3];
              const list = getFinalPractitionerData(data, accountUsers);
              if (isEditMode) {
                if (singleAccountRoleObj.externalUserId) {
                  const externalUser = data.find(
                    (item) => item.id == singleAccountRoleObj.externalUserId
                  );
                  setStateData((prev) => {
                    return {
                      ...prev,
                      practitionerData: externalUser ? [externalUser, ...list] : [],
                      selectedExternalUser: externalUser || ({} as PractitionerData),
                      isSelectedDisabled: true,
                      loading: false,
                    };
                  });
                } else {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      practitionerData: list,
                      loading: false,
                    };
                  });
                }
              } else {
                setStateData((prev) => {
                  return {
                    ...prev,
                    practitionerData: list,
                  };
                });
              }
            }
            setStateData((prev) => {
              return {
                ...prev,
                loading: false,
              };
            });
          })
          .catch((error) => {
            setErrors({
              ...errors,
              practitioner: 'Error in fetching practitioners list',
            });
            setStateData((prev) => {
              return {
                ...prev,
                loading: false,
                ehrList: [],
                employerList: [],
                practitionerData: [],
              };
            });
          });
      } catch (error) {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    }
  };
  const getName = (userData: any) => {
    if (!userData.externalUserId) {
      return ''
    }
    const selectedUser = stateData.practitionerData.find((item) => {
      return item.id === userData.externalUserId;
    });
    return  selectedUser?.displayName ?  `${selectedUser?.displayName} (${ehrDisplayName})` : '';
  };
  useEffect(() => {
    getEhrUserData();
  }, [singleAccountRoleObj.accountUuid]);

  return (
    <View
      style={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.FoldPixel.GRAY150,
        borderRadius: 12,
        padding: 16,
        marginBottom: 16,
        position: 'relative',
      }}
    >
      <ModalActionAntSelect
        allowClear={true}
        showSearch={false}
        isRequired
        label={'practiceLabel'}
        placeholder={"Please select"}
        isInvalid={errors.practice}
        errors={errors.practice}
        errorText={errors.practice}
        disabled={isDisabled}
        onClear={() => {
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME, {
            accountUuid: '',
            accountId: 0,
          });
        }}
        onChange={(value: any, data: any) => {
          const isPracticeAlreadyExist = accountUserRoleList.find(
            (practice) => {
              return (
                practice?.accountUuid && practice?.accountUuid === data.value
              );
            }
          );
          if (isPracticeAlreadyExist?.accountUuid) {
            setErrors((prev: any) => {
              return {
                ...prev,
                practice: 'Practice Already Selected',
              };
            });
            addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME, {
              accountUuid: '',
              accountId: 0,
            });
            return;
          }
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME, {
            accountUuid: data.value,
            accountId: parseInt(data.key),
          });
          setErrors((prev: any) => {
            return {
              ...prev,
              practice: '',
            };
          });
        }}
        value={singleAccountRoleObj.accountUuid || undefined}
        mode="single"
        data={associatedAccountList}
        optionProps={{key: 'id', value: 'uuid', label: 'name'}}
        extraStyle={{flex: 1}}
      />
      <ModalActionAntSelect
        value={singleAccountRoleObj.rolesToAdd}
        mode="multiple"
        isRequired={true}
        disabled={isDisabled}
        allowClear={true}
        label={'roles'}
        placeholder="Please select"
        isInvalid={errors.roles}
        errors={errors.roles}
        onClear={() => {
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES, {
            rolesToAdd: [],
          });
        }}
        errorText={errors.roles}
        onChange={(value: any) => {
          singleAccountRoleObj.rolesToAdd = value;
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES, {
            rolesToAdd: value,
          });
        }}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            ?.toLowerCase()
            ?.includes(input?.toLowerCase());
        }}
        data={stateData?.userRoles}
        optionProps={{key: 'id', value: 'id', label: 'value'}}
        extraStyle={{flex: 1}}
      />
      {(showPractitionerDropdown && !isAccountIsMaster() &&
        singleAccountRoleObj.accountId) ? (
        <FormControl style={styles.formElement}>
          <Label>
            <HStack alignItems={'center'} marginTop={1}>
              <DisplayText
                extraStyles={styles.labelText}
                textLocalId="mapUserToEhr"
              />
              <Tooltip
                title={intl.formatMessage({id: 'mapUserToEhrText'})}
                placement={'top'}
                destroyTooltipOnHide={true}
              >
                <Icon
                  mx={2}
                  as={AntIcon}
                  color={Colors.Custom.Gray500}
                  name="infocirlceo"
                  size="smMedium"
                />
              </Tooltip>
            </HStack>
          </Label>
          <AntSelect
            className="inviteUserDrawerSelect"
            allowClear={singleAccountRoleObj.externalUserId ? true : false}
            disabled={isDisabled}
            dropdownStyle={{
              borderRadius: 20,
            }}
            onClear={() => {
              addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER, {
                externalUserId: null,
              });
            }}
            value={
              cleared ? '' : singleAccountRoleObj?.id === -1
                ? getName(singleAccountRoleObj)
                : getName(singleAccountRoleObj)
            }
            onChange={(value) => {
              if (!value) {
                setCleared(true);
                return;
              }
              value && setCleared(false);
              addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER, {
                externalUserId: value,
              });
            }}
          >
            {stateData.practitionerData.map((item, index) => {
              return (
                <AntSelect.Option
                  key={item.id.toString()}
                  value={item.id.toString()}
                  label={`${item.firstName} ${item.lastName}`}
                  className={'inviteUserDrawer'}
                  style={{paddingTop: '2px', paddingBottom: '2px'}}
                >
                  <CardForLocationAndUser
                    name={item.displayName}
                    uuid={item.id}
                    isSearchComponent={true}
                    ehrName={ehrDisplayName}
                    showAvatar={true}
                  />
                  {index < stateData.practitionerData.length - 1 ? (
                    <Divider marginX={'2'} color={Colors.Custom.Gray200} />
                  ) : null}
                </AntSelect.Option>
              );
            })}
          </AntSelect>
        </FormControl>
      ) : <></>}

      {errors.practitioner && showPractitionerDropdown && !isAccountIsMaster() && (
        <Text fontSize={'xs'} color="error.500" fontWeight={500}>
          {errors.practitioner}
        </Text>
      )}
    </View>
  );
};

export default AddAccountRoleView;
