import { HStack, Pressable, useMediaQuery, View } from 'native-base';
import React, { useState } from 'react';
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import GraphViewIcon from '../../../../common/Svg/GraphViewIcon';
import ListIconSvg from '../../../../common/Svg/ListIconSvg';
import { Styles } from './SwitchButtonStyle';

const SwitchButton = (props: {
  isTableView?: boolean;
  onValueChange?: (value: boolean) => void;
  restStyles?: any;
}) => {
  const [stateData, setStateData] = useState({
    selectedBtn: props.isTableView ? 'table' : 'graph',
  });
  const { restStyles } = props;
  const btnTextArray = [
    {
      text: 'Table View',
      Code: 'table',
    },
    {
      text: 'Graph View',
      Code: 'graph',
    },
  ];

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  return (
    <View
      style={{
        height: 40,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 4,
        paddingVertical: 8,
        marginVertical: 8,
        backgroundColor: '#fff',
        ...restStyles
      }}
    >
      {btnTextArray.map((btn) => {
        return (
          <Pressable
            key={btn.Code}
            onPress={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedBtn: btn.Code,
                };
              });
              if (props.onValueChange && typeof props.onValueChange === 'function') {
                props.onValueChange(btn.Code === 'table')
              }
            }}
          >
            <View
              style={[
                Styles.swtichBtnStyle,
              ]}
            >
              <HStack space={2}>
                {btn.Code === 'graph' && (
                  <GraphViewIcon
                    isEnabled={stateData.selectedBtn === btn.Code}
                  />
                )}

                {btn.Code === 'table' && (
                  <View marginTop={1}>
                    <ListIconSvg
                      isEnabled={stateData.selectedBtn === btn.Code}
                      customStrokeColor={stateData.selectedBtn === btn.Code ? Colors.primary['300'] : Colors?.Custom?.Gray400}
                    />
                  </View>
                )}
              </HStack>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};
export default SwitchButton;
