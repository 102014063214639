import { View, Text, ScrollView, Pressable } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import {
  CarePlanScreenContext,
  ICarePlanComponentState,
  ICarePlanDetail,
  ICarePlanProps,
} from './interfaces';
import { VStack } from 'native-base';
import { Colors } from '../../../../styles';
import Header from './components/Header';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE } from '../../../../constants';
import Feather from 'react-native-vector-icons/Feather';
import AddOrUpdateCarePlanDetails from './components/AddOrUpdateCarePlanDetails';
import {useLazyQuery} from '@apollo/client';
import {GET_CARE_PLANS_BY_CONTACT_ID, GET_CARE_PLAN_REVIEW_TASK} from '../../../../services/CarePlan/CarePlanQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {useToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';
import {FHForm} from '../../../RightSideContainer/Forms/FHFormio';
import {getEnabledFormComponents} from '../../../../utils/capabilityUtils';
import {CARE_PLAN_COMPONENT_LIST, componentKeys} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  getAccountUUID,
  getFoldAccessToken,
} from '../../../../utils/commonUtils';
import { FormViewType } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import Stack from '../../../common/LayoutComponents/Stack';
import { GET_FORM_NAME_AND_ID, GET_FORM_RESPONSE_BY_FORM_LOG_ID } from '../../../../services/Forms/FormsQueries';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {
  CARE_PLAN_STATUS_CODES,
  FORM_STATUS_CODE,
  MLOV_CATEGORY,
} from '../../../../constants/MlovConst';
import TimeLineSkeletonLoader from '../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import { NoCarePlan } from './components/NoCarePlan';
import { forEachExtensiveFormComponent } from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { GoalViewType } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface';
import { removeDeletedFormValues } from './components/utils';
import { cloneDeep } from 'lodash';
import CarePlanAudit from './CarePlanAudit/CarePlanAudit';
import CrossIconSvg from '../../../common/Svg/CrossIconSvg';
import WriteInfoBackBanner from '../CareTimeline/components/WriteInfoBackBanner';
import {LeftOutlined} from '@ant-design/icons';
import {Button} from 'antd';

const CarePlan = (props: ICarePlanProps) => {
  const intl = useIntl();
  const [componentState, setComponentState] = useState<ICarePlanComponentState>(
    {
      carePlanScreenContext: CarePlanScreenContext.ADD_CARE_PLAN,
      formResponseComponents: [],
      carePlanLoading: true,
      enabledCarePlanComponents: CARE_PLAN_COMPONENT_LIST,
      taskId: '',
    }
  );

  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData.sidecarContext?.isSidecar;
  const ehrCapabilities = contextData.ehrCapabilities || [];
  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const foldAccessToken = getFoldAccessToken();
  const accountUUID = getAccountUUID();
  const [intakeOptions, setIntakeOptions] = useState({
    foldAccessToken,
    patientId,
    contactUuid: props.contactUuid,
    accountUUID,
    contactId: props.contactUuid,
    backgroundSaveEnabled: true,
    componentView: FormViewType.widget,
    ehrCapabilities: contextData.ehrCapabilities,
    isPreviewMode: true,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    sidecarContext: contextData.sidecarContext,
    accountLocationUuid: props.accountLocationUuid,
  });

  const toast = useToast();

  const [getCarePlan] = useLazyQuery(GET_CARE_PLANS_BY_CONTACT_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getFormLogNameAndId, {loading: loadingCarePlanAssessmentFormName}] = useLazyQuery(GET_FORM_NAME_AND_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onError: (error: any) => {
      toast({
        message: intl.formatMessage({
          id: 'carePlanAssessmentFormFetchError',
        }),
        toastType: ToastType.error,
      });
    },
  });

  const [getCarePlanTask] = useLazyQuery(GET_CARE_PLAN_REVIEW_TASK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getFormResponse] = useLazyQuery(GET_FORM_RESPONSE_BY_FORM_LOG_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const carePlanStatusMlovs =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.CARE_PLAN_STATUS
    ) || [];

  const draftCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.DRAFT
  );

  const activeCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.ACTIVE,
  );

  const formStatusList =
  getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.FORM_STATUS
  ) || [];

  const submittedformStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.SUBMITTED
  );

  const inReviewCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.IN_REVIEW,
  );

  useEffect(() => {
    getCarePlanDetails();
  }, []);

  const getCarePlanDetails = async () => {
    setComponentState((prev) => ({
      ...prev,
      carePlanLoading: true,
    }));

    try {
      const carePlanResponsePromise = getCarePlan({
        variables: {
          contactId: props.contactUuid,
        },
      });

      const variables = getVariablesForCarePlanAssessmentForm();
      const formLogNameAndIdPromise = getFormLogNameAndId({
        variables: variables,
      });

      const [carePlanResponse, formLogNameAndIdResponse] = await Promise.all([
        carePlanResponsePromise,
        formLogNameAndIdPromise,
      ]);

      const carePlans = carePlanResponse.data?.getContactCarePlans?.carePlans;
      if (carePlans?.length > 0) {
        getFormResponseData(carePlans[0]);
        let carePlanRevTaskId = '';
        if (carePlans[0].statusId === inReviewCarePlanStatusId) {
          const carePlanTaskResponse = await getCarePlanTask({
            variables: {
              carePlanId: carePlans[0].id,
            },
          });
          carePlanRevTaskId = carePlanTaskResponse.data?.resourceMappings?.[0]?.resourceId as string;
        }
        setComponentState((prev) => ({
          ...prev,
          carePlanDetails: carePlans[0],
          taskId: carePlanRevTaskId,
          submittedCarePlanAssessmentForm: {
            submittedCarePlanAssessmentFormId:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.formId,
            submittedCarePlanAssessmentFormName:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.form?.name,
          },
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          carePlanLoading: false,
          submittedCarePlanAssessmentForm: {
            submittedCarePlanAssessmentFormId:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.formId,
            submittedCarePlanAssessmentFormName:
              formLogNameAndIdResponse.data?.formResponses?.[0]?.form?.name,
          },
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        carePlanLoading: false,
      }));
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const getFormResponseData = async (carePlanDetails: ICarePlanDetail) => {
    try {
      const response = await getFormResponse({
        variables: {
          formLogId: carePlanDetails.formLogId,
        },
      });
      const formResponses =
        response.data.formLogs?.[0].formResponse?.formResponse;

      const carePlanScreenContext =
        [draftCarePlanStatusId, inReviewCarePlanStatusId].includes(carePlanDetails.statusId)
          ? CarePlanScreenContext.EDIT_CARE_PLAN
          : CarePlanScreenContext.CARE_PLAN_LISTING;

      const enabledCarePlanComponents = componentState?.enabledCarePlanComponents;

      enabledCarePlanComponents.forEach((enabledCarePlanComponent) => {
        if (enabledCarePlanComponent.key === componentKeys.GOALS) {
          enabledCarePlanComponent.selectedValue = {
            goals: carePlanDetails.goals?.map((goal) => {
              goal.viewType = GoalViewType.list;
              return goal
            }) || [],
            total: carePlanDetails.goals?.length || 0
          }
        }

        if (enabledCarePlanComponent.key === componentKeys.BARRIERS) {
          enabledCarePlanComponent.selectedValue = {
            barriers: carePlanDetails.barriers || [],
          }
        }

        if (enabledCarePlanComponent.key === componentKeys.INTERVENTION) {
          const intervention = carePlanDetails.interventions || [];
          const updatedInterventions = intervention.map((item: any) => {
            return {
              ...item,
              ...item.intervention,
              carePlanInterventionId: item?.id,
              startDateTime: carePlanDetails?.startDateTime
            };
          })
          enabledCarePlanComponent.selectedValue = {
            interventions: updatedInterventions || [],
          }
        }
      })

      const intervention = carePlanDetails?.interventions || [];

      const newInterventions = intervention.map((item: any) => {
        return {
          ...item,
          intervention: {
            ...item.intervention,
            status: item.status,
            carePlanInterventionId: item?.id
          },
          carePlanInterventionId: item?.id,
          startDateTime: carePlanDetails.startDateTime
        };
      })
      const data: any = {};
      // status updated in form response
      forEachExtensiveFormComponent(formResponses, (item: any) => {
        data[item.key] = item.selectedValue;
        if ((item.type === componentKeys.INTERVENTION && item.selectedValue.interventions.length > 0) ||
            (item.type !== componentKeys.INTERVENTION && item?.components?.length > 0)) {
                if (item.type === componentKeys.INTERVENTION && item.selectedValue.interventions.length > 0) {
                  item.selectedValue.interventions.forEach((oldInt: any) => {
                        const matchedIntervention = newInterventions.find((newInt: any) => newInt.intervention.id === oldInt.id);
                        if (matchedIntervention) {
                            oldInt.status = matchedIntervention.status;
                            oldInt.carePlanInterventionId = matchedIntervention.carePlanInterventionId;
                            oldInt.startDateTime = matchedIntervention.startDateTime;
                        }
                    });
                }
        }
    });

      setComponentState((prev) => ({
        ...prev,
        formResponseComponents: removeDeletedFormValues(formResponses) || [],
        carePlanScreenContext: carePlanScreenContext,
        enabledCarePlanComponents: enabledCarePlanComponents,
        carePlanLoading: false,
        submittedResponse: { data },
      }));
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        carePlanLoading: false,
      }));
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const getVariablesForCarePlanAssessmentForm = () => {
    return {
      where: {
        isDeleted: {
          _eq: false,
        },
        contactId: {
          _eq: props?.contactUuid,
        },
        form: {
          formCategory: {
            code: {
              _eq: 'CARE_PLAN_ASSESSMENT',
            },
          },
        },
        formLog: {
          statusId: {
            _eq: submittedformStatusId,
          },
          isDeleted: {
            _eq: false,
          },
          contactId: {
            _eq: props?.contactUuid,
          },
        },
      },
    };
  }

  const getHeaderActions = () => {
    if (
      componentState.carePlanScreenContext ===
      CarePlanScreenContext.ADD_CARE_PLAN
    ) {
      return (
        <FoldButton
          customProps={{
            btnText: intl.formatMessage({ id: 'plan' }),
          }}
          nativeProps={{
            leftIcon: (
              <Feather
                name="plus"
                color={Colors.Custom.mainPrimaryPurple}
                size={16}
              />
            ),
            backgroundColor: Colors.Custom.BackgroundColor,
            variant: BUTTON_TYPE.PRIMARY,
            color: Colors.Custom.mainPrimaryPurple,
            onPress: () => {
              setComponentState((prev) => ({
                ...prev,
                carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
              }));
            },
          }}
        />
      );
    } else if (componentState.carePlanScreenContext === CarePlanScreenContext.CARE_PLAN_LISTING) {
      return (
        <Stack direction="row" space={16}>
          <Pressable
            onPress={() => {
              setComponentState((prev) => ({
                ...prev,
                carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
              }));
            }}>
              {<Text style={{color: Colors.Custom.mainPrimaryPurple, fontSize: 14}}>{'Edit Care Plan'}</Text>}
          </Pressable>
          <View style={{ backgroundColor: Colors.Custom.Gray500, width: 0.2 }} />
          <Pressable
            onPress={() => {
              setComponentState((prev) => ({
                ...prev,
                carePlanScreenContext: CarePlanScreenContext.CARE_PLAN_AUDIT,
                carePlanDiff: {
                  old: [],
                  new: [],
                }
              }));
            }}>
            <Text style={{color: Colors.Custom.mainPrimaryPurple, fontSize: 14}}>{'History'}</Text>
          </Pressable>
        </Stack>
      );
    } else if (componentState.carePlanScreenContext === CarePlanScreenContext.CARE_PLAN_AUDIT) {
      return (
        <Pressable onPress={() => {
          setComponentState((prev) => ({
            ...prev,
            carePlanScreenContext:
              CarePlanScreenContext.CARE_PLAN_LISTING,
          }));
        }}>
          <CrossIconSvg
            size={18}
          />
        </Pressable>
      );
    }


  };
  const isInReviewed = componentState.carePlanDetails?.statusId === inReviewCarePlanStatusId;
  const getCarePlanTitleBasedOnStatus = (statusId?: string) => {
    if (isInReviewed)  {
      return 'inReview'
    }
    return 'assignedToPatient'
  }

  const renderMainViewByScreenContext = () => {
    switch (componentState.carePlanScreenContext) {
      case CarePlanScreenContext.ADD_CARE_PLAN:
        return (
          <Stack direction="column" style={{flex: 1}}>
            <Header
              context={componentState.carePlanScreenContext}
              headerAction={getHeaderActions()}
              title={intl.formatMessage({id: 'carePlan'})}
            />
            {!!componentState?.submittedCarePlanAssessmentForm
              ?.submittedCarePlanAssessmentFormId && (
              <WriteInfoBackBanner
                message={intl.formatMessage({
                  id: 'assessmentFormSubmittedInstruction',
                })}
              />
            )}
            <NoCarePlan title="noCarePlan" />
          </Stack>
        );
      case CarePlanScreenContext.CARE_PLAN_LISTING:
        return (
          <Stack direction="column" style={{flex: 1}}>
            <Header
              prefixAction={
                isSidecar ? (
                  <Button onClick={() =>  props?.onClose?.(false)} type="text" icon={<LeftOutlined />}/>
                ) : undefined
              }
              context={componentState.carePlanScreenContext}
              headerAction={getHeaderActions()}
              title={intl.formatMessage({id: 'carePlanDetails'})}
              subTitle={getCarePlanTitleBasedOnStatus(
                componentState?.carePlanDetails?.statusId
              )}
              subTitleStyle={{
                color: isInReviewed
                  ? Colors.Custom.orangeMessageTopBar
                  : Colors.Custom.Gray500,
              }}
            />
            <ScrollView style={{padding: 16}}>
            {!!componentState?.submittedCarePlanAssessmentForm
                ?.submittedCarePlanAssessmentFormId && (
                <WriteInfoBackBanner
                  message={intl.formatMessage({
                    id: 'assessmentFormSubmittedInstruction',
                  })}
                />
              )}
              <FHForm
                optionData={intakeOptions}
                components={componentState.enabledCarePlanComponents}
              />
            </ScrollView>
          </Stack>
        );
      case CarePlanScreenContext.EDIT_CARE_PLAN:
        return (
          <AddOrUpdateCarePlanDetails
            carePlanReviewTaskId={componentState?.taskId}
            onCarePlanAdded={()=> {
              if (isSidecar) {
                props?.onClose?.(true);
                return;
              }
              getCarePlanDetails();
            }}
            onCancelClick={() => {
              if (isSidecar) {
                props?.onClose?.(false);
                return
              }
              if (componentState?.carePlanDetails?.statusId === inReviewCarePlanStatusId) {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.EDIT_CARE_PLAN,
                }));
              } else if (componentState?.carePlanDetails?.statusId === activeCarePlanStatusId) {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.CARE_PLAN_LISTING,
                }));
              } else {
                setComponentState((prev) => ({
                  ...prev,
                  carePlanScreenContext: CarePlanScreenContext.ADD_CARE_PLAN,
                }));
              }
            }}
            carePlanDetails={componentState?.carePlanDetails}
            formResponseComponents={cloneDeep(componentState?.formResponseComponents)}
            submittedCarePlanAssessmentForm={componentState?.submittedCarePlanAssessmentForm}
            loadingCarePlanAssessmentFormName={loadingCarePlanAssessmentFormName}
            submittedResponse={componentState.submittedResponse}
            {...props}
          />
        );

      case CarePlanScreenContext.CARE_PLAN_AUDIT:
        if (componentState.carePlanDetails) {
          return (
            <Stack direction="column" style={{ flex: 1 }}>
              <Header
                context={componentState.carePlanScreenContext}
                headerAction={getHeaderActions()}
                title={intl.formatMessage({ id: 'carePlanHistory' })}
              />
              <ScrollView style={{ padding: 16 }}>
              {!!componentState?.submittedCarePlanAssessmentForm
                  ?.submittedCarePlanAssessmentFormId && (
                  <WriteInfoBackBanner
                    message={intl.formatMessage({
                      id: 'assessmentFormSubmittedInstruction',
                    })}
                  />
                )}
                {![draftCarePlanStatusId, inReviewCarePlanStatusId].includes(componentState.carePlanDetails.statusId) && (
                  <CarePlanAudit
                    carePlanDetails={componentState.carePlanDetails}
                    components={cloneDeep(componentState?.formResponseComponents)}
                  />
                )}
              </ScrollView>
            </Stack>
          );
        }
        break;
      default:
        return <View />;
    }
  };

  return (
    <VStack
      backgroundColor={Colors.Custom.BackgroundColor}
      flex={1}
      mt={isSidecar ? undefined : 2}
      style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
    >
      {componentState?.carePlanLoading && <TimeLineSkeletonLoader />}
      {!componentState?.carePlanLoading && renderMainViewByScreenContext()}
    </VStack>
  );
};

export default CarePlan;
