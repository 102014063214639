import {Dropdown, Menu} from 'antd';
import {Button, HStack, Icon, Text, useMediaQuery, View} from 'native-base';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import {
  getOnFocusButtonStyles,
  isEmployerRole,
} from '../../../../utils/commonUtils';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../Svg/PlusSvg';

export interface IDropdownButtonProps {
  title: string;
  showBtnTextForSmallScreen?: boolean;
  onClick?: (key: string) => void;
  isDisabled?: boolean;
}

export enum addTaskButtonKeys {
  addTask = 'AddTask',
  addTaskPool = 'AddTaskPool',
}

const CustomAddTaskButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  const addNewMenu = () => {
    return (
      <Menu
        items={[
          {
            key: addTaskButtonKeys.addTask,
            label: intl.formatMessage({id: 'addTask'}),
          },
          {
            key: addTaskButtonKeys.addTaskPool,
            label: intl.formatMessage({id: 'addTaskPool'}),
          },
        ]}
        onClick={(data: any) => {
          if (props.onClick && typeof props.onClick === 'function') {
            props.onClick(data.key)
          }
        }
        }
      />
    );
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const isEmployer = isEmployerRole();

  return (
    <View
      style={{
        padding: 5,
        height: 36,
        marginTop: 1,
        paddingTop: 6,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FoldButton
        nativeProps={{
          isDisabled: props.isDisabled,
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            if (props.onClick && typeof props.onClick === 'function') {
              props.onClick(addTaskButtonKeys.addTask);
            }
          },
          leftIcon: (
            <PlusIcon defaultColor={props.isDisabled ? Colors.Custom.Gray300 : '#825AC7'}/>
          ),
          rightIcon: (
            <>
              {!isEmployer ? (
                <HStack>
                  <Dropdown
                    overlay={!props.isDisabled ? addNewMenu : <></>}
                    placement="bottomRight"
                    overlayStyle={{width: 150}}
                  >
                    <Icon
                      onPress={() => {}}
                      color={props.isDisabled ? Colors.Custom.Gray300 : '#825AC7'}
                      as={AntIcon}
                      name={'down'}
                      size="4"
                    />
                  </Dropdown>
                </HStack>
              ) : (
                <></>
              )}
            </>
          ),
          style: {minWidth: 'auto'}
        }}
        customProps={{
          btnText: (!isSmallScreen || props.showBtnTextForSmallScreen) && props.title ? props.title : '',
          withRightBorder: true,
          isDisableOnPress: true
        }}
      ></FoldButton>
    </View>
  );
};

export default CustomAddTaskButton;
