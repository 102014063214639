import {Editor} from '@toast-ui/react-editor';
import {MutableRefObject} from 'react';

export const getSearchStringForCannedResponseEditor = (
  editorRef: MutableRefObject<Editor | null>
) => {
  if (!editorRef.current) {
    return '';
  }
  const inst = editorRef.current.getInstance();
  const markdown = inst.getMarkdown();
  if (markdown.indexOf('\n') !== -1) {
    return markdown.substring(0, markdown.indexOf('\n'));
  }
  return markdown;
};
