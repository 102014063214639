import {
  Text,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {FormError} from '../../CustomWrapper/CustomComponentHelper';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { cloneDeep } from 'lodash';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { Colors } from '../../../../../../../styles';
import ReasonForVisitSearch from '../../../../../../common/ReasonForVisitSearch/ReasonForVisitSearch';
import { ADD_NEW_REASON_FOR_VISIT } from '../../../../../../../constants';
import ReasonForVisitFreeText from '../../../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';


export interface IChiefComplaintComponentValue {
  chiefComplaint?: {
    code?: string;
    displayName?: string,
    type?: string,
  }
}

interface IAddOrUpdateChiefComplaintState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
}

const AddOrUpdateChiefComplaint = (props: IFormComponentProps) => {
  const { options } = props;
  const componentRef = useRef();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isDoNotAllowToReplaceNextLine = options?.isDoNotAllowToReplaceNextLine || false;
  const [componentValue, setComponentValue] =
    useState<IChiefComplaintComponentValue>(
      props.component?.selectedValue?.chiefComplaint ? { chiefComplaint: props.component?.selectedValue?.chiefComplaint } : {}
    );
  const [componentState, setComponentState] =
    useState<IAddOrUpdateChiefComplaintState>({
      showErrors: false,
      loading: false,
      // For chief complaint, we don't need to show form error as save to resource fail or section not applicable
      // Thus commenting below change
      // formError: contextData.formError,
    });

  const validateData = (currentData: IChiefComplaintComponentValue): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData?.chiefComplaint?.displayName, message: !currentData?.chiefComplaint?.displayName ? `${props.component.label} is required` : '' };
    }
    return { isValid: true, message: '' };
  };

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  props.validateRef.current = validateData;
  if (isPreviewMode) {
    let displayValue = componentValue.chiefComplaint?.displayName ?? '';
    if ((displayValue || '')?.trim().length > 0 && isDoNotAllowToReplaceNextLine) {
      displayValue = displayValue.replaceAll('\n', '<br/>');
    }
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {displayValue ? (
              <div className='page-break'>
                <DetailPreview titleLocalId={props.component.label}>
                  <VStack>
                    <Text fontSize="md">
                      {displayValue}
                    </Text>
                  </VStack>
                </DetailPreview>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <VStack ref={componentRef}>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      {!componentState.loading && !componentState.formError && (
        <VStack space={4} flex={1}>
          <ReasonForVisitFreeText
            macroTemplatesEnabled={props.component?.macroTemplatesEnabled && !isPatientForm}
            customStyles={{fontSize:17}}
            numberOfLines={3}
            value={componentValue.chiefComplaint?.displayName}
            isDisabled={props.disabled}
            isRequired={props.component?.validate?.required}
            isShowError={componentState.showErrors && !componentValue.chiefComplaint?.displayName}
            customPlaceHolder={props.component.placeHolder}
            onChange={(value) => {
              if (value?.type === ADD_NEW_REASON_FOR_VISIT) {
                value.code = undefined;
              }
              // removing ...prev as componentValue contains only one key
              setComponentValue((prev) => ({chiefComplaint: value}));
            }}
          />
        </VStack>
      )}
    </VStack>
  );
};

export default AddOrUpdateChiefComplaint;
