import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  CarePlanHeaderActions,
  CarePlanScreenContext,
  IAddOrUpdateCarePlanProps,
  ICarePlanDetailsProps,
  ICarePlanDetailsState,
  ICarePlanProps,
} from '../interfaces';
import Header from './Header';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {Colors} from '../../../../../styles';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../../constants';
import {Button, Modal, Popover, Tooltip} from 'antd';
import {
  Box,
  View,
  Text,
  VStack,
  HStack,
  Skeleton,
  IconButton,
  FlatList,
  Pressable,
  Divider,
  Spinner,
  ScrollView,
  Badge,
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import FormsQueries, {
  GET_CARE_PLAN_FORMS,
} from '../../../../../services/Forms/FormsQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import CarePlanTemplatesView from './CarePlanTemplatesView';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getAccountUUID,
  getFoldAccessToken,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {FormContext, FormViewType} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {FHForm} from '../../../../RightSideContainer/Forms/FHFormio';
import ReactToPrint from 'react-to-print';
import {PrintContactContent} from '../../../../common/PrintContactContent/PrintContactContent';
import {createTaskForReviewer, getCarePlanTitle, getPostDataForCarePlan, getSubmittedResponseFromComponents, setComponentsSelectedValues, mergeComponents, setDefaultComponentValues, updateReviewTaskStatus, validateCarePlanPostData} from './utils';
import {COMPONENTS_TO_MERGE, CONTAINER_COMPONENTS, CarePlanViewType} from '../CarePlanConstants';
import {ADD_OR_UPDATE_CARE_PLAN} from '../../../../../services/CarePlan/CarePlanQueries';
import {useToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import { getMlovByCategory, getMlovIdFromCode, getMlovIdFromCodeCategory, getMlovListByCategoryCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { CARE_PLAN_STATUS_CODES } from '../../../../../constants/MlovConst';
import SignIcon from '../../../../../assets/Icons/SignIcon';
import { title } from 'process';
import { NoCarePlan } from './NoCarePlan';
import Stack from '../../../../common/LayoutComponents/Stack';
import { forEachExtensiveFormComponent } from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {v4 as uuidv4} from 'uuid';
import FormResponseView from '../../../../common/FormResponseView/FormResponseView';
import ProviderSelectForReview from './ProviderSelectForReview';
import {TaskQueries} from '../../../../../services';
import {IMlov} from '../../../../../Interfaces';
import {getDefaultPriorityId, getDefaultTaskStatusId} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import OverrideAntdModal from '../../../../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import ConfirmAssignInReviewCarePlan from './ConfirmAssignInReviewCarePlan';
import {getCompletedTaskStatusId} from '../../../../common/Tasks/TasksUtils/TasksUtils';
import { cloneDeep, isEqual } from 'lodash';
import CarePlanDiff from '../CarePlanDiff/CarePlanDiff';
import FoldButtonV2 from './FoldButtonV2';
import DeleteSvg from '../../../../common/Svg/DeleteSvg';
import CrossIconSvg from '../../../../common/Svg/CrossIconSvg';
import {SidecarPatientInfoBanner} from '../../../../SideCar/SidecarPatientInfoBanner';
import {SidecarPatientDataContext} from '../../../../SideCar/SidecarTaskDrawer';
import {LeftOutlined} from '@ant-design/icons';
import CarePlanReviewIcon from '../../../../../assets/Icons/CarePlanReviewIcon';

const AddOrUpdateCarePlanDetails = (props: IAddOrUpdateCarePlanProps) => {
  const toast = useToast();
  const intl = useIntl();
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData?.sidecarContext?.isSidecar;
  const sidecarPatientContextData = useContext(SidecarPatientDataContext);
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const ehrCapabilities = contextData.ehrCapabilities || [];
  const foldAccessToken = getFoldAccessToken();
  const [submitData, setSubmitData] = useState<any>({});
  const [addOrUpdateCarePlan] = useMutation(ADD_OR_UPDATE_CARE_PLAN, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [createTask] = useMutation(TaskQueries.ADD_OR_UPDATE_TASK);
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const [oldResponse] = useState(cloneDeep(props.submittedResponse));
  const [componentState, setComponentState] = useState<ICarePlanDetailsState>({
    formList: [],
    components: props.formResponseComponents || [],
    loadingFormIds: [],
    selectedFormIds: [],
    startRenderPrintComponent: false,
    formNamesMap: {},
    id: undefined,
    statusId: props.carePlanDetails?.statusId || '',
    isEditCarePlan: false,
    title: '',
    formIds: [],
    submittedResponse: {},
    showSubmittedCarePlanAssessmentFormDrawer: false,
    showProviderSelectionForReview: false,
    selectedProviderId: '',
    confirmInReviewAssignCarePlan: false,
    diffView: undefined,
    viewTypeLoading: false,
  });
  const [viewType, setViewType] = useState<CarePlanViewType>(CarePlanViewType.edit);

  const carePlanStatusMlovs =
  getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.CARE_PLAN_STATUS
  ) || [];


  const activeCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.ACTIVE,
  );

  const draftCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.DRAFT,
  );

  const inReviewCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.IN_REVIEW,
  );

  const isAssigned = useMemo(
    () => props.carePlanDetails?.statusId === activeCarePlanStatusId,
    [props.carePlanDetails?.statusId]
  );

  const isInReview = useMemo(
    () => props.carePlanDetails?.statusId === inReviewCarePlanStatusId,
    [props.carePlanDetails?.statusId]
  );

  const hideCancelButton = props.carePlanDetails?.statusId === draftCarePlanStatusId || props.carePlanDetails?.statusId === inReviewCarePlanStatusId;

  const taskPriorityList: IMlov[] = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  const taskStatusList: IMlov[] = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];

  const defaultTaskStatusId: string = useMemo(
    () => getDefaultTaskStatusId(taskStatusList),
    [taskStatusList.length]
  );

  const completedTaskStatusId = getCompletedTaskStatusId(taskStatusList);

  const defaultTaskPriorityId = useMemo(
    () => getDefaultPriorityId(taskPriorityList),
    [taskPriorityList.length]
  );


  useEffect(()=> {
    if(props.carePlanDetails?.id){
      setComponentState((prev) => ({
        ...prev,
        id: props.carePlanDetails?.id,
        formIds: props.carePlanDetails?.formId ? [props.carePlanDetails?.formId] : [],
        selectedFormIds: props.carePlanDetails?.selectedFormIds ? props.carePlanDetails?.selectedFormIds : [],
        title: props.carePlanDetails?.title || '',
        isEditCarePlan: true,
        submittedResponse: getSubmittedResponseFromComponents(componentState.components),
        statusId: props.carePlanDetails?.statusId,
      }));
    }
  }, [props.carePlanDetails])


  const handleFormChange = useCallback((data: any) => {
    setSubmitData(data);
  }, []);

  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  // THIS IS HANDLED FOR SAVE BUTTON ONLY
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

  const onSaveCarePlan = async (statusId: string, params:{
    reviewerId?: string,
    updateRelatedTask?: boolean,
    skipLoading?: boolean,
    closeDrawer?: boolean,
    isSaveInReview?: boolean
  }) => {
    const {reviewerId, skipLoading, closeDrawer, isSaveInReview} = params;
    const isSendForReview = statusId === inReviewCarePlanStatusId && reviewerId;

    const carePlanPostData = {
      ...componentState,
      statusId: statusId
    }

    const validationErrors = validateCarePlanPostData(carePlanPostData, componentState?.isEditCarePlan);

    if(validationErrors?.length && validationErrors?.length > 0){
      toast({
        message: validationErrors[0].errorMessage,
        toastType: ToastType.error,
      });
      return;
    }
    if (!skipLoading) {
      setSaveLoading(true);
    }
    try {
      const carePlanData = getPostDataForCarePlan(carePlanPostData, submitData, props.contactUuid, !isAssigned);

      const response = await addOrUpdateCarePlan({
        variables: {
          params: carePlanData,
        },
      });
      if (isSendForReview) {
        const taskResponse = await createTaskForReviewer({
          carePlanId: response.data.addOrUpdateContactCarePlan.carePlanId,
          reviewerId: reviewerId,
          priorityId: defaultTaskPriorityId,
          statusId: defaultTaskStatusId,
          contactUuid: props.contactUuid,
          loginUserId: userUUID,
          title: `Care Plan Review`,
          taskPromise: createTask,
        });

        if (!taskResponse) {
          toast({
            message: 'Something went wrong while sending care plan for review',
            toastType: ToastType.error,
          });
          setSaveLoading(false);
          return;
        }
      }

      if (params?.updateRelatedTask && !!props.carePlanReviewTaskId)  {
        const { success } = await updateReviewTaskStatus({
          taskId: props.carePlanReviewTaskId,
          taskPromise: updateTask,
          statusId: completedTaskStatusId,
        })

        if (!success) {
          toast({
            message: 'Something went wrong while updating related task',
            toastType: ToastType.error,
          });
          setSaveLoading(false);
        }


      }

      if (response.data.addOrUpdateContactCarePlan.carePlanId) {
        setComponentState((prev) => ({
          ...prev,
          id: response.data.addOrUpdateContactCarePlan.carePlanId,
        }));

        let msg = '';

        switch (statusId) {
          case activeCarePlanStatusId:
            msg = 'carePlanAssignedSuccessMsg';
            break;
          case draftCarePlanStatusId:
            msg = 'carePlanSavedSuccessMsg';
            break;
          case inReviewCarePlanStatusId:
            if (isSaveInReview) {
              msg = 'carePlanSavedSuccessMsg';
            } else {
              msg = 'carePlanSentForReviewSuccessMsg';
            }
            break;
        }

        toast({
          message: intl.formatMessage({
            id: msg,
          }),
          toastType: ToastType.success,
        });

        if (closeDrawer) {
          props.onCancelClick?.();
          setSaveLoading(false);
          setSaveBtnLoading(false);
          return;
        }
        props.onCarePlanAdded?.();

      }
      setSaveLoading(false);
      setSaveBtnLoading(false);
    } catch (error) {
      setSaveLoading(false);
      setSaveBtnLoading(false);
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const onActionPerformed = (action: CarePlanHeaderActions) => {
    switch (action) {
      case CarePlanHeaderActions.ASSIGNED_TO_PATIENT:
        if (isInReview) {
          setComponentState((prev) => ({
            ...prev,
            confirmInReviewAssignCarePlan: true,
          }));
        } else {
          onSaveCarePlan(activeCarePlanStatusId, {});
        }
        break;
      case CarePlanHeaderActions.SAVE_AS_DRAFT:
        onSaveCarePlan(draftCarePlanStatusId, {});
        break;
      case CarePlanHeaderActions.DOWNLOAD:
        break;
      case CarePlanHeaderActions.PRINT:
        setComponentState((prev) => ({
          ...prev,
          startRenderPrintComponent: true,
        }));
        break;
      case CarePlanHeaderActions.SEND_FOR_REVIEW:
        setComponentState((prev) => ({
          ...prev,
          showProviderSelectionForReview: true,
        }));
        break;
      case CarePlanHeaderActions.PREVIEW_CHANGES:
        setComponentState((prev) => {
          const oldComponents = cloneDeep(prev.components || []);
          const newComponents = cloneDeep(prev.components || []);
          forEachExtensiveFormComponent(oldComponents, (item: any) => {
            item.selectedValue = oldResponse?.data?.[item.key];
          });
          forEachExtensiveFormComponent(newComponents, (item: any) => {
            item.selectedValue = submitData?.data?.[item.key];
          });
          forEachExtensiveFormComponent(prev.components, (item: any) => {
            item.selectedValue = submitData?.data?.[item.key];
          });

          return {
            ...prev,
            diffView: {
              oldComponents: oldComponents,
              newComponents: newComponents,
            },
            submittedResponse: {data: cloneDeep(submitData?.data || {})},
          };
        });
        setViewType(CarePlanViewType.preview);
        break;

      case CarePlanHeaderActions.EDIT_CARE_PLAN:
        setComponentState((prev) => {
          return {
            ...prev,
            diffView: undefined,
          };
        });
        setViewType(CarePlanViewType.edit);
        break;
      case CarePlanHeaderActions.SAVE_CARE_PLAN_IN_REVIEW:
        setSaveBtnLoading(true);
        onSaveCarePlan(inReviewCarePlanStatusId, {
          updateRelatedTask: false,
          reviewerId: undefined,
          skipLoading: true,
          closeDrawer: true,
          isSaveInReview: true,
        });
        break;
    }
  };

  const onCloseSendForReview = useCallback(() => {
    setComponentState((prev) => ({
      ...prev,
      showProviderSelectionForReview: false,
    }));
  },[])

  const [getFormById] = useLazyQuery(FormsQueries.GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const carePlanForms = useQuery(GET_CARE_PLAN_FORMS, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      fetchPolicy: 'no-cache',
    },
  });
  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const accountLocationUuid = props.personData?.accountLocationUuid;
  const [intakeOptions] = useState({
    foldAccessToken,
    accountUUID,
    patientId,
    contactId: props.contactUuid,
    backgroundSaveEnabled: false,
    ehrCapabilities: contextData.ehrCapabilities,
    accountLocationUuid: props.accountLocationUuid,
    formContext: FormContext.patientCarePlan,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    showDeleteButton: !isAssigned,
    sidecarContext: contextData.sidecarContext,
    onDeleteClick: (component: any) => {
      let submitData: any
      setSubmitData((prev: any) => {
        submitData = prev
        return {
          ...prev
        }
      })
      setComponentState((prev) => {
        const formChanges = submitData.data
        const updatedStates = setComponentsSelectedValues(prev.components, formChanges)
        const filteredComponents = removeComponent(updatedStates, component);
        return {
          ...prev,
          components: filteredComponents
        }
      })
    }
  });

  const isCarePlanDetailsChanged = () => {
    if (!submitData?.data) {
      return false;
    }
    return !isEqual(oldResponse?.data, submitData?.data);
  }

  const removeComponent = (components: any[], component: any) => {
    let found = false;
    const filteredComponents = components.filter((filteredComponent) => {
      if (filteredComponent.key === component.key) {
        found = true;
        return false;
      }
      return true;
    });

    if (found) {
      return filteredComponents;
    }

    filteredComponents.map((filteredComponent) => {
      if (filteredComponent.components?.length > 0) {
        filteredComponent.components = removeComponent(filteredComponent.components, component);
      }
      return filteredComponent;
    });

    return filteredComponents;
  }

  const getMergedFormComponents = (newComponents: any[]) => {
    const oldComponents = componentState.components;
    const oldComponentTypes = oldComponents.map((item) => item.type);

    newComponents.forEach((item) => {
      if (
        oldComponentTypes.includes(item.type) &&
        COMPONENTS_TO_MERGE.includes(item.type)
      ) {
        const matched = oldComponents.find(
          (component) => component.type === item.type
        );
        if (matched) {
          mergeComponents(matched, item);
        }
      } else {
        oldComponents.push(item);
      }
    });

    const uniqueComponentKeys : string[] = [];

    forEachExtensiveFormComponent(oldComponents || [], (component) => {
      if(!CONTAINER_COMPONENTS.includes(component.key)){
        if(uniqueComponentKeys.includes(component.key)){
          const newKey = `${component.key}_${uuidv4()}`;
          component.key = newKey;
          uniqueComponentKeys.push(newKey);
        }
        else {
          uniqueComponentKeys.push(component.key);
        }
      }
    });

    return oldComponents.filter((item) => item.key !== 'submit');
  };

  const onTemplateSelect = async (formId: string) => {
    try {
      setComponentState((prev) => ({
        ...prev,
        loadingFormIds: [...prev.loadingFormIds, formId],
        selectedFormIds: [...prev.selectedFormIds, formId],
      }));
      const formResponse = await getFormById({
        variables: {
          id: formId,
        },
      });
      const components = setDefaultComponentValues(formResponse.data.form?.components || []);
      const mergedComponents = getMergedFormComponents(components);

      setComponentState((prev) => {
        return {
          ...prev,
          components: mergedComponents,
          loadingFormIds: prev.loadingFormIds.filter((item) => item !== formId),
          formNamesMap: {
            ...prev.formNamesMap,
            [formId]: formResponse.data.form?.name,
          },
        };
      });
    } catch {
      setComponentState((prev) => {
        return {
          ...prev,
          loadingFormIds: prev.loadingFormIds.filter((item) => item !== formId),
          selectedFormIds: prev.selectedFormIds.filter(
            (item) => item !== formId
          ),
        };
      });
    }
  };

  const onSelectProviderForReview = (providerId: string) => {
    onCloseSendForReview();
    onSaveCarePlan(inReviewCarePlanStatusId, {
      reviewerId: providerId,
    });
  }

  const getHeaderSubTitle = (statusId?: string) => {
    let msg = '';
    switch (statusId) {
      case activeCarePlanStatusId:
        msg = 'assigned';
        break;
      case draftCarePlanStatusId:
        msg = 'draft';
        break;
      case inReviewCarePlanStatusId:
        msg = 'inReview';
        break;
      default:
        msg = '';
    }
    return msg;
  }

  const renderActionMenu = () => {
    const actions: any[] = [];
    actions.push({
      btnText: intl.formatMessage({
        id: 'publish',
      }),
      action: CarePlanHeaderActions.ASSIGNED_TO_PATIENT,
      isDisabled: false,
    });
    if (!isInReview) {
      actions.push({
        btnText: intl.formatMessage({
          id: 'saveAsDraft',
        }),
        action: CarePlanHeaderActions.SAVE_AS_DRAFT,
        isDisabled: false,
      });
    }

    if (!isAssigned && !isInReview) {
      actions.push({
        btnText: intl.formatMessage({
          id: 'sendForReview',
        }),
        action: CarePlanHeaderActions.SEND_FOR_REVIEW,
        isDisabled: false,
      });
    }

    return (
      <FlatList
        data={actions}
        renderItem={({item, index}) => (
          <>
            <Pressable
              disabled={item.isDisabled}
              flexDirection="row"
              alignItems="center"
              my={1.5}
              onPress={() => onActionPerformed(item.action)}
            >
              {item.icon}
              <Text
                color={Colors.Custom.Gray500}
                fontSize={12}
                fontWeight="400"
                marginLeft={item?.icon ? 4 : undefined}
              >
                {item.btnText}
              </Text>
            </Pressable>
            {index !== actions.length - 1 && <Divider />}
          </>
        )}
      />
    );
  };

  const renderShowAssessmentButton = () => {
    if (isSidecar) {
     return (
       <Tooltip title="Show Assessment">
         <Button
          type="text"
          icon={<CarePlanReviewIcon />}
          onClick={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showSubmittedCarePlanAssessmentFormDrawer:
                  !prev.showSubmittedCarePlanAssessmentFormDrawer,
              };
            });
          }}
         />
       </Tooltip>
     );
    }
    return (
      <FoldButtonV2
        label={
          componentState?.showSubmittedCarePlanAssessmentFormDrawer
            ? 'Hide Assessment'
            : 'Show Assessment'
        }
        buttonType={'secondary'}
        isDisabled={componentState.viewTypeLoading}
        isLoading={componentState.viewTypeLoading}
        onPress={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              showSubmittedCarePlanAssessmentFormDrawer:
                !prev.showSubmittedCarePlanAssessmentFormDrawer,
            };
          });
        }}
      />
    );
  };


  const getHeaderActions = () => {
    return (
      <>
        {props?.loadingCarePlanAssessmentFormName ? (
          <Spinner />
        ) : (
          !!props?.submittedCarePlanAssessmentForm
            ?.submittedCarePlanAssessmentFormId && (
            <>
              {renderShowAssessmentButton()}
              <View
                backgroundColor={Colors.Custom.Gray500}
                width={0.2}
                height={6}
                marginX={1}
              />
            </>
          )
        )}

        {!!isPreview && (
          <FoldButtonV2
            key={'EditButton'}
            label={intl.formatMessage({id: 'edit' })}
            buttonType={'secondary'}
            isDisabled={saveLoading || componentState.viewTypeLoading}
            isLoading={saveLoading || componentState.viewTypeLoading}
            onPress={() => {
              onActionPerformed(CarePlanHeaderActions.EDIT_CARE_PLAN);
            }}
          />
         )}
        {isAssigned && !isPreview ? (
          <FoldButtonV2
            key={'PreviewButton'}
            label={'Preview Changes'}
            buttonType={'secondary'}
            isDisabled={componentState.viewTypeLoading}
            isLoading={componentState.viewTypeLoading}
            onPress={() => {
              onActionPerformed(CarePlanHeaderActions.PREVIEW_CHANGES);
            }}
          />
        ) : (
          <></>
        )}
        {isInReview && (
          <>
            <FoldButtonV2
              key={'SaveButton'}
              label={intl.formatMessage({id: 'save' })}
              buttonType='secondary'
              isDisabled={saveBtnLoading || componentState.viewTypeLoading || saveLoading}
              isLoading={saveBtnLoading || componentState.viewTypeLoading}
              onPress={() => {
                onActionPerformed(CarePlanHeaderActions.SAVE_CARE_PLAN_IN_REVIEW);
              }}
            />
          </>
        )}
        {!isAssigned && !isInReview && (
          <FoldButton
            nativeProps={{
              disabled: saveLoading || componentState.viewTypeLoading,
              backgroundColor: Colors.Custom.BackgroundColor,
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                onActionPerformed(CarePlanHeaderActions.ASSIGNED_TO_PATIENT);
              },
              rightIcon: saveLoading || componentState.viewTypeLoading ? (
                <Spinner size="sm" color={Colors.Custom.mainPrimaryPurple} />
              ) : (
                <Popover
                  overlayClassName="custom-popover-styles"
                  showArrow={true}
                  destroyTooltipOnHide
                  placement="bottomRight"
                  content={renderActionMenu}
                  trigger="click"
                  overlayInnerStyle={{
                    borderRadius: 16,
                    width: 140,
                    padding: 0,
                  }}
                >
                  <Box>
                    <Feather
                      name={'chevron-down'}
                      size={20}
                      color={Colors.Custom.mainPrimaryPurple}
                      style={{
                        paddingHorizontal: 10,
                        alignSelf: 'center',
                      }}
                    />
                  </Box>
                </Popover>
              ),
              style: {minWidth: 'auto'},
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'publish',
              }),
              withRightBorder: true,
              isDisableOnPress: true,
            }}
          />
        )}
        {isAssigned || isInReview ? (
          <FoldButtonV2
            key={'PublishButton'}
            label={intl.formatMessage({id: 'publish'})}
            buttonType="primary"
            isDisabled={
              saveLoading || componentState.viewTypeLoading || saveBtnLoading
            }
            isLoading={saveLoading || componentState.viewTypeLoading}
            onPress={() => {
              onActionPerformed(CarePlanHeaderActions.ASSIGNED_TO_PATIENT);
            }}
          />
        ) : (
          <></>
        )}
        {!hideCancelButton && !isSidecar && (
        <>
          <View backgroundColor={Colors.Custom.Gray500} width={0.2} height={6} marginX={1} />
          <Pressable key={'CrossButton'} onPress={props.onCancelClick} isDisabled={saveLoading}>
            <CrossIconSvg
              size={18}
            />
          </Pressable>
        </>
        )}
      </>
    );
  };

  const componentLoading = carePlanForms.loading || componentState.viewTypeLoading;
  const isPreview = viewType === CarePlanViewType.preview;

  const componentRef = useRef(null);
  const printRef: any = useRef(null);
  const showPatientContextBanner = !!isSidecar && !!sidecarPatientContextData?.id;

  return (
    <>
       {showPatientContextBanner&&
        <HStack flex={1} paddingY={2} mx={4}>
          <SidecarPatientInfoBanner contactData={sidecarPatientContextData}  />
        </HStack>
       }
      <Header
        prefixAction={
          isSidecar ? (
            <Button onClick={() =>  props?.onCancelClick?.()} type="text" icon={<LeftOutlined />}/>
          ) : undefined
        }
        context={CarePlanScreenContext.EDIT_CARE_PLAN}
        headerAction={getHeaderActions()}
        title={intl.formatMessage({
          id: componentState.isEditCarePlan
            ? 'modifyCarePlan'
            : 'addNewCarePlan',
        })}
        subTitle={getHeaderSubTitle(componentState.statusId)}
        subTitleStyle={{
          color: isInReview
            ? Colors.Custom.orangeMessageTopBar
            : Colors.Custom.ErrorColor,
        }}
      />
      {componentLoading ? (
        <VStack m={4} space={4}>
          <HStack flexWrap={'wrap'} space={4} mb={2}>
            <Skeleton w={20} h={6} rounded={'full'} />
            <Skeleton w={20} h={6} rounded={'full'} />
            <Skeleton w={30} h={6} rounded={'full'} />
            <Skeleton w={40} h={6} rounded={'full'} />
          </HStack>
          <Skeleton.Text lines={4} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={3} />
          <Skeleton.Text lines={1} />
        </VStack>
      ) : (
        <Stack direction='column' style={{flex: 1}}>
          {!componentState?.isEditCarePlan && (
            <CarePlanTemplatesView
              selectedTemplateIds={componentState.selectedFormIds}
              templateList={carePlanForms.data.forms}
              onCheckTemplate={(templatId) => onTemplateSelect(templatId)}
              loadingTemplateIds={componentState.loadingFormIds}
            />
          )}
          {!componentState?.isEditCarePlan && componentState.selectedFormIds?.length === 0 && (
            <Stack direction='column' style={{flex: 1, justifyContent:'center', alignItems:"center"}}>
                <NoCarePlan title="noCarePlanTemplatesSelected" />
              </Stack>
            )}
         {componentState.components.length > 0 &&
            <ScrollView mx={4} py={4}>
              {componentState.components.length > 0 && (
                <div className='care-plan-styles' style={isPreview ? { display: 'none'} : {}}>
                  <FHForm
                    canUpdateOnChangeRef
                    isPreviewMode={false}
                    optionData={intakeOptions}
                    components={componentState.components}
                    submittedResponse={componentState.submittedResponse}
                    onChange={handleFormChange}
                    readOnly={false}
                  />
                </div>
              )}
              {componentState.components.length > 0 && !!isPreview && (
                <CarePlanDiff
                  oldData={componentState.diffView?.oldComponents || []}
                  newData={componentState.diffView?.newComponents || []}
                  components={componentState.components || []}
                  carePlanDetails={props.carePlanDetails}
                />
              )}
              {/* <ReactToPrint
              // documentTitle={getDocumentTitle()}
              trigger={() => <></>}
              content={() => componentRef.current}
              ref={printRef}
              onAfterPrint={() => {
                setComponentState((prev) => ({
                  ...prev,
                  startRenderPrintComponent: false,
                }));
              }}
              print={(target) => {
                return new Promise<any>((resolve, reject) => {
                  if (target.contentDocument) {
                    target.contentDocument.title = 'Care Plan';
                  }
                  target?.contentWindow?.print?.();
                  resolve(true);
                });
              }}
              removeAfterPrint={true}
            /> */}
              {componentState.startRenderPrintComponent && (
                <div style={{display: 'none'}}>
                  <PrintContactContent
                    triggerPrint={componentState.startRenderPrintComponent}
                    formattedContactData={props.personData}
                    showPreviewInDrawer={false}
                    isLoading={false}
                    enablePatientDemographics={false}
                    isOpen={false}
                    onClose={() => {
                      setComponentState((prev) => ({
                        ...prev,
                        startRenderPrintComponent: false,
                      }));
                    }}
                  >
                    <div className={`note-preview-styles custom-form-styles`}>
                      <FHForm
                        submittedResponse={submitData}
                        isPreviewMode={true}
                        optionData={intakeOptions}
                        components={componentState.components}
                        readOnly={false}
                      />
                    </div>
                  </PrintContactContent>
                </div>
              )}
            </ScrollView>
         }
        </Stack>
      )}
      {componentState.showProviderSelectionForReview && (
        <Modal
          closable={false}
          onCancel={onCloseSendForReview}
          footer={null}
          bodyStyle={{
            maxHeight: '70vh',
            paddingBottom: 10,
          }}
          open
        >
          <ProviderSelectForReview
            selectedProviderId=""
            onChange={onSelectProviderForReview}
            onCancel={onCloseSendForReview}
            contactId={props.contactUuid}
          />
        </Modal>
      )}
      {componentState.confirmInReviewAssignCarePlan && (
        <ConfirmAssignInReviewCarePlan
          onCancel={() =>
            setComponentState((prev) => ({
              ...prev,
              confirmInReviewAssignCarePlan: false,
            }))
          }
          onConfirm={() => {
            setComponentState((prev) => ({
              ...prev,
              confirmInReviewAssignCarePlan: false,
            }));
            onSaveCarePlan(activeCarePlanStatusId, {
              updateRelatedTask: true,
            });
          }}
        />
      )}
      {!!props?.submittedCarePlanAssessmentForm
        ?.submittedCarePlanAssessmentFormId &&
        !!props?.submittedCarePlanAssessmentForm
          ?.submittedCarePlanAssessmentFormName &&
        !!componentState?.showSubmittedCarePlanAssessmentFormDrawer && (
          <FormResponseView
            titleText={'Assessment'}
            mask={false}
            hideResponseList={true}
            drawerWidth={isSidecar ? '100%' : '28%'}
            onClose={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showSubmittedCarePlanAssessmentFormDrawer: false,
                };
              });
            }}
            contactId={props.contactUuid || ''}
            formId={
              props?.submittedCarePlanAssessmentForm
                ?.submittedCarePlanAssessmentFormId
            }
            formName={
              props?.submittedCarePlanAssessmentForm
                ?.submittedCarePlanAssessmentFormName
            }
          />
        )}
    </>
  );
};

export default AddOrUpdateCarePlanDetails;
