import { useLazyQuery } from '@apollo/client';
import { DatePicker, Divider, Select } from 'antd';
import { debounce } from 'lodash';
import { Checkbox, HStack, Input, Pressable, Spinner, Text, VStack, View, useMediaQuery } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IMlov } from '../../../../Interfaces';
import {
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { LeadQueries, TaskQueries } from '../../../../services';
import { GetLabelsByLabelTitleAndTypeId } from '../../../../services/Labels/LabelsQueries';
import { Colors } from '../../../../styles';
import { getDateStrFromFormat, getMomentObj } from '../../../../utils/DateUtils';
import {
  getAccountId,
  getUserEmployerId,
  getUserUUID,
  isEmployerRole
} from '../../../../utils/commonUtils';
import { getCareStudioMlovListByCategoryCode, getContactTypeId, getMlovId, getMlovListFromCategory, getMlovObjectFromCode, getMlovValue } from '../../../../utils/mlovUtils';
import { getContactFiltersObject } from '../../../RightSideContainer/Contacts/ContactsUtils';
import { isEmployerOwnerShowFilterByContact } from '../../../RightSideContainer/UserAccess/UserPermissionConst';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import { ILabelTask } from '../CareDashboardInterfaces';
import { ITopBarData } from './CareDashboardTopBar';
import { FilterWrapperComponent } from './FilterWrapperComponent';
import { BoardType, TaskFilterType } from './interfaces';
import moment from 'moment';
import {IAllUserPoolSelect, IUserPool} from '../CareDashboardWidget/UserAutoComplete';
import { TASK_TABS } from '../CareDashboardConstants';
import './FilterRow.css'
import Stack from '../../LayoutComponents/Stack';
import FoldButtonV2 from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import Feather from 'react-native-vector-icons/Feather';
import CrossIconSvg from '../../Svg/CrossIconSvg';
import CrossIcon from '../../Svg/CrossIcon';
import UserAutoCompleteMultiSelect from '../../CalendarWidget/UserAutoComplete/UserAutoCompleteMultiSelect';
import { getLabelForAssigneeField } from '../../../TaskModule/TaskModuleHelper';
import BoardTypeSelector from './BoardTypeSelector';

const { Option } = Select;

interface IFilterByTaskStatus {
  statusType: string;
  statusTypeUuid: string;
  id: number;
  label: string;
}


export interface IFilterByDueDate {
  dueDateType: string;
  dueDateTypeUuid: string;
  id: number;
  label: string;
}

export const dateRangeStatusUuid = 'date_range';
export const dateRangeStatusValue = 'Date Range';

const FilterRowView = (props: {
  selectedStatusList?: Array<IMlov>;
  selectedPriorityList?: Array<IMlov>;
  // selectedStatusType: string;
  // selectedStatusUuid: string;
  // selectedPriorityType: string;
  // selectedPriorityUuid: string;
  selectedDueDateType: string[];
  selectedDueDateUuid: string[];
  onActionPerformed: (
    tabCode: any,
    rawData: any,
  ) => void;
  topBarData: ITopBarData;
  onTopBarDataChange: (topBarData: ITopBarData) => void;
  onCloseFilterView: () => void;
  isPatientView?: boolean;
  selectedContactUuid?: {
    value: string | undefined;
    label: string;
    key: string;
    contactData: any;
  }[];
  selectedPatientName?: string[];
  selectedPatientLabel?: any[];
  setContactsData?: any;
  contactsData?: any;
  userPools?: any;
  children?: JSX.Element;
}) => {
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = !!mlovData.sidecarContext?.isSidecar;
  const { topBarData } = props;
  const intl = useIntl();
  const { setContactsData, contactsData } = props;
  const [contactsDataState, setContactsDataState] = useState(contactsData)
  // For local filter store
  const employerId = getUserEmployerId();

  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(TASK_STATUS.ACCEPTED, taskStatusMlovs);
  const completedStatusMlov = getMlovObjectFromCode(TASK_STATUS.COMPLETED, taskStatusMlovs);
  const missedStatusMlov = getMlovObjectFromCode(TASK_STATUS_CODES.MISSED, taskStatusMlovs);
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const highPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.HIGH, taskPriorityMlovs) as IMlov;
  const mediumPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.MEDIUM, taskPriorityMlovs) as IMlov;
  const lowPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.LOW, taskPriorityMlovs) as IMlov;

  const completedStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.COMPLETED
  );
  const requestedStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.ACCEPTED
  );
  const requestedStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.ACCEPTED
  );
  //For task priority
  const routinePriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'routine'
  );
  const routinePriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'routine'
  );
  const urgentPriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'urgent'
  );
  const urgentPriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'urgent'
  );
  const asapPriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'asap'
  );
  const asapPriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'asap'
  );
  //For Due Date
  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const overdueStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const overdueStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const todayStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const todayStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const tomorrowStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const tomorrowStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const laterStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );
  const laterStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );

  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const labelMlov = filteredResult[0];

  const [visible, setVisible] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [stateData, setStateData] = useState({
    statusTypeList: [] as IMlov[],
    priorityTypeList: [] as IMlov[],
    dueDateTypeList: [] as IFilterByDueDate[],
    selectedDueDateCodes: (props.selectedDueDateUuid || []),
    selectedStatusCodes: (props.selectedStatusList?.map?.((mlov) => mlov.code) || []),
    selectedPriorityCodes: (props.selectedPriorityList?.map?.((mlov) => mlov.code) || []),
    formData: {
      selectedContactUuid: props.selectedContactUuid
        ? props.selectedContactUuid
        : [],
      selectedPatientLabel: props.selectedPatientLabel
        ? props.selectedPatientLabel
        : [],
      selectedPatientName: props.selectedPatientName
        ? props.selectedPatientName
        : [],
      selectedStatusList: props.selectedStatusList
        ? props.selectedStatusList
        : [],
      selectedPriorityList: props.selectedPriorityList
        ? props.selectedPriorityList
        : [],
      // selectedStatusUuid: props.selectedStatusUuid
      //   ? props.selectedStatusUuid
      //   : 'all',
      // selectedPriority: props.selectedPriorityType
      //   ? props.selectedPriorityType
      //   : 'All',
      // selectedPriorityUuid: props.selectedPriorityUuid
      //   ? props.selectedPriorityUuid
      //   : 'all',
      selectedDueDate: props.selectedDueDateType
        ? props.selectedDueDateType
        : allStatusValue,
      selectedDueDateUuid: props.selectedDueDateUuid
        ? props.selectedDueDateUuid
        : allStatusUuid,
      // selectedSortUuid: props.selectedSortUuid,
      // selectedSortType: props.selectedSortType,
      // selectedSortValueAscending: props.selectedSortValueAscending,
      start: getDateStrFromFormat(
        props.topBarData.start ? props.topBarData.start : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(
        props.topBarData.end ? props.topBarData.end : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      selectedPools: props?.topBarData?.selectedPools
        ? props.topBarData.selectedPools
        : [],
      selectedUsers: props.topBarData?.selectedUsers || [],
      createdByIds: props.topBarData?.createdByIds || [],
      mentionUserIds: props.topBarData?.mentionUserIds || [],
      tempUserPool: [] as IUserPool[],
      tempAllUserPool: [] as IUserPool[],
      tempAllUserPoolSelect: [] as IAllUserPoolSelect[],
    },
  });

  useEffect(() => {
    if (props?.selectedContactUuid?.length) {
      setStateData((prev) => {
        return ({
          ...prev,
          formData: {
            ...prev.formData,
            selectedContactUuid: props.selectedContactUuid || []
          }
        })

      })
    }

  }, [props.selectedContactUuid])

  const [taskLabelData, setTaskLabelData] = useState<{
    taskLabelsList?: ILabelTask[];
    limit: number;
    offset: number;
    loading: boolean;
    searchString?: string;
    selectedLabels?: string[];
  }>({
    taskLabelsList: [],
    limit: 10,
    offset: 0,
    searchString: '',
    loading: false,
    selectedLabels: props?.topBarData?.labels
      ? props.topBarData.labels?.map(item => item?.uuid || '') || []
      : [],
  });

  const [customerData, setCustomerData] = useState<
    {
      value: string | undefined;
      label: string;
      key: string;
      contactData: any;
    }[]
  >([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const filter: any = {
    _or: [],
    contactTagging: {},
    contactInboxes: {},
  };
  const contactTypeId = getContactTypeId('CUSTOMER');
  const accountId = getAccountId();

  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  async function fetchLabelsForBatch(batchIds: string[]) {
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
        labelTypeId: labelMlov.id,
      },
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const onSubmit = async () => {
    setSearchText("");
    setCustomerData(stateData.formData.selectedContactUuid)
    setTaskLabelData((prev) => {
      return { ...prev, searchString: "" };
    });
    let getLabelsDataRes = [] as ILabelTask[];
    if (taskLabelData.selectedLabels?.length) {
      getLabelsDataRes = await fetchLabelsForBatch(taskLabelData.selectedLabels);
    }
    props.onActionPerformed(
      COMMON_ACTION_CODES.SAVE,
      { ...stateData.formData, selectedLabels: getLabelsDataRes },
    );
    setVisible(false);
  }

  useEffect(() => {
    if (props.selectedContactUuid) {
      setCustomerData(props.selectedContactUuid)
    }
  }, [props.selectedContactUuid])



  const [getContacts] = useLazyQuery(
    // ContactsQueries.SEARCH_CONTACTS_WITH_CONTACT_TYPE,
    LeadQueries.GetContacts,
    {
      onCompleted: (data: any) => {
        let contactsData = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setCustomerData(contactsData);
        setCustomerLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {
        setCustomerData([]);
        setCustomerLoading(false);
        // setIsOpen(true);
      },
    }
  );

  const onChangeCustomer = (searchText: string) => {
    if (searchText) onSearchCustomer(searchText);
  };

  const onSearchCustomer = async (searchText: string) => {
    try {
      setCustomerLoading(true);
      await getContacts({
        variables: {
          // accountId: accountId,
          // contactType: leadContactType,
          limit: 10,
          offset: 0,
          searchString: '%' + searchText + '%',
          order_by: {
            updatedAt: 'desc',
          },
          contact_filters: getContactFiltersObject(
            filter,
            searchText,
            accountId,
            [],
            [],
            '',
            [] as any,
            '',
            '',
            employerId
          ),
        },
      });
    } catch (e) { }
  };

  useEffect(() => {
    const initialStatusType = [
      // {
      //   id: 1,
      //   statusType: 'All',
      //   statusTypeUuid: 'all',
      //   label: 'All',
      // },
      {
        ...acceptedStatusMlov,
        value: 'Pending'
      } as IMlov,
      {
        ...missedStatusMlov,
      } as IMlov,
      {
        ...completedStatusMlov,
      } as IMlov,
      // {
      //   id: 2,
      //   statusType: completedStatusValue,
      //   statusTypeUuid: completedStatusUuid,
      //   label: completedStatusValue,
      // },
      // {
      //   id: 3,
      //   statusType: requestedStatusValue,
      //   statusTypeUuid: requestedStatusUuid,
      //   label: 'Not Completed',
      // },
    ];
    const initialPriorityType = [
      highPriorityMlov,
      mediumPriorityMlov,
      lowPriorityMlov,
      // {
      //   id: 1,
      //   priorityType: 'All',
      //   priorityTypeUuid: 'all',
      //   label: 'All',
      // },
      // {
      //   id: 2,
      //   priorityType: asapPriorityValue,
      //   priorityTypeUuid: asapPriorityUuid,
      //   label: asapPriorityValue,
      // },
      // {
      //   id: 3,
      //   priorityType: urgentPriorityValue,
      //   priorityTypeUuid: urgentPriorityUuid,
      //   label: urgentPriorityValue,
      // },
      // {
      //   id: 4,
      //   priorityType: routinePriorityValue,
      //   priorityTypeUuid: routinePriorityUuid,
      //   label: routinePriorityValue,
      // },
    ];
    const initialDueDateType = [
      {
        id: 1,
        dueDateType: overdueStatusValue,
        dueDateTypeUuid: overdueStatusUuid,
        label: overdueStatusValue,
      },
      {
        id: 2,
        dueDateType: todayStatusValue,
        dueDateTypeUuid: todayStatusUuid,
        label: todayStatusValue,
      },
      {
        id: 3,
        dueDateType: tomorrowStatusValue,
        dueDateTypeUuid: tomorrowStatusUuid,
        label: tomorrowStatusValue,
      },
      {
        id: 4,
        dueDateType: laterStatusValue,
        dueDateTypeUuid: laterStatusUuid,
        label: laterStatusValue,
      },
      {
        id: 5,
        dueDateType: dateRangeStatusValue,
        dueDateTypeUuid: dateRangeStatusUuid,
        label: dateRangeStatusValue,
      },
    ];

    setStateData((prev) => {
      return {
        ...prev,
        statusTypeList: initialStatusType,
        priorityTypeList: initialPriorityType,
        dueDateTypeList: initialDueDateType,
        selectedPriorityCodes: (props.selectedPriorityList?.map?.((mlov) => mlov.code) || []),
        selectedDueDateCodes: (props.selectedDueDateUuid || []),
        selectedStatusCodes: (props.selectedStatusList?.map?.((mlov) => mlov.code) || []),
        formData: {
          ...prev.formData,
          selectedStatusList: props.selectedStatusList
            ? props.selectedStatusList
            : [],
          selectedPriorityList: props.selectedPriorityList
            ? props.selectedPriorityList
            : [],
          // selectedStatusUuid: props.selectedStatusUuid
          //   ? props.selectedStatusUuid
          //   : 'all',
          // selectedPriority: props.selectedPriorityType
          //   ? props.selectedPriorityType
          //   : 'All',
          // selectedPriorityUuid: props.selectedPriorityUuid
          //   ? props.selectedPriorityUuid
          //   : 'all',
          selectedDueDate: props.selectedDueDateType
            ? props.selectedDueDateType
            : allStatusValue,
          selectedDueDateUuid: props.selectedDueDateUuid
            ? props.selectedDueDateUuid
            : allStatusUuid,
          selectedContactUuid: props.selectedContactUuid
            ? props.selectedContactUuid
            : [],
          selectedPatientLabel: props.selectedPatientLabel
            ? props.selectedPatientLabel
            : [],
          selectedPatientName: props.selectedPatientName
            ? props.selectedPatientName
            : [],
          selectedPools: props.topBarData.selectedPools
            ? props.topBarData.selectedPools
            : [],
        },
      };
    });
    setTaskLabelData(prev => {
      return {
        ...prev,
        taskLabelsList: props?.topBarData?.labels
          ? props.topBarData.labels
          : [],
        selectedLabels: props?.topBarData?.labels
          ? props.topBarData.labels?.map(item => item?.uuid || '') || []
          : [],
      }
    });
  }, [
    props.selectedStatusList,
    props.selectedPriorityList,
    props.selectedDueDateType,
    props.selectedPatientName,
    props.topBarData.selectedPools,
    props.topBarData?.labels,
  ]);


  const allDueDateUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: labelMlov.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams });
      const taskLabels = apiResponse?.data?.labels || [];
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: false,
          taskLabelsList: taskLabels,
        }
      });
    } catch (error) {
      setTaskLabelData(prev => { return { ...prev, loading: false } });
    }
  };

  const renderAssigneeFields = () => {
    if (!isSidecarContext) {
      return props.children
    }
    return (
      <Stack space={16} direction={isSidecarContext ? 'column' : 'row'} style={{ width: '100%' }}>
        {(topBarData.selectedTab === TASK_TABS.CREATED || topBarData.selectedTab === TASK_TABS.MENTIONED) &&
          <UserAutoCompleteMultiSelect
            labelText={getLabelForAssigneeField(topBarData.selectedTab)}
            isMentionOrCreatedSelect={true}
            filterType={topBarData.selectedTab === TASK_TABS.CREATED ? TaskFilterType.CreatedBy : TaskFilterType.Mention}
            selectedValue={topBarData.selectedTab === TASK_TABS.CREATED ? topBarData.createdByIds : topBarData.mentionUserIds}
            onChange={(selectedUser) => {
              const isCreatedTabSelected = topBarData.selectedTab === TASK_TABS.CREATED;
              const isMentionTabSelected = topBarData.selectedTab === TASK_TABS.MENTIONED;
              const updatedTopBarData = {
                ...( isCreatedTabSelected  && { createdByIds: selectedUser } ),
                ...( isMentionTabSelected  && { mentionUserIds: selectedUser } ),
              }
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    ...updatedTopBarData,
                  },
                };
              });
            }}
            selectedTab={topBarData?.selectedTab}
          />
        }
        <UserAutoCompleteMultiSelect
          labelText={'Assigned to'}
          selectedValue={stateData.formData.selectedUsers}
          onChange={(selectedUsers) => {
            setStateData((prev) => {
              return {
                ...prev,
                formData: {
                  ...prev.formData,
                  selectedUsers: selectedUsers,
                },
              };
            });
          }}
          onPoolChange={(newUserPool: IUserPool[]) => {
            setStateData((prev) => {
              return {
                ...prev,
                formData: {
                  ...prev.formData,
                  tempUserPool: newUserPool,
                },
              };
            });
          }}
          setAllUserPoolList={(allUsersPools: IUserPool[])=>{
            setStateData((prev) => {
              return {
                ...prev,
                formData: {
                  ...prev.formData,
                  tempAllUserPool: allUsersPools,
                },
              };
            });
          }}
          onAllUserPoolChange={(allUserPool: IAllUserPoolSelect[]) => {
            setStateData((prev) => {
              return {
                ...prev,
                formData: {
                  ...prev.formData,
                  tempAllUserPoolSelect: allUserPool,
                },
              };
            });
          }}
        />
        {topBarData.selectedTab &&
          ![TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(topBarData.selectedTab) && (
            <View marginBottom={isSidecarContext ? 2 : 0}>
            <BoardTypeSelector
              selectedType={topBarData.selectedBoardType || BoardType.Status}
              handleTypeChange={(type) => {
                props?.onActionPerformed && props?.onActionPerformed(COMMON_ACTION_CODES.SELECTED_BOARD_TYPE, {
                  selectedBoardType: type,
                })
              }
              }
            />
            </View>
          )}
      </Stack>
    );
  }

  const renderPool = ()=> {
    return (
      <>
        {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        noJustify={isSidecarContext}
        maxNumberOfAppliedFiltersToshow={2}
        maxWidth={'80%'}
        marginBottom={isSidecarContext ? 8 : 0} popoverMaxHeight={300} onClose={() => {
          !isSidecarContext && onSubmit();
        }} label={"Task Pool"} value={stateData.formData.selectedPools?.map((item) => item.label)} >
          <View style={{
            width: 250
          }}>
            <View style={{
              borderBottomColor: Colors.Custom.Gray200,
              borderBottomWidth: 1,
              paddingBottom: 14,

            }}>
              <Text fontWeight={600} style={{
                marginHorizontal: 4,
                marginVertical: 8
              }}>
                Search Pool
              </Text>
              <Input
                placeholder={'Select Task Pool'}
                value={searchText}
                onChangeText={(text) => {
                  setSearchText(text.toLowerCase());
                }}
                width={'100%'}
              />
            </View>
            <View>
              {props.userPools?.length ?
                props.userPools?.filter((item: any) => item?.name?.toLowerCase()?.includes(searchText) ? true : false)?.map((item: any, index: number) => {
                  const itemToAdd = {
                    "label": item.name,
                    "value": item.id,
                    "key": item.id,
                  };
                  return (
                    <Checkbox
                      key={item.id}
                      value={item.id}
                      style={{ marginVertical: 10 }}
                      alignItems={'center'}
                      isChecked={stateData.formData?.selectedPools?.find(item => item.key == itemToAdd.key) ? true : false}
                      onChange={(isChecked) => {
                        const selectedPoolsRaw = stateData.formData.selectedPools;

                        if (isChecked) {
                          selectedPoolsRaw.push(itemToAdd);
                        } else {
                          const index = selectedPoolsRaw.findIndex(item => item.key == itemToAdd.key);
                          if (index > -1) {
                            selectedPoolsRaw.splice(index, 1);
                          }
                        }
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              selectedPools: selectedPoolsRaw
                            },
                          };
                        });
                      }}
                    >
                      <Text
                        size={'smMedium'}
                        color={Colors.Custom.Gray700}
                        style={{ marginLeft: 8 }}
                      >
                        {item.name}
                      </Text>
                    </Checkbox>
                  )
                }) :
                <Text style={{
                  marginTop: 8
                }} size={'smMedium'}
                  color={Colors.Custom.Gray700}>
                  {intl.formatMessage({
                    id: 'noDataFound',
                  })}
                </Text>
              }
            </View>
          </View>
          {/*
            <ModalActionAntSelect
              mode={'multiple'}
              allowClear={true}
              leftMargin={'0'}
              showSearch={true}
              onSearch={debounce(onChangeCustomer, 500)}
              labelInValue={true}
              filterOption={false}
              value={stateData.formData?.selectedPools}
              isRequired={false}
              notFoundContent={intl.formatMessage({
                id: 'NoDataSelect',
              })}
              loading={customerLoading}
              placeholder={'Select Task Pool'}
              // accessibilityLabel="Select Employer"
              onChange={(value: any) => {
                if (value) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      formData: {
                        ...prev.formData,
                        selectedPools: value,
                      },
                    };
                  });
                }
              }}
              data={props.userPools}
              optionProps={{ key: 'id', value: 'id', label: 'name' }}
              extraStyle={{ width: 300 }}
            /> */}
        </FilterWrapperComponent>
      )}
      </>
    )
  }

  const renderTaskLabel = () => {
    const selectedLabelsTitle = taskLabelData.selectedLabels?.map?.(item => {
      const title = taskLabelData.taskLabelsList?.find(subitem => subitem.uuid == item);
      return title?.title;
    })
    return (
      <FilterWrapperComponent
      isMultiSelect
      showFilterAsDropDown={isSidecarContext}
      dropdownMarginLeft={isSidecarContext ? 0 : undefined}
      maxWidth={'80%'}
      maxNumberOfAppliedFiltersToshow={4}
      noJustify={isSidecarContext}
      onClose={() => {
        !isSidecarContext && onSubmit();
      }} popoverMaxHeight={300} label={"Labels"} value={selectedLabelsTitle} marginBottom={isSidecarContext ? 8 : 0}>
        <View style={{
          width: 250
        }}>
          <View style={{
            borderBottomColor: Colors.Custom.Gray200,
            borderBottomWidth: (taskLabelData?.taskLabelsList?.length && !taskLabelData.loading) || (taskLabelData.searchString && !taskLabelData.loading) || taskLabelData.loading ? 1 : 0,
            paddingBottom: 14,
          }}>
            <Text fontWeight={600} style={{
              marginHorizontal: 4,
              marginVertical: 8
            }}>
              Search Label
            </Text>
            <Input
              placeholder={'Search Labels'}
              value={taskLabelData.searchString}
              onChangeText={(text) => {
                setTaskLabelData((prev) => {
                  return { ...prev, searchString: text?.trim(),  loading: text?.trim() ? true : false };
                });
                debounce((value) => {
                  onSearchTaskLabel(value);
                }, 500)(text)
              }}
              width={'100%'}
            />
          </View>
          <View>
            {taskLabelData.loading && <Spinner marginTop={4}></Spinner>}
            {taskLabelData?.taskLabelsList?.length && !taskLabelData.loading?
              taskLabelData?.taskLabelsList?.map((item: ILabelTask, index: number) => {
                if (!item?.uuid) {
                  return null;
                }
                return (
                  <Checkbox
                    key={item.id}
                    value={item.id?.toString()}
                    style={{ marginVertical: 10 }}
                    alignItems={'center'}
                    isChecked={taskLabelData?.selectedLabels?.includes(item.uuid) ? true : false}
                    onChange={(isChecked) => {
                      if (!item?.uuid) {
                        return null;
                      }
                      const selectedLabelsRaw = taskLabelData?.selectedLabels || [];

                      if (isChecked) {
                        selectedLabelsRaw.push(item.uuid);
                      } else {
                        const index = selectedLabelsRaw.indexOf(item.uuid);
                        if (index > -1) {
                          selectedLabelsRaw.splice(index, 1);
                        }
                      }
                      setTaskLabelData((prev) => {
                        return {
                          ...prev,
                          selectedLabels: selectedLabelsRaw,
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={{ marginLeft: 8 }}
                    >
                      {item.title}
                    </Text>
                  </Checkbox>
                )
              }) : taskLabelData.searchString && !taskLabelData.loading ?
              <Text style={{
                marginTop: 8
              }} size={'smMedium'}
                color={Colors.Custom.Gray700}>
                {intl.formatMessage({
                  id: 'noDataFound',
                })}
              </Text> : null
            }
          </View>
        </View>

        {/* <Select
          mode="multiple"
          allowClear
          placeholder="Search Labels"
          showSearch={true}
          value={
            taskLabelData.selectedLabels?.length
              ? taskLabelData.selectedLabels
              : []
          }
          filterOption={false}
          notFoundContent={intl.formatMessage({
            id: 'NoDataSelect',
          })}
          onChange={(selectedLabels) => {
            const tempData = selectedLabels?.filter((item: string) => {
              return item != 'noData';
            });
            setTaskLabelData((prev) => {
              return {
                ...prev,
                selectedLabels: tempData,
              };
            });
          }}
          onSearch={debounce((value) => {
            setTaskLabelData((prev) => {
              return { ...prev, searchString: value?.trim() };
            });
            onSearchTaskLabel(value);
          }, 500)}
          loading={taskLabelData.loading}
          style={{ width: 200, height: 40 }}
        >
          {getLabelOptions()}
        </Select> */}
      </FilterWrapperComponent>
    );
  };

  const getDueDateValue = () => {
    if (stateData.formData?.selectedDueDate === 'All') {
      return [];
    }
    if (stateData.formData.selectedDueDate.includes(dateRangeStatusValue)) {
      const startDate = stateData.formData?.start || '';
      const endDate = stateData.formData?.end || '';
      const value = dateRangeStatusValue + ': ' + startDate + ' - ' + endDate;
      return [value];
    }
    return stateData.formData?.selectedDueDate || [];
  }

  const renderFilterView = (
    <Stack direction={isSidecarContext ? 'column' : 'row'} space={isSidecarContext ? 8 : undefined} style={isSidecarContext ? { overflow: 'scroll' } : {}}>
      {topBarData.selectedTab !== TASK_TABS.TASK_POOL && renderAssigneeFields()}
      {topBarData.selectedTab === TASK_TABS.TASK_POOL && renderPool()}
      {topBarData.selectedTab === TASK_TABS.TASK_POOL && renderAssigneeFields()}
      {!props.isPatientView &&
        (isEmployerRole() ? isEmployerOwnerShowFilterByContact() : true) && (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isSidecarContext}
          dropdownMarginLeft={isSidecarContext ? 0 : undefined}
          maxWidth={'80%'}
          maxNumberOfAppliedFiltersToshow={2}
          noJustify={isSidecarContext}
          marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
            !isSidecarContext && onSubmit();
          }} label={"Members"} value={stateData?.formData?.selectedContactUuid?.map((item) => item.label)} >
            <View style={{
              width: 250
            }}>
              <View style={{
                borderBottomColor: Colors.Custom.Gray200,
                borderBottomWidth: 1,
                paddingBottom: 14,

              }}>
                <Text fontWeight={600} style={{
                  marginHorizontal: 4,
                  marginVertical: 8
                }}>
                  Select Member
                </Text>
                <Input
                  placeholder={'Select Members'}
                  onChangeText={(searchText) => {
                    if(searchText){
                      setCustomerLoading(true)
                    }
                    setSearchText(searchText);
                    debounce((searchText) => onChangeCustomer(searchText), 500)(searchText)
                  }}
                  width={'100%'}
                  value={searchText}
                />
              </View>
              {customerLoading && <Spinner marginTop={4}></Spinner>}
              {!customerLoading && <View style={{
                maxHeight: 400,
                overflow: 'scroll'
              }}>
                {customerData?.length ?
                  customerData?.map((item, index: number) => {
                    return (
                      <Checkbox
                        key={item.key}
                        value={item.value || ""}
                        style={{ marginVertical: 10 }}
                        alignItems={'center'}
                        isChecked={stateData?.formData?.selectedContactUuid?.find((subitem: any) => subitem.key == item.key) ? true : false}
                        onChange={(isChecked) => {
                          const value = stateData.formData.selectedContactUuid;
                          if (isChecked) {
                            value.push(item as any);
                          } else {
                            const index = value.findIndex((subitem: any) => subitem.key == item.key);
                            if (index > -1) {
                              value.splice(index, 1);
                            }
                          }

                          if (value) {
                            const labelArray: string[] = [];
                            value.map((valueItem: any) => {
                              (valueItem?.label?.props?.contactData?.name ||
                                valueItem?.label) &&
                                labelArray.push(
                                  valueItem?.label?.props?.contactData?.name ||
                                  valueItem?.label
                                );
                            });
                            const newContactDataArray = value.reduce(
                              (acc: any, item: any) => {
                                const id = item.key;
                                const foundContact = contactsDataState.find(
                                  (contact: any) => contact.key === id
                                );
                                if (foundContact) {
                                  acc.push(foundContact);
                                } else {
                                  const customerInfo = customerData.find(
                                    (customer) => customer.key === id
                                  );
                                  if (customerInfo) {
                                    acc.push({
                                      key: customerInfo.key,
                                      label: customerInfo.label,
                                      value: customerInfo.value,
                                      contactData: customerInfo.contactData,
                                    });
                                  }
                                }
                                return acc;
                              },
                              []
                            );
                            setStateData((prev) => {
                              return {
                                ...prev,
                                formData: {
                                  ...prev.formData,
                                  selectedContactUuid: value,
                                  selectedPatientName: labelArray,
                                },
                              };
                            });
                            setContactsData(newContactDataArray);
                            setContactsDataState(newContactDataArray);
                          }
                        }}

                      >
                        <View style={{ marginLeft: 8, marginVertical: 6 }}>
                          <MemberInfoListItem
                            contactData={item.contactData}
                            showDateOfBirth={true}
                          />
                        </View>
                      </Checkbox>
                    )
                  }) :
                  searchText ?
                    <Text style={{
                      marginTop: 8
                    }} size={'smMedium'}
                      color={Colors.Custom.Gray700}>
                      {intl.formatMessage({
                        id: 'noDataFound',
                      })}
                    </Text> : <Text style={{
                      marginTop: 8
                    }} size={'smMedium'}
                      color={Colors.Custom.Gray700}>
                      {intl.formatMessage({
                        id: 'NoDataSelect',
                      })}
                    </Text>
                }
              </View>}
            </View>
            {/*
            <ModalActionAntSelect
              mode={'multiple'}
              allowClear={false}
              leftMargin={'0'}
              showSearch={true}
              onSearch={debounce(onChangeCustomer, 500)}
              labelInValue={true}
              filterOption={false}
              value={stateData.formData?.selectedContactUuid}
              isRequired={false}
              notFoundContent={intl.formatMessage({
                id: 'NoDataSelect',
              })}
              loading={customerLoading}
              placeholder={'Select Members'}
              accessibilityLabel="Select Employer"
              onChange={(value: any) => {
                if (value) {
                  const labelArray: string[] = [];
                  value.map((valueItem: any) => {
                    (valueItem?.label?.props?.contactData?.name ||
                      valueItem?.label) &&
                      labelArray.push(
                        valueItem?.label?.props?.contactData?.name ||
                        valueItem?.label
                      );
                  });
                  const newContactDataArray = value.reduce(
                    (acc: any, item: any) => {
                      const id = item.key;
                      const foundContact = contactsDataState.find(
                        (contact: any) => contact.key === id
                      );
                      if (foundContact) {
                        acc.push(foundContact);
                      } else {
                        const customerInfo = customerData.find(
                          (customer) => customer.key === id
                        );
                        if (customerInfo) {
                          acc.push({
                            key: customerInfo.key,
                            label: customerInfo.label,
                            value: customerInfo.value,
                            contactData: customerInfo.contactData,
                          });
                        }
                      }
                      return acc;
                    },
                    []
                  );
                  setStateData((prev) => {
                    return {
                      ...prev,
                      formData: {
                        ...prev.formData,
                        selectedContactUuid: newContactDataArray,
                        selectedPatientName: labelArray,
                      },
                    };
                  });
                  setContactsData(newContactDataArray);
                  setContactsDataState(newContactDataArray);
                }
              }}
              data={customerData}
              optionProps={{ key: 'key', value: 'value', label: 'label' }}
              extraStyle={{ width: 100 }}
              optionsArray={
                customerData &&
                customerData.map((option: any) => {
                  return (
                    <Option key={option['key']} value={option['value']}>
                      <MemberInfoListItem
                        contactData={option?.contactData}
                        isPatientSearchCard
                      />
                    </Option>
                  );
                })
              }
            /> */}
          </FilterWrapperComponent>
        )}
      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={3}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
          !isSidecarContext && onSubmit();
        }} label={"Status"} value={stateData.selectedStatusCodes.map(statusCode => stateData.statusTypeList.find(status => status.code === statusCode)?.value)} >
          {stateData.statusTypeList.length ? (
            <View style={{ paddingLeft: 16 }}>
              {stateData.statusTypeList.map((mlov) => {
                return (
                  <Checkbox
                    key={mlov.id}
                    value={mlov.code}
                    style={{ marginVertical: 10 }}
                    alignItems={'center'}
                    isChecked={stateData.selectedStatusCodes?.includes(
                      mlov.code
                    )}
                    onChange={(isChecked) => {
                      setStateData((stateData) => {
                        const selectedStatusCodes =
                          stateData.selectedStatusCodes || [];
                        if (isChecked) {
                          if (!selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.splice(
                              selectedStatusCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedStatusList: IMlov[] = [];
                        selectedStatusCodes?.length &&
                          stateData.statusTypeList?.forEach?.((status) => {
                            if (
                              selectedStatusCodes.indexOf?.(status.code) !==
                              -1
                            ) {
                              selectedStatusList.push(status);
                            }
                          });

                        return {
                          ...stateData,
                          selectedStatusCodes: [...selectedStatusCodes],
                          formData: {
                            ...stateData.formData,
                            selectedStatusList: [...selectedStatusList],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={{ marginLeft: 8 }}
                    >
                      {mlov.value}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      )}

      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={3}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
          !isSidecarContext && onSubmit();
        }} label={"Priority"} value={stateData.formData.selectedPriorityList?.map(item => item.value)} >
          {stateData.priorityTypeList.length ? (
            <View style={{ paddingLeft: 16 }}>
              {stateData.priorityTypeList.map((mlov) => {
                return (
                  <Checkbox
                    key={mlov.id}
                    value={mlov.code}
                    style={{ marginVertical: 10 }}
                    alignItems={'center'}
                    isChecked={stateData.selectedPriorityCodes?.includes(mlov.code)}
                    onChange={(isChecked) => {
                      setStateData((stateData) => {
                        const selectedPriorityCodes =
                          stateData.selectedPriorityCodes || [];
                        if (isChecked) {
                          if (!selectedPriorityCodes.includes(mlov.code)) {
                            selectedPriorityCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedPriorityCodes.includes(mlov.code)) {
                            selectedPriorityCodes.splice(
                              selectedPriorityCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedPriorityList: IMlov[] = [];
                        selectedPriorityCodes?.length &&
                          stateData.priorityTypeList?.forEach?.(
                            (status) => {
                              if (
                                selectedPriorityCodes.indexOf?.(
                                  status.code
                                ) !== -1
                              ) {
                                selectedPriorityList.push(status);
                              }
                            }
                          );

                        return {
                          ...stateData,
                          selectedPriorityCodes: [...selectedPriorityCodes],
                          formData: {
                            ...stateData.formData,
                            selectedPriorityList: [...selectedPriorityList],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={{ marginLeft: 8 }}
                    >
                      {mlov.value}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      )}

      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={4}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0}
        onClose={() => {
          !isSidecarContext && onSubmit();
        }}
        label={"Due Date"}
        popoverMaxHeight={300}
        value={getDueDateValue()} >
          {stateData.dueDateTypeList.length && (
            <View style={{ paddingLeft: 16 }}>
              {stateData.dueDateTypeList.map((type) => {
                return (
                  <Checkbox
                    key={type.dueDateTypeUuid}
                    value={type.label}
                    style={{ marginVertical: 10 }}
                    alignItems={'center'}
                    isChecked={stateData.selectedDueDateCodes?.includes(
                      type.dueDateTypeUuid
                    )}
                    onChange={(isChecked) => {
                      if (type.dueDateTypeUuid === dateRangeStatusUuid) {
                        if (isChecked) {
                          setStateData((stateData) => {
                            return {
                              ...stateData,
                              selectedDueDateCodes: [type.dueDateTypeUuid],
                              formData: {
                                ...stateData.formData,
                                selectedDueDate: [type.label],
                                selectedDueDateUuid: [type.dueDateTypeUuid],
                              },
                            };
                          });
                        } else {
                          setStateData((stateData) => {
                            return {
                              ...stateData,
                              selectedDueDateCodes: [allDueDateUuid],
                              formData: {
                                ...stateData.formData,
                                selectedDueDate: [],
                                selectedDueDateUuid: [allDueDateUuid],
                              },
                            };
                          });
                        }
                      } else {
                        setStateData((stateData) => {
                          let selectedDueDateCodes =
                            stateData.selectedDueDateCodes || [];
                          if (isChecked) {
                            if (
                              !selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              if (
                                selectedDueDateCodes.includes(
                                  dateRangeStatusUuid
                                )
                              ) {
                                selectedDueDateCodes = [type.dueDateTypeUuid];
                              } else {
                                if (Array.isArray(selectedDueDateCodes)) {
                                  selectedDueDateCodes.push(
                                    type.dueDateTypeUuid
                                  );
                                } else {
                                  selectedDueDateCodes = [
                                    type.dueDateTypeUuid,
                                  ];
                                }
                              }
                            }
                          } else {
                            if (
                              selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              selectedDueDateCodes.splice(
                                selectedDueDateCodes.indexOf(
                                  type.dueDateTypeUuid
                                ),
                                1
                              );
                            }
                          }

                          const selectedDueDateType: string[] = [];
                          selectedDueDateCodes?.length &&
                            stateData.dueDateTypeList?.forEach?.(
                              (dueDateType) => {
                                if (
                                  selectedDueDateCodes.indexOf?.(
                                    dueDateType.dueDateTypeUuid
                                  ) !== -1
                                ) {
                                  selectedDueDateType.push(dueDateType.label);
                                }
                              }
                            );
                          if (
                            selectedDueDateCodes.length > 1 &&
                            selectedDueDateCodes.includes(allDueDateUuid)
                          ) {
                            selectedDueDateCodes =
                              selectedDueDateCodes.filter(
                                (code) => code !== allDueDateUuid
                              );
                          }
                          if (selectedDueDateCodes.length === 0) {
                            selectedDueDateCodes.push(allDueDateUuid);
                          }

                          return {
                            ...stateData,
                            selectedDueDateCodes: [...selectedDueDateCodes],
                            formData: {
                              ...stateData.formData,
                              selectedDueDate: [...selectedDueDateType],
                              selectedDueDateUuid: [...selectedDueDateCodes],
                            },
                          };
                        });
                      }
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={{ marginLeft: 8 }}
                    >
                      {type.dueDateType}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          )}

          {(stateData.formData.selectedDueDateUuid.includes('date_range') ||
            props.isPatientView) && (
              <HStack style={{ }}>
                <VStack space={1}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    From Date
                  </Text>
                  <DatePicker
                    style={{
                      height: '44px',
                      width: 125,
                      borderColor: Colors.Custom.Gray300,
                      borderRadius: 8,
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      stateData.formData.start
                        ? moment(
                          stateData.formData.start,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                        : undefined
                    }
                    // disabledDate={(current: moment.Moment) => {
                    //   return current && isPastDay(current);
                    // }}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              start: formatStrings,
                              end: stateData.formData.end,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
                <VStack space={1} style={{
                  marginLeft: 4
                }}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    To Date
                  </Text>
                  <DatePicker
                    style={{
                      height: '44px',
                      width: 125,
                      borderColor: Colors.Custom.Gray300,
                      borderRadius: 8,
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      stateData.formData.end
                        ? moment(
                          stateData.formData.end,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                        : undefined
                    }
                    // disabledDate={(current: moment.Moment) => {
                    //   return current && isPastDay(current);
                    // }}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              end: formatStrings,
                              start: stateData.formData.start,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
              </HStack>
            )}
        </ FilterWrapperComponent>
      )}

      {topBarData.selectedTab !== TASK_TABS.TASK_POOL && renderPool()}

      {renderTaskLabel()}

    </Stack>
  )

  return (
    isSidecarContext ?
      <Stack direction={'column'} style={{ width: '100%' }}>
        <Stack direction={'column'} style={{ marginRight: -12, marginLeft: -12 }}>
          <Stack direction={'row'} style={{ alignItems: 'center', paddingTop: -6, marginTop: -6, paddingLeft: 12, paddingRight: 8 }}>
            <Text style={{ fontSize: 14, fontWeight: '600', color: Colors?.Custom?.MonochromeBlack }}>
              {intl.formatMessage({
                id: 'filters',
              })}
            </Text>
            <View style={{ flex: 1 }}></View>
            <FoldButtonV2
              key={'clearAll'}
              label={'Clear All'}
              buttonType={'secondary'}
              onPress={() => {
                props?.onActionPerformed(COMMON_ACTION_CODES.CLEAR_ALL, {})
              }}
            />
            <FoldButtonV2
              key={'apply'}
              label={intl.formatMessage({ id: 'apply' })}
              buttonType={'secondary'}
              onPress={() => {
                onSubmit();
              }}
            />
            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginHorizontal: 8,
              }}
            ></View>
            <Pressable
              onPress={props.onCloseFilterView}
            >
              <CrossIcon />
            </Pressable>
          </Stack>
          <Divider style={{ marginTop: 6, marginBottom: 12}} />
        </Stack>
          {renderFilterView}
      </Stack>
      :
      <div className={'task-filters'}>
        {renderFilterView}
      </div>
  );
};

export default FilterRowView;
