import {gql} from '@apollo/client';

export const GetUserAgent = gql`
  query GetUserAgent($searchString: String!) {
    users(
      where: {
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
      }
    ) {
      id
      uid
      type
      email
      name
      userRoles {
        id
        userRoleId
        userRole {
          id
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

export const AssignUser = gql`
  mutation AssignUser($id: bigint!, $assigneeId: Int!) {
    updateConversation(pk_columns: {id: $id}, _set: {assigneeId: $assigneeId}) {
      assigneeId
      contactId
      conversationUser {
        name
        id
        email
      }
    }
  }
`;
export const RemoveAssignUser = gql`
  mutation RemoveAssignUser($id: bigint!) {
    updateConversation(pk_columns: {id: $id}, _set: {assigneeId: null}) {
      assigneeId
      contactId
      conversationUser {
        name
        id
        email
      }
    }
  }
`;

export const GetUserAgentByName = gql`
  query GetUserByName($searchString: String!) {
    accountUsers(
      where: {
        user: {name: {_ilike: $searchString}}
      }
    ) {
      user {
        id
        uid
        uuid
        type
        email
        name
        userRoles {
          id
          userRoleId
          userRole {
            id
            roleCategory {
              code
            }
            userRole {
              id
              code
              value
            }
          }
        }
      }
    }
  }
`;

export default {
  GetUserAgent,
  AssignUser,
  RemoveAssignUser,
  GetUserAgentByName,
};
