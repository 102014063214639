import React, {useState, useEffect} from 'react';
import {View, Text, Pressable} from 'react-native';
import LogTimeSvg from '../Svg/LogTimeSvg';
import {Colors} from '../../../styles/Colors';
import Stack from '../LayoutComponents/Stack';
import {useIntl} from 'react-intl';

export interface IStopwatch {
  onStop: (minutes: number, seconds: number) => void;
  onStart: () => void;
}

const Stopwatch = (props: IStopwatch) => {
  const [time, setTime] = useState(0);

  const [isRunning, setIsRunning] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const startAndStop = () => {
    if (isRunning) {
      props.onStop(minutes, seconds);
    } else {
        props.onStart();
    }
    setIsRunning(!isRunning);
  };


  const startTimeElement = (
    <Stack
      style={{
        borderColor: Colors.Custom.PrimaryColor200,
        backgroundColor: Colors.FoldPixel.PRIMARY100,
        borderWidth: 0.5,
        padding: 8,
        alignItems: 'center',
        borderRadius: 4,
        width: 'fit-content',
      }}
      space={4}
      direction={'row'}
    >
      <LogTimeSvg />
      <Text
        style={{
          fontWeight: '500',
          fontSize: 14,
          color: Colors.FoldPixel.PRIMARY300,
        }}
      >
        {intl.formatMessage({id: 'startTime'})}
      </Text>
    </Stack>
  );

  const stopTimeElement = (
    <Stack
      style={{
        backgroundColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
        padding: 8,
        alignItems: 'center',
        borderRadius: 4,
        width: 'fit-content',
      }}
      space={4}
      direction={'row'}
    >
      <LogTimeSvg strokeColor={Colors.FoldPixel.STATUS_DARK_SUCCESS} />
      <Text
        style={{color: Colors.FoldPixel.STATUS_DARK_SUCCESS, fontWeight: '500'}}
      >
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </Text>
      <Text
        style={{
          fontWeight: '500',
          fontSize: 14,
          color: Colors.FoldPixel.STATUS_DARK_SUCCESS,
        }}
      >
        {' '}
        • {intl.formatMessage({id: 'stop'})}
      </Text>
    </Stack>
  );

  return (
    <View>
      <Pressable onPress={startAndStop}>
        {isRunning ? stopTimeElement : startTimeElement}
      </Pressable>
    </View>
  );
};

export default Stopwatch;
