import {useLazyQuery, useQuery} from '@apollo/client';
import {
  HStack,
  Pressable,
  View,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CONVERSATION_STATUS, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {COMPONENT_SUPPORTED_EVENT, CONVERSATION_LOCAL_EVENT_CODES, SUPPORTED_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {IGroupMember, IGroupUser} from '../../../../../services/Conversations/interfaces';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {IInboxMember} from '../../../../../services/Inbox/interfaces';
import {sendConversationNotificationNoMessageEvent} from '../../../../../services/SendNotification';
import {Colors} from '../../../../../styles';
import {EventBus} from '../../../../../utils/EventBus';
import {
  getAccountId,
  getUserId,
  getUserName,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {SearchBar} from '../../../../common/SearchBar';
import {assignConversationAPI, unAssignConversationAPI} from '../ConversationChannelNew/ConversationAPIService';
import {getAccountUserByUserId, getGroupMembersListForAssignee} from '../ConversationChannelNew/ConversationSidebarUtils';
import {CHANNEL_TYPE_CODE, ERROR_CODE} from '../ConversationConst';
import {IConversationActionView} from '../MessagingWindow/interface';
import UserListView from './UserListView';
import { getBodyForAssignUser, handleErrorMsgForAssign } from '../conversationUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {localBroadcastEvent} from '../../../../../utils/CustomEventHandler';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { IMessageCreated } from '../../../../../Interfaces/WebSocketEventInterfaces';
import { useToast as useCustomToast } from '../../../../Toast/ToastProvider';
import ConversationsQueriesV2 from '../../../../../services/Conversations/V2/ConversationsQueriesV2';

const ConversationActionView = (props: IConversationActionView) => {
  if (!props.conversation) {
    return null;
  }
  const customToast = useCustomToast();
  const intl = useIntl();
  const conversationId = props.conversation.id;
  const myUserId = getUserId();
  const accountId = getAccountId();
  const myUserUUID = getUserUUID();

  const groupMembers = props.conversation?.groupConversation?.groupMembers || [];
  const inboxMembers = props?.conversation?.conversationInbox?.inboxMembers || [];
  const userName = getUserName();
  const [users, setUsers] = useState([] as IGroupUser[]);
  const [loadingStates, setLoadingStates] = useState(false);

  const [stateData, setStateData] = useState({
    selectionLoading: false
  })

  const onUserListViewActionPerformed = async (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ASSIGN_USER:
        if (prevAssigneeUUID === rowData.id) {
          return
        }
        assignUsers(rowData.uuid, rowData?.name);
        break;
      case COMMON_ACTION_CODES.UNASSIGN:
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: true
          }
        })
        unAssignConversation()
        break;
    }
  };

  const unAssignConversation = async ()=> {
    const body = {
      conversationUuid: props.conversation?.uuid || '',
    };

    const unAssignResponse = await unAssignConversationAPI({
      body,
    }).catch((err: any) => {
      const errorMsg = handleErrorMsgForAssign(err?.response?.data);
      showToast(
        customToast,
        errorMsg?.length ? errorMsg : intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        2000,
        true
      );

      setStateData(prev => {
        return {
          ...prev,
          selectionLoading: false
        }
      })
    })
    if (unAssignResponse?.data?.conversationUuid) {
      try {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED, {
          conversation: props.conversation,
          assignUser: {},
          messageData: unAssignResponse?.data?.messageData
        });
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: false
          }
        });
      } catch (e) {
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: false
          }
        })
      }
      showToast(
        customToast,
        intl.formatMessage({id: 'userUnAssignSuccessfully'}),
        ToastType.success,
        1000,
        true
      );
      props?.onSelectUser?.(null)
    } else {
      setStateData(prev => {
        return {
          ...prev,
          selectionLoading: false
        }
      })
    }
  }

  const {data, refetch} = useQuery(
    ConversationsQueries.GetConversationUserById,
    {
      variables: {
        conversationId: conversationId,
        loginUserId: myUserId,
      },
    }
  );
  const [getAllInboxesMembersByInboxId] = useLazyQuery<{
    inboxMembers: IInboxMember[]
  }>(ConversationsQueriesV2.GetInboxMemberByInboxId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [GetInboxMemberPubSubTokenById] = useLazyQuery(
    InboxQueries.GetInboxMemberPubSubTokenById,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [GetGroupMembersByConversationUuid] = useLazyQuery(
    ConversationsQueriesV2.GetGroupMembersByConversationUuid,
    {
      variables: {
        conversationUuid: props?.conversation?.uuid
      },
      fetchPolicy: 'no-cache',
    }
  )

  const getMemberDetails = async (searchString: string) => {
    setUsers([]);
    setLoadingStates(true);
    const inboxId =
      props.conversation?.inboxId || props.conversation?.conversationInbox?.id;
    if (inboxId === -1) {
      const groupMembersData = await GetGroupMembersByConversationUuid();
      const groupMembersList =
        groupMembersData?.data?.groupConversations?.[0]?.groupMembers;
      if (groupMembersList) {
        const finalGroupMembers = getGroupMembersListForAssignee(
          groupMembersList,
          myUserUUID
        );
        setUsers(finalGroupMembers);
        setLoadingStates(false);
        props?.onActionPerformed &&
          props?.onActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_GROUP_MEMBER,
            groupMembersList
          );
      }
    } else {
      const allInboxMembers = await getAllInboxesMembersByInboxId({
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        variables: {
          inboxId: inboxId,
        },
      });
      if (allInboxMembers?.data && allInboxMembers.data?.inboxMembers?.length) {
        const privateConversMembers: IGroupUser[] = [];
        allInboxMembers.data?.inboxMembers?.map((userItem: any) => {
          privateConversMembers.push(userItem?.user || ({} as IGroupUser));
        });
        setUsers(privateConversMembers);
        setLoadingStates(false);
        props?.onActionPerformed &&
          props?.onActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_INBOX_MEMBER,
            allInboxMembers?.data?.inboxMembers
          );
      } else {
        setUsers([]);
        setLoadingStates(false);
      }
    }
  };
  const selectedUsers =
    props?.assignedUserData?.data?.conversations?.[0]?.assignedUser ||
    props?.assignedUserData ||
    {};
  const prevAssigneeUUID = selectedUsers.uuid || '';

  const assignUsers = async (userId: any, name: string) => {
    // setStateData(prev => {
    //   return {
    //     ...prev,
    //     selectionLoading: true
    //   }
    // });
    // setStateData(prev => {
    //   return {
    //     ...prev,
    //     selectionLoading: false
    //   }
    // });
    // TODO Memory_Issue
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_ACTION_BUS, {
      userUuid: userId,
      conversationUuid: props.conversation?.uuid,
      conversation: props.conversation,
    });
    props?.onSelectUser && props?.onSelectUser(userId, 0);

    // const body = getBodyForAssignUser(userId, props.conversation?.uuid)
    // const assignedUserRes = await assignConversationAPI({
    //   body,
    // }).catch((err: any) => {
    //   const errorMsg = handleErrorMsgForAssign(err?.response?.data);
    //   showToast(
    //     toast,
    //     errorMsg?.length
    //       ? errorMsg
    //       : intl.formatMessage({id: 'apiErrorMsg'}),
    //     ToastType.error,
    //     2000
    //   );

    //   setStateData(prev => {
    //     return {
    //       ...prev,
    //       selectionLoading: false
    //     }
    //   })
    // })

    // if (assignedUserRes?.data) {
    //   if (!isConversationEventHandlingEnabled&& props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME && userId !== myUserId) {
    //     const eventBus = EventBus.getEventBusInstance();
    //     eventBus.broadcastEvent(COMPONENT_SUPPORTED_EVENT.CONVERSATION_UPDATED, {
    //       conversation: props.conversation,
    //     });
    //   }
    //   const toastMessage =
    //     props?.conversation?.status === CONVERSATION_STATUS.RESOLVED
    //       ? assignedUserRes?.data?.messageData?.content
    //       : intl.formatMessage({id: 'userAssignSuccessfully'});
    //   props?.onSelectUser && props?.onSelectUser(userId, assignedUserRes?.data?.messageData?.status)
    //   showToast(
    //     toast,
    //     toastMessage,
    //     ToastType.success,
    //     1000,
    //   );
    //   //refetch();
    //   try {
    //     if (isConversationEventHandlingEnabled) {

    //       if (props?.conversation?.status === CONVERSATION_STATUS.RESOLVED) {
    //         localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED, {
    //           conversation: props?.conversation,
    //           messageData: assignedUserRes?.data?.messageData,
    //         });
    //       } else {
    //         localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNED, {
    //           conversation: props.conversation,
    //           assignUser: {
    //             assigneeId: userId,
    //             assigneeName: name,
    //             previousAssigneeId: prevAssigneeUUID,
    //             conversationUuid: props.conversation?.uuid,
    //             displayId: props.conversation?.displayId,
    //             conversationId: props.conversation?.id,
    //             assignByUserId: myUserId
    //           },
    //           messageData: assignedUserRes?.data?.messageData
    //         });
    //       }
    //       if (props?.conversation?.status === CONVERSATION_STATUS.RESOLVED) {
    //         localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED, {
    //           conversation: props?.conversation,
    //           messageData: {} as IMessageCreated,
    //         });
    //       }
    //     } else {
    //       sendConversationNotificationNoMessageEvent({
    //         conversationUuid: props.conversation?.uuid || '',
    //         eventData: {
    //           assigneeId: userId,
    //           assigneeName: name,
    //           previousAssigneeId: prevAssigneeUUID,
    //           conversationUuid: props.conversation?.uuid,
    //           displayId: props.conversation?.displayId,
    //           conversationId: props.conversation?.id,
    //           assignByUserId: myUserId
    //         },
    //         eventCode: SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
    //       }).catch((e) => {
    //
    //         })
    //     }

    //     setStateData(prev => {
    //       return {
    //         ...prev,
    //         selectionLoading: false
    //       }
    //     })
    //   } catch (e) {
    //
    //     setStateData(prev => {
    //       return {
    //         ...prev,
    //         selectionLoading: false
    //       }
    //     })
    //   }
    // }
  };


  const getGroupMembersList = () => {
    const finalGroupMembers = getGroupMembersListForAssignee(groupMembers, myUserUUID);
    setUsers(finalGroupMembers);
  }

  const getInboxMembersList = () => {
    const privateConversMembers: IGroupUser[] = [];
    inboxMembers?.map((userItem: any) => {
      privateConversMembers.push(
        userItem?.user || ({} as IGroupUser)
      );
    });
    setUsers(privateConversMembers);
  };

  useEffect(() => {
    if(groupMembers?.length === props?.conversation?.groupConversation?.groupMembers_aggregate?.aggregate?.count){
      getGroupMembersList();
    } else if (inboxMembers?.length === props?.conversation?.conversationInbox?.inboxMembers_aggregate?.aggregate?.count) {
      getInboxMembersList();
    } else {
      getMemberDetails('');
    }
  }, [props.conversation?.groupConversation?.groupMembers, props.conversation?.id]);

  const content = (
    <>
      <UserListView
        conversationId={conversationId}
        onActionPerformed={onUserListViewActionPerformed}
        loadingStates={loadingStates}
        users={users}
        selectedUsers={selectedUsers}
        selectionLoading={stateData.selectionLoading}
      />
    </>
  );

  return (
    <>
      <View flex={1}>
        <HStack flex={1} justifyContent={'space-between'}>
          <DisplayText
            extraStyles={{fontSize: 16, color: Colors.Custom.Gray400, fontWeight: '500'}}
            textLocalId="assignConversation"
          ></DisplayText>

          {(prevAssigneeUUID != myUserUUID) || !prevAssigneeUUID.length ? (
            <Pressable
              flexDirection={'row'}
              alignItems={'center'}
              onPress={() => {
                assignUsers(myUserUUID, userName);
              }}
              isDisabled={stateData.selectionLoading}
              justifyContent={'flex-end'}
            >
              <View
                backgroundColor={'white'}
                padding={1}
                px={3}
                borderRadius={8}
                style={{
                  borderWidth: 1,
                  borderColor: Colors.Custom.mainPrimaryPurple,
                  height: '28px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DisplayText
                  textLocalId="assignToMe"
                  size={'smLight'}
                  extraStyles={{
                    color: Colors.Custom.mainPrimaryPurple || '',
                    marginLeft: 2,
                  }}
                />
              </View>
            </Pressable>
          ) : (
            ''
          )}
        </HStack>

        <View
          flex={1}
          marginBottom={2}
          marginTop={2}
          style={{borderBottomWidth: 1, borderBottomColor: Colors.Custom.Gray300}}
        />
        <View marginY={2}>
          <SearchBar
            onChange={(value: string) => {
              const searchString = value;
              if (groupMembers?.length) {
                if (searchString.length) {
                  const privateConversMembers: IGroupUser[] = [];
                  groupMembers?.map((item: IGroupMember) => {
                    if (item?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
                      if (item?.user?.uuid !== myUserUUID) {
                        const name = item?.user?.name.toLowerCase() || '';
                        const textData = searchString.toLowerCase();
                        if (name.includes(textData)) {
                          if(getAccountUserByUserId(item?.user?.accountUsers,item?.user?.id)?.[0]?.isActive === true) {
                            privateConversMembers.push(item.user as IGroupUser)
                          }
                        }
                      }
                    }
                  })
                  setUsers(privateConversMembers);
                } else {
                  getGroupMembersList();
                }
              } else if (inboxMembers?.length) {
                if (searchString.length) {
                  const privateConversMembers: IGroupUser[] = [];
                  inboxMembers?.map((item: any) => {
                    if (item?.user?.uuid !== myUserUUID) {
                      const name = item?.user?.name.toLowerCase() || '';
                      const textData = searchString.toLowerCase();
                      if (name.includes(textData)) {
                        if (
                          getAccountUserByUserId(
                            item?.user?.accountUsers,
                            item?.user?.id
                          )?.[0]?.isActive === true
                        ) {
                          privateConversMembers.push(item.user);
                        }
                      }
                    }
                  });
                  setUsers(privateConversMembers);
                } else {
                  getInboxMembersList();
                }
              } else {
                getMemberDetails(searchString);
              }
            }}
          />
        </View>
        <View
          flex={1}
          marginBottom={2}
          marginTop={2}
          style={{borderBottomWidth: 1, borderBottomColor: Colors.Custom.Gray300}}
        />
        {content}
      </View>
    </>
  );
};

export default ConversationActionView;
