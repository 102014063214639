import React from 'react';
import {Box} from 'native-base';
import {Colors} from '../../styles';
import Feather from 'react-native-vector-icons/Feather';

const SignIcon = (props: {color?: string; size?: number}) => {
  return (
    <Box
      style={{
        transform: [{rotate: '-90deg'}],
      }}
    >
      <Feather
        name="pen-tool"
        size={props.size || 20}
        color={props.color || Colors.Custom.mainPrimaryPurple}
      />
    </Box>
  );
};

export default SignIcon;
