//import { Content } from 'antd/lib/layout/layout';
//import {TableTopBar} from '../../../../common/TableTopBar';
import {useLazyQuery} from '@apollo/client';
import {Select} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {Button, HStack, Skeleton, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {LeadQueries} from '../../../../../services';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {IContact} from '../../../TeamInbox/Conversations/interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {
  getCampaignSegmentRules,
  getContactsWithinGroup,
  getGroupsList,
  getLeadAndPopulationGroupsForCampaign
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {ICampaignErrors, ICampaignSegmentFormState} from '../interfaces';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import {Colors} from '../../../../../styles';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';

const PreviousSegmentGroup = (props: {
  isReadOnly?: boolean;
  initData?: ICampaignSegmentFormState;
  campaignDetailsError?: ICampaignErrors;
  onChange?: (value: ICampaignSegmentFormState) => void;
  onModalClose?: any;
  onSubmitForm?: any;
  segmentId?: any;
}) => {
  const intl = useIntl();
  const tenantId = getAccountUUID();
  const [campaignGroups, setCampaignGroups] = useState<any[]>([]);
  const [group, setGroup] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [defaultSearchList, setDefaultSearchList] = useState<any>([]);
  const [state, setState] = React.useState({
    loading: false,
    contactList: [] as IContact[],
  });

  const [getContactsByIds] = useLazyQuery(LeadQueries.GetContactsByIds);

  useEffect(() => {
    getLeadAndPopulationGroupsForCampaign({
      accountUuid: tenantId,
      name: '',
      limit: 20,
      offset: 0,
    }).then((resp: any) => {
      if (resp?.data?.data) {
        setCampaignGroups(resp?.data?.data);
        setDefaultSearchList(resp?.data?.data);
      }
    });
    if (props.segmentId) {
      getCampaignSegmentRules(props.segmentId).then((response) => {
        const group = response;
        setGroup(group);
        const isPresent = campaignGroups.some((data: any) => {
          return data.id !== group.id;
        });
        if (!isPresent) {
          setCampaignGroups([...campaignGroups, group]);
        }
      });
    }
  }, []);

  useEffect(() => {
    getLeadAndPopulationGroupsForCampaign({
      accountUuid: tenantId,
      name: search,
      limit: 20,
      offset: 0,
    }).then((resp: any) => {
      if (resp?.data?.data) {
        setCampaignGroups(resp?.data?.data);
      }
    });
  }, [search]);

  useEffect(() => {
    setGroup(props?.segmentId);
  }, [props.segmentId]);

  const getContactsByGroup = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const id = group?.id;
    try {
      const response = await getContactsWithinGroup(id, undefined);
      const contactIds = response?.data?.data || [];
      const contactResponse = await getContactsByIds({
        variables: {
          ids: contactIds,
        },
      });
      const contacts = contactResponse?.data?.contacts || [];
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactList: contacts,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactList: [],
        };
      });

    }
  };

  useEffect(() => {
    if (group?.id) {
      getContactsByGroup();
    }
  }, [group?.id]);

  return (
    <VStack style={{}}>
      <HStack alignItems={'center'} justifyContent={'center'} marginBottom={5}>
        <TitleSubtitleView
          isHideCommonButton={true}
          containerStyle={{marginLeft: 0, marginBottom: 15}}
          titleLabelId={'selectPreviousGroup'}
          subtitleLabelId=""
        />
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  props.onModalClose(false);
                },
                style: {marginRight: 20},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  props?.onSubmitForm(group);
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'submit',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <DisplayText
        extraStyles={{marginLeft: 5}}
        size={'lgMedium'}
        textLocalId={'audience'}
      />
      <Text style={{marginLeft: 5}} color="gray.500">
        {
          'You can define the which members will receive this campaign by creating rules below.'
        }
      </Text>

      <HStack flex={1} justifyContent={'center'}>
        <View
          flex={1}
          style={{marginHorizontal: 0, width: '100%', marginVertical: 20}}
        >
          <Content className="common-scroll">
            {campaignGroups ? (
              <View flex={1}>
                <Select
                  size="large"
                  showSearch
                  //allowClear
                  value={group?.name || undefined}
                  onSearch={(search: string) => {
                    setSearch(search);
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option!.name as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={(value: any[], data: any) => {
                    const selected = (campaignGroups || []).find(
                      (item) => item.id === data.id
                    );
                    setGroup(selected);
                  }}
                  placeholder="Select Category"
                  //loading={loading}
                  //notFoundContent={loading && <Spin size="small" />}
                  style={{height: '40px'}}
                  //className={isShowError && !value ? 'field-error' : ''}
                >
                  {campaignGroups.map((category: any) => {
                    return (
                      <Select.Option
                        key={category.id}
                        id={category.id}
                        value={category.name}
                        name={category.name}
                      >
                        <VStack>
                          <Text
                            style={{
                              fontSize: 16,
                            }}
                          >
                            {category?.name}
                          </Text>
                          <Text
                            style={{
                              color: Colors.Custom.Gray500,
                              fontSize: 14,
                            }}
                          >
                            {category.count} Members
                          </Text>
                        </VStack>
                        {/* {`${category?.name} (${category.count}) `} */}
                      </Select.Option>
                    );
                  })}
                </Select>
              </View>
            ) : null}
          </Content>
        </View>
      </HStack>
      <VStack>
        {state.loading ? (
          <Skeleton.Text lines={5} />
        ) : (
          <VStack space={'2'}>
            {state.contactList.map((item) => {
              return (
                <View>
                  <MemberInfoListItem contactData={item} showDateOfBirth={true} />
                </View>
              );
            })}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default PreviousSegmentGroup;
