import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormControl,
  Input,
  Stack,
  useMediaQuery,
  VStack,
  Text,
  Select,
  View,
  FlatList,
  HStack,
  Spinner,
} from 'native-base';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {v4 as uuidv4} from 'uuid';
import {useLazyQuery} from '@apollo/client';
import ContactsQueries from '../../../../../../../services/Contacts/ContactsQueries';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../../constants/Configs';
import {
  isValidEmail,
  numericStringMask,
  unmaskPhoneNumber,
} from '../../../../../../../utils/commonUtils';
import {IMlov} from '../../../../../../../Interfaces';
import {MlovQueries} from '../../../../../../../services';
import {PHONE_NUMBER_MASK} from '../../../../../../../constants';
import {FormError, componentKeys} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import { CustomComponentKey, EmergencyContactField, isRequiredField } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { cloneDeep } from 'lodash';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../../../styles';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { getCodesWithHeaders, getPatientDetailsWithHeaders } from '../../../../../../../services/CommonService/AidBoxService';
import { Patient } from 'fhir/r4';
import { CodeSearchType } from '../../../../../../common/CodeSearch/CodeSearchConstants';
import AllowOverrideMessage from '../../AllowOverrideMessage/AllowOverrideMessage';
import ContactPracticeToUpdateInfoMessage from '../../ContactPracticeToUpdateInfoMessage/ContactPracticeToUpdateInfoMessage';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import Feather from 'react-native-vector-icons/Feather';
import { CONTACT_RELATION_TYPES } from '../../../../../../common/ContactRelationView/ContactRelationConst';
export interface IEmergencyContactComponentValue {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  relationType?: IRelationItem;
}

interface IRelationItem {
  id?: string;
  code?: string;
  value?: string;
  display?: string;
};

interface IAddOrUpdateEmergencyContactState {
  showErrors: boolean;
  loading: boolean;
  relationList: IRelationItem[];
  formError?: FormError;
}


const AddOrUpdateEmergencyContact = (props: IFormComponentProps) => {
  const componentRef = useRef();
  const intl = useIntl();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const [componentValue, setComponentValue] =
    useState<IEmergencyContactComponentValue>(
      props.component?.selectedValue || {}
    );
    const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [componentState, setComponentState] =
    useState<IAddOrUpdateEmergencyContactState>({
      showErrors: false,
      loading: false,
      relationList: [],
      formError: contextData.formError,
    });

  const validateData = (currentData: IEmergencyContactComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    let isValid = true;
    isValid &&= !isInvalid(
      EmergencyContactField.firstName,
      currentData.firstName,
      true
    );
    isValid &&= !isInvalid(
      EmergencyContactField.lastName,
      currentData.lastName,
      true
    );
    isValid &&= !isInvalid(
      EmergencyContactField.email,
      currentData.email,
      true
    );
    isValid &&= !isInvalid(
      EmergencyContactField.phoneNumber,
      currentData.phoneNumber,
      true
    );
    isValid &&= !isInvalid(
      EmergencyContactField.relationType,
      currentData.relationType,
      true
    );
    return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isDisabled = () => !props.component?.allowToEdit || false;

  const isRequired = (field: EmergencyContactField) => {
    return isRequiredField(CustomComponentKey.EMERGENCY_CONTACT, field) && !isDisabled();
  };


  const isInvalid = (
    field: EmergencyContactField,
    value?: any,
    showErrors?: boolean
  ) => {
    const canShowErrors = showErrors || componentState.showErrors;
    switch (field) {
      case EmergencyContactField.email:
        if (value) {
          return !isValidEmail(value) && canShowErrors;
        }
        return !value && isRequired(field) && canShowErrors;

      case EmergencyContactField.phoneNumber:
        if (value) {
          return value.length !== 10 && canShowErrors;
        }
        return !value && isRequired(field) && canShowErrors;

      case EmergencyContactField.relationType:
        return (!value || !value.code) && isRequired(field) && canShowErrors;
      default:
        return !value && isRequired(field) && canShowErrors;
    }
  };

  const getExistingData = (patientId: string, relationList: IRelationItem[],skipLoader?: boolean) => {
    if(!skipLoader){
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getPatientDetailsWithHeaders(
      patientId,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        if (response?.data?.id) {
          const newData = {
            firstName: getFirstName(response.data),
            lastName: getLastName(response.data),
            email: getEmail(response.data),
            phoneNumber: getPhone(response.data),
            relationType: getRelationType(response.data, relationList),
          };
          setComponentValue((prev) => ({
            ...prev,
            ...newData,
          }));
          contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.EMERGENCY_CONTACT, newData);
        }
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: !response?.data?.id
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
        setImportFromChartLoading(false);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
        setImportFromChartLoading(false);
      },
      contextData.locationId,
    );
  };

  const getFirstName = (patientDetail: Patient) => {
    const patientContact = patientDetail?.contact?.[0];
    if (patientContact?.name?.given?.length) {
      return patientContact.name.given[0];
    }
    if (patientContact?.name?.text?.trim()) {
      const nameList = patientContact.name.text.split(' ');
      return nameList.length ? nameList[0] : '';
    }
    return '';
  };

  const getLastName = (patientDetail: Patient) => {
    const patientContact = patientDetail?.contact?.[0];
    if (patientContact?.name?.family) {
      return patientContact.name.family;
    }
    if (patientContact?.name?.text?.trim()) {
      const nameList = patientContact.name.text.split(' ');
      if (nameList.length) {
        nameList.splice(0, 1);
        return nameList.join(' ');
      }
    }
    return '';
  };

  const getEmail = (patientDetail: Patient) => {
    const patientContact = patientDetail?.contact?.[0];
    const emailData = patientContact?.telecom?.find((item) => {
      return item.system === 'email';
    });
    return emailData?.value || '';
  }

  const getRelationType = (patientDetail: Patient, relationList: IRelationItem[]) => {
    const patientContact = patientDetail?.contact?.[0];
    if (patientContact?.relationship?.length) {
      const relationCode = patientContact?.relationship[0].text || patientContact?.relationship[0].coding?.[0]?.display;
      const relationType = relationList.find((item) => {
        return relationCode && (relationCode?.toLowerCase() === item.code?.toLowerCase() || relationCode?.toLowerCase() === item.display?.toLowerCase());
      });
      return relationType ? {
        value: relationType?.display,
        code: relationType?.code,
      } : undefined;
    }
    return undefined;
  }

  const getPhone = (patientDetail: Patient) => {
    const patientContact = patientDetail?.contact?.[0];
    const phoneData = patientContact?.telecom?.find((item) => {
      return item.system === 'phone';
    });
    return unmaskPhoneNumber(phoneData?.value || '');
  }

  const fetchMetaData = async () => {
    try {
      const response = await getCodesWithHeaders(
        CodeSearchType.emergencyRelationType,
        !contextData.hasLoggedInContext,
        contextData.headers
      );

      if (response?.data?.expansion?.contains?.length) {
        setComponentState((prev) => ({
          ...prev,
          relationList: response?.data?.expansion?.contains,
        }));
        return response?.data?.expansion?.contains;
      } else {
        setComponentState((prev) => ({
          ...prev,
          formError: !response?.data?.expansion?.contains
            ? FormError.configurationDataAPIFail
            : prev.formError,
        }));
      }
    } catch (error) {

      setComponentState((prev) => ({
        ...prev,
        formError: FormError.configurationDataAPIFail,
      }));
    }
  };

  const initData = async () => {
    const patientId = contextData?.patientId;
    const relationList = await fetchMetaData() || [];
     // if fields are not editable the latest data should be prefilled
     // Removing !isPatientNote check here, as we need to prefill the notes data always
    if (patientId && /*!isPatientNote &&*/ (isDisabled() || !props.component.selectedValue)) {
      setComponentState((prev) => ({...prev, loading: true}));
      getExistingData(patientId, relationList);
    } else {
      setComponentState((prev) => ({...prev, loading: false}));
    }
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  props.validateRef.current = validateData;

  const canShow = (field: EmergencyContactField) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return true;
  };
  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];
    const data = ['firstName', 'lastName', 'phoneNumber', 'relationType'];
    data.forEach((item) => {
      const value = componentValue[
        item as keyof IEmergencyContactComponentValue
      ] as any;
      const previewItem = {
        key: `${intl.formatMessage({id: item})}`,
        value: `${item === 'relationType' ? value?.code : value}`,
        id: uuidv4(),
      };
      if (value) previewData.push(previewItem);
    });
    return previewData;
  };
  if (isPreviewMode) {
    const data = getPreviewData();
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {data.length > 0 && (
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={data}
                  initialNumToRender={data?.length}
                  keyExtractor={(item) => item.id}
                  renderItem={({item}) => (
                    <div className="page-break">
                      <HStack key={item.id} flex={1}>
                        <Text flex={3}>{item.key}</Text>
                        <Text flex={7}>
                          {item.value}
                        </Text>
                      </HStack>
                    </div>
                  )}
                />
              </DetailPreview>
            )}
          </>
        )}
      </>
    );
  }

  const showImportButton = isPatientNote && !isPreviewMode && !isDisabled();

  const fetchImportData = async () => {
    if (isPatientNote && !isPreviewMode && contextData?.contactId) {
      setImportFromChartLoading(true);
      const patientId = contextData?.patientId as string;
      const relationList = (await fetchMetaData()) || [];
      // if fields are not editable the latest data should be prefilled
      getExistingData(patientId, relationList , true);
    }
  };

  return (
    <div className='page-break'>
    <VStack ref={componentRef}>
      {isPatientForm && !props.component?.allowToEdit && <ContactPracticeToUpdateInfoMessage />}
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      <HStack alignItems={'center'} mb={2} mt={1}>
        <Text fontSize={16} fontWeight="bold" flex={1} >
          {props.component.label}
        </Text>
        {showImportButton && (
          <CustomButton
            styles={{alignSelf: 'flex-end'}}
            title="Import from Chart"
            isDisabled={importFromChartLoading}
            leftIcon={
              importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              )
            }
            onPress={fetchImportData}
          />
        )}
      </HStack>
      {!componentState.loading && !componentState.formError && (
        <VStack space={4} flex={1}>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            <FormControl
              isInvalid={isInvalid(
                EmergencyContactField.firstName,
                componentValue.firstName
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(EmergencyContactField.firstName)}
              >
                <DisplayText textLocalId="firstName" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                value={componentValue.firstName}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, firstName: text}));
                }}
              />
            </FormControl>
            <FormControl
              isInvalid={isInvalid(
                EmergencyContactField.lastName,
                componentValue.lastName
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(EmergencyContactField.lastName)}
              >
                <DisplayText textLocalId="lastName" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                value={componentValue.lastName}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, lastName: text}));
                }}
              />
            </FormControl>
          </Stack>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
          {/* Commented for the future use
           {canShow(EmergencyContactField.email) && (
            <FormControl
              isInvalid={isInvalid(
                EmergencyContactField.email,
                componentValue.email
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(EmergencyContactField.email)}
              >
                <DisplayText textLocalId="email" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                placeholder="abc@xyz.com"
                value={componentValue.email}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, email: text}));
                }}
              />
              {componentState.showErrors &&
                componentValue.email &&
                !isValidEmail(componentValue.email) && (
                  <Text marginTop={1} size="smNormal" color="error.500">
                    Please enter a valid email.
                  </Text>
                )}
            </FormControl>
          )} */}
            <FormControl
              isInvalid={isInvalid(
                EmergencyContactField.phoneNumber,
                componentValue.phoneNumber
              )}
              isDisabled={isDisabled()}
              isRequired={isRequired(EmergencyContactField.phoneNumber)}
              flex={1}
            >
              <FormControl.Label>
                <DisplayText textLocalId="phoneNumber" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                placeholder="(123) 123-1234"
                maxLength={14}
                value={numericStringMask(
                  componentValue.phoneNumber || '',
                  PHONE_NUMBER_MASK
                )}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  const number = unmaskPhoneNumber(text);
                  setComponentValue((prev) => ({...prev, phoneNumber: number}));
                }}
              />
              {componentState.showErrors &&
                componentValue.phoneNumber &&
                componentValue.phoneNumber.length !== 10 && (
                  <Text marginTop={1} size="smNormal" color="error.500">
                    Please enter a valid phone number.
                  </Text>
                )}
            </FormControl>

            <FormControl
              isInvalid={isInvalid(
                EmergencyContactField.relationType,
                componentValue.relationType
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(EmergencyContactField.relationType)}
              >
                <DisplayText textLocalId="relationType" />
              </FormControl.Label>
              <Select
                selectedValue={componentValue.relationType?.code || ''}
                isDisabled={props.disabled}
                onValueChange={(value: string) => {
                  const selectedRelation = componentState.relationList.find(
                    (relation: any) => {
                      return relation.code == value;
                    }
                  );
                  setComponentValue((prev) => ({
                    ...prev,
                    relationType: {
                      id: selectedRelation?.id,
                      code: selectedRelation?.code,
                      value: selectedRelation?.value,
                    },
                  }));
                }}
              >
                {componentState.relationList.map((option, index) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code || ''}
                      label={option.display || ''}
                    />
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          {contextData.isBuilderMode && !props.component?.allowToEdit && <AllowOverrideMessage />}
        </VStack>
      )}
    </VStack>
    </div>
  );
};

export default AddOrUpdateEmergencyContact;
