import {useLazyQuery} from '@apollo/client';
import {Popover, Tooltip, notification} from 'antd';
import {
  Button,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
} from 'native-base';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {default as AntIcon} from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {
  CONVERSATION_CONST,
  CONVERSATION_STATUS,
  CONVERSATION_STATUS_ACTION,
  GROUP_MEMBER_TYPE,
  HEADER_ACTION_ITEM,
  PERSON_TYPES,
  THIRTEEN_INCH_WIDTH,
  eleven_inch_width,
  getInActiveContactError,
} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {GROUP_TYPE_CODES, MLOV_CATEGORY} from '../../../../../constants/MlovConst';
import {COMPONENT_SUPPORTED_EVENT, CONVERSATION_LOCAL_EVENT_CODES, SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../services';
import {GET_CARE_TEAM} from '../../../../../services/CareTeam/CareTeamQueries';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {sendConversationNotificationNoMessageEvent} from '../../../../../services/SendNotification';
import {GET_USERS_WITH_IDS} from '../../../../../services/User/UserQueries';
import {Colors} from '../../../../../styles';
import {EventBus} from '../../../../../utils/EventBus';
import {
  getAccountId,
  getPatientAgeGender,
  getPrimaryGenderCode,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelTwillioSms,
  isWebChannelInboxType,
  notListedPronounId,
  showInfoOnMessageHeader
} from '../../../../../utils/commonUtils';
import {PatientInfoBanner} from '../../../../PatientInfoBanner/PatientInfoBanner';
import {isCurrentMenuLast} from '../../../../PersonOmniView/LeftContainer/PamiDetail/PamiDetailUtils';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {VideoCallSvg} from '../../../../common/Svg';
import EmailSvg from '../../../../common/Svg/EmailSvg';
import FileIconSvg from '../../../../common/Svg/FileIconSvg';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import ChatActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import SmsActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import SmsSvg from '../../../../common/Svg/SmsSvg';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import CreateAllConversationPopup from '../ConversationChannelNew/CreateAllConversationPopup';
import {CHANNEL_TYPE, CONVERSATION_TAB_CODES, ERROR_CODE} from '../ConversationConst';
import {IContact, IConversationData, IMessagingWindowHeaderProps} from '../interfaces';
import {
  canShowArchive,
  canShowAssignment,
  getAvatarNameAndType,
  getButtonActionObject,
  getEventCodeFromParentCode,
  getGroupMemberList,
  getMessagingHeaderInfoInJSON,
  getPatientAgeAndGenderJson,
  getPatientAgeAndGenderObject,
  getPatientAgeAndGenderStr,
  isBroadCastGroup,
  isGroupConversation,
  isGroupMember,
  isGroupMembersAvailable,
  isInboxMember,
  isInternalChat,
  isPrivateGroup,
  isUserGroupsGroup,
  messageDataKeysConverter
} from './MessagingUtils';
import {styles} from './MessagingWindowStyles';
import {assignStatusValue} from '../ConversationContainer/ConversationConst';
import MemberListByContactValue from './MemberListByContactValue';
import { getGroupName} from '../ConversationChannelNew/ConversationSidebarUtils';
import {ACTION_MESSAGE_CODE} from '../../../../common/ActionMessageView/ActionMessageViewConst';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { IUser } from '../../../Contacts/TeamMembers/interfaces';
import { useContainerDimensions } from '../../../../CustomHooks/ContainerDimensionHook';
import { IMessageTopHeaderInfo } from './interface';
import { ConversationAttachments } from '../../../../common/ChatUI/MessageBox/ConversationAttachments';
import { getAvatarFullName } from '../ConversationContainer/ConversationList/ConversationListItemUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {localBroadcastEvent} from '../../../../../utils/CustomEventHandler';
import { getEhrConfig, getEHRUrl } from '../../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData } from '../../../../../services/CommonService/AidBoxService';
import { IContentAttributesAssigneeData} from '../ConversationChannelNew/Interfaces';
import {ILocalAssigneeChangedEventData} from '../../../../BodyContainer/interface';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import SideCarBackSvg from '../../../../common/Svg/SideCarBackSvg';
import MenuHorizontalIcon from '../../../../common/Svg/MenuHorizontalIcon';
import ArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ArchiveActionSvg';
import UnArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/UnArchiveActionSvg';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import SearchActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SearchActionSvg';
import { getViewInEHRLink, redirectToNewTab } from '../../../../SideCar/SidecarUtils';

const MessagingWindowHeaderComponent = (props: IMessagingWindowHeaderProps) => {
  const ehrConfig = getEhrConfig();
  const ehrURL = getEHRUrl();
  const {
    contactData,
    conversation,
    onActionPerformed,
    isDisplayActions,
    selectedInboxTypeCode,
    showInfoIconInHeader,
    isDetailsContainerVisible,
    isInstantChatView,
    isSelectChatOn,
    isSeachMsgContainerVisible,
    parentCode,
    messagingWindowHeaderLoading,
    moduleCode,
    pcpUserData
  } = props;
  const {width, resetDimension} = useContainerDimensions(props?.headerContainerRef);
  const isEmailConversation = isChannelEmail(props.conversation?.conversationInbox?.channelType || '')
  const emailSubject = props.conversation?.additionalAttributes?.mail_subject || ''
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const headerContainerRef: any = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [button, setButton] = useState({} as any);
  const navigate = useNavigate();
  const genderCode = getPrimaryGenderCode(contactData);
  const groupName = getGroupName(conversation as IConversationData);
  const mlovData = useContext(CommonDataContext);
  const isPatientSidecarContext = mlovData.sidecarContext?.isPatient;
  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const showInfoOnHeaderData = showInfoOnMessageHeader();
  const pronounList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const notListedPronounData = notListedPronounId(pronounList);
  const avatarNameAndType = getAvatarNameAndType(userUuid, conversation)

  const [getInboxById] = useLazyQuery(InboxQueries.GetInboxByIdIsActive, {
    fetchPolicy: 'no-cache',
  });

  const [is13InchScreen, is11InchScreen] = useMediaQuery([
    {maxWidth: THIRTEEN_INCH_WIDTH},
    {maxWidth: eleven_inch_width},
  ]);
  const isNavigateToOmniView = is11InchScreen || is13InchScreen

  const handleNavigateToOmniView = () => {
    const contactId = contactData?.id;
    const type = contactData?.contactType?.contactType?.code || '';
    if (type == PERSON_TYPES.CUSTOMER) {
      props?.navigateOrOpenContactIdDrawer?.(contactId?.toString(), {
        state: conversation?.conversationInbox,
      });
    } else if (type == PERSON_TYPES.LEAD) {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  }

  const [stateData, setStateData] = useState({
    loadingArchive: false as boolean,
    conversationAttachmentDrawer: false,
    assignUserListPopup: false,
    moreActionPopup: false,
    isDetailsContainerVisible: isDetailsContainerVisible || false,
    isNoContactFound: false,
    substringLoading: false,
    subString: '' as any,
    assignUserName: '',
    actionMessageCode: '',
    actionMessageLoading: true,
    pcpUserData: pcpUserData || {} as IUser,
    contactData: {} as IContact,
    isSeachMsgContainerVisible: isSeachMsgContainerVisible || false,
    contactDeceasedDate: '' as string,
    assignedUserData: {} as any,
  });
  const channelType = conversation?.conversationInbox?.channelType || '';

  const findPatient = (members: any) => {
    const patient = members.filter((member: any) => {
      return member.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT;
    });
    return patient[0];
  };

  const handleResolve = async (status: any) => {
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS, {
      conversation: props.conversation,
      status,
    });
    if (status === assignStatusValue.ARCHIVED) {
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_ARCHIVED, {
        conversation: conversation,
      });
    } else if (status === assignStatusValue.OPEN) {
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_UN_ARCHIVED, {
        conversation: conversation,
      });
    }
    if (isInstantChatView && status === assignStatusValue.ARCHIVED && props?.onActionPerformed) {
      props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE, conversation)
    }
    setStateData((prev) => {
      return {
        ...prev,
        assignUserListPopup: false,
        loadingArchive: false,
      };
    });
  };

  const createNotification = async (updateConversation: any) => {
    if (updateConversation.conversationData?.status === assignStatusValue.ARCHIVED) {
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED, {
        conversation: conversation,
        messageData: updateConversation?.messageData
      });
    } else if (updateConversation.conversationData?.status === assignStatusValue.OPEN) {
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED, {
        conversation: conversation,
        messageData: updateConversation?.messageData
      });
    }
  };

  const [getCareTeam] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getUserDetailData] = useLazyQuery(GET_USERS_WITH_IDS);
  const [getAssignUserData] = useLazyQuery(
    ConversationsQueries.GetConversationUserById,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        conversationId: conversation?.id,
        loginUserId: userId,
      },
    }
  );

  const [getContactForMessageHeader] = useLazyQuery(LeadQueries.GetContactForMessageHeader, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      id: contactData.id,
    },
  });


  const onContactClicked = (contactId: any) => {
    const type = stateData.contactData.contactType
      ? stateData.contactData.contactType.contactType.value
      : '';
    let currentStatus = true;
    if (
      isGroupConversation(conversation || ({} as any)) &&
      isUserGroupsGroup(conversation || ({} as any))
    ) {
      currentStatus = true;
    } else {
      currentStatus = isActiveContact(contactData);
      if (!currentStatus && !props.defaultActiveStatus) {
        const message = getInActiveContactError(contactData);
        notification.error({
          message,
        });
        return;
      }
    }
    if (type == 'Customer') {
      props?.navigateOrOpenContactIdDrawer?.(contactId, {
        state: conversation?.conversationInbox,
      });

    } else if (type == 'Lead') {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  };

  const setHeaderTitle = (conversation: any) => {
    return conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT;
  };
  const setHeaderSubtitle = (conversation: any, displayName: string) => {
    if (
      conversation?.groupConversation?.groupType?.code ===
      GROUP_TYPE_CODES.PRIVATE
    ) {
      if (conversation?.groupConversation?.name.length > 0) {
        return conversation?.groupConversation?.name;
      } else {
        const patient = findPatient(
          conversation?.groupConversation?.groupMembers
        );
        const ageGender = getPatientAgeGender(patient?.contact?.person);

        return ageGender;
      }
    } else {
      return displayName;
    }
  };

  const isShowForSmsOrEmail =
    isChannelEmail(channelType) || isChannelTwillioSms(channelType)
      ? true
      : false;

  const substringForEmailOrSms: any = (
    <MemberListByContactValue
      contactData={contactData}
      channelType={channelType}
      conversationUuid={conversation?.uuid || ''}
      navigate={navigate}
      onCallBackAction={(code: any) => {
        if (COMMON_ACTION_CODES.NO_CONTACT_FOUND == code) {
          setStateData((prev) => {
            return {
              ...prev,
              isNoContactFound: true,
            };
          });
        }
      }}
    />
  );

  const getMessagingHeaderInfo = () => {
    if (showInfoOnHeaderData?.SHOW_PCP) {
      const headerData: IMessageTopHeaderInfo = getMessagingHeaderInfoInJSON(
        props?.pcpUserData || stateData?.pcpUserData ||'',
        showInfoOnHeaderData,
        contactData,
        userUuid,
        ehrConfig,
        selectedInboxTypeCode,
        conversation,
        isInstantChatView,
        {
          deceasedDate: stateData.contactDeceasedDate,
        }
      );
      return {
        ...headerData,
        subtitleData: JSON.stringify(headerData.subtitleData)
      };
    } else {
      const groupMembers = conversation?.groupConversation?.groupMembers || [];
      const groupMemberData = getGroupMemberList(groupMembers);
      const memberList = groupMemberData.groupMembersInfoList;
      const displayName =
        groupMemberData?.patientData?.name || memberList[0]?.name;
      let contactTypeStr, contactTypeData;
      if (conversation) {
        contactTypeStr = getPatientAgeAndGenderStr(
          conversation,
          ehrConfig,
          pcpUserData?.name || stateData?.pcpUserData?.name,
          undefined,
          {
            deceasedDate: stateData.contactDeceasedDate
          },
        );
        contactTypeData = getPatientAgeAndGenderObject(
          conversation,
          isInstantChatView,
          pcpUserData?.name || stateData?.pcpUserData?.name
        );
      }
      const choosenName =
        !isBroadCastGroup(conversation as IConversationData) &&
        showInfoOnHeaderData?.SHOW_CHOSEN_NAME &&
        contactData?.person?.chosenName?.length
          ? ' (' + contactData?.person?.chosenName + ')'
          : '';

      if (isPrivateGroup(conversation as any)) {
        if (conversation?.groupConversation?.name) {
          return {
            headerString: setHeaderTitle(conversation) + choosenName,
            subtitleData: contactTypeStr,
            memberSubtitleData:  contactTypeData,
          };
        } else {
          return {
            headerString: getAvatarFullName(conversation as any, true, '', contactData),
            subtitleData: contactTypeStr,
            memberSubtitleData:  contactTypeData,
          };
        }
      } else if (isInternalChat(conversation as any)) {
        let headerStringName = '';
        if (memberList.length === 2 && !conversation?.groupConversation?.name) {
          memberList.map((userData: any) => {
            if (userData?.uuid !== userUuid) {
              headerStringName = userData?.name;
            }
          });
        }
        return {
          headerString:
            headerStringName ? headerStringName : conversation?.groupConversation?.name ?
              conversation?.groupConversation?.name : avatarNameAndType?.name,
            subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
            } `,
            memberSubtitleData: contactTypeData,
        };
      } else if (isChannelTwillioSms(channelType)) {
        return {
          headerString:
            conversation?.groupConversation?.name &&
            conversation?.groupConversation?.name + choosenName,
          subtitleData: contactTypeStr,
        };
      } else if (isChannelEmail(selectedInboxTypeCode || '')) {
        return {
          headerString:
            (conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT) + choosenName,
          subtitleData: contactTypeStr,
        };
      } else {
        return {
          headerString:
            (conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT) + choosenName,
          subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
            } `,
        };
      }
    }
  };

  useEffect(() => {
    const buttonStatus = getButtonActionObject(conversation?.status);
    setButton(buttonStatus);
  }, [conversation?.status]);



  useEffect(() => {
    if (conversation?.assignedUser?.id)
    setStateData((prev) => {
      return {
        ...prev,
        assignUserName: conversation?.assignedUser?.name || '',
        assignedUserData: conversation?.assignedUser,
      };
    });
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
    eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent, {
      moduleCode: moduleCode,
    });
    eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
      moduleCode: moduleCode,
    });
    eventBus.removeEventListener(onConversationStatusChangedEvent);
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgAssigneeChangedListenerFn
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_CHANGED,
      onLocalConversationAssignedOrUnassignedEvent, {
        moduleCode: moduleCode,
      });

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_STATUS_CHANGED_EVENT,
      onLocalConversationStatusChangedEvent, {
        moduleCode: moduleCode,
      });

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
      onConversationStatusChangedEvent
    );

    if (!conversation?.groupConversation?.id && !showInfoIconInHeader) {
      getContactDataObject();
    }
    return () => {
      eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
      eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent, {
        moduleCode: moduleCode,
      });
      eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
        moduleCode: moduleCode,
      });
      eventBus.removeEventListener(onConversationStatusChangedEvent);
    };
  }, [conversation?.uuid]);

  const fetchPatientDeceasedDate = async () => {
    if (ehrConfig?.isAthena) {
      const deceasedDate = await getCurrentPatientDeceasedData(contactData);
      setStateData((prev) => ({...prev, contactDeceasedDate: deceasedDate}));
    } else {
      setStateData((prev) => ({...prev, contactDeceasedDate: ''}));
    }
  }

  useEffect(() => {
    // if (showInfoOnHeaderData?.SHOW_PCP) {
    //   getPCPDetails();
    // }
    fetchPatientDeceasedDate();
  }, [contactData?.uuid]);

  const getAssigneeName = async () => {
    const assigneeData = await getAssignUserData();
    if (assigneeData) {
      const assignUserName = assigneeData?.data?.conversations?.[0]?.assignedUser?.name || '';
      const assignedUser = assigneeData?.data?.conversations?.[0]?.assignedUser
      if (conversation) {
        conversation.assignedUser  ={...assignedUser}
        conversation.assigneeId = assignedUser?.id
      }
      setStateData((prev) => {
        return {
          ...prev,
          assignUserName: assignUserName,
          assignedUserData: assigneeData,
        };
      });
    }
  };

  const getContactDataObject = async () => {
    const contactData = await getContactForMessageHeader();
    if (contactData?.data?.contact) {
      setStateData((prev) => {
        return {
          ...prev,
          contactData: contactData?.data?.contact,
        };
      });
    }
  };

  const getPCPDetails = async () => {
    if (contactData?.uuid) {
      const careTeamResponse = await getCareTeam({
        variables: {
          contactId: contactData?.uuid,
        },
      });
      if (careTeamResponse?.data?.careTeams[0]?.primaryCarePhysicianUserId) {
        const careTeamPCPUsers =
          careTeamResponse?.data?.careTeams?.[0]?.userPool?.userPoolUsers?.filter(
            (user: any) =>
              user.userId ===
              careTeamResponse?.data?.careTeams[0]?.primaryCarePhysicianUserId
          ) || [];

        if (careTeamPCPUsers?.length) {
          const userData = await getUserDetailData({
            variables: {
              userIds: [careTeamPCPUsers?.[0]?.userId],
              accountId: accountId,
            },
          });
          setStateData((prev) => {
            return {
              ...prev,
              pcpUserData: userData?.data?.users?.[0],
            };
          });
        }
      }
    }
  };

  const onMsgAssigneeChangedListenerFn = useCallback(
    (data: any) => {
      data = Object.keys(data?.data || {}).length > 0
        ? messageDataKeysConverter(data.data)
        : data;
      if ((data?.conversationUuid === conversation?.uuid || data?.conversation_uuid === conversation?.uuid)) {
        const assignUserName = data?.assigneeName || data?.meta?.assignee?.name || data?.additionalData?.assigneeName || '';
        setStateData((prev) => {
          return {
            ...prev,
            assignUserName: assignUserName,
            assignedUserData: data?.contentAttributes?.assigneeToUserData,
          };
        });
      }
    },
    [conversation]
  );

  const onLocalConversationAssignedOrUnassignedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      const contentAttributesAssigneeData: IContentAttributesAssigneeData = eventData?.messageData?.contentAttributes;
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (contentAttributesAssigneeData && Object.keys(contentAttributesAssigneeData)?.length) {
          const assignUserName = contentAttributesAssigneeData?.assigneeToUserData?.name || '';
          const assignedUser = contentAttributesAssigneeData?.assigneeToUserData;
          if (conversation) {
            conversation.assignedUser = {...assignedUser}
            conversation.assigneeId = assignedUser?.id
          }
          setStateData((prev) => {
            return {
              ...prev,
              assignUserName: assignUserName,
              assignedUserData: contentAttributesAssigneeData?.assigneeToUserData,
            };
          });
        }
        if (isInstantChatView && eventData?.messageData?.conversation?.status !== conversation?.status) {
          const buttonStatus = getButtonActionObject(eventData?.messageData?.conversation?.status);
          setButton(buttonStatus);
        }
      }
    },
    []
  );

  const onConversationStatusChangedEvent = useCallback((eventData: any) => {
    if (
      eventData?.data?.conversation_uuid === conversation?.uuid ||
      eventData?.data?.conversationUuid === conversation?.uuid ||
      eventData?.conversation_uuid === conversation?.uuid
    ) {
      const buttonStatus = getButtonActionObject(
        eventData?.data?.conversation?.status
      );
      setButton(buttonStatus);
      if (
        eventData?.data?.conversation?.status === CONVERSATION_STATUS.RESOLVED
      ) {
        const unAssignedUserData = {
          id: -1,
          name: '',
          uuid: '',
          email: '',
        };
        setStateData((prev) => {
          return {
            ...prev,
            assignUserName: '',
            assignedUserData: unAssignedUserData,
          };
        });
      }
    }
  }, []);

  const onLocalConversationStatusChangedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (conversation) {
          conversation.status = eventData?.messageData?.conversation?.status as any;
        }
        if (isInstantChatView) {
          const buttonStatus = getButtonActionObject(eventData?.messageData?.conversation?.status);
          setButton(buttonStatus);
        }
      }
    },
    []
  );

  useEffect(() => {
    setStateData(prev => {
      return {
        ...prev,
        actionMessageLoading: true
      }
    })
  }, [conversation?.id])

  useEffect(() => {
    resetDimension();
  }, [props.isSideDetailVisible]);

  const handleAssignVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        assignUserListPopup: visible,
      };
    });
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const emailStyleBadge = () => {
    if (contactData.email) {
      return true;
    } else {
      return false;
    }
  };

  const handleEmail = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.MAIL, contactData);
    }
  };
  const handleSms = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactData);
    }
  };
  const getHeaderInfoElem = () => {
    const showRightArrow = !(
      isInstantChatView ||
      (isSideCarContext &&
        isInternalChat(conversation || ({} as IConversationData)))
    );
    const selectedPronoun = pronounList?.find((pronoun) => {
      return pronoun?.id === contactData?.person?.pronounId
    })
    const isShowPronounName =
      showInfoOnHeaderData?.SHOW_PRONOUN_NAME &&
      notListedPronounData?.id !== selectedPronoun?.id;
    const pronounName =
      isShowPronounName && contactData?.name ? selectedPronoun?.value : '';
    if (conversation?.groupConversation?.id) {
      return (
        <Pressable
          onPress={() => {
            if (showInfoIconInHeader) {
              if (isNavigateToOmniView) {
                handleNavigateToOmniView();
              } else {
                setStateData((prev) => {
                  return {
                    ...prev,
                    isDetailsContainerVisible:
                      !stateData.isDetailsContainerVisible,
                  };
                });
                if (props.onActionPerformed) {
                  props.onActionPerformed(
                    CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
                    {
                      isDetailsContainerVisible:
                        stateData.isDetailsContainerVisible,
                    }
                  );
                }
              }
              return;
            }
            let currentStatus = true;
            if (
              isGroupConversation(conversation || ({} as any)) &&
              isUserGroupsGroup(conversation || ({} as any))
            ) {
              currentStatus = true;
            } else {
              currentStatus = isActiveContact(contactData);
              if (!currentStatus && !props.defaultActiveStatus) {
                const message = getInActiveContactError(contactData);
                notification.error({
                  message,
                });
                return;
              }
            }

            if (conversation.groupConversation === null) {
              onActionPerformed &&
                onActionPerformed(
                  GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
                  conversation
                );
            } else if (conversation.groupConversation) {
              const patient = findPatient(
                conversation.groupConversation?.groupMembers
              );
              const contact = patient.contact;
              onActionPerformed && !isInstantChatView &&
                onActionPerformed(
                  GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
                  contact
                );
            }
          }}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'center'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              name={
                conversation?.groupConversation?.name
                  ? conversation?.groupConversation?.name
                  : avatarNameAndType?.name
                    ? avatarNameAndType?.name
                    : groupName
                      ? groupName
                      : contactData?.name || CONVERSATION_CONST.GROUP_CHAT
              }
              showRightArrow={showRightArrow}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={getMessagingHeaderInfo()?.headerString}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={contactData?.id}
              userType={
                avatarNameAndType?.userType
                  ? avatarNameAndType?.userType
                  : GROUP_MEMBER_TYPE.CONTACT
              }
              subtitleString={getMessagingHeaderInfo()?.subtitleData}
              memberSubtitleData={getMessagingHeaderInfo()?.memberSubtitleData}
              shouldShowSubtitleHighlighter={false}
              contactData={contactData}
              singleConversationData={conversation}
              getConversationTypeImgSvg={false}
              headerContainerWidth={width}
              onActionPerformed={onActionPerformed}
              deceasedDate={stateData.contactDeceasedDate}
              messagingWindowHeaderLoading={messagingWindowHeaderLoading || false}
            />
          </HStack>
        </Pressable>
      );
    } else if (contactData?.name) {
      const contactNameWithChosenName =
        contactData.name +
        (contactData?.person?.chosenName?.length
          ? ' (' + contactData?.person?.chosenName + ')'
          : '');
      let contactTypeStr;
      if (conversation) {
        contactTypeStr = showInfoOnHeaderData?.SHOW_PCP
          ? JSON.stringify(getPatientAgeAndGenderJson(
              conversation,
              ehrConfig,
              pcpUserData?.name || stateData?.pcpUserData?.name,
              isInstantChatView,
              {
                deceasedDate: stateData.contactDeceasedDate,
              }
            ))
          : getPatientAgeAndGenderStr(
              conversation,
              ehrConfig,
              pcpUserData?.name || stateData?.pcpUserData?.name,
              undefined,
              {
                deceasedDate: stateData.contactDeceasedDate,
              }
            );
      }
      return (
        <Pressable
          onPress={() => {
            if (showInfoIconInHeader) {
              setStateData((prev) => {
                return {
                  ...prev,
                  isDetailsContainerVisible:
                    !stateData.isDetailsContainerVisible,
                };
              });
              if (props.onActionPerformed) {
                if (isSideCarContext) {
                  const ehrPatientId = contactData?.patient?.patientId || '';
                  const openInEhrUrl = getViewInEHRLink(ehrPatientId, ehrURL);
                  if (openInEhrUrl) {
                    window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
                  } else {
                    redirectToNewTab(
                      `/members/patient/${contactData?.id}`
                    );
                  }
                } else {
                  props.onActionPerformed(
                    CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
                    {
                      isDetailsContainerVisible:
                        stateData.isDetailsContainerVisible,
                    }
                  );
                }
              }
              return;
            }
            !isInstantChatView && onContactClicked(contactData.id);
          }}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              isLetterAvatarShow={true}
              name={groupName ? groupName : contactData.name}
              showRightArrow={!isInstantChatView}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={contactNameWithChosenName}
              memberSubtitleData={getMessagingHeaderInfo()?.memberSubtitleData}
              onActionPerformed={onActionPerformed}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={contactData?.id}
              userType={GROUP_MEMBER_TYPE.CONTACT}
              subtitleString={
                !stateData.isNoContactFound && isShowForSmsOrEmail
                  ? substringForEmailOrSms
                  : contactTypeStr
              }
              shouldShowSubtitleHighlighter={false}
              contactData={contactData}
              singleConversationData={conversation}
              headerContainerWidth={width}
              deceasedDate={stateData.contactDeceasedDate}
              messagingWindowHeaderLoading={messagingWindowHeaderLoading || false}
            />
          </HStack>
        </Pressable>
      );
    }
    return <></>;
  };

  const isGroupMessage = () => {
    if (conversation) {
      return isGroupConversation(conversation);
    }
    return false;
  };

  const isShowEmailIcon = () => {
    let showEmail = true;
    if (
      (isGroupMessage() &&
        conversation?.groupConversation?.groupType?.code !==
        GROUP_TYPE_CODES.PRIVATE) ||
      (selectedInboxTypeCode &&
        !isWebChannelInboxType(selectedInboxTypeCode || ''))
    ) {
      showEmail = false;
    }
    return showEmail;
  };

  const onConversationAttachmentActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            conversationAttachmentDrawer: false,
          };
        });
        break;
      default:
        break;
    }
  };

  const showConversationAttachmentOption = () => {
    if (isChannelTwillioSms(channelType) || isPatientSidecarContext) {
      return false;
    }
    return true;
  };

  const isShowFinallyAssignee = () => {
    let isShowAssigneeButton = false;
    if (conversation) {
      const canShowAssigneeOnType =  canShowAssignment(conversation || {});
      const isGroupMemberPresent = isGroupMembersAvailable(conversation, userUuid);
      if (canShowAssigneeOnType && isGroupMemberPresent) {
        isShowAssigneeButton = true;
      }
    }
    return isShowAssigneeButton;
  }



  const canShowChat = () => {
    if (isPatientSidecarContext) {
      return false;
    }
    return (
      !stateData.isDetailsContainerVisible &&
      (isChannelEmail(channelType) || isChannelTwillioSms(channelType))
    );
  };

  const canShowSMS = () => {
    if (isPatientSidecarContext) {
      return false;
    }
    return (
      !stateData.isDetailsContainerVisible &&
      !isChannelTwillioSms(channelType || ({} as any))
    );
  };
  const groupMembers = conversation?.groupConversation?.groupMembers || [];
  let isLoggedInUserGroupMember = false;
  if (conversation?.groupConversation?.id) {
    isLoggedInUserGroupMember = isGroupMember(userUuid, groupMembers);
  } else if (conversation?.conversationInbox?.id) {
    isLoggedInUserGroupMember = isInboxMember(
      userUuid,
      conversation?.conversationInbox?.inboxMembers || []
    );
  }
  const isConversationChatOnly =
    isPrivateGroup(conversation as any)
      ? true
      : false;

  const getBackNavigationButton = () => {
    return (
      <Pressable onPress={props.navigationBackPressed} marginLeft={2}>
        <SideCarBackSvg />
      </Pressable>
    );
  };

  return (
    <>
      {emailSubject && (
        <View
          style={{
            padding: 8,
            backgroundColor: Colors.Custom.Gray50,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              lineHeight: 19.2,
              fontWeight: '500',
            }}
            {...testID('Subject')}
          >
            {`Subject: ${emailSubject}`}
          </Text>
        </View>
      )}
      <HStack
        ref={headerContainerRef}
        style={[
          styles.topBar,
          {
            position: 'relative',
            zIndex: 100000,
            justifyContent: props.showNavigationBack
              ? undefined
              : 'space-between',
          },
        ]}
      >
        {props.showNavigationBack && getBackNavigationButton()}
        {getHeaderInfoElem()}
        {showInfoIconInHeader && false && (
          <HStack
            marginLeft={3}
            alignContent={'center'}
            justifyContent={'center'}
            alignItems={'center'}
            justifyItems={'center'}
          >
            <View
              mr={2}
              borderLeftWidth={1}
              height={8}
              borderLeftColor={Colors.Custom.Gray200}
            ></View>
            <Pressable
              onPress={() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    isDetailsContainerVisible:
                      !stateData.isDetailsContainerVisible,
                  };
                });
                if (props.onActionPerformed) {
                  props.onActionPerformed(
                    CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
                    {
                      isDetailsContainerVisible:
                        stateData.isDetailsContainerVisible,
                    }
                  );
                }
              }}
            >
              <InfoSvg
                customDimension="18px"
                customColor={Colors.Custom.Gray500}
              ></InfoSvg>
            </Pressable>
          </HStack>
        )}
        <HStack
          style={{
            height: 65,
            right: 0,
            position: 'absolute',
            backgroundColor:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 104.46%)',
            paddingLeft: 10,
          }}
        >
          {isDisplayActions && (
            <HStack
              flex={1}
              justifyContent={'flex-end'}
              alignItems={'center'}
              space="4"
              pr="4"
            >
              {isShowEmailIcon() && false && (
                <Pressable onPress={handleEmail}>
                  <EmailSvg isEnabled={emailStyleBadge()} />
                </Pressable>
              )}
              {isShowEmailIcon() && false && (
                <Pressable
                  onPress={() => {
                    if (props.onActionPerformed) {
                      props.onActionPerformed(PERSON_ACTION_CODES.CALL, {});
                    }
                  }}
                >
                  <VideoCallSvg isEnabled={true} />
                </Pressable>
              )}
              {isShowEmailIcon() && false && (
                <Button
                  // colorScheme={'primary'}
                  onPress={handleSms}
                  // style={[styles.actionBtns]}
                  backgroundColor={'transparent'}
                  style={[styles.btnStyle]}
                >
                  <SmsSvg isEnabled={false} />
                </Button>
              )}
              {isSelectChatOn && props.copyMsgView}
              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                props?.selectChatView}
              {isSelectChatOn && props?.createNoteView}
              {isSelectChatOn && props.assignMessageTypeView}
              {isSelectChatOn && props?.createTaskView}
              {isSelectChatOn && props?.cancelView}
              {showConversationAttachmentOption() && false && (
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        conversationAttachmentDrawer: true,
                      };
                    });
                  }}
                  style={[
                    styles.btnStyle,
                    {justifyContent: 'center', alignItems: 'center'},
                  ]}
                >
                  <VStack alignItems={'center'}>
                    <FileIconSvg
                      customStrokeColor={Colors.Custom.Gray500}
                      width={16}
                      height={16}
                    />
                    <Text
                      fontSize={'12px'}
                      fontWeight={500}
                      color={Colors.Custom.Gray500}
                      marginLeft={1}
                    >
                      {'Files'}
                    </Text>
                  </VStack>
                </Pressable>
              )}
              {/* show chat icon */}
              {canShowChat() && false && (
                <Pressable
                  onPress={() => {
                    const actionCode =
                      CONVERSATION_ACTION_CODES.START_GROUP_CHAT;
                    const eventBus = EventBus.getEventBusInstance();
                    eventBus.broadcastEvent(
                      COMPONENT_SUPPORTED_EVENT.CONVERSATION_MORE_ACTIONS,
                      {
                        actionCode,
                      }
                    );
                  }}
                  style={[
                    styles.btnStyle,
                    {justifyContent: 'center', alignItems: 'center'},
                  ]}
                >
                  <VStack alignItems={'center'}>
                    <View height={'18px'} width={'18px'}>
                      <ChatActionSvg
                        customStrokeColor={Colors.Custom.Gray500}
                      />
                    </View>
                    <Text
                      fontSize={'12px'}
                      fontWeight={500}
                      color={Colors.Custom.Gray500}
                      marginLeft={1}
                    >
                      {'Chat'}
                    </Text>
                  </VStack>
                </Pressable>
              )}
              {/* show sms icon */}
              {canShowSMS() &&
                !isInternalChat(conversation || ({} as any)) &&
                false && (
                  <Pressable
                    onPress={() => {
                      const actionCode = CONVERSATION_ACTION_CODES.START_SMS;
                      const eventBus = EventBus.getEventBusInstance();
                      eventBus.broadcastEvent(
                        COMPONENT_SUPPORTED_EVENT.CONVERSATION_MORE_ACTIONS,
                        {
                          actionCode,
                          actionData: contactData,
                        }
                      );
                    }}
                    style={[
                      styles.btnStyle,
                      {justifyContent: 'center', alignItems: 'center'},
                    ]}
                  >
                    <VStack alignItems={'center'}>
                      <View height={'18px'} width={'18px'}>
                        <SmsActionSvg
                          customStrokeColor={Colors.Custom.Gray500}
                        />
                      </View>
                      <Text
                        fontSize={'12px'}
                        fontWeight={500}
                        color={Colors.Custom.Gray500}
                        marginLeft={1}
                      >
                        {'Sms'}
                      </Text>
                    </VStack>
                  </Pressable>
                )}

              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                canShowArchive(selectedInboxTypeCode ?? '', conversation) &&
                (stateData.loadingArchive ? (
                  <VStack
                    alignItems={'center'}
                    {...testID(TestIdentifiers.lazyLoading)}
                  >
                    <Spinner size="sm" />
                  </VStack>
                ) : (
                  <HStack>
                    <Tooltip title={button.title}>
                      <Pressable
                        onPress={() => {
                          handleResolve(button.nextStatusCode);
                        }}
                        {...testID('ArchivedBtnClick')}
                      >
                        <VStack alignItems={'center'}>
                          {button.currentStatusCode === 1 ? (
                            <UnArchiveActionSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <ArchiveActionSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              width={20}
                              height={20}
                            />
                          )}
                        </VStack>
                      </Pressable>
                    </Tooltip>
                  </HStack>
                ))}

              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                isShowFinallyAssignee() && (
                  <>
                    <HStack>
                      <Popover
                        overlayInnerStyle={{padding: 0, borderRadius: 12}}
                        overlayStyle={{padding: 0}}
                        overlay={{padding: 0}}
                        style={{padding: 0, borderRadius: 12}}
                        placement={'bottomLeft'}
                        trigger="click"
                        visible={stateData.assignUserListPopup}
                        onVisibleChange={handleAssignVisibleChange}
                        content={
                          <View width={300} {...testID('AssignPopover')}>
                            <ConversationActionView
                              conversation={conversation}
                              selectedInboxTypeCode={selectedInboxTypeCode}
                              style={{marginBottom: 10}}
                              assignedUserData={stateData.assignedUserData}
                              onSelectUser={(
                                user: any,
                                conversationStatus?: number
                              ) => {
                                setStateData((prev) => {
                                  return {
                                    ...prev,
                                    assignUserListPopup: false,
                                  };
                                });
                                // getAssigneeName();
                                // setStateData((prev) => {
                                //   return {
                                //     ...prev,
                                //     assignUserListPopup: false,
                                //     assignUserName: user?.name || '',
                                //   };
                                // });
                                // if (isInstantChatView && conversationStatus !== conversation?.status) {
                                //   const buttonStatus = getButtonActionObject(conversationStatus);
                                //   setButton(buttonStatus);
                                // }
                              }}
                              onActionPerformed={onActionPerformed}
                            />
                          </View>
                        }
                      >
                        <Tooltip
                          title={
                            stateData.assignUserName?.length
                              ? stateData.assignUserName
                              : 'Unassigned'
                          }
                        >
                          <Pressable
                            onPress={() => {
                              setStateData((prev) => {
                                return {
                                  ...prev,
                                  assignUserListPopup: true,
                                };
                              });
                            }}
                            {...testID('AssignBtnClick')}
                          >
                            <VStack alignItems={'center'}>
                              <AssignActionSvg
                                customStrokeColor={
                                  stateData.assignUserName &&
                                  stateData.assignUserName.length
                                    ? '#12B76A'
                                    : Colors.FoldPixel.GRAY300
                                }
                                width={20}
                                height={20}
                              />
                            </VStack>
                          </Pressable>
                        </Tooltip>
                      </Popover>
                    </HStack>
                  </>
                )}
              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                !isInstantChatView &&
                !isEmailConversation && (
                  <Tooltip title={'Search'}>
                    <Pressable
                      onPress={() => {
                        if (props.onActionPerformed) {
                          props.onActionPerformed(
                            CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
                            {
                              isSeachMsgContainerVisible:
                                stateData.isSeachMsgContainerVisible,
                            }
                          );
                          setStateData((prev) => {
                            return {
                              ...prev,
                              isSeachMsgContainerVisible:
                                !stateData.isSeachMsgContainerVisible,
                            };
                          });
                        }
                      }}
                    >
                      <VStack alignItems={'center'}>
                        <SearchActionSvg
                          customStrokeColor={Colors.FoldPixel.GRAY300}
                          width={20}
                          height={20}
                        />
                      </VStack>
                    </Pressable>
                  </Tooltip>
                )}
              {isGroupMessage() &&
                false &&
                !stateData.isDetailsContainerVisible && (
                  <Pressable
                    onPress={() => {
                      if (
                        onActionPerformed &&
                        typeof onActionPerformed === 'function'
                      ) {
                        onActionPerformed(
                          GROUP_ACTION_CODES.GROUP_HEADER_CLICKED,
                          {}
                        );
                      }
                    }}
                    style={[
                      styles.btnStyle,
                      {justifyContent: 'center', alignItems: 'center'},
                    ]}
                  >
                    <VStack alignItems={'center'}>
                      <AntIcon
                        name="addusergroup"
                        color={Colors.Custom.foldIconColor}
                        size={18}
                      />
                      <Text
                        fontSize={'12px'}
                        fontWeight={500}
                        color={Colors.Custom.Gray500}
                        marginLeft={1}
                      >
                        {'Group'}
                      </Text>
                    </VStack>
                  </Pressable>
                )}
              {!isSelectChatOn && (
                <Popover
                  overlayInnerStyle={{padding: 0, borderRadius: 12}}
                  overlayStyle={{padding: 0}}
                  overlay={{padding: 0}}
                  style={{padding: 0, borderRadius: 12}}
                  placement={'bottomLeft'}
                  trigger="click"
                  visible={stateData.moreActionPopup}
                  onVisibleChange={handleMoreActionVisibleChange}
                  content={
                    <View width={210}>
                      <CreateAllConversationPopup
                        contactData={contactData}
                        isDetailsContainerVisible={
                          stateData.isDetailsContainerVisible
                        }
                        selectedTabCode={CONVERSATION_TAB_CODES.PATIENTS}
                        openCount={0}
                        conversation={conversation}
                        isCompactView={
                          isDetailsContainerVisible ||
                          isSeachMsgContainerVisible
                        }
                        selectedInboxTypeCode={selectedInboxTypeCode}
                        getAssigneeName={getAssigneeName}
                        assignUserName={stateData.assignUserName}
                        isShowFinallyAssignee={isShowFinallyAssignee()}
                        isSingleContactPresentInSMSOrEmailInbox={
                          isShowForSmsOrEmail && stateData?.isNoContactFound
                        }
                        isInstantChatView={isInstantChatView}
                        assignedUserData={stateData.assignedUserData}
                        onCreateGroupPopupActionPerformed={(
                          actionCode: string,
                          actionData?: any
                        ) => {
                          if (actionCode === CONVERSATION_ACTION_CODES.COPY) {
                            props?.onActionPerformed?.(
                              CONVERSATION_ACTION_CODES.COPY,
                              actionData
                            );
                          }
                          if (
                            actionCode ===
                            GROUP_ACTION_CODES.GROUP_HEADER_CLICKED
                          ) {
                            if (
                              onActionPerformed &&
                              typeof onActionPerformed === 'function'
                            ) {
                              onActionPerformed(
                                GROUP_ACTION_CODES.GROUP_HEADER_CLICKED,
                                {}
                              );
                            }
                          } else if (
                            actionCode === CONVERSATION_ACTION_CODES.FILES
                          ) {
                            setStateData((prev) => {
                              return {
                                ...prev,
                                conversationAttachmentDrawer: true,
                              };
                            });
                          } else if (HEADER_ACTION_ITEM?.includes(actionCode)) {
                            onActionPerformed?.(actionCode, actionData);
                          } else if (
                            actionCode === CONVERSATION_ACTION_CODES.SELECT_CHAT
                          ) {
                            if (props?.handleSelectChat) {
                              props?.handleSelectChat();
                            }
                          } else if (
                            actionCode ===
                            CONVERSATION_ACTION_CODES.SEARCH_MESSAGE
                          ) {
                            if (props.onActionPerformed) {
                              props.onActionPerformed(
                                CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
                                {
                                  isSeachMsgContainerVisible:
                                    !stateData.isSeachMsgContainerVisible,
                                }
                              );
                              setStateData((prev) => {
                                return {
                                  ...prev,
                                  isSeachMsgContainerVisible:
                                    !stateData.isSeachMsgContainerVisible,
                                };
                              });
                            }
                          } else if (
                            actionCode === CONVERSATION_ACTION_CODES.ARCHIVE ||
                            actionCode === CONVERSATION_ACTION_CODES.UNARCHIVE
                          ) {
                            handleResolve(button.nextStatusCode);
                          } else {
                            const eventBus = EventBus.getEventBusInstance();
                            const eventCode =
                              getEventCodeFromParentCode(parentCode);
                            eventBus.broadcastEvent(eventCode, {
                              actionCode,
                              actionData,
                            });
                          }
                          setStateData((prev) => {
                            return {
                              ...prev,
                              moreActionPopup: false,
                            };
                          });
                        }}
                      />
                    </View>
                  }
                >
                  <Pressable
                    onPress={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          moreActionPopup: true,
                        };
                      });
                    }}
                    {...testID('MoreBtnClick')}
                  >
                    <Tooltip title={'More'}>
                      <VStack alignItems={'center'}>
                        <MenuHorizontalIcon width={24} height={24} />
                      </VStack>
                    </Tooltip>
                  </Pressable>
                </Popover>
              )}
            </HStack>
          )}
        </HStack>
      </HStack>

      <View
        width={'100%'}
        style={{
          backgroundColor: Colors.Custom.Gray200,
          height: 1,
          //margin: 'auto',
        }}
      ></View>
      {stateData?.conversationAttachmentDrawer && (
        <ConversationAttachments
          conversationId={conversation?.id || -1}
          conversationDisplayId={conversation?.displayId || -1}
          isDrawerVisible={stateData?.conversationAttachmentDrawer}
          onConversationAttachmentActionPerformed={
            onConversationAttachmentActionPerformed
          }
          openInDrawer={true}
        />
      )}
    </>
  );
};

export const MessagingWindowHeader = withMiniContactViewHOC(MessagingWindowHeaderComponent)

