import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  requiredText: {
    color: Colors.Custom.ErrorColor,
    marginLeft: 24,
    marginTop: 4,
  },
  pageContainerStyles: {
    marginBottom: 24,
    marginHorizontal: 8,
    flex: 1,
    paddingBottom: 40,
  },
  createBtn: {
    marginRight: 8,
    marginLeft: 8,
    backgroundColor: Colors.Custom.PrimaryBtnColor,
    borderWidth: 0,
  },
  templateCard: {
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.26,
    elevation: 8,
    backgroundColor: 'white',
    borderRadius: 12,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formContainer: {
    flex: 1,
    marginBottom: 12,
  },
  labelContainer: {
    marginBottom: 4,
    marginTop: 4,
  },
  defaultOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mergeTagPopoverView: {
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  readonlyLineContainer:{marginTop: 10},
  readPillContainer: {
    backgroundColor: Colors.Custom.Gray300,
    padding: 2.5,
    paddingHorizontal: 5,
    marginRight: 5,
    borderRadius: 5,
    marginTop:10
  },
  readonlyContainer: {paddingHorizontal: 24, paddingBottom: 24},
});
