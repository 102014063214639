import { CheckIcon, HStack } from "native-base";
import { ModalActionInput } from "./common/ModalActionCommonComponent/ModalActionInput";
import { ModalActionDatePicker } from "./common/ModalActionCommonComponent/ModalActionDatePicker";
import { DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, PATIENT_QUERRY_DATE_FROMATED } from "../constants";
import { getMomentObjectWithDateStringAndFormat } from "../utils/DateUtils";
import { ModalActionSelect } from "./common/ModalActionCommonComponent/ModalActionSelect";
import { codeToDisplayMapAthenaOptions, codeToDisplayMapElationOptions } from "../utils/commonUtils";
import React from "react";
import { CityAutoComplete } from "./common/City";
import { StateAutoComplete } from "./common/State";
import { ZipCodeAutoComplete } from "./common/ZipCodeComponent";
import { IZipCodeData } from "./common/ZipCodeComponent/interfaces";
import { getEhrConfig } from "../utils/capabilityUtils";
import { IInsuranceComponentState, IInsuranceMandatoryFieldAccordingToEHR, IInsuranceState } from "./InsuranceInterfaces";


interface IInsuranceViewProps {
    setInsuranceDataForRequestBody?: any;
    setSecInsuranceDataForRequestBody?: any;
    setTerInsuranceDataForRequestBody?: any;
    setSecondaryInsuranceDataForRequestBody?: any;
    setTertiaryInsuranceDataForRequestBody?: any;
    addPatientView?: boolean;
    contactId?: string | number;
    patientId?: string;
    contactUuid?: string;
    errors?: IInsuranceMandatoryFieldAccordingToEHR;
    insuranceData?: IInsuranceState;
    secondaryInsuranceData?: IInsuranceState;
    tertiaryInsuranceData?: IInsuranceState;
    insuranceNumber: string;
    componentState: IInsuranceComponentState;
    setComponentState: (stateUpdater: (prevState: IInsuranceComponentState) => IInsuranceComponentState) => void;
    setInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState) => void;
    setSecondaryInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState) => void;
    setTertiaryInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState) => void;
    setIsInsuranceCarrierNameFreeText: (value: boolean) => void;
    setIsPlanNameFreeText: (value: boolean) => void;
}
const InsuranceAdditionaldetailsView = (props: IInsuranceViewProps) => {
    const insuranceFieldData = props?.insuranceData || props?.secondaryInsuranceData || props?.tertiaryInsuranceData;
    const ehrConfig = getEhrConfig();
    const isAthena = ehrConfig.isAthena; 
    const currentDateMoment = getMomentObjectWithDateStringAndFormat(
        new Date().toISOString(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
    );
    const setInsuranceFieldData = (
        props?.setInsuranceData ||
        props?.setSecondaryInsuranceData ||
        props?.setTertiaryInsuranceData ||
        (() => { })
    ); 
    const setInsuranceNewPatientFieldData = (
        props?.setInsuranceDataForRequestBody ||
        props?.setSecInsuranceDataForRequestBody ||
        props?.setTerInsuranceDataForRequestBody ||
        (() => { })
    );
    return (
        <>
            <HStack space={10} mt={4}>
                <ModalActionInput
                    errors={props?.errors?.firstName }
                    errorText={props?.errors?.firstName}
                    fieldIs={'input'}
                    label={'firstName'}
                    placeholder={'Enter First Name'}
                    isRequired={true}
                    changeBackground={true}
                    value={insuranceFieldData?.firstName}
                    onChangeText={(value: any) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            firstName: value,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            firstName: value,
                        });
                    }}
                    extraStyle={{
                        flex: 1.0,
                    }}
                    notAllowEmoji={true}
                />
                <ModalActionInput
                    errors={props?.errors?.lastName }
                    errorText={props?.errors?.lastName}
                    fieldIs={'input'}
                    label={'lastName'}
                    placeholder={'Enter Last Name'}
                    isRequired={true}
                    value={insuranceFieldData?.lastName}
                    onChangeText={(value: any) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            lastName: value,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            lastName: value,
                        });
                    }}
                    extraStyle={{
                        flex: 1.0,
                    }}
                    notAllowEmoji={true}
                />
            </HStack>
            <HStack space={10} mt={4}>
            <ModalActionDatePicker
                label={'dob'}
                leftMargin={'0'}
                format={
                  ehrConfig.isElation
                    ? DATE_FORMATS.DISPLAY_DATE_FORMAT
                    : DISPLAY_SLASH_DATE_FORMAT
                }
                onChange={(value: any, dateString) => {
                  setInsuranceFieldData((prev) => ({
                    ...prev,
                    dob: dateString,
                  }));
                 setInsuranceNewPatientFieldData?.({
                      ...insuranceFieldData,
                      dob: dateString,
                    });
                }}
                defaultValue={currentDateMoment}
                value={
                    insuranceFieldData?.dob
                    ? ehrConfig.isElation
                      ? getMomentObjectWithDateStringAndFormat(
                        insuranceFieldData?.dob,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                      : getMomentObjectWithDateStringAndFormat(
                        insuranceFieldData?.dob,
                          DISPLAY_SLASH_DATE_FORMAT
                        )
                    : null
                }
                extraStyle={{flex: 1}}
              />
                <ModalActionSelect
                isRequired={isAthena}
                errors={isAthena && props?.errors?.sexAtBirth}
                errorText={isAthena && props?.errors?.sexAtBirth}
                label={'sexAtBirth'}
                selectedValue={insuranceFieldData?.sexAtBirth || ''}
                defaultValue={insuranceFieldData?.sexAtBirth}
                placeholder={`Select Sex At Birth`}
                endIcon={<CheckIcon size="5" />}
                onValueChange={(itemValue: any) => {
                  setInsuranceFieldData((prev) => ({
                    ...prev,
                    sexAtBirth: itemValue,
                  }));
                 setInsuranceNewPatientFieldData?.({
                      ...insuranceFieldData,
                      sexAtBirth: itemValue,
                    });
                }}
                data={codeToDisplayMapAthenaOptions}
                selectItemProps={{
                  key: 'code',
                  label: 'display',
                  value: 'display',
                }}
                customStyle={{
                  flex: 0.5,
                }}
              />
            </HStack>
            <HStack space={10} mt={4}>
                <ModalActionInput
                    label={'address'}
                    placeholder={'Enter Address'}
                    changeBackground={true}
                    value={insuranceFieldData?.address}
                    onChangeText={(value: any) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            address: value,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            address: value,
                        });
                    }}
                    extraStyle={{
                        flex: 1.0,
                        marginTop: 2,
                        Height: '120px',
                    }}
                    notAllowEmoji={true}
                />
            </HStack>           
            <HStack space={10} mt={4}>              
                <ZipCodeAutoComplete
                    isRequired={false}
                    changeBackground={true}
                    flex={1}
                    name={insuranceFieldData?.zipcode}
                    onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
                        setInsuranceFieldData((prev: any) => {
                            return {
                                ...prev,
                                zipCodeId:
                                    selectedZipCode?.id || selectedZipCode?.zipCodeId,
                                stateId: selectedZipCode?.stateId,
                                state:
                                    selectedZipCode?.zipcodeState?.name ||
                                    selectedZipCode?.stateName,
                                cityId: selectedZipCode?.cityId,
                                city:
                                    selectedZipCode?.zipcodeCity?.name ||
                                    selectedZipCode?.cityName,
                                abbr: selectedZipCode?.zipcodeState?.abbr || '',
                                zipCode:
                                    selectedZipCode?.code || selectedZipCode?.zipCode,
                            };
                        });
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            zipCodeId:
                                selectedZipCode?.id || selectedZipCode?.zipCodeId,
                            stateId: selectedZipCode?.stateId,
                            state:
                                selectedZipCode?.zipcodeState?.name ||
                                selectedZipCode?.stateName,
                            cityId: selectedZipCode?.cityId,
                            city:
                                selectedZipCode?.zipcodeCity?.name ||
                                selectedZipCode?.cityName,
                            abbr: selectedZipCode?.zipcodeState?.abbr || '',
                            zipCode:
                                selectedZipCode?.code || selectedZipCode?.zipCode,
                        });
                    }}
                    onValueChange={(value: string) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            zipcode: value,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            zipcode: value,
                        });
                    }}
                />
                 <ModalActionInput
                    fieldIs={'input'}
                    label={'country'}
                    placeholder={'Enter Country'}
                    changeBackground={true}
                    value={insuranceFieldData?.country}
                    onChangeText={(value: any) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            country: value,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            country: value,
                        });
                    }}
                    extraStyle={{
                        flex: 1.0,
                    }}
                    notAllowEmoji={true}
                />
            </HStack>
            <HStack space={10} mt={4}>
            <StateAutoComplete
                    flex={1}
                    name={insuranceFieldData?.state}
                    onAutoCompleteChange={(selectedId) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            stateId: selectedId,
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            stateId: selectedId,
                        });
                    }}
                />
                <CityAutoComplete
                    flex={1}
                    name={insuranceFieldData?.city}
                    onAutoCompleteChange={(selectedId, cityName) => {
                        setInsuranceFieldData((prev) => ({
                            ...prev,
                            cityId: selectedId,
                            city: cityName || '',
                        }));
                        setInsuranceNewPatientFieldData?.({
                            ...insuranceFieldData,
                            cityId: selectedId,
                            city: cityName || '',
                        });
                    }}
                />           
            </HStack>
        </>
    )
};
export default InsuranceAdditionaldetailsView;