import React, {useEffect, useState} from 'react';
import {IRecordingLink} from '../AppointmentBookingIntefaces';
import {
  Box,
  FlatList,
  HStack,
  Pressable,
  Spacer,
  Spinner,
  Text,
  VStack,
  View,
  Skeleton,
} from 'native-base';
import {Colors} from '../../../../../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import {getMomentObj} from '../../../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../../../constants';
import {Tooltip} from 'antd';
import {AppointmentQueries} from '../../../../../../../services';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {useIntl} from 'react-intl';

interface IRecordingListViewProps {
  appointmentId: string;
}

const RecordingListView = (props: IRecordingListViewProps) => {
  const [recordingLinks, setRecordingLinks] = useState<{
    links: IRecordingLink[];
    expanded: boolean;
    error: boolean;
    retryLoading: boolean;
    loading: boolean;
  }>({
    links: [],
    expanded: false,
    retryLoading: false,
    error: false,
    loading: false,
  });

  const intl = useIntl();
  const [getAppointmentRecordingLinks] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENT_RECORDING_LINK,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setRecordingLinks({
          links: data?.getAppointmentRecordingLink.data,
          expanded: false,
          error: false,
          retryLoading: false,
          loading: false,
        });
      },
      onError: (err) => {
        setRecordingLinks({
          links: [],
          expanded: false,
          error: true,
          retryLoading: false,
          loading: false,
        });
      },
    }
  );

  const fetchRecordings = async (id: string) => {
    await getAppointmentRecordingLinks({
      variables: {
        params: {
          appointmentId: id,
        },
      },
    });
  };

  useEffect(() => {
    if (!props?.appointmentId) {
      return;
    }
    setRecordingLinks((prev) => ({...prev, loading: true}));
    fetchRecordings(props?.appointmentId);
  }, [props?.appointmentId]);

  const renderItem = ({item, index}: {item: IRecordingLink; index: number}) => {
    return (
      <HStack
        key={item?.id}
        borderWidth={1}
        borderColor={Colors.Custom.Gray200}
        rounded={'sm'}
        mb={2}
        py={1}
        px={2}
        alignItems={'center'}
      >
        <Box p={2} backgroundColor={Colors.Custom.Gray100} rounded={'full'}>
          <Feather name="play-circle" color={Colors.Custom.Gray500} size={18} />
        </Box>
        <VStack ml={2}>
          <Text>Recording {index + 1}</Text>
          <Text color={Colors.Custom.Gray500}>
            {/* item?.recordingInfo?.recording_end || item.createdOn => item.createdOn is used handle old appointments */}
            {getMomentObj(
              item?.recordingInfo?.recording_end || item.createdOn
            ).format(DATE_FORMATS.SIGNED_NOTE_DATE)}
          </Text>
        </VStack>
        <Spacer />
        <Tooltip title="Download">
          <Pressable
            onPress={() => {
              window.open(item?.link, '_blank');
            }}
          >
            <Feather name="download" color={Colors.Custom.Gray500} size={18} />
          </Pressable>
        </Tooltip>
      </HStack>
    );
  };

  if (recordingLinks.error) {
    return (
      <HStack alignItems={'center'}>
        <Text
          fontSize={14}
          color={Colors.Custom.Gray400}
        >{`${intl.formatMessage({
          id: 'recordingsError',
        })} • `}</Text>
        {recordingLinks.retryLoading ? (
          <Spinner size={'sm'} />
        ) : (
          <Text
            fontSize={14}
            color={Colors.Custom.mainPrimaryPurple}
            onPress={() => {
              setRecordingLinks((prev) => ({...prev, retryLoading: true}));
              fetchRecordings(props?.appointmentId);
            }}
          >
            {intl.formatMessage({id: 'Retry'})}
          </Text>
        )}
      </HStack>
    );
  }

  const [first, ...rest] = recordingLinks?.links;

  if (recordingLinks.loading) {
    return <Skeleton.Text lines={2} rounded={'sm'}/>;
  }

  if (!recordingLinks.loading && recordingLinks.links.length === 0) {
    return <></>;
  }

  return (
    <View pt={2}>
      {!!first?.id && renderItem({item: first, index: 0})}
      {recordingLinks.links.length > 1 ? (
        <VStack>
          {recordingLinks.expanded && (
            <>
              {recordingLinks.links.map((item, index) =>
                index === 0 ? <></> : renderItem({item, index})
              )}
            </>
          )}
          <Text
            onPress={() =>
              setRecordingLinks((prev) => ({...prev, expanded: !prev.expanded}))
            }
            color={Colors.Custom.mainPrimaryPurple}
          >
            {recordingLinks?.expanded
              ? `Show less`
              : `Show ${rest?.length} more`}
          </Text>
        </VStack>
      ) : (
        <></>
      )}
    </View>
  );
};

export default RecordingListView;
