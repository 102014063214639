
export const COMPONENTS_TO_MERGE = [
  'barriers',
  'goals',
  'medications',
  'symptomMonitoring',
  'medicationManagement',
  'diet',
  'exercise',
  'habits',
  'homeMonitoring',
  'labMonitoring',
  'healthMaintenance',
];

export const CONTAINER_COMPONENTS = [
  'symptomMonitoring',
  'medicationManagement',
  'diet',
  'exercise',
  'habits',
  'homeMonitoring',
  'labMonitoring',
  'healthMaintenance',
  'panel',
]

export const TASK_RESOURCE_CODE_FOR_CARE_PLAN = 'CarePlan';

export enum CarePlanViewType {
  preview = 'PREVIEW',
  edit = 'EDIT',
}
