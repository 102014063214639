import React from 'react';

import {Text} from 'native-base';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { VitalGraphData } from '../interface';
import { VictoryPie } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/VictoryComponent';
import { View } from 'react-native';
import { isWeb } from '../../../../../../../utils/platformCheckUtils';
import { Colors } from '../../../../../../../styles';

interface IPieChartViewProps {
  graphData: VitalGraphData;
}

export const PieChartView = (props: IPieChartViewProps) => {
  const getColorScale = () => {
    return props.graphData?.graphData?.map(
      (graph) => graph?.metaData?.pieColor || '',
    );
  };

  return (
    <Stack direction="row" style={{alignItems: 'center'}}>
      <Stack
        direction='row'
        style={{
          flex: 0.6,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <View style={{height: isWeb() ? 200 : undefined, width: isWeb() ? 200 : undefined, margin: 16, alignSelf:'flex-end'}}>
          <VictoryPie
            padding={0}
            innerRadius={({datum}) => isWeb() ? 100 : 40}
            data={props.graphData?.graphData}
            width={!isWeb() ? 160 : undefined}
            height={!isWeb() ? 160 : undefined}
            colorScale={getColorScale()}
            labelComponent={<></>}
          />
        </View>
      </Stack>

      <Stack direction="column" style={{flex: 0.4}} space={8}>
        {props.graphData?.graphData?.map((graphData) => {
          return (
            <Stack direction="row" style={{alignItems: 'center'}} key={`pie_chart_info_view_${graphData.metaData?.titleText}`}>
              <View
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: graphData?.metaData?.pieColor,
                }}
              />
              <Stack direction="column" style={{marginLeft: 8}}>
                <Text
                  fontSize={12}
                  fontWeight={'400'}
                  color={Colors?.Custom.Gray400}>
                  {graphData.metaData?.titleText}
                </Text>
                <Text
                  fontSize={14}
                  color={Colors?.Custom.Gray500}>
                  {graphData.metaData?.subTitleText}
                </Text>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
