import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Pressable,
  Spacer,
  Spinner,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { Colors } from '../../styles';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import AttachmentButton from '../common/CareDashboard/CareDashboardKanBan/AttachmentButton/AttachmentButton';
import Card from './Card.native';
import {IAttachment} from './CardInterfaces';
import {EntityType} from './TaskEnum';
import { ITask } from '../common/CareDashboard/CareDashboardInterfaces';
import TaskQueries from '../../services/Task/TaskQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { BottomViewAction, getPriorityIcon, getTaskTitle, isTaskWithType, renderResourceMapCountAndDeleteField } from './TaskCardHelper';
import { getCurrentUserRole, getUserUUID } from '../../utils/commonUtils';
import { IUserRoleCode } from '../../Interfaces';
import { MLOV_CATEGORY, TASK_STATUS_CODES } from '../../constants/MlovConst';
import { BUTTON_TYPE, DATE_FORMATS } from '../../constants/StringConst';
import { getAssignedByType, isAllowMarkAsComplete, isAllowToEditTask } from '../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import SubtasksSvg from '../common/Svg/SubtasksSvg';
import { LabelDataListView } from '../common/CareDashboard/CareDashboardWidget/LabelDataListView';
import { getCompletedTaskStatusId } from '../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { getMlovListFromCategory } from '../../utils/mlovUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import { Modal, notification, Tooltip, Checkbox} from 'antd';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { isWeb } from '../../utils/platformCheckUtils';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { EventBus } from '../../utils/EventBus';
import { EVENT_NAMES } from '../../constants';
import { getDocumentRefByReferenceId } from '../../services/CommonService/OrderService';
import { FHIR_RESOURCE } from '../../constants/FhirConstant';
import { getEhrConfig, getResourceAbilities } from '../../utils/capabilityUtils';
import { DocStatus, NoteEntry } from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { SOURCE_MAP } from '../common/AddTask/AddTaskUtils';
import { isAllowToDeleteTask } from './TaskHelper';

const TaskCard = (props: {
  task: ITask,
  title: string;
  disable: boolean;
  isChecked: boolean;
  attachments: IAttachment[];
  tag: EntityType;
  description?: JSX.Element;
  bottomView?: JSX.Element;
  hideCheckBox?: boolean;
  loading?: boolean;
  assigneeName?: string;
  data: any;
  hideDetails?: boolean;
  isPatientTask?: boolean;
  onPress?: () => void;
  onAssigneeClick?: () => void;
  onCheckBoxChange: (isSelected: boolean) => void;
  onAttachmentClick: (attachment: IAttachment) => void;
  onCheckBoxSelect?: (isSelected: boolean) => void;
  onDeleteTaskHandler?: (
    task: ITask,
    action: BottomViewAction,
    data: any
  ) => void;
  hideDeleteAction?: boolean,
  hideAssigneeNavigation?: boolean;
  handleDeleteOnCallback?: boolean;
  showDeleteField?: boolean
}) => {
  const { task } = props;
  const intl = useIntl();
  const toast = useToast();
  const {onCheckBoxChange, onAttachmentClick} = props;
  const mlovData = useContext(CommonDataContext);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showLoaderOnCheckbox, setShowLoaderOnCheckbox] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const loginUserId = getUserUUID();
  const currentUserRoles: IUserRoleCode[] = getCurrentUserRole();
  const assignedBy = getAssignedByType(task, intl);
  const isTaskMissed = props?.task?.status?.code === TASK_STATUS_CODES.MISSED;
  const canMarkAsComplete = isAllowMarkAsComplete(task);
  const canEditTask = isAllowToEditTask(task);
  const taskStatusMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  // ehrConfig and visitNote configuration use for get visit note data
  const resourceAbilities = getResourceAbilities(FHIR_RESOURCE.DOCUMENT_REFERENCE);
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const ehrConfig = getEhrConfig();

  const onDeleteTaskConfirmation = () => {
    setDeleteLoading(true);
    updateTask({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        params: {
          id: props?.data?.id,
          data:{
            isDeleted:true
          }
        },
      },
      onCompleted: (data: any) => {
        showToast(
          toast,
          props?.data?.parentId ? intl.formatMessage({
            id: 'subtaskDeletedSuccess',
          }) : intl.formatMessage({
            id: 'taskDeletedMsg',
          }),
          ToastType.success,
          2000
        );
        setDeleteLoading(false);
        setDeleteConfirmation(false);
        if(isWeb()){
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK,{});
        }
        if (props?.onDeleteTaskHandler && typeof props?.onDeleteTaskHandler == 'function') {
          props?.onDeleteTaskHandler(props?.data, BottomViewAction.deleteTask, undefined);
        }
        setDeleteConfirmation(false);
      },
      onError: () => {
        setDeleteLoading(false);
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
          1000
        );
        setDeleteConfirmation(false);
      },
    });
  };

  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);

  useEffect(() => {
    //
  }, [props.title]);

  const getSubTaskLength = () => {
    return task?.subTasks?.length || 0;
  }

  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getPatientNoteViewByResourceData = async (resourceId: any) => {
    const response = await getDocumentRefByReferenceId(`${resourceId}`,ehrConfig.isAthena && foldVisitNoteWithEncountersEnabled);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    return noteEntry?.resource || {};
  }

  const isNoteStatusNotSigned = async (editTask: ITask): Promise<boolean> => {
    let noteStatusNotSigned = false;
    const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
    if (isTaskLinkWithNote) {
      const taskNoteResourceMapParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES };
      const resourceMapResponse = await getLinkNoteWithTask({ variables: taskNoteResourceMapParam });
      const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
      const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
      if (noteId) {
        try {
          const notesResponse  = await getPatientNoteViewByResourceData(noteId);
          noteStatusNotSigned = notesResponse && notesResponse?.docStatus === DocStatus.PRELIMINARY;
        } catch(error) {

          return false;
        }
      }
    }
    return noteStatusNotSigned;
  }

  const checkIfAllSubTasksAreCompleted = async (task: ITask,value: boolean) => {
    if (value) {
      const noteStatusNotSigned = await isNoteStatusNotSigned(task);
      if (noteStatusNotSigned) {
        notification.destroy();
        setShowLoaderOnCheckbox(false);
        notification.info({
          message: intl.formatMessage({
            id: 'completeTaskLinkNoteMessage',
          }),
          duration: 3.0,
          placement: 'top'
        });
        return;
      }
    }
    if (!task.subTasks || task.subTasks?.length === 0){
      onCheckBoxChange(value);
      return;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map(task => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.every(
          (subTask: any) => subTask.statusId === completedStatusId
        )
      ) {
        onCheckBoxChange(value);
        return;
      } else {
        notification.destroy();
        setShowLoaderOnCheckbox(false)
        notification.info({
          message: intl.formatMessage({
            id: 'subTaskPendingMsg',
          }),
          duration: 3.0,
          placement: 'top'
        });
        return;
      }
    } else {
      notification.destroy();
      setShowLoaderOnCheckbox(false)
      notification.info({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        duration: 3.0,
        placement: 'top'
      });
      return;
    }
  };

  const deleteModalButtons = [
    {
      textLocalId: 'cancel',
      buttonProps: {
        variant: BUTTON_TYPE.SECONDARY,
      },
      onPress: () => {
        setDeleteConfirmation(false);
      },
    },
    {
      textLocalId: 'delete',
      buttonProps: {
        variant: BUTTON_TYPE.PRIMARY,
        loading: deleteLoading,
      },
      loading: deleteLoading,
      onPress: async () => {
        onDeleteTaskConfirmation();
      },
    },
  ];

  const handleTaskDelete = async () => {
    if (props?.handleDeleteOnCallback) {
      if ((props?.onDeleteTaskHandler && typeof props?.onDeleteTaskHandler == 'function')) {
        props?.onDeleteTaskHandler(props?.data, BottomViewAction.deleteTask, undefined);
      }
      return;
    }
    if (task?.subTasks && task?.subTasks?.length > 0) {
      setDeleteLoading(true);
      const response = await getSubTaskByIds({
        variables: {
          ids: task.subTasks?.map((task) => task?.id),
        },
      });
      if (response?.data?.getTasks?.tasks?.length > 0) {
        if (isWeb()) {
          notification.info({
            message: intl.formatMessage({
              id: 'TaskNotDeletedMsgForTaskHasSubtask',
            }),
            duration: 3.0,
            placement: 'top'
          });
          setDeleteLoading(false);
          return;
        } else {
          showToast(
            toast,
            intl.formatMessage({id: 'TaskNotDeletedMsgForTaskHasSubtask'}),
            ToastType.info,
            1000
          );
          setDeleteLoading(false);
          return;
        }
      }
    } else {
      setDeleteConfirmation(true);
    }
  }

  return (
    <Card
      bgColor={'#FFF'}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
    >
      <Box key={`Box_${task.id}`}>
        <HStack key={`HStack_${task.id}`}>
          <VStack key={`VStack_${task.id}`} width={'80%'}>
            <View flexDirection="row" wordBreak={'break-word'}>
              {task?.parentId && (
                <View size={18} marginRight={1}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
              )}
            <Text
              fontWeight={600}
              style={{
                fontSize: 16,
              }}
              color={'black'}
            >
              { getTaskTitle(task) }
            </Text>
            </View>
            {getSubTaskLength() ? (
              <View flexDirection="row" mt={1}>
                <View size={18}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
                <Text
                  ml={1}
                  size={'smMedium'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${getSubTaskLength()} ${
                    getSubTaskLength() > 1 ? 'Subtasks' : 'Subtask'
                  } `}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {task?.labels?.length ? (
              <HStack space={2} flex={1} width={'100%'}>
                <LabelDataListView
                  key={'Labels_' + task.id}
                  hideAddMoreButton={true}
                  tagList={task?.labels || []}
                />
              </HStack>
            ) : (
              <></>
            )}
            {props?.data.endDateTime ? (
              <HStack space={2} alignItems="center">
                <Text
                  fontWeight={400}
                  style={{
                    fontSize: 13,
                  }}
                  color="gray.500"
                >
                  {`Due Date: ${getDateStrFromFormat(
                    props?.data?.endDateTime || new Date(),
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  ) || ''}`}
                </Text>
              </HStack>
            ) : null}
          </VStack>
          <Spacer />
          <HStack justifyContent={'flex-end'} height={'22px'}>
            {props.loading && !props.hideCheckBox && <Spinner />}
            {!props.loading && (
              <View mx={2} alignSelf="center">
                {getPriorityIcon(props?.task?.priority, undefined, 0)}
              </View>
            )}
            {!props.loading && !props.hideCheckBox &&
              (!showLoaderOnCheckbox ?
                (
                  <Pressable
                    onPress={(event) => {
                      event.stopPropagation();
                    }}
                    alignSelf="center"
                  >
                    <Checkbox
                      key={`CheckBox_${task.id}`}
                      checked={props.isChecked}
                      value="complete"
                      onChange={async (event) => {
                        const value = event.target.checked;
                        if (props.onCheckBoxSelect) {
                          props.onCheckBoxSelect(value);
                          return
                        }
                        if (isFormTask && props?.task?.status?.code === 'completed') {
                          const message ='Form already filled can not change status of completed task'
                          notification.destroy();
                          notification.info({
                            message: message,
                            duration: 3.0,
                            placement: 'top'
                          });
                          return;
                        }
                        if (!props.disable) {
                          setShowLoaderOnCheckbox(true)
                          await checkIfAllSubTasksAreCompleted(task, value);
                        }
                      }}
                    />
                  </Pressable>
                )
                :
                <Spinner />)
            }
          </HStack>
        </HStack>
      </Box>

      {props?.hideDetails && props?.hideDetails === true ? (
        <></>
      ) : (
        <View>
          <Box paddingY={2}>
            {/* {task.patientName && (
          <HStack space={2} alignItems="center">
            <Text fontWeight={200} fontSize={12}>
              {intl.formatMessage({id: 'patient'})}:
            </Text>
            <Text fontWeight={200} fontSize={12}>
              {task.patientName}
            </Text>
          </HStack>
        )} */}
            {props.attachments && props.attachments.length > 0 && (
              <Box flexDirection="row" flexWrap="wrap">
                {props.attachments.map((education) => {
                  return (
                    <View key={education.id} marginRight={2} marginTop={2}>
                      <AttachmentButton
                        data={education}
                        onAttachmentClick={(data) => {
                          onAttachmentClick(data);
                        }}
                      />
                    </View>
                  );
                })}
              </Box>
            )}
            {(props.assigneeName || !props?.hideDeleteAction) && (
              <Divider marginY={2} />
            )}
            <>
              <VStack>
                {props.assigneeName && (
                    <Pressable marginTop={2} marginBottom={2} onPress={props?.onAssigneeClick} isDisabled={props.hideAssigneeNavigation}>
                      <HStack space={2} alignItems="center" flex={1}>
                        {/* <Text fontWeight={600} fontSize={14} color="gray.700">
                      Patient:
                    </Text> */}
                         <Text
                          isTruncated
                          style={ props?.isPatientTask ? { fontSize: 14 , color:Colors.Custom.Gray500} : {fontSize: 16}}>
                          {props?.isPatientTask ? `${intl.formatMessage({
                        id: 'assignedTo',
                      })}: ${props.assigneeName}` : props.assigneeName}
                        </Text>
                        {!props.hideAssigneeNavigation &&
                        <Icon
                          as={FeatherIcon}
                          name="external-link"
                          size="xs"
                          color={Colors.Custom.Gray500}
                        />
                        }
                      </HStack>
                    </Pressable>
                )}
                {!props?.isPatientTask && props?.task?.assigneeUser?.name && (
                  <HStack maxW={'100%'}>
                    <Tooltip placement='top' title={
                      props?.task?.assigneeUser?.name
                    }>
                      <Text
                        isTruncated
                        style={{fontSize: 14}}
                        color={Colors.Custom.Gray500}
                      >{`${intl.formatMessage({
                        id: 'assignedTo',
                      })}: ${props?.task?.assigneeUser?.name}`}</Text>
                      </Tooltip>
                  </HStack>
                )}
                <HStack>
                  {!!assignedBy?.name && (
                    <Text
                      style={{fontSize: 14}}
                      color={Colors.Custom.Gray500}
                    >{`Assigned by: ${assignedBy?.name}`}</Text>
                  )}
                </HStack>
              </VStack>
              <VStack>

              </VStack>
            </>
            {props.bottomView}
            {renderResourceMapCountAndDeleteField({
              task: props?.data || task,
              loginUserId: loginUserId,
              currentUserRoles: currentUserRoles || [],
              deleteLoading: deleteLoading,
              showDeleteField: props?.showDeleteField !== undefined ? props?.showDeleteField : true,
              handleTaskDelete,
              intl
            })}
          </Box>
        </View>
      )}
      {isWeb() && showDeleteConfirmation && (
        <Modal
          open={showDeleteConfirmation}
          footer={null}
          closable={false}
        >
          <VStack>
            <Text
              mb={1}
              fontSize={'xl'}
              fontWeight={'bold'}
            >{`Delete Task?`}</Text>
            <Text my={1} fontSize={'md'}>
              {
                props?.data?.resourceMap?.notes ? intl.formatMessage({ id: 'deleteNoteLinkTaskConfirmation' }) : intl.formatMessage({ id: 'deleteTaskConfirmation' })
              }
            </Text>
            <HStack mt={5} justifyContent={'flex-end'}>
              {deleteModalButtons.map((button: any, index: number) => {
                return (
                  <FoldButton
                    key={index + button.btnText + task.id}
                    nativeProps={{
                      variant: button?.buttonProps?.variant,
                      onPress: () => {
                        button?.onPress();
                      },
                      ml: 2,
                      isLoading: button?.loading,
                      isDisabled: button?.loading
                    }}
                    customProps={{
                      btnText: intl.formatMessage({ id: button.textLocalId }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                );
              })}
            </HStack>
          </VStack>
        </Modal>
      )}
    </Card>
  );
};

export default TaskCard;
