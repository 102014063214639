import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../styles';

export const styles = StyleSheet.create({
  continueButton: {
    height: 32,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    marginRight: 8,
    padding: 8,
  },
  stopButton: {
    height: 32,
    backgroundColor: Colors.FoldPixel.PRIMARY100,
    padding: 8,
    borderWidth: 0.5,
    borderColor: Colors.Custom.PrimaryColor200
  },
  noteContainer: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderColor: Colors.FoldPixel.GRAY100,
    borderWidth: 0.5,
    paddingLeft: 4,
    paddingRight: 6,
    paddingVertical: 2,
    borderRadius: 4,
    width: 'fit-content',
    marginTop: 8,
  },
  noteText: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY200,
    fontStyle: 'italic',
    fontWeight: '400',
  },
  addNoteIconButton: {
    padding: 0,
    height: 20,
    width: 90,
  },
  listViewContainer: {
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 12,
  }
});
