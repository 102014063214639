import React, {useContext, useEffect, useState} from 'react';
import {useToast} from 'native-base';
import {MLOV_CATEGORY} from '../../../../constants';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  ADD_CARE_TEAM,
  ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
  DELETE_CARE_TEAM_USER,
  GET_CARE_TEAM,
  GET_CARE_TEAM_MEMBER_TYPES,
} from '../../../../services/CareTeam/CareTeamQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {
  CARE_TEAM,
  USER_POOL_CODES,
  USER_ROLE_CODES,
  CARE_TEAM_MEMBER_TYPE,
} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  GET_SINGLE_USER,
  GET_USERS_WITH_IDS,
  SEARCH_USERS_BY_NAME_AND_ROLE_CODES,
} from '../../../../services/User/UserQueries';
import {getAccountUUID, getAgentsList, isEnableCareProgram} from '../../../../utils/commonUtils';
import {useIntl} from 'react-intl';
import {IUser} from '../../../../Interfaces';
import {IUserSearch} from './interfaces';


import {CareTeamAction} from './CareTeamConst';
import {getCurrentEHR} from '../../MiddleContainer/PatientNotes/PatientNotesHelper';
import {EHRName} from '../../MiddleContainer/PatientNotes/interfaces';
import {ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP} from './CareTeamUtils';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import {MembersScreenStackParamList} from '../../../../navigation/StackNavigation/MemberScreenStack';
import {isWeb} from '../../../../utils/platformCheckUtils';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../constants/WebSocketConst';
import { localBroadcastEvent } from '../../../../utils/CustomEventHandler';


interface IMemberTypeData {
  id: string;
  userId: string;
}

const initialUserAction = {
  code: '',
  userName: '',
  uuid: '',
};

type CareTeamScreenNavigationProp = NativeStackNavigationProp<MembersScreenStackParamList>

const useCareTeam = ({
  contactUUID,
  navigation,
}: {
  contactUUID: string;
  navigation?: CareTeamScreenNavigationProp;
}) => {
  const intl = useIntl();
  const commonContextData = useContext(CommonDataContext);
  const toast = useToast();
  const currentEHR = getCurrentEHR();
  const isElation = currentEHR === EHRName.ELATION;
  const [currentTeam, setCurrentTeam] = useState<IUser[]>([]);
  const [careTeamId, setCareTeamId] = useState<string>('');
  const [userLoading, setUserLoading] = useState(false);
  const [userData, setUserData] = useState<IUser[]>([]);
  const [localPCP, setLocalPCP] = useState<string>('');
  const accountId = getAccountUUID();
  const [selectedValue, setSelectedValue] = useState<IUserSearch | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedUserAndAction, setSelectedUserAndAction] = useState<{
    code: string;
    userName: string;
    uuid: string;
  }>(initialUserAction);

  const [careTeamData, setCareTeamData] = useState<{
    id: string;
    userPoolId: string;
  }>({
    id: '',
    userPoolId: '',
  });

  const [selectedUserForEdit, setSelectedUserForEdit] = useState<
    string | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    modal: false,
    id: '',
    isDeleteLoading: false,
  });
  const isCareProgramEnabled = isEnableCareProgram(
    commonContextData.userSettings
  );
  const [selectedMemberType, setSelectedMemberType] = useState<string>(
    isCareProgramEnabled ? '' : 'other'
  );

  const careProgramRoles = [
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
    CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER,
  ];

  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  )?.filter((item) =>
    isCareProgramEnabled ? true : !careProgramRoles.includes(item.code)
  );
  const [roleUpdateLoading, setRoleUpdateLoading] = useState(false);
  const [careTeamMemberIds, setCareTeamMemberIds] = useState<{
    [key: string]: IMemberTypeData | undefined;
  }>({});
  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
    ),
    [CARE_TEAM_MEMBER_TYPE.PCP]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.PCP
    ),
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const isAllCareTeamMemberType = (memberType: string) => {
    return !careTeamMemberTypesList.map((item) => item.id).includes(memberType);
  };

  const handleLocalPCPChange = (value: string) => {
    setLocalPCP(value);
  };
  const removeLocalPCP = () => {
    setLocalPCP('');
  };
  const onDeleteConfirmationCancel = () => {
    setDeleteConfirmation((prev) => ({...prev, modal: false, id: '', isDeleteLoading: false}));
  };

  const onClose = () => {
    setLocalPCP('');
    setIsOpen(false);
    setCurrentTeam(
      currentTeam ? currentTeam.filter((member: IUser) => !member.isLocal) : []
    );
    setSelectedMemberType(isCareProgramEnabled ? '' : 'other');
  };
  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };
  const careTeamList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];

  const userPoolTypeList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_TEAM_USER_POOL
    ) || [];

  const careTeamUserPoolType = getMlovIdFromCode(
    userPoolTypeList,
    USER_POOL_CODES.CARE_TEAM
  );

  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);

  const [getUsersByRoles, {loading: searchUserLoading}] = useLazyQuery(
    SEARCH_USERS_BY_NAME_AND_ROLE_CODES
  );

  const getSearchWhereCondition = (searchString: string, currentTeam:IUser[]) => {
    const roles = [];
    const where: any = {
      accountUsers: {
        user: {},
      },
    };
    if (
      selectedMemberType ===
      careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
    ) {
      roles.push(
        ...ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[
          CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
        ]
      );
      if (isElation) {
        where.accountUsers.externalUserId = {_is_null: false};
      }
    }

    if (
      selectedMemberType ===
      careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
    ) {
      roles.push(
        ...ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[
          CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
        ]
      );
    }
    if (selectedMemberType === careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]) {
      roles.push(
        ...ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[CARE_TEAM_MEMBER_TYPE.PCP]
      );
      if (isElation) {
        where.accountUsers.externalUserId = {_is_null: false};
      }
    }

    if (roles.length > 0) {
      where.userRoles = {userRole: {userRole: {code: {_in: roles}}}};
      where._not = {
        userRoles: {
          userRole: {
            userRole: {
              code: {
                _in: [
                  USER_ROLE_CODES.WORFLOW_USER,
                  USER_ROLE_CODES.CUSTOMER_SUCCESS,
                ],
              },
            },
          },
        },
      };
    }
    if (searchString.length > 0) {
      where.accountUsers.user.name = {_ilike: `%${searchString}%`};
    }
    where.uuid = {_nin: currentTeam?.map((user) => user.uuid)};
    where.accountUsers.isActive = {_eq: true};
    return where;
  };

  const getCareTeamMemberTypeOptions = () => {
    const teamWithMemberTypes = currentTeam
      ?.filter((user) => user?.memberType && user?.memberType?.length > 0)
      .flatMap((item) => item.memberType);

    if (!currentTeam || currentTeam.length === 0) {
      return [
        ...careTeamMemberTypesList,
        {
          id: 'other',
          code: 'other',
          value: 'Care Team Member',
          categoryId: '',
        },
      ];
    }
    let options = careTeamMemberTypesList;
    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
      )
    ) {
      options = options.filter(
        (item) => item.id !== careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
      );
    }

    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      )
    ) {
      options = options.filter(
        (item) =>
          item.id !==
          careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      );
    }

    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      )
    ) {
      options = options.filter(
        (item) =>
          item.id !== careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      );
    }

    return [
      ...options,
      {
        id: 'other',
        code: 'other',
        value: 'Care Team Member',
        categoryId: '',
      },
    ];
  };

  //USER SEARCH Fn
  const onSearch = async (searchString: string, updatedTeam?:IUser[]) => {
    const response = await getUsersByRoles({
      variables: {
        where: getSearchWhereCondition(searchString, updatedTeam || currentTeam),
      },
      fetchPolicy: 'no-cache',
    });
    setUserData(response?.data?.users || []);
  };
  const formatUserArray = (users: IUser[]) => {
    const data = users.filter((user: IUser) => user?.isLocal);
    return data.map((user) => ({userId: user?.uuid}));
  };

  const [addOrUpdateCareTeamMemberType] = useMutation(
    ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getCareTeamMemberTypes] = useLazyQuery(GET_CARE_TEAM_MEMBER_TYPES, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    fetchPolicy: 'no-cache',
  });

  // GET SINGLE USER
  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  // GET USER DETAILS FROM IDS
  const [getUserDetailData] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });
  // GET ALL CARE TEAM
  const [fetchCareTeam, {loading, refetch}] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    variables: {
      contactId: contactUUID,
    },
    ...headers,
    onCompleted: async (careT) => {
      setCareTeamData((prev) => ({
        ...prev,
        id: careT?.careTeams[0]?.id,
        userPoolId: careT?.careTeams[0]?.userPool?.id,
      }));
      const memberTypesData = await processCareTeamMemberTypes(
        careT?.careTeams[0]?.id
      );
      setCareTeamId(careT?.careTeams[0]?.id || '');
      getUserDetailData({
        variables: {
          userIds:
            careT?.careTeams[0]?.userPool?.userPoolUsers?.map(
              (user: any) => user.userId
            ) || [],
          accountId: accountId,
        },
        onCompleted: (data) => {
          if (careT?.careTeams[0]?.userPool?.userPoolUsers && data?.users) {
            setCurrentTeam(
              careT?.careTeams[0]?.userPool?.userPoolUsers?.map(
                (teamMember: any) => {
                  const user = data.users.find((user: any) => {
                    return user.uuid === teamMember.userId;
                  });
                  if (user) {
                    const memberTypeId = memberTypesData
                      .filter((member) => member.userId === user.uuid)
                      ?.map((item) => item.careTeamMemberTypeId);
                    return {
                      ...user,
                      ...teamMember,
                      userId: user.id,
                      isLocal: false,
                      memberType: memberTypeId,
                    };
                  }
                  return teamMember;
                }
              )
            );
          }
          setDataLoading(false);
        },
      });
      setDataLoading(false);
    },
    onError: () => {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      setDataLoading(false);
    },
  });

  const processCareTeamMemberTypes = async (id: string) => {
    if (!id) {
      return [];
    }
    const memberTypeResponse = await getCareTeamMemberTypes({
      variables: {
        careTeamId: id,
      },
    });
    const memberTypes = memberTypeResponse?.data?.care_team_member_types as {
      careTeamId: string;
      careTeamMemberTypeId: string;
      id: string;
      userId: string;
    }[];
    const data: {[key: string]: IMemberTypeData} = {};
    if (memberTypes) {
      memberTypes.forEach((memberType) => {
        switch (memberType.careTeamMemberTypeId) {
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]:
            data[CARE_TEAM_MEMBER_TYPE.PCP] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]:
            data[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]:
            data[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          default:
            break;
        }
        setCareTeamMemberIds(data);
        return memberTypes;
      });
    }
    return memberTypes;
  };

  const updateRoles = async (users: IUser[], careTeamId: string) => {
    setRoleUpdateLoading(true);
    const updatePcp = users.find((user) =>
      user.memberType?.includes(careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP])
    );
    if (updatePcp) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.PCP]?.id,
          userId: updatePcp.uuid,
          careTeamId: careTeamId,
          careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
        },
      };
      const response = await addOrUpdateCareTeamMemberType({variables});
      if (response?.data?.addOrUpdateCareTeamMemberTypes?.id && isWeb()) {
        localBroadcastEvent(
          CONVERSATION_LOCAL_EVENT_CODES.LOCAL_PCP_USER_CHANGE,
          {
            userData: updatePcp,
            markAsPCP: true,
            contactUUID: contactUUID,
          }
        );
      }
      // HERE If no new user pcp is found but there is db id that means we need to delete it
    } else if (careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.PCP]?.userId) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.PCP]?.id,
          isDeleted: true,
        },
      };
      await addOrUpdateCareTeamMemberType({variables});
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.PCP]: undefined,
      }));
    }

    const updateBilling = users.find((user) =>
      user.memberType?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      )
    );

    if (updateBilling) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
            ?.id,
          userId: updateBilling.uuid,
          careTeamId: careTeamId,
          careTeamMemberTypeId:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
        },
      };
      await addOrUpdateCareTeamMemberType({variables});
      // HERE If no new user pcp is found but there is db id that means we need to delete it
    } else if (
      careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]?.userId
    ) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
            ?.id,
          isDeleted: true,
        },
      };
      await addOrUpdateCareTeamMemberType({variables});
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: undefined,
      }));
    }

    const updateCareManager = users.find((user) =>
      user.memberType?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      )
    );

    if (updateCareManager) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]?.id,
          userId: updateCareManager.uuid,
          careTeamId: careTeamId,
          careTeamMemberTypeId:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
        },
      };
      await addOrUpdateCareTeamMemberType({variables});
      // HERE If no new user pcp is found but there is db id that means we need to delete it
    } else if (
      careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]?.userId
    ) {
      const variables = {
        params: {
          id: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]?.id,
          isDeleted: true,
        },
      };
      await addOrUpdateCareTeamMemberType({variables});
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: undefined,
      }));
    }
    setRoleUpdateLoading(false);
  };

  useEffect(() => {
    setDataLoading(true);
    fetchCareTeam();
  }, [contactUUID]);

  // ADD CARE TEAM QUERY
  const [addCareTeam, {loading: saveCareTeamLoading}] = useMutation(
    ADD_CARE_TEAM,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: async (data) => {
        if (data) {
          await updateRoles(currentTeam || [], data?.createCareTeam?.id);
          onClose();
          showToast(
            toast,
            'Care Team Added Successfully',
            ToastType.success,
            2000
          );
          refetch();
          if (!isWeb()) {
            navigation?.pop();
          }
        }
      },
      onError: () => {
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
        onClose();
      },
    }
  );
  // DELETE CARE TEAM QUERY
  const [deleteCareTeam, {loading: isDeleteLoading}] = useMutation(
    DELETE_CARE_TEAM_USER,
    {
      ...headers,
      onError: () => {
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
        onClose();
      },
    }
  );
  // ON SAVE CLICKED BY USER
  const handleCareTeamSave = () => {
    addCareTeam({
      variables: {
        params: {
          ...(!!careTeamData.id && {id: careTeamData.id}),
          ...(localPCP !== '' && {primaryCarePhysicianUserId: localPCP}),
          contactId: contactUUID,
          careTeamTypeId: careTeamTypeId,

          userPool: {
            on_conflict: {
              constraint: 'user_pool_pkey',
              update_columns: 'isDeleted',
            },
            data: {
              ...(!!careTeamData.userPoolId && {
                id: careTeamData.userPoolId,
              }),
              typeId: careTeamUserPoolType,
              isDeleted: false,
              userPoolUsers: {
                data: formatUserArray(currentTeam || []),
              },
            },
          },
        },
      },
    });
  };


  useEffect(() => {
    if (saveCareTeamLoading && !isWeb()) {
      toast.closeAll();
      showToast(
        toast,
        intl.formatMessage({id: 'savingCareTeam'}),
        ToastType.info
      );
    }
  }, [saveCareTeamLoading]);

  useEffect(() => {
    onSearch('');
  }, []);

  // ON DELETE CLICKED
  const handleDelete = async (id: string, isLocal: boolean) => {
    if (isLocal) {
      const updatedTeam = currentTeam?.filter((item: IUser) => item.id !== id);
      setCurrentTeam(updatedTeam);
      removeLocalPCP();
      onSearch('',updatedTeam);
    } else {
      const userUUID = currentTeam?.find(
        (member) => member.id === deleteConfirmation.id
      )?.uuid;
      if (!userUUID) {
        return;
      }
      setDeleteConfirmation((prev) => ({ ...prev, isDeleteLoading: true }));
      const response = await deleteCareTeam({
        variables: {
          userId: id,
          careTeamId: careTeamData.id,
          userUuid: userUUID,
        },
      });
      if (response.data) {
        const userToBeDeleted = currentTeam?.find(
          (user) => user.id === deleteConfirmation.id
        );

        if (
          userToBeDeleted?.memberType &&
          userToBeDeleted?.memberType?.length > 0
        ) {
          const promiseList: Promise<any>[] = [];
          const newCareTeamIds = {...careTeamMemberIds};
          userToBeDeleted?.memberType?.forEach(async (memberType: string) => {
            const code = careTeamMemberTypesList.find(
              (item) => item.id === memberType
            )?.code;
            if (code) {
              promiseList.push(
                addOrUpdateCareTeamMemberType({
                  variables: {
                    params: {
                      id: newCareTeamIds[code]?.id,
                      isDeleted: true,
                    },
                  },
                })
              );
              newCareTeamIds[code] = undefined;
            }
          });

          await Promise.all(promiseList);
          setCareTeamMemberIds(newCareTeamIds);
        }
        refetch();
        setDeleteConfirmation((prev) => ({ ...prev, modal: false, id: '', isDeleteLoading: false }));
        showToast(
          toast,
          'Care Team Member Deleted Successfully',
          ToastType.success
        );
      }
    }
  };
  // ON USER ADD MEMBER FROM SEARCH
  const handleAddCareTeam = (value: string) => {
    setUserLoading(true);
    getSingleUserById({
      variables: {
        userId: value,
      },
      onCompleted: (data) => {
        let updatedTeam: IUser[] = [];
        setCurrentTeam((prev) => {
          updatedTeam = [
            {
              ...data.users[0],
              userId: data.users[0]?.id,
              isLocal: true,
              memberType: isAllCareTeamMemberType(selectedMemberType)
                ? undefined
                : [selectedMemberType],
            },
            ...(prev || []),
          ];
          return updatedTeam;
        });
        setSelectedValue(undefined);
        setUserLoading(false);
        setSelectedMemberType(isCareProgramEnabled ? '' : 'other');
        onSearch('', updatedTeam);
      },
      onError: () => {
        setUserLoading(false);
      },
    });
  };

  const onActionPressed = (action: CareTeamAction, member: IUser) => {
    switch (action) {
      case CareTeamAction.delete:
        if (member?.isLocal) {
          handleDelete(member.id, true);
        } else {
          setSelectedUserForEdit(undefined);
          setDeleteConfirmation((prev) => ({ ...prev, modal: true, id: member.id, isDeleteLoading: false }));
        }
        break;
      case CareTeamAction.markAsPCP:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
          user: member,
          isMark: true,
        });
        break;
      case CareTeamAction.unMarkAsPCP:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
          user: member,
          isMark: false,
        });
        break;
      case CareTeamAction.markAsCareManager:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
          user: member,
          isMark: true,
        });
        break;
      case CareTeamAction.unMarkAsCareManager:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
          user: member,
          isMark: false,
        });
        break;
      case CareTeamAction.markAsBilling:
        onChangeMemberType({
          memberType:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
          user: member,
          isMark: true,
        });
        break;
      case CareTeamAction.unMarkAsBilling:
        onChangeMemberType({
          memberType:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
          user: member,
          isMark: false,
        });
    }
  };

  const agentsData = getAgentsList();

  const getUserList = (team: IUser[]) => {
    return (
      team?.map((user) => {
        const matchUser = agentsData?.find((agent: any) => {
          if (user?.userId && user?.userId === agent?.id) {
            return agent;
          } else if (user?.id && user?.id === agent?.id) {
            return agent;
          }
        });
        if (matchUser) return {...user, agent: matchUser};
        else return user;
      }) || []
    );
  };

  const onChangeMemberType = async (params: {
    memberType: string;
    user: IUser;
    isMark: boolean;
    isDirectUpdate?: boolean;
  }) => {
    const careTeamId = careTeamData.id;
    const {memberType, user, isMark} = params;
    const {uuid} = user;

    const isUserWithSameRoleExist = currentTeam?.find((userListItem) => {
      return (
        userListItem.memberType?.includes(memberType) &&
        userListItem.uuid !== uuid
      );
    });

    if (!user.isLocal) {
      const updateTypeCode =
        careTeamMemberTypesList.find((type) => type.id === memberType)?.code ||
        '';
      const updateId = careTeamMemberIds[updateTypeCode]?.id;
      const params = isMark
        ? {
            id: updateId,
            userId: uuid,
            careTeamId: careTeamId,
            careTeamMemberTypeId: memberType,
            isDeleted: false,
          }
        : {
            id: updateId,
            isDeleted: true,
          };
      const response = await addOrUpdateCareTeamMemberType({
        variables: {
          params: params
        },
      });

      if (response?.data?.addOrUpdateCareTeamMemberTypes?.id) {
        if (
          memberType === careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP] &&
          isWeb()
        ) {
          localBroadcastEvent(
            CONVERSATION_LOCAL_EVENT_CODES.LOCAL_PCP_USER_CHANGE,
            {
              userData: user,
              markAsPCP: isMark,
              contactUUID: contactUUID,
            }
          );
        }
        setCareTeamMemberIds((prev) => ({
          ...prev,
          [updateTypeCode]: {
            id: response?.data?.addOrUpdateCareTeamMemberTypes?.id,
            userId: uuid as string,
          },
        }));
      }
    }

    const newList = currentTeam?.map((user) =>
      user.uuid === isUserWithSameRoleExist?.uuid
        ? {
            ...user,
            memberType: user.memberType?.filter(
              (memberTypeItem) => memberTypeItem !== memberType
            ),
          }
        : user
    );
    setCurrentTeam(
      newList?.map((user) =>
        user.uuid === uuid
          ? {
              ...user,
              memberType: isMark
                ? [...((user?.memberType as string[]) || []), memberType]
                : user.memberType?.filter((item) => item !== memberType),
            }
          : user
      )
    );
    setSelectedUserForEdit(undefined);
  };

  return {
    careTeamMemberTypes,
    selectedUserForEdit,
    isElation,
    mainContentLoading: loading,
    currentTeam,
    careTeamMemberTypesList,
    selectedUserAndAction,
    initialUserAction,
    deleteConfirmation,
    isOpen,
    saveCareTeamLoading: saveCareTeamLoading || roleUpdateLoading,
    selectedMemberType,
    drawerListLoading: loading || isDeleteLoading,
    selectedValue,
    userLoading,
    searchUserLoading,
    userData,
    agentsData,
    dataLoading,
    handleSelectUserForEdit: (id: string) => setSelectedUserForEdit(id),
    onActionPressed,
    getUserList,
    onDeleteConfirmationCancel,
    handleDelete,
    onClose,
    handleDrawer: (value: boolean) => setIsOpen(value),
    handleCareTeamSave,
    getCareTeamMemberTypeOptions,
    onSelectMemberType: (type: string) => {
      setSelectedMemberType(type);
      setUserData([]);
    },
    setUserData,
    onSearch,
    handleUserSelect: (value: IUserSearch, data: any) => {
      setSelectedValue(data);
      handleAddCareTeam(value.value);
    },
    handleDeleteConfirmation: (id: string) =>
      setDeleteConfirmation((prev) => ({ ...prev, modal: true, id: id, isDeleteLoading: false })),

    fetchCareTeam,
    isCareProgramEnabled,
  };
};

export default useCareTeam;
