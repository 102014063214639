import React from 'react';
import {Pagination, PaginationProps} from 'antd';
import {Dimensions, ViewStyle} from 'react-native';
import {Divider, View} from 'native-base';
import './TableWrapperStyle.css';

interface ITableWrapperProps {
  children: JSX.Element;
  pagination: PaginationProps;
  wrapperStyle?: ViewStyle;
  customStyle?: ViewStyle;
  minHeight?: number;
  showDivierAbovePagination?: boolean;
  hideOnSinglePage?: boolean;
  noMinHeight?: boolean;
  hidePagination?: boolean;
}

const TableWrapper = (props: ITableWrapperProps) => {
  const {children, pagination, wrapperStyle, minHeight, showDivierAbovePagination} = props;
  const {height} = Dimensions.get('window');

  const paginationOptions: PaginationProps = {
    ...pagination,
    pageSize: pagination.pageSize || 10,
    current: pagination.current || 1,
  };
  const getMinHeight = ()=>{
    if (minHeight) {
      return height - minHeight
    }
    return height - 180
  }

  return (
    <View
      style={{
        ...(!props?.noMinHeight && { minHeight: getMinHeight() }),
        justifyContent: 'space-between',
        position: 'relative',
        ...wrapperStyle,
      }}
    >
      {children}
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          ...props?.customStyle?.zIndex && {zIndex : props?.customStyle?.zIndex}
        }}
      >
        {!!showDivierAbovePagination && <Divider />}
        {!props.hidePagination &&
          <Pagination
            className="wrapper"
            {...paginationOptions}
            hideOnSinglePage={props?.hideOnSinglePage === false ? false : true}
          />}
      </View>
    </View>
  );
};

export default TableWrapper;
