import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import CommonService from '../../../services/CommonService/CommonService';
import { getAccountUUID } from '../../../utils/commonUtils';
import { getGroupsList } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { GroupSearchProps } from './interfaces';

const GroupSearch = (props: GroupSearchProps) => {
  const {selectedGroups, selectedGroupOptions, onSelectGroups} = props;

  const commonService = CommonService.getCommonServiceInstance();
  const workflowService = commonService.workflowService;

  const [state, setState] = useState({
    isOpen: false,
    loading: false,
    groupData: [] as Array<{ id: string; name: string;}>,
    // selectedIds: [] as string[],
  });
  const [searchText, setSearchText] = useState<string | undefined>('');

  const tenantId = getAccountUUID();

  const searchGroup = async (searchString: string, ruleIds?: string[]) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
        isOpen: true,
        groupData: [],
      };
    });
    await getGroups(searchString, ruleIds);
  };

  async function getGroups(searchString: string, ruleIds?: string[]) {

    const limit = 15;
    const name = searchString;
    const offset = 0;
    const category = undefined;
    const contactType = props.contactType;
    const sort = undefined;
    const filter = props.groupType;
    setSearchText(searchString);
    try {
      const response = await getGroupsList(
        tenantId,
        limit,
        name,
        offset,
        category,
        contactType,
        ruleIds,
        sort,
        filter
      );
      const groupData = ruleIds?.length ? response?.data : response?.data?.data;
      setState((prev) => {
        return {
          ...prev,
          groupData: groupData || [],
          loading: false,
        };
      });
    } catch (error) {

    }
  }

  useEffect(() => {
    if (selectedGroupOptions?.length) {
      setState((prev) => {
        return {
          ...prev,
          groupData: selectedGroupOptions,
        };
      });
    }
  }, []);

  return (
    <View>
      <Select
        size="large"
        mode="multiple"
        showSearch
        allowClear
        filterOption={false}
        onSearch={debounce(searchGroup, 500)}
        value={selectedGroups || selectedGroupOptions?.map?.((option) => option?.id) || undefined}
        onChange={(value, options) => {
          const selectedGroupOptions = options?.map((option: any) => ({id: option?.key, name: option?.children})) || [];
          onSelectGroups(value, selectedGroupOptions);
        }}
        onFocus={() => {
          const debouncedSearch = debounce((searchTerm) => searchGroup(searchTerm), 500);
          debouncedSearch('');
        }}
        placeholder="Search Groups"
        loading={state.loading || (!state?.groupData?.length)}
        notFoundContent={
          state.loading ?
          <Spin size="small" /> :
          ( searchText && !state.groupData.length && <Text>No Data Found</Text>)
        }
        style={{width: '100%'}}
      >
        {state.groupData.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </View>
  );
};

export default GroupSearch;
