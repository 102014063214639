import React, { useContext } from 'react'
import { IAddOrUpdateExternalOrdersViewProps } from '../interfaces';
import { Input, VStack, Text, HStack, IconButton, View, Icon, TextArea } from 'native-base';
import { OrderType } from '../../OrderConstants';
import { getOrderingPhysicianMessage, getUserContactDetails, getUserNPI, getUserSignatureId, ORDER_PRIORITY } from '../Helper';
import { generateUUID, isBrowserIncompatibleForPrint } from '../../../../../../../utils/commonUtils';
import PAMISearch, { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { Colors } from '../../../../../../../styles';
import UserAutoComplete, { IUserSearch } from '../../../../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import { MLOV_CATEGORY, USER_ROLE_CODES } from '../../../../../../../constants/MlovConst';
import { getCodeSystem } from '../../../../../../common/PAMISearch/PAMISearchHelper';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { debounce } from 'lodash';
import OrderSearch from '../../../../../../common/OrderSearch/OrderSearch';
import { getExternalOrderDisplay, OrderSearchType } from '../../../../../../common/OrderSearch/Helper';
import { Radio } from 'antd';
import { getCurrentTimeZone, getMomentObj, isPastDay } from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants';
import { ModalActionDatePicker } from '../../../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { getMlovListFromCategory } from '../../../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import InfoSvg from '../../../../../../common/Svg/InfoSvg';

const AddOrUpdateExternalOrdersView = (props: IAddOrUpdateExternalOrdersViewProps) => {
  // Constants
  const { orderType, showErrors, selectedOrder, onChange } = props;
  const isIncompatibleBrowser = isBrowserIncompatibleForPrint();
  const commonData = useContext(CommonDataContext);
  const providerTypesList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PROVIDER_IDENTIFIER_TYPE
  );

  // Functions
  const renderTestComponent = () => {
    return (
      <VStack space={2}>
        <Text color={Colors.Custom.Gray500}>Tests <Text color={Colors.error[500]}>*</Text></Text>
        {selectedOrder.orders.length > 0 && (
          <VStack space={1} marginLeft={2} marginY={1}>
            {selectedOrder.orders.map((item, index) => {
              return (
                <HStack key={index} space={2} alignItems={'flex-start'}>
                  <Text fontSize={16}>•</Text>
                  <VStack flexShrink={1}>
                    <Text>{getExternalOrderDisplay(item.order)}</Text>
                  </VStack>
                  <View width={8} marginTop={-0.5}>
                    <IconButton
                      colorScheme="gray"
                      tintColor="gray.500"
                      variant="ghost"
                      onPress={() => {
                        selectedOrder.orders.splice(index, 1);
                        onChange({
                          ...selectedOrder,
                          orders: [...selectedOrder.orders],
                        });
                      }}
                      icon={
                        <Icon
                          as={AntIcon}
                          name="closecircle"
                          size="3"
                          color="gray.400"
                        />
                      }
                    />
                  </View>
                </HStack>
              );
            })}
          </VStack>
        )}
        <OrderSearch
          isInvalid={showErrors && !selectedOrder.orders.length}
          enablePaginationOnScroll
          addNewOptionEnabled={false}
          clearOnSelection
          searchType={orderType == OrderType.RAD ? OrderSearchType.radiology : OrderSearchType.lab}
          placeholder={'Search and add new order'}
          isShowError={false}
          onChange={(value) => {
            if (value) {
              const newOrder = {
                order: value,
                serviceRequestId: generateUUID(),
                questionAnswers: [],
              };
              selectedOrder.orders.push(newOrder);
              onChange({
                ...selectedOrder,
                orders: [...selectedOrder.orders],
              });
            }
          }}
        />
      </VStack>
    )
  };

  const renderDiagnosisComponent = () => {
    return (
      <VStack space={2}>
        <Text color={Colors.Custom.Gray500}>Add Patient Diagnosis <Text color={Colors.error[500]}>*</Text></Text>
        {selectedOrder.diagnosis.length > 0 && (
          <VStack space={1} marginLeft={2} marginY={1}>
            {selectedOrder.diagnosis.map((item, index) => {
              return (
                <HStack key={index} space={2} alignItems={'flex-start'}>
                  <Text fontSize={16}>•</Text>
                  <VStack flexShrink={1}>
                    <Text>{item.text}</Text>
                    {(item.coding || []).map((codeData) => {
                      return (
                        <HStack key={codeData.code} space={2} alignItems="baseline">
                          <Text fontSize="2xs" color={Colors.Custom.Gray500}>
                            {getCodeSystem(codeData.system)}
                          </Text>
                          <Text fontSize="xs">
                            {codeData.code}
                          </Text>
                        </HStack>
                      );
                    })}
                  </VStack>
                  <View width={8} marginTop={-0.5}>
                    <IconButton
                      colorScheme="gray"
                      tintColor="gray.500"
                      variant="ghost"
                      onPress={() => {
                        selectedOrder.diagnosis.splice(index, 1);
                        onChange({
                          ...selectedOrder,
                          diagnosis: [...selectedOrder.diagnosis],
                        });
                      }}
                      icon={
                        <Icon
                          as={AntIcon}
                          name="closecircle"
                          size="3"
                          color="gray.400"
                        />
                      }
                    />
                  </View>
                </HStack>
              );
            })}
          </VStack>
        )}
        <PAMISearch
          showAdditionalDetails
          isInvalid={showErrors && !selectedOrder.diagnosis.length}
          enablePaginationOnScroll
          addNewOptionEnabled={false}
          clearOnSelection
          searchType={PAMISearchType.diagnosis}
          placeholder={'Search and add new diagnosis'}
          isShowError={false}
          onChange={(value) => {
            if (value) {
              const alreadyExists = selectedOrder.diagnosis.some((diagnosis) =>
                diagnosis?.coding && (
                  diagnosis?.coding?.[0]?.code === value?.coding?.[0]?.code &&
                  diagnosis?.coding?.[0]?.display === value?.coding?.[0]?.display
                )
              );
              if (!alreadyExists) {
                selectedOrder.diagnosis.push(value);
              }
              onChange({
                ...selectedOrder,
                diagnosis: [...selectedOrder.diagnosis],
              });
            }
          }}
        />
      </VStack>
    );
  }

  const renderFacilityAndDate = () => {
    return (
      <HStack space={4}>
        <VStack space={2} flex={1}>
          <Text color={Colors.Custom.Gray500}>Facility</Text>
          <Input
            defaultValue={selectedOrder.facility}
            placeholder={'Enter facility name'}
            _focus={{
              borderColor: Colors.Custom.Gray200
            }}
            borderWidth={1}
            borderColor={Colors.Custom.Gray200}
            borderRadius={8}
            height={'44px'}
            onChangeText={debounce((text) => {
              onChange({
                ...selectedOrder,
                facility: text,
              });
            }, 500)}
          />
        </VStack>
        <VStack space={2} flex={1}>
          <ModalActionDatePicker
            isRequired={true}
            isInvalid={showErrors && !selectedOrder.orderDate}
            label={'orderDate'}
            placeholder={'Select test date'}
            defaultValue={selectedOrder.orderDate ? getMomentObj(selectedOrder.orderDate) : undefined}
            format={DISPLAY_DATE_FORMAT}
            onChange={(date: any) => {
              if (date) {
                onChange({
                  ...selectedOrder,
                  orderDate: date.toISOString(),
                });
              }
            }}
            disabledDate={(current: any) => {
              return (
                current &&
                isPastDay(current, getCurrentTimeZone())
              );
            }}
            value={selectedOrder.orderDate ? getMomentObj(selectedOrder.orderDate) : undefined}
            customStyle={{flex: 1}}
          />
        </VStack>
      </HStack>
    );
  }

  const renderOrderingPhysicianAndPriority = () => {
    const infoMessage = selectedOrder.orderingPhysician ? getOrderingPhysicianMessage(selectedOrder.orderingPhysician) : '';
    return (
      <HStack space={4}>
        <VStack space={2} flex={1}>
          <Text color={Colors.Custom.Gray500}>Ordering Physician <Text color={Colors.error[500]}>*</Text></Text>
          <UserAutoComplete
            selectedUserUUID={selectedOrder.orderingPhysician?.userUuid}
            allowClear={false}
            roleCodes={[USER_ROLE_CODES.PHYSICIAN]}
            isInvalid={showErrors && !selectedOrder.orderingPhysician?.userUuid}
            onChange={(selectedUser: IUserSearch) => {
              const signatureId = getUserSignatureId(selectedUser.data, providerTypesList) || '';
              const npi = getUserNPI(selectedUser.data, providerTypesList) || '';
              const contactDetails = getUserContactDetails(selectedUser.data);
              onChange({
                ...selectedOrder,
                eSign: false,
                orderingPhysician: {
                  name: selectedUser.label,
                  userUuid: selectedUser.value,
                  signatureId: signatureId || '',
                  npi: npi,
                  phone: contactDetails.phoneNumber,
                  email: contactDetails.email,
                  fax: contactDetails.faxNumber,
                },
              });
            }}
            backgroundColor={'white'}
          />
          {infoMessage && (
            <HStack alignItems={'flex-start'} space={1}>
              <InfoSvg customColor={Colors.info[500]} />
              <Text color={Colors.info[500]} fontSize={14}>{infoMessage}</Text>
            </HStack>
          )}
        </VStack>
        <VStack space={2} flex={1}>
          <Text color={Colors.Custom.Gray500}>Priority</Text>
          <View marginTop={1}>
            <Radio.Group
              defaultValue={selectedOrder.priority}
              name="radio"
              onChange={(value) => {
                onChange({
                  ...selectedOrder,
                  priority: value.target.value,
                });
              }}
            >
              <HStack space={2}>
                {ORDER_PRIORITY.map((item) => {
                  return (
                    <Radio
                      key={`${item.code}_${item.display}`}
                      value={item.code}
                    >
                      {item.display}
                    </Radio>
                  );
                })}
              </HStack>
            </Radio.Group>
          </View>
        </VStack>
      </HStack>
    );
  }

  const renderFacilityInstruction = () => {
    return (
      <VStack space={2}>
        <Text color={Colors.Custom.Gray500}>Instruction to facility</Text>
        <TextArea
          backgroundColor={'white'}
          defaultValue={selectedOrder.facilityNotes}
          placeholder={'Enter instructions'}
          totalLines={3}
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          onChangeText={debounce((text) => {
            onChange({
              ...selectedOrder,
              facilityNotes: text,
            });
          }, 500)}
        />
      </VStack>
    );
  }

  const renderPatientInstruction = () => {
    return (
      <VStack space={2}>
        <Text color={Colors.Custom.Gray500}>Instruction to patient</Text>
        <TextArea
          backgroundColor={'white'}
          defaultValue={selectedOrder.patientNotes}
          placeholder={'Enter instructions'}
          totalLines={3}
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          onChangeText={debounce((text) => {
            onChange({
              ...selectedOrder,
              patientNotes: text,
            });
          }, 500)}
        />
      </VStack>
    );
  }

  return (
    <VStack space={5}>
      {
        isIncompatibleBrowser &&
        <HStack alignItems={'flex-start'} space={1}>
          <InfoSvg customColor={Colors.info[500]} />
          <Text color={Colors.info[500]} fontSize={14}>{'This browser version is not supported for print. Please update it to the latest version'}</Text>
        </HStack>
      }
      {renderTestComponent()}
      {renderDiagnosisComponent()}
      {renderOrderingPhysicianAndPriority()}
      {renderFacilityAndDate()}
      {renderFacilityInstruction()}
      {renderPatientInstruction()}
    </VStack>
  );
}

export default AddOrUpdateExternalOrdersView;
