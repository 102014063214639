import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

const GroupActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <G clipPath="url(#clip0_3588_126625)">
        <Path
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.692 2.003c1.092 0 1.99.92 1.99 2.053s-.891 2.047-1.99 2.047m3.063 5.726c.912-.413.912-1.106.912-1.886 0-1.147-1.454-2.107-3.354-2.28m-2.224-3.66c0 1.473-1.157 2.666-2.585 2.666S3.919 5.476 3.919 4.003c0-1.474 1.157-2.667 2.585-2.667s2.585 1.193 2.585 2.667zm2.585 7.666c0 1.66 0 3-5.17 3s-5.17-1.34-5.17-3 2.313-3 5.17-3c2.857 0 5.17 1.34 5.17 3z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_3588_126625">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default GroupActionSvg;
