import {useLazyQuery} from '@apollo/client';
import {Drawer, Select as AntSelect, Tooltip} from 'antd';
import {
  Divider,
  FormControl,
  HStack,
  Icon,
  Input,
  Pressable,
  ScrollView,
  Select,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  DData,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
} from '../../../../../constants';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../Interfaces';
import {getPractitionersData} from '../../../../../services/CommonService/AidBoxService';
import BaseService from '../../../../../services/CommonService/BaseService';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../styles';
import { getEHRName, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getUserUUID,
  isMasterAccount,
  isValidEmail,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getMlovIdFromCodeAndCategory, getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  ehrCodeDisplay,
  InviteUserDrawerProps,
  PractitionerData,
  SingleEmployer,
  UserData,
} from './interfaces';
import {styles} from './InviteUserDrawerStyles';
import {
  getErrorMessage,
  getFinalPractitionerData,
  getFormattedPractitionerData,
  getPostRequestBody,
  inviteUser,
  validateForm,
} from './InviteUserDrawerUtils';
import './inviteUserDrawer.css';
import CardForLocationAndUser from '../AddEditUser/components/CardForLocationAndUser';
import { getEhrList } from '../../../../../services/CommonService/IntegrationService';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import SkeletonLoader from '../AddEditUser/components/SkeletonLoader';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { useIntl } from 'react-intl';
import AssociatedAccountView from '../AssociatedAccountView/AssociatedAccountView';
import UsersAddedInfoView from '../AssociatedAccountView/UsersAddedInfoView';
import {IAccountRole} from '../AssociatedAccountView/interface';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';

const {Label, ErrorMessage} = FormControl;

const InviteUserDrawer = (props: InviteUserDrawerProps) => {
  const {isOpen, onClose} = props;
  const [cleared, setCleared] = useState(false)
  const [errors, setErrors] = useState<any>({});
  const accountId = getAccountId();
  const accountUuid = getAccountUUID()
  const [userData, setUserData] = useState<UserData>({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    externalUserId: '',
    employerId: '',
    employerName: '',
    id: -1,
    edit: false,
    roleName: '',
    associatedAccounts: [
      {
        id: 1,
        accountUuid: '',
        accountId: 0,
        externalUserId: '',
        rolesToAdd: [] as string[],
        rolesToDelete: [] as string[],
      },
    ] as IAccountRole[]
  });
  const [stateData, setStateData] = useState({
    usersData: [] as UserData[],
  });
  const [userRoles, setUserRoles] = useState([] as IMlov[]);
  const [loading, setLoading] = useState(false);
  const [practitionerData, setPractitionerData] = useState<PractitionerData[]>(
    []
  );
  const [employerList, setEmployerList] = useState<SingleEmployer[]>([]);

  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const tenantId = getAccountUUID();
  const userId = getUserUUID();
  const intl = useIntl();
  const isMasterAccountFlag = isMasterAccount()

  const practitionerAbilities = getResourceAbilities(
    FHIR_RESOURCE.PRACTITIONER
  );
  const showPractitionerDropdown = practitionerAbilities?.isEnabled || false;
  const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);
  const currentCodeEHR = getEHRName();

  const getEhrDisplayName = (ehrCode?: string) => {
    return (ehrList || []).find(ehr => ehr.code === (ehrCode || currentCodeEHR))?.display || '';
  };

  const baseService = BaseService.getSharedInstance().axios;

  const [getAccountUserExternalUserId] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountUserExternalUserId
  );

  const [getUserEmail] = useLazyQuery(
    UserPracticeLocationQueries.GET_USER_EMAIL
  );

  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: tenantId,
      query: '%%',
    },
  });


  const handleInviteUser = (body: any) => {
    inviteUser({baseService: baseService, body: body})
      .then((response) => {
        const duplicateEmailExist = response.data?.duplicateUserEmailList
          ?.length
          ? response.data?.duplicateUserEmailList
          : [];
        if (props.onCallBackAction && duplicateEmailExist.length) {
          props.onCallBackAction?.(
            COMMON_ACTION_CODES.DUPLICATE,
            duplicateEmailExist
          );
        }
        if (!(duplicateEmailExist && duplicateEmailExist.length > 0)) {
          showToast(toast, intl.formatMessage({id: 'userInvited'}), ToastType.success);
        }
        onClose(true);
      })
      .catch((error) => {

        showToast(toast, getErrorMessage(error), ToastType.error);
        setLoading(false);
        onClose(true);
      });
  };

  const handleSubmit = (data: any) => {
    setErrors({});
    handleInviteUser(data);
  };

  const getInitialDetail = () => {
    const userRoles =
      (getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.USER_ROLES) || [])
        ?.sort((a, b) => a?.value.localeCompare(b?.value));
    setUserRoles((prev) => [
      ...userRoles,
      {
        categoryId: '',
        code: '',
        id: '',
        value: '',
      },
    ]);
    try {
      setLoading(true);
      const promiseList = [
        getEmployers(),
        ...(showPractitionerDropdown
          ? [
              getEhrList(),
              getPractitionersData(),
              getAccountUserExternalUserId({
                variables: { accountId: accountId },
              }),
            ]
          : []),
      ];
      Promise.all(promiseList)
        .then((response) => {
          setEmployerList(response?.[0]?.data?.employers || []);
          if (showPractitionerDropdown) {
            if (response?.[1]?.data?.expansion?.contains?.length > 0) {
              setEhrList(response?.[1]?.data?.expansion?.contains);
            }
            const data = getFormattedPractitionerData(response[2].data);
            const accountUsers = response[3];
            const list = getFinalPractitionerData(data, accountUsers);
            setPractitionerData(list);
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrors({
            ...errors,
            practitioner: "Error in fetching practitioners list",
          });
          setPractitionerData([]);
          setEhrList([]);
          setEmployerList([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialDetail();
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60vw' : '38vw';
  const employerId = getMlovIdFromCodeAndCategory(USER_ROLE_CODES.EMPLOYER,MLOV_CATEGORY.USER_ROLES,false)
  const showEmployerSelection = () => {
    if (userData.role === 'EMPLOYER' && !isMasterAccountFlag) {
      return true
    }
    if (userData.associatedAccounts?.length) {
      for (const account of userData.associatedAccounts) {
        if (account.rolesToAdd?.includes(employerId)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const handleDeleteUser = (id: number) => {
    const updatedList = stateData?.usersData.filter((user: any) => {
      return user?.id !== id;
    });
    setStateData((prev) => {
      return {
        ...prev,
        usersData: updatedList,
      };
    });
  };

  const handleEditUser = (id: number) => {
    const selectedUser = stateData?.usersData.filter((user) => {
      return user;
    });
    setUserData((prev) => {
      return {
        ...prev,
        firstName: selectedUser[0]?.firstName,
        lastName: selectedUser[0]?.lastName,
        role: selectedUser[0]?.role,
        email: selectedUser[0]?.email,
        externalUserId: selectedUser[0]?.externalUserId,
        employerId: selectedUser[0]?.employerId,
        employerName: selectedUser[0]?.employerName,
        id: id,
        edit: true,
        associatedAccounts: selectedUser[0]?.associatedAccounts
      };
    });
  };

  const checkForDuplicateEmail = async (email: string) => {
    const isEmailExist = stateData?.usersData.some((userData) => {
      return userData?.email === email;
    });
    const isUserEmailExist = await getUserEmail({
      variables: {
        email: email,
      },
    });

    setErrors({
      ...errors,
      duplicateEmail:
        isEmailExist || isUserEmailExist?.data?.users.length
          ? 'Email already exist'
          : '',
      invalidEmail: !isValidEmail(email) ? 'Please enter a valid email' : '',
    });
    if (
      isEmailExist ||
      isUserEmailExist?.data?.users.length ||
      !isValidEmail(email)
    ) {
      return true;
    }
    return false;
  };
  const getName = (userData: any) => {
    if (!userData.externalUserId) {
      return ''
    }
    const selectedUser = practitionerData.find((item) => {
      return item.id === userData.externalUserId;
    });
    return `${selectedUser?.displayName} (${getEhrDisplayName()})`;
  };

  const getInviteUserField = (userData?: any) => {
    return (
      <View mb={2}>
        {showPractitionerDropdown && !isMasterAccountFlag &&
          practitionerData &&
          practitionerData.length > 0 &&
          (
            <FormControl style={styles.formElement}>
              <Label>
                <HStack alignItems={"center"} marginTop={1}>
                  <DisplayText extraStyles={styles.labelText} textLocalId="mapUserToEhr" />
                  <Tooltip
                    title={intl.formatMessage({id: 'mapUserToEhrText'})}
                    placement={'top'}
                    destroyTooltipOnHide={true}
                  >
                    <Icon
                      mx={2}
                      as={AntIcon}
                      color={Colors.Custom.Gray500}
                      name="infocirlceo"
                      size="smMedium"
                    />
                  </Tooltip>
                </HStack>

              </Label>
              <AntSelect
                className='inviteUserDrawerSelect'
                allowClear={userData.externalUserId}
                dropdownStyle={{
                  borderRadius: 20,
                }}
                onChange={(value) => {
                  if (!value) {
                    setCleared(true);
                    return;
                  }
                  value && setCleared(false);
                  const selectedUser = practitionerData.find((item) => {
                    return item.id === value;
                  });
                  if (userData?.id === -1) {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        firstName: selectedUser?.firstName || '',
                        lastName: selectedUser?.lastName || '',
                        email: selectedUser?.email || '',
                        externalUserId: value,
                      };
                    });
                  }
                  else {
                    const updatedUserData = stateData?.usersData.map((user) => {
                      if (user.id === userData?.id) {
                        return {
                          ...user,
                          firstName: selectedUser?.firstName || '',
                          lastName: selectedUser?.lastName || '',
                          email: selectedUser?.email || '',
                          externalUserId: value,
                        };
                      } else {
                        return user;
                      }
                    });
                    setStateData((prev) => {
                      return {
                        ...prev,
                        usersData: updatedUserData,
                      };
                    });
                  }
                }}
                value={
                  cleared ? '' : userData?.id === -1
                    ? getName(userData)
                    : getName(stateData?.usersData[userData?.id])
                }
              >
                {practitionerData.map((item, index) => {
                  return (
                    <AntSelect.Option
                      key={item.id.toString()}
                      value={item.id.toString()}
                      label={`${item.firstName} ${item.lastName}`}
                      className={'inviteUserDrawer'}
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      <CardForLocationAndUser
                        name={item.displayName}
                        uuid={item.id}
                        isSearchComponent={true}
                        ehrName={getEhrDisplayName()}
                        showAvatar={true}
                      />
                      {index < practitionerData.length - 1 ? <Divider marginX={'2'} color={Colors.Custom.Gray200} /> : null}
                    </AntSelect.Option>
                  );
                })}
              </AntSelect>
            </FormControl>
          )}

        {errors.practitioner && (
          <Text fontSize={'xs'} color="error.500" fontWeight={500}>
            {errors.practitioner}
          </Text>
        )}
        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={errors.firstName}
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="firstName" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.firstName
                : stateData?.usersData[userData?.id]?.firstName
            }
            onChangeText={(firstName) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    firstName: firstName,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, firstName: firstName};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {errors.firstName && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.firstName}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={errors.lastName}
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="lastName" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.lastName
                : stateData?.usersData[userData?.id]?.lastName
            }
            onChangeText={(lastName) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    lastName: lastName,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, lastName: lastName};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {errors.lastName && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.lastName}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={
            errors.email || errors.duplicateEmail || errors.invalidEmail
          }
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="email" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.email
                : stateData?.usersData[userData?.id]?.email
            }
            onBlur={() => {
              checkForDuplicateEmail(userData?.email);
            }}
            onChangeText={(email) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    email: email,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, email: email};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {(errors.duplicateEmail || errors.email || errors.invalidEmail) && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.duplicateEmail || errors.email || errors.invalidEmail}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={[styles.formElement,{display: isMasterAccountFlag ? 'none' : 'flex'}]}
          isRequired
          isInvalid={errors.role}
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="role" />
          </Label>

          <ModalActionAntSelect
            allowClear={true}
            showSearch={false}
            placeholder="Please select"
            onChange={(value: any) => {
              const selectedRole = userRoles.find(
                (role) => role.code === value
              );

              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    role: value,
                    roleName: selectedRole?.value,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {
                      ...user,
                      role: value,
                      roleName: selectedRole?.value,
                    };
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
            defaultValue={
              userData?.id === -1
                ? userData?.role
                : stateData?.usersData[userData?.id]?.role
            }
            selectedValue={
              userData?.id === -1
                ? userData?.role
                : stateData?.usersData[userData?.id]?.role
            }
            data={userRoles}
            optionProps={{key: 'id', value: 'code', label: 'value'}}
            extraStyle={{flex: 1}}
          />

          {errors.role && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.role}
            </ErrorMessage>
          )}
        </FormControl>

        {showEmployerSelection() && (
          <FormControl
            style={styles.formElement}
            isRequired
            isInvalid={errors.employerId}
          >
            <Label style={styles.labelText}>
              <DisplayText textLocalId="employer" />
            </Label>
            <Select
              // selectedValue={
              //   stateData?.usersData[userData?.id]?.employerId || ''
              // }
              selectedValue={
                userData?.id === -1
                  ? userData?.employerId
                  : stateData?.usersData[userData?.id]?.employerId || ''
              }
              onValueChange={(value) => {
                const employerName = employerList.filter((employer) => {
                  return employer?.id === value;
                });

                if (userData?.id === -1) {
                  setUserData((prev) => {
                    return {
                      ...prev,
                      employerId: value,
                      employerName: employerName[0]?.name,
                    };
                  });
                } else {
                  const updatedUserData = stateData?.usersData.map((user) => {
                    if (user.id === userData?.id) {
                      return {
                        ...user,
                        employerId: value,
                        employerName: employerName[0]?.name,
                      };
                    } else {
                      return user;
                    }
                  });
                  setStateData((prev) => {
                    return {
                      ...prev,
                      usersData: updatedUserData,
                    };
                  });
                }
              }}
            >
              {employerList.map((item) => {
                return (
                  <Select.Item
                    label={item.name}
                    value={item.id}
                    key={item.id}
                  />
                );
              })}
            </Select>

            {errors.employerId && (
              <ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {errors.employerId}
              </ErrorMessage>
            )}
          </FormControl>
        )}
        {isMasterAccountFlag && <>
          <AssociatedAccountView
          userData={userData}
          userRoles={userRoles}
          isDisabled={false}
          associatedAccounts={userData.associatedAccounts}
          updateAccountUserRoleList={(data: any)=> {
            setErrors((prev: any)=> {
              return {
                ...prev,
                accountRole: ''
              }
            })
            setUserData((prev)=> {
              return {
                ...prev,
                associatedAccounts: data
              }
            })
          }}
        />
         {errors.accountRole && (
          <Text fontSize={'xs'} color="error.500" fontWeight={500}>
            {errors.accountRole}
          </Text>
        )}</>}
      </View>
    );
  };
  const getUpdatedUserData = (userData: UserData) => {
    if (!isMasterAccountFlag) {
      const selectedRoleId = userRoles.find((role) => {
        return role.code === userData.role
      })
      const associatedAccounts = [
        {
          accountId: accountId as number,
          accountUuid: accountUuid,
          externalUserId: userData.externalUserId || '',
          rolesToAdd: selectedRoleId?.id ? [selectedRoleId?.id] : ['']
        }
      ] as IAccountRole[]
      return {...userData, associatedAccounts: associatedAccounts}
    }
    return userData
  }

//Removed Bulk Invite User Info

  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width={drawerWidth}
      title={
        <>
          <ModalActionTitle
            title={'inviteUser'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'sendInvitation',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: loading,

                onClick: async () => {
                  setLoading(true);
                  const isEmailDuplicate = await checkForDuplicateEmail(
                    userData?.email
                  );
                  const {isValid, errors, isEmpty} = validateForm(
                    userData,
                    isEmailDuplicate,
                    {isEmployerSelected: showEmployerSelection(),
                    isMasterAccount: isMasterAccountFlag}
                  );
                  if (stateData?.usersData?.length && isValid) {
                    const body = getPostRequestBody([
                      userData,
                      ...stateData?.usersData,
                    ]);
                    handleSubmit(body);
                  } else if (stateData?.usersData?.length === 0 && isValid) {
                    const updatedUserData: UserData = getUpdatedUserData(userData)
                    const body = getPostRequestBody([updatedUserData]);
                    handleSubmit(body);
                  } else if (stateData?.usersData?.length && isEmpty) {
                    const body = getPostRequestBody(stateData?.usersData);
                    handleSubmit(body);
                  } else {
                    setErrors(errors);
                    setLoading(false);
                  }
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack style={[styles.container]}>
      { loading ? ( <SkeletonLoader /> ) :
        (
          <>
            <View style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: Colors.FoldPixel.GRAY150,
              borderRadius: 12,
              padding: 16
              }}>{getInviteUserField(userData)}</View>
              {/* Removed bulk invite option */}
          </>
        )}
      </VStack>
    </Drawer>
  );
};

export default InviteUserDrawer;
