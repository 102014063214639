import { Drawer } from "antd"
import { Skeleton, VStack } from "native-base";
import { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { BUTTON_TYPE } from "../../../../constants/StringConst";
import { getHieDataReportByUrl } from "../../../../services/CommonService/AidBoxService";
import { downloadReport } from "../../../../services/CommonService/OrderService";
import { Colors } from "../../../../styles/Colors";
import { getEhrConfig } from "../../../../utils/capabilityUtils";
import { MIME_TYPES } from "../../../common/DocumentViewer/DocumentViewerHelper";
import Iframe from "../../../common/Iframe/Iframe";
import { ModalActionTitle } from "../../../common/ModalActionTitle/ModalActionTitle";
import { isValidHttpUrl } from "../../../RightSideContainer/Contacts/TeamMembers/EditAccount/EditAccountUtils";
import { SOURCE_TYPES } from "../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst";
import { getBase64 } from "../OtherDetails/PatientDocuments/UploadPatientDocument";
import { SUPPORTED_IMAGE_URLS } from "./RecentReport/ReportUtils";


export const MediaReportView = (props: any) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - 120;
  const [mediaReportDetail, setMediaReportDetail] =
    useState<any>({
      source: props.source,
      reportSource: props.reportSource,
      showReportPdf: props.showReportPdf,
      reportData: '',
      loading: false,
  });
  const ehrConfig = getEhrConfig()

  const getReport = async () => {
    if (props?.hieDataReportUrl) {
      const reportUrl = await getHieDataReportByUrl(props?.hieDataReportUrl);
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          source: reportUrl.data,
          loading: false
        };
      });
      return;
    }
    downloadReport(mediaReportDetail.source, mediaReportDetail.reportSource, false, (async response => {
      const file: any = {};
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          reportData: file.blobUrl,
          loading: false
        };
      });
    }), (error) => {
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          loading: false
        };
      });
    }, props?.reportId, ehrConfig)
  }

  const  getResourceTypeWiseView = (): JSX.Element => {
    // If isFetchMediaFromIntegration is true then directly return iframe element with reportData as source
    if (props?.reportSource === SOURCE_TYPES.HIE) {
      if (props?.htmlDataString && props?.isHtmlFormattedData) {
        return (<Iframe srcDoc={props.htmlDataString} height={finalHeight} width="100%" customStyle={{border: 0 }}></Iframe>)
      } else {
        return (<Iframe source={mediaReportDetail.source} height={finalHeight} width="100%" customStyle={{ border: 0 }}></Iframe>)
      }
    }
    if (props?.isFetchMediaFromIntegration) {
      return (<Iframe source={mediaReportDetail.reportData} height={finalHeight} width="100%" customStyle={{border: 0 }}></Iframe>)
    }
    if (!props?.sourceType || (props?.sourceType && [MIME_TYPES.DOC, MIME_TYPES.DOCX, MIME_TYPES.ODT, MIME_TYPES.PDF].includes(props.sourceType))) {
      return (
        <Iframe source={mediaReportDetail.source} height={finalHeight} width="100%" customStyle={{ border: 0 }}></Iframe>
      );
    } else if (mediaReportDetail.source && isValidHttpUrl(mediaReportDetail.source)) {
      return (<img src={mediaReportDetail.source} style={{maxHeight: finalHeight}} />);
    } else if (props?.sourceType && SUPPORTED_IMAGE_URLS.includes(props?.sourceType)) {
      return (<img src={`data:image/jpeg;base64,${mediaReportDetail.source}`} style={{maxHeight: finalHeight}} />);
    } else {
      return (<></>);
    }
  }

  useEffect(() => {
    if (props?.isFetchMediaFromIntegration || props?.hieDataReportUrl) {
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          loading: true
        };
      });
      getReport();
    }
  }, [])

  return (
    <Drawer
      visible={mediaReportDetail.showReportPdf}
      width={props?.isImaging ? '50%': '70%'}
      title={
      <ModalActionTitle
        title={props?.drawerTitle || 'Report'}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'back',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props.onClose();
            },
          },
        ]}
      />}
      onClose={() => {
        if (typeof props.onClose === 'function') {
          props?.onClose();
        }
      }}>
        {mediaReportDetail.loading && (
          <VStack>
            <Skeleton.Text my={3} />
            <Skeleton />
          </VStack>
        )}
        {!mediaReportDetail?.loading && (getResourceTypeWiseView())}
    </Drawer>
  )
}
