import {Modal} from 'antd';
import React from 'react';
import {IUpdateCostAlertProps} from './interfaces';
import {Button, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';

const UpdateCostAlert = (props: IUpdateCostAlertProps) => {
  const {isOpen, onClose, onConfirm, onCancel} = props;
  const intl = useIntl();
  return (
    <Modal
      title="Confirmation"
      open={isOpen}
      footer={null}
      destroyOnClose
      closable
      onCancel={onCancel}
      bodyStyle={{
        paddingTop: 12,
        zIndex: 9999999999,
      }}
    >
      <View>
        <Text size={'mdMedium'}>
          {intl.formatMessage({id: 'updateCostAlert'})}
        </Text>
        <Button.Group
          style={{marginTop: 12, flexDirection: 'column'}}
          justifyContent={'flex-end'}
          space={'2'}
        >
          <Button
            onPress={() => {
              onConfirm();
            }}
            style={{
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                color: 'white',
              }}
            >{`Yes, update cost for all old records`}</Text>
          </Button>

          <Button
            onPress={() => {
              onClose();
            }}
            style={{
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                color: 'white',
              }}
            >{`No, consider new cost going forward for new records`}</Text>
          </Button>

          <Button
            onPress={() => {
              onCancel();
            }}
            style={{
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                color: 'white',
              }}
            >{`Cancel`}</Text>
          </Button>
        </Button.Group>
      </View>
    </Modal>
  );
};

export default UpdateCostAlert;
