import { Colors } from '../../../styles/Colors';
import { ICommonSvgProps } from './interfaces';

const ArchivedIcon = (props: ICommonSvgProps) => {
    const {
        customStrokeColor,
    } = props;
    const bgColor = customStrokeColor || Colors.Custom.Gray500;

    return (
        <svg 
            width={props?.width || "16"} 
            height={props?.height || "17"}
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M13.6666 5.16667V9.16667C13.6666 11.6808 13.6666 12.9379 12.8855 13.719C12.1045 14.5 10.8474 14.5 8.33325 14.5H7.66659C5.15243 14.5 3.89535 14.5 3.1143 13.719C2.33325 12.9379 2.33325 11.6808 2.33325 9.16667V5.16667M6.99992 9.5H8.99992C9.31055 9.5 9.46586 9.5 9.58837 9.44925C9.75173 9.38159 9.88151 9.25181 9.94917 9.08846C9.99992 8.96594 9.99992 8.81063 9.99992 8.5C9.99992 8.18937 9.99992 8.03406 9.94917 7.91154C9.88151 7.74819 9.75173 7.61841 9.58837 7.55075C9.46586 7.5 9.31055 7.5 8.99992 7.5H6.99992C6.68929 7.5 6.53398 7.5 6.41146 7.55075C6.24811 7.61841 6.11833 7.74819 6.05067 7.91154C5.99992 8.03406 5.99992 8.18937 5.99992 8.5C5.99992 8.81063 5.99992 8.96594 6.05067 9.08846C6.11833 9.25181 6.24811 9.38159 6.41146 9.44925C6.53398 9.5 6.68929 9.5 6.99992 9.5ZM2.66659 5.16667H13.3333C13.9618 5.16667 14.2761 5.16667 14.4713 4.9714C14.6666 4.77614 14.6666 4.46187 14.6666 3.83333C14.6666 3.20479 14.6666 2.89052 14.4713 2.69526C14.2761 2.5 13.9618 2.5 13.3333 2.5H2.66659C2.03805 2.5 1.72378 2.5 1.52851 2.69526C1.33325 2.89052 1.33325 3.20479 1.33325 3.83333C1.33325 4.46187 1.33325 4.77614 1.52851 4.9714C1.72378 5.16667 2.03805 5.16667 2.66659 5.16667Z" 
                stroke={bgColor}
                stroke-linecap="round"
            />
        </svg>
    );
};

export default ArchivedIcon;
