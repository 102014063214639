//import {DatePicker} from 'antd';
import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntDatePickerProps,
} from 'antd'
import IMask from 'imask'
import moment from 'moment'

import {FormControl, HStack, View} from 'native-base';
import {useRef, useState} from 'react';
import {StyleSheet} from 'react-native';
import {DATE_FORMATS} from '../../../constants';
import {Colors} from '../../../styles';
import {getDateStrFromMomentObj} from '../../../utils/DateUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import './ModalActionStyle.css'
import { IDisplayText } from '../DisplayText/interface';
import { isPastDay } from '../../../utils/DateUtils';

interface IModalActionDatePicker {
  isRequired?: boolean;
  label?: string;
  isInvalid?: string | boolean | any;
  defaultValue?: any;
  format: string;
  placeholder?: string;
  errors?: string | boolean;
  errorsText?: string | boolean;
  onChange: (value: any, dateString: any) => void;
  customStyle?: any;
  extraStyle?: any;
  disabledDate?: any;
  value?: any;
  leftMargin?: any;
  changeBackground?: boolean;
  className?: string;
  showTime?: boolean;
  minuteStep?: number;
  allowClear?: boolean;
  onSelect?: (value: any) => void;
  disablePicker?: boolean;
  isHideOkButton?: boolean;
  disabledTime?: any;
  labelStyles?: IDisplayText['extraStyles'];
  isSidecarTaskLabelSelect?: boolean;
}

export type DatePickerProps = Omit<AntDatePickerProps, 'format' | 'picker' | 'onKeyDown'>

export const ModalActionDatePicker = (props: IModalActionDatePicker) => {
  const {
    isRequired,
    label,
    isInvalid,
    defaultValue,
    format,
    placeholder,
    errors,
    onChange,
    customStyle,
    extraStyle,
    disabledDate,
    value,
    errorsText,
    leftMargin,
    changeBackground,
    className,
    showTime,
    minuteStep,
    allowClear,
    onSelect,
    disablePicker,
    isHideOkButton,
    disabledTime,
    labelStyles,
    isSidecarTaskLabelSelect,
  } = props;

  const DATE_FORMAT = format;
  const MASKED = IMask.createMask({
    blocks: {
      DD: {from: 1, mask: IMask.MaskedRange, to: 31},
      MM: {from: 1, mask: IMask.MaskedRange, to: 12},
      YYYY: {from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE},
    },
    format: (date: Date) => moment(date).format(DATE_FORMAT),
    mask: Date,
    parse: (date: string) => moment(date, DATE_FORMAT),
    pattern: DATE_FORMAT,
  })

  const datePickerRef: any = useRef(null);
  const [text, setText] = useState('');

  const getWidth = () => {
    if (extraStyle?.flex || customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  return (
    <View flex={extraStyle?.flex ? extraStyle.flex : ''} w={extraStyle?.containerWidth || undefined}>
      <FormControl
        marginLeft={leftMargin ? parseInt(leftMargin, 10) : 0}
        marginBottom={customStyle?.marginBottom || 1}
        isRequired={isRequired}
        isInvalid={isInvalid}
      >
        {label && (
        <HStack marginBottom={isSidecarTaskLabelSelect? customStyle.marginBottom : 0}>
        <FormControl.Label marginLeft={0}>
          {label && (
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
                ...labelStyles
              }}
              textLocalId={label}
            />
          )}
        </FormControl.Label>
        </HStack>
        )}
        <View marginTop={customStyle?.marginTop ? customStyle.marginTop : 1} backgroundColor={changeBackground ? '' : ''}>
          <AntdDatePicker
            style={{
              height: customStyle?.heigh ? customStyle.heigh : 40,
              width: customStyle?.width ? customStyle.width : getWidth(),
              borderRadius: customStyle?.borderRadius
                ? customStyle.borderRadius
                : 8,
              borderColor: isInvalid
              ? Colors.error[500]
              : customStyle?.borderColor
              ? customStyle.borderColor
              : Colors.Custom.BorderColor,
              fontSize: 12,
            }}
            popupClassName={isHideOkButton ? 'hide-ok-button' : undefined}
            className={className}
            value={value}
            defaultValue={defaultValue}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            format={format}
            placeholder={placeholder}
            {...(showTime !== undefined ? {showTime: showTime} : undefined)}
            {...(allowClear !== undefined ? {allowClear: allowClear} : undefined)}
            {...(minuteStep !== undefined ? {minuteStep: minuteStep} : undefined)}
            status={isInvalid ? 'error' : ''}
            onChange={(value, dateString) => {
              onChange(value, dateString);
            }}
            onSelect={onSelect}
            ref={datePickerRef}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const input = event.target as HTMLInputElement
              input.value = MASKED.resolve(input.value)

              if (event.key === 'Tab') {
                if (!input.value.length) {
                  const currentDate = moment(new Date());
                  const dateStr = getDateStrFromMomentObj(
                    currentDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  );
                  onChange(currentDate, dateStr);
                } else {
                  onChange(moment(input.value), input.value);
                }
                datePickerRef?.current?.setOpen?.(false);
              }
            }}
            {...(disablePicker ? {disabled: true} : undefined)}
          />
        </View>
        {errors && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errorsText}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </View>
  );
};

const styles = StyleSheet.create({
  datePickerStyle: {},
});
