import {gql} from '@apollo/client';

export const GetListOfTimezone = gql`
  query GetListOfTimezone {
    timezones {
      timezone
      gmtOffset
      id
      abbreviations
      updatedAt
      uuid
    }
  }
`;

export const GET_WORKING_HOURS = gql`
  query GET_WORKING_HOURS($inboxId: bigint!) {
    workingHours(where: {inboxId: {_eq: $inboxId}}) {
      id
      uuid
      updatedAt
      open_all_day
      openMinutes
      openHour
      isDeleted
      inboxId
      dayOfWeek
      createdAt
      closedAllDay
      closeMinutes
      closeHour
      accountId
    }
  }
`;

export default {GetListOfTimezone,GET_WORKING_HOURS};
