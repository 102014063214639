import React, { useEffect, useState } from 'react';
import {Pressable, Text, View} from 'react-native';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles/Colors';
import LogTimeSvg from '../../../../common/Svg/LogTimeSvg';
import {useIntl} from 'react-intl';
import {Drawer, Skeleton} from 'antd';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {BUTTON_TYPE} from '../../../../../constants/StringConst';
import ManualTimeLoggingDrawer from './ManualTimeLoggingDrawer';
import Stopwatch from '../../../../common/Stopwatch/Stopwatch';
import Feather from 'react-native-vector-icons/Feather';
import UseTimeLogForBilling from '../../../../CustomHooks/UseTimeLogForBilling';
import { useToast } from 'native-base';
import { SAVE_RECORDED_TIME_FOR_BILLING } from '../../../../../services/CareProgram/CareProgramQueries';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import ProviderActivityAndOutreachComponent from '../../../CareManagementBilling/CareProgramBIllingComponent/ProviderOutreachAndActivity/ProviderOutreachAndActivity';


export interface ITimeLoggingComponentState {
  openManualTimeLogDrawer: boolean;
  recordedTime: { minutes: number; seconds: number};
  showLoggedTime: boolean;
  showManuallyTimeLoggingButton: boolean;
  openActivityDrawer: boolean;
}

const TimeLoggingComponent = (props: {contactUuid: string}) => {
  const intl = useIntl();
  const toast = useToast();
  const [timeLoggingComponentState, setTimeLoggingComponent] =
    useState<ITimeLoggingComponentState>({
      openManualTimeLogDrawer: false,
      recordedTime: {minutes: 0, seconds: 0},
      showLoggedTime: false,
      showManuallyTimeLoggingButton: true,
      openActivityDrawer: false,
    });
  const [loadingState, setLoadingState] = useState({
    loadingPreviousrecordedTime: false,
  });
  const {
    billableTimeInMinutes,
    contactCareProgramId,
    careProgramTaskTypeList,
    onTimeLogged,
    previousRecordedTime,
    timeLoggingComponentLoaders,
    loadingContactCareProgramId,
  } = UseTimeLogForBilling({
    contactId: props?.contactUuid,
  });

  useEffect(() => {
    if (previousRecordedTime) {
      setLoadingState((prev) => {
        return {
          ...prev,
          loadingPreviousrecordedTime: true,
        };
      });
      const isPreviouslyRecordedTime =
        previousRecordedTime.minutes > 0 || previousRecordedTime.seconds > 0;
      setTimeLoggingComponent((prev) => {
        return {
          ...prev,
          recordedTime: {
            minutes: previousRecordedTime.minutes,
            seconds: previousRecordedTime.seconds,
          },
          showLoggedTime: isPreviouslyRecordedTime,
          showManuallyTimeLoggingButton: !isPreviouslyRecordedTime,
        };
      });
      setLoadingState((prev) => {
        return {
          ...prev,
          loadingPreviousrecordedTime: false,
        };
      });
    }
  }, [previousRecordedTime]);

  const [saveRecordedTime] = useMutation(SAVE_RECORDED_TIME_FOR_BILLING, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onError: () => {
      showToast(toast, 'Error in saving recorded time', ToastType.error, 4000);
    },
  });

  const saveRecordedSeconds = (minutes?: number, seconds?: number) => {
    if (contactCareProgramId) {
      saveRecordedTime({
        variables: {
          params: {
            contactCareProgramId: contactCareProgramId,
            timerNoOfSeconds:
              (minutes || timeLoggingComponentState.recordedTime.minutes) * 60 +
              (seconds || timeLoggingComponentState.recordedTime.seconds),
          },
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      saveRecordedSeconds();
    };
  }, []);

  const onStopwatchStop = (minutes: number, seconds: number) => {
    saveRecordedSeconds(minutes, seconds );
    setTimeLoggingComponent((prev) => {
      return {
        ...prev,
        recordedTime: {
          minutes: minutes,
          seconds: seconds,
        },
        showLoggedTime: true,
      };
    });
  };

  const onCloseOfAvtivityDrawer = () => {
    setTimeLoggingComponent((prev) => {
      return {
        ...prev,
        openActivityDrawer: false,
      };
    });
  };

  const showLoggedTimeElement = (
    <Stack
      style={{
        borderColor: Colors.Custom.PrimaryColor200,
        backgroundColor: Colors.FoldPixel.PRIMARY100,
        borderWidth: 0.5,
        padding: 8,
        alignItems: 'center',
        borderRadius: 4,
        width: 'fit-content',
      }}
      space={4}
      direction={'row'}
    >
      <Pressable
        onPress={() => {
          setTimeLoggingComponent((prev) => {
            return {
              ...prev,
              openManualTimeLogDrawer: true,
            };
          });
        }}
        style={{flexDirection: 'row'}}
      >
        <LogTimeSvg />
        <Text style={{color: Colors.FoldPixel.PRIMARY300, marginHorizontal: 3}}>
          {timeLoggingComponentState.recordedTime.minutes
            .toString()
            .padStart(2, '0')}
          :
          {timeLoggingComponentState.recordedTime.seconds
            .toString()
            .padStart(2, '0')}
          •
        </Text>
        <Text
          style={{
            fontWeight: '500',
            fontSize: 14,
            color: Colors.FoldPixel.PRIMARY300,
          }}
        >
          {intl.formatMessage({id: 'logTime'})}
        </Text>
      </Pressable>
      <Feather
        onPress={() => {
          setTimeLoggingComponent((prev) => {
            return {
              ...prev,
              recordedTime: {
                minutes: 0,
                seconds: 0,
              },
              showLoggedTime: false,
              showManuallyTimeLoggingButton: true,
            };
          });
        }}
        name="x"
        size={18}
        color={Colors.FoldPixel.PRIMARY300}
      />
    </Stack>
  );

  const renderComponent = (
    <Stack style={{padding: 8}} direction={'column'} space={8}>
      <Text
        style={{
          fontWeight: '400',
          fontSize: 14,
          color: Colors.FoldPixel.GRAY400,
        }}
      >
        {intl.formatMessage({id: 'activityTimer'})}
      </Text>
      {billableTimeInMinutes > 0 && (
        <Stack direction={'row'} space={5}>
          <Pressable
            onPress={() => {
              setTimeLoggingComponent((prev) => {
                return {
                  ...prev,
                  openActivityDrawer: true,
                };
              });
            }}
          >
            <Text
              style={{
                fontWeight: '400',
                fontSize: 14,
                color: Colors.FoldPixel.PRIMARY300,
              }}
            >
              Billable {billableTimeInMinutes} mins
            </Text>
          </Pressable>
        </Stack>
      )}
      <Stack direction={'row'} space={5}>
        {timeLoggingComponentState.showLoggedTime ? (
          showLoggedTimeElement
        ) : (
          <Stopwatch
            onStop={onStopwatchStop}
            onStart={() => {
              setTimeLoggingComponent((prev) => {
                return {
                  ...prev,
                  showManuallyTimeLoggingButton: false,
                };
              });
            }}
          />
        )}
        {timeLoggingComponentState.showManuallyTimeLoggingButton && (
          <Pressable
            onPress={() => {
              setTimeLoggingComponent((prev) => {
                return {
                  ...prev,
                  openManualTimeLogDrawer: true,
                };
              });
            }}
          >
            <Stack
              style={{
                borderColor: Colors.FoldPixel.GRAY200,
                borderWidth: 0.5,
                padding: 8,
                alignItems: 'center',
                borderRadius: 4,
                width: 'fit-content',
              }}
              space={4}
              direction={'row'}
            >
              <LogTimeSvg />
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: 14,
                  color: Colors.FoldPixel.GRAY300,
                }}
              >
                {intl.formatMessage({id: 'logTimeManually'})}
              </Text>
            </Stack>
          </Pressable>
        )}
      </Stack>
    </Stack>
  );

  const loadingComponent = timeLoggingComponentLoaders.billableMinutesLoading ||
        timeLoggingComponentLoaders.previousRecordedTimeLoading ||
        loadingState.loadingPreviousrecordedTime ||
        loadingContactCareProgramId

  return !loadingComponent && !contactCareProgramId ? (
    <></>
  ) : (
    <>
      <View
        style={[
          {
            backgroundColor: Colors.Custom.ContainerBGColor,
            borderRadius: 8,
            shadowColor: 'rgba(16, 24, 40, 0.06)',
            shadowOffset: {width: 1, height: 2},
            shadowRadius: 0,
            paddingHorizontal: 4,
            paddingVertical: 8,
            marginTop: 12,
          },
        ]}
      >
        {loadingComponent ? <Skeleton active /> : renderComponent}
      </View>
      {timeLoggingComponentState?.openManualTimeLogDrawer && (
        <ManualTimeLoggingDrawer
          onClose={(
            isSave?: boolean,
            totalBillingMinutes?: number,
            timerNoOfSeconds?: number
          ) => {
            setTimeLoggingComponent((prev) => {
              return {
                ...prev,
                openManualTimeLogDrawer: false,
              };
            });
            if (
              isSave &&
              totalBillingMinutes !== undefined &&
              timerNoOfSeconds !== undefined
            ) {
              setTimeLoggingComponent((prev) => {
                const isTimeRemaining = timerNoOfSeconds > 0;
                return {
                  ...prev,
                  recordedTime: {
                    minutes: Math.floor(timerNoOfSeconds / 60),
                    seconds: timerNoOfSeconds % 60,
                  },
                  showLoggedTime: isTimeRemaining,
                  showManuallyTimeLoggingButton: !isTimeRemaining,
                };
              });
              onTimeLogged(totalBillingMinutes);
            }
          }}
          recordedTime={timeLoggingComponentState.recordedTime}
          contactCareProgramId={contactCareProgramId}
        />
      )}
      {timeLoggingComponentState?.openActivityDrawer && (
        <Drawer
          destroyOnClose
          placement="right"
          open={true}
          width={'30%'}
          onClose={onCloseOfAvtivityDrawer}
          closable
          title={
            <ModalActionTitle
              title={'ccmActivity'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onCloseOfAvtivityDrawer,
                },
              ]}
            />
          }
        >
          <ProviderActivityAndOutreachComponent
            showActivityFilter={true}
            contactCareProgramId={contactCareProgramId}
            isTimeTrackingView={true}
          />
        </Drawer>
      )}
    </>
  );
};

export default TimeLoggingComponent;
