import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles/Colors';

export const styles = StyleSheet.create({
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
  mainMenuContent: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    cursor: 'pointer',
  },
  lastMenu: {
    paddingBottom: 15,
  },
  firstMenu: {
    paddingTop: 10,
  },
  mainMenuTxt: {
    marginLeft: 10
  },
  icons: {
    marginRight: 5,
    fontSize: 10,
    color: Colors.Custom.IconColorWhite,
  },
  btnText: {
    fontSize: 12,
    color: Colors.Custom.FontColorWhite,
    marginLeft: 10,
  },
  actionBtns: {
    height: 30,
    borderRadius: 15,
    color: Colors.Custom.IconColorWhite,
  },
  dividerStyle: {
    width: '100%',
    color: '#E4E7EC',
    backgroundColor: '#E4E7EC'
  },
  actionIcons: {
    color: Colors.Custom.SuccessColor,
    marginTop: 0,
    marginRight: 0,
    position: 'absolute',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    bottom: 10,
    left: 14
  },
  preferredIcon: {
    height: 12,
    width: 12,
    marginTop: 0,
    marginRight: 0,
    position: 'absolute',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    bottom: 12,
    left: 16
  },
});


