import {ATTACHMENT_TYPE, CONTENT_TYPE} from '../../../../../../constants';
import React from 'react';
import {default as FeatherIcon} from 'react-native-vector-icons/Feather';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {IConversationData} from '../../interfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { HStack } from 'native-base';
import { Colors } from '../../../../../../styles';

export const AttachmentMsgContentElem = (props: {
  contentType: string;
  singleConversationData?: IConversationData;
  iconSize?: number;
}): JSX.Element => {
  let attachmentContentElem = <></>;
  const {contentType, singleConversationData, iconSize} = props;

  if (contentType.includes(ATTACHMENT_TYPE.AUDIO)) {
    attachmentContentElem = (
      <HStack space={0.5} alignContent={'center'}>
        <FeatherIcon
          name="headphones"
          size={iconSize || 18}
          style={{
            marginRight: 4,
          }}
        />
        {singleConversationData ? (
          <DisplayText
            textLocalId="audioMessage"
            size={
              !isWeb()
                ? 'sm'
                : singleConversationData?.unreadMsgCount
                ? 'smSemibold'
                : 'smLight'
            }
          />
        ) : null}
      </HStack>
    );
  } else if (contentType.includes(ATTACHMENT_TYPE.IMAGE)) {
    attachmentContentElem = (
      <HStack space={0.5} alignContent={'center'}>
        <FeatherIcon
          name="image"
          size={iconSize || 18}
          style={{
            marginTop:1
          }}
          color={Colors.Custom.Gray600}
        />
        {singleConversationData ? (
          <DisplayText
            textLocalId="pictureMessage"
            size={
              !isWeb()
                ? 'sm'
                : 'smLight'
            }
            extraStyles={{color:Colors.Custom.Gray600,}}
          />
        ) : null}
      </HStack>
    );
  } else if (contentType.includes(CONTENT_TYPE.PDF)) {
    attachmentContentElem = (
      <HStack space={0.5} alignContent={'center'}>
      <AntIcon
          name="pdffile1"
          size={iconSize || 18}
          style={{
            marginRight: 4,
          }}
        />
        {singleConversationData ? (
          <DisplayText
            textLocalId="pdfMessage"
            size={
              !isWeb()
                ? 'sm'
                : singleConversationData?.unreadMsgCount
                ? 'smSemibold'
                : 'smLight'
            }
          />
        ) : null}
      </HStack>
    );
  } else if (contentType.includes(ATTACHMENT_TYPE.VIDEO)) {
    attachmentContentElem = (
      <HStack space={0.5} alignContent={'center'}>
      <FeatherIcon
          name="video"
          size={iconSize || 18}
          style={{
            marginRight: 4,
          }}
        />
        {singleConversationData ? (
          <DisplayText
            textLocalId="videoMessage"
            size={
              !isWeb()
                ? 'sm'
                : singleConversationData?.unreadMsgCount
                ? 'smSemibold'
                : 'smLight'
            }
          />
        ) : null}
      </HStack>
    );
  } else {
    attachmentContentElem = (
      <HStack space={0.5} alignContent={'center'}>
      <AntIcon
          name="file1"
          size={iconSize || 18}
          style={{
            marginRight: 4,
          }}
        />
        {singleConversationData ? (
          <DisplayText
            textLocalId="fileMessage"
            size={
              !isWeb()
                ? 'sm'
                : singleConversationData?.unreadMsgCount
                ? 'smSemibold'
                : 'smLight'
            }
          />
        ) : null}
      </HStack>
    );
  }
  return attachmentContentElem;
};
