import {Collapse, Divider} from 'antd';
import {cloneDeep} from 'lodash';
import {HStack, ScrollView, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import FHCustomAntTab from '../../../../common/CustomAntTab/FHCustomAntTab';
import FHCustomSwitch from '../../../../common/CustomSwtich/FHCustomSwitch';
import {SearchBar} from '../../../../common/SearchBar';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import LibNodeCard from '../../../Workflow/LibNodeCard/LibNodeCard';
import {IBuilderGroups, IFormBuilderSideBarProps} from './intefaces';

const FormBuilderSideBar = (props: IFormBuilderSideBarProps) => {
  const {groups, onFilterChange} = props;
  const [selectedTab, setSelectedTab] = useState('healthComponents');
  const originalGroups = groups;
  const [filteredGroups, setFilteredGroups] =
    useState<IBuilderGroups[]>(originalGroups);

  const handleSearchTextChange = (text: string) => {
    const copiedGroups = cloneDeep(originalGroups);
    copiedGroups.forEach((group) => {
      group.components = group.components.filter((group) => {
        return group.title.toLowerCase().includes(text.toLowerCase());
      });
    });
    // setFilteredGroups(
    //   copiedGroups.filter((group) => group.components.length > 0)
    // );
    setFilteredGroups(copiedGroups);
    if (onFilterChange) onFilterChange();
  };
  const intl = useIntl();
  return (
    <VStack backgroundColor="white" space={4}>
      <View m={0}>
        <FHCustomAntTab
          containerStyle={{paddingHorizontal: 0}}
          tabBarExtraContent={{left: <View width={3} />}}
          tabsArray={
            originalGroups.length > 0
              ? originalGroups.map((originalGroupItem: any) => ({
                  text: originalGroupItem.title,
                  code: originalGroupItem.key,
                  shouldShowInfoIcon: originalGroupItem.key === 'healthComponents',
                  toolTipMessage: originalGroupItem.key === 'healthComponents' ? 'healthComponentsInfo': undefined
                }))
              : []
          }
          onTabSelect={(code: string) => {
            setSelectedTab(code);
          }}
          selectedCode={'healthComponents'}
        />
      </View>

      <View paddingX={2}>
        <View marginBottom={2}>
          <SearchBar
            onChange={(value: string) => handleSearchTextChange(value || '')}
          />
        </View>
        <View style={{maxHeight:'65vh'}}>
          <ScrollView backgroundColor="white" contentContainerStyle={{paddingBottom:60}}>
            {filteredGroups.length > 0 && (
              <Collapse
                className="ant-collapse-custom"
                defaultActiveKey={filteredGroups.map((group) => group.key)}
              >
                {filteredGroups.map((group) => {
                  return group.key === selectedTab ? (
                    <div className="fh-form-sidebar-container">
                      {group.components.length > 0 ? (
                        group.components.map((item, index) => {
                          return (
                            <div
                              className="drag-copy"
                              data-group={group.key}
                              data-key={item.key}
                              data-type={item.type}
                              key={index + group.key + 'view'}
                              style={{marginBottom: '10px'}}
                            >
                              <View
                                paddingTop={index === 0 ? 2 : 1}
                                paddingBottom={
                                  index === group.components.length - 1 ? 2 : 1
                                }
                                style={{
                                  // borderBottomColor: Colors.Custom.Gray100,
                                  // borderBottomWidth: 2,
                                  borderColor: Colors.Custom.Gray100,
                                  borderWidth: 2,
                                  borderRadius: 16,
                                }}
                              >
                                <LibNodeCard
                                  title={item.title}
                                  iconInfo={{lib: item.iconLib, icon: item.icon}}
                                />
                              </View>
                            </div>
                          );
                        })
                      ) : (
                        <View flex={1}>
                          <Text>
                            {intl.formatMessage({id: 'noComponentsAvailable'})}
                          </Text>
                        </View>
                      )}
                    </div>
                  ) : null;
                })}
              </Collapse>
            )}
            {filteredGroups.length === 0 && (
              <View flex={1}>
                <Text>No matching components available</Text>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </VStack>
  );
};

export default FormBuilderSideBar;
