import { useLazyQuery, useQuery } from '@apollo/client';
import { Divider, FlatList, HStack, Pressable, ScrollView, Skeleton, Spinner, Text, useToast, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { ITimelineViewProps } from '..';
import { CARESTUDIO_APOLLO_CONTEXT, FEED_SERVICE_APOLLO_CONTEXT } from '../../../../constants/Configs';
import {
  getOrganizations,
  getPractitioners,
} from '../../../../services/CommonService/AidBoxService';
import FeedsQueries from '../../../../services/Feeds/FeedsQueries';
import { getAccountUUID, getUserUUID, isNewCareTimeLineViewEnabled } from '../../../../utils/commonUtils';
import { NoActivitySvg } from '../../../common/Svg';
import { DATE_RANGE_FILTER, FEED_RESOURCE_TYPE, TIME_LINE_SOURCE_TYPES } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import {
  getEncounterOrgMap,
  getGroupByEncounter,
  getOrganizationsData,
  getPractitionerAppointmentMap,
  getSortedFhrData,
  sortSubListMap,
} from '../FhirDataProcessing';
import { getFormattedPractitionersData } from '../MiddleContainerUtils';
import { OrganizationData } from './interfaces';
import { styles } from './TimelineViewStyles';
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Collapse, Radio, Space, Tooltip } from 'antd';
import { Colors } from '../../../../styles';
import { getPanelHeaderText } from '../../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer/FhirCollapse/FhirCollapse';
import { EHR, FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { formatFeedResponse, getTimelineItemCustomTitle } from './TimelineViewHelper';
import { getEhrConfig, getResourceAbilities } from '../../../../utils/capabilityUtils';
import CarePlanTimeline from '../PatientNotes/CarePlanTimeline/CarePlanTimeline';
import { GET_USERS_FOR_CALENDAR_WITH_LOCATION_AND_CONTACT_DETAILS } from '../../../../services/User/UserQueries';
import { getEhrWiseCareTimeCategory, getEhrWiseNoteDefaultConfig, getUserDetailByUserUuid } from '../CareTimeline/CareTimelineUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { VisitSummary } from '../../LeftContainer/RecentActivity/VisitSummary/VisitSummary';
import { tooltipInnerOverlayStyle, tooltipOverlayStyle, tooltipPlacement } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { useIntl } from 'react-intl';
import { TIMELINE_RESOURCES, careTimelineTabList } from '../PatientNotes/constants';
import { CarePlanTopBarView } from '../../../common/MemebersView/CarePlanTopBarView';
import { SearchBar } from '../../../common/SearchBar';
import { SingleTimeline } from '../SingleTimeline';
import { StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import LoadMoreIconSvg from '../../../common/Svg/TimelineSvg/LoadMoreIconSvg';
import { debounce } from 'lodash';
import EmptyTimlineFilterIconSvg from '../../../common/Svg/TimelineSvg/EmptyTimlineFilterIconSvg';
import { AppointmentQueries } from '../../../../services';
import { isUUID } from '../../../common/WebinarSearch/Utils';
import CrossIconSvg from '../../../common/Svg/CrossIconSvg';
import SearchIconSvg from '../../../common/Svg/TimelineSvg/SearchIconSvg';
import Stack from '../../../common/LayoutComponents/Stack';

const TimelineView = (props: ITimelineViewProps) => {
  const ehrConfig = getEhrConfig();
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  );
  const initialPagination = { timelineLimit: 50, offset: 0, loadMore: false }
  const [showSearchBar, setShowSearchBar] = useState(false)

  const [showEmptyFilterView, setShowEmptyFilterView] = useState(false)
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timelineState, setTimelineState] = useState({
    practitionerData: [] as { id: string; name: string }[],
    organizationData: [] as OrganizationData[],
    accountUserList: []
  });

  const [filterParams, setFilterParams] = useState({
    selectedTimelineFilter: careTimelineTabList[0].categoryCode,
    searchTags: '',
    pagination: initialPagination
  });
  const { height } = useWindowDimensions();
  const intl = useIntl()
  const userId = getUserUUID();
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;
  const commonData = useContext(CommonDataContext);

  const isCareTimeLineViewEnabled = isNewCareTimeLineViewEnabled(commonData.userSettings);
  const [showOldView, setShowShowOldView] = useState(!isCareTimeLineViewEnabled);

  const accountUUID = getAccountUUID();
  const ehrCapabilities = commonData.ehrCapabilities || [];
  const toast = useToast();
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const CARE_ACTIVITY_FEED = 'CARE_ACTIVITY_FEED';
  const DISPLAY_TYPE = [
    FHIR_RESOURCE.MEDICATION_STATEMENT,
    FHIR_RESOURCE.MEDICATION_REQUEST,
    FHIR_RESOURCE.ALLERGY,
    FHIR_RESOURCE.APPOINTMENT,
    FHIR_RESOURCE.CONDITION,
    FHIR_RESOURCE.DIAGNOSTIC_REPORT,
    FHIR_RESOURCE.IMMUNIZATION,
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  ]

  const PAMI_DISPLAY_TYPE = [
    FHIR_RESOURCE.CONDITION,
    FHIR_RESOURCE.ALLERGY,
    FHIR_RESOURCE.MEDICATION_STATEMENT,
    FHIR_RESOURCE.MEDICATION_REQUEST,
    FHIR_RESOURCE.IMMUNIZATION
  ]

  const [componentState, setComponentState] = useState<{
    activePanel: string[];
  }>({
    activePanel: [CARE_ACTIVITY_FEED],
  });
  const { Panel } = Collapse;
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const [timeSourceValue, setTimeSourceValue] = useState<string[]>([])
  const [dateFilterValue, setDateFilterValue] = useState(DATE_RANGE_FILTER.ALL_TIME)

  const [getAppointmentIdsFromExternalIds] = useLazyQuery(AppointmentQueries.GET_APPOINTMENTS_BY_EXTERNAL_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT }
  })

  const [getTimelineData, { error }] = useLazyQuery(FeedsQueries.GET_FEEDS, {
    fetchPolicy: 'no-cache',
    context: {
      service: FEED_SERVICE_APOLLO_CONTEXT,
      headers: {},
    },
    variables: {
      ...(!showOldView && {
        limit: filterParams.pagination?.timelineLimit,
        offset: filterParams.pagination?.offset,
      }),
      feedDataWhereCondition: {
        isDeleted: { _eq: false },
        _and: [
          {
            _or: [
              {
                resourceType: { _neq: FEED_RESOURCE_TYPE.LOGIN_ACTIVITY },
              },
              {
                resourceType: { _is_null: true },
              },
            ],
          },
          {
            _or: [
              {
                patient_id: {
                  _eq: props?.personData?.patientUuid,
                },
              },
              {
                patient_id: {
                  _eq: props?.personData?.contactUUID,
                },
              },
            ],
          },
          {
            ...(timeSourceValue.length > 0) && {
              source: {
                _in: timeSourceValue
              }
            }
          },
          {
            ...(showSearchBar && filterParams.searchTags.length > 0) && {
              search_tags: {
                _ilike: `%${filterParams.searchTags}%`
              }
            }
          },
          {
            ...(dateFilterValue && dateFilterValue !== DATE_RANGE_FILTER.ALL_TIME) && {
              time_stamp: {
                _gte: dateFilterValue
              }
            }
          }
        ],
        categoryId: {
          _is_null: true,
        },
        display_type: {
          _in: filterParams.selectedTimelineFilter === 'PAMI' ? PAMI_DISPLAY_TYPE : DISPLAY_TYPE
        },
        ...(filterParams.selectedTimelineFilter !== 'All' && filterParams.selectedTimelineFilter !== 'PAMI') && {
          display_type: {
            _eq: filterParams.selectedTimelineFilter
          }
        }
      },
    },
  });

  useEffect(() => {
    if (filterParams.pagination.offset === 0) {
      setFilterLoading(true);
    } else if (filterParams.pagination.loadMore) {
      setShowMoreLoading(true);
    }
    callGetTimelineData();
  }, [
    timeSourceValue,
    dateFilterValue,
    filterParams.searchTags,
    filterParams.pagination,
  ]);


  const fetchAppointmentIds = (filteredAppointments: any[], currentFhirFeedData: any[]) => {
    const appointmentIds = filteredAppointments?.map(
      (feedData) => feedData?.resourceData.id ? `${feedData?.resourceData.id}` : undefined
    );
    const externalAppointmentIds: string[] = [];
    appointmentIds.forEach((item) => {
      if (item) {
        externalAppointmentIds.push(item)
      }
    });

    const foldAppointmentIds : string[] = [];
    externalAppointmentIds.forEach((id) => {
      if (isUUID(id)) {
        foldAppointmentIds.push(id);
      }
    });

    getAppointmentIdsFromExternalIds({
      variables: {externalAppointmentIds, foldAppointmentIds},
    })
      .then((response) => {
        if(response?.data?.appointments){
          const updatedFhirData = currentFhirFeedData?.map((fhirDataObj: any) => {
            const appointmentData = response?.data?.appointments?.find(
              (appointment: any) =>
                appointment.externalAppointmentId ===
                  fhirDataObj.resourceData?.id ||
                appointment.id === fhirDataObj.resourceData?.id
            );
            if (
              appointmentData?.id
            ) {
              fhirDataObj.resourceData.appointmentId = appointmentData?.id;
              const participantUserId = appointmentData?.participants?.[0]?.userId;
              const accountUser = timelineState.accountUserList?.find((accountUser: any)=> accountUser?.uuid === participantUserId) as any;
              fhirDataObj.resourceData.username = accountUser?.name;
            }
            return fhirDataObj;
          });

          setFhirFeedData(updatedFhirData);
        }
      })
      .catch((error) => {

      });
  }

  const callGetTimelineData = () => {
    getTimelineData().then((response) => {
      if (response.data?.feed_data) {
        if (
          response.data?.feed_data?.length <
          filterParams.pagination.timelineLimit
        ) {
          setShowLoadMoreButton(false);
        } else {
          setShowLoadMoreButton(true);
        }

        const formattedFeedResponse = formatFeedResponse(response.data?.feed_data);
        const updatedFhirData = filterParams.pagination?.loadMore
          ? [...fhirFeedData, ...formattedFeedResponse]
          : formattedFeedResponse;
        setFhirFeedData(updatedFhirData);

        const filteredAppointments = updatedFhirData.filter(
          (fhirData: any) =>
            fhirData.resourceType === TIMELINE_RESOURCES.APPOINTMENT && fhirData.resourceData.id
        );

        if(filteredAppointments?.length > 0){
          fetchAppointmentIds(filteredAppointments, updatedFhirData)
        }
      }

      setShowMoreLoading (false);
      setFilterLoading(false);
      setLoading(false);
    })
      .catch((error) => {
        setShowMoreLoading(false)
        setFilterLoading(false)
      })
  }

  const [getAccountUserLocation] = useLazyQuery(
    GET_USERS_FOR_CALENDAR_WITH_LOCATION_AND_CONTACT_DETAILS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (data: any) => {

        const accountUserList = data?.users || [];
        const currentUserData = getUserDetailByUserUuid(data?.users || [], userId);
        const noteConfig = {
          flag: getEhrWiseNoteDefaultConfig(ehrConfig, {
            foldVisitNoteWithEncountersEnabled,
            contextData: commonData
          }, currentUserData?.externalUserId),
        };
        const timelineCategories = getEhrWiseCareTimeCategory(ehrConfig);

        setTimelineState(prev => {
          return {
            ...prev,
            accountUserList,
            ehrConfig,
            ehrCapabilities,
            currentUserData,
            noteConfig,
            timelineCategories,
          }
        });
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        showToast(toast, 'Facing issue while getting user detail. Please check and try again.', ToastType.error);
      },
    }
  );

  const [fhirFeedData, setFhirFeedData] = useState<any[]>([])

  useEffect(() => {
    setLoading(true);
    getAccountUserLocation({
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
  }, [])

  const jsonData = fhirFeedData.map((item: any) => {
    return typeof item.data === 'string' ? JSON.parse(item.data) : item.data;
  });

  const orgIds: string[] = [];
  jsonData?.forEach((item: any) => {
    let orgId = '';
    if (item.resourceType === 'Encounter' && item.serviceProvider) {
      if (item.serviceProvider?.reference) {
        orgId = item.serviceProvider.reference?.split('|').pop();
        orgIds.push(orgId);
      }
      if (item.serviceProvider?.identifier) {
        orgId = item.serviceProvider?.identifier?.value;
        orgIds.push(orgId);
      }
    }
  });

  const encounterData = jsonData?.filter(
    (item: any) => item.resourceType === 'Encounter'
  );

  const appointmentData = jsonData.filter(
    (item: any) => item.resourceType === 'Appointment'
  );

  const encounterOrgMap = getEncounterOrgMap(
    encounterData,
    timelineState.organizationData
  );

  const practitionerAppointmentMap = getPractitionerAppointmentMap({
    appointmentData: appointmentData,
    practitionerData: timelineState.practitionerData,
  });

  const sortedFhirData = getSortedFhrData(fhirFeedData);
  const groupByEncounterList = getGroupByEncounter(sortedFhirData);
  const finalFhirData = sortSubListMap(groupByEncounterList);
  finalFhirData.forEach((item) => {
    const mainRecordData = JSON.parse(item.mainRecord.data);
    const recordId = mainRecordData.id;
    item.mainRecord.orgData = encounterOrgMap[recordId];
    item.mainRecord.practitionerData = practitionerAppointmentMap[recordId];
    item.mainRecord.customTitle = getTimelineItemCustomTitle(mainRecordData, {
      ehrConfig,
      categories: resourceAbilities?.allowedCategories,
    })
  });

  const filteredFhirList = finalFhirData.filter(
    (item) =>
      getPanelHeaderText(item.mainRecord, item.mainRecord.orgData) !== ''
  );

  // group by date
  const groupByDate = filteredFhirList.reduce((acc, object) => {
    const date = object.mainRecord.time_stamp;
    const key = new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(object);
    return acc;
  }, {});

  useEffect(() => {
    getOrganizations()
      .then((response) => {
        const data = getOrganizationsData(response.data);
        setTimelineState((prev) => {
          return {
            ...prev,
            organizationData: data,
          };
        });
      })
      .catch((error) => {

      });
    getPractitioners({
      successHandler(response) {
        const practitionerData = getFormattedPractitionersData(response.data);
        setTimelineState((prev) => {
          return {
            ...prev,
            practitionerData: practitionerData,
          };
        });
      },
      errorHandler(error) {

      },
    });
  }, []);

  const timeLineAvailableTypes = (
    Object.keys(TIME_LINE_SOURCE_TYPES) as Array<keyof typeof TIME_LINE_SOURCE_TYPES>
  ).map((key) => ({
    label: key,
    value: key,
    key: key
  }))

  const onSearch = (searchString: string) => {
    setFilterParams((prev)=> ({
      ...prev,
      searchTags: searchString,
      pagination: initialPagination
    }))
  };

  const LoadMoreButton = showOldView ? null : (
    <Stack direction="column">
      {!showLoadMoreButton ?
       <Text color={Colors.FoldPixel.GRAY300} alignSelf={'center'} fontSize={14}>{"No More Activity"}</Text> :
       <TouchableOpacity
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: 4,
          alignItems: 'center',
          alignSelf: 'center',
          backgroundColor: '#F5F0FF',
          paddingVertical: 6,
          paddingHorizontal: 8,
          position: 'absolute',
          bottom: -30,
        }}
        onPress={() => {
          setFilterParams((prev) => ({
            ...prev,
            pagination: {
              offset: prev.pagination.offset + prev.pagination.timelineLimit,
              timelineLimit: 20,
              loadMore: true,
            },
          }));
        }}
      >
      <Text
        fontSize={14}
        lineHeight={21}
        fontWeight={500}
        fontFamily={'Manrope'}
        color={Colors.Custom.PurpleColor}
      >
        Load More
      </Text>
      {!showMoreLoading && <LoadMoreIconSvg />}
      {showMoreLoading && <Spinner style={{marginLeft: 5}} />}
      </TouchableOpacity>
      }
    </Stack>
  );

  return (
    <View style={styles.container} width={'100%'} paddingRight={0} height={'89%'}>
      {loading ? (
        <View>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <VStack style={{ paddingTop: 10, justifyContent: 'center' }} >
          {showOldView ?
            <>{Object.keys(groupByDate).map((value, index) => {
              const list = groupByDate[value];
              return (
                <>
                  <HStack
                    key={index}
                    style={{
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        lineHeight: 24,
                        fontWeight: '500',
                        color: 'black',
                        paddingTop: 8,
                        paddingHorizontal: 16,
                        marginBottom: 17,
                        marginTop: 5,
                      }}
                    >
                      {value}
                    </Text>
                  </HStack>

                  {list.map((singleTimeline: any, index: number) => {
                    return (
                      <>
                        <SingleTimeline
                          timelineDate={singleTimeline.time_stamp}
                          timelineTitleElem={
                            <Text>{singleTimeline.display_type}</Text>
                          }
                          timelineType={singleTimeline.display_type}
                          key={index}
                          fhirData={singleTimeline}
                          displayType={singleTimeline?.display_type}
                        />
                      </>
                    );
                  })}
                </>
              );
            })}
            {LoadMoreButton}
            </>
            :
            <Collapse
              activeKey={componentState.activePanel}
              expandIconPosition="end"
              expandIcon={({ isActive }) => {
                const tooltipText = isActive ? intl.formatMessage({ id: 'collapse' }) : intl.formatMessage({ id: 'expand' });
                return (
                  <Tooltip
                    title={tooltipText}
                    placement={tooltipPlacement}
                    showArrow={false}
                    overlayStyle={tooltipOverlayStyle}
                    overlayInnerStyle={tooltipInnerOverlayStyle}>
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  </Tooltip>
                )
              }}
              onChange={(activeKeys) => {
                if (componentState.activePanel.length === 0) {
                  setComponentState((prev) => ({ ...prev, activePanel: [CARE_ACTIVITY_FEED] }));
                } else {
                  setComponentState((prev) => ({ ...prev, activePanel: activeKeys as string[] }));
                }
              }}
              className='timeline-collapse'
              style={{
                borderRadius: '8px !important',
                borderWidth: 0,
                background: '#f2f4f7',
                width: '100%',
                position: 'relative',
                top: 0
              }}>
              <Panel forceRender key={CARE_ACTIVITY_FEED}
                header={
                  <HStack alignItems={'center'} bgColor={Colors.Custom.BackgroundColor} borderTopRadius={0}>
                    <Text
                      fontSize={20}
                      color={Colors.Custom.ProfileViewTextColor}
                      fontWeight={600}
                    >
                      {intl.formatMessage({ id: 'careActivityFeed' })}
                    </Text>
                  </HStack>}>
                {!showSearchBar ? <CarePlanTopBarView
                  filterCodes={careTimelineTabList}
                  onChangeCategoryCode={(value: any) => {
                    if (value !== 'search') {
                      setShowLoadMoreButton(true)
                      setFilterLoading(true);
                      setFilterParams((prev)=> ({
                        ...prev,
                        selectedTimelineFilter: value,
                        pagination: initialPagination
                      }))

                    } else {
                      setShowSearchBar(true)
                    }
                  }}
                  timeSourceValue={timeSourceValue}
                  dateFilterValue={dateFilterValue}
                  onActionPerformed={(value:any) => {
                    setTimeSourceValue(value)
                  }}
                  onDateFilterChange={(value) => {
                    setDateFilterValue(value)
                  }}
                  showFilterIcon={true}
                  tabHeaderStyle={{ minWidth: 40, alignContent: 'center', marginRight: -5 }}
                  selectedCategoryCode={filterParams.selectedTimelineFilter}
                  showInfoIcon={false}
                  showSearchBar={true}
                  filterLoading={false}
                /> :
                  <View style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                    <SearchBar
                      height={8}
                      width={'97%'}
                      searchBarInputStyles={{ backgroundColor: '#FFFFFF' }}
                      placeholderText={'Search Care Activity'}
                      borderRadius={8}
                      onChange={debounce(onSearch, 600)}
                      leftElement={
                        <View margin={3}>
                          <SearchIconSvg
                            size={18}
                            customStrokeColor={Colors.FoldPixel.GRAY200}
                          />
                        </View>
                      }
                      rightElement={
                        <Pressable
                          height={'40px'}
                          width={'40px'}
                          alignItems={"center"}
                          justifyContent={"center"}
                          onPress={()=> {
                              setFilterParams((prev) => ({
                              ...prev,
                              searchTags: ''
                            }))
                            setShowSearchBar(false)
                          }}>
                          <CrossIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} size={20}/>
                        </Pressable>
                     }
                    />
                    <Divider style={{ marginTop: 10 }} />
                  </View>
                }
                {filterLoading ? (
                  <View minHeight={'300px'} padding={4}>
                    <Skeleton.Text lines={10} />
                  </View>
                ) : ((Object.keys(groupByDate).length > 0 && !filterLoading) ? <ScrollView style={{ marginTop: 10, marginBottom: 20, height: height - 290 }}>
                  {Object.keys(groupByDate).map((value, groupIndex) => {
                    const list = groupByDate[value];
                    const isGroupLastIndex = groupIndex === Object.keys(groupByDate)?.length - 1;

                    return (
                      <FlatList
                        data={list}
                        style={{ marginTop: 0 }}
                        renderItem={({ item, index }) =>
                          <CarePlanTimeline
                            key={`${value}_${index}`}
                            isLastIndex={isGroupLastIndex && index === list?.length - 1 ? true : false}
                            fhirData={item}
                            personData={props?.personData}
                            unFormattedContactData={props.unFormattedContactData}
                            ehrConfig={ehrConfig}
                            ehrCapabilities={ehrCapabilities}
                            accountUserList={timelineState.accountUserList}
                            elationFormData={elationFormData}
                            commonData={commonData}
                          />
                        }
                      />
                    );
                  })}
                  <View style={{ marginBottom: 40 }}>
                    {LoadMoreButton}
                  </View>
                </ScrollView> :
                  <View
                    style={style.centerView}
                  >
                    <EmptyTimlineFilterIconSvg />
                    <Text color={Colors.FoldPixel.GRAY200} fontSize={12} marginTop={4}>No Activity Found</Text>
                  </View>)}
              </Panel>
            </Collapse>}
            {isHIEEnabled && <VisitSummary personData={props.personData} />}
        </VStack>
      )}

      {!filterLoading  && showEmptyFilterView && (error || filteredFhirList.length === 0 || Object.keys(groupByDate).length === 0) && (
        <View
          style={style.centerView}
        >
          <NoActivitySvg titleId="noActivity" />
        </View>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  centerView: {
    flex: 1,
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 200,
  }
})

export default TimelineView;
