import { Colors } from "../../../../styles/Colors";
import { ICommonSvgProps } from "../interfaces";

const MentionIcon = (props: ICommonSvgProps) => {
    const {
        customStrokeColor,
        width,
        height,
    } = props;
    const bgColor = customStrokeColor || Colors.Custom.Gray500;

    return (
        <svg
            width={ width ? width : "inherit"} 
            height={ height ? height : "inherit"} 
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M8 15C8.27614 15 8.5 14.7761 8.5 14.5C8.5 14.2239 8.27614 14 8 14V15ZM13.6392 10.554L13.1694 10.3829L13.6392 10.554ZM13.1463 11.227L13.4853 11.5945L13.4853 11.5945L13.1463 11.227ZM13.2268 11.1527L12.8878 10.7852L12.8878 10.7852L13.2268 11.1527ZM11.1288 11.3858L10.8514 11.8019L11.1288 11.3858ZM8 14C4.96243 14 2.5 11.5376 2.5 8.5H1.5C1.5 12.0899 4.41015 15 8 15V14ZM2.5 8.5C2.5 5.46243 4.96243 3 8 3V2C4.41015 2 1.5 4.91015 1.5 8.5H2.5ZM8 3C11.0376 3 13.5 5.46243 13.5 8.5H14.5C14.5 4.91015 11.5899 2 8 2V3ZM13.5 8.5C13.5 9.16228 13.3832 9.79616 13.1694 10.3829L14.109 10.7252C14.3621 10.0304 14.5 9.28073 14.5 8.5H13.5ZM13.4853 11.5945L13.5659 11.5202L12.8878 10.7852L12.8072 10.8595L13.4853 11.5945ZM10.9 10.0241V8.5H9.9V10.0241H10.9ZM10.8514 11.8019C11.672 12.3489 12.7604 12.2632 13.4853 11.5945L12.8072 10.8595C12.4216 11.2153 11.8426 11.2608 11.4061 10.9698L10.8514 11.8019ZM13.1694 10.3829C13.1178 10.5246 13.0221 10.6613 12.8878 10.7852L13.5658 11.5202C13.7928 11.3108 13.9923 11.0454 14.109 10.7252L13.1694 10.3829ZM11.4061 10.9698C11.0899 10.759 10.9 10.4041 10.9 10.0241H9.9C9.9 10.7385 10.257 11.4056 10.8514 11.8019L11.4061 10.9698ZM9.9 8.5C9.9 9.54934 9.04934 10.4 8 10.4V11.4C9.60163 11.4 10.9 10.1016 10.9 8.5H9.9ZM8 10.4C6.95066 10.4 6.1 9.54934 6.1 8.5H5.1C5.1 10.1016 6.39837 11.4 8 11.4V10.4ZM6.1 8.5C6.1 7.45066 6.95066 6.6 8 6.6V5.6C6.39837 5.6 5.1 6.89837 5.1 8.5H6.1ZM8 6.6C9.04934 6.6 9.9 7.45066 9.9 8.5H10.9C10.9 6.89837 9.60163 5.6 8 5.6V6.6Z" 
                fill={bgColor}
            />
        </svg>
    );
};

export default MentionIcon;
