import React, { useState } from 'react'
import { HStack, View, Text, TextArea, Pressable, VStack } from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../../styles';
import { addOrUpdateSectionData } from '../CustomComponentHelper';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import { Checkbox } from 'antd';
import { IAllowedOperation, IKeyOperation } from '../../../../../../../Interfaces';
import CustomTextAreaField from '../../TextAreaComponent/CustomTextAreaField';

interface ISectionLevelDetailsProps {
  note: string;
  resource: string;
  patientId: string;
  accountLocationId?: string;
  nkda?: boolean;
  nkdaMessage?: string;
  fieldCapabilities: IKeyOperation | undefined;
  organizationCapabilites?: IAllowedOperation;
  onChange: (note: string, nkda?: boolean) => void;
}

interface ISectionLevelDetailsState {
  editMode: boolean;
  hoverIn: boolean;
  nkdaSelected: boolean;
}

const SectionLevelDetails = (props: ISectionLevelDetailsProps) => {
  const { note, resource, nkda, nkdaMessage, fieldCapabilities, patientId, accountLocationId, onChange } = props;

  const CONFIG = {
    canShowNKDA: resource === FHIR_RESOURCE.ALLERGY,
    canUpdateNKDA: (props?.organizationCapabilites?.canCreate || props?.organizationCapabilites?.canUpdate),
    canCreateNote: (props?.organizationCapabilites?.canCreate || props?.organizationCapabilites?.canUpdate) && fieldCapabilities?.sectionNote?.canCreate || false,
    canEditNote: (props?.organizationCapabilites?.canCreate || props?.organizationCapabilites?.canUpdate) && fieldCapabilities?.sectionNote?.canUpdate || false,
  }
  const [componentState, setComponentState] = useState<ISectionLevelDetailsState>({
    editMode: false,
    hoverIn: false,
    nkdaSelected: false,
  });

  const updateNote = async (text: string) => {
    onChange(text, nkda);
    await addOrUpdateSectionData(resource, {
      patientId,
      locationId: accountLocationId,
      note: text,
      ...(CONFIG.canShowNKDA && { nkda: nkda })
    })
  }

  const updateNKDA = async (flag: boolean) => {
    setComponentState(prev => ({...prev, nkdaSelected: flag}));
    onChange(note, flag);
    await addOrUpdateSectionData(resource, {
      note: note,
      patientId,
      locationId: accountLocationId,
      ...(CONFIG.canShowNKDA && { nkda: flag })
    })
  }

  return (
    <VStack space={4}>
      {CONFIG.canShowNKDA && (
        <>
          <Checkbox
            disabled={!CONFIG.canUpdateNKDA}
            checked={nkda}
            onChange={(event) => {
              updateNKDA(event.target.checked);
            }}
          >
            NKDA
          </Checkbox>
          {nkdaMessage && componentState.nkdaSelected && (
            <Text fontSize={12} color={Colors.Custom.Gray500}>{nkdaMessage}</Text>
          )}
        </>
      )}
      <Pressable
        onHoverIn={() => setComponentState(prev => ({...prev, hoverIn: CONFIG.canEditNote}))}
        onHoverOut={() => setComponentState(prev => ({...prev, hoverIn: false}))}
        isDisabled={componentState.editMode}
        flex={1}
        width={'100%'}
        onPress={() => {
          if (!componentState.editMode && (CONFIG.canEditNote || (CONFIG.canCreateNote && !note))) {
            setComponentState(prev => ({...prev, editMode: true}))
          }
        }}
        >
        {!(componentState.editMode || note) && CONFIG.canCreateNote && (
          <HStack space={1}>
            <Feather
              name="plus"
              size={16}
              color={Colors.Custom.mainPrimaryPurple}
            />
            <Text fontSize={12} color={Colors.Custom.mainPrimaryPurple}>
              Section Note
            </Text>
          </HStack>
        )}
        {(componentState.editMode || note) && (
          <HStack space={2}>
            <Feather
              name="file-text"
              size={16}
              color={componentState.hoverIn && !componentState.editMode ? Colors.Custom.mainPrimaryPurple : Colors.Custom.Gray500}
              style={{paddingTop: 2}}
              />
            <View flex={1}>
              {!componentState.editMode && (
                <Text fontSize={12} color={componentState.hoverIn ? Colors.Custom.mainPrimaryPurple : Colors.Custom.Gray500}>
                  {note}
                </Text>
              )}
              {componentState.editMode && (
                <CustomTextAreaField
                  placeholder={'Section Note'}
                  value={note}
                  macroTemplatesEnabled
                  onValueChange={(text: string) => {
                    onChange(text, nkda);
                  }}
                  onBlur={() => {
                    updateNote(note);
                    setComponentState(prev => ({...prev, editMode: false}));
                  }}
                  additionalProps={{
                    autoFocus: true,
                    rounded: 6,
                    borderRadius: 6
                  }}
                />
              )}
            </View>
          </HStack>
        )}
      </Pressable>
    </VStack>
  );
}

export default SectionLevelDetails
