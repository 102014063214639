import {Dropdown, Popover, Tooltip} from 'antd';
import {
  Button,
  Divider,
  HStack,
  Pressable,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {TABLE_TOP_BAR_ACTION_CODES} from '.';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PERSON_TYPES,
} from '../../../constants';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import {SearchBar} from '../SearchBar';
import FilterNewIconSvg from '../Svg/FilterNewIconSvg';
import {ITableTopBarButtonProps, ITableTopBarProps} from './Interfaces';
import {styles} from './TableTopBarStyles';
import './TableTopBarPopoverCss.css'
export interface SearchState {
  searchString?: string;
}

export const TableTopBar = (props: ITableTopBarProps) => {
  const intl = useIntl();

  const {
    extraStyles,
    placeHolderText,
    hStackMinHight,
    topContainerStyle,
    searchBarMinWidth,
    alignSearchBarFlexEnd,
    isDividerNotVisible,
    fullWidthHeader,
    searchBarCustommStyle,
    searchBarWidth,
    selectedTab
  } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState({
    selectedId: 0,
    isOpen: false,
    zIndex: 1,
  });
  const [searchDate, setSearchDate] = useState<SearchState>({
    searchString: undefined,
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const handleVisibleChange = (e: any) => {
    setIsPopoverOpen(e);
  };

  const getHeight = () => {
    if (props?.buttonList?.length) {
      return 80;
    } else {
      return 80;
    }
  };

  useEffect(() => {
    setIsPopoverOpen({
      ...isPopoverOpen,
      selectedId: 0,
      isOpen: props.popOverClick ? props.popOverClick : false,
    });
  }, [props]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (props.onActionPerformed && searchDate.searchString !== undefined) {
        props.onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.SEARCH, {
          searchString: searchDate.searchString,
        });
      }
    }, 800);
    return () => clearTimeout(delayDebounceFn);
  }, [searchDate.searchString]);

  useEffect(() => {
    setSearchDate({
      searchString: undefined,
    });
  }, [props?.selectedTab]);

  return (
    <View style={[extraStyles || {}]}>
      <View
        style={[
          props.buttonList
            ? [styles.topContainer, props.buttonCustomStyles || {}]
            : null,
          // topContainerStyle ? topContainerStyle : {},
        ]}
      >
        <HStack flex={1}>
          {props.title && props.title.trim().length && (
            <VStack flex={0.5} justifyContent={'center'} paddingLeft="5">
              <View style={styles.left}>
                {props.title && (
                  <DisplayText
                    size={props?.titleSize ? props?.titleSize : 'lgMedium'}
                    textLocalId={props.title}
                    extraStyles={styles.leftText}
                  />
                )}
              </View>
            </VStack>
          )}

          <VStack
            flex={props.title && props.title.trim().length ? 0.5 : 1}
            justifyContent={'center'}
          >
            <HStack
              justifyContent={
                (props.title && props.title.trim().length) ||
                alignSearchBarFlexEnd
                  ? 'flex-end'
                  : 'space-between'
              }
              alignItems={'center'}
            >
              {!props.hideSearchBar && (
                <>
                  <VStack
                    justifyContent={'flex-end'}
                    style={[searchBarCustommStyle]}
                  >
                    <View flexDirection={'row'} alignItems={'center'}>
                      <SearchBar
                        searchText={props.searchText || ''}
                        selectedTab={selectedTab}
                        width={
                          searchBarWidth
                            ? searchBarWidth
                            : isSmallScreen
                            ? 250
                            : 300
                        }
                        minWidth={
                          searchBarMinWidth ? searchBarMinWidth : 'auto'
                        }
                        height={10}
                        searchBarInputStyles={props.searchInputStyle}
                        placeholderText={placeHolderText}
                        borderRadius={8}
                        onChange={(value: string) => {
                          const searchStr = value;
                          setSearchDate({
                            searchString: searchStr,
                          });
                        }}
                      />
                      {props.showFilterIcon || props.buttonList?.length ? (
                        <View
                          style={{
                            width: 1,
                            height: 20,
                            backgroundColor: Colors.Custom.Gray200,
                            marginHorizontal: 16,
                          }}
                        ></View>
                      ) : (
                        <></>
                      )}
                    </View>
                  </VStack>
                </>
              )}
              <VStack {...(fullWidthHeader ? {width: '100%'} : undefined)}>
                <HStack width={'100%'}>
                  {props.headerElement}
                  {props.buttonList && props.buttonList.length > 0 && (
                    <VStack alignItems={'flex-end'} justifyContent={'center'}>
                      <View
                        style={[
                          styles.left,
                          {
                            paddingRight: props.showFilterIcon ? 0 : 6,
                          },
                        ]}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        {props?.buttonList.map(
                          (
                            singleButton: ITableTopBarButtonProps,
                            index: number
                          ) => {
                            if (!singleButton) {
                              return null;
                            }

                            if (
                              singleButton.btnType === 'IMPORT' &&
                              singleButton?.contactType ===
                                PERSON_TYPES.CUSTOMER
                            ) {
                              {
                                singleButton.isTransBtn ? (
                                  <>
                                    <FoldButton
                                      nativeProps={{
                                        size: singleButton.size,
                                        rightIcon: singleButton?.rightIcon,
                                        variant:
                                          singleButton.variant || 'PRIMARY',
                                        leftIcon: singleButton?.leftIcon,
                                        onPress: () => singleButton.btnClick(),
                                        ...singleButton.accessibilityProps,
                                        style: singleButton?.style || {},
                                        isDisabled: singleButton?.isDisabled,
                                      }}
                                      key={index}
                                      customProps={{
                                        withRightBorder:
                                          singleButton.withRightBorder || false,
                                        btnText: !isSmallScreen
                                          ? intl.formatMessage({
                                              id: singleButton.btnText,
                                            })
                                          : '',
                                      }}
                                    />
                                    <View
                                      style={{
                                        width: 1,
                                        height: 20,
                                        backgroundColor: Colors.Custom.Gray200,
                                        marginHorizontal: 16,
                                      }}
                                    ></View>
                                  </>
                                ) : null;
                              }
                            } else {
                              return (
                                <>
                                  {singleButton.content ? (
                                    singleButton.dropDownType ===
                                    'MENU_DROPDOWN' ? (
                                      <Dropdown
                                        overlay={singleButton.content}
                                        placement="bottomRight"
                                      >
                                        <FoldButton
                                          nativeProps={{
                                            size: singleButton.size,
                                            rightIcon: singleButton?.rightIcon,
                                            variant:
                                              singleButton.variant || 'PRIMARY',
                                            leftIcon: singleButton.leftIcon,
                                            ...singleButton.accessibilityProps,
                                            onPressIn: () => {
                                              setIsPopoverOpen({
                                                ...isPopoverOpen,
                                                selectedId:
                                                  singleButton.id || 0,
                                                isOpen: true,
                                              });
                                            },
                                            onPress: () =>
                                              singleButton.btnClick(),
                                            style: singleButton.style || {},
                                            isDisabled: singleButton?.isDisabled,
                                          }}
                                          key={index}
                                          customProps={{
                                            withRightBorder:
                                              singleButton.withRightBorder ||
                                              false,
                                            btnText: !isSmallScreen
                                              ? intl.formatMessage({
                                                  id: singleButton.btnText,
                                                })
                                              : '',
                                          }}
                                        />
                                      </Dropdown>
                                    ) : (
                                      <Popover
                                        key={index}
                                        overlayInnerStyle={{
                                          bottom: 3,
                                          borderRadius: 16,
                                          padding: 0,
                                        }}
                                        content={singleButton.content || <></>}
                                        title=""
                                        trigger="click"
                                        visible={
                                          isPopoverOpen.selectedId ===
                                          singleButton.id
                                            ? isPopoverOpen.isOpen
                                            : false
                                        }
                                        onVisibleChange={handleVisibleChange}
                                        placement={singleButton?.popoverPosition || 'bottom'}
                                      >
                                        <FoldButton
                                          nativeProps={{
                                            size: singleButton.size,
                                            rightIcon: singleButton?.rightIcon,
                                            variant:
                                              singleButton.variant || 'PRIMARY',
                                            leftIcon: singleButton.leftIcon,
                                            ...singleButton.accessibilityProps,
                                            onPress: () => {
                                              setIsPopoverOpen({
                                                ...isPopoverOpen,
                                                selectedId:
                                                  singleButton.id || 0,
                                                isOpen: !isPopoverOpen.isOpen,
                                              });
                                            },
                                            style: singleButton.style || {},
                                            isDisabled: singleButton?.isDisabled,
                                          }}
                                          key={index}
                                          customProps={{
                                            withRightBorder:
                                              singleButton.withRightBorder ||
                                              false,
                                            btnText: singleButton.btnText
                                              ? intl.formatMessage({
                                                  id: singleButton.btnText,
                                                })
                                              : '',
                                          }}
                                        />
                                      </Popover>
                                    )
                                  ) : singleButton.isTransBtn ? (
                                    <FoldButton
                                      nativeProps={{
                                        size: singleButton.size,
                                        rightIcon: singleButton?.rightIcon,
                                        variant:
                                          singleButton.variant || 'PRIMARY',
                                        leftIcon: singleButton.leftIcon,
                                        onPress: () => singleButton.btnClick(),
                                        ...singleButton.accessibilityProps,
                                        style: singleButton.style || {},
                                        marginRight: 1,
                                        isDisabled: singleButton?.isDisabled,
                                      }}
                                      key={index}
                                      customProps={{
                                        withRightBorder:
                                          singleButton.withRightBorder || false,
                                        btnText: !isSmallScreen
                                          ? intl.formatMessage({
                                              id: singleButton.btnText,
                                            })
                                          : '',
                                      }}
                                    />
                                  ) : (
                                    <FoldButton
                                      nativeProps={{
                                        size: singleButton.size,
                                        rightIcon: singleButton?.rightIcon,
                                        variant:
                                          singleButton.variant || 'PRIMARY',
                                        leftIcon: singleButton.leftIcon,
                                        isLoading: singleButton.isLoading,
                                        onPress: () => singleButton.btnClick(),
                                        ...singleButton.accessibilityProps,
                                        style: singleButton.style || {},
                                        marginRight: 1,
                                        isDisabled: singleButton?.isDisabled,
                                      }}
                                      key={index}
                                      customProps={{
                                        withRightBorder:
                                          singleButton.withRightBorder || false,
                                        btnText: !isSmallScreen
                                          && singleButton.btnText ? intl.formatMessage({
                                              id: singleButton.btnText,
                                            })
                                          : '',
                                        badgeView: singleButton?.badgeView
                                      }}
                                    />
                                  )}

                                  {(props.buttonList?.length || 1) - 1 !=
                                  index ? (
                                    <View
                                      style={{
                                        width: 1,
                                        height: 20,
                                        backgroundColor: !singleButton.isRightDividerNotVisible ? Colors.Custom.Gray200 : undefined,
                                        marginHorizontal: singleButton.isRightDividerNotVisible ? 4 : 16,
                                      }}
                                    ></View>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          }
                        )}
                      </View>
                    </VStack>
                  )}
                  {props.showFilterIcon && (
                    <VStack
                      style={{
                        marginLeft: 16,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip
                        title={props?.appliedFilters?.join(' \n') || props?.filterText}
                        arrowPointAtCenter={false}
                        overlayStyle={{whiteSpace: 'pre-line'}}
                      >
                        <Pressable
                          onPress={() => {
                            if (props.onActionPerformed)
                              props.onActionPerformed(
                                TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW,
                                {}
                              );
                          }}
                          style={[
                            {
                              width: 32,
                              height: 32,
                              justifyContent: 'center',
                              marginHorizontal: 2,
                            },
                          ]}
                        >
                          {props?.appliedFilters?.length ? (
                            <View
                              style={{
                                position: 'absolute',
                                top: -7.5,
                                right: -7.5,
                                backgroundColor: Colors.danger[500],
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{color: '#ffffff'}}>
                                {props?.appliedFilters?.length}
                              </Text>
                            </View>
                          ) : null}
                          <FilterNewIconSvg
                            isEnabled={true}
                            //customStrokeColor={Colors.secondary['800']}
                            //customBgColor={Colors.secondary['100']}
                          />
                        </Pressable>
                      </Tooltip>
                    </VStack>
                  )}
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </View>

      {/* {!isDividerNotVisible ? <Divider /> : null} */}
    </View>
  );
};

export default TableTopBar;
