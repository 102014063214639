import React from 'react';
import { ICommonSvgProps } from './interfaces';

const ArrowRightFillSvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.customStrokeColor || '#667085';
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.5L3 1.5L3 9.5L7 5.5Z"
        fill="#6F7A90"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowRightFillSvg;
