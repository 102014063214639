import React from 'react';
import { Colors } from '../../../../styles';
import { ICommonSvgProps } from '../interfaces';

const BrowseAttachmentSvg = (props: ICommonSvgProps) => {
    const { isSelected } = props;
    const strokeColor = isSelected
        ? Colors.primary['500']
        : props.isPrimary
            ? Colors.primary['300']
            : props.defaultColor || '#fff';
    return (
      <svg
        width="inherit"
        height="inherit"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.27834 11.8699L10.5389 6.83439C11.1705 6.22982 11.1705 5.24961 10.5389 4.64504C9.90732 4.04046 8.8833 4.04046 8.25171 4.64504L3.02924 9.64407C1.82921 10.7928 1.82921 12.6552 3.02924 13.8038C4.22928 14.9525 6.17491 14.9525 7.37494 13.8038L12.6736 8.73184C14.4421 7.03903 14.4421 4.29444 12.6736 2.60164C10.9052 0.908829 8.03793 0.908829 6.26946 2.60164L2 6.68844"
          stroke={strokeColor}
          stroke-linecap="round"
        />
      </svg>
    );
};

export default BrowseAttachmentSvg;
