import { View } from 'react-native'
import React, { useState } from 'react'
import { ITaskCategory, ITaskDisplayConfig, ITaskMetaData } from '../../TaskInterfaces'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { DragDropContext, DragStart, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { EventBus } from '../../../../utils/EventBus';
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants';
import { CARE_DASHBOARD_COLUMN_CODE } from '../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { TASK_DUE_DATE_CODES } from '../../../../constants/MlovConst';
import TaskKanbanColumn from './TaskKanbanColumn';
import { HStack } from 'native-base';
import useTaskCountManager from '../../../common/CareDashboard/CustomHook/useTaskCountManager';

interface ITaskKanbanView {
  config: ITaskDisplayConfig;
  metaData: ITaskMetaData;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
}

const TaskKanbanView = (props: ITaskKanbanView) => {
  const { config, metaData, onActionPerformed, onTaskDetail, onMemberClick } = props;
  const categories = config.categories;
  const eventBus = EventBus.getEventBusInstance();
  const taskCountData = useTaskCountManager({
    params: config.categories,
    isFetchTodaysCount: true
  });

  // States
  const [dragSourceColumn, setDragSourceColumn] = useState<string | undefined>();

  // Other methods
  function getColumnAndIndex(location: DraggableLocation) {
    return {
      column: categories.find(item => item.code === location.droppableId),
      index: location.index,
    }
  }

  const onDragHandler = async (result: DropResult) => {
    setDragSourceColumn(undefined);

    // If no destination then return
    if (!result.destination) {
      return;
    }

    // If element is dragged and dropped in same column (rearranged in same column) then return
    if (result.destination.droppableId === result.source.droppableId) {
      return;
    }

    eventBus.broadcastEvent(TASK_EVENTS.ON_TASK_DRAG_FROM_SOURCE, {
      source: getColumnAndIndex(result.source),
      destination: getColumnAndIndex(result.destination)
    });
  };

  const isDropDisabled = (column: ITaskCategory) => {
    return (
      dragSourceColumn ===
        CARE_DASHBOARD_COLUMN_CODE.completed ||
      (dragSourceColumn === CARE_DASHBOARD_COLUMN_CODE.dueNow &&
        column.code === CARE_DASHBOARD_COLUMN_CODE.today) ||
      (dragSourceColumn === CARE_DASHBOARD_COLUMN_CODE.today &&
        column.code === CARE_DASHBOARD_COLUMN_CODE.dueNow) ||
      column.code === dragSourceColumn || Object?.values(TASK_DUE_DATE_CODES)?.includes(column?.code)
    );
  };

  const onDragStart = (start: DragStart) => {
    setDragSourceColumn(start.source.droppableId);
  }

  return (
    <View style={{paddingHorizontal: 0, paddingBottom: 8, paddingTop: 16}}>
      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={onDragHandler}
      >
        <HStack space={4} marginX={4}>
          {categories.map((category, index) => {
            return (
              <TaskKanbanColumn
                key={`${category.code}_${index}`}
                category={category}
                metaData={metaData}
                noOfColumns={categories.length}
                taskCountData={taskCountData}
                isDropDisabled={isDropDisabled(category)}
                onActionPerformed={onActionPerformed}
                onTaskDetail={onTaskDetail}
                onMemberClick={onMemberClick}
              />
            );
          })}
        </HStack>
      </DragDropContext>
    </View>
  )
}

export default TaskKanbanView;
