import {gql} from '@apollo/client';

export const GetLabels = gql`
query GetLabels {
  labels {
    id
    title
    description
    color
    showOnSidebar
  }
}

`;

export default {GetLabels};
