import { Drawer } from 'antd';
import React, { useState } from 'react'
import { BUTTON_TYPE, RIGHT_SIDE_CONTAINER_CODE } from '../../../../../../constants';
import { Colors } from '../../../../../../styles';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { IOrderDetailsDrawer } from './interfaces';
import { getOrderDisplayItems } from './OrderDetailsUtils';
import { Skeleton, Spinner, View } from 'native-base';
import { ReportCategory } from '../OrderUtils';
import Stack from '../../../../../common/LayoutComponents/Stack';
import { isMarkAsReviewEnabled } from '../../../../../../utils/capabilityUtils';
import { DiagnosticReport } from 'fhir/r4';
import { isReportReviewedByUser } from '../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {Text} from 'react-native';
import InfoSvg from '../../../../../common/Svg/InfoSvg';
import { CustomSendCommunicationButton } from '../../../../LeftContainer/RecentActivity/RecentReport/CustomSendCommunicationButton';
import { useIntl } from 'react-intl';

const OrderDetailsDrawer = (props: IOrderDetailsDrawer) => {
  const height = window.innerHeight - 124;
  const onClose = () => {
    props.onClose && props.onClose();
  };
  const intl = useIntl();

  const orderDisplay = getOrderDisplayItems({
    order: props.order,
    report: props.report,
    action: props.action,
    prescription: props.prescription,
    documentTitle: props?.documentTitle,
  });

  const getPreferredCommunicationDetail = () => {
    if(
      props.unformmatedContactDetails?.contactPreferredCommunicationChannel?.length > 0
    ) {
      const contactPreferredCommunication = props.unformmatedContactDetails?.contactPreferredCommunicationChannel[0];
      if(contactPreferredCommunication?.id){
        return {
          preferredCommunicationTypeId: contactPreferredCommunication?.id,
          preferredCommunicationCode: contactPreferredCommunication?.preferredCommunicationModeMlov?.code,
          preferredCommunicationValue: contactPreferredCommunication?.preferredCommunicationModeMlov?.value,
        }
      }
    } else {
      return {
      };
    }
  }

  const preferredCommunicationDetail = getPreferredCommunicationDetail();

  return (
  <>
    <Drawer
      headerStyle={{borderWidth: 0, marginBottom: 0, paddingBottom: 0}}
      destroyOnClose
      placement="right"
      onClose={onClose}
      visible={props.isVisible}
      closable={false}
      width={'40%'}
      title={
        <>
          <ModalActionTitle
            title={
              props?.reportType === ReportCategory.IMAGING
                ? 'Results'
                : 'Order Details'
            }
            titleColor={''}
            showCustomButton={props?.reportType === ReportCategory.IMAGING ? true : false}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: props.onClose,
              },
            ]}
            customButton={
              <CustomSendCommunicationButton
                contactData={props.contactData}
                defaultCommunicationCode={
                  preferredCommunicationDetail?.preferredCommunicationCode
                }
                title={'Send Communication'}
                isLoading={false}
                onActionPerformed={(actionCode) => {
                  props?.onCommunicationActionPerformed?.(actionCode);
                }}
              />
            }
          />
        </>
      }
    >
      <View marginX={-2}>
        {props?.reportType === ReportCategory.IMAGING && preferredCommunicationDetail?.preferredCommunicationCode && (
          <Stack
            direction={'row'}
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <InfoSvg customColor={Colors.Custom.Gray500} />
            <Text style={{color: Colors.Custom.Gray500}}>
              {preferredCommunicationDetail?.preferredCommunicationCode
                ? `Patient preferred communication channel is ${preferredCommunicationDetail?.preferredCommunicationCode}`
                : ''}
            </Text>
          </Stack>
        )}
        {orderDisplay}
        {!!props?.reportResultViewLoading ? (
            <Skeleton.Text style={{marginTop: 16}} lines={4} />
          ) : props?.showReportResultView ? (
            props?.reportResultView
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color: Colors.Custom.Gray400,
                  marginTop: 24,
                  fontWeight: '500',
                }}
              >
                 {intl.formatMessage({id: 'noLinkedReports'})}
              </Text>
            </View>
          )}
      </View>
    </Drawer>
  </>
  );
}

export default OrderDetailsDrawer
