export const CONFIG_CODES = {
  ENABLE_MEMBERSHIP: 'ENABLE_MEMBERSHIP',
  IS_SHOW_VIEW_ALL_AUTOMATION_ANALYTIC: 'IS_SHOW_VIEW_ALL_AUTOMATION_ANALYTIC',
  ENABLE_MARRIAGE_ANNIVERSARY_DATE: 'ENABLE_MARRIAGE_ANNIVERSARY_DATE',
  MEMBERSHIP_OTP_VALIDATE: 'MEMBERSHIP_OTP_VALIDATE',
  SEND_EMAIL_OR_SMS_ON_PATIENT_OFFLINE: 'SEND_EMAIL_OR_SMS_ON_PATIENT_OFFLINE',
  DEFAULT_THEME: 'DEFAULT_THEME',
  IS_PHONE_OR_EMAIL_REQUIRED: 'IS_PHONE_OR_EMAIL_REQUIRED',
  ENABLE_EMPLOYER_REPORTING: 'ENABLE_EMPLOYER_REPORTING',
  ENABLE_LOCK_SCREEN: 'ENABLE_LOCK_SCREEN',
  ENABLE_GET_MARKET_COST_FROM_JSON: 'ENABLE_GET_MARKET_COST_FROM_JSON',
  SHOW_PRACTICE_MESSAGE_TOGETHER: 'SHOW_PRACTICE_MESSAGE_TOGETHER',
  CONSENT_REQUIRED: 'CONSENT_REQUIRED',
  ENABLE_SCHEDULE_MESSAGE: 'ENABLE_SCHEDULE_MESSAGE',
  ALLOW_PATIENT_ONE_TWO_ONE_CHAT:'ALLOW_PATIENT_ONE_TWO_ONE_CHAT',
  TOTAL_EMPLOYEE_MEMBERSHIP_COST: 'TOTAL_EMPLOYEE_MEMBERSHIP_COST',
  ALLOWED_IMAGING_CPT_DATA: 'ALLOWED_IMAGING_CPT_DATA',
  IS_VBC_TENANT: 'IS_VBC_TENANT',
  ENABLE_ENTER_TO_NEXT_LINE: 'ENABLE_ENTER_TO_NEXT_LINE',
  SINGLE_PATIENT_READ_RECEIPTS: 'SINGLE_PATIENT_READ_RECEIPTS',
  SHOW_MESSAGE_INFO_TO_ALL_GROUP_USERS: 'SHOW_MESSAGE_INFO_TO_ALL_GROUP_USERS',
  BLOCK_NON_SSO_FLOW: 'BLOCK_NON_SSO_FLOW',
  COPY_SELECT_MESSAGE_IN_DESCENDING: 'COPY_SELECT_MESSAGE_IN_DESCENDING',
  ENABLE_PRESERVE_TEXT_TABS_SWITCH: 'ENABLE_PRESERVE_TEXT_TABS_SWITCH',
  ENABLE_ZOOM: 'ENABLE_ZOOM',
  ENABLE_BROADCAST_MESSAGING:'ENABLE_BROADCAST_MESSAGING',
  ENABLE_JOURNEY_PACKAGE: 'ENABLE_JOURNEY_PACKAGE',
  ARCHIVE_CONVERSATION_ON_SEND_MESSAGE: 'ENABLE_ARCHIVE_ON_SEND',
  PATIENT_MESSAGING_ENABLED: 'PATIENT_MESSAGING_ENABLED',
  ENABLE_CHAT_HISTORY_READ: 'ENABLE_CHAT_HISTORY_READ',
  ENABLE_TASK_COUNT_ON_MESSAGE: 'ENABLE_TASK_COUNT_ON_MESSAGE',
  DISABLE_ANALYTICS: 'DISABLE_ANALYTICS',
  OPEN_ZOOM_LINKS: 'OPEN_ZOOM_LINKS',
  REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE: 'REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE',
  ENABLE_NEW_COST_SAVING_API: 'ENABLE_NEW_COST_SAVING_API',
  HIE_ENABLE: 'HIE_ENABLE',
  DISABLE_EMPLOYER_TAB: 'DISABLE_EMPLOYER_TAB',
  ENABLE_POWER_BI_ANALYTICS: 'ENABLE_POWER_BI_ANALYTICS',
  ENABLE_E_FAX_COMMUNICATION: 'ENABLE_E_FAX_COMMUNICATION',
  CONTACT_MEMBERSHIP_DISABLED: 'CONTACT_MEMBERSHIP_DISABLED',
  ANALYTICS_START_DATE: 'ANALYTICS_START_DATE',
  HIDE_SECTION_OR_WIDGET: 'HIDE_SECTION_OR_WIDGET',
  SHOW_SECTION_OR_WIDGET: 'SHOW_SECTION_OR_WIDGET',
  ENABLE_OUTLOOK: 'ENABLE_OUTLOOK',
  SHOW_CALL_RECORDING_AND_TRANSCRIPTION: 'SHOW_CALL_RECORDING_AND_TRANSCRIPTION',
  DISPLAY_TOKEN_EXPIRY_ALERT: 'DISPLAY_TOKEN_EXPIRY_ALERT',
  SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR: 'SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR',
  EMAIL_VIEW_USER_PREFERENCE: 'EMAIL_VIEW_USER_PREFERENCE',
  LLM_CONFIG: 'LLM_CONFIG',
  SHOW_SCROLL_INDICATOR: 'SHOW_SCROLL_INDICATOR',
  DISABLE_CONVERSATION_ON_PHONE_NUMBER_MISS_MATCH: 'DISABLE_CONVERSATION_ON_PHONE_NUMBER_MISS_MATCH'
}

export const ON_OFF_CONFIG_VALUE = {
  ON: 'ON',
  OFF: 'OFF',
} as const

export const DEFAULT_CONFIG_VALUE: {[index: string]: string} = {
  IS_PHONE_OR_EMAIL_REQUIRED: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_EMPLOYER_REPORTING: ON_OFF_CONFIG_VALUE.OFF,
  TOTAL_EMPLOYEE_MEMBERSHIP_COST: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_ENTER_TO_NEXT_LINE: ON_OFF_CONFIG_VALUE.OFF,
  SINGLE_PATIENT_READ_RECEIPTS: ON_OFF_CONFIG_VALUE.OFF,
  SHOW_MESSAGE_INFO_TO_ALL_GROUP_USERS: ON_OFF_CONFIG_VALUE.OFF,
  COPY_SELECT_MESSAGE_IN_DESCENDING: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_PRESERVE_TEXT_TABS_SWITCH: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_CHAT_HISTORY_READ: ON_OFF_CONFIG_VALUE.OFF,
  PATIENT_MESSAGING_ENABLED: ON_OFF_CONFIG_VALUE.ON,
  ENABLE_JOURNEY_PACKAGE: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_TASK_COUNT_ON_MESSAGE: ON_OFF_CONFIG_VALUE.OFF,
  DISABLE_ANALYTICS: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_NEW_COST_SAVING_API: ON_OFF_CONFIG_VALUE.ON,
  REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE: ON_OFF_CONFIG_VALUE.ON,
  HIE_ENABLE: ON_OFF_CONFIG_VALUE.OFF,
  DISABLE_EMPLOYER_TAB: ON_OFF_CONFIG_VALUE.OFF,
  ENABLE_E_FAX_COMMUNICATION: ON_OFF_CONFIG_VALUE.OFF,
  CONTACT_MEMBERSHIP_DISABLED: ON_OFF_CONFIG_VALUE.OFF,
  DISABLE_CONVERSATION_ON_PHONE_NUMBER_MISS_MATCH: ON_OFF_CONFIG_VALUE.OFF
}

export const SEND_EMAIL_OR_SMS_ON_PATIENT_OFFLINE = {
  OFF: ON_OFF_CONFIG_VALUE.OFF,
  VERIFIED: 'VERIFIED',
  NON_VERIFIED: 'NON_VERIFIED',
}
