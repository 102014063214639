import React from "react";
import { Path, Svg } from "react-native-svg";

function SortTaskIcon(props:{
    color?:string
}) {
    const {color} = props
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 18 18"
      id='sort-task-icon'
      
    >
      <Path
        id="sort-task-icon-path"
        stroke={color || "#667085"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.5 9h4.167M1.5 14h6.667M1.5 4h1.667M11.5 4L14 1.5m0 0L16.5 4M14 1.5v5.833M16.5 14L14 16.5m0 0L11.5 14m2.5 2.5v-5.833"
      ></Path>
    </Svg>
  );
}

export default SortTaskIcon;