import React from "react";
import { Path, Svg } from "react-native-svg";

function AddTaskIcon() {
  return (
    <Svg    
        id='add-task-icon'
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <Path
        id='add-task-icon-path'
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 1.667v11.666M1.167 7.5h11.666"
      ></Path>
    </Svg>
  );
}

export default AddTaskIcon;
