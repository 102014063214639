import {Drawer, Popover} from 'antd';
import {HStack, Pressable, Text, useToast, View, VStack} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {useNavigate} from 'react-router-dom';
import {
  BUTTON_TYPE,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
  QUICK_ACTION_CODES,
} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles';
import {
  getAccountUUID,
  getDrawerWidthByScreens,
  getFeatureFlag,
  getUserActionObjectFromActionCode,
  getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
  isChildAccount,
  isMasterAccount,
  navigateToOtherScreen,
} from '../../../utils/commonUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {EventBus} from '../../../utils/EventBus';
import {getContactTypeId} from '../../../utils/mlovUtils';
import {
  isMacintosh,
  SHORT_CODE_KEYS_MAC,
  SHORT_CODE_KEYS_WINDOWS,
} from '../../../utils/ShortKeys';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import SelectPatientCallDrawer from '../../RightSideContainer/CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import {AddOrUpdateLead} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {getFormDataFromLeadData} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {InviteUserDrawer} from '../../RightSideContainer/Contacts/TeamMembers/InviteUserDrawer';
import AddOrUpdateCampaign from '../../RightSideContainer/ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {CampaignSegmentForm} from '../../RightSideContainer/ContentManagement/CampaignManagement/CampaignSegmentForm';
import {ICampaignSegmentFormState} from '../../RightSideContainer/ContentManagement/CampaignManagement/interfaces';
import {EmployerQuickCreate} from '../../RightSideContainer/EmployerManagement/EmployerQuickCreate';
import {getPreFilterMemberStatus, patientFilterForCreateSegment} from '../../RightSideContainer/MembersManagement/Helper';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {CONVERSATION_TAB_CODES} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {SMS_INBOX_ACTION_CODES} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {
  getUserPermissionByEntityAndActionCode,
  USER_ACCESS_PERMISSION,
} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {createCampaignSegment, getCampaignSegmentRules} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import AppointmentBooking from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import {ParticipantType} from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import CreateChannelView from '../CreateChannelView/CreateChannelView';
import {IContact} from '../CreateChannelView/interface';
import {DisplayText} from '../DisplayText/DisplayText';
import EmailDrawerCommon from '../EmailPopupView/EmailDrawerCommon';
import MeetingView from '../MeetingView/MeetingView';
import AutomationOutlineIcon from '../Svg/AutomationOutline';
import CampaignOutlineIcon from '../Svg/CampaignOutline';
import CreateNew from '../Svg/CreateNew';
import AutomationSvg from '../Svg/GlobalActionSvg/AutomationSvg';
import CampaignSvg from '../Svg/GlobalActionSvg/CampaignSvg';
import CareJourneysSvg from '../Svg/GlobalActionSvg/CareJourneysSvg';
import EmployerSvgIcon from '../Svg/GlobalActionSvg/EmployerSvgIcon';
import PatientGroup from '../Svg/GlobalActionSvg/PatientGroup';
import PracticeInvite from '../Svg/GlobalActionSvg/PracticeInvite';
import TasksIconSvg from '../Svg/GlobalActionSvg/TasksIconSvg';
import VideoMeeting from '../Svg/GlobalActionSvg/VideoMeeting';
import VoiceCall from '../Svg/GlobalActionSvg/VoiceCall';
import PatientOutlineIcon from '../Svg/PatientOutlineIcon';
import ChatActionSvg from '../Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../Svg/PersonActionSvgIcons/SmsActionSvg';
import CalendarSvg from '../Svg/SideMenu/CalendarSvg';
import TasksSvg from '../Svg/SideMenu/TasksSvg';
import {handleCreateMemberGroupAction} from '../UsageLevel/UsageLevelHandlers';
import {styles} from './GlobalActionStyles';
import './PopoverStyle.css';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {getOrgAbilites, getResourceAbilities} from '../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../constants/FhirConstant';
import {SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import InstantChatView from '../ChatDrawer/InstantChatView';
import { EmailDrawerCommonV2 } from '../EmailDrawerCommonV2';
import EFaxActionSvg from '../Svg/PersonActionSvgIcons/EFaxActionSvg';
import CreateEFaxConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {useLazyQuery} from '@apollo/client';
import {INewConversationResponse} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getFrequencyValue} from '../../RightSideContainer/ContentManagement/CampaignManagement/utils';
import CalenderAddSvg from '../Svg/CalenderAddSvg';
import CreateNewGlobalActionSvg from '../Svg/CreateNewGlobalActionSvg';

const GlobalActions = (props: {isBtn?: boolean}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const patientOrgAbilites = getOrgAbilites(FHIR_RESOURCE.PATIENT);
  const [visible, setVisible] = useState(false);
  const currentUserId = getUserUUID();
  const currentUserUUID = getUserUUID();
  const currentUserData = getUserData();
  const userId = getUserId();
  const mlovData = useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, mlovData);
  const isChildAccountFlag = isChildAccount()
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const createJourneyUrl = '/admin/commerce/journeys/product/-1/journey/create';
  const toast = useToast();
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const [openModal, setOpenModal] = useState({
    type: '',
    data: null as any,
  });
  const [stateData, setStateData] = useState({
    formDataFromLeadData: {} as any,
    selectedConversationData: {} as any,
  });
  const [employerConfig, setEmployerConfig] = useState({
    isAllowedToAdd: true,
  });
  const isAddTaskAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.TASKS
  );
  const canCreatePatient = patientOrgAbilites?.canCreate;
  const isAddMemberAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const isMessageAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const isAddScheduleAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CALENDAR
  );
  const isAddCampaignAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CAMPAIGN_MANAGER
  );
  const isCallsAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CALL_LOGS
  );
  const isAccountAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.ACCOUNT_SETTINGS
  );
  const isAutomationAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );
  const isSettingAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.SETTING
  );
  const isAddEmployerAllowed = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.EMPLOYER
  );
  const showJourneyTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY
  );
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: currentUserUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversationData: selectedConversationData,
          };
        });
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const {width} = Dimensions.get('window');
  const finalWidth = getDrawerWidthByScreens({
    screenWidth: width,
    divider: 2.5,
  });
  const quickActionList = [
    ...(isAutomationAllowed
      ? [
          {
            displayString: 'Automation',
            actionCode: COMMON_ACTION_CODES.CREATE_AUTOMATION,
            shortKey: isMacintosh()
              ? SHORT_CODE_KEYS_MAC.automation
              : SHORT_CODE_KEYS_WINDOWS.automation,
            icon: <AutomationOutlineIcon></AutomationOutlineIcon>,
          },
        ]
      : []),
    {
      displayString: 'Campaign',
      actionCode: COMMON_ACTION_CODES.CREATE_CAMPAIGN,
      shortKey: isMacintosh()
        ? SHORT_CODE_KEYS_MAC.campaign
        : SHORT_CODE_KEYS_WINDOWS.campaign,
      icon: <CampaignOutlineIcon></CampaignOutlineIcon>,
    },

    ...(isAddMemberAllowed && canCreatePatient
      ? [
          {
            displayString: 'Member',
            actionCode: COMMON_ACTION_CODES.ADD_MANUALLY,
            shortKey: isMacintosh()
              ? SHORT_CODE_KEYS_MAC.member
              : SHORT_CODE_KEYS_WINDOWS.member,
            icon: <PatientOutlineIcon></PatientOutlineIcon>,
          },
        ]
      : []),

    ...(isAddTaskAllowed
      ? [
          {
            displayString: 'Task',
            actionCode: COMMON_ACTION_CODES.ADD_TASK,
            shortKey: isMacintosh()
              ? SHORT_CODE_KEYS_MAC.task
              : SHORT_CODE_KEYS_WINDOWS.task,
            icon: (
              <View display={'inline'} w={5}>
                <TasksSvg defaultColor="#667085" />
              </View>
            ),
          },
        ]
      : []),
  ];

  const addNewActionList = [
    ...(isAddMemberAllowed && canCreatePatient
      ? [
          {
            displayString: 'Patient',
            actionCode: COMMON_ACTION_CODES.ADD_MANUALLY,
            shortKey: isMacintosh()
              ? SHORT_CODE_KEYS_MAC.member
              : SHORT_CODE_KEYS_WINDOWS.member,
            icon: (
              <PatientOutlineIcon width={20} height={20}></PatientOutlineIcon>
            ),
          },
        ]
      : []),
    ...(isAddTaskAllowed
      ? [
          {
            displayString: 'Task',
            actionCode: COMMON_ACTION_CODES.ADD_TASK,
            icon: (
              <TasksIconSvg width={20} height={20} />
            ),
          },
        ]
      : []),
    ...(isAddCampaignAllowed
      ? [
          {
            displayString: 'Campaign',
            actionCode: COMMON_ACTION_CODES.CREATE_CAMPAIGN,
            icon: <CampaignSvg width={20} height={20} />,
          },
        ]
      : []),
    ...(isAddEmployerAllowed && employerConfig?.isAllowedToAdd && !isChildAccountFlag
      ? [
          {
            displayString: 'Employer',
            actionCode: QUICK_ACTION_CODES.ADD_NEW_EMPLOYER,
            icon: <EmployerSvgIcon />,
          },
        ]
      : []),
    ...(isAddMemberAllowed
      ? [
          {
            displayString: 'Patient Group',
            actionCode: QUICK_ACTION_CODES.ADD_PATIENT_GROUP,
            icon: <PatientGroup width={20} height={20} />,
          },
        ]
      : []),
  ];
  const startNewActionList = [
    {
      displayString: 'Video Meeting',
      actionCode: QUICK_ACTION_CODES.CREATE_VIDEO_MEETING,
      icon: <VideoMeeting width={20} height={20} />,
    },
    {
      displayString: 'Voice Call',
      actionCode: QUICK_ACTION_CODES.CREATE_VOICE_MEETING,
      icon: <VoiceCall width={20} height={20} />,
    },
    ...(isMessageAllowed ? [{
      displayString: 'Chat',
      actionCode: QUICK_ACTION_CODES.CREATE_CHAT,
      icon: (
        <ChatActionSvg customStrokeColor="#8A94A8" width={20} height={20} isActive={true} />
      ),
    },
    {
      displayString: 'SMS',
      actionCode: QUICK_ACTION_CODES.CREATE_SMS,
      icon: (
        <SmsActionSvg width={20} height={20} customStrokeColor="#8A94A8" isActive={true} />
      ),
    },
    {
      displayString: 'Email',
      actionCode: QUICK_ACTION_CODES.CREATE_EMAIL,
      icon: (
        <EmailActionSvg width={20} height={20} customStrokeColor="#8A94A8" isActive={true} />
      ),
    },
    ...(isEFaxMessagingEnabled
      ? [
          {
            displayString: 'e-Fax',
            actionCode: QUICK_ACTION_CODES.CREATE_EFAX,
            icon: (
              <EFaxActionSvg width={20} height={20} customStrokeColor="#8A94A8" isActive={true} />
            ),
          },
        ]
      : [])] : [])
  ];
  const inviteActionList = [
    {
      displayString: 'Invite Practice user',
      actionCode: QUICK_ACTION_CODES.INVITE_PRACTICE_USER,
      icon: <PracticeInvite width={20} height={20} customStrokeColor="#8A94A8" />,
    },
  ];
  const buildActionList = [
    ...(isAutomationAllowed
      ? [
          {
            displayString: 'Automation',
            actionCode: COMMON_ACTION_CODES.CREATE_AUTOMATION,
            shortKey: isMacintosh()
              ? SHORT_CODE_KEYS_MAC.automation
              : SHORT_CODE_KEYS_WINDOWS.automation,
            icon: <AutomationSvg width={20} height={20} customStrokeColor="#8A94A8" />,
          },
        ]
      : []),
    ...(isSettingAllowed && showJourneyTab
      ? [
          {
            displayString: 'Care Journey',
            actionCode: QUICK_ACTION_CODES.ADD_CARE_JOURNEY,
            icon: <CareJourneysSvg width={20} height={20} customStrokeColor="#8A94A8" />,
          },
        ]
      : []),
  ];

  const getHintConfig = async () => {
    setEmployerConfig((prev) => {
      return {
        ...prev,
        isAllowedToAdd: !openInHint,
      };
    });
  };

  useEffect(() => {
    if (isVbcTenant) {
      setEmployerConfig((prev) => {
        return {
          ...prev,
          isAllowedToAdd: false,
        };
      });
    } else {
      getHintConfig();
    }
  }, []);

  const getPopoverStatus = useCallback((data: any) => {
    const {visiblePopover} = data;
    setVisible(visiblePopover);
  }, []);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('POPOVER', getPopoverStatus);
    return () => {
      eventBus.removeEventListener(getPopoverStatus);
    }
  }, []);

  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_A_MEMBER_GROUP'
  );
  const level = userActionObject.level || '';

  const createSegmentGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState
  ) => {
    createCampaignSegment({
      name: campaignSegmentFormData?.audienceName || '',
      tenantId: accountUuid,
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...!campaignSegmentFormData.patientList?.length && (getPreFilterMemberStatus(campaignSegmentFormData))
    })
      .then(async (resp: any) => {
        if (resp?.id) {
          let popGroupResponse = resp;
          if (resp?.inputRuleId) {
            popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
          }
          showToast(
            toast,
            resp.type == 'DYNAMIC'
              ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
              : intl.formatMessage({id: 'createGroupSuccessMessage'}),
            ToastType.success,
            undefined,
          );
          setOpenModal({
            type: '',
            data: null,
          });
          handleCreateMemberGroupAction({
            accountUuid: accountUuid,
            userUuid: currentUserId,
            level,
          })
            .catch((error) => {

            });
        }
      })
      .catch((error) => {
        showToast(
          toast,
          error?.response?.data?.message || 'Something went wrong',
          ToastType.error,
        );
      });
  };
  // HANDLE CLOUD CALL
  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
      });
    }
  };

  const onActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD_MANUALLY:
        setOpenModal({
          type: COMMON_ACTION_CODES.ADD_MANUALLY,
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.ADD_SCHEDULE:
        setOpenModal({
          type: COMMON_ACTION_CODES.ADD_SCHEDULE,
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.ADD_TASK:
        setOpenModal({
          type: COMMON_ACTION_CODES.ADD_TASK,
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setOpenModal({
          type: COMMON_ACTION_CODES.CREATE_CAMPAIGN,
          data: null,
        });
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        navigateToOtherScreen(
          navigate,
          '/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS'
        );
        break;
      case QUICK_ACTION_CODES.ADD_NEW_EMPLOYER:
        setOpenModal({
          type: QUICK_ACTION_CODES.ADD_NEW_EMPLOYER,
          data: null,
        });
        break;

      case QUICK_ACTION_CODES.ADD_PATIENT_GROUP:
        setOpenModal({
          type: QUICK_ACTION_CODES.ADD_PATIENT_GROUP,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.CREATE_VIDEO_MEETING:
        setOpenModal({
          type: QUICK_ACTION_CODES.CREATE_VIDEO_MEETING,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.CREATE_VOICE_MEETING:
        setOpenModal({
          type: QUICK_ACTION_CODES.CREATE_VOICE_MEETING,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.INVITE_PRACTICE_USER:
        setOpenModal({
          type: QUICK_ACTION_CODES.INVITE_PRACTICE_USER,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        navigateToOtherScreen(navigate, createJourneyUrl);
        break;
      case QUICK_ACTION_CODES.CREATE_SMS:
        setOpenModal({
          type: RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.CREATE_EMAIL:
        setOpenModal({
          type: RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.CREATE_CHAT:
        setOpenModal({
          type: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          data: null,
        });
        break;
      case QUICK_ACTION_CODES.CREATE_EFAX:
        setOpenModal({
          type: CONVERSATION_ACTION_CODES.START_EFAX,
          data: null,
        });
        break;
      default:
    }
  };
  const addNewActionView = (
    <VStack marginRight={5}>
      <VStack>
        <DisplayText
          textLocalId="addNews"
          extraStyles={{
            color: Colors.Custom.mainPrimaryPurple,
            letterSpacing: 1,
            marginLeft: 2,
          }}
          size="xsBold"
        />
      </VStack>
      <VStack>
        {addNewActionList.map((action, index) => {
          return (
            <Pressable
              key={index}
              onPress={() => {
                onActionPerformed(action.actionCode);
                setVisible(!visible);
              }}
            >
              <HStack style={[styles.mainMenuContent, {borderBottomWidth: 0}]}>
                <>{action.icon}</>
                <View>
                  <Text
                    size={'mdMedium'}
                    style={[styles.mainMenuTxt, {color: '#000'}]}
                  >
                    {action.displayString}
                  </Text>
                </View>
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </VStack>
  );

  const createAndInviteActionView = (
    <VStack marginLeft={5} marginRight={5}>
      {isCallsAllowed ? (
        <>
          <VStack>
            <DisplayText
              textLocalId="startNew"
              extraStyles={{
                color: Colors.Custom.mainPrimaryPurple,
                letterSpacing: 1,
              }}
              size="xsBold"
            />
          </VStack>
          <VStack>
            {startNewActionList.map((action, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() => {
                    onActionPerformed(action.actionCode);
                    setVisible(!visible);
                  }}
                >
                  <HStack
                    style={[styles.mainMenuContent, {borderBottomWidth: 0}]}
                  >
                    <>{action.icon}</>
                    <View>
                      <Text
                        size={'mdMedium'}
                        style={[styles.mainMenuTxt, {color: '#000'}]}
                      >
                        {action.displayString}
                      </Text>
                    </View>
                  </HStack>
                </Pressable>
              );
            })}
          </VStack>
        </>
      ) : null}
    </VStack>
  );
  const buildActionView = (
    <VStack marginLeft={5}>
      <>
        <VStack>
          <DisplayText
            textLocalId="buildNew"
            extraStyles={{
              color: Colors.Custom.mainPrimaryPurple,
              letterSpacing: 1,
            }}
            size="xsBold"
          />
        </VStack>
        <VStack>
          {buildActionList.map((action, index) => {
            return (
              <Pressable
                key={index}
                onPress={() => {
                  onActionPerformed(action.actionCode);
                  setVisible(!visible);
                }}
              >
                <HStack
                  style={[styles.mainMenuContent, {borderBottomWidth: 0}]}
                >
                  <>{action.icon}</>
                  <View>
                    <Text
                      size={'mdMedium'}
                      style={[styles.mainMenuTxt, {color: '#000'}]}
                    >
                      {action.displayString}
                    </Text>
                  </View>
                </HStack>
              </Pressable>
            );
          })}
        </VStack>
      </>
      {isAccountAllowed && !isChildAccountFlag && (
        <>
          <VStack style={{marginTop: isCallsAllowed ? 28 : 0}}>
            <DisplayText
              textLocalId="invite"
              extraStyles={{
                color: Colors.Custom.mainPrimaryPurple,
                letterSpacing: 1,
              }}
              size="xsBold"
            />
          </VStack>
          <VStack>
            {inviteActionList.map((action, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() => {
                    onActionPerformed(action.actionCode);
                    setVisible(!visible);
                  }}
                >
                  <HStack
                    style={[styles.mainMenuContent, {borderBottomWidth: 0}]}
                  >
                    <>{action.icon}</>
                    <View>
                      <Text
                        size={'mdMedium'}
                        style={[styles.mainMenuTxt, {color: '#000'}]}
                      >
                        {action.displayString}
                      </Text>
                    </View>
                  </HStack>
                </Pressable>
              );
            })}
          </VStack>
        </>
      )}
    </VStack>
  );
  const content = (
    <HStack style={{justifyContent: 'space-between'}}>
      {addNewActionList.length > 0 && addNewActionView}
      {(isCallsAllowed || isAccountAllowed) && createAndInviteActionView}
      {buildActionList.length > 0 && buildActionView}
    </HStack>
  );

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };
  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setOpenModal({
          type: '',
          data: null,
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversationData: actionData,
          };
        });
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {
          conversations: actionData,
        });
        setOpenModal({
          type: '',
          data: null,
        });
        break;
    }
  };
  return (
    <View>
      <HStack space="2">
        {quickActionList.length > 0 ? (
          <Popover
            overlayInnerStyle={{
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              borderRadius: 16,
              width: 'auto',
            }}
            overlayClassName="globalActions"
            className="popoverStyle"
            placement={'bottomLeft'}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
            content={content}
          >
            {props.isBtn &&
            (addNewActionList.length > 0 ||
              buildActionList.length > 0 ||
              isCallsAllowed ||
              isAccountAllowed) ? (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    handleVisibleChange(!visible);
                  },
                  leftIcon: (
                    <CreateNewGlobalActionSvg customColor={Colors.Custom.mainPrimaryPurple} />
                  ),
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'createNew'}),
                }}
              ></FoldButton>
            ) : (
              (addNewActionList.length > 0 ||
                buildActionList.length > 0 ||
                isCallsAllowed ||
                isAccountAllowed) && <Fontisto name="more-v-a" size={18} />
            )}
          </Popover>
        ) : (
          <></>
        )}

        {isAddScheduleAllowed ? (
          <>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onActionPerformed(COMMON_ACTION_CODES.ADD_SCHEDULE);
                },
                leftIcon: (
                  <View size={18}>
                    <CalenderAddSvg
                      customStrokeColor={Colors.Custom.mainPrimaryPurple}
                    />
                  </View>
                ),
              }}
              customProps={{
                btnText: (
                  <DisplayText
                    textLocalId={'schedule'}
                    size={'smBold'}
                    extraStyles={{color: Colors.Custom.mainPrimaryPurple}}
                  />
                ),
                withRightBorder: false,
              }}
            ></FoldButton>
          </>
        ) : (
          <></>
        )}
      </HStack>
      {openModal.type === COMMON_ACTION_CODES.ADD_MANUALLY && (
        <AddOrUpdateLead
          singleLeadData={undefined}
          onFormCompleteAction={(actionCode: string) => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
          personTypeUuid={contactTypeUuid}
          personType={PERSON_TYPES.CUSTOMER}
        />
      )}

      {openModal.type === COMMON_ACTION_CODES.ADD_SCHEDULE && (
        <AppointmentBooking
          isVisible={true}
          onComplete={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
          onCancel={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        />
      )}

      {openModal.type === COMMON_ACTION_CODES.ADD_TASK && (
        <AddOrUpdateTask
          assignee={{
            value: currentUserUUID,
            label: getUserFullName(),
            key: currentUserUUID,
            type: ParticipantType.staff,
            details: currentUserData,
          }}
          isVisible={true}
          onComplete={(data) => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
          onCancel={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        />
      )}

      <Drawer
        visible={
          openModal.type === COMMON_ACTION_CODES.CREATE_CAMPAIGN ? true : false
        }
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setOpenModal({
            type: '',
            data: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {openModal.type === COMMON_ACTION_CODES.CREATE_CAMPAIGN && (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            close={(status) => {
              if (status != 'cancel') {
                showToast(
                  toast,
                  status == 'created'
                    ? 'Campaign created successfully.'
                    : 'Campaign updated successfully.',
                  ToastType.success
                );
              }
              setOpenModal({
                type: '',
                data: null,
              });
            }}
          />
        )}
      </Drawer>
      <Drawer
        className="headerBorder"
        width={finalWidth}
        visible={
          openModal.type === QUICK_ACTION_CODES.ADD_NEW_EMPLOYER ? true : false
        }
        closeIcon={null}
        closable
        maskClosable={true}
        onClose={() => {
          setOpenModal({
            type: '',
            data: null,
          });
        }}
      >
        {
          <>
            <EmployerQuickCreate
              isModalOpen={openModal?.type == 'quickCreate'}
              onModalClose={(shouldRefetch?: boolean) => {
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              title="quickCreate"
            />
          </>
        }
      </Drawer>

      {openModal.type === QUICK_ACTION_CODES.ADD_PATIENT_GROUP ? (
        <Drawer
          width={finalWidth}
          visible={
            openModal.type === QUICK_ACTION_CODES.ADD_PATIENT_GROUP
              ? true
              : false
          }
          closeIcon={null}
          closable
          maskClosable={true}
          destroyOnClose
          onClose={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        >
          <CampaignSegmentForm
            isPatientGroup
            onModalClose={() => {
              setOpenModal({
                type: '',
                data: null,
              });
            }}
            onSubmitForm={(
              campaignSegmentFormData: ICampaignSegmentFormState
            ) => {
              createSegmentGroup(campaignSegmentFormData);
              setOpenModal({
                type: '',
                data: null,
              });
            }}
          />
        </Drawer>
      ) : (
        <></>
      )}

      {openModal.type === QUICK_ACTION_CODES.INVITE_PRACTICE_USER && (
        <InviteUserDrawer
          isOpen={
            openModal.type === QUICK_ACTION_CODES.INVITE_PRACTICE_USER
              ? true
              : false
          }
          onClose={(isRefresh?: boolean) => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        />
      )}
      {openModal.type === QUICK_ACTION_CODES.CREATE_VOICE_MEETING && (
        <SelectPatientCallDrawer
          isDrawerVisible={
            openModal.type === QUICK_ACTION_CODES.CREATE_VOICE_MEETING
              ? true
              : false
          }
          selectedRecord={null}
          handleCloudCall={handleCloudCall}
          onCreateCallConversationActionPerformed={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        />
      )}
      {openModal.type === QUICK_ACTION_CODES.CREATE_VIDEO_MEETING && (
        <MeetingView
          personData={stateData.formDataFromLeadData}
          isFromGlobal={true}
          selectedContact={(data) => {
            const formattedContactData = getFormDataFromLeadData(
              data.patientData || {},
              mlovData
            );
            setStateData((prev) => {
              return {
                ...prev,
                formDataFromLeadData: formattedContactData,
              };
            });
          }}
          onPersonActionPerformed={(actionCode: any) => {
            if (actionCode == COMMON_ACTION_CODES.CANCEL) {
              setOpenModal({
                type: '',
                data: null,
              });
            }
            setStateData((prev) => {
              return {
                ...prev,
                formDataFromLeadData: {},
              };
            });
          }}
        />
      )}
      {openModal.type === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE) {
              setOpenModal({
                type: '',
                data: null,
              });
            }
            if (
              actionData?.selectedInboxConversationData?.conversations?.[0]?.id
            ) {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedConversationData:
                    actionData.selectedInboxConversationData.conversations[0],
                };
              });
            }
          }}
        />
      )}
      {openModal.type === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommonV2
          isOpen={
            openModal.type === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
          }
          onEmailSent={({msgData}) => {
            handleOnCreateNewEmailConversation(msgData?.conversationId);
          }}
          onClose={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        />
      )}
      {openModal.type == CONVERSATION_ACTION_CODES.START_GROUP_CHAT && (
        <CreateChannelView
          isDrawerVisible={true}
          onCreateChannelActionPerformed={onCreateChannelActionPerformed}
          selectedTabCode={CONVERSATION_TAB_CODES.PRIVATE}
        />
      )}
      {openModal.type == CONVERSATION_ACTION_CODES.START_EFAX && (
        <CreateEFaxConversationDrawer
          visible={true}
          onActionPerformed={onCreateChannelActionPerformed}
      />
      )}
      {stateData?.selectedConversationData?.id && (
        <InstantChatView
          selectedConversation={stateData?.selectedConversationData}
          contactData={{} as any}
          isLoading={false}
          isDrawerVisible={true}
          isInstantChatView={true}
          instantChatFromNotifications={true}
          onActionPerformed={(actionCode: any, actionData?: any) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedConversationData: {} as any,
                };
              });
            } else if (actionCode === COMMON_ACTION_CODES.ITEM_CLICKED) {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedConversationData: actionData,
                };
              });
            } else if (actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT) {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedConversationData: actionData,
                };
              });
            }
          }}
        />
      )}
    </View>
  );
};
export default GlobalActions;
