import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  bottomBorder: {
    borderBottomColor: 'primary.600',
    borderBottomWidth: 3,
  },
  mainContainer: {
    minHeight: 150,
    backgroundColor: Colors.Custom.ContainerBGColor
  },
  icons: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 12,
    color: Colors.Custom.IconColor,
  },
  actionBtns: {
    padding: 5,
    height: 24,
    width: 80,
    borderRadius: 20,
    color: Colors.Custom.IconColorWhite,
  },
  actionIcon: {
    marginRight: 10,
    fontSize: 10,
    color: Colors.Custom.IconColorWhite,
  },
  btnText: {
    fontSize: 12,
    color: Colors.Custom.FontColorWhite,
    marginLeft: 10,
  },
  lastMenu: {
    paddingBottom: 15,
  },
  mainMenuTxt: {
    marginLeft: 10
  },
  dividerStyle: {
    width: '100%',
  },
  mainMenuContent: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    cursor: 'pointer',
  },
  firstMenu: {
    paddingTop: 10,
  },
});
