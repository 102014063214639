import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const VideoPopoverActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke={strokeColor}
        d="M1.333 7.664c0-2.192 0-3.287.606-4.025.11-.135.234-.259.37-.37.737-.605 1.833-.605 4.024-.605 2.192 0 3.288 0 4.025.605.136.111.26.235.37.37.606.738.606 1.833.606 4.025v.667c0 2.191 0 3.287-.606 4.025-.11.135-.234.259-.37.37-.737.605-1.833.605-4.025.605-2.191 0-3.287 0-4.025-.606a2.667 2.667 0 01-.37-.37c-.605-.737-.605-1.833-.605-4.024v-.667z"
      ></Path>
      <Path
        stroke={strokeColor}
        d="M11.334 6.33l.438-.219c1.298-.648 1.946-.973 2.42-.68.475.294.475 1.019.475 2.47v.194c0 1.45 0 2.175-.474 2.468-.475.294-1.123-.03-2.42-.68l-.44-.219V6.331z"
      ></Path>
    </Svg>
  );
};

export default VideoPopoverActionSvg;
