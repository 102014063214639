import {gql} from '@apollo/client';

export const GET_CONTACTS_AND_USERS = gql`
  query GetContactsAndUsers($searchString: String, $limit: Int) {
    contacts(where: {name: {_ilike: $searchString}}, limit: $limit) {
      id
      name
      uuid
    }
    users(where: {name: {_ilike: $searchString}}, limit: $limit) {
      id
      name
      uuid
    }
  }
`;

export default {GET_CONTACTS_AND_USERS};
