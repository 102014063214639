import React, { useState, useMemo, useEffect } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import { EDITOR_ACTIONS, IRichJoditEditorProps } from './interface';

const JoditRichEditor = (props: IRichJoditEditorProps) => {
  const editorRef = React.useRef<Jodit | null>(null);
  const [content, setContent] = useState('');

  const placeholder = props?.placeholder || 'Start typing...';
  const config = useMemo(() => ({
    readonly: props?.readonly || false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    disablePlugins: ['add-new-line'],
    statusbar: false,
    border: false,
    toolbarAdaptive: false,
    removeButtons: ['table'],
    toolbarSticky: true,
    showTooltip: true,
    useNativeTooltip: true,
    buttons: [
      ...['bold', 'italic', 'underline', 'strikethrough', 'brush', '|'],
      ...['fontsize', 'font', '|'],
      ...['left', 'center', 'right', 'justify', '|'],
      ...['ul', 'ol', '|'],
      ...['link', 'image', 'table', '|'],
      ...['undo', 'redo', '|'],
    ],
    placeholder: placeholder,
    style: {
      ...(props?.style ? props?.style : {}),
      height: props.height,
    },
  }), [props.height, props.content, props?.readonly, props?.style]);

  useEffect(() => {
    if(editorRef?.current?.editor) {
      props?.editorActions && props?.editorActions(EDITOR_ACTIONS.RESET_HEIGHT, props?.height);
    }
  }, []);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  return (
    <JoditEditor
      ref={(editor) => {
        editorRef.current = editor;
        props?.setRef && props?.setRef(editor);

        if(editorRef?.current?.editor) {
          if(props?.onKeydown) {
            editorRef.current.editor.onkeydown = props.onKeydown;
          }
        }
      }}
      value={content}
      config={config}
      onChange={(newValue) => {
        setContent(newValue);
        props?.onChange && props?.onChange();
      }}
    />
  );
};

export default JoditRichEditor;
