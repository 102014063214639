import {useCallback, useState} from 'react';
import {
  Builder,
  JsonTree,
  Query,
  Utils as QbUtils,
  BuilderProps,
  Config,
  ImmutableTree
} from '@react-awesome-query-builder/antd';
// types
import 'antd/dist/antd.css';
import './QueryBuilder.css';
import {defaultInitTree} from './QueryBuilderHelper';

interface IQueryBuilderProps {
  jsonTree: any;
  config: Config;
  handleJSONTreeStateChange: (displayJSONTree: any) => void;
}
const queryValue: JsonTree = {
  id: QbUtils.uuid(),
  type: 'group',
};

const getDefaultInitTree = ()=> {
  const filteredDefaultChildren = defaultInitTree.children1.filter((tree)=>{
    return tree.properties.field === 'hasTags'
  })
  defaultInitTree.children1 = filteredDefaultChildren;
  return defaultInitTree
}
const QueryBuilder = (props: IQueryBuilderProps) => {
  const initValue =
    props.jsonTree && Object.keys(props.jsonTree).length > 0
      ? props.jsonTree
      : getDefaultInitTree();

  const initTree = QbUtils.checkTree(QbUtils.loadTree(initValue), props.config);
  const [state, setState] = useState({
    tree: initTree,
    config: props.config,
  });

  const onChangeFunction = (immutableTree: ImmutableTree, config: Config) => {
    setState((prevState) => {
      return {...prevState, tree: immutableTree, config: config};
    });
    const displayJSONTree = QbUtils.getTree(immutableTree);

    props.handleJSONTreeStateChange(displayJSONTree);
  };
  const onChange = useCallback(onChangeFunction, []);

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{padding: '10px'}}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );
  return (
    <>
      <Query
        {...props.config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </>
  );
};
export default QueryBuilder;
