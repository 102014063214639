import {
  HStack,
  Pressable,
  Spinner,
  Text,
  Tooltip,
  VStack,
  View,
  useToast,
} from 'native-base';
import {Colors} from '../../../../../../styles';
import React from 'react';
import {handleMarkMentionAsUnread} from '../MessagingUtils';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import {EventBus} from '../../../../../../utils/EventBus';
import {CUSTOM_MESSAGE_EVENT_CODES} from '../../../../../../constants/WebSocketConst';
import AtSignSvg from '../../../../../common/Svg/AtSignSvg';
import Close from '../../../../../../assets/svg/Close';
import { TestIdentifiers, testID } from '../../../../../../testUtils';
// import '../../../../common/ChatUI/MessageBox/Message-box.css';
// import '../../../../common/ChatUI/MessageBox/MessageViewer.css';

export interface MentionBarProps {
  text: string;
  selectedConversationMentionId: string;
  handleClose: () => void;
}

const MentionBar = (props: MentionBarProps) => {
  const {text, handleClose} = props;
  const [state, setState] = React.useState({
    loading: false,
  });
  const toast = useToast();

  const finalText = text
    .replace(/ *\([^)]*\) */g, '')
    .replace(/[\])}[{(]/g, ' ');

  const handleMarkAsUnread = async () => {
    if (!props.selectedConversationMentionId) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const mentionResp = await handleMarkMentionAsUnread([props.selectedConversationMentionId], false);
      if (mentionResp?.data?.updateConversationMentions?.returning?.length) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.MARK_US_UNREAD_MENTION, {
          mentionUuid: props.selectedConversationMentionId,
          isRead: false
        })
      }
      handleClose();
      showToast(toast, 'Marked as unread', ToastType.success);
    } catch (error) {

      showToast(toast, 'Something went wrong.', ToastType.error);
    }
    setState((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  return (
    <HStack
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Colors.Custom.Gray50,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        paddingHorizontal: 16,
        paddingVertical: 8,
      }}
    >
      <HStack flex={0.5}>
        <VStack
          style={{
            marginRight: 10,
          }}
          {...testID('image')}
        >
          <AtSignSvg size={20}/>
        </VStack>
        <VStack
          style={{
            width: '94%',
            //maxHeight: 70,
            //overflow: 'scroll',
          }}
        >
          <Tooltip label={finalText}>
          <Text
            noOfLines={1}
            style={{
              color: Colors.Custom.Gray500,
              fontWeight: '500',
              fontSize: 14,
              lineHeight: 21,
            }}
            {...testID('MentionTextMessage')}
          >
            {finalText}
          </Text>
          </Tooltip>
        </VStack>
      </HStack>

      <HStack
        flex={0.5}
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {state.loading ? (
          <View
            style={{
              marginRight: 12,
            }}
            {...testID(TestIdentifiers.lazyLoading)}
          >
            <Spinner />
          </View>
        ) : (
          <Pressable
            onPress={() => {
              handleMarkAsUnread();
            }}
            style={{
              marginRight: 12,
            }}
          >
            <Text
              style={{
                color: Colors.Custom.mainPrimaryPurple,
              }}
              {...testID('MarkAsUnread')}
            >
              {'Mark as unread'}
            </Text>
          </Pressable>
        )}

        <VStack
          style={{
            position: 'relative',
            right: 10,
          }}
        >
          <Pressable
            onPress={() => {
              handleClose();
            }}
            {...testID(TestIdentifiers.closeBtn)}
          >
            <Close size={20} fill={Colors.Custom.Gray500}/>
          </Pressable>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default MentionBar;
