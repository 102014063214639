import React from 'react';
import Stack from '../../../../../common/LayoutComponents/Stack';
import NoPinnedWidgetsIconSvg from '../../../../../common/Svg/NoPinnedWidgetsIconSvg';
import NoDataFoundWithCustomIcon from '../../../../../common/NoDataFound/NoDataFoundWithCustomIcon';

export const NoPinnedWidgetView = () => {
  return (
    <Stack
      direction="column"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 40,
      }}
    >
      <NoDataFoundWithCustomIcon
        icon={<NoPinnedWidgetsIconSvg />}
        displayString="noPinnedWidgets"
      />
    </Stack>
  );
};
