import React, { useContext } from 'react';
import { Select } from 'antd';
import { BoardType } from './interfaces';
import { Radio, HStack, Pressable, Text, VStack, View } from 'native-base';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../styles';
import { FilterWrapperComponent } from './FilterWrapperComponent';
import { CommonDataContext } from '../../../../context/CommonDataContext';

const BoardTypeSelector = (props: {
  selectedType: BoardType;
  handleTypeChange: (type: BoardType) => void;
}) => {
  const { selectedType, handleTypeChange } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = !!mlovData.sidecarContext?.isSidecar;
  const availableTypes = [BoardType.Status, BoardType.Priority, BoardType.dueDate].map((key) => ({
    label: key === 'dueDate' ? 'Due Date' : key,
    value: key,
    key: key,
  }));

  return (
    <>
      <FilterWrapperComponent showFilterAsDropDown={isSidecarContext} dropdownMarginLeft={isSidecarContext ? 0 : undefined} label={intl.formatMessage({ id: 'viewBy' })} value={[selectedType === BoardType.dueDate ? 'Due Date' : selectedType]} noJustify={isSidecarContext}>
        <View style={{
          width: 100
        }}>
          <Radio.Group
            name={"BoardTypeSelector"}
            //value={item.value}
            alignItems={'flex-start'}
            value={selectedType}
            onChange={(value) => {
              handleTypeChange(value as BoardType);
            }}
          >
            {availableTypes?.length ?
              availableTypes?.map((item) => {
                return (
                  <Radio
                    key={item.key}
                    value={item.value}
                    alignItems={'center'}
                    style={{marginVertical:10}}
                  >
                    <Text style={{ marginLeft: 8 }} fontSize={16}>
                      {item?.label}
                    </Text>
                  </Radio>
                )
              }) :
              null
            }
          </Radio.Group>
        </View>
        {/* <Select
          bordered={false}
          style={{
            flex: 1,
            height: 38,
            fontSize: 14,
            fontWeight:600,
            color:'black'
          }}

          onChange={(value) => handleTypeChange(value)}
          value={selectedType}
          options={availableTypes}
          size='large'
        /> */}
      </FilterWrapperComponent>
    </>

  );
};

export default BoardTypeSelector;
