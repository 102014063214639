import Stack from '../LayoutComponents/Stack';
import { Colors } from '../../../styles';
import { InputNumber, Select } from 'antd';
import { IInterventionMlovs, IMlov } from '../../../Interfaces';
import { CaretDownOutlined } from '@ant-design/icons';

interface IDateTimeDurationInputProps {
  value: string | undefined;
  durationUnits: IMlov[] | IInterventionMlovs[];
  selectedDurationUnitId: string | undefined;
  onDurationUnitChange?: (unitId: string) => void;
  onValueChange?: (value: string) => void;
  placeholder?: string
  borderColor?: string
  borderWidth?: number
  suffixIcon?: JSX.Element;
  inputFieldWidth?: number
}

export const DateTimeDurationInput = (props: IDateTimeDurationInputProps) => {
  const {
    durationUnits,
    selectedDurationUnitId,
    value,
    onValueChange,
    onDurationUnitChange,
    placeholder,
    borderColor,
    borderWidth,
    suffixIcon,
    inputFieldWidth = 32
  } = props;

  const getDurationOptions = () => {
    return durationUnits.map((unit) => {
      return {
        value: unit.id,
        label: unit.value,
      };
    });
  };

  return (
    <Stack
      direction="row"
      style={{
        borderColor: borderColor || Colors.Custom.PrimaryColor,
        borderWidth: borderWidth || 0.5,
        backgroundColor: 'white',
        borderRadius: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <InputNumber
        value={value}
        style={{
          width: inputFieldWidth,
        }}
        placeholder={placeholder}
        controls={false}
        min={"0"}
        size='small'
        bordered={false}
        onChange={(value) => {
          onValueChange && value && onValueChange(value.toString());
        }}
      />
      <Select
        style={{
          borderWidth: 0,
          backgroundColor: 'white'
        }}
        size='small'
        {...(suffixIcon && { suffixIcon: suffixIcon })}
        bordered={false}
        options={getDurationOptions()}
        value={selectedDurationUnitId}
        onSelect={onDurationUnitChange}
      />
    </Stack>
  );
};
