import {Center, Image, ScrollView, Spinner, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import PhoneCallSvg from '../Svg/PhoneCallSvg';
import SmsSvg from '../Svg/SmsSvg';
import {ICallAndSmsNumberListView, INumberList} from './interface';
import { styles } from './Styles';
import {canCreateSMSInboxWithPhoneNumber} from '../../../utils/commonUtils';
import { TestIdentifiers, testID } from '../../../testUtils';
import { useLazyQuery } from '@apollo/client';
import { ASSIGN_TYPE } from '../../RightSideContainer/CloudTelephony/CloudTelePhoneyConst';
import VirtualPhoneNumberQueries from '../../RightSideContainer/TeamInbox/CallLogs/VirtualPhoneNumberQueries';
import { Pressable } from 'react-native';
import { AntdSpin } from './AntdSpin';

const CallAndSmsNumberListView = (props: ICallAndSmsNumberListView) => {
  const {numberList, onActionPerformed, isHideSms, defaultNumber} = props;

  const [stateData, setStateData] = useState({
    numberList: [] as INumberList[],
    loading: false,
  });

  const [GetVirtualPhoneNumberAssigneeNames] = useLazyQuery(
    VirtualPhoneNumberQueries.GetVirtualPhoneNumberAssigneeNames,{
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const itemIndex = numberList.findIndex((number) => {
      return number?.phoneNumber === defaultNumber?.phoneNumber;
    });
    const updatedList = (numberList || []).filter((item) =>
      canCreateSMSInboxWithPhoneNumber(item?.phoneNumber)
    );
    if (itemIndex !== -1) {
      const itemToMove = updatedList?.splice(itemIndex, 1)[0];
      updatedList?.unshift(itemToMove);
    }
    const userList: (string | undefined)[] = [];
    const locationList: (string | undefined)[] = [];
    updatedList?.forEach((user) => {
      if (user?.assigneeType === ASSIGN_TYPE.USER && user?.assigneeId) {
        userList.push(user?.assigneeId);
      } else if (user?.assigneeType === ASSIGN_TYPE.CLINIC && user?.assigneeId) {
        locationList.push(user?.assigneeId);
      }
    })

    async function getData() {
      const responseData = await GetVirtualPhoneNumberAssigneeNames({
        variables: {
          userUuids: userList,
          locationUuids: locationList
        },
      })
      .then((response) => {
        const { users, accountLocations } = response.data;
        updatedList?.forEach((assignee) => {
          if (assignee.assigneeType === ASSIGN_TYPE.USER) {
            const foundUser = users.find((user: { uuid: string | undefined; }) => user.uuid === assignee.assigneeId);
            if (foundUser) {
              assignee.name = foundUser.name;
            }
          } else if (assignee.assigneeType === ASSIGN_TYPE.CLINIC) {
              const foundLocation = accountLocations.find((location: { uuid: string | undefined; }) => location.uuid === assignee.assigneeId);
              if (foundLocation) {
                assignee.name = foundLocation.practiceLocation.name;
              }
          }
        });
        setStateData((prev) => {
          return {
            ...prev,
            numberList: updatedList,
            loading: false
          }
        });
      })
      .catch((error) => {
        setStateData((prev) => {
          return {
            ...prev,
            numberList: [],
            loading: false
          }
        });
      });
    }

    getData();
  }, []);

  const getNumberListItemUI = (item: INumberList, index: number) => {
    return (
      <View key={index}>
        <Text
          {...testID('From')}
          style={{color: Colors.Custom.Gray400}}
          size={'exsMedium'}
        >
          From
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 6,
            paddingBottom: 8,
            borderBottomWidth: 1,
            borderColor: Colors.Custom.Gray200,
            justifyContent: 'space-between',
          }}
        >
          <View flex={1}>
            <Text
              size={'smMedium'}
              style={{
                marginHorizontal: 4,
                color: Colors.Custom.Gray700,
              }}
              {...testID(TestIdentifiers.name)}
            >
              {item.name}
            </Text>
            <Text
              size={'xsMedium'}
              style={{
                marginHorizontal: 4,
                color: Colors.Custom.Gray400,
              }}
              {...testID(TestIdentifiers.phoneNumber)}
            >
              {item.phoneNumber}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Pressable
              onPress={() => {
                if (item.isCallPossible) {
                  onActionPerformed(COMMON_ACTION_CODES.CALL, {
                    fromNumber: item.phoneNumber,
                  });
                }
              }}
              style={{marginHorizontal: 4, zIndex: 999}}
              {...testID(TestIdentifiers.callBtn)}
            >
              <View style={{width: 40, height: 40}}>
                {isWeb() ? (
                  <PhoneCallSvg
                    isEnabled={item.isCallPossible}
                    customStrokeColor={
                      item.isCallPossible
                        ? Colors.secondary['800']
                        : Colors.Custom.Gray300
                    }
                    customBgColor={
                      item.isCallPossible
                        ? Colors.secondary['100']
                        : Colors.Custom.Gray100
                    }
                  />
                ) : (
                  <Image
                    resizeMode="contain"
                    size={'40px'}
                    source={
                      item.isCallPossible
                        ? require('../../../assets/images/PNGImages/call_start.png')
                        : require('../../../assets/images/PNGImages/call_start_disabled.png')
                    }
                    alt="image"
                    key={`${item.isCallPossible}`}
                  />
                )}
              </View>
            </Pressable>
            {isHideSms ? (
              <></>
            ) : (
              <Pressable
                style={{marginHorizontal: 4}}
                onPress={() => {
                  if (item.isSmsInbox) {
                    onActionPerformed(COMMON_ACTION_CODES.SMS, {
                      smsInboxId: item.smsInboxId,
                    });
                  }
                }}
                {...testID(TestIdentifiers.smsBtn)}
              >
                <View style={{width: 36, height: 36}}>
                  {isWeb() ? (
                    <SmsSvg
                      key={index}
                      isEnabled={item.isSmsInbox}
                      customStrokeColor={
                        item.isSmsInbox
                          ? Colors.secondary['800']
                          : Colors.Custom.Gray300
                      }
                      customBgColor={
                        item.isSmsInbox
                          ? Colors.secondary['100']
                          : Colors.Custom.Gray100
                      }
                    />
                  ) : (
                    <Image
                      resizeMode="contain"
                      size={'40px'}
                      source={
                        item.isSmsInbox
                          ? require('../../../assets/images/PNGImages/message_start.png')
                          : require('../../../assets/images/PNGImages/message_start_disabled.png')
                      }
                      alt="image"
                      key={`${item.isSmsInbox}`}
                    />
                  )}
                </View>
              </Pressable>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <ScrollView style={{maxHeight: 400}} {...testID(TestIdentifiers.scrollListView)}>
      {stateData.loading ? (
        <Center {...testID(TestIdentifiers.pageLoading)}>
          {isWeb() ? <AntdSpin/> : <Spinner/>}
        </Center>
      ) : stateData?.numberList.length ? (
        stateData?.numberList.map((item, index) => {
          if (item.isSmsInbox || item.isCallPossible) {
            return (
              <>
                {isWeb() ? (
                  getNumberListItemUI(item, index)
                ) : (
                  <Pressable
                    onPress={() => {
                      if (item.isCallPossible) {
                        onActionPerformed(COMMON_ACTION_CODES.CALL, {
                          fromNumber: item.phoneNumber,
                        });
                      }
                    }}
                    style={{marginHorizontal: 4, zIndex: 999}}
                    {...testID(TestIdentifiers.callBtn)}
                  >
                    {getNumberListItemUI(item, index)}
                  </Pressable>
                )}
              </>
            );
          }
        })
      ) : (
        <Text {...testID(TestIdentifiers.noDataFound)}>No Data Found</Text>
      )}
    </ScrollView>
  );
};

export default CallAndSmsNumberListView;
