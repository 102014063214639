import {useLazyQuery} from '@apollo/client';
import {HStack, Pressable} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {GROUP_MEMBER_TYPE, PARENT_CODE} from '../../../constants';
import {WEB_SOCKET_EVENT_CODE} from '../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ILoginUserData} from '../../../Interfaces';
import {UserQueries} from '../../../services';
import {getProfileImage} from '../../../services/ProfileImage/ProfileImage';
import {Colors} from '../../../styles';
import {
  getAccountId,
  getCurrentUserRoleCodes,
  getUserId,
} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import LocalStorage from '../../../utils/LocalStorage';
import AddEditUser from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUser';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import CustomSwtich from '../CustomSwtich/CustomSwtich';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import ProfileViewModal from './ProfileViewModal';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const {Device} = require('twilio-client');
const ProfileView = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const currentUserRoles = getCurrentUserRoleCodes();
  const userId = getUserId();
  const [userEditData, setUserEditData] = useState({
    showUserEdit: false,
    userInfo: [] as any,
    id: userData.uuid,
    name: userData.name,
    profileImage: userData.avatar_url.includes('404')
      ? ''
      : userData.avatar_url,
    email: userData.email,
    showProfileModal: false,
  });

  const onCloseAddEditDrawer = () => {
    setUserEditData({...userEditData, showUserEdit: false});
  };

  const [getUsersData] = useLazyQuery<IUsersResponse>(
    UserQueries.GET_USERS_BY_ID,
    {
      variables: {
        accountId: getAccountId(),
        userUuid: userData.uuid,
      },
    }
  );
  const updateProfileImage = (data: any) => {
    setUserEditData({
      ...userEditData,
      profileImage: data.profileImage,
      name: data.name,
    });
  };
  const setProfileImageAndName = async () => {
    const userRes: any = await getProfileImage(userData.name, userData.email);
    if (userRes?.data?.avatar_url) {
      setUserEditData((prev) => {
        return {
          ...prev,
          profileImage: userRes?.data?.avatar_url.includes('404')
            ? ''
            : userRes?.data?.avatar_url,
          name: userRes?.data?.available_name,
        };
      });
    }
  };
  const onCompleteAddEditDrawer = (data: any) => {
    setProfileImageAndName();
    setUserEditData({...userEditData, showUserEdit: false});
  };
  const fetchUserData = async () => {
    const data = await getUsersData();
    // let roles = '';
    // data?.data?.users[0].userRoles?.forEach((element: any) => {
    //   roles += element?.userRole?.userRole?.code;
    // });
    setUserEditData({...userEditData, userInfo: data?.data?.users[0]});
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(updateProfileImage);

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE,
      updateProfileImage
    );
    fetchUserData();
    return () => {
      eventBus.removeEventListener(updateProfileImage);
    };
  }, [location]);

  const userSignOut = (): void => {
    Device.destroy();
    navigate('/login', { state: {logout: true}})
  };

  const onActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case 'PROFILE':
        setUserEditData({
          ...userEditData,
          showUserEdit: true,
          showProfileModal: false,
        });
        break;
      case 'LOGOUT':
        userSignOut();
        break;
      case 'CLOSE':
        setUserEditData({...userEditData, showProfileModal: false});
        break;
    }
  };

  useEffect(() => {
    setProfileImageAndName();
  }, []);

  return (
    <>
      <HStack
        style={{
          paddingLeft: 12,
          borderLeftWidth: 1,
          borderLeftColor: Colors.Custom.Gray200,
          alignItems: 'center',
        }}
      >
        <CustomSwtich />
        <Pressable
          onPress={() =>
            setUserEditData({...userEditData, showProfileModal: true})
          }
        >
          {/* <Avatar
    alignSelf="center"
    size="9"
    width={38}
    bg={getAvatarColorInHsl(userPopoverData.userFullName)}
  >
    {getInitialsFromFullName(userPopoverData.userFullName)}
  </Avatar> */}
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
            }}
            isLetterAvatarShow
            userData={{
              userId: userId,
              userType: GROUP_MEMBER_TYPE.USER,
              imgSrc: userEditData?.profileImage,
              roles: currentUserRoles,
              userName: userEditData?.name
            }}
          />
        </Pressable>
      </HStack>
      {userEditData.showProfileModal && (
        <ProfileViewModal
          userEditData={userEditData}
          onActionPerformed={onActionPerformed}
        />
      )}

      {userEditData.showUserEdit && (
        <AddEditUser
          roleIsDisable
          onClose={(actionCode, data) => {
            if (actionCode === 'COMPLETE') {
              onCompleteAddEditDrawer(data);
              return;
            }
            onCloseAddEditDrawer();
          }}
          user={{
            id: userData.id,
            email: userEditData.email,
            name: userEditData.name,
            avatar_url: userEditData.profileImage,
            userRoles: userEditData.userInfo.userRoles,
            uuid: userData.uuid,
          }}
          parentCode={PARENT_CODE.SIDE_MENU_PANEL}
        />
      )}
    </>
  );
};
export default ProfileView;
