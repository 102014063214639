import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {IPatientNoteCardProps} from '../interfaces';
import {IFormState} from '../PatientNotes/components/DocumentationView';
import {getCapabilitiesFormComponents} from '../PatientNotes/components/DocumentationViewHelper';
import {getResourceAbilities} from '../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import Header from '../PatientNotes/components/Header/Header';
import {DocStatus} from '../PatientNotes/interfaces';
import {getTitle} from '../PatientNotes/components/AddOrUpdateTemplate/AddOrUpdateTemplateHelper';
import {ScrollView} from 'native-base';
import {renderContent} from '../PatientNotes/components/ReadOnlyDocumentView/Helper';
import { isWeb } from '../../../../utils/platformCheckUtils';

const ReadOnlyPatientNoteView = (props: {
  cardData: IPatientNoteCardProps;
  ehrCapabilities: any;
  handleGoBack: () => void;
}) => {
  const {cardData} = props;
  const [formState, setFormState] = useState<IFormState>();
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  useEffect(() => {
    setFormState(
      getCapabilitiesFormComponents(elationFormData, cardData.formId || '', {
        formContent: cardData.formContent,
        formName: cardData.formName,
      })
    );
  }, []);
  const isSignedNote = () => cardData?.status === DocStatus.FINAL;
  return (
    <View>
      {isWeb() && (
        <Header
          showBackButton
          form={{name: formState?.name || '', id: formState?.id || ''}}
          showTemplateSelection={!cardData?.resourceId}
          backButtonAction={() => {
            props.handleGoBack();
          }}
          title={getTitle(
            isSignedNote(),
            cardData.createdByUserName || '',
            cardData.signedByUserName || '',
            cardData.formName || '',
            cardData.status || '',
            cardData.signedByUserId || '',
            cardData.authorUuid
          )}
          currentTemplateId={formState?.id}
        />
      )}

      <ScrollView flex={isWeb() ? 1 : undefined} h="100%" mx={5} mt={5}>
        {formState && renderContent(formState)}
      </ScrollView>
    </View>
  );
};

export default ReadOnlyPatientNoteView;
