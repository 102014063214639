import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ITaskCategory, ITaskMetaData, MultiSelectAction, MultiSelectState } from '../../TaskInterfaces'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { Checkbox, Table, TableColumnsType } from 'antd';
import useTaskCountManager, { ITaskCount } from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import { Badge, HStack, Spinner, View, Text, Spacer, Pressable, Icon, VStack } from 'native-base';
import TaskTableView from './TaskTableView';
import { Colors } from '../../../../styles';
import { DisplayUserList } from '../../../common/DisplayUserList/DisplayUserList';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../../common/CareDashboard/CareDashboardConstants';
import { EventBus } from '../../../../utils/EventBus';
import { BoardType } from '../../../common/CareDashboard/CareDashboardTopBar/interfaces';
import AddTaskIcon from '../../../../assets/Icons/AddTaskIcon';
import { IUserPool } from '../../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import { DisplayUserListDrawer } from '../../../common/DisplayUserList/DisplayUserListDrawer';
import { Tooltip } from 'native-base';
import './TaskListView.css'
import { getTodayDueTaskTextLabel } from '../../../TaskCard/TaskHelper';
import { TASK_DUE_DATE_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import OpenedCollapseSvg from '../../../common/Svg/SideCarSvg/OpenedCollapseSvg';
import ClosedCollapseSvg from '../../../common/Svg/SideCarSvg/ClosedCollpaseSvg';
import { TaskPanelTypeCode } from '../../../TaskCard/TaskEnum';

interface ITaskNestedTableViewProps {
  categories: ITaskCategory[];
  metaData: ITaskMetaData;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
  onTaskCountUpdate?: (taskCount: ITaskCount[]) => void;
}

const TaskNestedTableView = (props: ITaskNestedTableViewProps) => {
  const {categories, metaData, onActionPerformed, onTaskDetail, onMemberClick} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const eventBus = EventBus.getEventBusInstance();
  const isTaskPoolBoard = metaData.boardType === BoardType.taskPool;
  const accountUsers = metaData.masterUsersList.map((item) => {
    return {
      id: parseInt(item.id),
      name: item.name,
      uuid: item.uuid,
      email: item.email,
    }
  });

  // States
  const [multiSelectState, setMultiSelectState] = useState<{[index: string]: MultiSelectState}>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [viewTaskPoolUsersState, setViewTaskPoolUsersState] = useState<{
    userPool?: IUserPool;
    userIds: string[];
    isOpen: boolean;
    defaultUserId?: string;
  }>({
    userIds: [],
    isOpen: false,
    defaultUserId: undefined,
  });
  const { taskCount, todaysTaskCount } = useTaskCountManager({
    params: categories,
    isFetchTodaysCount: true
  });

  // Lifecycle methods
  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onCategoryMultiStateChange, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onCategoryMultiStateChange, { moduleCode: TASK_MODULE_CODE })
    }
  }, [])

  useEffect(() => {
    if (categories?.[0]?.filters?.searchString) {
      const keys = categories.map((item) => {
        return item.code
      });
      setExpandedRowKeys(keys);
    }
  }, [categories]);

  useEffect(() => {
    props?.onTaskCountUpdate?.(taskCount)
    // If any column is already expanded then do not update expandedRows
    const keys = categories.map((item) => item.code);
    const filteredExpandedRowKeys = expandedRowKeys.filter(key => keys.includes(key));
    if (filteredExpandedRowKeys.length) {
      return;
    }
    let findCode = '';
    (taskCount || []).find((countData, index: number) => {
      if (countData.count > 0) {
        findCode = countData.code;
        return true;
      }
    });
    const defaultRow = categories.find(item => item.code === findCode);
    if (defaultRow) {
      onTableRowExpand(true, defaultRow);
    }
  }, [taskCount]);

  // Table column
  const categoryColumns: TableColumnsType<any> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, record: any) => {
        const primaryId = record?.id || record?.code;
        const countData = taskCount.filter(item => item.code === primaryId)?.[0];
        const todaysCountData = todaysTaskCount.filter(item => item.code === primaryId)?.[0];
        const multiState = multiSelectState?.[primaryId];

        // Pool specific code
        let userIds: string[] = [];
        let defaultUserId: string | undefined = undefined;
        const userPool = metaData.masterPoolList.find(item => item.id === primaryId);
        if (isTaskPoolBoard && userPool?.userPoolUsers?.length) {
          userIds = userPool?.userPoolUsers?.map((userPoolUser) => {
            if (userPoolUser.isDefault) {
              defaultUserId = userPoolUser.userId;
            }
            return userPoolUser.userId;
          });
        }

        return (
          <HStack space={2} alignItems="center" key={primaryId + '-name'}>
            {isSidecarContext && metaData.bulkTaskEnabled && (
              <Pressable
                key={record.name + '-checkbox'}
                onPress={(event) => {
                event.stopPropagation();
              }}>
                <View marginLeft={-1}>
                  <Checkbox
                    style={{marginTop: 1, color: Colors.Custom.SuccessColor}}
                    indeterminate={multiState === MultiSelectState.some}
                    checked={multiState === MultiSelectState.all}
                    onChange={async (event) => {
                      const eventBus = EventBus.getEventBusInstance();
                      eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, {
                        categoryCode: primaryId,
                        bulkAction: event.target.checked ? MultiSelectAction.selectAllTasks : MultiSelectAction.unSelectAllTask
                      });
                      event.stopPropagation();
                    }}
                  />
                </View>
              </Pressable>
            )}
            <View key={record.name + '-name'}>
              <Text style={{ color: Colors?.FoldPixel?.GRAY300, fontWeight: '600', fontSize: 14, lineHeight: 16.8 }}>{record.name} </Text>
            </View>
            {expandedRowKeys?.includes(record?.key) ? <OpenedCollapseSvg /> : <ClosedCollapseSvg />}
            <Badge
              key={record.name + '-badge'}
              fontWeight={500}
              backgroundColor={
                isSidecarContext ? Colors.FoldPixel.GRAY50 : '#F2F4F7'
              }
              _text={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 14,
              }}
              alignSelf="center"
              rounded={isSidecarContext ? '' : "full"}
              borderRadius={isSidecarContext ? 4 : null}
              justifyContent={'center'}
              style={{
                paddingHorizontal: 6,
                paddingVertical: 2.5,
              }}
            >
              <Text color={Colors.FoldPixel.GRAY300} fontSize={'xs'}>
                {getTodayDueTaskTextLabel(todaysCountData?.count, countData?.count, record.code, isTaskPoolBoard)}
              </Text>
            </Badge>
            <Spacer />
            {isTaskPoolBoard &&
              <>
                <Tooltip label={'Show all users'} placement={'top'} >
                  <View key={record.name + '-users'}>
                    <DisplayUserList
                      userIds={userIds}
                      accountUsers={accountUsers}
                      showAsAvatarGroup={true}
                      defaultUserId={defaultUserId}
                      maxAvatarGroupUsers={5}

                      onClick={() => {
                        setViewTaskPoolUsersState((prev) => ({
                          ...prev,
                          isOpen: true,
                          userIds,
                          userPool,
                          defaultUserId,
                        }));
                      }}
                    />
                  </View>
                </Tooltip>
                <View
                  style={{
                    width: 1,
                    height: 20,
                    backgroundColor: Colors.Custom.Gray200,
                    marginHorizontal: 8,
                  }}
                />
                <Tooltip label={'Add Task'} placement={'top'} hasArrow={true} key={record.name + '-add-task'}>
                  <Pressable
                    key={record.value}
                    onPress={() => {
                      eventBus.broadcastEvent(TASK_EVENTS.OPEN_TASK_DETAIL, { taskPool: { value: primaryId, key: primaryId, label: record.name } });
                    }}
                    alignItems={'center'}
                    justifyContent="center"
                    style={{
                      height: 36,
                      marginLeft: 5,
                      marginRight: 6,
                    }}
                  >
                    <Icon color={Colors.FoldPixel.GRAY200} as={<AddTaskIcon />} />
                  </Pressable>
                </Tooltip>
              </>
            }
          </HStack>
        );
      },
    },
  ];

  // Other methods
  const onCategoryMultiStateChange = useCallback((data: { categoryCode: string; state: MultiSelectState }) => {
    if (data?.categoryCode && data?.state) {
      setMultiSelectState((prev) => {
        return {
          ...prev,
          [data?.categoryCode]: data?.state,
        }
      })
    }
  }, []);

  const onTableRowExpand = (expanded: boolean, record: ITaskCategory) => {
    setExpandedRowKeys((prev) => {
      if (expanded && !prev.includes(record.code)) {
        prev.push(record.code);
      } else {
        prev = prev.filter(item => item !== record.code);
      }
      return [...prev];
    });
  }

  const expandedRowRender = (rawData: ITaskCategory) => {
    return (
      <TaskTableView
        category={rawData}
        metaData={metaData}
        onTaskDetail={onTaskDetail}
        onMemberClick={onMemberClick}
        onActionPerformed={onActionPerformed}
      />
    );
  };

  const getDisplayCategoriesForView = () => {
    return categories.filter(item => (item.code !== TaskPanelTypeCode.INTERNAL) && (item.code !== TaskPanelTypeCode.PATIENT))
  }


  return (
    <View>
      {taskCount.length > 0 ? (
        <View style={{overflow:"hidden"}}>
          <Table
            rowKey={(row) => row?.key || row?.code}
            className='task-nested-table'
            columns={categoryColumns}
            showHeader={false}
            expandable={{
              expandedRowRender,
              expandedRowKeys,
              onExpand: onTableRowExpand,
              showExpandColumn: false,
              expandIcon: () => <></>,
              expandRowByClick: true,
            }}
            dataSource={getDisplayCategoriesForView()}
            pagination={false}
          />
      </View>
      ) : (
        <View
          flex={1}
          margin={4}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Spinner size={'lg'} />
        </View>
      )}
      {viewTaskPoolUsersState.isOpen && (
        <DisplayUserListDrawer
          userIds={viewTaskPoolUsersState.userIds}
          accountUsers={accountUsers}
          isOpen={viewTaskPoolUsersState.isOpen}
          defaultUserId={viewTaskPoolUsersState.defaultUserId}
          onClose={() => {
            setViewTaskPoolUsersState((prev) => ({ ...prev, isOpen: false, userIds: [], userPool: undefined}))
          }}
        >
          <VStack space={1}>
            <Text color={Colors.Custom.Gray500} fontSize={'xs'}>{`${viewTaskPoolUsersState.userIds?.length} user(s) under task pool`}</Text>
            <Text fontWeight={'bold'} fontSize={'lg'}>{viewTaskPoolUsersState.userPool?.name}</Text>
          </VStack>
        </DisplayUserListDrawer>
      )}
    </View>
  )
}

export default TaskNestedTableView
