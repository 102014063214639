import { Drawer } from 'antd';
import {
  HStack, Icon, Pressable, Text, useToast, View,
  VStack
} from 'native-base';
import React, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../styles/Colors';
import { getAccountUUID } from '../../../utils/commonUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { capitalizeText } from '../../common/ContactRelationView/ContactRelationUtils';
import { DisplayText } from '../../common/DisplayText/DisplayText';
import { NoTagsSvg } from '../../common/Svg';
import GroupIcon from '../../common/Svg/GroupIcon';
import GroupIconOutline from '../../common/Svg/GroupIconOutline';
import GroupListIcon from '../../common/Svg/GroupListIcon';
import { CampaignSegmentForm } from '../../RightSideContainer/ContentManagement/CampaignManagement/CampaignSegmentForm';
import { ICampaignSegmentFormState } from '../../RightSideContainer/ContentManagement/CampaignManagement/interfaces';
import { getPreFilterMemberStatus, patientFilterForCreateSegment } from '../../RightSideContainer/MembersManagement/Helper';
import {
  createCampaignSegment, getCampaignSegmentRules, getGroupDetailById, getMemberGroupListByContactId
} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getFrequencyValue} from '../../RightSideContainer/ContentManagement/CampaignManagement/utils';

export const MemberGroupList = (props: any) => {
  const [groupList, setGroupList] = useState([] as any);
  const {width, height} = Dimensions.get('window');
  const finalWidth = width * 0.5;
  const [openModal, setOpenModal] = useState({
    segmentData: {} as any,
    isOpen: false,
  });
  const intl = useIntl()
  const toast = useToast();
  const accountUuid = getAccountUUID();
  useEffect(() => {
    getGroupList();
  }, [props.contactId]);

  const getGroupList = async () => {
    const result = await getMemberGroupListByContactId({
      contactId: props.contactId,
      accountUuid: accountUuid
    });
    const groupList = (result?.data.filter((group: any) => !!group.name) || []);
    setGroupList(groupList);
  };



  const updateGroupContact = (
    campaignSegmentFormData: ICampaignSegmentFormState
  ) => {
    createCampaignSegment({
      id: openModal?.segmentData?.id,
      name: campaignSegmentFormData?.audienceName || '',
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...!campaignSegmentFormData.patientList?.length && (getPreFilterMemberStatus(campaignSegmentFormData))
    }).then(async (resp: any) => {
      if (resp?.id) {
        let popGroupResponse = resp;
        if (resp?.inputRuleId) {
          popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
        }
        showToast(toast,  resp.type == 'DYNAMIC' ?
        `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
        : intl.formatMessage({id: 'createGroupSuccessMessage'}), ToastType.success);
        setOpenModal({
          segmentData: null,
          isOpen: false,
        });
        getGroupList();
      }
    }).catch((error)=>{
      showToast(toast, (error?.response?.data?.message ||  intl.formatMessage({id: 'apiErrorMsg'})), ToastType.error);
    });
  };
  const renderContent = () => {
    return (
      <View
        style={[
          {
            backgroundColor: '#ffffff',
            marginHorizontal: 24,
            borderRadius: 8,
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
            paddingHorizontal: 16,
            paddingVertical: 12,
          },
        ]}
      >
        {!groupList?.length && <NoTagsSvg titleId='noGroupsAvailable' />}
        {groupList?.map((data: any, index: number) => {
          return (
            <VStack key={`groupList_${data?.ruleId}_${index}`}
              borderTopColor={'gray.100'} borderTopWidth={!index ? 0 : 1}>
              <Pressable
                onPress={async () => {
                  if(!data.ruleId || !data.accountUuid){
                    return;
                  }
                  const groupDataResponse = await getGroupDetailById(data.ruleId);
                  setOpenModal({
                    segmentData: groupDataResponse?.data,
                    isOpen: true,
                  });
                }}
              >
                <HStack alignItems={'center'} flex={1} marginBottom={1} marginY={2}>
                  <GroupIconOutline />
                  <VStack style={{marginLeft: 16,width:"80%"}}>
                    <Text
                      flex={1}
                      fontSize={16}
                      color={Colors.Custom.Gray900}
                      size="mdBold"
                      numberOfLines={1}
                    >
                      {data.name || ''}
                    </Text>
                    <Text
                      flex={1}
                      fontSize={14}
                      color={Colors.Custom.Gray900}
                      size="smNormal"
                    >
                      {'-'}
                    </Text>
                  </VStack>
                  {isWeb() && (
                    <VStack
                      marginRight={0}
                      justifySelf={'flex-end'}
                      alignItems={'flex-end'}
                      flex={1}
                    >
                      <Icon
                        as={AntIcon}
                        name="right"
                        size="3"
                        alignSelf={'flex-end'}
                        marginRight={0}
                      />
                    </VStack>
                  )}
                </HStack>
              </Pressable>
            </VStack>
          );
        })}
      </View>
    );
  };

  return (
    <>
      <View
        style={[
          {
            backgroundColor: Colors.Custom.Gray50,
            shadowColor: `rgba(16, 24, 40, 0.06)`,
            shadowRadius: 1,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          },
        ]}
      >
        <View style={{padding: 24}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 4,
              }}
            >
              <View style={{marginRight: 10}}>
                <GroupIcon />
              </View>
              <DisplayText size={'xlBold'} textLocalId={'memberGroups'} />
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
      <>
        {isWeb() && openModal.isOpen && (
          <Drawer
            width={finalWidth}
            visible={openModal?.isOpen}
            closeIcon={null}
            closable
            // title={onDrawerHeader(openModal?.type)}
            maskClosable={true}
            destroyOnClose
            onClose={() => {
              setOpenModal({
                segmentData: null,
                isOpen: false,
              });
            }}
          >
            <CampaignSegmentForm
              // campaignDetailsError={campaignDetailsError}
              initData={{
                audienceName: openModal?.segmentData?.name || '',
                patientList:
                  openModal?.segmentData?.rule?.patientFilter?.contactIds ||
                  [],
                jsonTree:openModal?.segmentData?.displayJsonTree,
                description: openModal?.segmentData?.description,
                executionFrequency: openModal?.segmentData?.executionFrequency,
                memberStatusCodeFromResponseWhenEditingGroup: openModal?.segmentData?.preFilter?.hintMembershipStatus?.params?.value
              }}
              onModalClose={() => {
                setOpenModal({
                  segmentData: null,
                  isOpen: false,
                });
              }}
              onSubmitForm={(
                campaignSegmentFormData: ICampaignSegmentFormState
              ) => {
                updateGroupContact(campaignSegmentFormData);
              }}
              editing={true}
            />
          </Drawer>
        )}
      </>
    </>
  );
};
