import {HStack, Text} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';

function WriteInfoBackBanner(props: {
  message?: string;
}) {
  const intl = useIntl();
  return (
    <HStack
      backgroundColor={Colors.Custom.infoBannerBgColor}
      rounded={'lg'}
      p={2}
      mb={2}
    >
      <Feather name="info" size={20} color={Colors.Custom.infoColor} />
      <Text ml={2} fontWeight={200}>{intl.formatMessage({id: props.message || 'writeBackInfoMessage'})}</Text>
    </HStack>
  );
}

export default React.memo(WriteInfoBackBanner);
