import {Dropdown, Menu} from 'antd';
import {Button, View, Text, HStack, Icon} from 'native-base';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles/Colors';
import {IPersonCommunicationDetail} from '../OrdersInterface';
import AntIcon from 'react-native-vector-icons/AntDesign';
import React from 'react';
import { BUTTON_TYPE, RIGHT_SIDE_CONTAINER_CODE } from '../../../../../constants/StringConst';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';


export interface IDropdownButtonProps {
  title: string;
  onClick?: (key: string) => void;
}

export enum MENU_KEYS {
  sendEmail = 'sendEmail',
  meeting = 'meeting',
  sendSMS = 'sendSMS',
  phoneCall = 'phoneCall',
  chat = 'chatWithPatient',
}

export const CustomSendCommunicationButton = (
  props: IPersonCommunicationDetail,
) => {
  const intl = useIntl();
  const phoneNumberExist = () => props?.contactData?.phoneObj?.value || props?.contactData?.phone;
  const emailExist = () => props?.contactData?.emailObj?.value || props?.contactData?.email;
  const showMeeting = () => phoneNumberExist() || emailExist();
  const onActionHandler = (key: string) => {
    let actionCode = '';
    switch (key) {
      case MENU_KEYS.sendEmail:
        actionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP;
        break;
      case MENU_KEYS.sendSMS:
        actionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER;
        break;
      case MENU_KEYS.chat:
        actionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_CHAT_DRAWER;
        break;
      case MENU_KEYS.meeting:
        actionCode = RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW;
        break;
    }

    if (actionCode && actionCode?.length && props?.onActionPerformed && typeof props.onActionPerformed === 'function') {
      props?.onActionPerformed(actionCode);
    }
  }

  const actionMenu = () => {
    return (
      <Menu
        style={{marginTop: 10}}
        items={[
          {
            key: MENU_KEYS.sendEmail,
            label: intl.formatMessage({id: 'sendEmail'}),
          },
          phoneNumberExist() && {
            key: MENU_KEYS.sendSMS,
            label: intl.formatMessage({id: 'sendSms'}),
          },
          {
            key: MENU_KEYS.chat,
            label: intl.formatMessage({id: 'startChat'}),
          },
          showMeeting() && {
            key: MENU_KEYS.meeting,
            label: intl.formatMessage({id: 'startMeeting'}),
          },
        ]}
        onClick={(data: any) => {
          onActionHandler(data?.key);
        }}
      />
    );
  };

  return (
    <>
    <View
      style={{
        padding: 5,
        height: 36,
        marginTop: 1,
        paddingTop: 6,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-12px',
      }}>

      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            //
          },
          rightIcon: (
            <Dropdown
              overlay={actionMenu}
              placement="bottomRight"
              overlayStyle={{width: 190,}}
            >
              <Icon color={'#825AC7'} as={AntIcon} name={'down'} size="4" />
            </Dropdown>
          ),
          style: {minWidth: 'auto'}
        }}
        customProps={{
          btnText: props.title,
          withRightBorder: true,
          isDisableOnPress: true
        }}
      />
    </View>
    </>
  );
};
