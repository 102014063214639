import {HStack, Text, View, VStack} from 'native-base';
import {GROUP_MEMBER_TYPE, MESSAGE_ACTION_CONST} from '../../../../../../constants';
import {Colors} from '../../../../../../styles/Colors';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {IGroupMemberListItemProps} from './interfaces';
import React from 'react';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import { getPrimaryGenderCode } from '../../../../../../utils/commonUtils';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { IUser } from '../../../../../../Interfaces';
export const GroupMemberListItem = (props: IGroupMemberListItemProps) => {
  const {memberData, inboxId,taskUserRoles} = props;
  const genderCode = getPrimaryGenderCode(memberData?.contact);

  const getMemberName = (): string => {
    let name = '';
    if (memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
      name = memberData.user?.name || memberData?.name || '';
    } else if (
      memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
      memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      name = memberData.contact?.name || '';
    } else if (inboxId && inboxId !== -1) {
      name = memberData?.user?.name || '';
    }
    return name;
  };
  const getRoles = (user: IUser) => {
    const roleNames =
      user.userRoles?.map((role: any) => role?.userRole?.userRole?.value).filter((value: any) => value != null) || [];
    return roleNames.join(' , ');
  };
  const getMemberData = (): any => {
    let userData = {};
    if (memberData.groupUserType.code === GROUP_MEMBER_TYPE.USER) {
      userData = memberData?.user || {};
    } else if (
      memberData.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT ||
      memberData.groupUserType.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      userData = memberData?.contact || {};
    }
    return userData;
  };
  const getMemberTypeValue = (): string => {
    let memberTypeValue = '';
    if (
      memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.USER ||
      memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
      memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      memberTypeValue = memberData.groupUserType.value || '';
    } else if (inboxId && inboxId !== -1) {
      memberTypeValue = GROUP_MEMBER_TYPE.USER;
    }
    return memberTypeValue;
  };

  const getUserRoles = (member: any) => {
    const userRoleCodes: string[] = [];
    member?.user?.userRoles?.forEach((el: any) => {
      if (el?.userRole?.userRole?.code) {
        userRoleCodes.push(el?.userRole?.userRole?.code);
      }
    });
    return userRoleCodes;
  };

  const getDisplayCardAvatar = (
    singleMemberData: any,
    avatarSize: string
  ): JSX.Element => {
    if (singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.USER || inboxId !== -1) {
      const userRoles = getUserRoles(singleMemberData);
      return (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: 10,
          }}
          isLetterAvatarShow
          userData={{
            userId: singleMemberData?.user,
            userType: GROUP_MEMBER_TYPE.USER,
            roles: userRoles,
            userName: singleMemberData?.user?.name
          }}
        />
      );
    } else if (
      singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
      singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      return (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: 10,
          }}
          isLetterAvatarShow
          userData={{
            userId: singleMemberData?.contact?.id,
            userType: GROUP_MEMBER_TYPE.CONTACT,
            genderCode: genderCode,
            contactType:
              singleMemberData?.contact?.contactType?.contactType?.code,
            userName: singleMemberData?.contact?.name
          }}
        />
      );
    }
    return (
      <DisplayCardAvatar
        avatarStyle={{
          avatarSize: 10,
        }}
        isLetterAvatarShow
        userData={{
          userId: '',
          userType: GROUP_MEMBER_TYPE.CONTACT,
          userName: getMemberName()
        }}
      />
    );
  };
  const getMemberDetailText = (): string => {
    return taskUserRoles ? getRoles(memberData) : getMemberTypeValue();
  };

  return (
    <HStack flex={1} flexDirection={'row'}>
      <VStack justifyContent={'center'}>
        {isWeb()
          ? getDisplayCardAvatar(memberData, '8')
          : getDisplayCardAvatar(memberData, '12')}
      </VStack>
      {isWeb() ? (
        <VStack>
          <HStack>
            <View flex={1} justifyContent={'center'} marginLeft={2}>
              <Text {...testID(TestIdentifiers.name)} size={'smMedium'} color={Colors.Custom.Gray900}>
                {getMemberName()}
              </Text>
            </View>
          </HStack>
          <HStack>
            <View flex={1} justifyContent={'center'} marginLeft={2}>
            <Text {...testID(TestIdentifiers.memberType)} color={Colors.Custom.Gray500}>{getMemberDetailText()}</Text>
            </View>
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <HStack>
            <View
              height={'100%'}
              width={
                props?.parentCode === MESSAGE_ACTION_CONST.UPDATE_MESSAGE
                  ? '100%'
                  : 'auto'
              }
              justifyContent={'center'}
              marginLeft={2}
            >
              <Text {...testID(TestIdentifiers.name)} size={'smMedium'} color={Colors.Custom.Gray900}>
                {getMemberName()}
              </Text>
              <Text {...testID(TestIdentifiers.memberType)} color={Colors.Custom.Gray500} size={'xsMedium'}>
                {getMemberTypeValue()}
              </Text>
            </View>
          </HStack>
        </VStack>
      )}
    </HStack>
  );
};
