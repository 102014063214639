export const TestIdentifiers = {
  lazyLoading: 'LazyLoading',
  pageLoading: 'PageLoading',
  imageLoading: 'ImageLoading',
  videoLoading: 'VideoLoading',
  searchBar: 'SearchBar',
  deleteBtn: 'DeleteBtn',
  editBtn: 'EditBtn',
  printBtn: 'PrintBtn',
  loginUserName: 'LoginUserName',
  loginPassword: 'LoginPassword',
  loginBtn: 'LoginButton',
  sidebar: 'Sidebar',
  createForm: 'CreateForm',
  cancelForm: 'CancelForm',
  formName: 'FormName',
  previewForm: 'PreviewForm',
  saveForm: 'SaveForm',
  closePreview: 'ClosePreview',
  clone: 'Clone',
  basicCard: 'BasicCard',
  backButton: 'BackButton',
  filterButton: 'FilterButton',
  addButton: 'AddButton',
  doneButton: 'DoneButton',
  reasonForVisit: 'ReasonForVisit',
  scheduleButton: 'ScheduleAppointmentButton',
  eventName: 'EventName',
  all: 'All',
  history: 'History',
  comments: 'Comments',
  activity: 'Activity',
  automation: 'Automation',
  sortTaskAudit: 'SortTaskAudit',
  loadMore: 'LoadMore',
  showLess: 'ShowLess',
  copyRightText: 'CopyRightText',
  tnCCheckbox: 'TnCCheckbox',
  tnCLink: 'TnCLink',
  privacyPolicyLink: 'PrivacyPolicyLink',
  showPasswordBtn: 'ShowPasswordBtn',
  forgotPasswordBtn: 'ForgotPasswordBtn',
  forgotPasswordEmail: 'ForgotPasswordEmail',
  submitBtn: 'SubmitButton',
  backToLogin: 'BackToLogin',
  backToLockScreen: 'BackToLockScreen',
  memberShipStatus: 'MemberShipStatus',
  name: 'Name',
  email: 'Email',
  phoneNumber: 'PhoneNumber',
  memberCardSubTitle: 'MemberCardSubTitle',
  location: 'Location',
  checkbox: 'Checkbox',
  memberCardAction: 'MemberCardAction',
  memberShipStatusValue: 'MemberShipStatusValue',
  allAppointment: 'AllAppointmentBtn',
  avatarInitials: 'AvatarInitials',
  groupAppointmentIcon: 'GroupAppointmentIcon',
  appointmentCardContactName: 'AppointmentContactName',
  appointmentCardSubString: 'AppointmentSubString',
  appointmentCardStartTime: 'AppointmentStartTime',
  appointmentCardDuration: 'AppointmentDuration',
  appointmentCardStatus: 'AppointmentStatus',
  appointmentCardUserName: 'AppointmentCardUserName',
  joinMeetingBtn: 'JoinMeetingBtn',
  alertMessage: 'AlertMessage',
  alertBtn: 'AlertBtn',
  headerTitle: 'HeaderTitle',
  calendarDefaultMessage: 'CalendarDefaultMessage',
  noDataViewMessage: 'NoDataViewMessage',
  userName: 'UserName',
  userEmail: 'UserEmail',
  userSelect: 'UserSelect',
  appointmentInfoLabel: 'AppointmentInfoLabel',
  secondaryUserMessage: 'SecondaryUserMessage',
  locationMessage: 'LocationMessage',
  locationErrorMessage: 'LocationErrorMessage',
  appointmentName: 'AppointmentName',
  selectDateLabel: 'SelectDateLabel',
  timeSlotsLabel: 'TimeSlotsLabel',
  dateInfoMessage: 'DateInfoMessage',
  alertTitle: 'AlertTitle',
  cancelAppointmentBtn: 'CancelAppointmentBtn',
  rescheduleAppointmentBtn: 'RescheduleAppointmentBtn',
  checkInAppointmentBtn: 'CheckInAppointmentBtn',
  checkoutAppointmentBtn: 'CheckoutAppointmentBtn',
  unblockAppointmentBtn: 'UnblockAppointmentBtn',
  appointmentDate: 'AppointmentDate',
  searchBtn: 'SearchBtn',
  taskTitle: 'TaskTitle',
  taskDueDate: 'TaskDueDate',
  taskAssignee: 'TaskAssignee',
  taskAssignedBy: 'TaskAssignedBy',
  taskSubTaskCount: 'TaskSubTaskCount',
  taskAttachmentCount: 'TaskAttachmentCount',
  taskADescription: 'TaskADescription',
  viewInstruction: 'ViewInstruction',
  cancelBtn: 'CancelBtn',
  updateBtn: 'UpdateBtn',
  switchAccountBtn: 'SwitchAccountBtn',
  loginBg: 'LoginBackgroundImage',
  foldhealthLogoIcon: 'FoldhealthLogoIcon',
  foldhealthLogoWithName: 'FoldhealthLogoWithName',
  isRequiredInput: 'IsRequiredInput',
  errorMessage: 'ErrorMessage',
  forgotPasswordText: 'ForgotPasswordText',
  welcomeBack: 'WelcomeBackText',
  passwordText: 'PasswordText',
  settingsBtn: 'SettingsBtn',
  conversationTabClick: 'ConversationTabClick',
  selectDropdown: 'SelectDropdown',
  infoBtn: 'InfoBtn',
  closeBtn: 'CloseBtn',
  phiWarning : 'PhiWarning',
  actionMsgHeader: 'ActionMsgHeader',
  actionMsgHeaderString: 'ActionMsgHeaderString',
  actionMsgHeaderDate: 'ActionMsgHeaderDate',
  transitionBtn: 'TransitionBtn',
  voiceRecordPlayBtn: 'VoiceRecordPlayBtn',
  callTrailBtn: 'CallTrailBtn',
  assignType: 'AssignType',
  senderName: 'MessageSenderName',
  messageDateString: 'MessageDateString',
  attachmentView: 'AttachmentView',
  downloadBtn: 'DownloadBtn',
  messageContent: 'MessageContent',
  inputField: 'InputField',
  textAreaField: 'TextAreaField',
  noDataFound: 'NoDataFound',
  viewProfile: 'ViewProfile',
  radioGroup: 'RadioGroup',
  radioButton: 'RadioButton',
  messageFooterInput: 'MessageFooterInput',
  attachmentBtn: 'AttachmentBtn',
  fileName: 'FileName',
  cancelText: 'CancelText',
  memberType: 'MemberType',
  scrollListView: 'ScrollListView',
  cannedResponseText: 'CannedResponseText',
  changeText: 'Change',
  contentViewer: 'ContentViewer',
  webView: 'WebView',
  callBtn: 'CallBtn',
  smsBtn: 'SmsBtn',
  badgeCount: 'BadgeCount',
  badge: 'Badge',
  userImage: 'UserImage',
  subTitle: 'SubTitle',
  role: 'Role',
  practiceLocations: 'PracticeLocations',
  practiceLocationName: 'PracticeLocationName',
  logout: 'Logout',
  messageCommunicationTypesTagRemainingClick: 'MessageCommunicationTypesTagRemainingClick',
  headerInfoClick: 'HeaderInfoClick',
  fileNameClick: 'FileNameClick',
  playBtn: 'PlayBtn',
  viewProfileClick: 'ViewProfileClick',
  failed: 'Failed',
  failedBtnClick: 'FailedBtnClick',
  messageSendBtn: 'MessageSendBtn',
  messageSendIcon: 'MessageSendIcon',
  scheduleIcon: 'ScheduleIcon',
  scheduleBtnClick: 'ScheduleBtnClick',
  scheduleDatePickerView: 'ScheduleDatePickerView',
  datePicker: 'DatePicker',
  messageTime: 'MessageTime',
  threeDots: 'ThreeDots',
  articleTitle: 'ArticleTitle',
  articleLinkBtn: 'ArticleLinkBtn',
  articleDropDown: 'ArticleDropDown',
  sendArticleLinkText: 'SendArticleLinkText',
  enableBtn: 'EnableBtn',
  popGroupEnabledConfirmationMsg: 'popGroupEnabledConfirmationMsg',
  popGroupEnabledConfirmationLabel: 'popGroupEnabledConfirmationLabel',
};

export default TestIdentifiers;
