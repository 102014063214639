import {gql} from "@apollo/client";

export const GET_CONSUMER_CUSTOM_CONTACT_TYPE = gql`
  query GetCustomerCustomContactType($code: String!) {
    customContactTypes(where: {
      code: {
        _eq: $code
      },
      isDefault: {
        _eq: TRUE
      }
    }) {
      id
    }
  }
`

export const GET_CUSTOM_ATTRIBUTE_ID = gql`
  query getCustomAttributeId($key: String) {
    customAttributes(where: {key: {_eq: $key}}) {
      id
      key
    }
  }
`;

export default {
  GET_CONSUMER_CUSTOM_CONTACT_TYPE,
  GET_CUSTOM_ATTRIBUTE_ID,
};
