import React from "react";

function PinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="34"
      fill="none"
      viewBox="0 0 38 34"
    >
      <g filter="url(#123_123_adfsdsfdsfdsbc)">
        <rect width="32" height="28" x="3" y="2" fill="#FDFAFF" rx="14"></rect>
        <path
          stroke="#825AC7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M18.978 22.667l.006-4.58m2.512-3.56V12.48h.467c.26 0 .46-.207.46-.48V9.807c-.12-.274-.32-.48-.58-.473l-5.777.02c-.26 0-.46.206-.46.48v2.186c-.007.34.253.48.453.473h.46v2.047c-.6.414-2.179 1.58-2.186 3.08 0 .273.194.48.46.473l3.745-.013h.92l3.744-.02c.36-.033.493-.306.46-.48 0-1.5-1.566-2.66-2.16-3.066l-.006.013z"
        ></path>
        <rect
          width="31.5"
          height="27.5"
          x="3.25"
          y="2.25"
          stroke="#EFE0FC"
          strokeWidth="0.5"
          rx="13.75"
        ></rect>
      </g>
      <defs>
        <filter
          id="123_123_adfsdsfdsfdsbc"
          width="38"
          height="34"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1165_159889"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1165_159889"
            result="effect2_dropShadow_1165_159889"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1165_159889"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default PinIcon;
