import {useLazyQuery, useQuery} from '@apollo/client';
import {notification} from 'antd';
import {
  Badge,
  Box,
  Divider,
  HStack,
  Icon,
  InfoOutlineIcon,
  Modal,
  Pressable,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {
  getInActiveContactError,
  GROUP_MEMBER_TYPE,
} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  APPOINTMENT_STATUS_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
  USER_ROLE_CODES,
} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {UserQueries} from '../../../../services';
import AppointmentQueries from '../../../../services/Appointment/AppointmentQueries';
import {Colors} from '../../../../styles';
import {
  getAccountUUID,
  getUserFullName,
  getUserUUID,
  isActiveContact,
  isZoomEnabled,
} from '../../../../utils/commonUtils';
import {addTimeToDate, getDateObject} from '../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../utils/mlovUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {AddActionView} from '../../../common/Buttons/AddActionView';
import {AppointmentType} from '../../../common/CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import {getAppointmentDateTime} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {IBookedAppointment} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/Interfaces';
import {
  isFutureMeeting,
  isPastMeeting,
} from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {IParticipantData} from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import AppointmentDetail from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import AppointmentDetailCardOption from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetailCardOption';
import BookingWorkflow from '../../../common/CalendarWidget/BookingWorkflows/BookingWorkflow';
import {ParticipantType} from '../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import FHAlertDialog from '../../../common/FHAlertDialog/FHAlertDialog.native';
import { IAppointmentTask } from '../../../../services/Appointment/AppointmentInterface';
import { AppointmentTasksDrawer } from '../../../common/AppointmentTaskCheckList/AppointmentTasksDrawer';
import {NoTagsSvg} from '../../../common/Svg';
import CalenderIcon from '../../../common/Svg/CalenderIcon';
import CommonDataProvider from '../../../CommonDataProvider/CommonDataProvider';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from '../../../SideMenuBar/SideBarConst';
import { AppointmentDetailsBanner } from '../../MiddleContainer/PatientNotes/components/AppointmentDetailsBanner';
import {ZoomHelper} from '../../../../services/ZoomHelper';
import { isZoomMeetingId } from '../../../../services/HmsMeetingServices';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { EventBus } from '../../../../utils/EventBus';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../constants/WebSocketConst';

interface PatientAppointmentViewProps {
  contactData: IContact;
  taggableType: string;
  contactId: number;
  style?: any;
  conversationData?: any;
  refetchPatientAppointment?: boolean;
  shouldShowApointment?: boolean;
  onClose?: any;
  borderLessView?: boolean;
  navigation?: any;
  showMore?: boolean;
}

export interface IAppointmentDetail {
  id: string;
  name?: string;
  startDateTime: string;
  endDateTime: string;
  participants: IParticipantData[];
  reasonForVisit: {
    code: string;
    displayName: string;
  };
  notes: any[];
  isBlockedInterval: boolean;
  statusId: string;
  reference?: any;
  declinedReason?: string;
  proposedTime?: string;
  userPracticeLocationId?: string;
  accountLocationId?: string;
  locationTypeId?: string;
  isRsvpEnabled?: boolean;
  appointmentType: {
    id: string;
    eventName: string;
    locationType: {
      value: string;
      code: string;
    };
    category: {
      code: string;
    };
  };
  tasks?: IAppointmentTask[];
  isRecurrentAppointment: boolean;
  externalSeriesId?: string;
  seriesId?: string;
}

export const PatientAppointmentDataView = (
  props: PatientAppointmentViewProps
) => {
  const {style, borderLessView, contactData, navigation} = props;
  const toast = useToast();
  const userUUID = getUserUUID();
  const userFullName = getUserFullName();
  const accountUUID = getAccountUUID();
  const commonContextData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  const [currentScreen, setCurrentScreen] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [newAppointmentModalOpen, setNewAppointmentModel] = useState(false);
  const [editAppointmentModalOpen, setModalOpen] = useState(false);
  const [appointmentTasksModalOpen, setAppointmentTasksModalOpen] = useState(false);
  const [appointmentLoader, setAppointmentLoader] = useState(true);
  const [appointmentList, setAppointmentData] = useState<IAppointmentDetail[]>(
    []
  );
  const [appointmentUserList, setAppointmentUser] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointmentDetail>({} as IAppointmentDetail);
  const [patientAppointmentDetail, setAppointmentDetails] = useState<any>({
    user: {
      uuid: '',
      name: '',
    },
    defaultParticipants: {
      label: '',
      key: '',
      value: '',
      type: '',
    },
  });

  const [joinMeetingConfirmation, setJoinMeetingConfirmation] = useState<{
    visible: boolean;
    selectedAppointment?: IAppointmentDetail;
    header: string;
    message: string;
  }>({
    visible: false,
    header: '',
    message: '',
    selectedAppointment: undefined,
  });
  const intl = useIntl();

  useEffect(() => {
    if (props.shouldShowApointment) {
      setNewAppointmentModel(!newAppointmentModalOpen);
      setPatientAppointmentData();
      setCurrentScreen(COMMON_ACTION_CODES.ADD);
    }
  }, [props.shouldShowApointment]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_SCHEDULE_APPOINTMENT, fetchAppointments);
    return () => {
      eventBus.removeEventListener(fetchAppointments);
    };
  },[contactData?.uuid])

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
  };

  const showAppointmentBookingToast = (message: string,type:ToastType) => {
    showToast(
      toast,
      message,
      type,
    );
  };

  const getAppointmentStatusIds = () => {
    return appointmentStatusList.map((status) => status.id);
  };

  const getAppointmentFetchParams = () => {
    const appointmentStatusIds = getAppointmentStatusIds();
    return {
      startDateTime: getDateObject(new Date()).toISOString(),
      endDateTime: addTimeToDate(new Date(), 6, 'MONTH').toISOString(),
      statusIds: appointmentStatusIds,
      contactIds: [props.contactData.uuid],
    };
  };

  const [getPatientAppointments] = useLazyQuery(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_CONTACT_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: getAppointmentFetchParams(),
      onCompleted: (data) => {
        if (data && data.appointments && data.appointments.length) {
          setAppointmentData(data.appointments);
        } else {
          setAppointmentData([]);
        }
        setAppointmentLoader(false);
      },
      onError: (err) => {

        setAppointmentLoader(false);
      },
    }
  );

  const fetchAppointments = () => {
    getPatientAppointments({
      variables: getAppointmentFetchParams(),
    });
  };

  const {loading} = useQuery(UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUUID,
      roleCode: USER_ROLE_CODES.EMPLOYER
    },
    onCompleted: (data: any) => {
      setAppointmentUser(data.users || []);
    },
    onError: (error) => {

    },
  });

  const getUserNameDetail = (appointment: IAppointmentDetail) => {
    return appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user?.name;
  };

  const getUserDetailData = (appointment: IAppointmentDetail) => {
    return appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user as any;
  };

  const getAppointmentLocation = (appointment: IAppointmentDetail) => {
    let locationName = null;
    const userPracticeLocationId = appointment.userPracticeLocationId;
    const userId = appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user?.uuid;

    if (userId && userPracticeLocationId) {
      const user = appointmentUserList.find((user) => {
        return user.uuid === userId;
      });

      locationName = (user?.userPracticeLocations || []).forEach(
        (location: any) => {
          return location.uuid === userPracticeLocationId;
        }
      )?.accountLocation?.practiceLocation?.name;
    }
    return (
      locationName || appointment?.appointmentType?.locationType?.value || ''
    );
  };

  const getAppointmentDetails = () => {
    return {detail: selectedAppointment};
  };

  const setPatientAppointmentData = () => {
    setAppointmentDetails((prev: any) => {
      return {
        ...prev,
        user: {
          uuid: userUUID,
          name: userFullName,
        },
        defaultParticipants: {
          label: props.contactData?.name,
          key: props.contactData.uuid,
          value: props.contactData.uuid,
          type: ParticipantType.patient,
        },
      };
    });
  };

  const resetPatientAppointmentData = () => {
    setAppointmentDetails((prev: any) => {
      return {
        ...prev,
        user: {
          uuid: '',
          name: '',
        },
        defaultParticipants: {
          label: '',
          key: '',
          value: '',
          type: '',
        },
      };
    });
  };
  useEffect(() => {
    if (currentScreen !== COMMON_ACTION_CODES.EDIT) {
      fetchAppointments();
    }
  }, [currentScreen, props.refetchPatientAppointment, contactData?.uuid]);

  const renderContent = () => {
    return (
      <View
        style={[
          {
            backgroundColor: '#ffffff',
            marginHorizontal: 24,
            borderRadius: 8,
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
            paddingHorizontal: 16,
          },
        ]}
      >
        {appointmentLoader ? (
          <Skeleton.Text lines={3} />
        ) : (
          <>
            {appointmentList && appointmentList.length ? (
              <>
                {appointmentList.map(
                  (appointment: IAppointmentDetail, index: number) => {
                    const key = `appointment_${appointment.id}_${index}`
                    if (props?.showMore && !showAll && index > 1) {
                      return <React.Fragment key={key} />;
                    }
                    return (
                      <VStack key={key}>
                        {index != 0 && (
                          <Divider />
                        )}
                        <View my={4}>
                          <AppointmentDetailsBanner
                            appointment={appointment}
                            canOpenAppointmentDetailsModal={false}
                            canOpenAppointmentTaskModal={false}
                            canShowViewChecklistLink={true}
                            onClickAppointment={(appointment: IAppointmentDetail) => {
                              setSelectedAppointment(appointment);
                              setModalOpen(!editAppointmentModalOpen);
                              setCurrentScreen(COMMON_ACTION_CODES.EDIT);
                            }}
                            onClickAppointmentChecklist={(appointment: IAppointmentDetail) => {
                              setSelectedAppointment(appointment);
                              setCurrentScreen(COMMON_ACTION_CODES.EDIT);
                              setAppointmentTasksModalOpen(true);
                            }}
                          />
                        </View>
                      </VStack>
                    );
                  }
                )}
                {props?.showMore && appointmentList?.length > 2 ? (
                  <Pressable
                    onPress={() => {
                      setShowAll(!showAll);
                    }}
                    style={{
                      paddingBottom: 20,
                    }}
                  >
                    <Text
                      size={'smSemibold'}
                      style={{
                        textAlign: 'center',
                        color: Colors.primary[500],
                        textDecorationLine: 'underline',
                      }}
                    >
                      {showAll ? `Show less` : `Show more`}
                    </Text>
                  </Pressable>
                ) : null}
              </>
            ) : (
              <NoTagsSvg titleId="appointmentNotFound" />
            )}
          </>
        )}
      </View>
    );
  };

  const onSendResponseSuccess = (setDetailState: any) => {
    setCurrentScreen('');
    fetchAppointments();
  };

  const bookAppointment = (action?: any) => {
    if (selectedAppointment) {
      const bookedAppointment: IBookedAppointment = selectedAppointment as any;
      navigation?.push('BookAppointment', {
        appointmentDetail: bookedAppointment,
        onComplete: onBookingComplete,
        action,
      });
    }
  };

  const onBookingComplete = () => {
    fetchAppointments();
  };

  const onJoinMeetingClicked = (appointment: IAppointmentDetail) => {
    if (appointment) {
      const isFutureAppointment = isFutureMeeting(appointment);
      const isPastAppointment = isPastMeeting(appointment);

      if (isFutureAppointment || isPastAppointment) {
        setJoinMeetingConfirmation({
          visible: true,
          selectedAppointment: appointment,
          header: intl.formatMessage({
            id: isFutureAppointment
              ? 'joinFutureAppointment'
              : 'joinPastAppointment',
          }),
          message: intl.formatMessage({
            id: isFutureAppointment
              ? 'joinFutureAppointmentMsg'
              : 'joinPastAppointmentMsg',
          }),
        });
      } else {
        joinMeeting(appointment);
      }
    }
  };
  const isZoomEnabledAccount = isZoomEnabled();

  const joinMeeting = async (detail: IAppointmentDetail) => {
    const meetingId =
      detail?.reference?.meetingInfo?.meeting?.meetingId ||
      detail.reference?.meetingInfo?.id;
    const isZoomMeeting = await isZoomMeetingId({meetingId});
    const isWebinar =
      detail?.reference?.meetingInfo?.webinarId &&
      detail?.appointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
    if (isWebinar) {
      resetJoinMeetingData();
      ZoomHelper.startWebinar({
        webinarId: detail?.reference?.meetingInfo?.webinarId,
        userUuid: userUUID,
      });
      return;
    }
    if (isZoomMeeting) {
      resetJoinMeetingData();
      ZoomHelper.startMeeting({
        meetingId: detail?.reference?.meetingInfo?.meeting?.meetingId,
        userUuid: userUUID,
      });
      return;
    }
    navigation?.push('HMSPreviewScreen', {
      meetingId: detail.reference?.meetingInfo?.id,
    });
    resetJoinMeetingData();
  };

  const resetJoinMeetingData = () => {
    setJoinMeetingConfirmation({
      visible: false,
      selectedAppointment: undefined,
      message: '',
      header: '',
    });
  };

  const showAppointments = () => {
    if (isWeb()) {
      return true;
    } else {
      if (
        USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
          MAIN_MENU_CODES.CALENDAR
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <View
        style={{
          backgroundColor: Colors.Custom.Gray50,
        }}
      >
        <View style={{padding: showAppointments() ? 24 : 0}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {showAppointments() && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginLeft: 2,
                }}
              >
                <View style={{marginRight: 10}}>
                  <CalenderIcon />
                </View>
                <VStack marginTop={-2}>
                  <DisplayText
                    size={'xlBold'}
                    textLocalId={'futureAppointment'}
                  />
                  <Text fontSize={12} color={Colors.Custom.Gray500}>Next 6 months</Text>
                </VStack>
              </View>
            )}
            {isWeb() && (
              <Tooltip label={'Add Appointment'} placement={'top'}>
                <Pressable
                  onPress={() => {
                    const currentStatus = isActiveContact(contactData);
                    if (!currentStatus) {
                      const message = getInActiveContactError(contactData);
                      notification.error({
                        message,
                      });
                      return;
                    }
                    setNewAppointmentModel(!newAppointmentModalOpen);
                    setPatientAppointmentData();
                    setCurrentScreen(COMMON_ACTION_CODES.ADD);
                  }}
                >
                  <AddActionView extraStyles={{width: 16, height: 16, marginLeft: 8}}/>
                </Pressable>
              </Tooltip>
            )}
          </View>
        </View>
        {showAppointments() && renderContent()}
        <FHAlertDialog
          isOpen={joinMeetingConfirmation.visible}
          header={joinMeetingConfirmation.header}
          message={joinMeetingConfirmation.message}
          buttonActions={[
            {
              textLocalId: 'Cancel',
              buttonProps: {
                colorScheme: 'muted',
                variant: 'outline',
              },
              onPress: () => {
                resetJoinMeetingData();
              },
            },
            {
              textLocalId: 'Join',
              textColor: 'white',
              buttonProps: {
                backgroundColor: Colors.primary[700],
              },
              onPress: () => {
                if (joinMeetingConfirmation.selectedAppointment) {
                  joinMeeting(joinMeetingConfirmation.selectedAppointment);
                }
              },
            },
          ]}
        />
      </View>
      <>
        {currentScreen === COMMON_ACTION_CODES.ADD && (
          <BookingWorkflow
            user={patientAppointmentDetail.user}
            isOpen={newAppointmentModalOpen}
            appointmentType={AppointmentType.bookAppointment}
            defaultParticipants={[patientAppointmentDetail.defaultParticipants]}
            onClose={() => {
              setCurrentScreen('');
              showAppointmentBookingToast('Appointment booked successfully!', ToastType.success);
              setNewAppointmentModel(!newAppointmentModalOpen);
              resetPatientAppointmentData();
              props.onClose();
            }}
            onCancel={() => {
              setCurrentScreen('');
              setNewAppointmentModel(!newAppointmentModalOpen);
              resetPatientAppointmentData();
              props.onClose();
            }}
          />
        )}
        {currentScreen === COMMON_ACTION_CODES.EDIT && (
          <>
            {isWeb() ? (
              <>
                <AppointmentDetail
                  reasonsForVisitList={[selectedAppointment.reasonForVisit]}
                  event={getAppointmentDetails()}
                  isVisible={editAppointmentModalOpen}
                  onStatusChange={() => {
                    setModalOpen(!editAppointmentModalOpen);
                    fetchAppointments();
                  }}
                  onClose={() => {
                    setModalOpen(!editAppointmentModalOpen);
                    setCurrentScreen('');
                  }}
                />

                {
                  appointmentTasksModalOpen &&
                  <AppointmentTasksDrawer
                    appointmentId={selectedAppointment?.id}
                    isVisible={appointmentTasksModalOpen}

                    canAddNewTasks={false}
                    canDeleteTasks={false}
                    canEditStatus={true}
                    canEditTitle={false}

                    onChange={(tasks) => {
                      setAppointmentData((prev) => {
                        if (prev?.length) {
                          for (const appointment of prev) {
                            if (appointment?.id && appointment.id === selectedAppointment?.id) {
                              appointment.tasks = tasks;
                              break;
                            }
                          }
                        }
                        return [ ...prev ];
                      });
                    }}

                    onClose={() => {
                      setAppointmentTasksModalOpen(false);
                      setCurrentScreen('');
                    }}
                  />
                }
              </>
            ) : (
              <Modal isOpen={true} onClose={() => setCurrentScreen('')}>
                <Modal.Content width={320} bgColor="#ffffff" borderRadius={20}>
                  <CommonDataProvider allCommonData={commonContextData}>
                    <AppointmentDetailCardOption
                      appointment={selectedAppointment}
                      careStudioMlovData={commonContextData.CARE_STUDIO_MLOV}
                      onStatusChange={() => {
                        setCurrentScreen('');
                        fetchAppointments();
                      }}
                      onJoinMeeting={() => {
                        if (selectedAppointment) {
                          onJoinMeetingClicked(selectedAppointment);
                          setCurrentScreen('');
                        }
                      }}
                      onBookAppointment={(action?: any) => {
                        bookAppointment(action);
                        setCurrentScreen('');
                      }}
                      onSendResponseSuccess={onSendResponseSuccess}
                      navigation={props.navigation}
                    />
                  </CommonDataProvider>
                </Modal.Content>
              </Modal>
            )}
          </>
        )}
      </>
    </>
  );
};
