const DELETE_CAMPAIGN = `
    mutation UpdateCampaign($id:uuid!)
    { updateCampaign(pk_columns: {id: $id},
       _set: {isDeleted: true}) { id } }`;

const UPDATE_CAMPAIGN_STATUS = `
       mutation UpdateCampaign(
         $id:uuid!
         $status: String
         $updatedBy: uuid
       )
       {
         updateCampaign(pk_columns: {id: $id}, _set: {
         status: $status,
         updatedBy: $updatedBy
        }) { id, status } }`;

const UPDATE_CAMPAIGN = `
mutation UpdateCampaign($campaignSegmentId: uuid!, $campeignId: uuid!, $campaignSegmentData: campaignSegment_set_input, $campeignData: campaign_set_input) {
  updateCampaign(pk_columns: {id: $campeignId}, _set: $campeignData) {
    id
  }
  updateCampaignSegment(pk_columns: {id: $campaignSegmentId}, _set: $campaignSegmentData) {
    id
  }
}
`;

const UPDATE_CAMPAIGN_WITHOUT_SEGMENT = `
mutation UpdateCampaign($campeignId: uuid!, $campeignData: campaign_set_input) {
  updateCampaign(pk_columns: {id: $campeignId}, _set: $campeignData) {
    id
  }
}
`;

const SAVE_CAMPAIGN = `
mutation CreateCampaign($segmentTypeValue:String,$segmentType:String,$name: String, $description: String, $isImmediate: Boolean, $startDate: timestamptz, $numberOfRetry: Int, $campaignSegment: campaignSegment_obj_rel_insert_input, $retryDelayInDays: Int, $emailTemplateId: Int, $segmentId: uuid, $channelType: String, $metadata: jsonb) {
  insertCampaign(object: {segmentTypeValue:$segmentTypeValue,segmentType:$segmentType,name: $name, description: $description, isImmediate: $isImmediate, startDate: $startDate, campaignSegment: $campaignSegment, numberOfRetry: $numberOfRetry, emailTemplateId: $emailTemplateId, retryDelayInDays: $retryDelayInDays, segmentId: $segmentId, channelType: $channelType, metadata: $metadata}) {
  id
  }
}`;

const GET_CAMPAIGNS = `
query GetCampaigns($tenantId: uuid!) {
  campaigns(order_by: {createdOn: desc}, where: {tenantId: {_eq: $tenantId}, isDeleted: {_eq: false}}) {
    id
    name
    segmentId
    description
    isImmediate
    startDate
    patientFilter
    numberOfRetry
    emailTemplateId
    smsText
    status
    retryDelayInDays
    metadata
    channelType
  }
}

`;

const GET_CAMPAIGNS_BY_STATUSES = `
query GetCampaigns($tenantId: uuid!, $statusCodes: [String]!) {
  campaigns(order_by: {createdOn: desc}, where: {tenantId: {_eq: $tenantId}, isDeleted: {_eq: false}, status: {_in: $statusCodes}}) {
    id
    name
    segmentId
    description
    isImmediate
    startDate
    patientFilter
    numberOfRetry
    emailTemplateId
    segmentType
    segmentTypeValue
    smsText
    status
    retryDelayInDays
    metadata
    channelType
  }
}

`;

const SEARCH_CAMPAIGNS = `
  query SearchCampaigns($tenantId: uuid!, $searchString: String) {
    campaigns(
      order_by: {createdOn: desc}
      where: {
        tenantId: {_eq: $tenantId}
        isDeleted: {_eq: false}
        name: {_ilike: $searchString}
      }
    ) {
      id
      name
      segmentId
      description
      isImmediate
      startDate
      patientFilter
      numberOfRetry
      emailTemplateId
      smsText
      status
      retryDelayInDays
      metadata
      channelType
    }
  }
`;

export default {
  SAVE_CAMPAIGN,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_BY_STATUSES,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_CAMPAIGN_WITHOUT_SEGMENT,
  SEARCH_CAMPAIGNS
};
