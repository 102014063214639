import { useContext, useEffect, useState } from 'react';
import {
  CARE_PROGRAM_TYPES,
  MLOV_CATEGORY,
} from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { useLazyQuery } from '@apollo/client';
import { GET_CARE_PROGRAM_ENROLLMENT_DATE } from '../../../../../services/CareTeam/CareTeamQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { IFormCommonData } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { MlovQueries } from '../../../../../services';
import { IMlov } from '../../../../../Interfaces';

function useLatestCareProgramEnrollmentDate(params: {
  type: keyof typeof CARE_PROGRAM_TYPES | string;
  contactId: string;
}) {
  const {type} = params;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const tenantId = contextData?.tenantId;
  const [date, setDate] = useState<string>('');

  const [getAccountMlovList, accountMlovQuery] = useLazyQuery(MlovQueries.GET_ACCOUNT_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getDate, {data, loading, refetch, error}] = useLazyQuery(
    GET_CARE_PROGRAM_ENROLLMENT_DATE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const fetchData = async () => {
    const mlovData = await getAccountMlovList({
      variables: {
        categories: [
          MLOV_CATEGORY.CARE_PROGRAM_TYPES,
          MLOV_CATEGORY.CARE_PROGRAM_STEP,
        ],
      },
    });
    const careProgramTypes: IMlov[] = (
      mlovData?.data?.accountMlovsDeprecated || []
    ).filter(
      (mlov: any) =>
        mlov?.category?.category === MLOV_CATEGORY.CARE_PROGRAM_TYPES &&
        (!mlov.tenantId || mlov.tenantId === tenantId)
    );

    const careProgramType = careProgramTypes.find(
      (item) => item.code === params?.type
    );
    const responseDate = await getDate({
      variables: {
        contactId: params?.contactId,
        typeId: careProgramType?.id,
      },
    });

    setDate(
      responseDate.data?.contactCarePrograms?.[0]?.enrollmentLog
        ?.enrollmentDateTime
    );
  };


  useEffect(() => {
    if(!params.contactId){
      return
    }
    fetchData();
  },[])

  return {
    date,
    loading: accountMlovQuery.loading || loading,
    error,
    refetch,
  };
}

export default useLatestCareProgramEnrollmentDate;
