import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  topStack: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    width: '100%',
  },
  timelineCard: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderRadius: 3,
    elevation: 4,
  },
  dateText: {
    color: '#babbe8',
    fontSize: 14,
  },
  timelineIconStyle: {
    backgroundColor: 'blue',
    padding: 6,
    borderRadius: 50,
  },
  p20: {
    padding: 20,
  },
  mainTitleText: {
    fontSize: 20,
    marginLeft: 25,
    marginBottom: 10,
    marginTop: 20,
  },
});
