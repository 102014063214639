import { gql } from '@apollo/client';

export const SEARCH_PATIENT = gql`
  query SearchPatients($searchString: String, $limit: Int, $contactType: uuid) {
    contacts(
      where: {
        name: {_ilike: $searchString}
        contactType: {typeId: {_eq: $contactType}}
        _or: [{email: {_is_null: false}}, {phoneNumber: {_is_null: false}}]
        isDeleted: {_eq: false}
      }
      limit: $limit
    ) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_PATIENTS_BY_IDS = gql`
  query SearchPatientByIds($patientIds: [String]) {
    getFhirPatients(params: {ids: $patientIds}) {
      patients
    }
  }
`;

export const CreatePatientWithContactId = gql`
  mutation CreatePatientWithContactId(
    $patientUuid: uuid
    $contactId: Int
    $accountId: Int
    $annualVisitDate: timestamp
    $isWeeklyCheckin: Boolean
    $patientId: String
  ) {
    createContactPatient(
      object: {
        patientUuid: $patientUuid
        contactId: $contactId
        accountId: $accountId
        annualVisitDate: $annualVisitDate
        isWeeklyCheckin: $isWeeklyCheckin
        patientId: $patientId
      }
    ) {
      id
      patientUuid
      contactId
      patientId
    }
  }
`;

export const UpdatePatientWithContactId = gql`
  mutation updatePatientWithPatientUuidd(
    $contactId: Int!
    $annualVisitDate: timestamp!
    $isWeeklyCheckin: Boolean!
  ) {
    updateContactPatients(
      where: {contactId: {_eq: $contactId}}
      _set: {
        annualVisitDate: $annualVisitDate
        isWeeklyCheckin: $isWeeklyCheckin
      }
    ) {
      returning {
        contactId
        patientId
        patientUuid
        isWeeklyCheckin
        annualVisitDate
      }
    }
  }
`;

export const SEARCH_PATIENT_WITH_CUSTOM_QUERY = gql`
  query SearchPatientWithCustomQuery($limit: Int, $where: contacts_bool_exp) {
    contacts(limit: $limit, where: $where) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      phoneNumber
      additionalAttributes
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          uuid
          name
          practiceCity {
            name
            id
          }
        }
        accountLocation {
          uuid
          locationUuid
          practiceLocation {
            uuid
            name
          }
        }
      }
    }
  }
`;
export const SEARCH_PATIENT_IN_GLOBAL_SEARCH = gql`
  query SearchPatientWithCustomQuery($limit: Int, $offset: Int = 0, $where: contacts_bool_exp) {
    contacts(limit: $limit, offset: $offset, where: $where) {
      contactDeactivationInfo {
        isActive
      }
      additionalAttributes
      isActive
      id
      name
      email
      uuid
      phoneNumber
      additionalAttributes
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
      }
    }
  }
`;

export const SEARCH_PATIENT_IN_GLOBAL_SEARCH_WITH_ORDERBY = gql`
  query SearchPatientWithCustomQuery($limit: Int, $offset: Int = 0, $where: contacts_bool_exp) {
    contacts(limit: $limit, offset: $offset, where: $where, order_by: { name: asc }) {
      contactDeactivationInfo {
        isActive
      }
      additionalAttributes
      isActive
      id
      name
      email
      uuid
      phoneNumber
      additionalAttributes
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
      }
    }
  }
`;

export default {
  SEARCH_PATIENT,
  GET_PATIENTS_BY_IDS,
  CreatePatientWithContactId,
  UpdatePatientWithContactId,
  SEARCH_PATIENT_WITH_CUSTOM_QUERY,
  SEARCH_PATIENT_IN_GLOBAL_SEARCH,
  SEARCH_PATIENT_IN_GLOBAL_SEARCH_WITH_ORDERBY
};
