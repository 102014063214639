import {HStack, ScrollView, SectionList, Text, View, VStack} from 'native-base';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {isBirthSexPrimary} from '../../../../../../utils/capabilityUtils';
import {getUserUUID} from '../../../../../../utils/commonUtils';
import {getDateStrFromFormat} from '../../../../../../utils/DateUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {DisplayCardAvatar} from '../../../../DisplayCard/DisplayCardAvatar';
import ReadMsgSvgNative from '../../../../Svg/ReadMsgSvgNative';
import SendMesgSvgNative from '../../../../Svg/SendMesgSvgNative';
import React from 'react';

import {getMemberName, getMemberNameForReamMessageInfo} from '../../../../GroupUserMentionList/Utils';
import {IMessageReadInfoItemProps} from './interface';
import {IMessageUnreadUserAndContactFinalList} from '../interface';

export const MessageReadInfoItem = (props: IMessageReadInfoItemProps) => {
  const userUuid = getUserUUID();

  const isBirthSexPrimaryValue = isBirthSexPrimary();
  const getPrimaryGenderCode = (contactData: any) => {
    let genderCode =
      contactData?.person?.gender?.code ||
      contactData?.person?.sexAtBirth?.code ||
      '';
    if (isBirthSexPrimaryValue) {
      genderCode =
        contactData?.person?.sexAtBirth?.code ||
        contactData?.person?.gender?.code ||
        '';
    }
    return genderCode;
  };

  const getAvatarUrl = (userId: any) => {
    let profileImg = '';
    props?.agentsData?.map((item: any) => {
      if (item.id === userId) {
        profileImg = item.thumbnail.includes('404') ? '' : item.thumbnail;
      }
    });
    return profileImg;
  };

  const getDisplayCardAvatar = (singleMemberData: IMessageUnreadUserAndContactFinalList): JSX.Element => {
    if (singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
      const userRoles = getUserRoles(singleMemberData);
      const userImg = getAvatarUrl(singleMemberData?.user?.id);
      return (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: singleMemberData?.user,
            userType: GROUP_MEMBER_TYPE.USER,
            roles: userRoles,
            imgSrc: userImg || '',
            userName: singleMemberData?.user?.name
          }}
        />
      );
    } else if (
      singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
      singleMemberData?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      return (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: singleMemberData?.contact?.id,
            userType: GROUP_MEMBER_TYPE.CONTACT,
            genderCode: getPrimaryGenderCode(singleMemberData?.contact),
            contactType: singleMemberData?.groupUserType?.code,
            userName: singleMemberData?.contact?.name
          }}
        />
      );
    }
    return (
      <DisplayCardAvatar
        avatarStyle={{
          avatarSize: '12',
        }}
        isLetterAvatarShow
        userData={{
          userId: '',
          userType: GROUP_MEMBER_TYPE.CONTACT,
          userName: getMemberName(singleMemberData as any)
        }}
      />
    );
  };

  const getUserRoles = (member: IMessageUnreadUserAndContactFinalList) => {
    const userRoleCodes: string[] = [];
    member?.user?.userRoles?.forEach((el: any) => {
      if (el?.userRole?.userRole?.code) {
        userRoleCodes.push(el?.userRole?.userRole?.code);
      }
    });
    return userRoleCodes;
  };

  const groupMemberRelation = (member: IMessageUnreadUserAndContactFinalList) => {
    let role = '';
    if (
      member?.groupUserType?.code === GROUP_MEMBER_TYPE.USER &&
      member?.user?.userRoles?.length
    ) {
      member?.user?.userRoles?.forEach((el: any) => {
        if (role?.length && el?.userRole?.userRole?.value) {
          role = role + ', ' + el?.userRole?.userRole?.value;
        } else if (el?.userRole?.userRole?.value) {
          role = el?.userRole?.userRole?.value;
        }
      });
    } else if (member?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT) {
      // patientRelation.map((el: any) => {
      //   if (member?.contact?.id === el?.relationContactId)
      //     role = el?.relationType?.code;
      // });
    }
    if (role?.length < 19) {
      return role;
    } else {
      return role.slice(0, 19).concat('...');
    }
  };

  const getLastSeenByDate = (singleUserData: IMessageUnreadUserAndContactFinalList) => {
    if (singleUserData?.readInfo?.createdAt) {
      return getDateStrFromFormat(singleUserData?.readInfo?.createdAt);
    } else {
      return '';
    }
  };

  const renderMemberDetails = ({item}: {item:IMessageUnreadUserAndContactFinalList}) => {
    return (
      <HStack key={ 'group_member_item' + item.groupUserId} justifyContent={'space-between'}>
        {props?.parentCode === 'MESSAGE_READ' && userUuid === item?.groupUserId ? (
          <></>
        ) : (
          <HStack space={1} mb={2}>
            <VStack justifyContent={'center'}>
              {getDisplayCardAvatar(item)}
            </VStack>
            <VStack justifyContent={'center'}>
              <VStack>
                <View flex={1} justifyContent={'center'} marginLeft={2}>
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    {getMemberNameForReamMessageInfo(item)}
                  </Text>
                </View>
                <View flex={1} justifyContent={'center'} marginLeft={2}>
                  <Text fontSize={12} color={Colors.Custom.Gray500}>
                    {groupMemberRelation(item)}
                  </Text>
                </View>
                {props?.parentCode === 'MESSAGE_READ' && (
                  <HStack marginLeft={2} space={2} alignItems={'center'}>
                    {getLastSeenByDate(item) ? (
                      <ReadMsgSvgNative />
                    ) : (
                      <SendMesgSvgNative />
                    )}
                    <Text>{getLastSeenByDate(item)}</Text>
                  </HStack>
                )}
              </VStack>
            </VStack>
          </HStack>
        )}
      </HStack>
    );
  };

  const getRemainingUserCount = () => {
    if (props?.remainingCount && props?.remainingCount > 0) {
      return <Text>{`${props?.remainingCount} Remaining`}</Text>;
    } else {
      return <></>;
    }
  };

  const getMembers = () => {
    return (
      <HStack width={'full'} mr={4} >
        <SectionList
          sections={props?.messageReadUserData}
          renderItem={renderMemberDetails}
          renderSectionHeader={({section}: any) =>
            section.data.length ? (
              <View mb={2}>
                {props?.parentCode !== 'MESSAGE_READ' && (
                  <Text color={Colors.Custom.Gray600}>{section.title}</Text>
                )}
              </View>
            ) : null
          }
          keyExtractor={(item: any) => item.id}
        />
      </HStack>
    );
  };
  return (
    <VStack mx={!isWeb() ? 6 : 0} flex={1}>
      {isWeb() ? (
        <ScrollView
          showsVerticalScrollIndicator={false}
          maxH={props.height ? props.height : 300}
          overflow={'scroll'}
        >
          {getMembers()}
        </ScrollView>
      ) : (
        <VStack  height={'100%'}>{getMembers()}</VStack>
      )}
      {props?.parentCode === 'MESSAGE_READ' && (
        <VStack height={'full'}>{getRemainingUserCount()}</VStack>
      )}
    </VStack>
  );
};
