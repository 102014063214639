import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  mainTitleText: {
    fontSize: 20,
    marginLeft: 25,
    marginBottom: 10,
    marginTop: 20,
  },
});
