import {Affix, Progress} from 'antd';
import {cloneDeep} from 'lodash';
import {Box, Text, View, Spinner, HStack, Divider, Center, Pressable, Skeleton, VStack} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSearchParams, useLocation} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {FHIR_RESOURCE} from '../../../constants/FhirConstant';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getResourceContentWithHeaders} from '../../../services/CommonService/AidBoxService';
import {Colors} from '../../../styles';
import {getConfigDataFromCode} from '../../../utils/commonUtils';
import { IThemeAttachments } from '../../BodyContainer/interface';
import BrandingLogoWrapper from '../../common/BrandingLogoWrapper/BrandingLogoWrapper';
import { ComponentToPrint } from '../../common/PrintComponent/ComponentToPrint';
import InfoSvg from '../../common/Svg/InfoSvg';
import { getAdminAppUrl, getPatientAppUrl } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getDefaultComponent } from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import {FHForm} from '../../RightSideContainer/Forms/FHFormio';
import {forEachExtensiveFormComponent} from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IDefaultFormTheme} from '../../RightSideContainer/Forms/interfaces';
import {
  IExtensiveComponentData,
  IFormComponentWithReferenceData,
} from './interface';
import {
  checkIfSocailHistoryComponentExist,
  getComponentKey,
  getFormComponentsDataForProgress,
  getPercentageScore,
  getProgressScoreOfComponent,
} from './PublicFormHelper';
import {IFormPrefilledByFoldProgressState, IPublicFormData} from './PublicFormInterfaces';
import './PublicFormStyles.scss';
import ReactHtmlParser from 'react-html-parser';
import { getComponentTypes } from '../../RightSideContainer/ContentManagement/EmailComponents/EmailComponentsUtils';
import Feather from 'react-native-vector-icons/Feather';

const PublicFormView = (props: {
  formData: IPublicFormData;
  submittedData?: any;
  readOnly?: boolean;
  hideBorder?: boolean;
  backgroundColor?: string;
  optionData?: any;
  extraStyles?: {[index: string]: any};

  accountNameHeaderBgColor?: string;
  accountNameTextColor?: string;
  accountLogoPosition?: string;
  accountNamePosition?: string;

  isFromFormWidget?: boolean;
  proxyHeaders?: any;

  onChange?: (data: any) => void;
  onSubmit?: (data: any) => void;

  formPrefilledByFoldProgress?: IFormPrefilledByFoldProgressState;
  updateFormPrefilledByFoldProgress?: (formPrefilledByFoldProgressByComponent: IFormPrefilledByFoldProgressState) => void;

  accountConfig?: any;
  isPreviewMode?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const allParamValue = location.search.split('=');
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(
    props?.isPreviewMode || searchParams?.get('isPreviewMode') && searchParams.get('isPreviewMode') === 'true' ? true : false
  );

  const [ formPrefilledByFoldProgressState, setFormPrefilledByFoldProgressState ]
    = useState<IFormPrefilledByFoldProgressState>(props?.formPrefilledByFoldProgress || {
      componentWiseProgress: {},
      disallowProgressUpdates: false,
      formPrefilledByFoldPercent: 0,
    });

  const componentStateRef = React.useRef<IExtensiveComponentData>({
    components: [],
    total: 0,
  });

  const handleOnChangeFoldPrefilledDataProgress = useCallback((componentKey: string, selectedValue: any) => {
    const components = componentStateRef?.current?.components;
    const component = components?.find((component) => {
      if (component.key === componentKey) {
        return true;
      }
    });

    const currentScore = getProgressScoreOfComponent(component, selectedValue) || 0;

    setFormPrefilledByFoldProgressState(prev => {
      prev.componentWiseProgress[componentKey] = currentScore;

      let totalScore = 0;
      Object.values(prev.componentWiseProgress).forEach((value) => {
        if (value) {
          totalScore += value;
        }
      });

      const totalComponentsLength = componentStateRef?.current.total;

      const percent = +((totalScore / totalComponentsLength) * 100);
      return {
        ...prev,
        formPrefilledByFoldPercent: percent,
      };
    });
  }, []);
  const commonData = useContext(CommonDataContext);
  const accountData: any = commonData.accountSubdomain || {};
  const accountName = accountData.accountToSubdomain?.name;

  const getFormIsPrint = () => {
    return searchParams?.get('isPrint');
  };

  const [optionData, setOptionData] = useState({
    ...(props?.optionData || {}),
    updateFormPrefilledByFoldProgress: handleOnChangeFoldPrefilledDataProgress,
    tenantName: accountName,
    isPrintForm: getFormIsPrint()
  });

  const [submittedData, setSubmittedData] = useState(props?.submittedData);
  const [formComponent, setFormComponent] = useState(
    props?.formData?.components
  );
  const [formHeaderFooter, setFormHeaderFooter] = useState<{header: any; footer: any}>({header: '', footer: ''});
  const componentRef = React.useRef(null);
  const printRef: any = React.useRef(null);
  const [progess, setProgress] = useState(0);
  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined = {} as IDefaultFormTheme;
  const [isProgressBarStickyTop, setIsProgressBarStickyTop] = useState(false);

  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const tempThemeAdditionalData: string = accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = tempThemeAdditionalData?.length ? JSON.parse(tempThemeAdditionalData) : {} as IThemeAttachments;
  const subdomainMap: any = commonData?.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  const logo = finalThemeAdditionalAttributes?.attachment?.logo?.url;

  if (
    defaultThemeConfig?.accountConfigurations &&
    defaultThemeConfig?.accountConfigurations?.length
  ) {
    configJson = JSON.parse(
      defaultThemeConfig?.accountConfigurations[0]?.value
    );
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue) {
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const [componentState, setComponentState] = useState<{
    loading: boolean;
    showFinancialWarning: boolean;
  }>({
    loading: false,
    showFinancialWarning: true,
  });

  const getFormReadOnly = () => {
    const readOnly = searchParams?.get('readOnly');
    if (readOnly) {
      return true;
    }
    return false;
  };

  const handleOnChange = useCallback(
    (data: any) => {
      if (props.onChange) {
        props.onChange(data);
        !props.readOnly && updateProgress(data);
      }
    },
    []
  );

  const handleOnSubmit = useCallback((data: any) => {
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }, []);

  const getComponents = useCallback(() => {
    const components = props.formData.components;
    if (allParamValue?.length && searchParams?.get('formData')) {
      const data = decodeURIComponent(allParamValue[allParamValue?.length - 1]);
      const formData = JSON.parse(data || '{}');
      const response = formData?.response;

      if (response?.data) {
        forEachExtensiveFormComponent(components, (component) => {
          if (component.key) {
            component.selectedValue = response.data[component.key];
          }
        });
      }
    }
    const isReadOnly = getFormReadOnly();
    const isPrint = getFormIsPrint();
    if (isReadOnly || isPrint) {
      return (
        (components || [])?.filter((formComponentMeta) => {
          return formComponentMeta.key !== 'submit';
        }) || []
      );
    }
    return components;
  }, [props.formData.components]);

  const renderDefaultThemeHeader = (): JSX.Element => {
    return (
      <View backgroundColor={formThemeConfig?.topBar?.backgroundColor}>
        <View marginY={2}>
          <BrandingLogoWrapper
            accountNameTextColor={formThemeConfig?.accountName?.color}
            accountLogoPosition={formThemeConfig?.accountLogo?.position}
            accountNamePosition={formThemeConfig?.accountName?.position}
            isFromPrint={true}
          />
        </View>
      </View>
    );
  }

  const updateMergeTag = (headerFooterElem: string, mergeTags: any | undefined): string => {
    if (headerFooterElem.includes('{{global')) {
      headerFooterElem = headerFooterElem.replaceAll('{{global.accountLogo}}', logo);
      headerFooterElem = headerFooterElem.replaceAll('{{global.accountName}}', accountName);
      headerFooterElem = headerFooterElem.replaceAll('{{global.clinicName}}', accountName);
      headerFooterElem = headerFooterElem.replaceAll('{{global.accountAdminUrl}}', getAdminAppUrl(subdomainName));
      headerFooterElem = headerFooterElem.replaceAll('{{global.accountPatientAppUrl}}', getPatientAppUrl(subdomainName));
    }
    if (mergeTags && Object.keys(mergeTags)?.length) {
      Object.keys(mergeTags).forEach(key => {
        if (headerFooterElem.includes(key)) {
          headerFooterElem = headerFooterElem.replaceAll(`{{${key}}}`, mergeTags[key]);
        }
      });
    }
    return headerFooterElem;
  }

  const getHeaderFooterMergeTag = (componentTypes: any[], headerFooterCode: string) => {
    const componentType =  (componentTypes || []).find(componentType => {
      return componentType && componentType?.attributes?.code === headerFooterCode;
    });
    if (componentType && componentType?.attributes?.mergeTags) {
      return componentType?.attributes?.mergeTags
    }
    return undefined;
  };

  const setHeaderAndFooter = async () => {
    setComponentState((prev) => ({...prev, loading: true}));
    try {
      const promiseList = [
        getDefaultComponent({type: 'HEADER'}, props.isFromFormWidget, props.proxyHeaders),
        getDefaultComponent({type: 'FOOTER'}, props.isFromFormWidget, props.proxyHeaders),
        getComponentTypes(props.isFromFormWidget, props.proxyHeaders)
      ];
      const [headerResponse, footerResponse, componentTypes] = await Promise.all(promiseList);
      const headerMergeTag = getHeaderFooterMergeTag(componentTypes?.data || [], 'HEADER');
      const footerMergeTag = getHeaderFooterMergeTag(componentTypes?.data || [], 'FOOTER');

      let headerElem = headerResponse.data.length > 0 ? headerResponse.data[0]?.attributes?.bodyHtml : undefined;
      let footerElem = footerResponse.data.length > 0 ? footerResponse.data[0]?.attributes?.bodyHtml : '';
      if (!headerElem) {
        headerElem = renderDefaultThemeHeader();
      } else {
        headerElem = updateMergeTag(headerElem, headerMergeTag || {});
      }
      if (footerElem) {
        footerElem = updateMergeTag(footerElem, footerMergeTag || {});
      }
      setFormHeaderFooter(prev => {
        return {
          ...prev,
          header: headerElem,
          footer: footerElem,
        }
      });
      if (getFormIsPrint()) {
        // TODO : need to change this logic.
        setTimeout(() => {
          setComponentState((prev) => ({...prev, loading: false}));
          if (printRef?.current && printRef?.current?.handleClick) {
            printRef.current.handleClick();
          }
        }, 1000 * 10);
      }
      setComponentState((prev) => ({...prev, loading: false}));
    } catch(error) {
      setComponentState((prev) => ({...prev, loading: false}));
    }
  };

  const renderHeader = (header: any) => {
    if (header && typeof header === 'string') {
      return ReactHtmlParser(header);
    } else {
      return header;
    }
  };

  useEffect(() => {
    setHeaderAndFooter();
  }, []);

  useEffect(() => {
    if (props?.submittedData) {
      setOptionData((prev: any) => ({
        ...prev,
        ehrCapabilities: props.submittedData?.ehrCapabilities,
      }));
      setSubmittedData(props.submittedData?.response);
    }
    if (allParamValue?.length && searchParams?.get('formData')) {
      const data = decodeURIComponent(allParamValue[allParamValue?.length - 1]);
      const formData = JSON.parse(data || '{}');
      setOptionData((prev: any) => ({
        ...prev,
        ehrCapabilities: formData?.ehrCapabilities || [],
      }));
      setSubmittedData(formData?.response || {});
    }
  }, [!!props?.readOnly ? JSON.stringify(props?.submittedData) : props?.submittedData]);

  useEffect(() => {
    props?.updateFormPrefilledByFoldProgress?.(formPrefilledByFoldProgressState);
  }, [formPrefilledByFoldProgressState]);

  const updateProgress = (formData: any) => {
    const percent = getPercentageScore(
      componentStateRef.current,
      formData?.data
    );
    setProgress(percent);
  };

  const getProgressReferenceData = async () => {
    setComponentState((prev) => ({...prev, loading: true}));
    if (checkIfSocailHistoryComponentExist(props.formData.components)) {
      await getResourceContentWithHeaders(
        FHIR_RESOURCE.QUESTIONNAIRE,
        ``,
        false,
        {},
        (response) => {
          if (response?.entry) {
            const questionnaireData = response?.entry?.map(
              (item: {resource: any}) => item?.resource
            );
            const data = getFormComponentsDataForProgress(
              props.formData.components,
              props.optionData,
              questionnaireData
            );
            componentStateRef.current = data;
          }
          setComponentState((prev) => ({...prev, loading: false}));
        },
        (error) => {

          setComponentState((prev) => ({...prev, loading: false}));
        },
        '',
        false
      );
    } else {
      const data = getFormComponentsDataForProgress(
        props.formData.components,
        props.optionData
      );
      // setComponentMetaData(data);
      componentStateRef.current = data;
      setComponentState((prev) => ({...prev, loading: false}));
    }
  };

  const showFormProgressbar = (): boolean => {
    if (getFormIsPrint()) {
      return false;
    }
    if (props.readOnly) {
      return false;
    }
    if (props.isPreviewMode) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    //initailize the progress data
    setProgress(0);
    showFormProgressbar() && getProgressReferenceData();
    setFormComponent(getComponents());
  }, [JSON.stringify(props.formData.components)]);

  const headerElement = renderHeader(formHeaderFooter.header)
  const SkeletonForm = props?.formData?.components || new Array(6).fill(0);
  return (
    <>
      {componentState?.loading ? (
        <VStack
          flex={1}
          justifyContent={'flex-end'}
          marginX={
            props.extraStyles?.marginX || {
              base: 0,
              lg: 32,
            }
          }
        >
          {SkeletonForm?.map((item, index) => (
            <Box key={index} m={2}>
              <Skeleton.Text mb={1} />
              <Skeleton height={100}  />
            </Box>
          ))}
        </VStack>
      ) : (
       <>
        <View flex={1}>
          <Box
            borderColor={Colors.Custom.BorderColor}
            backgroundColor={props.backgroundColor || 'white'}
            rounded="lg"
            borderWidth={props.hideBorder ? 0 : 1}
            marginX={
              props.extraStyles?.marginX || {
                base: 0,
                lg: 32,
              }
            }
            marginY={
              props.extraStyles?.marginY || {
                base: 0,
                lg: 4,
              }
            }
            style={props.extraStyles || {}}
          >
            <View>
              <div
                style={
                  !props.readOnly
                    ? {
                        backgroundColor: Colors.Custom.BackgroundColor,
                      }
                    : {}
                }
              >
                <View>
                  {headerElement ? headerElement : <Skeleton height={100} m={2}/>}
                </View>
                <Affix
                  offsetTop={0}
                  onChange={(affixed) =>
                    setIsProgressBarStickyTop(affixed ?? false)
                  }
                >
                  <Box
                    borderColor={Colors.Custom.BorderColor}
                    borderBottomWidth={isProgressBarStickyTop ? 1 : 0}
                    mb={4}
                    backgroundColor={Colors.Custom.BackgroundColor}
                  >
                    {showFormProgressbar() && (
                      <Progress
                        className="custom-progress-styles"
                        percent={progess}
                        strokeColor={Colors.Custom.SuccessColor}
                        trailColor={Colors.Custom.Gray100}
                      />
                    )}
                  </Box>
                </Affix>
                {componentState.showFinancialWarning && !isPreviewMode && (
                  <HStack alignItems={'center'} justifyContent={'center'} space={2} marginX={2} marginTop={2}>
                    <InfoSvg customColor={Colors.Custom.Gray500} />
                    <Text size={'smMedium'} style={{color: Colors.Custom.Gray500}} flexShrink={1}>
                      Please do not enter any financial information (credit card details etc) in this form
                    </Text>
                    {!(props.readOnly || getFormReadOnly()) && (
                      <>
                        <View width={0.3} height={4} backgroundColor={Colors.Custom.Gray200} />
                        <Pressable onPress={() => setComponentState((prev) => ({...prev, showFinancialWarning: false}))}>
                          <Feather name="x" size={18} color={Colors.Custom.Gray500} />
                        </Pressable>
                      </>
                    )}
                  </HStack>
                )}
                <Divider mt={1} />
              </div>
              <View
                padding={
                  props.extraStyles?.padding || {
                    base: 4,
                    sm: 2,
                    md: 4,
                    lg: 8,
                    xl: 8,
                  }
                }
              >
                <FHForm
                  readOnly={props.readOnly || getFormReadOnly()}
                  submittedResponse={submittedData}
                  optionData={optionData}
                  components={formComponent}
                  onChange={handleOnChange}
                  onSubmit={handleOnSubmit}
                  isPreviewMode={isPreviewMode}
                />
              </View>
              <View>
                  {renderHeader(formHeaderFooter.footer)}
                </View>
            </View>
          </Box>
        </View>

        <ReactToPrint
          documentTitle={props.formData.name}
          trigger={() => <></>}
          content={() => componentRef.current}
          ref={printRef}
          print={target => {
            return new Promise<any>((resolve, reject) => {
              if (target.contentDocument) {
                target.contentDocument.title = props.formData.name || '';
              }
              target?.contentWindow?.print?.();
              resolve(true);
            });
          }}
          removeAfterPrint={true}
          />
        <div style={{display: 'none'}}>
          <ComponentToPrint ref={componentRef} header={formHeaderFooter.header} footer={formHeaderFooter.footer}>
            <>
              {componentState.showFinancialWarning && !isPreviewMode && (
                <HStack alignItems={'center'} justifyContent={'center'} space={2} marginX={2} marginTop={2}>
                  <InfoSvg customColor={Colors.Custom.Gray500} />
                  <Text size={'smMedium'} style={{color: Colors.Custom.Gray500}} flexShrink={1}>
                    Please do not enter any financial information (credit card details etc) in this form
                  </Text>
                  {!(props.readOnly || getFormReadOnly()) && (
                    <>
                      <View width={0.3} height={4} backgroundColor={Colors.Custom.Gray200} />
                      <Pressable onPress={() => setComponentState((prev) => ({...prev, showFinancialWarning: false}))}>
                        <Feather name="x" size={18} color={Colors.Custom.Gray500} />
                      </Pressable>
                    </>
                  )}
                </HStack>
              )}
              <View padding={props.extraStyles?.padding || {base: 4, sm: 2, md: 4, lg: 8, xl: 8 }}>
                <FHForm
                  submittedResponse={submittedData}
                  optionData={optionData}
                  components={formComponent}
                  isPreviewMode={isPreviewMode}
                />
              </View>
            </>
          </ComponentToPrint>
        </div>
       </>
      )}
    </>
  );
};

export default PublicFormView;
