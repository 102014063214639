import React, { useContext, useEffect, useState } from 'react'
import { Drawer } from 'antd';
import { Center, Spinner, View } from 'native-base';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../../../constants';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { OrderType } from '../OrderConstants';
import { Colors } from '../../../../../../styles';
import { CodeableConcept, RequestGroup } from 'fhir/r4';
import { EventBus } from '../../../../../../utils/EventBus';
import { SUPPORTED_EVENT_CODE } from '../../../../../../constants/WebSocketConst';
import AddOrUpdateExternalOrdersView from './components/AddOrUpdateExternalOrdersView';
import { IOrderViewModel } from './interfaces';
import { getUserName, getUserUUID, isBrowserIncompatibleForPrint } from '../../../../../../utils/commonUtils';
import { getFHIRTemplateFromOrder, getOrderViewModelFromRequestGroup, getUserContactDetails, getUserNPI, getUserSignatureId, OrderStatus } from './Helper';
import { addOrUpdateOrder } from '../../../../../../services/CommonService/OrderService';
import { EXTENSION_URLS } from '../../../PatientNotes/components/AddOrUpdateTemplate/constant';
import { UserQueries } from '../../../../../../services';
import { useLazyQuery } from '@apollo/client';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { PrintRequisitionForm } from '../../../../../common/PrintRequisitionForm/PrintRequisitionForm';
import BaseService from '../../../../../../services/CommonService/BaseService';

interface IAddOrUpdateExternalOrdersProps {
  isVisible: boolean;
  printOrder: boolean;
  patientContactId: string | number;
  orderType: OrderType;
  documentReferenceId?: string;
  unFormattedContactData: any;
  personData: any;
  currentOrder?: RequestGroup;
  existingDiagnosis: CodeableConcept[];
  onClose?: () => void;
}

interface IAddOrUpdateExternalOrdersState {
  saveAsDraftLoading: boolean;
  isPrintDrawerOpen: boolean;
  submitLoading: boolean;
  printLoading: boolean;
  viewLoading: boolean;
  errorMessage: string;
  showErrors: boolean;
  selectedOrder: IOrderViewModel;
}

enum OrderAction {
  submit,
  saveAsDraft
}

const AddOrUpdateExternalOrders = (props: IAddOrUpdateExternalOrdersProps) => {
  // Constants
  const {
    isVisible,
    orderType,
    currentOrder,
    patientContactId,
    documentReferenceId,
    unFormattedContactData,
    personData,
    printOrder,
    existingDiagnosis,
    onClose
  } = props;
  const height = window.innerHeight - 112;
  const loggedInUserName = getUserName();
  const loggedInUserUuid = getUserUUID();
  const isIncompatibleBrowser = isBrowserIncompatibleForPrint();
  const canShowFormDrawer = !printOrder && isVisible;
  const commonData = useContext(CommonDataContext);
  const providerTypesList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PROVIDER_IDENTIFIER_TYPE
  );

  // States
  const [componentState, setComponentState] = useState<IAddOrUpdateExternalOrdersState>({
    submitLoading: false,
    isPrintDrawerOpen: printOrder,
    saveAsDraftLoading: false,
    viewLoading: false,
    printLoading: printOrder,
    errorMessage: 'Something went wrong to initiate ordering experience.',
    showErrors: false,
    selectedOrder: getOrderViewModelFromRequestGroup(currentOrder, {
      patientId: personData.patientId || personData.patientUuid || '',
      authorName: loggedInUserName,
      authorUserUuid: loggedInUserUuid,
      orderType: orderType,
      documentReferenceId: documentReferenceId,
      existingDiagnosis: existingDiagnosis,
    }),
  });

  // hooks
  const [getOrderingPhysicianDetails] = useLazyQuery(UserQueries.GET_USER_IDENTIFIERS_WITH_UUID);

  useEffect(() => {
    initializeComponent();
  }, []);

  // Functions
  const initializeComponent = async () => {
    if (currentOrder) {
      await setOrderingPhysicianDetails();
    }
    if (printOrder) {
      onPrintClick();
    }
  }

  const setOrderingPhysicianDetails = async () => {
    const orderingPhysician = currentOrder?.extension?.find((item) => item.url === EXTENSION_URLS.orderingPhysician);
    const userUuid = orderingPhysician?.valueReference?.reference?.split('/')?.[1] || '';
    if (userUuid) {
      setComponentState((prev) => ({...prev, viewLoading: true}));
      try {
        const response = await getOrderingPhysicianDetails({
          fetchPolicy: 'no-cache',
          variables: {
            userId: userUuid,
          },
        });
        if (response.data?.users?.length && componentState.selectedOrder.orderingPhysician) {
          const signatureId = getUserSignatureId(response.data.users[0], providerTypesList) || '';
          const npi = getUserNPI(response.data.users[0], providerTypesList) || '';
          const userContact = getUserContactDetails(response.data.users[0]);
          componentState.selectedOrder.orderingPhysician.npi = npi;
          componentState.selectedOrder.orderingPhysician.signatureId = signatureId;
          componentState.selectedOrder.orderingPhysician.phone = userContact.phoneNumber;
          componentState.selectedOrder.orderingPhysician.email = userContact.email;
          componentState.selectedOrder.orderingPhysician.fax = userContact.faxNumber;
          setComponentState((prev) => ({
            ...prev,
            viewLoading: false,
            selectedOrder: componentState.selectedOrder,
          }));
        } else {
          setComponentState((prev) => ({...prev, viewLoading: false}));
        }
      } catch (error) {

        setComponentState((prev) => ({...prev, viewLoading: false}));
      }
    }
  }

  const setOrderingPhysicianSignatureUrl = async () => {
    const signatureId = componentState.selectedOrder.orderingPhysician?.signatureId;
    if (signatureId) {
      try {
        const baseService = BaseService.getSharedInstance().axios;
        const response = await baseService.get(
          `/crm-nest/api/attachment/${signatureId}`,
        );
        if (response.data?.url && componentState.selectedOrder.orderingPhysician) {
          componentState.selectedOrder.orderingPhysician.signatureUrl = response.data?.url;
          setComponentState((prev) => ({
            ...prev,
            selectedOrder: componentState.selectedOrder,
          }));
        }
      } catch (error) {

      }
    }
  }

  const getHeaderTitle = () => {
    switch (orderType) {
      case OrderType.LAB: return currentOrder?.id ? 'Edit Order' : 'New Lab Order';
      case OrderType.MED: return 'eRx';
      case OrderType.RAD: return currentOrder?.id ? 'Edit Order' : 'New Imaging Order';
    }
  };

  const broadcastOrderEvent = (code: string) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(code, {patientId: props.personData?.patientId || props.personData?.patientUuid});
  };

  const onCloseDrawer = (triggerEvent: boolean) => {
    if (triggerEvent) broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
    onClose?.();
  }

  const isInvalidOrderData = (selectedOrder: IOrderViewModel, action: OrderAction): boolean => {
    return (
      !selectedOrder.orders.length ||
      !selectedOrder.diagnosis.length ||
      !selectedOrder.orderingPhysician?.userUuid ||
      !selectedOrder.orderDate
    );
  }

  const onSubmit = async () => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!isInvalidOrderData(componentState.selectedOrder, OrderAction.submit)) {
      const order = getFHIRTemplateFromOrder(componentState.selectedOrder, OrderStatus.active);
      setComponentState((prev) => ({...prev, submitLoading: true }));
      try {
        await addOrUpdateOrder(order);
        setComponentState((prev) => ({...prev, submitLoading: false }));
        onCloseDrawer(true);
      } catch (error) {

        setComponentState((prev) => ({...prev, submitLoading: false }));
      }
    }
  }

  const onSaveAsDraft = async () => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!isInvalidOrderData(componentState.selectedOrder, OrderAction.saveAsDraft)) {
      const order = getFHIRTemplateFromOrder(componentState.selectedOrder, OrderStatus.draft);
      setComponentState((prev) => ({...prev, saveAsDraftLoading: true }));
      try {
        await addOrUpdateOrder(order);
        setComponentState((prev) => ({...prev, saveAsDraftLoading: false }));
        onCloseDrawer(true);
      } catch (error) {

        setComponentState((prev) => ({...prev, saveAsDraftLoading: false }));
      }
    }
  }

  const onPrintClick = async () => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!isInvalidOrderData(componentState.selectedOrder, OrderAction.submit)) {
      const order = getFHIRTemplateFromOrder(componentState.selectedOrder, OrderStatus.active);
      setComponentState((prev) => ({...prev, printLoading: true }));
      try {
        await addOrUpdateOrder(order);
        await setOrderingPhysicianSignatureUrl();
        setComponentState((prev) => ({...prev, isPrintDrawerOpen: true, printLoading: false }));
      } catch (error) {

        setComponentState((prev) => ({...prev, printLoading: false }));
      }
    }
    setComponentState((prev) => ({...prev, printLoading: false}));
  }

  return (
    <>
    <Drawer
      headerStyle={{borderWidth: 0, marginBottom: 0}}
      destroyOnClose
      placement="right"
      onClose={() => onCloseDrawer(false)}
      open={canShowFormDrawer}
      closable={false}
      width={'50%'}
      maskClosable={false}
      title={
        <ModalActionTitle
          title={getHeaderTitle()}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              size: 'sm',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              isDisabled: componentState.saveAsDraftLoading || componentState.submitLoading || componentState.viewLoading || componentState.printLoading,
              onClick: () => onCloseDrawer(false),
            },
            {
              show: true,
              id: 2,
              btnText: 'saveAsDraft',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: componentState.saveAsDraftLoading,
              isDisabled: componentState.saveAsDraftLoading || componentState.submitLoading || componentState.viewLoading || componentState.printLoading,
              onClick: onSaveAsDraft,
            },
            {
              show: !isIncompatibleBrowser,
              id: 3,
              btnText: 'print',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: componentState.printLoading,
              isDisabled: componentState.saveAsDraftLoading || componentState.submitLoading || componentState.viewLoading || componentState.printLoading,
              onClick: onPrintClick,
            },
            {
              show: true,
              id: 4,
              btnText: 'sign',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: componentState.submitLoading,
              isDisabled: componentState.saveAsDraftLoading || componentState.submitLoading || componentState.viewLoading || componentState.printLoading,
              onClick: onSubmit,
            },
          ]}
        />
      }
    >
      {canShowFormDrawer && componentState.viewLoading && (
        <Center h='100%'>
          <Spinner size={'lg'} />
        </Center>
      )}
      {canShowFormDrawer && !componentState.viewLoading && (
        <View height={height} marginX={-2} overflowY="scroll">
          <AddOrUpdateExternalOrdersView
            orderType={orderType}
            selectedOrder={componentState.selectedOrder}
            showErrors={componentState.showErrors}
            onChange={(order) => {
              setComponentState((prev) => ({...prev, selectedOrder: order}));
            }}
          />
        </View>
      )}
    </Drawer>
    {
      componentState.isPrintDrawerOpen &&
      <PrintRequisitionForm
        unFormattedContactData={unFormattedContactData}
        isOpen={componentState.isPrintDrawerOpen}
        showPreviewInDrawer={true}
        isLoading={false}
        selectedOrder={componentState.selectedOrder}
        onClose={() => {
          setComponentState((prev) => ({...prev, isPrintDrawerOpen: false}));
          onCloseDrawer(true);
        }}
      />
    }
    </>
  );
}

export default AddOrUpdateExternalOrders;
