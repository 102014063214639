import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.BorderColor,
  },
  cannedResponsesMain: {
    width: '97%',
    overflow: 'scroll',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginLeft: 10,
    marginRight: 15,
    padding: 5,
  },
  topBar: {
    //justifyContent: 'space-between',
    height: 65,
    alignItems: 'center',
    borderBottomColor: Colors.Custom.GRAY150,
  },

  flexStart: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 10,
  },

  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 10,
  },
  xsFontSize: {
    fontSize: 12,
  },
  sFontSize: {
    fontSize: 13,
  },
  scrollViewStyle: {
    // overflow: 'scroll',
    borderBottomColor: Colors.Custom.BorderColor,
  },
  msgFooterStyle: {
    borderTopColor: 'coolGray.200',
  },
  icons: {
    // marginLeft: 5,
    fontSize: 14.29,
    color: Colors.Custom.IconColorWhite,
  },
  actionIcons: {
    color: Colors.Custom.IconColorWhite,
  },

  btnText: {
    color: Colors.Custom.FontColorWhite,
    marginRight: 5,
  },
  footerBtn: {
    borderRadius: 0,
    marginLeft: 12,
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: Colors.Custom.BorderColor,
  },
  footerBtnWrapper: {
    // borderTopWidth: 1,
    // borderColor: Colors.Custom.BorderColor,
    //backgroundColor: '#FCFDFD',
  },
  mainMenuContent: {
    paddingTop: 2,
    paddingBottom: 2,
    cursor: 'pointer',
  },
  lastMenu: {
    paddingBottom: 10,
  },
  firstMenu: {
    paddingTop: 10,
  },
  mainMenuTxt: {
    marginLeft: 5,
  },
  dividerStyle: {
    width: '100%',
  },
  btnStyle: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  actionBtns: {
    padding: 5,
    height: 28,
    borderRadius: 35,
  },
  messageHeader: {
    border: '1px solid #EAECF0',
    boxSizing: 'border-box',
    // padding: '5',
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: 8,
  },
  spinnerStyle: {
    marginHorizontal: 'auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    // backgroundColor: '#0000000f',
    height: 'auto',
    width: '100%',
    padding: 0,
  },
});


export const replyMessageStyle = StyleSheet.create({
  ReplyMessageView: {
    maxHeight: 150,
    // width: '97%',
    // overflow: 'scroll',
    position: 'absolute',
    left: 0,
    zIndex: 100,
    borderRadius: 8,
    borderWidth: 1,
    marginLeft: 10,
    marginRight: 15,
    padding: 5,
  },
  ReplyMessageViewMob: {
    maxHeight: 150,
    width: '97%',
    overflow: 'scroll',
    left: 0,
    zIndex: 100,
    borderRadius: 8,
    borderWidth: 1,
    marginLeft: 10,
    marginRight: 15,
    padding: 5,
  },
  listMessageView: {
    maxHeight: 150,
    // width: '97%',
    overflow: 'scroll',
    // position: 'absolute',
    // left: 0,
    zIndex: 100,
    borderRadius: 8,
    borderWidth: 1,
    marginLeft: 10,
    marginRight: 15,
    padding: 5,
  },
});
