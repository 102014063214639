import  React,{useState} from 'react';
import {Modal} from 'antd';
import {Button, View} from 'native-base';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../../constants';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';

// const {Content, Footer, Header, CloseButton, Body} = Modal;

const DeleteMessageModal = (props: any) => {
  const { onClose, onConfirm, isOpen} = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  return (
    <>
      <Modal className="delete-message-modal" title="Message" open={isOpen}
        onCancel={()=> {
          onClose()
        }}
        bodyStyle={{
          marginLeft:30,
          marginRight:30
        }}        
        footer={null}
      >
        <View>
          <DisplayText size='mdMedium' textLocalId={props?.titleMessage} />
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'ok',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>

    </>
  );
};

export default DeleteMessageModal;
