import {IFeedData} from "./interface";

export const groupByFeedDataByTimeStamp = (feedDataList: IFeedData[]) => {
  return feedDataList?.reduce((singleData: any, singleFeedDataObj) => {
    const date = singleFeedDataObj.time_stamp;
    const key = new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
    if (!singleData[key]) {
      singleData[key] = [];
    }
    singleData[key].push(singleFeedDataObj);
    return singleData;
  }, {});
}
