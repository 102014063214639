import {useLazyQuery} from "@apollo/client";
import {Drawer} from "antd";
import {View, Text, VStack} from "native-base";
import React, {useContext, useEffect, useState} from "react";
import {COMMON_ACTION_CODES} from "../../../../../../../../../../constants/ActionConst";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../../../../../../constants/Configs";
import {BUTTON_TYPE} from "../../../../../../../../../../constants/StringConst";
import {CommonDataContext} from "../../../../../../../../../../context/CommonDataContext";
import FormsQueries from "../../../../../../../../../../services/Forms/FormsQueries";
import {Colors} from "../../../../../../../../../../styles/Colors";
import {getAccountUUID} from "../../../../../../../../../../utils/commonUtils";
import {ModalActionTitle} from "../../../../../../../../../common/ModalActionTitle/ModalActionTitle";
import PublicFormView from "../../../../../../../../../PublicPages/PublicForm/PublicFormView";
import RiskScoreDisplay from "../../../../../../../../Forms/FormBuilderWidget/RiskScoreDisplay/RiskScoreDisplay";
import {getSubmittedDataFromComponents} from "./FormUtils";

import {ISubmittedFormResponse} from "./interface";


const ActivityFormResponseDrawer = (props: {
  resourceData: ISubmittedFormResponse,
  formId: string;
  isDrawerVisible: boolean;
  onActionPerformed: (actionCode: string, actionData?: any) => void;
}) => {

  const {
    resourceData,
    isDrawerVisible,
    onActionPerformed
  } = props;
  const contextData = useContext(CommonDataContext);
  const ehrCapabilities = contextData.ehrCapabilities;
  const accountUUID = getAccountUUID();
  const submittedFinaldata = {
    response: getSubmittedDataFromComponents(resourceData?.formResponse?.components || []),
    ehrCapabilities: ehrCapabilities
  }
  const [activityFormResponseState, setActivityFormResponseState] = useState({
    formData: {
      name: ''
    } as any,
    isLoading: true,
  });

  const [getFormsNameById] = useLazyQuery(FormsQueries.GET_FORMS_NAME_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getFormDetails = async () => {
    setActivityFormResponseState((prev) => {
      return {
        ...prev,
        isLoading: true,
      }
    });

    const formRespData = await getFormsNameById({
      variables: {
        formId: props.formId,
      }
    }).catch((error)=> {

    });


    setActivityFormResponseState((prev) => {
      return {
        ...prev,
        isLoading: false,
        formData: formRespData?.data.forms[0]
      }
    });
  }

  useEffect(() => {
    getFormDetails();
  }, [props.formId]);


  return (

    <Drawer
      visible={isDrawerVisible}
      onClose={() => {
        onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
      }}
      destroyOnClose
      width={'50%'}
      placement="right"
      title={
        <ModalActionTitle
        title={'formResponse'}
        titleColor={''}
        buttonList={[
          {
            id: 1,
            btnText: 'close',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
            },
          },
        ]}
      />
      }
    >
      {resourceData?.formResponse?.components && (
        <VStack space={2}>
          {
            !!resourceData?.metadataScore?.length &&
            resourceData?.metadataScore.map?.((score, index) => {
              if (!score?.metadata?.state) {
                return <React.Fragment key={index}/>;
              }
              return <RiskScoreDisplay
                key={index}
                name={score.metadata.name}
                interpretation={score.metadata.state}
                score={score.metadata.score}
              />
            })
          }
          <PublicFormView
            formData={{
              name: activityFormResponseState?.formData?.name,
              components: resourceData?.formResponse?.components,
              id: resourceData?.id,
              noData: false,
            }}
            optionData={{
              accountUUID,
              ehrCapabilities,
            }}
            submittedData={submittedFinaldata}
            readOnly
            extraStyles={{marginX: 4}}
          />
        </VStack>
      )}
    </Drawer>
  );
};

export default ActivityFormResponseDrawer;
