import { DATE_FORMATS } from '../../../../../constants';
import { IMlov } from '../../../../../Interfaces';
import { getAccountUUID, unmaskPhoneNumber } from '../../../../../utils/commonUtils';
import { getDateStrFromFormat, getMomentObj } from '../../../../../utils/DateUtils';
import { getMlovCodeFromId, getMlovObjectFromCode, getMlovObjectFromId } from '../../../../../utils/mlovUtils';
import { getBase64 } from '../../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { isValidPhoneNumber } from '../../Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {IUser} from '../interfaces';
import { AddressInfo, Contact, ContactDetails, ContactDetailState, IdentifierType, IdentifierTypeState, PersonContactDetails } from './interfaces';
import { v4 as uuidV4 } from 'uuid';

export const processContactDetails = (data: PersonContactDetails[]) => {
  const response: {
    [key: string]: ContactDetailState;
  } = {};
  data?.forEach((item) => {
    item?.contactDetails?.forEach((contact:Contact) => {
      response[contact?.relationType?.code] = {
        value: contact.value,
        contactDetailId: contact.id,
        id:item.id,
        typeId: contact.contactTypeId,
      };
    });
  });
  return response;
};

export const getContactDetailsByContactType = (data: PersonContactDetails[], contactType: string) => {
  let response = {} as ContactDetailState;
  data?.forEach((item) => {
    item?.contactDetails?.forEach((contact:Contact) => {
      if(contact?.relationType?.code === contactType){
        response = {
          value: contact.value,
          contactDetailId: contact.id,
          id:item.id,
          typeId: contact.contactTypeId,
        };
      }
    });
  });
  return response;
};

export const processProviderIdentifierDetails = (data:IdentifierType[],providerIdentifierList:IMlov[]) => {
  const response: {
    [key: string]: IdentifierTypeState;
  } = {};

  data?.forEach((item) => {
    const mlovObj = getMlovObjectFromId(
      item.identifierTypeId,
      providerIdentifierList
    );
    if (mlovObj) {
      response[mlovObj.code] = {
        code: mlovObj?.code,
        identifierTypeId: item?.identifierTypeId,
        id: item.id,
        value: item.value,
        accountLocationId: item.accountLocationId,
        stateName:item?.states?.name || undefined,
      };
    }
  });
  return response;
}

export const getProviderIdentifierDataForPost = (
  data: any,
  providerIdentifierList: IMlov[],
  accountUUID: string,
  deaStateId: string,
  includeUserId?: boolean
) => {
  const response: IdentifierType[] = [];
  Object.keys(data).forEach((key) => {
    const mlov = getMlovObjectFromCode(key, providerIdentifierList);
    if (mlov) {
      response.push({
        accountId: accountUUID,
        identifierTypeId: data[key].identifierTypeId || mlov.id,
        value: data[key].value,
        id: data[key].id,
        ...(mlov.code === 'DEA_NUMBER' && {accountLocationId: deaStateId}),
        ...(includeUserId && {userId: data['uuid']}),
      });
    }
  });
  return response;
};



export const getContactDetailsDataForPost = (
  data: any,
  contactTypeList: IMlov[],
  accountUUID: string,
  isNewData?: boolean
) => {
  const createNewResponse : {
    contactTypeId: string;
    value: string;
    accountId: string;
  }[] = [];
  const response: {
    id: string;
    contactDetailId: string;
    accountId: string,
    contactDetails: {
      data: { value: string; contactTypeId: string, accountId: string,}[];
      on_conflict: any;
    };
  }[] = [];
  contactTypeList.forEach((mlov) => {
    const key = mlov.code;
    if (data[key]) {
      if (isNewData) {
        createNewResponse.push({
          contactTypeId: mlov.id,
          value: data[key].value,
          accountId: accountUUID,
        });
      } else {
        response.push({
          id: data[key].id,
          // If contactDetailId is not available then add new generated uuidV4() to make sure graphql query does not fail
          contactDetailId: data[key].contactDetailId || uuidV4(),
          accountId: accountUUID,
          contactDetails: {
            data: [
              {
                accountId: accountUUID,
                value: data[key].value,
                contactTypeId: data[key].typeId || mlov.id,
              },
            ],
            on_conflict: {
              constraint: 'contact_details_pkey',
              update_columns: ['value', 'contactTypeId'],
            },
          },
        });
      }
    }
  });
  return isNewData ? createNewResponse : response;
};


export const getPostData = (userFormData:any,providerTypesList:IMlov[],accountUuid:string,personContactTypeList: IMlov[]) => {
  return {
    id: userFormData.id,
    uuid: userFormData.uuid,
    name: userFormData.name,
    subtitle: userFormData.subtitle,
    description: userFormData.description,
    signatureAttachmentId:
      userFormData.signatureAttachmentId || undefined,
    providerIdentifiers: {
      data: getProviderIdentifierDataForPost(
        userFormData,
        providerTypesList,
        accountUuid,
        userFormData?.deaStateId
      ),
      on_conflict: {
        constraint: 'provider_identifiers_pkey',
        update_columns: ['value','accountLocationId'],
      },
    },
    persons: {
      data: {
        id: userFormData?.personId || undefined,
        name: userFormData.name,
        firstName: userFormData?.firstName || undefined,
        lastName: userFormData?.lastName || undefined,
        middleName: userFormData?.middleName || undefined,
        genderId: userFormData.genderId,
        birthSexId: userFormData.genderId,
        dateOfBirth: userFormData.birthDate ? getDateStrFromFormat(
          userFormData.birthDate,
          DATE_FORMATS.API_DATE_FORMAT
        ) : undefined,
        accountId: accountUuid,
        personContactDetails: {
          data: getContactDetailsDataForPost(
            userFormData,
            personContactTypeList,
            accountUuid
          ),
          on_conflict: {
            constraint: 'person_contact_details_pkey',
            update_columns: ['contactDetailId'],
          },
        },
        personAddressDetails: {
          data: [
            {
              id: userFormData?.addressRelationId || undefined,
              addressDetailId: userFormData?.addressId,
              accountId: accountUuid,
              addressDetails: {
                data: [
                  {
                    typeId: userFormData?.addressTypeId,
                    cityId: userFormData?.cityId,
                    stateId: userFormData?.stateId,
                    line1: userFormData?.line1,
                    line2: userFormData?.line2,
                    countryId: userFormData?.countryId,
                    accountId: accountUuid,
                    zipcodeId: userFormData?.zipcodeId,
                  },
                ] as AddressInfo[],
                on_conflict: {
                  constraint: 'addresses_pkey',
                  update_columns: [
                    'typeId',
                    'cityId',
                    'zipcodeId',
                    'line1',
                    'line2',
                    'countryId',
                    'stateId'
                  ],
                },
              },
            },
          ],
          on_conflict: {
            constraint: 'person_address_details_pkey',
            update_columns: ['addressDetailId'],
          },
        },
      },
      on_conflict: {
        constraint: 'persons_pkey',
        update_columns: [
          'name',
          'firstName',
          'lastName',
          'middleName',
          'genderId',
          'birthSexId',
          'dateOfBirth',
        ],
      },
    },
  };
}
export const getFormattedUserData = (user: IUser) => {
  const locations = [] as any;
  const userRoles = [] as any;
  const locationUUid = [] as string[];
  const userRolesId = [] as string[];

  user.userPracticeLocations?.forEach((item) => {
   !item?.isDeleted && item?.accountLocations?.forEach((accountLocation) => {
      if (!accountLocation?.practiceLocation?.isDeleted && accountLocation?.practiceLocation?.name) {
        const tempLocation = {
          uuid: accountLocation?.practiceLocation?.uuid,
          locationUuid: accountLocation?.practiceLocation?.uuid,
        };
        locationUUid.push(accountLocation?.practiceLocation?.uuid);
        locations.push(tempLocation);
      }
    });
  });
  user.userRoles?.map((role: any) => {
    const tempRole = {
      userRoleId: role?.userRoleId,
    };
    userRolesId.push(role?.userRole?.userRole?.id);
    userRoles.push(tempRole);
  });
  const formattedUserData = {
    id: user?.id,
    name: user?.name || '',
    email: user?.email || '',
    subtitle: user?.subtitle || '',
    description: user?.description || '',
    userPracticeLocation: locations,
    uuid: user?.uuid,
    userRoles: userRoles,
    locationUUid: locationUUid,
    userRolesId: userRolesId,
  };
  return formattedUserData;
};

export const getUserCategoriesQueryObj = (roleId: string[]) => {
  const _or: any = [];
  roleId?.forEach((element) => {
    _or.push({
      roleId: {_eq: element},
    });
  });
  return {_or: _or};
};

export const getFirstAndLastNameFromUserName = (userName: string) => {
  const name = userName?.split(' ');
  let firstNameFromUserName = '';
  let lastNameFromUserName = '';
  if (name?.length) {
    firstNameFromUserName = name[0];
    name?.splice(0, 1);
    if (name.length) {
      lastNameFromUserName = name?.join(' ');
    }
  }
  return {firstName: firstNameFromUserName, lastName: lastNameFromUserName};
}

export const validates = (
  leadFormData: any,
  otherChecks: {
    isGenderAtBirthRequired: boolean;
    isPractitionerRequired: boolean;
    isAddressLine1Required:boolean;
    isMobileNumberRequired:boolean;
    isFaxNumberRequired: boolean;
    isZipCodeRequired: boolean;
    isBirthDateRequired: boolean;
    shouldPerformPhoneNumberValidation: boolean;
    isMasterAccount?: boolean
  }
) => {
  const {
    isGenderAtBirthRequired,
    isPractitionerRequired,
    isAddressLine1Required,
    isMobileNumberRequired,
    isFaxNumberRequired,
    isZipCodeRequired,
    isBirthDateRequired,
    shouldPerformPhoneNumberValidation,
    isMasterAccount
  } = otherChecks;
  const today = new Date();
  const currentData = getMomentObj(today);
  const currentBirthdate = getMomentObj(leadFormData.birthDate);
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;
  errors.firstName = !leadFormData.firstName
    ? 'First Name is required'
    : leadFormData.firstName.length < 3
    ? 'First Name is too short'
    : '';

  errors.lastName = !leadFormData.lastName
    ? 'Last Name is required'
    : leadFormData.lastName.length < 3
    ? 'Last Name is too short'
    : '';

  errors.email =
    !leadFormData.email
      ? 'Email is required'
      : leadFormData.email &&
        !leadFormData.email.match(emailRegex)
      ? 'Please enter a valid email'
      : '';

  const unmaskedPhone = unmaskPhoneNumber(leadFormData.phone || '');
  errors.mobileNumber =
    !unmaskedPhone && isMobileNumberRequired
      ? 'Phone number is required'
      : unmaskedPhone && unmaskedPhone.length !== 10
      ? 'Phone number should be 10 digits'
      : '';
  if (shouldPerformPhoneNumberValidation && unmaskedPhone.length && !errors.mobileNumber) {
    const phoneValidation = isValidPhoneNumber(unmaskedPhone);
    if (!phoneValidation.isValid && phoneValidation.error) {
      errors.mobileNumber = phoneValidation.error;
    }
  }

  const unmaskedFax = unmaskPhoneNumber(leadFormData.fax || '');
  errors.fax =
    !unmaskedFax && isFaxNumberRequired
      ? 'Fax number is required'
      : unmaskedFax && unmaskedFax.length !== 10
      ? 'Fax number should be 10 digits'
      : '';
  if (shouldPerformPhoneNumberValidation && unmaskedFax.length && !errors.fax) {
    const phoneValidation = isValidPhoneNumber(unmaskedFax, 'Fax number');
    if (!phoneValidation.isValid && phoneValidation.error) {
      errors.fax = phoneValidation.error;
    }
  }


  errors.zipcode = !leadFormData.zipCodeId && isZipCodeRequired  ? 'Zip code is required' : leadFormData.zipCode?.length < 5 ? 'Zip code should be 5 digit' : '';

  errors.birthdate = !leadFormData.birthDate && isBirthDateRequired ? 'Birth date is required' : '';
  if (leadFormData.birthDate && currentBirthdate > currentData) {
    errors.birthdate = 'Date of birth should not be future date';
  }

  if (isAddressLine1Required) {
    errors.line1 = !leadFormData.line1?.trim() ? 'Address Line 1 is required':'';
  }

  if (isGenderAtBirthRequired) {
    errors.sexAtBirth = !leadFormData.sexAtBirth
      ? 'Gender at Birth is required'
      : '';
  }

  if (isPractitionerRequired) {
    errors.practitioner = !leadFormData.practitionerId
      ? 'Please select a practitioner'
      : '';
  }

  if (!leadFormData.roles.length && !isMasterAccount) {
    errors.roles = 'Please select at least one role';
  }
  errors.accountRole = !leadFormData.associatedAccounts?.length && isMasterAccount ? 'Practice selection and roles selection is mandatory' : ''
  if (leadFormData.associatedAccounts?.length) {
    leadFormData.associatedAccounts.forEach((account: any)=> {
      if ((!account?.editedRoles?.length) && !account.accountUuid) {
        errors.accountRole = 'Practice selection and roles selection is mandatory'
      } else  if (!account.editedRoles?.length) {
        errors.accountRole = 'Roles selection is mandatory'
      } else if (!account.accountUuid) {
        errors.accountRole = 'Practice selection is mandatory'
      }
    })
  }
  if (
    errors.firstName ||
    errors.lastName ||
    errors.email ||
    errors.fax ||
    errors.mobileNumber ||
    errors.zipcode ||
    errors.birthdate ||
    errors.line1 ||
    errors.sexAtBirth ||
    errors.practitioner ||
    errors.roles ||
    errors.accountRole
  ) {
    validate = false;
  }
  return {validate: validate, errors: errors};
};
