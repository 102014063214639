import {APPOINTMENT_PARTICIPANT_STATUS_CODES, LOCATION_TYPE_CODES} from '../../../constants/MlovConst';
import {IMlov, IUser} from '../../../Interfaces';
import {
  addDaysInDate,
  isCurrentDateInFutureComparedToOther,
} from '../../../utils/DateUtils';
import {getMlovIdFromCode} from '../../../utils/mlovUtils';
import {FormStatus} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingEnums';
import {IParticipantData, IUserPracticeLocation} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import { AppointmentAvailabilityCode } from '../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import {IBookingWidgetData} from './AppointmentBookingWidget';

export const APPOINTMENT_FORM_CODE = 'APPOINTMENT_SIGN_UP';

export const getFutureSlots = (response: any) => {
  if (
    response?.getAvailableSlots?.availabilities?.length > 0 &&
    response.getAvailableSlots.availabilities[0].slots?.length > 0
  ) {
    const futureSlots =
      response.getAvailableSlots.availabilities[0].slots.filter((slot: any) => {
        return isCurrentDateInFutureComparedToOther(
          slot.startDateTime,
          new Date()
        );
      });
    return futureSlots;
  }
};

export const getDataForAppointmentBooking = (
  bookingData: IBookingWidgetData,
  statusId: string,
  contactId: string,
  appointmentParticipantStatusList: IMlov[],
  APPOINTMENT_PARTICIPANT_TYPE_IDS: {patient?: string; primaryUser?: string; secondaryUser?: string;}
) => {
  const appointmentType = bookingData.appointmentType;
  const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
  const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
  let secondaryUsers = bookingData.secondaryUserList;
  const participants: IParticipantData[] = [];
  participants.push({
    contactId,
    isInitiator: true,
    participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS.patient,
    statusId: getMlovIdFromCode(
      appointmentParticipantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
  });
  if (isCareTeamBased || isProviderBased) {
    const otherSecondaryUsers = bookingData.userList.filter(item => item.uuid !== bookingData.user?.uuid);
    secondaryUsers = [...otherSecondaryUsers, ...secondaryUsers];
    participants.push({
      userId: bookingData.user?.uuid,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS.primaryUser,
      statusId: getMlovIdFromCode(
        appointmentParticipantStatusList,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
      ),
    });
    if (!bookingData.skipSecondaryUsersForCareTeamType) {
      secondaryUsers.forEach((secUserId) => {
        participants.push({
          userId: secUserId.uuid,
          participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS.secondaryUser,
          statusId: getMlovIdFromCode(
            appointmentParticipantStatusList,
            APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
          ),
        });
      });
    }
  } else {
    participants.push({
      userId: bookingData.user?.uuid,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS.primaryUser,
    });
  }

  const selectedForms = bookingData.consentForms.filter(
    (form) => form.isSelected && form.status === FormStatus.pending
  );

  let accountLocationId = bookingData.location?.accountLocation?.uuid;

  const isVirtualVisit = bookingData.appointmentType?.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
  const isVirtualLocation = isVirtualVisit && !bookingData.disAllowVirtualLocation && !bookingData.disallowToScheduleForOtherLocation;

  if (isVirtualLocation) {
    accountLocationId = undefined;
  }

  const tasks = (bookingData.appointmentType?.tasks || []).map((task) => {
    return {
      title: task.title,
    };
  });

  return [
    {
      name: bookingData.appointmentType?.eventName,
      appointmentTypeId: bookingData.appointmentType?.id,
      startDateTime: bookingData.slot?.startDateTime,
      statusId,
      endDateTime: bookingData.slot?.endDateTime,
      reasonForVisit: bookingData.reasonForVisit,
      participants: participants,
      notes: [],
      formIds: selectedForms.map((form) => form.id),
      accountLocationId: accountLocationId,
      tasks: tasks,
      locationTypeId: bookingData.locationTypeId,
    },
  ];
};

export const getUsersFilteredBasedOnRoles = (
  response: any,
  roleCode: string
) => {
  return response.users.filter((user: any) => {
    if (user.userRoles && user.userRoles.length > 0) {
      const userRoles = user.userRoles.filter((role: any) => {
        return (
          role.userRole &&
          role.userRole.userRole &&
          role.userRole.userRole.code === roleCode
        );
      });
      return userRoles.length > 0;
    }
    return false;
  });
};

export const getConsentFormsFormAPIResponse = (response: any) => {
  return response.forms.map((form: any) => {
    return {
      id: form.id,
      name: form.name,
      isSelected: true,
      status:
        form.formResponses_aggregate?.aggregate?.count > 0
          ? FormStatus.received
          : FormStatus.pending,
    };
  });
};

export const isBookingDateRangeError = (bookingData: IBookingWidgetData) => {
  const bookWithinDays = bookingData.appointmentType?.bookWithinDays;
  return (
    bookWithinDays &&
    isCurrentDateInFutureComparedToOther(
      bookingData.selectedDate,
      addDaysInDate(new Date(), bookWithinDays)
    )
  );
};

export const getUserFromId = (users: IUser[], id: string) => {
  const matchedData = users.filter((item) => {
    return item.id === id;
  });
  if (matchedData.length > 0) {
    return matchedData[0];
  }
};

export const getSelectedUserValue = (user?: IUser): any => {
  return user ? user.id : undefined;
};

export const getSelectedLocationValue = (
  location?: IUserPracticeLocation
): any => {
  return location ? location.uuid : undefined;
};

export const getLocationFromId = (
  locations: IUserPracticeLocation[],
  id: string
) => {
  const matchedData = locations.filter((item) => {
    return item.uuid === id;
  });
  if (matchedData.length > 0) {
    return matchedData[0];
  }
};

export const isVirtualLocationType = (scheduleLocationTypeList: IMlov[], locationTypeId: string | undefined) => {
  return scheduleLocationTypeList.some(locationType => {
    return locationTypeId && locationType.id === locationTypeId && locationType.code === 'VIRTUAL';
  })
};

export const getLocationTypeValue = (scheduleLocationTypeList: IMlov[], locationTypeId: string | undefined) => {
   const locationType = scheduleLocationTypeList.find(locationType => {
    return locationTypeId && locationType.id === locationTypeId;
  });
  return locationType ? locationType.value : '';
}
