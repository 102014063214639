import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    formElement: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      formLabel: {
        marginLeft: 4,
      },
})