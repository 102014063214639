
import { HStack, View, VStack } from "native-base";
import { useState } from "react";
import { Colors } from "../../../../../styles";
import { ICON_LIB } from "../../../../common/CircleIconView/CircleIconView";
import { IConditionState, IUserActionState } from "./FlowNodeInterface";
import ShowLogCountByLogType from "./ShowLogCountByLogType";






function NodeLevelExecutionLogSecond(props: { rootNodeId?: string, parentNode?: string, nodeMetaData: IConditionState | IUserActionState, uiNodeId: string }) {
  const [isHideChat, setIsHideChat] = useState(false)
  const [isHideEmail, setIsHideEmail] = useState(false)
  const [isHideNotification, setIsHideNotification] = useState(false)
  const [isHideForm, setIsHideForm] = useState(false)
  const infoUiNode = (props?.nodeMetaData?.type == 'AllCondition' && props.parentNode ? props.parentNode : props.uiNodeId) || '';
  const hideCondition = (!isHideEmail || !isHideNotification || !isHideChat || !isHideForm)
  return(
  <VStack backgroundColor={Colors.primary[300]} width={hideCondition ? '' : '0%' } paddingX={hideCondition ? 3 : 0} paddingY={hideCondition ? 2 : 0}>
    <HStack margin={0} justifyContent={'center'} alignItems={'center'} height={'100%'} >
        <VStack
        // backgroundColor={'yellow.400'}  
          // width={70}
          // padding={5}
          height={'100%'}
          flexWrap='wrap'
          alignItems={'center'}
          justifyContent={'center'} >
          {  <View flex={isHideEmail ? 0 : 1} alignItems={'center'}
            justifyContent={'center'}><ShowLogCountByLogType key={infoUiNode + '_Email'} setIsHide={setIsHideEmail} iconInfo={{ icon: 'email', lib: ICON_LIB.CustomSvgIcon }} logTypeList={['EMAIL_SEND']} uiNodeId={infoUiNode}></ShowLogCountByLogType></View>}
          {<View flex={isHideNotification ? 0 : 1} alignItems={'center'}
            justifyContent={'center'}><ShowLogCountByLogType key={infoUiNode + '_Notification'} setIsHide={setIsHideNotification} iconInfo={{ icon: 'pushNotification', lib: ICON_LIB.CustomSvgIcon }} logTypeList={['PUSH_NOTIFICATION_SEND']} uiNodeId={infoUiNode}></ShowLogCountByLogType></View>}
          { <View flex={isHideChat ? 0 : 1} alignItems={'center'}
            justifyContent={'center'}><ShowLogCountByLogType key={infoUiNode + '_SMS'} setIsHide={setIsHideChat} iconInfo={{ icon: 'sms', lib: ICON_LIB.CustomSvgIcon }} logTypeList={['SMS_SEND']} uiNodeId={infoUiNode}></ShowLogCountByLogType></View>}
          { <View flex={isHideForm ? 0 : 1} alignItems={'center'}
            justifyContent={'center'}><ShowLogCountByLogType key={infoUiNode + '_Form'} setIsHide={setIsHideForm} iconInfo={{ icon: 'forms', lib: ICON_LIB.CustomSvgIcon }} logTypeList={['FORM_SEND']} uiNodeId={infoUiNode}></ShowLogCountByLogType></View>}
        </VStack>
    </HStack>
  </VStack> )
}

export default NodeLevelExecutionLogSecond;
