import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

function SendScheduleMsgSvg(props: ICommonSvgProps) {
  const {isSelected} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_3722_35167)">
        <path
          stroke={props?.customStrokeColor ? props?.customStrokeColor : "#8C5AE2"}
          strokeLinecap="round"
          d="M8 5.336v2.667l1.667 1.666m5-1.666a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3722_35167">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SendScheduleMsgSvg;
