import { useLazyQuery } from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import { ISearchFieldProps } from '../../../../../../../Interfaces';
import CarePlanGoalQueries from '../../../../../../../services/CarePlanGoalQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { IGoalEntityResult } from './interface';
import { useToast } from '../../../../../../Toast/ToastProvider';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../../../../utils/commonViewUtils';

export interface IGoalEntitySearchProps extends ISearchFieldProps {
  placeholder?: string;
  additionalAPIParams?: {[index: string]: any};
}

const GoalEntitySearch = (props: IGoalEntitySearchProps) => {
  const {value, isShowError, placeholder, additionalAPIParams, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<IGoalEntityResult[]>([]);
  const toast = useToast();
  const intl = useIntl();

  useEffect(() => {
    if (value?.code || value?.formId) {
      setSearchData([value]);
    }
    else {
      searchItem('')
    }
  }, [additionalAPIParams?.categoryCodes]);

  const [getItems] = useLazyQuery(
    CarePlanGoalQueries.GET_GOAL_ENTITIES,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const searchItem = (searchString: string) => {
    setSearchData([]);
    setLoading(true);
    getItems({
      variables: {
        limit: 10,
        offset: 0,
        searchTerm: `${searchString}`,
        ...additionalAPIParams,
      },
      onCompleted: (data) => {
        if (data?.getGoalTargetEntities?.results?.length) {
          const list = data.getGoalTargetEntities.results.map((item: IGoalEntityResult) => {
            return item;
          })
          setSearchData(list);
        }
        setLoading(false);
      },
      onError: (error) => {

        setLoading(false);
        toast({
          message: intl.formatMessage({
            id: 'apiErrorMsg',
          }),
          toastType: ToastType.error,
        });
      }
    });
  };

  const getDataFromId = (id: any) => {
    const matchedData = searchData.filter((item) => {
      return item.code === id || item.formId === id;
    });
    if (matchedData.length > 0) {
      return matchedData[0];
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      value={value ? value.code || value.formId : undefined}
      onSearch={debounce(searchItem, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
        }
      }}
      onClear={()=> {
        onChange(undefined);
      }}
      placeholder={placeholder || 'Search'}
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'field-error' : ''}
    >
      {searchData.map((item, index) => {
        return (
          <Select.Option
            key={`${item.code || item.formId}_${index}`}
            value={item.code || item.formId}
          >
            {item.display}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default GoalEntitySearch;
