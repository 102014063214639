export enum VisitType {
    OP = 'OP visits',
    ER = 'ER visits',
    Speciality = 'Specialty visits',
    IP = 'IP visits',
    homeVisit = 'Home Visit'
}

export enum VisitCodes {
    OP = 'OP',
    IP = 'IP',
    ER = 'ER',
    Speciality = 'Speciality',
    homeVisit = 'Home Visit'
}

export const visitTypesWithCodes = [
    {
        visitType: "OP visits",
        visitCode: "OP",
    },
    {
        visitType: "IP visits",
        visitCode: "IP",
    },
    {
        visitType: "ER visits",
        visitCode: "ER",
    },
    {
        visitType: "Specialty visits",
        visitCode: "Speciality",
    },
    {
        visitType: "Home Visit",
        visitCode: "Home Visit",
    }
];