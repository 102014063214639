export enum OperatorCase {
    MULTISELECT_CONTAINS = 'multiselect_contains',
    MULTISELECT_EQUALS = 'multiselect_equals',
    MULTISELECT_NOT_CONTAINS = 'multiselect_not_contains',
    MULTISELECT_NOT_EQUALS = 'multiselect_not_equals',
    EQUAL = 'equal',
    LESS = 'less',
    GREATER = 'greater',
    GREATER_OR_EQUAL = 'greater_or_equal',
    LESS_OR_EQUAL = 'less_or_equal',
    SELECT_EQUALS = 'select_equals',
    NOT_EQUAL = 'not_equal',
    SELECT_NOT_EQUALS = 'select_not_equals',
    IS_NULL = 'is_null',
    IS_NOT_NULL = 'is_not_null',
    LIKE = 'like',
    NOT_LIKE = 'not_like',
}

export enum ReturnOperator {
    IN = 'in',
    NOT_IN = 'notIn',
    LESS_THAN = 'lessThan',
    GREATER_THAN = 'greaterThan',
    GREATER_THAN_INCLUSIVE = 'greaterThanInclusive',
    LESS_THAN_INCLUSIVE = 'lessThanInclusive',
    EQUAL = 'equal',
    NOT_EQUAL = 'notEqual',
    ALL = 'all',
    ANY = 'any',
    LIKE = 'like',
    NOT_LIKE = 'notLike',
}