import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 5,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 5,
  },
  spinnerStyle: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    // backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
});
