import React, {useContext} from 'react';
import {IParticipantSearch} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {IThemeAttachments} from '../../BodyContainer/interface';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE} from '../../../constants';
import {Badge, HStack, Spacer, Spinner, Text, VStack, View} from 'native-base';
import { getUserUUID } from '../../../utils/commonUtils';
import { Colors } from '../../../styles';

const UserInfoItem = (props: {
  option: IParticipantSearch;
  contactData: any;
  showPendingTaskCount?: boolean;
  pendingTaskCount?: number;
  isPendingTaskCountLoading?: boolean;
  isSidecarTaskLabelSelect?: boolean;
}) => {
  const userUUID = getUserUUID();
  const {option, contactData, isSidecarTaskLabelSelect} = props;
  const mlovData = useContext(CommonDataContext);
  const accountThemeConfig: any = mlovData.accountThemeConfig || {};
  const isCurrentUser = option.key === userUUID;
  const pendingTaskCount = props.pendingTaskCount || 0;
  const renderUserAvatar = (option: IParticipantSearch) => {
    let logoElem = <></>;
    const tempThemeAdditionalData: string =
      accountThemeConfig.additionalAttributes || '{}';

    const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
      tempThemeAdditionalData
    );
    if (finalThemeAdditionalAttributes?.attachment?.logo?.isLogoWithName) {
      logoElem = (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: isSidecarTaskLabelSelect ? '3' : '12',
            height: isSidecarTaskLabelSelect ? 32 : 48,
            width: isSidecarTaskLabelSelect ? 32 : 48,
            ...(isSidecarTaskLabelSelect ?
              {
                textStyle: {
                  fontSize: 12
                }
              } : {}
            )
          }}
          isLetterAvatarShow
          userData={{
            userName: option.label,
            userId: option.key,
            userType: GROUP_MEMBER_TYPE.USER,
          }}
        />
      );
    } else {
      logoElem = (
        <DisplayCardAvatar
          isLetterAvatarShow
          avatarStyle={{
            avatarSize: isSidecarTaskLabelSelect ? '3' : '12',
          }}
          userData={{
            userName: option.label,
            userId: option.key,
            userType: GROUP_MEMBER_TYPE.USER,
          }}
        />
      );
    }
    return logoElem;
  };

  const getRoles = (agentRecord: any) => {
    if (agentRecord?.user?.userRoles || agentRecord?.userRoles) {
      const userRoleText = (agentRecord?.user?.userRoles || agentRecord?.userRoles)?.map((role: any) => {
        return (role?.userRole?.userRole?.value + ',');
      });
      return (
        <Text mr={1} fontSize={11} fontWeight={400} color={'gray.500'} numberOfLines={1}>
          {userRoleText}
        </Text>
      );
    } else if (agentRecord?.role) {
      return (
        <Text mr={1} fontSize={11} fontWeight={400} color={'gray.500'} numberOfLines={1}>{agentRecord?.role}</Text>
      );
    } else {
      <Text size={'xsMedium'} color={'coolGray.500'} numberOfLines={1}>
        {option?.contactData?.email || contactData?.email}
      </Text>
    }
  };

  return (
    <HStack>
      <HStack px={(props?.contactData?.role === "administrator" && !isSidecarTaskLabelSelect) ? 3 : 0} flex={3}>
        {renderUserAvatar(option as IParticipantSearch)}
        <VStack ml={2} width={'100%'}>
          <Text
            size={'smBold'}
            color={'coolGray.700'}
            justifyContent={'center'}
            numberOfLines={1}
          >
            {option.label} {isCurrentUser && '(You)'}
          </Text>
          <HStack flex={1}>{getRoles(contactData)}</HStack>
        </VStack>
      </HStack>
      <Spacer/>
      {
        props.showPendingTaskCount
        && props.isPendingTaskCountLoading
        && (
          <Spinner size={'sm'} />
        )
      }
      {
        props.showPendingTaskCount
        && !props.isPendingTaskCountLoading
        && (
          <HStack
            flex={1}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Badge
              backgroundColor={Colors.Custom.Gray100}
              borderColor={Colors.Custom.Gray300}
              alignSelf={'center'}
              rounded={'sm'}
              justifyContent={'center'}
              paddingY={1}
              paddingX={2}
            >
              <Text
                numberOfLines={1}
                color={Colors.Custom.Gray400}
                fontSize={11}
              >
                {`${pendingTaskCount} ${pendingTaskCount <= 1 ? 'task' : 'tasks'}`}
              </Text>
            </Badge>
          </HStack>
        )
      }
    </HStack>
  );
};

export default UserInfoItem;
