import {Divider, Spinner, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../../constants/WebSocketConst';
import {getAccountUUID, getUserUUID, isChannelEmail} from '../../../../utils/commonUtils';
import {EventBus} from '../../../../utils/EventBus';
import {
  getNotifications,
  updateAllNotificationReadStatus,
  updateSingleNotificationReadStatus,
} from '../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {EmptyStateSvg} from '../../Svg';
import {NotificationResponse, SingleNotification} from '../interfaces';
import {NotificationListHeader} from '../NotificationListHeader';
import {NotificationListItem} from '../NotificationListItem';
import {
  getConversationIdsFromNotificationList,
  getFormattedNotificationList,
  getTitleAndDescriptionForEmailNotification,
  isConversationNotification,
  NOTIFICATION_TYPES,
} from '../NotificationsUtils';
import {useLazyQuery} from '@apollo/client';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';

const NotificationList = (props: {onClick: any; onUpdate: any}) => {
  const [listState, setListState] = useState({
    isLoading: false,
    isNoDataFound: false,
    apiResponse: {} as NotificationResponse,
    listData: [] as SingleNotification[],
  });
  const userUUID = getUserUUID();
  const pathname = useLocation().pathname;
  const userType = 'USER';
  const userId = getUserUUID();
  const tenantId = getAccountUUID();
  const navigateToScreen = useNavigate();
  const [getConversationAdditionalAttributes] = useLazyQuery(
    ConversationsQueries.GetConversationAdditionalAttributes
  );
  const getOnClickActionByNotificationType = (
    type: string,
    singleNotification: SingleNotification
  ) => {
    switch (type) {
      // case NOTIFICATION_TYPES.NEW_APPOINTMENT:
      // case NOTIFICATION_TYPES.APPOINTMENT_RSVP:
        // navigateToScreen('/schedule');
        // break;
      case NOTIFICATION_TYPES.RECURRING_APPOINTMENT:
        const eventBus = EventBus.getEventBusInstance();
        if (pathname !== '/schedule') {
          navigateToScreen('/schedule');
        } else {
          eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.REFRESH_CALENDAR, {});
        }
        break;
      case NOTIFICATION_TYPES.PRESCRIBE_DOSE_SPOT_MEDICATION_COUNT_UPDATE:
        break;
    }
  };

  const getConversationNotificationData = async (
    notificationList: SingleNotification[]
  ) => {
    const conversationIds =
      getConversationIdsFromNotificationList(notificationList);
    if (conversationIds.length === 0) {
      return notificationList;
    }
    const response = await getConversationAdditionalAttributes({
      variables: {
        ids: conversationIds,
      },
    });
    const conversations: Record<string, any>[] =
      response.data?.conversations || [];
    const emailConversations = conversations.filter((item: any) =>
      isChannelEmail(item?.conversationInbox?.channelType || '')
    );
    if (emailConversations.length === 0) {
      return notificationList;
    }
    const emailConversationsMap = new Map<string, any>();
    emailConversations.forEach((item: any) => {
      emailConversationsMap.set(item.id.toString(), item);
    });
    notificationList.forEach((item) => {
      if (
        isConversationNotification(item) &&
        emailConversationsMap.has(item.typeId)
      ) {
        const singleEmailConversation = emailConversationsMap.get(item.typeId);
        const {description, title} = getTitleAndDescriptionForEmailNotification(
          {
            singleEmailConversation,
            notificationData: item,
          }
        );
        item.title = title;
        item.description = description;
      }
    });
    return notificationList;
  };

  const handleAllReadStatus = () => {
    updateAllNotificationReadStatus({
      isRead: true,
      userId: userId,
      userType: 'USER',
    })
      .then((response) => {
        props.onUpdate();
        getNotificationsList();
      })
      .catch((error) => {
      });
  };

  const handleSingleReadStatus = (singleNotification: SingleNotification) => {
    const promise = updateSingleNotificationReadStatus({
      type: singleNotification.type,
      typeId: singleNotification.typeId,
      userId: userUUID,
      isRead: true,
    })
      .catch((error) => {
      });
    return promise;
  };

  const onActionPerformed = (
    actionCode: string,
    actionData: SingleNotification
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.UPDATE:
        handleSingleReadStatus(actionData).then(() => {
          props.onClick(actionData.type, actionData);
          getOnClickActionByNotificationType(actionData.type, actionData); // action to move the respective screens
        });

        break;
      case COMMON_ACTION_CODES.DELETE:
        handleSingleReadStatus(actionData).then(() => {
          props.onUpdate();
          getNotificationsList();
        });
        break;
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED,
      getNotificationsList
    );

    return () => {
      eventBus.removeEventListener(getNotificationsList);
    };
  }, []);

  const getNotificationsList = () => {
    try {
      getNotifications({userId, userType, tenantId}).then(async (response) => {
        const formattedNotificationList = await getConversationNotificationData(
          getFormattedNotificationList(response)
        );
        setListState((prev) => {
          return {
            ...prev,
            apiResponse: response.data,
            listData: formattedNotificationList,
            isLoading: false,
          };
        });
      });
    } catch (error) {
      setListState((prev) => {
        return {
          ...prev,
          isNoDataFound: true,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    setListState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getNotificationsList();
  }, []);

  return (
    <VStack>
      {listState.listData.length > 0 && (
        <NotificationListHeader
          onMarkAllAsRead={() => {
            handleAllReadStatus();
          }}
        />
      )}
      <VStack maxHeight={400} overflow={'scroll'}>
        {listState.listData.map((item, index) => {
          return (
            <View key={`${item.id}_${index}`}>
              <NotificationListItem
                singleNotification={item}
                onActionPerformed={onActionPerformed}
                key={item.id}
              />
              {index != listState.listData.length - 1 ? (
                <Divider margin={1} />
              ) : (
                <></>
              )}
            </View>
          );
        })}
      </VStack>
      {listState.isLoading && (
        <View
          flex={1}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </View>
      )}

      {!listState.isLoading && listState.listData.length === 0 && (
        <View
          flex={1}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyStateSvg titleId="noNotifications" />
        </View>
      )}
    </VStack>
  );
};

export default NotificationList;
