const TaskCompleteSvgIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4701_262965)">
      <path
        d="M12.8337 6.46334V7C12.8329 8.25792 12.4256 9.4819 11.6724 10.4894C10.9193 11.4969 9.86057 12.2339 8.65428 12.5906C7.44799 12.9473 6.15873 12.9044 4.97877 12.4685C3.79881 12.0326 2.79137 11.2269 2.10672 10.1716C1.42206 9.11636 1.09687 7.86804 1.17964 6.61285C1.2624 5.35767 1.7487 4.16286 2.56599 3.20663C3.38328 2.2504 4.48778 1.58398 5.71477 1.30675C6.94175 1.02953 8.22548 1.15637 9.3745 1.66834M12.8337 2.33334L7.00033 8.1725L5.25033 6.4225"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4701_262965">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TaskCompleteSvgIcon;
