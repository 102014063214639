import {IOmniViewSections} from "../Interfaces";
import {CLINICAL_CONST, PATIENT_DEMOGRAPHIC_CONST} from "./StringConst"

export const OMNI_VIEW_TOP_BAR_BTN_CONST = {
  DEMOGRAPHICS: 'DEMOGRAPHICS',
  CLINICAL_RECORD: 'CLINICAL_RECORD',
};

export const OMNI_VIEW_CLIN_SECTION_BTNS: IOmniViewSections[] = [
  {
    displayString: 'Problems - Active',
    code: CLINICAL_CONST.PROBLEM_ACTIVE,
  },
  {
    displayString: 'Problems - Historical',
    code: CLINICAL_CONST.PROBLEM_HISTORIC,
  },
  {
    displayString: 'Allergies',
    code: CLINICAL_CONST.ALLERGY,
  },
  {
    displayString: 'Medications',
    code: CLINICAL_CONST.MEDICATION,
  },
  {
    displayString: 'Immunizations',
    code: CLINICAL_CONST.IMMUNIZATION,
  },
  {
    displayString: 'Visits',
    code: CLINICAL_CONST.VISIT_HISTORY,
  },
  {
    displayString: 'Historical Lab Values',
    code: CLINICAL_CONST.HISTORICAL_LAB,
  }
];


export const OMNI_VIEW_PERSON_SECTION_BTNS: IOmniViewSections[] = [
  {
    displayString: 'Personal Details',
    code: PATIENT_DEMOGRAPHIC_CONST.PERSONAL_DETAILS,
  },
  {
    displayString: 'Contact Details',
    code: PATIENT_DEMOGRAPHIC_CONST.CONTACT_DETAILS,
  },
  // {
  //   displayString: 'Emergency Contact',
  //   code: PATIENT_DEMOGRAPHIS_CONST.EMERGENCY_CONTACT,
  // },
];