import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {HStack, Skeleton, View, VStack, Text, Pressable, useToast} from 'native-base';
import {useContext, useEffect, useRef, useState} from 'react';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import TagQueries from '../../../services/Tags/TagQueries';
import {Colors} from '../../../styles';
import {getAccountId, getRandomNumberBetweenOneAndMax} from '../../../utils/commonUtils';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import {
  formatGetCategoryId,
  formatGetLabelDetails,
  formatGetTags,
} from '../../RightSideContainer/Contacts/Tags/Helper/formatTagsData';
import {tagColorList} from '../../RightSideContainer/Contacts/Tags/TagsModal';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {createLabelAndLabelType} from '../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
import {useIntl} from 'react-intl';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
const {Option} = Select;

interface addTag {
  onFormCompleteAction: (actionCode: string, rawData?: unknown) => void;
  tagType: string;
  contactId?: number | undefined;
  contactData?: any;
  contactTags?: any;
  selectedLabelIds?: number[];
}

export const AddTagView = (props: addTag) => {
  const [showModal, setShowModal] = useState(true);
  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const selectRef: any = useRef();
  const toast = useToast();
  const intl = useIntl();

  const filteredResult = labels?.filter((item) => {
    return item.code === 'CONTACT';
  });
  const mlov = filteredResult[0];
  const accountId = getAccountId()
  const selectedTagCategoryId: any = formatGetCategoryId(
    commonData.MLOV.LabelType,
    props.tagType || 'Contact'
  );
  const [currentCategoryId, setCurrentCategoryId] = useState(selectedTagCategoryId);
  const [labelData, setLabelData] = useState<any>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [stateData, setStateData] = useState({
    labelsData: [] as any,
    loading: false,
    searchString: '',
  })

  const [addLabelTaggings] = useMutation(TagQueries.AddLabelTaggings);
  const [loading, setLoading] = useState(false);
  const contactTags = props?.contactData?.contactTagging
    ? props?.contactData?.contactTagging
    : props.contactTags;
  const {refetch} = useQuery(TagQueries.GetLabelsByFilter, {
    variables: {category: currentCategoryId, searchString: `%%`},
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLabelData(formatGetTags(data.labels, contactTags));
    },
  });
  const [createLabel] = useMutation(TagQueries.CreateLabel);
  const [createLabelType] = useMutation(TagQueries.CreateLabelType);
  const [getTagsBySearchString] = useLazyQuery(TagQueries.GetLabelsByFilter, {
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      if (response.labels.length) {
        let labels = response.labels;
        if(props.selectedLabelIds?.length){
          labels = labels.filter((label: any) => {
            return !props.selectedLabelIds?.includes(label?.id)
          })
        }

        setStateData((prev) => {
          return {
            ...prev,
            labelsData: labels,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            labelsData: [],
            loading: false,
          };
        });
      }
    },
    onError: (error) => {
      setStateData((prev) => {
        return {
          ...prev,
          labelsData: [],
          loading: false,
        };
      });
    },
  })
  const onCloseModal = () => {
    setShowModal(false);
    props.onFormCompleteAction('');
  };
  const addNewTag = async (searchedTag: string) => {
    if (selectRef.current) {
      selectRef?.current?.blur();
    }
    const color = tagColorList[getRandomNumberBetweenOneAndMax(14)]
    try {
      const createLabelBody = [{
        accountId: accountId,
        color: color,
        description: `${searchedTag} description`,
        title: searchedTag,
        labelTypeId: mlov.id,
      }]
      const response = await createLabelAndLabelType(createLabelBody);
      if (response?.data?.length) {
        const tag = response?.data?.[0];
        const temp = {
          id: tag?.id,
          title: tag?.title,
          color: tag?.color,
          description: tag?.description
        }
        setSelectedLabels(prev => {
          return [...prev, tag?.id]
        })
        const currentLabelData = [...stateData.labelsData];
        currentLabelData.push(temp);
        setStateData(prev => {
          return {
            ...prev,
            labelsData: [...currentLabelData],
            searchString: ''
          }
        })
        refetch();
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.networkError?.result?.message
      showToast(
        toast,
        message || intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        4000
      );
    }
    setStateData((prev: any) => {
      return {
        ...prev,
        searchString: ''
      }
    })
  }
  const handleSubmit = () => {
    setLoading(true);
    const addTagObjectList: any = [];
    selectedLabels.forEach((tagId) => {
      addTagObjectList.push({
        taggerTypeId: commonData.MLOV.TaggerType[0].id,
        taggableTypeId: currentCategoryId,
        labelId: parseInt(tagId),
        taggableId: props.contactId,
        taggerId: commonData?.userData?.id || '',
        accountId: commonData?.userData?.account_id || '',
      });
    });

    const updatedLabels = formatGetLabelDetails(labelData, selectedLabels);
    addLabelTaggings({variables: {addTagObjectList}})
      .then((res) => {
        setLoading(false);
        props.onFormCompleteAction(
          COMMON_ACTION_CODES.ADDED_OR_UPDATED,
          updatedLabels
        );
      })
      .catch(() => {
        setLoading(false);
        props.onFormCompleteAction(
          COMMON_ACTION_CODES.ADDED_OR_UPDATED,
          updatedLabels
        );
      });
  };
  const onSearchText = () => {
    setStateData(prev => {
      return {
        ...prev,
        loading: true
      }
    })
    getTagsBySearchString({
      variables: {
        category: currentCategoryId,
        searchString: `%${stateData.searchString}%`,
      },
    })

  };
  useEffect(() => {
    const selectedCategory: any = formatGetCategoryId(
      commonData.MLOV.LabelType,
      props.tagType || 'Contact'
    );
    setCurrentCategoryId(selectedCategory);
  }, []);
  useEffect(() => {
    if (stateData?.searchString.length) {
      onSearchText()
    }
  }, [stateData.searchString])
  const getTagOptions = () => {
    if (stateData.labelsData.length) {
      return stateData.labelsData?.map((label: any) => {
        return <Option key={label.id} value={label.id}>
          {label?.title}
        </Option>
      })
    } else if (!stateData.loading && stateData.searchString && stateData.labelsData.length === 0) {
      return (<Option key={'noData'} value={'noData'}>
        <Pressable onPress={() => {
          addNewTag(stateData.searchString)
        }} >
          {`Add - ${stateData.searchString}`}
        </Pressable>
      </Option>)
    }

  }
  return (
    <Drawer
      visible={showModal}
      width={550}
      onClose={() => onCloseModal()}
      title={
        <>
          <ModalActionTitle
            title={'setTag'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onCloseModal();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'save',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                // leftIcon: '',
                onClick: () => {
                  handleSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      {loading ? (
        <VStack height={'100%'}>
          <Skeleton padding={10} height="60%" />
          <Skeleton.Text padding={10} />
        </VStack>
      ) : (
        <View>
          <View style={{marginBottom: 12}}>
            <HStack>
              <DisplayText size={'mdNormal'} textLocalId="selectTagsFor" />
              <Text size={'mdNormal'}>{props.tagType.toLowerCase()}</Text>
            </HStack>
          </View>
          <View style={{marginBottom: 12}}>
            <Select
              ref={selectRef}
              mode="multiple"
              allowClear
              placeholder="Search tags"
              showSearch
              value={selectedLabels.length ? selectedLabels : []}
              filterOption={false}
              onChange={(value) => {
                const tempData = value?.filter((item: string) => {
                  return item != 'noData'
                })
                setSelectedLabels(tempData)
              }}
              onSearch={debounce((value) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    searchString: value,
                  };
                });
              }, 500)}
              loading={stateData.loading}
              notFoundContent={stateData.loading && <Spin size="large" />}
              style={{width: '100%', }}
            >
              {getTagOptions()}
            </Select>
          </View>
        </View>
      )}
    </Drawer>
  );
};
