import  React from 'react';
import { IKeyOperation } from '../../../../../../../Interfaces';
import { IIntervention } from '../interfaces';
import { InterventionFields } from '../../CustomComponentUtils';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import TaskIconSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/TaskIconSvg';
import FormIconSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/FormIconSvg';
import EducationContentSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/EducationContentSvg';
import VitalIconSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/VitalIconSvg';
import AppointmentIconSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/AppointmentIconSvg';
import InternalTaskIconSvg from '../../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/InternalTaskIconSvg';
import HighPriorityIcon from '../../../../../../../assets/Icons/HighPriorityIcon';
import MediumPriorityIcon from '../../../../../../../assets/Icons/MediumPriorityIcon';
import LowPriorityIcon from '../../../../../../../assets/Icons/LowPriorityIcon';
import { TASK_STATUS_CODES } from '../../../../../../../constants/MlovConst';
import { Colors } from '../../../../../../../styles';
import { EntityType, InterventionPriority, interventionType } from './InterventionConstants';
import { addTimeToDate, getDateDifferenceInDays } from '../../../../../../../utils/DateUtils';

const currentDate = new Date();

export const isInvalid = (
  field: InterventionFields,
  item: IIntervention,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== InterventionFields.title) {
    return false;
  }
  switch (field) {
    case InterventionFields.title:
      if (
        (fields?.title?.isRequired || false) &&
        submitTriggered
      ) {
        return !item?.title;
      }
      return false;
    default:
      return false;
  }
};

export const getInterventionIcon = (interventionType: string) => {
  switch (interventionType) {
    case EntityType.MemberTask:
      return <TaskIconSvg />
    case EntityType.Form:
      return <FormIconSvg />
    case EntityType.EducationContent:
      return <EducationContentSvg />
    case EntityType.MeasureVital:
      return <VitalIconSvg />
    case EntityType.ProviderTask:
      return <InternalTaskIconSvg />
    case EntityType.AppointmentLink:
      return <AppointmentIconSvg />
  }
};

export const getPriorityIcon = (priority: string) => {
  switch (priority) {
    case InterventionPriority.High:
    // case 'asap':
      return <HighPriorityIcon />
    case InterventionPriority.Medium:
    // case 'urgent':
      return <MediumPriorityIcon />
    case InterventionPriority.Low:
    // case 'routine':
      return <LowPriorityIcon />
  }
};

// this maps the key value pair from metadata to userInputFieldList and keep remaining props as it is
export const mapValues = (array1: any, array2: any) => {
  return array2.map((item: any) => {
    const matchingItem = array1.find((item1: any) => item1.key === item.key);
    if (matchingItem) {
      return {
        ...item,
        value: matchingItem.value
      };
    } else {
      return item;
    }
  });
};

export const getInterventionType = (type: string) => {
  const updatedInterventionType = interventionType.find(item => item.code === type || item.value === type)
  return updatedInterventionType
}

// this method takes metadata as a input and convert to key value pair for userInputFieldList
export const reverseGetMetadata = (metadata: { [key: string]: string }): { key: string, value: string }[] => {
  return Object.entries(metadata).map(([key, value]) => ({ key, value }));
}

export const getDueDateInDays = (startDateTime: string, item: IIntervention) => {
  const planStartDate = new Date(startDateTime);
  let dueDate = null;
  const trigger = item?.trigger;
  const unitType = trigger?.unit;
  const unitValue = trigger?.value;
  if (unitType && unitValue) {
    switch(unitType) {
      case 'day':
        dueDate = addTimeToDate(planStartDate, unitValue, 'DAY');
        break;
      case 'week':
        dueDate = addTimeToDate(planStartDate, unitValue, 'WEEK');
        break;
      case 'month':
        dueDate = addTimeToDate(planStartDate, unitValue, 'MONTH');
        break;
    }
    if (dueDate) {
      const differenceDays =  getDateDifferenceInDays(startDateTime, dueDate) 

      if (differenceDays >= 0) {
        return 'Due ' + (differenceDays === 0 ? 'Today' : differenceDays === 1 ? 'Tomorrow' : `in ${differenceDays} days`);
      } else {
        return 'Missed'
      }
    }
  }
  return 'Missed'
}

export const getTaskStatusWiseBackgroundColor = (statusCode: string) => {
  let statusColor = Colors.primary[700];
  switch (statusCode) {
    case TASK_STATUS_CODES.ACCEPTED:
      statusColor = Colors.Custom.PendingTaskBackgroundColor;
      break;
    case TASK_STATUS_CODES.COMPLETED:
      statusColor = Colors.Custom.CompletedTaskBackgroundColor;
      break;
    case TASK_STATUS_CODES.MISSED:
      statusColor = Colors.Custom.PendingTaskBackgroundColor;
      break;
  }
  return statusColor;
}

export const getTaskStatusWiseFontColor = (statusCode: string) => {
  let statusColor = Colors.primary[700];
  switch (statusCode) {
    case TASK_STATUS_CODES.ACCEPTED:
      statusColor = Colors.Custom.PendingTaskFontColor;
      break;
    case TASK_STATUS_CODES.COMPLETED:
      statusColor = Colors.Custom.CompletedTaskFontColor;
      break;
    case TASK_STATUS_CODES.MISSED:
      statusColor = Colors.Custom.PendingTaskFontColor;
      break;
  }
  return statusColor;
}
