import {IInbox, IInboxOptions} from '../../../../../../services/Inbox/interfaces';
import {IUserConversationFilterResp} from '../../ConversationChannelNew/Interfaces';
import {IUserInboxList} from '../../ConversationChannelTabs/interfaces';

export type IChannelTypes =
  | 'Channel::WebWidget'
  | 'Channel::TwilioSms'
  | 'Channel::Email'
  | 'Channel::Whatsapp';

export interface IConversationFilterProps {
  showModal: boolean;
  onModalAction: (actionCode: string, actionData?: any) => void;
  selectedTab: string;
  inboxList: IUserInboxList;
  selectedData?: ISelectedFilterData;
  userVirtualMobileNo?: IVirtualPhoneNumberAssignee[];
  userFilterData: IUserConversationFilterResp;
  emailInboxUserPreference?: string;
  showChooseInbox?: boolean;
}

export interface ISelectedFilterData {
  selectedInboxId: number;
  selectedContactType: string;
  selectedDateCode: string;
}

export interface IConversationFilterState {
  selectedInboxId: number;
  emailSelectedInboxId: number;
  selectedContactType: string;
  selectedDateCode: string;
  selectedInboxData?: IInboxOptions;
  emailSelectedInboxData?: IInboxOptions;
  selectedAssignedFilter: string;
  selectedAssignedStatusFilter?: IAssigneeStatusType;
  readType?: string;
  showOnlyMentioned?: boolean;
  fromDate?: any;
  toDate?: any;
  direction: string,
  isOutOfOfficeHours: string,
  status: string,
  searchQuery: string,
  webPhoneToUserId: string,
  toNumber?: string,
  internalUsers?: AllUsers[],
  selectedAssignedUserData?: Users,
  selectedAssignedUserDataWeb?: Users[],
  fetchUserLoading?: boolean,
  mainUsersList?: AllUsers[]
  mentionReadStatus?: string
}

export interface IConversationFilterTempData {
  inboxFilterData: IInboxFilterQuery;
  contactTypeFilterData: any;
  dateFilterData: IDateFilterQuery[];
  conversationChannelFilter: IConversationChannelFilterQuery;
}

export interface IFilterDataObj {
  toDate: string;
  fromDate: string;
}

export interface IDateFilterQuery {
  lastActivityAt?: ICreatedAt;
  message?: IMessageDateFilterQuery;
}

export interface IMessageDateFilterQuery {
  createdAt: ICreatedAt;
}

interface ICreatedAt {
  _lte?: string;
  _gte?: string;
  _lt?: string;
  _gt?: string;
}

export interface IInboxFilterQuery {
  inboxId: {
    _eq: number;
  };
}

export interface IConversationChannelFilterQuery {
  conversationInbox: {
    channelType: {
      _eq: IChannelTypes;
    };
  };
}

export interface IConversationContactTypeQuery {
  conversationContact: IConversationContactType;
}

interface IConversationContactType {
  contactType: {
    contactType: {
      code: {
        _eq?: string;
        _neq?: string;
      };
    };
  };
}

export interface IAdditionFilterData {
  accountId: number;
  userUuid: string;
  groupTypeCodeIdObj: {[index: string]: string};
  id?: number;
  isPatientMessagingEnabled?: boolean;
  isEFaxMessagingEnabled?: boolean;
  emailInboxUserPreference?: string;
  otherContactTypeId?: string;
  isEmailUserPreferenceEnabled?: boolean;
}

export enum FilterSectionCode {
  contactType = 'contactType',
  dateType = 'dateType',
  mention = 'mention',
  assignment = 'assignment',
  assignmentStatus = 'assignmentStatus',
  readType = 'readType',
  inboxName = 'inboxName',
  contactStatus = 'contactStatus',
  tag = 'tag',
  location = 'location',
  direction = 'direction',
  outOfOfficeHours = 'outOfOfficeHours',
  callStatus = 'callStatus',
  phoneTypes = 'phoneTypes',
  employeeType = 'employeeType',
  mentionReadStatusType = 'mentionReadStatusType'

}

export enum FilterModule {
  conversations,
  tasks,
  contacts,
}

export enum FilterSectionSelectionType {
  singleSelect,
  multiSelect,
  dropdown,
  navigation,
}
export interface ICommonFilterData {
  section: FilterModule;
  filterSections: ICommonFilterSection[];
  toNumber?: string;
  toNumberFilter?: boolean;
  AllVirtualMobileNo?: IVirtualPhoneNumberAssignee[];
  selectedInboxTypeCode?: string;
  selectedInbox?: IInbox;
  allInboxList?: IUserInboxList;
  selectedAssignedUserData?: Users;
  selectedAssignedUsersData?: Users[];
}
export interface ICommonFilterSection {
  filterSectionCode: FilterSectionCode;
  filterSectionHeading: string;
  filterOptions: FilterItem[];
  filterSectionSelectionType: FilterSectionSelectionType;
}

export interface FilterItem {
  code: string;
  value: string | number;
  label: string;
  isSelected: boolean;
  isDefault?: boolean;
}


export interface IVirtualPhoneNumberData {
  virtualPhoneNumberAssignees: IVirtualPhoneNumberAssignee[];
}

export interface IVirtualPhoneNumberAssignee {
  id: string;
  assignee: string;
  assigneeId?: string;
  assigneeTypeId: string;
  assigneeType: IVirtualPhoneNumberAssigneeType;
  virtualPhoneNumber: string;
  assigneeName?: string;
}

export interface IVirtualPhoneNumberAssigneeType {
  code: string;
}
export interface IAssigneeStatusType {
  code: string;
  value: string;
  label: string;
  isSelected: boolean;
  isDefault?: boolean;
}
export interface IChooseInboxProps {
  toNumber: string;
  AllVirtualMobileNo?: IVirtualPhoneNumberAssignee[];
  onValueChange?: (selectedNumber: any) => void;
}
export interface IChooseAssignedProps {
  selectedUsers?: Users[];
  onValueChange?: (selectedUser: Users[]) => void;
  navigation?: any
}
export interface AllUsers {
  users: Users;
  count: number;
}

export interface Users {
  id: number;
  uuid: string;
  name: string;
}
