import {View, Text, Pressable} from "react-native"
import {Colors} from "../../../../../styles"
import InfoSvg from "../../../../common/Svg/InfoSvg"
import AntDesign from "react-native-vector-icons/AntDesign"
import {useIntl} from "react-intl"
import {useMediaQuery} from "native-base"
import {SMALL_WINDOW} from "../../../../../constants"
import { TestIdentifiers, testID } from "../../../../../testUtils"

export const PhiInfo = (props: {onClose: ()=> void, isMentionTab: boolean})=> {
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: SMALL_WINDOW }
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  return (
    <View style={{
      width: '100%',
      backgroundColor: Colors.Custom.Gray50,
      padding: 6,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopWidth: props?.isMentionTab ? 0 : 1,
      borderColor: Colors.Custom.Gray200
    }}>
      <View style={{marginHorizontal: 10}} {...testID('image')}>
        <InfoSvg customColor={Colors.Custom.Gray500} customDimension={'20'} />
      </View>
      <Text {...testID(TestIdentifiers.phiWarning)} style={{color: Colors.Custom.Gray500, fontSize: isSmallScreen ? 12 : 14, fontWeight: '500', lineHeight: 21}}>
        {intl.formatMessage({id:'phiWarning'})}
      </Text>
      <View style={{flex: 1, flexDirection:'row-reverse'}}>
        <Pressable {...testID(TestIdentifiers.closeBtn)} onPress={props?.onClose}>
          <AntDesign color={Colors.Custom.Gray400} size={18} name="close" {...testID('image')}/>
        </Pressable>
      </View>
    </View>
  )
}