import {Icon} from 'native-base';
import React from 'react';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {DATE_FORMATS} from '../../../../../constants';
import {
  addDaysInDate,
  addDurationInDate,
  addTimeToDate,
  getDateObjectFromStringAndFormat,
  getDateStrFromFormat,
  getDateStrFromFormatWithTimezone,
  getDiffInDays,
  getDiffInMinutes,
  getFormattedDate,
  getMomentObj,
  isValidUnitType,
} from '../../../../../utils/DateUtils';
import {
  IAppointmentDetail,
  IAppointmentType,
  IAppointmentTypeGroup,
  IAppointmentUser,
  IBookedAppointment,
  IPracticeLocation,
  ISlot,
  IUserPracticeLocation,
  IUserSearch,
} from './Interfaces';
import {IAppointmentDetail as IAppointmentCompleteDetail} from '../../CalendarWidgetInterfaces';
import { getMlovObjectFromId } from '../../../../../utils/mlovUtils';
import { APPOINTMENT_TYPE_DATA_TYPE_CODES } from '../Booking/AppointmentBooking/AppointmentConstant';
import { APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES } from '../../../../../constants/MlovConst';
import { IMlov } from '../../../../../Interfaces';
import { IAppointmentType as IWidgetAppointmentType} from '../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';

export const getAppointmentDescription = (
  appointmentType: IAppointmentType
): string => {
  let descriptionString = appointmentType.duration + ' min';
  if (appointmentType.description) {
    descriptionString += '\n' + appointmentType.description;
  }
  return descriptionString;
};

export const getBookingDataFromAppointmentType = (
  appointmentType: IAppointmentType
): IAppointmentTypeGroup | undefined => {
  if (
    appointmentType?.appointmentTypeGroup &&
    appointmentType?.appointmentTypeGroup.length > 0
  ) {
    return appointmentType.appointmentTypeGroup[0];
  }
  return undefined;
};

export const getNoSlotMessage = (date: string): string => {
  const dateObj = getDateObjectFromStringAndFormat(
    date,
    DATE_FORMATS.CONVERSATION_DATE_FILTER
  );
  const dateStr = getFormattedDate(dateObj, DATE_FORMATS.SLOT_DATE_FORMAT);
  return 'No slots available for ' + dateStr + '.';
};

export const getRowData = (
  data: IAppointmentType
): IAppointmentUser | undefined => {
  if (data?.appointmentTypeGroup && data?.appointmentTypeGroup.length > 0) {
    const userData = data.appointmentTypeGroup[0];
    return {
      name: userData.user?.name || '',
      duration: userData.duration + ' min',
      appointmentName: data.eventName,
      appointmentLocation: data.locationType?.value || '',
      appointmentDetails: data.description,
    };
  }
};

export const setUserData = (
  appointmentTypes: IAppointmentType[],
  userData: IUserSearch[]
): IAppointmentType[] => {
  const userMap: any = {};
  appointmentTypes.forEach((event: IAppointmentType) => {
    if (event?.appointmentTypeGroup) {
      (event?.appointmentTypeGroup || []).forEach((user: IAppointmentTypeGroup) => {
        if (user.userId && userMap[`${user.userId}`]) {
          user.user = userMap[`${user.userId}`];
        } else {
          const matchedUser = userData.filter((userObj: IUserSearch) => {
            return userObj.uuid === user.userId;
          });

          if (matchedUser && matchedUser.length > 0) {
            user.user = matchedUser[0];
            userMap[matchedUser[0].uuid] = matchedUser[0];
          }
        }
      });
    }
  });
  return appointmentTypes;
};

export const getConfirmRowData = (
  data?: IAppointmentType
): IAppointmentUser | undefined => {
  if (
    data &&
    data?.appointmentTypeGroup &&
    data?.appointmentTypeGroup.length > 0
  ) {
    const userData = data.appointmentTypeGroup[0];
    return {
      name: userData.user?.name || '',
      duration: '',
      appointmentName: data.eventName,
      appointmentLocation: data.locationType?.value || '',
      appointmentDetails: data.description,
    };
  }
};

export const getDetailRowData = (
  data?: IAppointmentType,
  selectedSlot?: ISlot,
  selectedLocation?: IUserPracticeLocation
): IAppointmentDetail[] => {
  const list: IAppointmentDetail[] = [];
  if (
    data &&
    data?.appointmentTypeGroup &&
    data?.appointmentTypeGroup.length > 0
  ) {
    const userData = data.appointmentTypeGroup[0];
    list.push({
      title: 'Duration',
      subTitle: `${userData.duration} min`,
      description: '',
      icon: <Icon name="clock-o" as={FontAwesome} size="6" />,
    });
    if (selectedSlot) {
      list.push({
        title: 'Date and time',
        subTitle: getFormattedDate(
          selectedSlot.startDateTime,
          DATE_FORMATS.APPOINTMENT_CONFIRM_DATE_FORMAT
        ),
        description: '',
        icon: <Icon name="calendar-check-o" as={FontAwesome} size="6" />,
      });
    }
    list.push({
      title: 'Location',
      subTitle: selectedLocation
        ? getCompletePracticeLocation(selectedLocation.practiceLocation)
        : data.locationType?.value || '',
      description: '',
      icon: <Icon name="home" as={FontAwesome} size="6" />,
    });
  }
  return list;
};

export const getMaxDateForBooking = (
  appointmentType: IAppointmentType | IWidgetAppointmentType
): string | undefined => {
  if (appointmentType?.bookWithinDays) {
    const maxDate = addDaysInDate(new Date(), appointmentType.bookWithinDays);
    return getFormattedDate(maxDate, DATE_FORMATS.CONVERSATION_DATE_FILTER);
  }
  return undefined;
};

export const getCompletePracticeLocation = (
  userPracticeLocation: IPracticeLocation
): string => {
  const locationItems: string[] = [];
  if (userPracticeLocation.addressLine1) {
    locationItems.push(userPracticeLocation.addressLine1);
  }
  if (userPracticeLocation.addressLine2) {
    locationItems.push(userPracticeLocation.addressLine2);
  }
  if (userPracticeLocation.practiceCity?.name) {
    locationItems.push(userPracticeLocation.practiceCity.name);
  }
  if (userPracticeLocation.practiceState?.name) {
    locationItems.push(userPracticeLocation.practiceState.name);
  }
  if (userPracticeLocation.practiceCountry?.name) {
    locationItems.push(userPracticeLocation.practiceCountry.name);
  }
  if (userPracticeLocation.practiceZipcode?.code) {
    locationItems.push(userPracticeLocation.practiceZipcode.code);
  }
  const location = locationItems.join(', ');
  if (locationItems.length) {
    return `${location} (${userPracticeLocation.name})`;
  }
  return userPracticeLocation.name;
};

export const getDataForParticipantStatusUpdate = (
  statusId: string,
  appointmentDetail: IBookedAppointment,
  currentUserContactUUID?: string,
  extraData?: {
    proposedTime?: string;
    declinedReason?: string;
    needsActionStatusId?: string;
  }
) => {
  let isCurrentUserInitiator = false;
  let startDateTime;
  let endDateTime;
  const allParticipantStatusCodes: string[] = [];
  appointmentDetail.participants.forEach((participant) => {
    if (participant.contact?.uuid === currentUserContactUUID) {
      isCurrentUserInitiator = participant.isInitiator || false;
    }
    if (participant.statusId) {
      allParticipantStatusCodes.push(participant.statusId);
    }
  });
  if (
    isCurrentUserInitiator &&
    allParticipantStatusCodes.length &&
    allParticipantStatusCodes.includes(extraData?.needsActionStatusId || '') &&
    appointmentDetail.proposedTime
  ) {
    const duration = getDiffInMinutes(
      new Date(appointmentDetail.startDateTime),
      new Date(appointmentDetail.endDateTime)
    );
    startDateTime = appointmentDetail.proposedTime;
    endDateTime = addTimeToDate(
      appointmentDetail.proposedTime,
      duration,
      'MINUTE'
    ).toISOString();
  }
  return {
    id: appointmentDetail.id,
    proposedTime: extraData?.proposedTime,
    declinedReason: extraData?.declinedReason,
    startDateTime,
    endDateTime,
    participants: appointmentDetail.participants.map((participant) => {
      const isCurrentUser =
        participant.contact?.uuid === currentUserContactUUID;
      return {
        id: participant.id,
        isStatusUpdated: isCurrentUser ? true : undefined,
        statusId: isCurrentUser ? statusId : participant.statusId,
        isInitiator: participant.isInitiator,
      };
    }),
  };
};

export const getAppointmentDateTime = (
  appointment: IAppointmentCompleteDetail | any,
  timeZone?: string | undefined,
  dateFormat?: string
) => {
  let startDate = '';
  let endDate = '';
  let startTime = '';
  let endTime = '';
  if (timeZone) {
    startDate = getDateStrFromFormatWithTimezone(appointment.startDateTime, timeZone, dateFormat || 'ddd, MMM DD');
    endDate = getDateStrFromFormatWithTimezone(appointment.endDateTime, timeZone, dateFormat || 'ddd, MMM DD');
    startTime = getDateStrFromFormatWithTimezone(appointment.startDateTime, timeZone, DATE_FORMATS.DISPLAY_TIME_FORMAT);
    endTime = getDateStrFromFormatWithTimezone(appointment.endDateTime, timeZone, DATE_FORMATS.DISPLAY_TIME_FORMAT);
  } else {
    startDate = getDateStrFromFormat(appointment.startDateTime, dateFormat || 'ddd, MMM DD');
    endDate = getDateStrFromFormat(appointment.endDateTime, dateFormat || 'ddd, MMM DD');
    startTime = getDateStrFromFormat(appointment.startDateTime, DATE_FORMATS.DISPLAY_TIME_FORMAT);
    endTime = getDateStrFromFormat(appointment.endDateTime, DATE_FORMATS.DISPLAY_TIME_FORMAT);
  }
  return (
    `${startDate}   ${startTime}  -  ` +
    (startDate !== endDate ? `${endDate}   ${endTime}` : endTime)
  );
};

export const getAppointmentTimeWithTimeZone = (startDateTime: string, endDateTime: string, timeZone?: string) => {
    const appointmentStartTime = getDateStrFromFormat(
      startDateTime,
      DATE_FORMATS.DISPLAY_TIME_FORMAT
    );
    const appointmentEndTime = getDateStrFromFormat(
      endDateTime,
      DATE_FORMATS.DISPLAY_TIME_FORMAT
    );
    return (
      `${appointmentStartTime} - ${appointmentEndTime}`
    );
};

export const getAppointmentDateTimeWithTimeZone = (startDateTime: string, endDateTime: string, timeZone?: string) => {
  const startDate = getDateStrFromFormat(
    startDateTime,
    'ddd, MMM DD'
  );
  const endDate = getDateStrFromFormat(endDateTime, 'ddd, MMM DD');
  const startTime = getDateStrFromFormat(
    startDateTime,
    DATE_FORMATS.DISPLAY_TIME_FORMAT
  );
  const endTime = getDateStrFromFormat(
    endDateTime,
    DATE_FORMATS.DISPLAY_TIME_FORMAT
  );

  return (
    `${startDate}   ${startTime}  -  ` +
    (startDate !== endDate ? `${endDate}   ${endTime}` : endTime)
  );
};

export const filterNonDeletedLocationUser = (users: any[]) => {
  const accountUsers: any[] = [];
  (users || []).forEach((user: any) => {
    const userPracticeLocations = (user?.userPracticeLocations || []).filter((location: any) => {
      return location?.accountLocation?.practiceLocation?.uuid;
    });
    user['userPracticeLocations'] = userPracticeLocations;
    accountUsers.push(user);
  });
  return accountUsers;
};

export const formatAppointmentTypes = (
  appointmentTypes: IAppointmentType[] | IWidgetAppointmentType[],
  mlovs: IMlov[],
  isValidate?: boolean
) => {
  return appointmentTypes.map(
    (appointmentType: IAppointmentType | IWidgetAppointmentType) => {
      appointmentType?.customFields?.map((customField) => {
        const appointmentFieldTypeMlov = getMlovObjectFromId(
          customField?.fieldTypeId || '',
          mlovs
        );
        if (
          customField?.valueDataTypeCode ===
          APPOINTMENT_TYPE_DATA_TYPE_CODES.DURATION
        ) {
          const unitMlov = getMlovObjectFromId(
            customField?.value?.unitId || '',
            mlovs
          );
          if (unitMlov && isValidUnitType(unitMlov.code.toLocaleLowerCase())) {
            if (
              appointmentFieldTypeMlov?.code ===
              APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_OFFSET
            ) {
              const updatedDate = addDurationInDate(
                parseInt(customField?.value.value || '0'),
                unitMlov.code.toLocaleLowerCase() as moment.unitOfTime.DurationConstructor,
                true
              );
              const bookFromDays = getDiffInDays(
                getMomentObj(new Date()).utc(),
                updatedDate
              );

              if (bookFromDays) {
                appointmentType.bookFromDays = bookFromDays;
                const bookingSpan = `${parseInt(customField?.value.value || '0')} ${
                  unitMlov.value.toLowerCase()
                }`;

                appointmentType.bookFromSpanText = bookingSpan;
              }

              appointmentType.slotStartTime = updatedDate.toISOString();
            }

            if (
              appointmentFieldTypeMlov?.code ===
              APPOINTMENT_TYPE_CUSTOM_FIELD_TYPES.SLOT_WITHIN_LIMIT
            ) {
              let value = parseInt(customField?.value.value || '0');

              if (value && unitMlov.code.toLocaleLowerCase() === 'days') {
                value -= 1;
              }

              //minus one day from boook within days if unit is days
              const updatedDate = addDurationInDate(
                value,
                unitMlov.code.toLocaleLowerCase() as moment.unitOfTime.DurationConstructor,
                true
              );

              const actualBookWithinDate = addDurationInDate(
                parseInt(customField?.value.value || '0'),
                unitMlov.code.toLocaleLowerCase() as moment.unitOfTime.DurationConstructor,
                true
              );

              const bookWithinDays = getDiffInDays(
                getMomentObj(new Date()).utc(),
                actualBookWithinDate,
                false
              );

              if (bookWithinDays) {
                appointmentType.bookWithinDays = bookWithinDays;
                const bookingSpan = `${parseInt(customField?.value?.value || '0')} ${
                  unitMlov.value.toLowerCase()
                }`;

                appointmentType.bookWithinSpanText = bookingSpan;
              }

              appointmentType.slotEndTime = isValidate ? actualBookWithinDate.toISOString() : updatedDate.toISOString();
            }
          }
        }
      });
      return appointmentType;
    }
  );
};

export const filterSlotsByAppointentType = (
  slots: ISlot[],
  appointmentType: IAppointmentType | IWidgetAppointmentType | undefined
) => {
  return slots.filter((slot) => {
    if (appointmentType?.slotStartTime && appointmentType?.slotEndTime) {
      return (
        new Date(slot.startDateTime) >
          new Date(appointmentType.slotStartTime) &&
        new Date(slot.endDateTime) < new Date(appointmentType.slotEndTime)
      );
    }
    if (appointmentType?.slotStartTime) {
      return (
        new Date(slot.startDateTime) > new Date(appointmentType.slotStartTime)
      );
    }
    if (appointmentType?.slotEndTime) {
      return new Date(slot.endDateTime) < new Date(appointmentType.slotEndTime);
    }
    return true;
  });
};

export const getBookingErrorTextMessage = (
  appointmentType: IAppointmentType | IWidgetAppointmentType | undefined,
  intl: any
) => {
  if (
    appointmentType?.bookFromSpanText &&
    appointmentType?.bookWithinSpanText
  ) {
    let msg = intl.formatMessage({id: 'selectSlotError'});

    msg = msg.replace('$bookWithinOffset', appointmentType?.bookFromSpanText);
    msg = msg.replace('$bookWithinLimit', appointmentType?.bookWithinSpanText);
    return msg;
  }

  return '';
};