import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles';

export const styles = StyleSheet.create({
  mainContainer:{
      marginHorizontal:16
  },
  boxElement: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: '#fff',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  templateCard: {
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.26,
    elevation: 8,
    backgroundColor: 'white',
    borderRadius: 12,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formContainer: {
    flex: 1,
    marginBottom: 12,
  },
  labelContainer: {
    marginBottom: 4,
    marginTop: 4,
  },
  defaultOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mergeTagPopoverView: {
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  readonlyLineContainer:{marginTop: 10},
  readPillContainer: {
    backgroundColor: Colors.Custom.Gray300,
    padding: 2.5,
    paddingHorizontal: 5,
    marginRight: 5,
    borderRadius: 5,
    marginTop:10
  },
  readonlyContainer: {paddingHorizontal: 24, paddingBottom: 24},
});
