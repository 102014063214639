import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  deviceContainer: {
    marginTop: 10,
  },
  listContainer: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  displayListItemFirstItem: {
    paddingTop: 16,
  },
  displayListItemLastItem: {
    paddingBottom: 16,
  },
  displayListItem: {
    paddingTop: 6,
    marginRight: 20,
    marginLeft: 20,
    paddingBottom: 6,
    maxHeight: 200,
    overflow: 'scroll',
    cursor: 'pointer',
    minHeight:40
    // justifyContent: 'space-between',
  },
  valueContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  valueTxt: {
    fontSize: 12,
    color: Colors.Custom.DataKeyColor,
  },
  keyContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  keyTxt: {
    fontSize: 14,
  },
  listStyle: {
    borderColor: Colors.Custom.FontColorWhite,
    borderWidth: 1,
    borderRadius: 10,
    marginVertical: 3,
    width: '95%',
    margin: 'auto',
  },
  avatarStyle: {
    borderRadius: 10,
  },
  fullNameTxt: {
    marginLeft: 16,
    color: Colors.Custom.Gray900
  },
  spinnerStyle: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    // backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
});
