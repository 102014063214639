import { useQuery } from "@apollo/client";
import React, { useContext } from 'react';
import { Pressable, Skeleton, VStack } from "native-base";
import { useState } from "react";
import { CONVERSATION_ACTION_CODES } from "../../../../../../constants/ActionConst";
import AppInfoQueries from "../../../../../../services/AppInfo/AppInfoQueries";
import { IContact } from "../../../../../RightSideContainer/TeamInbox/Conversations/interfaces";
import MessagingContactDetailsDrawer from "../../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer";
import MemberInfoListItem from "../../../../MemberInfoListItem/MemberInfoListItem";
import { IContactCardItemProps } from "./interfase";
import { CommonDataContext } from "../../../../../../context/CommonDataContext";
import { redirectToNewTab } from "../../../../../SideCar/SidecarUtils";
import { PERSON_TYPES } from "../../../../../../constants";
import { getPatientEHRUrl } from "../../../../../../utils/capabilityUtils";

const ContactCardView = (props: IContactCardItemProps) => {
  const {
    contactUUID,
    textColor,
  } = props;
  const [stateData, setStateData] = useState({
    contactData: {} as IContact,
    contactDrawerOpen: false,
  });
  const getContactByUuidApi = useQuery(AppInfoQueries.GET_CONTACT_BY_UUID, {
    variables: {
      contactUUID: contactUUID,
    },
    onCompleted: (data: any) => {
      if (data?.contacts?.length) {
        setStateData({
          ...stateData,
          contactData: data.contacts[0],
        });
      } else {
        setStateData({
          ...stateData,
          contactData: {} as IContact,
        });
      }
    },
  });
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const ehrPatientId = stateData.contactData?.patient?.patientId || '';
  const openInEhrUrl = getPatientEHRUrl(ehrPatientId);


  return (
    <VStack>
      {getContactByUuidApi.loading ? (
        <Skeleton></Skeleton>
      )
        :
        <Pressable
          onPress={() => {
            if (isSideCarContext) {
              if (stateData.contactData.contactType?.contactType.code == PERSON_TYPES.CUSTOMER) {
                if (openInEhrUrl) {
                  window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
                } else {
                  redirectToNewTab(`/members/patient/${stateData.contactData?.id}`);
                }
              } else if (stateData.contactData.contactType?.contactType.code == PERSON_TYPES.LEAD) {
                redirectToNewTab(`/members/prospect/${stateData.contactData?.id}`);
              }
            } else {
              setStateData({
                ...stateData,
                contactDrawerOpen: true,
              });
            }
          }}
        >
          <MemberInfoListItem
            contactData={stateData.contactData || {} as IContact}
            isPatientSearchCard
            textColor={textColor}
            showDetailIcon
            showDateOfBirth={true}
          />
        </Pressable>
      }

      {stateData.contactDrawerOpen && (
        <MessagingContactDetailsDrawer
          contactData={stateData.contactData}
          isDrawerVisible={stateData.contactDrawerOpen ? true : false}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setStateData({
                ...stateData,
                contactDrawerOpen: false,
              });
            }
          }}
          borderLessView={true}
        />
      )}
    </VStack>
  )
}

export default ContactCardView;
