import { AutoComplete, Drawer, Select, Space, Spin, Tooltip, Upload, notification } from 'antd';
import { CheckIcon, Divider, HStack, Pressable, ScrollView, Skeleton, Spinner, Text, VStack, View, useMediaQuery } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { Colors } from '../styles';
import { DisplayText } from './common/DisplayText/DisplayText';
import { AddActionView } from './common/Buttons/AddActionView';
import { INSURANCE_MEDIA_NUMBER } from '../constants';
import { ModalActionTitle } from './common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE, DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, INSURANCE_NUMBER, IPAD_MINI_WIDTH, IPAD_WIDTH, PATIENT_QUERRY_DATE_FROMATED, SMALL_WINDOW_1700 } from '../constants';
import { Dimensions } from 'react-native';
import { ModalActionInput } from './common/ModalActionCommonComponent/ModalActionInput';
import { ModalActionSelect } from './common/ModalActionCommonComponent/ModalActionSelect';
import { BASE_URL } from '../constants/Configs';
import UploadInsuranceCard from './UploadInsuranceCard';
import { ModalActionDatePicker } from './common/ModalActionCommonComponent/ModalActionDatePicker';
import { getDateStrFromFormat, getMomentObjectWithDateStringAndFormat, isValidDate } from '../utils/DateUtils';
import AdddetailsIconSvg from './common/Svg/AddDetailsIconSvg';
import { useIntl } from 'react-intl';
import { getCopayAmountTypeData, getInsuranceCardImage, getInsuranceCardImageElation, getInsuranceFieldData, getPatientRelationTypeData, getSearchInsuranceCarrieNameElation, getSearchInsuranceCarrieOrPlanName, getSearchPlanNameElation, postInsuranceCardImage, postInsuranceFieldData, putInsuranceFielddata } from '../services/CommonService/AidBoxService';
import { ModalActionAutoComplete } from './common/ModalActionCommonComponent/ModalActionAutoComplete';
import { debounce } from 'lodash';
import { getCapability, getEhrConfig } from '../utils/capabilityUtils';
import InsuranceCardEmptyStateSvg from './common/Svg/InsuranceCardEmptyStateSvg';
import InsuranceCardPlanNameSvg from './common/Svg/InsuranceCardPlanNameSvg';
import InsuranceCardNotExpireSvg from './common/Svg/InsuranceCardNotExpireSvg';
import InsuranceCardExpireSvg from './common/Svg/InsuranceCardExpireSvg';
import { validateInsuranceFields, codeToDisplayMapAthena, codeToDisplayMapAthenaOptions, codeToDisplayMapElation, codeToDisplayMapElationOptions, displayToCodeMapAthena, displayToCodeMapElation, renderCopayFields, isInsuranceEnable } from '../utils/commonUtils';
import { EXTENSION_URLS } from './PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { ImageSide, handleAddData, handleAddDataElation, handleUpdate, handleUpdateElation, getInsuranceStateClear, isInsuranceStateEmpty  } from './InsuranceUtils';
import { ZipCodeAutoComplete } from './common/ZipCodeComponent';
import { IZipCodeData } from './common/ZipCodeComponent/interfaces';
import { StateAutoComplete } from './common/State';
import { CityAutoComplete } from './common/City';
import { IExtension } from './PersonOmniView/LeftContainer/RecentActivity/OrdersInterface';
import { FHIR_RESOURCE } from '../constants/FhirConstant';
import AddOrUpdateInsuranceView from './AddOrUpdateInsuranceView';
import InsuranceAdditionaldetailsView from './InsuranceAdditionalDetailsView';
import { ICopayAmount, ICopayDetail, IInsuranceCardState, IInsuranceComponentState, IInsuranceMandatoryFieldAccordingToEHR, IInsuranceState } from './InsuranceInterfaces';
import { CommonDataContext } from '../context/CommonDataContext';
import { Resource } from 'fhir/r4';


interface IInsuranceViewProps {
  setInsuranceDataForRequestBody?: any;
  setSecInsuranceDataForRequestBody?: any;
  setTerInsuranceDataForRequestBody?: any;
  setSecondaryInsuranceDataForRequestBody?: any;
  setTertiaryInsuranceDataForRequestBody?: any;
  addPatientView?: boolean;
  contactId?: string | number;
  patientId?: string;
  contactUuid?: string;
}


const InsuranceView = (props: IInsuranceViewProps) => {
  const [componentState, setComponentState] = useState<IInsuranceComponentState>({
    isOpen: false,
    isLoading: true,
    base64Data: '',
    base64FrontSideData: '',
    isInsuranceDataLoading: true,
    base64BackSideData: '',
    copayDetails: [] as ICopayDetail[],
    additionalDetailsOpen: false,
    addInsuranceOpen: false,
    insuraceCarrierNameDataArray: [],
    organizationId: '',
    loadingInsuraceCarrierNameData: false,
    loadingPlanName: false,
    copayFieldsCount: 1,
    copayAmountType: [] as ICopayAmount[],
    patientRelationType: [] as ICopayAmount[],
    planNameDisable: true,
    base64SecondaryData: '',
    base64FrontSideSecondaryData: '',
    base64BackSideSecondaryData: '',
    copaySecondaryDetails: [] as ICopayDetail[],
    additionalSecondaryDetailsOpen: false,
    insuraceCarrierNameSecondaryDataArray: [],
    organizationIdSecondary: '',
    loadingInsuraceCarrierNameSecondaryData: false,
    loadingSecondaryPlanName: false,
    copaySecondaryFieldsCount: 1,
    copaySecondaryAmountType: [] as ICopayAmount[],
    patientSecondaryRelationType: [] as ICopayAmount[],
    planNameSecondaryDisable: true,
    base64DataTertiary: '',
    base64FrontSideTertiaryData: '',
    base64BackSideTertiaryData: '',
    copayTertiaryDetails: [] as ICopayDetail[],
    additionalTertiaryDetailsOpen: false,
    addTertiaryInsuranceOpen: false,
    insuraceCarrierNameTertiaryDataArray: [],
    organizationIdTertiary: '',
    loadingInsuraceCarrierNameTertiaryData: false,
    loadingPlanNameTertiary: false,
    copayTertiaryFieldsCount: 1,
    copayTertiaryAmountType: [] as ICopayAmount[],
    patientRelationTypeTertiary: [] as ICopayAmount[],
    planNameTertiaryDisable: true,
    isPrimaryInsuranceDeleted: false,
    isSecondaryInsuranceDeleted: false,
    isTertiaryInsuranceDeleted: false,
    isSaveButtonLoading: false,
  })
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings);
  const [errors, setErrors] = useState<IInsuranceMandatoryFieldAccordingToEHR>();
  const [secondaryErrors, setSecondaryErrors] = useState<IInsuranceMandatoryFieldAccordingToEHR>();
  const [tertiaryErrors, setTertairyErrors] = useState<IInsuranceMandatoryFieldAccordingToEHR>();
  const [insuranceCardName,setInsuranceCardName] = useState('')
  const [insuranceData, setInsuranceData] = useState<IInsuranceState | undefined>({
    addSecondaryInsuranceOpen: false,
    addTertiaryInsuranceOpen: false,
  });
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState<IInsuranceState>();
  const [tertiaryInsuranceData, setTertiaryInsuranceData] = useState<IInsuranceState>();
  const [isPlanNameFreeText, setIsPlanNameFreeText] = useState(false);
  const [isInsuranceCarrierNameFreeText, setIsInsuranceCarrierNameFreeText] = useState(false);
  const insuranceCapability = getCapability(FHIR_RESOURCE.COVERAGE);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const ehrConfig = getEhrConfig();
  const isElation = ehrConfig.isElation;
  const insuranceOperations = insuranceCapability?.abilities?.keyAllowedOperations;
  const canPlanNameCreate = ehrConfig.isAthena ? insuranceOperations?.planName.canCreate : true;
  const findResourceObjectWithSequenceNumber = (response: any, targetSequenceNumber: number) => {
    for (const entry of response.entry) {
      const resource = entry.resource;
      const sequenceNumberExtension = resource.extension.find((extension: any) => extension.url === EXTENSION_URLS.insuranceSequenceNumber);
      if (sequenceNumberExtension && sequenceNumberExtension.valueInteger === targetSequenceNumber) {
        return resource;
      }
    }
    return null;
  }
  const fetchFieldData = () => {
    setComponentState((prev) => ({
      ...prev,
      isInsuranceDataLoading: true
    }));
    getInsuranceFieldData(props?.patientId || '')
      .then((response: any) => {
        const data = response?.data
        const entry = ehrConfig.isElation ? data?.entry?.find((entry: any) => entry?.resource?.order === 1)?.resource : findResourceObjectWithSequenceNumber(data, 1);
        const valueHumanName = entry?.policyHolder?.extension?.find((ext: any) => 'valueHumanName' in ext)?.valueHumanName;
        const birthSexExtension = entry?.policyHolder?.extension.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceSexAtBirth);
        const birthSexValueCode = birthSexExtension ? birthSexExtension?.valueCode : null;
        const birthSexDisplay: string = ehrConfig.isElation ? codeToDisplayMapElation[birthSexValueCode] : codeToDisplayMapAthena[birthSexValueCode];
        const dobExtension = entry?.policyHolder?.extension.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceDob);
        const dobValueCode = dobExtension ? dobExtension?.valueString : null;
        const dobElation = dobValueCode && getDateStrFromFormat(dobValueCode, PATIENT_QUERRY_DATE_FROMATED)
        const lastCheckedExtension = entry?.extension?.find((ext: any) => ext.url === EXTENSION_URLS.insuranceLastChecked);
        const lastCheckedValuString = lastCheckedExtension ? lastCheckedExtension?.valueString : null;
        const planObject = entry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding?.code === "plan");
        });
        const planNameWithCode = planObject?.name;
        const organizationIdElation = entry?.payor?.[0]?.reference?.split('/')?.[1];
        const planNameId = planObject?.value;
        const indexOfBracket = planNameWithCode?.indexOf("[");
        const planName = indexOfBracket !== -1 ? planNameWithCode?.substring(0, indexOfBracket).trim() : planNameWithCode.trim();
        const insurancePackageId = planNameWithCode?.match(/\[(\d+)\]/)?.[1]
        const insuranceCarrierObject = entry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding.code === "group");
        });
        const insuranceCarrierName = insuranceCarrierObject?.name;
        const groupId = entry?.class?.find((item: any) => item?.type?.coding[0]?.code === "group")?.value;
        const valueAddress = entry?.policyHolder?.extension?.find((ext: any) => 'valueAddress' in ext)?.valueAddress;
        const base64Athena = ehrConfig.isAthena ? entry?.contained?.[0]?.content?.data : '';
        const memberIdElation = entry?.identifier?.find((id: any) => id.system === "member-id-number")?.value || "";
        const memberIdAthena = (entry?.identifier?.find((id: any) => id.system === "insurance-id-number")?.value || "");
        const copayAmountElation = entry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'copay')?.valueMoney?.value;
        const copayAmountAthena = entry?.costToBeneficiary?.valueMoney?.value;
        const deductibleAmount = entry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'deductible')?.valueMoney?.value;
        const policyHolderIDAthena = entry?.extension?.find((ext: IExtension) => ext?.url === EXTENSION_URLS.insurancePolicyHolderIdAthena)?.valueString;
        const policyHolderIDElation = entry?.policyHolder?.extension?.find((ext: IExtension) => ext?.url === EXTENSION_URLS.insurancePolicyHolderIdElation)?.valueString;
        const secondaryInsuranceAthenaEntry = findResourceObjectWithSequenceNumber(data, 2);
        const tertiaryInsuranceAthenaEntry = findResourceObjectWithSequenceNumber(data, 3);
        const secondaryInsuranceEntry = ehrConfig.isElation ? data?.entry?.find((entry: any) => entry?.resource?.order === 2)?.resource : secondaryInsuranceAthenaEntry;
        const tertiaryInsuranceEntry = ehrConfig.isAthena && tertiaryInsuranceAthenaEntry;
        const secondaryValueHumanName = secondaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => 'valueHumanName' in ext)?.valueHumanName;
        const tertiaryValueHumanName = tertiaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => 'valueHumanName' in ext)?.valueHumanName;
        const secondaryBirthSexExtension = secondaryInsuranceEntry?.policyHolder?.extension.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceSexAtBirth);
        const secondaryBirthSexValueCode = secondaryBirthSexExtension?.valueCode;
        const secondaryBirthSexDisplay: string = ehrConfig.isElation ? codeToDisplayMapElation[secondaryBirthSexValueCode] : codeToDisplayMapAthena[secondaryBirthSexValueCode];
        const tertiaryBirthSexExtension = tertiaryInsuranceEntry?.policyHolder?.extension.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceSexAtBirth);
        const tertiaryBirthSexValueCode = codeToDisplayMapAthena[tertiaryBirthSexExtension?.valueCode];
        const tertiaryBirthSexDisplay: string = ehrConfig.isElation ? codeToDisplayMapElation[tertiaryBirthSexValueCode] : tertiaryBirthSexValueCode;
        const secondaryDobExtension = secondaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceDob);
        const secondaryDobValueCode = secondaryDobExtension?.valueString;
        const secondaryDobElation = secondaryDobValueCode && getDateStrFromFormat(secondaryDobValueCode, PATIENT_QUERRY_DATE_FROMATED)
        const tertiaryDobExtension = tertiaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceDob);
        const tertiaryDobValueCode = tertiaryDobExtension?.valueString;
        const tertiaryDobElation = getDateStrFromFormat(tertiaryDobValueCode, PATIENT_QUERRY_DATE_FROMATED);
        const secondaryLastCheckedExtension = secondaryInsuranceEntry?.extension?.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceLastChecked);
        const secondaryLastCheckedValuString = secondaryLastCheckedExtension?.valueString;
        const tertiaryLastCheckedExtension = tertiaryInsuranceEntry?.extension?.find((ext: any) => ext?.url === EXTENSION_URLS.insuranceLastChecked);
        const tertiaryLastCheckedValuString = tertiaryLastCheckedExtension?.valueString;
        const secondaryPlanObject = secondaryInsuranceEntry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding?.code === "plan");
        });
        const tertiaryPlanObject = tertiaryInsuranceEntry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding.code === "plan");
        });
        const secondaryPlanNameWithCode = secondaryPlanObject?.name;
        const tertiaryPlanNameWithCode = tertiaryPlanObject?.name;
        const secondaryOrganizationIdElation = secondaryInsuranceEntry?.payor?.[0]?.reference?.split('/')?.[1];
        const tertiaryOrganizationIdElation = tertiaryInsuranceEntry?.payor?.[0]?.reference?.split('/')?.[1];
        const secondaryPlanNameId = secondaryPlanObject?.value;
        const tertiaryPlanNameId = tertiaryPlanObject?.value;
        const secondaryIndexOfBracket = secondaryPlanNameWithCode?.indexOf("[");
        const tertiaryIndexOfBracket = tertiaryPlanNameWithCode?.indexOf("[");
        const secondaryPlanName = secondaryIndexOfBracket !== -1 ? secondaryPlanNameWithCode?.substring(0, secondaryIndexOfBracket)?.trim() : secondaryPlanNameWithCode?.trim();
        const tertiaryPlanName = tertiaryIndexOfBracket !== -1 ? tertiaryPlanNameWithCode?.substring(0, tertiaryIndexOfBracket)?.trim() : tertiaryPlanNameWithCode?.trim();
        const secondaryInsurancePackageId = secondaryPlanNameWithCode?.match(/\[(\d+)\]/)?.[1]
        const tertiaryInsurancePackageId = tertiaryPlanNameWithCode?.match(/\[(\d+)\]/)?.[1]
        const secondaryInsurancecarrierObject = secondaryInsuranceEntry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding?.code === "group");
        });
        const tertiaryInsurancecarrierObject = tertiaryInsuranceEntry?.class?.find((item: any) => {
          return item?.type?.coding?.some((coding: any) => coding?.code === "group");
        });
        const secondaryInsuranceCarrierName = secondaryInsurancecarrierObject?.name;
        const tertiaryInsuranceCarrierName = tertiaryInsurancecarrierObject?.name;
        const secondaryGroupId = secondaryInsuranceEntry?.class?.find((item: any) => item?.type?.coding[0]?.code === "group")?.value;
        const tertiaryGroupId = tertiaryInsuranceEntry?.class?.find((item: any) => item?.type?.coding[0]?.code === "group")?.value;
        const secondaryValueAddress = secondaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => 'valueAddress' in ext)?.valueAddress;
        const tertiaryValueAddress = tertiaryInsuranceEntry?.policyHolder?.extension?.find((ext: any) => 'valueAddress' in ext)?.valueAddress;
        const secondaryBase64Athena = ehrConfig.isAthena ? secondaryInsuranceEntry?.contained?.[0]?.content?.data : '';
        const tertiaryBase64Athena = ehrConfig.isAthena ? tertiaryInsuranceEntry?.contained?.[0]?.content?.data : '';
        const secondaryMemberIdElation = secondaryInsuranceEntry?.identifier?.find((id: any) => id?.system === "member-id-number")?.value || "";
        const secondaryMemberIdAthena = (secondaryInsuranceEntry?.identifier?.find((id: any) => id?.system === "insurance-id-number")?.value || "");
        const tertiaryMemberIdElation = tertiaryInsuranceEntry?.identifier?.find((id: any) => id?.system === "member-id-number")?.value || "";
        const tertiaryMemberIdAthena = (tertiaryInsuranceEntry?.identifier?.find((id: any) => id?.system === "insurance-id-number")?.value || "");
        const secondaryCopayAmountElation = secondaryInsuranceEntry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'copay')?.valueMoney?.value;
        const secondaryCopayAmountAthena = secondaryInsuranceEntry?.costToBeneficiary?.valueMoney?.value;
        const tertiaryCopayAmountElation = tertiaryInsuranceEntry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'copay')?.valueMoney?.value;
        const tertiaryCopayAmountAthena = tertiaryInsuranceEntry?.costToBeneficiary?.valueMoney?.value;
        const secondaryDeductibleAmount = secondaryInsuranceEntry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'deductible')?.valueMoney?.value
        const tertiaryDeductibleAmount = tertiaryInsuranceEntry?.costToBeneficiary?.find((entry: any) => entry?.type?.text === 'deductible')?.valueMoney?.value
        const secondaryPolicyHolderIDAthena = secondaryInsuranceEntry?.extension?.find((ext: IExtension) => ext?.url === EXTENSION_URLS.insurancePolicyHolderIdAthena)?.valueString;
        const secondarypolicyHolderIDElation = secondaryInsuranceEntry?.policyHolder?.extension?.find((ext: IExtension) => ext?.url === EXTENSION_URLS.insurancePolicyHolderIdElation)?.valueString;
        const tertiaryPolicyHolderIDAthena = tertiaryInsuranceEntry?.extension?.find((ext: IExtension) => ext?.url === EXTENSION_URLS.insurancePolicyHolderIdAthena)?.valueString;
        setInsuranceCardName(planName || secondaryPlanName || tertiaryPlanName)

        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
          isInsuranceDataLoading: false
        }));
        setInsuranceData((prev) => ({
          ...prev,
          addSecondaryInsuranceOpen: false,
          addTertiaryInsuranceOpen: false,
          insuranceId: entry?.id || '',
          patientWithPatientId:
            props?.patientId ||
            entry?.beneficiary?.id ||
            entry?.beneficiary?.reference?.split('/')?.[1] ||
            '',
          suffix: valueHumanName?.suffix || '',
          insuranceCarrierName: isElation
            ? entry?.payor?.[0]?.display
            : insuranceCarrierName,
          planName: isElation
            ? entry?.class?.find(
              (entry: any) => entry?.type?.coding?.[0]?.code === 'plan'
            )?.name
            : planName,
          firstName: valueHumanName?.given?.[0] || '',
          middleName: valueHumanName?.given?.[1] || '',
          lastName: valueHumanName?.family || '',
          dob: isElation ? dobElation : dobValueCode,
          sexAtBirth: birthSexDisplay,
          memberID: isElation ? memberIdElation : memberIdAthena,
          groupID: (groupId === 'undefined' || groupId === 'UNDEFINED') ? "" : groupId,
          copayAmount: isElation ? copayAmountElation : copayAmountAthena,
          copayAmountType: isElation ? '' : entry?.costToBeneficiary?.type?.text,
          issueDate: entry?.period?.start || '',
          endDate: entry?.period?.end || '',
          coinsurance: '',
          address: valueAddress?.line?.[0] || '',
          city: valueAddress?.city,
          state: valueAddress?.state,
          country: valueAddress?.country,
          zipcode: valueAddress?.postalCode,
          policyGroupNumber: isElation ? policyHolderIDElation : policyHolderIDAthena,
          patientRealtionWithPolicyHolder: entry?.relationship?.text || '',
          patientRelationshipCode: entry?.relationship?.coding?.[0]?.code,
          lastChecked: lastCheckedValuString,
          deductibleAmount: deductibleAmount,
          insurancePackageId: insurancePackageId,
          base64ImageLink: base64Athena,
          planNameId: planNameId,
          organizationId: organizationIdElation
        }));
        setSecondaryInsuranceData((prev) => ({
          ...prev,
          insuranceId: secondaryInsuranceEntry?.id || '',
          patientWithPatientId:
            props?.patientId ||
            secondaryInsuranceEntry?.beneficiary?.id ||
            secondaryInsuranceEntry?.beneficiary?.reference?.split('/')?.[1] ||
            '',
          suffix: secondaryValueHumanName?.suffix || '',
          insuranceCarrierName: isElation
            ? secondaryInsuranceEntry?.payor?.[0]?.display
            : secondaryInsuranceCarrierName,
          planName: isElation
            ? secondaryInsuranceEntry?.class?.find(
              (entry: any) => entry?.type?.coding?.[0]?.code === 'plan'
            )?.name
            : secondaryPlanName,
          firstName: secondaryValueHumanName?.given?.[0] || '',
          middleName: secondaryValueHumanName?.given?.[1] || '',
          lastName: secondaryValueHumanName?.family || '',
          dob: isElation ? secondaryDobElation : secondaryDobValueCode,
          sexAtBirth: secondaryBirthSexDisplay,
          memberID: isElation ? secondaryMemberIdElation : secondaryMemberIdAthena,
          groupID: (secondaryGroupId === 'undefined' || secondaryGroupId === 'UNDEFINED' ) ? "" : secondaryGroupId,
          copayAmount: isElation ? secondaryCopayAmountElation : secondaryCopayAmountAthena,
          copayAmountType: isElation ? '' : secondaryInsuranceEntry?.costToBeneficiary?.type?.text,
          issueDate: secondaryInsuranceEntry?.period?.start || '',
          endDate: secondaryInsuranceEntry?.period?.end || '',
          coinsurance: '',
          address: secondaryValueAddress?.line?.[0] || '',
          city: secondaryValueAddress?.city,
          state: secondaryValueAddress?.state,
          country: secondaryValueAddress?.country,
          zipcode: secondaryValueAddress?.postalCode,
          policyGroupNumber: isElation ? secondarypolicyHolderIDElation : secondaryPolicyHolderIDAthena,
          patientRealtionWithPolicyHolder: secondaryInsuranceEntry?.relationship?.text || '',
          patientRelationshipCode: secondaryInsuranceEntry?.relationship?.coding?.[0]?.code,
          lastChecked: secondaryLastCheckedValuString,
          deductibleAmount: secondaryDeductibleAmount,
          insurancePackageId: secondaryInsurancePackageId,
          planNameId: secondaryPlanNameId,
          organizationId: secondaryOrganizationIdElation,
          base64ImageLink: secondaryBase64Athena
        }));
        setTertiaryInsuranceData((prev) => ({
          ...prev,
          insuranceId: tertiaryInsuranceEntry?.id || '',
          patientWithPatientId:
            props?.patientId ||
            tertiaryInsuranceEntry?.beneficiary?.id ||
            tertiaryInsuranceEntry?.beneficiary?.reference?.split('/')?.[1] ||
            '',
          suffix: tertiaryValueHumanName?.suffix || '',
          insuranceCarrierName: isElation
            ? tertiaryInsuranceEntry?.payor?.[0]?.display
            : tertiaryInsuranceCarrierName,
          planName: isElation
            ? tertiaryInsuranceEntry?.class?.find(
              (entry: any) => entry?.type?.coding?.[0]?.code === 'plan'
            )?.name
            : tertiaryPlanName,
          firstName: tertiaryValueHumanName?.given?.[0] || '',
          middleName: tertiaryValueHumanName?.given?.[1] || '',
          lastName: tertiaryValueHumanName?.family || '',
          dob: isElation ? tertiaryDobElation : tertiaryDobValueCode,
          sexAtBirth: tertiaryBirthSexDisplay,
          memberID: isElation ? tertiaryMemberIdElation : tertiaryMemberIdAthena,
          groupID: (tertiaryGroupId === 'undefined' || tertiaryGroupId === 'UNDEFINED')  ? "" : tertiaryGroupId,
          copayAmount: isElation ? tertiaryCopayAmountElation : tertiaryCopayAmountAthena,
          copayAmountType: isElation ? '' : tertiaryInsuranceEntry?.costToBeneficiary?.type?.text,
          issueDate: tertiaryInsuranceEntry?.period?.start || '',
          endDate: tertiaryInsuranceEntry?.period?.end || '',
          coinsurance: '',
          address: tertiaryValueAddress?.line?.[0] || '',
          city: tertiaryValueAddress?.city,
          state: tertiaryValueAddress?.state,
          country: tertiaryValueAddress?.country,
          zipcode: tertiaryValueAddress?.postalCode,
          policyGroupNumber: tertiaryPolicyHolderIDAthena,
          patientRealtionWithPolicyHolder: tertiaryInsuranceEntry?.relationship?.text || '',
          patientRelationshipCode: tertiaryInsuranceEntry?.relationship?.coding?.[0]?.code,
          lastChecked: tertiaryLastCheckedValuString,
          deductibleAmount: tertiaryDeductibleAmount,
          insurancePackageId: tertiaryInsurancePackageId,
          planNameId: tertiaryPlanNameId,
          organizationId: tertiaryOrganizationIdElation,
          base64ImageLink: tertiaryBase64Athena
        }));
        if(entry?.id){
          setComponentState((prev) => ({
            ...prev,
            additionalDetailsOpen: true,
          }));
        }
        if (secondaryInsuranceEntry?.id) {
          setInsuranceData((prev) => ({
            ...prev,
            addSecondaryInsuranceOpen: true,
          }))
          setComponentState((prev) => ({
            ...prev,
            additionalSecondaryDetailsOpen: true,
          }));
        }
        if (tertiaryInsuranceEntry?.id) {
          setInsuranceData((prev) => ({
            ...prev,
            addTertiaryInsuranceOpen: true,
          }))
          setComponentState((prev) => ({
            ...prev,
            additionalTertiaryDetailsOpen: true
          }));
        }
      })
      .catch((error) => {
        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
          isInsuranceDataLoading: false
        }));
      });
  };
  const fetchCopayAmountTypeData = async () => {
    getCopayAmountTypeData()
      .then((response: any) => {
        const data = response?.data;
        const codeDisplayArray = data?.expansion?.contains;
        setComponentState((prev) => ({
          ...prev,
          copayAmountType: codeDisplayArray,
        }));
        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      })
      .catch((err: any) => {

        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const showNotification = (messageId: string) => {
    notification.destroy();
    notification.success({
      message: intl.formatMessage({ id: messageId }),
      duration: 3.0,
      placement: 'top'
    });
  };

  const fetchPatientRelationType = async () => {
    getPatientRelationTypeData()
      .then((response: any) => {
        const data = response?.data;
        const codeDisplayArray = data?.expansion?.contains;
        setComponentState((prev) => ({
          ...prev,
          patientRelationType: codeDisplayArray,
        }));
        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      })
      .catch((err: any) => {

        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    !props.addPatientView && fetchFieldData();
    fetchCopayAmountTypeData();
    fetchPatientRelationType();
  }, []);

  const onCancelAction = () => {
    setComponentState((prev) => ({
      ...prev,
      isOpen: false,
      additionalDetailsOpen: !!insuranceData?.insuranceId ? true : false,
      additionalSecondaryDetailsOpen: !!secondaryInsuranceData?.insuranceId ? true : false,
      additionalTertiaryDetailsOpen: !!tertiaryInsuranceData?.insuranceId ? true : false,
      isPrimaryInsuranceDeleted: false,
      isSecondaryInsuranceDeleted: false,
      isTertiaryInsuranceDeleted: false
    }));
    setErrors((prev) => ({
      ...prev,
      memberId: '',
      groupId: '',
      firstName:'',
      lastName: '',
      insuranceCarrierName: '',
      planName: '',
      patientRealtionWithPolicyHolder: '',
      sexAtBirth: ''
    }));
    setSecondaryErrors((prev) => ({
      ...prev,
      memberId: '',
      groupId: '',
      firstName: '',
      lastName: '',
      insuranceCarrierName: '',
      planName: '',
      patientRealtionWithPolicyHolder: '',
      sexAtBirth: ''
    }));
    setTertairyErrors((prev) => ({
      ...prev,
      memberId: '',
      groupId: '',
      firstName: '',
      lastName: '',
      insuranceCarrierName: '',
      planName: '',
      patientRealtionWithPolicyHolder: '',
      sexAtBirth: ''
    }));
    if (insuranceData?.insuranceId === '' && !insuranceData?.insuranceId) {
      const clearedData = getInsuranceStateClear(insuranceData, INSURANCE_NUMBER.PRIMARY);
      setInsuranceData(clearedData);
    }
    if (secondaryInsuranceData?.insuranceId === '' && !secondaryInsuranceData?.insuranceId) {
      const clearedData = getInsuranceStateClear(secondaryInsuranceData, INSURANCE_NUMBER.SECONDARY);
      setSecondaryInsuranceData(clearedData);
    }
    if (tertiaryInsuranceData?.insuranceId === '' && !tertiaryInsuranceData?.insuranceId) {
      const clearedData = getInsuranceStateClear(tertiaryInsuranceData, INSURANCE_NUMBER.TERTIARY);
      setTertiaryInsuranceData(clearedData);
    }
    setInsuranceData((prev) => ({
      ...prev,
      addSecondaryInsuranceOpen: !!secondaryInsuranceData?.insuranceId ? true : false,
      addTertiaryInsuranceOpen: !!tertiaryInsuranceData?.insuranceId ? true : false,
    }));
    setComponentState((prev) => ({
      ...prev,
      copayFieldsCount: 1,
  }));
  }

  const onSaveOrCancelStateAction = () => {
    setComponentState((prev) => ({
      ...prev,
      isOpen: false,
      additionalDetailsOpen: insuranceData?.insuranceId ? true : false,
      additionalSecondaryDetailsOpen: secondaryInsuranceData?.insuranceId ? true : false,
      additionalTertiaryDetailsOpen: tertiaryInsuranceData?.insuranceId ? true : false,
      isPrimaryInsuranceDeleted: false,
      isSecondaryInsuranceDeleted: false,
      isTertiaryInsuranceDeleted: false
    }));
    setInsuranceData((prev) => ({
      ...prev,
      addSecondaryInsuranceOpen: secondaryInsuranceData?.insuranceId ? true : false,
      addTertiaryInsuranceOpen: tertiaryInsuranceData?.insuranceId ? true : false,
    }));
    setComponentState((prev) => ({
      ...prev,
      copayFieldsCount: 1,
    }));
  }

  const fetchImageData = () => {
    setComponentState((prev) => ({
      ...prev,
      isImageLoading: true
    }));
    getInsuranceCardImageElation(props?.patientId).then((response) => {
      const primaryFront = response?.data?.entry?.find((item: { resource: Resource }) => item?.resource?.id === INSURANCE_MEDIA_NUMBER.PRIMARY_FRONT)?.resource?.content?.data;
      const primaryBack = response?.data?.entry?.find((item: { resource: Resource }) => item?.resource?.id === INSURANCE_MEDIA_NUMBER.PRIMARY_BACK)?.resource?.content?.data;
      const secondaryFront = response?.data?.entry?.find((item: { resource: Resource }) => item?.resource?.id === INSURANCE_MEDIA_NUMBER.SECONDARY_FRONT)?.resource?.content?.data;
      const secondaryBack = response?.data?.entry?.find((item: { resource: Resource }) => item?.resource?.id === INSURANCE_MEDIA_NUMBER.SECONDARY_BACK)?.resource?.content?.data;
      setInsuranceData((prev) => ({
        ...prev,
        base64Image : {
          front: primaryFront,
          back: primaryBack
        }
      }));
      setSecondaryInsuranceData((prev) => ({
        ...prev,
        base64Image : {
          front: secondaryFront,
          back: secondaryBack
        }
      }));
    })
      .catch((err: any) => {
      });
  };
  useEffect(() => {
    ehrConfig.isElation && !props.addPatientView && fetchImageData();
  }, []);
  const onSave = () => {
    setComponentState((prev) => ({
      ...prev,
      additionalDetailsOpen: true,
      additionalSecondaryDetailsOpen: insuranceData?.addSecondaryInsuranceOpen ? true : false,
      additionalTertiaryDetailsOpen: insuranceData?.addTertiaryInsuranceOpen ? true : false,
    }));
    const error = validateInsuranceFields(insuranceData, ehrConfig.isAthena, secondaryInsuranceData, tertiaryInsuranceData,componentState.isPrimaryInsuranceDeleted,componentState.isSecondaryInsuranceDeleted,componentState.isTertiaryInsuranceDeleted, canPlanNameCreate);
    setErrors((prev) => ({
      ...prev,
      memberId: error?.errors?.memberId,
      groupId: error?.errors?.groupId,
      firstName: error?.errors?.firstName,
      lastName: error?.errors?.lastName,
      insuranceCarrierName: error?.errors?.insuranceCarrierName,
      planName: error?.errors?.planName,
      patientRealtionWithPolicyHolder:
        error?.errors?.patientRealtionWithPolicyHolder,
      sexAtBirth: error?.errors?.sexAtBirth
    }));
    setSecondaryErrors((prev) => ({
      ...prev,
      memberId: error?.secondaryErrors?.memberId,
      groupId: error?.secondaryErrors?.groupId,
      firstName: error?.secondaryErrors?.firstName,
      lastName: error?.secondaryErrors?.lastName,
      insuranceCarrierName: error?.secondaryErrors?.insuranceCarrierName,
      planName: error?.secondaryErrors?.planName,
      patientRealtionWithPolicyHolder:
        error?.secondaryErrors?.patientRealtionWithPolicyHolder,
      sexAtBirth: error?.secondaryErrors?.sexAtBirth
    }));
    setTertairyErrors((prev) => ({
      ...prev,
      memberId: error?.tertiaryErrors?.memberId,
      groupId: error?.tertiaryErrors?.groupId,
      firstName: error?.tertiaryErrors?.firstName,
      lastName: error?.tertiaryErrors?.lastName,
      insuranceCarrierName: error?.tertiaryErrors?.insuranceCarrierName,
      planName: error?.tertiaryErrors?.planName,
      patientRealtionWithPolicyHolder:
        error?.tertiaryErrors?.patientRealtionWithPolicyHolder,
      sexAtBirth: error?.tertiaryErrors?.sexAtBirth
    }));
    if (error.error !== '') {
      notification.error({
        message: error.error,
        duration: 3.0,
        placement: 'top',
      });
      return;
    } else if (!insuranceData?.insuranceCarrierName && !componentState.isPrimaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsg' }),
        duration: 3.0,
        placement: 'top',
      });
    } else if (insuranceData?.addSecondaryInsuranceOpen && !secondaryInsuranceData?.insuranceCarrierName && !componentState.isSecondaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsgSecondary' }),
        duration: 3.0,
        placement: 'top',
      });
    } else if (ehrConfig.isAthena &&  insuranceData?.addTertiaryInsuranceOpen && !tertiaryInsuranceData?.insuranceCarrierName && !componentState.isTertiaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsgTertiary' }),
        duration: 3.0,
        placement: 'top',
      });
    } else if (canPlanNameCreate && !insuranceData?.planName && !componentState.isPrimaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextPlanNameMsg' }),
        duration: 3.0,
        placement: 'top',
      });
    } else if (canPlanNameCreate && insuranceData?.addSecondaryInsuranceOpen && !secondaryInsuranceData?.planName && !componentState.isSecondaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextPlanNameMsgSecondary' }),
        duration: 3.0,
        placement: 'top',
      });
    } else if (canPlanNameCreate && ehrConfig.isAthena && insuranceData?.addTertiaryInsuranceOpen && !tertiaryInsuranceData?.planName && !componentState.isTertiaryInsuranceDeleted) {
      notification.error({
        message: intl.formatMessage({ id: 'freeTextPlanNameMsgTertairy' }),
        duration: 3.0,
        placement: 'top',
      });
    } else {
        ehrConfig.isElation
          ? postOrPutInsuranceDataElation()
            .then((response: any) => {
              onSaveOrCancelStateAction();
              fetchFieldData();
              fetchImageData();
              showNotification('insuranceAddSuccessMsg');
            })
            .catch((err: any) => {

            })
          : postOrPutInsuranceDataAthena ()
            .then((response: any) => {
              onSaveOrCancelStateAction();
              fetchFieldData();
              showNotification('insuranceAddSuccessMsg');
            })
            .catch((err: any) => {

              if (err?.response && err?.response?.data) {
                let error;
                const parsedMessage = err?.response?.data?.extensions;
                const errorMessage = JSON.parse(parsedMessage?.detailedError[0]?.details?.text)
                if(errorMessage.error === intl.formatMessage({id: 'insuranceExpiredMsg'})){
                   error = intl.formatMessage({id: 'insuranceCancelledOrExpiredMsg'})
                }
                else{
                  error = errorMessage.error
                }
                notification.destroy();
                notification.error({
                  message: error,
                  duration: 3.0,
                  placement: 'top'
                });
              } else {
                notification.destroy();
                notification.error({
                  message: 'Something went wrong',
                  duration: 3.0,
                  placement: 'top'
                });
              }
            });
      }
  };

  const postOrPutInsuranceDataAthena = async () => {
    setComponentState((prev) => ({
      ...prev,
      isSaveButtonLoading:true
    }))
    let secondaryRequestBodyUpdate, secondaryRequestBodyAdd, tertiaryRequestBodyAdd, tertiaryRequestBodyUpdate;
       const primaryRequestBodyAdd = await handleAddData(
        insuranceData,
        componentState.copayDetails,
        props?.patientId,
        insuranceData?.planNameDataArray || [],
        1
      )
      if (insuranceData?.addSecondaryInsuranceOpen) {
         secondaryRequestBodyAdd = await handleAddData(
          secondaryInsuranceData,
          componentState.copayDetails,
          props?.patientId,
          insuranceData?.secondaryPlanNameDataArray || [],
          2
        )
      }
      if (insuranceData?.addTertiaryInsuranceOpen) {
         tertiaryRequestBodyAdd = await handleAddData(
          tertiaryInsuranceData,
          componentState.copayDetails,
          props?.patientId,
          insuranceData?.tertiaryPlanNameDataArray || [],
          3
        )
      }
       const primaryRequestBodyUpdate = await handleUpdate(
        insuranceData,
        componentState.copayDetails,
        props?.patientId,
        insuranceData?.planNameDataArray || [],
        1
      )
      if (insuranceData?.addSecondaryInsuranceOpen) {
         secondaryRequestBodyUpdate = await handleUpdate(
          secondaryInsuranceData,
          componentState.copayDetails,
          props?.patientId,
          insuranceData?.secondaryPlanNameDataArray || [],
          2
        )
      }
      if (insuranceData?.addTertiaryInsuranceOpen) {
         tertiaryRequestBodyUpdate = await handleUpdate(
          tertiaryInsuranceData,
          componentState.copayDetails,
          props?.patientId,
          insuranceData?.tertiaryPlanNameDataArray || [],
          3
        )
      }
    try {
      (!componentState.isPrimaryInsuranceDeleted || !isInsuranceStateEmpty(insuranceData)) && (insuranceData?.insuranceId ?
        await putInsuranceFielddata(primaryRequestBodyUpdate, insuranceData.insuranceId) :
        await postInsuranceFieldData(primaryRequestBodyAdd));

        (!componentState.isSecondaryInsuranceDeleted || !isInsuranceStateEmpty(secondaryInsuranceData)) &&  (insuranceData?.addSecondaryInsuranceOpen && (secondaryInsuranceData?.insuranceId ?
        await putInsuranceFielddata(secondaryRequestBodyUpdate, secondaryInsuranceData.insuranceId) :
         await postInsuranceFieldData(secondaryRequestBodyAdd)));

        (!componentState.isTertiaryInsuranceDeleted || !isInsuranceStateEmpty(tertiaryInsuranceData)) && (insuranceData?.addTertiaryInsuranceOpen && (tertiaryInsuranceData?.insuranceId ?
        await putInsuranceFielddata(tertiaryRequestBodyUpdate, tertiaryInsuranceData.insuranceId) :
        postInsuranceFieldData(tertiaryRequestBodyAdd)));

    } catch (err: any) {
        throw err;
    }
    finally {
      setComponentState((prev) => ({
        ...prev,
        isSaveButtonLoading: false
      }));
    }
  }

  const postOrPutInsuranceDataElation = async () => {
    setComponentState((prev) => ({
      ...prev,
      isSaveButtonLoading:true
    }))
    let  secondaryRequestBodyUpdate, secondaryRequestBodyAdd ;
    const primaryRequestBodyAdd: any = await handleAddDataElation(
      insuranceData,
      props?.patientId || '',
      insuranceData?.planNameDataArray || [],
      1
    )
    if (insuranceData?.addSecondaryInsuranceOpen) {
      secondaryRequestBodyAdd = await handleAddDataElation(
        secondaryInsuranceData,
        props?.patientId || '',
        insuranceData?.secondaryPlanNameDataArray || [],
        2
      )
    }
    const primaryRequestBodyUpdate = await handleUpdateElation(
      insuranceData,
      props?.patientId || '',
      insuranceData?.planNameDataArray || [],
      1
    )
    if (insuranceData?.addSecondaryInsuranceOpen) {
      secondaryRequestBodyUpdate = await handleUpdateElation(
        secondaryInsuranceData,
        props?.patientId || '',
        insuranceData?.secondaryPlanNameDataArray || [],
        2
      )
    }

    try {

      const addOrUpdatePrimary = (!componentState.isPrimaryInsuranceDeleted || !isInsuranceStateEmpty(insuranceData)) && (insuranceData?.insuranceId ?
        await putInsuranceFielddata(primaryRequestBodyUpdate, insuranceData?.insuranceId || '') :
        await postInsuranceFieldData(primaryRequestBodyAdd));

      const addOrUpdateSecondary = (!componentState.isSecondaryInsuranceDeleted || !isInsuranceStateEmpty(insuranceData)) && (insuranceData?.addSecondaryInsuranceOpen && (secondaryInsuranceData?.insuranceId ?
        await putInsuranceFielddata(secondaryRequestBodyUpdate, secondaryInsuranceData?.insuranceId || '') :
        await postInsuranceFieldData(secondaryRequestBodyAdd)));

    } catch (err: any) {

      showNotification(err);
    }
    finally {
      setComponentState((prev) => ({
        ...prev,
        isSaveButtonLoading: false
      }));
    }
  }


  const addSecondaryInsurance = () => {
    return setInsuranceData((prev) => ({
      ...prev,
      addSecondaryInsuranceOpen: true,
    }));
  }

  const addTertiaryInsurance = () => {
    return setInsuranceData((prev) => ({
      ...prev,
      addTertiaryInsuranceOpen: true,
    }));
  }

  const handleCopayTypeChange = (itemValue: any, index: any) => {
    const updatedCopayDetails = [...componentState.copayDetails];
    updatedCopayDetails[index] = {
      ...updatedCopayDetails[index],
      type: itemValue,
    };
    setComponentState((prev) => ({
      ...prev,
      copayDetails: updatedCopayDetails,
    }));
    props?.setInsuranceDataForRequestBody?.({
      ...insuranceData,
      copayDetailsArray: updatedCopayDetails,
    });
  };

  const handleCopayAmountChange = (value: any, index: any) => {
    const updatedCopayDetails = [...componentState.copayDetails];
    updatedCopayDetails[index] = {
      ...updatedCopayDetails[index],
      amount: value,
    };
    setComponentState((prev) => ({
      ...prev,
      copayDetails: updatedCopayDetails,
    }));
    props?.setInsuranceDataForRequestBody?.({
      ...insuranceData,
      copayDetailsArray: updatedCopayDetails,
    });
  };

  const addCopayFields = () => {
    setComponentState((prev) => ({
      ...prev,
      copayFieldsCount: componentState.copayFieldsCount + 1,
    }));
  };

  const handleSecondaryDeleteAction = (value: boolean) => {
    setInsuranceData((prev) => ({
      ...prev,
      addSecondaryInsuranceOpen: value,
      addTertiaryInsuranceOpen: tertiaryInsuranceData?.insuranceId ? true : false
    }));
  }

  const handleTertiaryDeleteAction = (value: boolean) => {
    setInsuranceData((prev) => ({
      ...prev,
      addTertiaryInsuranceOpen: value,
    }));
  }

  const renderCopayField = () => {
    const copayFields = [];
    for (let i = 0; i < componentState.copayFieldsCount; i++) {
      copayFields.push(
        <HStack key={i} space={10} mt={4}>
          <ModalActionSelect
            isDisabled={true}
            label={'copayAmountType'}
            selectedValue={componentState.copayDetails[i]?.type || ''}
            placeholder={'Select Copay Amount Type'}
            endIcon={<CheckIcon size="5" />}
            onValueChange={(itemValue: any) => {
              handleCopayTypeChange(itemValue, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmountType: itemValue,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmountType: itemValue,
              });
            }}
            data={componentState.copayAmountType}
            selectItemProps={{
              key: 'code',
              label: 'display',
              value: 'code',
            }}
            customStyle={{
              flex: 1,
            }}
          />
          <ModalActionInput
            isDisabled={true}
            fieldIs={'input'}
            label={'copayAmount'}
            placeholder={'Enter Copay Amount'}
            value={componentState.copayDetails[i]?.amount ? `$${componentState.copayDetails[i]?.amount}` : ''}
            onChangeText={(value: any) => {
              handleCopayAmountChange(value, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmount: value,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmount: value,
              });
            }}
            extraStyle={{
              flex: 1.0,
            }}
            notAllowEmoji={true}
          />
        </HStack>
      );
    }
    return copayFields;
  };

  const renderCopayType = () => {
    return (
      <>
        {renderCopayField()}
        <HStack
          justifyContent={'flex-start'}
          display={'flex'}
          flex={1}
          marginTop={4}
          alignItems={'center'}
        >
          <Pressable onPress={addCopayFields}>
            <HStack alignItems={'center'}>
              <AdddetailsIconSvg />
              <Text color={Colors.Custom.mainPrimaryPurple}>
                {intl.formatMessage({ id: 'addCopayType' })}
              </Text>
            </HStack>
          </Pressable>
        </HStack>
      </>
    );
  };

  const renderSecondaryCopayField = () => {
    const copayFields = [];
    for (let i = 0; i < componentState.copayFieldsCount; i++) {
      copayFields.push(
        <HStack key={i} space={10} mt={4}>
          <ModalActionSelect
            isDisabled={true}
            label={'copayAmountType'}
            selectedValue={componentState.copayDetails[i]?.type || ''}
            placeholder={'Select Copay Amount Type'}
            endIcon={<CheckIcon size="5" />}
            onValueChange={(itemValue: any) => {
              handleCopayTypeChange(itemValue, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmountType: itemValue,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmountType: itemValue,
              });
            }}
            data={componentState.copayAmountType}
            selectItemProps={{
              key: 'code',
              label: 'display',
              value: 'code',
            }}
            customStyle={{
              flex: 1,
            }}
          />
          <ModalActionInput
            isDisabled={true}
            fieldIs={'input'}
            label={'copayAmount'}
            placeholder={'Enter Copay Amount'}
            value={componentState.copayDetails[i]?.amount ? `$${componentState.copayDetails[i]?.amount}` : ''}
            onChangeText={(value: any) => {
              handleCopayAmountChange(value, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmount: value,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmount: value,
              });
            }}
            extraStyle={{
              flex: 1.0,
            }}
            notAllowEmoji={true}
          />
        </HStack>
      );
    }
    return copayFields;
  };

  const renderSecondaryCopayType = () => {
    return (
      <>
        {renderSecondaryCopayField()}
        <HStack
          justifyContent={'flex-start'}
          display={'flex'}
          flex={1}
          marginTop={4}
          alignItems={'center'}
        >
          <Pressable onPress={addCopayFields}>
            <HStack alignItems={'center'}>
              <AdddetailsIconSvg />
              <Text color={Colors.Custom.mainPrimaryPurple}>
                {intl.formatMessage({ id: 'addCopayType' })}
              </Text>
            </HStack>
          </Pressable>
        </HStack>
      </>
    );
  };

  const renderTertiaryCopayField = () => {
    const copayFields = [];
    for (let i = 0; i < componentState.copayFieldsCount; i++) {
      copayFields.push(
        <HStack key={i} space={10} mt={4}>
          <ModalActionSelect
            isDisabled={true}
            label={'copayAmountType'}
            selectedValue={componentState.copayDetails[i]?.type || ''}
            placeholder={'Select Copay Amount Type'}
            endIcon={<CheckIcon size="5" />}
            onValueChange={(itemValue: any) => {
              handleCopayTypeChange(itemValue, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmountType: itemValue,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmountType: itemValue,
              });
            }}
            data={componentState.copayAmountType}
            selectItemProps={{
              key: 'code',
              label: 'display',
              value: 'code',
            }}
            customStyle={{
              flex: 1,
            }}
          />
          <ModalActionInput
            isDisabled={true}
            fieldIs={'input'}
            label={'copayAmount'}
            placeholder={'Enter Copay Amount'}
            value={componentState.copayDetails[i]?.amount ? `$${componentState.copayDetails[i]?.amount}` : ''}
            onChangeText={(value: any) => {
              handleCopayAmountChange(value, i);
              setInsuranceData((prev) => ({
                ...prev,
                copayAmount: value,
              }));
              props?.setInsuranceDataForRequestBody?.({
                ...insuranceData,
                copayAmount: value,
              });
            }}
            extraStyle={{
              flex: 1.0,
            }}
            notAllowEmoji={true}
          />
        </HStack>
      );
    }
    return copayFields;
  };


  const endDate = insuranceData?.endDate
    ? new Date(insuranceData?.endDate)
    : null;
  const currentDate = new Date();
  const currentDateMoment = getMomentObjectWithDateStringAndFormat(
    new Date().toISOString(),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  const isInsuranceExpired = endDate ? endDate <= currentDate : false;
  const activePlanName = insuranceData?.planName || secondaryInsuranceData?.planName || tertiaryInsuranceData?.planName;
  const activeInsuranceCarrierName = insuranceData?.insuranceCarrierName || secondaryInsuranceData?.insuranceCarrierName || tertiaryInsuranceData?.insuranceCarrierName;
  const insuranceCardPlanName = isElation ? activePlanName : activeInsuranceCarrierName;

  const renderInsuranceCard = () => {
    return (
      <>
        {(insuranceCardName) || !!insuranceData?.lastChecked ? (
          componentState.isInsuranceDataLoading ?
            <View
              style={[
                {
                  height: 100,
                  backgroundColor: '#ffffff',
                  marginHorizontal: 24,
                  borderRadius: 8,
                  padding: 16,
                  marginBottom: 50,
                },
              ]}>
              <Skeleton.Text lines={2} />
            </View> :
            (
              <Pressable
                onPress={() => {
                  (insuranceData?.insuranceId || secondaryInsuranceData?.insuranceId || tertiaryInsuranceData?.insuranceId) && setComponentState((prev) => ({
                    ...prev,
                    isOpen: true,
                  }));
                }}
              >
                <VStack
                  borderWidth={1}
                  borderColor={Colors.Custom.Gray200}
                  style={[
                    {
                      backgroundColor: '#ffffff',
                      marginHorizontal: 24,
                      borderRadius: 8,
                      padding: 16,
                      marginBottom: 50,
                    },
                  ]}
                >
                <VStack marginLeft={2}>
                  <HStack marginBottom={1} alignItems="flex-start">
                    <View style={{ marginTop: 4 }}>
                      {isInsuranceExpired ? (
                        <InsuranceCardExpireSvg />
                      ) : (
                        <InsuranceCardNotExpireSvg />
                      )}
                    </View>
                    <Text
                      style={{
                        fontWeight: '400',
                        fontSize: 16,
                        marginLeft: 6,
                        color: isInsuranceExpired
                          ? Colors.Custom.crossIconColor
                          : Colors.Custom.SuccessColor,
                      }}
                    >{`${insuranceCardName}`}</Text>
                  </HStack>
              {(insuranceData?.lastChecked || secondaryInsuranceData?.lastChecked || tertiaryInsuranceData?.lastChecked) && <HStack alignItems="center">
                <Text
                  style={{
                    color: Colors.Custom.Gray400,
                    fontWeight: '400',
                    fontSize: 14,
                  }}
                >{`Last Checked: ${(insuranceData?.insuranceId && insuranceData?.lastChecked) || (secondaryInsuranceData?.insuranceId && secondaryInsuranceData?.lastChecked) || (tertiaryInsuranceData?.insuranceId && tertiaryInsuranceData?.lastChecked)}`}</Text>
                <View style={{ flex: 1 }} />
                <Pressable onPress={fetchFieldData}>
                  <InsuranceCardPlanNameSvg />
                </Pressable>
              </HStack>
              }
            </VStack>
          </VStack>
          </Pressable>)
        ) : (
          <VStack padding={4} justifyContent={'center'} alignItems={'center'}>
                {componentState.isInsuranceDataLoading ?
                <View
                  style={[
                    {
                      height: 100,
                      width: 450,
                      backgroundColor: '#ffffff',
                      marginHorizontal: 24,
                      borderRadius: 8,
                      padding: 16,
                      marginBottom: 50,
                    },
                  ]}>
                  <Skeleton.Text lines={2} />
                </View> :
                <View
                  alignItems={'center'}
                  style={[
                    {
                      height: 100,
                      width: 450,
                      backgroundColor: '#ffffff',
                      marginHorizontal: 24,
                      borderRadius: 8,
                      padding: 16,
                      marginBottom: 50,
                    },
                  ]}>
                  <InsuranceCardEmptyStateSvg />
                </View>
                }
          </VStack>
        )}
      </>

    );
  };

  return (
    <>
      {props?.addPatientView ? (
        <>
        <View>
          <AddOrUpdateInsuranceView
            base64FrontData={insuranceData?.base64Image?.front}
            base64BackData={insuranceData?.base64Image?.back}
            setInsuranceDataForRequestBody={props?.setInsuranceDataForRequestBody}
            addPatientView={props?.addPatientView}
            patientId={props?.patientId}
            errors={errors}
            insuranceData={insuranceData}
            insuranceNumber={INSURANCE_NUMBER.PRIMARY}
            componentState={componentState}
            setComponentState={setComponentState}
            setInsuranceData={setInsuranceData}
            setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
            setIsPlanNameFreeText={setIsPlanNameFreeText}
          />
        </View>
          <View>
            <View>
              <Pressable style={{
                display: (allowInsurance) && !insuranceData?.addSecondaryInsuranceOpen ? 'flex' : 'none'
              }}
                onPress={addSecondaryInsurance}>
                <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                  <AdddetailsIconSvg />
                  <Text color={Colors.Custom.mainPrimaryPurple}>{`Add Secondary Insurance`}</Text>
                </HStack>
              </Pressable>
              {((allowInsurance) && insuranceData?.addSecondaryInsuranceOpen) && (
                <AddOrUpdateInsuranceView
                  base64FrontData={secondaryInsuranceData?.base64Image?.front}
                  base64BackData={secondaryInsuranceData?.base64Image?.back}
                  setSecInsuranceDataForRequestBody={props?.setSecInsuranceDataForRequestBody}
                  addPatientView={props?.addPatientView}
                  patientId={props?.patientId}
                  errors={secondaryErrors}
                  handleSecondaryDeleteAction={handleSecondaryDeleteAction}
                  addSecondaryInsuranceOpen={insuranceData?.addSecondaryInsuranceOpen}
                  secondaryInsuranceData={secondaryInsuranceData}
                  insuranceNumber={INSURANCE_NUMBER.SECONDARY}
                  componentState={componentState}
                  setComponentState={setComponentState}
                  setSecondaryInsuranceData={setSecondaryInsuranceData}
                  setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
                  setIsPlanNameFreeText={setIsPlanNameFreeText}
                />
              )}
            </View>
          </View>
          <View>
            <Pressable style={{
              display: (ehrConfig.isAthena) && !insuranceData?.addTertiaryInsuranceOpen && insuranceData?.addSecondaryInsuranceOpen ? 'flex' : 'none'
            }}
              onPress={addTertiaryInsurance}>
              <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                <AdddetailsIconSvg />
                <Text color={Colors.Custom.mainPrimaryPurple}>{`Add Tertiary Insurance`}</Text>
              </HStack>
            </Pressable>
            {ehrConfig.isAthena && insuranceData?.addTertiaryInsuranceOpen && insuranceData?.addSecondaryInsuranceOpen && (
              <AddOrUpdateInsuranceView
                base64FrontData={tertiaryInsuranceData?.base64Image?.front}
                base64BackData={tertiaryInsuranceData?.base64Image?.back}
                setTerInsuranceDataForRequestBody={props?.setTerInsuranceDataForRequestBody}
                addPatientView={props?.addPatientView}
                patientId={props?.patientId}
                errors={tertiaryErrors}
                tertiaryInsuranceData={tertiaryInsuranceData}
                handleTertiaryDeleteAction={handleTertiaryDeleteAction}
                insuranceNumber={INSURANCE_NUMBER.TERTIARY}
                addSecondaryInsuranceOpen={insuranceData?.addSecondaryInsuranceOpen}
                addTertiaryInsuranceOpen={insuranceData?.addTertiaryInsuranceOpen}
                componentState={componentState}
                setComponentState={setComponentState}
                setTertiaryInsuranceData={setTertiaryInsuranceData}
                setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
                setIsPlanNameFreeText={setIsPlanNameFreeText}
              />
            )}
          </View>
        </>
      ) : (
        <>
          {componentState.isOpen && <Drawer
            placement="right"
            open={componentState.isOpen}
            closable
            width={
                isIPadScreen || isIPadMiniScreen
                  ? '75%'
                  : 736
            }
            title={
              <ModalActionTitle
                title={'addInsurance'}
                titleColor={''}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'cancel',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      onCancelAction();
                    },
                  },
                  {
                    show: true,
                    id: 3,
                    btnText: 'save',
                    textColor: Colors.Custom.mainPrimaryPurple,
                    variant: BUTTON_TYPE.PRIMARY,
                    isLoading: componentState.isSaveButtonLoading,
                    isTransBtn: false,
                    onClick: () => {
                      onSave();
                    },
                  },
                ]}
              />
            }
          >
            {componentState.isLoading ? (
              <>
                <Spinner />
              </>
            ) : (
              <View>
                <>
                  <ScrollView>
                    <VStack ml={0} mr={0}>
                      <VStack>
                        <View>
                        <AddOrUpdateInsuranceView
                          base64FrontData={insuranceData?.base64Image?.front}
                          base64BackData={insuranceData?.base64Image?.back}
                          setInsuranceDataForRequestBody={props?.setInsuranceDataForRequestBody}
                          addPatientView={props?.addPatientView}
                          patientId={props?.patientId}
                          errors={errors}
                          insuranceData={insuranceData}
                          insuranceNumber={INSURANCE_NUMBER.PRIMARY}
                          componentState={componentState}
                          setComponentState={setComponentState}
                          setInsuranceData={setInsuranceData}
                          setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
                          setIsPlanNameFreeText={setIsPlanNameFreeText}
                        />
                        </View>

                        <View>
                          <View>
                            <Pressable style={{
                              display: (allowInsurance) && !insuranceData?.addSecondaryInsuranceOpen ? 'flex' : 'none'
                            }}
                              onPress={addSecondaryInsurance}>
                              <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                                <AdddetailsIconSvg />
                                <Text color={Colors.Custom.mainPrimaryPurple}>{`Add Secondary Insurance`}</Text>
                              </HStack>
                            </Pressable>
                            {((allowInsurance) && insuranceData?.addSecondaryInsuranceOpen) && (
                              <AddOrUpdateInsuranceView
                                base64FrontData={secondaryInsuranceData?.base64Image?.front}
                                base64BackData={secondaryInsuranceData?.base64Image?.back}
                                setSecInsuranceDataForRequestBody={props?.setSecInsuranceDataForRequestBody}
                                addPatientView={props?.addPatientView}
                                patientId={props?.patientId}
                                errors={secondaryErrors}
                                handleSecondaryDeleteAction={handleSecondaryDeleteAction}
                                addSecondaryInsuranceOpen={insuranceData?.addSecondaryInsuranceOpen}
                                secondaryInsuranceData={secondaryInsuranceData}
                                insuranceNumber={INSURANCE_NUMBER.SECONDARY}
                                componentState={componentState}
                                setComponentState={setComponentState}
                                setSecondaryInsuranceData={setSecondaryInsuranceData}
                                setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
                                setIsPlanNameFreeText={setIsPlanNameFreeText}
                              />
                            )}
                          </View>
                        </View>
                        <View>
                          <Pressable style={{
                            display: (ehrConfig.isAthena) && !insuranceData?.addTertiaryInsuranceOpen && insuranceData?.addSecondaryInsuranceOpen ? 'flex' : 'none'
                          }}
                            onPress={addTertiaryInsurance}>
                            <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                              <AdddetailsIconSvg />
                              <Text color={Colors.Custom.mainPrimaryPurple}>{`Add Tertiary Insurance`}</Text>
                            </HStack>
                          </Pressable>
                          {ehrConfig.isAthena && insuranceData?.addTertiaryInsuranceOpen && insuranceData?.addSecondaryInsuranceOpen && (
                            <AddOrUpdateInsuranceView
                              base64FrontData={tertiaryInsuranceData?.base64Image?.front}
                              base64BackData={tertiaryInsuranceData?.base64Image?.back}
                              setTerInsuranceDataForRequestBody={props?.setTerInsuranceDataForRequestBody}
                              addPatientView={props?.addPatientView}
                              patientId={props?.patientId}
                              errors={tertiaryErrors}
                              addTertiaryInsuranceOpen={insuranceData?.addTertiaryInsuranceOpen}
                              addSecondaryInsuranceOpen={insuranceData?.addSecondaryInsuranceOpen}
                              handleTertiaryDeleteAction={handleTertiaryDeleteAction}
                              tertiaryInsuranceData={tertiaryInsuranceData}
                              insuranceNumber={INSURANCE_NUMBER.TERTIARY}
                              componentState={componentState}
                              setComponentState={setComponentState}
                              setTertiaryInsuranceData={setTertiaryInsuranceData}
                              setIsInsuranceCarrierNameFreeText={setIsInsuranceCarrierNameFreeText}
                              setIsPlanNameFreeText={setIsPlanNameFreeText}
                            />
                          )}
                        </View>
                      </VStack>
                    </VStack>
                  </ScrollView>
                </>
              </View>
            )}
          </Drawer>
          }
          <View>
            <View
              style={{
                backgroundColor: Colors.Custom.Gray50,
              }}
            >
              <View style={{ padding: 16 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 9,
                    }}
                  >
                    <View style={{ marginRight: 10 }}></View>
                    <DisplayText size={'xlBold'} textLocalId={'insurance'} />
                  </View>
                  <Tooltip title={'View/Update Insurance Details'} placement={'top'}>
                    <Pressable
                      onPress={() => {
                        setComponentState((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                    >
                      {componentState.isInsuranceDataLoading ? <Spinner/> : <AddActionView />}
                    </Pressable>
                  </Tooltip>
                </View>
              </View>
              {!props?.addPatientView && renderInsuranceCard()}
              <View flex={1} style={{ paddingTop: 2, paddingBottom: 2 }}></View>
            </View>
          </View>
        </>
      )}
    </>
  );
};

export default InsuranceView;
