export const DefaultSlotGroupTime = {
  Morning: {
    StartTime: {
      HOURS: 0,
      MINUTES: 0,
    },
    EndTime: {
      HOURS: 12,
      MINUTES: 59,
    },
  },
  AfterNoon: {
    StartTime: {
      HOURS: 12,
      MINUTES: 0,
    },
    EndTime: {
      HOURS: 16,
      MINUTES: 59,
    },
  },
  Evening: {
    StartTime: {
      HOURS: 17,
      MINUTES: 0,
    },
    EndTime: {
      HOURS: 23,
      MINUTES: 59,
    },
  },
};

export enum AppointmentSlotGroup {
  morning = 'Morning',
  afterNoon = 'Afternoon',
  evening = 'Evening',
}

export const ONE_OFF_VISIT_APPT_DURATION = 30;
export const ONE_OFF_VISIT_APPT_CODE = 'ONE_OFF_VISIT';

export const APPOINTMENT_BOOKING_VIEW_CODES = {
  BOOKING_VIEW: 'BOOKING',
  PREVIEW_VIEW: 'PREVIEW_VIEW',
  SUMMARY_VIEW: 'SUMMARY_VIEW',
};

export const USER_SELECTION_CODE = {
  ALL_USER: 'ALL_USER',
  CUSTOM: 'CUSTOM',
}

export const MAX_FUTURE_DATE_RANGE = 90;

export const APPOINTMENT_TYPE_DATA_TYPE_CODES = {
  DURATION: 'DURATION',
  DISPLAY_CONFIG: 'DISPLAY_CONFIG',
}