import {
  HStack,
  VStack,
  View,
  Text,
  Pressable,
  Icon,
  useToast,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Colors} from '../../../styles/Colors';
import {Radio, Switch} from 'antd';
import {getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import BaseService from '../../../services/CommonService/BaseService';
import ArrowUpsvg from '../Svg/ConversationAllSvg/ArrowUpsvg';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import { NOTIFICATION_SETTING } from '../../../constants';

export const NotificationOptions = (props: any) => {
  const {iconMap, content, index, newData, onActionPerformed, onLoading} = props;
  const toast = useToast();
  const intl = useIntl();
  const bussinessHours: any = {
    BOTH: 'All Time (During and after office hours)',
    IN: 'Only during office hours',
    OUT: 'Only after office hours '
  };
  const [state, setState] = useState({
    isShowOption: false,
    switchValue: false,
    notificationData: content,
    radioValue: '',
    label: '',
  });
  const accountId = getAccountUUID();
  const userUUID = getUserUUID();

  const updateNotificationSettingApi = async (
    crmNotificationSettingId: string,
    id: string,
    userId: string,
    filterValue: string,
    pushValue: boolean,
    accountUUID: string,
    createdBy: string,
    updatedBy: string
  ) => {
    const baseService = BaseService.getSharedInstance().axios;
    const body = {
      userNotificationSettings: [
        {
          crmNotificationSettingId: crmNotificationSettingId,
          userId: userId,
          userNotificationSettingId: id,
          filterValue: {
            BUSINESS_HOURS: filterValue,
          },
          pushValue: pushValue,
          accountUuid: accountUUID,
          createdBy: createdBy,
          updatedBy: updatedBy,
        },
      ],
    };
    return await baseService.post('crm-nest/api/v1/notification-setting', {
      ...body,
    });
  };

  const onSwitchChange = async (
    crmNotificationSettingId: any,
    id: any,
    pushValue: boolean,
    filterValue?: any,
    isRadioButton?: boolean
  ) => {
    onLoading(true)
    const prevFilterValue = state?.radioValue
    try {
      if (!isRadioButton) {
        setState((prev) => {
          return {
            ...prev,
            switchValue: !prev.switchValue,
          };
        });
      }
      else{
        setState((prev) => {
          return {
            ...prev,
            radioValue: filterValue
          };
        });
      }

      const result = await updateNotificationSettingApi(
        crmNotificationSettingId,
        id,
        userUUID,
        filterValue,
        pushValue,
        accountId,
        userUUID,
        userUUID
      );
      const item = result?.data?.userNotificationSettings[0];
      const data = content?.userNotificationSettings;
      if (
        data?.length > 0 &&
        data[0]?.crmNotificationSettingId === item?.crmNotificationSettingId
      ) {
        content.userNotificationSettings[0].pushValue = item?.pushValue;
        content.userNotificationSettings[0].filterValue = item?.filterValue;
      } else if (data?.length === 0) {
        data.push({
          crmNotificationSettingId: item.crmNotificationSettingId,
          id: item.userNotificationSettingId,
          filterValue: item.filterValue,
          pushValue: item.pushValue,
          userId: item.userId,
        });
      }
      onLoading(false)
      onActionPerformed(false)
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          switchValue: isRadioButton ? prev.switchValue : !prev.switchValue,
          radioValue: isRadioButton ? filterValue === prevFilterValue : filterValue,
        };
      });
      onLoading(false)
      showToast(
        toast,
        intl.formatMessage({id: 'notificationSettingApiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const isCheckValue = () => {
    let isCheck = false;
    if (state?.notificationData?.userNotificationSettings?.length > 0) {
      isCheck = state?.notificationData?.userNotificationSettings[0]?.pushValue;
    } else {
      isCheck = state?.notificationData?.pushDefaultValue;
    }
    setState((prev) => {
      return {
        ...prev,
        switchValue: isCheck,
      };
    });
  };

  useEffect(() => {
    isCheckValue();
    setState((prev) => {
      return {
        ...prev,
        radioValue:
          state?.notificationData?.userNotificationSettings[0]?.filterValue
            ?.BUSINESS_HOURS || state?.notificationData?.filter?.BUSINESS_HOURS,
      };
    });
  }, []);

  return (
    <VStack paddingLeft={3}>
      <HStack>
        <View
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={2}
          style={{width: 40, height: 40}}
        >
          {iconMap[state?.notificationData.actionCode]}
        </View>
        <VStack
          flexDirection={'column'}
          flex={1}
          paddingY={1.5}
          paddingLeft={1}
          borderBottomWidth={index === newData.length - 1 ? 0 : 1}
          borderBottomColor={Colors.Custom.Gray200}
        >
          <HStack flex={1}>
            <View>
              <Text
                fontSize={16}
                fontWeight={500}
                lineHeight={27}
                color={Colors.Custom.Gray500}
                paddingRight={1}
              >
                {state?.notificationData.description}
              </Text>
              <HStack>
                <Pressable
                  flexDirection={'row'}
                  flex={1}
                  onPress={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isShowOption: !prev.isShowOption,
                      };
                    });
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={21}
                    color={Colors.Custom.Gray500}
                    paddingRight={1}
                  >
                    {bussinessHours[state.radioValue]}
                  </Text>
                  {state.switchValue ? (
                    <View
                      style={{
                        transform: [
                          {
                            rotate:
                              state.isShowOption && state.switchValue
                                ? '180deg'
                                : '0deg',
                          },
                        ],
                      }}
                    >
                      <ArrowUpsvg />
                    </View>
                  ) : (
                    <></>
                  )}
                </Pressable>
              </HStack>
            </View>
            <HStack flex={1} justifyContent={'flex-end'}>
              <Switch
                style={{
                  alignSelf: 'center',
                  backgroundColor: state.switchValue
                    ? Colors.primary[300]
                    : Colors.Custom.Gray200,
                }}
                checked={state.switchValue}
                onChange={(value) => {
                  onSwitchChange(
                    state?.notificationData.id,
                    state?.notificationData?.userNotificationSettings[0]?.id,
                    value,
                    state?.radioValue,
                    false
                  );
                }}
              />
            </HStack>
          </HStack>
          {state.isShowOption && state.switchValue && (
            <VStack paddingY={1.5} width={'100%'}>
              <Radio.Group
                name="NotificationOption"
                value={state.radioValue}
                onChange={(item) => {
                  onSwitchChange(
                    state?.notificationData.id,
                    state?.notificationData?.userNotificationSettings[0]?.id,
                    true,
                    item?.target?.value,
                    true
                  );
                }}
              >
                <VStack>
                  <Radio value={NOTIFICATION_SETTING.IN} style={{paddingBottom: 6, paddingTop: 6}}>
                    <Text
                      fontSize={16}
                      lineHeight={24}
                      color={Colors.Custom.Gray500}
                    >
                      {bussinessHours[NOTIFICATION_SETTING.IN]}
                    </Text>
                  </Radio>
                </VStack>
                <VStack>
                  <Radio
                    style={{paddingBottom: 6, paddingTop: 6}}
                    value={NOTIFICATION_SETTING.BOTH}
                  >
                    <Text
                      fontSize={16}
                      lineHeight={24}
                      color={Colors.Custom.Gray500}
                    >
                      {bussinessHours[NOTIFICATION_SETTING.BOTH]}
                    </Text>
                  </Radio>
                </VStack>
                <VStack>
                  <Radio
                    style={{paddingBottom: 6, paddingTop: 6}}
                    value={NOTIFICATION_SETTING.OUT}
                  >
                    <Text
                      fontSize={16}
                      lineHeight={24}
                      color={Colors.Custom.Gray500}
                    >
                      {bussinessHours[NOTIFICATION_SETTING.OUT]}
                    </Text>
                  </Radio>
                </VStack>
              </Radio.Group>
            </VStack>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};
