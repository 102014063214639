//import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Image,
  Input,
  ScrollView,
  Select,
  Text,
  VStack,
  TextArea,
  Icon,
  View,
  Skeleton
} from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, POPULATION_GROUP_TYPES } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import PatientDropdownList from '../../PatientDropdownList';
import { CampaignSegmentTableContext, ICampaignErrors, ICampaignSegmentFormState, IPopulationGroupItem } from '../interfaces';
import { RuleEngineInputField } from '../RuleEngineInputField';
import { styles } from './CampaignSegmentFormStyles';
import { FilterType, FrequencyType, ICampaignSegmentFormProps } from './interfaces';
import { getAccountUUID, isLeadGroupsPage } from '../../../../../utils/commonUtils';
import { getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { Select as AntdSelect, Spin, Tooltip } from 'antd';
import { isStaticGroup } from '../Helper';
import { getCampaignSegmentRules, getGroupsList, getLeadGroups } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { debounce } from 'lodash';
import { IMembersDatas } from '../../../MembersManagement/RulesInterface';

export const memberStatusOptions = [
  {
    code: 'active',
    value: 'Active'
  },
  {
    code: 'inactive',
    value: 'Inactive'
  }
]

export const frequencyOptions = [
  {
    code: '1d',
    value: '1 day'
  },
  {
    code: '1w',
    value: '1 week'
  },
  {
    code: '1M',
    value: '1 month'
  }
]

const CampaignSegmentForm = (props: ICampaignSegmentFormProps) => {
  const {initData, isPatientGroup} = props
  const context = props?.context || CampaignSegmentTableContext.POPULATION_GROUP;
  const accountUUID = getAccountUUID();
  const [furtherFormFieldsLoading, setFurtherFormFieldsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isInitialPopulationSelected, setIsInitialPopulationSelected] = useState(false);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState<FrequencyType>(props.initData?.executionFrequency as FrequencyType || FrequencyType.NONE);
  const [populationGroupState, setPopulationGroupState] = useState<{
    list: IPopulationGroupItem[];
    searchLoading: boolean;
    searchText: string | undefined;
    selectedPopulationGroupName: string | undefined;
  }>(
    {
    list: [],
    searchLoading: false,
    searchText: '',
    selectedPopulationGroupName: undefined,
  });

  const [campaignSegmentFormData, setCampaignSegmentFormData] =
    useState<ICampaignSegmentFormState>({
      audienceName: props?.initData?.audienceName || '',
      patientList: props.initData?.patientList || [],
      description: props.initData?.description || '',
      jsonTree: props.initData?.jsonTree,
      id: props.initData?.id,
      ...(props.initData?.id && {
        memberStatus: memberStatusOptions?.find(memberStatus =>
          memberStatus?.code === props.initData?.memberStatusCodeFromResponseWhenEditingGroup?.[0]),
        executionFrequency: props.initData?.executionFrequency,
        populationGroupId: props.initData?.populationGroupId || '',
      })
    });

    const [validation, setValidation] = useState({
      segmentName: '',
      selectedFilter: '',
      selectedFrequency: '',
      filter: {} as any,
      jsonTree: '',
      patientList: '',
    })
  const scrollableHeight = window.innerHeight - 160;

  const intl = useIntl();
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(FilterType.NONE);
  const isOnLeadGroups = isLeadGroupsPage() && !isPatientGroup;
  const hintCapability = getResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;

  const searchGroups = async (searchString: string) => {
    try {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchLoading: true,
        searchText: searchString,
      }));
      const response = isOnLeadGroups
        ? await getLeadGroups({accountUuid:accountUUID, name:searchString || ''})
        : await getGroupsList(accountUUID, 0, searchString || '', 0)

      const populationGroupResponseList: IMembersDatas[] = response?.data?.data;
      const filteredGroupsList = populationGroupResponseList.filter((group) => {
        const isDynamicGroup = group.type === POPULATION_GROUP_TYPES.DYNAMIC;
        const isDynamicLeadGroup = group.type === POPULATION_GROUP_TYPES.DYNAMIC_LEAD;
        return group.id !== campaignSegmentFormData.id &&
          !group.inputRuleId &&
          (isOnLeadGroups ? isDynamicLeadGroup : isDynamicGroup);
      });
      const formattedGroups: IPopulationGroupItem[] = filteredGroupsList.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setPopulationGroupState((prev) => ({
        ...prev,
        list: formattedGroups,
        searchLoading: false,
      }));
    } catch (error) {
      setPopulationGroupState((prev) => ({
        ...prev,
        list: [],
        searchLoading: false,
      }));
    }
  };

  const findMemberStatusOption = (statusCode: string | undefined) => {
    return memberStatusOptions.find((status) => status.code === statusCode) || undefined;
  };

    const fetchSelectedPopulationGroupDetails = async (groupId: string) => {
      try {
        const groupDetails = await getCampaignSegmentRules(groupId);
        const name = groupDetails?.name || '';
        const executionFrequency = groupDetails?.executionFrequency || '';
        const memberStatusCode = groupDetails?.preFilter?.hintMembershipStatus?.params?.value[0];
        const memberStatus = findMemberStatusOption(memberStatusCode);
        return {
          name,
          executionFrequency,
          memberStatus,
        };
      } catch (error) {
        return {
          name: '',
          executionFrequency: '',
          memberStatus: undefined
        };
      }
    };

    const initializeSelectedPopulationGroup = async () => {
      const populationGroupId = campaignSegmentFormData.populationGroupId;
      if (!!populationGroupId) {
        const groupDetails = await fetchSelectedPopulationGroupDetails(populationGroupId);
        setPopulationGroupState((prev) => ({
          ...prev,
          selectedPopulationGroupName: groupDetails.name,
        }));
        setCampaignSegmentFormData((prev) => ({
          ...prev,
          executionFrequency: groupDetails.executionFrequency,
          memberStatus: groupDetails.memberStatus
        }));
        setIsInitialPopulationSelected(true);
      }
    };


  useEffect(() => {
    initializeSelectedPopulationGroup();
  }, []);

  useEffect(() => {
    if ((context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES) || ((context === CampaignSegmentTableContext.POPULATION_GROUP) && !props.initData)) {
      setSelectedFilterType(FilterType.SELECT_BY_OTHER);
      return;
    }
    if (props.initData?.patientList?.length) {
      setSelectedFilterType(FilterType.SELECT_BY_PATIENT);
    } else if (props.initData?.jsonTree) {
      setSelectedFilterType(FilterType.SELECT_BY_OTHER);
    } else {
      setSelectedFilterType(FilterType.NONE);
    }
  }, [initData?.patientList?.length,initData?.jsonTree]);

  const handleJSONTreeStateChange = useCallback((newJsonTree: any) => {
    setCampaignSegmentFormData((prev: any) => {
      return {...prev, jsonTree: newJsonTree};
    });
  }, []);

  const removeOldData = () => {
    setCampaignSegmentFormData((prev) => {
      return {
        ...prev,
        patientList: [],
        jsonTree: '',
        memberStatus: undefined,
        executionFrequency: undefined,
        populationGroupId: '',
      };
    });
    setPopulationGroupState((prev) => ({
      ...prev,
      searchText: undefined,
      list: [],
      selectedPopulationGroupName: undefined,
    }));
  };

  const isValidForm = (data:any)=> {
    let isValid = true;
    setValidation(prev=> {
      return {
        ...prev,
        segmentName: '',
        selectedFilter: '',
        ...(isInitialPopulationSelected ? {} : { selectedFrequency: '' }),
        patientList: '',
        jsonTree: '',
      }
    })

    const  segmentName = !data?.audienceName?.slice().length ? 'Segment name mandatory' : ''
    const selectedFilter = !selectedFilterType.slice().length ? 'Choose filter' : '';
    const selectedFrequency = isStaticGroup(selectedFilterType) ? '' : !selectedFrequencyType.slice().length ? 'Choose Frequency' : '';

    const patientList = !data?.patientList?.length && !data.jsonTree  ? "Mandatory Field" : '';
    const jsonTree =  !data.jsonTree && !data?.patientList?.length ? "Mandatory Field" : '';

    setValidation(prev=> {
      return {
        ...prev,
        segmentName: segmentName,
        selectedFilter: selectedFilter,
        selectedFrequency: selectedFrequency,
        patientList: patientList,
        jsonTree: jsonTree,
      }
    })

    if(segmentName || selectedFilter || patientList || jsonTree || (selectedFrequency && !isInitialPopulationSelected)) {
      isValid = false
    }

    return isValid;
  }

  const geSearchMemberLabel = ()=> {
    if (isOnLeadGroups) {
      return 'leadContacts';
    } else if (props?.showLeadAndPatient) {
      return 'members';
    }
    return 'patients';
  }

  const handleSearch = debounce((value) => {
    if (!!value) {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchText: value,
      }));
      searchGroups(value);
    }
    else {
      setPopulationGroupState((prev) => ({
        ...prev,
        searchText: undefined,
        list: [],
        selectedPopulationGroupName: undefined,
      }));
    }
  }, 500);

  const renderNotFoundContent = () => {
    if (populationGroupState.searchLoading) {
      return <Spin size="small" />;
    } else {
      return populationGroupState.searchText ? (
        intl.formatMessage({ id: 'noDataFound' })
      ) : (
        intl.formatMessage({ id: 'NoDataSelect' })
      );
    }
  };

  const handleSelectChange = async (value: string, data: any) => {
    if (value) {
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          populationGroupId: data?.ruleId || undefined,
        };
      });
      setPopulationGroupState((prev) => {
        return {
          ...prev,
          selectedPopulationGroupName: value || undefined,
        }
      });
      setIsInitialPopulationSelected(true);
      setFurtherFormFieldsLoading(true);
      const res = await getCampaignSegmentRules(data.ruleId);
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          memberStatus: findMemberStatusOption(res?.preFilter?.hintMembershipStatus?.params?.value[0]) || undefined,
          executionFrequency: res?.executionFrequency,
          patientList:
            res.type === POPULATION_GROUP_TYPES.STATIC ||
            res.type === POPULATION_GROUP_TYPES.STATIC_LEAD
              ? res.rule.patientFilter.contactIds
              : [],
        };
      });
      setSelectedFrequencyType(res?.executionFrequency as FrequencyType);
      setFurtherFormFieldsLoading(false);
    } else {
      setCampaignSegmentFormData((prev) => {
        return {
          ...prev,
          populationGroupId: undefined,
          memberStatus: undefined,
          executionFrequency: undefined,
          patientList: [],
        };
      });
      setPopulationGroupState((prev) => {
        return {
          ...prev,
          selectedPopulationGroupName: undefined,
        }
      });
      setSelectedFrequencyType(FrequencyType.NONE);
      setIsInitialPopulationSelected(false);
      setFurtherFormFieldsLoading(false);
    }
  }

  return (
    // <PageBodyContainer>
    <VStack height={scrollableHeight} overflow="scroll">
      <HStack alignItems={'center'} justifyContent={'center'} marginBottom={5}>
        <TitleSubtitleView
          isTitleSubtitleOldView={true}
          isHideCommonButton={true}
          containerStyle={{marginLeft: 0, marginBottom: 5}}
          titleLabelId={
            props.isAddGroupMemberOnly
              ? 'Update Group Member'
              : props.editing ? 'updateAudienceGroup' : 'createAudienceGroup'
          }
          subtitleLabelId=""
          titleStyle={{ paddingBottom: 10}}
        />
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  props.onModalClose(false);
                },
                style: {marginRight: 12, paddingHorizontal: 16},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  if (!isValidForm(campaignSegmentFormData)) {
                    return;
                  }
                  if (campaignSegmentFormData.audienceName?.slice().length) {
                    setSubmitLoading(true);

                    props?.onSubmitForm(campaignSegmentFormData, () => {
                      removeOldData();
                      setSubmitLoading(false);
                    });
                  }
                },
                style: {paddingHorizontal: 16}
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: props.isAddGroupMemberOnly ? 'Add' : 'submit',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>

      <ScrollView>
        <VStack>
          {!props.isAddGroupMemberOnly && (
            <DisplayText
              extraStyles={{marginLeft: 0}}
              size={'lgMedium'}
              textLocalId={'audience'}
            />
          )}
          {!props.isAddGroupMemberOnly && (
            <Text style={{marginLeft: 0}} color="gray.500">
              {'You can define the group members here.'}
            </Text>
          )}

          <HStack flex={1} justifyContent={'center'}>
            <VStack flex={1}>
              {!props.isAddGroupMemberOnly && (
                <FormControl
                  style={[styles.formElement, {marginTop: 20}]}
                  isRequired={true}
                  isInvalid={
                    props.campaignDetailsError?.audienceName ||
                    validation.segmentName
                      ? true
                      : false
                  }
                >
                  <FormControl.Label style={styles.formLabel}>
                    <Image
                      width={'20px'}
                      height={'20px'}
                      style={{marginRight: 5}}
                      resizeMode={'contain'}
                      // style={styles.logoImg}
                      source={{
                        uri: 'https://i.imgur.com/0z2iM4G.png',
                        cache: 'force-cache',
                      }}
                      alt={'segmentName'}
                    />
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                      textLocalId={'segmentName'}
                    />
                  </FormControl.Label>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    isReadOnly={props.isReadOnly}
                    defaultValue={campaignSegmentFormData?.audienceName}
                    isInvalid={
                      props.campaignDetailsError?.audienceName ||
                      validation.segmentName
                        ? true
                        : false
                    }
                    value={campaignSegmentFormData?.audienceName}
                    onChangeText={(value: string) => {
                      setCampaignSegmentFormData((prev) => {
                        return {
                          ...prev,
                          audienceName: value,
                        };
                      });
                    }}
                    isFullWidth={true}
                    placeholder="Enter Segment Name"
                  />
                  {props.campaignDetailsError?.audienceName ||
                  validation.segmentName ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {props.campaignDetailsError?.audienceName ||
                        validation?.segmentName}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>
              )}

              {!props.isAddGroupMemberOnly && (
                <FormControl style={[styles.formElement]}>
                  <FormControl.Label style={styles.formLabel}>
                    <Image
                      width={'20px'}
                      height={'20px'}
                      style={{marginRight: 5}}
                      resizeMode={'contain'}
                      source={{
                        uri: 'https://i.imgur.com/rapJbNT.png',
                        cache: 'force-cache',
                      }}
                      alt={'description'}
                    />
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                      textLocalId={'description'}
                    />
                  </FormControl.Label>
                  <TextArea
                  _focus={{borderColor: '#D0D5DD'}}
                    isReadOnly={props.isReadOnly}
                    defaultValue={campaignSegmentFormData?.description}
                    value={campaignSegmentFormData?.description}
                    onChangeText={(value: string) => {
                      setCampaignSegmentFormData((prev) => {
                        return {
                          ...prev,
                          description: value,
                        };
                      });
                    }}
                    isFullWidth={true}
                    placeholder="Enter Description for Audience Group"
                  />
                </FormControl>
              )}

              {context === CampaignSegmentTableContext.POPULATION_GROUP && !props.isAddGroupMemberOnly && (
                <FormControl
                  style={[styles.formElement]}
                  key={'filter'}
                  isRequired
                  isInvalid={
                    props.campaignDetailsError?.campaignTime ||
                    validation.selectedFilter
                      ? true
                      : false
                  }
                >
                  <FormControl.Label style={styles.formLabel} isRequired>
                    <Image
                      width={'20px'}
                      height={'20px'}
                      style={{marginRight: 5}}
                      resizeMode={'contain'}
                      // style={styles.logoImg}
                      source={{
                        uri: 'https://i.imgur.com/yY6Ljxw.png',
                        cache: 'force-cache',
                      }}
                      alt={'Choose Filter'}
                    />
                    <DisplayText
                      textLocalId={'Choose Filter'}
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <Select
                    minWidth="150"
                    selectedValue={selectedFilterType || undefined}
                    accessibilityLabel="Choose Filter"
                    placeholder="Choose Filter"
                    _selectedItem={{
                      bg: 'teal.600',
                      endIcon: <CheckIcon size={5} />,
                    }}
                    onValueChange={(value) => {
                      setSelectedFilterType(value as FilterType);
                      removeOldData();
                    }}
                  >
                    <Select.Item
                      key={1}
                      label={intl.formatMessage({id:'staticSearchAndAddMembers'})}
                      value="selectPatient"
                    />
                    <Select.Item
                      key={2}
                      label={intl.formatMessage({id:'dynamicAddByPatientChars'})}
                      value="selectOther"
                    />
                  </Select>
                  {props.campaignDetailsError?.campaignTime ||
                  validation.selectedFilter ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {props.campaignDetailsError?.campaignTime ||
                        validation.selectedFilter}
                    </FormControl.ErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
              )}

              {(context === CampaignSegmentTableContext.POPULATION_GROUP &&
                selectedFilterType === FilterType.SELECT_BY_OTHER) && (
                  <FormControl
                    style={[styles.formElement]}
                    key={'initialPopulation'}
                  >
                    <FormControl.Label>
                    <HStack>
                      <DisplayText
                        textLocalId={intl.formatMessage({id:'initialPopulation'})}
                        size={'smMedium'}
                        extraStyles={{ color: Colors.Custom.Gray700 }}
                      />
                      <Tooltip
                        title={intl.formatMessage({id:'initialPopulationInfoMsg'})}
                        placement={'top'}
                        destroyTooltipOnHide={true}
                      >
                        <Icon
                          mt={1}
                          mx={2}
                          as={AntIcon}
                          color={Colors.Custom.Gray500}
                          name="infocirlceo"
                          size="smMedium"
                        />
                      </Tooltip>
                    </HStack>
                  </FormControl.Label>
                    <AntdSelect
                      style={{ minWidth: 150 }}
                      allowClear={true}
                      value={populationGroupState?.selectedPopulationGroupName}
                      placeholder={"Search and Add Initial Population"}
                      showSearch={true}
                      loading={populationGroupState.searchLoading}
                      onSearch={handleSearch}
                      notFoundContent={renderNotFoundContent()}
                      onChange={handleSelectChange}
                    >
                      {populationGroupState.list.map((item: any, index: any) => (
                        <AntdSelect.Option key={index} value={item?.name} label={item?.name} ruleId={item?.id}>
                          {item.name}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </FormControl>
              )}
              {furtherFormFieldsLoading ? (
                <Skeleton.Text lines={4}></Skeleton.Text>
              ) : (
                <>
                  {(selectedFilterType === FilterType.SELECT_BY_OTHER ||
                    (context === CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES)) &&
                    !props.isAddGroupMemberOnly && (
                      <>
                        <FormControl
                          style={[styles.formElement]}
                          key={'frequency'}
                          isRequired
                          isInvalid={
                            validation.selectedFrequency && !isInitialPopulationSelected
                              ? true
                              : false
                          }
                        >
                          {validation.selectedFrequency && !isInitialPopulationSelected && (
                            <FormControl.ErrorMessage
                              _text={{
                                fontSize: 'xs',
                                color: 'error.500',
                                fontWeight: 500,
                              }}
                            >
                              {validation.selectedFrequency}
                            </FormControl.ErrorMessage>
                          )}
                          <FormControl.Label style={styles.formLabel} isRequired>
                            <DisplayText
                              textLocalId={'Choose Frequency'}
                              size={'smMedium'}
                              extraStyles={{color: Colors.Custom.Gray700}}
                            />
                          </FormControl.Label>
                          <AntdSelect
                            style={{ minWidth: 150 }}
                            allowClear={true}
                            value={campaignSegmentFormData?.executionFrequency}
                            disabled={isInitialPopulationSelected}
                            placeholder={"Choose Frequency"}
                            onChange={(value: string, data: any) => {
                              setSelectedFrequencyType(value as FrequencyType);
                              setCampaignSegmentFormData((prev) => {
                                return {
                                  ...prev,
                                  executionFrequency: value,
                                };
                              });
                            }}
                          >
                            {frequencyOptions.map((item: any, index: any) => (
                              <AntdSelect.Option key={index} value={item?.code} label={item?.value}>
                                {item.value}
                              </AntdSelect.Option>
                            ))}
                          </AntdSelect>
                        </FormControl>
                        {isInitialPopulationSelected && (
                          <View>
                            <Text
                              paddingTop={-1}
                              fontSize={12}
                              color={Colors.Custom.Gray400}
                            >
                              {intl.formatMessage({ id: 'disabledExecutionFrequencyMsg' })}
                            </Text>
                          </View>
                        )}
                        {!isOnLeadGroups && openInHint && (
                          <>
                            <FormControl
                              style={[styles.formElement]}
                              key={'membershipStatus'}
                            >
                              <FormControl.Label style={styles.formLabel}>
                                <DisplayText
                                  textLocalId={openInHint ? 'Current Hint Membership Status' : 'Current Membership Status'}
                                  size={'smMedium'}
                                  extraStyles={{ color: Colors.Custom.Gray700 }}
                                />
                              </FormControl.Label>
                              <AntdSelect
                                style={{ minWidth: 150 }}
                                allowClear={true}
                                value={campaignSegmentFormData?.memberStatus?.code}
                                disabled={isInitialPopulationSelected}
                                placeholder={openInHint ? "Choose Hint Membership Status" : "Choose Membership Status"}
                                onChange={(value: string, data: any) => {
                                  const selectedMemberStatus = memberStatusOptions?.find(memberStatus => memberStatus?.code === value);
                                  setCampaignSegmentFormData((prev) => {
                                    const updatedMemberStatus = prev.memberStatus?.code === value ? undefined : selectedMemberStatus;
                                    return {
                                      ...prev,
                                      memberStatus: updatedMemberStatus,
                                    };
                                  });
                                }}
                              >
                                {memberStatusOptions.map((item: any, index: any) => (
                                  <AntdSelect.Option key={index} value={item?.code} label={item?.value}>
                                    {item.value}
                                  </AntdSelect.Option>
                                ))}
                              </AntdSelect>

                            </FormControl>
                            {(isInitialPopulationSelected) && (
                              <View>
                                <Text
                                  paddingTop={-1}
                                  fontSize={12}
                                  color={Colors.Custom.Gray400}
                                >
                                  {intl.formatMessage({ id: 'disabledMemberStatusMsg' })}
                                </Text>
                              </View>
                            )}
                          </>
                        )}
                        <FormControl isInvalid={validation.jsonTree ? true : false}>
                          {validation.patientList && (
                            <FormControl.ErrorMessage
                              _text={{
                                fontSize: 'xs',
                                color: 'error.500',
                                fontWeight: 500,
                              }}
                            >
                              {validation.jsonTree}
                            </FormControl.ErrorMessage>
                          )}
                          <RuleEngineInputField
                            isPatientGroup={isPatientGroup}
                            jsonTree={campaignSegmentFormData.jsonTree}
                            handleJSONTreeStateChange={handleJSONTreeStateChange}
                          />
                        </FormControl>
                      </>
                  )}
                </>
                )
              }
              {((context === CampaignSegmentTableContext.POPULATION_GROUP) &&
                (selectedFilterType === FilterType.SELECT_BY_PATIENT)) && (
                <>
                  <FormControl
                    style={[styles.formLastElement]}
                    isInvalid={
                      campaignSegmentFormData.errors || validation.patientList
                        ? true
                        : false
                    }
                  >
                    <FormControl.Label isRequired style={styles.formLabel}>
                      <Image
                        width={'20px'}
                        height={'20px'}
                        style={{marginRight: 5}}
                        resizeMode={'contain'}
                        // style={styles.logoImg}
                        source={{
                          uri: 'https://i.imgur.com/rapJbNT.png',
                          cache: 'force-cache',
                        }}
                        alt={'selectAppointmentPatients'}
                      />
                      <DisplayText
                        textLocalId={geSearchMemberLabel()}
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray700}}
                      />
                    </FormControl.Label>
                    <PatientDropdownList
                      notShowPatient={!!isOnLeadGroups}
                      showLeadAndPatient={props?.showLeadAndPatient}
                      defaultValue={campaignSegmentFormData.patientList}
                      onSelectValue={(actionData: any) => {
                        setCampaignSegmentFormData((prev) => {
                          return {
                            ...prev,
                            patientList: actionData,
                          };
                        });
                      }}
                    />
                    {campaignSegmentFormData.errors ||
                      (validation.patientList && (
                        <FormControl.ErrorMessage
                          _text={{
                            fontSize: 'xs',
                            color: 'error.500',
                            fontWeight: 500,
                          }}
                        >
                          {campaignSegmentFormData.errors ||
                            validation.patientList}
                        </FormControl.ErrorMessage>
                      ))}
                  </FormControl>
                </>
              )}
            </VStack>
          </HStack>
        </VStack>
      </ScrollView>
    </VStack>
    // </PageBodyContainer>
  );
};

export default  CampaignSegmentForm;
