import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const StickyNotesSvg = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#D94D55';
  return (
    <>
      {isWeb() ? (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99967 15.1693H7.99967C4.85698 15.1693 3.28563 15.1693 2.30932 14.193C1.33301 13.2166 1.33301 11.6453 1.33301 8.5026C1.33301 5.35991 1.33301 3.78856 2.30932 2.81225C3.28563 1.83594 4.85698 1.83594 7.99967 1.83594C11.1424 1.83594 12.7137 1.83594 13.69 2.81225C14.6663 3.78856 14.6663 5.35991 14.6663 8.5026V10.5026M9.99967 15.1693C12.577 15.1693 14.6663 13.0799 14.6663 10.5026M9.99967 15.1693C9.99967 13.9284 9.99967 13.308 10.1628 12.8059C10.4925 11.7911 11.2882 10.9955 12.303 10.6657C12.8051 10.5026 13.4255 10.5026 14.6663 10.5026"
            stroke={strokeColor}
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/stickyNotesPng.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default StickyNotesSvg;
