import React from 'react';
import {IContinueWithExistingConversationPopupProps} from './interface';
import {Button, Icon, Pressable, Text, View} from 'native-base';
import {SMS_INBOX_ACTION_CODES} from '../../../Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE, PATIENT_CONSENT_ERROR, PERSON_TYPES} from '../../../../../../constants/StringConst';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';
import { useNavigate } from 'react-router-dom';
import { withMiniContactViewHOC } from '../../../../../MiniContactViewHOC';
import { isConsentRequiredAndGiven, isContactConsentGiven } from '../../../../../../utils/commonUtils';

const ContinueWithExistingSmsConversationPopup = (
  props: IContinueWithExistingConversationPopupProps
) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {isOpen, onActionPerformed,existingConversation} = props;
  const onCloseModal = () => {
    onActionPerformed(SMS_INBOX_ACTION_CODES.MODAL_CLOSE);
  };
  const continueWithTheConversation = () => {
    onActionPerformed(SMS_INBOX_ACTION_CODES.CONTINUE_WITH_THE_SMS_CONVERSATION);
  };
  const existingConversationContactType = props?.existingConversation?.contact?.contactType?.contactType?.code

  const onPatientOrProfileNameClick = () => {
    if (existingConversationContactType == PERSON_TYPES.CUSTOMER) {
      props?.navigateOrOpenContactIdDrawer?.(props?.existingConversation?.contactId?.toString());

    } else if (existingConversationContactType == PERSON_TYPES.LEAD) {
      navigate(`/members/prospect/${props?.existingConversation?.contactId}`);
    }
  }
  const isConsentGiven = isConsentRequiredAndGiven(existingConversation?.contact);

  return (
    <>
      <Modal
        visible={isOpen}
        title={
          <Text fontSize={'lg'}>
            {intl.formatMessage({id: 'smsInboxExist'})}
          </Text>
        }
        onCancel={onCloseModal}
        footer={[
          (!isConsentGiven ?
            <View mb={4}>
              <Text fontSize={12} color={Colors.Custom.MissedCallIconColor} textAlign={'left'}>
              {`Please give consent for "${existingConversation?.contact?.name}" ${PATIENT_CONSENT_ERROR}`}
              </Text>
            </View> :
            <></>
          ),
          <Button.Group
            mx={{
              base: 'auto',
              md: 0,
            }}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: continueWithTheConversation,
                style: {marginLeft: 10},
                isDisabled: !isConsentGiven
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'sendSms',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: onCloseModal,
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>,
        ]}
      >
        {
          existingConversationContactType === PERSON_TYPES?.LEAD ?
            <View style={{ flexDirection: 'row' }}>
              <Text>
                The same number is also linked with a Prospect Profile{' '}
                <Pressable onPress={onPatientOrProfileNameClick}>
                  <Text>
                    {existingConversation?.contact?.name}
                    <Icon
                      as={FeatherIcon}
                      name="external-link"
                      size="xs"
                      color={Colors.secondary[500]}
                    />
                  </Text>
                </Pressable>
                {' '}which was created first. By continuing to send an SMS the message will be attached to the Prospect Profile {existingConversation?.contact?.name}'s chart. Send your Fold Customer service member a message if you would like us to merge these charts. Do you want to continue sending an SMS?
              </Text>
            </View>
            :
            existingConversationContactType === PERSON_TYPES?.CUSTOMER &&
            <View style={{ flexDirection: 'row' }}>
              <Text>
              The same phone number is also linked with{' '}
                <Pressable onPress={onPatientOrProfileNameClick}>
                  <Text>
                    {existingConversation?.contact?.name}
                    <Icon
                      as={FeatherIcon}
                      name="external-link"
                      size="xs"
                      color={Colors.secondary[500]}
                    />
                  </Text>
                </Pressable>
                {' '}, by continuing to send an SMS the message will be attached to {existingConversation?.contact?.name}'s chart. Do you want to continue sending an SMS?
              </Text>
            </View>
        }
      </Modal>
    </>
  );
};

export default withMiniContactViewHOC(ContinueWithExistingSmsConversationPopup);
