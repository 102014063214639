import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  bottomBorder: {
    borderBottomColor: Colors.primary[500],
    borderBottomWidth: 3,
  },

  panelBox: {
    paddingBottom: 2,
    paddingTop: 2,
  },

  detailsPanelBox: {
    paddingBottom: 10,
    paddingTop: 10,
  },

  panelBoxContents: {
    alignItem: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 5,
    flex:1
  },

  ticketPanel: {
    justifyContent: 'space-between',
    alignItem: 'center',
    padding: 15,
  },
  msgContactDetails: {
    height: 55,
  },
  listContainer: {
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: Colors.Custom.Gray50,
  },
  callMsgEmailActionBtns: {
    padding: 5,
    marginLeft: 15,
    height: 30,
    width: 54,
    borderRadius: 15,
  },
  actionsBtns: {
    paddingLeft: 4,
    paddingRight: 4,
    height: 22,
    borderRadius: 15,
    color: Colors.Custom.IconColorWhite,
  },
  mainMenuContent: {
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  lastMenu: {
    paddingBottom: 10,
  },
  firstMenu: {
    paddingTop: 10,
  },
  mainMenuTxt: {
    marginLeft: 5,
  },
  dividerStyle: {
    width: '100%',
  },
  btnStyle: {
    width: 32,
    height: 32,
    borderRadius: 18,
  },
  modalContainer: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalFooter: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
