import { CheckIcon, Divider, HStack, IconButton, Pressable, Text, View } from "native-base";
import UploadInsuranceCard from "./UploadInsuranceCard";
import { BUTTON_TYPE, DATE_FORMATS, INSURANCE_NUMBER } from "../constants";
import { ModalActionAutoComplete } from "./common/ModalActionCommonComponent/ModalActionAutoComplete";
import { ModalActionInput } from "./common/ModalActionCommonComponent/ModalActionInput";
import { getCapability, getEhrConfig } from "../utils/capabilityUtils";
import { Spin, notification } from "antd";
import { ModalActionSelect } from "./common/ModalActionCommonComponent/ModalActionSelect";
import { ModalActionDatePicker } from "./common/ModalActionCommonComponent/ModalActionDatePicker";
import { getMomentObjectWithDateStringAndFormat } from "../utils/DateUtils";
import { debounce } from 'lodash';
import { FHIR_RESOURCE } from "../constants/FhirConstant";
import AdddetailsIconSvg from "./common/Svg/AddDetailsIconSvg";
import { useIntl } from "react-intl";
import { Colors } from "../styles";
import { deleteInsurance, getSearchInsuranceCarrieNameElation, getSearchInsuranceCarrieOrPlanName, getSearchPlanNameElation } from "../services/CommonService/AidBoxService";
import { ImageSide } from "./InsuranceUtils";
import { ICopayAmount, IInsuranceComponentState, IInsuranceMandatoryFieldAccordingToEHR, IInsuranceState, planNameData } from "./InsuranceInterfaces";
import React, { useContext, useEffect, useState } from "react";
import DeleteIconSvg from "./RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/DeleteIconSvg";
import OverrideAntdModal from "./RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal";
import InsuranceAdditionaldetailsView from "./InsuranceAdditionalDetailsView";
import { CommonDataContext } from "../context/CommonDataContext";
import { isInsuranceEnable } from "../utils/commonUtils";

interface IInsuranceViewProps {
    setInsuranceDataForRequestBody?: any;
    setSecInsuranceDataForRequestBody?: any;
    setTerInsuranceDataForRequestBody?: any;
    setSecondaryInsuranceDataForRequestBody?: any;
    setTertiaryInsuranceDataForRequestBody?: any;
    addPatientView?: boolean;
    contactId?: string | number;
    patientId?: string;
    contactUuid?: string;
    errors?: IInsuranceMandatoryFieldAccordingToEHR;
    insuranceData?: IInsuranceState;
    secondaryInsuranceData?: IInsuranceState;
    tertiaryInsuranceData?: IInsuranceState;
    insuranceNumber: string;
    componentState: IInsuranceComponentState;
    setComponentState: (stateUpdater: (prevState: IInsuranceComponentState) => IInsuranceComponentState) => void;
    setInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState | undefined) => void;
    setSecondaryInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState | undefined) => void;
    setTertiaryInsuranceData?: (dataUpdater: (prevData: IInsuranceState | undefined) => IInsuranceState | undefined) => void;
    setIsInsuranceCarrierNameFreeText: (value: boolean) => void;
    setIsPlanNameFreeText: (value: boolean) => void;
    addSecondaryInsuranceOpen?: boolean;
    addTertiaryInsuranceOpen?: boolean;
    handleSecondaryDeleteAction?: (value: boolean) => void;
    handleTertiaryDeleteAction?: (value: boolean) => void;
    base64FrontData?: string | undefined;
    base64BackData?: string | undefined;
}


const AddOrUpdateInsuranceView = (props: IInsuranceViewProps) => {
    const InsuranceCapability = getCapability(FHIR_RESOURCE.COVERAGE);
    const commonData = useContext(CommonDataContext);
    const userSettings = commonData.userSettings;
    const allowInsurance = isInsuranceEnable(userSettings);
    const insuranceOperations = InsuranceCapability?.abilities?.keyAllowedOperations;
    const intl = useIntl();
    const ehrConfig = getEhrConfig();
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

    const insuranceFieldData: IInsuranceState | undefined = props?.insuranceData || props?.secondaryInsuranceData || props?.tertiaryInsuranceData;
    const loadingInsuranceCarrierName : boolean = props?.componentState?.loadingInsuraceCarrierNameData || props?.componentState?.loadingInsuraceCarrierNameSecondaryData || props?.componentState?.loadingInsuraceCarrierNameTertiaryData;
    const loadingPlanName: boolean = props?.componentState.loadingPlanName || props?.componentState.loadingSecondaryPlanName || props?.componentState.loadingPlanNameTertiary
    const setInsuranceFieldData = (
        props?.setInsuranceData ||
        props?.setSecondaryInsuranceData ||
        props?.setTertiaryInsuranceData ||
        (() => { })
    );
    const setInsuranceNewPatientFieldData = (
        props?.setInsuranceDataForRequestBody ||
        props?.setSecInsuranceDataForRequestBody ||
        props?.setTerInsuranceDataForRequestBody ||
        (() => { })
    );
    const isUpdate = insuranceFieldData?.insuranceId;
    const currentDateMoment = getMomentObjectWithDateStringAndFormat(
        new Date().toISOString(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
    );


    const searchInsuranceCarrierName = (searchString: string) => {
        if (searchString.length) {
            switch (props?.insuranceNumber) {
                case INSURANCE_NUMBER.PRIMARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingInsuraceCarrierNameData: true
                    }));
                    break;
                case INSURANCE_NUMBER.SECONDARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingInsuraceCarrierNameSecondaryData: true
                    }));
                    break;
                case INSURANCE_NUMBER.TERTIARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingInsuraceCarrierNameTertiaryData: true
                    }));
                    break;
            }
            if (ehrConfig.isElation) {
                getSearchInsuranceCarrieNameElation(searchString)
                    .then((response) => {
                        const simplifiedArray =
                            response?.data?.entry?.[0]?.resource?.data?.entry?.map(
                                (item: any) => ({
                                    id: item.id,
                                    name: item.name,
                                })
                            );
                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameData: false,
                                }));
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameSecondaryDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameSecondaryData: false,
                                }));
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameTertiaryDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameTertiaryData: false,
                                }));
                                break;
                        }
                    })
                    .catch((error: any) => {

                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameDataArray: [],
                                    loadingInsuraceCarrierNameData: false,
                                }));
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameSecondaryDataArray: [],
                                    loadingInsuraceCarrierNameSecondaryData: false,
                                }));
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameTertiaryDataArray: [],
                                    loadingInsuraceCarrierNameTertiaryData: false,
                                }));
                                break;
                        }
                    });
            } else {
                getSearchInsuranceCarrieOrPlanName(searchString,insuranceFieldData?.memberID || '')
                    .then((response) => {
                        const simplifiedArray = response?.data?.entry.map((item: any) => ({
                            id: item.resource.id,
                            name: item.resource.name,
                        }));
                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameData: false
                                }));
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameSecondaryDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameSecondaryData: false
                                }));
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameTertiaryDataArray: simplifiedArray || [],
                                    loadingInsuraceCarrierNameTertiaryData: false
                                }));
                                break;
                        }

                    })
                    .catch((error) => {

                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameDataArray: [],
                                    loadingInsuraceCarrierNameData: false
                                }));
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameSecondaryDataArray: [],
                                    loadingInsuraceCarrierNameSecondaryData: false
                                }))
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    insuraceCarrierNameTertiaryDataArray: [],
                                    loadingInsuraceCarrierNameTertiaryData: false
                                }))
                                break;
                        }
                    });
            }
        }
    };

    const searchPlanName = (searchString: string) => {
        if (searchString.length) {
            switch (props?.insuranceNumber) {
                case INSURANCE_NUMBER.PRIMARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingPlanName: true
                    }));
                    break;
                case INSURANCE_NUMBER.SECONDARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingSecondaryPlanName: true
                    }));
                    break;
                case INSURANCE_NUMBER.TERTIARY:
                    props?.setComponentState((prev) => ({
                        ...prev,
                        loadingPlanNameTertiary: true
                    }));
                    break;
            }
            if (ehrConfig.isElation) {
                getSearchPlanNameElation(searchString, insuranceFieldData?.organizationId || '')
                    .then((response) => {
                        const simplifiedArray =
                            response?.data?.entry?.map(
                                (item: any) => ({
                                    id: item.resource.id,
                                    name: item.resource.name,
                                })
                            );
                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    planNameDataArray: simplifiedArray,
                                });
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    secondaryPlanNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingSecondaryPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    secondaryPlanNameDataArray: simplifiedArray,
                                });
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    tertiaryPlanNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanNameTertiary: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    tertiaryPlanNameDataArray: simplifiedArray,
                                });
                                break;
                        }
                    })
                    .catch((error: any) => {

                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    planNameDataArray: [],
                                });
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    secondaryPlanNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingSecondaryPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    secondaryPlanNameDataArray: [],
                                })
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    tertiaryPlanNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanNameTertiary: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    tertiaryPlanNameDataArray: [],
                                });
                                break;
                        }
                    });
            } else {
                getSearchInsuranceCarrieOrPlanName(searchString,insuranceFieldData?.memberID || '')
                    .then((response) => {
                        const simplifiedArray = response?.data?.entry.map((item: any) => ({
                            id: item.resource.id,
                            name: item.resource.name,
                        }));
                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    planNameDataArray: simplifiedArray,
                                });
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    secondaryPlanNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingSecondaryPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    secondaryPlanNameDataArray: simplifiedArray,
                                });
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    tertiaryPlanNameDataArray: simplifiedArray,
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanNameTertiary: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    tertiaryPlanNameDataArray: simplifiedArray,
                                });
                                break;
                        }
                    })
                    .catch((error) => {

                        switch (props?.insuranceNumber) {
                            case INSURANCE_NUMBER.PRIMARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    planNameDataArray: [],
                                });
                                break;
                            case INSURANCE_NUMBER.SECONDARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    secondaryPlanNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingSecondaryPlanName: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    secondaryPlanNameDataArray: [],
                                });
                                break;
                            case INSURANCE_NUMBER.TERTIARY:
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    tertiaryPlanNameDataArray: [],
                                }));
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    loadingPlanNameTertiary: false,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    tertiaryPlanNameDataArray: [],
                                });
                                break;
                        }
                    });
            }
        }
    };

    const handleSetBase64Data = (data: string, cardText: string, insuranceNumber: string, isImageDeleted: boolean) => {
        let imageData = data;
        let isFrontSideImageDeleted = false;
        let isBackSideImageDeleted = false;
        let isAthenaImageDeleted = false;

        if (isImageDeleted) {
            imageData = '';
            switch (cardText) {
                case ImageSide.frontSide:
                    isFrontSideImageDeleted = true;
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        isFrontSideImageDeleted: isFrontSideImageDeleted,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        isFrontSideImageDeleted: isFrontSideImageDeleted,
                    });
                    break;
                case ImageSide.backSide:
                    isBackSideImageDeleted = true;
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        isBackSideImageDeleted: isBackSideImageDeleted,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        isBackSideImageDeleted: isBackSideImageDeleted,
                    });
                    break;
                default:
                    isAthenaImageDeleted = true;
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        isAthenaImageDeleted: isAthenaImageDeleted,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        isAthenaImageDeleted: isAthenaImageDeleted,
                    });
                    break;
            }
        }
        switch (insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
                if (cardText === ImageSide.frontSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64FrontSideData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            front: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            front: data
                        }
                    });
                } else if (cardText === ImageSide.backSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64BackSideData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            back: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            back: data
                        }
                    });
                } else {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64Data: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64ImageLink: data,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64ImageLink: data,
                    });
                }
                break;
            case INSURANCE_NUMBER.SECONDARY:
                if (cardText === ImageSide.frontSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64FrontSideSecondaryData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            front: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            front: data
                        }
                    });
                } else if (cardText === ImageSide.backSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64BackSideSecondaryData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            back: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            back: data
                        }
                    });
                } else {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64SecondaryData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64ImageLink: data,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64ImageLink: data,
                    });
                }
                break;
            case INSURANCE_NUMBER.TERTIARY:
                if (cardText === ImageSide.frontSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64FrontSideTertiaryData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            front: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            front: data
                        }
                    });
                } else if (cardText === ImageSide.backSide) {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64BackSideTertiaryData: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64Image: {
                            ...prev?.base64Image,
                            back: data
                        }
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64Image: {
                            ...insuranceFieldData?.base64Image,
                            back: data
                        }
                    });
                } else {
                    props?.setComponentState((prev) => ({
                        ...prev,
                        base64DataTertiary: data,
                    }));
                    setInsuranceFieldData((prev) => ({
                        ...prev,
                        base64ImageLink: data,
                    }));
                    setInsuranceNewPatientFieldData?.({
                        ...insuranceFieldData,
                        ...props?.componentState.copayDetails,
                        base64ImageLink: data,
                    });
                }
                break;
        }
    };

    const handleCopayTypeChange = (itemValue: any, index: any) => {
        const updatedCopayDetails = [...props?.componentState.copayDetails];
        updatedCopayDetails[index] = {
            ...updatedCopayDetails[index],
            type: itemValue,
        };
        props?.setComponentState((prev) => ({
            ...prev,
            copayDetails: updatedCopayDetails,
        }));
        setInsuranceNewPatientFieldData?.({
            ...insuranceFieldData,
            copayDetailsArray: updatedCopayDetails,
        });
    };

    const handleCopayAmountChange = (value: any, index: any) => {
        const updatedCopayDetails = [...props?.componentState.copayDetails];
        updatedCopayDetails[index] = {
            ...updatedCopayDetails[index],
            amount: value,
        };
        props?.setComponentState((prev) => ({
            ...prev,
            copayDetails: updatedCopayDetails,
        }));
        setInsuranceNewPatientFieldData?.({
            ...insuranceFieldData,
            copayDetailsArray: updatedCopayDetails,
        });
    };

    const addCopayFields = () => {
        props?.setComponentState((prev) => ({
            ...prev,
            copayFieldsCount: props?.componentState.copayFieldsCount + 1,
        }));
    };


    const renderCopayField = (): JSX.Element[] => {
        const copayFields = [];
        for (let i = 0; i < props?.componentState.copayFieldsCount; i++) {
            copayFields.push(
                <HStack key={i} space={10} mt={4}>
                    <ModalActionSelect
                        isDisabled={true}
                        label={'copayAmountType'}
                        selectedValue={props?.componentState.copayDetails[i]?.type || ''}
                        placeholder={'Select Copay Amount Type'}
                        endIcon={<CheckIcon size="5" />}
                        onValueChange={(itemValue: any) => {
                            handleCopayTypeChange(itemValue, i);
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                copayAmountType: itemValue,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                copayAmountType: itemValue,
                            });
                        }}
                        data={props?.componentState.copayAmountType}
                        selectItemProps={{
                            key: 'code',
                            label: 'display',
                            value: 'code',
                        }}
                        customStyle={{
                            flex: 1,
                        }}
                    />
                    <ModalActionInput
                        isDisabled={true}
                        fieldIs={'input'}
                        label={'copayAmount'}
                        placeholder={'Enter Copay Amount'}
                        value={props?.componentState.copayDetails[i]?.amount ? `$${props?.componentState.copayDetails[i]?.amount}` : ''}
                        onChangeText={(value: any) => {
                            handleCopayAmountChange(value, i);
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                copayAmount: value,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                copayAmount: value,
                            });
                        }}
                        extraStyle={{
                            flex: 1.0,
                        }}
                        notAllowEmoji={true}
                    />
                </HStack>
            );
        }
        return copayFields;
    };


    const getInsuranceCarrierNameData = () => {
        switch (props?.insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
                return props?.componentState.insuraceCarrierNameDataArray
            case INSURANCE_NUMBER.SECONDARY:
                return props?.componentState.insuraceCarrierNameSecondaryDataArray
            case INSURANCE_NUMBER.TERTIARY:
                return props?.componentState.insuraceCarrierNameTertiaryDataArray
        }
    }

    const getPlanNameData = () => {
        switch (props?.insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
                return insuranceFieldData?.planNameDataArray
            case INSURANCE_NUMBER.SECONDARY:
                return insuranceFieldData?.secondaryPlanNameDataArray
            case INSURANCE_NUMBER.TERTIARY:
                return insuranceFieldData?.tertiaryPlanNameDataArray
        }
    }

    const renderCopayType = () => {
        return (
            <>
                {renderCopayField()}
                <HStack
                    justifyContent={'flex-start'}
                    display={'flex'}
                    flex={1}
                    marginTop={4}
                    alignItems={'center'}
                >
                    <Pressable onPress={addCopayFields}>
                        <HStack alignItems={'center'}>
                            <AdddetailsIconSvg />
                            <Text color={Colors.Custom.mainPrimaryPurple}>
                                {intl.formatMessage({ id: 'addCopayType' })}
                            </Text>
                        </HStack>
                    </Pressable>
                </HStack>
            </>
        );
    };

    const clearInsuranceData = (insuranceNumber: string, insuranceData: IInsuranceState | undefined) => {
        if (insuranceData || props?.secondaryInsuranceData || props?.tertiaryInsuranceData) {
            const clearedData: IInsuranceState | undefined = {};
            const dataToClear = insuranceData || props?.secondaryInsuranceData || props?.tertiaryInsuranceData || {};
            Object.keys(dataToClear).forEach(key => {
                if (
                    insuranceNumber === INSURANCE_NUMBER.PRIMARY &&
                    (key === 'addSecondaryInsuranceOpen' || key === 'addTertiaryInsuranceOpen')
                ) {
                    clearedData[key] = dataToClear[key];
                } else {
                    clearedData[key as keyof IInsuranceState] = undefined;
                }
            });
            if (insuranceNumber === INSURANCE_NUMBER.PRIMARY && props?.setInsuranceData) {
                props?.setInsuranceData(() => clearedData);
                props?.setComponentState((prev) => ({
                    ...prev,
                    isPrimaryInsuranceDeleted: true
                }))
            } else if (insuranceNumber === INSURANCE_NUMBER.SECONDARY && props?.setSecondaryInsuranceData) {
                props?.setSecondaryInsuranceData(() => clearedData);
                props?.setComponentState((prev) => ({
                    ...prev,
                    isSecondaryInsuranceDeleted: true
                }))
            } else if (insuranceNumber === INSURANCE_NUMBER.TERTIARY && props?.setTertiaryInsuranceData) {
                props?.setTertiaryInsuranceData(() => clearedData);
                props?.setComponentState((prev) => ({
                    ...prev,
                    isTertiaryInsuranceDeleted: true
                }))
            }
        }
    }

    const showNotificationForInsuranceDeletedSuccess = (insuranceNumber: string) => {
        notification.destroy();
        notification.success({
            message: `${insuranceNumber} insurance deleted successfully`,
            duration: 3.0,
            placement: 'top'
          })
    }


    const hideInsuranceEntity = ( insuranceNumber: string) => {
        if(insuranceNumber === INSURANCE_NUMBER.SECONDARY){
            props?.handleSecondaryDeleteAction && props?.handleSecondaryDeleteAction(false);
        }
        else if(insuranceNumber === INSURANCE_NUMBER.TERTIARY){
            props?.handleTertiaryDeleteAction && props?.handleTertiaryDeleteAction(false);
        }
    }

    const deleteAPIactions = (insuranceId: string, insuranceNumber: string) => {
        props?.setComponentState((prev) => ({
            ...prev,
            isSaveButtonLoading: true,
        }))
        deleteInsurance(insuranceId || '', props?.patientId || '').then(() => {
            clearInsuranceData(insuranceNumber, props?.insuranceData);
            showNotificationForInsuranceDeletedSuccess(insuranceNumber);
            props?.setComponentState((prev) => ({
                ...prev,
                isSaveButtonLoading: false,
            }))
            hideInsuranceEntity(insuranceNumber);
        }
        ).catch((err) => {
            if(err && err?.response?.data?.message){
                let error;
                const errorMessage = err?.response?.data?.extensions;
                const parsedMessage = JSON.parse(errorMessage?.detailedError[0]?.details?.text)
                if(parsedMessage.error === intl.formatMessage({id :'insuranceExpiredDeleteMsg'})){
                    error = intl.formatMessage({id: 'insuranceCancelledOrExpiredMsg'})
                    notification.destroy();
                    notification.error({
                      message: intl.formatMessage({id: 'insuranceCancelledOrExpiredMsg'}),
                      duration: 3.0,
                      placement: 'top'
                    });
                }
            }
            props?.setComponentState((prev) => ({
                ...prev,
                isSaveButtonLoading: false,
            }))
        })
    }

    const DeleteInsurance = (insuranceNumber: string) => {
        switch (insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
                deleteAPIactions(props?.insuranceData?.insuranceId || '', insuranceNumber);
                break;
            case INSURANCE_NUMBER.SECONDARY:
                deleteAPIactions(props?.secondaryInsuranceData?.insuranceId || '', insuranceNumber);
                break;
            case INSURANCE_NUMBER.TERTIARY:
                deleteAPIactions(props?.tertiaryInsuranceData?.insuranceId || '', insuranceNumber);
                break;
        }
    }

    const deleteConfirmationModal = (): JSX.Element => {
        return(
            <OverrideAntdModal
                isOpen={isDeleteConfirmationModalOpen}
                onClose={() => {
                    setIsDeleteConfirmationModalOpen(false);
                }}
                onConfirm={() => {
                    setIsDeleteConfirmationModalOpen(false);
                    if (insuranceFieldData?.insuranceId) {
                        DeleteInsurance(props?.insuranceNumber);
                    }
                    else {
                        hideInsuranceEntity(props?.insuranceNumber)
                    }
                }}
                confirmButtonText="Delete"
                closeButtonText="cancel"
                textContent={{
                    headerText: intl.formatMessage({ id: 'deleteInsurance' }),
                    message: intl.formatMessage({ id: 'deleteInsuranceMessage' }),
                }}
            />
        )
    }

    const getAdditionalDetailsElement = (): JSX.Element => {
        return (
            <InsuranceAdditionaldetailsView
            setInsuranceDataForRequestBody={props?.setInsuranceDataForRequestBody}
            setSecInsuranceDataForRequestBody={props?.setSecInsuranceDataForRequestBody}
            setTerInsuranceDataForRequestBody={props?.setTerInsuranceDataForRequestBody}
            addPatientView={props?.addPatientView}
            patientId={props?.patientId}
            errors={props?.errors}
            insuranceData={insuranceFieldData}
            insuranceNumber={props?.insuranceNumber}
            componentState={props?.componentState}
            setComponentState={props?.setComponentState}
            setInsuranceData={setInsuranceFieldData}
            setIsInsuranceCarrierNameFreeText={props?.setIsInsuranceCarrierNameFreeText}
            setIsPlanNameFreeText={props?.setIsPlanNameFreeText}
          />
        )
    }

    const getPriSecTerAdditionalDetailsView = (insuranceNumber: string) => {
        return (
            <>
                {(insuranceNumber === INSURANCE_NUMBER.PRIMARY) &&
                    <View>
                        <Pressable
                            style={{ display: (allowInsurance) && !props?.componentState.additionalDetailsOpen ? 'flex' : 'none' }}
                            onPress={addAdditionalDetails}
                        >
                            <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                                <AdddetailsIconSvg />
                                <Text color={Colors.Custom.mainPrimaryPurple}>{intl.formatMessage({ id: 'addAdditionalDetails' })}</Text>
                            </HStack>
                        </Pressable>
                        {(allowInsurance) && props?.componentState.additionalDetailsOpen &&
                            getAdditionalDetailsElement()
                        }
                    </View>
                }
                {(insuranceNumber === INSURANCE_NUMBER.SECONDARY) &&
                    <View>
                        <Pressable style={{
                            display: (allowInsurance) && !props?.componentState.additionalSecondaryDetailsOpen && props?.addSecondaryInsuranceOpen ? 'flex' : 'none'
                        }}
                            onPress={addSecondaryAdditionalDetails}>
                            <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                                <AdddetailsIconSvg />
                                <Text color={Colors.Custom.mainPrimaryPurple}>{intl.formatMessage({ id: 'addAdditionalDetails' })}</Text>
                            </HStack>
                        </Pressable>

                        {((allowInsurance) && props?.componentState.additionalSecondaryDetailsOpen && props?.addSecondaryInsuranceOpen) && (
                            getAdditionalDetailsElement()
                        )}
                    </View>
                }
                {(insuranceNumber === INSURANCE_NUMBER.TERTIARY) &&
                    <View>
                        <Pressable style={{
                            display: (allowInsurance) && !props?.componentState.additionalTertiaryDetailsOpen && props?.addTertiaryInsuranceOpen ? 'flex' : 'none'
                        }}
                            onPress={addTertiaryAdditionalDetails}>
                            <HStack justifyContent={'flex-start'} display={'flex'} flex={1} marginTop={4} alignItems={'center'} flexDirection={'row'}>
                                <AdddetailsIconSvg />
                                <Text color={Colors.Custom.mainPrimaryPurple}>{intl.formatMessage({ id: 'addAdditionalDetails' })}</Text>
                            </HStack>
                        </Pressable>
                        {props?.componentState.additionalTertiaryDetailsOpen && (
                            getAdditionalDetailsElement()
                        )}
                    </View>
                }
            </>
        )
    }

    const addAdditionalDetails = () => {
        return props?.setComponentState((prev) => ({
            ...prev,
            additionalDetailsOpen: true,
        }));
    };

    const addSecondaryAdditionalDetails = () => {
        return props?.setComponentState((prev) => ({
            ...prev,
            additionalSecondaryDetailsOpen: true,
        }));
    };

    const addTertiaryAdditionalDetails = () => {
        return props?.setComponentState((prev) => ({
            ...prev,
            additionalTertiaryDetailsOpen: true,
        }));
    };
    const isPrimaryInsuranceMissing = (!props?.insuranceData?.insuranceId && props?.insuranceNumber === INSURANCE_NUMBER.PRIMARY)

    const renderInsuranceFieldAthena = (insuranceNumber: string) => {
        return (
            <>
                <View borderColor={Colors.Custom.Gray200} borderWidth={1} borderRadius={8} marginTop={props?.insuranceNumber === INSURANCE_NUMBER.PRIMARY ? 0 : 4}>
                    <View borderWidth={1} borderColor={Colors.Custom.Gray200} borderTopRadius={8} backgroundColor={Colors.Custom.Gray100}>
                <HStack justifyContent="space-between" alignItems={'center'} borderColor={Colors.Custom.Gray200} paddingX={4}>
                    <Text
                        fontWeight={'500'}
                        fontSize={16}
                        paddingY={2}
                    >{`${insuranceNumber} Insurance`}</Text>
                            <IconButton
                                colorScheme="red"
                                variant="ghost"
                                onPress={() => isPrimaryInsuranceMissing ? setIsDeleteConfirmationModalOpen(false) : setIsDeleteConfirmationModalOpen(true)}
                                icon={
                                    <DeleteIconSvg
                                        width={24}
                                        height={24}
                                        color={isPrimaryInsuranceMissing ? Colors.Custom.Gray200 : Colors.Custom.crossIconColor}
                                    />
                                }
                            />
                </HStack>
                    </View>
                    <View paddingTop={2} paddingX={4} paddingBottom={2}>
                {deleteConfirmationModal()}
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <HStack>
                        <UploadInsuranceCard
                            isPrimaryInsuranceDeleted={props?.componentState.isPrimaryInsuranceDeleted}
                            isSecondaryInsuranceDeleted={props?.componentState.isSecondaryInsuranceDeleted}
                            isTertiaryInsuranceDeleted={props?.componentState.isTertiaryInsuranceDeleted}
                            insuranceId={insuranceFieldData?.insuranceId}
                            patientId={props?.patientId || ''}
                            patientWithPatientId={insuranceFieldData?.patientWithPatientId || ''}
                            setBase64Data={handleSetBase64Data}
                            addPatientView={props?.addPatientView}
                            base64ImageData={insuranceFieldData?.base64ImageLink}
                            insuranceNumber={props?.insuranceNumber}
                        />
                    </HStack>
                </View>
                <HStack space={10} mt={4}>
                    <ModalActionAutoComplete
                        isRequired={true}
                        disabled={isUpdate && (!insuranceOperations?.insuranceCarrierName?.canUpdate && insuranceFieldData?.insuranceCarrierName)}
                        value={isUpdate && insuranceFieldData?.insuranceCarrierName}
                        allowClear={true}
                        errors={props?.errors?.insuranceCarrierName }
                        errorText={props?.errors?.insuranceCarrierName}
                        label={'insuranceCarrierName'}
                        placeholder="Search Insurance Carrier Name"
                        onSelect={(value: any, options: any) => {
                            props?.setIsInsuranceCarrierNameFreeText(false);
                            const organizationIdForPlanName = (
                                props?.componentState.insuraceCarrierNameDataArray as planNameData[]
                            )?.find(
                                (item: planNameData) => item.name.trim() === value.trim()
                            )?.id;
                            props?.setComponentState((prev) => ({
                                ...prev,
                                organizationId: options?.key || organizationIdForPlanName || '',
                            }));
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                insuranceCarrierName: value,
                                organizationId: options?.key || organizationIdForPlanName,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                insuranceCarrierName: value,
                                organizationId:  options?.key || organizationIdForPlanName,
                            });
                        }}
                        onClear={() => {
                            setInsuranceFieldData((prev) => ({
                                ...insuranceFieldData,
                                insuranceCarrierName: '',
                            }));
                        }}
                        onSearch={debounce(searchInsuranceCarrierName, 500)}
                        customStyle={{ flex: 1 }}
                        notFoundContent={
                            loadingInsuranceCarrierName && (
                                <Spin size="small" />
                            )
                        }
                        data={getInsuranceCarrierNameData()}
                        optionProps={{ id: 'id', code: 'name', value: 'name' }}
                        valueType={'Zipcode'}
                        maxLength={20}
                    />
                </HStack>
                {insuranceOperations?.planName.canCreate &&
                 <HStack space={10} mt={4}>
                    <ModalActionAutoComplete
                        isRequired={true}
                        disabled={isUpdate && (!insuranceOperations?.planName.canUpdate && insuranceFieldData?.insuranceCarrierName)}
                        value={isUpdate && insuranceFieldData?.planName}
                        errors={props?.errors?.planName}
                        errorText={props?.errors?.planName}
                        allowClear={true}
                        label={'planName'}
                        placeholder="Search Plan Name"
                        onSelect={(value: any, options: any) => {
                            props?.setIsPlanNameFreeText(false);
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                planName: value,
                                planNameId: options?.key
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                planName: value,
                                organizationId:  options?.key ,
                            });
                        }}
                        onClear={() => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                planName: '',
                            }));
                        }}
                        onSearch={debounce(searchPlanName, 500)}
                        customStyle={{ flex: 1 }}
                        notFoundContent={
                            loadingPlanName && <Spin size="small" />
                        }
                        data={getPlanNameData()}
                        optionProps={{ id: 'id', code: 'name', value: 'name' }}
                        valueType={'Zipcode'}
                        maxLength={20}
                    />
                </HStack>
                }
                <HStack space={10} mt={4}>
                    <ModalActionInput
                        fieldIs={'input'}
                        label={'groupID'}
                        placeholder={'Enter Group ID'}
                        changeBackground={true}
                        value={insuranceFieldData?.groupID}
                        onChangeText={(value: any) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                groupID: value,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                groupID: value,
                            });
                        }}
                        extraStyle={{
                            flex: 1.0,
                        }}
                        notAllowEmoji={true}
                    />

                    <ModalActionInput
                        fieldIs={'input'}
                        label={'memberID'}
                        errors={props?.errors?.memberId}
                        errorText={props?.errors?.memberId}
                        placeholder={'Enter Member ID'}
                        isRequired={true}
                        value={insuranceFieldData?.memberID}
                        onChangeText={(value: any) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                memberID: value,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                memberID: value,
                            });
                        }}
                        extraStyle={{
                            flex: 1.0,
                        }}
                        notAllowEmoji={true}
                    />
                </HStack>

                {renderCopayType()}
                <HStack space={10} mt={4}>
                    <ModalActionInput
                        isDisabled={true}
                        inputRightElement={<Text>{`%  `}</Text>}
                        fieldIs={'input'}
                        label={'coInsurance'}
                        placeholder={'Enter Coinsurance'}
                        changeBackground={true}
                        value={insuranceFieldData?.coinsurance}
                        onChangeText={(value: any) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                coinsurance: value,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                coinsurance: value,
                            });
                        }}
                        extraStyle={{
                            flex: 0.47,
                            marginTop: 2,
                            paddingLeft: 10,
                        }}
                        notAllowEmoji={true}
                    />
                </HStack>
                <HStack space={10} mt={4} width={'100%'}>
                    <ModalActionDatePicker
                        placeholder={'Select Date'}
                        label={'issueDate'}
                        leftMargin={'0'}
                        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                        onChange={(value: any, dateString) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                issueDate: dateString,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                issueDate: dateString,
                            });
                        }}
                        defaultValue={currentDateMoment}
                        value={
                            insuranceFieldData?.issueDate
                                ? getMomentObjectWithDateStringAndFormat(
                                    insuranceFieldData.issueDate,
                                    DATE_FORMATS.DISPLAY_DATE_FORMAT
                                )
                                : null
                        }
                        extraStyle={{ flex: 0.5 }}
                    />
                    <ModalActionDatePicker
                        placeholder={'Select Date'}
                        label={'expirationDate'}
                        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                        onChange={(value: any, dateString) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                endDate: dateString,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                endDate: dateString,
                            });
                        }}
                        defaultValue={currentDateMoment}
                        value={
                            insuranceFieldData?.endDate
                                ? getMomentObjectWithDateStringAndFormat(
                                    insuranceFieldData.endDate,
                                    DATE_FORMATS.DISPLAY_DATE_FORMAT
                                )
                                : null
                        }
                        extraStyle={{ flex: 0.5 }}
                    />
                </HStack>
                <HStack>
                    <Text
                        fontWeight={'500'}
                        fontSize={16}
                        paddingTop={4}
                    >{`${insuranceNumber} Insurance Policyholder`}</Text>
                </HStack>
                <HStack
                    space={10}
                    mt={2}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <ModalActionSelect
                        isRequired={true}
                        label={'Patient’s relationship to policy holder'}
                        selectedValue={insuranceFieldData?.patientRelationshipCode?.toString() || ''}
                        placeholder={`Select Patient’s Relationship To Policy Holder`}
                        endIcon={<CheckIcon size="5" />}
                        onValueChange={(code: any) => {
                            const selectedItem = props?.componentState.patientRelationType.find((item: ICopayAmount) => item.code.toString() === code);
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                patientRealtionWithPolicyHolder: selectedItem?.display,
                                patientRelationshipCode: selectedItem?.code
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                patientRealtionWithPolicyHolder: selectedItem?.display,
                                patientRelationshipCode: selectedItem?.code
                            });
                        }}
                        errors={props?.errors?.patientRealtionWithPolicyHolder}
                        errorText={props?.errors?.patientRealtionWithPolicyHolder}
                        data={props?.componentState.patientRelationType.map(item => {
                                    return {
                                        code: item.code.toString(),
                                        display: item.display
                                    }
                                })}
                        selectItemProps={{
                            key: 'code',
                            label: 'display',
                            value: 'code',
                        }}
                        customStyle={{
                            flex: 1,
                        }}
                    />

                    <ModalActionInput
                        fieldIs={'input'}
                        isDisabled={!insuranceFieldData?.insuranceId && !insuranceOperations?.policyHolderId.canCreate}
                        label={'policyHolderID'}
                        placeholder={'Enter Policy Holder ID'}
                        changeBackground={true}
                        value={insuranceFieldData?.policyGroupNumber}
                        onChangeText={(value: any) => {
                            setInsuranceFieldData((prev) => ({
                                ...prev,
                                policyGroupNumber: value,
                            }));
                            setInsuranceNewPatientFieldData?.({
                                ...insuranceFieldData,
                                policyGroupNumber: value,
                            });
                        }}
                        extraStyle={{
                            flex: 1.0,
                        }}
                        notAllowEmoji={true}
                    />
                </HStack>
                {getPriSecTerAdditionalDetailsView(insuranceNumber)}
                </View>
                </View>
            </>
        );
    };

    const renderInsuranceFieldElation = (insuranceNumber: string) => {
        return (
            <View borderColor={Colors.Custom.Gray200} borderWidth={1} borderRadius={8} marginTop={props?.insuranceNumber === INSURANCE_NUMBER.PRIMARY ? 0 : 4}>
                <View borderWidth={1} borderColor={Colors.Custom.Gray200}  borderTopRadius={8} backgroundColor={Colors.Custom.Gray100}>
                    <HStack justifyContent="space-between" alignItems={'center'} marginY={2} borderRadius={4} borderColor={Colors.Custom.Gray200} paddingX={4}>
                        <Text
                            fontWeight={'500'}
                            fontSize={16}
                            paddingLeft={2}
                        >{`${insuranceNumber} Insurance`}</Text>
                        <IconButton
                            colorScheme="red"
                            variant="ghost"
                            onPress={() => isPrimaryInsuranceMissing ? setIsDeleteConfirmationModalOpen(false) : setIsDeleteConfirmationModalOpen(true)}
                            icon={
                                <DeleteIconSvg
                                    width={24}
                                    height={24}
                                    color={isPrimaryInsuranceMissing ? Colors.Custom.Gray400 : Colors.Custom.crossIconColor}
                                />
                            }
                        />
                    </HStack>
                </View >
                <View paddingTop={6} paddingX={4} paddingBottom={2}>
                    {deleteConfirmationModal()}
                    <HStack flex={1} space={10} paddingX={2}>
                        <View>
                        <UploadInsuranceCard
                            base64FrontData={props?.base64FrontData}
                            isPrimaryInsuranceDeleted={props?.componentState.isPrimaryInsuranceDeleted}
                            cardText="Front Side"
                            insuranceId={insuranceFieldData?.insuranceId}
                            patientId={props?.patientId || ''}
                            patientWithPatientId={insuranceFieldData?.patientWithPatientId || ''}
                            setBase64Data={handleSetBase64Data}
                            addPatientView={props?.addPatientView}
                            insuranceNumber={props?.insuranceNumber}
                        />
                        </View>
                        <View>
                        <UploadInsuranceCard
                            base64BackData={props?.base64BackData}
                            isPrimaryInsuranceDeleted={props?.componentState.isPrimaryInsuranceDeleted}
                            cardText="Back Side"
                            insuranceId={insuranceFieldData?.insuranceId}
                            patientId={props?.patientId || ''}
                            patientWithPatientId={insuranceFieldData?.patientWithPatientId || ''}
                            setBase64Data={handleSetBase64Data}
                            addPatientView={props?.addPatientView}
                            insuranceNumber={props?.insuranceNumber}
                        />
                        </View>
                    </HStack>
                    <HStack space={10} mt={4}>
                        <ModalActionAutoComplete
                            isRequired={true}
                            errors={props?.errors?.insuranceCarrierName}
                            errorText={props?.errors?.insuranceCarrierName}
                            value={insuranceFieldData?.insuranceId && insuranceFieldData?.insuranceCarrierName}
                            disabled={insuranceFieldData?.insuranceId}
                            allowClear={true}
                            label={'insuranceCarrierName'}
                            placeholder="Search Insurance Carrier Name"
                            onSelect={(value: any, options: any) => {
                                props?.setIsInsuranceCarrierNameFreeText(false);
                                const organizationIdForPlanName = (
                                    props?.componentState.insuraceCarrierNameDataArray as planNameData[]
                                )?.find(
                                    (item: planNameData) => item.name.trim() === value.trim()
                                )?.id;
                                props?.setComponentState((prev) => ({
                                    ...prev,
                                    organizationId: options?.key || organizationIdForPlanName || '',
                                }));
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    insuranceCarrierName: value,
                                    organizationId:  options?.key || organizationIdForPlanName,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    insuranceCarrierName: value,
                                    organizationId:  options?.key || organizationIdForPlanName,
                                });
                            }}
                            onClear={() => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    insuranceCarrierName: '',
                                }));
                            }}
                            onSearch={debounce(searchInsuranceCarrierName, 500)}
                            customStyle={{ flex: 1 }}
                            notFoundContent={
                                loadingInsuranceCarrierName && (
                                    <Spin size="small" />
                                )
                            }
                            data={getInsuranceCarrierNameData()}
                            optionProps={{ id: 'id', code: 'name', value: 'name' }}
                            valueType={'Zipcode'}
                            maxLength={20}
                        />
                    </HStack>
                    <HStack space={10} mt={4}>
                        <ModalActionAutoComplete
                            isRequired={true}
                            errors={props?.errors?.planName}
                            errorText={props?.errors?.planName}
                            disabled={!insuranceFieldData?.insuranceId ? !insuranceFieldData?.organizationId : true}
                            value={insuranceFieldData?.insuranceId && insuranceFieldData?.planName}
                            allowClear={true}
                            label={'planName'}
                            placeholder="Search Plan Name"
                            onSelect={(value: any, options: any) => {
                                props?.setIsPlanNameFreeText(false);
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planName: value,
                                    planNameId: options?.key
                                }));
                            }}
                            onClear={() => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    planName: '',
                                }));
                            }}
                            onSearch={debounce(searchPlanName, 500)}
                            customStyle={{ flex: 1 }}
                            notFoundContent={
                                loadingPlanName && <Spin size="small" />
                            }
                            data={getPlanNameData()}
                            optionProps={{ id: 'id', code: 'name', value: 'name' }}
                            valueType={'Zipcode'}
                            maxLength={20}
                        />
                    </HStack>
                    <HStack space={10} mt={4}>
                        <ModalActionInput
                            errors={props?.errors?.groupId}
                            errorText={props?.errors?.groupId}
                            fieldIs={'input'}
                            label={'groupID'}
                            isRequired={true}
                            placeholder={'Enter Group ID'}
                            changeBackground={true}
                            value={insuranceFieldData?.groupID}
                            onChangeText={(value: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    groupID: value,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    groupID: value,
                                });
                            }}
                            extraStyle={{
                                flex: 1.0,
                            }}
                            notAllowEmoji={true}
                        />

                        <ModalActionInput
                            fieldIs={'input'}
                            label={'memberID'}
                            placeholder={'Enter Member ID'}
                            isRequired={true}
                            errors={props?.errors?.memberId}
                            errorText={props?.errors?.memberId}
                            value={insuranceFieldData?.memberID}
                            onChangeText={(value: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    memberID: value,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    memberID: value,
                                });
                            }}
                            extraStyle={{
                                flex: 1.0,
                            }}
                            notAllowEmoji={true}
                        />
                    </HStack>

                    <HStack space={10} mt={4}>
                        <ModalActionInput
                            fieldIs={'input'}
                            label={'copayAmount'}
                            placeholder={'Enter Copay Amount'}
                            value={insuranceFieldData?.copayAmount}
                            onChangeText={(value: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    copayAmount: value,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    copayAmount: value,
                                });
                            }}
                            extraStyle={{
                                flex: 1.0,
                            }}
                            notAllowEmoji={true}
                        />
                        <ModalActionInput
                            fieldIs={'input'}
                            label={'deductible'}
                            placeholder={'Enter Deductible Amount'}
                            value={insuranceFieldData?.deductibleAmount}
                            onChangeText={(value: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    deductibleAmount: value,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    deductibleAmount: value,
                                });
                            }}
                            extraStyle={{
                                flex: 1.0,
                            }}
                            notAllowEmoji={true}
                        />
                    </HStack>

                    <HStack space={10} mt={4} width={'100%'}>
                        <ModalActionDatePicker
                            placeholder={'Select Date'}
                            label={'issueDate'}
                            leftMargin={'0'}
                            format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                            onChange={(value: any, dateString) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    issueDate: dateString,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    issueDate: dateString,
                                });
                            }}
                            value={
                                insuranceFieldData?.issueDate
                                    ? getMomentObjectWithDateStringAndFormat(
                                        insuranceFieldData.issueDate,
                                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                                    )
                                    : null
                            }
                            extraStyle={{ flex: 0.5 }}
                        />
                        <ModalActionDatePicker
                            placeholder={'Select Date'}
                            label={'expirationDate'}
                            format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                            onChange={(value: any, dateString) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    endDate: dateString,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    endDate: dateString,
                                });
                            }}
                            value={
                                insuranceFieldData?.endDate
                                    ? getMomentObjectWithDateStringAndFormat(
                                        insuranceFieldData.endDate,
                                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                                    )
                                    : null
                            }
                            extraStyle={{ flex: 0.5 }}
                        />
                    </HStack>
                    <HStack>
                        <Text
                            fontWeight={'500'}
                            fontSize={16}
                            paddingY={4}
                        >{`${insuranceNumber} Insurance Policyholder`}</Text>
                    </HStack>
                    <HStack
                        space={10}
                        mt={4}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <ModalActionSelect
                            label={'Patient’s relationship to policy holder'}
                            selectedValue={insuranceFieldData?.patientRealtionWithPolicyHolder || ''}
                            placeholder={`Select Patient’s Relationship To Policy Holder`}
                            endIcon={<CheckIcon size="5" />}
                            onValueChange={(itemValue: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...prev,
                                    patientRealtionWithPolicyHolder: itemValue,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    patientRealtionWithPolicyHolder: itemValue,
                                });
                            }}
                            data={props?.componentState.patientRelationType}
                            selectItemProps={{
                                key: 'code',
                                label: 'display',
                                value: 'display',
                            }}
                            customStyle={{
                                flex: 1,
                            }}
                        />

                        <ModalActionInput
                            fieldIs={'input'}
                            label={'policyHolderID'}
                            placeholder={'Enter Policy Holder ID'}
                            changeBackground={true}
                            value={insuranceFieldData?.policyGroupNumber}
                            onChangeText={(value: any) => {
                                setInsuranceFieldData((prev) => ({
                                    ...insuranceFieldData,
                                    policyGroupNumber: value,
                                }));
                                setInsuranceNewPatientFieldData?.({
                                    ...insuranceFieldData,
                                    policyGroupNumber: value,
                                });
                            }}
                            extraStyle={{
                                flex: 1.0,
                            }}
                            notAllowEmoji={true}
                        />
                    </HStack>
                    {getPriSecTerAdditionalDetailsView(insuranceNumber)}
                </View>
            </View>
        );
    };

    return (
        <>
            {ehrConfig.isElation
                ? renderInsuranceFieldElation(
                    props?.insuranceNumber
                )
                : renderInsuranceFieldAthena(
                    props?.insuranceNumber
                )}
        </>
    )
};
export default AddOrUpdateInsuranceView;
