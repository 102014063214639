import {gql} from '@apollo/client';



const GetAllConversationWithCallMessages = gql`
 query GetAllConversationWithCallMessages($limit: Int!, $offset: Int!, $loginUserId: bigint!, $loginUserUuid: uuid!, $whereCondition: conversations_bool_exp!) {
  conversationMessageGroups(
    limit: $limit,
    offset: $offset
    order_by: {
      messageCreatedAt: desc_nulls_last
    },
    where: {
      conversation: $whereCondition
    }
  )  {
    messageConversation {
      contactId
      accountId
      id
      uuid
      conversationMainUuid: uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      updatedAt
      lastActivityAt
      workflowCommunicationLogResponseId
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        name
        isDeleted
        channelTwilioSms {
          phoneNumber
        }
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
      }
      unreadMessages: unreadMsg(args: {useruuid: $loginUserUuid}, where: {_or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}}) {
        id
      }

      conversationContact {
        contactPracticeLocations(where: {practiceLocationUuid: {_is_null: false}}) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        inbox {
          id
          name
        }
        sourceId
      }
      mentions {
        userId
        conversationId
      }
      assignedUser {
        id
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers_aggregate {
          aggregate {
            count
          }
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            accountId
            id
            email
            name
            uuid
            contactType {
              contactType {
                code
              }
            }
            phoneNumber
            patient {
              patientId
              patientUuid
            }
            person {
              birthDate
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
            }
            contactConsents {
              consentId
            }
          }
          user {
            id
            name
            uuid
            userRoles {
              userRole {
                userRole {
                  code
                  value
                }
              }
            }
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
    message {
      senderType
      senderId
      content
      createdAt
      updatedAt
      messageType
      id
      uuid
      contentAttributes
      messageAttachments {
        extension
        externalUrl
        fileType
        id
        updatedAt
        activeStorageAttachments {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
      senderUser {
        id
        uuid
        email
        name
      }
      senderContact {
        id
        uuid
        email
        name
      }
    }
  }
}
`;
export default {
  GetAllConversationWithCallMessages,
};
