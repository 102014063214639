import {gql} from '@apollo/client';

export const GetAllThemes = gql`
  query GetAllThemes {
    themeConfigs {
      colorConfig
      id
      name
    }
  }
`;

export const GetAccountTheme = gql`
  query GetAccountTheme {
    accountThemes {
      accountId
      theme {
        colorConfig
        id
        isDeleted
        name
      }
      additionalAttributes
    }
  }
`;

export default {GetAllThemes, GetAccountTheme};
