import {AutoComplete} from 'antd';
import {FormControl, HStack, Spinner, View} from 'native-base';
import {useState} from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
//FIXME: styles to be commented for mobile
import './ModalActionStyle.css';
const {Option} = AutoComplete;

interface IModalActionAutoComplete {
  label?: string;
  value?: string | undefined | any;
  defaultValue?: any;
  onSelect?: (value: any, options?: any) => void;
  onSearch?: any;
  onChange?: any;
  data: any;
  placeholder?: any;
  isRequired?: boolean;
  isInvalid?: string | boolean | any;
  errors?: string | boolean;
  errorText?: string | boolean;
  style?: any;
  customStyle?: any;
  extraStyle?: any;
  classStyle?: any;
  optionProps: IOptionProps;
  notFoundContent?: any;
  disabled?: any;
  allowClear?: boolean;
  onClear?: () => void;
  clearIcon?: boolean;
  showAlternateOption?: boolean | any;
  alternateOption?: any;
  loading?: boolean;
  onBlur?: any;
  isContactCustomDropdown?: (contactData: any) => void;
  valueType?: string;
  maxLength?: number;
  changeBackground?: boolean
  onFocus?: any
}

interface IOptionProps {
  id: string | number;
  code: string | number;
  value: any;
}

export const ModalActionAutoComplete = (
  props: IModalActionAutoComplete
): any => {
  const {
    value,
    isRequired,
    isInvalid,
    label,
    defaultValue,
    onSelect,
    onSearch,
    data,
    placeholder,
    errors,
    errorText,
    style,
    customStyle,
    classStyle,
    onChange,
    optionProps,
    notFoundContent,
    disabled,
    onClear,
    clearIcon,
    allowClear,
    showAlternateOption,
    alternateOption,
    loading,
    isContactCustomDropdown,
    valueType,
    maxLength,
    changeBackground,
    onFocus
  } = props;
  const [inputValue, setInputValue] = useState('');

  const getWidth = () => {
    if (customStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  return (
    <View flex={customStyle?.flex || ''}>
      <FormControl isRequired={isRequired} isInvalid={errors || isInvalid}>
        <HStack>
          {label && (
            <FormControl.Label marginLeft={0} style={[style || '']}>
              {label && (
                <DisplayText
                  textLocalId={label}
                  extraStyles={{
                    color: Colors.Custom.Gray500,
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                />
              )}
            </FormControl.Label>
          )}
          {loading && (
            <View>
              <Spinner />
            </View>
          )}
        </HStack>
        <View borderRadius={6} background={changeBackground ? '' : ''}>
          <AutoComplete
            size="large"
            style={{
              width: customStyle?.width ? customStyle?.width : getWidth(),
              fontSize: 12,
            }}
            maxLength={maxLength ? maxLength : undefined}
            value={value ? value : inputValue}
            disabled={disabled || ''}
            allowClear={allowClear}
            clearIcon={clearIcon}
            className={classStyle || ''}
            defaultValue={defaultValue || ''}
            notFoundContent={notFoundContent || ''}
            onChange={(value, options) => {
              if (valueType === 'Zipcode') {
                setInputValue(value);
                onChange && onChange(value, options);
              } else {
                setInputValue(value);
                onChange && onChange(value, options);
              }
            }}
            onSelect={(value: any, options: any) => {
              onSelect && onSelect(value, options);
            }}
            onFocus={onFocus}
            onClear={() => {
              setInputValue('');
              onClear && onClear();
            }}
            placeholder={placeholder || ''}
            onSearch={onSearch}
          >
            {showAlternateOption ? (
              <Option>{alternateOption}</Option>
            ) : (
              data &&
              data.map((option: any, index: any) => {
                return (
                  <Option
                    key={
                      option[optionProps.id] || JSON.stringify(option) || index
                    }
                    value={option[optionProps.code]}
                  >
                    {isContactCustomDropdown ?
                      isContactCustomDropdown(option)
                      :
                      option[optionProps.value]
                    }
                  </Option>
                );
              })
            )}
          </AutoComplete>
        </View>
        {errors && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {errorText}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </View>
  );
};
