import React from 'react';
import {Image} from 'native-base';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';
import { isWeb } from '../../../utils/platformCheckUtils';

const SendButtonSvg = (props: ICommonSvgProps) => {
  const {
    customStrokeColor,
  } = props;
  const height = props.height || '16';
  const width = props.width || '16';
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={props?.customStrokeColor ? props?.customStrokeColor : "#8C5AE2"}
            d="M10.067 11.025l.224.447-.224-.447zm-3.022 1.511l-.224-.447.224.447zm0-9.072l.224-.448-.224.448zm3.022 1.51l-.223.448.223-.447zM3.464 8.956l.447.224-.447-.224zm.292-.692l-.49-.097.49.097zm-.292-1.218l.447-.224-.447.224zm.292.692l.49-.097-.49.097zM3.23 2.03l-.128-.483.128.483zM2.03 3.231l-.483-.128.483.128zm1.202 10.74l.128-.483-.128.483zM2.03 12.769l-.483.128.483-.128zm5.124-5.29a.5.5 0 00-.006 1l.006-1zm3.68 1.021a.5.5 0 10.006-1l-.005 1zm-.99 2.078L6.822 12.09l.448.895 3.022-1.511-.447-.895zM6.822 3.911l3.023 1.51.447-.893-3.022-1.512-.448.895zm-2.91 5.268c.158-.318.284-.558.335-.82l-.98-.193c-.02.097-.066.198-.25.565l.895.448zm-.895-1.91c.184.367.23.468.25.565l.98-.194c-.051-.26-.177-.501-.335-.819l-.895.448zm1.23 1.09a1.853 1.853 0 000-.719l-.98.194a.853.853 0 010 .332l.98.194zM7.27 3.017C6.21 2.488 5.39 2.077 4.758 1.822c-.606-.245-1.17-.405-1.655-.276l.256.966c.132-.035.41-.01 1.025.237.586.237 1.364.625 2.437 1.162l.448-.895zM3.91 6.821c-.537-1.073-.925-1.85-1.162-2.437-.247-.614-.272-.893-.237-1.025l-.966-.256c-.129.485.031 1.049.276 1.655.255.633.666 1.453 1.194 2.51l.895-.447zm-.808-5.275a2.191 2.191 0 00-1.557 1.557l.966.256c.11-.414.433-.737.847-.847l-.256-.966zm3.718 10.543c-1.073.537-1.85.925-2.437 1.162-.614.247-.893.272-1.025.237l-.256.966c.485.129 1.049-.032 1.655-.276.633-.255 1.453-.666 2.51-1.194l-.447-.895zM3.016 8.731c-.528 1.058-.939 1.878-1.194 2.511-.245.606-.405 1.17-.276 1.655l.966-.256c-.035-.132-.01-.41.237-1.025.237-.586.625-1.364 1.162-2.437l-.895-.448zm.343 4.757a1.191 1.191 0 01-.847-.847l-.966.256a2.191 2.191 0 001.557 1.557l.256-.966zm6.932-2.015c1.304-.653 2.335-1.167 3.032-1.653.695-.485 1.204-1.042 1.204-1.82h-1c0 .267-.15.563-.776 1-.623.434-1.572.91-2.907 1.578l.447.895zm-.447-6.051C11.179 6.09 12.128 6.566 12.75 7c.626.437.776.733.776 1h1c0-.778-.51-1.335-1.204-1.82-.697-.486-1.728-1-3.032-1.652l-.447.894zM7.147 8.48l3.687.02.005-1-3.686-.02-.006 1z"
          ></path>
        </svg>
      ) : (
        <Image
          width={props?.width ? props?.width : 19}
          tintColor={
            customStrokeColor
              ? customStrokeColor
              : Colors.Custom.mainPrimaryPurple
          }
          height={props?.height ? props?.height : 19}
          source={require('../../../assets/images/SendButton.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default SendButtonSvg;
