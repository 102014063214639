import {Timeline} from 'antd';
import {View} from 'native-base';
import React from 'react';
import {ISingleTimelineProps} from '..';
import {FhirCollapse} from '../../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer/FhirCollapse';
import './SingleTimelineCss.css';
import {styles} from './SingleTimelineStyles';

const {Item} = Timeline;

const SingleTimeline = (props: ISingleTimelineProps) => {
  // const displayData = props.fhirData ? JSON.parse(props.fhirData) : {};
  // const fhirJson = props.fhirData ? JSON.parse(props.fhirData) : {};

  return props.fhirData ? (
    <View style={styles.container}>
      <Item pending dot={<span></span>}>
        {props.fhirData && (
          <View style={{borderRadius: 10}}>
            <FhirCollapse fhirRecord={props.fhirData}></FhirCollapse>
            {/* <FhirResource
              fhirResource={displayData}
              fhirVersion={fhirVersions.R4}
              // fhirIcons={fhirIcons}
              withCarinBBProfile
            /> */}
          </View>
        )}
      </Item>
    </View>
  ) : (
    <></>
  );
};

export default SingleTimeline;
