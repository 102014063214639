import { useLazyQuery } from '@apollo/client';
import { Box, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { GROUP_MEMBER_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT, FEED_SERVICE_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { LABEL_TYPE_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { GET_ALL_USERS_BY_IDS } from '../../../../services/User/UserQueries';
import { GET_PATIENT_CARE_JOURNEY_DETAIL_BY_ID, GET_CARE_JOURNEY_CONFIGS } from '../../../../services/CareJourney/CareJourneyQueries';
import { getContactListByUuids } from '../../../../services/Contacts/ContactsQueries';
import { GET_LABEL_BY_IDS } from '../../../../services/Labels/LabelsQueries';
import { GET_ATTACHMENT_DETAILS, GET_COMMENTS_BY_ID, GET_TASK_LOGS } from '../../../../services/Task/TaskQueries';
import { GET_TASK_POOL_BY_IDS } from '../../../../services/TaskPool/TaskPoolQueries';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { getMlovListFromCategory, getMlovObjectFromCode } from '../../../../utils/mlovUtils';
import useGetBatchedAccountUsers from '../../../CustomHooks/useGetBatchedAccountUsers';
import { ACTION_CODE, AUDIT_ACTION_CODES, fieldsOfTask, FIELDS_OF_TASK, TASK_RESOURCE_TYPE_CODES } from '../../Audit/AuditHelper';
import { IInitialBatchLogsCountData, IMappedAccountUsers, IAuditState } from '../../Audit/AuditInterfaces';
import { activityTabsCode } from '../AddEditComment/TaskCommentsHelper';
import { ITaskCommentsList } from '../interfaces';
import { TASK_CHANGE_PERFORMER_TYPE, TASK_AUDIT_LOG_PAGE_LIMIT } from './TaskAuditConstants';
import { getContentWithMentionText, getContentWithMentionTextForMobile } from '../AddTaskUtils';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { processActivityLogs } from './AuditHookHelper';

type IParamsAudit = {
    taskId?: string;
    commentsList?: ITaskCommentsList[];
    activeTabCodeInActivity?: activityTabsCode;
    patientCareJourneyId?: any;
    accountUsers?: any[];
}

const useTaskAudit = (params: IParamsAudit) => {
    const [taskLogData, setTaskLogData] = useState<IAuditState[]>([]);
    const [mappingUuidWithData, setMappingUuidWithData] = useState<{ [key: string]: any }>({})
    const toast = useToast();
    const [paginationState, setPaginationState] = useState(
        {
            paginationLoading: false,
            currentPage: 1,
            pageSize: TASK_AUDIT_LOG_PAGE_LIMIT,
            offset: 0,
            total: undefined,
        }
    );
    const [initialBatchLogsCountData, setInitialBatchLogsCountData] = useState<IInitialBatchLogsCountData>({
        logCount: 0,
        transactionIdsCount: 0,
    });
    const [totalTransactionsFetchedCount, setTotalTransactionsFetchedCount] = useState(0)
    const [transactionIdOfCreatedTask, setTransactionIdOfCreatedTask] = useState('')
    const [sortState, setSortState] = useState({
        dueDate: {
            desc: true
        } 
    })
    
    const [journeyDetails, setJourneyDetails] = useState({title: ''})

    const mlovData = useContext(CommonDataContext);

    const mlovs = getMlovListFromCategory(
        mlovData.MLOV,
        MLOV_CATEGORY.LABEL_TYPE
    ) || [];
    const takLabelMlov = getMlovObjectFromCode(LABEL_TYPE_CODES.TASK, mlovs);

    const accountUsersMappedById: { [key: string]: IMappedAccountUsers } = {};

    useEffect(() => {
        setPaginationState({
            paginationLoading: false,
            currentPage: 1,
            pageSize: TASK_AUDIT_LOG_PAGE_LIMIT,
            offset: 0,
            total: undefined,
        });
        setTaskLogData([]);
        setTotalTransactionsFetchedCount(0);
        if (params?.activeTabCodeInActivity !== activityTabsCode?.COMMENTS) {
            getTaskLogData(TASK_AUDIT_LOG_PAGE_LIMIT, 0, sortState);
        }
    }, [params?.activeTabCodeInActivity]);

    useEffect(() => {
        if (!params?.taskId && params?.activeTabCodeInActivity !== activityTabsCode?.COMMENTS) {
            getTaskLogData(TASK_AUDIT_LOG_PAGE_LIMIT, 0, sortState)
        }
    }, [params?.commentsList])

    useEffect(() => {
        if (params?.patientCareJourneyId) {
            getPatientCareJourneyDetails(params?.patientCareJourneyId)
        }
    }, [])

    const [getTaskLog, { loading: taskLogQueryLoading }] = useLazyQuery(GET_TASK_LOGS, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const [getCareJourneyDetails, {loading: careJourneyDetailsLoading}] = useLazyQuery(GET_PATIENT_CARE_JOURNEY_DETAIL_BY_ID, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const [searchTaskLabelByUuids, {loading: labelDetailsLoading}] = useLazyQuery(GET_LABEL_BY_IDS, {
        fetchPolicy: 'no-cache',
        onError: error => {
            showToast(toast, 'Error in fetching label details for logs', ToastType.error, 4000);
        },
    });

    const [getAttachmentDetailsByUuids, {loading: attachmentDetailsLoading}] = useLazyQuery(GET_ATTACHMENT_DETAILS, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onError: error => {
            showToast(toast, 'Error in fetching attachment details for logs', ToastType.error, 4000);
        },
    })

    const [getTaskPoolByUuids, {loading: taskPoolDetailsLoading}] = useLazyQuery(GET_TASK_POOL_BY_IDS, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onError: error => {
            showToast(toast, 'Error in fetching task pool details for logs', ToastType.error, 4000);
        },
    })

    const [getContactsByUuids,{loading: contactDetailsLoading}] = useLazyQuery(getContactListByUuids, {
        fetchPolicy: 'no-cache',
        onError: error => {
            showToast(toast, 'Error in fetching attachment patient/prospect for logs', ToastType.error, 4000);
        },
    })

    const [getCommentsByUuids, {loading: commentDetailsLoading}] = useLazyQuery(GET_COMMENTS_BY_ID, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        onError: error => {
            showToast(toast, 'Error in fetching attachment comments for logs', ToastType.error, 4000);
        },
    })

    const getDataKey = (field: FIELDS_OF_TASK) => {
        switch (field) {
            case FIELDS_OF_TASK.LABEL_ID:
                return "labels";
            case FIELDS_OF_TASK.ATTACHMENT_ID:
                return "attachments";
            case FIELDS_OF_TASK.USER_POOL_ID:
                return "userPools";
            case FIELDS_OF_TASK.CONTACT_ID:
                return "contacts";
            case FIELDS_OF_TASK.COMMENT_ID:
                return "taskComments";
            default:
                return "";
        }
    };

    const handleApiResponse = (item: FIELDS_OF_TASK, index: any, apiResponses: any) => {
        const dataKey = getDataKey(item);
        apiResponses?.[index]?.data?.[dataKey]?.forEach((dataItem: any) => {
            let comment = ''
            if (dataItem?.comment) {
                comment = formatComment(dataItem?.comment)
            }
            setMappingUuidWithData(prev => ({ ...prev, [dataItem?.uuid || dataItem?.id]: { ...dataItem, displayName: dataItem?.title || dataItem?.name || comment } }));
        });
    };

    const getDeletedDataOfTask = async (
        labelIdsToFetch: string[],
        attachmentIdsToFetch: string[],
        taskPoolIdsToFetch: string[],
        contactIdsToFetch: string[],
        commentIdsToFetch: string[]
    ) => {
        const promiseList: Promise<any>[] = [];
        const promisesSequence: FIELDS_OF_TASK[] = [];

        const pushPromise = (apiFunction: any, variablesForQuery: any, field: FIELDS_OF_TASK) => {
            const { labelIds, attachmentIds, poolIds, contactUuidList, commentIds } = variablesForQuery;

            if (labelIds?.length || attachmentIds?.length || poolIds?.length || contactUuidList?.length || commentIds?.length) {
                promiseList.push(apiFunction({ variables: variablesForQuery }));
                promisesSequence.push(field);
            }
        };

        pushPromise(searchTaskLabelByUuids, {
            labelTypeId: takLabelMlov?.id,
            labelIds: labelIdsToFetch,
        }, FIELDS_OF_TASK.LABEL_ID);

        pushPromise(getAttachmentDetailsByUuids, {
            attachmentIds: attachmentIdsToFetch,
        }, FIELDS_OF_TASK.ATTACHMENT_ID);

        pushPromise(getTaskPoolByUuids, {
            poolIds: taskPoolIdsToFetch,
        }, FIELDS_OF_TASK.USER_POOL_ID);

        pushPromise(getContactsByUuids, {
            contactUuidList: contactIdsToFetch,
        }, FIELDS_OF_TASK.CONTACT_ID);

        pushPromise(getCommentsByUuids, {
            commentIds: commentIdsToFetch,
        }, FIELDS_OF_TASK.COMMENT_ID);
        
        const apiResponses = await Promise.all(promiseList);

        promisesSequence.forEach((item, index) => {
            handleApiResponse(item, index, apiResponses);
        });

    }    

    const formatComment = (comment: any) => {
        return isWeb() ? getContentWithMentionText(
            comment,
            accountUserList || []
        ) : getContentWithMentionTextForMobile(comment, accountUserList || [])
    }

    const getTaskLogData = async (limit: number, offset: number, orderBy: {dueDate: {desc: boolean}}, emptyTaskLog?: boolean) => {
        let createdTaskTransactionId = ''
        if (params?.taskId) {
            const taskLogResponse = await getTaskLog({
                variables: {
                    resourceId: params?.taskId,
                    limit: limit,
                    offset: offset,
                    orderBy: {timeStamp: orderBy?.dueDate?.desc ? 'desc' : 'asc'},
                    excludedResourceType: params?.activeTabCodeInActivity === activityTabsCode?.HISTORY ? ['TASK_COMMENT'] : []
                }
            });
            const labelIdsToFetch: string[] = [];
            const attachmentIdsToFetch: string[] = [];
            const contactIdsToFetch: string[] = [];
            const taskPoolIdsToFetch: string[] = [];
            const commentIdsToFetch: string[] = [];

            const activityLogs = taskLogResponse?.data?.getActivityLogs?.activityLogs;
            const transactionIdsFetched: any =[]

            activityLogs?.forEach((log: IAuditState) => {
                transactionIdsFetched.push(log?.transactionId)
                const newData = log?.data?.newData;
                const oldData = log?.data?.oldData;
                const parentResourceOldData = log?.parentResourceData?.oldData;

                const pushToFetchArray = (field: keyof typeof fieldsOfTask, fetchArray: string[]) => {
                    const pushIfNotMapped = (value: string | undefined | string[]) => {
                        if (!!value && Array.isArray(value)) {
                            value?.forEach((item) => {
                                if (!!item && !mappingUuidWithData?.[item]) {
                                    fetchArray.push(item)
                                }
                            })
                        }
                        else if (!!value && !mappingUuidWithData?.[value]) {
                            fetchArray.push(value);
                        }
                    };
                    const fieldName = fieldsOfTask[field]
                    pushIfNotMapped(parentResourceOldData?.map((data: any) => data?.[fieldName]) || oldData?.[fieldName]);
                    pushIfNotMapped(newData?.[fieldName]);
                    if (field === FIELDS_OF_TASK.CONTACT_ID) {
                        pushIfNotMapped(newData?.[fieldsOfTask?.ASSIGNEE_ID]);
                        pushIfNotMapped(oldData?.[fieldsOfTask?.ASSIGNEE_ID]);
                    }
                };

                if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS && log?.actionCode !== ACTION_CODE.CREATE) {
                    pushToFetchArray(FIELDS_OF_TASK.USER_POOL_ID, taskPoolIdsToFetch);
                    pushToFetchArray(FIELDS_OF_TASK.CONTACT_ID, contactIdsToFetch);
                }
                else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_LABEL) {
                    pushToFetchArray(FIELDS_OF_TASK.LABEL_ID, labelIdsToFetch);
                }
                else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_ATTACHMENT) {
                    pushToFetchArray(FIELDS_OF_TASK.ATTACHMENT_ID, attachmentIdsToFetch);
                }
                else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_COMMENTS && !newData?.comment) {
                    commentIdsToFetch.push(log?.resourceId)
                }
                
                if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS && log?.actionCode === ACTION_CODE.CREATE) {
                    createdTaskTransactionId = log?.transactionId;
                    setTransactionIdOfCreatedTask(log?.transactionId)
                }
                if (log?.performedByTypeCode === GROUP_MEMBER_TYPE.CONTACT && !mappingUuidWithData?.[log?.performedById]) {
                    contactIdsToFetch.push(log?.performedById)
                }

                if (newData?.comment) {
                    newData.comment = formatComment(newData?.comment)
                }
                if (oldData?.comment) {
                    oldData.comment = formatComment(oldData?.comment)
                }

            });

            const {restructuredData, patientCareJourneyIds} = processActivityLogs(
                activityLogs,
                [TASK_RESOURCE_TYPE_CODES.TASK_LABEL, TASK_RESOURCE_TYPE_CODES.TASK_ATTACHMENT],
                { creationCode: TASK_RESOURCE_TYPE_CODES.TASKS, transactionId: createdTaskTransactionId || transactionIdOfCreatedTask },
            )

            const fetchedResponse = await getDeletedDataOfTask(
                [...new Set(labelIdsToFetch)],
                [...new Set(attachmentIdsToFetch)],
                [...new Set(taskPoolIdsToFetch)],
                [...new Set(contactIdsToFetch)],
                [...new Set(commentIdsToFetch)]
            )

            const uniqueTransactionIdsFetchedCount = Array.from(new Set(transactionIdsFetched))?.length
            if (emptyTaskLog) {
                setTaskLogData(restructuredData);
                setTotalTransactionsFetchedCount(uniqueTransactionIdsFetchedCount)
            }
            else {
                setTaskLogData((prev) => prev.concat(restructuredData))
                setTotalTransactionsFetchedCount((prev) => prev + (uniqueTransactionIdsFetchedCount))
            }

            if (offset === 0) {
                setInitialBatchLogsCountData({
                    logCount: restructuredData?.length,
                    transactionIdsCount: uniqueTransactionIdsFetchedCount
                })
            }
            setPaginationState((prev) => {
                return {
                    ...prev,
                    paginationLoading: false,
                    offset: prev.offset + Array.from(new Set(transactionIdsFetched))?.length,
                    total: taskLogResponse?.data?.getActivityLogs?.aggregate?.total // this total is the total number of transactions
                }
            }
            )
        }
        else {
            if (params?.commentsList && params?.commentsList.length > 0) {
                const taskLogs = [] as IAuditState[];
                params?.commentsList?.forEach((commentData: any) => {
                    taskLogs.push(getLogForCommentAdded(commentData))
                })
                taskLogs?.sort((a, b) => {
                    const timestampA: any = new Date(a.timestamp).getTime();
                    const timestampB: any = new Date(b.timestamp).getTime();
                    if (orderBy?.dueDate?.desc) {
                        return timestampB - timestampA
                    }
                    else return timestampA - timestampB
                });
                setTaskLogData(taskLogs)
            }
            else {
                setTaskLogData([])
            }
        }
    }

    const getPatientCareJourneyDetails = async(careJourneyId: string) => {
        const journeyDetailsReponse = await getCareJourneyDetails({
            variables: {
                id: careJourneyId
            }
        })
        setJourneyDetails({title: journeyDetailsReponse?.data?.careJourney?.title})
    }

    const handleActions = (actionCode: string) => {
        switch (actionCode) {
            case AUDIT_ACTION_CODES.SHOW_MORE:
                getTaskLogData(TASK_AUDIT_LOG_PAGE_LIMIT, paginationState?.offset, sortState);
                break;
            case AUDIT_ACTION_CODES.SHOW_LESS:
                // When "Show Less" is clicked, it displays the list of elements fetched in the initial log batch.
                // The offset for the next log batch is set to the number of transactions fetched in the initial log
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        offset: initialBatchLogsCountData?.transactionIdsCount,
                    };
                });
                setTotalTransactionsFetchedCount(initialBatchLogsCountData?.transactionIdsCount)
                setTaskLogData((prev) => {
                    return prev.slice(0, initialBatchLogsCountData?.logCount);
                });
                break
            case AUDIT_ACTION_CODES.CHANGE_SORT:
                setSortState((prev) => {
                    const newSort = { dueDate: { desc: !prev.dueDate?.desc } };
                    getTaskLogData(TASK_AUDIT_LOG_PAGE_LIMIT, 0, newSort, true)
                    return newSort;
                });
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        offset: 0,
                    };
                });
                break;
            default:
                break;
        }
    };

    const getLogForCommentAdded = (commentData: any) => {
        const newLog = {
            transactionId: '',
            resourceId: commentData?.id,
            resourceTypeCode: TASK_RESOURCE_TYPE_CODES.TASK_COMMENTS,
            performedById: commentData?.createdBy,
            performedByTypeCode: TASK_CHANGE_PERFORMER_TYPE.USER,
            timestamp: commentData?.createdOn,
            actionCode: ACTION_CODE.CREATE,
            data: {
                newData: commentData ,
                oldData: {}
            },
            id: commentData?.id,
            parentResourceId: '',
            parentResourceTypeCode: TASK_RESOURCE_TYPE_CODES.TASKS,
            source: '',
        }
        return newLog
    }

    const handleNewCommentAdded = (commentData: any) => {
        const commentDataCopy = commentData;
        commentDataCopy.comment = formatComment(commentData.comment)
        const newLog = getLogForCommentAdded(commentDataCopy);
        const taskLogDataCopy = taskLogData
        if (!params?.taskId) {
            sortState?.dueDate?.desc ? taskLogDataCopy?.unshift(newLog) : taskLogDataCopy?.push(newLog);
            setTaskLogData(taskLogDataCopy);
        }
        else {
        if (sortState?.dueDate?.desc) {
            taskLogDataCopy?.unshift(newLog);
            setTaskLogData(taskLogDataCopy);
            const logCountToAdd: any = 1;
            setPaginationState((prev) => {
                return {
                    ...prev,
                    total: prev?.total ? prev?.total + logCountToAdd : logCountToAdd,
                    offset: prev?.offset + logCountToAdd
                }
            })
            setTotalTransactionsFetchedCount((prev) => prev + 1)
        }
        else if (totalTransactionsFetchedCount === paginationState?.total) {
            taskLogDataCopy?.push(newLog);
            setTaskLogData(taskLogDataCopy);
            const logCountToAdd: any = 1;
            setPaginationState((prev) => {
                return {
                    ...prev,
                    total: prev?.total ? prev?.total + logCountToAdd : logCountToAdd,
                    offset: prev?.offset + logCountToAdd
                }
            })
            setTotalTransactionsFetchedCount((prev) => prev + 1)
        }
        }
    }

    const {
        loading: accountUserLoading,
        error: accountUsersError,
        userList: accountUserListAfterBatching,
    } = useGetBatchedAccountUsers({
        onError: () => { showToast(toast, 'Error in fetching account users', ToastType.error, 4000) },
        usersQueryName: GET_ALL_USERS_BY_IDS,
        doNotFetchOnMount:!!params?.accountUsers && params?.accountUsers?.length > 0
    });
    const accountUserList = params?.accountUsers || accountUserListAfterBatching;

    accountUserList.forEach(user => {
        const { uuid, id, name, email } = user;
        accountUsersMappedById[uuid] = { id, name, email };
    });
    const loadingAuditData = {
        initialAuditDataLoading: paginationState?.offset === 0 && (taskLogQueryLoading || accountUserLoading || careJourneyDetailsLoading || labelDetailsLoading || attachmentDetailsLoading || taskPoolDetailsLoading || contactDetailsLoading || commentDetailsLoading),
        paginationLoading: paginationState?.offset > 0 && (taskLogQueryLoading || accountUserLoading || careJourneyDetailsLoading || labelDetailsLoading || attachmentDetailsLoading || taskPoolDetailsLoading || contactDetailsLoading || commentDetailsLoading),
    }
    return {
        taskLogAuditData: {
            taskLogData: taskLogData,
            loadingAuditData: loadingAuditData,
            aggregate: paginationState?.total
        },
        accountUsersMappedById: accountUsersMappedById,
        mappingUuidWithData: mappingUuidWithData,
        handleActions: handleActions,
        offset: paginationState?.offset,
        sortState: sortState,
        handleNewCommentAdded: handleNewCommentAdded,
        journeyDetails,
        totalTransactionsFetchedCount,
        initialBatchLogsCountData
    }
}

export default useTaskAudit

