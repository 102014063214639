import { IBarrier } from "../../../RightSideContainer/Forms/FHFormio/CustomComponents/Barriers/interfaces";
import { IGoal } from "../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface";
import { IIntervention } from "../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces";
import {IPersonData} from "../interfaces";

export interface ICarePlanProps {
  contactUuid: string;
  contactName: string;
  contactId: number;
  accountLocationUuid: string;
  personData: IPersonData;
  onClose?: (isAdded: boolean) => void;
}
export interface IAddOrUpdateCarePlanProps {
  contactUuid: string;
  contactName: string;
  contactId: number;
  accountLocationUuid: string;
  personData: IPersonData;
  carePlanDetails?: ICarePlanDetail;
  formResponseComponents: any[];
  onCarePlanAdded?: () => void;
  onCancelClick?: () => void
  submittedCarePlanAssessmentForm?: ICarePlanComponentState['submittedCarePlanAssessmentForm']
  loadingCarePlanAssessmentFormName?: boolean;
  carePlanReviewTaskId?: string;
  submittedResponse?: any;
}

export enum CarePlanScreenContext {
  CARE_PLAN_LISTING = 'CARE_PLAN',
  ADD_CARE_PLAN = 'ADD_CARE_PLAN',
  EDIT_CARE_PLAN = 'EDIT_CARE_PLAN',
  CARE_PLAN_AUDIT = 'CARE_PLAN_AUDIT',
}

export interface ICarePlanComponentState {
  carePlanScreenContext: CarePlanScreenContext;
  carePlanDetails?: ICarePlanDetail,
  carePlanLoading?: boolean;
  formResponseComponents: any[];
  enabledCarePlanComponents: any[];
  submittedCarePlanAssessmentForm?: {
    submittedCarePlanAssessmentFormId?: string;
    submittedCarePlanAssessmentFormName?: string;
  }
  taskId?: string;
  submittedResponse?: any;
  carePlanDiff?: {
    old?: any,
    new: any,
  }
}

export interface ICarePlanDetailsProps {
  contactUuid: string;
  contactName: string;
  contactId: number;
  accountLocationUuid: string;
}

export interface ICarePlanDetailsState {
  formList: ICarePlanTemplate[];
  components: any[];
  loadingFormIds: string[];
  selectedFormIds: string[];
  formIds: string[];
  startRenderPrintComponent: boolean;
  formNamesMap: {
    [key: string]: string;
  }
  id?: string | undefined;
  statusId: string | undefined;
  isEditCarePlan: boolean;
  title: string;
  showProviderSelectionForReview: boolean;
  selectedProviderId: string;
  confirmInReviewAssignCarePlan: boolean;
  diffView?: {
    oldComponents: any[];
    newComponents: any[];
  }
  viewTypeLoading: boolean;
  submittedResponse: any;
  showSubmittedCarePlanAssessmentFormDrawer: boolean;
}

export interface ICarePlanTemplate {
  id: string;
  name: string;
}

export interface ICarePlanDetail {
  statusId: string;
  id: string;
  formId: string;
  formLogId: string;
  title: string;
  goals?: IGoal[];
  barriers?: IBarrier[];
  selectedFormIds: string[];
  interventions?: IIntervention[];
  startDateTime?: string
}

export interface ICarePlanTemplatesProps {
  templateList: ICarePlanTemplate[];
  onCheckTemplate: (templateId: string) => void;
  selectedTemplateIds: string[];
  loadingTemplateIds: string[];
}

export enum CarePlanHeaderActions {
  ASSIGNED_TO_PATIENT = 'ASSIGNED_TO_PATIENT',
  DOWNLOAD = 'DOWNLOAD',
  PRINT = 'PRINT',
  SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
  SEND_FOR_REVIEW = 'SEND_FOR_REVIEW',
  PREVIEW_CHANGES = 'PREVIEW_CHANGES',
  EDIT_CARE_PLAN = 'EDIT_CARE_PLAN',
  SAVE_CARE_PLAN_IN_REVIEW = 'SAVE_CARE_PLAN_IN_REVIEW',
}

export enum CarePlanValidationErrors {
  CARE_PLAN_TEMPLATES_MISSING = 'CARE_PLAN_TEMPLATES_MISSING',
}

export interface ICarePlanPayLoad {
  statusId: string;
  formIds?: string[];
  title: string;
  carePlanId?: string;
  components: any[];
  contactId: string;
  selectedFormIds: string[];
}

export interface ICarePlanValidationError {
  errorCode: CarePlanValidationErrors;
  errorMessage: string;
}
