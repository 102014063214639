import { Popover } from "antd";
import { RequestGroup } from "fhir/r4";
import { VStack, Text, Pressable, HStack, Spacer, Divider, Spinner } from "native-base";
import { Colors } from "../../../../../styles";
import { getApplicableOrderStatusList, getAuthorDisplay, getStatusDisplay, isOrderAddedFromFold } from "./OrderUtils";
import AntIcon from 'react-native-vector-icons/AntDesign';
import { OrderStatus } from "./AddOrUpdateExternalOrders/Helper";


const renderStatusView = (
  order: RequestGroup,
  isLoading: boolean,
  onStatusSelect: (status: string) => void,
) => {
  const orderStatusList = (order.status === OrderStatus.active || order.status === OrderStatus.completed) ? getApplicableOrderStatusList(order).filter((orderStatus) => orderStatus.code !== OrderStatus.draft) : getApplicableOrderStatusList(order);
  const isFoldOrder = isOrderAddedFromFold(order);
  if (!isFoldOrder) {
    return <Text color={Colors.Custom.Gray500} fontWeight={400} fontSize={14}>{getStatusDisplay(order.status)}</Text>
  }
  return (
    <Popover
      overlayInnerStyle={{
        borderRadius: 16,
      }}
      overlayClassName="note-template"
      placement="bottom"
      content={
        <VStack width={150} maxH={300} minHeight={120} padding={2}>
          {orderStatusList.map((item, index) => (
            <VStack space={2}>
              <Pressable
                key={item.code}
                px={3}
                pt={1}
                onPress={() => onStatusSelect(item.code)}
              >
                <HStack my={2} alignItems="center">
                  <Text color={order.status == item.code ? Colors.Custom.PurpleColor : Colors.Custom.Gray500}>
                    {item.display}
                  </Text>
                  <Spacer />
                </HStack>
              </Pressable>
              {orderStatusList.length !== index + 1 && <Divider />}
            </VStack>
          ))}
        </VStack>
      }
    >
      <HStack alignItems={'center'} space={2}>
        <Text color={Colors.Custom.PurpleColor}>{getStatusDisplay(order.status)}</Text>
        {isLoading && <Spinner size={'sm'} />}
        {!isLoading && <AntIcon name="down" size={12} color={Colors.Custom.PurpleColor} style={{marginTop: 1}} />}
      </HStack>
    </Popover>
  );
}

export const renderOrderSubtitle = (
  order: RequestGroup,
  isLoading: boolean,
  onStatusSelect: (status: string) => void
) => {
  return (
    <HStack space={1}>
      {renderStatusView(order, isLoading, onStatusSelect)}
      <Text color={Colors.Custom.Gray500} fontWeight={400} fontSize={14}>•</Text>
      <Text color={Colors.Custom.Gray500} fontWeight={400} fontSize={14}>{getAuthorDisplay(order)}</Text>
    </HStack>
  );
}
