import {Icon, Image, Text} from 'native-base';
import {RESOURCE_MAP} from '../AddTask/AddTaskUtils';
import {IResourceMapDetail} from '../../CustomHooks/useResourceMapping';
import {
  CARE_TEAM_MEMBER_TYPE,
  OUT_REACH_OUTCOME_CATEGORIES,
  OUT_REACH_OUTCOME_CATEGORY_CODES,
} from '../../../constants/MlovConst';
import {
  IAdditionalAttribute,
  IContactCareProgramResponse,
  IContactCareProgramStepOutreach,
  StepOutreachLogModeOfContact,
  UpdateCareProgramStepOutreachInput,
} from '../../../services/ContactCareProgram/interface';
import {getAttributeByKey} from '../CareProgramPanel/CareProgramUtils';
import {IntlShape, useIntl} from 'react-intl';
import {IData} from './components/OutreachStepNoteView';
import {ILoginUserData, IUser} from '../../../Interfaces';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../constants';
import React from 'react';
import {Colors} from '../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import {IMlov} from '../../RightSideContainer/Journeys/JourneysOfCare/Table/Interfaces';
import { ADDITIONAL_ATTRIBUTE_KEY } from '../MemebersView/constant';
import WalkingIconSvg from '../Svg/TimelineSvg/WalkingIconSvg';
import { canCommunicateWithInactivePatient, getSecondaryValue, isActiveContact, isContactConsentGiven } from '../../../utils/commonUtils';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';

const enabledAttributes = [
  'facility',
  'admittedDate',
  'dischargeDate',
  'facilityType',
  'dischargeTo',
];

const featherProps = {
  size: 14,
  color: Colors.FoldPixel.GRAY300,
  style: { marginRight: 2 },
};

const getIconComponent = (resourceType: StepOutreachLogModeOfContact) => {
  switch (resourceType) {
    case StepOutreachLogModeOfContact.CHAT:
      return <Feather name="message-circle" {...featherProps} />;
    case StepOutreachLogModeOfContact.EMAIL:
      return <Feather name="mail" {...featherProps} />;
    case StepOutreachLogModeOfContact.SMS:
      return <Feather name="message-square" {...featherProps} />;
    case StepOutreachLogModeOfContact.VOICE_CALL:
      return <Feather name="phone" {...featherProps} />;
    case StepOutreachLogModeOfContact.IN_PERSON:
      return <WalkingIconSvg />;
  }
};

const getTextBySourceTypeCode = (
  sourceTypeCode: string,
  item?: IResourceMapDetail
) => {
  switch (sourceTypeCode) {
    case RESOURCE_MAP.MESSAGE:
      return `Message sent`;
    case RESOURCE_MAP.TASK:
      return `Task created`;
    case RESOURCE_MAP.APPOINTMENT:
      return `Appointment booked`;
  }
};

const getInfoListView = (contactData: any) => {
  const items: {
    label: string;
    value: string;
  }[] = [];

  const careManagerName = (
    contactData?.careTeam?.careTeamMemberType || []
  ).find((careTeamMember: any) => {
    return (
      careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    );
  });

  items.push({
    label: 'Care Manager',
    value: careManagerName?.memberName || '--',
  });
};

const getEnabledContactModes = (contact: IContact, consentCheck:boolean) => {
  const contactActiveStatus = isActiveContact(contact);
  const isPatientLoggedIn = !!contact?.additionalAttributes?.loggedInTime;
  const email = contact?.email || getSecondaryValue(contact?.personContact, 'email') || '';
  const phoneNumber = contact?.phoneNumber || getSecondaryValue(contact?.personContact, 'phone') || '';
  const isCommuniCateWithInActivePatient = canCommunicateWithInactivePatient(contact);
  const enableChat = contactActiveStatus && isPatientLoggedIn;
  const enableSendEmail =
    isCommuniCateWithInActivePatient && email && consentCheck;
  const enableSendSMS =
    isCommuniCateWithInActivePatient && phoneNumber && consentCheck;
  const enablePhoneCall = contactActiveStatus && consentCheck && phoneNumber;

  const enabledModes: StepOutreachLogModeOfContact[] = [];
  enabledModes.push(StepOutreachLogModeOfContact.IN_PERSON);
  if (enablePhoneCall) {
    enabledModes.push(StepOutreachLogModeOfContact.VOICE_CALL);
  }
  if (enableChat) {
    enabledModes.push(StepOutreachLogModeOfContact.CHAT);
  }
  if (enableSendSMS) {
    enabledModes.push(StepOutreachLogModeOfContact.SMS);
  }
  if (enableSendEmail) {
    enabledModes.push(StepOutreachLogModeOfContact.EMAIL);
  }
  return enabledModes;
};

const formatAttributes = (
  attributes: IAdditionalAttribute[],
  careProgram: IContactCareProgramResponse,
  intl: IntlShape
) => {
  const values: {
    label: string;
    value: string;
  }[] = [];

  attributes.forEach((attribute: IAdditionalAttribute) => {
    let value = getAttributeByKey(attribute.attributeKey, careProgram);
    if (value && attribute.attributeKey == ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE || attribute.attributeKey == ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE) {
      value = getDateStrFromFormat(value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
    }
    if (value && enabledAttributes.includes(attribute.attributeKey)) {
      values.push({
        label: intl.formatMessage({id: attribute.attributeKey}),
        value: value,
      });
    }
  });

  return values;
};

const sortOutreachStepBySequence = (
  outreachStep: IContactCareProgramStepOutreach[]
) => {
  return outreachStep.sort((a, b) => a.sequence - b.sequence);
};

const getFormattedDataForUpdate = (
  step: IContactCareProgramStepOutreach,
  data: IData,
  statusId: string
) => {
  const params: UpdateCareProgramStepOutreachInput = {
    id: step.stepOutreachLog.id,
    outreachId: step.stepOutreachLog.outreachId,
    resourceId: step.stepOutreachLog.resourceId,
    resourceType: step.stepOutreachLog.resourceType,
    statusId: statusId,
    typeId: step.stepOutreachLog.typeId,
    stepId: step.stepOutreachLog.stepId,
    communicationMode: data.modeOfContact,
    outcomeId: data.outcomeId,
    note: data.note,
  };

  return params;
};

const getUserMapByUUID = (users: IUser[], loggedInUser:ILoginUserData ) => {
  const map: Record<string, IUser> = {};

  users.forEach((user: IUser) => {
    map[user.uuid] = user;
  });

  map[loggedInUser.uuid] = {
    name: loggedInUser.name,
    id: `${loggedInUser.id}`,
    uuid: loggedInUser.uuid,
  };

  return map;
};

const getOutComeColorByOutComeCode = (
  outComeMlov: IMlov
): string | undefined => {
  let key = '';
  Object.keys(OUT_REACH_OUTCOME_CATEGORIES).forEach((categoryKey) => {
    const list = OUT_REACH_OUTCOME_CATEGORIES[categoryKey];
    const mlovCode = list.includes(outComeMlov.code);
    if (mlovCode) {
      key = categoryKey;
    }
  });

  switch (key) {
    case OUT_REACH_OUTCOME_CATEGORY_CODES.OUTREACH_COMPLETED:
      return Colors.FoldPixel.STATUS_DARK_SUCCESS;
    case OUT_REACH_OUTCOME_CATEGORY_CODES.FOLLOW_UP_NEEDED:
      return Colors.FoldPixel.STATUS_IN_PROGRESS;
    case OUT_REACH_OUTCOME_CATEGORY_CODES.REMOVE_PATIENT:
      return Colors.FoldPixel.STATUS_ERROR;
    default:
      return Colors.FoldPixel.GRAY300;
  }
};

const getSubtitleText = (params: {
  step: IContactCareProgramStepOutreach;
  userMap: Record<string, IUser>;
  outcomeMLOV: IMlov | undefined;
  isOutreachDoneByAutomation: boolean;
}) => {
  const { step, userMap, outcomeMLOV, isOutreachDoneByAutomation } = params;
  const {stepOutreachLog} = step;
  const user = userMap[stepOutreachLog.outreachCompletedBy];
  const date = getDateStrFromFormat(
    stepOutreachLog.outreachDateTime,
    DATE_FORMATS.BILLING_ACTIVITY_DATE
  );
  const communicationMode = stepOutreachLog.communicationMode;

  const arrayOfTextElements: JSX.Element[] = [];

  if (communicationMode) {
    const IconComponent = getIconComponent(communicationMode);
    arrayOfTextElements.push(
      <Text key={'communicationMode'}>
        {IconComponent}
        {communicationMode}
      </Text>
    );
  }

  if (isOutreachDoneByAutomation) {
    arrayOfTextElements.push(
      <Text>
        <Feather
          name={'zap'}
          {...featherProps}
        />
        Automation
      </Text>
    );
  } else if (user) {
    arrayOfTextElements.push(
      <Text>
        <Feather
          name={'user'}
          {...featherProps}
        />
        {user.name}
      </Text>
    );
  }

  if (date && date !== 'Invalid date') {
    arrayOfTextElements.push(<Text>{date}</Text>);
  }

  const arrayOfNoteAndOutcome: JSX.Element[] = [];

  if (outcomeMLOV) {
    const color = getOutComeColorByOutComeCode(outcomeMLOV);
    arrayOfNoteAndOutcome.push(
      <Text fontWeight={600} color={color}>
        {outcomeMLOV.value}
      </Text>
    );
  }

  if (stepOutreachLog.note) {
    arrayOfNoteAndOutcome.push(<Text>{stepOutreachLog.note}</Text>);
  }

  return (
    <>
      <Text color={Colors.FoldPixel.GRAY300} mr={2}>
        {arrayOfTextElements.map((element, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Text fontWeight={'bold'}> • </Text>}
            {element}
          </React.Fragment>
        ))}
      </Text>
      <Text color={Colors.FoldPixel.GRAY300}>
        {arrayOfNoteAndOutcome.map((element, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Text fontWeight={'bold'}> - </Text>}
            {element}
          </React.Fragment>
        ))}
      </Text>
    </>
  );
};

export const isConsentRequiredAndGiven = (contactData: any, isRequired: boolean) => {
  const isGiven = isContactConsentGiven(contactData);
  if (!isRequired) {
    return true;
  }
  return isGiven && isRequired;
}

export default {
  getIconComponent,
  getTextBySourceTypeCode,
  getInfoListView,
  formatAttributes,
  sortOutreachStepBySequence,
  getFormattedDataForUpdate,
  getUserMapByUUID,
  getSubtitleText,
  getEnabledContactModes,
  isConsentRequiredAndGiven,
};
