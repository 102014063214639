import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    padding: 12,
    paddingHorizontal: 0
  },
  formElement: {
    flex: 1,
    marginBottom: 12,
  },
  labelText: {
    marginBottom: 4,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
