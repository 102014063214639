import {
  View,
  Text,
  ScrollView,
  Center,
  Spinner,
  Pressable,
  Actionsheet,
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';

import React from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../../styles';
import {BUTTON_TYPE, GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {Popover} from 'antd';
import {useQuery} from '@apollo/client';
import {GET_ELATION_USERS_HAVING_EXT_USER_IDS} from '../../../../../../services/User/UserQueries';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../constants/Configs';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {debounce} from 'lodash';
import {useToggle} from 'react-use';
import {IUser} from '../../../../../../Interfaces';
import {Dimensions, FlatList, Keyboard} from 'react-native';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {SearchBar} from '../../../../../common/SearchBar';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {CustomScrollView} from '../../../../../common/ScrollView/CustomScrollView';
import Stack from '../../../../../common/LayoutComponents/Stack';

interface ProviderSelectionForNoteSaveProps {
  loading: boolean;
  disabled: boolean;
  onSelect: (user: IUser) => void;
}

const ProviderSelectionForNoteSave = (
  props: ProviderSelectionForNoteSaveProps
) => {
  const {loading, disabled} = props;
  const intl = useIntl();
  const [open, toggle] = useToggle(false);
  const width = Dimensions.get('window').width;

  const userQuery = useQuery<{
    users: IUser[];
  }>(GET_ELATION_USERS_HAVING_EXT_USER_IDS, {
    fetchPolicy: 'no-cache',
    variables: {
      name: '%%',
    },
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
    },
  });

  const renderUserInfoMessage = () => (
    <Stack
      direction="row"
      style={{
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 8,
        padding: 8,
        marginHorizontal:16,
        marginBottom: 8,
      }}
    >
      <Feather name="info" size={16} color={Colors.Custom.Gray500} />
      <Text
        pl={1}
        fontSize={12}
        textAlign={isWeb() ? undefined : 'center'}
        color={Colors.Custom.Gray500}
      >
        {intl.formatMessage({
          id: 'theSelectedProviderWillBeTheAuthorOfTheCurrentNote',
        })}
      </Text>
    </Stack>
  );

  const renderItem = React.useCallback(
    ({item}: {item: IUser}) => {
      const roleString = item?.userRoles
        ?.map((role) => role?.userRole?.userRole?.value)
        .filter((role) => !!role?.length)
        .join(', ');
      return (
        <Pressable
          _hover={{
            backgroundColor: Colors.Custom.Gray100,
          }}
          _pressed={{
            backgroundColor: Colors.Custom.Gray100,
          }}
          rounded={'md'}
          flexDir={'row'}
          mb={2}
          p={2}
          alignItems={'flex-start'}
          onPress={() => {
            toggle();
            const user:IUser = {
              ...item,
              externalUserId: item?.accountUsers?.[0]?.externalUserId,
            }
            props.onSelect(user);
          }}
        >
          <DisplayCardAvatar
            avatarStyle={{
              width: 36,
              height: 36,
              disableTop: true,
              textStyle: {
                fontSize: 16,
              },
            }}
            userData={{
              userType: GROUP_MEMBER_TYPE.USER,
              userId: item?.userId || '',
              name: item?.name,
              userName: item?.name,
              imgSrc: '',
            }}
            isLetterAvatarShow
          />
          <Stack  direction='column' style={{
            marginLeft: isWeb() ? 10 : 8,
            width: isWeb() ? '80%' : '100%',
          }}>
            <Text color={Colors.Custom.Gray700} fontWeight={'400'}>
              {item?.name}
            </Text>
            <Text
              fontSize={12}
              fontWeight={'400'}
              color={Colors.Custom.Gray500}
              maxW={'90%'}
              isTruncated
            >
              {roleString}
            </Text>
          </Stack>
        </Pressable>
      );
    },
    [props.onSelect]
  );

  const renderSaveButton = () => (
    <FoldButton
      customProps={{
        btnText: intl.formatMessage({id: 'save'}),
      }}
      nativeProps={{
        backgroundColor: Colors.Custom.BackgroundColor,
        isLoading: loading,
        variant: BUTTON_TYPE.PRIMARY,
        isDisabled: disabled,
        color: Colors.Custom.mainPrimaryPurple,
        rightIcon: <AntIcon name="down" size={12} />,
        onPress: () => {
          Keyboard.dismiss(); 
          toggle(); 
        },
      }}
    />
  );

  const renderMainListView = () => {
    return (
      <CustomScrollView
        style={{
          flexGrow: 1,
          width: isWeb() ? undefined : width - 20,
        }}
      >
        <View width={isWeb() ? 350 : undefined}>
          <View paddingX={4} paddingTop={4}>
            <SearchBar
              placeholderText="Search Users"
              onChange={debounce(
                (value) =>
                  userQuery.refetch({
                    name: `%${value}%`,
                  }),
                200
              )}
            />
            <Text
              fontSize={14}
              fontWeight={'400'}
              color={Colors.Custom.Gray500}
              pt={2}
            >
              {intl.formatMessage({id: 'saveOnBehalfOf'})}
            </Text>
          </View>

          <ScrollView maxH={400} padding={2}>
            {userQuery.loading ? (
              <Center h="100%" my={2}>
                <Spinner />
              </Center>
            ) : (
              <Stack direction='column'>
                {userQuery.data?.users?.length ? (
                  <FlatList
                    renderItem={renderItem}
                    data={userQuery.data?.users || []}
                    keyExtractor={(item) => item.uuid}
                  />
                ) : (
                  <Text
                    textAlign={'center'}
                    color={Colors.Custom.Gray500}
                    fontWeight={'400'}
                    fontSize={12}
                    my={5}
                  >
                   {intl.formatMessage({id: 'noUserFound'})}
                  </Text>
                )}
              </Stack>
            )}
          </ScrollView>
          {isWeb() && renderUserInfoMessage()}
        </View>
      </CustomScrollView>
    );
  };

  if (!isWeb()) {
    return (
      <>
        {renderSaveButton()}
        <Actionsheet isOpen={open} onClose={() => toggle(false)}>
          <Actionsheet.Content backgroundColor={'white'} maxH={500}>
            {renderMainListView()}
            {renderUserInfoMessage()}
          </Actionsheet.Content>
        </Actionsheet>
      </>
    );
  }

  return (
    <Popover
      overlayInnerStyle={{
        borderRadius: 16,
      }}
      overlayClassName="note-template"
      placement="bottom"
      open={open}
      onOpenChange={toggle}
      content={renderMainListView()}
    >
      {renderSaveButton()}
    </Popover>
  );
};

export default ProviderSelectionForNoteSave;
