import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Icon, Text, VStack} from 'native-base';
import { useLazyQuery } from '@apollo/client';
import { ISearchFieldProps } from '../../../../../../../Interfaces';
import { IGoal } from '../AddOrUpdateGoals/interface';
import CarePlanGoalQueries from '../../../../../../../services/CarePlanGoalQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { Colors } from '../../../../../../../styles';

interface IAdditionalHealthComponentSearchProps extends ISearchFieldProps {
  placeholder?: string;
  disabled?: boolean;
  addNewOptionEnabled?: boolean;
  clearOnSelection?: boolean;
  additionalHeaders?: {[index: string]: any};
  skipAPICall?: boolean;
  showAdditionalDetails?: boolean;
  isInvalid?: boolean;
  filterGoals?: IGoal[];
}

interface IGoalTemplate {
  id?: string
  title: string;
  entityTypeId?: string;
  template?: IGoal;
}

interface IHealthComponentState {
  loading: boolean;
  searchValue: string;
  healthComponentData: IGoalTemplate[];
  selectedValue?: IGoal;
  offset: number;
  pageSize: number;
  dataFinished: boolean;
}

const GoalTemplateSearch = (props: IAdditionalHealthComponentSearchProps) => {
  const defaultOffset = 0;
  const defaultPageSize = 25;
  const addNewPrefix = "Add "
  const {value, isShowError, isInvalid, onChange} = props;

  const [componentState, setComponentState] = useState<IHealthComponentState>({
    loading: false,
    searchValue: '',
    healthComponentData: [],
    selectedValue: undefined,
    offset: defaultOffset,
    pageSize: defaultPageSize,
    dataFinished: false,
  })

  const [
    getGoalTemplate,
  ] = useLazyQuery(CarePlanGoalQueries.GET_GOAL_TEMPLATES, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

const searchValue = (searchString: string, forceTrigger?: boolean) => {
    const offset = defaultOffset;
    const pageSize = defaultPageSize;
    setComponentState((prev) => ({
      ...prev,
      searchValue: searchString,
      offset: offset,
      pageSize: pageSize,
      dataFinished: false,
      healthComponentData: [],
    }));
    if (props.skipAPICall) {
      const list: IGoalTemplate[] = [];
      if (props.addNewOptionEnabled) {
        list.push({
          title: searchString.trim(),
        });
      }
      setComponentState((prev) => ({...prev, healthComponentData: list}));
    } else if (searchString.length > 1 || forceTrigger) {
      callAPI(searchString, offset, pageSize);
    }
  };

  const callAPI = (searchString: string, offset: number, pageSize: number) => {
    setComponentState((prev) => ({...prev, loading: true}));
    getGoalTemplate({
      variables: {
        searchTerm: searchString,
        limit: pageSize,
        offset: offset,
      }
    })
      ?.then((response) => {
        let list: IGoalTemplate[] = [];
        const totalCount = response?.data?.getGoalTemplates?.total || 0;
        if (response?.data?.getGoalTemplates?.results?.length) {
          list = response?.data?.getGoalTemplates?.results;
        }
        setComponentState((prev) => {
          let finalList = prev.healthComponentData;
          const fetchedCount = list.length;
          if (props.addNewOptionEnabled && finalList?.length === 1 && !finalList?.[0]?.id) {
            finalList.splice(0, 1);
          }
          finalList = offset !== 0 ? [...finalList, ...list] : list;
          let filteredListTiles: string[] = [];
          if (props.filterGoals && props.filterGoals.length > 0) {
            filteredListTiles = props.filterGoals?.map((goal) =>
              goal.title?.toLocaleLowerCase()
            );
            finalList = finalList.filter(
              (goal) =>
                !filteredListTiles.includes(goal.title?.toLocaleLowerCase())
            );
          }

          if (
            props.addNewOptionEnabled &&
            finalList?.length === 0 &&
            !filteredListTiles.includes(
              searchString.trim()?.toLocaleLowerCase()
            )
          ) {
            finalList.push({
              title: searchString.trim(),
            });
          }
          return {
            ...prev,
            loading: false,
            dataFinished: fetchedCount === 0 || totalCount >= finalList?.length,
            offset: offset,
            healthComponentData: finalList,
            originalData: finalList,
          };
        });
      })
      .catch((error) => {
        setComponentState((prev) => ({...prev, loading: false}));
      });
  }

  const getDataFromId = (id: string) => {
    const matchedData = componentState.healthComponentData.filter((item) => {
      return item?.id === id;
    });
    if (matchedData.length > 0) {
      return matchedData[0];
    }
  };

  const checkIdMissingInData = () => {
    return componentState.healthComponentData.length === 1 && componentState.healthComponentData.some((item) => !item?.id)
  }

  const getSelectedValue = () => {
    if (props?.clearOnSelection) {
      return componentState.selectedValue
        ? {
            label: <Text>{componentState.selectedValue?.title}</Text>,
            value: componentState.selectedValue?.id,
            key: componentState.selectedValue?.id,
          }
        : undefined;
    } else {
      return value ? value.title : undefined;
    }
  }

  return (
    <Select
      size="large"
      showSearch
      disabled={props.disabled}
      allowClear
      suffixIcon={
        !componentState.searchValue && !props.skipAPICall ? (
          <Icon
            color={Colors.Custom.Gray500}
            as={AntIcon}
            name={'search1'}
            size="4"
          />
        ) : null
      }
      status={isInvalid ? 'error' : undefined}
      filterOption={false}
      value={getSelectedValue()}
      onSearch={debounce(searchValue, 500)}
      onChange={(value: any[], data: any) => {
        if (data?.label) {
          let selectedValue: IGoal | undefined;
          setComponentState((prev) => ({...prev, searchValue: data.label}));
          if (data?.value) {
            const goalTemplate = getDataFromId(data.value);
            selectedValue = goalTemplate?.template;
            if (selectedValue) {
              selectedValue.templateId = goalTemplate?.id;
            }
          }
          if (!selectedValue) {
            selectedValue = {
              title: data?.label,
              goalTargets: [],
            };
          }
          onChange(selectedValue);
          if (props.clearOnSelection) {
            setComponentState((prev) => ({...prev, selectedValue: selectedValue, healthComponentData: []}));
            setTimeout(() => {
              setComponentState((prev) => ({
                ...prev,
                selectedValue: undefined,
              }));
            }, 500);            
          }
        }
         else {
          onChange(undefined);
          setComponentState((prev) => ({...prev, searchValue: ''}));
        }
      }}
      placeholder={props.placeholder || 'Search'}
      loading={componentState.loading}
      notFoundContent={componentState.loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'pami-search field-error' : 'pami-search'}
      optionLabelProp={checkIdMissingInData() ? "label" : undefined}
    >
      {componentState.healthComponentData.map((component, index) => {
        return (
          <Select.Option key={`${component.title}_${index}`} value={component?.id} label={component.title}>
              <VStack>
                <Text>
                  {props.addNewOptionEnabled && !component?.id ? `${addNewPrefix}${component?.title}` : `${component?.title}`}
                </Text>
              </VStack>
          </Select.Option>
        );
      })}
      {componentState.healthComponentData.length && componentState.loading && (
        <Select.Option key={`goal_template_loading`} disabled>
          <Spin size="small" />
        </Select.Option>
      )}
    </Select>
  );
};

export default GoalTemplateSearch;
