import {HStack, Pressable, Skeleton, View, VStack, Text, Spinner} from 'native-base';
import {GROUP_MEMBER_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {IGroupUser, IUserRoles} from '../../../../../services/Conversations/interfaces';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import SendMesgSvg from '../../../../common/Svg/SendMesgSvg';
import RoleDataListView from './RoleDataListView';
import { Tooltip } from 'antd';
import {useState} from 'react';
import CrossSvgIcon from '../../../../common/Svg/CrossSvgIcon';
import {Colors} from '../../../../../styles';
import UnassignUserSvg from '../../../../common/Svg/UnassignUserSvg';
import { TestIdentifiers, testID } from '../../../../../testUtils';

const UserListView = (props: any) => {
  const {loadingStates, users, selectedUsers, selectionLoading} = props;
  const [userListViewState, setUserListViewState] = useState({
    showUnassigned: false
  })
  const getRoles = (userRoles: any) => {
    return (userRoles)?.map((role: any) => {
      return (
        <Text mr={1} fontSize={12} fontWeight={400} color={'gray.500'}>{role?.userRole?.userRole?.value + ','}</Text>
      );
    });
  };
  return (
    <>
      <View style={{position: 'relative'}}>
        {selectionLoading && (
          <Spinner
            style={{
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
              zIndex: 12

            }}
            position={'absolute'}
            {...testID(TestIdentifiers.lazyLoading)}
          />
        )}
        {loadingStates ? (
          <View padding={3} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton.Text lines={3} />
          </View>
        ) : (
          <View overflow={'scroll'} maxHeight={400}>
            {
              users &&
              users.length > 0 &&
              users?.map((userItem: IGroupUser, index: number) => {
                return (
                  <Pressable
                    key={index}
                    onPress={() => {
                      props.onActionPerformed(
                        COMMON_ACTION_CODES.ASSIGN_USER,
                        userItem
                      );
                    }}
                    {...testID('UsderItemClick')}
                    style={[{position: 'relative'}, {flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}]}
                  >
                    <HStack marginY={1} marginX={1} alignItems={'center'} style={{width: '70%'}}>
                      <VStack>
                        <DisplayCardAvatar
                          avatarStyle={{
                            avatarSize: '12',
                          }}
                          isLetterAvatarShow={true}
                          userData={{
                            userId: userItem?.id || '',
                            userType: GROUP_MEMBER_TYPE.USER,
                            userName: userItem.name,
                          }}
                        />
                      </VStack>
                      <VStack>
                        <VStack marginX={2}>
                          <Tooltip title={userItem?.name} placement="topLeft">
                            <Text
                              color={'black'}
                              fontSize={14}
                              fontWeight={500}
                              numberOfLines={1}
                              maxW={140}
                              isTruncated
                              {...testID('UserName')}
                            >
                              {userItem?.name}
                            </Text>
                          </Tooltip>
                        </VStack>
                        <View marginX={2}>
                          <HStack
                            flex={1}
                            style={{
                              width: 230,
                            }}
                            {...testID('RoleListView')}
                          >
                            <RoleDataListView
                              userRoles={userItem?.userRoles || [] as IUserRoles[]}
                              isPopupView={true}
                            />
                          </HStack>
                        </View>
                      </VStack>
                    </HStack>
                    {selectedUsers.id == userItem?.id  ? (
                      <Pressable onPress={() => {
                        props.onActionPerformed(
                          COMMON_ACTION_CODES.UNASSIGN,
                          userItem
                        );
                      }}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip title='Unassign'>
                        <View style={{
                          width: 20, height: 20,
                          alignItems: 'center', alignSelf: 'center',
                          marginRight: 8
                        }}
                        {...testID('Unassign')}>
                          <UnassignUserSvg customStrokeColor={Colors.error[600]} />
                        </View>
                        </Tooltip>
                      </Pressable>
                    ) : (
                      <></>
                    )}
                  </Pressable>
                );
              })
            }
          </View>
        )}
      </View>
    </>
  );
};

export default UserListView;
