import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Input,
  Pressable,
  ScrollView,
  Spacer,
  Spinner,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';
import { getTitle } from '../AddOrUpdateTemplate/AddOrUpdateTemplateHelper';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { notification, Popover, Tooltip } from 'antd';
import PinnedIcon from '../../../../../../assets/Icons/PinnedIcon';
import PinIcon from '../../../../../../assets/Icons/PinIcon';
import { getCurrentEHR } from '../../PatientNotesHelper';
import {EHRName} from '../../interfaces';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {IFormListState} from '../DocumentationView';
import CreateForm from '../CreatePatientNote';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import FormsQueries, { GET_FORM_CATEGORIES, GET_NOTES_FORM } from '../../../../../../services/Forms/FormsQueries';
import { IFormCategory } from '../../../../../RightSideContainer/Forms/interfaces';
import { getFormList } from '../DocumentationViewHelper';
import { getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { BUTTON_TYPE, MLOV_CATEGORY, SMALL_WINDOW, SMALL_WINDOW_1700 } from '../../../../../../constants';
import { USER_PREFERENCE_CODE } from '../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { useIntl } from 'react-intl';
import { clipText } from '../AddOrUpdateTemplate/helper';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { SearchBar } from '../../../../../common/SearchBar';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { CustomHeaderBackArrow } from '../../../../../NativeComponents/CustomHeaderBackArrow';
import CustomSelect from '../../../../../common/CustomComponents/CustomSelect/CustomSelect';
import {
  getEhrConfig,
  isDefaultNoteAllowed,
} from '../../../CareTimeline/CareTimelineUtils';
import {debounce} from 'lodash';
export const GLOBAL_INPUT_BORDER_RADIUS = 18;

const Header = (props: {
  title: JSX.Element;
  backButtonDisabled?: boolean;
  headerAction?: JSX.Element;
  form: {
    id: string;
    name: string;
  };
  showTemplateSelection: boolean;
  currentTemplateId?: string;
  defaultNote?: {
    id: string;
    formId: string;
  };
  showNoteTitleInput?: boolean;
  showBackButton?: boolean;
  disableDefaultTemplateSelection?: boolean;
  backButtonAction: () => void;
  handleSelectedTemplateChange?: (templateId: string, templateName?: string) => void;
  handleNewlyCreatedNote?: (noteId: string, templateName?: string) => void;
  handleDefaultNoteStateChange?: (templateId: string) => void;
  onNoteTitleChange?: (title: string) => void;
  navigation?: any;
  noteTitle?: string;
  showNoteTitleErrorText?: boolean
}) => {
  const {
    title,
    backButtonDisabled,
    headerAction,
    form,
    showTemplateSelection,
    currentTemplateId,
    defaultNote,
    navigation,
    backButtonAction,
    handleSelectedTemplateChange,
    handleNewlyCreatedNote,
    handleDefaultNoteStateChange,
    showNoteTitleErrorText
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const userPreferenceList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.DEFAULT_FORM
  );
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: SMALL_WINDOW },
    { maxWidth: SMALL_WINDOW_1700 },
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false
  const currentEHR = getCurrentEHR();
  const userUUID = getUserUUID();
  const isFold = currentEHR === EHRName.FOLD;
  const isElation = currentEHR === EHRName.ELATION;
  const isAthena = currentEHR === EHRName.ATHENA;
  const ehrConfig = getEhrConfig(currentEHR);
  const [createForm, setCreateForm] = useState(false);
  const [formList, setFormList] = useState<IFormListState[]>([]);
  const [soapNoteCategoryId, setSoapNoteCategoryId] = useState('');
  const [isActionPopoverVisible, setIsActionPopoverVisible] = useState(false);
  const [loadingId, setLoadingId] = useState('');
  const [searchString, setSearchString] = useState('');
  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const showActionSheet = () => setActionSheetVisible(true);
  const hideActionSheet = () => setActionSheetVisible(false);
  const [
    createOrUpdatePatientDefaultNote,
    { loading: updateDefaultFormLoading },
  ] = useMutation(FormsQueries.CREATE_USER_DEFAULT_NOTE_FORM, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [
    getAllNotesFrom,
    { loading: allNotesLoading, refetch: refetchAllNotes },
  ] = useLazyQuery(GET_NOTES_FORM, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (data: any) => {
      const formData = data?.forms.filter(
        (item: any) => item.id === defaultNote?.formId
      );
      if (formData.length > 0) {
        formData.push(
          ...data?.forms.filter((item: any) => item.id !== defaultNote?.formId)
        );
        setFormList(formData);
      } else {
        setFormList(data?.forms);
      }
    },
  });
  const { loading: allCategoriesLoading } = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    skip: !(isFold || isAthena),
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
    },
    onCompleted(data) {
      const noteCategoryId = data?.formCategories?.filter(
        (category: IFormCategory) => category.code === 'SOAP'
      )?.[0]?.id;
      setSoapNoteCategoryId(noteCategoryId);
      getAllNotesFrom({
        variables: {
          categoryId: noteCategoryId,
          searchString: '%%',
        },
      });
    },
  });
  const showCreateFormModal = (show: boolean) => {
    setCreateForm(show);
  };
  const handleDefaultNoteChange = (noteId: string) => {
    setLoadingId(noteId);
    const data = {
      object: {
        userId: userUUID,
        userPreferencesTypeId: userPreferenceId,
        preferencesJson: JSON.stringify({ defaultForm: noteId }),
        id: defaultNote?.id ? defaultNote?.id : undefined,
      },
    };
    createOrUpdatePatientDefaultNote({
      variables: data,
      onCompleted: (data: any) => {
        if (data.createUserPreference.id) {
          handleDefaultNoteStateChange?.(noteId);
          setLoadingId('');
          notification.success({
            message: 'Default note updated successfully',
          });
        }
      },
      onError: (error: any) => {
        setLoadingId('');
        notification.error({
          message: 'Error in updating default note',
        });
      },
    });
  };
  const templateListLoading = allNotesLoading || allCategoriesLoading;
  useEffect(() => {
    if (isElation) {
      const data = getFormList(isElation, elationFormData);
      setFormList(data);
    }
  }, []);
  useEffect(() => {
    if (defaultNote?.formId !== '' && formList.length > 0) {
      if (formList[0].id !== defaultNote?.formId) {
        setFormList((prevFormList: any) => {
          const defaultNoteItem = prevFormList.filter(
            (item: any) => item.id === defaultNote?.formId
          );
          const otherItems = prevFormList.filter(
            (item: any) => item.id !== defaultNote?.formId
          );
          if (defaultNoteItem.length > 0) {
            return [defaultNoteItem[0], ...otherItems];
          } else {
            return prevFormList;
          }
        });
      }
    }
  }, [defaultNote?.formId]);

  const selectedTemplateCatergorId = formList?.find(
    (item) => item.id === form.id
  )?.categoryId;


  const renderTemplateSelectionView = () => {
    let view: JSX.Element = <></>;
    if (isWeb()) {
      view = (
        <Popover
          overlayInnerStyle={{
            borderRadius: 16,
          }}
          overlayClassName="note-template"
          placement="bottom"
          content={
            <View width={350}>
              <View paddingX={5} paddingY={4}>
                <SearchBar
                  placeholderText="Search Template"
                  onChange={(searchString) => {
                    setSearchString(searchString);
                  }}
                />
              </View>
              <Divider />
              <ScrollView maxH={300} minHeight={120} padding={2}>
                {templateListLoading && (
                  <Center h="100%">
                    <Spinner size={'lg'} />
                  </Center>
                )}
                {!templateListLoading &&
                  formList
                    ?.filter((item) =>
                      item.name
                        .toLocaleLowerCase()
                        .includes(searchString.toLocaleLowerCase())
                    )
                    ?.map((item, index) => (
                      <Pressable
                        key={`${item.id}_${index}`}
                        px={3}
                        pt={1}
                        cursor={
                          currentTemplateId !== item.id ? 'pointer' : undefined
                        }
                        disabled={currentTemplateId === item.id}
                        onPress={() => handleSelectedTemplateChange?.(item.id, item.name)}
                      >
                        <HStack my={2} alignItems="center">
                            <Text
                              flex={1}
                              selectable={false}
                              color={
                                currentTemplateId !== '' &&
                                currentTemplateId === item.id
                                  ? Colors.Custom.PurpleColor
                                  : Colors.Custom.Gray500
                              }
                            >
                              {item.name}
                            </Text>
                          <Spacer />
                          {isDefaultNoteAllowed(ehrConfig) &&
                            !props?.disableDefaultTemplateSelection && (
                              <>
                                {loadingId === item?.id &&
                                updateDefaultFormLoading ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <Pressable
                                    onPress={() =>
                                      !(defaultNote?.formId === item.id) &&
                                      handleDefaultNoteChange(item.id)
                                    }
                                  >
                                    {defaultNote?.formId === item.id ? (
                                      <PinnedIcon />
                                    ) : (
                                      <PinIcon />
                                    )}
                                  </Pressable>
                                )}
                              </>
                            )}
                        </HStack>
                        {formList?.filter((item) =>
                          item.name
                            .toLocaleLowerCase()
                            .includes(searchString.toLocaleLowerCase())
                        )?.length !==
                          index + 1 && <Divider />}
                      </Pressable>
                    ))}
                {!templateListLoading &&
                  formList?.filter((item) =>
                    item.name
                      .toLocaleLowerCase()
                      .includes(searchString.toLocaleLowerCase())
                  )?.length === 0 && (
                    <Text
                      color={Colors.Custom.Gray500}
                      paddingX={4}
                      paddingY={5}
                    >
                      {'No template found'}
                    </Text>
                  )}
              </ScrollView>
              {(isFold || (isAthena && foldVisitNoteWithEncountersEnabled)) && (
                <>
                  <Divider />
                  <Pressable
                    paddingX={5}
                    paddingY={3}
                    onPress={() => showCreateFormModal(true)}
                  >
                    <HStack my={2} alignItems="center">
                      <Text
                        color={Colors.Custom.PurpleColor}
                        fontWeight="semibold"
                      >
                        {intl.formatMessage({ id: 'create_new_template' })}
                      </Text>
                      <Spacer />
                      <View paddingRight={4}>
                        <AntIcon
                          name="right"
                          size={12}
                          color={Colors.Custom.PurpleColor}
                        />
                      </View>
                    </HStack>
                  </Pressable>
                </>
              )}
            </View>
          }
        >
          <Button
            rightIcon={<AntIcon name="down" size={12} />}
            mx={2}
            backgroundColor={Colors.Custom.BackgroundColor}
            borderColor={Colors.Custom.PurpleColor}
            _hover={{
              backgroundColor: Colors.Custom.PurpleColor,
              _text: {
                color: 'white',
              },
            }}
            maxW={'1/3'}
            _text={{
              color: Colors.Custom.PurpleColor,
              fontWeight: 'bold',
            }}
            borderWidth={1}
            style={{
              borderRadius: 6,
            }}
          >
            {form.id ? clipText(form.name, isSmallScreen ? 10 : 20) : 'Select Template'}
          </Button>
        </Popover>
      )
    } else {
      view = (
        <Box minH={54} mt={4}>
          <CustomSelect
            label=""
            placeholder="Select Template"
            value={form.id}
            options={formList}
            isDisabled={!formList.length}
            optionProps={{key: 'id', value: 'id', label: 'name'}}
            styles={{
              labelColor: Colors.Custom.Gray700,
              borderColor: Colors.Custom.Gray300,
              borderRadius: GLOBAL_INPUT_BORDER_RADIUS,
              height: 50,
            }}
            customDropDownIcon={
              <Feather
                name="chevron-down"
                size={20}
                color={Colors.Custom.Gray200}
              />
            }
            customRenderOption={(item: IFormListState) => {
              return (
                <HStack alignItems={'center'} minW={'full'}>
                  <Text>{item.name}</Text>
                </HStack>
              );
            }}
            onSelect={(option) => {
              handleSelectedTemplateChange?.(option.id);
            }}
          />
        </Box>
      );
    }
    return view;
  }

  return (
    <VStack>
      <VStack
        borderBottomWidth={1}
        borderBottomColor={Colors.Custom.Gray200}
        w="100%"
        px={2}
        paddingY={4}
        backgroundColor={Colors.Custom.Gray100}
        style={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}
      >
        <HStack alignItems="center">
          {!isWeb() && props.showBackButton && (
            <CustomHeaderBackArrow
              onPress={() => {
                navigation?.goBack();
              }}
              iconType={''}
            />
          )}
          {title}
          {isWeb() && showTemplateSelection && renderTemplateSelectionView()}
          <Spacer />
          <HStack space={2} alignItems="center">
            {headerAction}
          </HStack>
          {isWeb() ? (
            <>
              <CreateForm
                showModal={createForm}
                onCancel={() => {
                  showCreateFormModal(false);
                }}
                quickNoteCreation={true}
                noteCategoryId={soapNoteCategoryId}
                handleNewlyCreatedNote={(noteId, templateName?: string) => {
                  refetchAllNotes();
                  handleNewlyCreatedNote?.(noteId, templateName);
                }}
                style={{margin: -20}}
              />

              {props.showBackButton && (
                <Button
                  backgroundColor={Colors.Custom.Gray100}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 30,
                    marginLeft: 10,
                  }}
                  disabled={backButtonDisabled}
                  onPress={() => {
                    backButtonAction();
                  }}
                >
                  <AntIcon name="close" size={18} />
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </HStack>

        {props?.showNoteTitleInput ? (
          <View
            style={{
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            <Input
              defaultValue={props?.noteTitle}
              onChangeText={debounce((text) => {
                props?.onNoteTitleChange?.(text);
              }, 500)}
              background={'white'}
              placeholder="Enter note title here"
            />
            {showNoteTitleErrorText && (
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.danger[600],
                  fontWeight: 500,
                  fontSize: 14,
                }}
                textLocalId={"mandatoryNoteTitleFieldError"}
                testID={'MandatoryNoteTitleFieldError'}
              />
            )}
          </View>
        ) : null}
        {!isWeb() && showTemplateSelection && renderTemplateSelectionView()}
      </VStack>
    </VStack>
  );
};

export default Header;
