import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  btnStyle: {
    height: 40,
    borderWidth: 1,
    minWidth: 64,
    paddingHorizontal: 14,
    paddingVertical: 8,
  },
  secondarybtnStyle: {
    borderWidth: 1,
    borderColor: Colors.Custom.mainSecondaryBrown,
    backgroundColor:'#ffffff00'
  },
  primarybtnStyle: {
    borderColor: Colors.Custom.mainPrimaryPurple,
    borderWidth: 1,
    backgroundColor:'#ffffff00'
  },
  tertiarybtnStyle: {
    borderWidth: 1,
  },
});
