import { Drawer } from 'antd';
import { Center, Spinner, View } from 'native-base';
import {useEffect, useState} from 'react';
import { BUTTON_TYPE } from '../../../constants/StringConst';
import {getOrderingURLFromIntegration} from '../../../services/CommonService/OrderService';
import { Colors } from '../../../styles/Colors';
import {OrderType} from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import {getOrderType} from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import {IDoseSpotNotification, IDoseSpotNotificationState} from './interfaces';

export const DoseSpotNotification = (props: IDoseSpotNotification) => {

  const height = window.innerHeight - 124;
  const [doseSpotNotificationState, setDoseSpotNotification] =
    useState<IDoseSpotNotificationState>({
      type: props.type,
      notification: props.notification,
      showModal: true,
      loading: false,
      iframeUrl: '',
      iframeLoading: false,
    });

  const getIframeUrl = () => {
    getOrderingURLFromIntegration(
      {
        category: 'MEDICATION',
        isPractitionerNotification: true,
      },
      response => {
        setDoseSpotNotification(prev => ({
          ...prev,
          loading: false,
          ...(response?.data?.widgetUrl && { iframeUrl: response.data.widgetUrl }),
        }));
      },
      error => {

        setDoseSpotNotification(prev => ({
          ...prev,
          loading: false,
          iframeUrl: '',
        }));
      },
    );
  };

  const onClose = () => {
    setDoseSpotNotification(prev => ({
      ...prev,
      showModal: false,
    }));
    props.onClose();
  }

  useEffect(() => {
    setDoseSpotNotification(prev => ({
      ...prev,
      loading: true,
      iframeLoading: true
    }));
    try {
      getIframeUrl();
    } catch (error) {
    }
  }, [props?.notification?.id]);


  return (
    <Drawer
      headerStyle={{borderWidth: 0, marginBottom: 0}}
      destroyOnClose
      placement="right"
      onClose={onClose}
      visible={doseSpotNotificationState.showModal}
      closable
      width={'80%'}
      title={
        <>
          <ModalActionTitle
            title={'prescriberNotificationDetail'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: onClose,
              },
            ]}
          />
        </>
      }
    >
      <View height={height} marginX={-2}>
        {(doseSpotNotificationState.loading || doseSpotNotificationState.iframeLoading)  && (
            <Center h='100%'>
              <Spinner size={'lg'} />
            </Center>
          )}
        {!doseSpotNotificationState.loading && doseSpotNotificationState.iframeUrl && (
          <iframe
            src={doseSpotNotificationState.iframeUrl}
            height="100%"
            width="100%"
            style={{border: 'none', visibility: doseSpotNotificationState.iframeLoading ? 'hidden' : 'visible'}}
            onLoad={() => {
              setDoseSpotNotification((prev) => ({
                ...prev,
                iframeLoading: false,
              }));
            }}
          />
        )}
      </View>
    </Drawer>
  )
};
