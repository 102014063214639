import {Drawer} from 'antd';
import {CheckIcon, Pressable, Spinner, Text, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  MLOV_CATEGORY,
  SENDER_TYPE,
} from '../../../../../../constants';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../../styles';
import {getMsgEchoId} from '../../../../../../utils/commonUtils';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {getMlovCodeIdObj} from '../../../../../../utils/mlovUtils';
import WorkFlowListByEntityEvents from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import {ModalActionSelect} from '../../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {ContentTypes} from '../../../../ContentManagement/ContentManagementConsts';
import {getTemplates} from '../../../../ContentManagement/ContentManagementUtils';
import {
  getFormattedPEContent,
  getPreviewLinkFromSlug,
} from '../../../../ContentManagement/PatientEducation/PatientEducationUtils';
import {getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {sendEmailMessageAPI} from '../../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {IConversationData} from '../../interfaces';
import {getUserIdListFromMsgContent} from '../ConversationMentions/ConversationMentions';
import {getShortLink, messageDataKeysConverter} from '../MessagingUtils';

export interface IAttachArticleDrawerProps {
  updateMessage?: (message:string) => void;
  isUpdateMode?: boolean;
  isDrawerVisible: boolean;
  conversationData: IConversationData;
  privateNote: string;
  onClose: () => void;
  onMsgSend: (msgText: string, msgData?: any) => void;
  onArticleSelect: (articleLink: string) => void;
  isOnlySelectArticle?: boolean
}

const AttachArticleDrawer = (props: IAttachArticleDrawerProps) => {
  const {
    isDrawerVisible,
    conversationData,
    privateNote,
    onArticleSelect,
    onClose,
    onMsgSend,
  } = props;
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.MLOV;
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const {displayId: conversationId, accountId} = conversationData;

  const [articleState, setArticleState] = useState({
    articleData: [] as any[],
    loading: false,
    selectedArticle: {} as any,
    isSendLoading: false,
  });

  const setLoading = (value: boolean) => {
    setArticleState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  };

  const setOnCloseState = () => {
    setArticleState((prev) => {
      return {
        ...prev,
        selectedArticle: {},
        loading: false,
        isSendLoading: false,
      };
    });
  };

  const getArticleMessageText = async (title: string, link: string) => {
    const shortLink = (await getShortLink(link)) || link;
    return `${title} \n ${shortLink}`;
  };

  const articleLink = getPreviewLinkFromSlug(articleState.selectedArticle.slug);

  const sendArticleLink = async () => {
    if (articleState.selectedArticle && articleState.selectedArticle.id) {
      setArticleState((prev) => {
        return {
          ...prev,
          isSendLoading: true,
        };
      });
      if (props.isOnlySelectArticle) {
        onArticleSelect(articleLink);
        return;
      }
      const textContent = await getArticleMessageText(
        articleState.selectedArticle.title,
        articleLink
      );
      const commonService = CommonService.getCommonServiceInstance();
      const axiosService = commonService.axiosService;
      const formData = new FormData();

      formData.append('echo_id', getMsgEchoId());
      formData.append('private', privateNote);
      formData.append('cc_emails', '');
      formData.append('bcc_emails', '');
      formData.append('content', textContent);
      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && textContent) {
        const userMentionIds = getUserIdListFromMsgContent(textContent, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          formData.append('user_mention_ids', userMentionIdStr);
        }
      }
      if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && privateNote == 'false') {
        sendEmailMessageAPI({
          private: false,
          content: textContent,
          conversationDisplayId: conversationData?.displayId,
          inboxId: conversationData?.inboxId,
          subject: conversationData?.additionalAttributes?.mail_subject || '',
          user_mention_ids: userMentionIdStr
        }).then((response: any) => {
          setOnCloseState();
          if (response && response.data) {
            response.data['sender_type'] = SENDER_TYPE?.USER;
            const messageRepData = messageDataKeysConverter(response.data);
            const currentTime = getDateToMomentISOString();
            const responseData = {...messageRepData, currentTime};
            onMsgSend(messageRepData.content, responseData);
          }
        }).catch((e) => {
          // catch
        });
      } else {
        if(props.isUpdateMode && props.updateMessage) {
            setArticleState((prev) => {
              return {
                ...prev,
                isSendLoading: true,
              };
            });
            props.updateMessage(textContent)
            setOnCloseState();
            onClose();
        } else {
          setArticleState((prev) => {
            return {
              ...prev,
              isSendLoading: true,
            };
          });
          axiosService
            .post(
              `/accounts/${accountId}/conversations/${conversationId}/messages`,
              formData,
              {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              }
            )
            .then((response: any) => {
              setOnCloseState();
              if (response) {
                response.data['sender_type'] = SENDER_TYPE?.USER;
                const currentTime = getDateToMomentISOString();
                const responseData = {...response.data, currentTime};
                onMsgSend(response.data.content, responseData);
                onClose();
              }
            }).catch(e => {
              //catch
            });
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getTemplates(`${ContentTypes.patientEducationArticles.path}`)
      .then((data) => {
        setLoading(false);
        return getFormattedPEContent(data);
      })
      .then((formattedData) => {
        setLoading(false);
        setArticleState((prev) => {
          return {
            ...prev,
            articleData: formattedData,
          };
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );


  return (
    <Drawer
      visible={isDrawerVisible}
      onClose={() => {
        setOnCloseState();
        onClose();
      }}
      destroyOnClose
      width={isSideCarContext ? '100%' : 550}
      mask={isSideCarContext ? false : true}
      closable={false}
      placement="right"
      title={
        <>
          <ModalActionTitle
            title={'Select an Article'}
            titleColor={''}
            buttonList={[
              {
                ...(isSideCarContext && {
                  show: true,
                  id: 1,
                  btnText: 'back',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setOnCloseState();;
                    onClose();
                  },
                }),
              },
              {
                show: true,
                id: 1,
                btnText: props.isOnlySelectArticle ? 'insert' : (props.isUpdateMode ? 'addArticleLink' : 'sendArticleLink'),
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: articleState.isSendLoading,
                isDisabled:
                  articleState.selectedArticle &&
                    articleState.selectedArticle.id
                    ? false
                    : true,
                onClick: () => {
                  sendArticleLink();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack mx={0}>
        {articleState.loading ? (
          <>
            <Spinner />
          </>
        ) : (
          <>
            <ModalActionSelect
              endIcon={<CheckIcon size="5" />}
              selectedValue={articleState.selectedArticle.id}
              placeholder="Select an Article"
              onValueChange={(value: string) => {
                const article = articleState.articleData.find(
                  (item) => item.id === parseInt(value)
                );
                setArticleState((prev) => {
                  return {
                    ...prev,
                    selectedArticle: article,
                  };
                });
              }}
              data={articleState.articleData}
              selectItemProps={{
                key: 'id',
                label: 'title',
                value: 'id',
              }}
              customStyle={{flex: 1}}
              marginBottom={2}
            />

            {/* {articleState.selectedArticle && articleState.selectedArticle?.id && (
              <VStack style={{marginTop: 10}}>
                <Text style={{marginTop: 10, marginBottom: 10}}>
                  Message Preview
                </Text>
                <View
                  style={{
                    padding: 10,
                    backgroundColor: '#FCF9FF',
                    width: 'fit-content',
                    borderRadius: 10,
                    borderColor: Colors.Custom.Gray50,
                    borderWidth: 2,
                    flexDirection: 'column',
                  }}
                >
                  <Text>{articleState.selectedArticle.title}</Text>
                  <Pressable
                    onPress={() => {
                      window.open(articleLink, '_blank');
                    }}
                  >
                    <Text
                      style={{
                        textDecorationColor: 'black',
                        textDecorationStyle: 'solid',
                        textDecorationLine: 'underline',
                      }}
                    >
                      Click here to open article
                    </Text>{' '}
                  </Pressable>
                </View>
              </VStack>
            )} */}

            {false && showAutomationTab && (
              <WorkFlowListByEntityEvents
                addUrl={CUSTOM_AUTOMATION_URL.ARTICAL}
                title={ENTITY_EVENTS_TITLE_SUBTITLE.ARTICAL_WORKFLOW_TITLE}
                subtitle={
                  ENTITY_EVENTS_TITLE_SUBTITLE.ARTICAL_WORKFLOW_SUB_TITLE
                }
                entityEventList={ENTITY_EVENTS_TYPE.ARTICAL}
              // onValueChanage={(eventEntityList: any) => {
              //   setAppointmentBookingState((prev) => ({
              //     ...prev,
              //     workflowList: eventEntityList,
              //   }));
              // }}
              ></WorkFlowListByEntityEvents>
            )}
          </>
        )}
      </VStack>
    </Drawer>
  );
};

export default AttachArticleDrawer;
