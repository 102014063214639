import axios, { AxiosRequestConfig } from 'axios';
import { getEnvVariable } from '../../constants/BaseUrlConst';
import { CARE_STUDIO_NEST_URL } from '../../constants/Configs';
import LocalStorage from '../../utils/LocalStorage';
import { createAxiosInstance } from '../../utils/APIUtils';

export default class CareStudioService {
  private static careStudioServiceInstance: CareStudioService;
  public careStudioAxiosService;
  public fileUploadService;
  public mediaUploadService;
  public createCareJourneyService;
  private commonRequestInterceptor;

  constructor() {
    if (CareStudioService.careStudioServiceInstance) {
      throw new Error('Error');
    }

    this.commonRequestInterceptor = async (config: AxiosRequestConfig<any>) => {
      const userDataString = await LocalStorage.getItem('user');
      const userData = JSON.parse(userDataString);
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      const accountId =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].id || ''
          : '';

      config.headers = {
        ...config.headers,
        fold_access_token: fold_access_token,
        'account-uuid': accountUUID,
        account_id: accountId,
        account: accountId,
        'user-uuid': userData?.data.uuid || '',
        userId: userData?.data.id || '',
      };
      if (userData?.data.uuid) {
        config.headers['x-hasura-user-id'] = userData?.data.uuid;
      }
      return config;
    }

    this.careStudioAxiosService = createAxiosInstance({
      baseURL: CARE_STUDIO_NEST_URL,
      headers: {},
    });

    // interceptor
    this.careStudioAxiosService.interceptors.request.use(
      this.commonRequestInterceptor,
      (error) => {}
    );

    this.fileUploadService = createAxiosInstance({
      baseURL: `${CARE_STUDIO_NEST_URL}/attachment/upload/multiple`,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    this.fileUploadService.interceptors.request.use(
      this.commonRequestInterceptor,
      (error) => {}
    );

    this.mediaUploadService = createAxiosInstance({
      baseURL: `${CARE_STUDIO_NEST_URL}/attachment/uploadExternalAttachment`,
    });

    this.mediaUploadService.interceptors.request.use(
      this.commonRequestInterceptor,
      (error) => {}
    );

    this.createCareJourneyService = createAxiosInstance({
      baseURL: `${CARE_STUDIO_NEST_URL}/careJourney/create`,
      headers: {
        'content-type': 'application/json',
      },
    });

    this.createCareJourneyService.interceptors.request.use(
      async (config: AxiosRequestConfig<any>) => {
        const headers = (await this.commonRequestInterceptor(config)).headers;

        const envStr = getEnvVariable();
        let accessToken = '';
        let tokenType = '';
        let commercesSubdomain = '';

        const accountInfo = await LocalStorage.getItem('account');
        const accountInfoJson = JSON.parse(accountInfo || '{}');
        const subdomainName = accountInfoJson?.subDomain;
        if (subdomainName && envStr.length) {
          commercesSubdomain = subdomainName.replace('.' + envStr + '.', '.website.' + envStr + '.');
        } else if (subdomainName && !envStr.length) {
          const tenantName = subdomainName.replace('.foldhealth.io', '');
          commercesSubdomain = `${tenantName}.website.foldhealth.io`;
        }
        const commerceUserTokenStr = await LocalStorage.getItem('commerceUserToken');
        const commerceUserTokenData = JSON.parse(commerceUserTokenStr || '{}');
        if (commerceUserTokenData) {
          accessToken = commerceUserTokenData.access_token || '';
          tokenType = commerceUserTokenData.token_type || '';
        }

        config.headers = {
          ...config.headers,
          ...headers,
          subdomain: commercesSubdomain,
        };

        if (accessToken && tokenType) {
          config.headers['Authorization'] = `${tokenType} ${accessToken}`;
        }

        return config;
      },
      (error) => {}
    );

    CareStudioService.careStudioServiceInstance = this;
  }
  static getCareStudioServiceInstance(): CareStudioService {
    return (
      CareStudioService.careStudioServiceInstance ??
      (CareStudioService.careStudioServiceInstance = new CareStudioService())
    );
  }
}
