import { View } from 'react-native'
import React, { useContext } from 'react'
import { ITaskDisplayConfig, ITaskMetaData } from '../../TaskInterfaces'
import TaskNestedTableView from './TaskNestedTableView';
import TaskTableView from './TaskTableView';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { ITaskCount } from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import TaskListRowHeaderView from './TaskListRowHeaderView';
import { getSidecarTaskListHeader } from '../../TaskModuleUIHelper';
import { TaskListRowHeaderAction } from './TaskListConstants';
import Stack from '../../../common/LayoutComponents/Stack';
import { Colors } from '../../../../styles';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';

interface ITaskListView {
  config: ITaskDisplayConfig;
  metaData: ITaskMetaData;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onTaskCountUpdate?: (taskCount: ITaskCount[]) => void;
}

const TaskListView = (props: ITaskListView) => {
  const {config, metaData, onActionPerformed, onTaskDetail, onMemberClick, onTaskCountUpdate} = props;
  const useNestedTables = !!config.boardType;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;

  if (!config.categories.length) {
    return <></>;
  }

  const onHeaderActionPerformed = (
    action: TaskListRowHeaderAction,
    data: {
      [index: string]: any;
    }
  ) => {
    switch (action) {
      case TaskListRowHeaderAction.BULK_ACTION_CHANGED:
        onActionPerformed?.(COMMON_ACTION_CODES.ON_BULK_ACTION, {
          bulkActionEnabled: data.bulkEnabled,
        });
        break;

      case TaskListRowHeaderAction.SORT_CHANGED:
        onActionPerformed?.(COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER, {
          sorter: {
            ...(data.sortOrder !== 'none' && { columnKey: data.code }),
            order: data.sortOrder === 'desc' ? 'descend' : 'ascend',
          }
        });
        break;
    }
  }

  return (
    <View>
      {isSidecarContext && (
        <Stack direction="column">
          <TaskListRowHeaderView
            metadata={{
              allowBulkAction: !metaData.personData,
            }}
            bulkActionEnabled={metaData.bulkTaskEnabled || false}
            columns={getSidecarTaskListHeader({
              orderBy: config.categories?.[0]?.filters?.orderBy,
            })}
            onActionPerformed={onHeaderActionPerformed}
          />
          <View style={{ height: 0.5, backgroundColor: Colors.Custom.Gray300, width: '100%' }} />
        </Stack>
      )}
      {
      useNestedTables ?
        <TaskNestedTableView
          categories={config.categories}
          onActionPerformed={onActionPerformed}
          metaData={{...metaData, boardType: config.boardType}}
          onTaskDetail={onTaskDetail}
          onMemberClick={onMemberClick}
          onTaskCountUpdate={onTaskCountUpdate}
        />
      :
        <TaskTableView
          category={config.categories[0]}
          onActionPerformed={onActionPerformed}
          metaData={{...metaData, boardType: config.boardType}}
          onTaskDetail={onTaskDetail}
          onMemberClick={onMemberClick}
        />
      }
    </View>
  )
}

export default TaskListView
