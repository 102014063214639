import {Box, Divider, HStack, Icon, Text, VStack} from 'native-base';
import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {DATE_FORMATS} from '../../../constants';
import {Colors} from '../../../styles';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';

const AppointmentBookingSuccessPage = (props: {
  appointmentName: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  appointmentLocation: string;
  isRSVPAppointment: boolean;
  customWhereMessage?: string;
}) => {

  return (
    <Box>
      <VStack space={2}>
        <VStack alignItems="center" justifyContent="center" space={4}>
          <Icon
            as={AntIcon}
            name="checkcircleo"
            size="12"
            color="success.500"
          />
          <VStack alignItems="center" justifyContent="center">
            <DisplayText textLocalId="thankYou" size="lgBold" />
            <DisplayText
              textLocalId={props.isRSVPAppointment ? "appointmentScheduledRSVPHeader":  "appointmentScheduledHeader"}
              size="lgBold"
            />
          </VStack>
          <Divider />
          <VStack width="full" space={4}>
            <HStack>
              <Text flex={1} size="smNormal" textAlign="center">
                What
              </Text>
              <Text flex={1} size="smNormal">
                {props.appointmentName}
              </Text>
            </HStack>
            <HStack>
              <Text flex={1} size="smNormal" textAlign="center">
                When
              </Text>
              <VStack flex={1}>
                <Text size="smNormal">
                  {getDateStrFromFormat(
                    props.appointmentStartDate || new Date(),
                    'dddd, MMM DD, YYYY'
                  )}
                  {'   '}
                </Text>
                <Text size="smNormal">
                  {getDateStrFromFormat(
                    props.appointmentStartDate || new Date(),
                    DATE_FORMATS.DISPLAY_TIME_FORMAT
                  )}
                  {'  '}-{'  '}
                  {getDateStrFromFormat(
                    props.appointmentEndDate || new Date(),
                    DATE_FORMATS.DISPLAY_TIME_FORMAT
                  )}
                </Text>
              </VStack>
            </HStack>
            <HStack>
              <Text flex={1} size="smNormal" textAlign="center">
                Where
              </Text>
              <Text flex={1} size="smNormal">
                {props?.customWhereMessage || props.appointmentLocation}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default AppointmentBookingSuccessPage;
