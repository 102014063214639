import {StyleSheet} from 'react-native';

const topBottomPadding = 6;
const sidePadding = 16;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  recordRow: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: topBottomPadding,
    paddingTop: topBottomPadding,
    paddingLeft: sidePadding,
    paddingRight: sidePadding,
  },
  titleText: {
    // color: '#344054',
    color: 'black',
  },
  displayText: {
    color: '#667085',
  },
});
