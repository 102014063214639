
import {useLazyQuery, useMutation} from '@apollo/client';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import {Checkbox, DatePicker, notification, Popover, Radio, RadioChangeEvent, Statistic, Switch, Tooltip, Upload} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {UploadFile} from 'antd/lib/upload/interface';
import {AxiosResponse} from 'axios';
import {
  HStack, Pressable,
  Spinner,
  Text, useToast,
  View,
  VStack,
  Divider
} from 'native-base';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';
import Feather from 'react-native-vector-icons/Feather';
import {
  BUTTON_TYPE,
  CONVERSATION_STATUS,
  CONVERSATION_STATUS_STRING,
  getInActiveContactError,
  GROUP_TYPES,
  KEYBOARD_KEY_CODE,
  MLOV_CATEGORY,
  PARENT_CODE,
  PARENT_CODE_CLASS_NAME,
  RIGHT_SIDE_CONTAINER_CODE,
  SENDER_TYPE
} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES
} from '../../../../../constants/ActionConst';
import {CONVERSATION_LOCAL_EVENT_CODES, CUSTOM_MESSAGE_EVENT_CODES, SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {
  IGroupContact,
  IMessageData
} from '../../../../../services/Conversations/interfaces';
import {sendMessageWithRawBody} from '../../../../../services/Message/MessageService';
import {Colors} from '../../../../../styles/Colors';
import {
  findStringDifference,
  canCreateSMSInboxWithPhoneNumber,
  getAccountId,
  getAccountUUID,
  getChannelType,
  getConfigDataFromCode,
  getMsgEchoId,
  getUserData,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelEmailOrSms,
  isChannelTwillioSms,
  isEmployerRole,
  isInternalChannelInboxType,
  isValidJsonString,
  isWebChannelInboxType,
  markdownLinkToPlainText,
  parseHtmlToPlainText
} from '../../../../../utils/commonUtils';
import { showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {disabledDateTime, getDateToMomentISOString, isPastDay} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getContactTypeId, getMlovCodeIdObj} from '../../../../../utils/mlovUtils';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import GroupUserMentionList from '../../../../common/GroupUserMentionList/GroupUserMentionList';
import {DatePickerProps} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {NOTIFICATION_TYPES} from '../../../../common/Notifications/NotificationsUtils';
import EducationContent from '../../../../common/Svg/EducationContent';
import SendButtonSvg from '../../../../common/Svg/SendButtonSvg';
import SendScheduleMsgSvg from '../../../../common/Svg/sendScheduleMsgSvg';
import CalendarSvg from '../../../../common/Svg/SideMenu/CalendarSvg';
import FormSvg from '../../../../common/Svg/SideMenu/FormSvg';
import {AddOrUpdateLead} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {updateSingleNotificationReadStatus} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import '../../Conversations/ConversationContainer/customStylesTabs.css';
import {getWebhookEventId, scheduleMessageApi, sendEmailMessageAPI, sendScheduleMessageApi} from '../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES, SCHEDULE_MESSAGE_STATUS, TOTAL_FAILED_MESSAGE_STATUS, TOTAL_SCHEDULE_MESSAGE_STATUS} from '../ConversationConst';
import {IMessagingWindowFooterProps, ITypingStatus} from '../interfaces';
import {AttachArticleDrawer} from './AttachArticleDrawer';
import AttachFormDrawer from './AttachFormDrawer';
import AttachAppointmentDrawer from './AttachmentAppointmentDrawer/AttachAppointmentDrawer';
import AttachmentDrawer from './AttachmentDrawer/AttachmentDrawer';
import CannedResponses from './CannedResponses/CannedResponses';
import {ICannedResponses} from './CannedResponses/interface';
import ContactSearchDrawer from './ContactSearchDrawer';
import {
  getConversationMentionsData,
  getUserIdsFromMsgContent,
  getUserIdListFromMsgContent,
  sendNotificationOnMention
} from './ConversationMentions/ConversationMentions';
import './customFooterStylesTabs.css';
import {DeleteMessageModal} from './DeleteMessageModal';
import {IGroupMemberInfo, IMessageResp, IOnEnterActionData} from './interface';
import './customFooterStylesTabs.css';
import {
  MESSAGE_CUSTOM_CARD_KEY,
  MESSAGE_CUSTOM_CARD_KEY_NAME
} from './MessagingCustomCard/CustomCardConst';
import {
  getCategoryCode,
  getGroupMemberList,
  getMessageBodyWithAllParams,
  getPatientDataFromGroupMember,
  getSendMsgOptionList,
  GroupTypeIs,
  isGroupConversation,
  isInternalChat,
  isLoginUserDeletedFromInbox,
  isPrivateGroup,
  messageDataKeysConverter,
  getShortLink,
  isArchiveOnSend,
  getPlaceholder,
  getEventCodeFromParentCode,
  getMessagingFooterTabLabel,
  saveConversationMessage,
  removeConversationMessage,
  getFilteredMentionMessage
} from './MessagingUtils';
import './MessagingWindowFooterStyles.css';
import {styles} from './MessagingWindowStyles';
import {hideEditorToolbar, KEY_PRESS_CODES, ON_PRESS_ENTER_ACTION_CODES, MSG_SEND_OPTIONS} from './MsgConst';
import MsgReplyDrawer from './MsgReplyDrawer/MsgReplyDrawer';
import ReplyMessageView from './ReplyMessageView';
import ScheduleMessageContainer from './ScheduleMessageContainer';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import locale from "antd/es/date-picker/locale/de_DE";
import {IScheduleMessageParams, ISendScheduleParams} from '../ConversationChannelTabs/CreateSmsConversationDrawer/interface';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {Dimensions} from 'react-native';
import {MediaSelectionModal} from '../../../ContentManagement/PatientEducation/MediaSelectionModal';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import { isArchiveOnSendEnable } from '../../../Sales/ProductsAndServices/JourneyPackages/PackagesUtils';
import {PhiInfo} from './PhiInfo';
import LocalStorage from '../../../../../utils/LocalStorage';
import {LOCAL_STORAGE_CONST} from '../../../../../screens/MainScreen/MainScreenConst';
import {getBasicDeviceInfoForMessageSend} from '../../../../../utils/platformCheckUtils';
import debounce from 'lodash/debounce';
import {MESSAGE_DRAFT_TYPES} from '../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import { getSearchStringForCannedResponseEditor } from './CannedResponses/CannedResponsesUtils.web';
import BrowseScheduleSvg from '../../../../common/Svg/SideMenu/BrowseScheduleSvg';
import BrowseMediaSvg from '../../../../common/Svg/SideMenu/BrowseMediaSvg';
import BrowseAttachmentSvg from '../../../../common/Svg/SideMenu/BrowseAttachmentSvg';
import MessageReplySvg from '../../../../common/Svg/SideMenu/MessageReplySvg';
import BrowseContactSvg from '../../../../common/Svg/SideMenu/BrowseContactSvg';

const {Countdown} = Statistic
export const MessagingWindowFooter = (props: IMessagingWindowFooterProps) => {
  const {isInDrawerView, isDetailsContainerVisible, isInstantChatView, parentCode, isSeachMsgContainerVisible} = props;
  const editorRef: any = useRef<Editor>(null);
  const phiContainerRef: any = useRef();
  const typingStatusRef = useRef<{
    isCurrentUserTyping: boolean,
    typingIntervalFn?: NodeJS.Timeout,
  }>({
    isCurrentUserTyping: false,
  });
  const previousRichTextValue = useRef("");
  const commonData = useContext(CommonDataContext);
  const userData = getUserData();
  const mlovData = commonData.MLOV;
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const contentMaxHeight = 132;
  const contentMinHeight = 44;
  const [editorInputHightState, setEditorInputHeightState] = useState(contentMinHeight)
  const userId = getUserId();
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const accountId = getAccountId()
  const intl = useIntl();
  const archiveOnSendConfig = getConfigDataFromCode(CONFIG_CODES.ARCHIVE_CONVERSATION_ON_SEND_MESSAGE);
  const isArchiveEnable =
    isArchiveOnSendEnable(archiveOnSendConfig) &&
    !isInternalChat(props?.conversationData)
  const groupMembers =
    props.conversationData?.groupConversation?.groupMembers || [];
  const groupMemberData = getGroupMemberList(groupMembers);
  const isPatientBroadCastGroup =
    props.conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.PATIENT_BROADCAST;
  let isMemberExist: any = {} as IGroupMemberInfo;
  isMemberExist = groupMemberData?.groupMembersInfoList?.find((item) => {
    return item.uuid === userUUID;
  }) || {};
  const timeGreaterThan = -120000
  const {width} = Dimensions.get('window');
  const minWidth = 1500;
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const {
    conversationData,
    onMsgSend,
    selectedTabCode,
    selectedInboxTypeCode,
    onActionMsgFooterPerformed,
    conversationInbox,
    contactData,
    onMsgChangeText,
  } = props;
  const inboxTypeFromConversation =
    conversationData?.conversationInbox?.channelType || '';
  const [createConversationMentions] = useMutation(
    ConversationsQueries.createConversationMentions
  );
  const [getMessageData] = useLazyQuery<{
    message: IMessageData;
  }>(ConversationsQueries.getMessageDataId, {
    fetchPolicy: 'no-cache',
  });
  const [GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS] = useLazyQuery(ConversationsQueries.GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS, {
    fetchPolicy: 'no-cache',
  })
  let onEnterNewLine = {} as any;
  if (isValidJsonString(props?.isNewLineOnEnter?.preferencesJson || '{}')) {
    onEnterNewLine = JSON.parse(props?.isNewLineOnEnter?.preferencesJson || '{}');
  }
  const onEnterNewLineValue: boolean = onEnterNewLine?.userMessages?.isNewLineOnEnter || false;
  const isScheduledSendEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_SCHEDULE_MESSAGE);
  const enableEnterToNextLine = isAccountConfigEnabled(CONFIG_CODES.ENABLE_ENTER_TO_NEXT_LINE)

  const isShowReplyTab = () => {
    if (
      !isChannelTwillioSms(
        props?.conversationData?.conversationInbox?.channelType
      )
    ) {
      return true;
    }
    const isSendSMSAllowed = canCreateSMSInboxWithPhoneNumber(
      props?.conversationData?.conversationInbox?.channelTwilioSms?.phoneNumber?.toString() ||
        ''
    );
    if (isSendSMSAllowed) {
      return true;
    }
    return false;
  };

  const [messagingWindowFooterState, setMessagingWindowFooterState] = useState({
    showAgentList: false,
    privateNote: isShowReplyTab() ? 'false' : 'true',
    fileList: [] as UploadFile<any>[],
    richTextValue: '',
    initialValue: '',
    isInitialValueLoading: true,
    uploadingMsg: false,
    isDrawerVisible: false,
    formLink: '',
    formDrawer: false,
    showMediaDrawer: false,
    isMsgReplyDrawerVisible: false,
    attachmentPopover: false,
    messageType: isShowReplyTab() ? 'reply' : 'internalMessage',
    articleLink: '',
    articleDrawer: false,
    contactDrawer: false,
    categoryCode: '',
    selectedReplyMsg: {} as IReplyMessageObject,
    appointmentDrawer: false,
    showSendMessageDisable: false,
    contactDeactivationStatus: false,
    browseContactUUID: '',
    code: '',
    scheduleMessageDrawer: false,
    showSchedulePopover: false,
    loading: false,
    totalScheduleMessage:   0,
    totalScheduleFailedMessage: 0,
    showPhiWarning: false,
    isShowOnEnterActionPopup: false,
    onEnterActionMsgFooterText: onEnterNewLineValue
      ? intl.formatMessage({id: 'pressEnterToStartANewLine'})
      : intl.formatMessage({id: 'pressEnterToSendTheMessage'}),
    onSelectedEnterNewLine: onEnterNewLineValue || false,
    isArchiveOnMessageSend: false,
    showArchiveOnSend:
      conversationData?.status === CONVERSATION_STATUS.OPEN || false,
  });

  const hideCannedResponse = () => {
    isDisplayUserAgents(false);
  };
  const msgToast = useToast();

  const isNotInternal = () => {
    if (selectedInboxTypeCode !== CONVERSATION_TAB_CODES.INTERNAL) {
      return true;
    } else {
      return false;
    }
  };

  const [GetGroupMemberPupsub] = useLazyQuery(
    ConversationsQueries.GROUP_MEMBER_PUPSUB,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getScheduleMessagesCount = async () => {
    const scheduleAndFailedMsgData =
      await GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS({
        variables: {
          conversationUuid: conversationData?.uuid,
          scheduleMsgStatus: SCHEDULE_MESSAGE_STATUS.SCHEDULED,
          failedMsgStatus: SCHEDULE_MESSAGE_STATUS.FAILED,
        },
      });
    if (
      scheduleAndFailedMsgData?.data?.scheduleMsgCount?.aggregate?.count > 0
    ) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          totalScheduleMessage:
            scheduleAndFailedMsgData?.data?.scheduleMsgCount?.aggregate?.count,
        };
      });
    }
    if (
      scheduleAndFailedMsgData?.data?.failedScheduleMsgCount?.aggregate?.count >
      0
    ) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          totalScheduleFailedMessage:
            scheduleAndFailedMsgData?.data?.failedScheduleMsgCount?.aggregate
              ?.count,
        };
      });
    }
  };
  const onActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST:
        setMessagingWindowFooterState({
          ...messagingWindowFooterState,
          scheduleMessageDrawer: true,
          isInitialValueLoading: false,
        });
        break;
    }
  };
  const resetMessageWindowHeight = () => {
    props.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.RESET);
    setEditorInputHeightState(contentMinHeight);
  }
  const onConversationPopupActionPerformed = (
    data: {actionCode: string, actionData?: any}
  ) => {
    onActionPerformed(
      data?.actionCode,
      data?.actionData,
    );
  };

  useEffect(() => {
    calculateTextBoxHeight()
  }, [isDetailsContainerVisible, messagingWindowFooterState?.isInitialValueLoading])

  useEffect(()=>{
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();
      let newPlaceholder = '';
      newPlaceholder = getPlaceholder(
        intl,
        messagingWindowFooterState?.privateNote,
        selectedInboxTypeCode,
        messagingWindowFooterState.onSelectedEnterNewLine,
        isSideCarContext,
        isInDrawerView || isInstantChatView
      );
      editor.setPlaceholder(newPlaceholder);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CHANGE_MESSAGE_USER_PREFERENCE,
      handleOnEnterActionEvent
    );
    return () => {
      eventBus.removeEventListener(handleOnEnterActionEvent);
    };
  },[messagingWindowFooterState.messageType, messagingWindowFooterState?.isInitialValueLoading, messagingWindowFooterState.onSelectedEnterNewLine]);

  const setConversationSavedText = async () => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        isInitialValueLoading:true,
      }
    });
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        initialValue: props?.conversationDraftMessage || '',
        richTextValue: props?.conversationDraftMessage || '',
        isInitialValueLoading: false,
      }
    });
    if (editorRef?.current && props?.conversationDraftMessage || '') {
      const inst = editorRef.current.getInstance();
      inst.setMarkdown(props?.conversationDraftMessage || '');
    }
  }

  useEffect(() => {
    const categoryCode = getCategoryCode(
      selectedInboxTypeCode,
      inboxTypeFromConversation
    );
    getScheduleMessagesCount();
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: '',
        fileList: [],
        privateNote: isShowReplyTab() ? 'false' : 'true',
        messageType: isShowReplyTab() ? 'reply' : 'internalMessage',
        showAgentList: false,
        categoryCode: categoryCode,
        selectedReplyMsg: {} as any,
      };
    });
    setConversationSavedText();
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER || isInstantChatView) {
      updateSingleNotificationReadStatus({
        type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
        typeId: conversationData.id + '',
        userId: userUUID,
        isRead: true,
      });
    }
    let currentStatus = true;
    if (isGroupConversation(conversationData)) {
      if (isPrivateGroup(conversationData)) {
        currentStatus = true;
      } else {
        currentStatus = true;
      }
    } else if (conversationData?.inboxId > 0) {
      currentStatus = true;
    }
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        contactDeactivationStatus: !currentStatus,
      };
    });
  }, [conversationData.id, selectedInboxTypeCode]);

  useEffect(() => {
    setMessagingWindowFooterState((prev: any) => {
      return {
        ...prev,
        selectedReplyMsg: props.selectedReplyMsg,
        messageType: props.selectedReplyMsg?.private ?
            'internalMessage' :
            messagingWindowFooterState.messageType,
        privateNote: props.selectedReplyMsg?.private ?
          'true' :
          messagingWindowFooterState.privateNote,
      };
    });
  }, [
    props?.selectedReplyMsg ||
    props?.selectedReplyMsg?.id ||
    props?.selectedReplyMsg?.uuid ||
    props?.selectedReplyMsg?.position ||
    props?.selectedReplyMsg?.senderFullName,
    props?.selectedReplyMsg?.attachmentId ||
    props?.selectedReplyMsg?.text ||
    props?.selectedReplyMsg?.private ||
    props?.selectedReplyMsg?.messageType ||
    props?.selectedReplyMsg?.dateStr,
  ]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const eventCode = getEventCodeFromParentCode(parentCode);
    eventBus.removeEventListener(showArchiveOnSend);
    const newNotification = (data: any) => {
      const singleNotification = data?.data || {};
      const conversationId = conversationData.id + '';
      if (
        singleNotification.type == NOTIFICATION_TYPES.CONVERSATION_UPDATED &&
        singleNotification.typeId === conversationId
      ) {
        updateSingleNotificationReadStatus({
          type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
          typeId: conversationId,
          userId: userUUID,
          isRead: true,
        });
      }
    };
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED,
      newNotification
    );
    eventBus.addEventListener(
      eventCode,
      onConversationPopupActionPerformed
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      showArchiveOnSend
    )
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      showArchiveOnSend
    )
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      showArchiveOnSend
    )
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      showArchiveOnSend
    )

    return () => {
      eventBus.removeEventListener(newNotification);
      eventBus.removeEventListener(onConversationPopupActionPerformed);
      eventBus.removeEventListener(showArchiveOnSend);
    };
  }, []);

  const showArchiveOnSend = (conversationData: any) => {
    if (
      conversationData?.data?.conversationStatus === CONVERSATION_STATUS.OPEN ||
      conversationData?.data?.conversation?.status === CONVERSATION_STATUS.OPEN ||
      conversationData?.conversation?.status === CONVERSATION_STATUS_STRING.OPEN
    ) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          showArchiveOnSend: true,
        };
      });
    } else {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          showArchiveOnSend: false,
        };
      });
    }
  };

  const saveConversationMessageInMessageStorage = () => {
    let messageText = '';
    if (editorRef.current) {
      const inst = editorRef.current.getInstance();
      messageText = inst.getMarkdown();
    }
    if (props?.conversationData?.uuid) {
      if (messageText !== props?.conversationDraftMessage) {
        saveConversationMessage(props?.conversationData?.uuid, {
          content: messageText
        }, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
      }
    }
  }

  const showToastMsg = (isError: boolean) => {
    showToast(msgToast, intl.formatMessage({id: isError ? 'errorOnMsgSend' : 'onAttachmentSend'}), isError ? ToastType.error : ToastType.success);
  };

  const getPlainString = () => {
    return messagingWindowFooterState.richTextValue;
  };

  const handleSendMessage = (sendScheduleMessageBody?: ISendScheduleParams) => {
    let plainString = '';
    if (isWebChannelInboxType(selectedInboxTypeCode || '')) {
      if (editorRef.current) {
        const inst = editorRef.current.getInstance();
        plainString = inst.getMarkdown();
      }
      const editorHtmlElemList: any = document?.querySelectorAll(
        '.messagewindow-editor .ProseMirror.toastui-editor-contents .ProseMirror-trailingBreak'
      );
      if (editorHtmlElemList?.length) {
        for (let index = 0; index < editorHtmlElemList.length; index++) {
          const element = editorHtmlElemList[index];
          if (element?.remove) {
            element.remove();
          }
        }
      }
      const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
      const editorHtmlElem: any = document?.querySelector(
        `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
      );
      if (editorHtmlElem && editorHtmlElem.innerHTML) {
        editorHtmlElem.innerHTML = '';
        if (editorHtmlElem.focus) {
          editorHtmlElem.focus();
        }
      }
      if (editorRef?.current) {
        const inst = editorRef.current.getInstance();
        inst.reset();
      }
    } else {
      plainString = messagingWindowFooterState.richTextValue;
    }
    const messagePlainStr = parseHtmlToPlainText(plainString.trim());
    if (sendScheduleMessageBody?.content) {
      sendMessage(sendScheduleMessageBody?.content, sendScheduleMessageBody)
    } else if (messagePlainStr && messagePlainStr.trim().length > 0) {
      sendMessage(messagePlainStr.replace(/\\/g, ''))
    }
  };

  const getClassNameForFooter = () => {
    let parentClassName = '';
    if (parentCode) {
      parentClassName = PARENT_CODE_CLASS_NAME[parentCode];
    }
    return parentClassName;
  };

  const updateConversationMentionsData = async (messageData: IMessageResp) => {
    const userListData = getUserIdsFromMsgContent(
      messageData.content,
      groupMemberCodeIdObj
    );

    if (userListData?.length) {
      const messageResp = await getMessageData({
        variables: {
          messageId: messageData.id,
        },
      }).catch((error) => {

      });

      if (messageResp?.data?.message?.id === messageData.id) {
        const conversationMentionData = getConversationMentionsData({
          accountUUID,
          userUUID,
          msgData: messageResp?.data?.message,
          selectedConversationData: conversationData,
          userList: userListData,
        });
        const mentionResponse = await createConversationMentions({
          variables: {
            mentionDataList: conversationMentionData,
          },
        });
        await sendNotificationOnMention({
          mentionResponse:
            mentionResponse?.data?.createConversationMentions?.returning || [],
          conversationUuid: conversationData.uuid,
          displayId: conversationData.displayId
        });
      }
    }
  };

  const getScheduleMessageObj = async (message: string, dateString: string) => {
    const channelType = getChannelType(inboxTypeFromConversation)
    const workflowEventId = await getWorkFlowEventId(dateString)
    if (workflowEventId) {
      return {
        content: message,
        conversationUuid: conversationData?.uuid,
        private: messagingWindowFooterState.privateNote === 'true' ? true : false,
        contentType: conversationData?.conversationInbox?.channelEmail?.forwardRouteId && messagingWindowFooterState.privateNote == 'false' ? 12 : 0,
        senderType: SENDER_TYPE?.USER,
        scheduledAt: dateString,
        workflowEventId: workflowEventId,
        status: 'scheduled',
        inboxId: conversationData?.inboxId || conversationData?.contactInbox?.inbox?.id || -1,
        senderId: userId,
        createdBy: userUUID,
        updatedBy: userUUID,
        accountUuid: accountUUID,
        channelType: channelType
      }
    }
    return {} as IScheduleMessageParams
  }

  const getWorkFlowEventId = async (date: string) => {
    const body = {
      nextExecutionDate: date,
      data: {
        accountId: accountId,
        accountUUID: accountUUID
      }
    }
    const webhookEventId = await getWebhookEventId(body);
    return webhookEventId?.data?.id
  };

  const sendMessage = async (message: string, sendScheduleMessageBody?: ISendScheduleParams) => {
    let currentStatus = true;
    const deviceInfo = await getBasicDeviceInfoForMessageSend();
    const additionalAttributes = {
      deviceInfo: deviceInfo,
    };
    if (isGroupConversation(conversationData)) {
      if (isPrivateGroup(conversationData)) {
        const patientData: IGroupContact = getPatientDataFromGroupMember(
          conversationData?.groupConversation?.groupMembers
        );
        // currentStatus = patientData?.isActive;
        currentStatus = true;
      } else {
        currentStatus = true;
      }
    } else if (conversationData?.inboxId > 0) {
      currentStatus = true;
      // currentStatus = conversationData?.conversationContact?.isActive;
    }
    if (!currentStatus) {
      const message = getInActiveContactError(contactData);
      notification.error({
        message,
      });
      return;
    }
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        uploadingMsg: true,
      };
    });
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    let ccEmails = '';
    if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && conversationData?.conversationInbox?.channelEmail?.forwardToEmail) {
      ccEmails = conversationData?.conversationInbox?.channelEmail?.forwardToEmail;
    }
    // const htmlString = richTextValue || '';
    //let plainString = parseHtmlToPlainText(htmlString) || '';
    const formData = new FormData();
    const echoId = getMsgEchoId()
    formData.append('echo_id', echoId);
    formData.append('private', messagingWindowFooterState.privateNote);
    formData.append('cc_emails', ccEmails);
    formData.append('bcc_emails', '');
    formData.append('additional_attributes', JSON.stringify(additionalAttributes));
    if (message) {
      // plainString = trimEnd(plainString, '\n');
      formData.append('content', message);
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          richTextValue: '',
        };
      });
    }

    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
      const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        formData.append('user_mention_ids', userMentionIds.toString());
      }
    }
    if (messagingWindowFooterState.selectedReplyMsg?.uuid) {
      formData.append(
        'parent_message_uuid',
        messagingWindowFooterState.selectedReplyMsg?.uuid
      );
    }
    groupMemberCodeIdObj
    const currentTime = getDateToMomentISOString();

    if (
      messagingWindowFooterState.fileList &&
      messagingWindowFooterState.fileList.length
    ) {
      messagingWindowFooterState.fileList.forEach((fileObj) => {
        if (fileObj.originFileObj) {
          fileObj.originFileObj;
          formData.append('attachments[]', fileObj.originFileObj as any);
        }
      });
    }

    let userMentionIdStr = '';
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
      const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        userMentionIdStr = userMentionIds.toString();
        formData.append('user_mention_ids', userMentionIdStr);
      }
    }
    if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && messagingWindowFooterState.privateNote == 'false') {
      sendEmailMessageAPI({
        private: false,
        content: message,
        conversationDisplayId: conversationData?.displayId,
        inboxId: conversationData?.inboxId,
        subject: conversationData?.additionalAttributes?.mail_subject || '',
        user_mention_ids: userMentionIdStr
      }).then(async (responseMsg: AxiosResponse<IMessageResp>) => {
        if (responseMsg && responseMsg.data) {
          await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
          if (props?.conversationData?.status === 1) {
            const eventBus = EventBus.getEventBusInstance()
            eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
              conversationData: props?.conversationData,
              eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
            });
          }

          responseMsg.data['sender_type'] = 'user';
          const messageRepData = messageDataKeysConverter(responseMsg.data);
          const responseData = {...messageRepData, currentTime};
          onMsgSend(message, responseData, messagingWindowFooterState.selectedReplyMsg);
          updateConversationMentionsData(messageRepData);
          if (
            messagingWindowFooterState.fileList &&
            messagingWindowFooterState.fileList.length
          ) {
            showToastMsg(false);
          }
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              fileList: [],
              richTextValue: '',
              uploadingMsg: false,
              selectedReplyMsg: {} as IReplyMessageObject,
            };
          });
          const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
          const editorHtmlElem: any = document?.querySelector(
            `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
          );
          if (editorHtmlElem && editorHtmlElem.innerHTML) {
            editorHtmlElem.innerHTML = '';
            if (editorHtmlElem.focus) {
              editorHtmlElem.focus();
            }
          }
          if (editorRef?.current) {
            const inst = editorRef.current.getInstance();
            resetMessageWindowHeight();
            inst.reset();
          }
        }
      })
        .catch(() => {
          showToastMsg(true);
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              uploadingMsg: false,
            };
          });
        });
    } else {
      await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
      if (props.onAddMsgToQueue && props.msgQueue) {
        const obj = {
          sender_type: 'user',
          sending: true,
          echo_id: echoId,
          private:
            messagingWindowFooterState?.messageType === 'internalMessage'
              ? true
              : false,
        };
        const responseData = {...obj, currentTime};
        const eventBus = EventBus.getEventBusInstance()
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.SEND_MESSAGE, {
          accountId,
          conversationId,
          formData,
          message,
          parentMessage: messagingWindowFooterState.selectedReplyMsg,
          echoId,
          selectedConversation: {
            uuid: conversationData?.uuid,
            displayId: conversationData?.displayId
          }
        });
        if (props?.conversationData?.status === 1) {
          const eventBus = EventBus.getEventBusInstance()
          eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
            conversationData: props?.conversationData,
            eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
          });
        }

        onMsgSend(
          message,
          responseData,
          messagingWindowFooterState.selectedReplyMsg
        );
        if (
          messagingWindowFooterState.fileList &&
          messagingWindowFooterState.fileList.length
        ) {
          showToastMsg(false);
        }
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            fileList: [],
            richTextValue: '',
            uploadingMsg: false,
            selectedReplyMsg: {} as IReplyMessageObject,
          };
        });
        const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
        const editorHtmlElem: any = document?.querySelector(
          `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents ${parentClassName}`
        );
        if (editorHtmlElem && editorHtmlElem.innerHTML) {
          editorHtmlElem.innerHTML = '';
          if (editorHtmlElem.focus) {
            editorHtmlElem.focus();
          }
        }
        if (editorRef?.current) {
          const inst = editorRef.current.getInstance();
          inst.reset();
          resetMessageWindowHeight();
        }
        // props.onAddMsgToQueue([
        //   ...props.msgQueue,
        //   {
        //     conversationId,
        //     data: formData,
        //     conversationData: props.conversationData,
        //   },
        // ]);
      } else {
        axiosService.post(
          `/accounts/${accountId}/conversations/${conversationId}/messages`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
          .then(async (responseMsg: AxiosResponse<IMessageResp>) => {
            if (responseMsg && responseMsg.data) {
              await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
              if (props?.conversationData?.status === 1) {
                const eventBus = EventBus.getEventBusInstance()
                eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
                  conversationData: props?.conversationData,
                  eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
                });
              }
              responseMsg.data['sender_type'] = 'user';
              const responseData = {...responseMsg.data, currentTime};
              onMsgSend(message, responseData, messagingWindowFooterState.selectedReplyMsg);
              updateConversationMentionsData(responseMsg.data);
              if (
                messagingWindowFooterState.fileList &&
                messagingWindowFooterState.fileList.length
              ) {
                showToastMsg(false);
              }
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  fileList: [],
                  richTextValue: '',
                  uploadingMsg: false,
                  selectedReplyMsg: {} as IReplyMessageObject,
                };
              });
              const parentClassName = parentCode ? `.${getClassNameForFooter()}`: '';
              const editorHtmlElem: any = document?.querySelector(
                `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
              );
              if (editorHtmlElem && editorHtmlElem.innerHTML) {
                editorHtmlElem.innerHTML = '';
                if (editorHtmlElem.focus) {
                  editorHtmlElem.focus();
                }
              }
              if (editorRef?.current) {
                const inst = editorRef.current.getInstance();
                inst.reset();
                resetMessageWindowHeight();
              }
            }
          })
          .catch(() => {
            showToastMsg(true);
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                uploadingMsg: false,
              };
            });
          });
      }
    }
    if (sendScheduleMessageBody) {
      const sendScheduleResponse = await sendScheduleMessageApi(sendScheduleMessageBody);
      if (sendScheduleResponse?.data?.id) {
        setMessagingWindowFooterState({
          ...messagingWindowFooterState,
          scheduleMessageDrawer: false,
        });
        showToast(
          msgToast,
          intl.formatMessage({id: 'sendSuccess'}),
          ToastType.success
        );
      } else {
        showToast(
          msgToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    }
  };

  const toggleTypingStatus = () => {
    if (
      conversationData?.inboxId === -1 ||
      (conversationData?.inboxId > 0 &&
        conversationData?.conversationInbox?.channelType ===
        CHANNEL_TYPE.CHANNEL_WEB_WIDGET)
    ) {
      if (!typingStatusRef?.current) {
        return;
      }
      if (!typingStatusRef.current?.isCurrentUserTyping) {
        toggleTypingStatusApi('on');
        if (typingStatusRef?.current) {
          typingStatusRef.current.isCurrentUserTyping = true;
        }
      }

      if (typingStatusRef.current?.typingIntervalFn) {
        clearTimeout(typingStatusRef.current.typingIntervalFn);
      }

      if (typingStatusRef?.current) {
        const typingIntervalFn = setTimeout(() => {
          toggleTypingStatusApi('off');
          if (typingStatusRef?.current) {
            typingStatusRef.current.isCurrentUserTyping = false;
          }
        }, 2000);
        typingStatusRef.current.typingIntervalFn = typingIntervalFn;
      }
    }
  };

  const toggleTypingStatusApi = (typingStatus: ITypingStatus) => {
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    const bodyData = JSON.stringify({
      is_private: messagingWindowFooterState.privateNote == 'true',
      typing_status: typingStatus,
    });
    axiosService
      .post(
        `/accounts/${accountId}/conversations/${conversationId}/toggle_typing_status`,
        bodyData,
        {
          data: bodyData,
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .catch(() => {
        // catch
      });
  };

  const sendContactCard = async (messageCustomCardKey: string) => {
    if (messagingWindowFooterState?.browseContactUUID) {
      //setSendFormLoading(true);
      const accountId = conversationData.accountId;
      const conversationId = conversationData.displayId;

      const messageBodyWithAllParams = getMessageBodyWithAllParams(
        messageCustomCardKey as MESSAGE_CUSTOM_CARD_KEY_NAME,
        accountId,
        conversationId,
        messagingWindowFooterState.browseContactUUID,
        messagingWindowFooterState.privateNote
      );

      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && messageBodyWithAllParams?.messageBody?.content) {
        const userMentionIds = getUserIdListFromMsgContent(messageBodyWithAllParams?.messageBody?.content, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          if (userMentionIdStr) {
            messageBodyWithAllParams.messageBody.user_mention_ids = userMentionIdStr;
          }
        }
      }

      const messageResp = await sendMessageWithRawBody(
        messageBodyWithAllParams
      );
      if (messageResp) {
        try {
          //  setSendFormLoading(false);
          setMessagingWindowFooterState({
            ...messagingWindowFooterState,
            contactDrawer: false,
            browseContactUUID: '',
          });
          if (messageResp) {
            const currentTime = getDateToMomentISOString();
            const responseData = {...messageResp.data, currentTime};
            onMsgSend(messageResp.data.content, responseData);
          }
        } catch { }
      }
    }
  };

  const isDisplayCannedResponses = () => {
    return (
      messagingWindowFooterState.richTextValue &&
      messagingWindowFooterState.richTextValue.indexOf('/') === 0
    );
  };

  const isDisplayUserAgents = (listCheck: boolean) => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        showAgentList: listCheck,
      };
    });
  };

  const onUserAgentSelection = (userSelected: any) => {
    const inst = editorRef?.current?.getInstance();
    const plainString: string = (inst && inst?.getMarkdown()) || '';
    const currentText = parseHtmlToPlainText(plainString);
    const diffLocation = findStringDifference(
      currentText,
      previousRichTextValue?.current
    );
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');

    const searchText = currentText?.slice(updateIndex + 1, diffIndex);
    let startIndex = firstText?.length;
    let cursorPadding = 1;
    if (firstText[firstText?.length - 1] === '\n') {
      const searchTextSplit = searchText?.split('\n');
      if (searchTextSplit?.length > 0 && searchTextSplit[0]?.length == 0) {
        startIndex -= searchText?.length - 1;
        cursorPadding = searchText?.length - 1;
      } else if (searchTextSplit?.length > 2) {
        startIndex -= searchText?.length - searchTextSplit[0]?.length - 1;
        cursorPadding = searchTextSplit?.length - 2;
      } else {
        cursorPadding = 0;
      }
    }

    let replaced = currentText?.slice(0, updateIndex)?.replace(/\n/g, '<br>');
    replaced += `${userSelected}&nbsp;`;
    replaced += currentText
      ?.slice(startIndex, currentText.length)
      ?.replace(/\n/g, '<br>');

    previousRichTextValue.current = replaced;
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: replaced,
        showAgentList: false,
      };
    });
    if (editorRef?.current) {
      const inst = editorRef.current?.getInstance();
      const selection = inst?.getSelection();
      const searchTextLength = currentText?.slice(updateIndex, diffIndex)?.length - 2;
      const selectedUserTextLength = getFilteredMentionMessage(userSelected)?.length - 1;
      const index = selectedUserTextLength - searchTextLength;
      inst.setMarkdown(replaced);
      const selectionPosition = selection[1] as number;
      const selectionIndex = (selectionPosition) + index - 1 + cursorPadding;
      inst.setSelection(selectionIndex);
    }
  };
  const getUserListElem = () => {
    let listElem = <></>;
    const currentText =  parseHtmlToPlainText(messagingWindowFooterState?.richTextValue)?.split("\n")?.join(" ");
    const previousText = parseHtmlToPlainText(previousRichTextValue?.current)?.split("\n")?.join(" ");
    const diffLocation = findStringDifference(currentText, previousText)
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');
    const text = currentText?.slice(updateIndex, diffIndex)
    if (
      messagingWindowFooterState.showAgentList &&
      ((isGroupConversation(conversationData) &&
        conversationData?.groupConversation?.groupMembers?.length &&
        !isPatientBroadCastGroup) ||
        (conversationData?.inboxId !== -1 &&
          messagingWindowFooterState?.privateNote == 'true'))
    ) {
      listElem = (
        <GroupUserMentionList
          isReplyMessage={
            messagingWindowFooterState?.selectedReplyMsg?.uuid ? true : false
          }
          memberList={
            conversationData?.groupConversation?.groupMembers ||
            conversationData?.conversationInbox?.inboxMembers ||
            []
          }
          searchString={text}
          onMemberSelection={(selectedUser: any) => {
            onUserAgentSelection(selectedUser);
          }}
          hideCannedResponse={hideCannedResponse}
          selectedTab={messagingWindowFooterState.messageType}
          inboxId={conversationData?.inboxId}
          conversationUuid={conversationData?.uuid}
          memberListAggergateCount={
            conversationData?.groupConversation?.groupMembers_aggregate?.aggregate?.count ||
            conversationData?.conversationInbox?.inboxMembers_aggregate?.aggregate?.count
          }
          onActionPerformed={(actionCode: string, actionData?: any) => {
            props?.onMentionActionPerformed && props?.onMentionActionPerformed(actionCode, actionData)
          }}
        />
      );
    }

    return listElem;
  };
  const onDrawerActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.SEND_ATTACHMENT:
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            fileList: [],
            isDrawerVisible: false,
          };
        });
        // handleSendMessage();
        if (editorRef.current) {
          const inst = editorRef.current.getInstance();
          inst.reset();
        }
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            fileList: [],
            isDrawerVisible: false,
          };
        });
        break;
    }
  };

  const getAttachment = () => {
    const currentStatus = isActiveContact(contactData);
    if (!currentStatus) {
      return <View></View>;
    }
    if (
      !isChannelTwillioSms(inboxTypeFromConversation || '') &&
      !isChannelEmail(inboxTypeFromConversation)
    ) {
      return (
          <Tooltip title='Attachment'>
            <Upload
              style={{background: ''}}
              multiple={true}
              beforeUpload={() => false}
              maxCount={10}
              onChange={(info) => {
                const tempFileList = [...info.fileList];
                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    fileList: tempFileList,
                    isDrawerVisible: true,
                  };
                });
              }}
              fileList={[]}
              className="msg-attachment"
              data-testId={TestIdentifiers.attachmentBtn}
            >
              {conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.USER_BROADCAST ||
                conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.INTERNAL ? (
                  <View size={18} {...testID('image')}>
                    <BrowseAttachmentSvg defaultColor={Colors.FoldPixel.GRAY300} />
                  </View>
              ) : (
                <>
                  <View size={18} {...testID('image')}>
                    <BrowseAttachmentSvg defaultColor={Colors.FoldPixel.GRAY300} />
                  </View>
                </>
              )}
            </Upload>
          </Tooltip>
      );
    } else {
      return <View></View>;
    }
  };

  const getBrowseFormsElem = () => {
    return (
      <Tooltip title="Forms">
        <Pressable
          onPress={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                formDrawer: true,
              };
            });
          }}
          {...testID('BrowseFormBtn')}
        >
          <View size={18} {...testID('image')}>
            <FormSvg defaultColor={Colors.FoldPixel.GRAY300} />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getMediaAttachmentElem = () => {
    return (
      <Tooltip title="Media">
        <Pressable
          onPress={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              showMediaDrawer: true,
            });
          }}
          {...testID('BrowseMediaBtn')}
        >
          <View size={18} {...testID('image')}>
            <BrowseMediaSvg defaultColor={Colors.FoldPixel.GRAY300}/>
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getBrowseArticlesElem = () => {
    return (
      <Tooltip title="Articles">
        <Pressable
          onPress={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              articleDrawer: true,
            });
          }}
          {...testID('BrowseArticleBtn')}
        >
          <View size={18} {...testID('image')}>
            <EducationContent
              defaultColor={Colors.FoldPixel.GRAY300}
            />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getSendAppointmentLinkElem = () => {
    return (
      <Tooltip title="Appointment Link">
        <Pressable
          onPress={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              appointmentDrawer: true,
            });
          }}
          {...testID('SendAppointmentLinkBtn')}
        >
          <View size={18} {...testID('image')}>
            <CalendarSvg defaultColor={Colors.FoldPixel.GRAY300} />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getBrowseContact = () => {
    if (!isEmployerRole()) {
      return (
        <Tooltip title="Contacts">
          <Pressable
            onPress={() => {
              const currentStatus = isActiveContact(contactData);
              if (!isInternalChannelInboxType(selectedInboxTypeCode || '')
                && !currentStatus) {
                const message = getInActiveContactError(contactData);
                notification.error({
                  message,
                });
                return;
              }
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                contactDrawer: true,
              });
            }}
            {...testID('BrowseContactBtn')}
          >
            <View size={18} {...testID('image')}>
              <BrowseContactSvg defaultColor={Colors.FoldPixel.GRAY300} />
            </View>
          </Pressable>
        </Tooltip>
      );
    } else {
      return <View></View>;
    }
  };

  const getScheduleMessage = () => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}} paddingRight={5}>
        <Tooltip title="Scheduled">
          <Pressable
            onPress={() => {
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                scheduleMessageDrawer: true,
              });
            }}
            {...testID(TestIdentifiers.scheduleButton)}
          >
            <View size={18} {...testID('image')}>
              <BrowseScheduleSvg defaultColor={Colors.FoldPixel.GRAY300}/>
            </View>
          </Pressable>
        </Tooltip>
        <View style={{
          display: messagingWindowFooterState?.totalScheduleMessage > 0 ? 'flex' : 'none',
          marginHorizontal: 4
        }}>
          <Text {...testID('TotalScheduleMessage')} size={'smMedium'}>
            {messagingWindowFooterState?.totalScheduleMessage}
          </Text>
        </View>
        <View style={{
          backgroundColor: Colors.Custom.Gray100,
          borderRadius: 20,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: Colors.Custom.Gray200,
          paddingHorizontal: 6,
          marginHorizontal: 4,
          flexDirection: 'row',
          alignItems: 'center',
          display: messagingWindowFooterState?.totalScheduleFailedMessage > 0 ? 'flex' : 'none'
        }}>
          <InfoSvg customColor={Colors.error?.[600]} customDimension='12' />
          <Text {...testID('TotalScheduleFailedMessage')} style={{marginLeft: 4}} size={'smMedium'} color={Colors.error?.[600]}>
            {messagingWindowFooterState?.totalScheduleFailedMessage}
          </Text>
        </View>
      </View>
    );
  };

  const getSendMsgOptionElemFromCode = (singleMsgOptionCode: string) => {
    switch (singleMsgOptionCode) {
      case MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS:
        return getAttachment();
      case MSG_SEND_OPTIONS.BROWSE_FORMS:
        return getBrowseFormsElem();
      case MSG_SEND_OPTIONS.BROWSE_ARTICLES:
        return getBrowseArticlesElem();
      case MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK:
        return getSendAppointmentLinkElem();
      case MSG_SEND_OPTIONS.BROWSE_CONTACT:
        return getBrowseContact();
      case MSG_SEND_OPTIONS.MEDIA_ATTACHMENTS:
        return getMediaAttachmentElem();
      case MSG_SEND_OPTIONS.SCHEDULE_MESSAGE:
        if (isScheduledSendEnabled && messagingWindowFooterState?.privateNote == 'false') {
          return getScheduleMessage();
        }
    }
  };

  const getAttachmentContentElem = () => {
    const sendMsgOptionList = getSendMsgOptionList(
      selectedInboxTypeCode || '',
      conversationData,
      conversationInbox
    );

    if (sendMsgOptionList.length > 0) {
      return (
        <HStack alignItems={'center'} justifyContent={'center'}>
          {sendMsgOptionList.map((singleMsgOptionCode) => {
            return (
              <View justifyContent={'center'} alignItems={'center'} key={`sendMsgOptionList_${singleMsgOptionCode}`} ml={4}>
                {getSendMsgOptionElemFromCode(singleMsgOptionCode)}
              </View>
            )
          })}
        </HStack>
      );
    }
  };

  const onMsgReplyActionPerformed = (actionCode: string, actionData: {
    content: string,
    cursorPosition: number,
  }) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            isMsgReplyDrawerVisible: false,
            richTextValue: actionData?.content
          };
        });
        if (editorRef?.current) {
          const inst = editorRef.current.getInstance();
          inst.setMarkdown(actionData?.content);
          if (actionData?.cursorPosition !== -1) {
            inst.setSelection(actionData?.cursorPosition,actionData?.cursorPosition);
          }
        }
        break;
    }
  };

  const toShowPhiWarning = () => {
    if (conversationData?.conversationInbox?.channelType) {
      const type = conversationData?.conversationInbox?.channelType || '';
      if (
        type === CHANNEL_TYPE.CHANNEL_EMAIL &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_WEB_WIDGET &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      } else if (type === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return true
      }
      return false;
    } else {
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return true
      }
      if (contactData?.source) {
        if (contactData?.source === 'WEB_WIDGET') {
          return true;
        }
        return false;
      }
      return false;
    }
  };
  const getMarginTop = () => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS &&
      messagingWindowFooterState?.messageType === 'internalMessage') {
      return 46;
    } else {
      return ''
    }
  }

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            code: RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS,
          };
        });
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setMessagingWindowFooterState({
          ...messagingWindowFooterState,
          contactDrawer: false,
          scheduleMessageDrawer: false,
          browseContactUUID: '',
        });
        break;
      default:
        setMessagingWindowFooterState({
          ...messagingWindowFooterState,
          contactDrawer: false,
          browseContactUUID: '',
        });
        break;
    }
  };

  const onClose = () => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        showSendMessageDisable: false,
      };
    });
  }

  const getMarginForToolbarItems = () => {
    if (hideText()) {
      if (isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return '85%'
      } else if (isChannelTwillioSms(inboxTypeFromConversation)) {
        return '82%'
      } else {
        return '78%'
      }
    } else {
      if (isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return '78%'
      } else if (isChannelTwillioSms(inboxTypeFromConversation)) {
        return '74%'
      } else {
        return '68%'
      }
    }
  }

  const setLoadingFalse = () => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        loading: false,
        showSchedulePopover: false,
      };
    });
  }

  const scheduleMessage = async (message: string, dateString: string) => {
    let body = {} as IScheduleMessageParams
    body = await getScheduleMessageObj(message, dateString)
    if (body?.workflowEventId) {
      const scheduleMessageResponse = await scheduleMessageApi(body);
      await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
      if (scheduleMessageResponse?.data?.id) {
        setLoadingFalse();
        showToast(
          msgToast,
          intl.formatMessage({id: 'scheduleSuccess'}),
          ToastType.success
        );
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            totalScheduleMessage: prev.totalScheduleMessage + 1
          }
        })
      } else {
        setLoadingFalse()
        showToast(
          msgToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    } else {
      setLoadingFalse()
      showToast(
        msgToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }

  const onOk = (value: DatePickerProps['value']) => {
    const dateString = moment.utc(value).format();
    const currentTime = moment(new Date());
    const duration = moment.duration(currentTime.diff(dateString));
    if (duration.asMilliseconds() >= timeGreaterThan) {
      showToast(
        msgToast,
        intl.formatMessage({id: 'selectTimeInFuture'}),
        ToastType.error
      );
      setLoadingFalse()
      return;
    }
    const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
    const editorHtmlElem: any = document?.querySelector(
      `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
    );
    if (editorHtmlElem && editorHtmlElem.innerHTML) {
      resetMessageWindowHeight();
      editorHtmlElem.innerHTML = '';
      if (editorHtmlElem.focus) {
        editorHtmlElem.focus();
      }
    }
    if (
      !messagingWindowFooterState?.contactDeactivationStatus ||
      messagingWindowFooterState.privateNote == 'true'
    ) {
      setMessagingWindowFooterState((prev)=>{
        return {
          ...prev,
          loading: true,
          richTextValue: '',
        }
      })
      let plainString = '';
      if (isWebChannelInboxType(selectedInboxTypeCode || '')) {
        if (editorRef.current) {
          const inst = editorRef.current.getInstance();
          plainString = inst.getMarkdown();
        }
      } else {
        plainString = messagingWindowFooterState.richTextValue;
      }
      const messagePlainStr = parseHtmlToPlainText(plainString.trim());
      if (messagePlainStr && messagePlainStr.trim().length > 0) {
        scheduleMessage(messagePlainStr.replace(/\\/g, ''), dateString)
      }
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          showSchedulePopover: false,
        };
      });
    } else {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          showSendMessageDisable: true,
          showSchedulePopover: false,
          loading: false
        };
      });
    }
  };
  const datePickerRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (datePickerRef?.current) {
        if (datePickerRef?.current && !datePickerRef?.current?.contains?.(event?.target)) {
          setMessagingWindowFooterState((prev) => {
            if (prev?.showSchedulePopover) {
              return {
                ...prev,
                showSchedulePopover: true,
              }
            } else {
              return {
                ...prev,
              };
            }
          });
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      toggleTypingStatusApi('off');
      if (typingStatusRef?.current?.typingIntervalFn) {
        clearTimeout(typingStatusRef?.current?.typingIntervalFn);
      }
      if (datePickerRef.current) {
        datePickerRef.current = null;
      }
    };
  }, []);

  const shouldShowPhiInfo = ()=> {
    return messagingWindowFooterState?.showPhiWarning && toShowPhiWarning();
  }

  useEffect(()=> {
    LocalStorage.getItem(LOCAL_STORAGE_CONST.phiWarning).then((res) => {
      const data = JSON.parse(res);
      const phiInfoRead = data?.phiWarningRead;
      setMessagingWindowFooterState((prev)=> {
        return {
          ...prev,
          showPhiWarning: phiInfoRead ? false : true,
        }
      })
    })
  },[conversationData.id, selectedInboxTypeCode])

  useEffect(() => {
    props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.PHI_WARNING, {
      showPhiWarning: shouldShowPhiInfo(),
    });
  }, [messagingWindowFooterState.showPhiWarning]);

  const datePickerFooter = () => {
    return (
      <View position={'absolute'}>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  showSchedulePopover: false
                }
              })
            },
            borderWidth: 0
          }}
          customProps={{
            btnText: intl.formatMessage({id: 'cancel'}),
          }}
        ></FoldButton>
      </View>
    )
  }

  const hideText = () => {
    if (isDetailsContainerVisible || isSeachMsgContainerVisible || parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL || isInstantChatView || width < minWidth) {
      return false;
    }
    return true;
  }
  const debouncedSaveConversationMessage = useCallback(
    debounce(() => {
      saveConversationMessageInMessageStorage();
    }, 500), []);

  const calculateTextBoxHeight = () => {
    const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
    const editorHtmlElemList: any = document?.querySelectorAll(
      `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
    );
    editorHtmlElemList[0]?.setAttribute('data-testid', TestIdentifiers.messageFooterInput);

    const editorInputHeight = editorHtmlElemList[0]?.clientHeight;
    let messageText = '';
    if (editorRef.current) {
      const inst = editorRef.current.getInstance();
      messageText = inst.getMarkdown();
    }
    if (editorInputHeight < contentMaxHeight) {
      if (editorInputHeight > contentMinHeight) {
        const updatedValue = editorInputHeight - editorInputHightState
        setEditorInputHeightState(editorInputHeight)
        updateHeight(editorInputHeight);
        props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.UPDATE, updatedValue)
      } else if (editorInputHeight === contentMinHeight) {
        setEditorInputHeightState(contentMinHeight)
        props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.RESET)
      }
    }
    if (!messagingWindowFooterState?.isInitialValueLoading) {
      debouncedSaveConversationMessage();
    }
  }

  const updateHeight = (editorInputHeight: number) => {
    setEditorInputHeightState(editorInputHeight)
  };

  const getOtherClassName = () => {
    if (hideText()) {
      if (!isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return 'messagewindow-editor-toolbar-without-collapse'
      }
    } else {
      if (isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return 'messagewindow-editor-toolbar-internal-collapse'
      } else {
        return 'messagewindow-editor-toolbar-other-collapse'
      }
    }
  }

  const getFormattedMessageText = (text: string) => {
    if (
      messagingWindowFooterState.privateNote === 'false' &&
      isChannelEmailOrSms(inboxTypeFromConversation || '')
    ) {
      return markdownLinkToPlainText(text);
    }
    return text;
  };
  const handleOnEnterActionEvent = (actionData: IOnEnterActionData) => {
    if (actionData?.parentCode && actionData?.parentCode !== parentCode) {
      const actionCode = actionData?.isNewLineOnEnter
        ? ON_PRESS_ENTER_ACTION_CODES.NEW_LINE
        : ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE;
      let intlMessageCode = 'pressEnterToSendTheMessage';
      if (actionCode === ON_PRESS_ENTER_ACTION_CODES.NEW_LINE) {
        intlMessageCode = 'pressEnterToStartANewLine';
      }
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          onEnterActionMsgFooterText: intl.formatMessage({
            id: intlMessageCode,
          }),
          onSelectedEnterNewLine: actionData?.isNewLineOnEnter,
        };
      });
    }
  };
  const handleOnEnterActionFooterText = (e: RadioChangeEvent) => {
    if (e.target.value === ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          onEnterActionMsgFooterText: intl.formatMessage({
            id: 'pressEnterToSendTheMessage',
          }),
          onSelectedEnterNewLine: false,
          isShowOnEnterActionPopup: false,
        };
      });
      onActionMsgFooterPerformed(ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE, {
        isArchiveSendChecked: messagingWindowFooterState.isArchiveOnMessageSend,
        isNewLineOnEnter: false,
      });
    } else if (e.target.value === ON_PRESS_ENTER_ACTION_CODES.NEW_LINE) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          onEnterActionMsgFooterText: intl.formatMessage({
            id: 'pressEnterToStartANewLine',
          }),
          onSelectedEnterNewLine: true,
          isShowOnEnterActionPopup: false,
        };
      });
      onActionMsgFooterPerformed(ON_PRESS_ENTER_ACTION_CODES.NEW_LINE, {
        isArchiveSendChecked: messagingWindowFooterState.isArchiveOnMessageSend,
        isNewLineOnEnter: true,
      });
    }
  };
  const handleOnEnterActionChangePopup = () => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        isShowOnEnterActionPopup:
          !messagingWindowFooterState.isShowOnEnterActionPopup,
      };
    });
  };

  const concateArticleMessage = (articleLink: string) => {
    const updatedText = getUpdatedMessage(articleLink);

    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        articleLink: articleLink,
        articleDrawer: false,
        richTextValue: updatedText,
      };
    });

    if (editorRef?.current) {
      const inst = editorRef.current.getInstance();
      inst.setMarkdown(updatedText);
    }
  };

  const concateFormLinkMessage = (formLink: string) => {
    const updatedText = getUpdatedMessage(formLink);

    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        formLink: formLink,
        richTextValue: updatedText,
        formDrawer: false,
        selectedReplyMsg: {} as IReplyMessageObject,
      };
    });

    if (editorRef?.current) {
      const inst = editorRef.current.getInstance();
      inst.setMarkdown(updatedText);
    }
  };

  const getUpdatedMessage = (messageText: string) => {
    let updatedText = messagingWindowFooterState.richTextValue;
    if (editorRef?.current) {
      const inst = editorRef.current.getInstance();
      updatedText = inst.getMarkdown();
    }
    updatedText += '\n' + messageText;

    return updatedText;
  };

  return (
    <>
      {shouldShowPhiInfo() && (
        <View ref={phiContainerRef} width={'100%'}>
          <PhiInfo
            isMentionTab={
              selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
            }
            onClose={() => {
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  showPhiWarning: false,
                };
              });
              LocalStorage.setItem(
                LOCAL_STORAGE_CONST.phiWarning,
                JSON.stringify({phiWarningRead: true})
              );
            }}
          />
        </View>
      )}
      <View>
        {true ||
          !isActiveContact(props.contactData) ||
          (!!!isMemberExist?.id && props.isInDrawerView && (
            <>
              <Content
                className={'editor-inactive'}
                onClick={(e) => {
                  e.preventDefault();
                  const currentStatus = isActiveContact(contactData);
                  if (!currentStatus) {
                    const message = getInActiveContactError(contactData);
                    notification.error({
                      message,
                    });
                    return;
                  }
                }}
                data-testid={TestIdentifiers.contentViewer}
              ></Content>
            </>
          ))}

        {isLoginUserDeletedFromInbox(props?.conversationData, userData) && (
          <>
            <Content
              className={'editor-inactive'}
              onClick={(e) => {
                e.preventDefault();
                const currentStatus = isActiveContact(contactData);
                if (!currentStatus) {
                  const message = getInActiveContactError(contactData);
                  notification.error({
                    message,
                  });
                  return;
                }
              }}
              data-testid={TestIdentifiers.contentViewer}
            ></Content>
          </>
        )}

        <VStack style={styles.msgFooterStyle}>
          {isDisplayCannedResponses() ? (
            <CannedResponses
              isReplyMessage={
                messagingWindowFooterState?.selectedReplyMsg?.uuid
                  ? true
                  : false
              }
              categoryCode={messagingWindowFooterState.categoryCode}
              searchString={getSearchStringForCannedResponseEditor(editorRef)}
              onCannedResponsesSelection={(
                cannedResponseData: ICannedResponses
              ) => {
                const finalText = `${cannedResponseData.content}${(
                  messagingWindowFooterState.richTextValue || ''
                ).replace(
                  getSearchStringForCannedResponseEditor(editorRef),
                  ''
                )}`;

                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    richTextValue: finalText || '',
                  };
                });
                if (editorRef.current) {
                  const inst = editorRef.current.getInstance();
                  const currentText = inst.getMarkdown();
                  const finalText =
                    `${cannedResponseData.content}${currentText}`.replace(
                      getSearchStringForCannedResponseEditor(editorRef),
                      ''
                    );
                  inst.setMarkdown(finalText);
                  inst.moveCursorToStart(true);
                }
              }}
              parentCode={PARENT_CODE.MESSAGING_WINDOW_FOOTER}
            />
          ) : (
            <View></View>
          )}
          {getUserListElem()}
          {messagingWindowFooterState.selectedReplyMsg.uuid ? (
            <ReplyMessageView
              isCannedOpen={isDisplayCannedResponses() || false}
              onClearReplyMessage={() => {
                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    selectedReplyMsg: {} as IReplyMessageObject,
                  };
                });
              }}
              selectedReply={messagingWindowFooterState.selectedReplyMsg}
            />
          ) : (
            <></>
          )}
          <View
            style={{
              borderColor: Colors.Custom.BorderColor,
              borderBottomWidth: 1,
              width: '100%',
              margin: 'auto',
            }}
          ></View>
          <VStack padding="16px" paddingBottom={'0px'} paddingTop={'16px'}>
            <HStack marginBottom={'12px'}>
              {GroupTypeIs(conversationData) ? (
                <View
                  flex={0.5}
                  style={{flexDirection: 'row', alignItems: 'flex-end'}}
                >
                  <Switch
                    style={{
                      alignSelf: 'center',
                      backgroundColor:
                        messagingWindowFooterState?.messageType !== 'reply'
                          ? '#F37232'
                          : Colors.Custom.Gray200,
                      borderRadius: 4,
                    }}
                    disabled={!isShowReplyTab()}
                    checked={
                      messagingWindowFooterState?.messageType !== 'reply'
                    }
                    onChange={(switchStatus) => {
                      const tabCode = switchStatus
                        ? 'internalMessage'
                        : 'reply';

                      if (
                        messagingWindowFooterState.selectedReplyMsg?.private
                      ) {
                        notification.error({
                          message: intl.formatMessage({
                            id: 'switchToReplyAlert',
                          }),
                          duration: 3,
                          closeIcon: (
                            <Countdown
                              valueStyle={{
                                fontSize: 12,
                              }}
                              value={Date.now() + 4000}
                              format="s"
                            />
                          ),
                          placement: 'topRight',
                          style: {
                            borderRadius: 8,
                          },
                        });

                        return;
                      }
                      let isInternalMessage = false;
                      setMessagingWindowFooterState((prev) => {
                        return {
                          ...prev,
                          messageType: tabCode,
                        };
                      });
                      if (
                        tabCode === 'reply' &&
                        messagingWindowFooterState?.messageType !== 'reply'
                      ) {
                        let formattedText =
                          messagingWindowFooterState?.richTextValue;
                        if (
                          props.selectedInboxTypeCode ==
                          CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
                        ) {
                          formattedText =
                            getFilteredMentionMessage(formattedText);
                        }
                        if (editorRef?.current) {
                          const inst = editorRef.current?.getInstance();
                          inst.setMarkdown(formattedText);
                        }
                        setMessagingWindowFooterState((prev) => {
                          return {
                            ...prev,
                            richTextValue: formattedText,
                            privateNote: 'false',
                            showAgentList: false,
                          };
                        });
                      } else if (
                        tabCode === 'internalMessage' &&
                        messagingWindowFooterState?.messageType !==
                          'internalMessage'
                      ) {
                        isInternalMessage = true;
                        setMessagingWindowFooterState((prev) => {
                          return {
                            ...prev,
                            privateNote: 'true',
                            showAgentList: false,
                          };
                        });
                      }
                    }}
                  />
                  <Text
                    marginLeft={'8px'}
                    marginRight={'4px'}
                    fontWeight={400}
                    color={Colors.Custom.Gray400}
                  >
                    Internal
                  </Text>

                  {!isShowReplyTab() && (
                    <Tooltip
                      title="Please note that SMS functionality is not available for toll-free numbers"
                      placement="top"
                    >
                      <View style={{alignSelf: 'center'}}>
                        <Feather
                          name="info"
                          style={{
                            fontSize: 14,
                            color: Colors.Custom.Gray400
                          }}
                          {...testID('image')}
                        />
                      </View>
                    </Tooltip>
                  )}
                </View>
              ) : (
                <View flex={0.5}></View>
              )}

              <HStack
                flex={0.5}
                justifyContent={'flex-end'}
                alignItems={'center'}
                flexWrap={'wrap'}
                // marginLeft={getMarginForToolbarItems()}
              >
                {getAttachmentContentElem()}
                {!isInDrawerView && (
                  <VStack ml={-1} mt={0.5} alignItems={'center'}>
                    <Tooltip title="Expand Reply" placement="left">
                      <Pressable
                        onPress={() => {
                          if (
                            !messagingWindowFooterState?.contactDeactivationStatus ||
                            messagingWindowFooterState.privateNote == 'true'
                          ) {
                            setMessagingWindowFooterState((prev) => {
                              return {
                                ...prev,
                                isMsgReplyDrawerVisible: true,
                              };
                            });
                          } else {
                            setMessagingWindowFooterState((prev) => {
                              return {
                                ...prev,
                                showSendMessageDisable: true,
                              };
                            });
                          }
                        }}
                        {...testID('ExpandBtn')}
                      >
                        <View size={18} {...testID('image')}>
                          <MessageReplySvg
                            defaultColor={Colors.FoldPixel.GRAY300}
                          />
                        </View>
                      </Pressable>
                    </Tooltip>
                  </VStack>
                )}
              </HStack>
            </HStack>
            <HStack
              height={editorInputHightState + 10 + 'px'}
              style={{
                width: '100%',
              }}
              space={1}
            >
              <View
                style={{width: '88%'}}
                nativeID="toastui-editor-toolbar-height"
              >
                <Content
                  className={`messagewindow-editor ${getOtherClassName()} ${
                    messagingWindowFooterState?.messageType !== 'reply'
                      ? 'internalMessage-editor'
                      : ''
                  } ${getClassNameForFooter()}`}
                  data-testid={'EditorContent'}
                >
                  <Editor
                    initialValue={messagingWindowFooterState?.initialValue}
                    previewStyle="vertical"
                    height={`${contentMaxHeight}px`}
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                    previewHighlight={false}
                    ref={editorRef}
                    toolbarItems={
                      hideEditorToolbar
                        ? [[]]
                        : !isChannelTwillioSms(inboxTypeFromConversation || '')
                        ? [['heading', 'bold', 'italic', 'strike', 'link']]
                        : [[]]
                    }
                    autofocus={false}
                    onKeydown={(editorType: any, event: KeyboardEvent) => {
                      const keyCode = event.which || event.keyCode;
                      if (
                        (keyCode === 37 ||
                          keyCode === 38 ||
                          keyCode === 39 ||
                          keyCode === 40) &&
                        messagingWindowFooterState.showAgentList
                      ) {
                        event.preventDefault();
                        event.stopPropagation();
                        return;
                      }
                      if (keyCode === 27 || keyCode === 32) {
                        isDisplayUserAgents(false);
                      }
                      if (
                        isDisplayCannedResponses() &&
                        (event.key === KEY_PRESS_CODES.ARROW_UP ||
                          event.key === KEY_PRESS_CODES.ARROW_DOWN ||
                          event.key === KEY_PRESS_CODES.ENTER)
                      ) {
                        event.preventDefault();
                        return;
                      }
                      if (keyCode === 50 && event.key !== '2') {
                        if (
                          props.enableShowAccountNameOnlyToPatient &&
                          messagingWindowFooterState?.messageType !==
                            'internalMessage' &&
                          !isInternalChat(conversationData)
                        ) {
                          isDisplayUserAgents(false);
                          return;
                        }
                        if (
                          isChannelTwillioSms(inboxTypeFromConversation || '')
                        ) {
                          if (
                            messagingWindowFooterState?.messageType ===
                            'internalMessage'
                          ) {
                            isDisplayUserAgents(true);
                          } else {
                            isDisplayUserAgents(true);
                          }
                        } else {
                          isDisplayUserAgents(true);
                        }
                      }
                      // else if (keyCode === 8) {
                      //   isDisplayUserAgents(false);
                      // }
                      if (editorRef && editorRef?.current) {
                        const inst = editorRef.current.getInstance();
                        const plainString: string = inst.getMarkdown() || '';
                        let messagePlainStr = parseHtmlToPlainText(
                          plainString.trim()
                        );
                        messagePlainStr = messagePlainStr?.trim() || '';
                        // if(messagePlainStr?.length === 0){
                        //   isDisplayUserAgents(false);
                        // }
                        if (editorRef && editorRef?.current) {
                          const inst = editorRef.current.getInstance();
                          const plainString: string = inst.getMarkdown() || '';
                          let messagePlainStr = parseHtmlToPlainText(
                            plainString.trim()
                          );
                          messagePlainStr = messagePlainStr?.trim() || '';
                          // if(messagePlainStr?.length === 0){
                          //   isDisplayUserAgents(false);
                          // }

                          if (
                            messagingWindowFooterState.onSelectedEnterNewLine
                          ) {
                            if (
                              event.shiftKey &&
                              event.key === KEY_PRESS_CODES.ENTER
                            ) {
                              handleSendMessage();
                              return;
                            }
                            if (event.key === KEY_PRESS_CODES.ENTER) {
                              event.preventDefault();
                            }
                            return;
                          }
                          if (
                            keyCode === 13 &&
                            !event.shiftKey &&
                            !messagingWindowFooterState.showAgentList &&
                            messagePlainStr
                          ) {
                            event.preventDefault();
                            event.stopPropagation();
                            handleSendMessage();
                            setTimeout(() => {
                              inst.reset();
                              inst.setMarkdown('');
                            }, 0);
                          }
                        }
                      }
                      if (
                        isDisplayCannedResponses() &&
                        (event.key === KEY_PRESS_CODES.ARROW_UP ||
                          event.key === KEY_PRESS_CODES.ARROW_DOWN ||
                          event.key === KEY_PRESS_CODES.ENTER)
                      ) {
                        event.preventDefault();
                        return;
                      }
                    }}
                    onKeyup={(editorType: any, event: KeyboardEvent) => {
                      const keyCode = event.which || event.keyCode;
                      return;
                      if (
                        keyCode === 13 &&
                        !event.shiftKey &&
                        !messagingWindowFooterState.showAgentList &&
                        !enableEnterToNextLine &&
                        event?.code === KEYBOARD_KEY_CODE.ENTER
                      ) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (editorRef && editorRef?.current) {
                          const inst = editorRef.current.getInstance();
                          inst.setMarkdown('');
                        }
                      }
                    }}
                    onChange={() => {
                      if (editorRef && editorRef?.current) {
                        const inst = editorRef.current.getInstance();
                        const plainString: string =
                          (inst && inst.getMarkdown()) || '';
                        const currentText =
                          parseHtmlToPlainText(plainString) || '';
                        calculateTextBoxHeight();
                        const includeAt = plainString?.slice(
                          plainString.lastIndexOf('@')
                        );
                        if (!plainString.length) {
                          isDisplayUserAgents(false);
                        } else if (!includeAt.trim().includes('@')) {
                          isDisplayUserAgents(false);
                        }

                        previousRichTextValue.current =
                          messagingWindowFooterState.richTextValue;
                        setMessagingWindowFooterState((prev) => {
                          return {
                            ...prev,
                            richTextValue: currentText,
                          };
                        });

                        if (plainString.length < 2) {
                          onMsgChangeText?.(plainString);
                        }
                        if (plainString.length > 0) {
                          toggleTypingStatus();
                        }
                      }
                      // if (keyCode === 13 && !event.shiftKey) {
                      //   event.preventDefault();
                      //   event.stopPropagation();
                      // }
                    }}
                    data-testid={TestIdentifiers.messageFooterInput}
                  />
                </Content>
              </View>

              <VStack style={{width: '12%'}} justifyContent={'center'} mb={2}>
                <View
                  height={46}
                  width={'100%'}
                  alignSelf={'flex-start'}
                  justifySelf={'center'}
                  justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  borderRadius={6}
                  borderWidth={1}
                  backgroundColor={
                    messagingWindowFooterState?.messageType !== 'reply'
                      ? Colors.secondary['100']
                      : Colors.Custom.PrimaryColor100
                  }
                  borderColor={
                    messagingWindowFooterState?.messageType !== 'reply'
                      ? Colors.secondary['500']
                      : Colors.Custom.PrimaryColor200
                  }
                >
                  <HStack
                    justifyContent={'center'}
                    height={42}
                    width={'100%'}
                  >
                    <Pressable
                      flex={isScheduledSendEnabled ? hideText() ? .7 : .5 : undefined}
                      onPress={() => {
                        if (
                          !messagingWindowFooterState?.contactDeactivationStatus ||
                          messagingWindowFooterState.privateNote == 'true'
                        ) {
                          handleSendMessage();
                        } else {
                          setMessagingWindowFooterState((prev) => {
                            return {
                              ...prev,
                              showSendMessageDisable: true,
                            };
                          });
                        }
                      }}
                      // isLoading={messagingWindowFooterState.uploadingMsg}
                      {...testID(TestIdentifiers.messageSendBtn)}
                    >
                      <HStack
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={'100%'}
                        space={2}
                        borderRightWidth={isScheduledSendEnabled ? 1 : 0}
                        borderColor={
                          messagingWindowFooterState?.messageType !== 'reply'
                            ? Colors.secondary['500']
                            : Colors.Custom.PrimaryColor200
                        }
                      >
                        <View {...testID(TestIdentifiers.messageSendIcon)}>
                          <SendButtonSvg
                            isEnabled={true}
                            customStrokeColor={
                              messagingWindowFooterState?.messageType !==
                              'reply'
                                ? Colors.secondary['500']
                                : Colors.primary['300']
                            }
                          />
                        </View>
                        {hideText() && (
                          <Text
                            color={
                              messagingWindowFooterState?.messageType !==
                              'reply'
                                ? Colors.secondary['500']
                                : Colors.primary['300']
                            }
                            {...testID('Send')}
                          >
                            {'Send'}
                          </Text>
                        )}
                      </HStack>
                    </Pressable>
                    {isScheduledSendEnabled ?
                    <Pressable
                      flex={hideText() ? .3 : .5}
                      alignItems={'center'}
                      justifyContent={'center'}
                      height={'100%'}>
                      {isScheduledSendEnabled &&
                        messagingWindowFooterState?.showSchedulePopover && (
                          <View
                            ref={datePickerRef}
                            position={'absolute'}
                            {...testID(TestIdentifiers.scheduleDatePickerView)}
                          >
                            <DatePicker
                              className="popover-class"
                              open={
                                messagingWindowFooterState?.showSchedulePopover &&
                                !messagingWindowFooterState?.loading
                              }
                              showTime={{format: 'HH:mm'}}
                              renderExtraFooter={() => (
                                <>{datePickerFooter()}</>
                              )}
                              disabledDate={(current: any) => {
                                return current && isPastDay(current);
                              }}
                              onOk={onOk}
                              disabledTime={disabledDateTime}
                              locale={{
                                ...locale,
                                lang: {
                                  ...locale.lang,
                                  ok: 'Schedule Send',
                                },
                              }}
                              data-testid={TestIdentifiers.datePicker}
                            />
                          </View>
                        )}
                      {isScheduledSendEnabled && (
                        <HStack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                            <Pressable
                              height={'20px'} width={'20px'} marginTop={1}
                              onPress={() => {
                                if (
                                  messagingWindowFooterState?.richTextValue
                                    ?.length > 0
                                ) {
                                  setMessagingWindowFooterState((prev) => {
                                    return {
                                      ...prev,
                                      showSchedulePopover: true,
                                    };
                                  });
                                }
                              }}
                              {...testID(TestIdentifiers.scheduleBtnClick)}
                            >
                              {!messagingWindowFooterState?.loading ? (
                                <View {...testID(TestIdentifiers.scheduleIcon)}>
                                  <SendScheduleMsgSvg
                                    isEnabled={true}
                                    customStrokeColor={
                                      messagingWindowFooterState?.richTextValue
                                        ?.length > 0
                                        ? messagingWindowFooterState?.messageType !==
                                          'reply'
                                          ? Colors.secondary['500']
                                          : Colors.primary['300']
                                        : messagingWindowFooterState?.messageType !==
                                          'reply'
                                        ? Colors.secondary['300']
                                        : Colors.primary['100']
                                    }
                                  />
                                </View>
                              ) : (
                                <View
                                  position={'absolute'}
                                  {...testID(TestIdentifiers.lazyLoading)}
                                >
                                  <Spinner accessibilityLabel="Loading posts" />
                                </View>
                              )}
                            </Pressable>
                        </HStack>
                      )}
                    </Pressable>
                    : <></>}
                  </HStack>
                </View>
              </VStack>
            </HStack>
            <HStack alignItems={'center'} flex={1}>
              <View style={{flexDirection: 'row'}}>
                <Content>
                  <View style={{flex: 1, flexDirection: 'row'}}>
                    <Pressable
                      onPress={handleOnEnterActionChangePopup}>
                      <View style={{flex: 1, flexDirection: 'row'}}>
                        <Text
                          {...testID('OnEnterActionMsgFooterText')}
                          style={{
                            color: Colors.Custom.Gray400,
                            minWidth: 'fit-content',
                          }}
                        >
                          {
                            messagingWindowFooterState.onEnterActionMsgFooterText
                          }
                          •
                        </Text>
                        <Popover
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <VStack>
                              <Text
                                style={{
                                  color: Colors.Custom.Gray400,
                                  fontWeight: '700',
                                }}
                                fontFamily={'Manrope'}
                                letterSpacing={1}
                                fontSize={'12'}
                                {...testID('PressEnterTo')}
                              >
                                PRESS
                                <Text
                                  style={{
                                    backgroundColor: Colors.Custom.Gray100,
                                    color: Colors.Custom.Gray500,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    borderRadius: 4,
                                    marginLeft: 6,
                                    marginRight: 6,
                                    fontWeight: '500',
                                  }}
                                  fontSize={'16'}
                                >
                                  Enter
                                </Text>
                                TO ...
                              </Text>
                              <Radio.Group
                                onChange={(e) => {
                                  handleOnEnterActionFooterText(e);
                                }}
                                style={{marginTop: 0}}
                                value={
                                  messagingWindowFooterState.onSelectedEnterNewLine
                                    ? ON_PRESS_ENTER_ACTION_CODES.NEW_LINE
                                    : ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE
                                }
                                data-testid={TestIdentifiers.radioGroup}
                              >
                                <Divider
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    width: 225,
                                    borderColor: Colors.Custom.Gray300,
                                  }}
                                  thickness={1.5}
                                />
                                <Radio
                                  value={
                                    ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE
                                  }
                                  onClick={handleOnEnterActionChangePopup}
                                  style={{
                                    paddingLeft: 16,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                  data-testid={TestIdentifiers.radioButton}
                                >
                                  <Text
                                    {...testID('SendTheMessage')}
                                    style={{color: Colors.Custom.Gray500}}
                                    fontSize={16}
                                  >
                                    {intl.formatMessage({id: 'sendTheMessage'})}
                                  </Text>
                                </Radio>
                                <Divider
                                  style={{
                                    marginTop: 6,
                                    marginBottom: 6,
                                    marginLeft: 16,
                                    width: 193,
                                    borderColor: Colors.Custom.Gray200,
                                  }}
                                />
                                <Radio
                                  value={ON_PRESS_ENTER_ACTION_CODES.NEW_LINE}
                                  onClick={handleOnEnterActionChangePopup}
                                  style={{paddingLeft: 16, paddingTop: 5}}
                                  data-testid={TestIdentifiers.radioButton}
                                >
                                  <Text
                                    {...testID('StartANewLine')}
                                    style={{color: Colors.Custom.Gray500}}
                                    fontSize={16}
                                  >
                                    {intl.formatMessage({id: 'startANewLine'})}
                                  </Text>
                                </Radio>
                              </Radio.Group>
                            </VStack>
                          }
                          title=""
                          trigger="click"
                          placement={'bottomRight'}
                          visible={
                            messagingWindowFooterState.isShowOnEnterActionPopup
                          }
                          onVisibleChange={() => {
                            setMessagingWindowFooterState((prev) => {
                              return {
                                ...prev,
                                isShowOnEnterActionPopup:
                                  !prev.isShowOnEnterActionPopup,
                              };
                            });
                          }}
                        >
                          <Text
                            {...testID(TestIdentifiers.changeText)}
                            style={{color: Colors.Custom.PurpleColor}}
                          >
                            {intl.formatMessage({id: 'change'})}
                          </Text>
                        </Popover>
                      </View>
                    </Pressable>
                  </View>
                </Content>
              </View>
              <View flex={1} />
              {(isArchiveEnable &&
              messagingWindowFooterState.showArchiveOnSend) ? (
                <HStack justifyContent={'flex-end'} alignItems={'center'}>
                  <Checkbox
                    defaultChecked={isArchiveOnSend(props?.archiveOnSendData)}
                    checked={isArchiveOnSend(props?.archiveOnSendData)}
                    key={conversationData.id}
                    onChange={(item) => {
                      onActionMsgFooterPerformed(
                        COMMON_ACTION_CODES.CHANGE_ARCHIVE_ON_SEND,
                        {
                          isArchiveSendChecked: item?.target?.checked,
                        }
                      );
                      setMessagingWindowFooterState((prev) => {
                        return {
                          ...prev,
                          isArchiveOnMessageSend: item?.target?.checked,
                        };
                      });
                    }}
                    data-testid={TestIdentifiers.checkbox}
                  >
                    <Tooltip title="Archive on send">
                      <Text
                        fontSize={hideText() ? '12px' : 11}
                        fontWeight={600}
                        color={Colors.Custom.Gray400}
                        {...testID('ArchiveOnSend')}
                      >
                        Archive on send
                      </Text>
                    </Tooltip>
                  </Checkbox>
                </HStack>
              ) : (
                <></>
              )}
            </HStack>
          </VStack>
        </VStack>

        <AttachmentDrawer
          isDrawerVisible={messagingWindowFooterState.isDrawerVisible}
          fileList={messagingWindowFooterState.fileList}
          onDrawerActionPerformed={onDrawerActionPerformed}
          conversationData={conversationData}
          onMsgSend={onMsgSend}
          message={parseHtmlToPlainText(
            messagingWindowFooterState.richTextValue.trim()
          )}
          privateNote={messagingWindowFooterState.privateNote}
        />
        <AttachFormDrawer
          conversationData={conversationData}
          onMsgSend={onMsgSend}
          isOpen={messagingWindowFooterState.formDrawer}
          privateNote={messagingWindowFooterState.privateNote}
          onClose={() => {
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                formDrawer: false,
              };
            });
          }}
          onFormSelect={concateFormLinkMessage}
          updateMessage={concateFormLinkMessage}
          isUpdateMode
        />

        {messagingWindowFooterState.showMediaDrawer && (
          <MediaSelectionModal
            isOpen={messagingWindowFooterState.showMediaDrawer}
            onClose={() => {
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                showMediaDrawer: false,
              });
            }}
            onSelect={async (mediaData) => {
              if (mediaData.url) {
                const shortLink = await getShortLink(mediaData.url);
                const updatedText = getUpdatedMessage(shortLink);

                if (editorRef?.current) {
                  const inst = editorRef.current.getInstance();
                  inst.setMarkdown(updatedText);
                }
                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    showMediaDrawer: false,
                    selectedReplyMsg: {} as IReplyMessageObject,
                    richTextValue: updatedText,
                  };
                });
              }
            }}
          />
        )}

        {messagingWindowFooterState.articleDrawer && (
          <AttachArticleDrawer
            isDrawerVisible={messagingWindowFooterState.articleDrawer}
            privateNote={messagingWindowFooterState.privateNote}
            onClose={() => {
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  articleDrawer: false,
                };
              });
            }}
            conversationData={conversationData}
            onMsgSend={onMsgSend}
            onArticleSelect={concateArticleMessage}
            updateMessage={concateArticleMessage}
            isUpdateMode
          />
        )}

        {messagingWindowFooterState.isMsgReplyDrawerVisible && (
          <MsgReplyDrawer
            conversationData={props?.conversationData}
            conversationInbox={conversationInbox}
            onMsgSend={(
              msgText: string,
              msgData?: any,
              parentMessage?: IReplyMessageObject | undefined
            ) => {
              const currentTime = getDateToMomentISOString();
              const responseData = {...msgData, currentTime};
              props.onMsgSend(msgText, responseData, parentMessage);
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  isMsgReplyDrawerVisible: false,
                };
              });
              if (editorRef.current) {
                const inst = editorRef.current.getInstance();
                inst.reset();
              }
            }}
            showMessageTypeTab={true}
            selectedTabCode={selectedTabCode}
            messageType={messagingWindowFooterState.messageType}
            selectedInboxTypeCode={selectedInboxTypeCode}
            onActionMsgFooterPerformed={onActionMsgFooterPerformed}
            isDrawerVisible={messagingWindowFooterState.isMsgReplyDrawerVisible}
            onMsgReplyActionPerformed={onMsgReplyActionPerformed}
            contactData={contactData}
            msgText={getPlainString()}
            isReplyMode
            onMentionActionPerformed={(
              actionCode: string,
              actionData?: any
            ) => {
              props?.onMentionActionPerformed &&
                props?.onMentionActionPerformed(actionCode, actionData);
            }}
          />
        )}
        <DeleteMessageModal
          titleMessage="deactivateMessageTxt"
          heading="Message"
          onConfirm={() => {
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                showSendMessageDisable: false,
              };
            });
          }}
          onClose={onClose}
          isOpen={
            messagingWindowFooterState?.showSendMessageDisable ? true : false
          }
        />

        {messagingWindowFooterState.appointmentDrawer && (
          <AttachAppointmentDrawer
            isOpen={messagingWindowFooterState.appointmentDrawer}
            conversationData={conversationData}
            onClose={() => {
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                appointmentDrawer: false,
              });
            }}
            onSendMessage={(appointmentMessage: string) => {
              //sendMessage(appointmentMessage);
              const updatedText = getUpdatedMessage(appointmentMessage);

              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                appointmentDrawer: false,
                selectedReplyMsg: {} as IReplyMessageObject,
                richTextValue: updatedText,
              });

              if (editorRef?.current) {
                const inst = editorRef.current.getInstance();
                inst.setMarkdown(updatedText);
              }
            }}
            isUpdateMode
          />
        )}

        {messagingWindowFooterState.contactDrawer && (
          <ContactSearchDrawer
            onMsgSend={() => {
              sendContactCard(MESSAGE_CUSTOM_CARD_KEY.CONTACT_CARD);
            }}
            onFormCompleteAction={(actionCode: string) => {
              onViewChangeActionPerformed(actionCode);
            }}
            onAutoCompleteChange={(action: any, data: any) => {
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                browseContactUUID: data,
              });
            }}
          />
        )}

        {messagingWindowFooterState.scheduleMessageDrawer && (
          <ScheduleMessageContainer
            onFormCompleteAction={(actionCode: string) => {
              onViewChangeActionPerformed(actionCode);
            }}
            conversationUuid={conversationData?.uuid}
            handleSendMessage={handleSendMessage}
          />
        )}

        {messagingWindowFooterState.code ===
          RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS && (
          <AddOrUpdateLead
            isShowAddContactTypeOption={true}
            personTypeUuid={getContactTypeId('CUSTOMER')}
            personType={'CUSTOMER'}
            singleLeadData={undefined}
            onFormCompleteAction={() => {
              onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
            }}
            handleCloseModal={() => {
              setMessagingWindowFooterState((prev) => {
                return {
                  ...prev,
                  code: '',
                  contactDrawer: false,
                };
              });
            }}
            isShowModal={
              messagingWindowFooterState.code ===
              RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS
            }
          />
        )}
      </View>
    </>
  );
};

export default MessagingWindowFooter;
