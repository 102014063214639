export const TAB_KEYS = {
    PATIENT: 'patient',
    MY_PATIENT: 'myPatient',
    PROSPECT: 'prospect',
    GROUPS: 'groups',
    LEAD_GROUPS: 'lead_groups',
    CODE_GROUPS: 'codeGroups',
    IMPORT_SUMMARY: 'importSummary',
    OVERVIEW: 'OVERVIEW'
};

export const COMPARISON_OPERATOR = {
  ANY: 'ANY'
}
