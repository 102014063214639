import {FormBuilder} from '@foldhealth/formio-react';
import React from 'react';
import '../FHFormio';
import FileService from '../FileService/FileService';
import {FormBuilder as FormioFormBuilder, Builders} from '@foldhealth/formiojs';
import ReactDOM from 'react-dom';
import CustomWrapper from '../CustomComponents/CustomWrapper/CustomWrapper';
import Dragula from 'dragula';
import FormBuilderSideBar from '../FormBuilderSideBar/FormBuilderSideBar';
import {
  IBuilderComponent,
  IBuilderGroups,
} from '../FormBuilderSideBar/intefaces';
import {Divider} from 'antd';
import {HStack, View} from 'native-base';
import { Colors } from '../../../../../styles';

const WebformBuilder = Builders.builders.webform;

class CustomWebformBuilder extends WebformBuilder {
  render() {
    return this.renderTemplate('builder', {
      sidebar: `<div id="builder-sidebar-${this.id}" ref="sidebar"></div>`,
      form: this.webform.render(),
    });
  }

  getBuilderGroups() {
    const groups: IBuilderGroups[] = [];
    this.groupOrder.forEach((groupKey: string) => {
      const group = this.groups[groupKey];
      const components: IBuilderComponent[] = [];
      if (group.componentOrder?.length) {
        group.componentOrder.forEach((componentKey: string) => {
          const component = group.components[componentKey];
          if (component) {
            components.push({
              title: component.title,
              key: component.key,
              group: group.key,
              icon: component.icon,
              iconLib: component.iconLib || 'AntIcon',
              weight: component.weight,
              type: component.schema?.type || '',
              schema: component.schema,
            });
          }
        });
      }
      groups.push({
        title: group.title,
        key: group.key,
        weight: group.weight,
        components: components && components.length ? components : [],
      });
    });
    return groups;
  }

  attachReact(element: any) {
    const groups = this.getBuilderGroups();
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <HStack style={{height: '79vh'}}>
          <View width={'100%'} paddingY={'10px'}>
            <FormBuilderSideBar
              groups={groups}
              onFilterChange={() => {
                this.updateDragAndDrop();
              }}
            />
          </View>
          <View style={{height: 'auto', width: 0.5, backgroundColor: Colors.Custom.Gray200}} />
        </HStack>
      </CustomWrapper>,
      element
    );
  }

  attach(element: any) {
    this.on('change', (form: any) => {
      this.populateRecaptchaSettings(form);
    });
    return super.attach(element).then(() => {
      this.loadRefs(element, {
        form: 'single',
        sidebar: 'single',
      });
      this.attachReact(this.refs.sidebar);
      if (this.dragDropEnabled) {
        this.initDragula();
      }

      if (this.refs.form) {
        return this.webform.attach(this.refs.form);
      }
    });
  }

  initDragula() {
    const options = this.options;

    if (this.dragula) {
      this.dragula.destroy();
    }

    const data = this.refs.sidebar.querySelectorAll(
      '.fh-form-sidebar-container'
    );
    const containersArray = Array.prototype.slice.call(data).filter((item) => {
      return item.id !== 'group-container-resource';
    });

    this.dragula = Dragula(containersArray, {
      moves(el) {
        if (!el) return false;
        let moves = true;

        const list = Array.from(el.classList).filter(
          (item) => item.indexOf('formio-component-') === 0
        );
        list.forEach((item) => {
          const key = item.slice('formio-component-'.length);
          if (options.disabled && options.disabled.includes(key)) {
            moves = false;
          }
        });

        if (el.classList.contains('no-drag')) {
          moves = false;
        }
        return moves;
      },
      copy(el) {
        return el.classList.contains('drag-copy');
      },
      accepts(el, target) {
        if (!el || !target) return false;
        return (
          !el.contains(target) &&
          !target.classList.contains('fh-form-sidebar-container')
        );
      },
    }).on('drop', (element, target, source, sibling) => {
        this.scrollPosition = this.refs.form.scrollTop;
        this.onDrop(element, target, source, sibling);
      }
    );
  }
}

class CustomFormBuilder extends FormioFormBuilder {
  create(display: string) {
    Builders.builders['webform'] = CustomWebformBuilder;
    // return new CustomWebformBuilder(this.element, this.options);

    if (Builders.builders[display]) {
      return new Builders.builders[display](this.element, this.options);
    } else {
      return new Builders.builders['webform'](this.element, this.options);
    }
  }
}

const FHFormBuilder = (props: {
  defaultComponents?: any;
  builderConfig: any;
  options?: any;
  onChange?: (data: any) => void;
  onEditComponent?: () => void;
  onRemoveComponent?: (isSelectedComponentRemoved: any) => void;
}) => {
  return (
    <View marginX={4}>
      <FormBuilder
        form={{
          display: 'form',
          components: props.defaultComponents,
        }}
        Builder={CustomFormBuilder}
        onChange={(schema: any) => {
          if (props.onChange) {
            props.onChange(schema);
          }
        }}
        options={{
          ...props.options,
          builder: props.builderConfig,
          fileService: new FileService(),
          noDefaultSubmitButton: true,
          onEditComponent: props?.onEditComponent,
          onRemoveComponent: props?.onRemoveComponent
        }}
      />
    </View>
  );
};
export default FHFormBuilder;
