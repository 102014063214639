import React from 'react';
// Views
import {Button,Divider,HStack,IToastProps,Icon,Pressable,Spinner,Text,VStack,View} from 'native-base';
import {useContext} from 'react';
import {IntlShape} from 'react-intl';
// import {TagView} from '../../Table/TagView';
import {Checkbox,Popover,Tooltip, notification} from 'antd';
import moment from 'moment';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {getCurrentTimeZone,getDateStrFromFormat,getEndOfDay,getISODateFromDisplayDate,getNextDateStartOfDay,getPreviousDateEndOfDay,getStartOfDay} from '../../../../utils/DateUtils';
import {
  BottomViewAction,
  getPriorityIcon,
  renderResourceMapCountAndDeleteField,
} from '../../../TaskCard/TaskCardHelper';
import {isAllowToDeleteTask} from '../../../TaskCard/TaskHelper';
import UserAutoComplete,{
  IUserSearch,
} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import {ITask, ITaskActionParams} from '../CareDashboardInterfaces';
import {IUserPool} from '../CareDashboardWidget/UserAutoComplete';


import {LazyQueryExecFunction,OperationVariables} from '@apollo/client';
import {ColumnsType} from 'antd/es/table';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {IMlov,IUserRoleCode,IVitalData} from '../../../../Interfaces/CommonInterfaces';
import {MLOV_CATEGORY,TASK_PRIORITY_CODES,TASK_STATUS,TASK_STATUS_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext,ICommonData} from '../../../../context/CommonDataContext';
import {isEmployerRole} from '../../../../utils/commonUtils';
import {ToastType,showToast} from '../../../../utils/commonViewUtils';
import {getMlovId,getMlovIdFromCode,getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {TaskPanelType} from '../../../TaskCard/TaskEnum';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import SubtasksSvg from '../../Svg/SubtasksSvg';
import {ITopBarData} from '../CareDashboardTopBar/CareDashboardTopBar';
import {dateRangeStatusUuid} from '../CareDashboardTopBar/FilterDrawerView';
import {BoardType} from '../CareDashboardTopBar/interfaces';
import {PropertyType,getColumnName,getCompletedTaskStatusId} from '../CareDashboardUtils/CareDashboardUtils';
import {LabelDataListView} from '../CareDashboardWidget/LabelDataListView';
import CareDashboardTableActions from './CareDashboardTableActions';
import { DATE_FORMATS } from '../../../../constants';
import UserIcon from '../../../../assets/Icons/UserIcon';
import AutomationIcon from '../../../../assets/Icons/AutomationIcon';
import JourneyIcon from '../../../../assets/Icons/JourneyIcon';
import { TASK_TABS } from '../CareDashboardConstants';
import NoteLinkIcon from '../../../../assets/Icons/NoteLinkIcon';
import { SOURCE_MAP } from '../../AddTask/AddTaskUtils';
import TrashBin2Icon from '../../../../assets/Icons/TrashBin2Icon';
import ReadMsgSvg from '../../Svg/ReadMsgSvg';
import ClockIcon from '../../../../assets/Icons/ClockIcon';
import MissedCallSvg from '../../Svg/ConversationAllSvg/MissedCallSvg';
import Missed from '../../../../assets/Icons/Missed';
import ReadMsgSvg2024 from '../../Svg/ReadMsgSvg2024';
import TrashBinIcon2024 from '../../../../assets/Icons/TrashBinIcon2024';
import ClockIcon2024 from '../../../../assets/Icons/ClockIcon2024';
import MissedTaskIcon2024 from '../../../../assets/Icons/MissedTaskIcon2024';

const TASK_STATUS_FILTER_CODES = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  ALL: 'All',
};
const TASK_STATUS_FILTER_VALUES = {
  PENDING: 'Not Completed',
  COMPLETED: 'Completed',
  MISSED: 'Missed',
}


export const TASK_ASSIGNEE_TYPE_CODES = {
  User: 'USER',
  Contact: 'CONTACT',
}


export const MAX_PAGES_ALLOWED_IN_LIST = 2;
export const PAGE_SIZE = 25;

const isTaskCompleted = (task: ITask): boolean => {
  if (task.status && task.status.value) {
    return task.status.value === 'Completed'
  }
  return false;
}

const getTaskAssignee = (
  task: ITask,
  userList: {
    id: string;
    name: string;
    uuid: string;
    email: string;
  }[]
) => {
  const assigneeType = task.assigneeTypeCode;
  switch (assigneeType) {
    case TASK_ASSIGNEE_TYPE_CODES.User:
      const assigneeValue = userList
          .filter((user: any) => user.uuid === task?.assigneeId)
          .map((user: any) => ({
            value: user.uuid,
            label: user.name,
            key: user.uuid,
          }));
      return assigneeValue[0];
    case TASK_ASSIGNEE_TYPE_CODES.Contact:
        return {
          value: task?.assigneeUser?.uuid,
          label: task?.assigneeUser?.name,
          key: task?.assigneeUser?.uuid,
        }
  }
  return '';
};

export const getMatchActiveUserById = (userId: string, accountUserList?: any[]): any => {
  return (accountUserList || []).find(user => {
    return user?.uuid === userId;
  });
}

export const getUserObjectForDisplay = (selectedUser: any) => {
  return {
    key: selectedUser?.uuid,
    value: selectedUser?.uuid,
    label: selectedUser?.name,
    contactData: selectedUser,
    type: ParticipantType?.staff
  }
}

export const filterActiveUsersAndDefaultAssigneeOfPool = (pool: any, userList: { id: string; name: string; uuid: string; email: string; }[]) => {
  const selectedPoolUsers: any[] = [];
  let defaultAssignee:any = undefined;
  if (pool?.id && pool?.userPoolUsers?.length) {
    (pool?.userPoolUsers || []).forEach((user: any) => {
      const selectedUser = getMatchActiveUserById(user?.userId, userList);
      if (selectedUser) {
        const userDisplayObject = getUserObjectForDisplay(selectedUser);
        if (user.isDefault) {
          defaultAssignee = userDisplayObject;
        } else {
          selectedPoolUsers.push(userDisplayObject);
        }
      }
    });
  }
  return {
    defaultAssignee,
    selectedPoolUsers,
  };
}

export const isAssigneePartOfPool = (userId: string, poolId: string, userPools: IUserPool[] | undefined) => {
  return (userPools || []).some(pool => {
    if (pool?.id === poolId && pool?.userPoolUsers?.length) {
      return (pool?.userPoolUsers || []).some(user => {
        return user?.userId === userId;
      });
    }
    return false;
  })
};

export const isAllowToEditTask = (task: ITask | any) => {
  const isCareJourneyOrFormTask  = task?.referenceData?.careJourneyId || task?.referenceData?.formId || task?.referenceData?.entityType == 'FORM';
  return task?.id && !isCareJourneyOrFormTask ? true : false;
}

export const isAllowMarkAsComplete = (task: ITask | any) => {
  if (!task?.id) {
    return false;
  }
  // if task is not completed then any one can mark it as completed
  return !(task?.isCompleted || isTaskCompleted(task));
}


export const getTaskTableColumns = (
  intl: IntlShape,
  accessUserData: IUserSearch[],
  onBottomViewAction: (
    task: ITask,
    action: BottomViewAction,
    data?: any
  ) => void,
  onTaskDetail: (data: ITask) => void,
  userList: {
    id: string;
    name: string;
    uuid: string;
    email: string;
  }[],
  loginUserId: string,
  currentUserRoles: IUserRoleCode[],
  boardType: BoardType,
  toast: {
    show: (props: IToastProps) => any;
    close: (id: any) => void;
    closeAll: () => void;
    isActive: (id: any) => boolean;
  },
  getSubTaskByIds: LazyQueryExecFunction<any, OperationVariables>,
  onChangeCategory?: (
    sourceDroppableId: string,
    destDroppableId: string,
    reorderItem: ITask
  ) => void,
  categorySection?: string,
  assignee?: IUserSearch[],
  userTaskDisplayCategory?: any,
  shouldCategoryChangeNotDisplay = false,
  userPool?: IUserPool[],
  onDeleteTaskHandler?: (data: ITask) => void,
  selectedSortUuid?: string,
  selectedSortValueAscending?: boolean,
  selectedStatusList?: IMlov[],
  selectedPriorityList?: IMlov[],
  onMemberClick?: (task: ITask) => void,
  vitals?: IVitalData[],
  style?: any,
  onTaskAction?: (code: string, data: any) => void,
): any[] => {
  const sortOrder = selectedSortValueAscending ? 'ascend' : 'descend';
  const statusFilteredValue = selectedStatusList?.map((mlov) => mlov.code) || null;
  const priorityFilteredValue = selectedPriorityList?.map((mlov) => mlov.code) || null;
  const isEmployer = isEmployerRole();
  const showFilterForPriority = boardType !== BoardType.Priority
  const showFilterForStatus = boardType !== BoardType.Status
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if (!task.subTasks || task.subTasks?.length === 0) {
      onBottomViewAction(task, BottomViewAction.markAsComplete);
      return;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map((task) => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.some(
          (subTask: any) => subTask.statusId !== completedStatusId
        )
      ) {
        showToast(
          toast,
          intl.formatMessage({
            id: 'subTaskPendingMsg',
          }),
          ToastType.error
        );
        return;
      } else {
        onBottomViewAction(task, BottomViewAction.markAsComplete);
        return;
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        ToastType.error
      );
      return;
    }
  };

  const columns: ColumnsType<ITask> = [
    {
      key: 'completionStatus',
      width: '3%',
      render: (task: ITask) => {

        // if task not completed any one can mark it as complete
        const canMarkAsComplete = true;

        return (
          <View flexDirection="row" alignItems={'center'}>
            {canMarkAsComplete && (
              <>
                <Tooltip
                  title={
                    isTaskCompleted(task)
                      ? 'Mark As Incomplete'
                      : 'Mark As Complete'
                  }
                  placement='topLeft'
                >
                    <Checkbox
                      style={{marginTop: 1, color: Colors.Custom.SuccessColor}}
                      checked={isTaskCompleted(task)}
                      // colorScheme="success"
                      // value="complete"
                      // accessibilityLabel="mark as complete"
                      onChange={async (event) => {
                        if (event.target.checked) {
                          await checkIfAllSubTasksAreCompleted(task);
                        } else {
                          onBottomViewAction(
                            task,
                            BottomViewAction.markAsIncomplete
                          );
                        }
                      }}
                    />
                </Tooltip>
              </>
            )}
          </View>
        );
      },
    },
    {
      onCell: (record: ITask) => {
        return {
          onClick: () => onTaskDetail(record),
        };
      },
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'title'})}
        </Text>
      ),
      key: 'title',
      // dataIndex: 'title',

      // sorter: (a:any, b:any) => a.title.length - b.title.length,
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'title' ? sortOrder : null,
      width: '22%',
      render: (task: ITask) => {
        const renderFieldParam: ITaskActionParams = {
          task: task,
          loginUserId: loginUserId,
          currentUserRoles: currentUserRoles || [],
          showDeleteField: false,
          intl: intl
        };
        return (
          <VStack>
            <View flexDirection="row" wordBreak={'break-word'}>
              {task?.parentId && (
                <View size={18} marginRight={1}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
              )}
              <Text flex={9} size={'smMedium'} color={Colors.Custom.Gray900}>
                {task?.title}
              </Text>
            </View>
            {task?.subTasks?.length ? (
              <View flexDirection="row" mt={1}>
                <View size={18}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
                <Text
                  ml={1}
                  size={'smMedium'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${task?.subTasks?.length} ${
                    task?.subTasks?.length > 1 ? 'Subtasks' : 'Subtask'
                  } `}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {renderResourceMapCountAndDeleteField(renderFieldParam)}
          </VStack>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500}}
          cursor="pointer"
        >
          <Tooltip title={intl.formatMessage({id: 'Priority'})}>P</Tooltip>
        </Text>
      ),
      key: 'priority',
      filteredValue: priorityFilteredValue,
      width: '4%',
      filterIcon: showFilterForPriority ?  (filtered: boolean) => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      filters: showFilterForPriority ? [
        {
          text: 'High',
          value: TASK_PRIORITY_CODES.HIGH,
        },
        {
          text: 'Medium',
          value: TASK_PRIORITY_CODES.MEDIUM,
        },
        {
          text: 'Low',
          value: TASK_PRIORITY_CODES.LOW,
        },
      ] : undefined,
      onFilter: (value, record) => {
        return record.priority?.code?.includes(value as string) || false;
      },
      filterSearch: true,
      render: (task: ITask) => {
        return (
          <View flexDirection="row" justifyContent={'center'}>
            <View flex={1}>{getPriorityIcon(task?.priority, true)}</View>
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'labels'})}
        </Text>
      ),
      key: 'labels',
      width: '15%',
      render: (task: ITask) => {
        return (
          <LabelDataListView
            key={task.id}
            hideAddMoreButton={true}
            tagList={task?.labels || []}
          />
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'patientName'})}
        </Text>
      ),
      key: 'patient',

      // dataIndex: 'contact',
      // sorter: (patient1: any, patient2: any) => {
      //   const patient1Name: string =
      //     patient1?.contact?.name ||
      //     patient1?.patient?.name ||
      //     patient1?.patientName ||
      //     '';
      //   const patient2Name: string =
      //     patient2?.contact?.name ||
      //     patient2?.patient?.name ||
      //     patient2?.patientName ||
      //     '';
      //   return patient1Name.localeCompare(patient2Name);
      // },
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: selectedSortUuid === 'patient' ? sortOrder : null,
      width: '10%',
      render: (task: ITask) => {
        return (
          <View flexDirection="row" alignItems="center">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {task.contact && task.contact.name
                ? task.contact.name
                : task.patientName
                ? task.patientName
                : '-'}
            </Text>
            {task?.contact?.uuid && (
              <Icon
                onPress={() => {
                  onMemberClick?.(task);
                }}
                as={Feather}
                name="external-link"
                size="xs"
                ml={1}
                color={Colors.Custom.Gray500}
              />
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'DueDate'})}
        </Text>
      ),
      key: 'endDateTime',
      dataIndex: 'endDateTime',
      sorter: (a: any, b: any) =>
        moment(a.endDateTime).unix() - moment(b.endDateTime).unix(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'endDateTime' ? sortOrder : null,
      width: '10%',
      render: (endDateTime: string) => {
        return (
          <View flexDirection="row">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {getDateStrFromFormat(endDateTime || new Date(), DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Status'})}
        </Text>
      ),
      key: 'status',
      dataIndex: 'status',
      filters: showFilterForStatus ? [
        {
          text: TASK_STATUS_FILTER_VALUES.COMPLETED,
          value: TASK_STATUS.COMPLETED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.PENDING,
          value: TASK_STATUS.ACCEPTED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.MISSED,
          value: TASK_STATUS.MISSED,
        },
      ] : undefined,
      onFilter: (value, record) => {
        if (value === TASK_STATUS.ACCEPTED) {
          return record.status?.code !== TASK_STATUS.COMPLETED;
        } else {
          return record.status?.code.includes(value as string) || false;
        }
      },
      filterSearch: true,
      filteredValue: statusFilteredValue,
      filterIcon: showFilterForStatus ? (filtered: boolean) => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      width: '8%',
      render: (status: any) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {status && status.value ? status.value : '-'}
          </Text>
          // <HStack>
          //   {isCompleted && (
          //     <Feather
          //       name="check-circle"
          //       size={20}
          //       style={{
          //         marginRight: 5,
          //       }}
          //       color={Colors.Custom.SuccessColor}
          //     />
          //   )}
          //   <Text
          //     size={'smMedium'}
          //     color={
          //       isCompleted ? Colors.Custom.SuccessColor : Colors.Custom.Gray900
          //     }
          //   >
          //     {status && status.value ? status.value : '-'}
          //   </Text>
          // </HStack>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Assignee'})}
        </Text>
      ),
      key: 'Assignee',
      width: '18%',
      // sorter: (a: any, b: any) => a.assigneeName.localeCompare(b.assigneeName),
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: selectedSortUuid === 'Assignee' ? sortOrder : null,
      render: (task: ITask) => {
        const assigneeValue = getTaskAssignee(task, userList);
        const isNoteContextTask = task?.resourceMap?.notes;
        let poolUsers: any[] = [];
        let poolDefaultUser: any = undefined;
        if (userPool?.length && task?.userPoolId) {
          const selectedPool = (userPool || []).find(pool => {
            return pool?.id === task?.userPoolId;
          });
          if (selectedPool?.id) {
            const {selectedPoolUsers, defaultAssignee} = filterActiveUsersAndDefaultAssigneeOfPool(selectedPool, userList);
            poolUsers = selectedPoolUsers || [];
            poolDefaultUser = defaultAssignee || undefined;
          }
        }

        return (
          <View style={{marginTop: 7, marginLeft: 4, width: '100%'}}>
            <Pressable>
              {/* <CareDashboardChangeAssignee
                assignee={assignee}
                onChangeAssignee={(assignee: IUserSearch) => {
                  onBottomViewAction(
                    task,
                    BottomViewAction.changeAssignee,
                    assignee
                  );
                }}
                userPool={userPool}
              /> */}

              {/* For All Users */}
              {/* <UserAutoComplete
                labelText={''}
                isShowError={false}
                allowClear= {false}
                selectedValue={
                  assigneeValue && assigneeValue.length > 0
                    ? assigneeValue[0]
                    : undefined
                }
                onChange={(selectedUser: any) => {
                  onBottomViewAction(
                    task,
                    BottomViewAction.changeAssignee,
                    selectedUser
                  );
                }}
                usersData={userList}
                minWidth={220}
              /> */}
              {isEmployer && (
                <UserAutoComplete
                  labelText={''}
                  isShowError={false}
                  allowClear={false}
                  isDisabled={task?.status?.value === 'Completed' ? true : false}
                  selectedValue={assigneeValue || undefined}
                  excludeOtherEmployers={true}
                  onChange={(selectedUser: any) => {
                    onBottomViewAction(
                      task,
                      BottomViewAction.changeAssignee,
                      {
                        ...selectedUser,
                        type: ParticipantType.staff,
                      }
                    );
                  }}
                  usersData={userList}
                  minWidth={'100%'}
                />
              )}
              {!isEmployer && (
                <PatientSearchAndSelect
                  showCurrentUserAtTop={task?.userPoolId ? false : true}
                  isProspect={true}
                  showEmail
                  isDisabled={task?.status?.value === 'Completed'? true : false}
                  showError={false}
                  placeholder="Search Staff or Member"
                  value={assigneeValue ? assigneeValue : undefined}
                  onChange={(participants: any, data: any) => {
                    const contactIdValue =
                      participants?.label?.props?.contactData?.uuid ||
                      participants?.value;
                    const contactIdKey = contactIdValue || participants?.key;
                    const assigneeData = {
                      label: participants?.label,
                      key: contactIdKey,
                      value: contactIdValue,
                      type: data?.type,
                    };

                    if (task?.userPoolId && !isAssigneePartOfPool(contactIdKey, task?.userPoolId, userPool)) {
                      task.userPoolId = undefined;
                    }
                    onBottomViewAction(
                      task,
                      BottomViewAction.changeAssignee,
                      assigneeData
                    );
                  }}
                  allowClear={false}
                  selectedPoolUsers={poolUsers}
                  defaultAssigneeInSelectedPool={poolDefaultUser}
                  disablePatientSearch={isNoteContextTask}
                  disableLeadsSearch={isNoteContextTask}
                  showUserPendingTasksCount={true}
                  dropdownPopupMinWidth={450}
                />
              )}
              {/* For the manageable users only which are assigned through the task management */}
              {/* {accessUserData && (
                <Select
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={false}
                  value={assigneeValue || undefined}
                  onChange={(value: any[], data: any) => {
                    if (data && data.value) {
                      const matchedData = finalAccessUserData.filter(
                        (item) => item.key === data.key
                      );
                      const assigneeData = {
                        label: matchedData[0].label,
                        key: matchedData[0].key,
                        value: matchedData[0].value,
                      };
                      onBottomViewAction(
                        task,
                        BottomViewAction.changeAssignee,
                        assigneeData
                      );
                    }
                  }}
                  placeholder={intl.formatMessage({id: 'searchPeople'})}
                  style={{height: '44px'}}
                >
                  {finalAccessUserData.map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              )} */}
            </Pressable>
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'assignedBy'})}
        </Text>
      ),
      key: 'action',
      width: '13%',
      render: (task: ITask) => {
        const {icon, name} = getAssignedByType(task,intl);
        return (
          <View flexDirection="row" alignItems="center">
            <HStack alignItems={'center'} maxW={'100%'}>
              <View>
                {icon}
              </View>
              <Tooltip title={name}>
                <Text
                  ml={1.5}
                  size={'smMedium'}
                  color={Colors.Custom.Gray900}
                  isTruncated
                >
                  {name}
                </Text>
              </Tooltip>
            </HStack>
          </View>
        );
      },
    },

    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'actions'})}
        </Text>
      ),
      key: 'action',
      width: '6%',
      render: (task: ITask) => {
        return (
          <View flexDirection="row" alignItems={'center'}>
            {/* {!shouldCategoryChangeNotDisplay && categorySection && (
              <>
                <View style={{marginTop: 3, marginLeft: 7}}>
                  <CareDashboardChangeCategory
                    onChangeCategory={(category) =>
                      onChangeCategory &&
                      onChangeCategory(categorySection, category, task)
                    }
                    categorySection={categorySection}
                    userTaskDisplayCategory={userTaskDisplayCategory}
                  />
                </View>
              </>
            )} */}

            {isAllowToDeleteTask(task) && (
              <>
                <Tooltip title="Delete task" placement="topLeft">
                  <Button
                    alignContent={'center'}
                    justifyContent={'center'}
                    ml={1}
                    style={{width: 24, height: 24}}
                    backgroundColor={'transparent'}
                    onPress={() => {
                      if (task?.subTasks?.length !== 0) {
                        notification.info({
                          message: intl.formatMessage({
                            id: 'TaskNotDeletedMsgForTaskHasSubtask',
                          }),
                          duration: 3.0,
                        });
                      } else if (
                        task?.subTasks?.length === 0 &&
                        onDeleteTaskHandler &&
                        typeof onDeleteTaskHandler === 'function'
                      ) {
                        onDeleteTaskHandler(task);
                      }
                    }}
                  >
                    <Feather
                      name="trash-2"
                      size={18}
                      color={Colors.Custom.Gray500}
                    />
                  </Button>
                </Tooltip>
              </>
            )}
            {onTaskAction && typeof onTaskAction === 'function' && boardType !== BoardType.dueDate &&
              <View mx={2}>
                <CareDashboardTableActions
                  completedStatusId={completedStatusId}
                  intl={intl}
                  task={task}
                  boardType={boardType}
                  checkIfAllSubTasksAreCompleted={checkIfAllSubTasksAreCompleted}
                  onTaskAction={onTaskAction}
                />
            </View>
            }

          </View>
        );
      },
    },
  ];

  return columns;
};

export const getTaskTableColumnsV2 = (
  intl: IntlShape,
  userList: {
    id: string;
    name: string;
    uuid: string;
    email: string;
  }[],
  boardType: BoardType,
  userPool?: IUserPool[],
  selectedSortUuid?: string,
  selectedSortValueAscending?: boolean,
  selectedStatusList?: IMlov[],
  selectedPriorityList?: IMlov[],
  taskActionsInProgress?: {taskId: string, action: BottomViewAction}[],
  getSubTaskByIds?: LazyQueryExecFunction<any, OperationVariables>,
  onBottomViewAction?: (
    task: ITask,
    action: BottomViewAction,
    data?: any
  ) => void,
  onTaskDetail?: (data: ITask) => void,
  onMemberClick?: (task: ITask) => void,
  onShowToast?: (message: string, type: ToastType) => void,
): any[] => {
  const sortOrder = selectedSortValueAscending ? 'ascend' : 'descend';
  const statusFilteredValue = selectedStatusList?.map((mlov) => mlov.code) || null;
  const priorityFilteredValue = selectedPriorityList?.map((mlov) => mlov.code) || null;
  const isEmployer = isEmployerRole();
  const showFilterForPriority = boardType !== BoardType.Priority
  const showFilterForStatus = boardType !== BoardType.Status
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if (!task.subTasks || task.subTasks?.length === 0) {
      onBottomViewAction?.(task, BottomViewAction.markAsComplete);
      return;
    }
    const response = await getSubTaskByIds?.({
      variables: {
        ids: task.subTasks?.map((task) => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.some(
          (subTask: any) => subTask.statusId !== completedStatusId
        )
      ) {
        onShowToast?.(intl.formatMessage({ id: 'subTaskPendingMsg' }), ToastType.error);
        return;
      } else {
        onBottomViewAction?.(task, BottomViewAction.markAsComplete);
        return;
      }
    } else {
      onShowToast?.(intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error);
      return;
    }
  };

  const columns: ColumnsType<ITask> = [
    {
      key: 'completionStatus',
      width: '3%',
      render: (task: ITask) => {

        // if task not completed any one can mark it as complete
        const canMarkAsComplete = true;
        const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;

        return (
          <View flexDirection="row" alignItems={'center'}>
            {isTaskActionInProgress && <Spinner size={'sm'} />}
            {!isTaskActionInProgress && canMarkAsComplete && (
              <>
                <Tooltip
                  title={
                    isTaskCompleted(task)
                      ? 'Mark As Incomplete'
                      : 'Mark As Complete'
                  }
                  placement='topLeft'
                >
                    <Checkbox
                      style={{marginTop: 1, color: Colors.Custom.SuccessColor}}
                      checked={isTaskCompleted(task)}
                      // colorScheme="success"
                      // value="complete"
                      // accessibilityLabel="mark as complete"
                      onChange={async (event) => {
                        if (event.target.checked) {
                          await checkIfAllSubTasksAreCompleted(task);
                        } else {
                          onBottomViewAction?.(
                            task,
                            BottomViewAction.markAsIncomplete
                          );
                        }
                      }}
                    />
                </Tooltip>
              </>
            )}
          </View>
        );
      },
    },
    {
      onCell: (record: ITask) => {
        return {
          onClick: () => {
            const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === record.id)?.length;
            if (!isTaskActionInProgress) {
              onTaskDetail?.(record)
            }
          },
        };
      },
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'title'})}
        </Text>
      ),
      key: 'title',
      // dataIndex: 'title',

      // sorter: (a:any, b:any) => a.title.length - b.title.length,
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'title' ? sortOrder : null,
      width: '22%',
      render: (task: ITask) => {
        return (
          <VStack>
            <View flexDirection="row" wordBreak={'break-word'}>
              {task?.parentId && (
                <View size={18} marginRight={1}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
              )}
              <Text flex={9} size={'smMedium'} color={Colors.Custom.Gray900}>
                {task?.title}
              </Text>
            </View>
            {task?.subTasks?.length ? (
              <View flexDirection="row" mt={1}>
                <View size={18}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
                <Text
                  ml={1}
                  size={'smMedium'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${task?.subTasks?.length} ${
                    task?.subTasks?.length > 1 ? 'Subtasks' : 'Subtask'
                  } `}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {task?.attachments?.length ? (
              <View flexDirection="row" mt={1}>
                <View display={'inline'} size={18}>
                  <AntDesign
                    size={16}
                    name={'paperclip'}
                    color={Colors.Custom.PrimaryColor}
                  />
                </View>
                <Text
                  ml={1}
                  size={'smMedium'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${task?.attachments?.length} ${
                    task?.attachments?.length > 1 ? 'Attachments' : 'Attachment'
                  } `}
                </Text>
              </View>
            ) : (
              <></>
            )}
          </VStack>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500}}
          cursor="pointer"
        >
          <Tooltip title={intl.formatMessage({id: 'Priority'})}>P</Tooltip>
        </Text>
      ),
      key: 'priority',
      filteredValue: priorityFilteredValue,
      width: '4%',
      filterIcon: showFilterForPriority ?  (filtered: boolean) => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      filters: showFilterForPriority ? [
        {
          text: 'High',
          value: TASK_PRIORITY_CODES.HIGH,
        },
        {
          text: 'Medium',
          value: TASK_PRIORITY_CODES.MEDIUM,
        },
        {
          text: 'Low',
          value: TASK_PRIORITY_CODES.LOW,
        },
      ] : undefined,
      onFilter: (value, record) => {
        return record.priority?.code?.includes(value as string) || false;
      },
      filterSearch: true,
      render: (task: ITask) => {
        return (
          <View flexDirection="row" justifyContent={'center'}>
            <View flex={1}>{getPriorityIcon(task?.priority, true)}</View>
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'labels'})}
        </Text>
      ),
      key: 'labels',
      width: '15%',
      render: (task: ITask) => {
        return (
          <LabelDataListView
            key={task.id}
            hideAddMoreButton={true}
            tagList={task?.labels || []}
          />
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'patientName'})}
        </Text>
      ),
      key: 'patient',

      // dataIndex: 'contact',
      // sorter: (patient1: any, patient2: any) => {
      //   const patient1Name: string =
      //     patient1?.contact?.name ||
      //     patient1?.patient?.name ||
      //     patient1?.patientName ||
      //     '';
      //   const patient2Name: string =
      //     patient2?.contact?.name ||
      //     patient2?.patient?.name ||
      //     patient2?.patientName ||
      //     '';
      //   return patient1Name.localeCompare(patient2Name);
      // },
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: selectedSortUuid === 'patient' ? sortOrder : null,
      width: '10%',
      render: (task: ITask) => {
        return (
          <View flexDirection="row" alignItems="center">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {task.contact && task.contact.name
                ? task.contact.name
                : task.patientName
                ? task.patientName
                : '-'}
            </Text>
            {task?.contact?.uuid && (
              <Icon
                onPress={() => {
                  onMemberClick?.(task);
                }}
                as={Feather}
                name="external-link"
                size="xs"
                ml={1}
                color={Colors.Custom.Gray500}
              />
            )}
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'DueDate'})}
        </Text>
      ),
      key: 'endDateTime',
      dataIndex: 'endDateTime',
      sorter: (a: any, b: any) =>
        moment(a.endDateTime).unix() - moment(b.endDateTime).unix(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: selectedSortUuid === 'endDateTime' ? sortOrder : null,
      width: '10%',
      render: (endDateTime: string) => {
        return (
          <View flexDirection="row">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {getDateStrFromFormat(endDateTime || new Date(), DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Status'})}
        </Text>
      ),
      key: 'status',
      dataIndex: 'status',
      filters: showFilterForStatus ? [
        {
          text: TASK_STATUS_FILTER_VALUES.COMPLETED,
          value: TASK_STATUS.COMPLETED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.PENDING,
          value: TASK_STATUS.ACCEPTED,
        },
        {
          text: TASK_STATUS_FILTER_VALUES.MISSED,
          value: TASK_STATUS.MISSED,
        },
      ] : undefined,
      onFilter: (value, record) => {
        if (value === TASK_STATUS.ACCEPTED) {
          return record.status?.code !== TASK_STATUS.COMPLETED;
        } else {
          return record.status?.code.includes(value as string) || false;
        }
      },
      filterSearch: true,
      filteredValue: statusFilteredValue,
      filterIcon: showFilterForStatus ? (filtered: boolean) => (
        <Feather name="filter" color={Colors.Custom.IATextColor} />
      ) : undefined,
      width: '8%',
      render: (status: any) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {status && status.value ? status.value : '-'}
          </Text>
          // <HStack>
          //   {isCompleted && (
          //     <Feather
          //       name="check-circle"
          //       size={20}
          //       style={{
          //         marginRight: 5,
          //       }}
          //       color={Colors.Custom.SuccessColor}
          //     />
          //   )}
          //   <Text
          //     size={'smMedium'}
          //     color={
          //       isCompleted ? Colors.Custom.SuccessColor : Colors.Custom.Gray900
          //     }
          //   >
          //     {status && status.value ? status.value : '-'}
          //   </Text>
          // </HStack>
        );
      },
    },
    {
      title: (
        <Text size={'xsMedium'} style={{color: Colors.Custom.Gray500}}>
          {intl.formatMessage({id: 'Assignee'})}
        </Text>
      ),
      key: 'Assignee',
      width: '18%',
      // sorter: (a: any, b: any) => a.assigneeName.localeCompare(b.assigneeName),
      // sortDirections: ['ascend', 'descend'],
      // sortOrder: selectedSortUuid === 'Assignee' ? sortOrder : null,
      render: (task: ITask) => {
        const isTaskActionInProgress = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;
        const assigneeValue = getTaskAssignee(task, userList);
        let poolUsers: any[] = [];
        let poolDefaultUser: any = undefined;
        if (userPool?.length && task?.userPoolId) {
          const selectedPool = (userPool || []).find(pool => {
            return pool?.id === task?.userPoolId;
          });
          if (selectedPool?.id) {
            const {selectedPoolUsers, defaultAssignee} = filterActiveUsersAndDefaultAssigneeOfPool(selectedPool, userList);
            poolUsers = selectedPoolUsers || [];
            poolDefaultUser = defaultAssignee || undefined;
          }
        }
        const isDisabled = task?.status?.value === 'Completed' || isTaskActionInProgress;

        return (
          <View style={{marginTop: 7, marginLeft: 4, width: '100%'}}>
            <Pressable>
              {/* <CareDashboardChangeAssignee
                assignee={assignee}
                onChangeAssignee={(assignee: IUserSearch) => {
                  onBottomViewAction(
                    task,
                    BottomViewAction.changeAssignee,
                    assignee
                  );
                }}
                userPool={userPool}
              /> */}

              {/* For All Users */}
              {/* <UserAutoComplete
                labelText={''}
                isShowError={false}
                allowClear= {false}
                selectedValue={
                  assigneeValue && assigneeValue.length > 0
                    ? assigneeValue[0]
                    : undefined
                }
                onChange={(selectedUser: any) => {
                  onBottomViewAction(
                    task,
                    BottomViewAction.changeAssignee,
                    selectedUser
                  );
                }}
                usersData={userList}
                minWidth={220}
              /> */}
              {isEmployer && (
                <UserAutoComplete
                  labelText={''}
                  isShowError={false}
                  allowClear={false}
                  isDisabled={isDisabled}
                  selectedValue={assigneeValue || undefined}
                  excludeOtherEmployers={true}
                  onChange={(selectedUser: any) => {
                    onBottomViewAction?.(
                      task,
                      BottomViewAction.changeAssignee,
                      {
                        ...selectedUser,
                        type: ParticipantType.staff,
                      }
                    );
                  }}
                  usersData={userList}
                  minWidth={'100%'}
                />
              )}
              {!isEmployer && (
                <PatientSearchAndSelect
                  showCurrentUserAtTop={task?.userPoolId ? false : true}
                  isProspect={true}
                  showEmail
                  isDisabled={isDisabled}
                  showError={false}
                  placeholder="Search Staff or Member"
                  value={assigneeValue ? assigneeValue : undefined}
                  onChange={(participants: any, data: any) => {
                    const contactIdValue =
                      participants?.label?.props?.contactData?.uuid ||
                      participants?.value;
                    const contactIdKey = contactIdValue || participants?.key;
                    const assigneeData = {
                      label: participants?.label,
                      key: contactIdKey,
                      value: contactIdValue,
                      type: data?.type,
                    };

                    if (task?.userPoolId && !isAssigneePartOfPool(contactIdKey, task?.userPoolId, userPool)) {
                      task.userPoolId = undefined;
                    }
                    onBottomViewAction?.(
                      task,
                      BottomViewAction.changeAssignee,
                      assigneeData
                    );
                  }}
                  allowClear={false}
                  selectedPoolUsers={poolUsers}
                  defaultAssigneeInSelectedPool={poolDefaultUser}
                  dropdownPopupMinWidth={450}
                  showUserPendingTasksCount={true}
                />
              )}
              {/* For the manageable users only which are assigned through the task management */}
              {/* {accessUserData && (
                <Select
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={false}
                  value={assigneeValue || undefined}
                  onChange={(value: any[], data: any) => {
                    if (data && data.value) {
                      const matchedData = finalAccessUserData.filter(
                        (item) => item.key === data.key
                      );
                      const assigneeData = {
                        label: matchedData[0].label,
                        key: matchedData[0].key,
                        value: matchedData[0].value,
                      };
                      onBottomViewAction(
                        task,
                        BottomViewAction.changeAssignee,
                        assigneeData
                      );
                    }
                  }}
                  placeholder={intl.formatMessage({id: 'searchPeople'})}
                  style={{height: '44px'}}
                >
                  {finalAccessUserData.map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              )} */}
            </Pressable>
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'assignedBy'})}
        </Text>
      ),
      key: 'action',
      width: '14%',
      render: (task: ITask) => {
        const {icon, name} = getAssignedByType(task,intl);
        return (
          <View flexDirection="row" alignItems="center">
            <HStack alignItems={'center'} maxW={'100%'}>
              {icon}
              <Tooltip title={name}>
                <Text
                  ml={1.5}
                  size={'smMedium'}
                  color={Colors.Custom.Gray900}
                  isTruncated
                >
                  {name}
                </Text>
              </Tooltip>
            </HStack>
          </View>
        );
      },
    },

    {
      title: (
        <Text
          size={'xsMedium'}
          style={{color: Colors.Custom.Gray500, marginLeft: 8}}
        >
          {intl.formatMessage({id: 'actions'})}
        </Text>
      ),
      key: 'action',
      width: '5%',
      render: (task: ITask) => {
        const isDisabled = !!taskActionsInProgress?.filter((item) => item.taskId === task.id)?.length;
        return (
          <View flexDirection="row" alignItems={'center'}>
            {/* {!shouldCategoryChangeNotDisplay && categorySection && (
              <>
                <View style={{marginTop: 3, marginLeft: 7}}>
                  <CareDashboardChangeCategory
                    onChangeCategory={(category) =>
                      onChangeCategory &&
                      onChangeCategory(categorySection, category, task)
                    }
                    categorySection={categorySection}
                    userTaskDisplayCategory={userTaskDisplayCategory}
                  />
                </View>
              </>
            )} */}

            {isAllowToDeleteTask(task) && (
              <>
                <Tooltip title="Delete task" placement="topLeft">
                  <Button
                    alignContent={'center'}
                    justifyContent={'center'}
                    ml={1}
                    style={{width: 24, height: 24}}
                    backgroundColor={'transparent'}
                    isDisabled={isDisabled}
                    onPress={() => {
                      if (task?.subTasks?.length !== 0) {
                        notification.info({
                          message: intl.formatMessage({
                            id: 'TaskNotDeletedMsgForTaskHasSubtask',
                          }),
                          duration: 3.0,
                          placement: 'top'
                        });
                      } else if (task?.subTasks?.length === 0) {
                        onBottomViewAction?.(task, BottomViewAction.deleteTask, undefined);
                      }
                    }}
                  >
                    <Feather
                      name="trash-2"
                      size={18}
                      color={Colors.Custom.Gray500}
                    />
                  </Button>
                </Tooltip>
              </>
            )}
            {!isDisabled && boardType !== BoardType.dueDate &&
              <View mx={2}>
                <CareDashboardTableActions
                  completedStatusId={completedStatusId}
                  intl={intl}
                  task={task}
                  boardType={boardType}
                  checkIfAllSubTasksAreCompleted={checkIfAllSubTasksAreCompleted}
                  onTaskAction={(code: string, task: ITask, action: BottomViewAction) => {
                    onBottomViewAction?.(task, action, { code })
                  }}
                />
            </View>
            }

          </View>
        );
      },
    },
  ];

  return columns;
};

export function getFetchTaskParams(params: {
  commonDataContext: ICommonData;
  topBarData: ITopBarData;
  isPatientTask: boolean;
  personData: any;
  currentUserId: string;
  offset: number;
  columnCode: string;
  patientTaskFilters: any;
  userPoolId?: string;
  pageSize?:number;
  isWithoutPagination?: boolean;
  searchString?: string;
}) {
  const isFromPatientTaskPanel = params.topBarData.panelType === TaskPanelType.PATIENT;
  const isFromPatientContextTaskPanel = params.topBarData.panelType === TaskPanelType.INTERNAL;
  const completedStatusUuid = getMlovId(
    params.commonDataContext.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.COMPLETED,
  );
  const missedStatusUuid = getMlovId(
    params.commonDataContext.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.MISSED,
  );

  const taskStatusMlovList = getMlovListFromCategory(params.commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const taskPriorityMlovList = getMlovListFromCategory(params.commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_PRIORITY) || [];

  const selectedSortObject: any = {};
  const selectedSortUuid = params.topBarData.selectedSortUuid || '';
  const selectedMentionsSortUuid = params.topBarData.selectedMentionsSortUuid || '';
  const isCreatedByView = params.columnCode === TASK_TABS.CREATED;
  const isMentionsTaskView = params.columnCode === TASK_TABS.MENTIONED;
  const isAssignedTaskView = params.topBarData.selectedTab === TASK_TABS.ASSIGNED;
  const isTaskPoolView = !!params.userPoolId;

  if (!isMentionsTaskView && params.topBarData.selectedSortUuid && params.topBarData.selectedSortUuid !== 'none') {
    selectedSortObject[selectedSortUuid] = params.topBarData.selectedSortValueAscending ? 'asc' : 'desc';
  }
  if(isMentionsTaskView && params.topBarData.selectedMentionsSortUuid && params.topBarData.selectedMentionsSortUuid !== 'none'){
      selectedSortObject[selectedMentionsSortUuid] = params.topBarData.selectedMentionsSortValueAscending ? 'asc' : 'desc';
  }

  let assigneeIds:(string | null)[] = [];
  let createdByIds:(string | null)[] = [];
  let mentionedUserIds:(string | null)[] = [];

  if (params.isPatientTask) {
    if (params.personData?.contactUUID) {
      assigneeIds.push(params.personData.contactUUID)
    }
  } else {
    assigneeIds = params?.topBarData?.selectedUsers?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
    createdByIds = params?.topBarData?.createdByIds?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
    mentionedUserIds = params?.topBarData?.mentionUserIds?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
  }

  if (!assigneeIds?.length && !isCreatedByView) {
    throw new Error();
  } else {
    let paramsObject: any = {};

    let apiDateRange = getISODateFromDisplayDate(params.topBarData);

    if (params.isPatientTask) {
      if (params?.patientTaskFilters?.formData) {
        apiDateRange = getISODateFromDisplayDate(params?.patientTaskFilters?.formData);
      }
      paramsObject = {
        assigneeIds: isFromPatientTaskPanel ? assigneeIds : [],
        contactIds: isFromPatientContextTaskPanel ? assigneeIds : [],
        ...(isFromPatientContextTaskPanel && {
          assigneeIdsNotIn: assigneeIds,
        }),
        ...(apiDateRange?.start && { startDateTime: apiDateRange.start }),
        ...(apiDateRange?.end && { endDateTime: apiDateRange.end }),
        orderBy: selectedSortObject,
        offset: params.offset,
        limit: params?.pageSize || PAGE_SIZE,
        timezone: getCurrentTimeZone(),
        taskDueDateCategoryIds: params?.patientTaskFilters?.formData?.selectedDueDateUuid,
      };

      if (params.patientTaskFilters?.formData?.selectedPriorityTypes?.length) {
        paramsObject.priorityIds = params.patientTaskFilters.formData.selectedPriorityTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (params.patientTaskFilters?.formData?.selectedTaskEntityTypes?.length) {
        paramsObject.entityTypeIds = params.patientTaskFilters.formData.selectedTaskEntityTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (params.patientTaskFilters?.formData?.selectedStatusTypes?.length) {
        paramsObject.statusIds = params.patientTaskFilters.formData.selectedStatusTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (!params?.patientTaskFilters?.formData?.selectedDueDateUuid?.includes(dateRangeStatusUuid)) {
        delete paramsObject?.startDateTime;
        delete paramsObject?.endDateTime;
      } else {
        paramsObject.taskDueDateCategoryIds = (paramsObject as any).taskDueDateCategoryIds.filter((id: string) => id !== dateRangeStatusUuid)
      }

      // props.filter?.formData?.selectedPriorityTypes,
      // props.filter?.formData?.selectedTaskEntityTypes,
      // props.filter?.formData?.selectedStatusTypes,
    } else {
      paramsObject = {
        startDateTime: apiDateRange.start,
        endDateTime: apiDateRange.end,
        assigneeIds: assigneeIds,
        ...( isCreatedByView && { createdByIds: createdByIds } ),
        ...(isTaskPoolView && { userPoolIds: [ params.userPoolId ] }),
        ...(isMentionsTaskView && { mentionedUserOrContactIds: mentionedUserIds}),
        statusIds: params.topBarData.selectedStatusList?.map?.((mlov) => mlov.id) || [],
        taskDueDateCategoryIds: params.topBarData.selectedDueDateUuid,
        priorityIds: params.topBarData.selectedPriorityList?.map?.((mlov) => mlov.id) || [],
        contactIds:
          params.topBarData.selectedContactUuid && params.topBarData.selectedContactUuid.length
            ? params.topBarData.selectedContactUuid.map(
                (contactItem: any) => contactItem.value
              )
            : [],
        offset: params.offset,
        limit: params?.pageSize || PAGE_SIZE,
        orderBy: selectedSortObject,
        timezone: getCurrentTimeZone(),
      };

      // if selected status type is requested then we will pass completed uuid in statusIdsNotIn key
      // that means requested = not completed task
      // if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.ACCEPTED) {
      // // if (props.selectedStatusType === requestedStatusValue) {
      //   delete (paramsObject as any).statusIds;
      //   (paramsObject as any).statusIdsNotIn = [completedStatusUuid,missedStatusUuid];
      // } else if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.COMPLETED) {
      //   paramsObject.statusIds = [ completedStatusUuid ];
      // } else if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.MISSED) {
      //   delete (paramsObject as any).statusIdsNotIn;
      //   paramsObject.statusIds = [ params.topBarData.selectedStatusList[0]?.id ];
      // } else
      if ((params.topBarData.selectedStatusList?.length || 0) > 0) {
        delete (paramsObject as any).statusIdsNotIn;
        paramsObject.statusIds = params.topBarData.selectedStatusList?.map?.((mlov) => mlov.id) || []
      } else {
        delete (paramsObject as any).statusIds;
      }

      // Selected Due Date value is not date range then do not pass startDateTime and EndDateTime Filter
      if (!params.topBarData.selectedDueDateUuid?.includes(dateRangeStatusUuid)) {
        delete paramsObject.startDateTime;
        delete paramsObject.endDateTime;
      } else {
        paramsObject.taskDueDateCategoryIds = (paramsObject as any).taskDueDateCategoryIds.filter((id: string) => id !== dateRangeStatusUuid)
      }
    }

    if (!isTaskPoolView && params.topBarData.selectedPools?.length) {
      paramsObject.userPoolIds = params.topBarData.selectedPools?.map((item) => item.value).filter((item?: string) => !!item);
    }

    if (params.columnCode) {
      if (params.isPatientTask) {
        const statusId = getMlovIdFromCode(taskStatusMlovList, params.columnCode);
        if (statusId) {
          // if (!paramsObject.statusIds?.length) {
          //   paramsObject.statusIds = [];
          // }
          paramsObject.statusIds = [];
          paramsObject.statusIds.push(statusId);
        }
      } else if (isAssignedTaskView) {
        if (params.topBarData.selectedBoardType === BoardType.Status) {
          // if (!paramsObject.statusIds?.length) {
          //   paramsObject.statusIds = [];
          // }
          paramsObject.statusIds = [];
          const statusId = getMlovIdFromCode(taskStatusMlovList, params.columnCode);
          if (statusId) {
            paramsObject.statusIds.push(statusId);
          }
        } else if (params.topBarData.selectedBoardType === BoardType.Priority) {
          // if (!paramsObject.priorityIds?.length) {
          //   paramsObject.priorityIds = [];
          // }
          paramsObject.priorityIds = [];
          const priorityId = getMlovIdFromCode(taskPriorityMlovList, params.columnCode);
          if (priorityId) {
            paramsObject.priorityIds.push(priorityId);
          }
        } else if (params.topBarData.selectedBoardType === BoardType.dueDate) {
          delete paramsObject.taskDueDateCategoryIds;
          if(params.columnCode === 'past') {
            paramsObject.endDateTime = getPreviousDateEndOfDay();
            delete paramsObject.startDateTime;
          }
          else if(params.columnCode === 'today') {
            paramsObject.startDateTime = getStartOfDay()?.toISOString();
            paramsObject.endDateTime = getEndOfDay()?.toISOString();
          }
          else if(params.columnCode === 'future') {
            paramsObject.startDateTime = getNextDateStartOfDay();
            delete paramsObject.endDateTime;
          }
        }
      }
    }

    if (params.topBarData?.labels?.length) {
      paramsObject.labels = params.topBarData?.labels?.map(
        (item) => item?.uuid || ''
      ) || [];
    }
    if (params.searchString) {
      paramsObject.searchString = params.searchString;
    }

    if (params.isWithoutPagination) {
      delete paramsObject.offset;
      delete paramsObject.limit;
    }

    return paramsObject;
  }
}


export const getAssignedByType = (task: ITask, intl: IntlShape) => {
  let icon = <></>;
  let name = "";
  // is automation task if assignedBy is not present and careJourney is not present
  if (!task?.assignedByUser?.name && !task?.patientCareJourney?.careJourney?.id) {
    name = intl.formatMessage({id: 'automation'});
    icon = <AutomationIcon />;
  }
  // is care journey task if careJourney is present
  if (task?.patientCareJourney?.careJourney?.id) {
    name = task?.patientCareJourney?.careJourney?.title || '';
    icon = <JourneyIcon  />;
  }
  // is created by some user if assignedBy is present
  if (task?.assignedByUser?.name) {
    name = task?.assignedByUser?.name || '';
    icon = <UserIcon />;
  }

  return {
    icon,
    name,
  };
}

export const getIconByAction = (action: string)=> {
  switch (action) {
    case TASK_STATUS_CODES.DELETE:
      return <TrashBinIcon2024 />;
    case TASK_STATUS_CODES.COMPLETED:
      return <ReadMsgSvg2024 />
    case TASK_STATUS_CODES.ACCEPTED:
      return <ClockIcon2024 />
    case TASK_STATUS_CODES.MISSED:
      return <MissedTaskIcon2024 />
    default:
      return '';
  }
}
