import {deleteDraft, getDraft, saveDraft} from "../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Api";
import {MESSAGE_DRAFT_TYPES} from "../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const";
import {IAllDraftBody, IMessageDraftTypes} from "../../../../../common/EmailDrawerCommonV2/interfaces";

export class MessageStorage {
  private static messageStorageInst: MessageStorage;
  constructor() {
    if (MessageStorage.messageStorageInst) {
      throw new Error('Use MessageStorage.eventBusInst instead of new.');
    }
    MessageStorage.messageStorageInst = this;
  }
  static getMessageStorageInstance(): MessageStorage {
    return MessageStorage.messageStorageInst ?? (MessageStorage.messageStorageInst = new MessageStorage());
  }

  public saveConversationMessage(conversationUuid: string, draftData: Partial<IAllDraftBody>, draftType: IMessageDraftTypes) {
    if (conversationUuid) {
      saveDraft({
        conversationUuid: conversationUuid,
        draftType: draftType,
        draftData: draftData,
      });
    }
  }

  public async getSaveConversationMessage(conversationUuid: string, draftType: IMessageDraftTypes, abortSignal: AbortSignal): Promise<Record<string, any>> {
    if (conversationUuid) {
      try {
        const existingDraftData = await getDraft({
          conversationUuid: conversationUuid,
          draftType: draftType,
          abortSignal,
        });
        return existingDraftData || {};
      } catch (error) {
        return {};
      }
    }
    return {};
  }

  public async deleteDraftConversationMessage (conversationUuid: string, draftType: IMessageDraftTypes)  {
    return await deleteDraft({conversationUuid, draftType});
  };
}
