import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  selectedTab: {
    backgroundColor: Colors.Custom.MonochromeWhite,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedTabNewDesign: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.FoldPixel.GRAY150
  },
  tab: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedView: {
    backgroundColor: Colors.Custom.Gray200,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
});
