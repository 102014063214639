import React, {useMemo} from 'react';
import {View} from 'react-native';
import { HOME_MONITORING_VITALS, enabledVitalsForHomeMonitoringWithConfigs} from '../utils';
import {
  LineSegment,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/VictoryComponent';
import {Colors} from '../../../../../../../styles';
import {getDateStrFromFormat} from '../../../../../../../utils/DateUtils';
import {CustomPlotName, getGoalLines, getGraphNodeColor, getGraphNodeShape} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';
import {DISPLAY_DATE_FORMAT} from '../../../../../../../constants';
import NoDataFoundCard from './NoDataFoundCard';
import { VStack } from 'native-base';
import { Vital } from '../../../../../../../utils/VitalUtils';
import GoalLinesView from '../../Goals/GoalLinesView';
import BloodPressureGraphView from '../../Goals/BloodPressureGoalView';
import { GraphDataOperation, GraphsTypes, IGraph, VitalGraphData } from '../interface';
import { PieChartView } from './PieChartView';

interface IHMGraphViewProps {
  renderGraphType: GraphsTypes;
  graphData: VitalGraphData;
  ccmDate?: string;
  measure?: any;
}

const HMGraphView = (props: IHMGraphViewProps) => {
  const fontSize = isWeb() ? 8 : 12;
  const {ccmDate, graphData, renderGraphType} = props;
  const vitalConfig = useMemo(() => HOME_MONITORING_VITALS.find((data) => data.loinc === graphData.code), [graphData.code]);
  const isBarGraph = renderGraphType === GraphsTypes.BAR || renderGraphType === GraphsTypes.SPLIT_BAR;
  const isVerticalScatter = renderGraphType === GraphsTypes.VERTICAL_SCATTER;
  const hideTickValues = renderGraphType === GraphsTypes.SPLIT_BAR;
  const tooltipTextColor = Colors.Custom.Gray500;

  const isEmptyGraph = graphData.graphData.length === 0;
  const dataOperation = graphData.dataOperation;
  const isLowEvent = dataOperation === GraphDataOperation.LOW_EVENT;
  const filterFunc = (data: any) => {
    return (typeof data.y === 'number' && data.y !== 0) || (typeof data.y === 'string' && data.y !== '0');
  }
  const config = props.graphData?.config;

  const goalLines = getGoalLines(props?.measure);

  if(graphData?.code === Vital.height) {
    goalLines.forEach(goalLine => goalLine.value = (+goalLine.value)/12)
  }

  if (isEmptyGraph) {
    return (
      <View>
        <NoDataFoundCard />
      </View>
    );
  }  

  const renderChart = () => {
    switch (renderGraphType) {
      case GraphsTypes.BAR:
        return (
          <VictoryBar
            barRatio={0.5}
            data={props.graphData.graphData}
            barWidth={config?.barWidth}
            style={{
              data: {
                fill:
                  dataOperation === GraphDataOperation.LOW_EVENT
                    ? Colors.Custom.ErrorColor
                    : config?.barColor
                    ? config?.barColor
                    : Colors.Custom.BarColorV2,
              },
            }}
            cornerRadius={{
              bottom: 2,
              top: 2,
            }}
            labels={
              config?.hideBarTooltip
                ? undefined 
                : (d: any) => {
                  return d.datum.tooltipText ? d.datum.tooltipText : [];
                }
            }
            labelComponent={
              <VictoryTooltip
                {...props}
                renderInPortal={isWeb()}
                flyoutStyle={{            
                  strokeWidth: 1,
                  fill: (d: any) => {
                    return d.datum.tooltipText ? Colors.Custom.White : 'none';
                  },
                  stroke: (d: any) => {
                    return d.datum.tooltipText? Colors.Custom.Gray200 : 'none';
                  },
                }}                          
                style={{
                  fill: (d: any) => {
                    return tooltipTextColor;
                  },
                  fontSize: fontSize,
                }}
                constrainToVisibleArea
              />
            }
          />
        );

      case GraphsTypes.LINE:
        return <VictoryLine data={props.graphData.graphData.filter(filterFunc)} style={{
            data: {
                stroke: Colors.Custom.graphLineColor,
                strokeWidth: 1,
            }
        }} />;
       
      case GraphsTypes.SPLIT_BAR:
          return (
              <VictoryStack
                colorScale={graphData?.graphData?.[0]?.metaData?.colorScale}>
                {graphData?.graphData?.map((graphDataObj, index) => {
                  return (
                    <VictoryBar
                      key={`victory_split_bar_${index}`}
                      labelComponent={
                        <VictoryTooltip
                          renderInPortal={isWeb()}
                          flyoutStyle={{
                            fill: (d: any) => {
                              return d.text ? Colors.Custom.White : 'none';
                            },
                            stroke: (d: any) => {
                              return d.text ? Colors.Custom.Gray200 : 'none';
                            },
                            strokeWidth: 1,                           
                          }}
                          style={{
                            fill: (d: any) => {
                              return tooltipTextColor;
                            },
                            fontSize: 8,
                          }}
                          constrainToVisibleArea
                        />
                      }        
                      style={{ data: { opacity: config?.barOpacity || 1 } }}    
                      barWidth={config?.barWidth || 10}
                      data={graphDataObj.subGraphData || []}
                      x={graphDataObj.x}
                      y={graphDataObj.y}
                      labels={
                        config?.hideBarTooltip
                          ? undefined 
                          : graphDataObj.subGraphData?.map(
                            (subGraphObj: any) => subGraphObj.tooltipText || '',
                          )
                      }
                      cornerRadius={{
                        top: index === graphData?.graphData?.length - 1 ? 1 : 0,
                      }}
                    />
                  );
                })}
              </VictoryStack> 
          );
    
      case GraphsTypes.VERTICAL_SCATTER: 
        return (
          <VictoryGroup>
            {props.graphData.graphData?.map((graph, index) => {
              return (
                <VictoryScatter
                  style={{
                    data: {
                      fill: getGraphNodeColor(graph),
                    },
                  }}
                  symbol={getGraphNodeShape(graph)}
                  labelComponent={
                    <VictoryTooltip
                      flyoutStyle={{
                        fill: Colors.Custom.White,
                        strokeWidth: 1,
                        stroke: Colors.Custom.Gray200,
                      }}      
                      style={{
                        fill: (d: any) => {
                          return tooltipTextColor;
                        },
                        fontSize: fontSize,
                        backgroundColor: Colors.Custom.White,
                      }}
                      constrainToVisibleArea
                    />
                  }
                  labels={(d: any) => {              
                    return d.datum.tooltipText ? d.datum.tooltipText : `${d.datum.y} ${vitalConfig?.unit}`;
                  }}
                  size={1.5}
                  data={graph as []}
                />
              );
            })}

            {props.graphData.barData?.map((graph, index) => {
              return (
                <VictoryBar
                  barWidth={1}
                  style={{
                    data: {
                      fill: getGraphNodeColor(graph),
                    },
                  }}
                  labelComponent={
                    <VictoryLabel
                      style={[{fill: Colors.Custom.White}]}
                      dy={100}
                    />
                  }
                  data={graph as []}
                />
              );
            })}
          </VictoryGroup>
        );      
      case GraphsTypes.SPLIT_BAR:
          return (
            <VictoryStack
              colorScale={graphData?.graphData?.[0]?.metaData?.colorScale}>
              {graphData?.graphData?.map((graphDataObj, index) => {
                return (
                  <VictoryBar
                    key={`victory_split_bar_${index}`}
                    labelComponent={
                      <VictoryTooltip
                        flyoutStyle={{
                          fill: Colors.Custom.White,
                          strokeWidth: 1,
                          stroke: Colors.Custom.Gray200,
                        }}
                        style={{
                          fill: (d: any) => {
                            return tooltipTextColor;
                          },
                          fontSize: 8,
                        }}
                        constrainToVisibleArea
                      />
                    }        
                    style={{ data: { opacity: config?.barOpacity || 1 } }}    
                    barWidth={config?.barWidth || 10}
                    data={graphDataObj.subGraphData || []}
                    x={graphDataObj.x}
                    y={graphDataObj.y}
                    labels={
                      config?.hideBarTooltip
                        ? undefined 
                        : graphDataObj.subGraphData?.map(
                          (subGraphObj : IGraph) => subGraphObj.tooltipText || '',
                        )
                    }
                    cornerRadius={{
                      top: index === graphData?.graphData?.length - 1 ? 1 : 0,
                    }}
                  />
                );
              })}
            </VictoryStack>
          );
        default:
        return <></>;
    }
  };

  const containerComponent = (
    <VictoryVoronoiContainer
      labelComponent={
        <VictoryTooltip
          flyoutStyle={{
            fill: Colors.Custom.White,
            strokeWidth: 1,
            stroke: Colors.Custom.Gray200,
          }}
          style={{
            fill: (d: any) => {
              return tooltipTextColor;
            },
            fontSize: 8,
          }}
          constrainToVisibleArea
        />
      }
      labels={(point: { datum: { childName: any; }; }, index: any, points: any) => {
        const tooltipName = point.datum.childName;
        if (tooltipName === CustomPlotName.CCM) {
          return `CCM started here: ${getDateStrFromFormat(
                    ccmDate,
                    DISPLAY_DATE_FORMAT
                  )}`
        } else if(tooltipName && tooltipName.includes('Goal')) {
          return tooltipName;
        }
        return ''
      }}
    />
  );

  const VictoryFlyOut = () => {
    return  (
      <View />
    )
  }

  const getGoalLinesView = () => {
    const isBloodPressureGraph = graphData?.code === Vital.bloodPressure;
    return isBloodPressureGraph ? 
      BloodPressureGraphView({
        goalLines: goalLines,
        graphData: graphData?.graphData,
        fontSize: fontSize,
        unit: graphData?.displayUnit || vitalConfig?.unit || '',
        isExtended: true
      }) :
      GoalLinesView({
        goalLines: goalLines,
        graphData: graphData?.graphData,
        fontSize: fontSize,
        unit: graphData?.displayUnit || vitalConfig?.unit || '',
        isExtended: true
      });
  }  

  const renderMainView = () => {
    switch (renderGraphType) {
      case GraphsTypes.PIE_CHART:
        return <PieChartView graphData={graphData} />;
      default:   
        return (
        <VictoryChart
          minDomain={{y: 0}}
          domainPadding={{x: 20, y: config?.domainYPadding || 20}}
          theme={VictoryTheme.material}
          height={isWeb() ? 140 : 240}
          containerComponent={containerComponent}
          padding={{top: 10, bottom: 30, left: isWeb() ? 40 : 50, right: isWeb() ? 40 : 70}}
        >
           <VictoryAxis
              fixLabelOverlap
              tickFormat={(t) => `${t}`}
              axisLabelComponent={<VictoryLabel dy={-12} />}
              tickValues={
                hideTickValues
                  ? undefined
                  : graphData.graphData.map((data) => data.x)
              }
              style={{
                tickLabels: {
                  fontSize: fontSize,
                  fill:
                    graphData.graphData.length === 0
                      ? 'transparent'
                      : Colors.Custom.Gray500,
                },
                axis: {stroke: Colors.Custom.Gray100},
              }}
            />
            <VictoryAxis
              tickValues={hideTickValues ? undefined : graphData.tickValues}
              tickFormat={hideTickValues ? undefined : graphData.tickLabelFunc}
              dependentAxis
              fixLabelOverlap
              style={{
                tickLabels: {
                  fontSize: fontSize,
                  fill:
                    graphData.graphData.length === 0
                      ? 'transparent'
                      : Colors.Custom.Gray500,
                },
                grid: {
                  stroke: Colors.Custom.Gray200,
                  strokeWidth: 1,
                },
                axisLabel: {
                  fontSize: fontSize,
                },
                axis: {stroke: Colors.Custom.Gray100},
              }}
              label={graphData.displayUnit || config?.yAxisLabel}
              tickLabelComponent={<VictoryLabel dx={config?.tickLabelXPadding || 0}/>}         
              axisLabelComponent={<VictoryLabel dy={-20}></VictoryLabel>}
            />
          {renderChart()}
          {!isBarGraph && !isVerticalScatter && (
            <VictoryScatter
              data={props.graphData.graphData.filter(filterFunc)}
              style={{
                  data: {
                      fill: Colors.Custom.graphLineColor,
                  },
              }}
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{
                    fill: Colors.Custom.White,
                    strokeWidth: 1,
                    stroke: Colors.Custom.Gray200,
                  }}
                  style={{
                    fill: (d: any) => {
                      return tooltipTextColor;
                    },
                    fontSize: fontSize,
                    backgroundColor: Colors.Custom.White,
                  }}
                  constrainToVisibleArea
                />
              }
              size={1.5}
              labels={(d: any) => {              
                return d.datum.tooltipText ? d.datum.tooltipText : `${d.datum.y} ${vitalConfig?.unit}`;
              }}
            />
          )}
          {graphData?.annontationIndex !== undefined && props.ccmDate && !isEmptyGraph && (
            <VictoryLine
              name={CustomPlotName.CCM}
              style={{
                data: {
                  stroke: Colors.Custom.ErrorColor,
                  strokeWidth: 1,
                  strokeDasharray: 1,
                },
                labels: {fontSize: fontSize},
              }}
              labelComponent={<VictoryLabel  y={100} />}
              x={() => graphData?.annontationIndex as number}
            />
          )}
          {graphData?.subGraphLinesData?.map((subGraphData, index) => {
              return (
                <VictoryLine
                  style={{
                    data: {
                      stroke:
                        subGraphData.metaData?.lineColor ||
                        Colors.Custom.BarColorV2,
                      borderRadius: 1,
                      strokeWidth: 1.5,
                      strokeLinecap: 'round',
                    },
                  }}
                  
                  key={`victory_sub_graph_line_${index}`}
                  labelComponent={
                    <VictoryTooltip
                      renderInPortal={isWeb()}
                      flyoutStyle={{
                        fill: Colors.Custom.White,
                        strokeWidth: 1,
                        stroke: Colors.Custom.Gray200,
                      }}
                      style={{
                        fill: (d: any) => {
                          return tooltipTextColor;
                        },
                        fontSize: 8,
                      }}
                      constrainToVisibleArea
                    />
                  }
                  labels={(d: any) => {
                    return `${d.datum.tooltipText}`;
                  }}
                  data={subGraphData?.subGraphData || []}
                />
              );
            })}      
            {getGoalLinesView()}

        </VictoryChart>
      )
    }
  }

  return (
    <View>
       {renderMainView()}
    </View>
  );
};

export default HMGraphView;
