import React, {useMemo} from 'react';
import {
  FilterTypes,
  HOME_MONITORING_VITALS,
  getIconBasedOnVital,
  getLastRecordedDate,
} from '../utils';
import {HStack, Spacer, Text, Tooltip as NativeTooltip, VStack, Actionsheet, Box, useMediaQuery, Stack, ScrollView} from 'native-base';
import {Tooltip} from 'antd';
import {Colors} from '../../../../../../../styles';
import ValueHighlight from './ValueHighlight';
import {CustomGraph} from '../../../../../../PersonOmniView/LeftContainer/PamiDetail/VitalsData.native';
import {Dimensions, View} from 'react-native';
import {CustomLineGraph} from '../../CustomLineGraph/CustomLineGraph';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';
import {useIntl} from 'react-intl';
import HMGraphView from './HMGraphView';
import {tooltipInnerOverlayStyle} from '../../CustomWrapper/CustomComponentHelper';
import Feather from 'react-native-vector-icons/Feather';
import BottomSheetModal from '../../../../../../common/BottomSheetModal/BottomSheetModal';
import { IPAD_MINI_WIDTH, IPAD_WIDTH, SMALL_SCREEN, SMALL_WINDOW, SMALL_WINDOW_1500 } from '../../../../../../../constants';
import { GraphDataOperation, ILastRecordedData, VitalGraphData } from '../interface';


const VitalGraphCard = (props: {
  vital: VitalGraphData;
  ccmDate?: string;
  measure?: any;
  lastRecorded: ILastRecordedData | undefined;
  extraFilter?: JSX.Element
}) => {
  const refRBSheet = React.useRef();
  const [infoVisible, setInfoVisible] = React.useState(false);
  const intl = useIntl();
  const {vital} = props;
  const isEmptyGraph = vital.graphData.length === 0;
  const lastRecordedDate = props?.lastRecorded?.date || '';
  const config = HOME_MONITORING_VITALS.find(
    (data) => data.loinc === vital.code
  );
  const Icon = getIconBasedOnVital(vital.code);
  const unit = vital.displayUnit || config?.displayUnit || config?.unit;
  const title = vital.title;
  const isLowEvent = vital.dataOperation === GraphDataOperation.LOW_EVENT;
  const source = vital.source;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const screenDimensions = Dimensions.get('window');

  const isSmallWidth = screenDimensions?.width < SMALL_WINDOW_1500;
  const renderHightlightValues = () => {
    return vital?.highLightValues?.map((value, index) => (
      <ValueHighlight
        key={index}
        value={isEmptyGraph ? '--' : value?.value || '--'}
        unit={value?.hideUnit ? '' : value?.unit || unit || ''}
        hideUnit={value.hideUnit}
        type={value.subValue}
        subValueView={value?.subValueView}
      />
    ))
  }

  return (
    <VStack
      borderWidth={0.5}
      rounded={'lg'}
      borderColor={Colors.Custom.Gray200}
      my={2}
    >
      <VStack
        width={'100%'}
        p={2}
        alignItems={isWeb() ? 'flex-start' : undefined}
      >
        <Stack flex={1} flexWrap={"wrap"} width={"100%"} flexDirection={isIPadMiniScreen || isIPadScreen  ? 'column' : 'row'}>
          <HStack alignItems={"center"} width={isIPadMiniScreen || isIPadScreen ? '100%' : 'auto'}>
            <Icon width={'24'} height={'24'} />
            <Text
              fontSize={16}
              fontWeight={600}
              color={Colors.Custom.Gray600}
              selectable={false}
            >
              {` ${title} ${unit ? `(${unit})` : ''}`}
              {isLowEvent &&
                (isWeb() ? (
                  <Tooltip
                    title={
                      'Blood glucose levels below 70 mg/dl qualify as low glucose events.'
                    }
                    placement="top"
                    showArrow={false}
                    overlayStyle={{paddingTop: '12px'}}
                    overlayInnerStyle={tooltipInnerOverlayStyle}
                  >
                    <Feather
                      name="info"
                      style={{marginLeft: 2, marginTop: 2}}
                      size={18}
                      color={Colors.Custom.Gray500}
                    />
                  </Tooltip>
                ) : (
                  <>
                    <Feather
                      name="info"
                      style={{marginLeft: 2}}
                      size={18}
                      color={Colors.Custom.Gray500}
                      onPress={() => setInfoVisible(true)}
                    />
                  </>
                ))}
            </Text>
          </HStack>
            {isWeb() && <Spacer />}
            {vital.dataAvailability?.length > 0 &&
              !isEmptyGraph &&
              vital.filterType !== FilterTypes.Day && (
                <Text fontSize={14} color={Colors.Custom.Gray400}>
                  {vital.dataAvailability}
                </Text>
              )}
        </Stack>
          {!isEmptyGraph && !!lastRecordedDate && (
            <Text fontSize={14} color={Colors.Custom.Gray400}>
              {intl.formatMessage({id: 'lastRecordedOn'})} {lastRecordedDate}{' '}
              {source ? ` • ${source} ` : ''}
            </Text>
          )}
      </VStack>
        {vital.graphInfoView ? (
          <View style={{alignSelf: isWeb() ? 'center' : 'flex-start', marginLeft: !isWeb() ? 6 : 0}}>{vital.graphInfoView}</View>
        ) : null}
      <View
        style={{
          position: 'relative',
        }}
      >
        <HMGraphView
          renderGraphType={vital.graphType}
          graphData={vital}
          ccmDate={props.ccmDate}
          measure={props?.measure}
        />
      </View>
      {vital?.highLightValues?.length > 0 && (        
        <Stack
          direction="row"
          style={{
            borderTopWidth: 0.5,
            borderColor:Colors.Custom.Gray200,
            backgroundColor:Colors.Custom.Gray50,
            padding: 12,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}>
           {vital?.highLightValues?.length > 3 && isSmallWidth ? (
            <ScrollView horizontal flex={1}>{renderHightlightValues()}</ScrollView>
          ) : (
            renderHightlightValues()
          )}
          {props.extraFilter && props.extraFilter}
        </Stack>
      )}
      {!isWeb() && infoVisible && (
        <Actionsheet isOpen onClose={() => setInfoVisible(false)}>
          <Actionsheet.Content>
            <VStack maxH={60}>
              <HStack flex={1} alignItems={'flex-start'}>
                <Feather
                  name="info"
                  size={18}
                  color={Colors.Custom.Gray500}
                  style={{marginRight: 4, marginTop: 4}}
                />
                <Text>
                  Blood glucose levels below 70 mg/dl qualify as low glucose
                  events.
                </Text>
              </HStack>
            </VStack>
          </Actionsheet.Content>
        </Actionsheet>
      )}
    </VStack>
  );
};

export default VitalGraphCard;
