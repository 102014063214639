import {StyleSheet} from 'react-native';

const topBottomPadding = 6;
const sidePadding = 16;

export const styles = StyleSheet.create({
  container: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  branchListItem: {
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    paddingRight: sidePadding,
    paddingLeft: sidePadding,
    margin: 0,
    maxHeight: 200,
    cursor: 'pointer',
  },
});
