import {View, useToast} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {getInboxTypeCode} from '../../../utils/commonUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {IConversationData} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getContactDataAndType} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
// import MessagingWindow from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';
import {IGroupListByMember} from './interface';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../constants/ActionConst';
import {EventBus} from '../../../utils/EventBus';
import OverrideAntdModal from '../../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { CONVERSATION_LOCAL_EVENT_CODES} from '../../../constants/WebSocketConst';
import InstantChatView from '../ChatDrawer/InstantChatView';
import { localBroadcastEvent } from '../../../utils/CustomEventHandler';
import GroupListByMemberV2 from './V2/GroupListByMemberV2';

const GroupListPanel = (props: IGroupListByMember) => {
  const {groupMemberData, onGroupListActionPerformed, parentCode, conversationData} = props;
  const [groupListPanelData, setGroupListPanelData] = useState({
    isMessageWindowVisible: false,
    selectedConversation: {} as IConversationData,
    msgText: '',
    openConfirmationModal: false,
    updateCommunicationList: false,
  });
  const toast = useToast();
  const intl = useIntl();

  const contactInfo = getContactDataAndType(
    groupListPanelData?.selectedConversation || ({} as any)
  );
  const contactData: any = contactInfo?.contactData || ({} as any);
  const currentContactData: any = props?.contactData || ({} as any);

  const onSelect = (selectedConversation?: IConversationData) => {
    if (conversationData?.uuid !== selectedConversation?.uuid) {
      setGroupListPanelData((prev) => {
        return {
          ...prev,
          isMessageWindowVisible: true,
          selectedConversation: selectedConversation || ({} as any),
        };
      });
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'aviodDrawerForSameConversation'}),
        ToastType.info,
        4000
      );
    }
  };

  const onClose = () => {
    setGroupListPanelData((prev) => {
      return {
        ...prev,
        isMessageWindowVisible: false,
        selectedConversation: {} as any,
      };
    });
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAl_UPDATE_SELECTED_CONVERSATION, {
      conversation: groupListPanelData.selectedConversation?.uuid
    });
  }

  const onMsgSendOnArchiveConversationListenerFn = useCallback(
    (data: {conversationData: IConversationData}) => {
      const conversationData = data?.conversationData;
      if (conversationData?.id && conversationData?.status === 1) {
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              status: 0,
            },
          };
        });
      }
    },
    [groupListPanelData]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgSendOnArchiveConversationListenerFn
    );
    return () => {
      eventBus.removeEventListener(onMsgSendOnArchiveConversationListenerFn);
    };
  }, [groupListPanelData?.selectedConversation?.id]);

  const onConversationActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              unreadMessages: [],
              unreadMsgCount: 0
            }
          }
        })
        break;
      }
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT: {
        onClose();
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            updateCommunicationList: !prev.updateCommunicationList,
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.ITEM_CLICKED:
        setGroupListPanelData((prev) => {
          return {
            ...prev,
            selectedConversation: actionData,
          };
        });
        break;
      case COMMON_ACTION_CODES.CLOSE_MODAL:
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        onClose();
    }
  };

  return (
    <View>
      <View flex={1} pb={2} style={{marginHorizontal: 25, marginBottom: 0}}>
        <GroupListByMemberV2
          groupMemberData={groupMemberData}
          onGroupListActionPerformed={onGroupListActionPerformed}
          onSelect={onSelect}
          parentCode={parentCode}
          shouldDisableLimit={props?.shouldDisableLimit}
          contactData={currentContactData}
          updateCommunicationList={groupListPanelData.updateCommunicationList}
        />
      </View>
      {isWeb() && groupListPanelData.isMessageWindowVisible && (
        <InstantChatView
          selectedConversation={groupListPanelData.selectedConversation}
          contactData={contactData}
          onActionPerformed={onConversationActionPerformed}
          isDrawerVisible={groupListPanelData.isMessageWindowVisible}
          isLoading={false}
          isInstantChatView={true}
          selectedInboxTypeCode={getInboxTypeCode(
            groupListPanelData?.selectedConversation?.conversationInbox
              ?.channelType || ''
          )}
          instantChatFromDetailPanel={true}
          moduleCode={`MessagingWindow/GroupListPanel`}
        />
      )}
      {isWeb() &&
        groupListPanelData.isMessageWindowVisible &&
        groupListPanelData.openConfirmationModal && (
          <OverrideAntdModal
            isOpen={groupListPanelData.openConfirmationModal}
            onClose={() => {
              setGroupListPanelData((prev) => {
                return {
                  ...prev,
                  openConfirmationModal: false,
                };
              });
            }}
            onConfirm={() => {
              setGroupListPanelData((prev) => {
                return {
                  ...prev,
                  openConfirmationModal: false,
                };
              });
              onClose();
            }}
            closeButtonText="cancel"
            textContent={{
              headerText: 'Confirmation',
              message: `Do you want to close the conversation?`,
            }}
          />
        )}
    </View>
  );
};
export default GroupListPanel;
