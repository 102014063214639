import {IInbox} from '../../../services/Inbox/interfaces';
import {ICTMlov} from '../../../utils/interfaces';
import {ASSIGN_TYPE} from '../../RightSideContainer/CloudTelephony/CloudTelePhoneyConst';
import {getAssigneeNameFromAssigneeIdList} from '../../RightSideContainer/CloudTelephony/CloudTelephonyUtils';
import {IUser} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {INumberList, IVirtualNumber} from './interface';

export const getCallAndSmsNumber = (
  virtualNumbers: IVirtualNumber[],
  inboxes: IInbox[],
  assigneesType: ICTMlov[],
  userList: IUser[],
  showOnlyCallNumbers: boolean,
): INumberList[] => {
  const numberList: INumberList[] = [];
  const assigneesTypeList = [...assigneesType];
  virtualNumbers.forEach((number) => {
    const smsCheck = isSmsInboxWithNumber(number.virtualPhoneNumber, inboxes);
    const callCheck = isCallEnabled(number);
    const tempSingleAssignee: ICTMlov =
      assigneesTypeList.find((item: ICTMlov) => {
        return item.id == number.assigneeTypeId;
      }) || ({} as any);
    const assigneeName = getAssigneeNameFromAssigneeIdList(
      number,
      userList,
      tempSingleAssignee
    );
    const temp: INumberList = {
      name: assigneeName,
      phoneNumber: number.virtualPhoneNumber,
      isSmsInbox: smsCheck.isSmsInbox,
      smsInboxId: smsCheck.id,
      isCallPossible: callCheck,
      assigneeType: number.assigneeType.code,
      assigneeId: number.assigneeId
    };
    if (showOnlyCallNumbers && callCheck) {
      numberList.push(temp);
    } else if (smsCheck.isSmsInbox || callCheck) {
      numberList.push(temp);
    }
  });
  return numberList;
};
export const getOnlyCallFormated = (
  virtualNumbers: IVirtualNumber[],
  showOnlyCallNumbers: boolean,
): INumberList[] => {
  const numberList: INumberList[] = [];
  virtualNumbers.forEach((number) => {
    if (showOnlyCallNumbers && !number.disableOutgoingCalls) {
      const temp: INumberList = {
        name: number?.assignee,
        phoneNumber: number.virtualPhoneNumber,
        isCallPossible: true,
        assigneeType: number.assigneeType.code,
        assigneeId: number.assigneeId
      };
      numberList.push(temp);
    } else if (!showOnlyCallNumbers) {
      const temp: INumberList = {
        name: number?.assignee,
        phoneNumber: number.virtualPhoneNumber,
        isCallPossible: true,
        assigneeType: number.assigneeType.code,
        assigneeId: number.assigneeId
      };
      numberList.push(temp);
    }
  });
  return numberList;
};
export const isSmsInboxWithNumber = (number: string, inboxes: IInbox[]) => {
  const inboxWithNumber = inboxes?.find((item) => {
    return item?.channelTwilioSms?.phoneNumber === number;
  });
  return inboxWithNumber?.id
    ? {
        id: inboxWithNumber?.id,
        isSmsInbox: true,
      }
    : {
        id: -1,
        isSmsInbox: false,
      };
};
export const isCallEnabled = (number: IVirtualNumber) => {
  return number?.disableOutgoingCalls ? false : true;
};
