import { StyleSheet } from 'react-native';
import { isWeb } from '../../../utils/platformCheckUtils';

export const styles = StyleSheet.create({
    logDetail: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto'
    },
    markDownWrapper: {
        flexShrink: 1,
        flexBasis: 'auto'
    },
    logStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
        marginLeft: 45,
        marginTop: -4,
        flexWrap: 'wrap',
        flex: 1,
        paddingLeft: isWeb() ? 0 : 5
    }
});
