
import { Drawer } from "antd";
import { HStack, Spacer, View, VStack } from "native-base";
import { useState, useEffect } from 'react';
import { useSearchParams,useNavigate } from "react-router-dom";
import { BUTTON_TYPE } from "../../../../../../constants";
import { Colors } from "../../../../../../styles";
import CustomTabsView from "../../../../../common/CustomTabsView/CustomTabsView";
import { ModalActionTitle } from "../../../../../common/ModalActionTitle/ModalActionTitle";
import WorkflowAuditFilter from "../../AddOrUpdateWorkflow/WorkflowAuditFilter";
import { IWorkflowGraphPopup } from "../Interfaces";
import { EmailGraphByTag } from "./EmailGraphByTag";
import { SmsGraphByTag } from "./SmsGraphByTag";
import WorkflowExecutionGraph from "./WorkflowExecutionGraph";
import WorkflowExecutionLevelLog from "./WorkflowExecutionLevelLog";
import { FlowType } from "../../../../../../context/WorkflowContext";
const WorkflowGraphPopup = (props: IWorkflowGraphPopup): JSX.Element => {
  const [isHideEmailGraph, setIsHideEmailGraph] = useState(false);
  const [isHideSmsGraph, setIsHideSmsGraph] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const isDrawerShow = props?.isShow.toString();
  

  return <>
    <Drawer
      open={props?.isShow}
      onClose={() => {
        props.onClose();
      }}
      width={'75vw'}
      title={
        <ModalActionTitle
          title={'Automation'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'Close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();

              },
            }
          ]}
        />
      }
    >
      <View padding={0} paddingTop={0}>
        <CustomTabsView flowType={props?.workflowData?.flowType as FlowType} workflowMasterId={props?.workflowData?.workflowMasterId} isDrawerOpen={isDrawerShow}
          tabsList={[
            {
              key: 'OVERVIEW',
              title: 'Summary',
              tabContainerElem: () => {
                return (
                 

                   
                      <View flex={1}   >
                        <WorkflowExecutionGraph legendPosition={props.workflowData?.isSendSurveyForm && false ? 'bottom' : 'right'} workflowData={props.workflowData}></WorkflowExecutionGraph>
                      </View>
                    
                    
                  
                );
              },
            },
            {
              key: 'EMAIL_OVERVIEW',
              title: 'Email statistics',
              tabContainerElem: () => {
                return (
                  <VStack margin={0} height={'100%'} width='100%' overflow={'visible'}  >

                    <VStack marginY={2}>
                      <WorkflowAuditFilter isAddUniqueEmailStatusFilter={true} isShowEmailStatus={true} defaultDayRange={8} contactList={[]} isDateMandatory={true}></WorkflowAuditFilter>
                    </VStack>
                    {
                      !isHideEmailGraph && <VStack flex={5} width={'full'} paddingX={5} key={(searchParams.get('emailStatus') || '') +  (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')}>
                        <EmailGraphByTag  isDateMandatory={true} key={(searchParams.get('emailStatus') || '') + (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')} setIsHideEmailGraph={(value: any) => {
                          // setIsHideEmailGraph(value)
                        }} tagData={ {tag:props.workflowData?.workflowMasterId, tagType: 'workflowMasterId'} }></EmailGraphByTag>
                      </VStack>
                    }
                  </VStack>
                );
              },
            },
            {
              key: 'SMS_OVERVIEW',
              title: 'SMS statistics',
              tabContainerElem: () => {
                return (
                  <VStack margin={0} height={600} width='100%' overflow={'visible'}  >

                    <VStack marginY={10}>
                      <WorkflowAuditFilter isAddUniqueEmailStatusFilter={true} isShowEmailStatus={false} isShowSmsStatus={true} defaultDayRange={8} contactList={[]} isDateMandatory={true}></WorkflowAuditFilter>
                    </VStack>
                    {
                      !isHideSmsGraph && <VStack flex={5} width={'full'} paddingX={5} key={(searchParams.get('smsStatus') || '') +  (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')}>
                        <SmsGraphByTag isDateMandatory={true} key={(searchParams.get('smsStatus') || '') + (searchParams.get('fromDate') || '') + (searchParams.get('toDate') || '')} setIsHideSmsGraph={(value: any) => {
                          // setIsHideSmsGraph(value)
                        }} tagData={ {tag:props.workflowData?.workflowMasterId, tagType: 'workflowMasterId'} }></SmsGraphByTag>
                      </VStack>
                    }
                  </VStack>
                );
              },
            },
            {
              key: 'EMAIL_EXECUTION',
              title: 'Individual Execution',
              tabContainerElem: () => {
                return (
                  <VStack margin={0} height={600} width='100%' overflow={'visible'}>

                    <WorkflowExecutionLevelLog width={'64vw'} limit={5} flowType={props?.workflowData?.flowType} isShowPersonLevelCommunicationInfo={true} workflowMasterId={props?.workflowData?.workflowMasterId}></WorkflowExecutionLevelLog>
                  </VStack>
                );
              },
            },
          ]}
        />
      </View>
    </Drawer>

  </>
}
export default WorkflowGraphPopup;
