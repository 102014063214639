import { Jodit } from "jodit-react";
import { Ref } from "react";
import { StyleSheet } from "react-native";

export interface IRichJoditEditorProps {
    content: string,
    
    onChange?: () => void,
    readonly?: boolean,
    setRef?: (ref: Jodit | null) => void;
    placeholder?: string,
    height?: number | string,
    onKeydown?: ((editorType: any, event: any) => any) | any,
    style?: any,
    editorActions?: (actionCode: EDITOR_ACTIONS, actionData?: any) => void,
}

export enum EDITOR_ACTIONS {
    RESET_HEIGHT = 'resetHeight'
}