import React from 'react';
import {Pressable, Text, VStack} from 'native-base';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {ICopiedMessageItem} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {isMessageCopied} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {testID} from '../../testUtils';
import {Tooltip} from 'antd';
import AssignMessageTypeSvg from '../common/Svg/PersonActionSvgIcons/AssignMessageTypeSvg';
import { View } from 'react-native';

export interface IAssignMessageTypeButtonProps {
  onClick: () => void;
  copyMessageList: ICopiedMessageItem[];
  copyCode: string;
}
const AssignMessageTypeButton = (props: IAssignMessageTypeButtonProps) => {
  const {onClick, copyMessageList, copyCode} = props;
  const intl = useIntl();
  const isMessageCopy = isMessageCopied(copyMessageList, copyCode);
  return (
    <Tooltip title={intl.formatMessage({id: 'assignType'})}>
      <Pressable
        disabled={!isMessageCopy}
        onPress={() => {
          onClick();
        }}
        {...testID('AssignMessageTypeBtn')}
      >
        <View
          style={{
            alignItems: 'center',
            height: 20, 
            width: 20,
          }}
          {...testID('image')}
        >
          <AssignMessageTypeSvg
            customStrokeColor={
              isMessageCopy ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
            }
          />
        </View>
      </Pressable>
    </Tooltip>
  );
};

export default AssignMessageTypeButton;
