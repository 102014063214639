import {Viewer} from '@toast-ui/react-editor';
import moment from 'moment';
import React from 'react';
import {Pressable, Text, View} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {DATE_FORMATS} from '../../../../constants';
import {Colors} from '../../../../styles';
import {
  MESSAGE_CUSTOM_CARD_KEY,
  MESSAGE_CUSTOM_CARD_TYPE,
} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import {EmailHtml} from '../../EmailPopupView/EmailHtml';
import {EmailAttachments} from '../EmailAttachments';
import {EmailMessageActions} from '../EmailMessageActions';
import MessagingCustomCard from '../MessageBox/MessagingCustomCard/MessagingCustomCard';
import MsgAttachment from '../MsgAttachment/MsgAttachment';
import {
  getEmailDataFromContentAttributes,
  getSenderUserData,
  processMessageData,
} from './EmailMessageBoxUtils';
import {
  IEmailMessageBoxProps,
  IEmailMessageContentAttributes,
} from './interfaces';
import {styles} from './styles';
import {ForwardedEmailTag} from '../ForwardedEmailTag';
import CreateNewTaskSvg from '../../Svg/CreateNewTaskSvg';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import {Tooltip} from 'native-base';
import AssignMessageTypeSvg from '../../Svg/PersonActionSvgIcons/AssignMessageTypeSvg';
import { testID } from '../../../../testUtils';

const EmailMessageBox = (props: IEmailMessageBoxProps) => {
  const userData = getSenderUserData(props.messageData);
  const [state, setState] = React.useState({
    isDisplayFullContent: props.isDisplayFullContent || false,
    msgContent: props.messageData.messageData?.content || '',
  });

  const toggleDisplayFullContent = () => {
    setState((prev) => {
      return {
        ...prev,
        isDisplayFullContent: !prev.isDisplayFullContent,
      };
    });
  };

  const isAutomatedMessage = props.messageData.messageType === 8;
  const senderName = isAutomatedMessage
    ? 'Automated Email'
    : props.messageData.senderUser?.name ||
      props.messageData.senderContact?.name;
  const contentAttributes: Partial<IEmailMessageContentAttributes> =
    props.messageData.messageData?.contentAttributes || {};
  const isForwarded = contentAttributes?.isForwarded || false;
  const {bcc, cc, hasAttachments, importance, to} =
    getEmailDataFromContentAttributes(contentAttributes);
  const subtitle = to.length > 0 ? `To: ${to.join(',')}` : '';
  const messageDate = moment(
    props.messageData.dateStr,
    DATE_FORMATS.MESSAGE_DATE_FORMAT,
    true
  ).isValid()
    ? props.messageData.dateStr
    : moment(props.messageData.dateStr).format(
        DATE_FORMATS.MESSAGE_DATE_FORMAT
      );

  const showTaskCount = () => {
    return props.messageData.taskCount && props.messageData.taskCount > 0;
  };

  const showAssignedTypes = () => {
    return (
      props.messageData.communicationTypes &&
      props.messageData.communicationTypes.length > 0
    );
  };

  const getEmailMessageBoxElement = (): JSX.Element => {
    if (props.messageData.private) {
      return (
        <View
          style={[
            styles.emailMessageContainerStyle,
            styles.privateMessageContainerStyle,
          ]}
        >
          <View style={styles.internalMessageHeaderView}>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Feather name="users" size={15} color={'#F47A3E'} />
              <Text style={styles.internalTitleText}>Internal</Text>
            </View>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Text style={styles.internalSenderNameText}>{senderName}</Text>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: 7,
                  onlineBatchSize: 1,
                  textStyle: {
                    fontSize: 10,
                  },
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: userData?.userId || -1,
                  userType: userData?.userType as any,
                  userName: senderName,
                }}
              />
              <View
                style={{
                  width: 1.5,
                  height: 16,
                  marginHorizontal: 8,
                  backgroundColor: Colors.Custom.Gray200,
                }}
              />
              <EmailMessageActions
                onActionPerformed={(actionId) => {
                  props.onActionPerformed(actionId, props.messageData);
                }}
                messageData={props.messageData}
                channelData={props.channelData}
              />
            </View>
          </View>
          <View
            style={{
              width: '100%',
            }}
          >
            {props.messageData.messageData?.contentType ===
              MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
            props.messageData.messageData?.contentType ===
              MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD ? (
              <View
                style={{
                  padding: 10,
                  borderRadius: 10,
                  borderColor: Colors.FoldPixel.GRAY400,
                  borderWidth: 0.5,
                  width: 'max-content',
                  marginVertical: 10,
                }}
              >
                <MessagingCustomCard
                  conversationMessage={props.messageData.messageData}
                  navigation={{}}
                />
              </View>
            ) : (
              <Viewer
                linkAttributes={{
                  target: '_blank',
                }}
                extendedAutolinks={true}
                initialValue={props.messageData.messageData?.content}
              />
            )}

            <MsgAttachment
              attachments={(props.messageData.msgAttachment || []) as any[]}
              position={'left'}
              dateStr={''}
              senderFullName={senderName}
              isNestedMessageEle
            />
          </View>

          <View style={styles.internalMsgDateView}>
            <View>
              {showTaskCount() || showAssignedTypes() ? (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {showTaskCount() ? (
                    <Tooltip label="Tasks" placement="top">
                      <Pressable
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onPress={() => {
                          if (props.onViewTaskAction) {
                            props.onViewTaskAction(props.messageData.uuid);
                          }
                        }}
                      >
                        <CreateNewTaskSvg
                          customStrokeColor={Colors.Custom.Gray400}
                          height={16}
                          width={16}
                        />
                        <Text
                          style={{
                            color: Colors.Custom.Gray400,
                            fontSize: 14,
                            marginLeft: 3,
                          }}
                        >
                          {props.messageData.taskCount || 0}
                        </Text>
                      </Pressable>
                    </Tooltip>
                  ) : (
                    <></>
                  )}

                  {showTaskCount() && showAssignedTypes() ? (
                    <View
                      style={{
                        width: 1.5,
                        height: 16,
                        marginHorizontal: 8,
                        backgroundColor: Colors.Custom.Gray200,
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  {showAssignedTypes() ? (
                    <Tooltip label="Assigned Type" placement="top">
                      <Pressable
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onPress={() => {
                          props.onActionPerformed(
                            MessageActionID.assignType,
                            props.messageData
                          );
                        }}
                      >
                        <View
                          style={{
                            alignItems: 'center',
                            height: 18,
                            width: 18,
                          }}
                          {...testID('image')}
                        >
                          <AssignMessageTypeSvg
                            customStrokeColor={Colors.FoldPixel.GRAY300}
                          />
                        </View>
                        <Text
                          style={{
                            color: Colors.Custom.Gray400,
                            fontSize: 14,
                            marginLeft: 3,
                          }}
                        >
                          {props.messageData.communicationTypes?.length || 0}
                        </Text>
                      </Pressable>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
            <Text style={styles.internalMsgDateText}>{messageDate}</Text>
          </View>
        </View>
      );
    }
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderColor: '#E9ECF1',
          borderWidth: 1,
          borderRadius: 8,
          overflow: 'hidden',
          marginTop: 10,
        }}
      >
        <Pressable
          style={[styles.emailMessageContainerStyle]}
          onPress={() => {
            toggleDisplayFullContent();
          }}
        >
          <View style={styles.avatarStyle}>
            <DisplayCardAvatar
              avatarStyle={{
                avatarSize: '12',
                onlineBatchSize: 1,
              }}
              isLetterAvatarShow={true}
              userData={{
                userId: userData?.userId || -1,
                userType: userData?.userType as any,
                userName: senderName,
              }}
            />
          </View>
          <View style={styles.emailContentStyle}>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    lineHeight: 19.2,
                    color: '#3A485F',
                  }}
                >
                  {senderName}
                </Text>
                {isForwarded ? (
                  <View
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    <ForwardedEmailTag />
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <Text style={[styles.internalMsgDateText, {marginTop: 6}]}>
                {messageDate}
              </Text>
            </View>
            {state.isDisplayFullContent ? (
              <></>
            ) : (
              <View>
                <Text
                  style={styles.subtitleStyle}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {subtitle}
                </Text>
              </View>
            )}
            {state.isDisplayFullContent ? (
              <>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.emailListTextStyle}>{`To: ${to.join(
                    ','
                  )}`}</Text>
                </View>
                {cc.length > 0 ? (
                  <View>
                    <Text style={styles.emailListTextStyle}>{`CC: ${cc.join(
                      ','
                    )}`}</Text>
                  </View>
                ) : (
                  <></>
                )}
                {bcc.length > 0 ? (
                  <View>
                    <Text style={styles.emailListTextStyle}>{`BCC: ${bcc.join(
                      ','
                    )}`}</Text>
                  </View>
                ) : (
                  <></>
                )}
                {hasAttachments ? (
                  <View
                    style={{
                      paddingVertical: 4,
                    }}
                  >
                    <EmailAttachments
                      messageUuid={props.messageData.uuid}
                      senderType={props?.messageData?.senderType}
                    />
                  </View>
                ) : (
                  <></>
                )}
                <View
                  style={{
                    marginTop: 4,
                    flexWrap: 'wrap',
                    maxWidth: '100%',
                  }}
                >
                  <EmailHtml html={state.msgContent} />
                </View>
              </>
            ) : (
              <></>
            )}
          </View>
          <View
            style={[
              styles.actionPopoverStyle,
              {
                justifyContent: state.isDisplayFullContent
                  ? 'flex-start'
                  : 'center',
              },
            ]}
          >
            <EmailMessageActions
              onActionPerformed={(actionId) => {
                props.onActionPerformed(actionId, props.messageData);
              }}
              messageData={props.messageData}
              channelData={props.channelData}
            />
          </View>
        </Pressable>

        {showTaskCount() || showAssignedTypes() ? (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: 10,
              backgroundColor: Colors.Custom.Gray100,
            }}
          >
            {showTaskCount() ? (
              <Tooltip label="Tasks" placement="top">
                <Pressable
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onPress={() => {
                    if (props.onViewTaskAction) {
                      props.onViewTaskAction(props.messageData.uuid);
                    }
                  }}
                >
                  <CreateNewTaskSvg
                    customStrokeColor={Colors.Custom.Gray400}
                    height={16}
                    width={16}
                  />
                  <Text
                    style={{
                      color: Colors.Custom.Gray400,
                      fontSize: 14,
                      marginLeft: 3,
                    }}
                  >
                    {props.messageData.taskCount || 0}
                  </Text>
                </Pressable>
              </Tooltip>
            ) : (
              <></>
            )}

            {showTaskCount() && showAssignedTypes() ? (
              <View
                style={{
                  width: 1.5,
                  height: 16,
                  marginHorizontal: 8,
                  backgroundColor: Colors.Custom.Gray200,
                }}
              />
            ) : (
              <></>
            )}

            {showAssignedTypes() ? (
              <Tooltip label="Assigned Type" placement="top">
                <Pressable
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onPress={() => {
                    props.onActionPerformed(
                      MessageActionID.assignType,
                      props.messageData
                    );
                  }}
                >
                  <View
                    style={{
                      alignItems: 'center',
                      height: 18,
                      width: 18,
                    }}
                    {...testID('image')}
                  >
                    <AssignMessageTypeSvg
                      customStrokeColor={Colors.FoldPixel.GRAY300}
                    />
                  </View>
                  <Text
                    style={{
                      color: Colors.Custom.Gray400,
                      fontSize: 14,
                      marginLeft: 3,
                    }}
                  >
                    {props.messageData.communicationTypes?.length || 0}
                  </Text>
                </Pressable>
              </Tooltip>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  };

  const getProcessMessageData = async () => {
    const response = await processMessageData({messageData: props.messageData});
    setState((prev) => {
      return {
        ...prev,
        msgContent: response.content || '',
      };
    });
  };

  React.useEffect(() => {
    if (state.isDisplayFullContent) {
      getProcessMessageData();
    }
  }, [state.isDisplayFullContent]);

  return getEmailMessageBoxElement();
};

export default EmailMessageBox;
