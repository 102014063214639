
import { Spinner, Text, View } from "native-base";
import { useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import ShowPeopleInfo from "./ShowPeopleInfo";




function ShowNodeLevelPeopleLogInfo(props: {isCountLoading?:boolean, uiNodeTimeSavedInSeconds: number, uiNodeTotalExecutionCount: number, rootNodeType: string, parentNodeId?: string, uiNodeId: string, groupByEvent: string }) {
  const [isVisible, setIsVisible] = useState(false)
  return (<Pressable
    style={{

      width: '100%',
      // height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    onPress={() => {
      setIsVisible(true);
    }}
  ><View flex={1} width={'100%'} height={'100%'} justifyContent='center' alignItems={'center'}>
      {props?.isCountLoading ? <Spinner justifyContent='center' alignItems={'center'} position={'absolute'} /> :
      <View justifyContent={'center'} alignContent={'center'} alignItems={'center'} justifyItems={'center'}>
        <Text fontSize={14} fontWeight={'600'} style={{ zIndex: 1000 }} color={'white'}>
          {props.uiNodeTotalExecutionCount}
        </Text>
          {
            (!props.groupByEvent || props.groupByEvent === 'CONTACT') &&
            <Text style={{fontSize: 12 , color:'white'}}>{'members'}</Text>
          }
          {
            (props.groupByEvent === 'USER') &&
            <Text style={{fontSize: 12, color:'white'}}>{'users'}</Text>
          }
          {
            (props.groupByEvent === 'TASK') &&
            <Text style={{fontSize: 12, color:'white'}}>{'tasks'}</Text>
          }
          {
            (props.groupByEvent === 'ORDER') &&
            <Text style={{fontSize: 12, color:'white'}}>{'orders'}</Text>
          }
          {
            isVisible &&
            <ShowPeopleInfo
              rootNodeType={props.rootNodeType}
              uiNodeId={props.uiNodeId}
              rootNodeId={props.parentNodeId}
              isVisible={isVisible}
              setVisible={setIsVisible}
            ></ShowPeopleInfo>
          }
      </View>}
    </View>
  </Pressable>)


}
export default ShowNodeLevelPeopleLogInfo;
