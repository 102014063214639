import {useLazyQuery} from '@apollo/client';
import {
  Box,
  Button,
  FlatList,
  HStack,
  Skeleton,
  Text,
  View,
  VStack,
  Select
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {SafeAreaView} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../../../../constants/ActionConst';
import {FEED_SERVICE_APOLLO_CONTEXT} from '../../../../../../../../constants/Configs';
import {
  FEED_CATEGORY,
  MLOV_CATEGORY,
} from '../../../../../../../../constants/MlovConst';
import {CRM_ACTIVITY_TIMELINE_CONST, FILTER_OPTIONS, MODULE_PAGINATION_COUNT} from '../../../../../../../../constants/StringConst';
import {CommonDataContext} from '../../../../../../../../context/CommonDataContext';
import FeedsQueries from '../../../../../../../../services/Feeds/FeedsQueries';
import {getAccountUUID} from '../../../../../../../../utils/commonUtils';
import {getMlovId} from '../../../../../../../../utils/mlovUtils';
import NoActivitySvg from '../../../../../../../common/Svg/NoActivitySvg';
import ActivityAdditionalInfo from '../ActivityAdditionalInfo/ActivityAdditionalInfo';
import {groupByFeedDataByTimeStamp} from '../ActivityUtils';
import {IFeedData, IFeedRespData} from '../interface';
import SingleTimelineCard from '../SingleTimelineCard/SingleTimelineCard';
import {IContactTimelineView} from './interfaces';
// import {Select, Timeline} from 'antd';
import {FEED_RESOURCE_TYPE} from '../FeedConst';
import LocalStorage from '../../../../../../../../utils/LocalStorage';
import { isWeb } from '../../../../../../../../utils/platformCheckUtils';
import { TestIdentifiers, testID } from '../../../../../../../../testUtils';
import { COMPONENT_TEST_IDENTIFIER } from '../../../../../../../../testUtils/ComponentTestIdentifiers';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../../../../constants/WebSocketConst';
import { EventBus } from '../../../../../../../../utils/EventBus';

const ContactTimelineView = (props: IContactTimelineView) => {
  const {contactData} = props;

  const accountUUID = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const contactFeedCategoryId = getMlovId(
    commonData.MLOV,
    MLOV_CATEGORY.FEED_CATEGORY,
    FEED_CATEGORY.CONTACT_ACTIVITY
  );
  const providerFeedCategoryID= getMlovId(
    commonData.MLOV,
    MLOV_CATEGORY.FEED_CATEGORY,
    FEED_CATEGORY.PROVIDER_ACTIVITY
  );
  const [componentState, setComponentState] = useState({
    feedData: [] as IFeedData[],
    isFetchMore: false,
    showFetchMore: true,
    isFeedDataLoading: true,
    groupDateByFeedData: {} as {[index: string]: IFeedData[]},
    selectedResourceData: {} as any,
    selectedResourceCode: '',
    activityFilter: CRM_ACTIVITY_TIMELINE_CONST.ALL_ACTIVITIES,
  });

  const [GetAllContactFeeds, {fetchMore: fetchMoreGetContactFeeds}] =
    useLazyQuery<IFeedRespData>(FeedsQueries.GetAllContactFeeds, {
      fetchPolicy: 'no-cache',
      variables: {
        limit: MODULE_PAGINATION_COUNT.CRM_ACTIVITY_PAGE_SIZE,
        offset: 0,
        feedDataWhereCondition: {
          resourceType: {_neq: FEED_RESOURCE_TYPE.LOGIN_ACTIVITY},
          isDeleted: {_eq: false},
          categoryId: {
            _in: [contactFeedCategoryId, providerFeedCategoryID]
          },
          _or: [
            {
              patient_id: {
                _eq: contactData?.patient?.patientUuid,
              },
            },
            {
              patient_id: {
                _eq: contactData?.uuid,
              },
            },
          ],
        },
      },
    });

  const getAllFeedData = async () => {
    setComponentState((prev) => {
      return {
        ...prev,
        feedData: [],
        groupDateByFeedData: {},
        isFeedDataLoading: true,
      };
    });
    const feedDataWhereCondition: any = [
      {
        resourceType: {
          _nin: [FEED_RESOURCE_TYPE.LOGIN_ACTIVITY, FEED_RESOURCE_TYPE.MEMBERSHIP, FEED_RESOURCE_TYPE.APPOINTMENT]
      }
    }
    ];
    if (componentState.activityFilter !== CRM_ACTIVITY_TIMELINE_CONST.ALL_ACTIVITIES) {
      feedDataWhereCondition.push({resourceType: {_eq: componentState.activityFilter}})
    }
    const feedData = await GetAllContactFeeds({
      context: {
        service: FEED_SERVICE_APOLLO_CONTEXT,
      },
      variables: {
        limit: MODULE_PAGINATION_COUNT.CRM_ACTIVITY_PAGE_SIZE,
        offset: 0,
        feedDataWhereCondition: {
          _and: feedDataWhereCondition,
          isDeleted: {_eq: false},
          categoryId: {
            _in: [contactFeedCategoryId, providerFeedCategoryID]
          },
          _or: [
            contactData?.patient?.patientUuid && {
              patient_id: {
                _eq: contactData?.patient?.patientUuid,
              },
            },
            {
              patient_id: {
                _eq: contactData?.uuid,
              },
            },
          ].filter((condition) => !!condition),
        },
      },
    });
    if (feedData?.data?.feed_data.length) {
      const feedList = feedData?.data?.feed_data;
      const groupFeedData = groupByFeedDataByTimeStamp(feedList);
      setComponentState((prev) => {
        return {
          ...prev,
          feedData: feedList,
          groupDateByFeedData: groupFeedData,
          isFeedDataLoading: false,
        };
      });
    } else {
      setComponentState((prev) => {
        return {
          ...prev,
          feedData: [],
          groupDateByFeedData: {},
          isFeedDataLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    getAllFeedData();
  }, [componentState.activityFilter, props?.contactData?.uuid ]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_CONTACT_UPDATE,
      getAllFeedData
    );
    return () => {
      eventBus.removeEventListener(getAllFeedData);
    };
  },[])

  const onTimelineHeaderClick = (resourceType: string, resourceData: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        selectedResourceCode: resourceType,
        selectedResourceData: resourceData,
      };
    });
  };

  const onActivityAdditionalInfoActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setComponentState((prev) => {
          return {
            ...prev,
            selectedResourceCode: '',
            selectedResourceData: {},
          };
        });
        break;
      default:
        break;
    }
  };

  const renderSingleActivityCard = ({item, index}: any) => {
    const singleDateFeedDataList = componentState?.groupDateByFeedData[item];
    const firstEntry = componentState.feedData[componentState.feedData.length - 1].id;
    const timeStampIndex = Date.now();
    return (
      <>
        <HStack
          key={timeStampIndex}
          style={{
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 21,
              fontWeight: '600',
              color: 'rgb(102, 112, 133)',
              padding: 4,
              marginLeft: 17,
              marginTop:17,
              textTransform: 'uppercase',
              letterSpacing: 2,
              fontFamily: 'Manrope'
            }}
          >
            {item}
          </Text>
        </HStack>
        <VStack>
          {singleDateFeedDataList.map((singleTimelineFeedData: any, index: number) => {
            const singleFeedIndex = Date.now();
            return (
              <SingleTimelineCard
                singleFeedData={singleTimelineFeedData}
                firstEntryID={firstEntry}
                onHeaderClick={(actionCode, singleFeedData) => {
                  onTimelineHeaderClick(actionCode, singleFeedData);
                }}
              />
            );
          })}
        </VStack>
      </>
    );
  };


  const renderContactTimeLineFooter = (
    showFetchMore: boolean,
    isFetchMore: boolean,
    conversationList: unknown[],
    loadMoreFeedData: () => void
  ): JSX.Element => {
    const intl = useIntl();
    let footerElem = <></>;
    if (
      showFetchMore &&
      conversationList.length >= MODULE_PAGINATION_COUNT.CRM_ACTIVITY_PAGE_SIZE
    ) {
      if (isFetchMore) {
        footerElem = <Skeleton.Text mx={4} mt={2} lines={6}></Skeleton.Text>;
      } else {
        footerElem = (
          <Button
            size="md"
            variant="link"
            onPress={() => {
              loadMoreFeedData();
            }}
            marginBottom={6}
          >
            {intl.formatMessage({
              id: 'loadMoreActivity',
            })}
          </Button>
        );
      }
    }
    return footerElem;
  };

  const updateFetchMore = (fetchMoreResult: IFeedRespData) => {
    if (fetchMoreResult && fetchMoreResult?.feed_data?.length === 0) {
      setComponentState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
          showFetchMore: false,
        };
      });
    } else {
      setComponentState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
        };
      });
    }
  };

  const loadMoreContactActivity = (): void => {
    setComponentState((prev) => {
      return {
        ...prev,
        isFetchMore: true,
      };
    });

    fetchMoreGetContactFeeds({
      variables: {
        offset: componentState?.feedData.length,
      },
      updateQuery: (prevResult, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prevResult;
        const allFeedData: any = {
          ...prevResult,
          feedData: [
            ...componentState?.feedData,
            ...fetchMoreResult?.feed_data,
          ],
          feed_data: [
            ...componentState?.feedData,
            ...fetchMoreResult?.feed_data,
          ],
        };
        const groupFeedData = groupByFeedDataByTimeStamp(allFeedData.feedData);
        setComponentState((prev) => {
          return {
            ...prev,
            feedData: [...allFeedData.feedData],
            groupDateByFeedData: groupFeedData,
          };
        });
        updateFetchMore(fetchMoreResult);
        return allFeedData;
      },
    });
  };

  return (
    <View>
      {componentState?.isFeedDataLoading ? (
        <View {...testID(TestIdentifiers.pageLoading)}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <View>
          {(componentState.feedData?.length !== 0 ||
            componentState.activityFilter !==
              CRM_ACTIVITY_TIMELINE_CONST.ALL_ACTIVITIES) && (
            <View
              {...testID(COMPONENT_TEST_IDENTIFIER.activityDropdown)}
              borderBottomColor={'gray.200'}
              borderBottomWidth={1}
            >
              <Box
                maxW={isWeb() ? '250' : '100%'}
                px={2}
                py={4}
                fontSize={isWeb() ? 14 : 16}
                fontWeight={'400'}
                style={{
                  borderRadius: 10,
                }}
              >
                <Select
                  selectedValue={componentState.activityFilter}
                  accessibilityLabel="Choose Activities"
                  onValueChange={(itemValue) =>
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        activityFilter: itemValue,
                      };
                    })
                  }
                >
                  {FILTER_OPTIONS.map((item) => {
                    return (
                      <Select.Item label={item.label} value={item.value} />
                    );
                  })}
                </Select>
              </Box>
            </View>
          )}
          {/* <Timeline> */}
          <SafeAreaView>
            <FlatList
            marginBottom={10}
              {...testID(COMPONENT_TEST_IDENTIFIER.flatList)}
              data={Object.keys(componentState.groupDateByFeedData)}
              keyExtractor={(item, index) => {
                return 'msgBox_' + item;
              }}
              renderItem={renderSingleActivityCard}
              ListFooterComponent={() => {
                return renderContactTimeLineFooter(
                  componentState.showFetchMore,
                  componentState.isFetchMore,
                  componentState.feedData,
                  loadMoreContactActivity
                );
              }}
            />
          </SafeAreaView>
          {/* </Timeline> */}
        </View>
      )}

      {!componentState?.isFeedDataLoading &&
        componentState.feedData?.length === 0 && (
          <View
            style={{
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NoActivitySvg titleId="noContactActivity" />
          </View>
        )}

      <ActivityAdditionalInfo
        resourceType={componentState.selectedResourceCode}
        resourceData={componentState.selectedResourceData}
        onActionPerformed={(actionCode, actionData) => {
          onActivityAdditionalInfoActionPerformed(actionCode, actionData);
        }}
      />
    </View>
  );
};

export default React.memo(ContactTimelineView);
