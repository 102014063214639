import {Drawer, Select, Select as MultiSelect, Tag} from 'antd';
import {debounce} from 'lodash';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  useMediaQuery,
  View,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import { getUserUUID } from '../../../../utils/commonUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import UserWithRoleItem from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/UserWithRoleItem';
import { ALL_USERS_CODE } from '../../UserScheduleSetting/UserScheduleHelper';
import {
  IAddPoolModalProps,
  ITaskPoolTableData,
} from '../TaskInterfaces/TaskInterface';
import { CommonDataContext } from '../../../../context/CommonDataContext';
const {Label} = FormControl;
const { Option } = Select;

const AddPoolModal = (props: IAddPoolModalProps) => {
  const {isModalOpen, onModalClose, onSave, title} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const currentUserUUID = getUserUUID();
  const [newPoolName, setNewPoolName] = useState(
    props.selectedTaskData?.name ? props.selectedTaskData.name : ''
  );
  const intl = useIntl();
  const isCurrentUserInTheAllowedList = props?.usersData?.find((user: any) => user.uuid === currentUserUUID)
  const [newPoolUsers, setNewPoolUsers] = useState(
   (props.selectedTaskData?.userPoolUsers &&
      props.selectedTaskData?.userPoolUsers.length > 0)
      ? props.selectedTaskData?.userPoolUsers.map((data: any) => data.userId)?.filter((item: any) => !!item)
      : isCurrentUserInTheAllowedList && !props.isEdit ? [currentUserUUID] : []
  );
  const [newPoolAssignee, setNewPoolAssignee] = useState('unassigned');
  const [newPoolUsersArray, setNewPoolUsersArray] = useState<any>([
    {
      name: 'Unassigned',
      uuid: 'unassigned',
    },
  ]);
  const [tempNewPoolUsersArray, setTempNewPoolUsersArray] = useState<any>([]);
  const [customerLoading, setCustomerLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    newPoolNameError: '',
    newPoolSelectUserError: '',
    newPoolSelectAssigneeError: '',
  });

  const validate = () => {
    setErrors({});
    if (newPoolName.trim().length === 0) {
      setErrors({newPoolNameError: 'Please fill this field'});
      return false;
    }

    if (!newPoolUsers.length) {
      setErrors({newPoolSelectUserError: 'Please fill this field'});
      return false;
    }

    if (!newPoolAssignee) {
      setErrors({newPoolSelectAssigneeError: 'Please fill this field'});
      return false;
    }

    return true;
  };

  const handleAddNewPool = () => {
    if (validate()) {
      const newPool: ITaskPoolTableData = {
        newPoolName,
        newPoolUsers,
        poolId: props.selectedTaskData?.id,
        poolUsers: props.selectedTaskData?.userPoolUsers,
        isDefault: newPoolAssignee,
      };

      onSave(newPool);
      setNewPoolName('');
      setNewPoolUsers(isCurrentUserInTheAllowedList && !props.isEdit ? [currentUserUUID] : []);
      setNewPoolAssignee('unassigned');
      setNewPoolUsersArray([]);
      setErrors({});
      onModalClose();
    }
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const finalWidth = isIPadMiniScreen || isIPadScreen || isSidecarContext ? width * 0.6 : width / 3;

  useEffect(() => {
    setNewPoolName(
      props.selectedTaskData?.name ? props.selectedTaskData.name : ''
    );

    let selectedUserIds =
      props.selectedTaskData?.userPoolUsers &&
      props.selectedTaskData?.userPoolUsers.length > 0
        ? props.selectedTaskData?.userPoolUsers.map((data: any) => data.userId)
        : isCurrentUserInTheAllowedList && !props.isEdit ? [currentUserUUID] : [];
    const defaultUserPool =
      props.selectedTaskData?.userPoolUsers &&
      props.selectedTaskData?.userPoolUsers.length > 0
        ? props.selectedTaskData?.userPoolUsers.filter(
            (data: any) => data.isDefault
          )
        : [];
    selectedUserIds = selectedUserIds?.filter((item: string) => !!item);
    setNewPoolUsers(selectedUserIds);
    setNewPoolAssignee(
      defaultUserPool && defaultUserPool.length > 0
        ? defaultUserPool[0]['userId']
        : 'unassigned'
    );
    // const updatedUserPoolUsersArray = newPoolUsersArray;
    const selectedUserData = props.usersData.filter((userItem: any) =>
      selectedUserIds.includes(userItem.uuid)
    );
    // selectedUserData.map((selectedUserItem: any) => {
    //   const isExistData = newPoolUsersArray.filter(
    //     (dataItem: any) => dataItem.uuid === selectedUserItem.uuid
    //   );
    //   if (!(isExistData && isExistData.length > 0)) {
    //     updatedUserPoolUsersArray.push(selectedUserItem);
    //   }
    // });

    setNewPoolUsersArray(selectedUserData);
  }, [props.selectedTaskData]);


  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={ tagProps.closable}
        onClose={tagProps.onClose}
        style={{marginRight: 3}}
        className={'custom-tag'}
      >
        {tagProps.label}
      </Tag>
    );
  };

  const onChangeCustomer = (searchText: string) => {
    setCustomerLoading(true);

    if (searchText) {
      if (!tempNewPoolUsersArray.length)
        setTempNewPoolUsersArray(newPoolUsersArray);
      const updatedUserPoolUsersArray: any =
        tempNewPoolUsersArray.length > 0
          ? [
              ...tempNewPoolUsersArray.filter((userItem: any) => {
                return String(userItem.name)
                  .toLocaleLowerCase()
                  .includes(String(searchText).toLocaleLowerCase());
              }),
            ]
          : [
              ...newPoolUsersArray.filter((userItem: any) => {
                return String(userItem.name)
                  .toLocaleLowerCase()
                  .includes(String(searchText).toLocaleLowerCase());
              }),
            ];
      setNewPoolUsersArray(updatedUserPoolUsersArray);
    } else {
      const updatedUserPoolUsersArray = newPoolUsersArray;
      const selectedUserData = props.usersData.filter((userItem: any) =>
        newPoolUsers.includes(userItem.uuid)
      );
      selectedUserData.map((selectedUserItem: any) => {
        const isExistData = newPoolUsersArray.filter(
          (dataItem: any) => dataItem.uuid === selectedUserItem.uuid
        );
        if (!(isExistData && isExistData.length > 0)) {
          updatedUserPoolUsersArray.push(selectedUserItem);
        }
      });
      const filterUnAssigned = updatedUserPoolUsersArray.filter(
        (dataItem: any) => dataItem.uuid === 'unassigned'
      );
      if (!filterUnAssigned.length) {
        updatedUserPoolUsersArray.unshift({
          name: 'Unassigned',
          uuid: 'unassigned',
        });
      }

      setTempNewPoolUsersArray([]);
      setNewPoolUsersArray(updatedUserPoolUsersArray);
    }

    setCustomerLoading(false);
  };

  const handleUsersChange = (values: string[], selectedData: any[]) => {
    let userIds: string[] = [];
    const userSelectedIds = selectedData.map((item: any) => {
        return item.userId;
    })?.filter(item => !!item);
    userIds = userSelectedIds;

    setCustomerLoading(true);
    setNewPoolUsers(userIds);
    // const updatedUserPoolUsersArray = newPoolUsersArray;
    const selectedUserData = props.usersData.filter((userItem: any) =>
      userIds.includes(userItem.uuid)
    );

    const defaultAssigneeUser = newPoolAssignee && selectedUserData.find((userItem: any) =>
      newPoolAssignee === userItem.uuid
    );

    if (!defaultAssigneeUser) {
      setNewPoolAssignee('unassigned');
    }

    setNewPoolUsersArray(selectedUserData);
    setCustomerLoading(false);
}

  return (
    <Drawer
      width={isSidecarContext ? '100%' : finalWidth}
      mask={isSidecarContext ? false : true}
      visible={isModalOpen}
      onClose={() => {
        setNewPoolName('');
        setNewPoolUsers(isCurrentUserInTheAllowedList && !props.isEdit ? [currentUserUUID] : []);
        setNewPoolAssignee('unassigned');
        setNewPoolUsersArray([]);
        setErrors({});
        onModalClose();
      }}
      title={
        <>
          <HStack
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ModalActionTitle isHeadNotSticky={true} title={title} titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: isSidecarContext ? 'back' : 'cancel',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setNewPoolName('');
                    setNewPoolUsers(isCurrentUserInTheAllowedList && !props.isEdit ? [currentUserUUID] : []);
                    setErrors({});
                    onModalClose();
                  },
                },
                {
                  show: true,
                  id: 2,
                  btnText: 'save',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    handleAddNewPool();
                  },
                },
              ]}
            />
          </HStack>
        </>
      }
    >
      <View>
        <FormControl>
          <Label>
            <DisplayText size={'mdNormal'} textLocalId={'taskPoolName'} />
            <Text style={{ color: 'red' }}>*</Text>
          </Label>
          <Input
            _focus={{
              borderColor: Colors.Custom.Gray200
            }}
            size={'mdNormal'}
            value={newPoolName}
            onChangeText={(text: string) => setNewPoolName(text)}
          />
          <Text size={'smLight'} color="error.500">
            {errors?.newPoolNameError}
          </Text>
        </FormControl>
        <FormControl marginTop={2}>
          <Label>
            <DisplayText size={'mdNormal'} textLocalId={'taskPoolUsers'} />
            <Text style={{ color: 'red' }}>*</Text>
          </Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            //defaultValue={getUsersName([...defaultValue], dataIs)}
            value={newPoolUsers}
            optionFilterProp="label"
            optionLabelProp="label"
            tagRender={tagRender}
            placeholder="Select Users"
            onChange={handleUsersChange}
            maxTagCount={'responsive'}
          >
            {props.usersData.map((agentRecord: any) => {
              return (
                <Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.uuid}
                  label={agentRecord?.name}
                >
                  <UserWithRoleItem
                    agentRecord={agentRecord}
                  />
                </Option>
              );
            })}
          </MultiSelect>

          <Text size={'smLight'} color="error.500">
            {errors?.newPoolSelectUserError}
          </Text>
        </FormControl>
        <FormControl marginTop={2}>
          <ModalActionAntSelect
            allowClear={false}
            showSearch={true}
            leftMargin={'0'}
            onSearch={debounce(onChangeCustomer, 500)}
            labelInValue={true}
            filterOption={false}
            defaultValue={newPoolAssignee}
            value={newPoolAssignee}
            isRequired={false}
            label={intl.formatMessage({
              id: 'taskPoolDefaultAssignee',
            })}
            notFoundContent={intl.formatMessage({
              id: 'NoDataSelect',
            })}
            placeholder={intl.formatMessage({
              id: 'taskPoolDefaultAssignee',
            })}
            onChange={(value: any) => {
              setNewPoolAssignee(value?.key);
            }}
            data={newPoolUsersArray}
            loading={customerLoading}
            optionProps={{key: 'uuid', value: 'uuid', label: 'name'}}
            extraStyle={{flex: 1}}
          />
        </FormControl>

        {/* {!props.isEdit && (
          <FormControl>
            <Label>
              <DisplayText
                size={'mdNormal'}
                textLocalId={'taskPoolDefaultAssignee'}
              />
            </Label>

            <Select
              defaultValue={newPoolAssignee}
              value={newPoolAssignee}
              onChange={(selectedUser: any) => {
                setNewPoolAssignee(selectedUser);
              }}
            >
              <Select.Option value={'unassigned'}>Unassigned</Select.Option>
              {newPoolUsersArray.map((userItem: any) => {
                return (
                  <Select.Option value={userItem.uuid}>
                    {userItem.name}
                  </Select.Option>
                );
              })}
            </Select>
            <Text size={'smLight'} color="error.500">
              {errors?.newPoolSelectAssigneeError}
            </Text>
          </FormControl>
        )} */}
      </View>
    </Drawer>
  );
};

export default AddPoolModal;
