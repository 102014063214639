import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  swtichBtnStyle: {
    paddingHorizontal: 16,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
  },
});
