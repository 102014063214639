import {useLazyQuery} from '@apollo/client';
import {Modal} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {Checkbox, Skeleton, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getPatientsByNameAndBirthDate} from '../../../../../services/CommonService/AidBoxService';
import ConvertContacts from '../../../../../services/ConvertContacts/ConvertContacts';
import {Colors} from '../../../../../styles';
import { getBirthSexLabel, getGenderIdentityLabel, getResourceAbilities, isBirthSexPrimary } from '../../../../../utils/capabilityUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {EmptyStateSvg} from '../../../../common/Svg';
import { CapabilityResource } from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {
  getConvertContactFormattedData,
  getModalTitleByPersonType,
} from '../../ContactsUtils';
import {ContactDisplayCard} from '../../Visitor/ConvertContactsList/ContactDisplayCard';
import {
  getFormattedPatientData,
  getFormDataFromLeadData,
} from '../LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {styles} from './ConvertLeadListStyle';

export interface IConvertLeadList {
  contactData?: any;
  onSave: (contactId: string | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  personType: string;
  onCreatePatient: () => void;
  isChecked: (checked: boolean) => void;
}

// const {Content, Body, Header, CloseButton} = Modal;

const ConvertContactList = (props: IConvertLeadList) => {
  const {contactData, isOpen, onClose} = props;
  const [converLeadListState, setConverLeadListState] = useState({
    contactDataList: [] as any,
    searchLoading: false,
  });

  const setLoadingState = (loading: boolean) => [
    setConverLeadListState((prev) => {
      return {
        ...prev,
        searchLoading: loading,
      };
    }),
  ];

  const patientCapability = getResourceAbilities(CapabilityResource.patient);
  const isGenderIdentityHidden = patientCapability?.keyAllowedOperations?.gender?.isHidden || false;

  const mlovData = useContext(CommonDataContext);
  let formattedData = contactData.singleLeadData;
  if (!contactData?.singleLeadData?.firstName) {
    formattedData = getFormDataFromLeadData(
      contactData?.contactData?.singleLeadData || contactData?.singleLeadData,
      mlovData
    );
  }

  const getGenderData = () => {
    if (isGenderIdentityHidden || isBirthSexPrimary()) {
      return {
        label: getBirthSexLabel(),
        value: formattedData?.person?.sexAtBirth || formattedData?.sexAtBirth
      }
    }
    return {
      value: formattedData.genderName,
      label: getGenderIdentityLabel(),
    }
  }

  const [GetPatientList] = useLazyQuery(ConvertContacts.GetPatientList, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const getPatientsList = async () => {
    const patientsListResp = await GetPatientList({
      variables: {
        birthDate: formattedData.birthDate,
        firstName: formattedData.firstName,
        gender: formattedData.genderName,
      },
    });
    setConverLeadListState((prev) => {
      return {
        ...prev,
        contactDataList: getConvertContactFormattedData(
          patientsListResp?.data?.fhir?.PatientList || []
        ),
      };
    });
  };

  const getPatientListFromEHR = () => {
    setLoadingState(true);
    getPatientsByNameAndBirthDate({
      birthDate: formattedData?.birthDate,
      firstName: formattedData?.firstName,
      lastName: formattedData?.lastName,
      successHandler(response) {
        successHandler(response);
      },
      errorHandler(error) {
        errorHandler(error);
      },
    });
  };

  const successHandler = (response: any) => {
    setLoadingState(false);
    const patientList = getFormattedPatientData(response.data);

    setConverLeadListState((prev) => {
      return {
        ...prev,
        contactDataList: patientList,
      };
    });
  };

  const errorHandler = (error: any) => {
    setLoadingState(false);
    setConverLeadListState((prev) => {
      return {
        ...prev,
        contactDataList: [],
      };
    });

  };

  useEffect(() => {
    getPatientsList();
  }, [contactData?.id]);

  useEffect(() => {
    getPatientListFromEHR();
  }, []);

  return (
    <Modal
      open={isOpen}
      //  onClose={() => onClose()} style={styles.modalStyle}
      title={
        <DisplayText
          textType="Heading"
          textLocalId={getModalTitleByPersonType(props.personType)}
        />
      }
      onCancel={onClose}
      footer={false}
      style={{minWidth: 1000}}
    >
      <View style={styles.modalContent}>
        <View style={styles.modalBodyStyle}>
          <ContactDisplayCard
            contactData={{
              contactName: `${formattedData.firstName} ${formattedData.lastName}`,
              contactCity: formattedData.cityName,
              contactGender: getGenderData().value,
              contactGenderLabel: getGenderData().label,
              contactPostalCode: formattedData.zipCode,
              contactState: formattedData.stateName,
              contactId: formattedData.id,
            }}
            btnText="Create Patient"
            onBtnPress={() => {
              props.onCreatePatient();
            }}
          />

          <View style={styles.titleView}>
            <DisplayText
              textType="Heading"
              textLocalId="foundInEhr"
              extraStyles={{
                color: 'red',
              }}
            />
          </View>

          {converLeadListState.searchLoading && (
            <View
              style={{
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <Skeleton.Text lines={4} />
            </View>
          )}

          {converLeadListState?.contactDataList &&
          converLeadListState?.contactDataList?.length > 0 ? (
            converLeadListState?.contactDataList?.map((contact: any) => {
              return (
                <>
                  <ContactDisplayCard
                    contactData={{
                      contactName: `${contact.firstName} ${contact.lastName}`,
                      contactCity: contact.city,
                      contactGender: contact.gender,
                      contactState: contact.state,
                      contactPostalCode: contact.zipCode,
                      contactId: contact.id,
                    }}
                  />
                </>
              );
            })
          ) : (
            <View margin={'auto'} display={'flex'}>
              <EmptyStateSvg />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default ConvertContactList;
