import {ApolloError, useLazyQuery, useQuery} from '@apollo/client';
import {
  Box,
  Divider,
  HStack,
  Icon,
  Image,
  Skeleton,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {MLOV_CATEGORY} from '../../../constants';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
} from '../../../constants/Configs';
import {APPOINTMENT_PARTICIPANT_STATUS_CODES, APPOINTMENT_STATUS_CODES, APPOINTMENT_TYPE_CATEGORY_CODES, LOCATION_TYPE_CODES, MLOV_CODES} from '../../../constants/MlovConst';
import {AppointmentQueries, FormsQueries, MlovQueries} from '../../../services';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../styles';
import {
  getMlovByCategory,
  getMlovById,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import BrandingLogoWrapper from '../../common/BrandingLogoWrapper/BrandingLogoWrapper';
import {getCompletePracticeLocation} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {IParticipantData} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import {AppointmentDetailsWidget} from './AppointmentDetailsWidget';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { IAppointmentDetail } from '../../common/CalendarWidget/CalendarWidgetInterfaces';
import { IDefaultFormTheme } from '../../RightSideContainer/Forms/interfaces';
import { getConfigDataFromCode } from '../../../utils/commonUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IMlov } from '../../../Interfaces';

interface IProps {
  params: {
    appointmentId: string;
    participantId: string;
    accountId: string;
    numberAccountId?: string;
  };
}

interface IWidgetState {
  appointment?: IAppointmentDetail;
  appointmentLocation?: string;
  needRSVPAction?: boolean;
  isAppointmentInPast?: boolean;
  showSendResponseSuccessMsg?: boolean;
  isAccepted:boolean
  actionCode?: string;
  isAppointmentLoading?: boolean;
  appointmentLoadingError?: any
}

export function AppointmentRSVPWidget(props: IProps) {
  const [widgetState, setWidgetState] = useState<IWidgetState>({
    needRSVPAction: true,
    isAppointmentInPast: false,
    isAccepted: false,
    isAppointmentLoading: true
  });

  // const toast = useToast();
  // const intl = useIntl();

  const rawCareStudioMlovData = useQuery(
    MlovQueries.GetAllCareMlovsWithCategory,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        headers: {
          // 'x-hasura-admin-secret': props.params.accessToken,
          'account-uuid': props.params.accountId,
        },
      },
    }
  );

  const careStudioMlovData = getMlovByCategory(rawCareStudioMlovData.data);
  const mlovData = useContext(CommonDataContext);

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      careStudioMlovData,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const appointmentStatusList =
    getMlovListFromCategory(
      careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    booked: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.BOOKED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
  };

  useEffect(()=> {
    if(rawCareStudioMlovData.data && !widgetState.appointment && !widgetState.appointmentLoadingError){
      getAppointmentData();
    }
  }, [rawCareStudioMlovData.data])

  const [getAppointmentData] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENT_BY_ID,
    {
      variables: {appointmentId: props.params.appointmentId},
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        headers: {
          // 'x-hasura-admin-secret': props.params.accessToken,
          'account-uuid': props.params.accountId,
        },
      },
      onCompleted: onAppointmentGetSuccess,
      onError: onAppointmentGetError,
    }
  );

  const [getAppointmentLocation, locationAPIQuery] = useLazyQuery(
    UserPracticeLocationQueries.GET_USER_PRACTICE_LOCATIONS_BY_UUIDS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          // 'x-hasura-admin-secret': props.params.accessToken,
          'account-uuid': props.params.accountId,
        },
      },
    }
  );

  const currentParticipant = findParticipant(
    widgetState?.appointment?.participants,
    props.params.participantId
  );
  const isParticipantInviteDeleted =
    widgetState?.appointment?.appointmentType?.category?.code ===
      APPOINTMENT_TYPE_CATEGORY_CODES.GROUP_SESSION && !currentParticipant;
  if (!currentParticipant) {
    showError();
    // return <></>;
  }

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const isLoading = widgetState.isAppointmentLoading || locationAPIQuery.loading;
  const isApiError =
    !isLoading && (!!widgetState.appointmentLoadingError || !!locationAPIQuery.error);
  const apiError = isApiError
    ? widgetState.appointmentLoadingError || locationAPIQuery.error
    : undefined;
  const canShowAppointmentDetails =
    !isLoading &&
    !isApiError &&
    widgetState.appointment?.participants?.length &&
    currentParticipant &&
    careStudioMlovData;

  function onAppointmentGetSuccess(data: any) {
    if (
      data &&
      data.appointments &&
      data.appointments.length &&
      data.appointments[0].id === props.params.appointmentId
    ) {
      const appointment = data.appointments[0];

      setWidgetState((prevState) => ({...prevState, appointment: appointment, isAppointmentLoading: false}));
      setLocationName(appointment);

      const appointmentStatusId = appointment.statusId;
      const isAppointmentStatusPending =
        appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;
      const isAppointmentStatusDecline =
        appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;
      const isAppointmentStatusProposed =
        appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;
      const isAppointmentStatusBooked =
        appointmentStatusId === APPOINTMENT_STATUS_IDS.booked;

      const isGroupAppointment = appointment?.appointmentType?.category?.code === APPOINTMENT_TYPE_CATEGORY_CODES.GROUP_SESSION;
      const isAppointmentInPast = new Date(appointment?.endDateTime).getTime() < new Date()?.getTime();

      const needRSVPAction =
        (isGroupAppointment && isAppointmentStatusBooked) ||
        isAppointmentStatusPending ||
        isAppointmentStatusDecline ||
        isAppointmentStatusProposed;

      setWidgetState((prevState) => ({...prevState, needRSVPAction, isAppointmentInPast }));
    } else {
      setWidgetState((prevState) => ({...prevState, isAppointmentLoading: false}));
      showError();
    }
  }

  function setLocationName(appointment: IAppointmentDetail) {
    let locationTypeCode = appointment?.appointmentType?.locationType?.code;
    let locationTypeValue = appointment?.appointmentType?.locationType?.value;

    const locationTypeId = appointment?.locationTypeId;
    if (locationTypeId) {
      const locationType: IMlov = scheduleLocationTypeList.find((locationType) => { return locationType.id === locationTypeId; }) || ({} as IMlov);

      if (locationType?.code) {
        locationTypeCode = locationType.code;
        locationTypeValue = locationType.value;
      }
    }

    if (locationTypeCode === LOCATION_TYPE_CODES.VIRTUAL) {
      return setWidgetState((prevState) => ({
        ...prevState,
        appointmentLocation: locationTypeValue,
      }));
    }

    if (appointment.userPracticeLocationId) {
      getAppointmentLocation({
        variables: {
          userPracticeLocationIds: [appointment.userPracticeLocationId],
        },
      })
        .then((response) => {
          if (
            response?.data?.userPracticeLocations?.length &&
            response.data.userPracticeLocations[0] &&
            response.data.userPracticeLocations[0]?.accountLocation
              ?.practiceLocation?.name
          ) {
            const location = response.data.userPracticeLocations[0];
            let locationString = '';
            if (location?.accountLocation?.practiceLocation?.name) {
              locationString = getCompletePracticeLocation(
                location.accountLocation.practiceLocation
              );
              locationString = locationString
                ? `${locationString} (${location.accountLocation.practiceLocation.name})`
                : location.accountLocation.practiceLocation.name;
            }
            setWidgetState((prevState) => ({
              ...prevState,
              appointmentLocation: locationString,
            }));
          } else {
            setWidgetState((prevState) => ({
              ...prevState,
              appointmentLocation:
                appointment.appointmentType?.locationType?.value,
            }));
          }
        })
        .catch((error: ApolloError) => {
          showError();
          setWidgetState((prevState) => ({
            ...prevState,
            appointmentLocation:
              appointment.appointmentType?.locationType?.value,
          }));

        });
    } else {
      setWidgetState((prevState) => ({
        ...prevState,
        appointmentLocation: appointment.appointmentType?.locationType?.value,
      }));
    }
  }

  function onAppointmentGetError(error: ApolloError) {
    setWidgetState((prevState) => ({
      ...prevState,
      isAppointmentLoading: false,
      appointmentLoadingError: error
    }));
    showError();

  }

  function showError() {
    // showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  }

  function findParticipant(
    participants: IParticipantData[] | undefined,
    participantId?: string
  ) {
    if (!participants || !participants.length || !participantId)
      return undefined;
    return participants.find(
      (participant) => participant.id && participant.id === participantId
    );
  }


  const getSuccessMsg = () => {
    if(widgetState.actionCode === APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED){
      return 'RSVP Accepted – Thank you!';
    }
    if (
      widgetState.actionCode === APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ) {
      return 'RSVP Declined – Thank you!';
    }
    if (
      widgetState.actionCode ===
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    )
      return 'Sent Response Successfully';
  }

  const getIcon = () => {
    if (
      [
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION,
      ].includes(widgetState.actionCode as string)
    ) {
      return require('../../../assets/gifs/message_sent_read.gif');
    } else {
      return require('../../../assets/gifs/appt_declined.gif');
    }
  }

  return (
    <>
      <Box
        borderColor={Colors.Custom.BorderColor}
        borderRadius={8}
        rounded="lg"
        borderWidth={1}
        backgroundColor={'white'}
        // p="4"
        marginX={{base: 0, sm: '4%', md: '12%', lg: '20%', xl: '24%'}}
        marginY={{base: 0, lg: 8}}
      >
        <View
          backgroundColor={formThemeConfig?.topBar?.backgroundColor || 'white'}
          height={100}
          rounded={'xs'}
        >
          <View marginY={2}>
            <BrandingLogoWrapper />
          </View>
        </View>

        <Divider orientation="horizontal" />

        <View padding={{base: 4, sm: 2, md: 4, lg: 4, xl: 4}}>
          {isLoading && (
            <View>
              <Skeleton />
            </View>
          )}
          {isApiError && (
            <VStack alignItems="center" justifyContent="center" space={4}>
              <HStack space={8} alignItems="center" justifyContent="center">
                <View marginTop={1}>
                  <Icon
                    as={AntIcon}
                    name="closecircleo"
                    size="12"
                    color="error.500"
                  />
                </View>
                <View size="xsMedium" flex={1}>
                  <Text>{apiError}</Text>
                </View>
              </HStack>
            </VStack>
          )}
          {canShowAppointmentDetails &&
            widgetState.needRSVPAction &&
            !widgetState.isAppointmentInPast &&
            !widgetState.showSendResponseSuccessMsg && (
              <AppointmentDetailsWidget
                appointment={widgetState.appointment as IAppointmentDetail}
                appointmentLocation={widgetState.appointmentLocation}
                currentParticipantId={props.params.participantId}
                careStudioMlovData={careStudioMlovData}
                headers={props.params}
                onSendResponseSuccess={(code: string) => {
                  setWidgetState((prevState) => ({
                    ...prevState,
                    showSendResponseSuccessMsg: true,
                    actionCode: code
                  }));
                }}
              />
            )}

          {((canShowAppointmentDetails &&
            !widgetState.needRSVPAction &&
            !widgetState.isAppointmentInPast) ||
            isParticipantInviteDeleted) && (
              <VStack alignItems="center" justifyContent="center" space={4}>
                <HStack space={8} alignItems="center" justifyContent="center">
                  <View size="xsMedium" flex={1} marginTop={1}>
                    <Text>
                      This link is no longer valid or expired. The appointment
                      request may have changed/cancelled or you have already
                      taken an action for this appointment request
                    </Text>
                  </View>
                </HStack>
              </VStack>
            )}

          {canShowAppointmentDetails && widgetState.isAppointmentInPast && (
            <VStack alignItems="center" justifyContent="center" space={4}>
              <HStack space={8} alignItems="center" justifyContent="center">
                <View marginTop={1}>
                  <Icon
                    as={AntIcon}
                    name="closecircleo"
                    size="12"
                    color="error.500"
                  />
                </View>
                <View size="xsMedium" flex={1}>
                  <Text color="error.500">This link is no longer valid or expired.</Text>
                </View>
              </HStack>
            </VStack>
          )}

          {canShowAppointmentDetails &&
            widgetState.showSendResponseSuccessMsg && (
              <VStack alignItems="center" justifyContent="center" space={4}>
                <HStack space={8} alignItems="center" justifyContent="center">
                  <View marginTop={1}>
                  {widgetState?.isAccepted !== undefined ? (
                      <Image
                        size={24}
                        source={getIcon()}
                        alt="image"
                        key={Math.random()}
                      />
                    ) : (
                      <Icon
                        as={AntIcon}
                        name="checkcircleo"
                        size="12"
                        color="success.500"
                      />
                    )}
                  </View>
                  <View size="xsMedium" flex={1}>
                    <Text>{getSuccessMsg()}</Text>
                  </View>
                </HStack>
              </VStack>
            )}
          {!currentParticipant &&
            !isLoading &&
            !isApiError &&
            !widgetState.showSendResponseSuccessMsg &&
            !isParticipantInviteDeleted && (
              <VStack alignItems="center" justifyContent="center" space={4}>
                <HStack space={8} alignItems="center" justifyContent="center">
                  <View marginTop={1}>
                    <Icon
                      as={AntIcon}
                      name="closecircleo"
                      size="12"
                      color="error.500"
                    />
                  </View>
                  <View size="xsMedium" flex={1}>
                    <Text color="error.500">Something went wrong</Text>
                  </View>
                </HStack>
              </VStack>
            )}
        </View>

        <View
          mt={5}
          backgroundColor={formThemeConfig?.topBar?.backgroundColor || 'white'}
          height={10}
          rounded={'xs'}
        />
      </Box>
    </>
  );
}
