import React from "react";
import {Path, Svg} from "react-native-svg";
import {Colors} from "../../styles";

function CarePlanReviewIcon() {
  return (
    <Svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <Path
        stroke={Colors.Custom.GRAY350}
        strokeLinecap="round"
        d="M13.65 5.7l.386-.387a1.639 1.639 0 112.317 2.317l-.386.386M13.65 5.7s.048.82.772 1.545c.725.724 1.545.772 1.545.772M13.65 5.7L10.1 9.25c-.241.241-.361.362-.465.494a2.73 2.73 0 00-.312.505c-.072.152-.126.313-.233.636l-.456 1.367m7.333-4.235l-3.55 3.551c-.24.24-.361.36-.494.464a2.727 2.727 0 01-.504.312c-.152.072-.313.126-.636.234l-1.367.455m0 0l-.334.112a.442.442 0 01-.56-.56l.112-.333m.782.781l-.782-.781M5.167 9.833H7.25M5.167 6.5h5.416m-5.416 6.666h1.25m8.607-11.523c-.977-.976-2.548-.976-5.69-.976H7.666c-3.143 0-4.714 0-5.69.976C1 2.619 1 4.19 1 7.333v3.333c0 3.143 0 4.714.976 5.69.977.977 2.548.977 5.69.977h1.667c3.143 0 4.714 0 5.69-.976.787-.786.94-1.957.97-4.024"
      ></Path>
    </Svg>
  );
}

export default React.memo(CarePlanReviewIcon);
