import { Dimensions, View } from 'react-native'
import React, { useContext, useState } from 'react'
import { ITaskCategory, ITaskMetaData, MultiSelectAction, MultiSelectState } from '../../TaskInterfaces';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../utils/commonViewUtils';
import useTaskManager from '../../CustomHooks/useTaskManager';
import { TASK_DEFAULT_PAGE_SIZE } from '../../TaskModuleHelper';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { useLazyQuery } from '@apollo/client';
import { TaskQueries } from '../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { BottomViewAction } from '../../../TaskCard/TaskCardHelper';
import { ConfigProvider, Empty, Modal, Table, TableColumnsType, notification } from 'antd';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { FetchTaskState } from '../../../common/CareDashboard/CareDashboardConstants';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { isEmployerRole } from '../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getTaskTableViewColumns } from '../../TaskModuleUIHelper';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { Text, HStack, VStack } from 'native-base';
import './TaskTableView.css';
import SidePagination from '../../../SideCar/SidePagination';

interface ITaskTableViewProps {
  category: ITaskCategory;
  metaData: ITaskMetaData;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
}

const TaskTableView = (props: ITaskTableViewProps) => {
  const {category, metaData, onActionPerformed, onTaskDetail, onMemberClick} = props;
  const intl = useIntl();
  const isEmployer = isEmployerRole();
  const screenHeight = Dimensions.get('window').height;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const taskStatusMlov = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const taskPriorityMlov = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_PRIORITY) || [];
  const selectedTaskIds = metaData.selectedTasks?.map(item => item.id) || [];

  const onShowToast = (message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }
  // Task data manager
  const {
    loading,
    tasks,
    pagination,
    taskActionsInProgress,
    fetchTasks,
    handleTaskActions,
    handleMultiSelection,
    getMultiselectState,
  } = useTaskManager({
    filters: category.filters,
    categoryCode: category.code,
    boardType: metaData.boardType,
    pagination: {
      pageNumber: 1,
      pageSize: TASK_DEFAULT_PAGE_SIZE,
    },
    multiSelectData: {
      bulkActionEnabled: metaData.bulkTaskEnabled,
      selectedTasks: metaData.selectedTasks,
    },
    showToast: onShowToast,
    onSelectedTaskChange: metaData.onSelectedTaskChange,
  });

  // States
  const [deleteTaskData, setDeleteTaskData] = useState<{ showConfirmation: boolean, task?: ITask }>({
    showConfirmation: false,
    task: undefined,
  });

  // APIs
  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const onBottomViewAction = async (task: ITask, action: BottomViewAction, data?: any) => {
    switch (action) {
      case BottomViewAction.deleteTask:
        setDeleteTaskData((prev) => ({...prev, showConfirmation: true, task}));
        break;

      default:
        await handleTaskActions(task, action, data);
        break;
    }
  }

  const expandedColumns: TableColumnsType<any> = getTaskTableViewColumns(
    category.filters,
    {
      intl,
      userList: metaData.masterUsersList,
      boardType: metaData.boardType,
      userPool: metaData.masterPoolList,
      isEmployer,
      taskStatusMlov,
      taskPriorityMlov,
      taskActionsInProgress,
      selectedTasks: selectedTaskIds,
      multiSelectState: getMultiselectState(),
      bulkTaskEnabled: metaData.bulkTaskEnabled,
      sidecarContext: commonData.sidecarContext,
      panelType: metaData.panelType
    },
    {
      getSubTaskByIds,
      onBottomViewAction,
      onTaskDetail,
      onMemberClick,
      onShowToast,
      handleMultiSelection,
  });

  const onDeleteConfirm = async () => {
    const task = deleteTaskData.task;
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
    if (task) {
      await handleTaskActions(task, BottomViewAction.deleteTask, undefined);
    }
  }

  const onDeleteCancel = () => {
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
  }

  if (category.filters.searchString && !tasks?.length && !loading) {
    return (
      <Empty
        description={'No tasks found in search'}
      />
    )
  }

  return (
  <>
    {!tasks?.length && !loading ? (
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={'No tasks to show'}
          />
        )}
      >
        <Table
          columns={expandedColumns}
          size={'small'}
          dataSource={tasks}
          showHeader={!isSidecarContext}
          onChange={(_, filters, sorter, extra) => {
            if (extra?.action !== 'paginate') {
              onActionPerformed?.(COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER, { filters, sorter });
            }
          }}
          pagination={false}
          rowKey={(row) => row.id}
        />
      </ConfigProvider>
    ) : (
      <Table
        className="task-table-view"
        scroll={{ y: (screenHeight - 320) }}
        columns={expandedColumns}
        loading={loading}
        size={'small'}
        dataSource={tasks}
        rowKey={(row) => row.id as string}
        showHeader={!isSidecarContext}
        onChange={(_, filters, sorter, extra) => {
          if (extra?.action !== 'paginate') {
            onActionPerformed?.(COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER, { filters, sorter });
          }
        }}
        pagination={false}
        footer={() => (
          <View style={{padding:5}}>
            <SidePagination
              page={pagination.pageNumber}
              pageSize={TASK_DEFAULT_PAGE_SIZE}
              totalCount={pagination.total}
              handleChange={(pageNumber: number, pageSize: number) =>
                fetchTasks({ pageNumber, pageSize }, category.filters, { state: FetchTaskState.resetAndFetchFromPagination })
              }
            />
          </View>
        )}
      />
    )}
    {deleteTaskData.showConfirmation && (
      <Modal
        open={deleteTaskData.showConfirmation}
        footer={null}
        closable={false}
      >
        <VStack>
          <Text
            mb={1}
            fontSize={'xl'}
            fontWeight={'bold'}
          >{`Delete Task?`}</Text>
          <Text my={1} fontSize={'md'}>
            {
              deleteTaskData?.task?.resourceMap?.notes ? intl.formatMessage({id: 'deleteNoteLinkTaskConfirmation'}) : intl.formatMessage({id: 'deleteTaskConfirmation'})
            }
          </Text>
          <HStack mt={5} justifyContent={'flex-end'}>
            {[
              {
                textLocalId: 'cancel',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY,
                },
                onPress: onDeleteCancel,
              },
              {
                textLocalId: 'delete',
                buttonProps: {
                  variant: BUTTON_TYPE.PRIMARY,
                },
                onPress: onDeleteConfirm,
              },
            ].map((button: any, index: number) => {
                return (
                  <FoldButton
                    key={index + button.btnText}
                    nativeProps={{
                      variant: button?.buttonProps?.variant,
                      onPress: () => {
                        button?.onPress();
                      },
                      ml: 2,
                      isLoading: button?.loading,
                      isDisabled: button?.loading
                    }}
                    customProps={{
                      btnText: intl.formatMessage({id: button.textLocalId}),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                );
            })}
          </HStack>
        </VStack>
      </Modal>
    )}
    </>
  )
}

export default TaskTableView
