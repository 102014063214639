export const getLastTenDigit = (number: string) => {
  return number?.slice(-10);
};

export const getCreateContactQuery = (
  accountId: any,
  phoneNumber: any,
  contactTypeId: string,
  sourceText: string,
  accountUuid: string
) => {
  const object = {
    accountId,
    contactType: {
      data: {
        typeId: contactTypeId,
        contact: {
          data: {
            accountId,
            name: phoneNumber,
            phoneNumber: phoneNumber,
            source: sourceText,
            // personAddress: {
            //   data: [
            //     {
            //       accountId,
            //       cityId: zipCode.cityId,
            //       zipcodeId: zipCode.id,
            //       stateId: zipCode.stateId,
            //     },
            //   ],
            // },
            personContact: {
              data: [
                {
                  value: 'bodyData.email',
                  accountId,
                },
                {
                  value: phoneNumber,
                  accountId,
                },
              ],
            },
            contactPracticeLocations: {
              data: [
                {
                  accountUuid,
                },
              ],
            },
            // contactTagging: {
            //   data: contactTaggingArray,
            // },
          },
        },
      },
    },
    firstName: phoneNumber,
  };
  return object;
};

export const isUuid = (string: string) => {
  // Regular expression to check if string is a valid UUID
const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

return regexExp.test(string); // true
}
