import { SEARCH_TYPES } from '../../constants/FhirConstant';
import { INTEGRATION_BASE_URL } from '../../constants/BaseUrlConst';
import BaseService from './BaseService';
import LocalStorage from '../../utils/LocalStorage';

const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const userData = JSON.parse(userDataString);
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
    const accountUUID =
        userData?.data.accounts.length > 0
            ? userData?.data.accounts[0].uuid || ''
            : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
        'access-token': responseAccessToken,
        fold_access_token,
        accountUUID,
    };
    if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
        headersObj['form-widget-access-token'] = formWidgetAccessToken;
    }
    return headersObj;
};

export const getEhrList = async () => {
    const { axios } = BaseService.getSharedInstance();
    const headers = await getHeaders();
    const path = `${INTEGRATION_BASE_URL}/api/codes?searchType=${SEARCH_TYPES.EHR}`;
    return axios.get(path, { headers: headers });
};