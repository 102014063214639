import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Colors } from '../../../styles';
import { Divider, HStack, Input, Text, VStack, FormControl, Stack } from 'native-base';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../constants';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { DateTimeDurationInput } from '../DateTimeDurationInput/DateTimeDurationInput';
import { CaretDownOutlined } from '@ant-design/icons';
import EditIconSvg from '../Svg/InterventionSvg/EditIconSvg';
import { interventionType, interventiondurationMlovs } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';
import { getInterventionIcon, getInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/AddOrUpdateInterventionHelper';
import { IInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces';

interface IInterventionRowProps {
    disabled?: boolean;
    isInvalid?: boolean;
    onInterventionTypeChange: (value: string) => void
    onInterventionTitleChange: (value: string) => void
    onCancel: () => void
    onSaveIntervention: () => void
    onTaskEdit: (value: string) => void
    onDurationChange: (value: any) => void
    interventionTitle?: string
    selectedDuration: any
    metdataError: boolean
    selectedIntervention: string | undefined
    disableIntervention: boolean
}

interface IInterventionTypeState {
    loading: boolean;
    selectedInterventionType: string | undefined;
    interventionTitle: string;
    selectedDuration: { id: string, value: string };
}

const InterventionRow = (props: IInterventionRowProps) => {
    const [openType, setOpenType] = useState(false)
    const {
        onInterventionTypeChange,
        onInterventionTitleChange,
        onDurationChange,
        onCancel,
        onSaveIntervention,
        onTaskEdit,
        metdataError,
        disableIntervention,
        interventionTitle,
        selectedDuration,
        selectedIntervention
    } = props;
    const initialDuration = {
        id: 'day',
        value: '1'
    }
    const [componentState, setComponentState] = useState<IInterventionTypeState>({
        loading: false,
        selectedInterventionType: '',
        selectedDuration: initialDuration,
        interventionTitle: '',
    })
    const [errors, setErrors] = useState<{ interventionType: boolean, executionAfter: boolean, interventionTitle: boolean }>({
        interventionType: false,
        executionAfter: false,
        interventionTitle: false
    });
    const commonData = useContext(CommonDataContext);
    const [isInterventionError, setIsInterventionError] = useState('')
    const intl = useIntl();

    useEffect(() => {
        if (interventionTitle) {
            setComponentState((prev) => ({
                ...prev,
                interventionTitle: interventionTitle,
                selectedDuration: selectedDuration,
            }))
        }

    }, [interventionTitle, selectedDuration, selectedIntervention])

    useEffect(() => {
        if (selectedIntervention) {
            const selectedInterventionType: IInterventionType | undefined = getInterventionType(selectedIntervention)
            setComponentState((prev) => ({
                ...prev,
                selectedInterventionType: selectedInterventionType?.value || selectedIntervention
            }))
        }
    }, [interventionTitle, selectedDuration, selectedIntervention])

    useEffect(() => {
        if (metdataError) {
            handleSave(metdataError)
        }
    }, [metdataError])

    const handleSave = (metaDataError: boolean) => {
        const newErrors = {
            interventionType: componentState.selectedInterventionType && componentState.selectedInterventionType.length > 1 ? false : true,
            executionAfter: Number(componentState.selectedDuration.value) > 0 ? false : true,
            interventionTitle: componentState.interventionTitle.length > 1 ? false : true,
        };

        setErrors(newErrors);

        if (!newErrors.interventionType && !newErrors.executionAfter && !newErrors.interventionTitle && !metaDataError) {
            onSaveIntervention()
        } else {
            if (metaDataError) {
                setIsInterventionError('Type details are mandatory')
            } else {
                Object.values(newErrors).some((error) => error !== null) ? setIsInterventionError('All fields are mandatory') : setIsInterventionError('');
            }
        }
    };

    return (
        <VStack style={{ borderWidth: 1, borderRadius: 8, width: '97%', borderColor: Colors.FoldPixel.GRAY150, alignSelf: 'center', marginTop: 10 }}>
            <HStack w={'100%'} style={{ padding: 15, flex: 1, justifyContent: 'space-between' }}>
                <FormControl width={'20%'} isInvalid={errors.interventionType}>
                    <FormControl.Label isRequired={true}>Type</FormControl.Label>
                    <Select
                        value={componentState.selectedInterventionType || undefined}
                        placeholder={'Select Intervention Type'}
                        open={openType && !disableIntervention}
                        onChange={() => setOpenType(!openType)}
                        onClick={() => {
                            if (!disableIntervention) {
                                setOpenType(true)
                            }
                            if (disableIntervention && componentState.selectedInterventionType) {
                                onTaskEdit(componentState.selectedInterventionType)
                            }
                        }}
                        onBlur={() => setOpenType(!openType)}
                        suffixIcon={disableIntervention ? <EditIconSvg /> : <CaretDownOutlined onClick={() => setOpenType(!openType)} />}
                        onSelect={(value: any) => {
                            if (componentState.selectedInterventionType && value !== getInterventionType(componentState.selectedInterventionType)?.code) {
                                onInterventionTypeChange(value)
                                setOpenType(false);
                                setComponentState((prev) => ({
                                    ...prev,
                                    selectedInterventionType: value,
                                }))
                                setErrors((prev) => ({
                                    ...prev,
                                    interventionType: false
                                }));
                            } else {
                                onInterventionTypeChange(value)
                            }
                        }}
                        className={errors.interventionType ? 'field-error' : 'intervention-type'}
                    >
                        {interventionType.map((item: any, index: any) => (
                            <>
                                {item.code === 'internalTask' && <Divider />}
                                <Select.Option key={index} value={item?.code} >
                                    <HStack alignItems={'center'} h={'35px'}>
                                        {getInterventionIcon(item?.code)}
                                        <Text marginLeft={-3} textOverflow={'ellipsis'}>{item.value}</Text>
                                    </HStack>
                                </Select.Option>
                            </>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ width: '60%', paddingLeft: 15, paddingRight: 15 }} isInvalid={errors.interventionTitle}>
                    <FormControl.Label isRequired={true}> Title </FormControl.Label>
                    <Input
                        value={interventionTitle}
                        _focus={{ borderColor: '#D0D5DD' }}
                        width={'100%'}
                        onChangeText={(text: string) => {
                            setComponentState((prev) => ({
                                ...prev,
                                interventionTitle: text,
                            }))
                            onInterventionTitleChange(text)
                        }}
                        height={38}
                        background={'#FFFFFF'}
                        borderRadius={4}
                        textAlign={'left'}
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={20}
                        paddingX={0}
                        placeholder='Enter the Intervention Title' />
                </FormControl>

                <FormControl width={'20%'} isInvalid={errors.executionAfter}>
                    <FormControl.Label isRequired={true}>Execute After</FormControl.Label>
                    <DateTimeDurationInput
                        durationUnits={interventiondurationMlovs}
                        borderColor={errors.executionAfter ? Colors.Custom.ErrorColor : Colors.Custom.Gray300}
                        borderWidth={1}
                        inputFieldWidth={50}
                        suffixIcon={<CaretDownOutlined />}
                        value={selectedDuration.value || undefined}
                        placeholder={"Enter the time"}
                        selectedDurationUnitId={selectedDuration.unit}
                        onValueChange={(value) => {
                            onDurationChange((prev: any) => ({
                                ...prev,
                                value
                            }))
                        }}
                        onDurationUnitChange={(id) => {
                            onDurationChange((prev: any) => ({
                                ...prev,
                                unit: id
                            }))
                        }}
                    />
                </FormControl>
            </HStack>

            {isInterventionError && <Text style={{
                color: Colors.Custom.ErrorColor,
                fontWeight: '500',
                paddingLeft: 15
            }}>{isInterventionError}</Text>}
            <Stack direction='row' style={{ justifyContent: 'flex-end' }} padding={15}>
                <FoldButton
                    nativeProps={{
                        variant: BUTTON_TYPE.SECONDARY,
                        style: { margin: 3 },
                        onPress: onCancel
                    }}
                    customProps={{
                        btnText: intl.formatMessage({
                            id: 'cancel',
                        }),
                        withRightBorder: false,
                    }}
                />
                <FoldButton
                    nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: { margin: 3 },
                        onPress: () => handleSave(false),
                    }}
                    customProps={{
                        btnText: intl.formatMessage({
                            id: 'save',
                        }),
                        withRightBorder: false,
                    }}
                />
            </Stack>
        </VStack>
    );
};

export default InterventionRow;
