import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  tabStyles: {
    // paddingHorizontal: 12,
    // paddingBottom: 20,
    // paddingTop:12,
    // height: 36,
    // justifyContent: 'center',
    // marginRight: 8,
    // alignItems: 'center',
    // fontSize: 14,
    // fontWeight: 500,
    paddingHorizontal: 12,
    paddingVertical: 10,
    // paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
  },
  tabsContainer: {
    marginBottom: 12,
  },
});
