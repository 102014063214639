import {Tag, Tooltip} from 'antd';
import {HStack, VStack, useToast} from 'native-base';
import React from 'react';
import {Text, View} from 'react-native';
import {styles} from './CommunicationTypesStyles';
import {IMessageCommunicationTypesProps} from './interfaces';
import {removeMessageCommunicationType} from './CommunicationTypesUtils';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {useIntl} from 'react-intl';
import { testID } from '../../../../../testUtils';

const MessageCommunicationTypes = (props: IMessageCommunicationTypesProps) => {
  const {types, isMessageBox, messageUuid, onRemoveCommunicationType} = props;
  const firstType = types?.[0]?.name || '';
  const totalRemainingTypes = types?.length ? types.length - 1 : 0;
  const remainingTypes = totalRemainingTypes > 0 ? types.slice(1) : [];
  const toast = useToast();
  const intl = useIntl();
  const [state, setState] = React.useState({
    loading: false,
  });

  const getContainerStyles = () => {
    if (!isMessageBox) {
      return styles.messageCommunicationTypeWrapper;
    }
    return {};
  };

  const onRemoveType = async (communicationTypeId: string) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await removeMessageCommunicationType({
        communicationTypeId: communicationTypeId,
        messageUuid: messageUuid || '',
      });
      showToast(
        toast,
        intl.formatMessage({id: 'removeCommunicationTypeSuccess'}),
        ToastType.success
      );
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      if (onRemoveCommunicationType) {
        onRemoveCommunicationType(communicationTypeId);
      }
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'removeCommunicationTypeError'}),
        ToastType.success
      );
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  return (
    <HStack space={'2'} style={{marginLeft: 10}} {...testID('MessageCommunicationTypesView')}>
      {types?.length !== 0 ? (
        <>
          <View style={getContainerStyles()} {...testID('MessageCommunicationTypesTagName')}>
            <Tag
              closable
              closeIcon={<MaterialCommunityIcons name="close" {...testID('image')}/>}
              onClose={() => {
                onRemoveType(types[0]?.id || '');
              }}
            >
              {firstType.slice(0, 30).trim()}
            </Tag>
          </View>
          {totalRemainingTypes > 0 && (
            <View style={getContainerStyles()}>
              <Tooltip
                color="white"
                placement="left"
                overlayInnerStyle={{
                  width: 'max-content',
                  backgroundColor: 'white',
                }}
                title={
                  <VStack space={'2'} style={{}}>
                    {remainingTypes.map((item) => {
                      return (
                        <View {...testID('MessageCommunicationTypesTagName')} key={item.id} style={getContainerStyles()}>
                          <Tag
                            closable
                            onClose={() => {
                              onRemoveType(item.id || '');
                            }}
                          >
                            {item.name}
                          </Tag>
                        </View>
                      );
                    })}
                  </VStack>
                }
              >
                <Text {...testID('MessageCommunicationTypesTagRemaining')}>{`+${totalRemainingTypes}`}</Text>
              </Tooltip>
            </View>
          )}
        </>
      ) : (
        <></>
      )}
    </HStack>
  );
};

export default MessageCommunicationTypes;
