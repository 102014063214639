import React from 'react';
import {Pressable, View} from 'native-base';
import FeedDataCollapse from '../FeedCollapse/FeedDataCollapse';
import {IFeedData} from '../interface';
import {Colors} from '../../../../../../../../styles';
import {FEED_CLICKABLE_RESOURCE_TYPE_LIST} from '../FeedConst';



const SingleTimelineCard = (props: {
  singleFeedData: IFeedData,
  firstEntryID: string,
  onHeaderClick: (actionCode: string, singleFeedData: IFeedData) => void;
}) => {
  const {singleFeedData,firstEntryID, onHeaderClick} = props;
  return singleFeedData ? (
    <View>
      {singleFeedData?.id && (
        <View 
        // shadow={'2'} style={{
        //   borderRadius: 10,
        //   borderWidth: 1,
        //   borderColor: Colors.Custom.BorderColor,
        // }}
        >
          <FeedDataCollapse
            singleFeedData={singleFeedData}
            firstEntryID={firstEntryID}
            onHeaderClick={(actionCode, singleFeedData) => {
              onHeaderClick(actionCode, singleFeedData);
            }}
          />
        </View>
      )}
    </View>
  ) : (
    <></>
  );
};

export default React.memo(SingleTimelineCard);
