import { Utils as QbUtils } from '@react-awesome-query-builder/antd';
import { DATE_FORMATS, PERSON_TYPES } from '../../../../../constants';
import { ICommonData } from '../../../../../context/CommonDataContext';
import { Vital } from '../../../../../utils/VitalUtils';
import { CONTACT_TYPE_CODES } from '../../../TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { ReturnOperator, OperatorCase } from './QueryBuilderConstants';
import { SUPPORTED_WEARABLES } from '../constants';
import { getMomentObjFromDateStrAndFormat } from '../../../../../utils/DateUtils';

interface IRuleProp {
  fact: string;
  operator: string;
  value: string | string[];
}

const getOperator = (opr: string) => {
  switch (opr) {
    case OperatorCase.MULTISELECT_CONTAINS:
    case OperatorCase.MULTISELECT_EQUALS:
      return ReturnOperator.IN; // in - all
    case OperatorCase.MULTISELECT_NOT_CONTAINS:
    case OperatorCase.MULTISELECT_NOT_EQUALS:
      return ReturnOperator.NOT_IN; // not_in -- any
    case OperatorCase.LESS:
      return ReturnOperator.LESS_THAN;
    case OperatorCase.GREATER:
      return ReturnOperator.GREATER_THAN;
    case OperatorCase.GREATER_OR_EQUAL:
      return ReturnOperator.GREATER_THAN_INCLUSIVE;
    case OperatorCase.LESS_OR_EQUAL:
      return ReturnOperator.LESS_THAN_INCLUSIVE;
    case OperatorCase.EQUAL:
    case OperatorCase.SELECT_EQUALS:
      return ReturnOperator.EQUAL;
    case OperatorCase.NOT_EQUAL:
    case OperatorCase.SELECT_NOT_EQUALS:
      return ReturnOperator.NOT_EQUAL;
    case OperatorCase.LIKE:
      return ReturnOperator.LIKE;
    case OperatorCase.NOT_LIKE:
      return ReturnOperator.NOT_LIKE;
    default:
      return opr;
  }
};

const getOperatorForCareTeam = (opr: string) => {
  switch (opr) {
    case 'multiselect_contains':
    case 'multiselect_equals':
      return 'equal';
    case 'multiselect_not_contains':
    case 'multiselect_not_equals':
      return 'notEqual';
    default:
      return opr;
  }
}

const getParamCondition = (condition: string) => {
  switch (condition) {
    case OperatorCase.MULTISELECT_EQUALS:
    case OperatorCase.MULTISELECT_NOT_EQUALS:
      return ReturnOperator.ALL;
    case OperatorCase.MULTISELECT_CONTAINS:
    case OperatorCase.MULTISELECT_NOT_CONTAINS:
      return ReturnOperator.ANY;

    default:
      return '';
  }
};

const getFieldRule = (jsonTree: any, children?: any[]) => {
  //   const rule: IRuleProp = {
  //     fact: '',
  //     operator: '',
  //     value: '',
  //   };
  //   rule.fact = jsonTree?.properties?.field;
  //   rule.operator = getOperator(jsonTree?.properties?.operator);
  //   rule.value = jsonTree?.properties?.value;
  //   return rule;
  const asyncListValues = jsonTree.asyncListValues || [];
  asyncListValues.forEach((item: any) => {
    if (!item?.title) {
      item.title = item.children;
    }
  });

  let result;
  switch (jsonTree?.field) {
    case 'employerId':
      const isOperatorNull = jsonTree?.operator === OperatorCase.IS_NULL;
      const isOperatorNotNull = jsonTree?.operator === OperatorCase.IS_NOT_NULL;
      if ((jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) || isOperatorNull || isOperatorNotNull) {
        result = {
          fact: 'employerId',
          operator: isOperatorNull ? ReturnOperator.EQUAL : isOperatorNotNull ? ReturnOperator.NOT_EQUAL : getOperator(jsonTree?.operator),
          value: (isOperatorNull || isOperatorNotNull) ? null : jsonTree?.value[0],
        };
      }
      break;

    case 'sexAtBirth':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        result = {
          fact: 'sexAtBirth',
          operator: getOperator(jsonTree?.operator),
          value: jsonTree?.value[0],
        };
      }
      break;
    case 'hasZipInGivenList':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        result = {
          fact: 'hasZipInGivenList',
          operator: 'equal',
          value: jsonTree?.operator === 'multiselect_contains',
          params: {
            zipcodeList: jsonTree?.value[0],
            condition: getParamCondition(jsonTree?.operator),
          },
        };
      }
      break;
    case 'age':
      const operator = getOperator(jsonTree?.operator);
      let value;
      let valueBetween;
      if (operator?.toLowerCase()?.indexOf('between') != -1) {
        valueBetween = {
          from: jsonTree?.value?.[0],
          to: jsonTree?.value?.[1]
        }
      } else {
        value = jsonTree?.value?.[0];
      }
      if (value || valueBetween) {
        result = {
          fact: 'age',
          operator: !!valueBetween ? 'equal' : operator,
          value: !!valueBetween ? true : value,
          ...(!!valueBetween && {
            params: {
              operator: 'between',
              valueBetween: valueBetween
            }
          })
        };
      }
      break;
    case 'contactTypeId':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        result = {
          fact: 'contactTypeId',
          operator: 'equal',
          value: true,
          params: {
            contactTypes: jsonTree?.value[0],
            condition: getParamCondition(jsonTree?.operator),
          },
        };
      }
      break;
    case 'hasTags':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        result = {
          fact: 'hasTags',
          operator: 'equal',
          value: jsonTree?.operator === 'multiselect_equals' || jsonTree?.operator === 'multiselect_contains',
          params: {
            tags: jsonTree?.value[0],
            condition: getParamCondition(jsonTree?.operator),
          },
        };
      }
      break;
    case 'practitioner':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        const operator = getOperator(jsonTree?.operator);
        result = {
          fact: 'practitioner',
          operator: operator,
          value: jsonTree?.value[0],
        };
      }
      break;
    case 'state':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        const operator = getOperator(jsonTree?.operator);
        result = {
          fact: 'state',
          operator: operator,
          value: jsonTree?.value[0],
        };
      }
      break;
    case 'source':
      result = getSourceRule(jsonTree);
      break;
    case 'genderIdentity':
      result = getGenderIdentityRule(jsonTree);
      break;
    case 'hasMembershipStatus':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        const operator = getOperator(jsonTree?.operator);
        result = {
          fact: 'hasMembershipStatus',
          operator: 'equal',
          value: operator === 'in',
          params: {
            value: jsonTree?.value?.[0],
            condition: 'any',
          },
        };
      }
      break;
    case 'form':
      result = getFormRule(jsonTree, children);
      break;
    case 'hasAppointment':
      result = getAppointmentRule(jsonTree, children);
      break;
    case 'hasLastVitalValue':
      result = getVitalRule(jsonTree, children);
      break;
    case 'hasCondition':
      result = getProblemRule(jsonTree, children);
      break;
    case 'hasFamilyHistory':
      result = getFamilyHistoryRule(jsonTree, children);
      break;
    case 'hasDiagnosis':
      result = getDiagnosisRule(jsonTree, children);
      break;
    case 'onMedication':
      result = getMedicationRule(jsonTree, children);
      break;
    case 'hasLabResult':
      result = getLabResultRule(jsonTree, children);
      break;
    case 'hasProcedure':
      result = getProcedureRule(jsonTree, children);
      break;
    case 'engagementOfCare':
      result = getPatientEngagementRule(jsonTree, children);
      break;
    case 'hasImmunization':
      result = getImmunizationRule(jsonTree, children);
      break;
    case 'hasMedicationStatement':
      result = getMedicationStatementRule(jsonTree, children);
      break;
    case 'hasRADOrder':
      result = getRadOrderRule(jsonTree, children);
      break;
    case 'hasPastMembershipStatus':
      result = getPastMembershipRule(jsonTree, children);
      break;
    case 'hasLocation':
      if (jsonTree?.value && jsonTree?.value?.[0] && jsonTree?.value[0]?.length) {
        const operator = getOperator(jsonTree?.operator);
        result = {
          fact: 'hasLocation',
          operator: operator,
          value: jsonTree?.value[0],
        }
      }
      break;
    case 'hasCareTeam':
      if (jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length) {
        const operator = getOperatorForCareTeam(jsonTree?.operator);
        result = {
          fact: 'hasCareTeamMembers',
          operator: operator,
          value: true,
          params: {
            userIds: jsonTree?.value?.[0],
            condition: getParamCondition(jsonTree?.operator),
          }
        };
      }
      break;
    case 'hasFoldMembershipStatus':
      if (jsonTree?.value && jsonTree.value?.[0] && jsonTree.value[0]?.length) {
        const operator = getOperator(jsonTree?.operator);
        result = {
          fact: 'hasFoldMembershipStatus',
          operator: operator,
          value: true,
          params: {
            value: [jsonTree.value[0]],
            condition: "all"
          }
        };
      }
      break;
    case 'wearable':
      result = getWearableRule(jsonTree, children);
      break;
    case 'customEHRAttributes':
      result = getCustomEHRAttributesRule(jsonTree, children);
      break;
    default:
      break;
  }
  return result;
};

export const getCustomEHRAttributesRule = (jsonTree: any, children?: any[]) => {
  let result: any;
  const subRules = children || [];
  const fields: any[] = [];

  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const condition = getParamCondition(subtree?.operator);
    const isValueAvailable = Array.isArray(subtree?.value) ? subtree?.value.length > 0 : subtree?.value !== undefined;
    if (isValueAvailable) {
      const fieldKey = subtree?.field?.replace('customEHRAttributes.', '');
      let valueEntry: any;

      if (subtree?.operator?.includes('between')) {
        valueEntry = {
          valueBetween: {
            low:
              subtree?.valueType[0] === 'date'
                ? getMomentObjFromDateStrAndFormat(
                    subtree?.value?.[0],
                    DATE_FORMATS.DISPLAY_DATE_FORMAT
                  ).toISOString()
                : subtree?.value?.[0],
            high:
              subtree?.valueType[0] === 'date'
                ? getMomentObjFromDateStrAndFormat(
                    subtree?.value?.[1],
                    DATE_FORMATS.DISPLAY_DATE_FORMAT
                  ).toISOString()
                : subtree?.value?.[1],
          },
        };
      } else {
        valueEntry = {
          value:
            subtree?.valueType[0] === 'date'
              ? getMomentObjFromDateStrAndFormat(
                  subtree?.value?.[0],
                  DATE_FORMATS.DISPLAY_DATE_FORMAT
                ).toISOString()
              : subtree?.value?.[0],
        };
      }
      if (subtree?.valueType[0] === 'multiselect') {
        valueEntry.condition = condition;
        valueEntry.type = "choice";
      }
      else {
        valueEntry.type = subtree?.valueType[0];
      }
      const fieldEntry = {
        field: fieldKey,
        operator: operator,
        ...valueEntry,
      };
      fields.push(fieldEntry);
    }
  });
  if (fields.length > 0) {
    result = {
      fact: 'patientCustomField',
      operator: 'equal',
      value: true,
      params: {
        fields: fields,
      },
    };
  }
  return result;
};

export const getWearableRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const repeats: any = {};
  const timeRange: any = {};
  const valueQuantity: any = {};
  let category = 'vital-signs';
  let vitalCode = '';
  let vitalSubCode = '';
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isValueAvailable = subtree?.operator?.includes('between') ? subtree?.value?.length > 1 : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (['wearable.name'].includes(subtree?.field)) {
        vitalCode = subtree?.value[0];
        const vitalConfig = SUPPORTED_WEARABLES.find(item => item.loinc === vitalCode);
        if (vitalConfig) {
          category = vitalConfig.category;
          vitalCode = vitalConfig.parentLoinc ?? vitalConfig.loinc;
          if (vitalConfig.parentLoinc) {
            vitalSubCode = vitalConfig.loinc;
          }
        }
      } else if (['wearable.value'].includes(subtree?.field)) {
        repeats.type = 'any';
        valueQuantity.operator = operator;
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.averageValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.increasedByValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'increase';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.increasedByPercentageValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'increase';
        valueQuantity.unit = 'pct';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.decreasedByValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'decrease';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.decreasedByPercentageValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'decrease';
        valueQuantity.unit = 'pct';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.deviatedByValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'vary';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.deviatedByPercentageValue'].includes(subtree?.field)) {
        repeats.type = 'average';
        valueQuantity.operator = operator;
        valueQuantity.variance = 'vary';
        valueQuantity.unit = 'pct';
        valueQuantity.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.lastConsecutiveDays'].includes(subtree?.field)) {
        repeats.operator = operator;
        repeats.unit = 'days';
        repeats.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.lastConsecutiveWeeks'].includes(subtree?.field)) {
        repeats.operator = operator;
        repeats.unit = 'weeks';
        repeats.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.lastRecording'].includes(subtree?.field)) {
        repeats.operator = operator;
        repeats.unit = 'recordings';
        repeats.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      } else if (['wearable.betweenTimeRange'].includes(subtree?.field)) {
        repeats.operator = operator;
        if (subtree?.value?.[0]) {
          const momentData = getMomentObjFromDateStrAndFormat(subtree?.value?.[0], DATE_FORMATS.API_TIME_FORMAT);
          timeRange.from = {
            hour: parseInt(momentData.format('HH')),
            minute: parseInt(momentData.format('mm')),
          }
        }
        if (subtree?.value?.[1]) {
          const momentData = getMomentObjFromDateStrAndFormat(subtree?.value?.[1], DATE_FORMATS.API_TIME_FORMAT);
          timeRange.to = {
            hour: parseInt(momentData.format('HH')),
            minute: parseInt(momentData.format('mm')),
          }
        }
      }
    }
  });
  if (vitalCode && valueQuantity.value) {
    result = {
      fact: 'wearable',
      operator: 'equal',
      value: true,
      params: {
        code: vitalCode,
        category,
        ...(vitalSubCode && { subcode: vitalSubCode }),
        ...(valueQuantity.value && { valueQuantity }),
        ...(timeRange.from && { timeRange }),
        ...(repeats.value && { repeats }),
      }
    };
  }
  return result;
}

export const getRadOrderRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasRADOrder.name'].includes(subtree?.field)) {
      item.condition = operator === 'in';
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0]?.map((code: string) => {
          return { code: code }
        });
      }
    } else if (['hasRADOrder.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'hasRADOrder',
      operator: 'equal',
      value: item.condition,
      params: {
        codes: item.value,
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getMedicationStatementRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasMedicationStatement.name'].includes(subtree?.field)) {
      item.condition = operator === 'in';
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
      }
    } else if (['hasMedicationStatement.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    } else if (['hasMedicationStatement.status'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.status = subtree?.value[0];
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'hasMedicationStatement',
      operator: 'equal',
      value: item.condition,
      params: {
        codeGroups: item.value,
        ...(item.status && { status: item.status }),
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getLabResultRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const labValue: any = {};
  const lab: any = {};
  const onset: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isValueAvailable = subtree?.operator?.includes('between') ? subtree?.value?.length > 1 :  subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (['hasLabResult.name'].includes(subtree?.field)) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        lab.value = subtree?.value[0];
      } else if (['hasLabResult.value'].includes(subtree?.field)) {
        labValue.operator = operator;
        labValue.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      }
      // --- kept for future reference ---
      // else if (['hasLabResult.onset'].includes(subtree?.field)) {
      //   onset.operator = operator;
      //   onset.value = subtree?.value[0];
      //   onset.unit = 'day';
      // }
    }
  });
  if (lab.value && labValue.operator) {
    result = {
      fact: 'hasLabResult',
      operator: 'equal',
      value: true,
      params: {
        code: lab.value,
        ...(onset.operator && { onset: onset }),
        valueQuantity: {
          operator: labValue.operator,
          value: labValue.value,
        },
      }
    };
  }
  return result;
}

export const getProcedureRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasProcedure.name'].includes(subtree?.field)) {
      item.condition = operator === 'in';
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
      }
    } else if (['hasProcedure.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'hasProcedure',
      operator: 'equal',
      value: item.condition,
      params: {
        codeGroups: item.value,
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getPatientEngagementRule = (jsonTree: any, children?: any[]) => {
  let result;
  const engagement = {
    value: true,
    operator: 'equal',
  };

  const subRules = children || [];
  const engagedInPast: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isValueAvailable = subtree?.operator?.includes('between')
      ? subtree?.value?.length > 1
      : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (
        [
          'engagementOfCare.engagedInPast',
          'engagementOfCare.notEngagedInPast',
        ].includes(subtree?.field)
      ) {
        engagedInPast.operator = operator;
        engagedInPast.value = subtree?.value[0];
        engagedInPast.unit = 'day';
        engagement.value = subtree?.field === 'engagementOfCare.notEngagedInPast' ? false : true;
      }
    }
  });
  if (engagedInPast.operator) {
    result = {
      fact: 'engagementOfCare',
      operator: engagement.operator,
      value: engagement.value,
      params: {
        last: engagedInPast,
      },
    };
  }
  return result;
}

export const getProblemRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  let nameOperatorValue = ''
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasCondition.name'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
        nameOperatorValue = subtree.operator
      }
    } else if (['hasCondition.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    } else if (['hasCondition.status'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.status = subtree?.value[0];
      }
    }

  });
  if (item.value) {
    result = {
      fact: 'hasCondition',
      operator: 'equal',
      value: nameOperatorValue === 'multiselect_equals',
      params: {
        codeGroups: item.value,
        category: 'problem-list-item',
        ...(item.status && { status: item.status }),
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getFamilyHistoryRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const item = {
    value: {
      codeGroups: [],
      codeGroupsOperator: '',
    },
    relationship: [],
  };
  const onsetAge: any = {};
  let hasAnyRelationship = false;
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasFamilyHistory.condition'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
      }
      item.value.codeGroups = subtree?.value[0];
      item.value.codeGroupsOperator = operator
    } else if (['hasFamilyHistory.relationship'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        hasAnyRelationship = subtree?.value[0].includes('any');
        item.relationship = subtree?.value[0];
      }
    } else if (['hasFamilyHistory.onsetAge'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.operator?.includes('between')
        ? subtree?.value?.length > 1
        : subtree?.value?.length > 0;
      if (isValueAvailable) {
        if (operator?.toLowerCase()?.indexOf('between') != -1) {
          onsetAge.valueBetween = {
            from: subtree?.value?.[0],
            to: subtree?.value?.[1]
          }
        } else {
          onsetAge.value = subtree?.value?.[0];
        }
        onsetAge.operator = operator;
      }
    }
  });
  if (item.value) {
    if (hasAnyRelationship && !item.value.codeGroups) {
      return null;
    }
    result = {
      fact: 'hasFamilyMemberHistory',
      operator: 'equal',
      value: true,
      params: {
        ...(item.value.codeGroups && {
          codeGroups: item.value.codeGroups,
          codeGroupsOperator: item.value.codeGroupsOperator
        }),
        ...(!hasAnyRelationship && item.relationship && { relationship: item.relationship }),
        ...(onsetAge.operator && { onsetAge: onsetAge }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getDiagnosisRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  let nameOperatorValue = ''
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasDiagnosis.name'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        nameOperatorValue = subtree.operator
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
      }
    } else if (['hasDiagnosis.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'hasCondition',
      operator: 'equal',
      value: nameOperatorValue === 'multiselect_equals',
      params: {
        codeGroups: item.value,
        category: 'encounter-diagnosis',
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getImmunizationRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  let nameOperatorValue = ''
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['hasImmunization.name'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
        nameOperatorValue = subtree.operator
      }
    } else if (['hasImmunization.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'hasImmunization',
      operator: 'equal',
      value: nameOperatorValue === 'multiselect_equals',
      params: {
        codeGroups: item.value,
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getMedicationRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const onset: any = {};
  const item: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    if (['onMedication.name'].includes(subtree?.field)) {
      item.condition = operator === 'in';
      const isValueAvailable = subtree?.value?.[0]?.length;
      if (isValueAvailable) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        item.value = subtree?.value[0];
      }
    } else if (['onMedication.onset'].includes(subtree?.field)) {
      const isValueAvailable = subtree?.value?.length;
      if (isValueAvailable) {
        onset.operator = operator;
        onset.value = subtree?.value[0];
        onset.unit = 'day';
      }
    }
  });
  if (item.value) {
    result = {
      fact: 'onMedication',
      operator: 'equal',
      value: item.condition,
      params: {
        codeGroups: item.value,
        ...(onset.operator && { onset: onset }),
        condition: 'any',
      },
    };
  }
  return result;
}

export const getFormRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const formResponse: any = {};
  const formScore: any = {};
  const form: any = {};
  let isNotOperation = false;
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isValueAvailable = subtree?.operator?.includes('between')
      ? subtree?.value?.length > 1
      : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (['form.name'].includes(subtree?.field)) {
        const asyncListValues = subtree.asyncListValues || [];
        asyncListValues.forEach((item: any) => {
          item.title = item.children;
        });
        form.value = subtree?.value[0];
      } else if (
        ['form.submitted', 'form.notSubmitted'].includes(subtree?.field)
      ) {
        isNotOperation = subtree?.field === 'form.notSubmitted';
        formResponse.operator = operator;
        formResponse.value = subtree?.value[0];
        formResponse.unit = 'day';
      } else if (['form.score'].includes(subtree?.field)) {
        formScore.operator = operator;
        formScore.value = subtree?.operator?.includes('between')
          ? {
            low: subtree?.value?.[0],
            high: subtree?.value?.[1],
          }
          : subtree?.value[0];
      }
    }
  });
  if (formResponse.operator || formScore.operator) {
    result = {
      fact: 'form',
      operator: 'equal',
      value: !isNotOperation,
      params: {
        ...(form.value && { formId: form.value }),
        ...(formResponse.operator && { response: formResponse }),
        ...(formScore.operator && { score: formScore }),
      },
    };
  }
  return result;
};

export const getPastMembershipRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const inBetween: any = {};

  function setBetweenData(subtree: any, isPastRule: boolean) {
    const isBetweenOperator = subtree?.operator?.includes('between');
    if (isBetweenOperator && subtree?.value?.length > 1) {
      inBetween.operator = 'between';
      const type = isPastRule ? 'last' : 'upcoming';
      inBetween.from = {
        type: type,
        value: isPastRule ? subtree?.value?.[1] : subtree?.value?.[0],
        unit: 'day',
      }
      inBetween.to = {
        type: type,
        value: isPastRule ? subtree?.value?.[0] : subtree?.value?.[1],
        unit: 'day',
      }
    }
  }

  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const isBetweenOperator = subtree?.operator?.includes('between');
    const isValueAvailable = isBetweenOperator
      ? subtree?.value?.length > 1
      : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (
        [
          'hasPastMembershipStatus.active',
          'hasPastMembershipStatus.inactive',
        ].includes(subtree?.field)
      ) {
        inBetween.value = subtree?.field === 'hasPastMembershipStatus.active' ? ['active'] : ['inactive'];
        setBetweenData(subtree, true);
      }
    }
  });
  if (inBetween.value || inBetween.operator) {
    result = {
      fact: 'hasPastMembershipStatus',
      operator: 'equal',
      value: true,
      params: {
        value: inBetween.value,
        between: {from: inBetween.from, to: inBetween.to}
      },
    };
  }
  return result;
};

export const getAppointmentRule = (jsonTree: any, children?: any[]) => {
  let result;
  const appointment: any = {
    value: 0,
    operator: undefined,
  };

  const subRules = children || [];
  const scheduledInPast: any = {};
  const scheduledInFuture: any = {};
  const scheduledInBetween: any = {};
  const appointmentTypes: any = {};
  const appointmentStatus: any = {};

  function setBetweenData(subtree: any, isPastRule: boolean) {
    const isBetweenOperator = subtree?.operator?.includes('between');
    if (isBetweenOperator && subtree?.value?.length > 1) {
      scheduledInBetween.operator = 'between';
      const type = isPastRule ? 'last' : 'upcoming';
      scheduledInBetween.from = {
        type: type,
        value: isPastRule ? subtree?.value?.[1] : subtree?.value?.[0],
        unit: 'day',
      }
      scheduledInBetween.to = {
        type: type,
        value: isPastRule ? subtree?.value?.[0] : subtree?.value?.[1],
        unit: 'day',
      }
    }
  }

  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isBetweenOperator = subtree?.operator?.includes('between');
    const isValueAvailable = isBetweenOperator
      ? subtree?.value?.length > 1
      : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (
        [
          'hasAppointment.scheduledInPast',
          'hasAppointment.notScheduledInPast',
        ].includes(subtree?.field)
      ) {
        scheduledInPast.operator = operator;
        scheduledInPast.value = subtree?.value[0];
        scheduledInPast.unit = 'day';
        appointment.operator =
          subtree?.field === 'hasAppointment.scheduledInPast'
            ? 'greaterThan'
            : 'equal';
        setBetweenData(subtree, true);
      } else if (
        [
          'hasAppointment.scheduledInFuture',
          'hasAppointment.notScheduledInFuture',
        ].includes(subtree?.field)
      ) {
        scheduledInFuture.operator = operator;
        scheduledInFuture.value = subtree?.value[0];
        scheduledInFuture.unit = 'day';
        appointment.operator =
          subtree?.field === 'hasAppointment.scheduledInFuture'
            ? 'greaterThan'
            : 'equal';
        setBetweenData(subtree, false);
      }
      if (['hasAppointment.appointmentType'].includes(subtree?.field)) {
        const isValueAvailable = subtree?.value?.[0]?.length;
        if (isValueAvailable) {
          appointmentTypes.value = subtree?.value[0];
        }
        if (!appointment.operator) {
          appointment.operator = 'greaterThan'
        }
        appointmentTypes.operator = operator;
      }
      else if (['hasAppointment.appointmentStatus'].includes(subtree?.field)) {
        const isValueAvailable = subtree?.value?.[0]?.length;
        if (isValueAvailable) {
          appointmentStatus.value = subtree?.value[0];
        }
        if (!appointment.operator) {
          appointment.operator = 'greaterThan'
        }
        appointmentStatus.operator = operator;
      }
    }
  });
  if (scheduledInPast.operator || scheduledInFuture.operator || scheduledInBetween.operator || appointmentTypes.operator || appointmentStatus.operator) {
    result = {
      fact: 'hasAppointment',
      operator: appointment.operator,
      value: appointment.value,
      params: {
        ...(scheduledInBetween.operator && { between: scheduledInBetween }),
        ...(scheduledInPast.operator && !scheduledInBetween.operator && { last: scheduledInPast }),
        ...(scheduledInFuture.operator && !scheduledInBetween.operator && { upcoming: scheduledInFuture }),
        ...(appointmentTypes.operator && {appointmentTypes: appointmentTypes.value}),
        ...(appointmentStatus.operator && {status: appointmentStatus.value})
      },
    };
  }
  return result;
};

export const getVitalRule = (jsonTree: any, children?: any[]) => {
  let result;
  const subRules = children || [];
  const vitalValue: any = {};
  const vital: any = {};
  subRules.forEach((rule) => {
    const subtree = rule.properties;
    const operator = getOperator(subtree?.operator);
    const isValueAvailable = subtree?.operator?.includes('between') ? subtree?.value?.length > 1 : subtree?.value?.length > 0;
    if (isValueAvailable) {
      if (['hasLastVitalValue.name'].includes(subtree?.field)) {
        vital.value = subtree?.value[0];
      } else if (['hasLastVitalValue.value'].includes(subtree?.field)) {
        vitalValue.operator = operator;
        vitalValue.value = subtree?.operator?.includes('between') ? {
          low: subtree?.value?.[0],
          high: subtree?.value?.[1],
        } : subtree?.value[0];
      }
    }
  });
  if (vital.value && vitalValue.operator) {
    const isBPVital = [Vital.systolicBloodPressure, Vital.diastolicBloodPressure].includes(vital.value);
    const vitalCode = isBPVital ? Vital.bloodPressure : vital.value;
    const vitalSubCode = isBPVital ? vital.value : undefined;
    result = {
      fact: 'hasLastVitalValue',
      operator: vitalValue.operator,
      value: vitalValue.value,
      params: {
        code: vitalCode,
        ...(vitalSubCode && { subcode: vitalSubCode }),
      }
    };
  }
  return result;
};

export const processQueryBuilderJSON = (jsonTree: any) => {
  let obj: any = {};
  if (jsonTree?.type === 'group') {
    if (jsonTree?.properties?.conjunction === 'OR') {
      obj.any = jsonTree.children1.map(
        (item: any) => processQueryBuilderJSON(item).conditions
      ).filter((item: any) => { return !!item });
      if (!obj?.any?.length) {
        obj = undefined
      }
    } else {
      obj.all = jsonTree.children1.map(
        (item: any) => processQueryBuilderJSON(item).conditions
      ).filter((item: any) => { return !!item });
      if (!obj?.all?.length) {
        obj = undefined
      }
    }
  } else if (jsonTree?.type === 'rule') {
    if (jsonTree.properties) {
      obj = getFieldRule(jsonTree.properties);
    }
  } else if (jsonTree?.type === 'rule_group') {
    if (['form', 'hasAppointment', 'hasCondition', 'hasFamilyHistory', 'hasDiagnosis', 'hasLastVitalValue', 'onMedication', 'hasLabResult', 'hasProcedure', 'engagementOfCare', 'hasImmunization', 'hasMedicationStatement', 'hasRADOrder', 'hasPastMembershipStatus', 'wearable', 'customEHRAttributes'].includes(jsonTree.properties.field)) {
      obj = getFieldRule(jsonTree.properties, jsonTree.children1);
    }
  }
  return {
    jsonTree,
    conditions: obj,
  };
};


// tags  condition: multiselect_equals == all multiselect_not_equals any
//  value : true
// opertaor : equal

//zip
//operator: 'equal',
//value: true,

// export const defaultInitTree: JsonTree = {
//   id: 'a8babb98-0123-4456-b89a-b183c586fc47',
//   type: 'group',
//   children1: {
//     'a8babb98-0123-4456-b89a-b183c586fc47': {
//       type: 'rule',
//       properties: {
//         field: 'employerId',
//         operator: 'multiselect_equals',
//         value: [],
//         valueSrc: ['value'],
//         valueError: [],
//         valueType: ['multiselect'],
//       },
//     },
//     '98a8a9ba-0123-4456-b89a-b16e721c8cd0': {
//       type: 'rule',
//       properties: {
//         field: 'sexAtBirth',
//         operator: 'select_equals',
//         value: [],
//         valueSrc: ['value'],
//         valueError: [],
//         valueType: ['select'],
//       },
//     },
//     '98a8a9ba-0123-4456-b89a-b16e721c8cd1': {
//       type: 'rule',
//       properties: {
//         field: 'hasZipInGivenList',
//         operator: 'multiselect_equals',
//         value: [],
//         valueSrc: ['value'],
//         valueError: [],
//         valueType: ['multiselect'],
//       },
//     },
//     '98a8a9ba-0123-4456-b89a-b16e721c8cd2': {
//       type: 'rule',
//       properties: {
//         field: 'age',
//         operator: 'less_or_equal',
//         value: [],
//         valueSrc: ['value'],
//         valueError: [],
//         valueType: ['number'],
//       },
//     },
//     '98a8a9ba-0123-4456-b89a-b16e721c8cd3': {
//       type: 'rule',
//       properties: {
//         field: 'hasTags',
//         operator: 'multiselect_equals',
//         value: [],
//         valueSrc: ['value'],
//         valueError: [],
//         valueType: ['multiselect'],
//       },
//     },
//   },
// };

export const defaultInitTree = {
  id: QbUtils.uuid(),
  type: 'group',
  children1: [
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'employerId',
        value: [[]],
        operator: 'multiselect_equals',
        valueSrc: ['value'],
        valueType: ['multiselect'],
        valueError: [null],
        asyncListValues: [],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'sexAtBirth',
        value: [[]],
        operator: 'select_equals',
        valueSrc: ['value'],
        valueType: ['select'],
        valueError: [null],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'hasZipInGivenList',
        value: [[]],
        operator: 'multiselect_equals',
        valueSrc: ['value'],
        valueType: ['multiselect'],
        valueError: [null],
        asyncListValues: [],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'age',
        value: [[]],
        operator: 'greater',
        valueSrc: ['value'],
        valueType: ['number'],
        valueError: [null],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'hasTags',
        value: [[]],
        operator: 'multiselect_equals',
        valueSrc: ['value'],
        valueType: ['multiselect'],
        valueError: ['Invalid value'],
        asyncListValues: [],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'contactTypeId',
        value: [[]],
        operator: 'multiselect_equals',
        valueSrc: ['value'],
        valueType: ['multiselect'],
        valueError: ['Invalid value'],
        asyncListValues: [],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'genderIdentity',
        value: [[]],
        operator: 'select_equals',
        valueSrc: ['value'],
        valueType: ['select'],
        valueError: [null],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'source',
        value: [[]],
        operator: 'multiselect_equals',
        valueSrc: ['value'],
        valueType: ['multiselect'],
        valueError: [null],
      },
    },
    {
      id: QbUtils.uuid(),
      type: 'rule',
      properties: {
        field: 'hasMembershipStatus',
        value: [''],
        operator: 'select_equals',
        valueSrc: ['value'],
        valueType: ['select'],
        valueError: [null],
      },
    },
  ],
};

export const initialAggregateState = {
  total: 0,
  emailTotal: 0,
  emailConsent: 0,
  smsTotal: 0,
  smsConsent: 0,
  notificationTotal: 0,
  notificationConsent: 0,
};

export const getAggregateWhereClause = (
  accountId: number,
  args: { contactIds?: string[], segmentType?: string, segmentTypeValue?: string , patientTypeContactId?: string, mlovData: ICommonData}
) => {
  const mlovData = args?.mlovData
  const customContactTypes: any[] =
    mlovData.defaultContactTypes?.customContactTypes || [];
  const customContactTypeIds: string[] = customContactTypes
    .filter((obj: any) => {
      if (obj.code) {
        return obj.code !== PERSON_TYPES.CUSTOMER;
      }
      return true;
    })
    .map((obj: any) => {
      return obj.id;
    });
  const clause: any = {
    total: {
      accountId: {
        _eq: accountId,
      },
    },
    emailTotal: {
      accountId: {
        _eq: accountId,
      },
      email: {
        _is_null: false,
      },
    },
    emailConsent: {
      accountId: {
        _eq: accountId,
      },
      contactConsents: { consentId: { _is_null: false } }
    },
    smsTotal: {
      accountId: {
        _eq: accountId,
      },
      phoneNumber: {
        _is_null: false,
      },
    },
    smsConsent: {
      accountId: {
        _eq: accountId,
      },
      contactConsents: { consentId: { _is_null: false } }
    },
    notificationConsent: {
      accountId: {
        _eq: accountId,
      },
      contactConsents: { consentId: { _is_null: false } }
    },
  };

  if (args?.segmentType == 'customSelected' && args?.segmentTypeValue == 'activePatient') {
    clause.total = {
      ...clause.total,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      isActive: {
        _eq: true,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

  } else if (args?.segmentTypeValue == 'inactivePatient') {
    clause.total = {
      ...clause.total,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      isActive: {
        _eq: false,
      },
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

  } else if (args?.segmentType == 'customSelected' && args?.segmentTypeValue == 'allPatient') {
    clause.total = {
      ...clause.total,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      contactType: { contactType: { code: { _eq: "CUSTOMER" } } }
    };
  } else if (args?.segmentType == 'customSelected' && args?.segmentTypeValue == 'allLead') {
    clause.total = {
      ...clause.total,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      contactType: {typeId: {_in: customContactTypeIds || []}},
    };
  } else if (args?.segmentType == 'customSelected' && args?.segmentTypeValue == 'customSelected') {
    clause.total = {
      ...clause.total,
      contactType: { contactType: { code: { _is_null: false } } }
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      contactType: { contactType: { code: { _is_null: false } } }
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      contactType: { contactType: { code: { _is_null: false } } }
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      contactType: { contactType: { code: { _is_null: false } } }
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      contactType: { contactType: { code: { _is_null: false } } }
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      contactType: { contactType: { code: { _is_null: false } } }
    };
  }



  if (args?.contactIds) {
    clause.total = {
      ...clause.total,
      id: {
        _in: args.contactIds,
      },
    };

    clause.emailTotal = {
      ...clause.emailTotal,
      id: {
        _in: args.contactIds,
      },
    };

    clause.emailConsent = {
      ...clause.emailConsent,
      id: {
        _in: args.contactIds,
      },
    };
    clause.smsTotal = {
      ...clause.smsTotal,
      id: {
        _in: args.contactIds,
      },
    };
    clause.smsConsent = {
      ...clause.smsConsent,
      id: {
        _in: args.contactIds,
      },
    };
    clause.notificationConsent = {
      ...clause.notificationConsent,
      id: {
        _in: args.contactIds,
      },
    };
  }

  return clause;
};

export const getLeadSourceList = (type: string) => {
  const leadSourceList = [
    {
      value: 'BULK_IMPORT',
      title: 'Bulk Import ',
    },
    {
      value: 'CALL',
      title: 'Call',
    },
    {
      value: 'EMAIL',
      title: 'Email',
    },
    {
      value: 'FOLD_ADMIN_APP_WEB',
      title: 'Admin App(Web)',
    },
    {
      value: 'FORM',
      title: 'Form',
    },
    {
      value: 'MEMBERSHIP_PURCHASE_REQUEST',
      title: 'Membership Purchase Request',
    },
    {
      value: 'SMS',
      title: 'SMS',
    },
    {
      value: 'SPRUCE',
      title: 'Spruce',
    },
    {
      value: 'UNKNOWN',
      title: 'Unknown',
    },
    {
      value: 'WEB_WIDGET',
      title: 'Web Widget',
    },
  ];
  if (type === CONTACT_TYPE_CODES.PATIENT) {
    leadSourceList.push({
      value: 'FOLD_ADMIN_APP_MOBILE',
      title: 'Fold Grow Mobile App',
    });
    leadSourceList.push({
      value: 'EHR',
      title: 'EHR',
    });
  }
  return leadSourceList;
}

const getSourceRule = (jsonTree: any) => {
  if (!isValidJsonTree(jsonTree)) {
    return;
  }
  return {
    fact: 'source',
    operator: 'equal',
    value: true,
    params: {
      source: jsonTree?.value[0],
      condition: getParamCondition(jsonTree?.operator),
    },
  };
};

const getGenderIdentityRule = (jsonTree: any) => {
  if (!isValidJsonTree(jsonTree)) {
    return;
  }
  return {
    fact: 'genderIdentity',
    operator: getOperator(jsonTree?.operator),
    value: jsonTree?.value[0],
  };
};

const isValidJsonTree = (jsonTree: any) => {
  return jsonTree?.value && jsonTree?.value[0] && jsonTree?.value[0].length;
};
