import moment from 'moment';
import {getEmailDataFromContentAttributes} from '../ChatUI/EmailMessageBox/EmailMessageBoxUtils';
import {SEND_EMAIL_OPTIONS} from './EmailDrawerCommonV2Const';
import {
  IEmailInbox,
  IEmailRecipient,
  IInboxResponse,
  IInputErrors,
  IMessageData,
  ISendEmailOptions,
} from './interfaces';
import {DATE_FORMATS} from '../../../constants';
import {getMembershipAPIToken} from '../../RightSideContainer/Sales/MembershipAndSubscriptions/MembershipAPI';
import {getMembershipLongLink} from '../../RightSideContainer/Sales/MembershipAndSubscriptions/MembershipUtils';
import {getShortLink} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {getAppointmentBookingLink} from '../../../utils/commonUtils';
import {IAppointmentType} from '../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {getProductsDetail} from '../../../services/Stripe/StripeServices';
import {EMAIL_PLATFORMS} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import {IChannelEmail} from '../../../services/Inbox/interfaces';
import {IInboxChannelEmail} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';

export const validateForm = (data: {
  inboxId: number;
  subject: string;
  to: IEmailRecipient[];
  sendEmailOption: ISendEmailOptions;
  templateId: number;
}) => {
  const inputErrors: IInputErrors = {
    from: '',
    subject: '',
    to: '',
    template: '',
  };
  let isValid = true;
  if (!data.inboxId) {
    inputErrors.from = 'emailInboxNotSelected';
    isValid = false;
  }

  if (!data.subject || data.subject.trim().length === 0) {
    inputErrors.subject = 'invalidSubject';
    isValid = false;
  }

  if (!(data.to && data.to.length !== 0)) {
    inputErrors.to = 'recipientNotSelected';
    isValid = false;
  }

  if (
    data.sendEmailOption === SEND_EMAIL_OPTIONS.TEMPLATE &&
    !data.templateId
  ) {
    inputErrors.template = 'templateNotSelected';
    isValid = false;
  }
  return {
    inputErrors,
    isValid,
  };
};

export const getLinkText = (text: string, link: string) => {
  return `<a href="${link}">${text}</a>`;
};

export const getFormattedEmailInboxes = (data: {
  emailInboxes: IInboxResponse[];
  supportEmail: string;
}) => {
  const {emailInboxes, supportEmail} = data;
  const result: IEmailInbox[] = [];
  emailInboxes.forEach((item) => {
    if (item.channelEmail) {
      if (item?.inboxType === EMAIL_PLATFORMS.OUTLOOK) {
        result.push({
          email: item.channelEmail.email,
          id: item.id,
          name: item.name,
          type: 'OUTLOOK',
        });
      } else if (item?.inboxType === EMAIL_PLATFORMS.MAILGUN) {
        result.push({
          email: item.channelEmail.email,
          id: item.id,
          name: item.name,
          type: 'MAILGUN_DEFAULT_INBOX',
        });
      } else {
        result.push({
          email: item.channelEmail?.email,
          id: item.id,
          name: item.name,
          type: 'GMAIL',
        });
      }
    }
  });
  result.push({
    id: -1,
    name: 'Default',
    email: supportEmail,
    type: 'DEFAULT',
  });
  return result;
};

export const extractFormIds = (inputString: string) => {
  const regex = /http:\/\/form\.([a-z\d-]+)\b/g;
  const formIds: string[] = [];
  let match;
  while ((match = regex.exec(inputString)) !== null) {
    formIds.push(match[1]);
  }
  return Array.from(new Set(formIds));
};

export const getPlaceholderUrl = (
  entity: 'form' | 'appointment',
  entityId: string
) => {
  return `http://${entity}.${entityId}`;
};

export const getExistingEmailMessageContent = (data: {
  inboxName: string;
  inboxAddress: string;
  messageData: IMessageData;
}) => {
  const {cc, bcc, to} = getEmailDataFromContentAttributes(
    data.messageData.contentAttributes
  );
  const dateStr = moment(data.messageData.createdAt).format(
    DATE_FORMATS.MESSAGE_DATE_FORMAT
  );
  return `
  <br/>
  <hr/>
  <p>From: ${data.inboxName} &lt;${data.inboxAddress}&gt; </p>
  <p>Sent: ${dateStr} </p>
  <p>To: ${to.join(',')} </p>
  ${cc.length > 0 ? `<p>CC: ${cc.join(',')} </p>` : ''}
  ${bcc.length > 0 ? `<p>BCC: ${bcc.join(',')} </p>` : ''}
  ${data.messageData.content}
  `;
};

export const removeDuplicatesByUuid = (data: IEmailRecipient[]) => {
  const result = data.filter(
    (v, i, a) => a.findIndex((v2) => v2.uuid === v.uuid) === i
  );
  return result;
};

export const getAppointmentPlaceholderLink = (data: {
  appointmentId: string;
  appointmentUsers: string[];
}) => {
  const placeholderUrl = `appointment.${
    data.appointmentId
  }.users.${data.appointmentUsers.toString()}`;
  return placeholderUrl;
};

export const extractAppointmentPlaceholderLinks = (inputString: string) => {
  const regex = /"appointment\.([a-z\d-]+).users.*?"/gi;
  const matches = inputString.match(regex);
  const placeholderLinks = Array.from(new Set(matches));
  return placeholderLinks;
};

export const getAppointmentIdAndUserIdsFromLink = (link: string) => {
  const appointmentId = link.substring(
    link.indexOf('.') + 1,
    link.lastIndexOf('.users')
  );
  const usersIds = link.substring(link.indexOf('.users.') + 7, link.length - 1);
  return {
    appointmentId: appointmentId,
    appointmentUsers: usersIds,
  };
};

export const getCurrentAndPendingList = (
  to: IEmailRecipient[],
  dividerNum = 3
) => {
  const currentList: IEmailRecipient[] = [];
  const pendingList: IEmailRecipient[] = [];
  to.forEach((item, index) => {
    if (index < dividerNum) {
      currentList.push(item);
    } else {
      pendingList.push(item);
    }
  });
  return {currentList, pendingList};
};

export const getAllUrlsFromInputString = (inputString: string) => {
  const exp =
    /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#@!$&'()*+%,;=]*)*$/gm;
  const regex = new RegExp(exp);
  return Array.from(new Set(inputString.match(regex)));
};

export const extractMembershipLinksFromInputString = (inputString: string) => {
  const regex = new RegExp(
    /http:\/\/membership\.([a-zA-Z\_\d]+).([a-zA-Z\_\d]+)/g
  );
  const matches = inputString.match(regex);
  const placeholderLinks = Array.from(new Set(matches));
  return placeholderLinks;
};

export const getMembershipLinkReplacedHtml = async (data: {
  html: string;
  contactUuid: string;
  accountId: string | number;
  accountUuid: string;
}) => {
  let currentHtml = data.html;
  const placeholderMembershipLinks =
    extractMembershipLinksFromInputString(currentHtml);
  if (placeholderMembershipLinks.length === 0) {
    return currentHtml;
  }
  const promiseList = placeholderMembershipLinks.map(async (item) => {
    const [productId, plan] = item.replace('http://membership.', '').split('.');
    const token = await getMembershipAPIToken({
      accountId: data.accountId,
      accountUuid: data.accountUuid,
      contactUuid: data.contactUuid,
    });
    const finalString = getMembershipLongLink({token, plan, productId});
    const shortLink = await getShortLink(finalString);
    return {shortLink, placeholderLink: item};
  });
  const response = await Promise.all(promiseList);
  response.forEach((item) => {
    const placeholder = item.placeholderLink;
    const shortLink = item.shortLink;
    const regex = new RegExp(placeholder, 'g');
    currentHtml = currentHtml.replace(regex, shortLink);
  });
  return currentHtml;
};

export const getAppointmentBookingLinkReplaceHtml = (data: {
  html: string;
  contactUuid: string;
  accountUuid: string;
}) => {
  let currentHtml = data.html;
  const placeholderAppointmentLinks =
    extractAppointmentPlaceholderLinks(currentHtml);
  placeholderAppointmentLinks.forEach((item) => {
    const appointmentData = getAppointmentIdAndUserIdsFromLink(item);
    const appointmentLink = getAppointmentBookingLink(
      data.accountUuid,
      data.contactUuid,
      '',
      {id: appointmentData.appointmentId} as IAppointmentType,
      appointmentData.appointmentUsers as unknown as string[]
    );
    const appointmentRegex = new RegExp(item, 'g');
    currentHtml = currentHtml.replace(appointmentRegex, `"${appointmentLink}"`);
  });
  return currentHtml;
};

export const getPlaceholderMembershipLink = async (
  productId?: string,
  plan?: string
) => {
  if (!productId || !plan) {
    return '';
  }
  try {
    const singleProductDetails = await getProductsDetail(productId);
    const tempLink = `http://membership.${productId}.${plan}`;
    const placeholderLink = `<a href="${tempLink}">${singleProductDetails?.data?.name}</a>`;
    return placeholderLink;
  } catch (error) {
    return '';
  }
};


export const getChannelEmailInboxType = (inboxChannelEmailData: IInboxChannelEmail) => {
  if (inboxChannelEmailData) {
    if (inboxChannelEmailData?.smtpAddress === 'outlook.com') {
     return EMAIL_PLATFORMS.OUTLOOK;
    } else {
      return EMAIL_PLATFORMS.GMAIL;
    }
  }
}

export const isOutlookInbox = (inboxChannelEmailData: IInboxChannelEmail) => {
  if (inboxChannelEmailData) {
    const channelEmailInboxType = getChannelEmailInboxType(inboxChannelEmailData);
    return (channelEmailInboxType === EMAIL_PLATFORMS.OUTLOOK)
  }
  return false;
}
