import React from 'react';
import {useMutation} from '@apollo/client';
import {View} from 'native-base';
import {
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
} from '../../../../constants';
import {LeadQueries} from '../../../../services';
import WorkFlowListByEntityEvents from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import {updateContactWorkflow} from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../styles';
import TaskIconOther from '../../../common/Svg/TaskIconOther';
import {getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';

export const EntityEventsListView = (props: any) => {
  const [SingleCreateContactQuery] = useMutation(
    LeadQueries.UpdateWorkflowMetaData
  );

  const UpdateWorkFlow = (workflowEventList: any) => {
    const data = updateContactWorkflow(workflowEventList);
    SingleCreateContactQuery({
      variables: {
        id: props.patient.id,
        workflowListObj: data,
      },
    });
  };

  const showAutomationTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.AUTOMATION
  );

  const showSettingTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.SETTING
  );

  return (
    <View
      style={{
        backgroundColor: Colors.Custom.Gray50,
      }}
    >
      {showAutomationTab && showSettingTab ? (
        <>
          <View style={{padding: 24}}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 9,
                }}
              >
                <View style={{marginRight: 10}}>
                  <TaskIconOther />
                </View>
                <DisplayText
                  size={'xlBold'}
                  extraStyles={{color: Colors.primary['700'] || ''}}
                  textLocalId={'activeAutomations'}
                />
              </View>
            </View>
          </View>
          <View
            style={[
              {
                backgroundColor: '#ffffff',
                marginHorizontal: 24,
                borderRadius: 8,
                // @ts-expect-error: Let's ignore a compile error like this unreachable code
                boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                paddingHorizontal: 16,
                paddingVertical: 8,
              },
            ]}
          >
            <WorkFlowListByEntityEvents
              isDetailsPage={true}
              workflowlist={
                props.patient.workflowMetaData &&
                props.patient.workflowMetaData.workflowList &&
                props.patient.workflowMetaData.workflowList.length > 0 &&
                props.patient.workflowMetaData.workflowList
              }
              addUrl={CUSTOM_AUTOMATION_URL.ADD_PATIENT}
              title={
                ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_TITLE
              }
              subtitle={
                ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_SUB_TITLE
              }
              entityEventList={ENTITY_EVENTS_TYPE.ADD_OR_UPDATE_LEAD}
              onValueChanage={(eventEntityList: any) => {
                UpdateWorkFlow(eventEntityList);
              }}
              backgroundColor={'#ffffff'}
            ></WorkFlowListByEntityEvents>
          </View>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default EntityEventsListView;
