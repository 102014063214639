import { getCurrentMonthStartDateTime, getPreviousMonthsStartDateTime } from "../../../../../../../utils/DateUtils";

export const FEED_RESOURCE_TYPE = {
  MESSAGE: 'MESSAGE',
  CONTACT_TAG: 'CONTACT_TAG',
  CONTACT_NOTES: 'CONTACT_NOTES',
  MEMBERSHIP: 'MEMBERSHIP',
  LOGIN_ACTIVITY: 'LOGIN_ACTIVITY',
  FORM_RESPONSE: 'FORM_RESPONSE',
  APPOINTMENT: 'APPOINTMENT',
  TASK: 'TASK',
  CALL: 'CALL',
  VIDEO_CALL: 'VIDEO_CALL',
};

export const FEED_CLICKABLE_RESOURCE_TYPE = {
  FORM_RESPONSE: 'FORM_RESPONSE',
  APPOINTMENT: 'APPOINTMENT',
  TASK: 'TASK',
};
export const FEED_CLICKABLE_RESOURCE_TYPE_LIST = ['FORM_RESPONSE', 'APPOINTMENT', 'TASK'];

export const TIME_LINE_SOURCE_TYPES = {
  EHR: 'EHR',
  HIE: 'HIE',
  FOLD: 'Fold',
};

export const SOURCE_TYPES = {
  All: 'All',
  FOLD: 'FOLD',
  EHR: 'EHR',
  HIE: 'HIE',
};

export const DATE_RANGE_FILTER = {
  ALL_TIME: 'All',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_THREE_MONTHS: 'Last 3 Months',
  // CUSTOM_RANGE: 'Custom Range'       Kept for future use
};

export const DATE_RANGE_FILTER_VALUES = {
  ALL_TIME: 'All',
  THIS_MONTH: getCurrentMonthStartDateTime(),
  LAST_MONTH: getPreviousMonthsStartDateTime(2),
  LAST_THREE_MONTHS: getPreviousMonthsStartDateTime(3),
  // CUSTOM_RANGE: 'Custom' Kept for future use
};

export const FEEDABLE_RESOURCES = {
  ALLERGY_INTOLERANCE: 'AllergyIntolerance',
  CONDITION: 'Condition',
  DIAGNOSTIC_REPORT: 'DiagnosticReport',
  DOCUMENT_REFERENCE: 'DocumentReference',
  IMMUNIZATION: 'Immunization',
  MEDICATION_STATEMENT: 'MedicationStatement',
  MEDICATION_REQUEST: 'MedicationRequest',
  OBSERVATION: 'Observation',
  PROCEDURE: 'Procedure',
  REQUEST_GROUP: 'RequestGroup',
  SERVICE_REQUEST: 'ServiceRequest',
  APPOINTMENT: 'Appointment'
};
