import {Dropdown, Menu} from 'antd';
import {Button, View, Text, HStack, Icon} from 'native-base';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles/Colors';
import AntIcon from 'react-native-vector-icons/AntDesign';
import React from 'react';
import { OrderType } from '../../Orders/OrdersAndReports/OrderConstants';


export interface IDropdownButtonProps {
  title: string;
  onClick?: (key: string) => void;
}

export enum MENU_KEYS {
  labOrder = 'LAB',
  externalLabOrder = 'EXT_LAB',
  radOrder = 'RAD',
  externalRadOrder = 'EXT_RAD',
  medOrder = 'MED',
}

export const CustomSendOrderButton = (
  props: any,
) => {
  const intl = useIntl();

  const onActionHandler = (key: string) => {
    let actionCode: any = '';
    switch (key) {
      case MENU_KEYS.labOrder:
        actionCode = OrderType.LAB
        break;
      case MENU_KEYS.medOrder:
        actionCode = OrderType.MED;
        break;
      case MENU_KEYS.radOrder:
        actionCode = OrderType.RAD;
        break;
    }

    if (props?.onActionPerformed && typeof props.onActionPerformed === 'function') {
      props?.onActionPerformed(actionCode);
    }
  }

  const actionMenu = () => {
    return (
      <Menu
        items={[
          {
            key: MENU_KEYS.labOrder,
            label: intl.formatMessage({id: 'labOrder'}),
          },
          {
            key: MENU_KEYS.medOrder,
            label: intl.formatMessage({id: 'medOrder'}),
          },
          {
            key: MENU_KEYS.radOrder,
            label: intl.formatMessage({id: 'radOrder'}),
          },
        ]}
        onClick={(data: any) => {
          onActionHandler(data?.key);
        }}
      />
    );
  };

  return (
    <>
    <View
      style={{
        padding: 5,
        height: 36,
        marginTop: 8,
        paddingTop: 6,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button.Group isAttached>
        <Button
          style={{
            backgroundColor: Colors.secondary['100'],
            height: 36,
            borderColor: Colors.secondary['200'],
            borderWidth: 1,
            paddingHorizontal: 18,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 16,
            borderTopLeftRadius: 16,
            borderTopRightRadius:  0,
          }}
          onPress={() => {
            //
          }}
          leftIcon={
            <Icon
              color={Colors.secondary['800']}
              as={AntIcon}
              name={'plus'}
              size="4"
            />
          }
        >
            <Text
              size={'xsMedium'}
              style={{
                fontSize: 14,
              }}
              color={Colors.secondary['800']}
            >
              {' Order'}
            </Text>
        </Button>
        <HStack>
            <Dropdown
              overlay={actionMenu}
              placement="bottomRight"
              overlayStyle={{width: 120}}
            >
              <Button
                style={{
                  backgroundColor: Colors.secondary['100'],
                  height: 36,
                  borderColor: Colors.secondary['200'],
                  borderWidth: 1,
                  borderLeftWidth: 0,
                  width: 12,
                  borderBottomLeftRadius: 0,
                  borderTopStartRadius: 0,
                  borderBottomRightRadius: 16,
                  borderTopRightRadius: 16,
                }}
                onPress={() => {}}
                alignSelf="center"
                variant="transparent"
                minH={8}
                rightIcon={
                  <Icon
                    color={Colors.secondary['800']}
                    as={AntIcon}
                    name={'down'}
                    size="4"
                  />
                }
              ></Button>
            </Dropdown>
        </HStack>
      </Button.Group>
    </View>
    </>
  );
};
