import {P} from '@antv/g2plot';
import {useLazyQuery, useMutation} from '@apollo/client';
import {Viewer} from '@toast-ui/react-editor';
import {Checkbox, Popover, Tooltip} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import parse from 'html-react-parser';
import moment from 'moment';
import {Divider, HStack, Pressable, Spinner, Text, useToast, View, VStack} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CHAT_DELIVERY_STATUS, COPY_MESSAGE_CODE, DATE_FORMATS, GROUP_MEMBER_TYPE, MLOV_CATEGORY} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {TaskQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import {GetUsersTaskAccess} from '../../../../services/TaskPool/TaskPoolQueries';
import {Colors} from '../../../../styles';
import {
  getAccountId,
  getAccountUUID,
  getInboxTypeCode,
  getMsgEchoId,
  getPrimaryGenderCode,
  getUserData,
  getUserFullName,
  getUserName,
  getUserUUID,
  isEFaxChannelInboxType,
  isEmailChannelInboxType,
  isHTMLString,
  isSMSChannelInboxType,
  removeScriptsAndStyles,
} from '../../../../utils/commonUtils';
import {getDateToMomentISOString} from '../../../../utils/DateUtils';
import {EventBus} from '../../../../utils/EventBus';
import {getMlovCodeIdObj, getMlovId, getMlovIdFromCode, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {
  IConversationData,
  IConversationMessage,
  IConversationMessageContentAttributes,
  IMessageBoxData,
  IMsgAttachment,
  ISearchMessageData,
  ISenderContact,
  ISenderUser,
  MESSAGE_TYPE,
  onAddCommunicationType,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {ICopiedMessageItem} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {MESSAGE_CUSTOM_CARD_KEY, MESSAGE_CUSTOM_CARD_TYPE} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import MissedCallActionMessage from './MessagingCustomCard/CallLogCard/MissedCallActionMessage';
import {getContactDataAndType, isBroadCastAndPrivateGroup, isGroupConversation, isSMSNotSent} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import ReplyMessageView from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ReplyMessageView';
import ActionMsg from '../../ActionMsg/ActionMsg';
import SectionMsgHeader from '../../ActionMsg/SectionMsgHeader';
import {AddNoteView} from '../../AddNoteView';
import {IUserSearch} from '../../CareDashboard/CareDashboardWidget/UserAutoComplete';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import {EmailHtml} from '../../EmailPopupView/EmailHtml';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import ReadMsgSvg from '../../Svg/ReadMsgSvg';
import SendMesgSvg from '../../Svg/SendMesgSvg';
import MsgAttachment from '../MsgAttachment/MsgAttachment';
import {IReplyMessageObject} from './interfaces';
import './Message-box.css';
import {styles} from './MessageBoxStyles';
import {
  detectURLs,
  getArticleFromMessageText,
  getParentMessageData,
  goToMessages,
  showMessageOption,
} from './MessageBoxUtils';
import './MessageViewer.css';
import MessagingCustomCard from './MessagingCustomCard/MessagingCustomCard';
import ScheduleMessageSvg from '../../Svg/ConversationAllSvg/ScheduleMessageSvg';
import {SUPPORTED_EVENT_CODE} from '../../../../constants/WebSocketConst';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import CreateNoteFromMessage from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CreateNoteFromMessage/CreateNoteFromMessage';
import AssignCommunicationTypeDrawer from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/AssignCommunicationTypeDrawer';
import {MessageCommunicationTypes} from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes';
import {ICommunicationType} from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/interfaces';
import {getCommunicationTypesByMessageUuids} from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/CommunicationTypesUtils';
import StatusErrorSvg from '../../Svg/StatusErrorSvg';
import {getUserIdListFromMsgContent} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ConversationMentions/ConversationMentions';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import MessageTaskCountView from './MessageTaskCountView';
import { getDisplayText, getSVG } from './MessageBoxTextIconUtils';
import EFaxMessageBox from './EFaxMessageBox';
import {unReadMessageEntriesInMessageLastSeen} from './MessageReadInfo/MessageReadInfoService';
import { TestIdentifiers, testID } from '../../../../testUtils';
import MenuHorizontalIcon from '../../Svg/MenuHorizontalIcon';

export type MessageBoxProps = {
  currentIndex?: number;
  navigation: any;
  id?: string | number;
  uuid?: string;
  messageType: number;
  position?: 'left' | 'right';
  text?: string;
  dateStr?: string;
  senderFullName?: string;
  attachments?: IMsgAttachment[];
  senderType: string;
  senderUser?: ISenderUser;
  senderContact?: ISenderContact;
  showModal?: any;
  onActionPerformed?: (actionCode?: string) => void;
  onReplyCallback?: (object: IReplyMessageObject) => void;
  private?: boolean;
  conversation: IConversationData;
  selectedInboxTypeCode?: string;
  conversationMessage?: IConversationMessage;
  parentMessage?: IMessageBoxData;
  onParentMsgClick?: (parentMsg: IMessageBoxData) => void;
  onPressMore?: () => void;
  messageAction?: any;
  item?: any;
  onMessageAction?: (
    actionId: MessageActionID,
    data?: any,
    replyObject?: IReplyMessageObject,
    extraData?: any
  ) => void;
  replyObject?: IReplyMessageObject;
  isSeen: boolean;
  isSeenByUsers: string[];
  isReadOnlyView?: boolean;
  handleCopyClick?: (data: ICopiedMessageItem) => void
  isCopyModeStateOnCode?: string;
  onAddCommunicationType?: onAddCommunicationType
  taskCount?: number;
  onViewTaskAction?: (messageUuid: string) => void;
  searchMessage?: ISearchMessageData;
  parentMessageUuid?: string;
  clickMessageUuid?: string;
  clickEventId?: string;
  resetClickedMessageUuid?: () => void
  onRedirectToMentionChange?: () => void;
  redirectToMention?: boolean;
  isDisplaySectionMsgHeader: boolean;
  groupMemberDataLoading?: boolean;
} & {type?: 'text'; text?: string};

const MessageBox: React.FC<MessageBoxProps> = (props) => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const {
    id,
    uuid,
    navigation,
    messageType,
    position = 'left',
    text = '',
    dateStr = '',
    senderFullName = '',
    attachments = [],
    conversation,
    senderUser,
    selectedInboxTypeCode,
    conversationMessage,
    showModal,
    onActionPerformed,
    onPressMore,
    messageAction,
    item,
    onMessageAction,
    replyObject,
    isSeen,
    isSeenByUsers,
    taskCount,
    searchMessage,
    onRedirectToMentionChange,
    redirectToMention,
    isDisplaySectionMsgHeader
  } = props;
  const isRightAvatarShow = true;
  let mainContainerStyle: any;
  let positionCls;
  const intl = useIntl();
  const toast = useToast();
  const contactInfo = getContactDataAndType(
    conversation || ({} as IConversationData)
  );
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const currentUserId = getUserUUID();
  const currentUserName = getUserFullName();
  const currentUserUUID = getUserUUID();
  const contactData = contactInfo.contactData;
  const genderCode = getPrimaryGenderCode(contactData);
  const currentUserData = getUserData();
  const inboxTypeCode =
    getInboxTypeCode(conversation?.conversationInbox?.channelType) ||
    selectedInboxTypeCode ||
    '';
  const isEFaxInbox = isEFaxChannelInboxType(conversation?.conversationInbox?.channelType);
  const showMessageInfoToAllUsers = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_MESSAGE_INFO_TO_ALL_GROUP_USERS
  );
  const showConversationTaskCount = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_TASK_COUNT_ON_MESSAGE
  );
  const status = item.status;
  const userName = getUserName();
  const isAutoResponse =
    props.conversationMessage?.contentAttributes?.isAutoResponse || false;
  const isScheduleMessage =
    props.conversationMessage?.contentAttributes?.isScheduleMessage || false;
  const isSenderLoggedInUser =
    (props.senderType?.toLowerCase() == 'user' &&
      (props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id)) ||
    showMessageInfoToAllUsers;

  const [showDrawer, setShowDrawer] = useState({
    showAddNoteModal: false as boolean,
    showAddTaskModal: false as boolean,
    showMessageReadInfoModal: false as boolean,
    messageData: {} as any,
    visible: false,
    attachmentActionVisible: false,
    addClinicalNotesOpen: false,
    addClinicalNotesOpenText: '' as string,
    showAssignTypeModal: false
  });
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [stateData, setStateData] = useState({
    selectedUser: {
      key: currentUserUUID,
      value: currentUserUUID,
      label: getUserFullName(),
    } as IUserSearch,
    // userPool: [] as IUserPool[],
    isMarkLoading: false as boolean,
    communicationTypes: item?.communicationTypes || ([] as ICommunicationType[]),
    loading: false,
    resendLoading: false,
    searchMessage: {} as ISearchMessageData,
  });
  const [profilePhoto, setProfilePhoto] = useState('');
  // const [userList, setUserList] = useState<
  //   Array<{
  //     id: string;
  //     name: string;
  //     uuid: string;
  //     email: string;
  //   }>
  // >([]);
  // const [getParentMessageById] = useLazyQuery(ConversationsQueries.GetParentMessageById,{
  //   fetchPolicy: 'cache-and-network'
  // })
  const commonData = useContext(CommonDataContext);
  const accessUserTaskPoolTypeId = getMlovId(
    commonData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );
  const mlovData = commonData.MLOV;
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];

  const [getUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      userId: [
        stateData?.selectedUser
          ? stateData?.selectedUser.value
          : currentUserUUID,
      ],
      userPoolTypeId: accessUserTaskPoolTypeId,
    },
    fetchPolicy: 'no-cache',
  });

  const messageObjectCallBack = (attachmentId?: number) => {
    const tempObject: IReplyMessageObject = {
      id,
      messageType,
      position: position,
      text: text,
      dateStr: dateStr,
      conversationMessage,
      senderFullName: senderFullName,
      attachments: attachments,
      attachmentId: attachmentId || undefined,
      private: props?.private || false,
      uuid: props.uuid || '',
    };
    if (props.onReplyCallback) {
      props.onReplyCallback(tempObject);
    }
  };

  const parentData = getParentMessageData(props?.parentMessage);

  // const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
  //   variables: {
  //     accountId: accountUuid,
  //   },
  // });

  // const fetchSystemUsers = async () => {
  //   const getUsersResult = await getUsers();
  //   if (getUsersResult.data?.users) setUserList(getUsersResult.data.users);
  //   fetchSelectedUserPool();
  // };

  const conversationTaggedItem = getMlovListFromCategory(
    commonData.MLOV,
    'ConversationTaggedItem'
  );

  const conversationUuid = conversation?.uuid || '';
  const itemTypeId = conversationTaggedItem[0]?.categoryId;
  const itemId = conversationTaggedItem[0]?.id;

  const [CreateConversationTask] = useMutation(
    TaskQueries.CREATE_CONVERSATION_TAGGED_ITEM
  );

  const fetchCommunicationTypesByMessageUuid = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const messageUuid = props.item.uuid || '';
    const response = await getCommunicationTypesByMessageUuids([messageUuid]);
    if (!response.data) {
      return;
    }
    const communicationTypes = (response.data || []).map(
      (item) => item?.communicationType
    );
    setStateData((prev) => {
      return {
        ...prev,
        communicationTypes,
        loading: false,
      };
    });
    if (props.onAddCommunicationType) {
      props.onAddCommunicationType({messageUuid, types: communicationTypes});
    }
  }

  useEffect(() => {
    setShowDrawer((prev) => {
      return {
        ...prev,
        showAddNoteModal: showModal.noteModal,
        showAddTaskModal: showModal.taskModal,
        showMessageReadInfoModal: showModal.messageReadInfo,
        messageData: showModal.messageData,
      };
    });
    // Copied this from fetchSelectedUserPool, as this method is commented added here
    setStateData(prev => {
      return {
        ...prev,
        communicationTypes: item?.communicationTypes || [],
        searchMessage: props?.searchMessage || {} as ISearchMessageData,
      }
    });
    // fetchSystemUsers();
  }, [props]);

  useEffect(() => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION && !props?.clickEventId) {
      if (conversation?.conversationMessages[0]?.id === id && redirectToMention) {
        goToMessages(uuid || '');
        onRedirectToMentionChange?.();
      }
    }
    return () => {
      setShowDrawer(() => {
        return {
          showAddNoteModal: false as boolean,
          showAddTaskModal: false as boolean,
          showMessageReadInfoModal: false as boolean,
          messageData: {} as any,
          visible: false,
          attachmentActionVisible: false,
          addClinicalNotesOpen: false,
          addClinicalNotesOpenText: '' as string,
          showAssignTypeModal: false,
        };
      });
      setStateData(() => {
        return {
          selectedUser: {
            key: currentUserUUID,
            value: currentUserUUID,
            label: getUserFullName(),
          } as IUserSearch,
          // userPool: [] as IUserPool[],
          isMarkLoading: false as boolean,
          communicationTypes:
            item?.communicationTypes || ([] as ICommunicationType[]),
          loading: false,
          resendLoading: false,
          searchMessage: {} as ISearchMessageData,
        };
      });
    };
  }, [])


  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const existingRef = timeoutRef?.current;
    if (existingRef) {
      clearTimeout(existingRef);
    }
    if (props.clickMessageUuid || props?.clickEventId) {
      const element = document.getElementById(
        props.clickMessageUuid || searchMessage?.uuid || ''
      ) as HTMLDivElement;
      if (!element) {
        return;
      }
      element.style.backgroundColor = Colors.Custom.Gray100;
      element.style.borderRadius = '16';
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      timeout = setTimeout(() => {
        element.style.backgroundColor = 'transparent';
        element.style.borderRadius = '0';
        if (props.resetClickedMessageUuid) {
          props.resetClickedMessageUuid();
        }
      timeoutRef.current = timeout;
      }, 2000);
    }

    return () => {
      if (timeout || timeoutRef.current) {
        clearTimeout(timeout || timeoutRef.current);
      }
    };
  }, [props.clickMessageUuid, props?.clickEventId]);

  useEffect(() => {
    if (contactData?.contactProfilePhotos?.length && contactData?.contactProfilePhotos[0]?.attachmentId) {
      //getProfilePic();
    }
  }, [contactData?.contactProfilePhotos?.length])

  const getProfilePic = async () => {
    const baseService = BaseService.getSharedInstance().axios;
    const url = `/crm-nest/api/attachment/` + contactData?.contactProfilePhotos[0]?.attachmentId;
    baseService.get(url)
      .then((resp) => {
        setProfilePhoto(resp.data?.url)
        //return resp.data?.url;
      });
  }

  const onFormActionPerformed = (
    action: string,
    payload?: {addOrUpdateTask: {id: string}}
  ) => {
    const eventBus = EventBus.getEventBusInstance();
    switch (action) {
      case COMMON_ACTION_CODES.CANCEL:
        setShowDrawer({...showDrawer, showAddNoteModal: false});
        onActionPerformed && onActionPerformed();
        break;
      case COMMON_ACTION_CODES.COMPLETED:
        setShowDrawer({...showDrawer, showAddNoteModal: false});
        onActionPerformed && onActionPerformed();

        eventBus.broadcastEvent(
          'ADD_OR_UPDATE_NOTES',
          COMMON_ACTION_CODES.COMPLETED
        );
        break;
      case COMMON_ACTION_CODES.TASK_COMPLETE:
        setShowDrawer({...showDrawer, showAddTaskModal: false});
        onActionPerformed && onActionPerformed();
        CreateConversationTask({
          variables: {
            accountUuid: accountUuid,
            conversationUuid: conversationUuid,
            itemTypeId: itemId,
            itemId: payload?.addOrUpdateTask?.id || '',
            messageUuid: conversation?.conversationMessages?.[0]?.uuid,
          },
        }).then((res) => {
          eventBus.broadcastEvent('NEW_TASK_ADDED', {});
        });
        break;
      case COMMON_ACTION_CODES.TASK_CANCEL:
        setShowDrawer({...showDrawer, showAddTaskModal: false});
        onActionPerformed && onActionPerformed();
        break;
      case COMMON_ACTION_CODES.READ_DRAWER_CANCEL:
        setShowDrawer({...showDrawer, showMessageReadInfoModal: false});
        onActionPerformed && onActionPerformed();
        break;
      default:
        return '';
        break;
    }
  };

  const setMessagesMarkAsReadUnread = async (item: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        isMarkLoading: true,
      }
    })
    const conversationLastActivity =
      new Date(
        item?.updatedDateStr ||
        item?.dateStr ||
        item?.messageData?.updatedAt ||
        new Date(getDateToMomentISOString())
      ).getTime() - 2;
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const updateLastSeenRes = await unReadMessageEntriesInMessageLastSeen(conversation.uuid, item?.messageData?.uuid, abortControllerRef?.current?.signal);
    if (updateLastSeenRes?.id) {
      showToast(
        toast,
        intl.formatMessage({
          id: 'markAsUnreadSuccess',
        }),
        ToastType.success
      );
      //handle conversation unread count
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
        {}
      );
      const eventConversationData = {
        ...conversation,
        eventCode: SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE
      }
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE,
        eventConversationData
      );
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        ToastType.error
      );
    }
    setStateData((prev) => {
      return {
        ...prev,
        isMarkLoading: false,
      }
    })
  };



  const getActionItemView = (actionId: MessageActionID, actionData?: any) => {
    const isSenderLoggedInUser =
      props.senderType?.toLowerCase() == 'user' &&
      (props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id);
    const isSenderIsUser = props.senderType?.toLowerCase() == 'user'
    if (
      !isSenderLoggedInUser &&
      (actionId == MessageActionID.edit ||
        actionId == MessageActionID.delete)
    ) {
      return null;
    }
    if (
      isBroadCastAndPrivateGroup(props.conversation) &&
      actionId === MessageActionID.assignType
    ) {
      return null;
    }
    if (
      (!isSenderIsUser || position === 'left') &&
      showMessageInfoToAllUsers &&
      actionId == MessageActionID.info
    ) {
      return null;
    }
    const onShowMessageOptionClick = () => {
      if (actionId === MessageActionID.assignType) {
        setShowDrawer((prev) => {
          return {
            ...prev,
            showAssignTypeModal: true,
          };
        });
        return;
      }
      if (actionId === MessageActionID.markAsReadUnread) {
        setMessagesMarkAsReadUnread(item);
      }
      if (actionData) {
        onMessageAction?.(
          actionId,
          {...item, isAttachment: true, selectedAttachment: actionData?.id},
          replyObject
        );
        handleAttachmentPopover();
      } else {
        onMessageAction?.(actionId, item, replyObject);
        handleMorePopover();
      }
      //messageAction?.actionModel?.selectedAction();
    }
    return (
      <Pressable onPress={onShowMessageOptionClick}>
        {showMessageOption(
          actionId,
          conversation,
          position,
          contactInfo,
          attachments,
          isSenderLoggedInUser,
          text,
        ) ? (
          <></>
        ) : (
          <HStack padding={2}>
            <View zIndex={9999}>{getSVG(actionId)}</View>
            <Text
              color={Colors.Custom.Gray500}
              style={{marginLeft: 12, display: 'flex', alignItems: 'center'}}
              lineHeight={20}
              noOfLines={1}
              {...testID('MessageActionLable')}
            >
              {getDisplayText(actionId)}
            </Text>
          </HStack>
        )}
      </Pressable>
    );
  };
  const getMessageSenderName = () => {
    let senderName = '';
    if (props.senderType?.toLowerCase() == 'user') {
      if (
        props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id
      ) {
        senderName = 'You';
      } else {
        senderName = props?.senderUser?.name || props?.senderFullName || '';
      }
    } else {
      senderName = props?.senderContact?.name || props?.senderFullName || '';
    }
    if (isAutoResponse || messageType === MESSAGE_TYPE.automation_message) {
      senderName = 'Automated Message'
    }
    return senderName.length ? senderName : '';
  };

  const getSenderUserData = (): {
    userId?: number;
    userType: string;
  } => {
    if (props.senderType?.toLowerCase() == 'user') {
      return {
        userId: props?.senderUser?.id,
        userType: GROUP_MEMBER_TYPE.USER,
      };
    } else if (props.senderType?.toLowerCase() == 'contact') {
      return {
        userId: props?.senderContact?.id,
        userType: GROUP_MEMBER_TYPE.CONTACT,
      };
    }
    return {} as any;
  };
  const userData = getSenderUserData();

  const handleMorePopover = () => {
    if (!props.uuid) {
      return;
    }
    setShowDrawer({
      ...showDrawer,
      visible: !showDrawer.visible,
    });
  };

  const handleAttachmentPopover = () => {
    setShowDrawer({
      ...showDrawer,
      attachmentActionVisible: !showDrawer?.attachmentActionVisible,
    });
  };


  const isDisplayCallMsgHeader =
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD)

  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);

  const handleResendMessage = () => {
    setStateData((prev) => {
      return {
        ...prev,
        resendLoading: true,
      };
    });
    const message = item.displayContent || '';
    const eventBus = EventBus.getEventBusInstance();
    const conversationData = props.conversation;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    const formData = new FormData();
    const echoId = getMsgEchoId();
    formData.append('echo_id', echoId);
    formData.append('private', '');
    formData.append('cc_emails', '');
    formData.append('bcc_emails', '');
    formData.append('content', message);
    if (
      groupMemberCodeIdObj &&
      Object.keys(groupMemberCodeIdObj)?.length &&
      message
    ) {
      const userMentionIds = getUserIdListFromMsgContent(
        message,
        groupMemberCodeIdObj
      );
      if (userMentionIds?.length) {
        formData.append('user_mention_ids', userMentionIds.toString());
      }
    }
    if (onMessageAction) {
      onMessageAction(MessageActionID.confirmedDelete, item);
    }
    eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.SEND_MESSAGE, {
      accountId,
      conversationId,
      formData,
      message,
      echoId,
      selectedConversation: {
        uuid: conversationData?.uuid,
        displayId: conversationData?.displayId
      }
    });
  }

  const onRemoveCommunicationType = (id: string) => {
    const currentTypes: ICommunicationType[] =
      stateData.communicationTypes || [];
    const remainingTypes = currentTypes.filter((item) => item.id !== id);
    setStateData((prev) => {
      return {
        ...prev,
        communicationTypes: remainingTypes,
      };
    });
    if (props.onAddCommunicationType) {
      props.onAddCommunicationType({
        messageUuid: item.uuid,
        types: remainingTypes,
      });
    }
  }

  const isCheckBoxVisible = () => {
    return (
      (props.isCopyModeStateOnCode === COPY_MESSAGE_CODE.COPY_MODE_ON ||
        props.isCopyModeStateOnCode === COPY_MESSAGE_CODE.COPY_MODE_COUNT) &&
      !(conversationMessage?.contentType == MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
        conversationMessage?.contentType === MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD)
    );
  };

  const getMsgElemFromMsgType = (): JSX.Element => {
    let msgElem = <></>;

    if (position == 'left') {
      mainContainerStyle = {
        flexDirection: 'row',
        // marginLeft: 30,
        alignItems: 'flex-start',
      };
      positionCls = [
        showConversationTaskCount && taskCount
        ? isEFaxInbox ? styles.rceEfaxMboxWithTask : styles.rceMboxWithTask
        : isEFaxInbox ? styles.rceEfaxMbox : styles.rceMbox,
        styles.rceMboxLeft,
        {
          backgroundColor: props.private ? Colors.secondary['500'] : 'white',
        },
      ];
    } else {
      mainContainerStyle = {
        flexDirection: 'row-reverse',
        paddingRight: isCheckBoxVisible() ? isRightAvatarShow ? 60 : 30 : isRightAvatarShow ? 30 : 5,
        alignItems: 'flex-end',
        transition: 'all 0.3s ease-out',
      };
      positionCls = [
        showConversationTaskCount && taskCount
          ? isEFaxInbox ? styles.rceEfaxMboxWithTask : styles.rceMboxWithTask
          : isEFaxInbox ? styles.rceEfaxMbox : styles.rceMbox,
        styles.rceMboxRight,
        {
          backgroundColor: props.private
            ? Colors.secondary['500']
            : Colors.Custom.mainPrimaryPurple,
        },
      ];
    }
    if (messageType === 2) {
      msgElem = <ActionMsg msgString={text} dateStr={dateStr} />;
    } else if (isDisplayCallMsgHeader) {
      msgElem = <MissedCallActionMessage
        dateStr={dateStr}
        item={item}
        conversationMessage={conversationMessage}
        contactInfo={contactInfo}
        selectedConversation={conversation}
        onClinicalNotesOpen={(text?: string) => {
          setShowDrawer((prev) => {
            return {
              ...prev,
              addClinicalNotesOpen: true,
              addClinicalNotesOpenText: text || '',
            };
          });
        }}
      />

    } else {
      msgElem = (
        <View
          style={[
            styles.mainContainer,
            mainContainerStyle,
            {alignItems: 'flex-end'}
          ]}
        >
          {position == 'left' ? (
            <View
              //justifyContent={'center'}
              style={{ marginBottom: 22, marginRight: 6 }}
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                    fontWeight: '500'
                  }
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: userData?.userId || -1,
                  userType: userData?.userType as any,
                  genderCode: genderCode as any,
                  imgSrc: profilePhoto,
                  userName: getMessageSenderName(),
                  isUserDeleted: props?.senderContact?.isDeleted || props?.senderUser?.isDeleted,
                }}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          <View style={styles.rceContainerMbox} key={id}>
            <View
              style={{
                flexDirection: 'row',
                marginLeft: position == 'left' ? 4 : 0,
                marginRight: position != 'left' ? 4 : 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <HStack
                style={{
                  alignItems: 'center',
                }}
              >
                <Text
                  size={'smBold'}
                  color={Colors.Custom.GRAY350}
                  noOfLines={1}
                  {...testID('MessageSenderName')}
                >
                  {getMessageSenderName()}
                </Text>
              </HStack>

              <HStack style={{alignItems: 'center', marginTop: 1, marginLeft: 10}}>
                {stateData.loading ? (
                  <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
                ) : (
                  <MessageCommunicationTypes
                    types={stateData.communicationTypes}
                    isMessageBox
                    messageUuid={item.uuid}
                    onRemoveCommunicationType={(id: string) => {
                      onRemoveCommunicationType(id);
                    }}
                  />
                )}
                {(props?.conversationMessage?.isScheduleMessage ||
                  isScheduleMessage) && (
                    <View ml={2} height={'20px'} width={'20px'} {...testID('image')}>
                    <ScheduleMessageSvg
                      customStrokeColor={Colors.success[500]}
                      isEnabled={true}
                    />
                  </View>
                )}
                <View style={{marginLeft: 5}}>
                  {isWeb() && !props?.isReadOnlyView ? (
                    <>
                      <Popover
                        overlayInnerStyle={{
                          bottom: 3,
                          borderRadius: 16,
                          padding: 0,
                        }}
                        content={
                          <>
                            {messageAction?.actionModel?.actionList?.map(
                              (action: MessageActionID) => {
                                if (
                                  conversationMessage?.contentType ===
                                    MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
                                  conversationMessage?.contentType ===
                                    MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD
                                ) {
                                  if (
                                    action !== MessageActionID.edit &&
                                    action !== MessageActionID.createTask
                                  ) {
                                    return getActionItemView(action);
                                  }
                                } else if (isSenderLoggedInUser) {
                                  if (
                                    action !== MessageActionID.markAsReadUnread
                                  ) {
                                    return getActionItemView(action);
                                  }
                                } else {
                                  return getActionItemView(action);
                                }
                              }
                            )}
                          </>
                        }
                        title=""
                        trigger="click"
                        placement={'bottomRight'}
                        visible={showDrawer.visible}
                        onVisibleChange={handleMorePopover}
                      >
                        {((props?.uuid &&
                          props.uuid?.length > 0) || status === CHAT_DELIVERY_STATUS.API_FAILED) &&
                        !stateData.isMarkLoading ? (
                          <View {...testID('image')}>
                            <MenuHorizontalIcon height={18} width={18}/>
                          </View>
                        ) : (
                          <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
                        )}
                      </Popover>
                    </>
                  ) : null}
                </View>
              </HStack>
            </View>

            <View style={[styles.rceMboxClearPadding]}>
              <View style={styles.rceMboxBody}>
                <View style={[positionCls, {minHeight: 40, marginRight: 1}]}>
                  {(parentData?.uuid || props?.parentMessageUuid) ?  (
                    <Pressable
                      flex={1}
                      onPress={() => {
                        const element = goToMessages(parentData.uuid);
                        if (
                          !element &&
                          props.onParentMsgClick &&
                          props.parentMessage
                        ) {
                          props.onParentMsgClick(props.parentMessage);
                        }
                      }}
                    >
                      <ReplyMessageView
                        isInMessageList={true}
                        selectedReply={parentData}
                        isDeletedMessage={!parentData?.uuid || (!parentData?.attachments?.length && !parentData?.text) }
                      />
                    </Pressable>
                  ) : (
                    <></>
                  )}

                  {getMessageContentElem()}
                  {attachments &&
                  attachments.length &&
                  !isEmailChannelInboxType(inboxTypeCode) ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1,
                        alignItems: 'flex-start',
                        marginHorizontal: 7,
                        marginRight: 30,
                      }}
                      {...testID(TestIdentifiers.messageContent)}
                    >
                      <View flex={1}>
                        <MsgAttachment
                          isNestedMessageEle={true}
                          attachments={attachments}
                          position={position}
                          dateStr={dateStr}
                          messageObjectCallBack={(id) =>
                            messageObjectCallBack(id)
                          }
                          senderFullName={getMessageSenderName()}
                          messageAction={messageAction}
                          getActionItemView={(action: any, attachment: any) =>
                            getActionItemView(action, attachment)
                          }
                          attachmentActionVisible={
                            showDrawer?.attachmentActionVisible
                          }
                          handleAttachmentPopover={handleAttachmentPopover}
                          msgWithAttachment={text ? true : false}
                          communicationTypes={stateData.communicationTypes || []}
                          loading={stateData.loading}
                          private={props?.private}
                        />
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                {showConversationTaskCount && taskCount && taskCount > 0 && (
                  <MessageTaskCountView
                    taskCount={taskCount}
                    onActionPerformed={()=>{
                      if (props?.onViewTaskAction && conversationMessage?.uuid) {
                        props?.onViewTaskAction(conversationMessage?.uuid);
                      }
                    }}
                  />
                )}
              </View>
            </View>

            <HStack
              style={{
                justifyContent: 'flex-end',
              }}
            >
              {!isEmailChannelInboxType(inboxTypeCode) &&
              !isSMSChannelInboxType(inboxTypeCode) &&
              item?.messageData?.createdAt != item?.messageData?.updatedAt ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    {...testID('EditedAgo')}
                    size={'xsMedium'}
                    color={Colors.Custom.messageTimeColor}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {`${
                      isEFaxInbox ? 'Patient Context Edited On ' : 'Edited '
                    }`}
                    {moment(
                      item?.updatedDateStr ?? item?.messageData?.updatedAt
                    ).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
                  </Text>
                </View>
              ) : (
                <Text
                  {...testID('DateString')}
                  size={'xsMedium'}
                  color={Colors.Custom.messageTimeColor}
                  fontSize={12}
                  fontWeight={400}
                >
                  {moment(
                    dateStr,
                    DATE_FORMATS.DISPLAY_TIME_FORMAT,
                    true
                  ).isValid()
                    ? dateStr
                    : moment(dateStr).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
                </Text>
              )}
              {isSMSNotSent(status) && isSMSChannelInboxType(inboxTypeCode) && (
                <>
                  {stateData.resendLoading ? (
                    <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
                  ) : (
                    <>
                        {
                          item?.errorData?.customMessage || item?.errorData?.message ? (
                        <HStack style={styles.resendBtnContainerStyle}>
                          <Tooltip
                            title={
                              item?.errorData?.customMessage ||
                              item?.errorData?.message
                            }
                            placement={'top'}
                          >
                            <HStack
                              alignContent={'center'}
                              alignItems={'center'}
                            >
                              <View {...testID('image')}>
                                <StatusErrorSvg />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: '600',
                                    color: '#D92D20',
                                    marginLeft: 4,
                                  }}
                                  {...testID('Failed')}
                                >
                                  Failed
                                </Text>
                              </View>
                            </HStack>

                          </Tooltip>
                        </HStack>
                          ) :
                            (<></>)
                        }
                    </>
                  )}
                  {/* {stateData.resendLoading ? (
                    <Spinner />
                  ) : (
                    <Pressable
                      onPress={() => {
                        handleResendMessage();
                      }}
                    >
                      <HStack style={styles.resendBtnContainerStyle}>
                        <StatusErrorSvg />
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: '600',
                            color: '#D92D20',
                            marginLeft: 4,
                          }}
                          {...testID('Resend')}
                        >
                          Resend
                        </Text>
                      </HStack>
                    </Pressable>
                  )} */}
                </>
                )
              }
              {status === CHAT_DELIVERY_STATUS.API_FAILED && (
                <HStack style={styles.resendBtnContainerStyle}>
                  <StatusErrorSvg />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      color: '#D92D20',
                      marginLeft: 4,
                    }}
                    {...testID('Failed')}
                  >
                    Failed
                  </Text>
                </HStack>
              )}

              {position === 'right' && (
                <>
                  {(isSenderLoggedInUser && isGroupConversation(conversation)) ? (
                    <HStack style={{ alignSelf: 'flex-end', marginLeft: 2 }} {...testID(isSeen ? 'MessageRead' : 'MessageUnread')}>
                      {seenByMembers()}
                    </HStack>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </HStack>
          </View>

          {isRightAvatarShow && position == 'right' ? (
            <View
              style={{
                marginBottom: 22,
                position: 'absolute',
                right: isCheckBoxVisible() ? 28 : 0,
              }}
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                    fontWeight: '500'
                  }
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: userData?.userId || -1,
                  userType: userData?.userType as any,
                  genderCode: genderCode as any,
                  imgSrc: profilePhoto,
                  userName: getMessageSenderName(),
                  isUserDeleted: props?.senderContact?.isDeleted || props?.senderUser?.isDeleted,
                }}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          {isCheckBoxVisible() ? (
            <View
              style={[
                styles.copyCheckBoxStyle,
                {
                  alignSelf: 'center',
                },
              ]}
              {...testID(TestIdentifiers.checkbox)}
            >
              <Checkbox
                className="copy-checkbox"
                onChange={(item) => {
                  const tempData = {
                    text: text,
                    date: dateStr,
                    name:
                      getMessageSenderName()?.trim()?.toLocaleLowerCase() == 'you' ? userName : getMessageSenderName(),
                    id: id,
                    userId: userData?.userId,
                    isChecked: item?.target?.checked,
                    uuid: uuid,
                  };
                  props.handleCopyClick?.(tempData);
                }}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      );
    }
    return msgElem;
  };

  const getMessageContentElem = () => {
    if (isWeb() && inboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      let fullHtmlEmailBody: IConversationMessageContentAttributes = {} as any;
      try {
        if (typeof conversationMessage?.contentAttributes === 'object') {
          fullHtmlEmailBody = conversationMessage?.contentAttributes;
        } else {
          fullHtmlEmailBody = JSON.parse(conversationMessage?.contentAttributes);
        }
      } catch (error) {
        fullHtmlEmailBody = {} as any;
      }
      let fromEmail = '';
      if (fullHtmlEmailBody?.email?.from?.length) {
        fromEmail = fullHtmlEmailBody?.email?.from?.join(',');
      } else if (fullHtmlEmailBody.from?.length) {
        fromEmail = fullHtmlEmailBody.from;
      }

      let toEmail = '';
      if (fullHtmlEmailBody?.email?.to?.length) {
        toEmail = fullHtmlEmailBody?.email?.to?.join(',');
      } else if (fullHtmlEmailBody.to?.length) {
        if (typeof fullHtmlEmailBody.to === 'object') {
          toEmail = fullHtmlEmailBody?.to?.join(',');;
        } else {
          toEmail = fullHtmlEmailBody.to;
        }
      } else if (fullHtmlEmailBody?.To?.length) {
        if (typeof fullHtmlEmailBody.To === 'object') {
          toEmail = fullHtmlEmailBody?.To?.join(',');;
        } else {
          toEmail = fullHtmlEmailBody.To;
        }
      }

      if (toEmail || fromEmail) {
        let finalHtmlString = text;
        if (fullHtmlEmailBody?.email?.html_content?.full) {
          finalHtmlString = removeScriptsAndStyles(
            fullHtmlEmailBody?.email?.html_content?.full
          );
        } else if (fullHtmlEmailBody?.emailBody) {
          finalHtmlString = removeScriptsAndStyles(
            fullHtmlEmailBody?.emailBody
          );
        }
        const isHtml = isHTMLString(finalHtmlString);

        if (position == 'left') {
          return (
            <VStack {...testID(TestIdentifiers.messageContent)}>
              <VStack pb={2}>
                <HStack>
                  <VStack>
                    <Text {...testID('From')} fontSize={'md'}>From:</Text>
                  </VStack>
                  <VStack>
                    <Text {...testID('FromEmail')} ml={3}>{fromEmail}</Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack>
                    <Text {...testID('To')} fontSize={'md'}>To:</Text>
                  </VStack>
                  <VStack ml={3}>
                    <Text {...testID('ToEmail')}>{toEmail}</Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack>
                    <Text {...testID('Subject')} fontSize={'md'}>Subject:</Text>
                  </VStack>
                  <VStack ml={3}>
                    <Text
                      style={{
                        overflow: 'hidden',
                        width: '60%',
                      }}
                      numberOfLines={1}
                      textOverflow={'ellipsis'}
                      {...testID('SubjectContent')}
                    >
                      {fullHtmlEmailBody?.subject || fullHtmlEmailBody?.email?.subject || ''}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
              <Divider />
              <HStack style={{width: '100%'}} flex={1}>
                <EmailHtml html={finalHtmlString} />
              </HStack>
              <HStack>
                <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                  <MsgAttachment
                    attachments={attachments}
                    position={position}
                    dateStr={''}
                    senderFullName={getMessageSenderName()}
                    messageAction={messageAction}
                    getActionItemView={(action: any, attachment: any) =>
                      getActionItemView(action, attachment)
                    }
                    attachmentActionVisible={
                      showDrawer?.attachmentActionVisible
                    }
                    handleAttachmentPopover={handleAttachmentPopover}
                    communicationTypes={stateData.communicationTypes || []}
                    loading={stateData.loading}
                    private={props?.private}
                  />
                </View>
              </HStack>
            </VStack>
          );
        } else {
          return (
            <VStack>
              <HStack {...testID(TestIdentifiers.messageContent)}>
                {isHtml ? (
                  <EmailHtml html={finalHtmlString}/>
                ) : (
                  <Content className="message-box-viewer message-box-font-color">
                    <HStack style={{width: '100%'}} flex={1} {...testID('MessageHtmlString')}>
                      <EmailHtml html={finalHtmlString} />
                    </HStack>
                  </Content>
                )}
              </HStack>
              <HStack>
                <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                  <MsgAttachment
                    attachments={attachments}
                    position={position}
                    dateStr={''}
                    senderFullName={getMessageSenderName()}
                    messageAction={messageAction}
                    getActionItemView={(action: any, attachment: any) =>
                      getActionItemView(action, attachment)
                    }
                    attachmentActionVisible={
                      showDrawer?.attachmentActionVisible
                    }
                    handleAttachmentPopover={handleAttachmentPopover}
                    communicationTypes={stateData.communicationTypes || []}
                    loading={stateData.loading}
                    private={props?.private}
                  />
                </View>
              </HStack>
            </VStack>
          );
        }
      } else {
        const isTextHtml = isHTMLString(text)
        const newText = isTextHtml ? text : text.replace(/\n/g, '<br>');
        return (
          <VStack>
            <HStack {...testID(TestIdentifiers.messageContent)}>
              {
                position == 'left' ?
                  (
                    <Content
                      className={`message-box-viewer ${props.private ? 'message-box-font-color' : ''
                        }`}
                    >
                      <HStack style={{width: '100%'}} flex={1}>
                        <EmailHtml html={newText} />
                      </HStack>
                    </Content>
                  )
                  :
                  (
                    <Content className="message-box-viewer message-box-font-color">
                      <HStack style={{width: '100%'}} flex={1}>
                        <EmailHtml html={newText} />
                      </HStack>
                    </Content>
                  )
              }

            </HStack>
            <HStack>
              <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                <MsgAttachment
                  attachments={attachments}
                  position={position}
                  dateStr={''}
                  senderFullName={getMessageSenderName()}
                  messageAction={messageAction}
                  getActionItemView={(action: any, attachment: any) =>
                    getActionItemView(action, attachment)
                  }
                  attachmentActionVisible={showDrawer?.attachmentActionVisible}
                  handleAttachmentPopover={handleAttachmentPopover}
                  communicationTypes={stateData.communicationTypes || []}
                  loading={stateData.loading}
                  private={props?.private}
                />
              </View>
            </HStack>
          </VStack>
        );
      }
    } else if (
      conversationMessage?.contentType === 12 ||
      conversationMessage?.contentType === 'html'
    ) {
      return <View {...testID(TestIdentifiers.messageContent)}>{parse(text)}</View>;
    } else {
      return getPlainMsgContentElem();
    }
  };

  const getPlainMsgContentElem = () => {
    let title: any;
    let subTitle
    const url = detectURLs(text);
    const textUrlString = text
    if (url) {
      const articalInfo = getArticleFromMessageText(text);
      title = articalInfo[0] || '';
      subTitle = articalInfo[1] || '';
    }
    if (position == 'left') {
      return (
        <>
          {conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
            conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD ? (
            <MessagingCustomCard
              conversationMessage={conversationMessage}
              navigation={navigation}
            />
          ) : (
            (isEFaxChannelInboxType(conversation?.conversationInbox?.channelType) && !props?.private) ?
              <EFaxMessageBox
                messagePosition={position}
                conversationMessage={conversationMessage}
              />
            :
            <Content
              className={`${url ? 'link' : 'message-box-viewer'} ${props.private ? 'message-box-font-color' : ''
                }`}
              data-testid={TestIdentifiers.contentViewer}
            >
              {/* <Pressable
                onPress={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if(url){
                    Linking.openURL(url?.toString());
                  }
                }}
              > */}
              <Viewer
                linkAttributes={{
                  target: '_blank',
                }}
                extendedAutolinks={true}
                initialValue={textUrlString}
              />
              {/* </Pressable> */}
            </Content>
          )}
        </>
      );
    } else {
      return (
        <>
          {conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
            conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD ? (
            <MessagingCustomCard
              conversationMessage={conversationMessage}
              textColor={'white'}
              navigation={navigation}
            />
          ) : (
            (isEFaxChannelInboxType(conversation?.conversationInbox?.channelType) && !props?.private) ?
              <EFaxMessageBox
                messagePosition={position}
                conversationMessage={conversationMessage}
              />
            :
            <Content className="message-box-viewer message-box-font-color"
              data-testid={TestIdentifiers.contentViewer}>
              {/* <Pressable
                    onPress={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if(url){
                        Linking.openURL(url.toString());
                      }
                    }}
                  > */}
              <Viewer
                linkAttributes={{
                  target: '_blank',
                }}
                extendedAutolinks={true}
                initialValue={textUrlString}
              />
              {/* </Pressable> */}
            </Content>
          )}
        </>
      );
    }
  };

  const seenByMembers = () => {
    if (isSeen && !props?.groupMemberDataLoading) {
      return <ReadMsgSvg />;
    } else if (!props?.groupMemberDataLoading) {
      return <SendMesgSvg />;
    }
  };

  const getReadMessageStatus = () => {
    return position === 'right' ? (
      <>
        {isSenderLoggedInUser && isGroupConversation(conversation) ? (
          <HStack style={{marginLeft: 2 }} {...testID(isSeen ? 'MessageRead' : 'MessageUnread')}>
            {seenByMembers()}
          </HStack>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );
  };

  const getMsgElem = (): JSX.Element => {
    let msgTextElem = <></>;
    let msgAttachmentElem = <></>;
    if (text || conversation?.conversationInbox?.channelType === CHANNEL_TYPE.CHANNEL_EFAX ) {
      msgTextElem = getMsgElemFromMsgType();
    }
    if (attachments && attachments.length) {
      msgAttachmentElem = (
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            alignItems: 'flex-end',
            marginRight: position == 'left' ? 30 : isCheckBoxVisible() ? 30 : 0,
          }}
        >
          {position == 'left' ? (
            <View
              style={{marginBottom: 24, marginRight: -8}}
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                    fontWeight: '500'
                  }
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: userData?.userId || -1,
                  userType: userData?.userType as any,
                  genderCode: genderCode as any,
                  imgSrc: profilePhoto,
                  userName: getMessageSenderName()
                }}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          <View flex={1}
          style={{flexDirection: 'column'}}
          {...testID(TestIdentifiers.messageContent)}>
            <MsgAttachment
              attachments={attachments}
              position={position}
              dateStr={dateStr}
              messageObjectCallBack={(id) => messageObjectCallBack(id)}
              senderFullName={getMessageSenderName()}
              messageAction={messageAction}
              getActionItemView={(action: any, attachment: any) =>
                getActionItemView(action, attachment)
              }
              attachmentActionVisible={showDrawer?.attachmentActionVisible}
              handleAttachmentPopover={handleAttachmentPopover}
              communicationTypes={stateData.communicationTypes || []}
              loading={stateData.loading}
              private={props?.private}
              readMessageStatusView={getReadMessageStatus()}
            />
          </View>
          {isRightAvatarShow && position == 'right' ? (
            <View
              style={{
                marginBottom: 26,
              }}
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '7',
                  textStyle: {
                    fontSize: 12,
                    fontWeight: '500'
                  }
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: userData?.userId || -1,
                  userType: userData?.userType as any,
                  genderCode: genderCode as any,
                  imgSrc: profilePhoto,
                  userName: getMessageSenderName(),
                  isUserDeleted: props?.senderContact?.isDeleted || props?.senderUser?.isDeleted,
                }}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      );
    }
    if (messageType === 2) {
      return (
        <>
          {msgTextElem}
          {!text ? msgAttachmentElem : null}
        </>
      );
    } else if (isDisplayCallMsgHeader) {
      return (
        <>
          {msgTextElem}
          {!text ? msgAttachmentElem : null}
        </>
      );
    } else {
      return (
        <VStack>
          <HStack>{msgTextElem}</HStack>
          <HStack>{!text ? msgAttachmentElem : null}</HStack>
          {showDrawer.showAddNoteModal && id === showDrawer.messageData.id && (
            <AddNoteView
              selectedData={{content: text}}
              contactId={contactData?.id}
              onFormActionPerformed={(actionCode: string) => {
                onFormActionPerformed(actionCode);
              }}
            />
          )}

          {showDrawer.showAssignTypeModal && (
            <AssignCommunicationTypeDrawer
              isOpen={showDrawer.showAssignTypeModal}
              contactUuid={contactData.uuid || ''}
              messageUuids={[props?.item?.uuid]}
              onSave={() => {
                setShowDrawer((prev) => {
                  return {
                    ...prev,
                    showAssignTypeModal: false,
                  };
                });
                fetchCommunicationTypesByMessageUuid();
              }}
              onClose={() => {
                setShowDrawer((prev) => {
                  return {
                    ...prev,
                    showAssignTypeModal: false,
                  };
                });
              }}
            />
          )}

        </VStack>
      );
    }
  };

  return (
    <View nativeID={props.uuid}>
      {isDisplaySectionMsgHeader && (
        <SectionMsgHeader dateStr={dateStr} msgString={''} />
      )}
      {getMsgElem()}
      {/* {showDrawer.showAddTaskModal && showDrawer.messageData.id === id && (
        <GetUserPoolData
          setUserPool={(poolData: any) => {
            setAllUserPoolSelect(poolData);
          }}
        />
      )} */}
      {showDrawer.addClinicalNotesOpen && (
        <CreateNoteFromMessage
          conversationUUID={conversation?.uuid || ''}
          messageIds={[conversationMessage?.uuid]}
          isVisible={showDrawer.addClinicalNotesOpen}
          copiedMessages={showDrawer.addClinicalNotesOpenText}
          onClose={() => {
            setShowDrawer((prev) => {
              return {
                ...prev,
                addClinicalNotesOpen: false,
                addClinicalNotesOpenText: '',
              };
            });
          }}
          contactInfo={contactInfo}
        />
      )}
    </View>
  );
};

export default MessageBox;
