import {useQuery} from '@apollo/client';
import {Collapse} from 'antd';
import {HStack, Text, View, VStack} from 'native-base';
// import {deviceData} from '../../../../dummyData/personLeftContainerData';
import ContactDetailsQueries from '../../../../services/Conversations/ContactDetailsQueries';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import TaskIconOther from '../../../common/Svg/TaskIconOther';
import {IConversationData} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getFormattedDateString} from './OtherDeatilsUtils';
import {styles} from './OtherDetailsStyles';
const {Panel} = Collapse;

interface DevicePropertiesViewProps {
  conversationData?: IConversationData;
  style?: any;
  isDefaultClose?: boolean;
}
export const DevicePropertiesView = (props: DevicePropertiesViewProps) => {
  // const dataKeys = Object.keys(deviceData);
  const {conversationData, style} = props;
  const {data} = useQuery(ContactDetailsQueries.GetConversationDetail, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      id: conversationData?.id,
    },
  });
  const browser = data?.conversation?.additionalAttributes?.browser;
  const initiated_at = data?.conversation?.additionalAttributes?.initiated_at;
  const referer = data?.conversation?.additionalAttributes?.referer;
  // const deviceKey = Math.random();

  const renderContent = () => {
    return (
      <View
        style={[
          {backgroundColor: '#ffffff', marginHorizontal: 24, borderRadius: 8},
        ]}
      >
        {' '}
        <HStack flex={1} style={[styles.displayListItem]}>
          <VStack flex={0.3} style={styles.keyContainer}>
            <DisplayText
              size={'smNormal'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={'browser'}
            />
          </VStack>
          <VStack flex={0.7} style={styles.valueContainer}>
            <Text size={'smNormal'} style={{color: Colors.Custom.Gray500}}>
              {browser?.browser_name ? browser?.browser_name : '--'}
            </Text>
          </VStack>
        </HStack>
        <HStack flex={1} style={[styles.displayListItem]}>
          <VStack flex={0.3} style={styles.keyContainer}>
            <DisplayText
              size={'smNormal'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={'platform'}
            />
          </VStack>
          <VStack flex={0.7} style={styles.valueContainer}>
            <Text size={'smNormal'} style={{color: Colors.Custom.Gray500}}>
              {browser?.platform_name ? browser?.platform_name : '--'}{' '}
              {browser?.platform_version ? browser?.platform_version : ''}
            </Text>
          </VStack>
        </HStack>
        <HStack flex={1} style={[styles.displayListItem]}>
          <VStack flex={0.3} style={styles.keyContainer}>
            <DisplayText
              size={'smNormal'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={'origin'}
            />
          </VStack>
          <VStack flex={0.7} style={styles.valueContainer}>
            <Text
              size={'smNormal'}
              style={{color: Colors.Custom.Gray500}}
              wordBreak={'break-all'}
            >
              {referer ? referer : '--'}
            </Text>
          </VStack>
        </HStack>
        <HStack flex={1} style={[styles.displayListItem]} alignItems={'center'}>
          <VStack flex={0.3} style={styles.keyContainer}>
            <DisplayText
              size={'smNormal'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={'initiatedAt'}
            />
          </VStack>
          <VStack flex={0.7} style={styles.valueContainer}>
            <Text size={'smNormal'} style={{color: Colors.Custom.Gray500}}>
              {initiated_at?.timestamp
                ? getFormattedDateString(initiated_at?.timestamp)
                : '--'}
            </Text>
          </VStack>
        </HStack>
      </View>
    );
  };
  return (
    <>
      {/* <Collapse
      // defaultActiveKey={'DEVICE_1' + deviceKey}
      defaultActiveKey={''}
      style={style ? style : {marginBottom: 6}}
      expandIconPosition={'right'}
    >
      <Panel
        header={
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{marginRight: 10}}>
              <TaskIconOther />
            </View>
            <DisplayText
              size={'lgMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
              textLocalId={'deviceInfo'}
            />
          </View>
        }
        key={'TAG_1'}
      >

      </Panel>
    </Collapse> */}
      <View
        style={{
          backgroundColor: Colors.Custom.Gray50,
        }}
      >
        <View style={{padding: 24}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 9,
              }}
            >
              <View style={{marginRight: 10}}>
                <TaskIconOther />
              </View>
              <DisplayText
                size={'xlBold'}
                extraStyles={{color: Colors.primary['700'] || ''}}
                textLocalId={'deviceInfo'}
              />
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
    </>
  );
};
