import React from "react";
import { isWeb } from "../../../utils/platformCheckUtils";

const CrossSvgIcon = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
  ? props.customActiveColor
    ? props.customActiveColor
    : ''
  : props.customColor
  ? props.customColor
  : '#D94D55';
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{width: 'inherit', height: 'inherit'}}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18 6L6 18M6 6l12 12"
          ></path>
        </svg>
      ) : null}
    </>
  );
}

export default CrossSvgIcon;
