import { gql } from "@apollo/client";

const GET_WORKFLOW_EXECUTION_LOG = `
query getWorkflowExecutionLogs($workflowMasterId: uuid, $limit: Int, $offset: Int, $resourceType: String) {
  workflowExecutionLogs(limit: $limit, offset: $offset, where: {workflowMasterId: {_eq: $workflowMasterId}}, order_by: [{createdOn: desc}]) {
    workflowExecutionId
    workflowId
    workflowMasterId
    createdOn
    resourceLevelLog(limit:1,offset:0,distinct_on: [resourceId], where: {resourceType: {_eq: $resourceType}}) {
      resourceType
      resourceId
    }
  }
  aggregateWorkflowExecutionLog(order_by: [{createdOn: desc}], where: {workflowMasterId: {_eq: $workflowMasterId}}) {
    aggregate {
      count
    }
  }
}
`;

const GET_EMAIL_COMMUNICATION_LOG = `
query getEmailCommunicationLog($serviceLogBoolExp: serviceLog_bool_exp, $limit: Int, $offset: Int) {
  serviceLogs(limit: $limit, offset: $offset, where: $serviceLogBoolExp, order_by: [{createdOn: desc}]) {
    id
    workflowExecutionId
    createdOn
    logType
    status
    workflowMasterId
    metadata
    emailAnalyticTag {
      logs {
        eventCode
      }
    }
    resourceLevelLog(where:{resourceType:{_in:["CONTACT", "USER"]}}) {
      resourceType
      resourceId
    }
  }
  aggregateServiceLog(order_by: [{createdOn: desc}], where: $serviceLogBoolExp) {
    aggregate {
      count
    }
  }
  
}
`;

const GET_EMAIL_COMMUNICATION_LOG_V1 = `
query getEmailCommunicationLog($status:[String!]!,$serviceLogBoolExp: serviceLog_bool_exp!, $limit: Int, $offset: Int) {

 

  completed:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"COMPLETE"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }
  failed:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"ERROR"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }
  inprocess:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"IN_PROGRESS"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }

  serviceLogs(limit: $limit, offset: $offset, where:{_and:[$serviceLogBoolExp,{status:{_in:$status}}]}, order_by: [{createdOn: desc}]) {
    id
    workflowExecutionId
    createdOn
    logType
    status
    workflowMasterId
    metadata
    emailAnalyticTag {
      logs {
        eventCode
        body
      }
      opened:logs_aggregate(where:{eventCode:{_eq:"opened"}}) {
        aggregate {
          count
        }
      }
      clicked:logs_aggregate(where:{eventCode:{_eq:"clicked"}}) {
        aggregate {
          count
        }
      }
    }
    resourceLevelLog(where:{resourceType:{_in:["CONTACT","USER"]}}) {
      resourceType
      resourceId
    }
  }
  aggregateServiceLog(order_by: [{createdOn: desc}], where: $serviceLogBoolExp) {
    aggregate {
      count
    }
  }
}
`;

const GET_EMAIL_COMMUNICATION_LOG_METADATA = `
query getEmailCommunicationLog($serviceLogBoolExp: serviceLog_bool_exp, $limit: Int, $offset: Int) {
  serviceLogs(limit: $limit, offset: $offset, where: $serviceLogBoolExp, order_by: [{createdOn: desc}]) {
    metadata
  }
}
`;

const GET_EMAIL_COMMUNICATION_LOG_BY_CAMPEIGN_ID = `
query getEmailCommunicationLog($campaignId: uuid, $limit: Int, $offset: Int) {
  serviceLogs(limit: $limit, offset: $offset, where: {logType:{_in:["EMAIL_SEND", "PUSH_NOTIFICATION_SEND","SMS_SEND"]},campaignId: {_eq: $campaignId}}, order_by: [{createdOn: desc}]) {
    id
    workflowExecutionId
    createdOn
    logType
    status
    workflowMasterId
    metadata
    resourceLevelLog(where:{resourceType:{_in:["CONTACT", "USER"]}}) {
      resourceType
      resourceId
    }
  }
  aggregateServiceLog(order_by: [{createdOn: desc}], where: {logType:{_in:["EMAIL_SEND", "PUSH_NOTIFICATION_SEND","SMS_SEND"]},campaignId: {_eq: $campaignId}}) {
    aggregate {
      count
    }
  }
}
`;

const GET_NODE_LEVEL_EXECUTION_STATUS = `
query getNodeLevelExecutionStatus($resourceLevelLogBoolExp: resourceLevelLog_bool_exp) {
  aggregateResourceLevelLog(distinct_on: [resourceType, resourceId, workflowExecutionId], where: $resourceLevelLogBoolExp) {
    aggregate {
      count
    }
  }
}
`;


const GET_NODE_LEVEL_EXECUTION_STATUS_WITH_TIME = `
query getNodeLevelExecutionStatusWithTime($nodeLogBoolExp: nodeLog_bool_exp) {
  nodeLogs(distinct_on:[status],where: $nodeLogBoolExp) {
    status
  }
  timeSaveInSeconds: aggregateNodeLog(where: $nodeLogBoolExp) {
    aggregate {
      sum {
        timeSaveInSeconds
      }
    }
  }
}
`;

const CHECK_STEP_FUNCTION_REQUEST_QUEUE_STATUS  = `
query checkStepFunctionRequestQueueStatus($queueId: uuid!) {
  stepFunctionRequestQueue(id: $queueId) {
    executionStatus
  }
}

`

export const GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_ID = `
  query nodeLog($workflowId: uuid!) {
    nodeLogs(where: {workflowExecutionLog: {workflowId: {_eq: $workflowId}}}) {
      id
      nodeId
      status
    }
  }
`;

const GET_TOTAL_TIME_SAVED_BY_WORKFLOW = `
query getTotalTimeSavedByWorkflow($nodeLogBoolExp: nodeLog_bool_exp){
  timeSaveInSeconds: aggregateNodeLog(where: $nodeLogBoolExp) {
    aggregate {
      sum {
        timeSaveInSeconds
      }
    }
  }
}
`;

const GET_NODE_LEVEL_EXECUTION_COUNT = `
query getNodeLevelExecutionStatus( $resourceLevelLogBoolExp: resourceLevelLog_bool_exp) {
  aggregateResourceLevelLog(distinct_on: [resourceType, resourceId, workflowExecutionId], where: $resourceLevelLogBoolExp) {
    aggregate {
      count
    }
  }
}`;


const GET_NODE_LEVEL_RESOURCE_LOG_BY_BOOL_EXP = `
  query getResourceLog($offset:Int, $limit:Int,$resourceLevelLogBoolExp: resourceLevelLog_bool_exp) {
    resourceLevelLogs(offset:$offset,limit:$limit,distinct_on: [resourceType, resourceId, workflowExecutionId], where: $resourceLevelLogBoolExp) {
      id
      resourceId
      resourceType
      createdOn
      workflowExecutionId
      nodeLog {

        status
      }
    }
  }
`;

const GET_SERVICE_LOGS = `
  query getServiceLogs($where: serviceLog_bool_exp) {
    serviceLogs(where: $where) {
      campaignId
      resourceLevelLog(distinct_on: [resourceId], where: {resourceType: {_eq: "CONTACT"}}) {
        resourceType
        resourceId
      }
      workflowMasterId
      tenantId
    }
  }
`;

const GET_SERVICE_LOGS_BY_WORKFLOW_EXECUTION_ID = `
  query getServiceLogsByWorkflowExecutionID($where: serviceLog_bool_exp!) {
    serviceLogs (where:$where){
      id
      logType
      emailAnalyticTag {
        logs {
          body
          eventCode
        }
      }
      status
      metadata
      createdOn
    }
  }
`;

export const GET_CAMPAIGN_BY_RESOURCE_ID = `
  query GetCampaignByResourceId($contactId: String) {
    campaignList: serviceLogs(
      distinct_on: [campaignId]
      where: {
        campaignId: {_is_null: false}
        resourceLevelLog: {
          resourceType: {_eq: "CONTACT"}
          resourceId: {_eq: $contactId}
        }
      }
    ) {
      campaign {
        name
      }
    }
  }
`;

export const GET_WORKFLOW_MASTER_ID_BY_CONTACT_ID = `
  query GetWorkflowMasterIdByContactId($contactId: String) {
    serviceLogs(
      distinct_on: [workflowMasterId]
      where: {
        resourceLevelLog: {
          resourceId: {_eq: $contactId}
          resourceType: {_eq: "CONTACT"}
        }
      }
    ) {
      workflowMasterId
    }
  }
`;

export const GET_WORKFLOW_BY_WORKFLOW_MASTER_ID = `
  query GetWorkflowByWorkflowMasterId($workflowMasterIdList: [uuid!]) {
    workflows(
      where: {
        workflowMasterId: {_in: $workflowMasterIdList}
        isDeleted: {_eq: false}
      }
    ) {
      name
    }
  }
`;

export const GET_DASHBOARD_GENDER_GROUP = gql`
query GetGenderGroupCount($gender: String, $tenantId: Int) {
  contactPersonsAggregate(where: {gender: {code: {_eq: $gender}},accountId: {_eq: $tenantId}}) {
    aggregate {
      count
    }
  }
}
`;

export const getDynamicQuery = (query: string): any => {
  return gql`
    ${query}
  `;
};

export const GET_EXECUTED_WORKFLOWS = `
  query getExecutedAppointmentWorkflows(
    $resourceId: String
    $resourceType: String
    $tenantId: uuid
  ) {
    workflowExecutionLogs(
      where: {
        serviceLog: {
          resourceLevelLog: {
            resourceType: {_eq: $resourceType}
            resourceId: {_eq: $resourceId}
          }
        }
        tenantId: {_eq: $tenantId}
      }
    ) {
      workflow {
        name
        tenantId
        entity
        createdOn
        description
      }
      serviceLog {
        resourceLevelLog {
          resourceId
          resourceType
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_RESOURCE_ID_LIST = `
  query GetCampaignByResourceIdList($contactIdList: [String!]!) {
    campaignList: serviceLogs(
      distinct_on: [campaignId]
      where: {
        campaignId: {_is_null: false}
        resourceLevelLog: {
          resourceType: {_eq: "CONTACT"}
          resourceId: {_in: $contactIdList}
        }
      }
    ) {
      resourceLevelLog {
        resourceId
      }
      campaign {
        name
        status
      }
    }
  }
`;

export const GET_RESOURCE_LEVEL_LOGS = `
  query GetResourceLevelLogs($where: resourceLevelLog_bool_exp) {
    resourceLevelLogs(distinct_on: [resourceId], where: $where) {
      resourceId
    }
  }
`;

export const GET_WORKFLOW_EXECUTION_ID_BY_WORKFLOW_ID = `
  query GetWorkflowExecutionIdByWorkflowId($workflowId: uuid!) {
    workflowExecutionLogs(where: {workflowId: {_eq: $workflowId}}, order_by: {createdOn: desc_nulls_last}, limit: 1) {
      workflowExecutionId
    }
  }
`;

export const GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_EXECUTION_ID = `
  query GetNodeLevelExecutionStatusByWorkflowExecutionId($workflowExecutionId: uuid!) {
    nodeLogs(where: {workflowExecutionId: {_eq: $workflowExecutionId}}) {
      id
      nodeId
      status
      workflowExecutionId
      tenantId
      createdOn
    }
  }
`;

export const GET_WORKFLOW_LIST_FOR_TABLE_VIEW =`
  query getWorkflowList($limit:Int!, $offset:Int!, $boolExp:workflow_bool_exp!){

    workflows(limit:$limit,offset:$offset,order_by:{createdOn:desc}, where:$boolExp){
      name
      description
      workflowMasterId
      id
      userId
      isEnabled
      flowType
      triggerInfo {
        triggerType
        triggerCondition
      }
    }
    aggregateWorkflow(where:$boolExp) {
      aggregate {
        count
      }
    }
}
`;

export const GET_OPEN_AI_CONVERSATION = `query getOpenAiConversation($offset:Int,$limit:Int,$object: openAiConversation_bool_exp!) {
  openAiConversations(order_by:{createdOn:desc},offset:$offset,limit:$limit,where: $object) {
    id
    content
    userUuid
    actor
    responseType
    createdOn
  }
}
`

export const GET_MICRO_AUTOMATION_USED_COUNT_BY_WORKFLOW_MASTER_ID = `
query getMicroAutomationUsedCountByWorkflowMasterId($microAutomationWorkflowMasterId:uuid!){
  userInputFields(where:{valueAsUuid:{_eq:$microAutomationWorkflowMasterId},fieldType:{_eq:"AUTOMATION_SEARCH"},node:{graph:{workflow:{isDeleted:{_eq:false}}}}}) {
    node {
      graph {
        workflow {
          name
          workflowMasterId
        }
      }
    }
  }
}

`

export const GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID = `query getWorkflowExecutionLog($workflowMasterId: uuid!) {
  workflowExecutionLogs(where: {workflowMasterId: {_eq: $workflowMasterId}, isDeleted: {_eq: false}}, limit: 1) {
    workflowState
    workflowId
    workflowExecutionId
  }
}
`;

export default {
  GET_WORKFLOW_EXECUTION_LOG,
  GET_EMAIL_COMMUNICATION_LOG,
  GET_EMAIL_COMMUNICATION_LOG_METADATA,
  GET_EMAIL_COMMUNICATION_LOG_V1,
  GET_EMAIL_COMMUNICATION_LOG_BY_CAMPEIGN_ID,
  GET_NODE_LEVEL_EXECUTION_STATUS,
  GET_NODE_LEVEL_EXECUTION_COUNT,
  GET_TOTAL_TIME_SAVED_BY_WORKFLOW,
  GET_NODE_LEVEL_RESOURCE_LOG_BY_BOOL_EXP,
  GET_SERVICE_LOGS,
  GET_SERVICE_LOGS_BY_WORKFLOW_EXECUTION_ID,
  GET_CAMPAIGN_BY_RESOURCE_ID,
  GET_WORKFLOW_MASTER_ID_BY_CONTACT_ID,
  GET_WORKFLOW_BY_WORKFLOW_MASTER_ID,
  GET_DASHBOARD_GENDER_GROUP,
  getDynamicQuery,
  GET_EXECUTED_WORKFLOWS,
  GET_CAMPAIGN_BY_RESOURCE_ID_LIST,
  GET_NODE_LEVEL_EXECUTION_STATUS_WITH_TIME,
  GET_RESOURCE_LEVEL_LOGS,
  GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_ID,
  CHECK_STEP_FUNCTION_REQUEST_QUEUE_STATUS,
  GET_WORKFLOW_EXECUTION_ID_BY_WORKFLOW_ID,
  GET_NODE_LEVEL_EXECUTION_STATUS_BY_WORKFLOW_EXECUTION_ID,
  GET_WORKFLOW_LIST_FOR_TABLE_VIEW,
  GET_MICRO_AUTOMATION_USED_COUNT_BY_WORKFLOW_MASTER_ID,
  GET_OPEN_AI_CONVERSATION,
  GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID
};
