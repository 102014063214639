import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Drawer, Button as AntdButton } from 'antd';
import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import {
  Button,
  HStack,
  Select,
  Skeleton,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY } from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {
  FORM_SOURCE,
  FORM_STATUS_CODE,
  TASK_ENTITY_TYPE_CODES
} from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { FormsQueries, TaskQueries } from '../../../services';
import { getAccountUUID, getFormURL, getUserUUID } from '../../../utils/commonUtils';
import { getDateToMomentISOString, getFormTaskDates } from '../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../utils/mlovUtils';
import { ContentTypes} from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import { getAccountMergeTagData, getTemplateCategories, getTemplateCategoryList, getTemplates } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getFormattedEmailTemplateData } from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { ITemplateCategory } from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { getDefaultTaskStatusId } from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { DisplayText } from '../DisplayText/DisplayText';
import { sendEmail } from '../EmailPopupView/EmailPopupViewUtils';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import MultipleFormSearch from '../MultipleFormSearch/MultipleFormSearch';
import { useIntl } from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles';
import { GET_FORM_CATEGORY_ID_BY_CODES } from '../../../services/Forms/FormsQueries';
import { IFormCategory } from '../../RightSideContainer/Forms/interfaces';
import { LeftOutlined } from '@ant-design/icons';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
interface IContactSendFormProps {
  isVisible: boolean;
  assignmentData: {
    patientId: string;
    contactId: string;
    assignedById: string;
    patientEmail: string;
    patientFirstName: string;
    patientContactUUID: string;
    contactTypeCode?: string;
  };
  onActionComplete: () => void;
  onClose: () => void;
  categoryCodesToSearchForms?: string[];
  onCancelAction?: () => void;
  showButtonsInTopBar?: boolean;
}
interface IForm {
  id: string;
  name: string;
  isHealthComponentIncluded: boolean;
  formLink: string;
}
interface IContactSendFormState {
  showError: boolean;
  loading: boolean;
  selectedForms: IForm[];
  emailTemplateId?: string;
  //html?: string;
  templateData?: any;
}

const ContactSendForm = (props: IContactSendFormProps) => {
  const {isVisible, assignmentData, onClose, onActionComplete} = props;
  const accountUUID = getAccountUUID();
  const currentUserUuid = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const [formattedTemplates, setFormattedTemplates] = useState<any>([]);
  const [contactInfoMessage, setContactInfoMessage] = useState<string>('');
  const [categoryIdsToSearchForms, setCategoryIdsToSearchForms] = useState<string[]>([])
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];
  const formSourceId = getMlovIdFromCode(
    formSourceList,
    FORM_SOURCE.PATIENT_PROFILE
  );

  const formStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_STATUS
    ) || [];

  const formStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.PENDING
  );

  const taskEntityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ENTITY_TYPE
    ) || [];
  const TASK_ENTITY_TYPES = {
    form: getMlovIdFromCode(taskEntityTypes, TASK_ENTITY_TYPE_CODES.FORM),
  };
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const defaultStatusId = getDefaultTaskStatusId(taskStatusMlov);
  const defaultValue: IContactSendFormState = {
    showError: false,
    loading: false,
    selectedForms: [],
  };
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  const [sendFormState, setSendFormState] =
    useState<IContactSendFormState>(defaultValue);

  const [createFormLogLink] = useMutation(FormsQueries.CREATE_FORM_LOG_LINK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  useEffect(() => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setCategoryList(list);
      })
      .catch((error) => {});
  }, []);

  const getTemplatesByCategoryCode = () => {
    const path = `${ContentTypes.emails.path}?category=MULTIPLE_PATIENT_FORMS`;
    getTemplates(path)
      .then((response) => {
        return getFormattedEmailTemplateData(response);
      })
      .then((formattedTemplates) => {
        setFormattedTemplates(formattedTemplates)
        if (formattedTemplates.length > 0) {
          const template = formattedTemplates[0];
          setSendFormState((prev) => ({
            ...prev,
            emailTemplateId: template.id || '',
            templateData: template || {},
          }));
        }
      })
      .catch((error) => {
      });
  };

  const resetData = () => {
    setSendFormState((prev) => ({...prev, ...defaultValue}));
  };

  const getTasksDataForSendForm = (forms: IForm[]): any[] => {
    const taskData: any[] = [];
    const {startDate, endDate} = getFormTaskDates();
    forms.forEach((form) => {
      taskData.push({
        title: form.name,
        assigneeId: assignmentData.contactId,
        contactId: assignmentData.contactId,
        assignedById: assignmentData.assignedById,
        statusId: defaultStatusId,
        referenceData: {
          entity: {
            id: form.id,
            name: form.name,
            metadata: [],
            sourceId: formSourceId,
          },
          entityType: 'FORM',
        },
        entityTypeId: TASK_ENTITY_TYPES.form,
        formId: form.id,
        startDateTime: startDate,
        endDateTime: endDate,
      });
    });
    return taskData;
  };

  const getEmailData = (forms: IForm[]) => {
    return {
      // from: emailData?.from,
      to: [assignmentData.patientEmail],
      template: sendFormState.emailTemplateId,
      // templateCategoryCode: 'MULTIPLE_PATIENT_FORMS',
      data: getFormMergedTags(forms),
      accountId: accountUUID,
      tags: ['cms-email'],
    };
  };

  const sendEmailWithForms = (forms: IForm[]) => {
    const emailData = getEmailData(forms);
    sendEmail(emailData);
  };

  const onSubmitClick = async () => {
    const forms = sendFormState.selectedForms;
    const isError = !forms.length;
    setSendFormState((prev) => ({...prev, showError: isError}));
    if (!isError) {
      /*
      if (!assignmentData.patientId) {
        forms.forEach((form) => {
          form.taskId = uuidv4();
        });
        sendEmailWithForms(forms);
        resetData();
        onActionComplete();
        return;
      }
      */
     try {
      setSendFormState((prev) => ({...prev, loading: true}));
      const promiseList: Promise<any>[] = [];
      forms.forEach((form) => {
        const params = {
          contactId: assignmentData.contactId,
          formId: form.id,
          sourceId: formSourceId,
          requestedByUserId: currentUserUuid,
          subjectId: uuidv4(),
        };
        promiseList.push(createFormLogLink({
          variables: {
            params,
          },
        }))
      });

      const responses = await Promise.all(promiseList);
      responses.forEach((response) => {
        const formLink = response.data?.createFormLogLink?.formLink;
        const formId = response.data?.createFormLogLink?.formId;
        forms.forEach((form) => {
          if (form.id === formId) form.formLink = formLink;
        });
      })
      sendEmailWithForms(forms);
      resetData();
      onActionComplete();
      setSendFormState((prev) => ({...prev, loading: false}));
     } catch (error) {

      setSendFormState((prev) => ({...prev, loading: false}));
     }
    }
  };
  // account specific merge tags
  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags };
  };

  const getPreviewHtml = (templateData: any) => {
    const engine = new Liquid();
    const finalMergeTag = {...getMergeTags(templateData?.templateCategory, categoryList), ...getFormMergedTags(sendFormState.selectedForms)};
    const tpl = engine.parse(templateData?.templateHtml || '');
    return engine.renderSync(tpl, finalMergeTag);
  };

  const getFormMergedTags = (forms: IForm[]) => {
    return {
      patient: {
        firstName: assignmentData.patientFirstName,
      },
      formList: forms.map((form) => {
        return {
          name: form.name,
          link: form.formLink,
        };
      }),
    };
  };

  const {loading: loadingCategoryCodesToSearchForms} = useQuery(GET_FORM_CATEGORY_ID_BY_CODES, {
    fetchPolicy: 'no-cache',
    skip: !props?.categoryCodesToSearchForms,
    variables: {
      categoryCode: props?.categoryCodesToSearchForms,
      tenantId: accountUUID,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
      showToast(
        toast,
        intl.formatMessage({ id: 'apiErrorMsg' }),
        ToastType.error
      );
    },
    onCompleted(data) {
      const categoryIdsToSearchForms = data?.formCategories?.map((category: IFormCategory) => category?.id);
      setCategoryIdsToSearchForms(categoryIdsToSearchForms)
    },
  });

  useEffect(() => {
    getTemplatesByCategoryCode();
  }, []);

  useEffect(() => {
    const isFormWithHealthComponent = sendFormState.selectedForms.some((item) => item.isHealthComponentIncluded);
    if (assignmentData.contactTypeCode === 'LEAD' && isFormWithHealthComponent) {
      const message = intl.formatMessage({ id: 'healthComponentFormInfoMessageForLead' });
      setContactInfoMessage(message);
    } else {
      setContactInfoMessage('');
    }
  }, [sendFormState.selectedForms, assignmentData.contactTypeCode]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const drawerWidth = isSideCarContext ? '100%' :isSmallScreen ? '70%' : '35%';

  const backBtn = () => ({
    show: true,
    id: 1,
    btnText: 'cancel',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => {
      props?.onCancelAction?.()
    },
  });

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        resetData();
        onClose();
      }}
      destroyOnClose
      title={<ModalActionTitle title={'Select Forms'} titleColor={''}
      leftBackButton={isSideCarContext ? <AntdButton onClick={() => props?.onCancelAction?.()} type="text" icon={<LeftOutlined />}/> : undefined}
      buttonList={props?.showButtonsInTopBar ? [
        {...backBtn(), show: !isSideCarContext},,
        {
          show: true,
          id: 3,
          btnText: 'sendForms',
          textColor: Colors.Custom.mainPrimaryPurple,
          variant: BUTTON_TYPE.PRIMARY,
          isLoading: sendFormState?.loading,
          isTransBtn: false,
          onClick: () => {
            onSubmitClick();
          },
        },
      ] : []}
       />}
      width={drawerWidth}
      mask={isSideCarContext ? false : true}
      closable={false}
      placement="right"
    >
      {!props?.showButtonsInTopBar && sendFormState.loading && (
        <VStack space={4}>
          <Skeleton.Text />
          <Skeleton rounded="md" />
        </VStack>
      )}
      {(props?.showButtonsInTopBar || !sendFormState.loading) && (
        <VStack space={4}>
          <DisplayText
                textLocalId="template"
                ></DisplayText>
          <Select
            onValueChange={(value: string) => {
              const template = formattedTemplates.find(
                (item:any) => item.id == value
              );
              setSendFormState((prev) => ({
                ...prev,
                emailTemplateId: template.id || '',
                templateData: template || {},
              }));
            }}
            selectedValue={
              sendFormState.emailTemplateId
            }
          >
            {formattedTemplates.map((item:any) => {
              return (
                <Select.Item
                  label={item.templateName}
                  value={item.id as string}
                >
                  {item.templateName}
                </Select.Item>
              );
            })}
          </Select>
          <DisplayText
                textLocalId="formList"></DisplayText>
          <MultipleFormSearch
            isShowError={sendFormState.showError}
            value={sendFormState.selectedForms}
            infoMessage={contactInfoMessage}
            contactTypeCode={assignmentData?.contactTypeCode}
            onChange={(value) => {
              setSendFormState((prev) => ({...prev, selectedForms: value}));
            }}
            categoryIdsToSearchForms={categoryIdsToSearchForms}
          />
          { !props?.showButtonsInTopBar && (
            <HStack>
            <Spacer />
            <Button onPress={onSubmitClick} rounded="3xl">
              <DisplayText
                textLocalId="sendForms"
                extraStyles={{color: 'white'}}
              />
            </Button>
          </HStack>
        )}
          {sendFormState.templateData?.templateHtml && (
            <View>{parse(getPreviewHtml(sendFormState.templateData))}</View>
          )}
        </VStack>
      )}
    </Drawer>
  );
};

export default ContactSendForm;
