import React, { useState } from 'react';
import {Drawer, Upload, UploadFile, notification} from 'antd';
import {IEmailAttachmentDrawerProps} from './interfaces';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles';
import {BUTTON_TYPE} from '../../../constants';
import { getBase64 } from '../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { MEDIA_TYPES, MSG_ATTACHMENT_MIME_TYPE, WORD_DOC_MIME_TYPES, getMediaTypeFromMimeType } from '../DocumentViewer/DocumentViewerHelper';
import { IMediaLibraryData } from '../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import { RcFile } from 'antd/lib/upload';
import { IAttachmentComponentState } from '../AddTask/interfaces';
import CareStudioService from '../../../services/CommonService/CareStudioService';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { HStack, Pressable, useToast } from 'native-base';
import { DocumentViewer } from '../DocumentViewer/DocumentViewer';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import SideCarBackSvg from '../Svg/SideCarBackSvg';

const EmailAttachmentDrawer = (props: IEmailAttachmentDrawerProps) => {
  const toast = useToast();
  const intl = useIntl();
  const commonData = React.useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const [componentState, setComponentState] =
    useState<IAttachmentComponentState>({
      progress: 0,
      isLoading: false,
      showErrors: false,
      isPreviewVisible: false,
      previewTitle: undefined,
      isFileLoading: {},
    });

  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();

  const handlePreview = async (file: UploadFile) => {
    if (componentState.isFileLoading[(file as any).uid]) {
      return;
    }
    const mediaType = getMediaTypeFromMimeType(file.type || '');
    if (!(MSG_ATTACHMENT_MIME_TYPE.includes(file.type as string) || mediaType === MEDIA_TYPES.IMAGE)) {
      notification.info({
        message: intl.formatMessage({id: 'noPreviewAvailable'}),
        duration: 1.0,
        placement: 'top'
      });
      return;
    }

    let fileUrl = file?.url || '';
    if (
      !(file as any).id &&
      file?.type &&
      WORD_DOC_MIME_TYPES.includes(file?.type)
    ) {
       await convertToPdf(file);
    }

    const fileMediaData: IMediaLibraryData = {
      ...file,
      lastUpdated: file.lastModified?.toString() || '',
      mime: file.type as string,
      name: file.name,
      size: file?.size as number,
    };

    if (!(file as any).blobUrl && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (file.type && !fileUrl) {
      fileUrl =
        (file as any).blobUrl ||
        file.preview ||
        URL.createObjectURL(file.originFileObj as any);
    }
    setComponentState((prev) => ({
      ...prev,
      isPreviewVisible: true,
      previewTitle:
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      ...(file.type &&
        fileUrl && {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }),
    }));
  };

  const convertToPdf = async (file: any) => {
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });

    try {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const response = await service.post(
        `/attachment/convert-to-pdf`,
        formData,
        {
          responseType: 'blob',
        }
      );

      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    } catch (error) {
      showToast(toast, intl.formatMessage({id: 'errorOpeningFile'}), ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    }
  };

  const handlePreviewCancel = (value = false) => {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
    }));
  }

  return (
    <Drawer
      open={props.isOpen}
      onClose={props.onClose}
      width={isSideCarContext ? '100%' : '33vw'}
      title={
        <HStack alignItems={'center'}>
          {isSideCarContext && (
            <Pressable onPress={props.onClose} marginRight={2}>
              <SideCarBackSvg />
            </Pressable>
          )}
          <ModalActionTitle
            title={'Attachments'}
            buttonList={[
              {
                show: true,
                id: 2,
                btnText: 'Insert',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose();
                },
              },
            ]}
          />
        </HStack>
      }
    >
      <Upload
        className="attachment-drawer"
        listType="picture"
        style={{background: ''}}
        onPreview={handlePreview}
        multiple={true}
        beforeUpload={() => false}
        onChange={(info) => {
          const tempFileList = [...info.fileList];
          props.setFileList(tempFileList);
        }}
        onRemove={(file) => {
          props.setFileList((prev) => {
            const newFileList = prev.filter((item) => item.uid != file.uid);
            return [...newFileList];
          });
        }}
        fileList={props.fileList}
      />
      {componentState.previewTitle &&
        componentState.previewObj?.type &&
        componentState.isPreviewVisible && (
          <DocumentViewer
            fileName={componentState.previewTitle}
            fileType={componentState.previewObj.type}
            onClose={handlePreviewCancel}
            fileUrl={componentState.previewObj.url}
            isOpen={true}
            mediaPlayerHeight={'75%'}
            mediaPlayerWidth={'75%'}
          />
        )}
    </Drawer>
  );
};

export default EmailAttachmentDrawer;
