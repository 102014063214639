import {Button, Icon} from 'native-base';
import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {IPatientEducation} from '../../CareDashboardInterfaces';
import {getAttachmentIcon} from '../../CareDashboardUtils/CareDashboardUtils';

interface IAttachmentButtonProps {
  data: IPatientEducation;
  onAttachmentClick?: (attachment: IPatientEducation) => void;
}

export const AttachmentButton = (props: IAttachmentButtonProps) => {
  return (
    <Button
      height={28}
      colorScheme="muted"
      variant="outline"
      borderWidth={1}
      borderColor="gray.500"
      borderRadius={4}
      size="xs"
      flexShrink={1}
      leftIcon={
        <Icon
          size="xs"
          as={AntIcon}
          color="gray.500"
          name={getAttachmentIcon(props.data)}
        />
      }
      onPress={() => {
        if (props.onAttachmentClick) {
          props.onAttachmentClick(props.data);
        }
      }}
    >
      {props.data.description}
    </Button>
  );
};

export default AttachmentButton;
