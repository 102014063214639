import {capitalize, filter} from 'lodash';
import moment from 'moment';
import {IntlShape} from 'react-intl';
import {DATE_FORMATS, KANBANVIEWTYPE} from '../../../../constants';
import {
  ORDERED_TASK_DUE_DATE_CODES_DESC,
  ORDERED_TASK_PRIORITY_CODES_DESC,
  ORDERED_TASK_STATUS_CODES_DESC,
  TASK_DUE_DATE_CATEGORY,
  TASK_DUE_DATE_CODES,
  TASK_PRIORITY_CODES,
  TASK_STATUS_CODES,
} from '../../../../constants/MlovConst';
import {IMlov} from '../../../../Interfaces/CommonInterfaces';
import { TASK_DUE_DATE_CATEGORY_CODE } from '../../../../screens/TaskScreen/TaskScreenConst';
import {checkIfArrayAndIsNotEmpty} from '../../../../utils/arrUtils';
import {
  getCurrentTimeZone,
  getDateObjFromDateStrAndFormat,
  getDiffInHours,
  getDoLaterTimeSlot,
  getMomentObj,
  getMomentObjFromDateStrAndFormat,
  getOverDuesDate,
  getTodaysTimeSlot,
  getTomorrowsTimeSlot,
  isBeforeDate,
  isCurrentDateInFutureComparedToOther,
  isPastTime,
  isTodayDate,
} from '../../../../utils/DateUtils';
import {getMlovId, getMlovValue} from '../../../../utils/mlovUtils';
import {IKanBanColumnType} from '../../KanBanView/interfaces';
import {IContact, IPatientEducation, ITask} from '../CareDashboardInterfaces';
import { ITaskFilters } from '../CareDashboardTopBar/CareDashboardTopBar';
import { BoardType } from '../CareDashboardTopBar/interfaces';
import { TASK_TABS } from '../CareDashboardConstants';

export const GET_TASK_LABEL_BATCH_COUNT = 100;

export const TASK_COMPLETED_STATUS_CODE = 'completed';
export const TASK_DEFAULT_STATUS_CODE = 'accepted';
export const TASK_MISSED_STATUS_CODE = 'missed';
export const CARE_DASHBOARD_COLUMN_CODE = {
  today: 'TODAY',
  dueNow: 'DUE_NOW',
  notCompleted: 'NOT COMPLETED',
  completed: 'COMPLETED',
  pendingTasks: 'PENDING TASKS',
  missedTasks: 'MISSED TASKS',
  importantTasks: 'IMPORTANT TASKS',
  doLater: 'Do Later',
  doTomorrow: 'Do Tomorrow',
  doToday: 'Do Today',
  allAssigned: 'All Assigned',
  highPriority: "High",
  mediumPriority: "Medium",
  lowPriority: "Low"
};

export const CARE_DASHBOARD_COLUMN_CODE_VALUE = {
  doLater: 'do_later',
  doTomorrow: 'do_tomorrow',
  doToday: 'do_today',
  allAssigned: 'all_assigned',
};

/*const todayColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.today,
  columnName: 'Today',
  list: [],
  columnHeight: 584,
};

const dueNowColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.dueNow,
  columnName: 'Due Now',
  list: [],
  columnHeight: 584,
};*/

const completedColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.completed,
  columnName: 'Completed',
  list: [],
  columnHeight: 624,
};

const notCompletedColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.notCompleted,
  columnName: 'Not Completed',
  list: [],
  columnHeight: 624,
};

const pendingTasksColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.pendingTasks,
  columnName: 'Pending',
  list: [],
  columnHeight: 624,
};

const missedTasksColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.missedTasks,
  columnName: 'Missed',
  list: [],
  columnHeight: 624,
};

const importantTasksColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.importantTasks,
  columnName: 'Important',
  list: [],
  columnHeight: 624,
};

const doLaterColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.doLater,
  columnName: 'Do Later',
  list: [],
  columnHeight: 624,
  count: 0,
};

const doTomorrowColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.doTomorrow,
  columnName: 'Do Tomorrow',
  list: [],
  columnHeight: 624,
  count: 0,
};

const doTodayColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.doToday,
  columnName: 'Do Today',
  list: [],
  columnHeight: 624,
  count: 0,
};

const allAssignedColumn: IKanBanColumnType = {
  id: CARE_DASHBOARD_COLUMN_CODE.allAssigned,
  columnName: 'All Assigned',
  list: [],
  columnHeight: 624,
  count: 0,
};

export const DASHBOARD_COLUMNS: IKanBanColumnType[] = [
  //todayColumn,
  //dueNowColumn,
  // importantTasksColumn,
  // pendingTasksColumn,
  // missedTasksColumn,
  // completedColumn,
  allAssignedColumn,
  doTodayColumn,
  doTomorrowColumn,
  doLaterColumn,
];

export const TASK_CATEGORY = [
  {
    code: 'all_assigned',
    value: 'All Assigned',
  },
  {
    code: 'do_today',
    value: 'Do Today',
  },
  {
    code: 'do_tomorrow',
    value: 'Do Tomorrow',
  },
  {
    code: 'do_later',
    value: 'Do Later',
  },
];

export const KANBAN_COLUMNS: IKanBanColumnType[] = [
  // importantTasksColumn,
  // pendingTasksColumn,
  // missedTasksColumn,
  // completedColumn,
  completedColumn,
  notCompletedColumn,
];

const isTaskMissed = (startDateTime: string | Date): boolean => {
  const diff = getDiffInHours(startDateTime, moment(new Date()));
  //Todo - change this logic
  return diff > 12 ? true : false;
};

/**
 * @description set Task data for each column which are present in KanBan view for careDashboard
 * @param taskList (Tasks Data)
 * @param intl
 * @param view
 * @param type (Category type) (do_later, do_tomorrow, do_today, all_assigned)
 * @returns
 */
export const parseTaskDataIntoColumnsForNewCategory = (
  taskList: ITask[],
  intl: IntlShape,
  view: string,
  type: string,
  mlovData: any,
  userTaskDisplayCategory: any,
  columnType: BoardType
): IKanBanColumnType[] => {
  const typeStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    type
  );
  const typeStatusId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    type
  );

  const checkUserSpecificValue = userTaskDisplayCategory.filter(
    (dataItem: any) => dataItem.taskDisplayCategoryId === typeStatusId
  );


  // doLaterColumn.columnName = intl.formatMessage({id: 'doLater'});
  // doTomorrowColumn.columnName = intl.formatMessage({id: 'doTomorrow'});
  // doTodayColumn.columnName = intl.formatMessage({id: 'doToday'});
  // allAssignedColumn.columnName = intl.formatMessage({id: 'allAssigned'});
  if (type === 'do_later') {
    doLaterColumn.columnName =
      type === 'do_later'
        ? checkUserSpecificValue && checkUserSpecificValue.length
          ? checkUserSpecificValue[0]['categoryValue']
          : typeStatusValue
        : intl.formatMessage({id: 'doLater'});
  } else if (type === 'do_tomorrow') {
    doTomorrowColumn.columnName =
      type === 'do_tomorrow'
        ? checkUserSpecificValue && checkUserSpecificValue.length
          ? checkUserSpecificValue[0]['categoryValue']
          : typeStatusValue
        : intl.formatMessage({id: 'doTomorrow'});
  } else if (type === 'do_today') {
    doTodayColumn.columnName =
      type === 'do_today'
        ? checkUserSpecificValue && checkUserSpecificValue.length
          ? checkUserSpecificValue[0]['categoryValue']
          : typeStatusValue
        : intl.formatMessage({id: 'doToday'});
  } else if (type === 'all_assigned') {
    allAssignedColumn.columnName =
      type === 'all_assigned'
        ? checkUserSpecificValue && checkUserSpecificValue.length
          ? checkUserSpecificValue[0]['categoryValue']
          : typeStatusValue
        : intl.formatMessage({id: 'allAssigned'});
  }

  if (taskList) {
    /**
     * Sorting the task
     */
    // const sortedTasks =
    //   taskList.length > 0
    //     ? taskList.sort((task1, task2) => {
    //         if (task1.startDateTime && task2.startDateTime) {
    //           return isCurrentDateInFutureComparedToOther(
    //             task1.startDateTime,
    //             task2.startDateTime
    //           )
    //             ? 1
    //             : -1;
    //         }
    //         return 0;
    //       })
    //     : [];
    const sortedTasks = taskList.length > 0 ? taskList : [];

    if (type === CARE_DASHBOARD_COLUMN_CODE_VALUE.doLater) {
      const sortedUpdatedTasks = sortedTasks.map((taskItem: ITask) => {
        if (taskItem.status?.code === TASK_COMPLETED_STATUS_CODE) {
          taskItem.isCompleted = true;
          return taskItem;
        }

        return taskItem;
      });

      doLaterColumn.list = [];
      doLaterColumn.list = sortedUpdatedTasks;
    } else if (type === CARE_DASHBOARD_COLUMN_CODE_VALUE.doTomorrow) {
      const sortedUpdatedTasks = sortedTasks.map((taskItem: ITask) => {
        if (taskItem.status?.code === TASK_COMPLETED_STATUS_CODE) {
          taskItem.isCompleted = true;
          return taskItem;
        }

        return taskItem;
      });

      doTomorrowColumn.list = [];
      doTomorrowColumn.list = sortedUpdatedTasks;
    } else if (type === CARE_DASHBOARD_COLUMN_CODE_VALUE.doToday) {
      const sortedUpdatedTasks = sortedTasks.map((taskItem: ITask) => {
        if (taskItem.status?.code === TASK_COMPLETED_STATUS_CODE) {
          taskItem.isCompleted = true;
          return taskItem;
        }

        return taskItem;
      });

      doTodayColumn.list = [];
      doTodayColumn.list = sortedUpdatedTasks;
    } else if (type === CARE_DASHBOARD_COLUMN_CODE_VALUE.allAssigned) {
      const sortedUpdatedTasks = sortedTasks.map((taskItem: ITask) => {
        if (taskItem.status?.code === TASK_COMPLETED_STATUS_CODE) {
          taskItem.isCompleted = true;
          return taskItem;
        }

        return taskItem;
      });

      allAssignedColumn.list = [];
      allAssignedColumn.list = sortedUpdatedTasks;
    }

    // Increasing height for main task tab
    [allAssignedColumn, doTodayColumn, doTomorrowColumn, doLaterColumn].forEach(
      (column) =>
        (column.columnHeight =
          view !== KANBANVIEWTYPE.PATIENT_KANBAN ? 700 : 624)
    );
  }

  return [allAssignedColumn, doTodayColumn, doTomorrowColumn, doLaterColumn];
};

export const parseTaskDataIntoColumns = (
  taskList: ITask[],
  intl: IntlShape,
  view: string
): IKanBanColumnType[] => {
  //todayColumn.columnName = intl.formatMessage({id: 'today'});
  //dueNowColumn.columnName = intl.formatMessage({id: 'dueNow'});

  completedColumn.columnName = intl.formatMessage({id: 'completed'});
  notCompletedColumn.columnName = intl.formatMessage({id: 'notCompleted'});
  // pendingTasksColumn.columnName = intl.formatMessage({id: 'pendingTasks'});
  // missedTasksColumn.columnName = intl.formatMessage({id: 'missed'});
  // importantTasksColumn.columnName = intl.formatMessage({id: 'important'});

  [
    // importantTasksColumn,
    // pendingTasksColumn,
    // missedTasksColumn,
    notCompletedColumn,
    completedColumn,
  ].forEach((item) => {
    item.list = [];
  });
  if (taskList.length > 0) {
    // const sortedTasks = taskList.sort((task1, task2) => {
    //   if (task1.startDateTime && task2.startDateTime) {
    //     return isCurrentDateInFutureComparedToOther(
    //       task1.startDateTime,
    //       task2.startDateTime
    //     )
    //       ? 1
    //       : -1;
    //   }
    //   return 0;
    // });

    taskList.forEach((task) => {
      if (task.status?.code === TASK_COMPLETED_STATUS_CODE) {
        task.isCompleted = true;
        completedColumn.list.push(task);
      } else {
        notCompletedColumn.list.push(task);
      }
      //  else if (task.isEscalated) {
      //   importantTasksColumn.list.push(task);
      // } else if (task?.status?.code == TASK_MISSED_STATUS_CODE) {
      //   missedTasksColumn.list.push(task);
      // } else {
      //   pendingTasksColumn.list.push(task);
      // }
    });

    // Increasing height for main task tab
    [
      // importantTasksColumn,
      // pendingTasksColumn,
      // missedTasksColumn,
      completedColumn,
      notCompletedColumn,
    ].forEach(
      (column) =>
        (column.columnHeight =
          view !== KANBANVIEWTYPE.PATIENT_KANBAN ? 700 : 624)
    );
  }

  return [
    // importantTasksColumn,
    // pendingTasksColumn,
    // missedTasksColumn,
    notCompletedColumn,
    completedColumn,
  ];
};

// interface IKanbanTaskViewProps {
//   tasks:Array<ITask>
// }
export enum PropertyType {
  status = 'status',
  priority = 'priority',
  dueDate = 'dueDate',
}

export const getColumnName = (propertyType: PropertyType): {[key: string]: string} => {
  if (propertyType === PropertyType.status) {
    return {
      [TASK_STATUS_CODES.ACCEPTED]: 'Pending',
      [TASK_STATUS_CODES.MISSED]: 'Missed',
      [TASK_STATUS_CODES.COMPLETED]: 'Mark as Complete',
      [TASK_STATUS_CODES.DELETE]: 'Delete',
    };
  } else if (propertyType === PropertyType.priority) {
    return {
      [TASK_PRIORITY_CODES.HIGH]: 'High',
      [TASK_PRIORITY_CODES.MEDIUM]: 'Medium',
      [TASK_PRIORITY_CODES.LOW]: 'Low',
    };
  }
  else if (propertyType === PropertyType.dueDate) {
    return {
      [TASK_DUE_DATE_CODES.PAST]: 'Past',
      [TASK_DUE_DATE_CODES.TODAY]: 'Today',
      [TASK_DUE_DATE_CODES.FUTURE]: 'Future',
    };
  }
  return {};
};

interface IKanbanTaskViewColumnProps {
  id: string;
  columnName: string;
  list: Array<ITask> | Array<any>;
  filteredTaskList?:any;
}

export const generateColumnsForProperty = (
  propertyDetails: IPropertyDetails
): Array<IKanbanTaskViewColumnProps> => {
  const columns: Array<IKanbanTaskViewColumnProps> = [];
  const codes = propertyDetails.propertyCodes === undefined ? {}:propertyDetails?.propertyCodes
  Object.keys(codes).map((codeKey) => {
    const codeValue = codes[codeKey];

    columns.push({
      id: codeValue,
      columnName: getColumnName(propertyDetails.propertyType)?.[codeValue],
      list: [],
      filteredTaskList:[]
    });
  });

  //FUTURE USE
  // if (propertyType === PropertyType.status) {
  //   Object.keys(TASK_STATUS_CODES).map((statusCode: string) => {
  //     const taskStatusCodeValue = TASK_STATUS_CODES[statusCode]

  //     columns.push({
  //       id: taskStatusCodeValue,
  //       columnName: getColumnName(propertyType)?.[taskStatusCodeValue],
  //       list: [],
  //     });
  //   });
  //   return columns;
  // } else if(propertyType === PropertyType.priority){
  //   Object.keys(TASK_PRIORITY_CODES).map((priorityCode:string)=>{
  //     const priorityCodeValue = TASK_PRIORITY_CODES[priorityCode]

  //     columns.push({
  //       id: priorityCodeValue,
  //       columnName: getColumnName(propertyType)?.[priorityCodeValue],
  //       list: [],
  //     });
  //   })
  // }

  return columns;
};
export interface IPropertyDetails {
  propertyCodes: {
    [key: string]: string;
  };
  propertyType: PropertyType;
}

export const getTaskFilterByStatusCode = (statusCode: string, tasks: ITask[]) => {
  return (tasks || []).filter((task: ITask) => {
    return task?.status?.code === statusCode;
  });
};

export const getTaskFilterBydueDateCode = (statusCode: string, tasks: ITask[]) => {
  if (statusCode === 'past') {
    return (tasks || []).filter((task: ITask) => {
      return task?.endDateTime && isBeforeDate(task?.endDateTime, new Date())
    });
  } else if (statusCode === 'today') {
    return (tasks || []).filter((task: ITask) => {
      return task?.endDateTime && isTodayDate(task?.endDateTime)
    });
  } else {
    return (tasks || []).filter((task: ITask) => {
      return task?.endDateTime && !isBeforeDate(task?.endDateTime, new Date()) && !isTodayDate(task?.endDateTime)
    });
  }
};

export const getColumnsBasedUponTaskProperty = (
  tasks: Array<ITask> = [],
  propertyDetails: IPropertyDetails = {
    propertyCodes:TASK_STATUS_CODES,
    propertyType:PropertyType.status
  }
) => {
  const columns = generateColumnsForProperty(propertyDetails);
  if (checkIfArrayAndIsNotEmpty(tasks)) {
    tasks.forEach((task: ITask) => {
      if (task.status?.code === TASK_STATUS_CODES.COMPLETED) {
        task.isCompleted = true;
      }
    });

    if (propertyDetails?.propertyType === PropertyType.status) {
      columns.forEach((column: IKanbanTaskViewColumnProps) => {
        column.list =  getTaskFilterByStatusCode(column?.id, tasks);
      });
    } else if (propertyDetails?.propertyType === PropertyType.priority) {
      tasks.forEach((task: ITask) => {
        columns.forEach((column: IKanbanTaskViewColumnProps) => {
          if (task.priority?.code === column.id) {
            column.list.push(task);
          }
        });
      });
    }
    else if (propertyDetails?.propertyType === PropertyType.dueDate) {
      columns.forEach((column: IKanbanTaskViewColumnProps) => {
        column.list =  getTaskFilterBydueDateCode(column?.id, tasks);
      });
    }
  }

  if (propertyDetails?.propertyType === PropertyType.status) {
    columns.sort((a, b) => {
      const currentMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
        a.id || ''
      );
      const nextMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
        b.id || ''
      );
      return currentMlovScore - nextMlovScore;
    });
  } else if (propertyDetails?.propertyType === PropertyType.priority) {
    columns.sort((a, b) => {
      const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_DESC.indexOf(
        a.id || ''
      );
      const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_DESC.indexOf(
        b.id || ''
      );
      return currentMlovScore - nextMlovScore;
    })
  } else if (propertyDetails?.propertyType === PropertyType.dueDate) {
    columns.sort((a, b) => {
      const currentMlovScore = ORDERED_TASK_DUE_DATE_CODES_DESC.indexOf(
        a.id || ''
      );
      const nextMlovScore = ORDERED_TASK_DUE_DATE_CODES_DESC.indexOf(
        b.id || ''
      );
      return currentMlovScore - nextMlovScore;
    })
  }

  return columns;

};

export const getColumnHeaderColorScheme = (
  column: IKanBanColumnType
): string | undefined => {
  switch (column.id) {
    case CARE_DASHBOARD_COLUMN_CODE.missedTasks:
      return 'danger';
    case CARE_DASHBOARD_COLUMN_CODE.importantTasks:
      return 'primary';
    case CARE_DASHBOARD_COLUMN_CODE.completed:
      return 'success';
    case CARE_DASHBOARD_COLUMN_CODE.pendingTasks:
      return 'info';
    default:
      return 'success';
  }
};

export const getKanBanItemColorScheme = (item: ITask): string | undefined => {
  if (item.isEscalated) {
    return 'danger';
  }
  return 'info';
};

export const getAttachmentIcon = (attachment: IPatientEducation): string => {
  if (attachment.documentType.code === 'PDF') {
    return 'pdffile1';
  }
  return 'file1';
};

export const getCompletedTaskStatusId = (mlovs: IMlov[]): string => {
  const completedStatusMlov = mlovs.filter((mlov) => {
    return mlov.code === TASK_COMPLETED_STATUS_CODE;
  });
  if (completedStatusMlov.length > 0) {
    return completedStatusMlov[0].id;
  }
  return '';
};

export const getDefaultTaskStatusId = (mlovs: IMlov[]): string => {
  const completedStatusMlov = mlovs.filter((mlov) => {
    return mlov.code === TASK_DEFAULT_STATUS_CODE;
  });
  if (completedStatusMlov.length > 0) {
    return completedStatusMlov[0].id;
  }
  return '';
};

export const setPatientDataInTasks = (
  columns: IKanBanColumnType[],
  contacts: IContact[],
  category: string
): IKanBanColumnType[] => {
  const contactData: any = {};
  return columns.map((column: IKanBanColumnType) => {
    const code =
      column.id === 'All Assigned' || column.id === 'all_assigned'
        ? 'all_assigned'
        : column.id === 'Do Today' || column.id === 'do_today'
        ? 'do_today'
        : column.id === 'Do Tomorrow' || column.id === 'do_tomorrow'
        ? 'do_tomorrow'
        : column.id === 'Do Later' || column.id === 'do_later'
        ? 'do_later'
        : '';

    if (code === category) {
      column.list = column.list.map((task: ITask) => {

        if (task.contactId) {
          if (contactData[task.contactId]) {
            task.patientName = contactData[task.contactId].patientName;
            task.patientContactId = contactData[task.contactId].contactId;
            task.patientEmail = contactData[task.contactId].patientEmail;
            task.patientPhoneNumber =
              contactData[task.contactId].patientPhoneNumber;
            task.externalPatientId =
              contactData[task.contactId].externalPatientId;
            task.person = contactData[task.contactId].person;
          } else {
            const matchedPatient = contacts.filter((contact: IContact) => {
              return contact?.uuid === task.contactId;
            });
            if (matchedPatient.length > 0) {
              const patientName = matchedPatient[0].name;
              const contactId = matchedPatient[0].id;
              task.patientName = patientName;
              task.patientContactId = contactId;
              task.patientEmail = matchedPatient[0].email;
              task.patientPhoneNumber = matchedPatient[0].phoneNumber;
              task.person = matchedPatient[0].person;
              task.externalPatientId = matchedPatient[0].patient?.patientId;
              contactData[task.contactId] = {
                patientName: patientName,
                contactId: contactId,
                patientEmail: matchedPatient[0].email,
                patientPhoneNumber: matchedPatient[0].phoneNumber,
              };
            }
          }

          return task;
        }

        return task;
      });
    }

    return column;
  });

  // return columns;
};

export const setPatientDataInCreatedTasks = (
  tasks: ITask[],
  contacts: IContact[]
): ITask[] => {
  const contactData: any = {};

  tasks.forEach((task: ITask) => {
    if (task.contactId) {
      if (contactData[task.contactId]) {
        task.patientName = contactData[task.contactId].patientName;
        task.patientContactId = contactData[task.contactId].contactId;
        task.patientEmail = contactData[task.contactId].patientEmail;
        task.patientPhoneNumber =
          contactData[task.contactId].patientPhoneNumber;
        task.externalPatientId = contactData[task.contactId].externalPatientId;
        task.person = contactData[task.contactId].person;
      } else {
        const matchedPatient = contacts.filter((patient: IContact) => {
          return patient.uuid === task.contactId;
        });
        if (matchedPatient.length > 0) {
          const patientName = matchedPatient[0].name;
          const contactId = matchedPatient[0].id;
          task.patientName = patientName;
          task.patientContactId = contactId;
          task.patientEmail = matchedPatient[0].email;
          task.patientPhoneNumber = matchedPatient[0].phoneNumber;
          task.person = matchedPatient[0].person;
          task.externalPatientId = matchedPatient[0].patient?.patientId;
          contactData[task.contactId] = {
            patientName: patientName,
            contactId: contactId,
            patientEmail: matchedPatient[0].email,
            patientPhoneNumber: matchedPatient[0].phoneNumber,
          };
        }
      }
    }
  });

  return tasks;
};

export const getContactIds = (tasks: ITask[]): string[] => {
  const contactIds: string[] = [];
  tasks.forEach((task) => {
    if (task.contactId) {
      contactIds.push(task.contactId);
    }
  });
  return contactIds;
};

export const parseTaskColumnWithCount = (providerTaskCount: any) => {
  allAssignedColumn.count =
    providerTaskCount[
      CARE_DASHBOARD_COLUMN_CODE_VALUE.allAssigned
    ]?.aggregate?.total;
  doTodayColumn.count =
    providerTaskCount[
      CARE_DASHBOARD_COLUMN_CODE_VALUE.doToday
    ]?.aggregate?.total;
  doTomorrowColumn.count =
    providerTaskCount[
      CARE_DASHBOARD_COLUMN_CODE_VALUE.doTomorrow
    ]?.aggregate?.total;
  doLaterColumn.count =
    providerTaskCount[
      CARE_DASHBOARD_COLUMN_CODE_VALUE.doLater
    ]?.aggregate?.total;

  return [allAssignedColumn, doTodayColumn, doTomorrowColumn, doLaterColumn];
};

export const parseCompletedNotCompletedCountInColumns = (taskCount: any) => {
  completedColumn.count = taskCount?.completed?.aggregate?.total;
  notCompletedColumn.count = taskCount?.notCompleted?.aggregate?.total;

  return [notCompletedColumn, completedColumn];
};


export const getMissedTaskStatusId = (mlovs: IMlov[]): string => {
  const completedStatusMlov = mlovs.filter((mlov) => {
    return mlov.code === TASK_MISSED_STATUS_CODE;
  });
  if (completedStatusMlov.length > 0) {
    return completedStatusMlov[0].id;
  }
  return '';
};

export function doesTaskSatisfiesFilterConditions(task: ITask, filters: ITaskFilters) {
  if (!task?.id || !filters.selectedUserId?.length) {
    return true;
  }

  const taskStatusId = task.statusId || task.status?.id;
  const taskPriorityId = task.priorityId || task.priority?.id;

  if (filters.selectedStatusIds?.length) {
    if (!taskStatusId || !filters.selectedStatusIds.includes(taskStatusId)) {
      return false;
    }
  }

  if (filters.selectedPriorityIds?.length) {
    if (!taskPriorityId || !filters.selectedPriorityIds.includes(taskPriorityId)) {
      return false;
    }
  }

  let startDateString = filters.startDate;
  let endDateString = filters.endDate;

  if (filters.selectedDueDateCode?.includes('All') || filters.selectedDueDateCode?.length === 0) {
    startDateString = undefined;
    endDateString = undefined;
  } else if (filters.selectedDueDateCode && !filters.selectedDueDateCode.includes('date_range')) {
    filters.selectedDueDateCode.forEach((code)=>{
      const { start, end } = getStartAndEndDateByTaskDueDateCategory(code);
      startDateString = start;
      endDateString = end;})
  }

  const startDate = startDateString ? getMomentObjFromDateStrAndFormat(startDateString, DATE_FORMATS.DISPLAY_DATE_FORMAT)?.valueOf() : undefined;
  const endDate = endDateString ? getMomentObjFromDateStrAndFormat(endDateString, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    // ?.endOf('day')
    ?.valueOf() : undefined;

  if (startDate) {
    if (!task.endDateTime || startDate > getMomentObj(task.endDateTime)?.valueOf()) {
      return false;
    }
  }

  if (endDate) {
    if (!task.endDateTime || endDate < getMomentObj(task.endDateTime)?.valueOf()) {
      return false;
    }
  }

  if (filters.selectedContactUuid?.length) {
    if (!task.contactId || !filters.selectedContactUuid.includes(task.contactId)) {
      return false;
    }
  }

  if (filters.selectedTab === TASK_TABS.CREATED) {
    if (!task.createdBy || !filters.selectedUserId?.includes(task.createdBy)) {
      return false;
    }
  } else if (filters.selectedTab === TASK_TABS.TASK_POOL) {
    if (!task.userPoolId || (filters.userPoolIds?.length && !filters.userPoolIds?.includes(task.userPoolId))) {
      return false;
    }
  } else {
    if (!task.assigneeId || !filters.selectedUserId?.includes(task.assigneeId)) {
      return false;
    }
  }

  return true;
}

export function getStartAndEndDateByTaskDueDateCategory(code: string) {
  const result = {} as { start: any, end: any };
  const timezone = getCurrentTimeZone();

  switch (code) {
    case TASK_DUE_DATE_CATEGORY.ALL:
      return result;
    case TASK_DUE_DATE_CATEGORY.OVERDUE: {
      const { end } = getOverDuesDate(timezone);
      result.end = end;
      break;
    }
    case TASK_DUE_DATE_CATEGORY.TODAY: {
      const { start, end } = getTodaysTimeSlot(timezone);
      result.start = start;
      result.end = end;
      break;
    }
    case TASK_DUE_DATE_CATEGORY.TOMORROW: {
      const { start, end } = getTomorrowsTimeSlot(timezone);
      result.start = start;
      result.end = end;
      break;
    }
    case TASK_DUE_DATE_CATEGORY.LATER: {
      const { start } = getDoLaterTimeSlot(timezone);
      result.start = start;
      break;
    }
  }

  return result;
}

export const getAllAddedlabelsIds = (tasksList: ITask[]) => {
  let allLabelsIdLists = [] as string[];
  tasksList.forEach((item)=> {
     const labelIds = item?.labels?.map(labelItem => labelItem?.labelId || '');
     if (labelIds?.length) {
      allLabelsIdLists = [...allLabelsIdLists, ...labelIds];
    }
  })
  const uniqueIdSet = new Set(allLabelsIdLists);
  const uniqueIdArray = Array.from(uniqueIdSet);
  return uniqueIdArray;
}
export function areArraysEqual(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export const TASK_ACTIONS = [
  {
    key: 1,
    code: 'pending',
    value: 'Pending',
  },
  {
    key: 2,
    code: 'missed',
    value: 'Missed',
  },
  {
    key: 3,
    code: 'completed',
    value: 'Completed',
  }
];

export const getStatusIdByCode = (taskStatusMlov:IMlov[], statusCode: string)=> {
  const status = taskStatusMlov.filter(status => status.code === statusCode);
  if (status?.length && status[0].isDeleted === false) {
    return status[0].id
  }
  return ''
}

export const getDefaultPriorityId = (mlovs: IMlov[]): string => {
  return mlovs?.find((mlov) => {
    return mlov?.code === TASK_PRIORITY_CODES.MEDIUM
  })?.id || '';
}

export const isTaskDueDateIsPass = (endDate: string): boolean => {
  return endDate && isBeforeDate(endDate, new Date()) ? true : false;
}
