import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';
import {Colors} from '../../../../styles/Colors';

const CopyActionSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor} = props;
  const bgColor = customStrokeColor || Colors.Custom.Gray500;

  return (
    <Svg
      width={props?.width ? props?.width : '16'}
      height={props?.height ? props?.height : '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke={bgColor}
        strokeLinecap="round"
        d="M4 12.67a2 2 0 01-2-2v-4c0-2.515 0-3.772.781-4.553.781-.781 2.038-.781 4.552-.781H10a2 2 0 012 2M8 14.669h2c1.886 0 2.828 0 3.414-.585.586-.586.586-1.53.586-3.415V7.336c0-1.886 0-2.828-.586-3.414-.586-.586-1.528-.586-3.414-.586H8c-1.886 0-2.828 0-3.414.586C4 4.508 4 5.45 4 7.336v3.333c0 1.886 0 2.829.586 3.415.586.585 1.528.585 3.414.585z"
      ></Path>
    </Svg>
  );
};

export default CopyActionSvg;
