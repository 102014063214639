import {useEffect, useState} from 'react';
import {toArray} from 'lodash';
import {useLazyQuery} from '@apollo/client';
import {USER_ROLE_CODES} from '../../../constants/MlovConst';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {
  IAccountUserSearchResp,
  ICustomUserSearchProps,
  IInternalUsers,
  IInternalUsersState,
} from './interface';
import {CustomUserSearchView} from './CustomUserSearchView';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {filterLoggedInUserFromInternalUser, getSelectedUsersUUIDs} from './CustomUserSearchUtils';
import { getUserUUID } from '../../../utils/commonUtils';

export const CustomUserSearch = (props: ICustomUserSearchProps) => {
  const userUuid = getUserUUID();
  const [internalUserStateData, setInternalUserStateData] = useState({
    internalUsersOptions: [],
    internalUsersData: [],
    isLoading: false,
    selectedUsersUUIDs: [],
  } as IInternalUsersState);

  const [getInternalUsers] = useLazyQuery(InboxQueries.GetInternalUsers, {
    fetchPolicy: 'no-cache',
  });

  const [getInitialInternalUsers] = useLazyQuery(
    InboxQueries.GetInitialInternalUsers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const removeDuplicatesUsers = (users: IInternalUsers[]): IInternalUsers[] => {
    const uniqueUuids = new Set();

    return users.filter((item: IInternalUsers) => {
      const uuid = item.user.uuid;
      if (!uniqueUuids.has(uuid)) {
        uniqueUuids.add(uuid);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (props.selectedUsersData?.length) {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersData: removeDuplicatesUsers([
            ...prev.internalUsersData,
            ...props.selectedUsersData,
          ]),
          internalUsersOptions: removeDuplicatesUsers([
            ...prev.internalUsersData,
            ...props.selectedUsersData,
          ]),
          selectedUsersUUIDs: getSelectedUsersUUIDs(props.selectedUsersData),
        };
      });
    }
  }, [props.selectedUsersData]);

  const getInitialUsers = async () => {
    const searchedInternalUsers: {data: IAccountUserSearchResp} =
      await getInitialInternalUsers({
        variables: {
          roleCode: USER_ROLE_CODES.EMPLOYER,
          limit: 10,
        },
      });
    const filteredInternalUser = filterLoggedInUserFromInternalUser(
      searchedInternalUsers?.data?.accountUsers,
      userUuid
    );
    setInternalUserStateData((prev) => {
      return {
        ...prev,
        internalUsersData: removeDuplicatesUsers([
          ...prev.internalUsersData,
          ...filteredInternalUser,
        ]),
        internalUsersOptions: removeDuplicatesUsers([
          ...prev.internalUsersData,
          ...filteredInternalUser,
        ]),
      };
    });
  };

  useEffect(() => {
    getInitialUsers();
  }, []);

  const searchInternalUser = async (searchString: string) => {
    searchString = searchString.trim();
    if (searchString.length > 1) {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersOptions: [],
          isLoading: true,
        };
      });

      const searchedInternalUsers: {data: IAccountUserSearchResp} =
        await getInternalUsers({
          variables: {
            roleCode: USER_ROLE_CODES.EMPLOYER,
            limit: 10,
            searchString: `%${searchString}%`,
          },
        });

      const filteredInternalUser = filterLoggedInUserFromInternalUser(
        searchedInternalUsers?.data?.accountUsers,
        userUuid
      );
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersOptions: filteredInternalUser,
          isLoading: false,
        };
      });
    } else {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersOptions: internalUserStateData?.internalUsersData,
          isLoading: false,
          searchValue: searchString,
        };
      });
    }
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SEARCH:
        searchInternalUser(actionData);
        break;

      case COMMON_ACTION_CODES.USER_CHANGE:
        props.onChange(actionData?.values);
        const selectedInternalUsers = toArray(actionData.options).map(
          (option): IInternalUsers => {
            return {
              userId: option?.id,
              user: {
                userRoles: option?.label?.props?.userRoles,
                name: option?.label?.props?.name,
                uuid: option?.value,
                id: option?.id,
              },
            };
          }
        );
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            selectedUsersUUIDs: actionData?.values,
            internalUsersData: selectedInternalUsers,
          };
        });
        break;

      case COMMON_ACTION_CODES.USER_REMOVE:
        const selectedUUIDs = internalUserStateData?.selectedUsersUUIDs?.filter(
          (uuid) => {
            return uuid !== actionData;
          }
        );
        props?.onChange(selectedUUIDs);
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            selectedUsersUUIDs: selectedUUIDs,
          };
        });
        break;

      default:
        break;
    }
  };

  return (
    <CustomUserSearchView
      isRequired={props.isRequired}
      onActionPerformed={onActionPerformed}
      userSelectRef={props.userSelectRef}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      label={props.label}
      disabledUsers={props.disabledUsers}
      selectedUsersUUIDs={internalUserStateData.selectedUsersUUIDs}
      internalUsersOptions={internalUserStateData.internalUsersOptions}
      isLoading={internalUserStateData.isLoading}
    />
  );
};
