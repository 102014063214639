import {useLazyQuery} from '@apollo/client';
import {Popover} from 'antd';
import {Center, Modal, Spinner} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {MLOV_CATEGORY} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../constants/Configs';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CloudTelephonyQueries, UserQueries} from '../../../services';
import {getDefaultPhoneNumberByLocation} from '../../../services/CloudTelephony/CloudTelephonyQueries';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {IInbox} from '../../../services/Inbox/interfaces';
import {Colors} from '../../../styles';
import {getAccountId, getAccountUUID, getUserId} from '../../../utils/commonUtils';
import {getMlovId} from '../../../utils/mlovUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {CHANNEL_TYPE} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import CallAndSmsNumberListView from './CallAndSmsNumberListView';
import {INumberList, IOutboundCallSmsView, IVirtualNumber} from './interface';
import {styles} from './Styles';
import {
  getCallAndSmsNumber,
  getOnlyCallFormated,
  isCallEnabled,
  isSmsInboxWithNumber,
} from './utils';
import CallPreventionSvg from '../Svg/CallPreventionSvg';
import { useIntl } from 'react-intl';
import CustomBottomSheetAlertModel from '../CustomBottomSheetModal/CustomBottomSheetAlertModel';
import { TestIdentifiers, testID } from '../../../testUtils';
import { AntdSpin } from './AntdSpin';

const OutboundCallSmsView = (props: IOutboundCallSmsView) => {
  const {visible, handleAction, isHideSms, onClose, showOnlyCallNumbers, selectedContactData} = props;
  const [modalVisible, setModalVisible] = useState(false);
  const accountId = getAccountId();
  const userId = getUserId();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData;
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const callTypeId = CommunicationType?.find(
    (item: any) => item.code === 'CALL'
  );
  const intl = useIntl();
  const refRBSheet: any = useRef();
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const accountUUID = getAccountUUID();
  const clinicUuid = getMlovId(
    mlovData,
    MLOV_CATEGORY.VIRTUAL_NUMBER_ASSIGNEE,
    'CLINIC'
  );
  const otherUuid = getMlovId(
    mlovData,
    MLOV_CATEGORY.VIRTUAL_NUMBER_ASSIGNEE,
    'OTHER'
  );
  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  const [GET_DEFAULT_CALL_NUMBER] = useLazyQuery(
    CloudTelephonyQueries.GET_DEFAULT_CALL_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [getAccountLocationId] = useLazyQuery(
    CloudTelephonyQueries.getAccountLocationIdByPracticeLocationId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const locationUuid = selectedContactData?.contactPracticeLocations?.[0]?.practiceLocationUuid;
  const getTypeIdByCode = (code: string) => {
    const typeObj = CommunicationType.filter((communication) => {
      return communication?.code === code;
    });
    return typeObj[0]?.id;
  };
  const [stateData, setStateData] = useState({
    numberList: [] as INumberList[],
    inboxes: [] as IInbox[],
    virtualNumber: [] as IVirtualNumber[],
    loading: false,
    showAnotherPhoneCallAlert: false,
    defaultNumber: {},
  });
  const [getInboxesWithChannelTypeFilter] = useLazyQuery(
    InboxQueries.GetInboxesWithChannelTypeFilter,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
      variables: {
        whereCondition: {
          name: {
            _ilike: "%%"
          },
          accountId: {
            _eq: accountId
          },
          isDeleted: {
            _eq: false
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          inboxMembers: {
            userId: {
              _eq: userId
            },
            isDeleted: {
              _eq: false
            }
          }
        }
      },
    }
  );
  const [GET_NUMBER_CALL_SMS] = useLazyQuery(
    CloudTelephonyQueries.GET_OUTGOING_CALL_NUMBERS,{
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getNumbersByAssignee = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let virtualNumber: IVirtualNumber[] = [];
    let inboxes: IInbox[] = [];
    const userDataList = props?.inboxData?.userDataList?.data ? props?.inboxData?.userDataList : await getUsersData();
    if (!isHideSms) {
      const inboxResp = props?.inboxData?.inboxes?.data ? props?.inboxData?.inboxes : await getInboxesWithChannelTypeFilter({
        variables: {
          whereCondition: {
            name: {
              _ilike: "%%"
            },
            accountId: {
              _eq: accountId
            },
            isDeleted: {
              _eq: false
            },
            channelType: {
              _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
            },
            inboxMembers: {
              userId: {
                _eq: userId
              },
              isDeleted: {
                _eq: false
              }
            }
          }
        },
      }).catch((error) => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (inboxResp?.data?.inboxes?.length) {
        inboxes = inboxResp?.data?.inboxes;
        setStateData((prev) => {
          return {
            ...prev,
            inboxes: inboxResp?.data?.inboxes,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            inboxes: [],
          };
        });
      }
      const responseData = props?.inboxData?.virtualPhoneNumberAssignees?.data ? props?.inboxData?.virtualPhoneNumberAssignees : await GET_NUMBER_CALL_SMS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUUID,
          // filter_object: [
          //   {assigneeTypeId: {_eq: clinicUuid}},
          //   {assigneeTypeId: {_eq: otherUuid}},
          // ],
        },
      }).catch((error) => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (responseData?.data?.virtualPhoneNumberAssignees?.length > 0) {
        virtualNumber = responseData?.data?.virtualPhoneNumberAssignees;
        setStateData((prev) => {
          return {
            ...prev,
            virtualNumber: responseData?.data?.virtualPhoneNumberAssignees,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            virtualNumber: [],
            loading: false,
          };
        });
      }
      if (virtualNumber?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            numberList: getCallAndSmsNumber(
              virtualNumber,
              inboxes,
              assigneesTypeList,
              userDataList.data?.users || [],
              showOnlyCallNumbers || false,
            ),
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            numberList: [],
            loading: false,
          };
        });
      }
    } else {
      const responseData = await GET_NUMBER_CALL_SMS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUUID,
          // filter_object: [
          //   {assigneeTypeId: {_eq: clinicUuid}},
          //   {assigneeTypeId: {_eq: otherUuid}},
          // ],
        },
      }).catch((error) => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (responseData?.data?.virtualPhoneNumberAssignees?.length > 0) {
        virtualNumber = responseData?.data?.virtualPhoneNumberAssignees;
        setStateData((prev) => {
          return {
            ...prev,
            virtualNumber: responseData?.data?.virtualPhoneNumberAssignees,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            virtualNumber: [],
            loading: false,
          };
        });
      }
      if (virtualNumber?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            numberList: getOnlyCallFormated(virtualNumber, showOnlyCallNumbers || false),
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            numberList: [],
            loading: false,
          };
        });
      }
    }
    const response = await GET_DEFAULT_CALL_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUUID,
        defaultCommunicationTypeId: callTypeId?.id,
        tenantId: accountUUID
      },
    });
    if (response?.data?.numberDefaultCommunications?.length) {
      const communicationTypeId = getTypeIdByCode(COMMON_ACTION_CODES.CALL);
      const accountLocationResp: any = await getAccountLocationId({
        variables: {
          accountUuid: accountUUID,
          locationUuid: locationUuid,
        },
      });
      const locationId = accountLocationResp?.data?.accountLocations?.[0]?.uuid;
      const defaultForLocationResp: any = await getDefaultPhoneNumberByLocation(
        {
          accountUuid: accountUUID,
          locationId: locationId,
          communicationTypeId: communicationTypeId,
        }
      );

      const defaultForLocationNumber = defaultForLocationResp?.data;
      const practiceLocationDefaultNumber =
        defaultForLocationNumber?.[0]?.VirtualPhoneNumberAssignees
          ?.virtualPhoneNumber;
      const phoneNumber =
        response?.data?.numberDefaultCommunications[0]?.virtualNumberAssignee
          ?.virtualPhoneNumber;

      const finalDefaultNumber = practiceLocationDefaultNumber || phoneNumber;
      let defaultNumberObject: INumberList = {} as INumberList;
      if (finalDefaultNumber) {
        const accountDefaultAssignee =
          response?.data?.numberDefaultCommunications[0]?.virtualNumberAssignee
            ?.assignee;
        const practiceDefaultAssignee =
          defaultForLocationNumber?.[0]?.VirtualPhoneNumberAssignees?.assignee;
        const finalAssignee = practiceDefaultAssignee || accountDefaultAssignee;
        const smsCheck = isSmsInboxWithNumber(finalDefaultNumber, inboxes);
        defaultNumberObject = {
          isCallPossible: isCallEnabled(finalDefaultNumber),
          name: finalAssignee,
          phoneNumber: finalDefaultNumber,
          isSmsInbox: smsCheck.isSmsInbox,
          smsInboxId: smsCheck.id,
        };
      }
      setStateData((prev) => {
        return {
          ...prev,
          defaultNumber: defaultNumberObject,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const contentView = (
    <>
      {stateData.loading ? (
        <Center {...testID(TestIdentifiers.pageLoading)}>
          {isWeb() ? <AntdSpin/> : <Spinner/>}
        </Center>
      ) : (
        <CallAndSmsNumberListView
          isHideSms={isHideSms}
          numberList={stateData.numberList}
          onActionPerformed={handleAction}
          defaultNumber={stateData?.defaultNumber}
          // showOnlyCallNumbers={showOnlyCallNumbers}
        />
      )}
    </>
  );

  useEffect(() => {
    if (visible) {
      !props?.isActiveCall && getNumbersByAssignee();
    }
    if (!isWeb()) {
      if (props?.isActiveCall && visible) {
        setStateData((prev) => {
          return {...prev, showAnotherPhoneCallAlert: true};
        });
      } else {
        setModalVisible(visible);
      }
    }
  }, [visible]);
  const handleVisibleChange = (event: any) => {
    handleAction(COMMON_ACTION_CODES.CLOSE_MODAL);
  };

  return (
    <>
      {isWeb() ? (
        <Popover
          overlayInnerStyle={{
            bottom: 3,
            borderRadius: 16,
            padding: 0,
          }}
          content={contentView}
          trigger="click"
          placement="left"
          visible={visible}
          onVisibleChange={handleVisibleChange}
          overlayStyle={{borderRadius: 8}}
        >
          {props.content}
        </Popover>
      ) : (
        <>
          {props.content}
          <Center>
            {stateData.showAnotherPhoneCallAlert ? (
              <CustomBottomSheetAlertModel
                refRBSheet={refRBSheet}
                onCloseModal={() => {
                  setStateData((prev) => {
                    return {...prev, showAnotherPhoneCallAlert: false};
                  });
                  onClose?.();
                }}
                alertMessage={intl.formatMessage({
                  id: 'alreadyOnAnotherCall',
                })}
                alertIcon={<CallPreventionSvg />}
              />
            ) : (
              <Modal
                onClose={() => {
                  onClose?.();
                  setModalVisible(false);
                }}
                animationPreset="slide"
                isOpen={modalVisible}
                overlayVisible={true}
                style={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Modal.Content
                  width={'full'}
                  paddingX={3}
                  paddingY={3}
                  style={{
                    borderRadius: 16,
                    backgroundColor: '#fff',
                    borderWidth: 1,
                    borderColor: Colors.Custom.CardBorderColor,
                  }}
                >
                  {contentView}
                </Modal.Content>
              </Modal>
            )}
          </Center>
        </>
      )}
    </>
  );
};

export default OutboundCallSmsView;
