import {
  CLOUD_TELEPHONY_APOLLO_CONTEXT,
  CLOUD_TELEPHONY_GRAPHQL_URL,
} from './../../constants/Configs';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  // ServerError,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {FHIR_BASE_URL, allowToShowGqlQueryNames} from '../../constants/BaseUrlConst';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
  CARE_STUDIO_MIDDLEWARE_URL,
  CARE_STUDIO_PROXY_TO_CRM_URL,
  CRM_GRAPHQL_URL,
  FEED_SERVICE_APOLLO_CONTEXT,
  FEED_SERVICE_URL,
  FHIR_APOLLO_CONTEXT,
  HASURA_SECRET,
} from '../../constants/Configs';
import LocalStorage from '../../utils/LocalStorage';
import { isWeb } from '../../utils/platformCheckUtils';
import { getAppHeaders } from '../../utils/APIUtils';

let client: ApolloClient<NormalizedCacheObject>;

export const careStudioToCRMProxy = createHttpLink({
  uri: CARE_STUDIO_PROXY_TO_CRM_URL,
});

export const cloudTelephony = createHttpLink({
  uri: CLOUD_TELEPHONY_GRAPHQL_URL,
});

export const fhir = createHttpLink({
  uri: FHIR_BASE_URL,
});

const crmHttpLink = createHttpLink({
  uri: (operation) =>
    allowToShowGqlQueryNames()
      ? `${CRM_GRAPHQL_URL}?queryName=${operation?.operationName}`
      : CRM_GRAPHQL_URL,
});

const careStudioHttpLink = createHttpLink({
  uri: (operation) =>
    allowToShowGqlQueryNames()
      ? `${CARE_STUDIO_MIDDLEWARE_URL}?queryName=${operation?.operationName}`
      : CARE_STUDIO_MIDDLEWARE_URL,
});

const feedServiceHttpLink = createHttpLink({
  uri: FEED_SERVICE_URL,
});

const feedOrCloudTelephonyContextSwitchLink = ApolloLink.split(
  (operation) => {
    return operation.getContext().service === CLOUD_TELEPHONY_APOLLO_CONTEXT;
  },
  cloudTelephony,
  feedServiceHttpLink
);

const careStudioAndProxyCRMContextSwitchLink = ApolloLink.split(
  (operation) => {
    return operation.getContext().service === CARESTUDIO_PROXY_TO_CRM_CONTEXT;
  },
  careStudioToCRMProxy,
  careStudioHttpLink
);

const feedOrCareStudioContextSwitchLink = ApolloLink.split(
  (operation) => {
    return (
      operation.getContext().service === CARESTUDIO_APOLLO_CONTEXT ||
      operation.getContext().service === CARESTUDIO_PROXY_TO_CRM_CONTEXT
    );
  },
  careStudioAndProxyCRMContextSwitchLink,
  feedOrCloudTelephonyContextSwitchLink
);

const fhirOrCRMContextSwitchLink = ApolloLink.split(
  (operation) => {
    return operation.getContext().service === FHIR_APOLLO_CONTEXT;
  },
  fhir,
  crmHttpLink
);

const contextSwitchLink = ApolloLink.split(
  (operation) => {
    return (
      operation.getContext().service === CARESTUDIO_APOLLO_CONTEXT ||
      operation.getContext().service === CARESTUDIO_PROXY_TO_CRM_CONTEXT ||
      operation.getContext().service === FEED_SERVICE_APOLLO_CONTEXT ||
      operation.getContext().service === CLOUD_TELEPHONY_APOLLO_CONTEXT
    );
  },
  feedOrCareStudioContextSwitchLink,
  fhirOrCRMContextSwitchLink
);

const authLink = setContext(async (operation, {headers}) => {
  const isPublicUrl = isWeb() && window && window.location.href.includes('/widget.html#/public');
  const userDataString = await LocalStorage.getItem('user');
  const accountJsonString = await LocalStorage.getItem('account');

  const userData = JSON.parse(userDataString);
  const accountData = JSON.parse(accountJsonString);

  const apiAccessToken =
    userData?.data?.access_token || '';
  const client = userData?.client || '';
  const responseAccessToken = userData?.accessToken || '';
  const accountId = userData?.data.account_id || accountData?.accountId || '';
  const fold_access_token = userData?.fold_access_token || '';
  const headersObj = {
    api_access_token: apiAccessToken,
    account: accountId,
    client: client,
    ...(!isPublicUrl && {fold_access_token: fold_access_token}),
    'access-token': responseAccessToken,
    'x-hasura-admin-secret': HASURA_SECRET,
    'user-uuid': userData?.data.uuid || '',
    'account-uuid':
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '',
    ...headers,
    ...getAppHeaders(),
  };

  if (accountId) {
    headersObj['x-hasura-account-id'] = accountId;
  }
  if (headersObj?.['account-uuid']) {
    headersObj['x-hasura-account-uuid'] = headersObj['account-uuid'];
  }

  return {
    headers: headersObj,
    ...(!operation?.context?.service && {
      credentials: 'include',
      mode: 'cors',
    }),
  };
});

export function getClient(errorLink: any) {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  if (!client) {
    client = new ApolloClient({
      link: authLink.concat(errorLink).concat(contextSwitchLink),
      cache: new InMemoryCache({
        addTypename: false,
      }),
      defaultOptions: defaultOptions,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
      },
    });
  }
  return client;
}

export default {getClient};
