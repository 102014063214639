import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  Pressable,
  Text,
  TextArea,
  View,
  useToast,
} from 'native-base';
import {useIntl} from 'react-intl';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../common/PAMISearch/PAMISearch';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../CustomWrapper/CustomWrapper';
import {useContext, useEffect, useRef, useState} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {IMedicationStatement} from '../Medications/interfaces';
import {
  getMedicationName,
  getRecordListFormatted,
} from '../Medications/AddOrUpdateMedications/AddOrUpdateMedicationsHelper';
import {MEDICATION_STATUS_CODE} from '../../../../../../constants/MlovConst';
import {v4 as uuidv4} from 'uuid';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {FlatList, TextStyle, ViewStyle} from 'react-native';
import {useContainerDimensions} from '../../../../../CustomHooks/ContainerDimensionHook';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
} from '../../../../../../constants';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {MedicationStatus, MedicationViewType} from './interface';
import {
  getIndexByUniqueId,
  getMedicationStatusText,
  getMedicationUniqueId,
  getStartAndEndDateText,
  isMedicationEditing,
  removeFromEditView,
  updateViewTypeByUniqueId,
} from './MedicationUtils';
import {DetailViewCollapse} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {
  FormError,
  getSectionNoteDetails,
} from '../CustomWrapper/CustomComponentHelper';
import {Colors} from '../../../../../../styles';
import {DatePicker, Popover} from 'antd';
import {
  getDateStrFromFormat,
  getMomentObjectWithDateStringAndFormat,
  isBeforeDate,
  isFutureDate,
  isPastDateTime,
} from '../../../../../../utils/DateUtils';
import {IKeyOperation} from '../../../../../../Interfaces';
import {Extension} from 'fhir/r4';
import {cloneDeep, debounce} from 'lodash';
import ThreeDotsSvg from '../../../../../common/Svg/ThreeDotsSvg';
import {NoMedicationsSvg} from '../../../../../common/Svg';
import Feather from 'react-native-vector-icons/Feather';
import {MEDICATION_ACTIONS} from './Constants';
import DeleteSvg from '../../../../../common/Svg/DeleteSvg';
import AddNoteIcon from '../../../../../../assets/Icons/AddNoteIcon';
import {
  getMedicationRequestByPatientId,
  getMedicationStatementAndPatientReportedOrder,
} from '../../../../../../services/CommonService/OrderService';
import {filterAndSkipDuplicates} from '../CustomComponentUtils';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import FormComponentError from '../CustomWrapper/FormComponentError/FormComponentError';
import ThreeDotIconSvg from '../../../../../../assets/Icons/ThreeDotIconSvg';
import { styles } from './AddorUpdateCarePlanMedicationsStyles';
import { DoubleTickIconSvg } from '../../../../../../assets/Icons/DoubleTickIconSvg';
import { StopIconSvg } from '../../../../../../assets/Icons/StopIconSvg';
import CrossIconSvg from '../../../../../common/Svg/CrossIconSvg';
import { ACTIVE_STATUS_CODE } from '../constants';

interface IComponentValueState {
  medicationStatements: IMedicationStatement[];
}

interface IAddOrUpdateMedicationState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
  editModalCollapse: boolean;
  selectedMedication?: IMedicationStatement;
  customError?: string;
}

const AddOrUpdateCarePlanMedications = (props: IFormComponentProps) => {
  const componentRef = useRef();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;

  const intl = useIntl();
  const toast = useToast();
  const [componentValue, setComponentValue] = useState<IComponentValueState>(
    props.component?.selectedValue ||
      props.component?.defaultComponentValue || {
        medicationStatements: [],
      }
  );
  const {width} = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];

  const [componentState, setComponentState] =
    useState<IAddOrUpdateMedicationState>({
      selectedMedication: undefined,
      showErrors: false,
      loading: false,
      formError: contextData.formError,
      editModalCollapse: false,
    });

  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };

  const dateFormat = getFieldCapabilities()?.effectivePeriod?.format;
  const stopDateFormat = getFieldCapabilities()?.stopDate?.format || dateFormat;
  const isListEditing = isMedicationEditing(
    componentValue.medicationStatements
  );

  useEffect(() => {
    if (
      contextData.patientId &&
      componentValue.medicationStatements.length === 0
    ) {
      getExistingData({patientId: contextData.patientId, skipLoader: false});
    }
  }, []);

  useEffect(() => {
    const value = cloneDeep(componentValue);
    value.medicationStatements.forEach((item) => delete item.isNewAdded);
    if (contextData.isBuilderMode) {
      props.component.defaultComponentValue = value;
    }
    if (!isListEditing) {
      props.onChange(value);
    }
  }, [componentValue]);

  const addNewItem = (item: any) => {
    const data: IMedicationStatement = {
      medicationStatement: item,
      name: getMedicationName(item),
      status: {code: MEDICATION_STATUS_CODE.ACTIVE},
      uniqueId: uuidv4(),
      isFreeTextRecord: false,
      showNote: false,
      viewType: MedicationViewType.edit,
      isFirstEdit: true,
      isNewAdded: true,
    };

    setComponentState((prev) => ({
      ...prev,
      selectedMedication: cloneDeep(data),
    }));

    setComponentValue((prev) => {
      componentValue.medicationStatements.splice(0, 0, data);
      return {
        ...prev,
        medicationStatements: [...componentValue.medicationStatements],
      };
    });
  };

  const getExistingData = (params: {
    patientId: string;
    skipLoader?: boolean;
    manualImport?: boolean;
  }) => {
    const {patientId, skipLoader, manualImport} = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    try {
      Promise.all([
        getMedicationStatementAndPatientReportedOrder(
          patientId,
          contextData.locationId,
          !contextData.hasLoggedInContext,
          true,
          contextData.headers
        ),
        getMedicationRequestByPatientId(
          patientId,
          contextData.locationId,
          !contextData.hasLoggedInContext,
          contextData.headers
        ),
      ])
        .then((responses) => {
          const medicationRequestData = responses[1]?.data;
          const mergeResponse = cloneDeep(responses[0]?.data);
          const responseEntry = cloneDeep(
            mergeResponse.resource?.data?.entry || []
          );
          if (medicationRequestData?.entry?.length) {
            (medicationRequestData?.entry).forEach((resource: any) => {
              responseEntry.push(resource);
            });
          }
          mergeResponse.resource.data['entry'] = responseEntry;
          if (
            mergeResponse?.resource?.data?.entry?.length > 0 ||
            mergeResponse?.patientReported?.data?.entry?.length > 0
          ) {
            handleExistingDataResponse(mergeResponse, manualImport);
          } else {
            const sectionNote = getSectionNoteDetails(
              mergeResponse?.resource?.data?.meta?.extension || []
            ).sectionNote;
            setComponentState((prev) => ({
              ...prev,
              loading: false,
              sectionNote: sectionNote,
              showSavingIndicator: false,
              activePanels: [],
              formError: undefined,
            }));
            return;
          }
        })
        .catch((error) => {
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        });
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        showSavingIndicator: false,
        formError: FormError.existingDataAPIFail,
      }));
    }
  };

  const handleExistingDataResponse = (
    response: any,
    manualImport?: boolean
  ) => {
    if (isPatientForm) {
      const ehrRecords = response?.resource?.data?.entry;
      const sectionNote = getSectionNoteDetails(
        response?.resource?.data?.meta?.extension || []
      ).sectionNote;
      if (ehrRecords) {
        const formattedData = getRecordListFormatted(
          ehrRecords,
          getFieldCapabilities(),
          contextData.capabilities?.ehrName
        );
        const newRecordsAddedInForm =
          componentValue?.medicationStatements || [];
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: [...newRecordsAddedInForm, ...formattedData],
        }));
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        formError: !ehrRecords ? FormError.existingDataAPIFail : prev.formError,
      }));
    } else {
      const patientReportedRecords =
        response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(
        response?.resource?.data?.meta?.extension || []
      ).sectionNote;
      let patientReportedFormattedRecords: IMedicationStatement[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IMedicationStatement[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(
            patientReportedRecords,
            getFieldCapabilities(),
            contextData.capabilities?.ehrName
          );
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(
            ehrRecords,
            getFieldCapabilities(),
            contextData.capabilities?.ehrName
          );
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IMedicationStatement>(
              componentValue.medicationStatements,
              ehrFormattedRecords
            );
          }
        }
        const filteredData = ehrFormattedRecords?.filter(statement => statement?.status?.code?.toLowerCase() === ACTIVE_STATUS_CODE.toLowerCase()) || [];
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: [...filteredData],
        }));
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        formError:
          !ehrRecords || !patientReportedRecords
            ? FormError.existingDataAPIFail
            : prev.formError,
      }));
    }
  };

  const getSearchComponent = (): JSX.Element => {
    return (
      <Pressable width={'100%'} onPress={(event) => event.stopPropagation()}>
        <View flex={1} style={{width: '100%'}}>
          <PAMISearch
            addNewOptionEnabled={true}
            clearOnSelection
            searchType={PAMISearchType.medication}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new medication"
            isShowError={false}
            disabled={isListEditing}
            onChange={(value) => {
              if (value) {
                addNewItem(value);
              }
            }}
          />
        </View>
      </Pressable>
    );
  };

  const onDetailChange = (medication: IMedicationStatement) => {
    setComponentValue((prev) => {
      const prevMedications = prev.medicationStatements;
      const index = getIndexByUniqueId(prev.medicationStatements, medication);
      prevMedications[index] = medication;
      return {
        ...prev,
        medicationStatements: prevMedications,
      };
    });
  };

  const onSaveMedication = (medication: IMedicationStatement) => {
    delete medication.isFirstEdit;
    setComponentValue((prev) => {
      const updatedMedications = updateViewTypeByUniqueId(
        prev.medicationStatements,
        medication,
        MedicationViewType.list
      );
      return {
        ...prev,
        medicationStatements: updatedMedications,
      };
    });
  };

  const handleSigUpdate = (medication: IMedicationStatement, text: string) => {
    const extensionURL = getFieldCapabilities()?.sig?.extensionUrl || '';
    medication.sig = text;
    let hasExtension = false;
    if (!medication.extension) {
      medication.extension = [];
    }
    medication.extension?.forEach((extension: Extension) => {
      if (extension.url === extensionURL) {
        hasExtension = true;
        extension.valueString = text;
      }
    });
    if (!hasExtension) {
      medication.extension?.push({
        url: extensionURL,
        valueString: text,
      });
    }
    onDetailChange(medication);
  };

  const renderEditMedicationView = (medication: IMedicationStatement) => {
    const uniqueId = medication.id;
    const startDate = medication.effectivePeriod
      ? getDateStrFromFormat(
          medication.effectivePeriod,
          DATE_FORMATS.API_DATE_FORMAT
        )
      : '';

    return (
      <Stack
        direction="column"
        style={{paddingVertical: 12, paddingHorizontal: 12}}
      >
        <Stack style={{flex: 1}} space={10} direction="column">
          <FormControl isDisabled={false} flex={1}>
            <FormControl.Label isRequired={true}>
              <DisplayText
                textLocalId="title"
                extraStyles={{color: Colors.Custom.Gray400}}
              />
            </FormControl.Label>
            <Input
              value={medication.name || ''}
              isDisabled
              style={{height: '40px'}}
            />
          </FormControl>

          <Stack direction="row" space={8}>
            <FormControl isDisabled={false} flex={1}>
              <FormControl.Label>
                <DisplayText
                  textLocalId="startFrom"
                  extraStyles={{color: Colors.Custom.Gray400}}
                />
              </FormControl.Label>
              <DatePicker
                format={DISPLAY_DATE_FORMAT}
                value={
                  medication.effectivePeriod
                    ? getMomentObjectWithDateStringAndFormat(
                        medication.effectivePeriod,
                        dateFormat || ''
                      )
                    : undefined
                }
                size="small"
                style={{height: '40px', borderRadius: 4}}
                onChange={(value) => {
                  medication.effectivePeriod = dateFormat
                    ? value?.format(dateFormat)
                    : value?.toISOString();
                  onDetailChange(medication);
                }}
              />
            </FormControl>
            <FormControl isDisabled={false} flex={1}>
              <FormControl.Label>
                <DisplayText
                  textLocalId="stopDate"
                  extraStyles={{color: Colors.Custom.Gray400}}
                />
              </FormControl.Label>
              <DatePicker
                onChange={(value) => {
                  medication.stopDate = !!stopDateFormat
                    ? value?.format(stopDateFormat)
                    : value?.toISOString();
                  onDetailChange(medication);
                }}
                size="small"
                style={{height: '40px', borderRadius: 4}}
                format={DISPLAY_DATE_FORMAT}
                disabledDate={(current: any) => {
                  if (startDate) {
                    return current && isBeforeDate(current, startDate);
                  } else {
                    return current && isFutureDate(current);
                  }
                }}
                value={
                  medication.stopDate
                    ? getMomentObjectWithDateStringAndFormat(
                        medication.stopDate,
                        stopDateFormat || ''
                      )
                    : undefined
                }
              />
            </FormControl>
          </Stack>
          <FormControl isDisabled={false} flex={1}>
            <FormControl.Label>
              <DisplayText
                textLocalId="dosage"
                extraStyles={{color: Colors.Custom.Gray400}}
              />
            </FormControl.Label>
            <Input
              size={'small'}
              defaultValue={medication.sig}
              onChangeText={debounce((text: string) => {
                handleSigUpdate(medication, text);
              }, 100)}
              height={36}
            />
          </FormControl>
          {medication.showNote && (
            <FormControl isDisabled={false} flex={1}>
              <FormControl.Label>
                <DisplayText
                  textLocalId="notes"
                  extraStyles={{color: Colors.Custom.Gray400}}
                />
              </FormControl.Label>
              <TextArea
                totalLines={2}
                defaultValue={medication.note}
                onChangeText={debounce((text: string) => {
                  medication.note = text;
                  onDetailChange(medication);
                }, 500)}
              />
            </FormControl>
          )}

          <Stack direction="row" style={{justifyContent: 'flex-start'}}>
            {!medication.showNote && (
              <Button
                variant="unstyled"
                style={styles.addNoteIconButton}
                leftIcon={<AddNoteIcon />}
                onPress={() => {
                  medication.showNote = true;
                  onDetailChange(medication);
                }}
              >
                <DisplayText
                  textLocalId="addNote"
                  extraStyles={{color: Colors.Custom.Primary300}}
                />
              </Button>
            )}
          </Stack>
          <Stack direction="row" style={{justifyContent: 'flex-end'}}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                style: {margin: 3},
                onPress: () => {
                  setComponentValue((prev) => {
                    const updatedMedicatins = removeFromEditView(
                      prev.medicationStatements,
                      componentState.selectedMedication || medication
                    );
                    return {
                      ...prev,
                      medicationStatements: updatedMedicatins,
                    };
                  });
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                style: {margin: 3},
                onPress: () => {
                  onSaveMedication(medication);
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'save',
                }),
                withRightBorder: false,
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const getStyleByMedicationStatus = (medicationStatus: string) => {
    let icon = <></>;

    const style: ViewStyle = {
      height: 32,
      marginRight: 8,
      padding: 8,
      borderWidth: 0.5
    };

    const textStyle = {
      color: Colors.Custom.Gray500,
    };

    if (medicationStatus === MedicationStatus.willContinue) {
      style.borderColor = Colors.Custom.Gray300;
      icon = <DoubleTickIconSvg/>
    }

    if (medicationStatus === MedicationStatus.willStop) {
      style.backgroundColor = Colors.Custom.Red100;
      style.borderColor = Colors.FoldPixel.STATUS_ERROR;
      textStyle.color = Colors.FoldPixel.STATUS_ERROR;
      icon = <StopIconSvg/>
    }

    return {
      style,
      textStyle,
      icon
    };
  };

  const onActionPerformed = (
    action: string,
    medication: IMedicationStatement
  ) => {
    switch (action) {
      case MEDICATION_ACTIONS.EDIT:
        const updatedMedications = updateViewTypeByUniqueId(
          componentValue.medicationStatements,
          medication,
          MedicationViewType.edit
        );
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: updatedMedications,
        }));

        setComponentState((prev) => ({
          ...prev,
          selectedMedication: cloneDeep(medication),
        }));

        break;
      case MEDICATION_ACTIONS.DELETE:
        const index = getIndexByUniqueId(
          componentValue.medicationStatements,
          medication
        );
        componentValue.medicationStatements.splice(index, 1);
        setComponentValue((prev) => ({
          ...prev,
          medicationStatements: [...componentValue.medicationStatements],
        }));
        break;
    }
  };

  const renderActionMenu = (medication: IMedicationStatement) => {
    const actions: any[] = [];

    actions.push({
      btnText: 'Edit',
      action: MEDICATION_ACTIONS.EDIT,
      isDisabled: isListEditing,
      btnTextColor: Colors.Custom.Gray500,
      icon: <Feather name="edit-2" color={Colors.Custom.Gray500} size={18} />,
    });

    actions.push({
      btnText: 'Delete',
      btnTextColor: Colors.Custom.crossIconColor,
      action: MEDICATION_ACTIONS.DELETE,
      isDisabled: false,
      icon: <DeleteSvg />,
    });

    return (
      <FlatList
        data={actions}
        keyExtractor={(item)=> item.btnText}
        renderItem={({item, index}) => (
          <>
            <Pressable
              disabled={item.isDisabled}
              flexDirection="row"
              alignItems="center"
              my={2}
              onPress={() => onActionPerformed(item.action, medication)}
            >
              {item.icon}
              <Text
                color={item.btnTextColor}
                fontSize={14}
                fontWeight="400"
                marginLeft={4}
              >
                {item.btnText}
              </Text>
            </Pressable>
            {index !== actions.length - 1 && <Divider />}
          </>
        )}
      />
    );
  };

  const renderListMedicationView = (medication: IMedicationStatement) => {
    const startTimeEndTimeText = getStartAndEndDateText(medication);

    return (
      <Stack direction="column" style={{padding: 12}}>
        <Stack direction="row" style={{alignItems: 'center'}} space={3}>
          <Stack direction="column" style={{flex: 1, marginRight: 12}}>
            <DisplayText
              textLocalId={medication.name || ''}
              extraStyles={{
                fontSize: 14,
                color: Colors.FoldPixel.GRAY400,
              }}
            />
            {startTimeEndTimeText && (
              <DisplayText
                textLocalId={startTimeEndTimeText || ''}
                extraStyles={{
                  fontSize: 14,
                  color: Colors.FoldPixel.GRAY200,
                }}
              />
            )}
            {medication.sig && (
              <DisplayText
                textLocalId={medication.sig || ''}
                extraStyles={{
                  fontSize: 12,
                  marginTop: 2,
                  color: Colors.FoldPixel.GRAY200,
                }}
              />
            )}
          </Stack>
          <Stack direction="row" style={{alignItems: 'center'}}>
            {!medication.medicationStatus && !medication.isNewAdded && (
              <>
                <Button
                  variant="unstyled"
                  style={styles.continueButton}
                  onPress={() => {
                    medication.medicationStatus = MedicationStatus.willContinue;
                    onDetailChange(medication);
                  }}
                >
                  <DisplayText
                    textLocalId="continue"
                    extraStyles={{color: Colors.Custom.Gray500}}
                  />
                </Button>
                <Button
                  variant="unstyled"
                  style={styles.stopButton}
                  onPress={() => {
                    medication.medicationStatus = MedicationStatus.willStop;
                    onDetailChange(medication);
                  }}
                >
                  <DisplayText
                    textLocalId="stop"
                    extraStyles={{color: Colors.Custom.Primary300}}
                  />
                </Button>
              </>
            )}
            {medication.medicationStatus && (
              <Button
                variant="unstyled"
                style={
                  getStyleByMedicationStatus(medication.medicationStatus).style
                }
                leftIcon={
                  getStyleByMedicationStatus(medication.medicationStatus).icon
                }
                padding={2}
                rightIcon={
                  <Stack direction="row">
                    <Divider
                      orientation="vertical"
                      height={4}
                      marginRight={1.5}
                      marginTop={'1px'}
                      opacity={0.4}
                      backgroundColor={getStyleByMedicationStatus(medication.medicationStatus).textStyle.color}
                    />
                    <Pressable
                      onPress={() => {
                        medication.medicationStatus = undefined;
                        onDetailChange(medication);
                      }}
                    >
                      <CrossIconSvg
                        size={18}
                        customStrokeColor={
                          getStyleByMedicationStatus(
                            medication.medicationStatus
                          ).textStyle.color
                        }
                      />
                    </Pressable>
                  </Stack>
                }
              >
                <DisplayText
                  textLocalId={
                    getMedicationStatusText(medication.medicationStatus) || ''
                  }
                  extraStyles={
                    getStyleByMedicationStatus(medication.medicationStatus)
                      .textStyle
                  }
                />
              </Button>
            )}

            <Divider
              orientation="vertical"
              marginLeft={2}
              height={4}
              marginRight={2}
            />
            <Popover
              overlayClassName="custom-popover-styles"
              showArrow={true}
              destroyTooltipOnHide
              placement="bottomRight"
              content={renderActionMenu(medication)}
              trigger="click"
              overlayInnerStyle={{
                borderRadius: 16,
                width: 140,
                padding: 0,
              }}
              style={{cursor: 'pointer'}}
            >
              <View cursor={'pointer'}>
                <ThreeDotIconSvg style={{padding: 8, margin: 0}} />
              </View>
            </Popover>
          </Stack>
        </Stack>
        {medication.note && (
          <View style={styles.noteContainer}>
            <DisplayText
              textLocalId={medication.note || ''}
              extraStyles={styles.noteText}
            />
          </View>
        )}
      </Stack>
    );
  };

  const renderListView = () => {
    const medications = componentValue.medicationStatements;

    return (
      <FlatList
        data={medications}
        keyExtractor={(item) => `care_plan_medications_${getMedicationUniqueId(item)}`}
        renderItem={({item, index}) => {
          return (
            <Stack direction="column" key={getMedicationUniqueId(item)}>
              {item.viewType === MedicationViewType.edit &&
                renderEditMedicationView(item)}
              {(!item.viewType || item.viewType === MedicationViewType.list) &&
                renderListMedicationView(item)}
              {index !== medications.length - 1 && <Divider />}
            </Stack>
          );
        }}
      />
    );
  };

  return (
    <div className="page-break">
      <DetailViewCollapse
        btnList={[]}
        hideArrow
        collapsible="disabled"
        textLocalId={props.component.label}
        toggled={!componentState.editModalCollapse}
        onToggled={() => {
          setComponentState((prev) => ({
            ...prev,
            editModalCollapse: !prev.editModalCollapse,
          }));
        }}
      >
        <Stack
          direction="column"
          style={{flex: 1, paddingHorizontal: 12, paddingTop: 12}}
        >
          {getSearchComponent()}
          {componentValue.medicationStatements.length > 0 && (
            <Stack
              direction="column"
              style={styles.listViewContainer}
            >
              {renderListView()}
            </Stack>
          )}
          {componentValue.medicationStatements.length === 0 &&
            !componentState.loading && (
              <NoMedicationsSvg titleId="noMedicationList" />
            )}
          {componentState.loading && (
            <View padding={4}>
              <FormComponentLoader />
            </View>
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError
              error={FormError.custom}
              customError={componentState.customError}
            />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
        </Stack>
      </DetailViewCollapse>{' '}
    </div>
  );
};

export default AddOrUpdateCarePlanMedications;
