import React, {useContext} from 'react';
import AddOrUpdateTask from '../../AddTask/AddOrUpdateTask';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {
  getAccountUUID,
  getUserData,
  getUserFullName,
  getUserName,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {useMutation} from '@apollo/client';
import TaskQueries from '../../../../services/Task/TaskQueries';
import {EventBus} from '../../../../utils/EventBus';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {CONVERSATION_TAGGED_ITEM_TYPE} from '../../../../constants/StringConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {MessageReadInfo} from './MessageReadInfo/MessageReadInfo';
import {IMessageBoxActionViewDrawer} from './interfaces';

const MessageBoxActionViewDrawer = (props: IMessageBoxActionViewDrawer) => {
  const userUuid = getUserUUID();
  const currentUserData = getUserData();
  const userName = getUserName();
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);

  const [CreateConversationTask] = useMutation(
    TaskQueries.CREATE_CONVERSATION_TAGGED_ITEM
  );
  const conversationTaggedItem = getMlovListFromCategory(
    commonData.MLOV,
    'ConversationTaggedItem'
  );
  const itemTypeId = getMlovIdFromCode(
    conversationTaggedItem,
    CONVERSATION_TAGGED_ITEM_TYPE.TASK
  );

  const getSenderUserId = (selectedMessageData: any) => {
    if (selectedMessageData?.senderType?.toLowerCase() == 'user') {
      return selectedMessageData?.senderUser?.id;
    } else if (selectedMessageData?.senderType?.toLowerCase() == 'contact') {
      return selectedMessageData?.senderContact?.id;
    }
    return {};
  };

  const onFormActionPerformed = async (
    actionCode: string,
    payload?: {addOrUpdateTask: {id: string}}
  ) => {
    const eventBus = EventBus.getEventBusInstance();
    switch (actionCode) {
      case COMMON_ACTION_CODES.TASK_COMPLETE:
        if (props?.onActionPerformed) {
          props?.onActionPerformed();
        }
        try {
          const result = await CreateConversationTask({
            variables: {
              accountUuid: accountUuid,
              conversationUuid: props?.conversationUuid,
              itemTypeId: itemTypeId,
              itemId: payload?.addOrUpdateTask?.id || '',
              messageUuid: props?.actionData?.uuid,
            },
          });
          if (result) {
            eventBus.broadcastEvent('NEW_TASK_ADDED', {});
          }
        } catch (error) {}
        break;
      case COMMON_ACTION_CODES.TASK_CANCEL:
        if (props?.onActionPerformed) {
          props?.onActionPerformed();
        }
        break;
    }
  };

  const getMessageReadInfoElement = (messageData: any) => {
    return (
      <MessageReadInfo
        onCancel={() => {
          onFormActionPerformed(COMMON_ACTION_CODES.TASK_CANCEL);
        }}
        groupConversationId={props?.groupConversationId}
        messageDate={messageData.date}
        conversationUuid={props?.conversationUuid}
        private={messageData.private}
        senderId={messageData?.senderUser?.id}
        messageUuid={messageData?.uuid}
      />
    );
  };

  const getMessageSenderName = (selectedMessageData: any) => {
    let senderName = '';
    if (selectedMessageData?.senderType?.toLowerCase() == 'user') {
      if (selectedMessageData?.senderFullName == userName) {
        senderName = 'You';
      } else {
        senderName =
          selectedMessageData?.senderUser?.name ||
          selectedMessageData?.senderFullName ||
          '';
      }
    } else {
      senderName =
        selectedMessageData?.senderContact?.name ||
        selectedMessageData?.senderFullName ||
        '';
    }
    if (selectedMessageData?.messageData?.contentAttributes?.isAutoResponse) {
      senderName = 'Automated Message';
    }
    return senderName.length ? senderName : '';
  };

  const getAddOrUpdateTask = (messageData: any) => {
    const senderUserId = getSenderUserId(messageData);
    return (
      <AddOrUpdateTask
        extraData={{
          description: messageData?.displayContent,
          userId: senderUserId,
          userName: getMessageSenderName(messageData),
          dateTime: messageData?.dateStr,
        }}
        member={{
          value: props?.contactData?.uuid,
          label: props?.contactData?.name,
          key: props?.contactData?.uuid,
          type: ParticipantType.patient,
          contactData: props?.contactData,
          details: props?.contactData,
        }}
        task={
          {
            contact: props?.contactData,
          } as any
        }
        assignee={{
          value: userUuid,
          label: getUserFullName(),
          key: userUuid,
          type: ParticipantType.staff,
          details: currentUserData,
        }}
        isVisible={props?.actionCode === COMMON_ACTION_CODES.CREATE_TASK}
        onComplete={(data) => {
          onFormActionPerformed(COMMON_ACTION_CODES.TASK_COMPLETE, data);
        }}
        onCancel={() => {
          onFormActionPerformed(COMMON_ACTION_CODES.TASK_CANCEL);
        }}
      />
    );
  };

  const getMessageBoxAction = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CREATE_TASK:
        return getAddOrUpdateTask(actionData);

      case COMMON_ACTION_CODES.MESSAGE_INFO:
        return getMessageReadInfoElement(actionData);
    }
  };

  return <>{getMessageBoxAction(props?.actionCode, props?.actionData)}</>;
};

export default MessageBoxActionViewDrawer;
