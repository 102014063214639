import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

const ScheduleMsgActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <G clipPath="url(#clip0_3588_114983)">
        <Path
          fill={strokeColor}
          d="M1.721 13.22l.483.13-.483-.13zm.397-1.484l-.483-.129.483.13zm2.148 2.148l.13.483-.13-.483zm-1.484.398l-.129-.483.13.483zM2.03 10.97l.448-.223-.448.223zm3.003 3.003l-.223.447.223-.447zM8 8.208h-.5v.223l.166.149L8 8.208zm.5-2.872a.5.5 0 10-1 0h1zm1.166 5.039a.5.5 0 10.668-.745l-.668.745zm4.5-2.372A6.167 6.167 0 018 14.169v1a7.167 7.167 0 007.167-7.166h-1zm-12.333 0A6.167 6.167 0 018 1.836v-1A7.167 7.167 0 00.833 8.003h1zM8 1.836a6.167 6.167 0 016.167 6.167h1A7.167 7.167 0 008 .836v1zM2.204 13.35l.397-1.484-.966-.259-.397 1.484.966.259zm1.933.052l-1.484.397.259.966 1.484-.398-.259-.966zm-1.66-2.654a6.14 6.14 0 01-.643-2.745h-1c0 1.145.269 2.23.748 3.19l.895-.445zM8 14.169a6.14 6.14 0 01-2.745-.643l-.446.895A7.14 7.14 0 008 15.17v-1zm-3.604.198a.59.59 0 01.413.054l.446-.895a1.588 1.588 0 00-1.118-.124l.259.966zM2.6 11.867c.104-.39.04-.786-.124-1.118l-.895.446a.59.59 0 01.053.413l.966.259zM1.238 13.09a1.367 1.367 0 001.674 1.674l-.259-.966a.367.367 0 01-.449-.45l-.966-.258zM8.5 8.208V5.336h-1v2.872h1zm-.834.372l2 1.795.668-.745-2-1.794-.668.744z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_3588_114983">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ScheduleMsgActionSvg;
