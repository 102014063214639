import React from 'react';
import { Svg, Path } from 'react-native-svg';

const DiagnosticReportIconSvg = () => {
  return (
    <Svg
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 20 22"
    >
      <Path
        fill="#6F7A90"
        d="M10.5 5a.5.5 0 00-1 0h1zm-1 4a.5.5 0 001 0h-1zM8 6.5a.5.5 0 100 1v-1zm4 1a.5.5 0 000-1v1zm-6 5a.5.5 0 000 1v-1zm8 1a.5.5 0 000-1v1zm-7 3a.5.5 0 000 1v-1zm6 1a.5.5 0 000-1v1zm-4-16h2v-1H9v1zM18.5 9v4h1V9h-1zM11 20.5H9v1h2v-1zM1.5 13V9h-1v4h1zM9 20.5c-1.9 0-3.274-.001-4.322-.142-1.034-.139-1.675-.406-2.153-.883l-.707.707c.694.694 1.582 1.013 2.726 1.167 1.13.152 2.585.151 4.456.151v-1zM.5 13c0 1.871-.001 3.325.15 4.456.155 1.144.474 2.032 1.168 2.726l.707-.707c-.477-.478-.744-1.119-.883-2.152C1.502 16.274 1.5 14.9 1.5 13h-1zm18 0c0 1.9-.001 3.274-.142 4.323-.139 1.033-.406 1.674-.883 2.152l.707.707c.694-.694 1.013-1.582 1.167-2.726.152-1.13.151-2.585.151-4.456h-1zM11 21.5c1.871 0 3.325.001 4.456-.15 1.144-.155 2.032-.474 2.726-1.168l-.707-.707c-.478.477-1.119.744-2.153.883-1.048.14-2.422.142-4.322.142v1zm0-20c1.9 0 3.274.001 4.322.142 1.034.139 1.675.406 2.153.883l.707-.707C17.488 1.124 16.6.805 15.456.651 14.326.499 12.87.5 11 .5v1zM19.5 9c0-1.871.001-3.325-.15-4.456-.155-1.144-.474-2.032-1.168-2.726l-.707.707c.477.478.744 1.119.883 2.153.14 1.048.142 2.422.142 4.322h1zM9 .5C7.129.5 5.675.499 4.544.65 3.4.806 2.512 1.125 1.818 1.819l.707.707c.478-.477 1.119-.744 2.153-.883C5.726 1.502 7.1 1.5 9 1.5v-1zM1.5 9c0-1.9.001-3.274.142-4.322.139-1.034.406-1.675.883-2.153l-.707-.707C1.124 2.512.805 3.4.651 4.544.499 5.674.5 7.13.5 9h1zm8-4v2h1V5h-1zm0 2v2h1V7h-1zM8 7.5h2v-1H8v1zm2 0h2v-1h-2v1zm-4 6h8v-1H6v1zm1 4h6v-1H7v1z"
      ></Path>
    </Svg>
  );
};

export default DiagnosticReportIconSvg;
