import React, {useReducer, useState} from 'react';
import {CommonDataContext, ICommonData} from '../../context/CommonDataContext';
import {CommonDataAction, ICommonDataProviderProps, ICommonDataProviderState} from './interface';

const CommonDataProvider = (props: ICommonDataProviderProps) => {
  const {allCommonData} = props;

  const ourReducer = (
    state: ICommonData,
    action: {
      type: CommonDataAction;
      payload: any;
    }
  ): ICommonData => {
    switch (action.type) {
      case CommonDataAction.UPDATE_EHR_CAPABILITES:
        return {...state, ehrCapabilities: action.payload};
      default:
        return state;
    }
  };

  const [commonData, dispatch] = useReducer(ourReducer, allCommonData);

  return (
    <CommonDataContext.Provider value={{...commonData,dispatch:dispatch}}>
      {props.children}
    </CommonDataContext.Provider>
  );
};

export default CommonDataProvider;
