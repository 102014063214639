import {Button, View} from 'native-base';
import React, { useContext, useEffect} from 'react';
import {Dimensions} from 'react-native';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles';
import {IFoldButton} from '../interface';
import { testID } from '../../../testUtils';

export const FoldButton = (props: IFoldButton) => {
//   const {height} = Dimensions.get('window');
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

//   useEffect(() => {}, []);

  if (props.customProps?.hideButton) {
    return <></>;
  }
  const badgeView = props.customProps?.badgeView;

  const getButtonView = () => {
    return (
      <Button
        paddingLeft={isSideCarContext ? 2 : props.nativeProps?.leftIcon ? 3 : 4}
        paddingRight={isSideCarContext ? 2 : props.nativeProps?.rightIcon ? 3 : 4}
        {...props.nativeProps}
        {...testID(props.customProps.btnText)}
      >
        {props.customProps.btnText}
      </Button>
    );
  };

  return (
    <>
      {props.customProps.withRightBorder ? (
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <Button
            {...props.nativeProps}
            rightIcon={undefined}
            borderRightRadius={0}
            {...testID(props.customProps.btnText)}
          >
            {props.customProps.btnText}
          </Button>
          <Button
            width={'20px'}
            {...props.nativeProps}
            leftIcon={undefined}
            borderLeftRadius={0}
            borderLeftWidth={0}
            onPress={
              props.customProps?.isDisableOnPress
                ? null
                : props.nativeProps.onPress
            }
          ></Button>
        </View>
      ) : badgeView ? (
        <View style={{position: 'relative'}}>
          {badgeView}
          {getButtonView()}
        </View>
      ) : (
        getButtonView()
      )}
    </>
  );
};
