import {gql} from '@apollo/client';

export const GetTeamsForFilter = gql`
  query getTeamsForFilter {
    teams(order_by: {name: asc}) {
      name
      id
      conversations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

// export const CreateTeamMembers = gql`
//   mutation createTeamMembers($teamMembers: [team_members_insert_input!]!) {
//     createTeamMembers(objects: $teamMembers) {
//       returning {
//         id
//       }
//     }
//   }
// `;

export const CreateTeamMembers = gql`
  mutation createTeamMembers($teamMembers: [team_members_insert_input!]!) {
    createTeamMembers(
      objects: $teamMembers
      on_conflict: {constraint: team_members_pkey, update_columns: isDeleted}
    ) {
      returning {
        id
      }
    }
  }
`;

export const CreateTeamPreference = gql`
  mutation createTeamPreference($teamPreferenceData: team_preferences_insert_input!) {
    createTeamPreference(object: $teamPreferenceData) {
      id
    }
  }
`;



export const DeleteTeamMembers = gql`
  mutation deleteTeamMembers($teamMembers: [team_members_bool_exp!]!) {
    updateTeamMembers(where: {_or: $teamMembers}, _set: {isDeleted: true}) {
      returning {
        id
      }
    }
  }
`;

export const CreateTeam = gql`
  mutation createTeam(
    $accountId: bigint!
    $accountUuid: uuid!
    $name: String!
  ) {
    createTeam(
      object: {accountId: $accountId, accountUuid: $accountUuid, name: $name}
    ) {
      uuid
      id
      name
    }
  }
`;

export const CreatePracticeTeam = gql`
  mutation createTeam(
    $accountId: bigint!
    $accountUuid: uuid!
    $name: String!
    $practiceLocationUuid: uuid!
  ) {
    createTeam(
      object: {accountId: $accountId, accountUuid: $accountUuid, name: $name, practiceLocationUuid: $practiceLocationUuid}
    ) {
      uuid
      id
      name
    }
  }
`;

export const GetTeams = gql`
  query getTeams {
    teams {
      name
      teamMembers {
        userUuid
      }
      teamPreferencesRel {
        id
        preferenceMlov {
          code
        }
      }
      updatedAt
      uuid
      id
    }
  }
`;



export const GetDefaultCommunicationGroupTeams = gql`
  query getTeams($name: String!) {
    teams(
      where: {
        _or: [
          {name: {_eq: $name}}
          {
            teamPreferencesRel: {
              preferenceMlov: {code: {_eq: "DEFAULT_GENERAL_GROUP"}}
            }
          }
        ]
      }
    ) {
      name
      teamMembers {
        id
        uuid
        userUuid
        userData: useruuid {
          id
          uuid
          name
          email
        }
      }
      teamPreferencesRel {
        id
        preferenceMlov {
          code
        }
      }
      updatedAt
      uuid
      id
    }
  }
`;

export const GetCommunicationGroupTeams = gql`
  query getTeams($name: String, $where: teams_bool_exp) {
    teams(where: $where) {
      name
      teamMembers {
        id
        uuid
        userUuid
        userData: useruuid {
          id
          uuid
          name
          email
        }
      }
      teamPreferencesRel {
        id
        preferenceMlov {
          code
        }
      }
      updatedAt
      uuid
      id
    }
  }
`;

export const GetLocations = gql`
  query getLocations {
    accountLocations(where: {practiceLocation: {isDeleted: {_eq: false}}}) {
      uuid
      googleMapLink
      id
      locationUuid
      practiceLocation {
        id
        name
        uuid
        addressLine1
        addressLine2
        zipcodeUuid
        timezoneUuid
        cityUuid
        stateUuid
        countryUuid
        practiceZipcode {
          id
          code
        }
        practiceCity {
          id
          name
          stateId
        }
        practiceState {
          id
          name
        }
        practiceTimezone {
          id
          timezone
          displayValue
        }
      }
    }
  }
`;

export const getLocationsBySearchString = gql`
query getLocations($searchString: String) {
  accountLocations(where: {practiceLocation: {isDeleted: {_eq: false}, name: {_ilike: $searchString}}, isDeleted: {_eq: false}}) {
    uuid
    practiceLocation {
      id
      name
      uuid
    }
  }
}
`;
export const getLocationByAccount = gql`
query getLocations($limit: Int!, $offset: Int!, $accountUuid: uuid!, $searchString: String!) {
  accountLocations(where: {accountUuid: {_eq: $accountUuid},practiceLocation: {
    isDeleted: {_eq: false},
    _or: [
      {name: {_ilike: $searchString}},
      {addressLine1: {_ilike: $searchString}},
      {addressLine2: {_ilike: $searchString}},
      {practiceZipcode: {
        code: {
          _ilike: $searchString
        }
      }}
    ]
    }
    }, offset: $offset, limit: $limit) {
    uuid
      googleMapLink
      id
      locationUuid
      practiceLocation {
        id
        name
        uuid
        addressLine1
        addressLine2
        zipcodeUuid
        timezoneUuid
        cityUuid
        stateUuid
        countryUuid
        practiceZipcode {
          id
          code
        }
        practiceCity {
          id
          name
          stateId
        }
        practiceState {
          id
          name
        }
        practiceTimezone {
          id
          timezone
          displayValue
        }
      }
  }
  accountLocationsAggregate(where: {
    accountUuid: {
      _eq: $accountUuid
      },
    practiceLocation: {
        isDeleted: {
          _eq: false
          },
          _or: [
      {name: {_ilike: $searchString}},
      {addressLine1: {_ilike: $searchString}},
      {addressLine2: {_ilike: $searchString}},
      {practiceZipcode: {
        code: {
          _ilike: $searchString
        }
      }}
    ]
        }
      }
    ) {
    aggregate {
      count
    }
  }
}
`
export const getLocationTotalCount = gql`
query getLocations($accountUuid: uuid!) {
  accountLocationsAggregate(where: {
    accountUuid: {
      _eq: $accountUuid
      },
    practiceLocation: {
        isDeleted: {
          _eq: false
          },
        }
      }
    ) {
    aggregate {
      count
    }
  }
}
`

export const DeleteTeamMembersWithActionType = gql`
  mutation deleteTeamMembersWithActionType(
    $teamMembers: [team_members_bool_exp!]!
    $removeActionType: String!
    $additionalAttributes: jsonb!
  ) {
    updateTeamMembers(
      where: {_or: $teamMembers}
      _set: {
        isDeleted: true
        removeActionType: $removeActionType
        additionalAttributes: $additionalAttributes
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CreateTeamMembersWithActionType = gql`
  mutation createTeamMembers($teamMembers: [team_members_insert_input!]!) {
    createTeamMembers(
      objects: $teamMembers
      on_conflict: {
        constraint: team_members_pkey
        update_columns: [isDeleted, addActionType, additionalAttributes]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetTeamMemberDataByUserId = gql`
  query getTeamMemberDataByUserId($teamUserUuid: [uuid!]!, $teamUuid: uuid!) {
    teamMembers(
      where: {userUuid: {_in: $teamUserUuid}, teamUuid: {_eq: $teamUuid}}
    ) {
      teamUuid
      additionalAttributes
    }
  }
`;

export default {
  GetTeamsForFilter,
  CreateTeam,
  CreateTeamMembers,
  GetTeams,
  CreatePracticeTeam,
  GetLocations,
  DeleteTeamMembers,
  GetDefaultCommunicationGroupTeams,
  GetCommunicationGroupTeams,
  CreateTeamPreference,
  getLocationsBySearchString,
  getLocationByAccount,
  getLocationTotalCount,
  DeleteTeamMembersWithActionType,
  CreateTeamMembersWithActionType,
  GetTeamMemberDataByUserId
};
