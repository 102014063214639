import { Divider, HStack, Pressable, Skeleton, View, VStack, Text, Icon } from "native-base";
import { useContext, useEffect, useState } from "react";
import AntDesign from "react-native-vector-icons/AntDesign";
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useNavigate } from "react-router-dom";
import { DATE_FORMATS, ENTITY_EVENTS_TYPE, GROUP_MEMBER_TYPE, MLOV_CATEGORY } from "../../../constants";
import { TASK_PRIORITY_CODES } from "../../../constants/MlovConst";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { getPatientDetails } from "../../../services/CommonService/AidBoxService";
import { getAccountUUID } from "../../../utils/commonUtils";
import { getDateStrFromFormat, getMomentObjFromFormat, isCurrentDateInFutureComparedToOther } from "../../../utils/DateUtils";
import { getMlovListFromCategory } from "../../../utils/mlovUtils";
import { getAgeFromDate } from "../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils";
import { getWorkFlowListByEventEntity } from "../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";
import { descriptionDisplayModes, getDescription } from "../../TaskCard/TaskCardHelper";
import { DisplayCardAvatar } from "../DisplayCard/DisplayCardAvatar";
import { IAddOrUpdateTaskState, IAddTaskState, ISubTasksRes } from "./interfaces";
import WorkflowPreview from "./WorkflowPreview";
import { getVitalListFromCapability } from "../../../utils/capabilityUtils";
import { DisplayText } from "../DisplayText/DisplayText";
import AddSubTasks from "./AddEditTaskView/AddSubTasks";
import { ITask } from "../CareDashboard/CareDashboardInterfaces";
import { getCompletedTaskStatusId } from "../CareDashboard/CareDashboardUtils/CareDashboardUtils";
import { TaskQueries } from "../../../services";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import AddOrUpdateCommentForTask from "./AddEditComment/AddOrUpdateCommentForTask";
import ArrowDownSvg from "../Svg/ArrowDownSvg";
import ArrowRightSvg from "../Svg/ArrowRightSvg";
import { Colors } from "../../../styles/Colors";
import AttachmentsItemList from "./AddEditTaskView/AttachmentsItemList";
import { manageAttachmentsListData } from "./AddTaskUtils";
import { withMiniContactViewHOC } from "../../MiniContactViewHOC";
import { TaskViewTabsCode, TASK_VIEW_TABS } from "./AddEditComment/TaskCommentsHelper";
import { testID, TestIdentifiers } from "../../../testUtils";


const TaskDetailsPreview = (props: any) => {
    const navigate = useNavigate();
    const tenantId = getAccountUUID();
    const [taskDetailState, setTaskDetailState] = useState<IAddTaskState>({} as any);
    const [expandSubtask, setExpandSubtask] = useState(true);
    const [workflowEventEntityAllList, setWorkFlowEventEntityAllList] = useState([] as any);
    const [selectedTab, setSelectedTab] = useState(TASK_VIEW_TABS?.find((tab) => tab?.code === TaskViewTabsCode?.ACTIVITY))
    const mlovData = useContext(CommonDataContext);
    const taskPriorityMlov = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.TASK_PRIORITY,
        false
    );
    const defaultTaskPriority = taskPriorityMlov?.find((mlov) => {
        if (mlov.code === TASK_PRIORITY_CODES.LOW) return true;
    });
    const completedStatusId = getCompletedTaskStatusId(taskPriorityMlov);
    const vitals = getVitalListFromCapability();

    const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        fetchPolicy: 'no-cache',
      });

    useEffect(() => {
        updateTaskData();
        getAllEntityEventsList();
    }, [props.task]);

    useEffect(() => {
        if (props.task && props.task?.patientId) {
            setTaskDetailState((prev) => ({ ...prev, loading: true }));
            getPatientDetails(
                props.task?.patientId,
                (response: any) => {
                    if (response && response.data) {
                        setTaskDetailState((prev) => {
                            return {
                                ...prev,
                                loading: false,
                                assignee: props?.assignee,
                                patient: {
                                    contactId:
                                        props.task?.patientContactId || props.task?.patient?.id,
                                    name:
                                        props.task?.patientName || props.task?.patient?.name || '',
                                    birthDate: response.data.birthDate || '',
                                    email: props.task?.patientEmail || props.task?.patient?.email,
                                    phoneNumber:
                                        props.task?.patientPhoneNumber ||
                                        props.task?.patient?.phoneNumber,
                                    gender: props.task?.patient?.gender || response.data.gender,
                                },
                            };
                        });
                    } else {
                        setDefaultState();
                    }
                },
                (error: any) => {

                    setDefaultState();
                }
            );
        }
    }, [props.task]);

    useEffect(()=> {
        if (props.task?.id && props.task?.subTasks?.length) {
          getSubTask(props.task);
        }
      }, [props?.task?.id])

      const getSubTask = async (task: ITask) => {
        const subTasksId = task.subTasks?.map((item) => item.id.toString());
        const response = await getSubTaskByIds({
          variables: {
            ids: subTasksId,
          },
        });
        if (response?.data?.getTasks?.tasks?.length) {
          const subTaskRes = response.data.getTasks.tasks;
          const updatedItems = subTaskRes.map((item: ISubTasksRes) => ({
            ...item,
            isChecked: item.statusId === completedStatusId ? true : false,
            assignee: {
             value: item?.assigneeUser?.uuid,
             label: item?.assigneeUser?.name,
             key: item?.assigneeUser?.uuid,
             details: item?.assigneeUser,
            },
          }));
          setTaskDetailState((prev) => ({ ...prev, subTasks: updatedItems }));
        }
        setTaskDetailState((prev) => ({...prev, loading: false}));
      };

    const getAllEntityEventsList = () => {
        getWorkFlowListByEventEntity(
            ENTITY_EVENTS_TYPE.ADD_TASK,
            tenantId,
            async (dataList: any) => {
                dataList = dataList || [];
                const finalList = dataList.map((data: any) => {
                    return {
                        name: data.name,
                        //description: data.description,
                        //entity: data.entity,
                        //eventType: data.eventType,
                        isEnabled: data.isEnabled,
                        workflowMasterId: data.workflowMasterId,
                    };
                });

                if (
                    props.task && props.task?.referenceData &&
                    props.task?.referenceData?.workflowList &&
                    props.task?.referenceData.workflowList.length > 0
                ) {
                    setWorkFlowEventEntityAllList(updateWorkFlowList(finalList))
                }
            },
            (error: any) => {

                setWorkFlowEventEntityAllList([]);
            }
        );
    };

    const updateWorkFlowList = (data: any) => {
        const newList: any = [];
        props.task?.referenceData?.workflowList &&
        props.task?.referenceData?.workflowList.map((propsList: any) => {
                data && data.map((workflowList: any) => {
                    if (workflowList.workflowMasterId === propsList.workflowMasterId) {
                        const replaceItem = {
                            name: workflowList.name,
                            //description: workflowList.description,
                            // entity: workflowList.entity,
                            //eventType: workflowList.eventType,
                            workflowMasterId: workflowList.workflowMasterId,
                            isEnabled: propsList.isEnabled,
                            formNodeList: propsList.formNodeList,
                        };
                        newList.push(replaceItem)
                    }
                });
            });
        return newList;
    };

    const updateTaskData = async () => {
        setTaskDetailState((prev) => {
            return {
                ...prev,
                title: props.task?.title || '',
                description: props.task?.description || '',
                assignee: props?.assignee,
                assignedBy:
                    props?.task?.assignedBy && props?.task?.assignedBy?.uuid
                        ? {
                            value: props?.task?.assignedBy?.uuid,
                            label: props?.task?.assignedBy?.name || '',
                            key: props?.task?.assignedBy?.uuid,
                        }
                        : undefined,
                isImportant: false,
                showErrors: false,
                priority: props.task?.priority
                    ? {
                        key: props.task?.priority?.id || '',
                        value: props.task?.priority?.code || '',
                        label: props.task?.priority?.value || '',
                    }
                    : {
                        key: defaultTaskPriority?.id || '',
                        value: defaultTaskPriority?.code || '',
                        label: defaultTaskPriority?.value || '',
                    },
                startDate: getDateStrFromFormat(
                    props?.task?.startDateTime || new Date(),
                    DATE_FORMATS.DISPLAY_DATE_FORMAT
                ),
                workflowList: [],
                taskPool: {
                    key: '',
                    value: props?.task?.userPoolId || '',
                    label: '',
                },
                customer:
                    props?.task?.patient && props?.task?.patient?.patient?.patientUuid
                        ? {
                            key: props?.task?.patient?.patient?.patientUuid,
                            value: props?.task?.patient?.patient?.patientUuid,
                            label: props?.task?.patient?.name,
                        }
                        : {
                            key: '',
                            value: undefined,
                            label: '',
                        },
            }
        });
    };

    const setDefaultState = () => {
        setTaskDetailState((prev) => {
            return {
                ...prev,
                loading: false,
                assignee: props?.assignee,
                patient: {
                    contactId: props.task?.patientContactId || props.task?.patient?.id,
                    name: props.task?.patientName || props.task?.patient?.name || '',
                    birthDate: '',
                    email: props.task?.patientEmail || props.task?.patient?.email,
                    phoneNumber:
                        props.task?.patientPhoneNumber || props.task?.patient?.phoneNumber,
                },
            };
        });
    };

    const getJourneyDates = (careJourney: {
        startDateTime: string;
        endDateTime: string;
    }) => {
        let date = '';
        if (careJourney.startDateTime && careJourney.endDateTime) {
            date = getDateStrFromFormat(
                careJourney.startDateTime,
                DATE_FORMATS.DISPLAY_DATE_FORMAT
            );

            if (
                isCurrentDateInFutureComparedToOther(
                    new Date(),
                    careJourney.endDateTime
                )
            ) {
                date +=
                    ' - ' +
                    getDateStrFromFormat(
                        careJourney.endDateTime,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                    );
            } else {
                date += ' - Present';
            }
        }
        return date;
    };

    const onContactClicked = (contactId: any, contactType?: string) => {
        if (contactType == 'CUSTOMER') {
          props?.navigateOrOpenContactIdDrawer?.(contactId)
        } else if (contactType == 'LEAD') {
            navigate(`/members/prospect/${contactId}`);
        }
      };

    return (
      <View>
        {taskDetailState.loading && (
          <VStack space={4}>
            <Skeleton />
            <Skeleton.Text />
            <Divider />
            <Skeleton />
            <Skeleton.Text />
          </VStack>
        )}

        {!taskDetailState.loading && props.task && (
          <VStack mx={0} space={4}>
            {taskDetailState.contact?.name && (
              <VStack space={1} flex={1}>
                <Pressable
                  onPress={() => {
                    onContactClicked(
                      taskDetailState.contact?.contactId,
                      'CUSTOMER'
                    );
                  }}
                >
                  <HStack space={2} alignItems="center" flex={1}>
                    <VStack>
                      <DisplayCardAvatar
                        isLetterAvatarShow
                        avatarStyle={{
                          avatarSize: '12',
                        }}
                        userData={{
                          userId: taskDetailState.contact?.contactId || '',
                          userType: GROUP_MEMBER_TYPE.CONTACT,
                          userName: taskDetailState?.contact?.name,
                        }}
                      />
                    </VStack>
                    <VStack>
                      <HStack space={2}>
                        <Text fontWeight="medium" color="secondary.600">
                          {taskDetailState.contact?.name}
                        </Text>
                        <View marginTop={1} flex={1}>
                          <Icon
                            as={AntIcon}
                            name="right"
                            size="3"
                            alignSelf={'flex-end'}
                          />
                        </View>
                      </HStack>
                      <HStack space={2}>
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          color="gray.500"
                        >{`Patient`}</Text>
                        {taskDetailState.contact?.birthDate &&
                        getAgeFromDate(
                          getMomentObjFromFormat(
                            taskDetailState.contact?.birthDate,
                            DATE_FORMATS.DISPLAY_DATE_FORMAT
                          ).toDate()
                        ) > 0 ? (
                          <Text fontSize={12} fontWeight={400} color="gray.500">
                            {`|  `}
                            {getAgeFromDate(
                              getMomentObjFromFormat(
                                taskDetailState.contact?.birthDate,
                                DATE_FORMATS.DISPLAY_DATE_FORMAT
                              ).toDate()
                            )}
                            {'  yrs'}
                          </Text>
                        ) : null}
                        {taskDetailState.contact?.gender && (
                          <Text fontSize={12} fontWeight={400} color="gray.500">
                            {`|   `}
                            {taskDetailState.contact?.gender}
                          </Text>
                        )}
                      </HStack>
                    </VStack>
                  </HStack>
                </Pressable>
                <Divider />
              </VStack>
            )}

            {props.task.patientCareJourney &&
              props.task.patientCareJourney.careJourney &&
              (props.task?.patientCareJourney?.title || props.task?.patientCareJourney?.careJourney?.title) && (
                <VStack>
                  <Text fontWeight="bold">Journey Details</Text>
                  <Text>{props.task?.patientCareJourney?.title || props.task?.patientCareJourney?.careJourney?.title}</Text>
                  <Text>{getJourneyDates(props.task.patientCareJourney)}</Text>
                </VStack>
              )}

            <View>
              <Text fontSize="lg">{props.task.title}</Text>
              {getDescription(
                props.task,
                taskDetailState?.contact?.name,
                descriptionDisplayModes.VERBOSE,
                vitals
              )}
            </View>

            {taskDetailState.assignee && (
              <Text>
                Assigned to{' '}
                <Text fontWeight="bold">
                  {' '}
                  {taskDetailState.assignee.label || '-'}{' '}
                </Text>
              </Text>
            )}

            {(taskDetailState?.subTasks || [])?.length > 0 && (
              <VStack>
                  <HStack alignItems={'center'} ml={1}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                      textLocalId={'subTasks'}
                    />
                    <Pressable
                      ml={2}
                      onPress={() => {
                        setExpandSubtask(!expandSubtask);
                      }}
                    >
                      <View size={18}>
                        {expandSubtask ? (
                          <ArrowDownSvg customStrokeColor="black"></ArrowDownSvg>
                        ) : (
                          <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                        )}
                      </View>
                    </Pressable>
                    <Text
                      fontWeight={600}
                      fontSize={14}
                      color={Colors.Custom.Gray500}
                      ml={5}
                    >
                      {`${
                        taskDetailState?.subTasks?.filter(
                          (item) => item.isChecked
                        )?.length
                      }/${taskDetailState?.subTasks?.length}`}
                    </Text>
                  </HStack>
                  {expandSubtask && (
                    <>
                      <VStack>
                        {taskDetailState.subTasks?.map((data, index) => {
                          return (
                            <>
                              <AddSubTasks subTaskItem={data} isDisable />
                            </>
                          );
                        })}
                      </VStack>
                    </>
                  )}
              </VStack>
            )}
             {(props?.task?.attachments?.length || 0) > 0 && (
              <VStack>
                <DisplayText
                  extraStyles={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                  textLocalId={'attachments'}
                />
                <AttachmentsItemList
                  value={{} as IAddOrUpdateTaskState}
                  fileList={manageAttachmentsListData(props?.task?.attachments)}
                  isViewOnly
                />
              </VStack>
            )}
            <HStack space={1}>
              {TASK_VIEW_TABS?.map((tab) => {
                return (
                  <Pressable
                    key={tab?.key}
                    style={{ marginHorizontal: 1 }}
                    onPress={() => {
                      setSelectedTab(tab);
                    }}
                    {...testID(TestIdentifiers?.[tab?.code])}
                  >
                    <View
                      borderBottomColor={selectedTab?.code === tab?.code ? Colors?.Custom?.mainPrimaryPurple : Colors?.Custom?.BackgroundColor}
                      borderBottomWidth={4}
                      padding={2}>
                      <DisplayText
                        extraStyles={{
                          color: selectedTab?.code === tab?.code ? Colors.Custom.mainPrimaryPurple : Colors?.Custom?.Gray400,
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                        textLocalId={tab?.title}
                      />
                    </View>
                  </Pressable>
                )
              })}
            </HStack>
            {props.task?.id?.length && selectedTab?.code === TaskViewTabsCode?.ACTIVITY && (
              <AddOrUpdateCommentForTask task={props.task} />
            )}
            {workflowEventEntityAllList &&
              workflowEventEntityAllList.length > 0 && selectedTab?.code === TaskViewTabsCode?.AUTOMATION && (
                <WorkflowPreview
                  workFlowData={workflowEventEntityAllList}
                ></WorkflowPreview>
              )}
          </VStack>
        )}
      </View>
    );
}
export default withMiniContactViewHOC(TaskDetailsPreview);
