import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  emailRecipientContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
    justifyContent: 'space-between',
  },
  emailRecipientTitleText: {
    color: '#A7AFBE',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
  },
  recipientRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 10,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 6,
    borderRadius: 6,
    backgroundColor: '#F6F7F8',
    justifyContent: 'space-evenly',
    margin: 4,
  },
  emailText: {
    color: '#6F7A90',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    width: 200,
  },
  divider: {
    width: '100%',
    backgroundColor: '#A7AFBE',
    height: 0.5,
    marginVertical: 8,
  },
  label: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
    color: Colors.FoldPixel.GRAY200,
  },
  formControl: {
    marginTop: 16,
  },
});
