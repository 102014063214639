import { View } from 'react-native'
import React from 'react'
import { IStack } from './interfaces'
import { StackStyles } from './styles';

const Stack = (props: IStack) => {
  const {direction, space, children, style} = props;
  const arrayChildren = React.Children.toArray(children).filter((child)=> child);
  const stackStyle = direction === 'row' ? StackStyles.row : StackStyles.column;
  const spacerStyle = direction === 'row' ? { width: space } : { height: space };

  return (
    <View style={[stackStyle, style]}>
      {React.Children.map(arrayChildren, (child, index) => (
        <React.Fragment>
          {child}
          {space && arrayChildren[index + 1] && <View style={spacerStyle} />}
        </React.Fragment>
      ))}
    </View>
  )
}

export default Stack
