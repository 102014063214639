import { HStack, Text, VStack } from 'native-base';
import {isEmptyObject, isHTMLString} from '../../../utils/commonUtils';
import { MESSAGE_CUSTOM_CARD_TYPE } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {NotificationResponse, SingleNotification, Notification} from './interfaces';

export const NOTIFICATION_TYPES = {
  NEW_APPOINTMENT: 'appointment.created',
  NEW_TASK: 'task.created',
  CONVERSATION_UPDATED:'conversation.updated',
  IMPORT_COMPLETED:'import.completed',
  PRESCRIBE_DOSE_SPOT_MEDICATION_COUNT_UPDATE : 'dose-spot-prescribe-count.updated',
  APPOINTMENT_RSVP: 'appointment.rsvp',
  FORM_SUBMIT: 'form.submit',
  POPULATION_GROUP_ACTIVITY: 'populationGroup.formActivity',
  TASK_MENTIONED: "task.taskCommentMentionedUser",
  RECURRING_APPOINTMENT: 'appointment.recurring_appointment',
  TASK_CREATED: "task.created",
};

export const getDisplayStringByNotificationType = (type: string) => {
  let displayString = '';
  switch (type) {
    case NOTIFICATION_TYPES.NEW_APPOINTMENT:
      displayString = 'New Appointment';
      break;
    case NOTIFICATION_TYPES.NEW_TASK:
      displayString = 'New Task';
      break;
    case NOTIFICATION_TYPES.IMPORT_COMPLETED:
      displayString = 'Import Completed';
      break;
  }
  return displayString;
};

export const getUSLocaleDateString = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
  });

  return date === 'invalid date' ? dateString : date;
};

export const getFormattedNotificationList = (
  notificationResponse: NotificationResponse
): SingleNotification[] => {
  const formattedData: SingleNotification[] = [];
  const notifications = notificationResponse.notifications.sort((item1:any, item2:any)=>{
    return new Date(item1.createdOn) > new Date(item2.createdOn) ? -1 : 1;
  })
  notifications.forEach((item) => {
    const body: string = getBody(item?.metadata, item.description) || '';
    formattedData.push({
      id: item.id,
      createdOn: item.createdOn,
      description: body,
      title: item.title,
      type: item.type,
      typeId: item.typeId,
      typeDisplayString: getDisplayStringByNotificationType(item.type),
      metaData: item?.metadata,
      notificationDetailElement: getCustomNotificationElement(item),
    });
  });

  return formattedData;
};

const getBody = (info: any, description: string) => {
  let body = '';
  if(info?.messageDetails?.content && description){
    if(info?.messageDetails?.contentType === MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD){
      body = description.split(':')[0] + ': ' + 'Custom card';
    }else{
      body = description;
    }
  }else {
    body = description;
  }
   return body;
};

export const getAppointmentDateTimeString = (
  singleNotification: SingleNotification
) => {
  if (singleNotification?.type === NOTIFICATION_TYPES.APPOINTMENT_RSVP) {
    return '';
  }
  const date = singleNotification?.metaData?.appointment?.startDateTime || ""
  const dateTimeString = new Date(date).toLocaleTimeString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
  return dateTimeString == 'Invalid Date' ? "" : dateTimeString;
};

export const getCustomNotificationElement = (notification: any): JSX.Element => {
  switch(notification?.type) {
    case NOTIFICATION_TYPES.PRESCRIBE_DOSE_SPOT_MEDICATION_COUNT_UPDATE:
      return getDoseSpotPendingMedication(notification);
    case NOTIFICATION_TYPES.RECURRING_APPOINTMENT:
      return getRecurringAppointment(notification);  
  }
  return (<></>);
};

const getRecurringAppointment = (notification: any): JSX.Element => {
  const metaData: {
    actionCode: string;
    userData: any;
    statusWiseTransactionCount?: {
      Processed?: {
        transactionId: number;
      };
      Failed?: {
        transactionId: number;
      };
    };
  } = notification?.metadata;

  const successCount = metaData?.statusWiseTransactionCount?.Processed?.transactionId || 0;
  const failedCount = metaData?.statusWiseTransactionCount?.Failed?.transactionId || 0;

  return (
    <Text fontSize={14} fontWeight={'500'} mt={-2}>
      {successCount > 0 ? `Successfull: ${successCount}` : ''}
      {successCount > 0 && failedCount > 0 ? ' • ' : ''}
      {failedCount > 0 ? `Failed: ${failedCount}` : ''}
    </Text>
  );
}

const getDoseSpotPendingMedication = (notification: any): JSX.Element => {
  const metaData = notification?.metadata?.Data || notification?.metaData?.Data;
  if (metaData && metaData?.Total) {
    return (
      <VStack>
        {metaData?.Total?.ChangeRequestCount > 0 && (
          <Text fontSize={14} fontWeight={'500'}> {`Change Request Count: ${metaData?.Total?.ChangeRequestCount}`}</Text>
        )}
        {metaData?.Total?.PendingPrescriptionCount > 0 && (
          <Text fontSize={14} fontWeight={'500'}> {`Pending Prescription Count: ${metaData?.Total?.PendingPrescriptionCount}`}</Text>
        )}
        {metaData?.Total?.RefillRequestCount > 0 && (
          <Text fontSize={14} fontWeight={'500'}> {`Refill Request Count: ${metaData?.Total?.RefillRequestCount}`}</Text>
        )}
        {metaData?.Total?.TransmissionErrorCount > 0 && (
          <Text fontSize={14} fontWeight={'500'}> {`Transmission Request Count: ${metaData?.Total?.TransmissionErrorCount}`}</Text>
        )}
      </VStack>
    )
  }
  return (<></>);
}

export const getNotificationDataForEmailNotification = (
  data: SingleNotification
) => {
  const {description, title} = data;
  const isHtmlDescription = isHTMLString(description);
  if (isHtmlDescription) {
    const newTitle = (title || '')
      .replace('New message from ', 'New email from ')
      .trim();
    data.title = newTitle;
    data.description = 'Click here to open';
  }
  return data;
};

export const getFormatedNotification = (data: any, notificationArray: any) => {
  const formatedNotification: any = [];
  notificationArray.forEach((item: any) => {
    const newData = data.filter(
      (content: any) => content.resourceCode === item.code
    );
    if (newData?.length > 0) {
      const newFormated = {
        label: item.label,
        code: item.code,
        notificationObject: newData,
      };
      formatedNotification.push(newFormated);
    }
  });
  return formatedNotification;
};

export const getConversationIdsFromNotificationList = (
  notificationList: SingleNotification[]
) => {
  return notificationList
    .filter(isConversationNotification)
    .map((item) => Number(item.typeId))
    .filter(Boolean);
};

export const isConversationNotification = (
  singleNotification: SingleNotification
) => {
  return singleNotification.type === NOTIFICATION_TYPES.CONVERSATION_UPDATED;
};

export const getTitleAndDescriptionForEmailNotification = (data: {
  singleEmailConversation: Record<string, any>;
  notificationData: SingleNotification;
}) => {
  const {singleEmailConversation, notificationData} = data;
  const subject =
    singleEmailConversation?.additionalAttributes?.mail_subject || '';
  const description =
    !notificationData?.metaData?.messageData?.private &&
    subject &&
    subject.length > 0
      ? `Subject: ${subject}`
      : notificationData.description;
  const from =
    notificationData?.metaData?.messageData?.content_attributes?.from
      ?.emailAddress?.name ||
    notificationData?.metaData?.messageData?.content_attributes?.from;

  const title = from ? `New email from ${from}` : notificationData.title;
  return {title, description};
};
