import React from "react";
import { Divider, Text, VStack } from "native-base";
import { DocStatus } from "../../interfaces";
import { IFormState, IGroupField, ISingleField } from "../DocumentationView";

export const renderFeilds = (
    fields: ISingleField,
    index: number,
    extraIndent?: boolean
  ) => {
    return (
      <VStack
        key={fields.name + '_' + index}
        mx={extraIndent ? 2 : 0}
        my={1}
      >
        <Text fontWeight={600}>
          {fields?.name}
        </Text>
        <Divider my={2} backgroundColor={'black'} />
        <VStack ml={2}>
          {fields?.fields?.map((field: string, index: number) => {
            return (
              <Text key={field + '_' + index}>
                {field}
              </Text>
            );
          })}
        </VStack>
      </VStack>
    );
  };

 export const renderContent = (formState: IFormState): JSX.Element => {
    const group = formState?.group;
    const isGroup = formState?.isGroup;
    
    return (
      <>
        {isGroup
          ? group?.map((item: IGroupField, index) => (
              <>
                <VStack key={item.groupName + '_' + index} mb={2}>
                  <Text
                    style={{
                      fontWeight: '800',
                      color: '#000',
                      fontSize: 18,
                    }}
                  >
                    {item?.groupName}
                  </Text>
                  <Divider my={2} backgroundColor={'black'} />
                  {item?.data?.map(
                    (
                      field: {
                        name: string;
                        fields: string[];
                      },
                      index: number
                    ) => renderFeilds(field, index, false)
                  )}
                </VStack>
              </>
            ))
          : group?.map((field: ISingleField, index) =>
              renderFeilds(field, index, false)
            )}
      </>
    );
  };

export const getTitle = (
    isSignedNote: boolean,
    formName?: string,
    docStatus?: string
  ): JSX.Element => {
    if (isSignedNote) {
      return (
        <Text
          style={{
            fontSize: 20,
            fontWeight: '700',
            color: '#101828',
            marginLeft: 10,
          }}
        >
          Signed Note: {formName}
        </Text>
      );
    } else if (docStatus === DocStatus.PRELIMINARY) {
      return (
        <Text
          style={{
            fontSize: 20,
            fontWeight: '700',
            color: '#101828',
            marginLeft: 10,
          }}
        >
          In Progress Note: {formName}
        </Text>
      );
    }
    return (
      <Text
        style={{
          fontSize: 20,
          fontWeight: '700',
          color: '#101828',
          marginLeft: 10,
        }}
      >
        New Note: {formName}
      </Text>
    );
  };
