import {Drawer} from 'antd';
import {Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import AddOrUpdateForm from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';

const CreateForm = (props: {
  showModal: boolean;
  noteCategoryId: string;
  onCancel: () => void;
  handleNewlyCreatedNote: (noteId: string, templateName?: string) => void;
  title?:string;
  isFromEmpDashboard?:boolean;
  quickNoteCreation?:boolean;
  style?:any
  categoryCode?:string

}) => {
  const {showModal, noteCategoryId, onCancel, handleNewlyCreatedNote,title,isFromEmpDashboard,quickNoteCreation ,style,categoryCode} = props;
  const intl = useIntl();
  return (
    <Drawer
      open={showModal}
      onClose={onCancel}
      width={1200}
      title={
        <>
          <ModalActionTitle title={title ? title : 'newNoteTemplate'} />
          {isFromEmpDashboard ? (
            <Text style={{  position: 'absolute',
            width: '100%',
            top: 16,
            left: 0,
            backgroundColor: '#fff',
            paddingLeft: 24,
            zIndex: 1,
            color: Colors.Custom.Gray400,
            fontSize: 14
            }}>
              Please make sure the selected form should have 'Score' set for all
              the fields. As the customer satisfaction calculations are done
              based on the form each field defined scores.
            </Text>
          ) : (
            <></>
          )}
        </>
      }
    >
      {showModal && (
        <View style={[style ? style : {}]}>
          <AddOrUpdateForm
            categoryCode={categoryCode}
            handleNewlyCreatedNote={handleNewlyCreatedNote}
            onClose={onCancel}
            quickNoteCreation={!quickNoteCreation ? quickNoteCreation : true}
            noteCategoryId={noteCategoryId}
            isFromEmpDashboard={isFromEmpDashboard}
            heightOffset={16}
          />
        </View>
      )}
    </Drawer>
  );
};

export default CreateForm;
