import React from 'react';
import Svg, {Path} from 'react-native-svg';

function OpenedCollapseSvg() {
  return (
    <Svg width="6" height="6" fill="none" viewBox="0 0 6 6">
      <Path
        fill="#6F7A90"
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4l2-2H1l2 2z"
      ></Path>
    </Svg>
  );
}

export default OpenedCollapseSvg;
