import React from 'react';
import {Text, VStack} from 'native-base';
import {Colors} from '../../../../../../styles';
import {DocStatus} from '../../interfaces';
import { clipText } from './helper';

export const getTitle = (
  isSignedNote: boolean,
  authorName: string,
  signedByUserName?: string,
  formName?: string,
  docStatus?: string,
  signedByUserId?: string,
  authorUUID?: string,

): JSX.Element => {
  const name = formName ? clipText(formName, 32) : '';
  if (isSignedNote) {
    return (
      <VStack>
        <Text
          style={{
            fontSize: 20,
            fontWeight: '700',
            color: '#101828',
            marginLeft: 10,
          }}
        >
          Signed Note: {name}
        </Text>
        {authorUUID === signedByUserId ? (
          <Text
            style={{marginLeft: 10, color: Colors.Custom.Gray500, fontSize: 14}}
          >
            {`Created and signed by ${signedByUserName}`}
          </Text>
        ) : (
          <>
            {authorUUID && authorName && (
              <Text
                style={{
                  marginLeft: 10,
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                }}
              >
                {`Created by ${authorName}`}
              </Text>
            )}
            {signedByUserName && (
              <Text
                style={{
                  marginLeft: 10,
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                }}
              >
                {`Signed by ${signedByUserName || '- -'}`}
              </Text>
            )}
          </>
        )}
      </VStack>
    );
  } else if (docStatus === DocStatus.PRELIMINARY) {
    return (
      <VStack>
        <Text
          style={{
            fontSize: 20,
            fontWeight: '700',
            color: '#101828',
            marginLeft: 10,
          }}
        >
         {name}
        </Text>
        <Text
          fontSize="md"
          style={{marginLeft: 10, color: Colors.Custom.Gray500, fontSize: 14}}
        >
          In Progress Note
        </Text>
      </VStack>
    );
  }
  return (
    <Text
      style={{
        fontSize: 20,
        fontWeight: '700',
        color: '#101828',
        marginLeft: 10,
      }}
    >
      New Note
    </Text>
  );
};
