import {
  canClearConditionalValue,
  canShowConditionalDropdownOptions,
  canShowFieldHasEnteredValue,
  canShowDisplayRulesForSOAPHealthComponents,
  getContextComponents,
  getContextOptions,
} from '../Utils';

export default [
  {
    type: 'panel',
    title: 'Display rules',
    key: 'simple-conditional',
    theme: 'default',
    customConditional: function customConditional(context: any) {
      const hasComponents = context.instance?.options?.editForm?.components?.length > 1 ||
      context.instance?.options?.editForm?.components?.[0]?.components?.length > 0 ||
      context.instance?.options?.editForm?.components?.[0]?.columns?.length > 0;
      return (
        hasComponents &&
        !canShowDisplayRulesForSOAPHealthComponents(context)
      );
    },
    components: [
      {
        type: 'select',
        input: true,
        label: 'This field should be:',
        key: 'conditional.show',
        dataSrc: 'values',
        defaultValue: 'true',
        data: {
          values: [
            {label: 'Shown', value: 'true'},
            {label: 'Hidden', value: 'false'},
          ],
        },
      },
      {
        type: 'select',
        input: true,
        label: 'When the field:',
        key: 'conditional.when',
        dataSrc: 'custom',
        valueProperty: 'value',
        data: {
          custom(context: any) {
            return getContextComponents(context);
          },
        },
      },
      {
        type: 'select',
        input: true,
        label: 'Has value entered:',
        key: 'conditional.exist',
        dataSrc: 'values',
        defaultValue: '',
        data: {
          values: [
            {label: 'Yes', value: 'true'},
            {label: 'No', value: 'false'},
          ],
        },
        customConditional: function customConditional(context: any) {
          return canShowFieldHasEnteredValue(context);
        },
      },
      {
        type: 'oldtextfield',
        input: true,
        label: 'Has the value:',
        key: 'conditional.eq',
        customConditional: function customConditional(context: any) {
          return !canShowConditionalDropdownOptions(context) && (canShowFieldHasEnteredValue(context) ? context.row.conditional.exist : true);
        },
      },
      {
        type: 'select',
        input: true,
        label: 'Has the value:',
        key: 'conditional.eq',
        dataSrc: 'custom',
        valueProperty: 'value',
        clearOnHide: true,
        data: {
          custom(context: any) {
            return getContextOptions(context);
          },
        },
        calculateValue: function calculateValue(context: any) {
          if (canClearConditionalValue(context)) {
            return '';
          }
          return context.row.conditional.eq;
        },
        customConditional: function customConditional(context: any) {
          return canShowConditionalDropdownOptions(context) && (canShowFieldHasEnteredValue(context) ? context.row.conditional.exist : true)
        },
      },
    ],
  },
];
