import { Drawer, Select } from 'antd';
import {
  Checkbox,
  FormControl,
  HStack,
  Input,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useEffect, useState } from 'react';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../constants';
import { Colors } from '../../../../../styles';
import {
  getIsExportSupported,
  getUserUUID
} from '../../../../../utils/commonUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  exportWorkflowData,
  getAllTenants,
  getWorkflowByMasterId, getWorkflowCountByNameAndFlowType, saveIvrWorkflow, saveWorkflow
} from '../AddOrUpdateWorkflow/WorkflowApi';
import { workflowTriggerStyle as styles } from './../Styles';

import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import {
  handleSetupPhoneTreeAction
} from '../../../../common/UsageLevel/UsageLevelHandlers';
import { changeWorkflowIdForImport } from '../../workflowUtils';
import { IWorkflow, IWorkflowDetail } from './interface';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import { FlowType } from '../../../../../context/WorkflowContext';

const CloneWorkflow = (props: any) => {
  const { isOpen, onCompleteAction, workflowData } = props;
  const isExportSupported = getIsExportSupported();
  const [isTitleValid, setIsTitleValid] = useState<boolean>(true);
  const [componentState, setComponentState] = useState<any>({
    name: '',
    isLoading: false,
    description: '',
    environments: [],
    tenants: []
  });
  const intl = useIntl();
  const toast = useToast();

  const titleErrorMessage = workflowData?.flowType===FlowType.ivr
  ? `Phone tree with the name you provided is already in use. Please choose a different Phone tree name to save.`
  : `Automation with the name you provided is already in use. Please choose a different Automation name to save.`

  const userUuid = getUserUUID();

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '38vw';

  const handleOnClose = () => {
    onCompleteAction(COMMON_ACTION_CODES.CLOSE_MODAL);
  };

  const handleSubmit = async () => {
    setComponentState({ ...componentState, isLoading: true });
    const isValid = await validateWorkflowTitle();
    if (isValid) {
      setIsTitleValid(true);
      getWorkflowByMasterId(
        workflowData.workflowMasterId || '',
        workflowData.tenantId,
        getWorkflowSuccessHandler(),
        (error: any) => {

          setComponentState({...componentState, isLoading: false});
        }
      );
    } else {
      setComponentState({ ...componentState, isLoading: false });
      setIsTitleValid(false);
    }
  };

  const buildWorkflowInputPayload = (workflowData: IWorkflow) => {
    const triggerInfo = workflowData.triggerInfo;
    delete triggerInfo.id
    const graph = workflowData.graph;
    delete graph.id
    const workflowInputPayload = {
      flowType: workflowData.workflow.flowType,
      description: componentState.description,
      workflowName: componentState.name,
      triggerInfo: triggerInfo,
      graph: graph,
    }
    return workflowInputPayload;
  }

  const [allTenants, setAllTenants] = useState<any>([]);
  const [tenants, setTenants] = useState<any>([]);

  const clonedSuccessfullyToast = ()=> {
    showToast(
      toast,
      intl.formatMessage({id :workflowData?.flowType===FlowType.ivr? 'phoneTreeClonedSuccessfully': 'automationClonedSuccessfully'}),
      ToastType.success,
      4000
    );
  }

  const getWorkflowSuccessHandler = () => {
    return (workflowData: IWorkflow) => {
      const newWorkflowData = changeWorkflowIdForImport(workflowData);
      const workflowInputPayload = buildWorkflowInputPayload(newWorkflowData);
      if(isExportSupported){
        const selectTenants = (componentState.isSelectAll ? tenants : componentState.tenants);
        exportWorkflowData({'workflowData':workflowInputPayload, tenants: selectTenants}).finally(()=>{
          setComponentState({ ...componentState, isLoading: false });
          clonedSuccessfullyToast();
          onCompleteAction(COMMON_ACTION_CODES.CLONE);
        });
      }else{
      saveWorkflow(
        workflowInputPayload,
        saveWorkflowSuccessHandler(),
        (error: any) => {

          setComponentState({ ...componentState, isLoading: false });
          alert('Duplicate Step Machine Name');
        });
      }
    }
  }

  const saveWorkflowSuccessHandler = () => {
    return (result: any) => {
      if (workflowData.flowType != 'IVR') {
        clonedSuccessfullyToast();
        onCompleteAction(COMMON_ACTION_CODES.CLONE);
      } else {
        cloneIvr(result);
      }
    }
  }

  const validateWorkflowTitle = async () => {
    const titleCount = await getWorkflowCountByNameAndFlowType(componentState.name, workflowData.tenantId);
    return (titleCount?.count==0);
  }


  const setCurrentTenants = () => {
    const list: any = [];
    componentState?.environments?.forEach((env: any) => {
      allTenants.forEach((item: any) => {
        if (env == item.env) {
          list.push({ ...item });
        }
      });
    });
    setTenants(list);
    setComponentState((prev: any) => {
      const list: any = [];
      componentState?.environments?.forEach((env: any) => {
        prev?.tenants?.forEach((item: any) => {
          if (env == item.env) {
            list.push({ ...item });
          }
        });
      });
      return { ...prev, tenants: list }
    });

  }

  useEffect(() => {
    if (!allTenants.length && isExportSupported) {
      getAllTenants().then((response) => {
        const data = response.data;
        const list: any = [];
        Object.keys(data).forEach((key) => {
          data[key].forEach((item: any) => {
            list.push({ ...item, env: key });
          });
        });
        setAllTenants(list);
        setCurrentTenants();
      });
    } else if(isExportSupported) {
      setCurrentTenants();
    }

  }, [componentState?.environments])

  const environments = [{ code: 'production', name: 'Production' }, { code: 'sandbox', name: 'Sandbox' }, { code: 'qa', name: 'QA' }, { code: 'develop', name: 'development' }];
  const   cloneIvr = (workflowDetail: IWorkflowDetail) => {
    const ivrInput = {
      workflowMasterId: workflowDetail.workflowMasterId,
      tenantId: workflowDetail.tenantId,
      flowName: componentState.name,
      isUpdate: false,
    };
    saveIvrWorkflow(
      ivrInput,
      (result: any) => {
        handleSetupPhoneTreeAction({
          accountUuid: ivrInput.tenantId,
          userUuid,
          level: '',
        }).then((r) => {
          clonedSuccessfullyToast();
          onCompleteAction(COMMON_ACTION_CODES.CLONE);
        });
      },
      (error: any) => {

        setComponentState({ ...componentState, isLoading: false });
        alert('Error in save ivr workflow');
      }
    );
  }

  return (
    <Drawer
      width={drawerWidth}
      visible={isOpen}
      onClose={() => {
        handleOnClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={'cloneWorkflow'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  handleOnClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'submit',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: componentState.isLoading,
                isDisabled: componentState.name && ( !isExportSupported || ( componentState.isSelectAll || componentState?.tenants?.length)) ? false : true,
                onClick: () => {
                  handleSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack flex={1}>
        <HStack >
          <Text fontWeight={300} fontSize={16}>
            {'Name'}
            {<Text color="error.500">*</Text>}
          </Text>
        </HStack>
        <HStack >
          <View marginY={1}>
            <FormControl flex={1}>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                placeholder="Enter Name"
                style={{ height: 36 }}
                type="number"
                isDisabled={componentState.isLoading}
                onChangeText={(name) => {
                  const newValue = {
                    ...componentState,
                    name: name,
                  };
                  setComponentState(newValue);
                }}
              />
            </FormControl>
          </View>
        </HStack>
        {!isTitleValid && <Text color={Colors.Custom.ErrorColor}>{titleErrorMessage}</Text>}
      </VStack>
      <VStack style={[styles.workflowNameFlex, { width: '100%' }]}>
        <Text style={styles.workflowNameTextLabel}>Description</Text>
        <Input
        _focus={{borderColor: '#D0D5DD'}}
          style={styles.workflowNameInput}
          isFullWidth={true}
          placeholder="Description"
          lineHeight={4}
          isDisabled={componentState.isLoading}
          numberOfLines={5}
          multiline
          onChangeText={(description) => {
            const newValue = {
              ...componentState,
              description: description,
            };
            setComponentState(newValue);
          }}
        />
      </VStack>
      {isExportSupported && <VStack style={[styles.workflowNameFlex, { width: '100%' }]}>
        <HStack >
          <Text style={styles.workflowNameTextLabel} >
            {'Environments'}
            {<Text color="error.500">*</Text>}
          </Text>
        </HStack>
        <VStack width={'100%'} >
          <Select
            mode="multiple"
            allowClear
            filterOption={false}
            value={componentState.environments}
            onChange={(value: any[]) => {
              if (value) {
                setComponentState((prev: any) => {
                  return { ...prev, environments: value }
                });
              }
            }}
            placeholder="Select Environments"
            style={{ height: 'auto' }}
          >
            {environments.map((environment) => {
              return (
                <Select.Option key={environment.code} value={environment.code}>
                  {environment.name}
                </Select.Option>
              );
            })}
          </Select>
        </VStack>
      </VStack>}
      {!!componentState?.environments?.length  && isExportSupported && <VStack style={[styles.workflowNameFlex, { width: '100%' }]}>
        <HStack >
          <Text style={styles.workflowNameTextLabel}  marginRight={5}>
            {'Tenants'}
            {<Text color="error.500">*</Text>}
          </Text>
          <Checkbox
          marginLeft={5}
          value="complete"
                    isChecked={componentState.isSelectAll}
                    onChange={(value) => {
                      if(value){
                        setComponentState((prev:any)=>{
                          return {...prev,isSelectAll:value }
                        });
                      } else {
                        setComponentState((prev:any)=>{
                          return {...prev,isSelectAll:value }
                        });
                      }
                    }}
                  >
                    All Tenants
                  </Checkbox>
        </HStack>
        {!componentState.isSelectAll && <VStack width={'100%'} >
          <Select
            mode="multiple"
            allowClear
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            value={componentState.tenants?.map((tenant: any) => { return tenant?.uuid })}
            onChange={(value: any[]) => {
              if (value) {
                const result = value.map((uuid) => {
                  let item;
                  tenants.some((tenant: any) => {
                    if (tenant.uuid === uuid) {
                      item = tenant;
                    }
                  });
                  return item;
                });
                setComponentState((prev: any) => {
                  return { ...prev, tenants: result }
                });
              }
            }}
            placeholder="Select Tenants"
            style={{ height: 'auto' }}
          >
            {tenants.map((tenant: any) => {
              return (
                <Select.Option key={tenant.uuid} value={tenant.uuid}>
                  {tenant.name + ' (' + environments?.filter((item:any)=>{
                    return tenant.env == item.code
                  })[0]?.name + ')' }
                </Select.Option>
              );
            })}
          </Select>
        </VStack> }
      </VStack>}
    </Drawer>
  );
};

export default CloneWorkflow;
