import { gql } from '@apollo/client';

export const GetInboxes = gql`
  query getInboxes($searchString: String!) {
    inboxes(
      order_by: [{updatedAt: desc}]
      where: {isDeleted: {_eq: false}, name: {_ilike: $searchString}}
    ) {
      inboxMembers(where: {isDeleted: {_eq: false}}) {
        userId
        user {
          name
        }
        isDeleted
      }
      isDeleted
      accountId
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      channelTwilioSms {
        id
        phoneNumber
      }
      channelEmail {
        email
        imapAddress
        imapEmail
        imapPassword
        imapPort
        smtpAddress
        smtpEmail
        smtpPassword
        smtpPort
        id
        uuid
        forwardRouteId
        forwardToEmail
      }
    }
  }
`;

export const GetInboxesData = gql`
  query getInboxes($whereCondition: inboxes_bool_exp!) {
    inboxes(where: $whereCondition) {
      isDeleted
      inboxMembers {
        userId
        user {
          name
        }
        isDeleted
      }
      accountId
      enableAutoAssignment
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      channelTwilioSms {
        id
        phoneNumber
      }
      channelEmail {
        email
        imapAddress
        imapEmail
        imapPassword
        imapPort
        smtpAddress
        smtpEmail
        smtpPassword
        smtpPort
        uuid
        id
      }
    }
  }
`;

export const GetInboxById = gql`
  query getInboxById($id: String!) {
    inboxes(where: {id: {_eq: $id}}) {
      inboxMembers {
        userId
        user {
          name
        }
        isDeleted
      }
      accountId
      enableAutoAssignment
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      channelTwilioSms {
        id
        phoneNumber
      }
      channelEmail {
        email
        imapAddress
        imapEmail
        imapPassword
        imapPort
        smtpAddress
        smtpEmail
        smtpPassword
        smtpPort
      }
    }
  }
`;


export const GetInboxMemberPubSubTokenById = gql`
  query getInboxById($id: bigint!) {
    inboxes(where: {id: {_eq: $id}}) {
      inboxMembers {
        userId
        user {
          id
          pubsubToken
        }
      }
    }
  }
`;

export const updateMessage = gql`
  mutation editMessage($content: String!, $messageId: bigint!) {
    updateMessage(_set: {content: $content}, pk_columns: {id: $messageId}) {
      id
      content
      isDeleted
      updatedAt
      createdAt
    }
  }
`;

export const deleteMessage = gql`
  mutation deleteMessage($isDeleted: Boolean, $messageId: bigint!) {
    updateMessage(_set: {isDeleted: $isDeleted}, pk_columns: {id: $messageId}) {
      id
      isDeleted
      updatedAt
      createdAt
    }
  }
`;

export const GetInboxesWithChannelTypeFilter = gql`
  query getInboxes($whereCondition: inboxes_bool_exp!) {
    inboxes(where: $whereCondition) {
      inboxMembers {
        userId
        user {
          name
        }
      }
      accountId
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      channelTwilioSms {
        id
        phoneNumber
      }
      channelEmail {
        email
        imapAddress
        imapEmail
        imapPassword
        imapPort
      }
    }
  }
`;

export const GetInboxesForFilter = gql`
  query getInboxesForFilter($userId: Int!, $search: String!) {
    inboxes(
      where: {
        name: {_ilike: $search}
        inboxMembers: {userId: {_eq: $userId}}
        isDeleted: {_eq: false}
      }
      order_by: {name: asc}
    ) {
      channelId
      channelType
      name
      id
      contactInboxes_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GetAllNonDeletedInboxesMembers = gql`
  query getAllInboxesMembers($inboxId: Int!) {
    inboxMembers(where: {inboxId: {_eq: $inboxId}, isDeleted: {_eq: false}}) {
      id
      userId
      inboxId
      createdAt
      updatedAt
      uuid
      isDeleted
    }
  }
`;

export const getAllInboxesMembersByInboxId = gql`
  query getAllInboxesMembers($inboxId: Int!, $searchString: String!) {
    inboxMembers(where: {user: {accountUsers: {user: {name: {_ilike: $searchString} }}}, inboxId: {_eq: $inboxId}, isDeleted: {_eq: false}}) {
      id
      userId
      inboxId
      createdAt
      updatedAt
      uuid
      isDeleted
      user{
        accountUsers{
          user{
               name
               id
               uuid
               uid
               type
               email
               userRoles {
                 userRole {
                   roleCategory {
                     code
                   }
                   userRole {
                     id
                     code
                     value
                   }
                 }
               }
             }
           }
      }
    }
  }
`;

export const deleteInboxesMembers = gql`
  mutation deleteInboxMembers($inboxId: Int!, $userIds: [Int!]!) {
    updateInboxMembers(
      _set: {isDeleted: true}
      where: {userId: {_in: $userIds}, inboxId: {_eq: $inboxId}}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetInboxesForContact = gql`
  query getInboxesForContact($contactId: Int!, $inboxId: bigint!) {
    contactInboxes(
      where: {contactId: {_eq: $contactId}, inboxId: {_eq: $inboxId}}
    ) {
      id
      inboxId
      contactId
      conversations {
        id
        displayId
        uuid
        inboxId
      }
    }
  }
`;

export const GetInboxesForNumber = gql`
  query getInboxesForContact($inboxId: bigint!, $sourceId: String!) {
    contactInboxes(
      where: {inboxId: {_eq: $inboxId}, sourceId: {_eq: $sourceId}}
    ) {
      id
      inboxId
      sourceId
      contactId
      contact {
        name
        phoneNumber
        phoneNumberCountryCode
        formattedPhoneNumber
        contactType{
          contactType{
            code
          }
        }
        contactConsents {
          consentId
        }
      }
      conversations {
        id
        displayId
        uuid
      }
    }
  }
`;

export const GetInboxFromId = gql`
  query getinboxFromId($id: bigint!) {
    inbox(id: $id) {
      accountId
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      uuid
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      inboxAvailabilities(where: {isDeleted: {_eq: false}}) {
        accountLocationUuid
      }
    }
  }
`;

export const GetWidgetFromId = gql`
  query getWidgetFromId($id: bigint!) {
    channelWebWidget(id: $id) {
      accountId
      createdAt
      featureFlags
      hmacMandatory
      hmacToken
      id
      preChatFormEnabled
      preChatFormOptions
      replyTime
      websiteToken
      websiteUrl
      welcomeTagline
      welcomeTitle
      widgetColor
    }
  }
`;

export const GetTwilioSmsChannelFromId = gql`
  query getChannelTwilioSms($id: bigint!) {
    channelTwilioSms(id: $id) {
      id
      uuid
      phoneNumber
    }
  }
`;

export const GetChannelTwilioSmsList = gql`
  query getChannelTwilioSmsList {
    channelTwilioSmsList(where: {isDeleted: {_eq: false}}) {
      id
      uuid
      phoneNumber
      accountId
    }
  }
`;

export const AddInbox = gql`
  mutation addInbox(
    $name: String!
    $channelType: String!
    $channelId: Int!
    $greetingEnabled: Boolean!
    $greetingMessage: String!
    $accountId: Int!
  ) {
    createInbox(
      object: {
        name: $name
        channelType: $channelType
        accountId: $accountId
        channelId: $channelId
        greetingEnabled: $greetingEnabled
        greetingMessage: $greetingMessage
      }
    ) {
      id
      channelId
      greetingEnabled
      name
      createdAt
    }
  }
`;

export const CreateEmailChannel = gql`
  mutation createEmailChannel($forwardToEmail: String!, $email: String!) {
    createChannelEmail(
      object: {
        accountId: 2
        email: $email
        forwardToEmail: $forwardToEmail
        createdAt: "today"
        updatedAt: "today"
      }
    ) {
      accountId
      createdAt
      id
      email
    }
  }
`;

export const CreateWebWidgetChannel = gql`
  mutation createWebWidgetChannel(
    $websiteUrl: String!
    $websiteToken: String!
    $welcomeTagline: String!
    $welcomeTitle: String!
    $widgetColor: String!
    $preChatFormEnabled: Boolean!
    $preChatFormOptions: jsonb!
  ) {
    createChannelWebWidget(
      object: {
        accountId: $accountId
        createdAt: "today"
        websiteToken: $websiteToken
        websiteUrl: $websiteUrl
        welcomeTagline: $welcomeTagline
        welcomeTitle: $welcomeTitle
        widgetColor: $widgetColor
        preChatFormEnabled: $preChatFormEnabled
        preChatFormOptions: $preChatFormOptions
        updatedAt: "today"
      }
    ) {
      id
      websiteToken
    }
  }
`;

const UpdateWidgetSettings = gql`
  mutation MyMutation(
    $allowMessagesAfterResolved: Boolean!
    $csatSurveyEnabled: Boolean!
    $enableEmailCollect: Boolean!
    $greetingEnabled: Boolean!
    $greetingMessage: String!
    $enableAutoAssignment: Boolean!
    $name: String!
    $id: bigint!
    $channelId: bigint!
    $websiteUrl: String!
    $welcomeTagline: String!
    $welcomeTitle: String!
    $widgetColor: String!
    $replyTime: Int!
    $featureFlags: Int!
  ) {
    updateInbox(
      pk_columns: {id: $id}
      _set: {
        allowMessagesAfterResolved: $allowMessagesAfterResolved
        csatSurveyEnabled: $csatSurveyEnabled
        greetingEnabled: $greetingEnabled
        enableEmailCollect: $enableEmailCollect
        greetingMessage: $greetingMessage
        enableAutoAssignment: $enableAutoAssignment
        name: $name
      }
    ) {
      id
    }
    updateChannelWebWidget(
      pk_columns: {id: $channelId}
      _set: {
        websiteUrl: $websiteUrl
        welcomeTagline: $welcomeTagline
        welcomeTitle: $welcomeTitle
        widgetColor: $widgetColor
        replyTime: $replyTime
        featureFlags: $featureFlags
      }
    ) {
      id
    }
  }
`;

const UpdateBusinessHours = gql`
  mutation MyMutation(
    $id: bigint!
    $outOfOfficeMessage: String!
    $timezone: String!
    $workingHoursEnabled: Boolean!
  ) {
    updateInbox(
      pk_columns: {id: $id}
      _set: {
        outOfOfficeMessage: $outOfOfficeMessage
        timezone: $timezone
        workingHoursEnabled: $workingHoursEnabled
      }
    ) {
      id
    }
  }
`;

const UpdateWidgetPreChat = gql`
  mutation MyMutation(
    $id: bigint!
    $preChatFormEnabled: Boolean!
    $preChatFormOptions: jsonb!
  ) {
    updateChannelWebWidget(
      pk_columns: {id: $id}
      _set: {
        preChatFormEnabled: $preChatFormEnabled
        preChatFormOptions: $preChatFormOptions
      }
    ) {
      id
    }
  }
`;

const UpdateWidgetConfig = gql`
  mutation MyMutation($id: Int!, $hmacMandatory: Boolean!) {
    updateChannelWebWidget(
      pk_columns: {id: $id}
      _set: {hmacMandatory: $hmacMandatory}
    ) {
      id
    }
  }
`;

const GetAgents = gql`
  query MyQuery2($roleCode: String!) {
    accountUsers(
      where: {
        isActive: {_eq: true}
        userId: {_is_null: false}
        user: {userRoles: {userRole: {userRole: {code: {_neq: $roleCode}}}}}
      }
    ) {
      accountId
      userId
      user {
        email
        id
        confirmedAt
        name
        uuid
        userRoles {
          userRole {
            userRole {
              value
              code
            }
          }
        }
      }
    }
  }
`;

const GetInternalUsers = gql`
  query GetInternalUsers($roleCode: String!, $searchString: String!, $limit: Int!) {
    accountUsers(
      where: {
        isActive: { _eq: true }
        userId: { _is_null: false }
        user: {
          name: { _ilike: $searchString }
          userRoles: { 
            userRole: { 
              userRole: { 
                code: { _neq: $roleCode } 
              } 
            } 
          }
        }
      }
      limit: $limit
      order_by: { user: { name: asc } }
    ) {
      userId
      user {
        name
        uuid
        id
        userRoles {
          userRole {
            userRole {
              value
              code
            }
          }
        }
      }
    }
  }
`;

const GetInitialInternalUsers = gql`
  query GetInitialInternalUsers($roleCode: String!, $limit: Int!) {
    accountUsers(
      where: {
        isActive: { _eq: true }
        userId: { _is_null: false }
        user: {
          userRoles: { 
            userRole: { 
              userRole: { 
                code: { _neq: $roleCode } 
              } 
            } 
          }
        }
      }
      limit: $limit
      order_by: { user: { name: asc } }
    ) {
      userId
      user {
        name
        uuid
        id
        userRoles {
          userRole {
            userRole {
              value
              code
            }
          }
        }
      }
    }
  }
`;

const GetInternalUsersByUUIDs = gql`
  query getInternalUsersByUUIDs($userUuids: [uuid!]) {
    accountUsers(
      where: {
        isActive: { _eq: true }
        userId: { _is_null: false }
        user: {
          uuid: { _in: $userUuids }
        }
      }
      order_by: { user: { name: asc } }
    ) {
      userId
      user {
        name
        uuid
        id
        userRoles {
          userRole {
            userRole {
              value
              code
            }
          }
        }
      }
      role
    }
  }
`;

const GetAssignedAgents = gql`
  query MyQuery($inboxId: Int!) {
    inboxMemberAggregate(where: {inboxId: {_eq: $inboxId}}) {
      nodes {
        userId
      }
    }
  }
`;

const DeleteAgents = gql`
  mutation MyMutation($agentIdToDelete: [Int!], $inboxId: Int!) {
    deleteInboxMembers(
      where: {
        userId: {_in: $agentIdToDelete}
        _and: {}
        inboxId: {_eq: $inboxId}
      }
    ) {
      affected_rows
      returning {
        inboxId
        userId
      }
    }
  }
`;

const AddAgent = gql`
  mutation MyMutation($agentsToAdd: [inbox_members_insert_input!]!) {
    createInboxMembers(objects: $agentsToAdd) {
      affected_rows
      returning {
        id
        userId
      }
    }
  }
`;

export const createInboxMembers = gql`
  mutation createInboxMembers($objects: [inbox_members_insert_input!]!) {
    createInboxMembers(
      objects: $objects
      on_conflict: {
        constraint: inbox_members_inbox_id_user_id_key
        update_columns: isDeleted
      }
    ) {
      affected_rows
      returning {
        id
        userId
      }
    }
  }
`;

export const DeleteInbox = gql`
  mutation deleteInbox($id: bigint!) {
    updateInbox(_set: {isDeleted: true}, pk_columns: {id: $id}) {
      id
    }
  }
`;

export const UpdateChannelEmail = gql`
mutation updateChannelEmail($id: bigint!, $imapEmail: String, $email: String, $imapPassword: String, $smtpEmail: String, $smtpPassword: String) {
  updateChannelEmail(_set: {
    isDeleted: true,
    imapEmail: $imapEmail,
    email: $email,
    imapPassword: $imapPassword,
		smtpEmail: $smtpEmail,
		smtpPassword: $smtpPassword,
  }, pk_columns: {id: $id}) {
    id
  }
}
`;

export const UpdateChannelEmailForwardRoutId = gql`
mutation updateChannelEmail($id: bigint!, $forwardRouteId: String, $forwardToEmail: String) {
  updateChannelEmail(_set: {
    forwardRouteId: $forwardRouteId,
    forwardToEmail: $forwardToEmail,
  }, pk_columns: {id: $id}) {
    id
  }
}
`;

export const updateChannelTwilioSms = gql`
  mutation updateChannelTwilioSms($id: bigint!) {
    updateChannelTwilioSms(_set: {isDeleted: true}, pk_columns: {id: $id}) {
      id
    }
  }
`;

export const updateSMSInboxName = gql`
  mutation updateInboxName(
    $name: String!
    $id: bigint!
    $enableAutoAssignment: Boolean!
    $showInboxNameInEmailRecipients: Boolean = false
    $inboxType: String
  ) {
    updateInbox(
      _set: {
        name: $name
        isDeleted: false
        enableAutoAssignment: $enableAutoAssignment
        showInboxNameInEmailRecipients: $showInboxNameInEmailRecipients
        inboxType: $inboxType
      }
      pk_columns: {id: $id}
    ) {
      id
      name
      enableAutoAssignment
      showInboxNameInEmailRecipients
      inboxType
    }
  }
`;

export const updateOutOfOfficeMessage = gql`
  mutation updateOutOfOfficeMessage(
    $outOfOfficeMessage: String!
    $id: bigint!
  ) {
    updateInbox(
      _set: {outOfOfficeMessage: $outOfOfficeMessage}
      pk_columns: {id: $id}
    ) {
      id
    }
  }
`;

export const CHECK_SMS_INBOX_EXIST = gql`
  query CHECK_SMS_INBOX_EXIST($phoneNumber: String!) {
    channelTwilioSmsList(where: {phoneNumber: {_eq: $phoneNumber}}) {
      id
    }
  }
`;

export const GetInboxByIdIsActive = gql`
query GetInboxByIdIsActive($id: bigint = "252") {
  inboxes(where: {id: {_eq: $id}, isDeleted: {_eq: false}}) {
    id
    name
  }
}
`

export const GetChannelTypeById = gql`
  query getChannelTypeById($id: bigint!) {
    inboxes(where: {id: {_eq: $id}}) {
      id
      channelType
    }
  }
`;

export const CheckBlockNumber = gql`
  query CheckPhoneNumberBlock($toNumber: String!, $fromNumber: String!) {
    blockNumbers(
      where: {
        toNumber: {_eq: $toNumber}
        fromNumber: {_eq: $fromNumber}
        isDeleted: {_eq: false}
      }
      limit: 1
    ) {
      id
    }
  }
`;

export const GetInboxesForContactWithSourceId = gql`
  query getInboxesForContactWithSourceId($sourceId: String, $inboxId: bigint!) {
    contactInboxes(
      where: {sourceId: {_eq: $sourceId}, inboxId: {_eq: $inboxId}}
    ) {
      id
      inboxId
      contactId
      conversations {
        id
        displayId
        uuid
        inboxId
      }
    }
  }
`;

export const GetInboxesForEmail = gql`
  query GetInboxesForEmail($whereCondition: inboxes_bool_exp!) {
    inboxes(where: $whereCondition) {
      channelId
      channelType
      emailAddress
      id
      name
      inboxType
      channelEmail {
        smtpAddress
        email
      }
    }
  }
`;

export const GetEFaxInboxList = gql`
  query GetEFaxInboxes($userId: Int!, $accountId: Int!) {
    inboxes(
      where: {
        channelType: {_eq: "Channel::EFax"}
        inboxMembers: {userId: {_eq: $userId}}
        accountId: {_eq: $accountId}
      }
    ) {
      id
      name
      channelType
      channelEfax {
        efaxNumber
      }
    }
  }
`;

export const GetEFaxInboxListWithInboxMembers = gql`
  query GetEFaxInboxes($accountId: Int!) {
    inboxes(
      where: {channelType: {_eq: "Channel::EFax"}, accountId: {_eq: $accountId}}
    ) {
      id
      name
      channelType
      channelEfax {
        efaxNumber
      }
      inboxMembers {
        userId
        user {
          id
          uuid
          name
        }
      }
    }
  }
`;


export const UpdateInbox = gql`
  mutation UpdateInbox($id: bigint!, $_set: inboxes_set_input = {}) {
    updateInbox(pk_columns: {id: $id}, _set: $_set) {
      id
    }
  }
`;

export const GetInboxesForSettings = gql`
  query getInboxes($searchString: String!, $channelType: [String!]) {
    inboxes(
      order_by: [{updatedAt: desc}]
      where: {
        isDeleted: {_eq: false}
        name: {_ilike: $searchString}
        channelType: {_in: $channelType}
      }
    ) {
      inboxMembers(where: {isDeleted: {_eq: false}}) {
        userId
        user {
          name
        }
        isDeleted
      }
      isDeleted
      accountId
      allowMessagesAfterResolved
      channelId
      channelType
      createdAt
      csatSurveyEnabled
      emailAddress
      enableAutoAssignment
      enableEmailCollect
      greetingEnabled
      greetingMessage
      id
      name
      outOfOfficeMessage
      timezone
      updatedAt
      workingHoursEnabled
      showInboxNameInEmailRecipients
      inboxType
      channelTwilioSms {
        id
        phoneNumber
      }
      channelEmail {
        email
        imapAddress
        imapEmail
        imapPassword
        imapPort
        smtpAddress
        smtpEmail
        smtpPassword
        smtpPort
        id
        uuid
        forwardRouteId
        forwardToEmail
      }
    }
  }
`;

export default {
  GetInboxes,
  GetInboxesData,
  GetAgents,
  GetInternalUsers,
  GetInitialInternalUsers,
  GetInternalUsersByUUIDs,
  GetAssignedAgents,
  AddInbox,
  CreateEmailChannel,
  CreateWebWidgetChannel,
  GetInboxFromId,
  GetWidgetFromId,
  UpdateWidgetSettings,
  UpdateBusinessHours,
  UpdateWidgetPreChat,
  UpdateWidgetConfig,
  DeleteAgents,
  AddAgent,
  GetInboxesForFilter,
  DeleteInbox,
  GetTwilioSmsChannelFromId,
  GetChannelTwilioSmsList,
  updateSMSInboxName,
  updateChannelTwilioSms,
  GetInboxesForContact,
  GetInboxesWithChannelTypeFilter,
  updateOutOfOfficeMessage,
  updateMessage,
  deleteMessage,
  GetInboxesForNumber,
  CHECK_SMS_INBOX_EXIST,
  GetAllNonDeletedInboxesMembers,
  deleteInboxesMembers,
  createInboxMembers,
  GetInboxById,
  UpdateChannelEmail,
  UpdateChannelEmailForwardRoutId,
  getAllInboxesMembersByInboxId,
  GetInboxMemberPubSubTokenById,
  GetInboxByIdIsActive,
  GetChannelTypeById,
  CheckBlockNumber,
  GetInboxesForContactWithSourceId,
  GetInboxesForEmail,
  UpdateInbox,
  GetEFaxInboxList,
  GetEFaxInboxListWithInboxMembers,
  GetInboxesForSettings,
};
