import { Select, DatePicker, Empty } from 'antd';
import { Moment } from 'moment';
import {Box, HStack, Skeleton, Spacer, Text, View, VStack} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import { useIntl } from 'react-intl';
import { getActivities } from '../../../../../../../services/CommonService/AidBoxService';
import {LineGraph} from '../../../../../../common/Graph/LineGraph';
import {styles} from '../../DetailTableStyles';
import { formatGraphValue, getActivityFormattedData, getDateRange, getFormattedActivityData, getPatientData } from '../ActivityUtils';
import { CustomPlotName, IGraphDataSetInCoords, getAnonationIndex, getDomain } from '../../VitalsGraphView/VitalsGraphUtils';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { CustomLineGraph } from '../../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomLineGraph/CustomLineGraph';

interface IActivityProps {
    title: string;
    type: string;
    range: any;
    showAutomatic: boolean;
    patientId?: string;
    accountLocationUuid?: string;
    renderMode?: GRAPH_RENDER_MODE
    ccmDate?: string;
    isSmallView?: boolean;
    activityData?: any[];
    selectedParentFilterId?: string;
}

export enum GRAPH_RENDER_MODE {
  VICTORY,
  ANT
}

export const activityFrequencyUnitCodes = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'lastWeek',
  LAST_THREE_WEEKS: 'lastThreeWeeks',
  LAST_MONTH: 'lastMonth',
  LAST_THREE_MONTHS: 'lastThreeMonths',
  LAST_SIX_MONTHS: 'lastSixMonths',
  ALL: 'All'
};

const ActivitiesGraphView = (props: IActivityProps) => {
    const { RangePicker } = DatePicker;
    const intl = useIntl();
    const patientUuid = props.patientId;
    const type = props.type;
    const showAutomatic = props.showAutomatic;
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(false);
    const [selectedFilterId, setselectedFilterId] = useState<string>(activityFrequencyUnitCodes.TODAY);
    const [dateRange, setDateRange] = useState(props.range)
    const {ccmDate} = props;
    const graphFilterList = [
      {
        id: activityFrequencyUnitCodes.TODAY,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.TODAY}),
      },
      {
        id: activityFrequencyUnitCodes.YESTERDAY,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.YESTERDAY}),
      },
      {
        id: activityFrequencyUnitCodes.LAST_WEEK,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.LAST_WEEK}),
      },
      {
        id: activityFrequencyUnitCodes.LAST_THREE_WEEKS,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.LAST_THREE_WEEKS}),
      },
      {
        id: activityFrequencyUnitCodes.LAST_MONTH,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.LAST_MONTH}),
      },
      {
        id: activityFrequencyUnitCodes.LAST_THREE_MONTHS,
        value: intl.formatMessage({id: activityFrequencyUnitCodes.LAST_THREE_MONTHS}),
      },
    ];

    const graphPadding = props.isSmallView ? {top: 60, bottom: 60, left: 80, right:60} : undefined; 

    useEffect(() => {
      if (props.selectedParentFilterId) {
        setselectedFilterId(props.selectedParentFilterId);
        const range = getDateRange(props.selectedParentFilterId);
        setDateRange(range);
      }
    }, [props.selectedParentFilterId]);    


  const dataSets: Array<IGraphDataSetInCoords> = [];

(getActivityFormattedData(dateRange.startDate, dateRange.endDate, data, showAutomatic) || []).forEach((element) => {
      dataSets.push({
        dateStr: element.date,
        x: element.date,
        y: `${formatGraphValue(element.value)}`,
      });
    });    

  const unit = (data || []).find((element) => {
    return (element.unit?.length > 0)
  })?.unit;

  const getYAxisTitle = () => {
    const title = props.title;
    if (unit) {
      return `${title} (${unit})`
    }
    return title
  }

  const graphData = {
    graphData: dataSets,
    xField: 'date',
    yField: 'value',
    yAxis: {
      title: {
        text: getYAxisTitle(),
      },
    },
    seriesField: 'label',
    smooth: true,
    maintainAspectRatio: true,
    style: {maxHeight: 200},
    annontationIndex: ccmDate
      ? getAnonationIndex(ccmDate, dataSets as any)
      : undefined
  };


  const errorHandler = (error: any) => {
    setLoading(false);
  };

  const fetchActivities = (
    patientId: string,
    range: {
      startDate: Moment,
      endDate: Moment,
    },
    responseHandler: any,
    isDaily = false) => {
    getActivities([type], range.startDate.format('YYYY-MM-DD'), range.endDate.format('YYYY-MM-DD'), [patientId], responseHandler, errorHandler, isDaily, props.accountLocationUuid);
  }

  useEffect(() => {
    if (type && patientUuid && !props.isSmallView) {
      const range = getDateRange(selectedFilterId)
      setDateRange(range)
      setLoading(true);
      fetchActivities(patientUuid, range, (response: any) => {
        const dailyData = getPatientData(response?.data || [], patientUuid, true);
        const dailyListData = getFormattedActivityData(dailyData, props.type, true);
        fetchActivities(patientUuid, range, (response: any) => {
          setLoading(false);
          const activityData = getPatientData(response?.data || [], patientUuid);
          const activityListData = getFormattedActivityData(activityData, props.type);
          setData([...dailyListData, ...activityListData])
        })
      }, true)
    }
  }, [selectedFilterId])

  useEffect(()=> {
    setData(props.activityData || [])
  }, [props.activityData])

  return (
      <View style={styles.graphViewStyles} marginTop={props.isSmallView ? 10 : 30}>
        <Stack space={2} direction={props.isSmallView ? 'column' : 'row'}>
          <Text
            style={{marginRight: 10, fontSize: props.isSmallView? 12 : 14}}
          >
            {props.isSmallView ? graphData.yAxis.title.text : props?.title}
          </Text>
          <Spacer/>
          {graphFilterList.length && !props.isSmallView && (
            <Select
              defaultValue={selectedFilterId}
              placeholder="Select"
              size={'small'}
              style={{width: '125px'}}
              onChange={(value) => {
                setselectedFilterId(value);
              }}           
              disabled={loading}
            >
              {graphFilterList.map((data) => {
                return (
                  <Select.Option key={data.id} value={data.id}>
                    {data.value}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </Stack>
        <Box pt={1}>
          {loading ? (
            <VStack>
              <Skeleton h={31} borderRadius={8} />
              <Skeleton.Text lines={2} mt={2} />
              <Skeleton.Text lines={2} mt={2} />
            </VStack>
          ) : (
            <>
              {dataSets && dataSets.length ? (
                props.renderMode === GRAPH_RENDER_MODE.VICTORY ? (                
                  <CustomLineGraph
                    customStyle={{
                      flex: 1
                    }}
                    vitalGraph={graphData}
                    ccmDate={ccmDate} 
                    isSmallView={props.isSmallView || false}
                  />
                ) : (
                  <></>
                )
              ) : (
                <Empty />
              )}
            </>
          )}
        </Box>
      </View>
  );
};

export default ActivitiesGraphView;
