import {Drawer, notification} from 'antd';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalActionTitle} from '../../ModalActionTitle/ModalActionTitle';
import {
  Center,
  HStack,
  Icon,
  Pressable,
  Spinner,
  VStack,
  View,
  useMediaQuery,
  useToast,
} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  getInActiveContactError,
} from '../../../../constants/StringConst';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {ILabelTask, ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {
  LABEL_TYPE_CODES,
  MLOV_CATEGORY,
  TASK_STATUS,
} from '../../../../constants/MlovConst';
import TaskCard from '../../../TaskCard/TaskCard';
import {
  descriptionDisplayModes,
  getCardTag,
  getDescription,
} from '../../../TaskCard/TaskCardHelper';
import {getVitalListFromCapability} from '../../../../utils/capabilityUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {withMiniContactViewHOC} from '../../../MiniContactViewHOC';
import {getMlovId, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {useLazyQuery} from '@apollo/client';
import TaskQueries from '../../../../services/Task/TaskQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {ITaskListDrawerView} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import ConversationTaskResourcesQueries from '../../../../services/Conversations/ConversationTaskResourcesQueries';
import { getUserUUID } from '../../../../utils/commonUtils';
import TaskPoolQueries, { GetUsersTaskPoolAccess } from '../../../../services/TaskPool/TaskPoolQueries';
import { useIntl } from 'react-intl';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { IUserPoolUser } from '../../CareDashboard/CareDashboardWidget/UserAutoComplete';

const TaskListDrawerView = (props: ITaskListDrawerView) => {
  const {width} = Dimensions.get('window');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const vitals = getVitalListFromCapability();
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadScreen || isIPadMiniScreen
    ? width / 2
    : width / 3;
  const [state, setState] = useState({
    loading: false,
    taskList: [] as ITask[],
    errorMsg: '',
    manageAccessUser: [] as string[],
  });
  const intl = useIntl();
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const toast = useToast();

  const accessUserTypeId = getMlovId(
    commonData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  const [GetTaskIdByMessageUuid] = useLazyQuery(
    ConversationTaskResourcesQueries.GET_TASK_ID_BY_MESSAGE_UUID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [getTasks] = useLazyQuery(TaskQueries.GET_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getTaskPools] = useLazyQuery(TaskPoolQueries.GetTaskPool, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getUserTaskPoolAccess] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const taskLabelMlov = filteredResult[0];

  useEffect(() => {
    getTaskList(props?.taskMessageUuid);
  }, []);

  const fetchTaskList = () => {
    getTaskList(props?.taskMessageUuid);
  }

  const getTaskList = async (messageUuid: string) => {
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const response = await GetTaskIdByMessageUuid({
        variables: {
          data: {
            sourceIds: messageUuid,
          },
        },
      });
      if (response?.data?.getResourceMappings?.length > 0) {
        const result = response.data.getResourceMappings.find(
          (item: any) => item.sourceId === messageUuid
        );
        const taskIdList = result?.resourceIds || [];
        if (taskIdList.length > 0) {
          const getTasksData = await getTasks({
            variables: {
              params: {
                ids: taskIdList,
                orderBy: {
                  createdOn: 'desc',
                }
              },
            },
          });
          if (getTasksData?.data) {
            const taskList = getTasksData?.data?.getTasks?.tasks;
            const taskUserPoolList = [] as string[];
            if (taskList) {
              taskList?.forEach(async (item: any) => {
                const isCompleted = (item?.status?.code === TASK_STATUS.COMPLETED);
                item.isCompleted = isCompleted;
                item.assignedBy = item?.assignedByUser;
                if (item?.userPoolId) {
                  taskUserPoolList.push(item?.userPoolId)
                }
              });
              getTaskListWithLabels(taskList, taskUserPoolList);
            } else {
              setState((prev: any) => {
                return {
                  ...prev,
                  loading: false,
                };
              });
            }
          } else {
            setState((prev: any) => {
              return {
                ...prev,
                loading: false,
              };
            });
          }
        }
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'deletedTaskByOtherMemberMsg'}),
          ToastType.info
        );
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        onCancel();
      }
    } catch (error) {
      setState((prev: any) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getTaskListWithLabels = async (
    taskList: ITask[],
    taskUserPoolList: string[]
  ) => {
    try {
      const labelUuidList = getLabelIds(taskList);
      const response = await getLabels(labelUuidList);
      const labelDataResponse = response || [];
      taskList?.forEach((item) => {
        item.labels = labelDataResponse.filter((label: any) =>
          item.labels?.find((abc) => abc.labelId === label.uuid)
        );
      });
      getTaskPoolUser(taskList, taskUserPoolList);
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getTaskPoolUser = async (
    taskList: ITask[],
    taskUserPoolList: string[]
  ) => {
    try {
      const manageAccessUser = await getManangeTaskUser();
      const userPoolList = await getTaskPools({
        variables: {params: {ids: taskUserPoolList}},
      });
      const userUuidList = [] as string[];
      taskList?.forEach((item) => {
        const selectedPool = userPoolList?.data?.getUserPools?.userPools.find(
          (data: any) => data.id == item?.userPoolId
        );
        selectedPool?.userPoolUsers.forEach((data: any) => {
          userUuidList.push(data?.userId);
        });
        if (item?.userPool) {
          item.userPool.userPoolUsers = userUuidList;
        }
      });
      setState((prev: any) => {
        return {
          ...prev,
          taskList: taskList,
          manageAccessUser: manageAccessUser || [],
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getManangeTaskUser = async () => {
    try {
      const userPoolAccessData = await getUserTaskPoolAccess({
        variables: {
          userId: userUuid,
          userPoolTypeId: accessUserTypeId,
        },
      });
      if (userPoolAccessData?.data?.userPoolAccesses?.length) {
        const userPoolAccessUsersData: IUserPoolUser[] =
          userPoolAccessData?.data?.userPoolAccesses[0]?.user_pool
            ?.userPoolUsers;
        const userPoolAccessUserUuids = userPoolAccessUsersData?.map(
          (user) => user?.userId
        );
        return userPoolAccessUserUuids;
      }
      return [];
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      return [];
    }
  };

  const getLabelIds = (taskList: ITask[]) => {
    const labelUuids: ILabelTask[] = [];
    taskList?.forEach((item) => {
      const labels = item?.labels || [];
      labels?.forEach((data) => {
        if (data.labelId) {
          labelUuids.push(data);
        }
      });
    });
    const uniqueLabelIds = Array.from(new Set(labelUuids));
    return uniqueLabelIds;
  };

  const getLabels = async (labels: ILabelTask[]) => {
    const labelIds = labels?.map((label: ILabelTask) => label?.labelId);
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: labelIds,
        labelTypeId: taskLabelMlov.id,
      },
    });
    const labelRes =
      getLabelsRes?.data?.labels?.map((labelData: ILabelTask) => {
        const matchedLabel = labels?.find(
          (labelArg: ILabelTask) => labelArg?.labelId === labelData?.uuid
        );
        if (matchedLabel) {
          labelData.id = matchedLabel?.id;
          labelData.labelId = matchedLabel?.labelId;
        }
        return labelData;
      }) || [];
    return labelRes;
  };
  const onMemberClick = useCallback((task: ITask) => {
    if (task?.contact?.isActive === false) {
      const message = getInActiveContactError(task?.contact);
      notification.error({
        message,
      });
      return;
    }
    const contactType = task?.contact?.contactType?.contactType?.code;
    const contactId = task.patientContactId || task?.contact?.id;

    if (!contactId) {
      return;
    }
    // if (isEmployer){
    //   setSelectedRowContactData(task?.contact)
    //   setlSelectedViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW);
    // } else {

    if (contactType == 'CUSTOMER') {
      props?.openContactInWindowOrOpenContactIdDrawer?.(contactId);
    } else if (contactType == 'LEAD') {
      props?.navigateOrOpenLeadInWindow?.(contactId);
    }
    // }
  }, []);

  const onCancel = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.VIEW_TASK_CLOSE);
  };

  const handleTaskViewAccess = (item: ITask) => {
    if (!item) {
      return false;
    }
    if (item?.userPool?.userPoolUsers?.includes(userUuid)) {
      return true;
    }

    const ids = [item?.assignedById, item?.createdBy, item?.assigneeId];
    if (ids.length > 0) {
      if (ids.includes(userUuid)) {
        return true;
      }
      if (
        state.manageAccessUser.length > 0 &&
        ids.some((id) => id && state.manageAccessUser.includes(id))
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Drawer
      visible={props.taskModal}
      width={finalWidth}
      style={{paddingLeft: '0', paddingRight: '0'}}
      onClose={() => {
        onCancel();
      }}
      title={
        <HStack borderBottomWidth={1} borderBottomColor={'gray.200'}>
          <ModalActionTitle title={intl.formatMessage({id: 'taskFromMessage'})} titleColor={''} />
          <View
            paddingLeft={4}
            paddingRight={2}
            marginTop={4}
            marginBottom={4}
            borderLeftWidth={1}
            borderLeftColor={'gray.200'}
          >
            <Pressable
              alignItems={'flex-end'}
              justifyContent={'center'}
              alignSelf={'center'}
              onPress={onCancel}
            >
              <Icon size="5" color="gray.500" as={<AntIcon name="close" />} />
            </Pressable>
          </View>
        </HStack>
      }
    >
      {state.loading ? (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      ) : (
        <VStack>
          {state.taskList?.map((item) => {
            return (
              <TaskCard
                task={item}
                isPatientTask={false}
                key={item.id}
                title={item.title}
                disable={true}
                isChecked={
                  item.isCompleted ||
                  item?.status?.code === TASK_STATUS.COMPLETED ||
                  false
                }
                data={item}
                description={getDescription(
                  item,
                  item.patientName || item.contact?.name,
                  descriptionDisplayModes.SHORT,
                  vitals
                )}
                onDeleteTaskHandler={(deletedTask: ITask)=> {
                  getTaskList(props?.taskMessageUuid);
                }}
                tag={getCardTag(item)}
                assigneeName={item.patientName || item.contact?.name}
                onPress={() => {
                  if (handleTaskViewAccess(item)) {
                    props.onActionPerformed(
                      COMMON_ACTION_CODES.VIEW_TASK,
                      item
                    );
                  } else {
                    notification.info({
                      message: intl.formatMessage({id: 'accessDenied'}),
                      description: intl.formatMessage({id: 'taskAccessDeniedMessage'}),
                    });
                  }
                }}
                attachments={
                  item.patientEducations?.map((education: any) => {
                    return education.patientEducation;
                  }) || []
                }
                onCheckBoxChange={(isSelected) => {
                }}
                onAttachmentClick={(patientEducation) => {
                  window.open(patientEducation.url, '_blank');
                }}
                onAssigneeClick={() => {
                  onMemberClick(item);
                }}
                hideCheckBox={false}
                hideDeleteAction={true}
                showDeleteField={false}
              />
            );
          })}
        </VStack>
      )}
    </Drawer>
  );
};

export default withMiniContactViewHOC(TaskListDrawerView);
