import { IContactTopBarViewProp, IDashboardProgramFilter } from './interface';
import React, { useState } from 'react';
import { Stack, View, Text, Pressable, Skeleton, Divider } from 'native-base';
import { Colors } from '../../../styles/Colors';
import Feather from 'react-native-vector-icons/Feather';
import { Popover, Tooltip } from 'antd';
import LeftArrowIconSvg from '../Svg/AwvSvgs/LeftArrowIconSvg';
import { PATIENT_FILTERS_INFO } from './constant';
import SearchIconSvg from '../Svg/TimelineSvg/SearchIconSvg';
import { DATE_RANGE_FILTER, TIME_LINE_SOURCE_TYPES } from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import TimeLineRadioButtonView from '../../PersonOmniView/MiddleContainer/TimelineView/TimelineRadioButtonView';
import FilterIconSvg from '../Svg/TimelineSvg/FilterIconSvg';
import Chevron from '../Svg/Chevron';

export const CarePlanTopBarView = (props: IContactTopBarViewProp) => {
  const {
    filterCodes,
    onChangeCategoryCode,
    selectedCategoryCode,
    showInfoIcon = true,
    showSearchBar = false,
    tabHeaderStyle = {},
    timeSourceValue,
    dateFilterValue = DATE_RANGE_FILTER.ALL_TIME,
    onActionPerformed = () => { },
    onDateFilterChange = () => { },
    showFilterIcon,
  } = props;
  const [currentFilterCodes, setCurrentFilterCodes] = useState<IDashboardProgramFilter[]>(filterCodes)
  const [subFilterView, setSubFilterView] = useState<boolean>(false)
  const [openFilterPopover, setOpenFilterPopover] = useState(false)

  return (
    <View
      paddingX={2}
      style={{ paddingVertical: 10 }}
      borderBottomWidth={1}
      borderBottomColor={Colors.FoldPixel.GRAY100}>
      <Stack direction={'row'} flex={1} space={3} style={{alignItems: 'center'}}>
        {showSearchBar && <Pressable
          style={{ justifyContent: 'center' }}
          onPress={() => {
            onChangeCategoryCode('search');
          }}
        >
          <View
            borderRadius={8}
            padding={2}
            minW={10}
            alignItems={'center'}
            justifyContent={'center'}
            height={'40px'}
            borderColor={Colors.FoldPixel.GRAY300}
            borderWidth={0}
            marginRight={-2}
            key={`filter_code`}>
            <SearchIconSvg />
          </View>
        </Pressable>}
        {subFilterView && <Pressable
          style={{ justifyContent: 'center' }}
          onPress={() => {
            setCurrentFilterCodes(filterCodes)
            onChangeCategoryCode('ALL');
            setSubFilterView(false)
          }}
        >
          <View
            borderRadius={8}
            backgroundColor={Colors.FoldPixel.GRAY50}
            padding={2}
            minW={10}
            alignItems={'center'}
            justifyContent={'center'}
            height={'40px'}
            borderColor={Colors.FoldPixel.GRAY300}
            borderWidth={0}
            key={`filter_code`}>
            <LeftArrowIconSvg style={{ margin: 0, padding: 0 }} />
          </View>
        </Pressable>}
        {(currentFilterCodes || []).map(
          (filterCode: IDashboardProgramFilter, index: number) => {
            const isSelected = selectedCategoryCode == filterCode.categoryCode;
            const filterLoading = filterCode?.isLoading;
            return (
              <Stack direction={'row'} alignItems={"center"}>
                {filterCode.showLeftDivider && <Divider orientation='vertical' style={{height: 40, width: 1}} marginRight={3}/> }               
                <Pressable
                disabled={filterLoading}
                onPress={() => {
                  if (filterCode?.subFilters && filterCode?.subFilters?.length > 0) {
                    const newFilterCodes = []
                    newFilterCodes.push(filterCode)
                    filterCode.subFilters?.map((item) => newFilterCodes.push(item))
                    setCurrentFilterCodes(newFilterCodes)
                    setSubFilterView(true)
                  }
                  onChangeCategoryCode(filterCode.categoryCode);
                }}
              >
                <View
                  borderRadius={8}
                  backgroundColor={isSelected ? Colors.Custom.PrimaryColor100 : Colors.FoldPixel.GRAY50}
                  paddingX={2}
                  paddingY={1}
                  minW={16}
                  maxH={32}
                  style={tabHeaderStyle}
                  borderColor={isSelected ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY300}
                  borderWidth={isSelected ? 1 : 0}
                  key={`filter_code_${index}`}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Text color={isSelected ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY300}>
                      {filterCode.displayName}
                    </Text>
                    {(showInfoIcon && PATIENT_FILTERS_INFO[filterCode.categoryCode] && filterCode.categoryCode !== 'ALL') && (
                      <Tooltip
                        title={PATIENT_FILTERS_INFO[filterCode.categoryCode]}
                      >
                        <Feather
                          name="info"
                          style={{
                            fontSize: 16,
                            color: isSelected
                              ? Colors.Custom.Primary300
                              : Colors.Custom.Gray500,
                            padding: 0,
                            marginLeft: 4,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                  {filterLoading && (
                    <Stack direction={'row'} marginBottom={2} justifyContent={'center'}>
                      <Skeleton.Text alignContent={'center'} width={10} justifyContent={'center'} alignItems={'center'} lines={0.5} marginTop={'2px'}></Skeleton.Text>
                    </Stack>
                  )}
                  {!filterLoading && (
                    <Stack direction={'row'} style={{alignItems:'center', justifyContent:'center'}}>
                      <Text textAlign={'center'} mr={1} color={isSelected ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY300}>
                        {filterCode.count}
                      </Text>
                      {filterCode?.subFilters?.length && !subFilterView && <Chevron size={8}/>}
                    </Stack>
                  )}
                </View>
                </Pressable>
              </Stack>             
            );
          },
        )}
        {showFilterIcon && <Popover
          placement='bottom'
          open={openFilterPopover}
          overlayStyle={{
            borderRadius: 8
          }}
          overlayInnerStyle={{
            borderRadius: 8
          }}
          content={
            <TimeLineRadioButtonView
              radioGroupName="timeLineSourceSelector"
              radioGroupValue={timeSourceValue || []}
              radioButtonTypes={TIME_LINE_SOURCE_TYPES}
              dateRadioGroupValue={dateFilterValue}
              dateRangeFilters={DATE_RANGE_FILTER}
              onActionPerformed={(value) => {
                // setOpenFilterPopover(!openFilterPopover)
                onActionPerformed(value)
              }}
              onDateFilterChange={(value) => {
                setOpenFilterPopover(!openFilterPopover)
                onDateFilterChange(value)
              }}
            />
          }
          onOpenChange={() => {
            setOpenFilterPopover(!openFilterPopover)
          }}
          trigger={'click'}
          style={{
            fontSize: 200,
          }}
        >
          <Pressable onPress={() => setOpenFilterPopover(!openFilterPopover)} style={{ marginTop: 4, position: 'absolute', right: 10, alignSelf: 'center' }}>
            {((timeSourceValue?.length || 0) > 0 ||
              dateFilterValue !== DATE_RANGE_FILTER.ALL_TIME) && <View
                style={{
                  position: 'absolute',
                  top: -5.5,
                  right: -5.5,
                  backgroundColor: Colors.danger[500],
                  width: 10,
                  height: 10,
                  borderRadius: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />}
            <FilterIconSvg />
          </Pressable>
        </Popover>
        }
      </Stack>
    </View>
  );
};
