import { useLazyQuery } from '@apollo/client';
import { Select, Spin, notification } from 'antd';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { IContactRespData } from '../../../services/Contacts/interfaces';
import { getAccountId, isActiveContact } from '../../../utils/commonUtils';
import { ModalActionAntSelect } from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
// import './PatientDropdown.css';
import { useIntl } from 'react-intl';
import MemberInfoListItem from '../../common/MemberInfoListItem/MemberInfoListItem';
import { getContactTypeId } from '../../../utils/mlovUtils';
import { getChosenNameCondition } from '../Contacts/ContactsUtils';
import {getInActiveContactError} from '../../../constants';
import {isPatientDetailViewAllowed} from '../UserAccess/UserAccessPermission';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {withMiniContactViewHOC} from '../../MiniContactViewHOC';



const PatientDropdownList = (props: {
  defaultValue?: any;
  selectedAllContactData?: any;
  notShowPatient?: boolean;
  showLeadAndPatient?: boolean;
  onSelectValue: (actionData?: any) => void;
  openContactInWindowOrOpenContactIdDrawer?:(contactId?:string)=>void;
}) => {
  const [customerData, setCustomerData] = useState([] as any);
  const [selectedValue, setSelectedValue] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);

  const accountId = getAccountId();
  const customerTypeId = getContactTypeId('CUSTOMER');

  const redirectToProfile = (contactId?: number | string, contactTypeId?: string)=> {
    if (!contactId) {
      return;
    }
    if (contactTypeId === customerTypeId) {
      props?.openContactInWindowOrOpenContactIdDrawer?.(contactId.toString());
    } else {
      window.open(
        `/#/members/prospect/${contactId}`,
        '_blank'
      )
    }
  }

  const showRedirectIcon = ()=> {
    return isPatientDetailViewAllowed(mlovData.currentUserRoles,mlovData.userPermissions);
  }

  useEffect(() => {
    if (props?.defaultValue?.length) {
      setLoading(true)
      getContactsByIds({
        variables: {
          contactIdList: props?.defaultValue,
        },
      });
    }
  }, []);

  const [getContactsByIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (resp: any) => {
        const list: any = [];
        resp?.contacts?.map((contact: any) => {
          list.push({ label: contact.name, value: contact.id, key: contact.id });
        });
        setSelectedValue(list);
        setLoading(false)
        let contactsData = [];
        if (resp && resp.contacts && resp.contacts.length > 0) {
          contactsData = resp.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setCustomerData(contactsData);
        setLoading(false)
      },
      onError: (error: any) => {

      },
    }
  );

  const [getPatients] = useLazyQuery(
    ContactsQueries.GetContactWithOffset,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (resp: any) => {
        const patientList = resp?.contacts || [];
        let contactsData = [];
        if (patientList) {
          contactsData = resp.contacts
          .filter((contactItem: any) => contactItem?.uuid)
          .map((contact: any) => {
            return {
              label: contact.name,
              value: contact?.id,
              key: contact?.id,
              contactData: contact,
            };
          });
          setCustomerData(contactsData);

        } else {
          setCustomerData([]);
        }
        setLoading(false);
      },
      onError: (error: any) => {

      },
    }
  );

  const onChangeCustomer = (searchText: string) => {
    if (searchText) {
      onSearchCustomer(searchText);
    }
  };

  const getWhereCondition = (searchText: string) => {
    const where: any = {};
    const splitWords = searchText.split(' ').filter(word => word !== '')
    const chosenNameCondition = getChosenNameCondition(splitWords);

    where._or = [
       ...chosenNameCondition,
      {
        person: {
          chosenName: {
            _ilike: `%${searchText}%`,
          },
        },
      },
      {
        name: {
          _ilike: `%${searchText}%`,
        },
      },
    ];
    if (!props?.showLeadAndPatient) {
      if (props.notShowPatient) {
        where.contactType = {
          typeId: {
            _nin: [customerTypeId],
          },
        };
      } else {
        where.contactType = {
          typeId: {
            _eq: customerTypeId,
          },
        };
      }
    }
    return where;
  }


  const onSearchCustomer = async (searchText: string) => {
    try {
      setLoading(true);
      if(searchText.length > 0){
      await getPatients({
          variables: {
            where: getWhereCondition(searchText),
            limit:15,
            offset:0
        },
      });
    }
    } catch (e) {}
  };

  return (
    <ModalActionAntSelect
      mode={'multiple'}
      allowClear={false}
      leftMargin={'0'}
      showSearch={true}
      onSearch={debounce(onChangeCustomer, 500)}
      labelInValue={true}
      filterOption={false}
      value={selectedValue || undefined}
      isRequired={true}
      notFoundContent={intl.formatMessage({
        id: 'noMatchToSearch',
      })}
      loading={isLoading}
      placeholder={'Search'}
      onChange={(value: any) => {
        if (value) {
          const newValue = value.map((item: any) => {
            return {
              label: item?.label?.props?.contactData?.name || item?.label,
              key: item.key,
              value: item.value
            }
          })
          setSelectedValue(newValue)
          if (value && value.length > 0) {
            const actionData = value.map((data: any) => { return data?.value });
            props.onSelectValue(actionData)
          } else {
            props.onSelectValue(undefined)
          }
        }
      }}
      data={customerData}
      optionProps={{ key: 'key', value: 'value', label: 'label' }}
      extraStyle={{ flex: 1 }}
      optionsArray={
        customerData &&
        customerData.map((option: any) => {
          return (
            <Select.Option label={option?.contactData.id} key={option?.contactData.id} value={option?.contactData.id}>
              <MemberInfoListItem
                contactData={option?.contactData}
                isPatientSearchCard
                showRedirectIcon={showRedirectIcon()}
                onRedirectToProfile={() => {
                  const isActive=isActiveContact(option?.contactData);
                  if(!isActive) {
                    const message=getInActiveContactError(option?.contactData);
                    notification.error({
                      message,
                    });
                    return;
                  }
                  const contactId=option?.contactData?.id||option?.contactId;
                  const contactTypeId=option?.contactData?.contactType?.contactType?.id;
                  redirectToProfile(contactId,contactTypeId);
                }}
                showDateOfBirth={true}
              />
            </Select.Option>
          );
        })
      }
    />
  );
};

export default withMiniContactViewHOC(PatientDropdownList);
