import React from 'react';
import {HStack, Text} from 'native-base';
import {Switch, Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';

interface IShowToPatientSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  loading?: boolean;
  message: string;
  tooltipMessage?: string;
}

const ShowToPatientSwitch = (props: IShowToPatientSwitchProps) => {
  return (
    <HStack alignItems={'center'} space={1} flexWrap={'wrap'}>
      <Switch
        size="small"
        style={{
          backgroundColor: props.value
            ? Colors.Custom.mainPrimaryPurple
            : Colors.Custom.silverGray,
          width: 10,
        }}
        loading={props.loading}
        checked={props.value}
        onChange={props.onChange}
      />
      <Text color={Colors.Custom.Gray500}>{props?.message}</Text>
      {!!props?.tooltipMessage && (
        <Tooltip title={props?.tooltipMessage} placement="top">
          <Feather
            style={{
              marginTop: 2,
            }}
            size={14}
            name="info"
            color={Colors.Custom.Gray500}
          />
        </Tooltip>
      )}
    </HStack>
  );
};

export default ShowToPatientSwitch;
