import {gql} from '@apollo/client';

export const GetAllMlovCategories = gql`
  query MyQuery2 {
    mlovCategories {
      category
      id
    }
  }
`;

export default {GetAllMlovCategories};
