import {useLazyQuery} from '@apollo/client';
import {Select, Spin, Tag, Tooltip, notification} from 'antd';
import {debounce} from 'lodash';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Pressable,
  Text,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {PatientQueries, UserQueries} from '../../../services';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {Colors} from '../../../styles';
import {
  getAccountUUID,
  getUserData,
  getUserUUID,
  isActiveContact,
} from '../../../utils/commonUtils';
import {getContactTypeId} from '../../../utils/mlovUtils';
import {IAccountData} from '../../RightSideContainer/Contacts/TeamMembers/EditAccount/interface';
import {isAddMemberAllowed, isPatientDetailViewAllowed} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {ParticipantType} from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {
  IAutoCompleteData,
  IParticipantSearch,
} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import {DisplayText} from '../DisplayText/DisplayText';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import UserInfoItem from '../UserInfoItem/UserInfoItem';
import {getMatchedValue, getSearchQuery} from './PatientSearchUtils';
import './patientSearchAndSelectStyles.css';
import {getGroupsList, getLeadGroups} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {IMembersDatas} from '../../RightSideContainer/MembersManagement/RulesInterface';
import GroupInfoItem from './GroupInfoItem';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getInActiveContactError} from '../../../constants';
import {withMiniContactViewHOC} from '../../MiniContactViewHOC';
const {Option, OptGroup} = Select;

interface PatientAndGroupSearchSelect {
  isProspect?: boolean;
  defaultValue?: IParticipantSearch[];
  disableUserSearch?: boolean;
  disableLeadsSearch?: boolean;
  disablePatientSearch?: boolean;
  disableGroupSearch?: boolean;
  allowSingleSelectInCategory?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  showError?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  label?: string;
  patientsOptionLabel?: string;
  onChange: (participants: IParticipantSearch[], data?: any) => void;
  rightLabelTitle?: string;
  onRightLabelClick?: () => void;
  value?: any;
  allowAddNew?: boolean;
  addNewLabel?: string;
  border?: boolean;
  employerId?: string;
  contactData?: any;
  showEmail?: boolean;
  onFocus?: any;
  onBlur?: any;
  showPatientProfileLink?: boolean;
  onPatientProfileIconClick?: () => void;
  showTagInMultiline?: boolean;
  showPhoneNumber?: boolean;
  allowClear?: boolean;
  accountData?: IAccountData;
  showCurrentUserAtTop?: boolean;
  labelInfo?: string;
  labelColor?: string;
  offSuffixIcon?: boolean;
  isFromCreateSmsConversation?: boolean;
  poolSelected?: any;
  selectedPoolUsers?: any;
  defaultAssigneeInSelectedPool?: any;
  errors?: any;
  showErrorBorder?: boolean;
  searchPatientWithEmail?: boolean;
  closeSelect?: boolean;
  setCloseSelect?: () => void;
  isMultiSelect?: boolean;
  openContactInWindowOrOpenContactIdDrawer?:(contactId?:string)=>void;
}

const PatientAndGroupSearchSelect = (props: PatientAndGroupSearchSelect) => {
  const intl = useIntl();
  const accountId = getAccountUUID();
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [patientLoading, setPatientLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [patientData, setPatientData] = useState<IParticipantSearch[]>([]);
  const [staffData, setStaffData] = useState<IParticipantSearch[]>([]);
  const [leadData, setLeadData] = useState<IParticipantSearch[]>([]);
  const [groupData, setGroupData] = useState<IParticipantSearch[]>([]);
  const [leadGroupData, setLeadGroupData] = useState<IParticipantSearch[]>([]);
  const userUUID = getUserUUID();
  const currentUserData = getUserData();
  const {
    selectedPoolUsers,
    defaultAssigneeInSelectedPool,
    showCurrentUserAtTop,
  } = props;
  const customerContactType = getContactTypeId('CUSTOMER');
  const mlovData = useContext(CommonDataContext);

  const filterPoolUsers = (usersData: any[]) => {
    if (
      showCurrentUserAtTop ||
      selectedPoolUsers?.length ||
      defaultAssigneeInSelectedPool?.key
    ) {
      return (usersData || []).filter((user) => {
        if (defaultAssigneeInSelectedPool?.key === user?.value) {
          return false;
        }
        if (user?.value === userUUID && showCurrentUserAtTop) {
          return false;
        }
        const isUserInPoolList = (selectedPoolUsers || []).some(
          (poolUser: any) => {
            return poolUser?.key == user?.value;
          }
        );
        return isUserInPoolList ? false : true;
      });
    }
    return usersData;
  };

  const [getUsers] = useLazyQuery(UserQueries.SEARCH_USER_BY_TEXT, {
    onCompleted: (data: any) => {
      let usersData = [];
      if (data && data.users && data.users.length > 0) {
        usersData = data.users.map((user: any): IParticipantSearch => {
          return {
            label: user.name,
            value: user.uuid,
            key: user.uuid,
            email: user?.email,
            type: ParticipantType.staff,
            contactData: user,
          };
        });
      }
      const filterUsers = filterPoolUsers(usersData);
      setStaffData(filterUsers);
      setUserLoading(false);
      // setIsOpen(true);
    },
    onError: (error) => {

      setStaffData([]);
      setUserLoading(false);
      // setIsOpen(true);
    },
  });

  const [getContacts] = useLazyQuery(
    ContactsQueries.SEARCH_CONTACTS_WITH_GLOBAL_SEARCH,
    {
      onCompleted: (data: any) => {
        let contactsData = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts.map(
            (contact: any): IParticipantSearch => {
              return {
                label: contact.name,
                value: contact.uuid,
                key: contact.uuid,
                email: contact?.email,
                type: ParticipantType.leads,
                contactId: contact.uuid,
                contactData: contact,
              };
            }
          );
        }
        setLeadData(contactsData);
        setUserLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {

        setLeadData([]);
        setUserLoading(false);
        // setIsOpen(true);
      },
    }
  );

  const [getPatients] = useLazyQuery(
    PatientQueries.SEARCH_PATIENT_IN_GLOBAL_SEARCH,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const patientUpdatedData = getUpdatedPatientData(data);
        let updatedData = [];
        if (patientUpdatedData) {
          updatedData = patientUpdatedData.filter(
            (patient: IParticipantSearch) => {
              return patient.label;
            }
          );
        }
        setPatientData(updatedData);
        setPatientLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {

        setPatientData([]);
        setPatientLoading(false);
        // setIsOpen(true);
      },
    }
  );
  const getUpdatedPatientData = (data: any): any | undefined => {
    if (data && data.contacts && data.contacts.length > 0) {
      const patientList = data.contacts;
      return patientList.map((contact: any): IParticipantSearch => {
        return {
          label: contact.name,
          value: contact.uuid,
          key: contact.uuid,
          email: contact?.email,
          type: ParticipantType.patient,
          contactId: contact.id,
          contactData: contact,
        };
      });
    }
  };

  const searchPatient = async (searchString: string) => {
    setPatientData([]);
    setStaffData([]);
    setLeadData([]);
    setGroupData([]);
    setLeadGroupData([]);
    setSearchText(searchString);

    if (searchString.length > 0) {
      setPatientLoading(true);
      setIsOpen(true);
      const where = getSearchQuery({
        searchString,
        contactTypeId: customerContactType,
        employerId: props.employerId,
        isFromCreateSmsConversation: props.isFromCreateSmsConversation,
        searchPatientWithEmail: props.searchPatientWithEmail,
        isPatient: true,
      });
      if (where && !props.disablePatientSearch) {
        getPatients({
          variables: {
            limit: 20,
            ...where,
          },
        });
      } else {
        // if searchString is a date(birth date) but not a valid date or if it is a future date, then no need to run query
        setPatientData([]);
        setPatientLoading(false);
      }

      if (!props.disableUserSearch || !props.disableLeadsSearch) {
        setUserLoading(true);
        setIsOpen(true);
        if (!props.disableUserSearch) {
          getUsers({
            variables: {
              searchString: `%${searchString}%`,
              limit: 10,
              accountId: accountId,
            },
          });
        }
        if (!props.disableLeadsSearch) {
          const where = getSearchQuery({
            searchString,
            contactTypeId: customerContactType,
            employerId: props.employerId,
            isFromCreateSmsConversation: props.isFromCreateSmsConversation,
            searchPatientWithEmail: props.searchPatientWithEmail,
          });
          getContacts({
            variables: {
              limit: 10,
              ...where,
            },
          });
        }
      }
      if (!props.disableGroupSearch) {
        const rulesGroupListRes = await getGroupsList(
          accountId,
          10,
          searchString,
          0,
          undefined
        );
        const groupListData: IMembersDatas[] =
          rulesGroupListRes?.data?.data || [];
        if (groupListData?.length) {
          const formatGroupData = groupListData.map(
            (group: any): IParticipantSearch => {
              return {
                label: group.name,
                value: group.id,
                key: group.id,
                email: group.description,
                type: ParticipantType.group,
                contactId: group.id,
                contactData: group,
                count: group.count,
              };
            }
          );
          setGroupData(formatGroupData);
        }
      }
      if (!props?.disableGroupSearch && !props?.disableLeadsSearch) {
        const rulesLeadGroupListRes = await getLeadGroups({
          accountUuid: accountId,
          name: searchString,
          limit: 10,
          offset: 0,
        });
        const leadGroupListData: IMembersDatas[] =
          rulesLeadGroupListRes?.data?.data || [];
        if (leadGroupListData?.length) {
          const formatGroupData = leadGroupListData.map(
            (group: any): IParticipantSearch => {
              return {
                label: group.name,
                value: group.id,
                key: group.id,
                email: group.description,
                type: ParticipantType.leadGroup,
                contactId: group.id,
                contactData: group,
                count: group.count,
              };
            }
          );
          setLeadGroupData(formatGroupData);
        }
      }
    } else {
      setIsOpen(false);
    }
  };

  const getOptions = (): IAutoCompleteData[] => {
    const options: IAutoCompleteData[] = [];
    const employerStaff = staffData.filter((user: IParticipantSearch) => {
      return user?.contactData?.userRoles.some((item) => {
        return item?.userRole?.userRole?.code === 'EMPLOYER';
      });
    });
    const otherStaff = staffData.filter((user: IParticipantSearch) => {
      return !user?.contactData?.userRoles.some((item) => {
        return item?.userRole?.userRole?.code === 'EMPLOYER';
      });
    });
    if (selectedPoolUsers?.length) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {intl.formatMessage({id: 'usersInSelectedTaskPool'})}
          </Text>
        ),
        key: 'PoolUsers',
        options: selectedPoolUsers,
      });
    }
    if (staffData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            Staff
          </Text>
        ),
        key: 'Staffs',
        options: otherStaff,
      });
    }
    if (patientData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {props.patientsOptionLabel || 'Patients'}
          </Text>
        ),
        key: 'Patients',
        options: patientData,
      });
    }
    if (leadData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400" letterSpacing="sm">
            Prospects
          </Text>
        ),
        key: 'Prospects',
        options: leadData,
      });
    }
    if (employerStaff.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {'Employers'}
          </Text>
        ),
        key: 'Employers',
        options: employerStaff,
      });
    }
    if (groupData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {'Groups'}
          </Text>
        ),
        key: 'Groups',
        options: groupData,
      });
    }
    if(leadGroupData.length > 0) {
      options.push({
        label: (
          <Text fontSize="xs" color="gray.400">
            {'Lead Groups'}
          </Text>
        ),
        key: 'leadGroup',
        options: leadGroupData,
      });
    }
    return options;
  };

  const getTagClassName = (tagValue: any, tagLabel: string) => {
    const tagList =
      props.defaultValue &&
      props.defaultValue.filter((data: IParticipantSearch) => {
        return data.value === tagValue && data.label === tagLabel;
      });
    if (tagList && tagList.length > 0) {
      const tag = tagList[0];
      switch (tag.type) {
        case ParticipantType.patient:
          return 'patient-tag';
        case ParticipantType.staff:
          return 'staff-tag';
        case ParticipantType.leads:
          return 'contact-tag';
        default:
          return 'custom-tag';
          break;
      }
    }
    return 'custom-tag';
  };

  const getLabelName = (ids: string[]) => {
    if (props.isProspect) {
      const name = getOptions()?.map((group) => {
        return group.options
          .filter((item) => ids.includes(item.value))
          .map((item) =>
            item.label + (item.type === ParticipantType.group
              ? ` (${item?.count} ${
                  (item?.count || 0) > 1 ? 'Members' : 'Member'
                })`
              : '')
          );
      });
      if (name?.length) {
        return [name];
      }
    } else {
      const name = patientData
        .filter((item) => ids.includes(item.value))
        .map((item) => item.label);
      return name;
    }
  };

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const selectedTags =
      props.defaultValue &&
      props.defaultValue.filter(
        (participant) => participant.value === tagProps.value
      );
    const selectedTag =
      selectedTags && selectedTags.length > 0 ? selectedTags[0] : undefined;
   // const label = tagProps.value && getLabelName([tagProps.value])?.[0];

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={selectedTag?.isDisabled ? false : tagProps.closable}
        onClose={tagProps.onClose}
        style={{marginRight: 3}}
        className={getTagClassName(tagProps.value, tagProps.label)}
      >
        {tagProps.label}
      </Tag>
    );
  };

  const handleValueChange = (values: IParticipantSearch[]) => {
    const existingValue = props.defaultValue;
    let result: IParticipantSearch[] = [];

    values.forEach((selectedValue: IParticipantSearch) => {
      const matchedData = getMatchedValue(selectedValue.value, props.value);
      if (matchedData) {
        result.push(matchedData);
      } else {
        const matchedPatient = getMatchedValue(
          selectedValue.value,
          patientData
        );
        if (matchedPatient) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.patient
            );
          }
          result.push(matchedPatient);
        }

        const matchedStaff = getMatchedValue(selectedValue.value, staffData);
        if (matchedStaff) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.staff
            );
          }
          result.push(matchedStaff);
        }

        const matchedLead = getMatchedValue(selectedValue.value, leadData);
        if (matchedLead) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.leads
            );
          }
          result.push(matchedLead);
        }

        const matchedGroup = getMatchedValue(selectedValue.value, groupData);
        if (matchedGroup) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.group
            );
          }
          result.push(matchedGroup);
        }

        const matchedLeadGroup = getMatchedValue(selectedValue.value, leadGroupData);
        if (matchedLeadGroup) {
          if (props.allowSingleSelectInCategory) {
            result = result.filter(
              (value) => value.type !== ParticipantType.leadGroup
            );
          }
          result.push(matchedLeadGroup);
        }
      }
    });
    props.onChange(result);
  };

  const redirectToProfile = (contactId?: number | string, contactTypeId?: string)=> {
    if (!contactId) {
      return;
    }
    if (contactTypeId === customerContactType) {
      props?.openContactInWindowOrOpenContactIdDrawer?.(contactId.toString());
    } else {
      window.open(
        `/#/members/prospect/${contactId}`,
        '_blank'
      )
    }
  }

  const showRedirectIcon = ()=> {
    return isPatientDetailViewAllowed(mlovData.currentUserRoles,mlovData.userPermissions);
  }

  useEffect(() => {
    searchPatient('');

    if (
      props.value?.type === ParticipantType.patient &&
      props.value?.contactData
    ) {
      const patientUpdatedData = getUpdatedPatientData({
        contacts: [props.value.contactData],
      });
      let updatedData = [];
      if (patientUpdatedData) {
        updatedData = patientUpdatedData.filter(
          (patient: IParticipantSearch) => {
            return patient.label;
          }
        );
      }
      setPatientData(updatedData);
    }
  }, []);

  const renderPatientData = (patient: IParticipantSearch, index: number) => (
    <Option
      key={`${patient.key}_${index}`}
      value={patient.key}
      label={patient.label}
      email={patient?.email}
    >
      <MemberInfoListItem
        showDateOfBirth={true}
        contactData={patient?.contactData}
        isPatientSearchCard
        showPhoneNumber={props?.showPhoneNumber || false}
        showEmail={props?.showEmail || false}
        showTagging
      />
    </Option>
  );

  const isAllDataEmpty = () =>
    patientData.length === 0 &&
    staffData.length === 0 &&
    leadData.length === 0 &&
    selectedPoolUsers?.length === (0 || undefined) &&
    !userLoading &&
    !patientLoading;

  const isSearchTextEmpty = () => searchText === '';

  return (
    <>
      <View>
        <FormControl
          isRequired={props.isRequired}
          isInvalid={props.showError || props.isInvalid}
          isDisabled={props.isDisabled}
        >
          {props.label && (
            <HStack
              space={1}
              marginBottom={1}
              justifyContent={'space-between'}
              alignItems="center"
            >
              <FormControl.Label
                marginTop={props.rightLabelTitle ? 2 : 0}
                marginLeft={0}
              >
                <HStack>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: props.labelColor || Colors.Custom.Gray700,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                    textLocalId={props.label || ''}
                  />
                  {props?.labelInfo && props?.labelInfo?.length > 0 ? (
                    <Tooltip
                      title={props?.labelInfo}
                      placement={'top'}
                      destroyTooltipOnHide={true}
                    >
                      <Icon
                        mt={1}
                        mx={2}
                        as={AntIcon}
                        color={Colors.Custom.Gray500}
                        name="infocirlceo"
                        size="smMedium"
                      />
                    </Tooltip>
                  ) : null}
                </HStack>
              </FormControl.Label>
              {props.showPatientProfileLink && (
                <Pressable onPress={props.onPatientProfileIconClick}>
                  <HStack space={2} alignItems="center">
                    <Text fontSize="xs" color={Colors.secondary[500]}>
                      View Profile
                    </Text>
                    <Icon
                      as={FeatherIcon}
                      name="external-link"
                      size="xs"
                      color={Colors.secondary[500]}
                    />
                  </HStack>
                </Pressable>
              )}
              {isAddMemberAllowed() && props.rightLabelTitle && (
                <Button
                  style={{
                    height: 36,
                  }}
                  color={Colors.secondary['800']}
                  variant="link"
                  size={'lg'}
                  onPress={() => {
                    props.onRightLabelClick && props.onRightLabelClick();
                  }}
                >
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray700,
                      fontWeight: 500,
                      fontSize: 14,
                      textDecorationLine: 'underline',
                    }}
                    textLocalId={`+ ${
                      props.rightLabelTitle || props.addNewLabel
                    }`}
                  />
                </Button>
              )}
            </HStack>
          )}

          <Select
            status={
              props?.showErrorBorder && (props.isInvalid || props.showError)
                ? 'error'
                : undefined
            }
            style={{borderRadius: 8, height: 'auto'}}
            bordered={props?.border}
            dropdownStyle={{
              borderRadius: 8,
            }}
            showSearch={true}
            size={'large'}
            allowClear={props.allowClear === false ? props.allowClear : true}
            labelInValue={true}
            filterOption={false}
            disabled={props.isDisabled}
            value={props.value ? props.value : props.defaultValue}
            defaultValue={props.defaultValue}
            open={isOpen}
            suffixIcon={!props.offSuffixIcon}
            onFocus={() => {
              if (!props?.closeSelect) {
                setIsOpen(true);
                props?.onFocus?.();
              }
              props?.setCloseSelect && props?.setCloseSelect();
            }}
            onBlur={() => {
              setIsOpen(false);
              props?.onBlur?.();
            }}
            onSearch={debounce(searchPatient, 600)}
            onChange={(value: IParticipantSearch[], data: any) => {
              props?.isMultiSelect
                ? handleValueChange(value)
                : props.onChange(value);
              if (!props?.isMultiSelect) {
                setIsOpen(false);
              }
            }}
            placeholder={props.placeholder}
            loading={patientLoading || userLoading}
            mode={props?.isMultiSelect ? 'multiple' : undefined}
            tagRender={props?.isMultiSelect ? tagRender : undefined}
            notFoundContent={
              patientLoading || userLoading ? (
                <Spin size="small" />
              ) : (
                <View>
                  {(props.rightLabelTitle || props.allowAddNew) &&
                  searchText ? (
                    <Button
                      style={{
                        height: 36,
                      }}
                      color={Colors.secondary['800']}
                      variant="link"
                      size={'lg'}
                      onPress={() => {
                        props.onRightLabelClick && props.onRightLabelClick();
                      }}
                    >
                      {`+ ${props.rightLabelTitle || props.addNewLabel}`}
                    </Button>
                  ) : searchText ? (
                    <Text>No Data Found</Text>
                  ) : (
                    intl.formatMessage({id: 'NoDataSelect'})
                  )}
                </View>
              )
            }
          >
            {props.showCurrentUserAtTop && !userLoading && !patientLoading ? (
              <>
                <Option
                  key={userUUID}
                  label={currentUserData.name || ''}
                  value={userUUID}
                  email={currentUserData.email}
                  type={ParticipantType.staff}
                >
                  <UserInfoItem
                    option={{
                      key: userUUID,
                      label: currentUserData.name || '',
                      contactData: currentUserData as any,
                      value: userUUID,
                      type: ParticipantType.staff,
                    }}
                    contactData={currentUserData}
                  />
                </Option>
                {isAllDataEmpty() && (
                  <Option disabled>
                    <VStack>
                      <Text color={Colors.Custom.Gray400} fontSize={12} p={2}>
                        {isSearchTextEmpty()
                          ? 'Select “Myself” or enter staff / member’s name to start searching'
                          : 'No Data Found'}
                      </Text>
                    </VStack>
                  </Option>
                )}
              </>
            ) : null}

            {defaultAssigneeInSelectedPool && (
              <OptGroup
                label={intl.formatMessage({
                  id: 'defaultAssigneeInSelectedTaskPool',
                })}
              >
                <Option
                  key={defaultAssigneeInSelectedPool?.key}
                  label={defaultAssigneeInSelectedPool?.label ?? ''}
                  value={defaultAssigneeInSelectedPool?.key}
                  type={ParticipantType.staff}
                >
                  <UserInfoItem
                    option={defaultAssigneeInSelectedPool}
                    contactData={defaultAssigneeInSelectedPool?.contactData}
                  />
                </Option>
              </OptGroup>
            )}

            {props.isProspect
              ? getOptions().map((group) => (
                  <OptGroup key={group.key} label={group.label}>
                    {group.options.map((option, index) => (
                      <Option
                        key={`${option.key}_${index}`}
                        value={option.key}
                        label={option.label}
                        type={option.type}
                        email={option?.email}
                        disabled= {((group.key === 'Groups' || group.key === 'leadGroup') && !option?.count) ? true : false}
                      >
                        {group.key === 'Prospects' ||
                        group.key === 'Patients' ? (
                          <MemberInfoListItem
                            showDateOfBirth={true}
                            showEmail={props?.showEmail || false}
                            showPhoneNumber={props?.showPhoneNumber || false}
                            contactData={option?.contactData}
                            isPatientSearchCard
                            showTagging
                            showTagInMultiline={props?.showTagInMultiline}
                            showRedirectIcon={showRedirectIcon()}
                            onRedirectToProfile= {()=> {
                              const isActive = isActiveContact(option?.contactData);
                              if (!isActive) {
                                const message = getInActiveContactError(option?.contactData);
                                notification.error({
                                  message,
                                });
                                return;
                              }
                              const contactId = option?.contactData?.id || option?.contactId;
                              const contactTypeId = option?.contactData?.contactType?.contactType?.id;
                              redirectToProfile(contactId, contactTypeId);
                            }}
                          />
                        ) : group.key === 'Groups' || group.key === 'leadGroup' ? (
                          <GroupInfoItem option={option} />
                        ) : (
                          <UserInfoItem
                            option={option}
                            contactData={option.contactData}
                          />
                        )}
                      </Option>
                    ))}
                  </OptGroup>
                ))
              : patientData.map((patient, index) =>
                  renderPatientData(patient, index)
                )}
          </Select>
          <View mt={2}>
            {props?.errors?.patient && (
              <Text color={'error.500'} fontWeight="500" fontSize={'xs'}>
                {props?.errors?.patient}
              </Text>
            )}
          </View>
        </FormControl>
      </View>
    </>
  );
};

export default withMiniContactViewHOC(PatientAndGroupSearchSelect);
