import { Text, Dimensions } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Drawer, Radio, Skeleton, notification } from 'antd'
import { useMediaQuery, View } from 'native-base';
import { BUTTON_TYPE, DISPLAY_DATE_FORMAT, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY, SMALL_WINDOW_1700 } from '../../../constants';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { Colors } from '../../../styles';
import Stack from '../LayoutComponents/Stack';
import PatientSearchAndSelect from '../PatientSearch/PatientSearchAndSelect';
import { ModalActionDatePicker } from '../ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionInput } from '../ModalActionCommonComponent/ModalActionInput';
import { IUserPatientSearchItem } from '../../TaskModule/TaskInterfaces';
import { getMlovCodeFromId, getMlovIdFromCode, getMlovListByCategory, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { ADD_CARE_TEAM, ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES, GET_CARE_TEAM, GET_CARE_TEAM_MEMBER_TYPES_BY_TYPE_ID } from '../../../services/CareTeam/CareTeamQueries';
import { CARE_PROGRAM_CONSENT_TYPE, CARE_PROGRAM_STEP_CODES, CARE_PROGRAM_STEP_TYPE, CARE_PROGRAM_SUB_STEP_CODES, CARE_PROGRAM_TYPE_CODES, CARE_TEAM, CARE_TEAM_MEMBER_TYPE, USER_POOL_CODES, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { SEARCH_USERS_BY_ROLE_CODES } from '../../../services/User/UserQueries';
import { getCurrentEHR } from '../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import { EHRName } from '../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import { getMomentObj } from '../../../utils/DateUtils';
import { useIntl } from 'react-intl';
import { isPastDay } from '../../../utils/DateUtils';
import { Moment } from 'moment';

interface ICareProgramConsentProps {
  isVisible: boolean;
  contactId: string;
  contactCareProgramId: string;
  onClose: (action: boolean) => void;
  programName?: string;
  isInlineView?: boolean;
}

const CareProgramConsent = (props: ICareProgramConsentProps) => {
  // Constants
  const { contactId, isVisible, contactCareProgramId, onClose, isInlineView } = props;
  const contextData = useContext(CommonDataContext);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const currentEHR = getCurrentEHR();
  const isElation = currentEHR === EHRName.ELATION;
  const initialConsentTexts = {
    CCM: [
      'I understand that my PCP is willing to provide me CCM services covered under Medicare per calendar month.',
      'I understand these chronic care management services are subject to the usual Medicare deductible and coinsurance applied to physician services.',
      'I also understand that I can revoke this agreement at any time (effective at the end of calendar month) and can choose, instead, to receive these services from another health care professional after the calendar month in which I revoke this agreement.',
      'I understand that Medicare will pay one physician or health care professional to furnish chronic care management services within a given calendar month.',
    ],
    TCM: [
      'I understand that my PCP is willing to provide me TCM services covered under Medicare per calendar month.',
      'I understand these transitional care management services are subject to the usual Medicare deductible and coinsurance applied to physician services.',
      'I also understand that I can revoke this agreement at any time (effective at the end of calendar month) and can choose, instead, to receive these services from another health care professional after the calendar month in which I revoke this agreement.',
      'I understand that Medicare will pay one physician or health care professional to furnish transitional care management services within a given calendar month.',
    ],
    ECM: [
      'I understand that ECM is an opt-in benefit and that my participation is voluntary.',
      'I acknowledge that I have been informed of the Provider\'s expectations of me as a participant in ECM.',
      'I understand the expectations for my care from the ECM Provider and agree to adhere to the guidelines and recommendations provided to me.',
      'I acknowledge that I may opt into ECM verbally, but I understand that all verbal opt-ins must be documented by the ECM Provider.',
      'I consent to the ECM Provider documenting and maintaining records of my opt-in to ECM.',
      'I understand that Managed Care Plans (MCPs) may request evidence of my opt-in to ECM, and I agree to provide such evidence if requested.',
      'I reserve the right to withdraw from ECM at any time, and I understand that I can do so by informing the ECM Provider in writing.'
    ]
  };
  const consentTypeList = getMlovListByCategory(MLOV_CATEGORY.CONTACT_CARE_PROGRAM_CONSENT_TYPE);
  const declineConsentTypeId = getMlovIdFromCode(consentTypeList, CARE_PROGRAM_CONSENT_TYPE.DECLINED);
  const careProgramTypeList = getMlovListByCategory(MLOV_CATEGORY.CARE_PROGRAM_TYPES);
  const careTeamList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PRIMARY_CARE_TEAM) || [];
  const userPoolTypeList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_TEAM_USER_POOL) || [];
  const careTeamUserPoolType = getMlovIdFromCode(userPoolTypeList, USER_POOL_CODES.CARE_TEAM);
  const careTeamMemberTypesList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE) || [];
  const billingPractitionerMemberTypeId = getMlovIdFromCode(careTeamMemberTypesList, CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER);
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const careProgramStepList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP) || [];
  const careProgramSubStepList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_SUB_STEP) || [];
  const consentStepTypeId = getMlovIdFromCode(careProgramStepList, CARE_PROGRAM_STEP_CODES.CONSENT);
  const consentSubStepTypeId = getMlovIdFromCode(careProgramSubStepList, CARE_PROGRAM_SUB_STEP_CODES.CONSENT_RECEIVED);
  const consentSubStepDeclineTypeId = getMlovIdFromCode(careProgramSubStepList, CARE_PROGRAM_SUB_STEP_CODES.CONSENT_DECLINED);
  const enrollStepTypeId = getMlovIdFromCode(careProgramStepList, CARE_PROGRAM_STEP_CODES.ENROLLMENT);
  const enrollSubStepTypeId = getMlovIdFromCode(careProgramSubStepList, CARE_PROGRAM_SUB_STEP_CODES.ENROLLMENT_COMPLETE);
  const intl = useIntl();

  // States
  const [componentState, setComponentState] = useState<{
    showErrors: boolean;
    initialConsents: {text: string, value: boolean}[];
    consentTypeId?: string;
    consentAlreadyGiven: boolean;
    billingPractitioner?: IUserPatientSearchItem;
    enrollmentDate: string;
    notes?: string;
    careProgramTypeId?: string;
    contactCareProgramSteps: {id: string; careProgramStepTypeId: string; careProgramSubSteps: {id: string; careProgramSubStepTypeId: string}[]}[];
    isAlreadyDeclinedInApi: boolean
  }>({
    showErrors: false,
    initialConsents: [],
    consentTypeId: undefined,
    consentAlreadyGiven: false,
    billingPractitioner: undefined,
    enrollmentDate: getMomentObj(new Date()).toISOString(),
    notes: undefined,
    careProgramTypeId: undefined,
    contactCareProgramSteps: [],
    isAlreadyDeclinedInApi: false
  });

  const [careTeamData, setCareTeamData] = useState<{
    id: string;
    userPoolId: string;
    allUsers: IUserPatientSearchItem[];
    userLoading: boolean;
    consentLoading: boolean;
    enrollmentLoading: boolean;
    consentLogId: string;
    careTeamMemberPrimaryId: string;
    userPoolUsers: {id: string, userId: string}[];
  }>({
    id: '',
    userPoolId: '',
    allUsers: [],
    userLoading: false,
    consentLoading: false,
    enrollmentLoading: false,
    consentLogId: '',
    careTeamMemberPrimaryId: '',
    userPoolUsers: [],
  });

  // APIs
  const [getCareProgramConsent] = useLazyQuery(ContactCareProgram.GET_CONTACT_CARE_PROGRAM_CONSENT_BY_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getContactCareTeam] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getCareTeamMemberTypes] = useLazyQuery(GET_CARE_TEAM_MEMBER_TYPES_BY_TYPE_ID, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    fetchPolicy: 'no-cache',
  });

  const [getUsersByRoles] = useLazyQuery(SEARCH_USERS_BY_ROLE_CODES, {
    fetchPolicy: 'no-cache',
  });

  const [addOrUpdateCareTeamMemberType] = useMutation(ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT }
  });

  const [addCareTeam] = useMutation(ADD_CARE_TEAM, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT }
  });

  const [updateContactCareProgramStatus] = useMutation(ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STEP_STATUS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  // Life cycle methods
  useEffect(() => {
    if (contactId && contactCareProgramId) {
      fetchInitialData();
    }
    // fetch billing practitioner from contactId
    // fetch consent from contactId and contactCareProgramId
  }, [contactId, contactCareProgramId]);

  // Other methods
  const fetchInitialData = async () => {
    // Fetch care team data
    fetchCareTeamAndBillingPractitioner();
    // Fetch contact care program consent
    fetchConsent();
  }

  const fetchCareTeamAndBillingPractitioner = async () => {
    try {
      setCareTeamData((prev) => ({...prev, userLoading: true }));
      const [careTeamResponse, userResponse] = await Promise.all([
        getContactCareTeam({
          variables: {
            contactId: contactId,
          },
        }),
        getUsersByRoles({
          variables: {
            where: getSearchWhereCondition(),
          }
        })
      ]);

      const users = userResponse.data?.users || [];
      const applicableUsers: IUserPatientSearchItem[] = users.map((item: any) => {
        return {
          key: item.uuid,
          value: item.uuid,
          label: item.name,
          type: ParticipantType.staff,
          details: item,
        }
      });
      setCareTeamData((prev) => ({...prev, allUsers: applicableUsers }));

      const careTeamId = careTeamResponse.data?.careTeams?.[0]?.id;
      const userPoolId = careTeamResponse.data?.careTeams?.[0]?.userPool?.id;
      const userPoolUsers: {id: string, userId: string}[] = careTeamResponse.data?.careTeams?.[0]?.userPool?.userPoolUsers || [];

      if (careTeamId) {
        setCareTeamData((prev) => ({...prev, id: careTeamId, userPoolId: userPoolId, userPoolUsers: userPoolUsers }));

        const memberTypeResponse = await getCareTeamMemberTypes({
          variables: {
            careTeamId: careTeamId,
            careTeamMemberTypeId: billingPractitionerMemberTypeId
          },
        });
        const billingPractitionerUserId = memberTypeResponse.data?.care_team_member_types?.[0]?.userId;
        const careTeamMemberId = memberTypeResponse.data?.care_team_member_types?.[0]?.id;
        if (billingPractitionerUserId) {
          setComponentState((prev) => ({...prev, billingPractitioner: applicableUsers.find(item => item.key === billingPractitionerUserId ) }));
        }
        setCareTeamData((prev) => ({...prev, careTeamMemberPrimaryId: careTeamMemberId }));
      }
      setCareTeamData((prev) => ({...prev, userLoading: false }));
    } catch (error) {

      setCareTeamData((prev) => ({...prev, userLoading: false }));
    }
  }

  const fetchConsent = async () => {
    try {
      setCareTeamData((prev) => ({...prev, consentLoading: true }));
      const response = await getCareProgramConsent({
        variables: {
          contactCareProgramId: contactCareProgramId,
        }
      });
      const careProgram = response.data?.contactCarePrograms?.[0];
      const consentLog = careProgram?.consentLog;
      const careProgramTypeId = careProgram?.payerCareProgram?.careProgramTypeId;
      if (careProgramTypeId) {
        setInitialConsentData(careProgramTypeId);
      }
      setComponentState((prev) => ({
        ...prev,
        consentAlreadyGiven: consentLog?.hasConsent,
        consentTypeId: consentLog?.typeId,
        contactCareProgramSteps: careProgram?.contactCareProgramSteps || [],
        careProgramTypeId: careProgramTypeId,
        isAlreadyDeclinedInApi: consentLog?.typeId === declineConsentTypeId
      }));
      setCareTeamData((prev) => ({...prev, consentLoading: false, consentLogId: consentLog?.id }));
    } catch (error) {

      setCareTeamData((prev) => ({...prev, consentLoading: false }));
    }
  }

  const getSearchWhereCondition = () => {
    const where: any = {
      accountUsers: {},
    };
    if (isElation) {
      where.accountUsers.externalUserId = {_is_null: false};
    }
    where.userRoles = {userRole: {userRole: {code: {_in: [USER_ROLE_CODES.PHYSICIAN]}}}};
    where._not = {
      userRoles: {
        userRole: {
          userRole: {
            code: {
              _in: [
                USER_ROLE_CODES.WORFLOW_USER,
                USER_ROLE_CODES.CUSTOMER_SUCCESS,
              ],
            },
          },
        },
      },
    };
    where.accountUsers.isActive = {_eq: true};
    return where;
  };

  const onDeclineConsent = async () => {
    try {
      setCareTeamData((prev) => ({...prev, enrollmentLoading: true}));
      await addOrUpdateConsent();
      setCareTeamData((prev) => ({...prev, enrollmentLoading: false}));
      onClose(true);
    } catch (err) {

      setCareTeamData((prev) => ({...prev, enrollmentLoading: false}));
      notification.error({
        message: 'Something went wrong',
        duration: 3.0,
        placement: 'top',
      });
    }
  }

  const onEnroll = async () => {
    try {
      if (!isValidDataAvailable()) {
        return;
      }
      setCareTeamData((prev) => ({...prev, enrollmentLoading: true }));
      if (!componentState.consentAlreadyGiven) {
        await addOrUpdateConsent();
      }
      await addOrUpdateBillingPractitioner();
      await addOrUpdateEnrollment();
      setCareTeamData((prev) => ({...prev, enrollmentLoading: false }));
      onClose(true);
    } catch (error) {

      setCareTeamData((prev) => ({...prev, enrollmentLoading: false }));
      notification.error({
        message: 'Something went wrong',
        duration: 3.0,
        placement: 'top',
      })
    }
  }

  const addOrUpdateEnrollment = async () => {
    const step = componentState.contactCareProgramSteps.find((item) => item.careProgramStepTypeId === enrollStepTypeId);
    const subStep = step?.careProgramSubSteps.find((item) => item.careProgramSubStepTypeId === enrollSubStepTypeId);

    await updateContactCareProgramStatus({
      variables: {
        params: {
          careProgramStepId: step?.id,
          careProgramSubStepId: subStep?.id,
          contactCareProgramId: contactCareProgramId,
          payload: {
            stepCode: CARE_PROGRAM_STEP_TYPE.ENROLLMENT,
            enrollmentDateTime: componentState.enrollmentDate,
            enrollmentBy: componentState.billingPractitioner?.key,
            notes: componentState.notes,
          }
        }
      },
    })
  }

  const addOrUpdateBillingPractitioner = async () => {
    let careTeamId = careTeamData.id;
    const primaryId = careTeamData.userPoolUsers.find(item => item.userId === componentState.billingPractitioner?.key)?.id;
    const careTeamResponse = await addCareTeam({
      variables: {
        params: {
          ...(!!careTeamId && {id: careTeamId}),
          contactId: contactId,
          careTeamTypeId: careTeamTypeId,
          userPool: {
            on_conflict: {
              constraint: 'user_pool_pkey',
              update_columns: 'isDeleted',
            },
            data: {
              ...(!!careTeamData.userPoolId && {
                id: careTeamData.userPoolId,
              }),
              typeId: careTeamUserPoolType,
              isDeleted: false,
              ...(!primaryId && {userPoolUsers: {
                data: { userId: componentState.billingPractitioner?.key },
              }}),
            },
          },
        },
      },
    });
    careTeamId = careTeamResponse.data?.createCareTeam?.id || '';

    // Add or update billing practitioner
    await addOrUpdateCareTeamMemberType({
      variables: {
        params: {
          ...(careTeamData.careTeamMemberPrimaryId && { id: careTeamData.careTeamMemberPrimaryId }),
          userId: componentState.billingPractitioner?.key,
          careTeamId: careTeamId,
          careTeamMemberTypeId: billingPractitionerMemberTypeId,
        },
      }
    });
  }
  const isConsentStatusSelectedAsDeclined = componentState.consentTypeId === declineConsentTypeId;
  const addOrUpdateConsent = async () => {
    const substepTypeId = isConsentStatusSelectedAsDeclined ? consentSubStepDeclineTypeId : consentSubStepTypeId;
    const step = componentState.contactCareProgramSteps.find((item) => item.careProgramStepTypeId === consentStepTypeId);
    const subStep = step?.careProgramSubSteps.find((item) => item.careProgramSubStepTypeId === substepTypeId);

    if (contactCareProgramId) {
      const response = await updateContactCareProgramStatus({
        variables: {
          params: {
            careProgramStepId: step?.id,
            careProgramSubStepId: subStep?.id,
            contactCareProgramId: contactCareProgramId,
            payload: {
              stepCode: CARE_PROGRAM_STEP_CODES.CONSENT,
              hasConsent: substepTypeId === consentSubStepTypeId,
              consentTypeId: componentState.consentTypeId,
            }
          }
        },
      });

      if (!response.data?.updateContactCareProgramStatus?.id) {
      }
    } else {
    }
  }

  const renderInitialConsent = () => {
    const primaryConsentText = getInitialConsentPrimaryText();
    return (
      <Stack direction="column" space={8}>
        <Text style={{ fontSize: 16, fontWeight: '600' }}>{intl.formatMessage({id: 'consentMessageCheck'})}</Text>
        <Checkbox
          disabled={props?.isInlineView}
          checked={props?.isInlineView ? true : !componentState.initialConsents.some(item => !item.value)}
          onChange={(event) => {
            setComponentState((prev) => {
              const initialConsents = prev.initialConsents;
              initialConsents.forEach(item => item.value = event.target.checked);
              return {
                ...prev,
                initialConsents,
              }
            })
          }}
        >
          <Text style={{ fontSize: 14, fontWeight: '400', color: Colors.Custom.Gray500 }}>{primaryConsentText}</Text>
        </Checkbox>
        <Stack direction="column" space={8} style={{ marginLeft: 8 }}>
          {componentState.initialConsents.map((consentItem, index) => {
            return (
            <Checkbox
              disabled={props?.isInlineView}
              key={`InitialConsent${index}`}
              checked={props?.isInlineView ? true : consentItem.value}
              onChange={(event) => {
                setComponentState((prev) => {
                  const initialConsents = prev.initialConsents;
                  initialConsents[index].value = event.target.checked;
                  return {
                    ...prev,
                    initialConsents,
                  }
                })
              }}>
              <Text style={{ fontSize: 14, fontWeight: '400', color: Colors.Custom.Gray500 }}>{consentItem.text}</Text>
            </Checkbox>);
          })}
        </Stack>
      </Stack>
    );
  }

  const renderConsentType = () => {
    return (
      <Stack direction="column" space={8}>
        <Text style={{ fontSize: 16, fontWeight: '600' }}>{intl.formatMessage({id: 'consentMessage'})}</Text>
        <Stack direction="column" space={8} style={{ marginLeft: 8 }}>
          {consentTypeList.map((consentItem, index) => {
            return (
              <Radio
                key={`InitialConsent${index}`}
                checked={componentState.consentTypeId === consentItem.id}
                disabled={componentState.consentAlreadyGiven}
                onChange={(event) => {
                  const isSelected = componentState.consentTypeId === consentItem.id;
                  if (event.target.checked) {
                    setComponentState((prev) => ({...prev, consentTypeId: consentItem.id}));
                  } else if (isSelected) {
                    setComponentState((prev) => ({...prev, consentTypeId: undefined}));
                  }
                }}>
                <Text disabled={props?.isInlineView} style={{ fontSize: 14, fontWeight: '400', color: Colors.Custom.Gray500 }}>{consentItem.value}</Text>
              </Radio>
            );
          })}
        </Stack>
      </Stack>
    );
  }

  const renderBillingPractitioner = () => {
    return (
      <Stack direction="column" space={8}>
        <Text style={{ fontSize: 16, fontWeight: '600' }}>Updated By (Billing Practitioner)</Text>
        <ModalActionAntSelect
          allowClear={false}
          leftMargin={'0'}
          showSearch={true}
          labelInValue={true}
          disabled={props?.isInlineView}
          filterOption={(input: string, option: any) => {
            return (option?.children || '').toLowerCase().includes(input.toLowerCase());
          }}
          value={componentState.billingPractitioner?.key}
          isRequired={false}
          label={''}
          placeholder={'Select Staff'}
          onChange={(data: any) => {
            setComponentState((prev) => ({
              ...prev,
              billingPractitioner: data,
            }));
          }}
          data={careTeamData.allUsers}
          optionProps={{
            key: 'key',
            value: 'value',
            label: 'label',
          }}
          extraStyle={{ flex: 1 }}
        />
      </Stack>
    );
  }

  const renderEnrollmentDate = () => {
    return (
      <Stack direction="column" space={8}>
        <Text style={{ fontSize: 16, fontWeight: '600' }}>Enrollment Date</Text>
        <ModalActionDatePicker
          disablePicker={props?.isInlineView}
          isRequired={true}
          label={''}
          leftMargin={'0'}
          format={DISPLAY_DATE_FORMAT}
          value={componentState.enrollmentDate ? getMomentObj(componentState.enrollmentDate) : undefined}
          onChange={(data: any) => {
            setComponentState(prev => ({
              ...prev,
              enrollmentDate: data.toISOString()
            }));
          }}
          customStyle={{ flex: 1 }}
          allowClear={false}
          disabledDate={(current: moment.Moment | undefined) => {
            return current ? isPastDay(current) : false;
          }}
        />
      </Stack>
    );
  }

  const renderNotes = () => {
    return (
      <Stack direction="column" space={6}>
        <Text style={{ fontSize: 16, fontWeight: '600' }}>Notes</Text>
        <ModalActionInput
          isDisabled={props?.isInlineView}
          label={''}
          leftMargin={'0'}
          isRequired={false}
          isInvalid={false}
          value={componentState.notes}
          onChangeText={(text: any) => {
            setComponentState((prev) => ({ ...prev, notes: text }));
          }}
          extraStyle={{ flex: 1, Height: 88 }}
        />
      </Stack>
    );
  }

  const isValidDataAvailable = () => {
    if (isConsentStatusSelectedAsDeclined) {
      return true;
    }
    return !componentState.initialConsents.some(item => !item.value) &&
    componentState.consentTypeId &&
    componentState.billingPractitioner?.key &&
    componentState.enrollmentDate;
  }

  const setInitialConsentData = (careProgramTypeId: string) => {
    const code = getMlovCodeFromId(careProgramTypeList, careProgramTypeId);
    if (code) {
      let consentList: string[] = [];
      switch (code) {
        case CARE_PROGRAM_TYPE_CODES.CCM:
          consentList = initialConsentTexts.CCM;
          break;
        case CARE_PROGRAM_TYPE_CODES.TCM:
          consentList = initialConsentTexts.TCM;
          break;
        case CARE_PROGRAM_TYPE_CODES.ECM:
          consentList = initialConsentTexts.ECM;
          break;
      }

      if (consentList.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            initialConsents: consentList.map((item) => {
              return {
                text: item,
                value: false,
              }
            })
          }
        })
      }
    }
  }

  const getInitialConsentPrimaryText = () => {
    const code = getMlovCodeFromId(careProgramTypeList, componentState.careProgramTypeId || '');
    switch (code) {
      case CARE_PROGRAM_TYPE_CODES.CCM: return 'Inform patient that:';
      case CARE_PROGRAM_TYPE_CODES.TCM: return 'Inform patient that:';
      case CARE_PROGRAM_TYPE_CODES.ECM: return 'By opting into ECM, I agree to the following:';
      default: return 'Inform patient that:';
    }
  }

  const loading = careTeamData.consentLoading || careTeamData.userLoading;
  const drawerTitle = props?.programName ? `${props.programName} Consent` : 'Consent';


  const getConsentDetailView = (): JSX.Element => {
    return (
      <>
        {loading && <Skeleton active />}
        {!loading && (
          <Stack direction="column" space={24}>
            {renderInitialConsent()}
            {renderConsentType()}
            {!isConsentStatusSelectedAsDeclined && (
              <>
                {renderBillingPractitioner()}
                {renderEnrollmentDate()}
              </>
            )}
            {renderNotes()}
          </Stack>
        )}
      </>
    );
  }

  return (
      (
      isInlineView ?
        <View>
          {getConsentDetailView()}
        </View>
      :
      <Drawer
        destroyOnClose
        placement="right"
        open={isVisible}
        closable
        width={
          isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
        }
        onClose={() => {onClose(false)}}
        title={
          <ModalActionTitle
            title={drawerTitle}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                isDisabled: loading || careTeamData.enrollmentLoading,
                onClick: () => {
                  onClose(false);
                },
              },
              {
                show: true,
                id: 2,
                btnText: isConsentStatusSelectedAsDeclined ? 'decline' : 'enroll',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isDisabled: loading || !isValidDataAvailable() || (isConsentStatusSelectedAsDeclined && componentState.isAlreadyDeclinedInApi),
                onClick: isConsentStatusSelectedAsDeclined ? onDeclineConsent : onEnroll,
                isLoading: careTeamData.enrollmentLoading
              },
            ]}
          />
        }
      >
        {getConsentDetailView()}
      </Drawer>
    )
  )
}

export default CareProgramConsent
