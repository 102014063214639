import { Drawer, Image } from 'antd';
import { View } from 'native-base';
import React, { useContext } from 'react';
import { Dimensions } from 'react-native';
import ReactPlayer from 'react-player';
import { BUTTON_TYPE } from '../../../constants';
import { Colors } from '../../../styles';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { getMediaTypeFromMimeType, MEDIA_TYPES } from './DocumentViewerHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';

interface IProps {
  isOpen?: boolean;
  drawerWidth?: string;

  fileName: string;
  fileType: string;
  fileUrl: string;

  onClose: () => void;
  mediaPlayerHeight?: string;
  mediaPlayerWidth?: string;
}

const MODAL_ACTION_TITLE_HEIGHT = 60;

export function DocumentViewer(props: IProps) {
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData?.sidecarContext?.isSidecar;
  const { height: windowHeight } = Dimensions.get('window');
  const documentViewerContainerHeight = windowHeight - MODAL_ACTION_TITLE_HEIGHT - 100;

  const mediaType = getMediaTypeFromMimeType(props.fileType || '');
  const canShowDocument = !!(mediaType && props.fileUrl);

  return (
    <>
      {
        mediaType !== MEDIA_TYPES.IMAGE &&
        <Drawer
          open={props.isOpen || false}
          width={isSideCarContext ? '100%' : (props.drawerWidth || '70vw')}
          onClose={props.onClose}
          closable={false}
          title={
            <>
              <ModalActionTitle
                title={props.fileName}
                titleColor={''}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: isSideCarContext ? 'back' : 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: props.onClose,
                  }
                ]}
              />
            </>
          }
        >
          <View
            alignItems={'center'}
            justifyContent={'center'}
            height={documentViewerContainerHeight}
            width={'full'}
          >
            {
              canShowDocument &&
              (mediaType === MEDIA_TYPES.VIDEO || mediaType === MEDIA_TYPES.AUDIO) &&
              <ReactPlayer
                width={props?.mediaPlayerWidth || 'full'}
                height={props?.mediaPlayerHeight|| documentViewerContainerHeight}
                controls={true}
                url={props.fileUrl}
              />
            }
            {
              canShowDocument &&
              (mediaType === MEDIA_TYPES.PDF || mediaType === MEDIA_TYPES.WORD_DOC) &&
              <iframe width={'100%'} height={documentViewerContainerHeight} src={props.fileUrl}></iframe>
            }
          </View>
        </Drawer>
      }

      {
        canShowDocument &&
        mediaType === MEDIA_TYPES.IMAGE &&
        <Image
          preview={{
            closeIcon: false,
            visible: true,
            src: props.fileUrl,
            scaleStep: 0.2,
            onVisibleChange: (value) => {
              props.onClose();
            },
          }}
        />
      }
    </>
  );
}
