export const getMessage = (defaultAssignee?: any, isSubTaskAssigneeChange?: boolean, isAssigneeInPool?: boolean, taskAssigneeChangeToCurrentUser?: boolean, subtaskAssigneeChangeToCurrentUser?: boolean) => {
    if (defaultAssignee) {
        if (isSubTaskAssigneeChange) {
            if (!isAssigneeInPool) {
                return 'One or more task assignees do not belong to selected task pool. Going ahead will change these assignees to default assignee of task pool. Proceed?';
            }
            else {
                return 'One or more subtask assignees do not belong to selected task pool. Going ahead will change these assignees to default assignee of task pool. Proceed?'
            }
        }
        else {
            return 'Task assignee does not belong to selected task pool. Going ahead will change this assignee to default assignee of task pool. Proceed?'
        }
    }
    else {
        const basicMessageText = 'Selected task pool does not have a default assignee. Switching task pool will reset the current'
        if (taskAssigneeChangeToCurrentUser) {
            if (subtaskAssigneeChangeToCurrentUser) {
                return `${basicMessageText} task assignees. Proceed?`;
            }
            else {
                return 'Task assignee does not belong to selected task pool. Going ahead will reset the assignee. Proceed?'
            }
        }
        else if (subtaskAssigneeChangeToCurrentUser) {
            return `${basicMessageText} subtask assignee/s. Proceed?`;
        }
        if (isSubTaskAssigneeChange) {
            if (!isAssigneeInPool) {
                return `${basicMessageText} task assignees. Proceed?`;
            }
            else {
                return `${basicMessageText} subtask assignee/s. Proceed?`;
            }
        }
        else {
            return `${basicMessageText} assignee. Proceed?`;
        }
    }
}