import moment from 'moment';
import {Box, HStack, Text, VStack} from 'native-base';
import React from 'react';
import {getMessageDisplayTimeAgo} from '../../../utils/DateUtils';
import {styles} from './ActionMsgStyle';
import {IActionMsgProps} from './interface';
import { TestIdentifiers, testID } from '../../../testUtils';

const ActionMsg = (props: IActionMsgProps) => {
  const {msgString, dateStr} = props;
  return (
    <Box
      style={{
        alignItems: 'center',
      }}
      {...testID(TestIdentifiers.actionMsgHeader)}
    >
      <HStack style={styles.actionMsgBox}>
        <VStack justifyContent={'flex-end'}>
          <HStack style={{flexDirection: 'column', alignItems: 'center'}}>
            <Text {...testID(TestIdentifiers.actionMsgHeaderString)} mr={1} size={'xsMedium'} color={'coolGray.500'} textAlign={'center'}>
              {msgString}
            </Text>
            <Text {...testID(TestIdentifiers.actionMsgHeaderDate)} mr={1} size={'exsMedium'} color={'coolGray.400'}>
              {getMessageDisplayTimeAgo(moment(dateStr).format())}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ActionMsg;
