import {Drawer, Table, Tooltip, Popover, Select, Divider} from 'antd';
import {Text, HStack, Pressable, VStack,View, useToast, Spacer, Center} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions, ScrollView} from 'react-native';
import {BUTTON_TYPE, DATE_FORMATS, RIGHT_SIDE_CONTAINER_CODE} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles/Colors';
import {getCurrentTimeZone, getDateObject, getDateStrFromFormat, getDateStrFromFormatWithTimezone, getFormattedDate, isDateBetweenRange} from '../../../../../utils/DateUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IMediaRecord, IMediaReport, IObservationResultTable, IPendingReportDetails, IReportResultListDetails, IUpdateReportDetail} from '../OrdersInterface';
import '../../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/tableViewCustomStyle.css';
import Feather from 'react-native-vector-icons/Feather';
import { MediaReportView } from '../MediaReportView';
import { useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { getMlovListFromCategory, getMlovObjectFromCode } from '../../../../../utils/mlovUtils';
import { MLOV_CATEGORY, PERSON_CONTACT_TYPE_CODES } from '../../../../../constants/MlovConst';
import { IMlov } from '../../../../../Interfaces';
import { CustomSendCommunicationButton } from './CustomSendCommunicationButton';
import CreateSmsConversationDrawer from '../../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import MeetingView from '../../../../common/MeetingView/MeetingView';
import { getFormDataFromLeadData } from '../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import InstantChat from '../../../../common/ChatDrawer/InstantChat';
import { DiagnosticReport, Extension, RequestGroup } from 'fhir/r4';
import { canShowDownArrow, canShowUpArrow, getGraphFilterListForLabReports, getInterpretationDisplay, hasReferenceRange, isAbnormalValue, isAllReportViewedBy, isReportReviewedByUser } from './ReportUtils';
import { cloneDeep } from 'lodash';
import { EXTENSION_URLS } from '../../../MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { ReportSource, updateMultipleDiagnosticReport } from '../../../../../services/CommonService/OrderService';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { getUserName } from '../../../../../utils/commonUtils';
import { EventBus } from '../../../../../utils/EventBus';
import { SUPPORTED_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { getOrderDisplayItems } from '../../../MiddleContainer/Orders/OrdersAndReports/OrderDetailsDrawer/OrderDetailsUtils';
import { getEhrConfig, isMarkAsReviewEnabled } from '../../../../../utils/capabilityUtils';
import SwitchButton from './SwitchButton';
import { Tuple, VictoryAxis, VictoryChart, VictoryGroup, VictoryLine, VictoryScatter, VictoryTheme, VictoryTooltip, VictoryVoronoiContainerProps, VictoryZoomContainerProps, createContainer } from 'victory';
import { activityFrequencyUnitCodes } from '../../../MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivitiesGraphView/ActivitiesGraphView';
import { EmptyStateSvg } from '../../../../common/Svg';
import { getDateRange } from '../../../MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import { getGoalLines, getMeasure } from '../../../MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import { useCarePlanGoalsApi } from './CarePlanGoalsHook';
import { isTooltipForGoal } from '../../../../../utils/VitalUtils';
import GoalLinesView from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/GoalLinesView';


interface IReportResultViewProps {
  contactData: any;
  showModal: boolean;
  labelFontSize?: string;
  isHideGraphLabel?:boolean;
  reportName: string;
  observations: any[];
  isShowGraphOnly?:boolean;
  resources: DiagnosticReport[];
  unformmatedContactDetails?: any;
  onClose: () => void;
  onMarkedAsReviewed: () => void;
  documentTitle?: string;
  isImaging?: boolean;
}

interface IColumnData {
  date: string,
  selectedOrder?: RequestGroup,
  selectedReport?: DiagnosticReport
}

export const ReportResultView = (props: IReportResultViewProps) => {
  const {contactData} = props;
  const finalHeight = Dimensions.get('window').height - 120;
  const width = Dimensions.get('window').width
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const ehrConfig = getEhrConfig();
  const canShowMarkAsReview = isMarkAsReviewEnabled();
  const loginUserName = getUserName();
  const personContactTypeMlovs = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  const contactDetails = props?.contactData;
  let formattedContactData: any = '';

  if (contactDetails) {
    contactDetails['uuid'] = props?.contactData?.contactUUID
    formattedContactData = getFormDataFromLeadData(contactDetails || {}, mlovData);
  }

  const [goals, goalsLoading, fetchGoals] = useCarePlanGoalsApi();

  useEffect(() => {
    fetchGoals(contactData.contactUUID);
  }, [])  

  const popOverContent=(data: IColumnData)=>{
    const orderDisplay = getOrderDisplayItems({
      order: data.selectedOrder,
      report: data.selectedReport
    });
    return (
      <VStack space={2}>
        {orderDisplay}
      </VStack>
    )
  }

  const getInfoButton = (data: IColumnData): JSX.Element => {
    return (
      <Tooltip placement="top" title="View Details">
        <Popover placement ="bottomRight" content={popOverContent(data)} overlayStyle={{width: 520}} overlayInnerStyle={{borderRadius: 12, padding: 16}} trigger="click">
          <Feather
            name="info"
            style={{
              fontSize: 16,
              color: Colors.Custom.Gray500,
              padding: 0,
            }}
          />
        </Popover>
      </Tooltip>
    );
  };

  const getPreferredCommunicationDetail = () => {
    //
    // if (contactData?.personContact?.length) {
    //   const contactPreferredCommunication = (contactData?.personContact || []).find((contactDetail : any) => {
    //     if (contactDetail?.isPreferredCommunicationChannel) {
    //       return contactDetail;
    //     }
    //   });
    //   if (contactPreferredCommunication?.id &&
    //       contactPreferredCommunication?.personContactType?.id && contactPreferredCommunication?.value) {
    //     return {
    //       preferredCommunicationTypeId: contactPreferredCommunication?.personContactType?.id,
    //       preferredCommunicationCode: contactPreferredCommunication?.personContactType?.code,
    //       preferredCommunicationValue: contactPreferredCommunication?.value,
    //     };
    //   } else {
    //     const emailCommunicationType: IMlov = getMlovObjectFromCode(PERSON_CONTACT_TYPE_CODES.EMAIL, personContactTypeMlovs) || {} as IMlov;
    //     return {
    //       preferredCommunicationTypeId: emailCommunicationType?.id,
    //       preferredCommunicationCode: emailCommunicationType?.code,
    //       preferredCommunicationValue: contactData?.email,
    //     }
    //   }
    // }
    if(
      props.unformmatedContactDetails?.contactPreferredCommunicationChannel?.length > 0
    ) {
      const contactPreferredCommunication = props.unformmatedContactDetails?.contactPreferredCommunicationChannel[0];
      if(contactPreferredCommunication?.id){
        return {
          preferredCommunicationTypeId: contactPreferredCommunication?.id,
          preferredCommunicationCode: contactPreferredCommunication?.preferredCommunicationModeMlov?.code,
          preferredCommunicationValue: contactPreferredCommunication?.preferredCommunicationModeMlov?.value,
        }
      }
    } else {
      return {
      };
    }
  }
  const [reportResultDetail, setResultDetail] =
    useState<IReportResultListDetails>({
      observations: props.observations || [],
      loading: false,
      markedAsReviewedLoading: false,
      showModal: props.showModal,
      showReportPdf: false,
      pdfSource: '',
      selectedObservationName: '',
      imageBase64Source: '',
      sourceType: '',
      preferredCommunication: getPreferredCommunicationDetail(),
      showCommunicationDrawer: false,
      actionCode:'',
      isFetchMediaFromIntegration: false,
      selectedView: 'table',
      isTableView: true,
      selectedFilterId: activityFrequencyUnitCodes.ALL,
    });

  const range = getDateRange(reportResultDetail?.selectedFilterId);
  const onMarkedAsReviewed = (reportResources: DiagnosticReport[]) => {
    const reviewPendingReportList: DiagnosticReport[] = [];
    reportResources.forEach((resource: DiagnosticReport) => {
      if (!isReportReviewedByUser(resource)) {
        const selectedReport: DiagnosticReport = cloneDeep(resource);
        const reportExtensions: Extension[] =  selectedReport.extension || [];
        reportExtensions.push({
          url: EXTENSION_URLS.reviewedBy,
          valueString: loginUserName,
        });
        reportExtensions.push({
          url: EXTENSION_URLS.reviewedDate,
          valueString: getDateStrFromFormat(new Date(), DATE_FORMATS.API_DATE_FORMAT),
        });
        selectedReport['extension'] = reportExtensions || [];
        reviewPendingReportList.push(selectedReport);
      }
    });

    if (reviewPendingReportList?.length) {
      const updateReportBody: IUpdateReportDetail = {
        resourceType: 'Bundle',
        entry: []
      };
      const entries = updateReportBody.entry || [];
      (reviewPendingReportList).forEach(selectedResource => {
        entries.push(
          {
            resource: selectedResource,
          }
        );
      });
      setResultDetail((prev) => {
        return {
          ...prev,
          markedAsReviewedLoading: true,
        };
      });
      updateMultipleDiagnosticReport(updateReportBody, () => {
        reviewedByResponseHandler(reportResources);
      } , (error) => {
        setResultDetail((prev) => {
          return {
            ...prev,
            markedAsReviewedLoading: false,
          };
        });
      });
    }
  }

  const isEnabledMarkAsReviewed = () => {
    if (!canShowMarkAsReview) {
      return false;
    }
    const reportResources: DiagnosticReport[] = props.resources || [];
    const isAllReportReviewed = reportResources.every(resource => {
      return isReportReviewedByUser(resource);
    });
    return !isAllReportReviewed;
  }

  const reviewedByResponseHandler = (reportResources: DiagnosticReport[]) => {
    // update record locally
    const reviewPendingReportList: DiagnosticReport[] = [];
    (reportResources || []).forEach(resource => {
      if (!isReportReviewedByUser(resource)) {
        const reportExtensions: Extension[] =  resource?.extension || [];
        reportExtensions.push({
          url: EXTENSION_URLS.reviewedBy,
          valueString: loginUserName,
        });
        reportExtensions.push({
          url: EXTENSION_URLS.reviewedDate,
          valueString: getDateStrFromFormat(new Date(), DATE_FORMATS.API_DATE_FORMAT),
        });
        resource['extension'] = reportExtensions;
        reviewPendingReportList.push(resource);
      }
    });
    showToast(toast, 'Report has been marked as reviewed. You can access this from orders section when needed', ToastType.info);
    setResultDetail((prev) => {
      return {
        ...prev,
        markedAsReviewedLoading: false,
        showModal: false,
      };
    });
    broadcastOrderEvent(SUPPORTED_EVENT_CODE.REPORT_MARKED_AS_REVIEWED);
    props.onClose();
    props.onMarkedAsReviewed();
  };

  const broadcastOrderEvent = (code: string) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(code, {patientId: props.contactData?.patientId || props.contactData?.patientUuid});
  };

  const fetchUniqueResultDateInSelectedFilter = () => {
    const resultDates: IColumnData[] = [];
    (reportResultDetail.observations || []).forEach(observation => {
      (observation.results || []).forEach(result => {
        if (
          result?.effectiveDateTime &&
          !resultDates.some(item => item.date == result?.effectiveDateTime) &&
          (reportResultDetail?.selectedFilterId === activityFrequencyUnitCodes?.ALL ||
            isDateBetweenRange(getDateObject(result?.effectiveDateTime), getDateObject(range.startDate), getDateObject(range.endDate)))
        ) {
          resultDates.push({
            date: result?.effectiveDateTime,
            selectedOrder: result.selectedOrder,
            selectedReport: result.selectedReport,
          });
        }
      });
    });
    if (!resultDates.length && reportResultDetail.observations.length) {
      resultDates.push({
        date: '',
        selectedOrder: reportResultDetail.observations?.[0]?.results?.[0]?.selectedOrder,
        // selectedReport: reportResultDetail.observations?.[0]?.results?.[0]?.selectedReport,
      })
    }
    return resultDates;
  };

  const formattedTableSource = () => {
    const formattedResultList: IObservationResultTable[] = [];
    (reportResultDetail.observations || []).forEach(observation => {
      let observationUnit = '';
      const formattedResult: IObservationResultTable = {
        display: observation.display,
        observationId: observation.observationId,
        showGraph: observation.showGraph,
        isResultUrl: observation.isResultUrl,
      };
      if (ehrConfig?.isElation && !observation?.display?.length) {
        if (observation?.results[0]?.selectedReport?.category?.[0]?.text) {
          formattedResult.display = observation?.results[0]?.selectedReport?.category?.[0]?.text
        }
      }

      (observation.results || []).forEach(result => {
        observationUnit = result?.unit || '';
        if (result?.effectiveDateTime) {
          formattedResult[result?.effectiveDateTime] = {
            value: result.value,
            notes: result?.notes || '',
            resultList: result?.resultList || [],
            interpretation: result?.interpretation,
          };
          formattedResult.minValue = result?.referenceRange?.minValue;
          formattedResult.minValueUnit = result?.referenceRange?.minValueUnit;
          formattedResult.maxValue = result?.referenceRange?.maxValue;
          formattedResult.maxValueUnit = result?.referenceRange?.maxValueUnit;
        } else {
          formattedResult['resultList'] = result?.resultList;
        }
        formattedResult['valueAttachment'] = result?.valueAttachment;
        formattedResult['resultType'] = result?.resultType;
        formattedResult['isMultiReportResult'] = result?.isMultiReportResult;
        formattedResult['loinc'] = result?.loincCode;
        // check impact
        // formattedResult['resultList'] = result?.resultList;
      });
      if(!formattedResult?.display) {
        formattedResult['display'] = intl.formatMessage({id: 'report'});
      }
      formattedResult['unit'] = observationUnit || '';
      formattedResultList.push(formattedResult);
    });
    return formattedResultList;
  };

  const renderValue = (selectedRecord: IObservationResultTable, record: IMediaRecord, index: number) => {
    // If value is not available and notes are available then do not show "-" as value
    const displayValue = record?.value || (record?.notes ? '' : '-');
    const interpretation = getInterpretationDisplay(record?.interpretation);
    const hasRange = hasReferenceRange(selectedRecord);
    return (
      <HStack alignItems={'center'} flex={1}>
        <Text
          fontWeight={index === 0 ? 'bold' : 'medium'}
          color={isAbnormalValue(selectedRecord, record, ehrConfig) ? Colors.Custom.ErrorColor : ''}
        >
          {hasRange ? `${displayValue}` : parse(`${displayValue}`)}
        </Text>
        {canShowUpArrow(selectedRecord, record, ehrConfig) && (
          <Feather
            name="arrow-up"
            style={{fontSize: 18}}
            color={Colors.Custom.ErrorColor}
          />
        )}
        {canShowDownArrow(selectedRecord, record, ehrConfig) && (
          <Feather
            name="arrow-down"
            style={{fontSize: 18}}
            color={Colors.Custom.ErrorColor}
          />
        )}
        {!!interpretation && (
          <Text
            marginLeft={displayValue ? 3 : 0}
            fontWeight={index === 0 ? 'bold' : 'medium'}
            color={isAbnormalValue(selectedRecord, record, ehrConfig) ? Colors.Custom.ErrorColor : ''}>
            {displayValue ? `(${interpretation})` : `${interpretation}`}
          </Text>
        )}
      </HStack>
    );
  }

  const getResultTableViewColumns = (): any[] => {
    const primaryColumns: any[] = [
      {
        title: 'Test Name',
        dataIndex: 'display',
        key: 'display',
        width: '15%',
        fixed: 'left',
        render: (display: string, selectedRecord: any): JSX.Element => {
          return <HStack flex={1} space={2}>
                    <HStack flex={selectedRecord.showGraph ? 0.49 : 1} justifyContent={'flex-start'} flexWrap={'wrap'} space={1}>
                      <Text>{display}</Text>
                      {selectedRecord?.unit && (
                        <Text fontSize={12} fontWeight={'semibold'} color={Colors.Custom.Gray400}>{` ${selectedRecord.unit}`}</Text>
                      )}
                    </HStack>
                    {/* {selectedRecord.showGraph && (
                        <HStack flex={0.49} justifyContent={'flex-end'}>
                        <Tooltip title={`Show ${display?.toLowerCase()} graph`}>
                          <Pressable
                            onPress={() => {
                              //
                            }}
                          >
                            <Feather name="bar-chart-2" style={{ marginTop: 2, fontSize: 18, flex: 0.49, alignSelf: 'flex-end', justifyContent: 'flex-end' }} color={Colors.Custom.Gray700} />
                          </Pressable>
                        </Tooltip>
                      </HStack>
                    )} */}
            </HStack>;
        },
      },
    ];

    const uniqueDates = fetchUniqueResultDateInSelectedFilter();
    (uniqueDates || []).sort((a, b) => {
      return (
        new Date(b.date).getTime() -
        new Date(a.date).getTime()
      );
    });
    uniqueDates.forEach((data, index) => {
      primaryColumns.push({
        title: (
        <HStack alignItems="flex-start">
          <VStack flex={5}>
            <Text
              textAlign={'start'}
              fontWeight={index === 0 ? 'bold' : 'medium'}
            >
              {data.date ? getDateStrFromFormatWithTimezone(
                data.date,
                getCurrentTimeZone(),
                DATE_FORMATS.CONVERSATION_DATE_PICKER
              ) : 'Reports'}
            </Text>
            <Text
              fontWeight={index === 0 ? 'bold' : 'medium'}
              textAlign={'start'}
              fontSize={12}
              color={Colors.Custom.Gray400}
              marginLeft={1}
            >
              {data.date ? getDateStrFromFormatWithTimezone(
                data.date,
                getCurrentTimeZone(),
                DATE_FORMATS.DISPLAY_TIME_FORMAT
              ) : ''}
            </Text>
          </VStack>
          {!(ehrConfig.isAthena || props?.isImaging) && (
            <HStack flex={1} justifyContent={'flex-end'} space={1} alignItems={'baseline'} marginTop={1}>
              {getInfoButton(data)}
            </HStack>
            )}
        </HStack>
        ),
        dataIndex: data.date,
        key: data.date,
        width: '15%',
        fixed: index === 0 ? 'left' : '',
        render: (
          record: IMediaRecord,
          selectedRecord: IObservationResultTable
        ): JSX.Element => {
          return (
            <VStack space={2}>
            <HStack
              borderBottom={
                selectedRecord?.isResultUrl && index === 0 ? 'solid' : ''
              }
              borderBottomColor={Colors.primary[500]}
            >
                {!selectedRecord?.isResultUrl && hasReferenceRange(selectedRecord) && (
                  <Tooltip
                    title={`Min ${selectedRecord?.minValue} ${selectedRecord?.minValueUnit || ''} - Max ${selectedRecord?.maxValue} ${selectedRecord?.maxValueUnit || ''}`}
                  >
                    {renderValue(selectedRecord, record, index)}
                  </Tooltip>
                )}
              {!selectedRecord?.isResultUrl && !hasReferenceRange(selectedRecord) &&
                renderValue(selectedRecord, record, index)
              }
              {selectedRecord?.valueAttachment?.contentType && (
                  <VStack>
                    <Pressable
                      onPress={() => {
                        setResultDetail((prev) => {
                          return {
                            ...prev,
                            showReportPdf: true,
                            imageBase64Source:
                              selectedRecord?.valueAttachment?.data || '',
                            pdfSource: '',
                            selectedObservationName: selectedRecord?.display,
                            sourceType: selectedRecord?.resultType,
                          };
                        });
                      }}
                    >
                      <Text
                        style={{
                          textDecorationColor: 'black',
                          textDecorationStyle: 'solid',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {selectedRecord?.valueAttachment?.title || `Attachment`}
                      </Text>{' '}
                    </Pressable>
                  </VStack>
                )}
              <VStack space={2}>
              {selectedRecord?.isResultUrl &&
                (selectedRecord?.resultList?.length > 0 || (record?.resultList || []).length > 0) &&
                (selectedRecord.resultList || record?.resultList || []).map((result: IMediaReport) => {
                  return (
                    <VStack>
                      <Pressable
                        onPress={() => {
                          setResultDetail((prev) => {
                            return {
                              ...prev,
                              showReportPdf: true,
                              pdfSource: result?.resultUrl
                                ? result?.resultUrl
                                : result?.base64Data || '',
                              selectedObservationName: result?.title || selectedRecord?.display,
                              sourceType: result?.resultType,
                              isFetchMediaFromIntegration:
                                result?.isFetchMediaFromIntegration,
                            };
                          });
                        }}
                      >
                        <Text
                          style={{
                            textDecorationColor: 'black',
                            textDecorationStyle: 'solid',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {result?.title}
                        </Text>
                      </Pressable>
                    </VStack>
                  );
                })}
              {selectedRecord?.isResultUrl &&
                !(selectedRecord?.resultList?.length > 0 || (record?.resultList || []).length > 0) && (
                  <Text>{'-'}</Text>
                )}
              </VStack>
              {/* {selectedRecord?.isResultUrl && !selectedRecord?.resultList?.length && (
                    <VStack>
                      {dateValue && typeof dateValue === 'string' && (dateValue || '').split(',').map((link: string, index: number) => {
                          return (
                            <Pressable
                                onPress={() => {
                                  setResultDetail(prev => {
                                    return {
                                      ...prev,
                                      showReportPdf: true,
                                      pdfSource: link || '',
                                      selectedObservationName: selectedRecord?.display,
                                      sourceType: selectedRecord?.resultType,
                                    };
                                  });
                                }}
                              >
                                <Text
                                  style={{
                                    textDecorationColor: 'black',
                                    textDecorationStyle: 'solid',
                                    textDecorationLine: 'underline',
                                  }}
                                >
                                  {`Click here to open report ${index + 1}`}
                                </Text>{' '}
                              </Pressable>
                          )
                      })}
                    </VStack>
                  )} */}
            </HStack>
            {/* For athena we are receiving notes which includes spaces and to display proper structure we needed to replace one space with 3 spaces */}
            {!!record?.notes && (
              <Text>
                {parse(record.notes.replace(/\n/g, '<br/>').replace(/ /g, '&nbsp;&nbsp;&nbsp;'))}
              </Text>
            )}
            </VStack>
          );
        },
      });
    });

    return primaryColumns;
  };

  const displayColumns = getResultTableViewColumns();
  const tableWidth = displayColumns.length * 200;
  const handleSwitchValueChange = (value: any) => {
    setResultDetail((prev) => {
      return {
        ...prev,
      selectedView: value ? 'table' : 'graph',
      isTableView: value
      };
    });
  }

  const getUniqueDates =() => {
  return fetchUniqueResultDateInSelectedFilter().sort((a, b) => {
    return (
      new Date(a.date).getTime() -
      new Date(b.date).getTime()
    );
  })
  }

  const getLowestAndHighestValueOnYaxis = (result: any):Tuple<number> => {
    let lowest: any = undefined
    let highest: any = undefined
      getUniqueDates()?.forEach((item) => {
        const parsedToFloat = parseFloat(result?.[item?.date]?.value)
        if(!lowest && !highest) {
          lowest = parsedToFloat
          highest = parsedToFloat
        }
        else if(parsedToFloat < lowest) {
          lowest = parsedToFloat
        }
        else if(parsedToFloat > highest) {
          highest = parsedToFloat
        }
      })
    if (lowest === highest) {
      return [parseFloat((lowest - 1.0).toFixed(2)), parseFloat((highest + 1.0).toFixed(2))]
    }
    return [parseFloat(lowest.toFixed(2)), parseFloat(highest.toFixed(2))]
  }

  const getLowestMidAndHighestValueOnYaxis = (result: any):any[] => {
    const values = getLowestAndHighestValueOnYaxis(result);
    const lowestMidAndHighestValue = [values[0], parseFloat(((values[0] + values[1]) / 2).toFixed(2)), values[1]];
    return lowestMidAndHighestValue
  }

  const getDataForGraph = (result: any) => {
    const uniqueDates = fetchUniqueResultDateInSelectedFilter();
    (uniqueDates || []).sort((a, b) => {
      return new Date(a?.date).getTime() - new Date(b?.date).getTime();
    });
    const formattedDataForGraph: any[] = []
    let noValueIsANumber = true
    uniqueDates.forEach((item, dateIndex) => {
      if (!isNaN(result?.[item?.date]?.value)) {
        noValueIsANumber = false
        formattedDataForGraph.push( {
          x: getDateStrFromFormatWithTimezone(
            item?.date,
            getCurrentTimeZone(),
            DATE_FORMATS.CONVERSATION_DATE_PICKER
          ), y: parseFloat(parseFloat(result[item?.date]?.value).toFixed(2)),
          otherInfo: {...result[item?.date], dateTime: item?.date}
        })
      }
    })
    return { dataForGraph: formattedDataForGraph, noValueIsANumber }
  }

  const graphFilterList = getGraphFilterListForLabReports(intl)
  const VictoryZoomVoronoiContainer = createContainer<VictoryZoomContainerProps, VictoryVoronoiContainerProps>('zoom', 'voronoi');

  const getGoalLinesView = (goalLines: any[], dataForGraph: any[], unit?: string) => {
    return GoalLinesView({
      goalLines: goalLines,
      graphData: dataForGraph, 
      fontSize: 12,
      unit: unit || ''
    });
  }

  const renderGraph = (result: any, index: any): JSX.Element => {
    const { dataForGraph, noValueIsANumber } = getDataForGraph(result)
    const goalLines = getGoalLines(getMeasure(result.loinc, goals));
    if (fetchUniqueResultDateInSelectedFilter()?.[0]?.date?.length === 0 || !noValueIsANumber) {
      return (
        <View maxHeight={'130px'} flex={1} flexDirection={'column'}>
          <HStack alignItems={'center'} flex={0.9} >
            { props.isHideGraphLabel ? <></>:<Tooltip title={result?.display}>
              <Text
                fontWeight={400}
                flex={0.1}
                wordBreak={'break-word'}
                numberOfLines={4}
                fontSize={props.labelFontSize}
              >
                {result?.display}
              </Text>
            </Tooltip > }
            {dataForGraph?.length > 0 ?
            <View flex={0.9}>
              <VictoryChart
                theme={
                  {
                    ...VictoryTheme.material,
                    axis: {
                      ...VictoryTheme.material.axis,
                      style: {
                        ...VictoryTheme.material.axis?.style,
                        axis: { ...VictoryTheme.material.axis?.style?.axis, strokeWidth: 1, stroke: Colors?.Custom?.LabReportAxisColor },
                        grid: { ...VictoryTheme.material.axis?.style?.grid, strokeDasharray: undefined, },
                        ticks: { ...VictoryTheme.material.axis?.style?.ticks, stroke: 'transparent' }
                      }
                    }
                  }}
                padding={{bottom: 40, top: 40, right: 85, left: 40}}
                key={index}
                domainPadding={{ x: 20, y: 20 }}
                width={width - 500}
                height={165}
                standalone
                containerComponent={
                  <VictoryZoomVoronoiContainer
                    zoomDimension="x"
                    minimumZoom={{ x: 1 }}
                    allowZoom={false}
                    allowPan={true}
                    labelComponent={<VictoryTooltip
                      flyoutStyle={{
                        fill: Colors.Custom?.BackgroundColor,
                        strokeWidth: 1,
                        stroke: Colors.Custom?.graphLabelStroke,
                      }}
                      style={{
                        fill: (d: any) => {
                          return Colors.Custom?.HeadingColor;
                        },
                        fontSize: 24
                      }}
                      constrainToVisibleArea
                    />}
                    labels={(point, index, points) => {
                      const tooltipName = point.datum.childName;
                      if(isTooltipForGoal(tooltipName)) {
                        return tooltipName;
                      }
                      return '';
                    }}
                  />
                }
              >
                <VictoryAxis
                  dependentAxis
                  orientation="right"
                  tickValues={getLowestMidAndHighestValueOnYaxis(result)}
                />
                <VictoryAxis />
                <VictoryAxis
                  orientation="left"
                  tickFormat={() => ''}
                  style={{ grid: { stroke: 'transparent' } }}
                >
                </VictoryAxis>
                {getGoalLinesView(goalLines, dataForGraph, result?.unit)}
                {fetchUniqueResultDateInSelectedFilter().length === 1 ?
                  <VictoryScatter
                    size={4}
                    labelComponent={<VictoryTooltip flyoutStyle={{ fill: Colors.Custom?.BackgroundColor, strokeWidth: 1, stroke: Colors.Custom?.graphLabelStroke }} style={{ fill: (d: any) => { return isAbnormalValue(result, result[d?.datum?.otherInfo?.dateTime], ehrConfig) ? Colors?.Custom?.LabReportAbnormalColor : Colors.Custom?.HeadingColor } }} />}
                    labels={(d) =>
                      result[d?.datum?.otherInfo?.dateTime]?.value + ' ' + result?.unit
                    }
                    style={{
                      data: {
                        stroke: Colors?.Custom?.LabReportAbnormalColor,
                        fill: (d) => isAbnormalValue(result, result[d?.datum?.otherInfo?.dateTime], ehrConfig) ? Colors?.Custom?.LabReportAbnormalColor : Colors?.Custom?.SuccessColor
                      },
                      parent: { border: "1px solid #ccc" }
                    }}
                    data={
                      dataForGraph
                    }
                    domain={{ y: getLowestAndHighestValueOnYaxis(result) }}
                  />
                  :
                  <VictoryGroup
                    data={dataForGraph}
                  >
                    <VictoryLine
                      style={{
                        data: { stroke: Colors?.Custom?.Gray400 },
                        parent: { border: "1px solid #ccc" }
                      }}
                    />
                    <VictoryScatter
                      size={4}
                      labelComponent={<VictoryTooltip flyoutStyle={{ fill: Colors.Custom?.BackgroundColor, strokeWidth: 1, stroke: Colors.Custom?.graphLabelStroke }} style={{ fill: (d: any) => { return isAbnormalValue(result, result[d?.datum?.otherInfo?.dateTime], ehrConfig) ? Colors?.Custom?.LabReportAbnormalColor : Colors.Custom?.HeadingColor } }} />}
                      labels={(d) =>
                        result[d?.datum?.otherInfo?.dateTime]?.value + ' ' + result?.unit
                      } style={{
                        data: {
                          stroke: Colors?.Custom?.LabReportAbnormalColor,
                          fill: (d) => isAbnormalValue(result, result[d?.datum?.otherInfo?.dateTime], ehrConfig) ? Colors?.Custom?.LabReportAbnormalColor : Colors?.Custom?.SuccessColor
                        },
                        parent: { border: "1px solid #ccc" }
                      }}
                    />
                  </VictoryGroup>
                }
              </VictoryChart>
            </View>
              :
              <Center flex={0.9} p={4}>
                <EmptyStateSvg titleId='noData' />
              </Center>
            }
          </HStack>
          <Divider style={{ marginTop: 8, marginBottom: 0, backgroundColor: Colors?.Custom?.Gray200, flex: 0.1 }} />
        </View>
      )
    }
    return <></>
  }


  const renderReportResultView = (
    <>
      {reportResultDetail.showModal && (
        <>
          <HStack flex={1} space={1} marginTop={-4}>
            <HStack flex={isEnabledMarkAsReviewed() ? 0.49 : 1}>
              <Text fontSize="lg" fontWeight="semibold">
                {props.reportName || ''}
              </Text>
            </HStack>
            {reportResultDetail?.preferredCommunication
              ?.preferredCommunicationCode && (
              <HStack flex={0.49} justifyContent={'flex-end'}>
                <InfoSvg customColor={Colors.Custom.Gray500} />
                <Text size={'smMedium'} style={{color: Colors.Custom.Gray500}}>
                  {reportResultDetail?.preferredCommunication
                    ?.preferredCommunicationCode
                    ? `Patient preferred communication channel is ${reportResultDetail?.preferredCommunication?.preferredCommunicationCode}`
                    : ''}
                </Text>
              </HStack>
            )}
          </HStack>
          <HStack flex={1}>
            {reportResultDetail?.observations?.[0]?.results?.[0]?.selectedReport
              ?.category?.[0]?.coding &&
              reportResultDetail?.observations?.[0]?.results?.[0]?.selectedReport?.category?.[0]?.coding[0]?.code?.toLocaleLowerCase() ===
                'lab' &&
              !reportResultDetail?.observations?.[0]?.isResultUrl && (
                <HStack flex={1}>
                  <VStack marginTop={'10px'} flex={0.2}>
                    <Select
                      defaultValue={reportResultDetail?.selectedFilterId}
                      placeholder="Select"
                      size={'small'}
                      style={{width: '125px'}}
                      onChange={(value) => {
                        setResultDetail((prev) => {
                          return {
                            ...prev,
                            selectedFilterId: value,
                          };
                        });
                      }}
                    >
                      {graphFilterList.map((data) => {
                        return (
                          <Select.Option key={data.id} value={data.id}>
                            {data.value}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </VStack>
                  <Spacer></Spacer>
                  <SwitchButton
                    onValueChange={handleSwitchValueChange}
                    isTableView={reportResultDetail?.isTableView}
                  />
                </HStack>
              )}
          </HStack>
        </>
      )}
      <View mt={4} flex={1}>
        {reportResultDetail?.selectedView === 'table' ? (
          <Table
            loading={reportResultDetail.loading}
            rowKey={(record) => {
              return record?.observationId || '';
            }}
            className="patient-list-view report-table"
            scroll={{x: tableWidth, y: finalHeight - 132}}
            columns={displayColumns}
            dataSource={formattedTableSource()}
            pagination={false}
          />
        ) : (
          <ScrollView>
            {formattedTableSource()?.map((result, index) => {
              return renderGraph(result, index);
            })}
          </ScrollView>
        )}
      </View>
    </>
  );

  return (
    <>
    {reportResultDetail.showModal ? (
      <Drawer
        open={reportResultDetail.showModal && !props.isShowGraphOnly}
        width={'70%'}
        onClose={() => {
          setResultDetail((prev) => {
            return {
              ...prev,
              showModal: false,
            };
          });
          props.onClose();
        }}
        closable={false}
        title={<ModalActionTitle
          isHeadNotSticky
          title={intl.formatMessage({
            id: 'results',
          })}
          titleColor={''}
          showCustomButton={true}
          customButton={
            <CustomSendCommunicationButton
              contactData={props.contactData}
              defaultCommunicationCode={
                reportResultDetail?.preferredCommunication
                  ?.preferredCommunicationCode
              }
              title={'Send Communication'}
              isLoading={reportResultDetail.markedAsReviewedLoading}
              onActionPerformed={(actionCode: string) => {
                setResultDetail((prev) => {
                  return {
                    ...prev,
                    actionCode,
                    showModal: false,
                  };
                });
              }}
            />
          }
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              isDisabled:
                reportResultDetail.markedAsReviewedLoading ||
                reportResultDetail.loading,
              onClick: () => {
                setResultDetail((prev) => {
                  return {
                    ...prev,
                    showModal: false,
                  };
                });
                props.onClose();
              },
            },
            {
              show: isEnabledMarkAsReviewed(),
              id: 2,
              btnText: 'markAsReviewed',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled:
                reportResultDetail.markedAsReviewedLoading ||
                reportResultDetail.loading,
              onClick: () => {
                onMarkedAsReviewed(props.resources);
              },
            }
          ]}
        />}
      >
      {renderReportResultView}
        </Drawer>
      ) : (
        renderReportResultView
      )}
      {
        props.isShowGraphOnly ?
          formattedTableSource()?.map((result, index) => {
            const cloneResult = cloneDeep(result);
            if (cloneResult?.display && cloneResult?.unit && !cloneResult.display.includes(cloneResult.unit)) {
              cloneResult.display += ` (${result.unit})`;
            }
          return renderGraph(cloneResult, index)
        })  : <></>
      }

      {reportResultDetail.showReportPdf && (
        <MediaReportView
          sourceType={reportResultDetail?.sourceType}
          reportName={reportResultDetail.selectedObservationName}
          source={
            reportResultDetail.pdfSource ||
            reportResultDetail?.imageBase64Source
          }
          reportSource={ehrConfig.isAthena ? ReportSource.athena : ReportSource.healthGorilla}
          showReportPdf={reportResultDetail.showReportPdf}
          isFetchMediaFromIntegration={
            reportResultDetail.isFetchMediaFromIntegration
          }
          reportId={reportResultDetail?.observations[0]?.observationId}
          isImaging={props?.isImaging}
          onClose={() => {
            setResultDetail((prev) => {
              return {
                ...prev,
                source: '',
                showReportPdf: false,
                isFetchMediaFromIntegration: false,
                pdfSource: '',
                sourceType: '',
                selectedObservationName: '',
              };
            });
          }}
        />
      )}

      {reportResultDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommon
          contactData={contactDetails}
          category={{
            code: 'GENERAL',
            name: '',
          }}
          onMailCompleteAction={() => {
            props.onClose();
          }}
        />
      )}

      {reportResultDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            props.onClose();
          }}
          selectedPatient={props.unformmatedContactDetails}
        />
      )}

      {reportResultDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
        <MeetingView
          personData={formattedContactData}
          onPersonActionPerformed={() => {
            props.onClose();
          }}
        />
      )}

      {reportResultDetail.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CHAT_DRAWER && (
        <InstantChat
          contactUuid={props?.contactData?.contactUUID}
          isDrawerVisible={true}
          onActionPerformed={() => {
            props.onClose();
          }}
          contactData={props?.contactData}
        />
      )}
    </>
  );
};
