import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  messageCommunicationTypeWrapper: {
    padding: 3,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#0000001A',
  },
  messageCommunicationTypeText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 21,
    color: Colors.Custom.MonochromeBlack
  },
});
