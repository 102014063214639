import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const ChartActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        fill={strokeColor}
        d="M8.5 4.003a.5.5 0 00-1 0h1zm-1 2.666a.5.5 0 001 0h-1zm-.833-1.833a.5.5 0 100 1v-1zm2.666 1a.5.5 0 000-1v1zm-4 3a.5.5 0 000 1v-1zm5.334 1a.5.5 0 000-1v1zM6 11.503a.5.5 0 000 1v-1zm4 1a.5.5 0 000-1v1zM7.333 1.836h1.334v-1H7.333v1zM13.5 6.669v2.667h1V6.669h-1zm-4.833 7.5H7.333v1h1.334v-1zM2.5 9.336V6.669h-1v2.667h1zm4.833 4.833c-1.27 0-2.174 0-2.86-.093-.67-.09-1.056-.26-1.338-.541l-.707.707c.498.499 1.131.72 1.913.825.767.103 1.75.102 2.992.102v-1zM1.5 9.336c0 1.243-.001 2.225.102 2.993.105.781.327 1.414.826 1.913l.707-.707c-.283-.283-.452-.669-.542-1.34-.092-.685-.093-1.588-.093-2.86h-1zm12 0c0 1.271-.001 2.174-.093 2.86-.09.67-.26 1.056-.542 1.339l.707.707c.5-.5.72-1.132.826-1.913.103-.768.102-1.75.102-2.993h-1zm-4.833 5.833c1.243 0 2.225.001 2.992-.102.782-.105 1.415-.326 1.913-.825l-.707-.707c-.282.282-.668.45-1.339.541-.685.092-1.588.093-2.86.093v1zm0-13.333c1.27 0 2.174.001 2.86.093.67.09 1.056.26 1.338.542l.707-.708c-.498-.498-1.131-.72-1.913-.825C10.892.835 9.91.836 8.667.836v1zM14.5 6.669c0-1.243.001-2.225-.102-2.992-.105-.782-.327-1.415-.825-1.914l-.708.708c.283.282.452.668.542 1.339.092.685.093 1.588.093 2.86h1zM7.333.836C6.09.836 5.108.835 4.341.938c-.782.105-1.415.327-1.913.825l.707.708c.282-.283.668-.452 1.339-.542.685-.092 1.588-.093 2.86-.093v-1zM2.5 6.669c0-1.27.001-2.174.093-2.86.09-.67.26-1.056.542-1.338l-.707-.708c-.5.5-.72 1.132-.826 1.914-.103.767-.102 1.75-.102 2.992h1zm5-2.666v1.333h1V4.003h-1zm0 1.333v1.333h1V5.336h-1zm-.833.5H8v-1H6.667v1zm1.333 0h1.333v-1H8v1zm-2.667 4h5.334v-1H5.333v1zM6 12.503h4v-1H6v1z"
      ></Path>
    </Svg>
  );
};

export default ChartActionSvg;
