import {View, Text} from 'react-native';
import React, {useContext} from 'react';
import {HStack, Spacer, VStack} from 'native-base';
import {Colors} from '../../../../../styles';
import {CarePlanScreenContext} from '../interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CommonDataContext} from '../../../../../context/CommonDataContext';

const Header = (props: {
  headerAction: React.ReactNode;
  context: CarePlanScreenContext;
  title: string;
  subTitle?: string;
  subTitleStyle?: any
  prefixAction?: React.ReactNode;
}) => {
  const {headerAction, context, title, subTitle, subTitleStyle} = props;
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData?.sidecarContext?.isSidecar;
  return (
    <VStack>
      
      <VStack
        borderWidth={1}
        borderColor={Colors.Custom.Gray200}
        w="100%"
        px={5}
        paddingY={3}
        backgroundColor={isSidecar ? undefined : Colors.Custom.Gray100}
        style={isSidecar ? undefined : {borderTopLeftRadius: 8, borderTopRightRadius: 8}}
      >
        <HStack alignItems="center">
          {props.prefixAction}
          <Stack direction="column" style={{
            marginLeft: !!props?.prefixAction ? 10 : undefined
          }}>
            <DisplayText textLocalId={title || ''} size={'xlBold'} />
            {subTitle && (
              <DisplayText
                extraStyles={{
                  color: Colors.Custom.Gray500,
                  ...subTitleStyle
                }}
                textLocalId={subTitle || ''}
              />
            )}
          </Stack>
          <Spacer />
          <HStack space={1} alignItems="center">
            {headerAction}
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Header;
