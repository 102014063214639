import React from 'react';
import {Colors} from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import {Image} from 'native-base';

const SubtasksSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor} = props;
  const bgColor = customStrokeColor || Colors.Custom.Gray500;

  return (
    <>
      {isWeb() ? (
        <svg
          width="inherit"
          height="inherit"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9H19C20.6569 9 22 7.65685 22 6C22 4.34315 20.6569 3 19 3H5C3.34315 3 2 4.34315 2 6C2 7.65685 3.34315 9 5 9ZM5 9L5 14C5 16.2091 6.79086 18 9 18M9 18C9 19.6569 10.3431 21 12 21H19C20.6569 21 22 19.6569 22 18C22 16.3431 20.6569 15 19 15H12C10.3431 15 9 16.3431 9 18Z"
            stroke={customStrokeColor}
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <Image
          tintColor={customStrokeColor}
          size={'4'}
          source={require('../../../assets/images/PNGImages/subtasksIcon.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default SubtasksSvg;
