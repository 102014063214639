import { Pressable, Text, View } from 'react-native'
import React, { useCallback, useEffect } from 'react'
import Stack from '../../../common/LayoutComponents/Stack'
import { ITaskListRowHeaderView } from './TaskListInterface';
import SorterSvg from '../../../common/Svg/SorterSvg';
import { Tooltip } from 'native-base';
import BulkTaskDeselectSvg from '../../../common/Svg/Task/BulkTaskDeselectSvg';
import BulkTaskSelectSvg from '../../../common/Svg/Task/BulkTaskSelectSvg';
import { TaskListRowHeaderAction } from './TaskListConstants';
import { Colors } from '../../../../styles';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../../common/CareDashboard/CareDashboardConstants';
import { MultiSelectAction } from '../../TaskInterfaces';
import { EventBus } from '../../../../utils/EventBus';

const TaskListRowHeaderView = (props: ITaskListRowHeaderView) => {
  // Constants
  const { columns, bulkActionEnabled, metadata, onActionPerformed } = props;

  // Lifecycle methods
  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onTaskEvent, { moduleCode: TASK_MODULE_CODE });
    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onTaskEvent, { moduleCode: TASK_MODULE_CODE });
    }
  }, [])

  // Other methods
  const onTaskEvent = useCallback((data: { action: MultiSelectAction }) => {
    if (data?.action) {
      switch (data.action) {
        case MultiSelectAction.disableMultiSelect:
          onActionPerformed?.(TaskListRowHeaderAction.BULK_ACTION_CHANGED, { bulkEnabled: false });
          break;

        case MultiSelectAction.enableMultiSelect:
          onActionPerformed?.(TaskListRowHeaderAction.BULK_ACTION_CHANGED, { bulkEnabled: true });
          break;
      }
    }
  }, [props.bulkActionEnabled]);

  const onBulkActionChange = (bulkEnabled: boolean) => {
    onActionPerformed?.(TaskListRowHeaderAction.BULK_ACTION_CHANGED, { bulkEnabled });
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: bulkEnabled ? MultiSelectAction.enableMultiSelect : MultiSelectAction.disableMultiSelect });
  }

  return (
    <Stack direction='row' space={4} style={{ flex: 1, width: '100%', alignItems: 'center', paddingHorizontal: 8, paddingVertical: 8 }}>
      {metadata.allowBulkAction && (
        <View style={{ width: '6%' }}>
          <Tooltip
            placement='bottom'
            hasArrow
            label={'Bulk Task Update'}
          >
            <Pressable
              style={{marginLeft: 4}}
              onPress={() => {
                const bulkEnabled = !bulkActionEnabled;
                onBulkActionChange(bulkEnabled);
              }}>
                {bulkActionEnabled ? <BulkTaskDeselectSvg /> : <BulkTaskSelectSvg />}
            </Pressable>
          </Tooltip>
        </View>
      )}
      {columns.map((item, index) => {
        const width = !metadata.allowBulkAction && index === 0 ? item.width + 6 : item.width;
        return (
          <Stack key={`TaskListRowHeaderView_${item.code}`} direction='row' style={{ width: `${width}%`, paddingLeft: index > 0 || !metadata.allowBulkAction ? 6 : 0  }}>
            {item.title && (
              <Stack direction='row' space={4} style={{ alignItems: 'center' }}>
                <Text style={{ color: Colors.Custom.Gray500, fontSize: 14 }}>{item.title}</Text>
                <Pressable
                  onPress={() => {
                    const currentSortOrder = item.sortOrder || 'none';
                    const nextSortOrder = currentSortOrder === 'none' ? 'asc' : (currentSortOrder === 'asc' ? 'desc' : 'none');
                    onActionPerformed?.(TaskListRowHeaderAction.SORT_CHANGED, {
                      code: item.code,
                      sortOrder: nextSortOrder,
                    });
                  }}
                >
                  <SorterSvg sortOrder={item.sortOrder || 'none'} />
                </Pressable>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  )
}

export default TaskListRowHeaderView
