import {Button, Modal, Spinner, useToast} from 'native-base';
import React from 'react';
import {Pressable, Text, View} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {getBase64} from '../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {
  downloadEmailAttachments,
  getEmailAttachments,
} from '../../EmailDrawerCommonV2/EmailInboxApi';
import {IEmailAttachmentResponse} from '../../EmailDrawerCommonV2/interfaces';
import {IEmailAttachmentsProps} from './interfaces';
import { BUTTON_TYPE, SENDER_TYPE } from '../../../../constants';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';

const EmailAttachments = (props: IEmailAttachmentsProps) => {
  const [state, setState] = React.useState({
    isLoading: false,
    attachments: [] as IEmailAttachmentResponse[],
    isPHIAlertOpen: false,
    selectedAttachment: {} as IEmailAttachmentResponse
  });
  const toast = useToast();
  const intl = useIntl();

  const downloadAttachment = async (data: {
    attachment: IEmailAttachmentResponse;
  }) => {
    showToast(toast, 'Downloading...', ToastType.info);
    try {
      const file: any = {};
      const response = await downloadEmailAttachments({
        attachmentId: data.attachment.crmAttachments.id,
      });
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);
      const link = document.createElement('a');
      link.href = file.blobUrl;
      link.download = data.attachment.crmAttachments.name;
      link.click();
    } catch (error) {
      showToast(toast, 'Failed to download attachment', ToastType.error);
    }
  };

  const getAttachmentsForEmail = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      const response = await getEmailAttachments({
        messageUuid: props.messageUuid,
      });
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          attachments: response,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          attachments: [],
        };
      });
    }
  };

  React.useEffect(() => {
    getAttachmentsForEmail();
  }, []);

  return (
    <View>
      {state.isLoading ? (
        <View>
          <Spinner />
        </View>
      ) : (
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {state.attachments.map((item) => {
            return (
              <Pressable
                key={item.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: Colors.Custom.mainPrimaryPurple,
                  padding: 4,
                  borderRadius: 6,
                  margin: 2,
                }}
                onPress={() => {
                  if (
                    props?.senderType &&
                    props?.senderType === SENDER_TYPE.CONTACT
                  ) {
                    setState((prev) => {
                      return {
                        ...prev,
                        isPHIAlertOpen: true,
                        selectedAttachment: item
                      };
                    });
                  } else {
                    downloadAttachment({attachment: item});
                  }
                }}
              >
                <AntIcon name="paperclip" color={'white'} />
                <Text
                  style={{
                    color: 'white',
                    marginHorizontal: 4,
                    fontWeight: '500',
                    fontSize: 12,
                    lineHeight: 14.4,
                  }}
                >{`${item.crmAttachments.name}`}</Text>
              </Pressable>
            );
          })}
        </View>
      )}

      {state.isPHIAlertOpen ? (
        <Modal
          isOpen={state.isPHIAlertOpen}
          avoidKeyboard
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                isPHIAlertOpen: false,
              };
            });
          }}
          animationPreset={'slide'}
        >
          <Modal.Content
            width={'full'}
            paddingX={3}
            style={{
              backgroundColor: 'white',
            }}
          >
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={{color: 'black'}}>PHI Warning</Text>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderColor: 'white',
                paddingHorizontal: 14,
                paddingTop: 20,
                paddingBottom: 16,
              }}
            >
              <Text
                style={{
                  color: 'black',
                }}
              >
                {intl.formatMessage({id: 'fileDownloadPhiAlert'})}
              </Text>
            </Modal.Body>
            <Modal.Footer
              style={{
                backgroundColor: 'white',
              }}
            >
              <Button.Group gap={'0.5'}>
                <FoldButton
                  customProps={{
                    btnText: 'Confirm',
                  }}
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress() {
                      downloadAttachment({attachment: state.selectedAttachment});
                      setState((prev) => {
                        return {
                          ...prev,
                          isPHIAlertOpen: false,
                        };
                      });
                    },
                  }}
                />
                <FoldButton
                  customProps={{
                    btnText: 'Cancel',
                  }}
                  nativeProps={{
                    variant: BUTTON_TYPE.SECONDARY,
                    onPress() {
                      setState((prev) => {
                        return {
                          ...prev,
                          isPHIAlertOpen: false,
                        };
                      });
                    },
                  }}
                />
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      ) : (
        <></>
      )}
    </View>
  );
};

export default EmailAttachments;
