import axios from 'axios';
import {INTEGRATION_BASE_URL} from '../../constants/BaseUrlConst';
import {
  BILLING_BASE_URL,
  CLOUD_TELEPHONY_SERVICE_URL,
  CRM_BASE_URL,
  FOLD_APIS_URL,
  RAILS_SERVER_URL,
  WORKFLOW_SERVICE_URL,
  LLM_SERVICE_URL
} from '../../constants/Configs';
import LocalStorage from '../../utils/LocalStorage';
import { createAxiosInstance } from '../../utils/APIUtils';

export default class CommonService {
  private static commonServiceInstance: CommonService;
  public llmService: any;
  public axiosService;
  public workflowService;
  public cloudTelephonyService;
  public integrationService: any;
  public crmService: any;
  public billingService: any;
  public foldApiService: any;
  constructor() {
    if (CommonService.commonServiceInstance) {
      throw new Error(
        'Use CommonService.commonServiceInstance instead of new.'
      );
    }
    // const commonData = useContext(CommonDataContext);
    this.axiosService = createAxiosInstance({
      baseURL: `${RAILS_SERVER_URL}/api/v1`,
      headers: {
        'Content-type': 'application/json',
        'token-type': 'Bearer',
      },
    });
    this.axiosService.interceptors.request.use(
      async (config) => {
        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        const uid = userData?.data?.email || '';
        const client = userData?.client || '';
        const responseAccessToken = userData?.accessToken || '';
        const fold_access_token = userData?.fold_access_token;
        const accountUUID =
          userData?.data.accounts.length > 0
            ? userData?.data.accounts[0].uuid || ''
            : '';

        config.headers = {
          ...config.headers,
          client,
          'access-token': responseAccessToken,
          'account-uuid': accountUUID,
          uid,
          fold_access_token,
        };
        return config;
      },
      (error) => {

      }
    );

    this.llmService = createAxiosInstance({
      baseURL: `${LLM_SERVICE_URL}`,
      // baseURL: `http://0.0.0.0:8083/llm`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.workflowService = createAxiosInstance({
      baseURL: `${WORKFLOW_SERVICE_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.integrationService = createAxiosInstance({
      baseURL: `${INTEGRATION_BASE_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.cloudTelephonyService = createAxiosInstance({
      baseURL: `${CLOUD_TELEPHONY_SERVICE_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.crmService = createAxiosInstance({
      baseURL: `${CRM_BASE_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.billingService = createAxiosInstance({
      baseURL: `${BILLING_BASE_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.foldApiService = createAxiosInstance({
      baseURL: `${FOLD_APIS_URL}`,
      headers: {
        'Content-type': 'application/json',
      },
    });


    this.workflowService.interceptors.request.use(async (config) => {
      const userDataString = await LocalStorage.getItem('user');
      const accountJsonString = await LocalStorage.getItem('account');
      const accountData = JSON.parse(accountJsonString);
      const userData = JSON.parse(userDataString);
      const accountId =
        userData?.data.account_id || accountData?.accountId || '';
      const apiAccessToken = userData?.data?.access_token || '';
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      config.headers = {
        ...config.headers,
        'x-hasura-tenant-id': accountId + '',
        'x-hasura-account-id': accountId + '',
        'x-hasura-account-uuid': accountUUID + '',
        'x-hasura-user-id': userData?.data?.id || '',
        'account-uuid': accountUUID,
        api_access_token: apiAccessToken,
        fold_access_token,
      };

      return config;
    });

    this.llmService.interceptors.request.use(async (config: { headers: any; }) => {
      const userDataString = await LocalStorage.getItem('user');
      const accountJsonString = await LocalStorage.getItem('account');
      const accountData = JSON.parse(accountJsonString);
      const userData = JSON.parse(userDataString);
      const accountId =
        userData?.data.account_id || accountData?.accountId || '';
      const apiAccessToken = userData?.data?.access_token || '';
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      config.headers = {
        ...config.headers,
        'x-hasura-tenant-id': accountId + '',
        'x-hasura-account-id': accountId + '',
        'x-hasura-account-uuid': accountUUID + '',
        'x-hasura-user-id': userData?.data?.id || '',
        'account-uuid': accountUUID,
        'fold-access-token': fold_access_token,
        api_access_token: apiAccessToken,
        fold_access_token,
      };

      return config;
    });

    this.cloudTelephonyService.interceptors.request.use(async (config) => {
      const userDataString = await LocalStorage.getItem('user');
      const accountJsonString = await LocalStorage.getItem('account');
      const accountData = JSON.parse(accountJsonString);
      const userData = JSON.parse(userDataString);
      const accountId =
        userData?.data.account_id || accountData?.accountId || '';
      const apiAccessToken = userData?.data?.access_token || '';
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      config.headers = {
        ...config.headers,
        'x-hasura-tenant-id': accountId + '',
        'x-hasura-account-id': accountId + '',
        'x-hasura-account-uuid': accountUUID + '',
        'x-hasura-user-id': userData?.data?.id || '',
        'account-uuid': accountUUID,
        api_access_token: apiAccessToken,
        fold_access_token,
      };

      return config;
    });

    this.integrationService.interceptors.request.use(async (config: any) => {
      const userDataString = await LocalStorage.getItem('user');
      const accountJsonString = await LocalStorage.getItem('account');
      const accountData = JSON.parse(accountJsonString);
      const userData = JSON.parse(userDataString);
      const accountId =
        userData?.data.account_id || accountData?.accountId || '';
      const apiAccessToken = userData?.data?.access_token || '';
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      config.headers = {
        ...config.headers,
        'x-hasura-tenant-id': accountId + '',
        'x-hasura-account-id': accountId + '',
        'x-hasura-account-uuid': accountUUID + '',
        'x-hasura-user-id': userData?.data?.id || '',
        'account-uuid': accountUUID,
        api_access_token: apiAccessToken,
        fold_access_token,
      };

      return config;
    });

    this.foldApiService.interceptors.request.use(async (config: any) => {
      const userDataString = await LocalStorage.getItem('user');
      const userData = JSON.parse(userDataString);
      const fold_access_token = userData?.fold_access_token;
      config.headers = {
        ...config.headers,
        fold_access_token,
      };

      return config;
    });

    this.billingService.interceptors.request.use(async (config: any) => {
      const userDataString = await LocalStorage.getItem('user');
      const userData = JSON.parse(userDataString);
      const fold_access_token = userData?.fold_access_token;
      config.headers = {
        ...config.headers,
        fold_access_token,
      };

      return config;
    });

    this.crmService.interceptors.request.use(async (config: any) => {
      const userDataString = await LocalStorage.getItem('user');
      const accountJsonString = await LocalStorage.getItem('account');
      const accountData = JSON.parse(accountJsonString);
      const userData = JSON.parse(userDataString);
      const accountId =
        userData?.data.account_id || accountData?.accountId || '';
      const apiAccessToken = userData?.data?.access_token || '';
      const fold_access_token = userData?.fold_access_token;
      const accountUUID =
        userData?.data.accounts.length > 0
          ? userData?.data.accounts[0].uuid || ''
          : '';
      config.headers = {
        ...config.headers,
        'x-hasura-tenant-id': accountId + '',
        'x-hasura-account-id': accountId + '',
        'x-hasura-account-uuid': accountUUID + '',
        'x-hasura-user-id': userData?.data?.id || '',
        'account-uuid': accountUUID,
        api_access_token: apiAccessToken,
        fold_access_token,
      };

      return config;
    });

    CommonService.commonServiceInstance = this;
  }

  static getCommonServiceInstance(): CommonService {
    return (
      CommonService.commonServiceInstance ??
      (CommonService.commonServiceInstance = new CommonService())
    );
  }
}
