import React, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import {ICarePlanTemplate, ICarePlanTemplatesProps} from '../interfaces';
import {
  Badge,
  HStack,
  View,
  Text,
  Pressable,
  Spinner,
  Icon,
  Divider,
} from 'native-base';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { cloneDeep } from 'lodash';
import { capitalizeFirstLetterOfEachWord, capitalizeFirstOtherSmallLetter } from '../../../../RightSideContainer/ContentManagement/CampaignManagement/Helper';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { Input } from 'antd';
import './CarePlanTemplates.css'

const SHOW_MORE_BUTTON = 'showMore';
const SHOW_MORE_SIZE = 3;

const CarePlanTemplatesView = (props: ICarePlanTemplatesProps) => {
  const intl = useIntl();
  const [templateList, setTemplateList] = useState<ICarePlanTemplate[]>([]);
  const [filteredTemplateList, setFilteredTemplateList] = useState<ICarePlanTemplate[]>([]);

  const [searchText, setSearchText] = useState<string>('');

  const [isShowMore, setShowMore] = useState(false);
  const {    
    selectedTemplateIds,
    loadingTemplateIds,
    onCheckTemplate,
  } = props;

  useEffect(() => {
    const sortedTemplateList = props.templateList?.sort(
      (templateA, templateB) => {
        return (
          selectedTemplateIds.indexOf(templateB.id) -
          selectedTemplateIds.indexOf(templateA.id)
        );
      }
    );

    setFilteredTemplateList(sortedTemplateList);
  }, [props.templateList?.length]);

  useEffect(() => {
    if (filteredTemplateList.length > SHOW_MORE_SIZE) {
      updateTemplateListByShowMoreVisibility(false);
    }
    else {
      setTemplateList(filteredTemplateList)
    }
  }, [filteredTemplateList?.length]);

  useEffect(()=> {
    const filteredTemplateList = props.templateList.filter((template) => {
      if (!searchText) {
        return true;
      }
      return template.name
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
    setFilteredTemplateList(filteredTemplateList);
  }, [searchText])


  const updateTemplateListByShowMoreVisibility = (isShowMore: boolean) => {

    let updatedTemplateList: ICarePlanTemplate[] = [];

    if (isShowMore) {
      updatedTemplateList = cloneDeep(filteredTemplateList);
      updatedTemplateList.push({
        id: SHOW_MORE_BUTTON,
        name:
          capitalizeFirstLetterOfEachWord(
            intl.formatMessage({id: 'showLess'})
          ) || '',
      });
    } else {
      updatedTemplateList = cloneDeep(filteredTemplateList.slice(0, SHOW_MORE_SIZE));
      updatedTemplateList.push({
        id: SHOW_MORE_BUTTON,
        name: intl.formatMessage({id: 'showMore'}),
      });
    }

    setTemplateList(updatedTemplateList);
  };

  return (
    <View
      borderBottomWidth={1}
      borderColor={Colors.Custom.Gray200}
    >
      <Stack
        direction="row"
        style={{alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 12, paddingVertical: 8}}
      >
        <Text>
          {intl.formatMessage({id: 'carePlanTemplates'}).toUpperCase()}
        </Text>       
        <Input
          style={{
            borderRadius: 4,
            width: 280,
            height: 32,
            color: Colors.Custom.Gray500,
            borderColor: Colors.Custom.Gray300,
            paddingRight: 6
          }}
          onChange={(event)=> setSearchText(event.target.value)}
          value={searchText}
          placeholder='Search Template'
          prefix={
            <Icon
              size={'4'}
              color={'gray.400'}
              as={<AntIcon name="search1" />}
            />
          }
          
          suffix={searchText ? (
            <Pressable onPress={() => {
              setSearchText('');
            }}>
              <Icon
                size={'4'}
                color={'gray.400'}                
                as={<AntIcon name="closecircleo" />}
              />
            </Pressable>
          ) : (
            <></>
          )}
        />

      </Stack>
      <Divider />
      <Stack
        direction="row"
        style={{flex: 1, flexWrap: 'wrap', paddingHorizontal: 12, paddingBottom: 12, paddingTop: 4}}
      >
        {templateList.map((template) => {
          const isSelected = selectedTemplateIds.includes(template.id);
          const isLoading = loadingTemplateIds.includes(template.id);
          const isShowMoreButton = template.id === SHOW_MORE_BUTTON;

          const backgroundColor = isShowMoreButton
            ? Colors.FoldPixel.PRIMARY100
            : isSelected
            ? Colors.Custom.mainPrimaryPurple
            : Colors.Custom.Gray100;

          const textColor = isShowMoreButton
            ? Colors.Custom.Primary300
            : isSelected
            ? Colors.Custom.White
            : Colors.Custom.Gray500; 
                        
          return (
            <Pressable
              key={template.id}
              disabled={isLoading || isSelected}
              onPress={() => {
                if(template.id === SHOW_MORE_BUTTON){
                  setShowMore(!isShowMore);
                  updateTemplateListByShowMoreVisibility(!isShowMore);
                }else {
                  onCheckTemplate(template.id);
                }
              }}
              mr={2}
              mt={2}
            >
              <Badge
                flexDir={'row'}
                key={template.id}
                borderRadius={4}
                borderWidth={1}
                borderColor={!isSelected ? Colors.Custom.Gray200 : Colors.Custom.Primary300}
                paddingY={2}
                paddingX={2}
                backgroundColor={backgroundColor}
              >
                <Text
                  color={textColor}
                >
                  {template.name}
                </Text>
                {isLoading && <Spinner ml={0.5} size={'sm'} />}
              </Badge>
            </Pressable>
          );
        })}
        {templateList.length === 0 ? <Text color={Colors.Custom.Gray500} marginTop={1}>{intl.formatMessage({id: 'noTemplateFound'})}</Text> : <></>}
      </Stack>
    </View>
  );
};

export default CarePlanTemplatesView;
