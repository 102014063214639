import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';
import { isWeb } from '../../../../utils/platformCheckUtils';

const styles = StyleSheet.create({
  header: {
    padding: 4,
  },
  container: {
    flex:1,
    borderRadius: 8,
    borderWidth: 0.3,
    borderColor: Colors.Custom.Gray200,
    shadowColor: Colors.Custom.Gray100,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 1,
    shadowRadius: 8,
    elevation: 8,
    width: isWeb() ? '100%' : '90%',
    height:'100%',
    marginTop:8,
    backgroundColor:'white',
  },
  timeLineMonth: {

    fontSize: 16,
    fontWeight: '700',
    color: '#101828',
    paddingRight: 4,

    top: 0,
    transform: [{rotate: '-360deg'}],
  },
  dateBox: {
    borderRadius: 4,
    backgroundColor: Colors.Custom.Info50,
    maxWidth: 65,
    height: 30,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginVertical: 2,
  },
  noteCard: {
    // flexDirection: 'row',
    // alignItems: 'center',
    backgroundColor: Colors.Custom.Gray50,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray100,
    borderWidth: 0.5,
    padding: 12,
    flex: 1,
  },
  noteCardIcon: {
    width: 38,
    height: 38,
    borderRadius: 19,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.Custom.Gray25,
    shadowRadius: 4,
    shadowColor: Colors.Custom.Gray200,
  },
  moreCardIcon: {
    shadowRadius: 4,
    shadowColor: Colors.Custom.Gray200,
    borderWidth: 1,
    backgroundColor: Colors.Custom.Base50,
    borderColor: Colors.Custom.PrimaryBtnColor,
    width: 32,
    height: 32,
    borderRadius: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  internalNoteDataCard: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderRadius: 8,
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 0.5,
    padding: 12,
    flex: 1,
    marginVertical: 2,
    marginTop: 2
  },
  outerNoteTaskCard: {
    backgroundColor: Colors.Custom.White,
    borderRadius: 6,
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 0.5,
    marginTop: 10,
    padding: 2
  },
});

export default styles;
