import React from "react";
import { Colors } from "../../../../styles/Colors";
import { ICommonSvgProps } from "../interfaces";

const InternalChatSvg = (props: ICommonSvgProps) => {
    const strokeColor = props.customStrokeColor ? props.customStrokeColor : Colors.Custom.Gray500;
    return (
        <svg 
            style={{width: props?.width ? props?.width : 'inherit', height:  props?.height ? props?.height : 'inherit'}}
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6666 1.83203C12.6666 1.83203 13.9999 2.63203 13.9999 4.4987C13.9999 6.36536 12.6666 7.16536 12.6666 7.16536M11.3333 3.16536C11.3333 3.16536 11.9999 3.56536 11.9999 4.4987C11.9999 5.43203 11.3333 5.83203 11.3333 5.83203M9.33325 4.4987C9.33325 5.97146 8.13934 7.16536 6.66659 7.16536C5.19383 7.16536 3.99992 5.97146 3.99992 4.4987C3.99992 3.02594 5.19383 1.83203 6.66659 1.83203C8.13934 1.83203 9.33325 3.02594 9.33325 4.4987ZM11.9999 12.1654C11.9999 13.8222 11.9999 15.1654 6.66659 15.1654C1.33325 15.1654 1.33325 13.8222 1.33325 12.1654C1.33325 10.5085 3.72107 9.16536 6.66659 9.16536C9.6121 9.16536 11.9999 10.5085 11.9999 12.1654Z" 
                stroke={strokeColor}
                stroke-linecap="round"
            />
        </svg>
    );
}

export default InternalChatSvg;
