import MarkdownPreview from '@uiw/react-markdown-preview';
import {HStack, Pressable, Text, TextArea, View, VStack} from 'native-base';
import React, {useEffect, useRef} from 'react';
import {
  default as AntDesign,
  default as AntIcon,
} from 'react-native-vector-icons/AntDesign';
import {ATTACHMENT_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {isAndroid, isIOS, isWeb} from '../../../../../utils/platformCheckUtils';
import {markdownReplyInListStyle} from '../../../../common/ChatUI/MessageBox/MessageBoxStyles';
import FileIconSvg from '../../../../common/Svg/FileIconSvg';
import ImageSvg from '../../../../common/Svg/ImageSvg';
import VideoFileIcon from '../../../../common/Svg/VideoFileIcon';
import {AttachmentMsgContentElem} from '../ConversationContainer/ConversationList/AttachmentMsgContentElem';
import {IMsgAttachment} from '../interfaces';
import {IReplyMessageView, MessagingCustomCardContent} from './interface';
import {replyMessageStyle} from './MessagingWindowStyles';
import MessagingCustomCard from '../../../../common/ChatUI/MessageBox/MessagingCustomCard/MessagingCustomCard';
import { IConversationMessage } from '../../../../../Interfaces/WebSocketEventInterfaces';
import { isJsonString } from './MessagingUtils';
import { MESSAGE_CUSTOM_CARD_KEY } from './MessagingCustomCard/CustomCardConst';
import { mentionPatternForMobile } from '../../../../common/AddTask/AddTaskUtils';
import { testID, TestIdentifiers } from '../../../../../testUtils';
const ReplyMessageView = (props: IReplyMessageView) => {
  const ReplyMessageViewRef: any = useRef(null);
  const singleAttachment = props?.selectedReply?.attachments?.find((item) => {
    return item.id == props.selectedReply.attachmentId;
  });
  const getReplyTypeMessageView = () => {
    let view: any = <></>;
    let iconView: any = <></>;
    if (props?.selectedReply?.attachments?.length > 0) {
      let tempSingleAttachment = {} as IMsgAttachment;
      if (singleAttachment?.file_type && props.selectedReply.attachmentId) {
        tempSingleAttachment = singleAttachment;
      } else if (props.selectedReply.attachments[0].file_type) {
        tempSingleAttachment = props.selectedReply.attachments[0];
      }
      let fileObj = {fileName: 'Attachment File'};
      if (tempSingleAttachment.data_url) {
        fileObj = getFileExtension(tempSingleAttachment.data_url);
        if (isWeb()) {
          switch (tempSingleAttachment.file_type) {
            case ATTACHMENT_TYPE.FILE:
              iconView = <FileIconSvg />;
              break;
            case ATTACHMENT_TYPE.IMAGE:
              iconView = <ImageSvg />;
              break;
            case ATTACHMENT_TYPE.VIDEO:
              iconView = <VideoFileIcon />;
              break;
            case ATTACHMENT_TYPE.AUDIO:
              iconView = (
                <AntIcon name="file1" size={14} color={Colors.primary['500']} {...testID('image')}/>
              );
              break;
            default:
              break;
          }
        } else {
          iconView = (
            <>
              <AttachmentMsgContentElem
                contentType={tempSingleAttachment?.file_type || ''}
              />
            </>
          );
        }
      } else {
        iconView = (
          <AntIcon name="file1" size={14} color={Colors.primary['500']} {...testID('image')}/>
        );
      }
      view = (
        <VStack {...testID('ReplyMessageContent')}>
          <View
            style={{
              overflow: 'scroll',
              maxHeight: 46,
              display: 'flex',
              paddingHorizontal: 8,
              paddingVertical: 5,
            }}
          >
            <Text noOfLines={2} {...testID('ReplyText')}>
              {props.selectedReply?.text}
            </Text>
          </View>
        <HStack
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 8,
            backgroundColor: '#fff',
            alignItems: 'center',
            height: 44,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 16,
                padding: 12,
              }}
            >
              {iconView}
            </View>
          </View>
          <View flex={1} paddingRight={2}>
            <Text {...testID('FileName')} size={'smMedium'} color={Colors.Custom.Gray700}>
              {fileObj?.fileName || ''}
            </Text>
          </View>
        </HStack>
        </VStack>
      );
    } else if (props.selectedReply.text || props?.isDeletedMessage) {
      const content: MessagingCustomCardContent = isJsonString(
        props.selectedReply.text
      )
        ? JSON.parse(props.selectedReply.text)
        : {};
      if (content?.resourceCode === MESSAGE_CUSTOM_CARD_KEY.CONTACT_CARD) {
      view = (
        <View
          style={{
            overflow: 'scroll',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 8,
            borderStyle: 'solid',
            display: 'flex',
            paddingHorizontal: isWeb() ? 8 : 0,
            paddingVertical: isWeb() ? 5 : 0,
            backgroundColor: '#fff',
          }}
          {...testID('ReplyMessageContent')}
        >
          <MessagingCustomCard
            conversationMessage={
              {
                content: props.selectedReply?.text

              } as IConversationMessage
            }
            navigation={props?.navigation}
          />
        </View>
      )
    } else {
      if (isWeb()) {
        view = (
          <MarkdownPreview
            style={{
              fontSize: 14,
              flex: 0.9,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: 100,
              minHeight: 40,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray200,
              borderRadius: 8,
              borderStyle: 'solid',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 16,
              backgroundColor: props?.isDeletedMessage ? Colors.Custom.Gray100 : '#fff',
              color:'black',
              paddingBottom: 10,
              paddingTop: 10,
              maxLines:2,
              fontStyle: props?.isDeletedMessage ? 'italic' : 'inherit',
            }}
            source={
              replyMsg()
            }
          />
        );
      } else if (isIOS() || isAndroid()) {
        view = (
          <View
            style={{
              // flex: 1,
              overflow: 'scroll',
              maxHeight: 56,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray200,
              borderRadius: 8,
              borderStyle: 'solid',
              display: 'flex',
              paddingHorizontal: 8,
              paddingVertical: 5,
              backgroundColor: props?.isDeletedMessage ? Colors.Custom.Gray100 : '#fff',
            }}
            {...testID('ReplyMessageContent')}
          >
            {/* <Markdown style={markdownReplyInListStyle} mergeStyle={false}>
              {props.selectedReply?.text}
            </Markdown> */}
            <Text
              noOfLines={2}
              style={{
                fontStyle: 'italic',
              }}
              {...testID('ReplyMessage')}
            >
              {replyMsg()}
            </Text>
          </View>
        );
      }
    }}
    return view;
  };

  const replyMsg= () =>{
    let plainString = props?.isDeletedMessage ? 'This Message is Deleted' : props.selectedReply.text;
    const isMentionPresent = mentionPatternForMobile.test(plainString);
    if (isMentionPresent) {
      plainString = plainString.replace(mentionPatternForMobile, (match, userName, type, uuid) => {
      return `@${userName}`;
      });
    }
      plainString =plainString?.replace(/[|-]/g, ' ')
      plainString = plainString?.replace(/\s+/g, ' ')
      return plainString.length >= 80
        ? `${plainString.slice(
            0,
            props.isInMessageList ? 80 : 90
          )}.....`
        : plainString;
  }

  const getFileExtension = (url: string): {fileName: string; ext: string} => {
    const strList = url?.split('/');
    let ext = '';
    let fileNameStr = 'File';
    if (strList && strList.length) {
      const fileName = strList[strList.length - 1];
      if (fileName) {
        const stringList = fileName.split('.');
        fileNameStr = fileName;
        ext = stringList[1];
      }
    }
    return {
      fileName: fileNameStr,
      ext,
    };
  };
  {
    isWeb() &&
      useEffect(() => {
        if (!props.isInMessageList) {
          let cannedResponseTop = '-150px';
          const offsetHeight = 0;
          // if (props.isCannedOpen) {
          //   cannedResponseTop = '-200px';
          //   offsetHeight = 65;
          // }
          if (ReplyMessageViewRef?.current) {
            if (ReplyMessageViewRef.current.offsetHeight < 150) {
              cannedResponseTop =
                '-' +
                (ReplyMessageViewRef.current.offsetHeight + offsetHeight) +
                'px';
            }
            if (ReplyMessageViewRef?.current?.style) {
              ReplyMessageViewRef.current.style.top = cannedResponseTop;
            }
          }
        }
      }, [props.selectedReply.id, props.isCannedOpen]);
  }
  return (
    <HStack
      style={[
        props.isInMessageList
          ? replyMessageStyle.listMessageView
          : isWeb()
          ? replyMessageStyle.ReplyMessageView
          : replyMessageStyle.ReplyMessageViewMob,
        {
          marginLeft: isWeb() ? 16 : props.isInMessageList ? 12 : 10,
          alignSelf: 'center',
          width: isWeb() ? '95%' : props.isInMessageList ? '100%' : '95%',
          backgroundColor: props.selectedReply.private ? '#FEFCF6' : '#FAF5FE',
          borderColor: props.selectedReply.private
            ? Colors.secondary['100']
            : '#EFE0FC',
          padding: 16,
          justifyContent: 'space-between',
        },
      ]}
      ref={ReplyMessageViewRef}
    >
      <View flexDirection={'row'} flex={1}>
        {isWeb() ? (
          <View
            style={{
              borderWidth: 2.5,
              width: 0,
              // height: 68,
              borderRadius: 8,
              borderColor: props.selectedReply.private
                ? Colors.secondary['500']
                : Colors.primary['300'],
              marginRight: 16,
            }}
          ></View>
        ) : null}
        <View
          style={{
            width: isWeb() ? 340 : '100%',
            flex: 1,
            marginRight: props.isInMessageList && !isWeb() ? 0 : 10,
          }}
          {...testID('ReplyMessageContent')}
        >
          <View
            style={{
              flexDirection:
                props.isInMessageList && !isWeb() ? 'column' : 'row',
              justifyContent: 'space-between',
              marginBottom: 6,
              paddingHorizontal: 4,
            }}
          >
            <Text {...testID('MessageSenderName')} noOfLines={1} size={'smBold'} color={Colors.Custom.Gray700}>
              {props.selectedReply.senderFullName || 'You'}
            </Text>
            <Text {...testID('DateString')} size={'xsNormal'} noOfLines={1} color={Colors.Custom.Gray500}>
              {props.selectedReply.dateStr}
            </Text>
          </View>
          {getReplyTypeMessageView()}
        </View>
      </View>

      {props.isInMessageList ? (
        <></>
      ) : (
        <VStack
          style={
            {
              // flexDirection: 'row',
              // justifyContent: 'space-between',
              // alignItems: 'center',
            }
          }
        >
          <Pressable
            style={{
              width: 28,
              height: 28,
              borderRadius: 100,
              borderWidth: 1,
              borderColor: Colors.secondary['200'],
              backgroundColor: Colors.secondary['100'],
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (props.onClearReplyMessage) {
                props.onClearReplyMessage();
              }
            }}
            {...testID(TestIdentifiers.closeBtn)}
          >
            <AntDesign
              size={17}
              name={'close'}
              color={Colors.secondary['700']}
              {...testID('image')}
            />
          </Pressable>
        </VStack>
      )}
    </HStack>
  );
};

export default ReplyMessageView;
