import { Button, Modal as ModalAntd, Popover, Table, Tooltip } from 'antd';
import {
    HStack, Pressable,
    Text,
    VStack,
    View,
    useMediaQuery
} from 'native-base';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../../../constants';
import { Colors } from '../../../../../../styles';
// import './../../Styles.css';
// import './../../Styles.css';
import { useEffect, useState } from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { findIsAllowToRunAutomation, getAccountUUID, getCurrentUserRoleCodes, getUserName, getUserRoles, navigateToOtherScreen } from '../../../../../../utils/commonUtils';
import PageBodyContainer from '../../../../../common/PageBodyContainer/PageBodyContainer';

import { useLazyQuery } from '@apollo/client';
import { Switch } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {
    FlowType,
    IWorkflowContextData,
    WorkflowContext
} from '../../../../../../context/WorkflowContext';
import { GET_USERS_WITH_INT_IDS } from '../../../../../../services/User/UserQueries';
import { AppointmentsSvg, CommunicationSvg, DatabaseSvg, FormsSvg, JourneysSvg, LabsSvg, MicroAutomationSvg, PatientLeadsSvg, TasksSvg } from '../../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg';
import { MetaDataFieldType } from '../../AddOrUpdateWorkflow';
import { getMicroAutomationUsedCountByWorkflowMasterId, getWorkflowExecutionLogByWorkflowMasterId, getWorkflowNodeMasterData, getWorkflowNodeMetaData } from '../../AddOrUpdateWorkflow/WorkflowApi';
import WorkflowExecutionGraph from '../Helper/WorkflowExecutionGraph';
import { getWorkflowBasePath, getWorkflowUrlFromFlowType } from '../../../workflowUtils';
import { TEAM_MEMBERS_ROLES_CODE } from '../../../../Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import ShieldCheckSvg from '../../../../../common/Svg/ShieldCheckSvg';
import { TAB_KEYS } from '../../../../constants';
import CommonService from '../../../../../../services/CommonService/CommonService';
import { testID } from '../../../../../../testUtils/Utils';
import { TestIdentifiers } from '../../../../../../testUtils/TestIdentifiers';
import { cloneDeep } from 'lodash';

interface IWorkflowData {
  id: string;
  name?: string;
  workflowMasterId?: string;
  triggerInfo?: {
    triggerCondition: string;
    triggerType: string;
  };
}


const DisplayAlertOnMicroAutomationAction = (props: {
    microAutomationAlertData: {userInputFields?:any, isInactive: boolean, data: { id: string } };
    setMicroAutomationAlertData: any;
  }) => {
    const workflowList = (
      props?.microAutomationAlertData?.userInputFields || []
    )
      .map((data: any) => {
        return {
          name: data?.node?.graph?.workflow.name,
          workflowMasterId: data?.node?.graph?.workflow.workflowMasterId,
        };
      })
      .filter(
        (item: any, index: number, self: any) =>
          index ===
          self.findIndex(
            (record: any) => record?.workflowMasterId === item?.workflowMasterId
          )
      );

    return (
        <ModalAntd
        title='Cannot Disable/Delete Micro-Automation in use'
        open={props.microAutomationAlertData?.isInactive}
        closable={false}
        footer={[
          <Button
            key='close'
            onClick={() => {
              props.setMicroAutomationAlertData({
                data: {id: ''},
                userInputFields: [],
                isInactive: false,
              });
            }}
          >
            Close
          </Button>,
        ]}
      >
        <VStack maxHeight={'500px'} overflowY={'auto'}>
          <HStack>
            {
              'This Micro-Automation cannot be disabled or deleted as it is currently utilized in below Automations/Journey:'
            }
          </HStack>
          {
            workflowList?.map((workflow:any, index:number)=>{
                return <HStack>{(index+1) + '. ' + workflow?.name}</HStack>
            })
          }
        </VStack>
      </ModalAntd>
    );
  };


//--------------------------------------------------------------------------------------------------------------------------
const ShowUserInfo = (props: { userId: any, userMap: any, customStyle: any }) => {
    const loggedInUserRole = getCurrentUserRoleCodes();
    const userRoles = getUserRoles(props.userMap[props.userId])
    const userName = props.userMap[props.userId]?.name || ''
    const name = !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) && !loggedInUserRole.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS) &&
        (userRoles?.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) || userRoles?.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS))?
         'Fold Health' :  userName
    return (
        <span style={props.customStyle}>
            <>{name}</>
        </span>
    )
}

const NewWorkflowTableView = (props: { setClonePopup: any, setExecuteStatePopUp: any, showWorkflowPopUp: any, isIVRFlow: boolean, page: { total: number, page: number, pageSize: number }, setPage: any, loading: boolean, onWorkflowStatusChange: any, height: number, workflowList: any, onWorkflowDelete: any }): JSX.Element => {
    const [editActionVisible, setEditActionVisible] = useState(false);
    const [getUserByIds] = useLazyQuery(GET_USERS_WITH_INT_IDS);
    const [userMap, setUserMap] = useState<any>({ map: {} })
    const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
        { maxWidth: IPAD_WIDTH },
        { maxWidth: IPAD_MINI_WIDTH },
    ]);
    const commonService = CommonService.getCommonServiceInstance();
    const tenantId = getAccountUUID();
    const workflowService = commonService.workflowService;

    const isAllowToRunAutomation = findIsAllowToRunAutomation();



    const [workflowContext, setWorkflowContext] = useState<IWorkflowContextData>({
        nodeMasterDataMap: {},
        nodeMetaData: undefined,
        flowType: FlowType.all,
        setNodeMasterDataMap: (data: any) => {
            setWorkflowContext((prev: any) => ({ ...prev, nodeMasterDataMap: data }));
        },
    });




    useEffect(() => {
        getWorkflowNodeMasterData(
            FlowType.all,
            (nodeMasterData: any) => {
                const libNodeIdList = nodeMasterData.libNodeList;
                const nodeMasterDataMap = nodeMasterData.nodeMap;
                const frequencyList = nodeMasterData.frequencyList;

                setWorkflowContext((prev) => ({
                    ...prev,
                    nodeMasterDataMap: nodeMasterDataMap,
                }));
            },
            (error: any) => {

            }
        );
        getWorkflowNodeMetaData(
            FlowType.all,
            MetaDataFieldType.metaData,
            '',
            (data: any) => {
                setWorkflowContext((prev) => ({
                    ...prev,
                    nodeMetaData: {
                        frequencyList: data?.frequencyList || [],
                        triggerUnits: data?.triggerUnits || [],
                        durationUnits: data?.durationUnits || [],
                        reminderUnits: data?.reminderUnits || [],
                    },
                }));
            },
            (error: any) => {

            }
        );


    }, [])


    const loadUserList = async () => {
        const userIds: any = [];
        (props.workflowList || []).forEach((workflowData: any) => {
            if (workflowData?.userId) {
                const userId = parseInt(workflowData?.userId)
                if (userIds.indexOf(userId) == -1) {
                    userIds.push(userId)
                }
            }
        })
        if (userIds.length > 0) {
            const data = await getUserByIds({
                variables: {
                    userIds: userIds
                }
            });
            const newMap: any = {};
            (data?.data?.users || []).forEach((user: any) => {
                newMap[user?.id] = user;
            })
            setUserMap(newMap)
        }
    }

    useEffect(() => {
        if (!props.workflowList?.length) {
            return;
        }

        loadUserList();

    }, [props.workflowList])



    //fetching workflows




    // const getAuthorById: (id: any){

    // }


    const nodeMetaData = workflowContext.nodeMetaData;
    const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
    const isSmallScreen = isIPadMiniScreen || isIPadScreen;
    const navigate = useNavigate();
    const elementMargin = 5;
    const isShowNodeDisplayTemplate = true;
    const [loadingList, setLoadingList] = useState<any>([])
    const [popOverData, setPopOverData] = useState<any>({});
    const navigationPathMap: any = {
        [FlowType.patients]: '/admin/patients/automation',
        [FlowType.communication]: '/admin/communication/automation',
        [FlowType.scheduling]: '/admin/schedule/automation',
        [FlowType.form]: '/admin/forms/automation',
        [FlowType.journeys]: '/admin/journeys/automation',
        [FlowType.task]: '/admin/taskManagement/automation',
        [FlowType.lab]: '/admin/labs/automation',
        [FlowType.order]: '/admin/order/automation',
        [FlowType.DB_TRIGGER]: '/admin/database/automation',
        [FlowType.note]: '/admin/note/automation',
    }
    const editWorkflow = (workflow: any) => {
        setPopOverData((prev: any) => {
            const workflowId = workflow.id || '';
            return { ...prev, [workflowId]: !prev[workflowId] }
        });

        const navigationPath = navigationPathMap[workflow.flowType] || navigationPathMap[FlowType.form];
        navigateToOtherScreen(navigate, navigationPath +
            '/update' +
            '/' +
            workflow.workflowMasterId +
            '?flowType=' +
            workflow.flowType)
    }

    const showWorkflowExecutionLog = (workflow: any) => {
        const navigationPath = navigationPathMap[workflow.flowType] || navigationPathMap[FlowType.form];
        navigateToOtherScreen(navigate, navigationPath +
            '/execution/audit' +
            '/' +
            workflow.workflowMasterId +
            '?flowType=' +
            workflow.flowType)
    }

    const deleteWorkflow = (workflow: any) => {
        props.onWorkflowDelete(workflow);
    }


    const [isShowInactiveAlertData, setIsShowInactiveDataAlert] = useState({ isInactive: false, data: { id: '' } });
    const [microAutomationAlertData, setMicroAutomationAlertData] = useState({userInputFields:[], isInactive: false, data: { id: '' } });
    const [isShowActiveAlertData, setIsShowActiveDataAlert] = useState
    <{
        isActive: boolean;
        data: IWorkflowData;
        showPopGroupEnabledConfirmation: boolean;
        isFirstTimeEnabled?: boolean;
    }>({ isActive: false, data: {id: ''} as IWorkflowData, showPopGroupEnabledConfirmation: false, isFirstTimeEnabled: false });

    const getPopGroupRules = (record: any): {ids: string[], names: string[]} => {
     const isPopGroupTrigger = record.eventType === 'addOrUpdateMemberInPopGroup' && record.entity === 'popGroup';
     if (!isPopGroupTrigger) {
      return {ids: [], names: []};
     }
     let ruleIds: string[] = [];
     let isUserAllowedToExecuteOnExistingContact = false;
     const ruleIdNames: string[] = [];
     const nodes = record?.graph?.nodes || [];
     nodes.forEach((node: any) => {
       if (node?.data?.nodeType === 'ContactAddedInPopGroupFilterNode') {
        const userInputFieldList = node?.data?.metaData?.userInputFieldList || [];
        (userInputFieldList).forEach((userInputField: any) => {
          if (userInputField.key == 'popGroups' && userInputField?.value?.idList?.length > 0 && userInputField.selectedComparisonOperator.value == 'IN') {
            ruleIds = ruleIds.concat(userInputField?.value?.idList || []);
            (userInputField?.value?.selectedOptions || []).forEach((option: any) => {
              ruleIdNames.push(option?.name);
            });
          }
          if (userInputField.key == 'allowToExecuteOnExistingContact' && userInputField?.value == 'YES') {
            isUserAllowedToExecuteOnExistingContact = true;
          }
        });
       }
     });
     return isUserAllowedToExecuteOnExistingContact ? {ids: ruleIds, names: ruleIdNames} : {ids: [], names: []};
    }

    const postWorkflowEnabled = async () => {
      const enabledWorkflow = isShowActiveAlertData?.data;
      const workflowMasterId = cloneDeep(enabledWorkflow?.workflowMasterId || {});
      setLoadingList((prev: any) => {
        const newLoadingList = prev.filter((id: string) => {
          return isShowActiveAlertData?.data?.id != id;
        });
        return newLoadingList;
      });
      setIsShowActiveDataAlert({
        data: {id: ''},
        isActive: false,
        showPopGroupEnabledConfirmation: false
      });
      if (!workflowMasterId) {
        return false;
      }
      const rules = getPopGroupRules(enabledWorkflow);
      const data = {
        segmentIdList: rules?.ids || [],
        isSkipEmptyListExecution: true
      };
      const workflowTriggerResponse = await workflowService.post(`/workflow/event/trigger`, {
        data: data,
        eventType: 'executeStaticWorkflow',
        entity: 'workflow',
        workflowMasterList: [
          {
            workflowMasterId: workflowMasterId,
            isPersonLevelWorkflow: true,
            donNotAllowToExecuteOnExistingContact: true,
            formNodeList: []
          }
        ],
        'account-uuid': tenantId,
      });
    }

    const [searchParams, setSearchParams] = useSearchParams();

    const defaultParamsOnTabOpen = (tabKey: string, flowType: FlowType | undefined, workflowMasterId: string | undefined, isDrawerOpen: string | undefined) => {
      if (workflowMasterId && isDrawerOpen !== undefined && flowType !== undefined) {
        setSearchParams({ workflowMasterId: workflowMasterId, flowtype: flowType, key: tabKey, isdraweropen: isDrawerOpen });
      }
    };

    const variables = {
        "limit": 10,
        "offset": 0,
        "boolExp": {
            "isDeleted": { "_eq": false },
            "isDraftOnly": { "_neq": true },
            "name": { "_ilike": "%Patient%" },
            "tenantId": { "_eq": "02a088ac-9a36-4886-b3d8-41ba2c74ba5b" }
        }
    }


    const columnTitleStyle: any = {
        fontSize: '13px',
        FontWeight: '500',
        fontStyle: 'normal',
        letterSpacing: '1px',
        lineHeight: '110%',
        // textTransform: 'uppercase',
        // justifycontent: 'center'
    };
    const automationColumns = [
        {
            title: (
                <div style={columnTitleStyle}>Automations</div>
            ),
            key: 'automations',
            width: '24%',
            render: (record: any) => {
                let automationIcon;

                if (record.flowType === 'PATIENTS') {
                    automationIcon = <PatientLeadsSvg></PatientLeadsSvg>
                }
                // else if (record.flowType === 'APPOINTMENTS') {
                //     automationIcon = <SchedulingSvg></SchedulingSvg>
                // }
                else if (record.flowType === 'FORM') {
                    automationIcon = <FormsSvg></FormsSvg>
                }
                else if (record.flowType === 'CARE_JOURNEY') {
                    automationIcon = <JourneysSvg></JourneysSvg>
                }
                else if (record.flowType === 'TASK') {
                    automationIcon = <TasksSvg></TasksSvg>
                }
                else if (record.flowType === 'LAB') {
                    automationIcon = <LabsSvg></LabsSvg>
                }
                else if (record.flowType === 'COMMUNICATION') {
                    automationIcon = <CommunicationSvg></CommunicationSvg>
                }
                else if (record.flowType === 'MICRO_AUTOMATION') {
                    automationIcon = <MicroAutomationSvg></MicroAutomationSvg>
                }
                else if (record.flowType === 'DB_TRIGGER') {
                    automationIcon = <DatabaseSvg></DatabaseSvg>
                }
                else if (record.flowType === 'SCHEDULING') {
                    automationIcon = <AppointmentsSvg></AppointmentsSvg>
                }
                else if (record.flowType === 'JOURNEYS') {
                    automationIcon = <JourneysSvg></JourneysSvg>
                }
                else if(record.flowType == 'VISIT_NOTE'){
                    automationIcon = <TasksSvg></TasksSvg>
                }
                else {
                    // automationIcon = <defaultAutomationSvg></defaultAutomationSvg>
                }
                return <HStack space={2} width={'full'} flex={1}>
                    <HStack flex={1}>{automationIcon}</HStack>
                    <HStack flex={9}>
                        {<Text fontWeight={400} fontSize={'14px'} fontStyle={'normal'} color='#000'>
                            {record?.name}
                        </Text> || ''}
                    </HStack>
                </HStack >
            }
        },
        {
            title: (
                <div style={columnTitleStyle}>Description</div>
            ),
            dataIndex: 'description',
            key: 'description',
            // width: '48%',
            render: (text: any) => {
                const descriptionCustomStyle: any = {
                    fontSize: 14,
                    fontWeight: "400",
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.50)',
                }
                return (
                    <HStack>
                        <Text style={descriptionCustomStyle}>{text}</Text>
                    </HStack>
                )
            }
        },
        {
            title: (
                <div style={columnTitleStyle}>Last Modified By</div>
            ),
            key: 'author',
            dataIndex: 'userId',
            width: '16%',
            render: (userId: any) => {
                const authorDataFontStyle = {
                    fontSize: '14px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000',
                    lineHeight: 'normal'
                }
                return (
                    <ShowUserInfo userMap={userMap} userId={userId} customStyle={authorDataFontStyle}></ShowUserInfo>
                );
            }

        },
        {
            title: (
                <div style={columnTitleStyle}>Status</div>
            ),
            key: 'status',
            width: '8%',
            onCell: () => {
                return {
                  onClick: (event:any) => {
                    event.stopPropagation();
                  },
                };
            },
            render: (record: any) => {
                return (
                    !props.isIVRFlow ? (
                        <span style={{ alignItems: 'center', display: 'flex', justifyContent: 'left' }}>
                            <Tooltip title={!isAllowToRunAutomation ? 'Fold users not allowed to trigger automation' : ''}>
                            <Switch
                                size='small'
                                disabled={!isAllowToRunAutomation}
                                checked={record.isEnabled}
                                loading={loadingList.indexOf(record.id) !== -1}
                                // checkedChildren="Active"
                                // unCheckedChildren="Inactive"
                                onClick={(a, e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                style={{
                                    backgroundColor: record.isEnabled ? Colors.primary[300] : Colors.muted[500],
                                }}
                                onChange={async (checked) => {
                                    if(record?.flowType == FlowType.MICRO_AUTOMATION && !checked){
                                      getMicroAutomationUsedCountByWorkflowMasterId(
                                        {
                                          microAutomationWorkflowMasterId:
                                            record?.workflowMasterId,
                                        }
                                      ).then((response: any) => {
                                        const userInputFields =
                                          response?.data?.data
                                            ?.userInputFields || [];
                                        if (userInputFields.length) {
                                          setMicroAutomationAlertData({
                                            data: record,
                                            userInputFields:
                                              userInputFields || [],
                                            isInactive: true,
                                          });
                                        } else {
                                          setIsShowInactiveDataAlert({
                                            data: record,
                                            isInactive: true,
                                          });
                                        }
                                      });
                                    }

                                    else if (!checked) {
                                      setIsShowInactiveDataAlert({ data: record, isInactive: true});
                                    } else {
                                      let isPopGroupTriggerAutomation = false;
                                      let isFirstTimeEnabled = false;
                                      const workflowMasterId = record?.workflowMasterId || undefined
                                      const popGroupRules = getPopGroupRules(record);
                                      if (popGroupRules?.ids?.length > 0 && workflowMasterId) {
                                        const workflowExecutionLogResponse = await getWorkflowExecutionLogByWorkflowMasterId(workflowMasterId);
                                        const workflowExecutionLog = workflowExecutionLogResponse?.data?.workflowExecutionLogs || [];
                                        isPopGroupTriggerAutomation = true;
                                        if (workflowExecutionLog?.length && workflowExecutionLog?.[0]?.workflowState === 'WORKFLOW_COMPLETE') {
                                          isFirstTimeEnabled = false;
                                        } else {
                                          isFirstTimeEnabled = true;
                                        }
                                      } else {
                                        isPopGroupTriggerAutomation = false;
                                      }
                                      setIsShowActiveDataAlert({ data: record, isActive: !isPopGroupTriggerAutomation, showPopGroupEnabledConfirmation: isPopGroupTriggerAutomation, isFirstTimeEnabled: isFirstTimeEnabled });
                                    }
                                }}
                            />
                            </Tooltip>
                        </span>
                    ) : null // Render nothing if isIVRFlow is true
                )
            },
        },

        {
            title: (
                <div style={columnTitleStyle}>Actions</div>
            ),
            key: 'actions',
            width: '12%',
            onCell: () => {
                return {
                  onClick: (event:any) => {
                    event.stopPropagation();
                  },
                };
            },
            render: (record: any) => {
                const showViewAction = !record.isIVRFlow && record?.triggerInfo?.triggerCondition === 'ForAllPatientWithFilter';

                const content = (
                    <div>
                        <p>
                            {/* edit workflow */}
                            <View>
                                <Pressable
                                    onPress={() => {
                                        editWorkflow(record)
                                    }}
                                >

                                    <HStack
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 2
                                        }}
                                        cursor={'pointer'}
                                    >
                                        <Feather
                                            name="edit-3"
                                            size={18}
                                            color={Colors.Custom.Gray500}
                                        />
                                        <Text marginLeft={3} fontSize={12} fontWeight={400} color={'#000'}>Edit</Text>
                                    </HStack>
                                </Pressable>
                            </View >
                        </p>
                        <p>
                            {/* delete workflow */}
                            <View>
                                <Pressable
                                    onPress={() => {
                                        setPopOverData((prev: any) => {
                                            const workflowId = record.id || '';
                                            return { ...prev, [workflowId]: !prev[workflowId] }
                                        });
                                        if (
                                          record?.flowType ==
                                          FlowType.MICRO_AUTOMATION
                                        ) {
                                          getMicroAutomationUsedCountByWorkflowMasterId(
                                            {
                                              microAutomationWorkflowMasterId:
                                                record?.workflowMasterId,
                                            }
                                          ).then((response: any) => {
                                            const userInputFields =
                                              response?.data?.data
                                                ?.userInputFields || [];
                                            if (userInputFields?.length) {
                                              setMicroAutomationAlertData({
                                                data: record,
                                                userInputFields:
                                                  userInputFields,
                                                isInactive: true,
                                              });
                                            } else {
                                              deleteWorkflow(record);
                                            }
                                          });
                                        } else {
                                          deleteWorkflow(record);
                                        }
                                    }}
                                >
                                    <HStack
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 2
                                        }}
                                        cursor={'pointer'}
                                    >
                                        <MaterialCommunityIcon
                                            size={18}
                                            // name="delete-outline"
                                            name="trash-can-outline"
                                            color={Colors.Custom.Gray600}
                                        />
                                        <Text marginLeft={3} fontSize={12} fontWeight={400} color={'#000'}>Delete</Text>
                                    </HStack>
                                </Pressable>
                            </View>
                        </p>
                        <p>
                            {/* copy-content */}
                            <View>
                                <Pressable
                                    onPress={() => {
                                        props.setClonePopup(record)
                                    }}
                                >

                                    <HStack
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 2
                                        }}
                                        cursor={'pointer'}
                                    >
                                        <MaterialCommunityIcon
                                            size={18}
                                            name="content-copy"
                                            color={Colors.Custom.Gray600}
                                        />
                                        <Text marginLeft={3} fontSize={12} fontWeight={400} color={'#000'}>Copy</Text>
                                    </HStack>
                                </Pressable>
                            </View>
                        </p>
                    </div>
                )

                return (

                    <HStack width={'100%'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>

                        <View width={'33%'} alignItems={'center'} justifyContent={'center'}>

                            {showViewAction && (
                                <Tooltip title={!isAllowToRunAutomation ? 'Fold users can  Run Automation only on Members' : ''}>
                                <Pressable
                                    onPress={() => {
                                        setPopOverData((prev: any) => {
                                            const workflowId = record.id || '';
                                            return { ...prev, [workflowId]: !prev[workflowId] };
                                        });
                                        props.setExecuteStatePopUp(record);
                                    }}

                                >
                                    <HStack
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 2,
                                            justifyContent: 'center'
                                        }}
                                        cursor={'pointer'}
                                    >
                                        <Feather
                                            name="play"
                                            size={22}
                                            color={Colors.Custom.Gray600}
                                        />

                                    </HStack>
                                </Pressable>
                                </Tooltip>

                            )}
                        </View>

                        {showViewAction ? (
                            <View justifyContent={'center'} alignItems={'flex-end'} background={Colors.Custom.Gray200} width={'1px'} height={5} >
                            </View>
                        ) : null}


                        {!props.isIVRFlow &&
                            <View width={'33%'} >

                                <Pressable
                                    onPress={() => {
                                        setPopOverData((prev: any) => {
                                            const workflowId = record.id || '';
                                            return { ...prev, [workflowId]: !prev[workflowId] }
                                        });
                                        props.showWorkflowPopUp(record)
                                        defaultParamsOnTabOpen(TAB_KEYS.OVERVIEW,record?.flowType,record?.workflowMasterId,'true')
                                    }}
                                >
                                    <HStack
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 2,
                                            justifyContent: 'center'
                                        }}
                                        cursor={'pointer'}
                                    >

                                        <WorkflowExecutionGraph
                                            workflowData={record}
                                            isNewUi={true}
                                            isIcon={true}

                                            setDisabledStatus={(record: any, isDisabled: boolean) => {
                                                record.isDisabled = isDisabled;
                                            }}
                                        ></WorkflowExecutionGraph>

                                    </HStack>
                                </Pressable>

                            </View>
                        }

                        <View justifyContent={'center'} alignItems={'flex-end'} background={Colors.Custom.Gray200} width={'1px'} height={5}>
                        </View>

                        <View width={'33%'} >
                            <Popover content={content} trigger="hover" placement='bottom'>
                                <HStack
                                    style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        paddingLeft: 2,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Feather
                                        name="more-horizontal"
                                        size={22}
                                        color={Colors.Custom.Gray600}
                                    />

                                </HStack>
                            </Popover>
                        </View>

                    </HStack>

                );
            }
        },
    ]


    return (
      <>
        <WorkflowContext.Provider value={workflowContext}>
          <PageBodyContainer style={{height: props.height}}>
            {/* {props.loading || props.workflowList?.length ? <></> : <>No History</>} */}

            <Table
              scroll={{y: window.innerHeight - 270 + 'px'}}
              pagination={{
                position: ['bottomCenter'],
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: props?.page?.pageSize,
                current: !props?.loading ? props?.page?.page + 1 : 0,
                total: !props?.loading ? props?.page?.total : 0,
                showSizeChanger: true,
                onChange(page, pageSize) {
                  if (props.setPage) {
                    props.setPage(() => {
                      return {...props.page, page: page - 1 ,pageSize : pageSize};
                    });
                  }
                },
              }}
              loading={props.loading}
              columns={automationColumns}
              dataSource={props.workflowList}
              onRow={(data) => {
                return {
                  onClick: (event: any) => {
                    if (event?.target?.nodeName === 'INPUT') {
                      return;
                    }
                    const navigationPath = getWorkflowUrlFromFlowType(
                      data.flowType
                    );
                    navigate(
                      navigationPath +
                        '/update' +
                        '/' +
                        data.workflowMasterId +
                        '?flowType=' +
                        data.flowType
                    );
                  },
                };
              }}
            ></Table>
          </PageBodyContainer>



          <DisplayAlertOnMicroAutomationAction microAutomationAlertData={microAutomationAlertData} setMicroAutomationAlertData={setMicroAutomationAlertData}></DisplayAlertOnMicroAutomationAction>

          <ModalAntd
            className='workflowModalV2'
            closable ={false}
            title={
              <View>
                <VStack alignItems={'Center'}>
                  <ShieldCheckSvg></ShieldCheckSvg>
                  <Text fontWeight={500} fontSize={15} paddingTop={2}>
                    {'Disable Automation ?'}
                  </Text>
                </VStack>
              </View>
            }
            open={isShowInactiveAlertData.isInactive}
            onCancel={() => {
              setIsShowInactiveDataAlert({data: {id: ''}, isInactive: false});
            }}
            bodyStyle={{
              padding: 0,
              textAlign: 'center',
            }}
            footer={
              <View alignItems={'Center'}>
                <HStack space={4}>
                  <Button
                    key='disable'
                    onClick={() => {
                      setLoadingList((prev: any) => {
                        const newLoadingList = [...prev];
                        newLoadingList.push(isShowInactiveAlertData?.data?.id);
                        return newLoadingList;
                      });
                      props.onWorkflowStatusChange(
                        isShowInactiveAlertData?.data?.id,
                        false,
                        () => {
                          setLoadingList((prev: any) => {
                            const newLoadingList = prev.filter((id: string) => {
                              return isShowInactiveAlertData?.data?.id != id;
                            });
                            return newLoadingList;
                          });
                        }
                      );
                      setIsShowInactiveDataAlert({
                        data: {id: ''},
                        isInactive: false
                      });
                    }}
                    style={{width: 156, height: 32}}
                  >
                    {'Disable'}
                  </Button>
                  <Button
                    key='notdisable'
                    type='primary'
                    style={{width: 156, height: 32}}
                    onClick={() => {
                      setIsShowInactiveDataAlert({
                        data: {id: ''},
                        isInactive: false,
                      });
                    }}
                  >
                    {'Don’t disable'}
                  </Button>
                </HStack>
              </View>
            }
            width={500}
          >
            <Text color={Colors.FoldPixel.GRAY200}>
              {
                'Disabling this automation will halt all ongoing activities and scheduled actions. This means no further emails, reminders, or tasks will be generated or sent out as part of this specific process.'
              }
              <br></br>
              <br></br>
              {
                'Ensure you\'ve completed any critical actions or have an alternative plan in place before proceeding with deactivation.'
              }
            </Text>
          </ModalAntd>

          <ModalAntd
            className="workflowModalV2"
            closable ={false}
            title={
              <View>
                <VStack alignItems={'Center'}>
                  <ShieldCheckSvg></ShieldCheckSvg>
                  <Text fontWeight={500} fontSize={15} paddingTop={2}>
                    {'Enable Automation ?'}
                  </Text>
                </VStack>
              </View>
            }
            open={isShowActiveAlertData.isActive}
            onCancel={() => {
              setIsShowActiveDataAlert({data: {id: ''}, isActive: false, showPopGroupEnabledConfirmation: false});
            }}
            bodyStyle={{
              padding: 0,
              textAlign: 'center',
            }}
            footer={
              <View alignItems={'center'}>
                <HStack space={4}>
                  <Button
                    key='back'
                    onClick={() => {
                      setLoadingList((prev: any) => {
                        const newLoadingList = [...prev];
                        newLoadingList.push(isShowActiveAlertData?.data?.id);
                        return newLoadingList;
                      });
                      props.onWorkflowStatusChange(
                        isShowActiveAlertData?.data?.id,
                        true,
                        () => {
                          setLoadingList((prev: any) => {
                            const newLoadingList = prev.filter((id: string) => {
                              return isShowActiveAlertData?.data?.id != id;
                            });
                            return newLoadingList;
                          });
                          setIsShowActiveDataAlert(prev => ({...prev, showPopGroupEnabledConfirmation: false, data: {id: ''}, isActive: false}));
                        }
                      );
                    }}
                    style={{width: 156, height: 32}}
                  >
                    {'Enable'}
                  </Button>
                  <Button
                    key='notenable'
                    type='primary'
                    style={{width: 156, height: 32}}
                    onClick={() => {
                      setIsShowActiveDataAlert({
                        data: {id: ''},
                        isActive: false,
                        showPopGroupEnabledConfirmation: false
                      });
                    }}
                  >
                    {'Don’t enable'}
                  </Button>
                </HStack>
              </View>
            }
            width={500}
          >
            <Text color={Colors.FoldPixel.GRAY200}>
              {
                'You are about to enable an automation which could impact your current workflow. Are you sure you want to enable it?'
              }
            </Text>
          </ModalAntd>

          <ModalAntd
            className="workflowModalV2"
            closable ={false}
            title={
              <View key="popGroupEnabledConfirmation" {...testID(TestIdentifiers.popGroupEnabledConfirmationLabel)}>
                <VStack alignItems={'Center'}>
                  <ShieldCheckSvg></ShieldCheckSvg>
                  <Text fontWeight={500} fontSize={15} paddingTop={2}>
                    {'Enable Population group'}
                  </Text>
                </VStack>
              </View>
            }
            open={isShowActiveAlertData.showPopGroupEnabledConfirmation}
            onCancel={() => {
              setIsShowActiveDataAlert(prev => ({...prev, showPopGroupEnabledConfirmation: false, data: {id: ''}, isActive: false}));
            }}
            bodyStyle={{
              padding: 0,
              textAlign: 'center',
            }}
            footer={
              <View alignItems={'center'}>
                <HStack space={4}>
                  <Button
                    key='enable'
                    {...testID(TestIdentifiers.enableBtn)}
                    onClick={() => {
                      setLoadingList((prev: any) => {
                        const newLoadingList = [...prev];
                        newLoadingList.push(isShowActiveAlertData?.data?.id);
                        return newLoadingList;
                      });
                      props.onWorkflowStatusChange(
                        isShowActiveAlertData?.data?.id,
                        true,
                        postWorkflowEnabled
                      );
                    }}
                    style={{width: 156, height: 32}}
                  >
                    {'Enable'}
                  </Button>
                  <Button
                    key='cancelEnabled'
                    {...testID(TestIdentifiers.cancelBtn)}
                    type='primary'
                    style={{width: 156, height: 32}}
                    onClick={() => {
                      setIsShowActiveDataAlert(prev => ({...prev, showPopGroupEnabledConfirmation: false, data: {id: ''}, isActive: false}));
                    }}
                  >
                    {'Cancel'}
                  </Button>
                </HStack>
              </View>
            }
            width={500}
          >
            {isShowActiveAlertData?.isFirstTimeEnabled && (
              <Text color={Colors.FoldPixel.GRAY200} {...testID(TestIdentifiers.popGroupEnabledConfirmationMsg)}>
               {'There are one or more population groups selected within this automation. Enabling this automation will result in action being taken for existing members within these groups. If you wish to proceed, please click Enable. If you want to hold on, please click Cancel. You can change this setting in the automation on the population group selection filter.'}
              </Text>
            )}
            {!isShowActiveAlertData?.isFirstTimeEnabled && (
              <Text color={Colors.FoldPixel.GRAY200} {...testID(TestIdentifiers.popGroupEnabledConfirmationMsg)}>
                {'There are one or more population groups selected within this automation. Enabling this automation will result in action being taken for any additional members added to the groups since the automation was last disabled. If you wish to proceed, please click Enable. If you want to hold on, please click Cancel. You can change this setting in the automation on the population group selection filter.'}
              </Text>
            )}
          </ModalAntd>
        </WorkflowContext.Provider>
      </>
    );
}

export default NewWorkflowTableView;


