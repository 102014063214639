import React from 'react';
import {Colors} from '../../../../../../../styles/Colors';
import {
  getDiffInMonths,
  getMomentObj,
} from '../../../../../../../utils/DateUtils';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {ICondition} from '../../../../../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {StyleProp, Text, TextStyle} from 'react-native';

export const chronicCondtionsView = (params: {
  conditionNametextStyles: StyleProp<TextStyle>;
  monthTextStyles: StyleProp<TextStyle>;
  chronicConditions?: ICondition[];
}) => {
  const {conditionNametextStyles, monthTextStyles, chronicConditions} = params;
  return (
    <Stack direction={'row'} style={{justifyContent: 'flex-start', maxWidth: '100%'}}>
      {chronicConditions && chronicConditions?.length > 0 ? (
        <Stack direction={'column'} style={{maxWidth: '100%'}}>
          {chronicConditions?.map((condition: ICondition, index: number) => {
            const diffInMonth = condition?.onSetDateTime
              ? Math.floor(
                  getDiffInMonths(
                    condition?.onSetDateTime,
                    getMomentObj(new Date())
                  )
                )
              : 0;
            return (
            <Stack direction={'row'} key={index} style={{maxWidth: '100%'}}>
                <Text style={{maxWidth: '100%'}}>
                  {condition?.name}
                  {!!diffInMonth && (
                    <Text style={monthTextStyles}>
                      {`(${diffInMonth}m)`}{' '}
                    </Text>
                  )}
                  {index < chronicConditions?.length - 1 ? ',' : '.'}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Text style={{color: Colors.Custom.Gray900}}>{'-'}</Text>
      )}
    </Stack>
  );
};
