import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Modal, notification} from 'antd';
import {Observation, QuestionnaireItem} from 'fhir/r4';
import {Box, Button, useToast, View, Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES,
} from '../../../../../../constants';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../../../constants/ActionConst';
import {memberConsent} from '../../../../../../constants/ConstantValues';
import {CUSTOM_FIELDS, FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../constants/FhirConstant';
import {
  BUTTON_TYPE,
  CONSENT_CONST,
  CONTACT_SOURCE_OF_CREATION,
  DISPLAY_SLASH_DATE_FORMAT,
  HTTP_ERROR_CODE,
  OPTION_SELECTION_TYPE,
  OPTION_TYPE,
  PERSON_TYPES,
  PREFERRED_CHANNEL_CODE,
  RIGHT_SIDE_CONTAINER_CODE,
  SELECT_OPTION_DATA_TYPE,
} from '../../../../../../constants/StringConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {IEhrCapability, IMlov, IVitalData} from '../../../../../../Interfaces';
import {
  LeadQueries,
  MlovQueries,
  NoteQueries,
  PatientQueries,
} from '../../../../../../services';
import {
  CreatePatient,
  addOrUpdateLeadInIntegration,
  createPatientUsingIntegration,
  getAllLocations,
  getCurrentPatientDeceasedData,
  getDeceasedDate,
  getPatientDetails,
  getPatientsByNameAndBirthDate,
  getVitalsWithFilters,
  updatePatient,
} from '../../../../../../services/CommonService/AidBoxService';
import EmployeeQueries from '../../../../../../services/Employee/EmployeeQueries';
import EmployerQueries from '../../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import TagQueries from '../../../../../../services/Tags/TagQueries';
import {
  getBirthSexLabel,
  getCapability,
  getGenderIdentityLabel,
  getPossibleValuesByOperationName,
  getResourceAbilities,
  getSexAtBirthTransformValues,
  getVitalListFromCapability,
  isOrderTypeEnabled,
} from '../../../../../../utils/capabilityUtils';
import {
  validateInsuranceFields,
  displayToCodeMapAthena,
  displayToCodeMapElation,
  getAccountId,
  getAccountUUID,
  getContactUuid,
  getUserActionObjectFromActionCode,
  getUserId,
  getUserUUID,
  renderCopayFields,
  unmaskPhoneNumber,
  isInsuranceEnable,
} from '../../../../../../utils/commonUtils';
import {
  getBackgroundColor,
  showToast,
  ToastType,
} from '../../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {getDateStrFromFormat, getDateToMomentISOString, isValidDate} from '../../../../../../utils/DateUtils';
import {
  getContactTypeId,
  getMlovCodeFromId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {getObservationResourceTemplate, getUnit, Vital} from '../../../../../../utils/VitalUtils';
import {IZipCodeData} from '../../../../../common/ZipcodeMultiSelect/interfaces';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import { getEhrConfig } from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {OrderType} from '../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import { EHRName } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { getCurrentEHR } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {ADD_UPDATE_EVENTS, CapabilityResource} from '../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IObservation} from '../../../../Forms/FHFormio/CustomComponents/Vitals/interfaces';
import MessagingContactDetailsDrawer from '../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {insertUserAction} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getMissingFields} from '../../../ContactsUtils';
import {updatePatientObject} from '../../../Customer/CustomerView/AddOrUpdateCustomer/AddOrUpdateCustomerUtils';
import {
  formatGetCategoryId,
  formatGetTags,
} from '../../../Tags/Helper/formatTagsData';
import {styles} from './AddOrUpdateLeadStyles';
import {AddOrUpdateLeadView} from './AddOrUpdateLeadView';
import {
  addOrUpdateContactLanguage,
  crateContactPersonObj,
  createContactQueryObject,
  getCodeListFromResponse,
  getContactValueFromIdentifiers,
  getKeysForPersonType,
  getLegalSexFromResponse,
  getPatientMartialStatus,
  getPractitionerIdFromResponse,
  getRowDataFromLeadData,
  getSexAtBirthFromResponse,
  getSexualOrientationFromResponse,
  getStateAndCityNameFromResponse,
  updateContactQueryObj,
  updatePersonAddressObj,
  validateContactOrPatientDetails,
  validatesIndividually,
} from './AddOrUpdateQueryUtils';
import {
  getDuplicateEmailTextByContactType,
  getErrorMessageForSingleContactQuery,
  getFieldConfig,
  getFormDataFromLeadData,
  mapGenderDisplayCode,
  sendPatientAppInvite,
} from './AddOrUpdateUtils';
import ConfirmOnExitOrReload from '../../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import {IEhrFieldList} from './components/CustomEHRAttributes/interface';
import {
  formatCustomEhrFieldsForSave,getCustomEHRAttributesFromPatientData,
} from './components/CustomEHRAttributes/helper';
import {EventBus} from '../../../../../../utils/EventBus';
import { isEmpty, unionBy } from 'lodash';
import { capitalizeText } from '../../../../../common/ContactRelationView/ContactRelationUtils';
import { getExtensionByUrl } from '../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { EXTENSION_URLS } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { IContact } from '../../../../TeamInbox/Conversations/interfaces';
import { useToast as usCustomToast } from '../../../../../Toast/ToastProvider';
import {getCustomFieldAddUpdateFormattedData, validateCustomFieldsValue} from '../../../CustomField/CustomFieldUtils';
import {IContactTypes, IFormattedCustomFieldsData} from '../../../CustomField/interface';
import {createUpdateCustomFieldsForEntity} from '../../../CustomField/CustomFieldService';
import {CUSTOM_ATTRIBUTE_DATA_SOURCE, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from '../../../CustomField/CustomFieldConst';
import { getPostRequestBodyCoverageAthena, getPostRequestBodyCoverageElation } from '../../../../../InsuranceUtils';
import { IInsuranceState } from '../../../../../InsuranceInterfaces';

const mask = `(###) ###-####`;

export const AddOrUpdateLead = (props: any) => {
  // const {personType} = props;
  const {handleCloseModal, isShowModal} = props;
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings);
  const intl = useIntl();
  const[deceasedDate,setDeceasedDate] = useState('')
  const [insuranceDataForRequestBody, setInsuranceDataForRequestBody] = useState<IInsuranceState>();
  const [secInsuranceDataForRequestBody, setSecInsuranceDataForRequestBody] = useState<IInsuranceState>();
  const [terInsuranceDataForRequestBody, setTerInsuranceDataForRequestBody] = useState<IInsuranceState>();
  const [selectedViewCode, setSelectedViewCode] = useState(
    RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW
  );
  const currentEhr = getCurrentEHR();
  const isAthena = currentEhr === EHRName.ATHENA;
  const isFold = currentEhr === EHRName.FOLD;
  const [personTypeObj, setPersonTypeObj] = useState({
    personType: props.personType,
    personTypeUuid: props.personTypeUuid,
  });
  const [showModal, setShowModal] = useState(true);
  const [showUpdateAlertModal, setShowUpdateAlertModal] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const mlovData = useContext(CommonDataContext);
  const ehrCapabilities = mlovData.ehrCapabilities || [];
  const currentEHR = ehrCapabilities?.length ? ehrCapabilities?.[0]?.ehrName : '';
  const ehrConfig = getEhrConfig(currentEHR);
  const eventBus = EventBus.getEventBusInstance();

  const useAthenaMappedLocation = (isAthena && props.singleLeadData?.patient?.patientId);
  const consentMlovList: IMlov[] = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT
  ) || [];
  const consentType = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT
  );
  const bloodGroupList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.BLOOD_GROUP
  );
  const ConsentProviderType = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT_PROVIDER_TYPE
  );
  const personContactTypeList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );
  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );

  const isMEDEnabled = isOrderTypeEnabled(OrderType.MED);
  const accountUuid = getAccountUUID();
  const consentProviderId = getContactUuid();
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const accountId = getAccountId();
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [consentData, setConsentData] = useState({
    consent: false,
    consentAlreadyGiver: false,
    memberConsent: false,
    consentList: [
      getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.VOICE),
      getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.EMAIL),
      getMlovIdFromCode(consentMlovList, PREFERRED_CHANNEL_CODE.SMS)
    ],
  });
  const defaultContactTypes = useQuery(MlovQueries.GetAllDefaultContactTypes, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountUuid,
    },
  });
  const [stateData, setStateData] = useState({
    customFieldsValue: [] as IFormattedCustomFieldsData[],
    customFieldsError: {},
    customContactTypes: [] as IContactTypes[],
  });

  const customContactTypes = stateData?.customContactTypes?.length ? stateData?.customContactTypes : mlovData?.defaultContactTypes?.customContactTypes;
  const filteredContactTypes = customContactTypes?.filter?.((contactType: IContactTypes) => {
    return contactType.isDefault || contactType.isActive;
  })
  const personTypeList = filteredContactTypes;

  const getConsentProviderTypeUser = () => {
    return ConsentProviderType.filter((providerType) => {
      return providerType?.code === 'USER';
    });
  };

  const isInsuranceStateEmpty = (state: IInsuranceState | undefined): boolean => {
    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const value = state[key as keyof IInsuranceState];
            if (value && (typeof value === 'string' ? value.trim() !== '' : true)) {
                return false;
            }
        }
    }
    return true;
}


  const getConsentObjArray = (contactUuid: string) => {
    const consentProviderTypeUser = getConsentProviderTypeUser();
    const consentObjArray: any = [];
    consentType.forEach((consent) => {
      const consentObj = {
        accountUuid: accountUuid,
        consentId: consent?.id,
        consentProviderId: consentProviderId,
        consentProviderTypeId: consentProviderTypeUser[0]?.id,
        contactUuid: contactUuid,
      };
      consentObjArray.push(consentObj);
    });
    return consentObjArray;
  };
  const insuranceCapability = getCapability(FHIR_RESOURCE.COVERAGE);
  const insuranceOperations = insuranceCapability?.abilities?.keyAllowedOperations;
  const canPlanNameCreate = ehrConfig.isAthena ? insuranceOperations?.planName.canCreate : true
  const [CreateConsent] = useMutation(EmployeeQueries.createConsent);
  const [UpdateConsent] = useMutation(EmployeeQueries.updateConsent);
  const [DeleteUserConsent] = useMutation(EmployeeQueries.deleteUserConsent);

  const registerConsent = async (contactUuid: string) => {
    const consentObjArray = getConsentObjArray(contactUuid);
    const responseData = await CreateConsent({
      variables: {consent: consentObjArray},
    });
  };

  const addOrUpdateConsent = async (contactUuid: string) => {
    try {
      const consentProviderTypeUser = getConsentProviderTypeUser();
      const createConsentList: any = [];
      const updateConsentList: any[] = [];
      (consentData?.consentList || []).forEach((consentId: any) => {
        const isExistConsentId = (leadFormData?.contactConsents || []).some((consent: any) => {
          return consent?.consentId === consentId;
        });
        if (!isExistConsentId) {
          createConsentList.push({
            accountUuid: accountUuid,
            consentId: consentId,
            consentProviderId: consentProviderId,
            consentProviderTypeId: consentProviderTypeUser[0]?.id,
            contactUuid: contactUuid,
          });
        }
      });
      (leadFormData?.contactConsents || []).some((consent: any) => {
        const isExistConsentId = (consentData?.consentList || []).some((consentId: any) => {
          return consentId === consent?.consentId;
        });
        if (!isExistConsentId) {
          updateConsentList.push(consent.id);
        }
      });
      if (createConsentList?.length) {
        const createResponseData = await CreateConsent({
          variables: {consent: createConsentList},
        });
      }
      if (updateConsentList?.length) {
        const updateResponse = await UpdateConsent({
          variables: {ids: updateConsentList},
        });
      }
    } catch(error) {
    }
  };

  let personGender = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_GENDER
  );

  const genderTransformValues = getSexAtBirthTransformValues(
    KEY_ALLOWED_OPERATIONS.GENDER
  );

  personGender = mapGenderDisplayCode(personGender,genderTransformValues)

  const pronounList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const userUuid = getUserUUID();
  const selectedCategory: any = formatGetCategoryId(
    mlovData.MLOV.LabelType,
    'Contact'
  );
  const [createEmployee] = useMutation(EmployeeQueries.createEmployee);
  const [updateEmployee] = useMutation(EmployeeQueries.updateEmployee);
  const [addEmployee] = useMutation(EmployeeQueries.addEmployeeInEmployer);

  useQuery(EmployerQueries.employersSearch, {
    variables: {
      accountUuid: accountUuid,
      query: '%%',
    },
    onCompleted: (data) => {
      if (data?.employers) {
        setEmployers(data?.employers);
      }
    },
  });

  const [labelData, setLabelData] = useState<any>({
    tagList: [],
    finalTaglist: [],
  });
  const [selectedTagData, setSelectedTags] = useState<any>({
    selectedTags: [],
    tag: [],
  });

  const {data} = useQuery(
    UserPracticeLocationQueries.GetAccountAndPracticeLocations,
    {
      skip: isAthena && props.singleLeadData?.patient?.patientId,
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        userUuid: userUuid,
      },
    }
  );

  const [getAccountLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountAndPracticeLocations,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        userUuid: userUuid,
      },
    }
  );

  const contactTags = props?.singleLeadData?.contactTagging
    ? props?.singleLeadData?.contactTagging
    : [];
  useQuery(TagQueries.GetLabelsByFilter, {
    variables: {category: selectedCategory, searchString: `%%`},
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const tagList = formatGetTags(data.labels, contactTags);
      setLabelData({
        ...labelData,
        tagList: tagList,
        finalTaglist: tagList,
      });
    },
  });
  const practiceLocation = data?.accountLocations || [];
  const practiceLocationObject = practiceLocation.map((location: any) => {
    if (location.practiceLocation != null) {
      location.practiceLocation.accountLocationUuid = location.uuid;
      return location.practiceLocation;
    } else {
      return {} as any;
    }
  });

  const [isFetchingContactDetails, setIsFetchingContactDetails] = useState(props?.singleLeadData?.id ? true : false)
  const formattedLeadData = getFormDataFromLeadData(
    props.singleLeadData,
    mlovData
  );
  const [personTypeContact, setPersonTypeContact] = useState(
    props?.singleLeadData?.contactType?.contactType?.code ||
    personTypeObj.personType || props?.route?.params?.personType
  );
  const [UpdatePatientWithContactId] = useMutation(
    PatientQueries.UpdatePatientWithContactId
  );
  const [updateLead] = useMutation(LeadQueries.UpdateContact);
  const [updateEmailContact] = useMutation(LeadQueries.UpdatePersonContact);
  const [updatePhoneContact] = useMutation(LeadQueries.UpdatePersonContact);
  const [updatePersonAdress] = useMutation(LeadQueries.UpdatePersonAddress);
  const [createAddress] = useMutation(LeadQueries.CreateAddress);
  const [createNote] = useMutation(NoteQueries.CreateNoteByContact);
  const [updateLastNote] = useMutation(NoteQueries.updateLastNote);
  const [createEmailContact] = useMutation(LeadQueries.CreatePersonContact);
  const [createPhoneContact] = useMutation(LeadQueries.CreatePersonContact);
  const [updateContactPerson] = useMutation(LeadQueries.UpdateContactPerson);
  const [CreateContactLanguages] = useMutation(
    LeadQueries.CreateContactLanguages
  );
  const [UpdateContactLanguages] = useMutation(
    LeadQueries.UpdateContactLanguages
  );
  const [UpdateContactPracticeLocation] = useMutation(
    LeadQueries.UpdateContactPracticeLocation
  );
  const [SingleCreateContactQuery] = useMutation(
    LeadQueries.SingleCreateContactQuery
  );
  const [CreateContactPracticeLocation] = useMutation(
    LeadQueries.CreateContactPracticeLocation
  );
  const [CreatePatientWithContactId] = useMutation(
    PatientQueries.CreatePatientWithContactId
  );

  const [CreatePreferredChannel] = useMutation(
    LeadQueries.CreatePreferredChannel
  );
  const [UpdatePreferredChannel] = useMutation(
    LeadQueries.UpdatePreferredChannel
  );

  const [leadFormData, setLeadFormData]: any = useState(formattedLeadData);
  const [errors, setErrors]: any = useState({});
  const [formStatus, setFormStatus]: any = useState({loading: false});
  const [sendInvite, setSendInvite] = useState(() => {
    return leadFormData.leadId ? false : true;
  });
  const [selectLeadViewCode, setSelectLeadViewCode] = useState({
    alert: props.alert,
    invocationCode: '',
  });
  const [alerts, setAlerts] = useState({
    duplicateAlert: false,
    sameEmailExistsAlert: '',
    sameSecondEmailExistsAlert: '',
    duplicateNumber: false,
    sameNumberExistsAlert: '',
    sameSecondNumberExistsAlert: '',
    apiError: '',
    contactId: '' as any,
    contactData: {} as any
  });
  const genderAtBirthArray = getPossibleValuesByOperationName(
    KEY_ALLOWED_OPERATIONS.BIRTH_SEX
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientResource = CapabilityResource.patient;
  const patientCapability = getResourceAbilities(CapabilityResource.patient);
  const isPatientCreationFlow = personTypeContact === PERSON_TYPES.CUSTOMER || props.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER;
  const isLocationRequired = (patientCapability?.keyAllowedOperations?.location?.isRequired || false) && isPatientCreationFlow;
  const isLocationDisabled = false;

  // For lead this field should be hidden as it's stored in EHR not in CRM DB
  const maritalStatusCapability = patientCapability?.keyAllowedOperations?.maritalStatus;
  const isMartialStatusHidden = !maritalStatusCapability || maritalStatusCapability?.isHidden || !isPatientCreationFlow;
  const isMartialStatusRequired = (maritalStatusCapability?.isRequired || false) && isPatientCreationFlow && !isMartialStatusHidden;

  // For lead this field should be hidden as it's stored in EHR not in CRM DB
  const sexualOrientationCapability = patientCapability?.keyAllowedOperations?.sexualOrientation;
  const isSexualOrientationHidden = !sexualOrientationCapability || sexualOrientationCapability?.isHidden || !isPatientCreationFlow;
  const isSexualOrientationRequired = (sexualOrientationCapability?.isRequired || false) && isPatientCreationFlow && !isSexualOrientationHidden;

  // For lead this field should be hidden as it's stored in EHR not in CRM DB
  const raceCapability = patientCapability?.keyAllowedOperations?.race;
  const isRaceHidden = !raceCapability || raceCapability?.isHidden || !isPatientCreationFlow;
  const isRaceRequired = (raceCapability?.isRequired || false) && isPatientCreationFlow && !isRaceHidden;
  const isRaceMultiple = (raceCapability?.isMultiple || false);

  // For lead this field should be hidden as it's stored in EHR not in CRM DB
  const ethnicityCapability = patientCapability?.keyAllowedOperations?.ethnicity;
  const isEthnicityHidden = !ethnicityCapability || ethnicityCapability?.isHidden || !isPatientCreationFlow;
  const isEthnicityRequired = (ethnicityCapability?.isRequired || false) && !isEthnicityHidden;
  const isEthnicityMultiple = (ethnicityCapability?.isMultiple || false);
  // We don't want location to be prefilled for the first time, as in EHR once location is assigned to patient, it cannot be updated
  // So making defaultPracticeLocation to empty string in all cases
  const defaultPracticeLocation = isLocationRequired && practiceLocation?.length === 1 ? practiceLocation?.[0]?.practiceLocation?.uuid :  '';

  const shouldCreateUsingIntegration =
    (patientCapability?.createPatientUsingIntegration &&
      personTypeObj.personType === PERSON_TYPES.CUSTOMER) ||
    false;

  const shouldCreateLeadInIntegration =
    personTypeObj.personType === PERSON_TYPES.LEAD;

  const isGenderAtBirthRequired =
    patientCapability?.keyAllowedOperations?.birthSex?.isRequired || false;
  const genderAtBirthLabel = getBirthSexLabel();
  const genderIdentityLabel = getGenderIdentityLabel();

  const isGenderIdentityHidden =
    patientCapability?.keyAllowedOperations?.gender?.isHidden || false;
  const isPractitionerRequired = (personTypeContact === PERSON_TYPES.CUSTOMER ||
      props.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER) &&
      (patientCapability?.keyAllowedOperations?.generalPractitioner?.isRequired || false);
  const practitionerLabel = patientCapability?.keyAllowedOperations?.generalPractitioner?.foldDisplay || "Practitioner";
  const isLegalSexRequired =
    personTypeContact === PERSON_TYPES.CUSTOMER
      ? patientCapability?.keyAllowedOperations?.legalSex?.isRequired || false
      : false;

  const vitalList = getVitalListFromCapability();
  const fieldKeys = getKeysForPersonType(personTypeContact);

  const firstName = getFieldConfig(fieldKeys?.firstName, patientResource);
  const lastName = getFieldConfig(fieldKeys?.lastName, patientResource);
  const middleName = getFieldConfig(fieldKeys?.middleName, patientResource);
  const state = getFieldConfig(fieldKeys?.state, patientResource);
  const city = getFieldConfig(fieldKeys?.city, patientResource);
  // const phoneNumber = getFieldConfig(fieldKeys?.phoneNumber, patientResource);
  const phoneNumber = {
    isRequired: false,
  }
  const addressLine1 = getFieldConfig(
    fieldKeys?.addressLine1,
    patientResource
  );
  const addressLine2 = getFieldConfig(
    fieldKeys?.addressLine2,
    patientResource
  );
  const fieldsConfig: {[key: string]: any} = {
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    state: state,
    city: city,
    phoneNumber: phoneNumber,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
  };

  const addressLine1MaxChar = addressLine1?.maxCharLimit;
  // patientCapability?.keyAllowedOperations?.addressLine1?.maxCharLimit;
  const addressLine2MaxChar = addressLine2?.maxCharLimit;
  // patientCapability?.keyAllowedOperations?.addressLine2?.maxCharLimit;
  // Only for patient, address line1 and mobile number mandatory checks applied
  const isAddressLine1Required = !!addressLine1?.isRequired && isPatientCreationFlow;
  const isMobileNumberRequired = !!phoneNumber?.isRequired && isPatientCreationFlow && props.isOrderingWorkflow;
  const checkVitalsForLessThan18Age = isPatientCreationFlow && props.isOrderingWorkflow;

  const userId = getUserId();
  const onCancel = () => {
    if (isShowModal !== undefined) {
      handleCloseModal?.();
    } else {
      handleCloseModal?.();
      setShowModal(false);
    }
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const toast = useToast();
  const [screenSetting, setScreenSetting] = useState({
    openCalendar: false,
  });

  const userActionObject = getUserActionObjectFromActionCode('ADD_NEW_PATIENT');
  const level = userActionObject.level || '';
  const customToast = usCustomToast();
  const showToastMessage = (errorMessage: string, toastType: ToastType) => {
    if (errorMessage) {
      customToast({
        toastType: toastType,
        message: errorMessage,
        duration: 2000,
        closeAllPrevToast: true,
      })
    }
  };

  const createOrUpdateSecondaryContactAndPreferredChannel = (
    contactId?: any,
    contactUUid?: any
  ) => {
    const promiseList = [] as any[];
    if (leadFormData?.secondaryEmailObj?.id) {
      promiseList.push(
        updateEmailContact({
          variables: {
            id: leadFormData.secondaryEmailObj.id,
            contactId: contactId
              ? contactId
              : leadFormData.secondaryEmailObj.personContactId,
            contactTypeId: leadFormData.secondaryEmailObj.typeId,
            value: leadFormData.secondaryEmail,
            typeId: emailContactTypeId,
            rank: 2,
          },
        })
      );
    } else {
      if (leadFormData?.secondaryEmail) {
        promiseList.push(
          createEmailContact({
            variables: {
              accountId: accountId,
              personContactId: contactId ? contactId : leadFormData.leadId,
              typeId: emailContactTypeId,
              value: leadFormData.secondaryEmail,
              rank: 2,
            },
          })
        );
      }
    }
    if (leadFormData?.secondaryPhoneObj?.id) {
      promiseList.push(
        updatePhoneContact({
          variables: {
            id: leadFormData.secondaryPhoneObj.id,
            contactId: contactId
              ? contactId
              : leadFormData?.secondaryPhoneObj?.personContactId,
            contactTypeId: leadFormData?.secondaryPhoneObj?.typeId,
            value: leadFormData?.secondaryPhone,
            typeId: phoneContactTypeId,
            rank: 2,
          },
        })
      );
    } else {
      if (leadFormData?.secondaryPhone) {
        promiseList.push(
          createPhoneContact({
            variables: {
              accountId: accountId,
              personContactId: contactId ? contactId : leadFormData.leadId,
              typeId: phoneContactTypeId,
              value: leadFormData.secondaryPhone,
              rank: 2,
            },
          })
        );
      }
    }
    if (leadFormData?.preferredChannelId) {
      promiseList.push(
        UpdatePreferredChannel({
          variables: {
            id: leadFormData.preferredChannelId,
            contactUuid: contactUUid ? contactUUid : leadFormData?.contactUUID,
            preferredCommunicationModeId: leadFormData?.preferredModeId,
          },
        })
      );
    } else {
      if (leadFormData?.preferredModeId) {
        promiseList.push(
          CreatePreferredChannel({
            variables: {
              accountUuid: accountUuid,
              contactUuid: contactUUid ? contactUUid : leadFormData.contactUUID,
              preferredCommunicationModeId: leadFormData?.preferredModeId,
            },
          })
        );
      }
    }
    Promise.all(promiseList)
      .catch((err) => {

      });
  };

  const onSubmit = async () => {
    setAlerts((prev) => {
      return {
        ...prev,
        sameEmailExistsAlert: '',
        sameNumberExistsAlert: '',
        sameSecondEmailExistsAlert: '',
        sameSecondNumberExistsAlert: '',
      };
    });
    if (!isWeb()) {
      const result = validatesIndividually(leadFormData, {
        isGenderAtBirthRequired: isGenderAtBirthRequired,
        isMobileNumberRequired: isMobileNumberRequired,
        shouldPerformPhoneNumberValidation: isMEDEnabled,
        isPhoneOrEmailRequired: isPhoneOrEmailRequired,
        isPatientCreationFlow: isPatientCreationFlow,
        shouldCreateUsingIntegration,
        isLocationRequired,
        isPractitionerRequired,
      });
      if (!result.validate) {
        setFormStatus({...formStatus, loading: false});
        showToastMessage(result.errors, ToastType.error);
        return;
      } else {
        updateContactDataAfterConfirm();
        setFormStatus({
          loading: true,
        });
      }
    } else {
      //checked Add insurance mandatory field only if either one of the field is filled from add insurance screen
      if ((isAthena || ehrConfig.isElation) && !isInsuranceStateEmpty(insuranceDataForRequestBody)) {
        const error = validateInsuranceFields(insuranceDataForRequestBody, ehrConfig.isAthena, secInsuranceDataForRequestBody, terInsuranceDataForRequestBody, canPlanNameCreate);
        if(error.error){
          notification.error({
            message: error.error,
            placement: 'top',
            duration: 4.0
          })
          return;
        }
        else if (!insuranceDataForRequestBody?.insuranceCarrierName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsg' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        } else if (insuranceDataForRequestBody?.addSecondaryInsuranceOpen && !secInsuranceDataForRequestBody?.insuranceCarrierName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsgSecondary' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        } else if (ehrConfig.isAthena &&  insuranceDataForRequestBody?.addTertiaryInsuranceOpen && !terInsuranceDataForRequestBody?.insuranceCarrierName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextInsuranceCarrierNameMsgTertiary' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        }
        else if (canPlanNameCreate && !insuranceDataForRequestBody?.planName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextPlanNameMsg' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        }
        else if (canPlanNameCreate && insuranceDataForRequestBody?.addSecondaryInsuranceOpen && !secInsuranceDataForRequestBody?.planName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextPlanNameMsgSecondary' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        } else if (canPlanNameCreate && ehrConfig.isAthena && insuranceDataForRequestBody?.addTertiaryInsuranceOpen && !terInsuranceDataForRequestBody?.planName) {
          notification.error({
            message: intl.formatMessage({ id: 'freeTextPlanNameMsgTertairy' }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        }
     }

      const { customFieldsErrors, validated } = validateCustomFieldsValue(stateData?.customFieldsValue)
      setStateData((prev) => {
        return {
          ...prev,
          customFieldsError: customFieldsErrors,
        }
      })
      if (!validated) {
        return;
      }
      const {validate, errors} = validateContactOrPatientDetails(leadFormData, personTypeContact, {
        isGenderAtBirthRequired,
        isPractitionerRequired,
        isAddressLine1Required,
        isMobileNumberRequired,
        checkVitalsForLessThan18Age: checkVitalsForLessThan18Age,
        fieldKeys,
        isFaxNumberRequired: false,
        isPatientCreationFlow: isPatientCreationFlow,
        shouldPerformPhoneNumberValidation: isMEDEnabled,
        isPhoneOrEmailRequired: isPhoneOrEmailRequired,
        isLegalSexRequired: isLegalSexRequired,
        isLocationRequired: isLocationRequired,
        isSexualOrientationRequired: isSexualOrientationRequired,
        isMartialStatusRequired: isMartialStatusRequired,
        isRaceRequired: isRaceRequired,
        isRaceMultiple: isRaceMultiple,
        isEthnicityRequired: isEthnicityRequired,
        isEthnicityMultiple: isEthnicityMultiple,
      });

      setErrors(errors);
      // const isDuplicate = await checkForExistingContactWithSameEmail(
      //   leadFormData.email,
      //   leadFormData.phone,
      //   'PRIMARY'
      // );

      // const isSecondaryDuplicate = await checkForExistingContactWithSameEmail(
      //   leadFormData.secondaryEmail,
      //   leadFormData.secondaryPhone
      // );
      if (validate ) {
        // Show update alert message only while editing patient, in all other cases do not show alert
        if (leadFormData?.leadId && personTypeContact === PERSON_TYPES.CUSTOMER && !ehrConfig.isFold) {
          setShowUpdateAlertModal(true);
        } else {
          updateContactDataAfterConfirm();
        }
        setSelectLeadViewCode(prev=> {
          return {
            ...prev,
            alert: '',
            invocationCode: ''
          }
        })
      } else {
        setFormStatus({...formStatus, loading: false});
        if (props.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER) {
          const alert = getMissingFields(leadFormData, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            genderAtBirthLabel,
            genderIdentityLabel,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired:isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired: isPhoneOrEmailRequired

          }).message;
          setSelectLeadViewCode(prev=> {
            return {
              ...prev,
              alert: alert,
              invocationCode: COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER
            }
          })
        }
      }
    }
  };

  const getLeadObjectForIntegration = () => {
    const addTagObjectList = getAddTagObjectList();
    return {
      addTagObjectList,
      data: {
        ...leadFormData,
        typeId: personTypeObj.personTypeUuid,
        emailContactTypeId,
        phoneContactTypeId,
        userUuid,
      },
      userId,
      userUuid,
      defaultLocationUuid:
        leadFormData?.locationUuid || defaultPracticeLocation,
    };
  }

const formatCustomEhrFieldsForUpdate = (
  updatedFieldList: QuestionnaireItem[],
  prevResponse: QuestionnaireItem[]
) => {
  const prevResponseList = prevResponse.filter(
    (item) => item.text === CUSTOM_FIELDS
  );
  const EmptyCustomFieldLinkIDList = updatedFieldList.filter(
    (item: any) =>{
      if (item?.answer?.[0]?.hasOwnProperty('valueCoding')) {
        return (item?.answer?.[0]?.valueCoding?.code === undefined) || (item?.answer?.[0]?.valueCoding?.code === '')
      } else if (item?.answer?.[0]?.hasOwnProperty('valueString')) {
        return item?.answer?.[0]?.valueString === '';
      }
    }
  );
  let ItemUpdatedasNewValue:QuestionnaireItem[] = []
  if (isEmpty(prevResponseList)){
    ItemUpdatedasNewValue = updatedFieldList
  }
  else{
    ItemUpdatedasNewValue = updatedFieldList.filter(
    (updatedFieldListItem) => {
      if (
        !!prevResponseList.find(
          (prevResponseListitem) =>
            updatedFieldListItem.linkId !== prevResponseListitem.linkId
        )
      ) {
        return true;
      }
      return false;
    }
  );
  }
  const ItemUpdatedasNewFilledValueList = ItemUpdatedasNewValue.filter(
    (item: any) => {
      if (item?.answer?.[0].hasOwnProperty('valueCoding')) {
        if(item.answer[0].valueCoding.code){
          return true;
        }}
      else if (item?.answer?.[0].hasOwnProperty('valueString')) {
        if(item.answer[0].valueString) {
          return true;
        };
      }
    }
  );

  const filledPrevResponseList = prevResponseList.filter(
    (item: any) => Object.keys(item?.answer?.[0]).length !== 0
  );

  const ItemToSendUpdatedAsFilterStringList = EmptyCustomFieldLinkIDList.filter(
    (EmptyCustomFieldLinkIDListItem) => {
      if (
        !!filledPrevResponseList.find(
          (item) => EmptyCustomFieldLinkIDListItem.linkId === item.linkId
        )
      ) {
        return true;
      }
      return false;
    }
  );
  const ItemToSendUpdatedAsEmptyStringList =
    ItemToSendUpdatedAsFilterStringList.map((item: any) => {
      if (item?.answer?.[0].hasOwnProperty('valueString')) {
        item.answer[0].valueString = ' ';
      } else if (item?.answer[0].hasOwnProperty('valueCoding')) {
        item.answer[0].valueCoding.code = ' ';
      }
      return item;
    });

  let formattedArray = [];
  if (isEmpty(prevResponse)) {
    formattedArray = [
      ...filledPrevResponseList,
      ...ItemToSendUpdatedAsEmptyStringList,
    ];
  } else {
    formattedArray = [
      ...ItemToSendUpdatedAsEmptyStringList,
      ...ItemUpdatedasNewFilledValueList,
    ];
  }
  return [
    {
      resourceType: FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
      item: formattedArray,
    },
  ];
};

  const addUpdateCustomFields = async (contactUuid?: string) => {
    const customFieldAddUpdateFormattedData = getCustomFieldAddUpdateFormattedData(stateData?.customFieldsValue, CUSTOM_ATTRIBUTE_DATA_SOURCE.FOLD_APP, contactUuid);
    await createUpdateCustomFieldsForEntity(customFieldAddUpdateFormattedData)
    .catch((error)=> {
      customToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    })
  }

  function updateContactDataAfterConfirm() {
    const phone = (leadFormData.phone || '').replace(/[()-\s]/g, '');
    leadFormData.email = (leadFormData.email || '').toLowerCase();
    setLeadFormData((prev: any) => {
      return {
        ...prev,
        phone: phone,
        phoneObj: {...leadFormData.phoneObj, value: phone},
      };
    });
    setIsSubmitting(true);
    if (leadFormData?.leadId) {
      const eventBus = EventBus.getEventBusInstance();
      const addTagObjectList = getAddTagObjectList();
      if (shouldCreateUsingIntegration) {
        const memberConsent = getContactConsent();
        let ehrAttributes: {resourceType: string, item: any[]}[] = [];
        // If custom ehr attributes are updated then ehrAttributes will have data else we need to pass the data we received in get as it is
        if (leadFormData?.ehrAttributes) {
          ehrAttributes = formatCustomEhrFieldsForUpdate(
            leadFormData?.ehrAttributes?.[0]?.item || [],
            leadFormData?.contained?.find((item: any)=> item.resourceType === FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE)?.item || []
          )
        } else {
          ehrAttributes = leadFormData?.contained?.filter((item: any) => item.resourceType === FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE) || [];
          if (ehrAttributes?.[0]?.item?.length) {
            ehrAttributes[0].item = ehrAttributes[0].item.filter(item => item.text === CUSTOM_FIELDS);
          }
        }
        updatePatient({
          defaultLocationUuid: leadFormData?.locationUuid || defaultPracticeLocation,
          patientData: {
            ...leadFormData,
            ehrAttributes: ehrAttributes,
            ...memberConsent,
            patientCapability,
            typeId: personTypeObj.personTypeUuid,
          },
          addTagObjectList: addTagObjectList,
          isSexAtBirthRequired: shouldCreateUsingIntegration,
          sendInvite: sendInvite,
          userId: userId,
          pronounList,
          successHandler: async (response) => {
            eventBus.broadcastEvent(ADD_UPDATE_EVENTS.CONTACT_DETAIL_UPDATE, {leadFormData: leadFormData});
            if (ehrConfig.isAthena) {
              addOrUpdateConsent(leadFormData.contactUUID || '');
            }
            if (stateData?.customFieldsValue?.length) {
              await addUpdateCustomFields();
            }
            setFormStatus({...formStatus, loading: false});
            if (isWeb()) {
              props.onFormCompleteAction(
                COMMON_ACTION_CODES.ADDED_OR_UPDATED,
                {
                  updateData: updatePatientObject({
                    data: {
                      ...leadFormData,
                      typeId: personTypeObj.personTypeUuid,
                    },
                    addTagObjectList: addTagObjectList,
                    defaultLocationUuid: leadFormData?.locationUuid || defaultPracticeLocation,
                    isSexAtBirthRequired: shouldCreateUsingIntegration,
                    sendInvite: sendInvite,
                    userId: userId,
                    pronounList: pronounList
                  })
                }
              );
            }
          },
          errorHandler(error) {

            const message = error?.response?.data?.message ||
              'Something went wrong. Please try again later';
            setAlerts((prev) => {
              return {
                ...prev,
                apiError: message,
              };
            });
            setIsSubmitting(false);
            setFormStatus({...formStatus, loading: false});
          },
        });

        if (leadFormData.employeeId) {
          updateEmployee({
            variables: {
              employerId: leadFormData.employerId || null,
              contactId: leadFormData?.contactUUID,
            },
          }).then(async () => {
            if (!ehrConfig.isAthena) {
              if (consentData.consent &&
                consentData?.consentAlreadyGiver !== true) {
                registerConsent(leadFormData.contactUUID || '');
              } else if (!consentData.consent &&
                consentData?.consentAlreadyGiver === true) {
                const responseData = await DeleteUserConsent({
                  variables: {contactUuid: leadFormData?.contactUUID},
                });
              }
            }
          });
        } else {
          addEmployee({
            variables: {
              employerId: leadFormData.employerId || null,
              contactId: leadFormData?.contactUUID,
              accountUuid: accountUuid,
            },
          });
        }
      } else {
        addOrUpdateLeadInIntegration({
          ...getLeadObjectForIntegration(),
        }).then(async () => {
          eventBus.broadcastEvent(ADD_UPDATE_EVENTS.CONTACT_DETAIL_UPDATE, {leadFormData: leadFormData});
          if (stateData?.customFieldsValue?.length) {
            await addUpdateCustomFields(leadFormData.contactUUID);
          }
          if (ehrConfig.isAthena) {
            addOrUpdateConsent(leadFormData.contactUUID || '');
          } else {
            if (consentData.consent &&
              consentData?.consentAlreadyGiver !== true) {
              registerConsent(leadFormData.contactUUID || '');
            } else if (!consentData.consent &&
              consentData?.consentAlreadyGiver === true) {
              const responseData = await DeleteUserConsent({
                variables: {
                  contactUuid: leadFormData?.contactUUID,
                },
              });
            }
          }
          setFormStatus({...formStatus, loading: false});
          setIsSubmitting(false);
          if (isWeb()) {
            props.onFormCompleteAction(
              COMMON_ACTION_CODES.ADDED_OR_UPDATED, {
              invocationCode: props.invocationCode,
              leadFormData: getRowDataFromLeadData(
                leadFormData,
                props.singleLeadData,
                accountId
              ),
            });
          }
        }).catch(()=> {
          setIsSubmitting(false);
          setFormStatus({...formStatus, loading: false});
        })
      }
    } else {
      if (shouldCreateUsingIntegration) {
        const addTagObjectList = getAddTagObjectList();
        const memberConsent = getContactConsent();
        createPatientUsingIntegration({
          patientData: {
            ...leadFormData,
            ...memberConsent,
            patientCapability,
            typeId: personTypeObj.personTypeUuid,
            source: isWeb()
              ? CONTACT_SOURCE_OF_CREATION
              : 'FOLD_ADMIN_APP_MOBILE',
          },
          defaultLocationUuid: defaultPracticeLocation,
          sendInvite: sendInvite,
          userId: userId,
          userUuid,
          successHandler: async (response: any) => {
            if (response?.data?.reference?.contactUuid) {
              const contactUuid = response?.data?.reference?.contactUuid;
              if (stateData?.customFieldsValue?.length) {
                await addUpdateCustomFields(contactUuid);
              }
              if (ehrConfig.isAthena) {
                await addOrUpdateConsent(
                  response?.data?.reference?.contactUuid || ''
                );
              } else {
                if (
                  consentData.consent &&
                  consentData?.consentAlreadyGiver !== true
                ) {
                  await registerConsent(
                    response?.data?.reference?.contactUuid || ''
                  );
                }
              }
            }
            if (response?.data?.reference?.contactUuid && leadFormData?.employerId) {
              createEmployee({
                variables: {
                  employee: {
                    accountUuid: accountUuid,
                    employerId: leadFormData?.employerId || null,
                    type: 'employee',
                    contactId: response?.data?.reference?.contactUuid,
                  },
                },
              }).then(async (res) => {
                setFormStatus({...formStatus, loading: false});
                const contactUuid = res?.data?.createEmployee?.contact?.uuid;
                if (stateData?.customFieldsValue?.length) {
                  await addUpdateCustomFields(contactUuid);
                }
              });
            }
            setFormStatus({...formStatus, loading: false});
            if (isWeb()) {
              openDetailsModalMethod(response?.data?.reference?.contactId);
              eventBus.broadcastEvent(COMMON_ACTION_CODES.ADDED_OR_UPDATED,
                {
                  contactId: response?.data?.reference?.contactId,
                  contactName: response?.data?.data?.name[0].text
                }
              );
              if (props.shouldInvokeCallback) {
                props.onFormCompleteAction(
                  COMMON_ACTION_CODES.ADDED_OR_UPDATED,
                  response?.data
                );
              }
            } else {
              showToastMessage(
                intl.formatMessage({id: isPatientCreationFlow ? 'newPatientAddedMsg': 'newContactAddedMsg'}),
                ToastType.success
              );
              props.navigation.goBack();
              props?.route?.params?.onNewCustomerCreated(true);
            }
          },
          errorHandler(error) {

            if (error.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED){
              if (isWeb()) {
                notification.error({
                  message:  intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID})
                })
              } else {
                showToastMessage(
                  intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID}),
                  ToastType.error
                );
              }
              setIsSubmitting(false);
              setFormStatus({...formStatus, loading: false});
              return;
            }
            const message = error?.response?.data?.message ||
              'Something went wrong. Please try again later';
            setAlerts((prev) => {
              return {
                ...prev,
                apiError: message,
              };
            });
            setIsSubmitting(false);
            setFormStatus({...formStatus, loading: false});
          },
          addTagObjectList: addTagObjectList,
        });
      } else {
        const customerTypeId = personTypeObj.personTypeUuid;
        leadFormData.customerTypeId = customerTypeId;
        leadFormData.accountId = accountId;
        addOrUpdateLeadInIntegration({
          ...getLeadObjectForIntegration(),
        })
          .then(async (res) => {
            const contactUuid =
              getContactValueFromIdentifiers({
                data: res.data,
                key: 'contact-uuid',
              }) ?? res.data?.id;
              if (ehrConfig.isAthena) {
                addOrUpdateConsent(contactUuid || '');
              } else {
                if (
                  consentData.consent &&
                  consentData?.consentAlreadyGiver !== true
                ) {
                  registerConsent(contactUuid || '');
                } else if (
                  !consentData.consent &&
                  consentData?.consentAlreadyGiver === true
                ) {
                  const responseData = await DeleteUserConsent({
                    variables: {
                      contactUuid: contactUuid,
                    },
                  });
                }
              }
            let contactId = getContactValueFromIdentifiers({data: res.data, key: 'contact-id'})
            if (!contactId) {
              contactId = await getContactIdByUuid(contactUuid || '');
            }
            if (stateData?.customFieldsValue?.length) {
              await addUpdateCustomFields(contactUuid);
            }
            // We are not allowing creation of employee record when a lead is created as per CRM-5710
            // if (contactUuid) {
            //   createEmployee({
            //     variables: {
            //       employee: {
            //         accountUuid: accountUuid,
            //         employerId: leadFormData?.employerId || null,
            //         type: 'employee',
            //         contactId: contactUuid,
            //       },
            //     },
            //   }).then(async (res) => {
            //     setFormStatus({...formStatus, loading: false});
            //     const contactUuid = res?.data?.createEmployee?.contact?.uuid;
            //     if (stateData?.customFieldsValue?.length) {
            //       await addUpdateCustomFields(contactUuid);
            //     }
            //     if (ehrConfig.isAthena) {
            //       await addOrUpdateConsent(res?.data?.createEmployee?.contact?.uuid || '');
            //     } else {
            //       if (consentData.consent && consentData?.consentAlreadyGiver !== true) {
            //        await registerConsent(res?.data?.createEmployee?.contact?.uuid || '');
            //       }
            //     }
            //     if (props.shouldInvokeCallback) {
            //       props.onFormCompleteAction(
            //         COMMON_ACTION_CODES.ADDED_OR_UPDATED,
            //         contactId
            //       );
            //     }
            //   });
            // }
            setFormStatus({...formStatus, loading: false});
            if (isWeb()) {
              openDetailsModalMethod(
                contactId
              );
              eventBus.broadcastEvent(COMMON_ACTION_CODES.ADDED_OR_UPDATED,
                {
                  contactId: contactId,
                  contactName: res?.data?.name?.[0]?.text
                }
              );
              // props.onFormCompleteAction(
              //   COMMON_ACTION_CODES.ADDED_OR_UPDATED
              // );
            } else {
              showToastMessage(
                intl.formatMessage({id: 'newPatientAddedMsg'}),
                ToastType.success
              );
              props.navigation.goBack();
              props?.route?.params?.onNewCustomerCreated(true);
            }
          })
          .catch((err) => {
            const message = getErrorMessageForSingleContactQuery(err);
            showToastMessage(message, ToastType.error);
            setIsSubmitting(false);
            setFormStatus({...formStatus, loading: false});
          });
      }
    }
    setFormStatus({...formStatus, loading: true});
  }

  const handleVitalChange = (vital: Vital, value?: string) => {
    const prevList = leadFormData.vitalObservations || [];
    const effectiveDateTime = getDateToMomentISOString();
    const unit = getUnit(vital, vitalList);
    const index = prevList.findIndex((observation: IObservation) => {
      return observation.loinc === vital;
    });
    if (index === -1 && value) {
      // Add new
      prevList.push(getObservationResourceTemplate(vital, vitalList, value, effectiveDateTime));
    } else if (!value) {
      // Remove if no value
      if (index !== -1) prevList.splice(index, 1);
    } else if (
      prevList[index].valueQuantity ||
      prevList[index].component?.length
    ) {
      // Update if already exists
      prevList[index].valueQuantity = {
        value,
        unit,
      };
    }
    setLeadFormData((prev: any) => {
      return {
        ...prev,
        vitalObservations: prevList,
      }
    });
    if (props.observationsUpdated) {
      props.observationsUpdated(prevList);
    }
  };

  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactsbyUuid)

  const getContactIdByUuid = async (contactUuid: string) => {
    const response = await getContactsByUuid({variables: {
      "whereObj": {
        "uuid": {
          "_eq": contactUuid
        }
      }
    }})
    return response?.data?.contacts?.[0]?.id
  }

  const openDetailsModalMethod = async (contactId: any) => {
    const patientListRespData = await getContact({
      variables: {
        id: contactId,
      },
    });
    setSelectedRowData(patientListRespData.data.contact);
    setSelectedViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW);
    setShowModal(false);
  };

  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData || {});
    setDeceasedDate(deceasedDatePatient);
  }
  useEffect(() => {
    setDeceasedDate('');
    ehrConfig.isAthena && getPatientDeceasedData(props?.singleLeadData);
  }, [props?.singleLeadData?.patient?.patientId]);

  const getContactById = async () => {
    const patientListRespData = await getContact({
      variables: {
        id: props.singleLeadData?.id || props.singleLeadData?.singleLeadData?.id,
      },
    });
    const formattedData = getFormDataFromLeadData(
      patientListRespData?.data?.contact,
      mlovData
    );
    setLeadFormData((prev: any) => {
      // Patient and contact API is called simultaneously
      // From patient API, we received practitionerId and set to leadForm state
      // Here that practitionerId is overriden by formattedData practitionerId which is empty, causing practitioner dropdown to reset
      // Thus added practitionerId separately here
      const practitionerId = prev.practitionerId;
      return {
        ...prev,
        ...formattedData,
        practitionerId: formattedData.practitionerId || practitionerId,
      }
    });
    setIsFetchingContactDetails(false);
  }
  useEffect(() => {
    getContactById()
  }, [props.singleLeadData?.id])
  const getAddTagObjectList = () => {
    const addTagObjectList: any = [];
    leadFormData?.contactTags?.forEach((tagId: any) => {
      addTagObjectList?.push({
        taggerTypeId: mlovData.MLOV.TaggerType[0].id,
        taggableTypeId: selectedCategory,
        labelId: parseInt(tagId),
        taggerId: mlovData?.userData?.id || '',
        accountId: mlovData?.userData?.account_id || '',
      });
    });

    return addTagObjectList;
  };

  const selectZipcode = () => {
    props.navigation.navigate('SelectOptions', {
      screenTitle: 'search zipcode',
      dataList: [],
      selectedDataIds: leadFormData.zipCodeId ? [leadFormData.zipCodeId] : [],
      selectionMode: OPTION_SELECTION_TYPE.SINGLE,
      dataType: OPTION_TYPE.ZIPCODE,
      dataNotAvailableMsg: '',
      dataNotAvailableImageType: SELECT_OPTION_DATA_TYPE.ZIPCODE,
      onSelect: ({
        data,
        selectedItemsData,
      }: {
        data: [];
        selectedItemsData: IZipCodeData[];
      }) => {
        let id = '';
        let zipcodeStateName = '';
        let stateId = '';
        let cityId = '';
        let zipcodeCityName = '';
        let zipcodeStateAbbr = '';
        let code = '';
        if (selectedItemsData && selectedItemsData.length > 0) {
          if (selectedItemsData.length > 0) {
            if (selectedItemsData[0]) {
              id = selectedItemsData[0]?.id || '';
              code = selectedItemsData[0]?.code || '';
              zipcodeStateName = selectedItemsData[0]?.zipcodeState?.name || '';
              stateId = selectedItemsData[0]?.zipcodeState?.id || '';
              cityId = selectedItemsData[0]?.zipcodeCity?.id || '';
              zipcodeCityName = selectedItemsData[0]?.zipcodeCity?.name || '';
              zipcodeStateAbbr = selectedItemsData[0]?.zipcodeState?.abbr || '';
            }
          }
        }

        leadFormData.zipCodeId = id;
        leadFormData.stateId = stateId;
        leadFormData.stateName = zipcodeStateName;
        leadFormData.cityId = cityId;
        leadFormData.cityName = zipcodeCityName;
        leadFormData.abbr = zipcodeStateAbbr;
        leadFormData.zipCode = code;

        updateOnSubmitFunData();
      },
    });
  };

  const selectGender = () => {
    const dataList = [];
    for (let i = 0; i < genderAtBirthArray.length; i++) {
      dataList.push({
        id: genderAtBirthArray[i].code,
        title: genderAtBirthArray[i].display,
        subtitle: ' ',
        extraData: {genderData: genderAtBirthArray[i]},
      });
    }
    props.navigation.navigate('SelectOptions', {
      screenTitle: 'Select Gender',
      dataList: dataList,
      selectedDataIds:leadFormData.sexAtBirth ? [leadFormData.sexAtBirth] :[],
      selectionMode: OPTION_SELECTION_TYPE.SINGLE,
      dataType: OPTION_TYPE.GENDER,
      dataNotAvailableMsg: '',
      dataNotAvailableImageType: SELECT_OPTION_DATA_TYPE.GENDER,
      onSelect: ({
        data,
        selectedItemsData,
      }: {
        data: string[];
        selectedItemsData: any[];
      }) => {
        let sexAtBirth = '';
        const genderName = '';
        if (genderAtBirthArray && genderAtBirthArray?.length > 0) {
          const result = genderAtBirthArray.find((item) => {
            return item.code === selectedItemsData?.[0]?.genderData?.code;
          });
          sexAtBirth = result?.code || '';
        } else {
          sexAtBirth = selectedItemsData[0]?.genderData?.code;
        }
        leadFormData.sexAtBirth = sexAtBirth;
        leadFormData.genderName = genderName;

        updateOnSubmitFunData();
      },
    });
  };
  const selectBloodGroup = () => {
    const dataList = [];
    for (let i = 0; i < bloodGroupList.length; i++) {
      dataList.push({
        id: bloodGroupList[i].id,
        title: bloodGroupList[i].value,
        subtitle: '',
        extraData: {bloodGroupData: bloodGroupList[i]},
      });
    }
    props.navigation.navigate('SelectOptions', {
      screenTitle: 'Select Blood Group',
      dataList: dataList,
      selectedDataIds:leadFormData.bloodGroupId ? [leadFormData.bloodGroupId] :[],
      selectionMode: OPTION_SELECTION_TYPE.SINGLE,
      dataType: OPTION_TYPE.BLOOD_GROUP,
      dataNotAvailableMsg: '',
      dataNotAvailableImageType: SELECT_OPTION_DATA_TYPE.BLOOD_GROUP,
      onSelect: ({
        data,
        selectedItemsData,
      }: {
        data: string[];
        selectedItemsData: any[];
      }) => {
        const bloodGroupId = selectedItemsData[0]?.bloodGroupData?.id;
        leadFormData.bloodGroupId = bloodGroupId;
        updateOnSubmitFunData();
      },
    });
  };

  const selectContactType = () => {
    const dataList = [];
    for (const type of personTypeList) {
      if (
        [
          PERSON_TYPES.CUSTOMER,
        ].indexOf(type.code) !== -1
      ) {
        continue;
      }
      dataList.push({
        id: type.id,
        title:
        type.code === PERSON_TYPES.LEAD
            ? 'Lead'
            : type.code === PERSON_TYPES.CUSTOMER
            ? 'Patient'
            : capitalizeText(type.value),
        subtitle: '',
        extraData: {
          contactTypeData: type,
        },
      });
    }
    props.navigation.navigate('SelectOptions', {
      screenTitle: 'Select Contact Type',
      dataList: dataList,
      selectedDataIds: [],
      selectionMode: OPTION_SELECTION_TYPE.SINGLE,
      dataType: OPTION_TYPE.CONTACT_TYPE,
      dataNotAvailableMsg: '',
      dataNotAvailableImageType: SELECT_OPTION_DATA_TYPE.CONTACT_TYPE,
      onSelect: ({
        data,
        selectedItemsData,
      }: {
        data: string[];
        selectedItemsData: any[];
      }) => {
        let contactType: any;
        let contactTypeId: any;
        if (personTypeList && personTypeList?.length > 0) {
          const result = personTypeList.find((item: any) => {
            return item.id === selectedItemsData?.[0]?.contactTypeData?.id;
          });
          contactType = result;
          contactTypeId = selectedItemsData?.[0]?.contactTypeData?.id;
        }
        setPersonTypeObj((prev) => {
          return {
            ...prev,
            personTypeUuid: contactTypeId,
            personType: contactType.code || PERSON_TYPES.LEAD,
          };
        });
        setPersonTypeContact(contactType.code || PERSON_TYPES.LEAD);
        leadFormData.contactType = contactType;
        leadFormData.contactTypeId = contactTypeId;

        updateOnSubmitFunData();
      },
    });
  }

  const updateOnSubmitFunData = () => {
    props?.navigation.setParams({
      ...props?.navigation?.route?.param,
      onSubmit: onSubmit,
    });
  };

  const onDismissCalendarView = React.useCallback(() => {
    setScreenSetting((prev) => {
      return {
        ...prev,
        openCalendar: false,
      };
    });
  }, [screenSetting.openCalendar]);

  if (!isWeb()) {
    useEffect(() => {
      if (!props?.navigation?.route?.param?.onSubmit) {
        updateOnSubmitFunData();
      }
    }, []);
  }

  useEffect(() => {
    if (!isWeb()) {
      props?.navigation?.setParams({
        ...props?.navigation?.route?.param,
        isPatientCreating: formStatus.loading,
      });
    }
  }, [formStatus.loading]);

  const formatLocationsForAthena = async (locationList:any[],departmentId:any) => {
    const locationData = locationList.map(item => item?.resource);
    let providerGroupId = '';
    const matchingLocation = locationData.find(
      (loc) => loc.id === departmentId
    );
    if (matchingLocation) {
      providerGroupId =
        getExtensionByUrl(
          matchingLocation?.extension,
          EXTENSION_URLS.providerGroupId
        )?.valueString || '';
    }
    const locationListByGroup = locationData?.filter((location) => {
      if (
        getExtensionByUrl(location?.extension, EXTENSION_URLS.providerGroupId)
          ?.valueString === providerGroupId
      ) {
        return true;
      }
    });
    const locationListByGroupIds = locationListByGroup?.map(loc => getExtensionByUrl(loc?.extension, EXTENSION_URLS.foldLocationId)?.valueString).filter(item => item !== undefined);
    const accountLocationsData = await getAccountLocations();
    const practiceLocation = accountLocationsData?.data?.accountLocations || [];
    const practiceLocationObject = practiceLocation.map((location: any) => {
      if (location.practiceLocation != null) {
        location.practiceLocation.accountLocationUuid = location.uuid;
        return location.practiceLocation;
      } else {
        return {} as any;
      }
    });
    const finalLocations = practiceLocationObject.filter((obj:any) => {
      if(locationListByGroupIds.includes(obj?.accountLocationUuid)){
        return true;
      }
    });
    return finalLocations
  }

  useEffect(() => {
    let requestBodyForInsuranceDetailsElation: {}, requestBodyForSecInsuranceDetailsElation: {}, requestBodyForInsuranceDetails: {}, requestBodyForSecInsuranceDetails: {}, requestBodyForTerInsuranceDetails: {};
    if (ehrConfig.isElation) {
      requestBodyForInsuranceDetailsElation = getPostRequestBodyCoverageElation(insuranceDataForRequestBody, '', true, insuranceDataForRequestBody?.planNameDataArray || [], 1);
      requestBodyForSecInsuranceDetailsElation = getPostRequestBodyCoverageElation(secInsuranceDataForRequestBody, '', true, secInsuranceDataForRequestBody?.planNameDataArray || [], 2);
    }
    if (ehrConfig.isAthena) {
      requestBodyForInsuranceDetails = getPostRequestBodyCoverageAthena(insuranceDataForRequestBody, insuranceDataForRequestBody?.copayDetailsArray, true, '', insuranceDataForRequestBody?.planNameDataArray || [], 1);
      requestBodyForSecInsuranceDetails = getPostRequestBodyCoverageAthena(secInsuranceDataForRequestBody, secInsuranceDataForRequestBody?.copayDetailsArray, true, '', secInsuranceDataForRequestBody?.planNameDataArray || [], 2);
      requestBodyForTerInsuranceDetails = getPostRequestBodyCoverageAthena(terInsuranceDataForRequestBody, terInsuranceDataForRequestBody?.copayDetailsArray, true, '', terInsuranceDataForRequestBody?.planNameDataArray || [], 3);
    }

    if (allowInsurance) {
      setLeadFormData((prev: any) => {
        const prevContained = Array.isArray(prev.contained) ? prev.contained : [];
        const concatenatedContained = ehrConfig.isElation ? [requestBodyForInsuranceDetailsElation, requestBodyForSecInsuranceDetailsElation] : [requestBodyForInsuranceDetails, requestBodyForSecInsuranceDetails, requestBodyForTerInsuranceDetails];
        return {
          ...prev,
          contained: [...concatenatedContained] || [],
        };
      });
    }
  },[insuranceDataForRequestBody,secInsuranceDataForRequestBody,terInsuranceDataForRequestBody])

  useEffect(() => {

    setPersonTypeContact(
      props?.singleLeadData?.contactType?.contactType?.code ||
      personTypeObj.personType
    );

    if (props.singleLeadData?.patient?.patientId) {
      setFormStatus({...formStatus, loading: true});
      const accountLocationUuid = props.singleLeadData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
      getPatientDetails(
        props.singleLeadData?.patient?.patientId,
        async (response: any) => {
          const maritalStatus = getPatientMartialStatus(response.data);
          const object = getSexAtBirthFromResponse(response.data);
          const practitionerId = getPractitionerIdFromResponse(response.data);
          const legalSexFromResponse = getLegalSexFromResponse(response.data);
          const sexualOrientationObject = getSexualOrientationFromResponse(response.data, patientCapability) || {};
          const raceData = getCodeListFromResponse(response.data, raceCapability);
          const ethnicityData = getCodeListFromResponse(response.data, ethnicityCapability);
          const {abbr, cityName} = getStateAndCityNameFromResponse(
            response.data
          );
          let locationList:any[] = [];
          if (isAthena) {
            const departmentId =
              getExtensionByUrl(
                response?.data?.extension,
                EXTENSION_URLS.departmentId
              )?.valueInteger || '';
            const locationData = await getAllLocations();
            locationList = await formatLocationsForAthena(
              locationData?.data?.entry,
              departmentId
            );
          }
          setLeadFormData((prev: any) => {
            return {
              ...prev,
              sexAtBirth: object?.valueCode || '',
              practitionerId: practitionerId,
              legalSex: legalSexFromResponse,
              cityName: cityName,
              abbr: abbr,
              maritalStatus,
              sexualOrientation: sexualOrientationObject?.sexualOrientation || '',
              sexualOrientationText: sexualOrientationObject?.sexualOrientationText || '',
              race: raceData,
              ethnicity: ethnicityData,
              contained: response?.data?.contained || [],
              formattedLocations: locationList,
              // NOTE: this is fold specific handling for Emergency contact and should not impact any other ehr's
              contact: isFold ? response?.data?.contact : undefined
            }
          });
          setFormStatus({...formStatus, loading: false});
        },
        (error: any) => {
          setFormStatus({...formStatus, loading: true});
        },
        accountLocationUuid,
      );
    }

    if (props?.employerId) {
      setLeadFormData({
        ...leadFormData,
        employerId: props?.employerId,
      });
    }
    if (checkVitalsForLessThan18Age && props.singleLeadData?.patient?.patientId) {
      const heightObservation = getVitalsWithFilters(props.singleLeadData?.patient?.patientId, Vital.height, 1, 0, leadFormData?.locationUuid || defaultPracticeLocation);
      const weightObservation = getVitalsWithFilters(props.singleLeadData?.patient?.patientId, Vital.weight, 1, 0, leadFormData?.locationUuid || defaultPracticeLocation);
      Promise.all([heightObservation, weightObservation]).then((response) => {
        const list: Observation[] = [];
        if (response?.[0]?.data?.entry?.length) {
          list.push(response[0].data?.entry?.[0]?.resource);
        }
        if (response?.[1]?.data?.entry?.length) {
          list.push(response[1].data?.entry?.[0]?.resource);
        }
        setLeadFormData((prev: any) => {
          return {
            ...prev,
            vitalObservations: list
          }
        });
        if (props.observationsUpdated) {
          props.observationsUpdated(list);
        }
      });
    }
  }, [props?.singleLeadData?.contactType?.contactType?.code, props.singleLeadData?.patient?.patientId, props?.employerId]);



  useEffect(() => {
    const onSubmitDataEvent = (event: any) => {
      const keyName = event.key;
      if (event.shiftKey && keyName === 'Enter') {
        onSubmit();
      }
    }
    if (isWeb() && window) {
      window.addEventListener('keydown', onSubmitDataEvent, false);
    }
    return () => {
      if (isWeb() && window) {
        window.removeEventListener('keydown', onSubmitDataEvent);
      }
    }
  }, []);

  const shouldCheckForDuplicates = (
    firstName: string,
    lastName: string,
    birthDate: any
  ) =>
    firstName?.length > 1 &&
    lastName?.length > 1 &&
    !leadFormData.leadId &&
    personTypeContact === PERSON_TYPES.CUSTOMER &&
    birthDate;

  const alertDuplicates = (args: {
    firstName: string;
    lastName: string;
    birthDate: any;
  }) => {
    const {firstName, lastName, birthDate} = args;
    const check = shouldCheckForDuplicates(firstName, lastName, birthDate);
    if (check) {
      getPatientsByNameAndBirthDate({
        firstName: firstName,
        lastName: lastName,
        birthDate: birthDate,
        successHandler(response) {
          if (response.data.total >= 1) {
            setAlerts((prev) => {
              return {
                ...prev,
                duplicateAlert: true,
              };
            });
          } else {
            setAlerts((prev) => {
              return {
                ...prev,
                duplicateAlert: false,
              };
            });
          }
        },
        errorHandler(error) {
        },
      });
    }
  };

  const [getContactByEmail] = useLazyQuery(LeadQueries.GetContactByEmail, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [getContactByPhone] = useLazyQuery(LeadQueries.GetContactByEmail, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const checkForExistingContactWithSameEmail = async (
    email: string,
    phone: string,
    preferredType?: string
  ) => {
    let validEmail = true;
    let validNumber = true;
    if (email) {
      let patientListRespData = {} as any
      const getQueryObj = {
        email: {_ilike: email},
        accountId: {_eq: accountId},
        isDeleted: {_eq: false},
      };
      const duplicateEmailResp = await getContactByEmail({
        variables: {
          where: getQueryObj,
        },
      });
      if (duplicateEmailResp?.data?.contacts?.length > 0) {
        const contactType =
          duplicateEmailResp?.data?.contacts[0]?.contactType?.contactType?.code;


        const message = getDuplicateEmailTextByContactType(
          contactType,
          'EMAIL'
        );
        if (preferredType === 'PRIMARY') {
          if(
            contactType === PERSON_TYPES.LEAD ||
            contactType === PERSON_TYPES.SUPPORTING_MEMBER ||
            contactType === PERSON_TYPES.OTHER
            ) {
             patientListRespData = await getContact({
              variables: {
                id: duplicateEmailResp?.data?.contacts[0]?.id,
              },
            });
          }
          setAlerts((prev) => {
            return {
              ...prev,
              sameEmailExistsAlert: message,
              contactId: duplicateEmailResp?.data?.contacts[0]?.id,
              contactData: patientListRespData?.data?.contact
            };
          });
          if (!isWeb()) {
            showToastMessage(message, ToastType.error);
          }
        } else {
          setAlerts((prev) => {
            return {
              ...prev,
              sameSecondEmailExistsAlert: message,
              contactId: duplicateEmailResp?.data?.contacts[0]?.id,
            };
          });
        }
        validEmail = false;
        if (
          leadFormData.leadId &&
          leadFormData.leadId == duplicateEmailResp?.data?.contacts[0]?.id
        ) {
          if (preferredType === 'PRIMARY') {
            setAlerts((prev) => {
              return {
                ...prev,
                sameEmailExistsAlert: '',
                contactId: '' as any,
              };
            });
          } else {
            setAlerts((prev) => {
              return {
                ...prev,
                sameSecondEmailExistsAlert: '',
                contactId: '' as any,
              };
            });
          }
          validEmail = true;
        }
      }else {
        setAlerts((prev) => {
          return {
            ...prev,
            sameSecondEmailExistsAlert: '',
            sameEmailExistsAlert: '',
            contactId: '' as any,
            contactData: {} as any
          };
        });
      }
    }
    if (phone) {
      let patientListRespData = {} as any
      const getQueryObj = {
        phoneNumber: {_ilike: unmaskPhoneNumber(phone)},
        accountId: {_eq: accountId},
        isDeleted: {_eq: false},
      };
      const duplicatePhoneResp = await getContactByPhone({
        variables: {
          where: getQueryObj,
        },
      });
      if (duplicatePhoneResp?.data?.contacts?.length > 0) {
        const contactType =
          duplicatePhoneResp?.data?.contacts[0]?.contactType?.contactType?.code;
        const message = getDuplicateEmailTextByContactType(
          contactType,
          'PHONE'
        );
        if (preferredType === 'PRIMARY') {
          if(
            contactType === PERSON_TYPES.LEAD ||
            contactType === PERSON_TYPES.SUPPORTING_MEMBER ||
            contactType === PERSON_TYPES.OTHER
            ) {
             patientListRespData = await getContact({
              variables: {
                id: duplicatePhoneResp?.data?.contacts[0]?.id,
              },
            });
          }
          setAlerts((prev) => {
            return {
              ...prev,
              sameNumberExistsAlert: message,
              contactId: duplicatePhoneResp?.data?.contacts[0]?.id,
              contactData: patientListRespData?.data?.contact
            };
          });
          if (!isWeb()) {
            showToastMessage(message, ToastType.error);
          }
        } else {
          setAlerts((prev) => {
            return {
              ...prev,
              sameSecondNumberExistsAlert: message,
              contactId: duplicatePhoneResp?.data?.contacts[0]?.id,
            };
          });
        }
        validNumber = false;
        if (
          leadFormData.leadId &&
          leadFormData.leadId == duplicatePhoneResp?.data?.contacts[0]?.id
        ) {
          if (preferredType === 'PRIMARY') {
            setAlerts((prev) => {
              return {
                ...prev,
                sameNumberExistsAlert: '',
                contactId: '' as any,
              };
            });
          } else {
            setAlerts((prev) => {
              return {
                ...prev,
                sameSecondNumberExistsAlert: '',
                contactId: '' as any,
              };
            });
          }
          validNumber = true;
        }
      }else {
        setAlerts((prev) => {
          return {
            ...prev,
            sameNumberExistsAlert: '',
            sameSecondNumberExistsAlert: '',
            contactId: '' as any,
            contactData: {} as any
          };
        });
      }
    }
    return validNumber && validEmail;
  };

  const handleTagRemove = (tagObj: any) => {
    const updatedTag = selectedTagData?.tag?.filter((tag: string) => {
      return tagObj.id !== parseInt(tag);
    });

    const updatedTagObj = selectedTagData?.selectedTags.filter((tag: any) => {
      return tag.id !== tagObj.id;
    });
    setFinalTagList(updatedTagObj);
    setSelectedTags({
      ...selectedTagData,
      selectedTags: updatedTagObj,
      tag: updatedTag,
    });
    setLeadFormData({
      ...leadFormData,
      contactTags: updatedTag,
    });
  };

  const setFinalTagList = (value: any) => {
    const tags = labelData.tagList.filter((tag: any) => {
      return !value.includes(tag.id.toString());
    });
    setLabelData({...labelData, finalTaglist: tags});
  };

  const getContactConsent = () => {
    const  memberConsent = {
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
    };
    if (consentData?.consentList?.length) {
      (consentData?.consentList || []).forEach((consentId: string) => {
        const consentCode = getMlovCodeFromId(consentMlovList, consentId);
        switch (consentCode) {
          case CONSENT_CONST.consentToCall:
            memberConsent.consentToCall = true;
            break;
          case CONSENT_CONST.consentToEmail:
            memberConsent.consentToEmail = true;
            break;
          case CONSENT_CONST.consentToText:
            memberConsent.consentToText = true;
            break;
        }
      });
    }
    return memberConsent;
  }

  const updateCustomFields = (updatedFieldList: IEhrFieldList[]) => {
    const data = formatCustomEhrFieldsForSave(
      updatedFieldList,
      leadFormData?.contained
    );
    setLeadFormData((prev: any) => ({
      ...prev,
      ehrAttributes: [data],
    }
  ))
  }

  const handleSetInsuraceFieldRequestBody = (requestBody:IInsuranceState) => {
    setInsuranceDataForRequestBody(requestBody);
  }

  const handleSetSecInsuraceFieldRequestBody = (requestBody:IInsuranceState) => {
    setSecInsuranceDataForRequestBody(requestBody);
  }

  const handleSetTerInsuraceFieldRequestBody = (requestBody:IInsuranceState) => {
    setTerInsuranceDataForRequestBody(requestBody);
  }

  useEffect(() => {
    if (!isWeb()) {
      setPersonTypeObj((prev) => {
        return {
          ...prev,
          personType: props?.route?.params?.personType,
        };
      });
      setPersonTypeContact(props?.route?.params?.personType);
      if (props?.route?.params?.personType) {
        setPersonTypeObj((prev) => {
          return {
            ...prev,
            personTypeUuid: getContactTypeId(
              props?.route?.params?.personType
            ),
          };
        });
      }
    } else {
      setPersonTypeObj((prev) => {
        return {
          ...prev,
          personType: props.personType,
          personTypeUuid: props.personTypeUuid,
        };
      });
    }
  }, []);

  useEffect(() => {
    // NOTE: THIS EFFECT IS ONLY TO HANDLE CASE WHEN THERE'S ONLY ONE LOCTION AVAILABLE.
    if (
      isLocationRequired &&
      practiceLocation.length === 1 &&
      !leadFormData?.locationUuid &&
      isPatientCreationFlow
    ) {
      setLeadFormData((prev: any) => ({
        ...prev,
        locationUuid: defaultPracticeLocation,
      }));
    }
  },[leadFormData?.locationUuid, practiceLocation.length]);

  useEffect(() => {
    const selectedObj = personTypeList?.find?.((item: any) => {
      return item?.id === props?.personTypeUuid;
    });
    setLeadFormData({
      ...leadFormData,
      contactType: selectedObj,
      savedContactType: selectedObj,
      contactTypeId: props?.personTypeUuid,
    });
  },[props?.personTypeUuid, stateData?.customContactTypes])

  useEffect(() => {
    const customContactTypes = defaultContactTypes?.data?.customContactTypes || mlovData?.defaultContactTypes?.customContactTypes;
    setStateData((prev) => {
      return {
        ...prev,
        customContactTypes: customContactTypes
      }
    })
  },[defaultContactTypes?.data])

  return (
    <>
      {selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
        <AddOrUpdateLeadView
          isMEDEnabled={isMEDEnabled}
          deceasedDateOfCurrentPatient = {deceasedDate}
          updateCustomFields={updateCustomFields}
          setInsuranceDataForRequestBody={handleSetInsuraceFieldRequestBody}
          setSecInsuranceDataForRequestBody={handleSetSecInsuraceFieldRequestBody}
          setTerInsuranceDataForRequestBody={handleSetTerInsuraceFieldRequestBody}
          customFieldResponse={leadFormData?.contained}
          isPatientCreationFlow={isPatientCreationFlow}
          isEmployerHidden={
            patientCapability?.keyAllowedOperations?.employer?.isHidden
          }
          isLocationDisabled={isLocationDisabled}
          isLocationRequired={isLocationRequired}
          locationFieldLabel={patientCapability?.keyAllowedOperations?.location?.foldDisplay || "Select Location"}
          isFetchingContactDetails={isFetchingContactDetails}
          isShowAddContactTypeOption={props.isShowAddContactTypeOption}
          loggedInTime={
            props.singleLeadData?.additionalAttributes?.loggedInTime
          }
          leadFormData={leadFormData}
          setLeadFormData={setLeadFormData}
          setConsentChanges={(code: string, data: any) => {
            if (code === 'consent') {
              setConsentData({
                ...consentData,
                consent: data,
              });
            } else if (code === memberConsent) {
              setConsentData({...consentData, memberConsent: data});
            } else {
              setConsentData({
                ...consentData,
                consent: data,
                consentAlreadyGiver: data,
              });
            }
          }}
          setConsentList={(consentList: string[]) => {
            setConsentData((prev: any) => {
              return {
                ...prev,
                consentList: consentList
              };
            });
          }}
          personTypeContact={personTypeContact}
          showMore={showMore}
          showModal={isShowModal !== undefined ? isShowModal : showModal}
          onCloseDrawer={() => {
            if (isShowModal !== undefined) {
              handleCloseModal();
              setSelectedViewCode('')
            } else {
              setShowModal(false);
              onCancel();
              setSelectedViewCode('')
            }
          }}
          isSubmitting={isSubmitting}
          onCancelClick={() => {
            if (isShowModal !== undefined) {
              handleCloseModal();
            } else {
              setShowModal(false);
              onCancel();
            }
          }}
          onSubmit={onSubmit}
          formStatus={formStatus}
          styles={styles}
          alerts={alerts}
          errors={errors}
          alertDuplicates={alertDuplicates}
          checkForExistingContactWithSameEmail={
            // checkForExistingContactWithSameEmail
            ''
          }
          shouldCreateUsingIntegration={shouldCreateUsingIntegration}
          bloodGroupList={bloodGroupList}
          selectBloodGroup={selectBloodGroup}
          genderAtBirthLabel={genderAtBirthLabel}
          isGenderAtBirthRequired={isGenderAtBirthRequired}
          genderAtBirthArray={genderAtBirthArray}
          genderIdentityLabel={genderIdentityLabel}
          isGenderIdentityHidden={isGenderIdentityHidden}
          isPractitionerRequired={isPractitionerRequired}
          practitionerLabel={practitionerLabel}
          isMobileNumberRequired={isMobileNumberRequired}
          isAddressLine1Required={isAddressLine1Required}
          checkVitalsForLessThan18Age={checkVitalsForLessThan18Age}
          personGender={personGender}
          mask={mask}
          sendInvite={sendInvite}
          setSendInvite={setSendInvite}
          labelData={labelData}
          selectedTagData={selectedTagData}
          setFinalTagList={setFinalTagList}
          setSelectedTags={setSelectedTags}
          handleTagRemove={handleTagRemove}
          defaultPracticeLocation={defaultPracticeLocation}
          practiceLocationObject={
            useAthenaMappedLocation
              ? (leadFormData?.formattedLocations || [])
              : (practiceLocationObject || [])
          }
          employers={employers}
          setShowMore={setShowMore}
          selectZipcode={selectZipcode}
          updateOnSubmitFunData={updateOnSubmitFunData}
          screenSetting={screenSetting}
          setScreenSetting={setScreenSetting}
          onDismissCalendarView={onDismissCalendarView}
          selectGender={selectGender}
          selectContactType={selectContactType}
          personTypeList={personTypeList}
          headerName={props?.headerName}
          isLegalSexRequired={isLegalSexRequired}
          invocationCode={selectLeadViewCode.invocationCode || props.invocationCode}
          alert={selectLeadViewCode.alert}
          isPhoneOrEmailRequired={isPhoneOrEmailRequired}
          showConsentError={
            props?.showConsentError || props?.singleLeadData?.showConsentError
          }
          addressLine1MaxChar={addressLine1MaxChar}
          addressLine2MaxChar={addressLine2MaxChar}
          onPersonTypeChange={(personTypeObj: IMlov) => {
            setPersonTypeObj((prev) => {
              return {
                ...prev,
                personTypeUuid: personTypeObj.id,
                personType: personTypeObj.code || PERSON_TYPES.LEAD,
              };
            });
            setPersonTypeContact(personTypeObj.code || PERSON_TYPES.LEAD);
          }}
          contactTypeId={props?.contactType?.id ? props.contactType.id: personTypeObj.personTypeUuid}
          personTypeObj={personTypeObj}
          fieldKeys={fieldKeys}
          fieldsConfig={fieldsConfig}
          handleVitalChange={(vital: Vital, value: string) => handleVitalChange(vital, value)}
          pronounList={pronounList}
          setAlerts={setAlerts}
          handleChange={props.onFormCompleteAction}
          canUpdateLocationOnZipCode={!isLocationDisabled}
          isRaceRequired={isRaceRequired}
          isRaceMultiple={isRaceMultiple}
          isEthnicityRequired={isEthnicityRequired}
          isEthnicityMultiple={isEthnicityMultiple}
          isSexualOrientationHidden={isSexualOrientationHidden}
          isMartialStatusHidden={isMartialStatusHidden}
          isRaceHidden={isRaceHidden}
          isEthnicityHidden={isEthnicityHidden}
          isSexualOrientationRequired={isSexualOrientationRequired}
          isMartialStatusRequired={isMartialStatusRequired}
          onCustomAttributeDetailsChange={(customAttributesValue: IFormattedCustomFieldsData[])=> {
            setStateData((prev)=> {
              return {
                ...prev,
                customFieldsValue: customAttributesValue,
              }
            })
          }}
          customFieldsError={stateData?.customFieldsError}
          contactTypeLoading={defaultContactTypes?.loading}
        />
      )}

      {selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW && (
        <MessagingContactDetailsDrawer
          contactData={selectedRowData}
          isDrawerVisible={
            selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW
              ? true
              : false
          }
          contactType={personTypeContact}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setSelectedViewCode('');
              setTimeout(() => {
                props.onFormCompleteAction(
                  COMMON_ACTION_CODES.ADDED_OR_UPDATED
                );
              }, 10);
            }
          }}
          borderLessView={true}
        />
      )}
      {
        showUpdateAlertModal &&
        (
          <Modal
            title="Confirmation"
            open={showUpdateAlertModal}
            onCancel={() => {
              setShowUpdateAlertModal(false);
            }}
            footer={null}
            bodyStyle={{
              paddingTop: 12,
              zIndex: 9999999999
            }}
          >
            <View>
              <Text size={'mdMedium'} >
                {
                  intl.formatMessage({
                    id: 'contactUpdateConfirmationMessage',
                  })
                }
              </Text>
              <Button.Group style={{marginTop: 12}} justifyContent={'flex-end'}>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      setShowUpdateAlertModal(false);
                      updateContactDataAfterConfirm();
                    },
                  }}
                  customProps={{
                    btnText: intl.formatMessage({
                      id: 'Yes',
                    }),
                    withRightBorder: false,
                  }}
                ></FoldButton>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.SECONDARY,
                    onPress: () => {
                      setShowUpdateAlertModal(false);
                    },
                  }}
                  customProps={{
                    btnText: intl.formatMessage({
                      id: 'No',
                    }),
                    withRightBorder: false,
                  }}
                ></FoldButton>

              </Button.Group>
            </View>
          </Modal>
        )
      }
    </>
  );
};
