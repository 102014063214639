import {Drawer} from 'antd';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles/Colors';
import {Pressable, View, Text} from 'react-native';
import {useIntl} from 'react-intl';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {EMAIL_INBOX_USER_VIEW} from './PreserenceConst';
import {IEmailInboxUserView, IPreferenceSettingState} from './PreferencesInterface';
import {styles} from './PreferenceStyle';
import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {UserQueries} from '../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {getUserUUID} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import {ICustomToast} from '../../RightSideContainer/Contacts/CustomField/interface';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from '../../RightSideContainer/Contacts/CustomField/CustomFieldConst';
import {useToast} from '../../Toast/ToastProvider';
import {ToastType} from '../../../utils/commonViewUtils';
import {CUSTOM_COMMON_EVENT_CODES} from '../../../constants/WebSocketConst';
import {addUpdateUserPreference} from './PreferenceService';


export const PreferencesSetting = (props: any) => {
  const intl = useIntl();
  const [stateData, setStateData] = useState<IPreferenceSettingState>({
    prevEmailInboxUserView: EMAIL_INBOX_USER_VIEW[1],
    selectedEmailInboxUserView: EMAIL_INBOX_USER_VIEW[1],
    preferenceData: {},
    preferenceDataId: undefined,
    loading: false,
    userPreferenceLoading: false,
  })
  const toast = useToast();
  const userUuid = getUserUUID();

  const [addOrUpdatePreferences] = useMutation(
    UserQueries.ADD_OR_UPDATE_USER_PREFERENCE,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const setPreference = async () => {
    if (stateData?.selectedEmailInboxUserView?.code === stateData?.prevEmailInboxUserView?.code) {
      props?.onClose();
      return;
    }
    const prevJSON = stateData?.preferenceData || {};
    const newJSON = {
      ...prevJSON,
      userPreference: {
        emailInboxUserView: stateData?.selectedEmailInboxUserView?.code
      }
    };
    const data = {
      object: {
        userId: userUuid,
        userPreferencesTypeId: props?.userPreferenceId,
        preferencesJson: JSON.stringify(newJSON),
        id: stateData?.preferenceDataId,
      },
    };
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      }
    })
    try {
      const response = await addUpdateUserPreference(data);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
      if (response?.data?.userPreferences?.[0]?.id) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(CUSTOM_COMMON_EVENT_CODES.USER_PREFERENCE_UPDATED, {
          selectedEmailInboxUserView: stateData?.selectedEmailInboxUserView?.code,
        });
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'preferenceSuccess'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
      }
      props?.onClose();
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
      props?.onClose();
    }
  }

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        preferenceData: props?.preferenceData,
        preferenceDataId: props?.preferenceDataId,
        selectedEmailInboxUserView: props?.selectedEmailInboxUserView,
        prevEmailInboxUserView: props?.selectedEmailInboxUserView,
      }
    })
  },[])

  return (
    <Drawer
      visible={true}
      width={450}
      style={{paddingLeft: '0', paddingRight: '0'}}
      onClose={() => {
        props?.onClose();
      }}
      title={
        <View>
          <View>
            <ModalActionTitle
              title={intl.formatMessage({id: 'userPreference'})}
              titleColor={Colors.Custom.Gray800}
              titleSize={'24px'}
              rightButton={
                <View
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <FoldButton
                    customProps={{
                      btnText: 'Save',
                    }}
                    nativeProps={{
                      onPress() {
                        setPreference();
                      },
                      isLoading: stateData?.loading,
                      backgroundColor: Colors.Custom.Primary300,
                      _text: {
                        fontSize: 14,
                        lineHeight: 16.8,
                      },
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  />
                  <View
                    style={{
                      width: 1,
                      height: props.height || 16,
                      marginHorizontal: 8,
                      backgroundColor: Colors.Custom.Gray300,
                    }}
                  />
                  <Pressable
                    onPress={() => {
                      props?.onClose();
                    }}
                    disabled={false}
                  >
                    <AntIcon
                      style={{
                        marginLeft: 2
                      }}
                      name="close"
                      size={20} color={Colors.Custom.Gray400}
                    />
                  </Pressable>
                </View>
              }
            />
          </View>
        </View>
      }
    >
      <View
        style={{
          marginTop: -16,
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 12,
          }}
        >
          <View
            style={{
              padding: 10
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: Colors.FoldPixel.GRAY400
              }}
            >
              {intl.formatMessage({id: 'emailInboxView'})}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: Colors.FoldPixel.GRAY300,
                fontWeight: '400',
                marginTop: 4,
              }}
            >
              {intl.formatMessage({id: 'emailInboxPreferenceMsg'})}
            </Text>
          </View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.Custom.Gray200,
            }}
          />
          <View
            style={{
              paddingHorizontal: 10,
              paddingVertical: 4
            }}
          >
            {EMAIL_INBOX_USER_VIEW?.map?.((view: IEmailInboxUserView) => {
              return (
                <Pressable
                  key={view.code}
                  style={styles.radioButton}
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedEmailInboxUserView: view,
                      }
                    })
                  }}
                >
                  <View style={stateData?.selectedEmailInboxUserView?.code === view?.code ? styles.radioCircleSelected :  styles.radioCircle}>
                    {stateData?.selectedEmailInboxUserView?.code === view?.code && <View style={styles.selectedRadioCircle} />}
                  </View>
                  <Text style={{
                    marginLeft: 10,
                    fontSize: 14,
                    color: Colors.FoldPixel.GRAY400,
                  }}
                  >
                    {view?.title}
                  </Text>
                </Pressable>
              )
            })}
          </View>
        </View>
      </View>
    </Drawer>
  );
};
