import React, {useContext} from 'react';
import TaskListDrawerView from './TaskListDrawerView';
import AddOrUpdateTask from '../../AddTask/AddOrUpdateTask';
import {ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {
  getUserData,
  getUserFullName,
  getUserName,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {useLazyQuery, useMutation} from '@apollo/client';
import TaskQueries, {
  GET_TASK_DELETED_STATUS_BY_ID,
} from '../../../../services/Task/TaskQueries';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {Box, useToast} from 'native-base';
import {useIntl} from 'react-intl';
import {
  getCompletedTaskStatusId,
  getDefaultTaskStatusId,
} from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {BottomViewAction} from '../../../TaskCard/TaskCardHelper';
import {IMessageBoxDrawerAction} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import { getFilteredMentionMessage } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';

const MessageBoxDrawerAction = (props: IMessageBoxDrawerAction) => {
  const currentUserData = getUserData();
  const userName = getUserName();
  const userUuid = getUserUUID();
  const intl = useIntl();
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const defaultStatusIdForTask = getDefaultTaskStatusId(taskStatusMlov);

  const [getTaskStatusById] = useLazyQuery(GET_TASK_DELETED_STATUS_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [updateTaskStatus, {loading: isUpdateTaskStatusLoading}] = useMutation(
    TaskQueries.UPDATE_TASK_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (resp) => {
        const {updateTask} = resp;
        showTaskUpdateToastMsg(updateTask?.statusId || '');
      },
      onError: (error: any) => {

        showErrorPopup();
      },
    }
  );

  const showTaskUpdateToastMsg = (statusId: string) => {
    const isTaskCompleted = completedStatusId === statusId;
    const toastType = isTaskCompleted ? ToastType.success : ToastType.info;
    showToast(
      toast,
      intl.formatMessage({
        id: isTaskCompleted ? 'taskCompletedMsg' : 'taskIncompleteMsg',
      }),
      toastType,
      3000
    );
    props.onFormActionPerformed(COMMON_ACTION_CODES.TASK_CANCEL);
  };

  const showErrorPopup = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error,
    );
  };

  const getSenderUserId = (selectedMessageData: any) => {
    if (selectedMessageData?.senderType?.toLowerCase() == 'user') {
      return selectedMessageData?.senderUser?.id;
    } else if (selectedMessageData?.senderType?.toLowerCase() == 'contact') {
      return selectedMessageData?.senderContact?.id;
    }
    return {};
  };

  const getMessageSenderName = (selectedMessageData: any) => {
    let senderName = '';
    if (selectedMessageData?.senderType?.toLowerCase() == 'user') {
      if (selectedMessageData?.senderFullName == userName) {
        senderName = 'You';
      } else {
        senderName =
          selectedMessageData?.senderUser?.name ||
          selectedMessageData?.senderFullName ||
          '';
      }
    } else {
      senderName =
        selectedMessageData?.senderContact?.name ||
        selectedMessageData?.senderFullName ||
        '';
    }
    if (selectedMessageData?.messageData?.contentAttributes?.isAutoResponse) {
      senderName = 'Automated Message';
    }
    return senderName.length ? senderName : '';
  };

  const updateTaskStatusById = async (task: ITask, statusId: string) => {
    await updateTaskStatus({
      variables: {
        params: {
          id: task.id,
          data: {
            statusId,
          },
        },
      },
    });
  };

  const markTaskStatus = (task: ITask, isCompleted: boolean) => {
    if (task && task.taskType?.code !== 'dummy') {
      getTaskStatusById({
        variables: {
          taskId: task?.id,
        },
      })
        .then((response) => {
          if (
            response.data?.tasks?.length &&
            response.data?.tasks[0].isDeleted
          ) {
            showToast(
              toast,
              intl.formatMessage({id: 'alreadyDeletedTask'}),
              ToastType.info,
              2000
            );
          } else {
            updateTaskStatusById(
              task,
              isCompleted ? completedStatusId : defaultStatusIdForTask
            );
          }
        })
        .catch((error) => {
          showToast(
            toast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error,
            1000
          );
        });
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'dummyTaskCompleteMsg'}),
        ToastType.success,
      );
    }
  };

  const getAddorUpdateTask = (taskDetails: any) => {
    const filteredText = getFilteredMentionMessage(taskDetails?.newTaskData?.displayContent)
    return (
      <AddOrUpdateTask
        key={taskDetails?.task?.id ? 'editTask' : 'addNewTask'}
        task={
          taskDetails?.task?.id
            ? taskDetails?.task
            : ({
                contact: props?.contactData,
              } as any)
        }
        extraData={{
          description: filteredText,
          userId:
            taskDetails?.newTaskData?.userID ||
            getSenderUserId(taskDetails?.newTaskData),
          userName:
            taskDetails?.newTaskData?.senderName ||
            getMessageSenderName(taskDetails?.newTaskData),
          dateTime: taskDetails?.newTaskData?.dateStr,
        }}
        member={{
          value: props?.contactData?.uuid,
          label: props?.contactData?.name,
          key: props?.contactData?.uuid,
          type: ParticipantType.patient,
          contactData: props?.contactData,
          details: props?.contactData,
        }}
        isVisible={taskDetails.viewTaskModal}
        onComplete={(data) => {
          const actionCode = taskDetails?.task?.id
            ? COMMON_ACTION_CODES.TASK_UPDATE
            : COMMON_ACTION_CODES.TASK_COMPLETE;
          props?.onFormActionPerformed(
            actionCode,
            data.addOrUpdateTask,
            taskDetails?.newTaskData?.messageList
          );
        }}
        onCancel={() => {
          props.onFormActionPerformed(
            taskDetails?.task?.id
              ? COMMON_ACTION_CODES.TASK_CANCEL
              : COMMON_ACTION_CODES.VIEW_TASK_CLOSE
          );
        }}
        onMarkAsComplete={(
          task: ITask,
          action: BottomViewAction,
          data?: any
        ) => {
          markTaskStatus(task, true);
        }}
        fetchAllTypeTask={() => {
          props.onFormActionPerformed(COMMON_ACTION_CODES.TASK_CANCEL);
        }}
        restrictPatientOrLeadSelectionTo={{
          value: props?.contactData?.uuid,
          label: props?.contactData?.name,
          key: props?.contactData?.uuid,
          type: ParticipantType.patient,
          contactData: props?.contactData,
          details: props?.contactData,
        }}
      />
    );
  };

  const getTaskListDrawerView = (taskDetails: any) => {
    return (
      <TaskListDrawerView
        taskMessageUuid={taskDetails?.messageUuid}
        taskModal={taskDetails.taskModal}
        onActionPerformed={(actionCode: string, actionData?: ITask) => {
          props.onFormActionPerformed(actionCode, actionData);
        }}
      />
    );
  };

  const getMessageBoxAction = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CREATE_TASK:
        return getAddorUpdateTask(actionData);

      case COMMON_ACTION_CODES.VIEW_TASK:
        return getTaskListDrawerView(actionData);
    }
  };
  return <>{getMessageBoxAction(props?.actionCode, props?.actionData)}</>;
};

export default MessageBoxDrawerAction;
