import React from "react";

function PinnedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="36"
      fill="none"
      viewBox="0 0 38 36"
    >
      <g filter="url(#filter0_dd_1165_159860)">
        <rect
          width="32"
          height="29.065"
          x="3"
          y="2"
          fill="#FDFAFF"
          rx="14.533"
        ></rect>
        <path
          fill="#825AC7"
          d="M20.794 18.426l1.502-1.496.338.338c.193.193.489.186.689-.014l1.605-1.592c.104-.296.11-.585-.082-.778l-4.245-4.21c-.193-.194-.49-.187-.69.013l-1.605 1.592a.466.466 0 00-.014.682l.338.338-1.502 1.495c-.738-.13-2.743-.434-3.845.662-.2.2-.207.489-.014.682l2.75 2.729.323.324.352.344 2.75 2.729c.289.241.585.138.688-.014 1.103-1.096.8-3.087.662-3.817v-.007z"
        ></path>
        <path
          stroke="#825AC7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13 22.533l3.349-3.342m4.445-.765l1.502-1.496.338.338c.193.193.489.186.689-.014l1.605-1.592c.104-.296.11-.585-.082-.778l-4.245-4.21c-.193-.194-.49-.187-.69.013l-1.605 1.592a.466.466 0 00-.014.682l.338.338-1.502 1.495c-.738-.13-2.743-.434-3.845.662-.2.2-.207.489-.014.682l2.75 2.729.323.324.352.344 2.75 2.729c.289.241.585.138.688-.014 1.103-1.096.8-3.087.662-3.817v-.007z"
        ></path>
        <rect
          width="31.5"
          height="28.565"
          x="3.25"
          y="2.25"
          stroke="#EFE0FC"
          strokeWidth="0.5"
          rx="14.283"
        ></rect>
      </g>
      <defs>
        <filter
          id="filter0_dd_1165_159860"
          width="38"
          height="35.066"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1165_159860"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1165_159860"
            result="effect2_dropShadow_1165_159860"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1165_159860"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default PinnedIcon;
