import { gql } from '@apollo/client';

export const GET_CRM_APP_INFO = gql`
  query getCrmAppInfo {
    contactAggregate(
      where: {
        isDeleted: {_eq: false}
        contactType: {contactType: {code: {_eq: "CUSTOMER"}}}
      }
    ) {
      aggregate {
        count
      }
    }
    messageAggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CARE_STUDIO_APP_INFO = gql`
  query {
    aggregateForms {
      aggregate {
        count
      }
    }
    aggregateAppointments {
      aggregate {
        count
      }
    }
    aggregateCareJourneys {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TODAYS_NEXT_APPOINTMENT_WITH_LIMIT = gql`
  query getAppointments(
    $userUUID: uuid!
    $limit: Int!
    $offset: Int
    $fromTime: timestamptz!
    $toTime: timestamptz!
    $tenantId: uuid!
  ) {
    appointments(
      order_by: {startDateTime: asc}
      limit: $limit
      offset: $offset
      where: {
        participants: {userId: {_eq: $userUUID}}
        startDateTime: {_gte: $fromTime, _lte: $toTime}
        tenantId: {_eq: $tenantId}
        name: {_neq: "Blocked"}
        isDeleted: {_eq: false}
      }
    ) {
      tenantId
      name
      startDateTime
      endDateTime
      participants {
        id
        contactId
      }
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query getAppointments(
    $fromTime: timestamptz!
    $toTime: timestamptz!
    $tenantId: uuid!
  ) {
    appointments(
      where: {
        startDateTime: {_gte: $fromTime, _lte: $toTime}
        tenantId: {_eq: $tenantId}
        isDeleted: {_eq: false}
      }
    ) {
      tenantId
      name
      startDateTime
      endDateTime
      participants {
        id
        contactId
      }
    }
  }
`;

export const GET_APPOINTMENT_WITH_USER_UUID = gql`
  query getAppointments(
    $userIds: [uuid!]
    $fromTime: timestamptz!
    $toTime: timestamptz!
    $tenantId: uuid!
    $statusIds: [uuid!]
  ) {
    appointments(
      where: {
        participants: {userId: {_in: $userIds}}
        startDateTime: {_gte: $fromTime, _lte: $toTime}
        tenantId: {_eq: $tenantId}
        isDeleted: {_eq: false}
        statusId: {_in: $statusIds}
        isBlockedInterval: {_eq: false}
      }
    ) {
      tenantId
      name
      startDateTime
      participants {
        id
        contactId
      }
    }
  }
`;

export const GET_CONTACT_BY_UUID = gql`
  query getContactByUuId($contactUUID: uuid!) {
    contacts(where: {uuid: {_eq: $contactUUID}}) {
      contactDeactivationInfo {
        isActive
      }
      contactProfilePhotos{
        attachmentId
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
        }
        id
      }
      accountId
      notes {
        contactId
        content
        userId
        id
      }
      phoneNumber
      pubsubToken
      source
      updatedAt
      name
      email
      id
      uuid
      createdAt
      personContact {
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        id
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
      }
      employees {
        employerId
        contactId
        employer {
        name
      }
      }
    }
  }
`;

export const GET_CONTACT_BY_CONTACT_ID_LIST = gql`
  query getContactByUuId($contactIdList: [bigint!]!) {
    contacts(where: {id: {_in: $contactIdList}}) {
      name
      id
      person {
        gender {
          value
          code
        }
        birthDate
      }
      phoneNumber
    }
  }
`;

export const GET_CONTACT_NAME_BY_CONTACT_ID_LIST = gql`
  query getContactByUuId($contactIdList: [bigint!]!, $searchString:String) {
    contacts(limit:100,where: {name:{_ilike:$searchString},id: {_in: $contactIdList}}) {
      name
      id
      uuid
    }
  }
`;

export const GET_AGGREGATE_APPOINTMENTS = gql`
  query getAggregateAppointments($endDate: timestamptz) {
    aggregateAppointments(
      where: {isDeleted: {_eq: false}, createdOn: {_lte: $endDate}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_AGGREGATE_APPOINTMENTS_WITH_USER_ID = gql`
  query getAggregateAppointments($userUUID: uuid!, $endDate: timestamptz) {
    aggregateAppointments(
      where: {
        participants: {userId: {_eq: $userUUID}}
        createdOn: {_lte: $endDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_AGGREGATE_MEMBER = gql`
  query getContactCount($endDate: timestamp) {
    contactAggregate(
      where: {
        createdAt: {_lte: $endDate}
        contactType: {contactType: {code: {_eq: "CUSTOMER"}}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DAY_AGGREGATE_MEMBER = gql`
  query getContactCount($startDate: timestamp, $endDate: timestamp) {
    contactAggregate(
      where: {
        createdAt: {_gte: $startDate, _lte: $endDate}
        contactType: {contactType: {code: {_eq: "CUSTOMER"}}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_AGGREGATE_LEAD = gql`
  query getContactCount($endDate: timestamp) {
    contactAggregate(
      where: {
        createdAt: {_lte: $endDate}
        contactType: {contactType: {code: {_eq: "LEAD"}}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DAY_AGGREGATE_LEAD = gql`
  query getContactCount($startDate: timestamp, $endDate: timestamp) {
    contactAggregate(
      where: {
        createdAt: {_gte: $startDate, _lte: $endDate}
        contactType: {contactType: {code: {_eq: "LEAD"}}}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DAY_AGGREGATE_APPOINTMENTS = gql`
  query getAggregateAppointments(
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    aggregateAppointments(
      where: {
        isDeleted: {_eq: false}
        createdOn: {_lte: $endDate, _gte: $startDate}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DAY_AGGREGATE_APPOINTMENTS_WITH_USER_UUID = gql`
  query getAggregateAppointments(
    $userUUID: uuid!
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    aggregateAppointments(
      where: {
        isDeleted: {_eq: false}
        participants: {userId: {_eq: $userUUID}}
        createdOn: {_lte: $endDate, _gte: $startDate}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getAggregate = (query: string): any => {
  return gql`
    ${query}
  `;
};

export const GetAppData = `
  query GetAppData($appId: String) {
    mobile_apps(where: {applicationId: {_eq: $appId}}) {
      uuid
      applicationId
      link
      minimumVersion
      name
      os
    }
  }
`;

export default {
  GET_AGGREGATE_APPOINTMENTS_WITH_USER_ID,
  GET_CRM_APP_INFO,
  GET_CARE_STUDIO_APP_INFO,
  GET_TODAYS_NEXT_APPOINTMENT_WITH_LIMIT,
  GET_APPOINTMENT,
  GET_CONTACT_BY_UUID,
  GET_AGGREGATE_APPOINTMENTS,
  GET_DAY_AGGREGATE_APPOINTMENTS,
  GET_AGGREGATE_MEMBER,
  GET_DAY_AGGREGATE_APPOINTMENTS_WITH_USER_UUID,
  GET_DAY_AGGREGATE_MEMBER,
  GET_APPOINTMENT_WITH_USER_UUID,
  GET_AGGREGATE_LEAD,
  GET_DAY_AGGREGATE_LEAD,
  GET_CONTACT_BY_CONTACT_ID_LIST,
  GET_CONTACT_NAME_BY_CONTACT_ID_LIST,
  getAggregate,
  GetAppData,
};
