import { Drawer, Input, Tabs } from 'antd';
import { HStack, Pressable, ScrollView, Text, View, VStack } from 'native-base';
import { FC, useContext, useEffect, useState } from 'react';
import { WorkflowContext } from '../../../../../context/WorkflowContext';
import {
  NodeCategory
} from '../../../../common/CircleIconView/CircleIconView';
import NewLibNodeCard from '../../LibNodeCard/NewLibNodeCard';
import { workflowTriggerStyle } from '../../Workflow/Styles';
import { runFoldAutomationSvg } from '../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg';
// import './../../Workflow/Styles.css'
import { SearchOutlined } from '@ant-design/icons';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../styles/Colors';
import Feather from 'react-native-vector-icons/Feather';

const CATEGORY = {
  OPERATION: 'OPERATION',
  FILTER: 'FILTER',
  CONDITION: 'CONDITION'
}

const NewOutputModel: FC<{
  showModalData: any;
  sourceHandle: string;
  setShowModalData: any;
  onPress: any;
  allOutputNodeList: Array<string>;
  setIsEdit?: (isOpen: boolean) => void;
  canDo?: boolean;
  isAddedByFold?: boolean;
  parentDisplayName?: any;
}> = ({
  onPress,
  showModalData,
  sourceHandle,
  setShowModalData,
  parentDisplayName,
  allOutputNodeList,
}) => {
    const intl = useIntl()
    const [searchText, setSearchText] = useState('')
    const workflowContext = useContext(WorkflowContext);
    const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
    const nodeByCategory: any = {};
    const rowLength = 1;
    const processedMap: any = {};

    allOutputNodeList.forEach((nodeType: string | number) => {
      if (
        nodeMasterDataMap[nodeType] &&
        nodeMasterDataMap?.[nodeType].displayName &&
        !nodeMasterDataMap[nodeType]?.isDeprecated &&
        nodeMasterDataMap[nodeType]?.category?.code && !processedMap[nodeType]
      ) {
        processedMap[nodeType] = nodeType;
        if (!nodeByCategory[nodeMasterDataMap[nodeType].category?.code]) {
          nodeByCategory[nodeMasterDataMap[nodeType].category?.code] = {
            list: [[]],
            ...nodeMasterDataMap[nodeType].category,
          };
        }
        let index =
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list
            .length - 1;
        if (
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list[index]
            .length >= rowLength
        ) {
          nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list.push(
            []
          );
          index = index + 1;
        }
        nodeByCategory[nodeMasterDataMap[nodeType]?.category?.code].list[
          index
        ].push(nodeType);
      }
    });

    const GetOperationNodes = () => {
      const finalNode = (
        <HStack space={3} width="full">
          {nodeByCategory[CATEGORY.OPERATION] && (
            <VStack flex={1} backgroundColor={'white'} key={CATEGORY.OPERATION} >
              <HStack flex={1}
                style={{
                  borderColor: '#E4E7EC',
                  borderWidth: 1,
                  borderRadius: 8,
                  backgroundColor: Colors.FoldPixel.GRAY50
                }}
              >
                {nodeByCategory[CATEGORY.OPERATION].list.map((subList: string[], subIndex: number) => (
                  <HStack flex={1} key={subIndex}>
                    {subList.map((outputNodeType: string, itemIndex: number) => (
                      <View
                        flex={1}
                        style={{
                          borderRightWidth: subIndex === (nodeByCategory[CATEGORY.OPERATION]?.list?.length - 1) ? 0 : 1,
                          borderColor: '#E4E7EC',

                        }}
                        key={outputNodeType + CATEGORY.OPERATION + itemIndex}
                      >
                        <NewLibNodeCard
                          isNodeEnabled={true}
                          title={(nodeMasterDataMap?.[outputNodeType])?.displayName || outputNodeType}
                          iconInfo={(nodeMasterDataMap?.[outputNodeType])?.iconInfo}
                          nodeCategory={(nodeMasterDataMap?.[outputNodeType])?.category?.code || NodeCategory.moment}
                          isOperationNode={true}
                          onClick={() => {
                            setShowModalData({
                              ...(showModalData),
                              showModal: false,
                              source: '',
                            });
                            onPress(
                              {
                                sourceHandle: sourceHandle,
                                node: outputNodeType,
                                metaData: {},
                              },
                              showModalData.clientX,
                              showModalData.clientY,
                              nodeMasterDataMap?.[outputNodeType]?.disableEdit ? false : true
                            );
                          }}
                        />
                      </View>
                    ))}
                  </HStack>
                ))}
              </HStack>
            </VStack>
          )}
        </HStack>
      )
      return finalNode
    }

    const hasMatchingNodes = nodeByCategory[CATEGORY.FILTER]?.list
      .flat()
      .some((outputNodeType: string) =>
        (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

    const GetConditionNodes = () => {
      if (!hasMatchingNodes) {
        return null;
      }

      return (
        <ScrollView style={{ maxHeight: (window.innerHeight) * 0.6 }}>
          <VStack space={3} width="full">
            {nodeByCategory[CATEGORY.FILTER] && (
              <VStack flex={1} backgroundColor={'#white'} key={CATEGORY.FILTER} borderRadius={'8px'}>
                <View style={[workflowTriggerStyle.workflowSubtitle, { padding: 6 }]} >
                  <Text size="smMedium" color="#000000">
                    {nodeByCategory[CATEGORY.FILTER].display}
                  </Text>
                </View>
                <View
                  style={
                    {
                      borderColor: '#E4E7EC',
                      borderRadius: 8,
                      borderWidth: 1,
                      backgroundColor: Colors.FoldPixel.ACCENT_LIGHT_PINK
                    }
                  }
                >
                  {nodeByCategory[CATEGORY.FILTER].list.map((subList: string[], subIndex: number) => (
                    <HStack flex={1} key={subIndex}>
                      {subList
                        .filter((outputNodeType: string) =>
                          (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((outputNodeType: string, itemIndex: number) => (
                          <View
                            flex={1}
                            style={
                              {
                                borderColor: '#E4E7EC',
                                borderBottomWidth: subIndex === (nodeByCategory[CATEGORY.FILTER]?.list?.length - 1) ? 0 : 1,
                              }
                            }
                            key={outputNodeType + CATEGORY.FILTER + itemIndex}
                          >
                            <NewLibNodeCard
                              isNodeEnabled={true}
                              title={(nodeMasterDataMap?.[outputNodeType])?.displayName || outputNodeType}
                              iconInfo={(nodeMasterDataMap?.[outputNodeType])?.iconInfo}
                              nodeCategory={(nodeMasterDataMap?.[outputNodeType])?.category?.code || NodeCategory.moment}
                              isConditionNode={true}
                              onClick={() => {
                                setShowModalData({
                                  ...(showModalData),
                                  showModal: false,
                                  source: '',
                                });
                                onPress(
                                  {
                                    sourceHandle: sourceHandle,
                                    node: outputNodeType,
                                    metaData: {},
                                  },
                                  showModalData.clientX,
                                  showModalData.clientY,
                                  true
                                );
                              }}
                            />
                          </View>
                        ))}
                    </HStack>
                  ))}
                </View>
              </VStack>
            )}
          </VStack>
        </ScrollView>
      )
    };

    const shouldRenderCategory = (sortedList: string[][]) => { 
      return sortedList.some((subList: string[]) => {
        return subList.some((outputNodeType: any) => {
          return doesNodeMatchSearch(outputNodeType)
      })})
    };

    const doesNodeMatchSearch = (outputNodeType: string) => {
      return (
        (nodeMasterDataMap?.[outputNodeType]?.displayName || outputNodeType)
        .toLowerCase()
        .includes(searchText.toLowerCase())
      )
    };

    const sortByPriority = (nodeTypeA: number, nodeTypeB: number) => {
      const priorityA = nodeMasterDataMap?.[nodeTypeA]?.nodeSequencePriority || 0;
      const priorityB = nodeMasterDataMap?.[nodeTypeB]?.nodeSequencePriority || 0;
      return priorityB - priorityA;
    };

    const actionNodes = Object.keys(nodeByCategory).filter(category => ![CATEGORY.OPERATION, CATEGORY.FILTER].includes(category));

    const getSortedList = (category : string) =>{
      return (
        nodeByCategory[category].list.sort(sortByPriority) 
      )
    }
  

    const RenderCategory = (props:any) => {
      const {
        category,
        index,
        sortedList
      } = props
      
      const renderCategory = shouldRenderCategory(sortedList)
      if (renderCategory) {
        return (
          <VStack flex={1} backgroundColor={'white'} key={category + index} borderRadius={'8px'}>
            {renderCategory && (
              <View style={[workflowTriggerStyle.workflowSubtitle, { padding: 6 }]}>
                <Text size="smMedium" color="#000000">
                  {nodeByCategory[category].display}
                </Text>
              </View>
            )}
            <View
              style={{
                borderColor: Colors.FoldPixel.GRAY100,
                borderRadius: 8,
                borderWidth: renderCategory ? 1 : 0,
                backgroundColor: Colors.FoldPixel.PRIMARY100,
              }}
            >
              <MapSortedList 
              sourceHandle={sourceHandle} 
              onPress={onPress} 
              showModalData={showModalData} 
              setShowModalData={setShowModalData} 
              sortedList={sortedList} 
              category={category}  
              doesNodeMatchSearch={doesNodeMatchSearch} 
              nodeByCategory={nodeByCategory}  
              nodeMasterDataMap={nodeMasterDataMap}
              />
            </View>
          </VStack>
        );
      }
      return <></>;
    };


    const GetActionNodes = () => {

      return (
        <ScrollView style={{ maxHeight: window.innerHeight * 0.6 }}>
          <VStack width="full">
            {/* {actionNodes.map((category, index) => RenderCategory(category, index))} */}
            {actionNodes.map((category, index) => {
              const items = getSortedList(category)
              if(!items.length ){
                return <></>
              }
              return (
                <RenderCategory
                  category={category}
                  index={index}
                  key={category}
                  sortedList={items}
                />
              )
            })}
          </VStack>
        </ScrollView>
      );
    };


    const tabColumns = []

    if(actionNodes.length){
      tabColumns.push(
        {
          key: '1',
          label: 'Actions',
          children: <GetActionNodes />,
        }
      )
    }
    
    if(hasMatchingNodes){
      tabColumns.push(
        {
          key: '2',
          label : 'Conditions',
          children: <GetConditionNodes />
        })
    }

    return (

      <Drawer
        headerStyle={{ borderWidth: 0, margin: 0 , padding:0}}
        destroyOnClose
        placement="right"
        onClose={() => setShowModalData({ ...showModalData, showModal: false })}
        open={showModalData.showModal && showModalData.source === sourceHandle}
        closable
        width={'25%'}
      >
        <VStack space={'20px'} height={window.innerHeight - 250}>
          <VStack space={2}>

            <View paddingX={'6px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Text style={[
                workflowTriggerStyle.workflowSubtitle, {letterSpacing:1.2},]
              }>
                {'Select Next Step'}
              </Text>
              {<Pressable
                onPress={() => {
                  setShowModalData({ ...showModalData, showModal: false });
                }}
              >
                <View justifyContent={'center'} height={'36px'} >
                  <Feather name='x' size={24} color = {Colors.FoldPixel.GRAY300}/>
                </View>
              </Pressable>}
            </View>
            <View
              flex={10}
              flexDirection={'row'}
              gap={'16px'}
              borderRadius={'8px'}
              style={{
                borderColor: Colors.FoldPixel.PRIMARY300,
                borderWidth: 1,
                backgroundColor: Colors.FoldPixel.PRIMARY100,
                width: '100%',
                padding: 16,
                // height: '42px',
              }}
            >

              <View
                flex={0.5}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {runFoldAutomationSvg(24, 24)}
              </View>

              <View
                paddingY={'8px'}
                alignSelf={'center'}
                flex={9}
              >
                <Text
                  fontSize={'14px'}
                  color={'#000000'}
                >
                  {parentDisplayName}
                </Text>

              </View>
            </View>
          </VStack>

          <HStack>
            <GetOperationNodes />
          </HStack>

          <div className='workflow-antd-input'>
            <View>
              <Input
                placeholder='Search Condition or Action'
                width={'100%'}
                className="custom-antd-input-focus"
                value={searchText}
                prefix={<SearchOutlined style={{color : Colors.FoldPixel.GRAY200}}/>}
                allowClear={true}
                onChange={(text) => { setSearchText(text.target.value) }}
                style={{ height: '36px' , borderRadius:4}}
              ></Input>
            </View>
          </div>

          <div className='workflow-tab-nav'>
          <View>
            <Tabs
              centered={false}
              type='card'
              tabPosition='top'
              defaultActiveKey='1'
              items={tabColumns}
              // tabBarStyle={{justifyContent:'flex-start', alignItems:'flex-start', borderBottomWidth:0}}
            />
          </View>
          </div>
        </VStack>
      </Drawer>
    );
  };

  const MapSortedList = (props:any) => {
    const {
      sourceHandle,
      onPress,
      showModalData,
      setShowModalData,
      sortedList,
      category,
      doesNodeMatchSearch,
      nodeByCategory, 
      nodeMasterDataMap
    } = props;
    
    const [renderedNodes, setRenderedNodes] = useState(<></>)
    
    const calculateNewValue = () => {
      const renderedNodes:any = (sortedList||[]).map((subList: string[], subIndex: number) => (
        <HStack flex={1} key={subIndex}>
          {subList
            .filter(outputNodeType => {
              return doesNodeMatchSearch(outputNodeType)
            })
            .map((outputNodeType, itemIndex) => (
              <View
                flex={1}
                style={{
                  borderColor: Colors.FoldPixel.GRAY100,
                  borderBottomWidth: subIndex === (nodeByCategory[category]?.list?.length - 1) ? 0 : 1,
                }}
                key={outputNodeType + category + itemIndex + '_subIndex_' + subIndex}
              >
                <NewLibNodeCard
                  isNodeEnabled={true}
                  title={(nodeMasterDataMap?.[outputNodeType])?.displayName || outputNodeType}
                  iconInfo={(nodeMasterDataMap?.[outputNodeType])?.iconInfo}
                  nodeCategory={(nodeMasterDataMap?.[outputNodeType])?.category?.code || NodeCategory.moment}
                  isActionNode={true}
                  onClick={() => {
                    setShowModalData({
                      ...(showModalData),
                      showModal: false,
                      source: '',
                    });
                    onPress(
                      {
                        sourceHandle: sourceHandle,
                        node: outputNodeType,
                        metaData: {},
                      },
                      showModalData.clientX,
                      showModalData.clientY,
                      true
                    );
                  }}
                />
              </View>
            ))}
        </HStack>
      ))
      setRenderedNodes(renderedNodes)
    }
    
    useEffect(() => {
      calculateNewValue()
    }, [sortedList])
    
    return <>{renderedNodes}</>
  }

export default NewOutputModel
