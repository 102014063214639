import React from 'react';
import Stack from '../../../../../common/LayoutComponents/Stack';
import AlphabetAvatar from '../../../../../common/Avatar/AlphabetAvatar';
import {View} from 'react-native';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants/StringConst';
import {Text} from 'native-base';
import {Colors} from '../../../../../../styles/Colors';
import {ICustomPatientBanner} from './interface';
import { TestIdentifiers, testID } from '../../../../../../testUtils';

const CustomPatientBanner = (props: ICustomPatientBanner) => {
  return (
    <Stack direction="row" style={{alignItems: 'center'}}>
      <View>
        <AlphabetAvatar
          size={'6'}
          style={{
            alignItems: 'center',
            borderRadius: 6,
            borderWidth: 1,
            borderColor: Colors.Custom.PrimaryColor200,
          }}
          disableTop
          name={props?.contactData?.name}
          textStyle={{fontSize: 12, textAlign: 'center', padding: 1}}
          userType={GROUP_MEMBER_TYPE.CONTACT}
        />
      </View>
      <View style={{paddingHorizontal: 8}}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 14,
            color: props?.isMessagePositionLeft
              ? Colors.Custom.Gray500
              : Colors.Custom.White,
          }}
          {...testID(TestIdentifiers.name)}
        >
          {props?.contactData?.name}
        </Text>
      </View>
    </Stack>
  );
};

export default CustomPatientBanner;
