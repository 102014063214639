import {IAllowedOperation} from '../../../../../Interfaces';
import {PERSON_TYPES} from '../../../../../constants';
import {isPatientDetailViewAllowed} from '../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {
  OMNIVIEW_ACTIONS_BY_PERSON_TYPE,
  PERSON_ACTION_CODES,
  PERSON_ACTION_LIST,
} from './ActionConst';
import {IPersonAction} from './interfaces';

export const getActionObjListByPersonType = (
  personType: any,
  patientOrgAbilites?: IAllowedOperation,
  skipActions?: string[],
  isSendProviderRequestFormAllowed?: boolean
): IPersonAction[] => {
  let personActionCodeList: any;
  if (OMNIVIEW_ACTIONS_BY_PERSON_TYPE[personType]) {
    personActionCodeList = OMNIVIEW_ACTIONS_BY_PERSON_TYPE[personType];
  } else {
    personActionCodeList = OMNIVIEW_ACTIONS_BY_PERSON_TYPE[PERSON_TYPES.LEAD];
  }
  let actionObjList = PERSON_ACTION_LIST.filter((actionObj: IPersonAction) => {
    return personActionCodeList?.includes?.(actionObj.actionCode);
  });
  if (!(patientOrgAbilites?.canUpdate ?? true)) {
    return actionObjList.filter(
      (action) =>
        action.actionCode !== PERSON_ACTION_CODES.EDIT &&
        action.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  }
  if (!isPatientDetailViewAllowed() && personType == PERSON_TYPES.CUSTOMER) {
    actionObjList = actionObjList?.filter(
      (value) => value.actionCode != PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE
    );
  }

  if (isSendProviderRequestFormAllowed) {
    const tpaSendFormAction = PERSON_ACTION_LIST.find(
      (quickActionType) =>
        quickActionType.actionCode ===
        PERSON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM
    );
    if (tpaSendFormAction) {
      actionObjList.push(tpaSendFormAction);
    }
  }

  if (skipActions?.length) {
    actionObjList = actionObjList?.filter(
      (value) => !skipActions.includes(value.actionCode)
    );
  }

  return actionObjList || [];
};
