import { Text } from 'react-native'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormContext, IFormCommonData, IFormComponentProps } from '../CustomWrapper/CustomWrapper'
import { IFormValidationOutput } from '../CustomWrapper/interfaces'
import { CommonDataContext } from '../../../../../../context/CommonDataContext'
import CustomTextAreaField from './CustomTextAreaField'
import RenderHTML from 'react-native-render-html';

const TextAreaComponent = (props: IFormComponentProps) => {
  const { component, disabled, options } = props;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isRTE = component.editor === 'quill';
  const [componentState, setComponentState] = useState({ value: props.component?.selectedValue, showErrors: false});
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isDoNotAllowToReplaceNextLine = options?.isDoNotAllowToReplaceNextLine || false;

  const setValue = useCallback((value: any) => {
    if (value) {
      setComponentState((prev) => {
        return {
          ...prev,
          value,
        };
      });
    }
  }, []);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(componentState.value);
  }, [componentState.value]);

  if (isPreviewMode) {
    const displayValue = componentState.value ?? '-';
    if (isRTE) {
      return (
        <RenderHTML
          source={{
            html: displayValue,
          }}
          enableExperimentalMarginCollapsing={true}
          enableExperimentalBRCollapsing={true}
          baseStyle={{
            whiteSpace: 'pre',
            overflow: 'visible',
            width: '100%',
          }}
        />
      );
    }
    if (isDoNotAllowToReplaceNextLine) {
      return (
        <Text style={{fontSize: 16, fontWeight: '400'}}>{displayValue}</Text>
      );
    }
    const linBreakRegex = /<br\s*[\/]?>/gi;
    const parsedValue =
      typeof displayValue === 'string'
        ? displayValue.replaceAll(linBreakRegex, '\n')
        : displayValue;
    return (
      <Text style={{fontSize: 16, fontWeight: '400'}}>{parsedValue}</Text>
    );
  }

  return (
    <CustomTextAreaField
      value={componentState.value}
      macroTemplatesEnabled={component.macroTemplatesEnabled && !isPatientForm}
      isRTE={isRTE}
      disabled={disabled}
      numberOfLines={component.rows}
      placeholder={component.placeholder}
      showError={componentState.showErrors && !componentState.value}
      onValueChange={(text) => {
        setComponentState((prev) => {
          return {
            ...prev,
            value: text,
          };
        });
      }}
    />
  )
}

export default TextAreaComponent
