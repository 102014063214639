import {Tooltip, notification} from 'antd';
import moment from 'moment';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Box, HStack, useToast, View, VStack, Icon, useMediaQuery, Text} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {Pressable} from 'react-native';
import {useNavigate} from 'react-router-dom';
import EditSvg from '../../../../../assets/Icons/EditSvg';
import {DATE_FORMATS, getInActiveContactError, GROUP_MEMBER_TYPE, PERSON_TYPES, SMALL_WINDOW_1400, SMALL_WINDOW_1500} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {GROUP_TYPE_CODES} from '../../../../../constants/MlovConst';
import {getEhrConfig, getPrimaryGenderLabel} from '../../../../../utils/capabilityUtils';
import {
  getGenderTextByGenderCode,
  getPrimaryGenderCode,
  getPrimaryGenderValue,
  getSecondaryValue,
  isActiveContact
} from '../../../../../utils/commonUtils';
import {getAgeValue, getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {PersonActions} from '../../../../common/PersonActions/PersonActions';
import {PatientInfoBanner} from '../../../../PatientInfoBanner/PatientInfoBanner';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {AddOrUpdateLead} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {IContact, IContactType} from '../interfaces';
import {isGroupConversation} from '../MessagingWindow/MessagingUtils';
import { Colors } from '../../../../../styles/Colors';
import { capitalizeText } from '../../../../common/ContactRelationView/ContactRelationUtils';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { getDeceasedDate } from '../../../../../services/CommonService/AidBoxService';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { getCurrentPatientDeceasedData} from '../../../../../services/CommonService/AidBoxService';

import PatientHIEStatusView from '../../../../common/PatientHIEStatus/PatientHIEStatusView';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
// import {getAvatarFullName} from '../ConversationContainer/ConversationList/ConversationListItemUtils';

const ProfileDetailView = (props: any) => {
  const [stateData, setStateData] = useState({
    showEditDrawer: false as boolean,
    visible: false,
    isDeceased: false,
    deceasedDate: ''
  });
  const toast = useToast();
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    deceasedDate,
    handleEmail,
    handleSMS,
    handleCall,
    contactData,
    conversationData,
    onActionPerformed,
    contactType,
    hideVideoCallingButton,
    handleActions,
    shouldShowPersonActions,
    showEditProfileOnPatientInfoBanner,
    showDetailProfile,
    parentCode,
    showCloseDrawerIcon,
  } = props;
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1500 }
  ]);
  const isSmallScreen = isSmallWindow && !isSideCarContext;

  const genderCode = getPrimaryGenderCode(contactData);
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE);
  useEffect(() => {
    ehrConfig.isAthena &&
    getPatientDeceasedData(contactData)
  }, [contactData?.patient?.patientId]);

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    switch (actionCode) {
      case PERSON_ACTION_CODES.CANCEL:
        setStateData({...stateData, showEditDrawer: false});
        break;
      default:
        setStateData({...stateData, showEditDrawer: false});
        break;
    }
  };

  const getContactTypeValue = (contactType: IContactType): string => {
    if (contactType?.code) {
      if (contactType?.code == 'CUSTOMER') {
        return 'Patient';
      } else if (contactType?.code == PERSON_TYPES.LEAD) {
        return 'Prospect';
      } else if (contactType?.value) {
        return capitalizeText(contactType?.value);
      }
    } else if (contactType?.value) {
      return capitalizeText(contactType?.value);
    }
    return '';
  };

  const getPatientOrProspectElem = (): JSX.Element => {
    const contactTypeStr = getContactTypeValue(contactType);
    const genderValue = getGenderTextByGenderCode(contactData);
    const genderLabel = getPrimaryGenderLabel();
    const ageValue = getAgeValue(contactData?.person?.birthDate,stateData.deceasedDate);

    const getAgeAndGenderText = (): JSX.Element => {
      return (
        <HStack>
          <Text>
            {`${contactTypeStr}${
              contactData?.person?.birthDate &&
              getAgeValue(contactData?.person?.birthDate,stateData.deceasedDate)
                ? ' | '
                : ''
            }`}
          </Text>
          <Tooltip
            title={ageValue}
            placement="top"
            color={Colors.Custom.Gray700}
          >
            <Text>
              {contactData?.person?.birthDate
                ? getDateStrFromFormat(
                    contactData?.person?.birthDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  )
                : ''}
            </Text>
          </Tooltip>
          <Text>{genderValue ? ` | ${genderValue}` : ''}</Text>
        </HStack>
      );
    };
    return (
      <HStack>
        {contactData?.person?.birthDate ||
        genderValue ||
        contactTypeStr
          ? getAgeAndGenderText()
          : undefined}
      </HStack>
    );
  }


  const contactActiveStatus = isActiveContact(contactData)
  const isShowEmailIcon = () => {
    let showEmail = true;
    if (
      conversationData &&
      isGroupConversation(conversationData) &&
      conversationData?.groupConversation?.groupType?.code !==
      GROUP_TYPE_CODES.PRIVATE
    ) {
      showEmail = false;
    }
    return showEmail;
  };

  const isShowCallIcon = () => {
    let showCall = true;
    if (!contactData.phoneNumber) {
      showCall = false;
    }
    return showCall;
  };

  const isShowSmsIcon = () => {
    let showSms = false;
    if (contactData.phoneNumber) {
      showSms = true;
    }
    return showSms;
  };
  const ehrConfig = getEhrConfig();

  const getPatientDeceasedData = async (contactData: IContact) => {
    setStateData((prev)=> {
      return{
        ...prev,
        deceasedDate: ''
      }
    });
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData);
    setStateData((prev)=> {
      return{
        ...prev,
        deceasedDate: deceasedDatePatient
      }
    });
  };

  return (
    <VStack style={{width: '100%'}}>
      <HStack style={{width: '100%'}} justifyContent={'space-between'}>
        <View flex={1}>
          <PatientInfoBanner
            contactType={contactType}
            isLetterAvatarShow={true}
            name={contactData.name}
            hintExtraDetail={props.hintExtraDetail}
            isBigBannerNameTruncate={props.isBigBannerNameTruncate}
            contactData={contactData}
            contactActiveStatus={contactData?.isActive}
            bannerType="bigBanner"
            bannerDirection={props.bannerDirection}
            isHorizontal={props?.onCallScreen ? true : !isSmallScreen }
            isVerticalWithBigBanner={props?.onCallScreen ? false : isSmallScreen }
            isSelected={false}
            headerString={contactData.name || ''}
            groupType={
              conversationData?.groupConversation?.groupType?.code as any
            }
            loggedInTime={contactData?.additionalAttributes?.loggedInTime}
            genderCode={genderCode as any}
            userId={contactData?.id}
            userType={
              contactType?.code == 'CUSTOMER'
                ? GROUP_MEMBER_TYPE.PATIENT
                : GROUP_MEMBER_TYPE.CONTACT
            }
            deceasedDate ={stateData.deceasedDate}
            deceasedDateOrientation={!!conversationData && Object.keys(conversationData).length ? 'column' : 'row'}
            subtitleElement={getPatientOrProspectElem()}
            shouldShowSubtitleHighlighter={false}
            showDetailProfile={showDetailProfile}
            showEditProfile={showEditProfileOnPatientInfoBanner}
            onCallScreen={props?.onCallScreen}
            onShowDetails={() => {
              const currentStatus = isActiveContact(contactData)
              if (!currentStatus) {
                const message = getInActiveContactError(contactData)
                notification.error({
                  message,
                });
                return
              }
              if (contactType?.code == 'CUSTOMER') {
                props?.navigateOrOpenContactIdDrawer?.(contactData.id,{
                  state: {contactData: contactData},
                } );
              } else {
                navigate(`/members/prospect/${contactData.id}`, {
                  state: {contactData: contactData},
                });
              }
              if (props?.onLockScreen) {
                showToast(
                  toast,
                  intl.formatMessage({id: 'loginToTakeActionOnCall'}),
                  ToastType.error
                );
              }
            }
            }
            onEditDetails={() => {
              onActionPerformed(COMMON_ACTION_CODES.EDIT, contactData);
              if (props?.onLockScreen) {
                showToast(
                  toast,
                  intl.formatMessage({id: 'loginToTakeActionOnCall'}),
                  ToastType.error
                );
              }
            }}
            onPatientProfileIconClick={() => {
              const currentStatus = isActiveContact(contactData)
              if (!currentStatus) {
                const message = getInActiveContactError(contactData)
                notification.error({
                  message,
                });
                return
              }
              if (contactType?.code == PERSON_TYPES.CUSTOMER || contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
                props?.openContactInWindowOrOpenContactIdDrawer?.(contactData.id);
              } else {
                window.open(
                  `/#/members/prospect/${contactData.id}`,
                  '_blank'
                );
              }
            }}
            patientHieSyncCallView={
              props?.patientHieSyncCall && isHIEEnabled ? (
                <PatientHIEStatusView
                  patientData={contactData?.patient}
                ></PatientHIEStatusView>
              ) : (
                <></>
              )
            }
          />
        </View>
        {showCloseDrawerIcon && (
          <HStack
            flex={0.1}
            style={{position: 'absolute', right: 0, top: 0}}
            space={2}
            mr={-5}
          >
            <View style={{width: 36, height: 36}}>
              <Tooltip title="Close">
                <Pressable
                  onPress={() => {
                    if (props?.onActionPerformed) {
                      props?.onActionPerformed(
                        CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
                        {
                          isDetailsContainerVisible: false,
                        }
                      );
                    }
                  }}
                >
                  <Icon
                    m="2"
                    size={4}
                    color={Colors.Custom.Gray500}
                    as={<AntIcon name="close" />}
                  />
                </Pressable>
              </Tooltip>
            </View>
          </HStack>
        )}
      </HStack>
      {shouldShowPersonActions ? (
        <HStack
          justifyContent={'flex-start'}
          alignItems={'center'}
          space="3"
          marginTop={2}
        >
          <PersonActions
            shouldShowEmail={isShowEmailIcon() && !hideVideoCallingButton}
            showPhoneCall={!hideVideoCallingButton}
            showVideoCall={isShowEmailIcon() && !hideVideoCallingButton}
            showAppointment={!hideVideoCallingButton}
            showNotes={true}
            showSms={!hideVideoCallingButton}
            contactInfo={contactData}
            email={contactData?.email || getSecondaryValue(contactData?.personContact, 'email') || ''}
            phoneNumber={contactData?.phoneNumber || getSecondaryValue(contactData?.personContact, 'phone') || ''}
            parentCode={parentCode}
            hideVideoCallingButton={hideVideoCallingButton}
          />
        </HStack>
      ) : null}
      {stateData.showEditDrawer && (
        <AddOrUpdateLead
          singleLeadData={contactData}
          onFormCompleteAction={(actionCode: string) => {
            onViewChangeActionPerformed(actionCode);
          }}
          personType={contactType?.code}
          personTypeUuid={contactType?.id}
        />
      )}
    </VStack>
  );
};

export default withMiniContactViewHOC(ProfileDetailView);
