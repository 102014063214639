export const GET_NOTIFICATION_COUNT = `
  query GET_NOTIFICATION_COUNT(
    $userId: uuid!
    $userType: String
    $tenantId: uuid!
  ) {
    aggregateNotification(
      where: {
        userType: {_eq: $userType}
        userId: {_eq: $userId}
        isDeleted: {_eq: false}
        isRead: {_eq: false}
        tenantId: {_eq: $tenantId}
      }
      distinct_on: [type, typeId]
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_NOTIFICATIONS = `
query GET_NOTIFICATIONS($userId: uuid!, $userType: String, $tenantId: uuid!) {
  notifications(
    where: {
      userType: {_eq: $userType}
      isDeleted: {_eq: false}
      isRead: {_eq: false}
      userId: {_eq: $userId}
      tenantId: {_eq: $tenantId}
    }
    distinct_on: [type, typeId]
    order_by: {createdOn: desc, type: asc, typeId: asc}
  ) {
    id
    title
    description
    userId
    userType
    type
    typeId
    createdOn
    metadata
    eventCode
  }
}
`;

export const UPDATE_SINGLE_NOTIFICATION_READ_STATUS = `
  mutation updateSingleNotificationReadStatus($type: String!, $typeId: String!,$userId:uuid!, $isRead: Boolean) {
    updateNotifications(where: {type: {_eq: $type}, typeId: {_eq: $typeId},isRead:{_eq:false},userId:{_eq:$userId}}, _set: {isRead: $isRead}) {
      affected_rows
    }
  }
`;

export const UPDATE_ALL_NOTIFICATION_READ_STATUS = `
  mutation updateAllNotificationReadStatus(
    $userId: uuid!
    $isRead: Boolean
    $userType: String
  ) {
    updateNotifications(
      where: {userId: {_eq: $userId}, userType: {_eq: $userType}}
      _set: {isRead: $isRead}
    ) {
      affected_rows
    }
  }
`;

export const GET_WORKFLOW_LIST = `
query searchWorkflow($boolExp: workflow_bool_exp) {
  workflows(order_by: [{isEnabled: desc}, {createdOn: desc}], where: $boolExp) {
    id
    isDraftOnly
    draftId
    draft{
      metadata
    }
    createdOn
    name
    flowTypeMap {
      flowType
    }
    graph {
      edges {
        source
        target
      }
      nodes(where: {isDeleted: {_eq: false}}) {
        type
        id
        data
      }
    }
    description
    isEnabled
    tagList
    workflowMasterId
    asyncNodeList
    isSendSurveyForm
    id
    tenantId
    eventType
    entity
    flowType
    isDeleted
    triggerInfo {
      id
      date
      day
      timezone
      frequency
      time
      triggerCondition
      triggerType
    }
  }
}

`;


export const GET_WORKFLOW_LIST_ONLY = `
query searchWorkflow($boolExp: workflow_bool_exp) {
  workflows(order_by: [{isEnabled: desc}, {createdOn: desc}], where: $boolExp) {
    id
    isDraftOnly
    draftId
    draft{
      metadata
    }
    createdOn
    name
    description
    isEnabled
    tagList
    flowTypeMap {
      flowType
    }
    workflowMasterId
    asyncNodeList
    isSendSurveyForm
    id
    tenantId
    eventType
    entity
    flowType
    isDeleted
    triggerInfo {
      id
      date
      day
      timezone
      frequency
      time
      triggerCondition
      triggerType
    }
  }
}

`;

export const GET_WORKFLOW_COUNT_BY_NAME_AND_FLOWTYPE = `
query getWorkflowCountByNameAndFlowType($name: String!, $tenantId: uuid!) {
  aggregateWorkflow (where: {
    name: {_ilike: $name}
    isDeleted: {_eq: false}
    tenantId:{_eq: $tenantId}
  }) {
    aggregate{
      count
    }
  }
}
`;

export const GET_WORKFLOW_LIST_WITH_PAGE = `
query searchWorkflow($boolExp: workflow_bool_exp, $offset:Int, $limit:Int) {
  aggregateWorkflow(where:$boolExp){
    aggregate {
      count
    }
  }
  workflows(offset:$offset, limit:$limit,order_by: [{isEnabled: desc}, {createdOn: desc}], where: $boolExp) {
    id
    isDraftOnly
    draftId
    draft{
      metadata
    }
    createdOn
    name
    userId
    graph {
      edges {
        source
        target
      }
      nodes(where: {isDeleted: {_eq: false}}) {
        type
        id
        data
      }
    }
    description
    isEnabled
    tagList
    workflowMasterId
    asyncNodeList
    isSendSurveyForm
    id
    tenantId
    eventType
    entity
    flowType
    isDeleted
    triggerInfo {
      id
      date
      timezone
      day
      frequency
      time
      triggerCondition
      triggerType
    }
  }
}

`;

export const GET_WORKFLOW_LIST_ONLY_WITH_PAGE = `
query searchWorkflow($boolExp: workflow_bool_exp, $offset:Int, $limit:Int) {
  aggregateWorkflow(where:$boolExp){
    aggregate {
      count
    }
  }
  workflows(offset:$offset, limit:$limit,order_by: [{isEnabled: desc}, {createdOn: desc}], where: $boolExp) {
    id
    isDraftOnly
    draftId
    draft{
      metadata
    }
    createdOn
    name
    description
    userId
    isEnabled
    tagList
    workflowMasterId
    asyncNodeList
    isSendSurveyForm
    id
    tenantId
    eventType
    entity
    flowType
    isDeleted
    triggerInfo {
      id
      date
      timezone
      day
      frequency
      time
      triggerCondition
      triggerType
    }
  }
}

`;



export const GET_WORKFLOW_BY_ID = `
  query getWorkflowById($id: uuid!, $tenantId: uuid!) {
    workflows(
      where: {
        id: {_eq: $id}
        tenantId: {_eq: $tenantId}
      }
    ) {
      id
      isDraftOnly
      draftId
      draft{
        metadata
      }
      createdOn
      name
      description
      isEnabled
      tagList
      secondaryMasterId
      workflowMasterId
      asyncNodeList
      isSendSurveyForm
      id
      tenantId
      workflowArnList
      resourceExecutionId
      output
      eventType
      entity
      graphId
      userId
      surveyForm
      flowType
      isDeleted
      triggerInfo {
        id
        date
        day
        frequency
        time
        timezone
        triggerCondition
        triggerType
      }
      graph {
        createdOn
        id
        nodes {
          id
          title
          position
          data
          type
          positionAbsolute
          handleBounds
          height
          width
          z
        }
        edges {
          id
          sourceHandle
          targetHandle
          source
          target
          type
        }
        userId
        tenantId
      }
    }
  }
`;

export const GET_WORKFLOW_BY_MASTER_ID = `
  query getWorkflowByMasterId($workflowMasterId: uuid!, $tenantId: uuid!) {
    workflows(where: {workflowMasterId: {_eq: $workflowMasterId}, tenantId: {_eq: $tenantId}, isDeleted: {_eq: false}}) {
      id
      isDraftOnly
      flowTypeMap {
        flowType
      }
      draftId
      draft {
        metadata
      }
      createdOn
      name
      description
      isEnabled
      tagList
      secondaryMasterId
      workflowMasterId
      asyncNodeList
      isSendSurveyForm
      id
      tenantId
      workflowArnList
      resourceExecutionId
      output
      eventType
      entity
      graphId
      userId
      surveyForm
      flowType
      isDeleted
      triggerInfo {
        id
        date
        day
        frequency
        timezone
        time
        triggerCondition
        triggerType
      }
      graph {
        createdOn
        id
        nodes {
          id
          title
          position
          data
          type
          positionAbsolute
          handleBounds
          height
          width
          z
        }
        edges {
          id
          sourceHandle
          targetHandle
          source
          target
          type
        }
        userId
        tenantId
      }
    }
  }

`;

export const GET_WORKFLOW_INFO_BY_MASTER_ID = `
  query getWorkflowByMasterId($workflowMasterId: uuid!, $tenantId: uuid!) {
    workflows(
      where: {
        workflowMasterId: {_eq: $workflowMasterId}
        tenantId: {_eq: $tenantId}
        isDeleted: {_eq: false}
      }
    ) {
      id
      isDraftOnly
      draftId
      draft{
        metadata
      }
      createdOn
      name
      description
      workflowMasterId
      eventType
      entity
      triggerInfo {
        id
        date
        day
        frequency
        timezone
        time
        triggerCondition
        triggerType
      }
    }
  }
`;

// query getWorkflowExecutionLog($workflowMasterId: uuid!) {
//   workflowExecutionStat(workflowMasterId: $workflowMasterId) {
//     workflowMasterId
//     totalCount
//     completed
//     incomplete
//     failed
//     tenantId
//   }
// }

const GET_WORKFLOW_EXECUTION_DATA_BY_WORKFLOW_MASTER_ID = `
  query getStatByWorkflowMasterId($workflowMasterId:uuid!, $masterId:String){
    completed: aggregateWorkflowExecutionLog(where:{workflowMasterId:{_eq:$workflowMasterId},workflowState:{_eq:"WORKFLOW_COMPLETE"}}) {
      aggregate {
        count
      }
    }
    incomplete: aggregateWorkflowExecutionLog(where:{workflowMasterId:{_eq:$workflowMasterId},workflowState:{_nin:["WORKFLOW_ERROR","WORKFLOW_COMPLETE"] }}) {
      aggregate {
        count
      }
    }
    failed: aggregateWorkflowExecutionLog( where:{workflowMasterId:{_eq:$workflowMasterId},workflowState:{_eq:"WORKFLOW_ERROR" }}) {
      aggregate {
        count
      }
    },
    total: aggregateWorkflowExecutionLog( where:{workflowMasterId:{_eq:$workflowMasterId}}) {
      aggregate {
        count
      }
    }
    queued: aggregateStepFunctionRequestQueue(where:{executionStatus:{_eq:"REQUESTED"},masterId:{_eq:$masterId}}){
      aggregate {
        count
      }
    }
  }
`;
// const getWorkFlowState = (w)=> {
//   if(w === 'WORKFLOW_INCOMPLETE'){
//     return 'workflowState: _nin:["WORKFLOW_ERROR","WORKFLOW_COMPLETE"]';
//   } else {
//     return "workflowState: {_eq: $workflowState}"
//   }
// }

const GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID = `
  query getWorkflowExecutionLogsData($workflowMasterId: uuid!, $offset:Int, $limit:Int, $workflowState: String){
    workflowExecutionLogs(order_by:{createdOn:desc},limit:$limit,offset:$offset,where:{workflowState: {_eq: $workflowState},workflowMasterId:{_eq:$workflowMasterId}}) {
      workflowMasterId
      workflowExecutionId
      workflowState
      createdOn
      workflow {
        flowType
      }
      resourceLevelLog(distinct_on:[resourceId,resourceType]){
        resourceType
        resourceId
        createdOn
      }
      serviceLog(where:{workflowMasterId:{_eq:$workflowMasterId}}){
         id
        logType
        status
        emailAnalyticTag {
          logs {
            body
            eventCode
          }
        }
        metadata
        createdOn
      }
      smsLog: serviceLog_aggregate(where: {logType: {_eq: "SMS_SEND"},  workflowMasterId:{_eq:$workflowMasterId}}) {
        aggregate {
          count
        }
      }
       emailLog: serviceLog_aggregate(where: {logType: {_eq: "EMAIL_SEND"},  workflowMasterId:{_eq:$workflowMasterId}}) {
        aggregate {
          count
        }
      }
       notificationLog: serviceLog_aggregate(where: {logType: {_eq: "PUSH_NOTIFICATION_SEND"},  workflowMasterId:{_eq:$workflowMasterId}}) {
        aggregate {
          count
        }
      }
    }
  }

`;


const GET_INCOMPLETE_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID = `
  query getWorkflowExecutionLogsData($workflowMasterId: uuid!, $offset:Int, $limit:Int){
    workflowExecutionLogs(order_by:{createdOn:desc},limit:$limit,offset:$offset,where:{workflowState: {_nin:["WORKFLOW_ERROR","WORKFLOW_COMPLETE"]},workflowMasterId:{_eq:$workflowMasterId}}) {
      workflowMasterId
      workflowExecutionId
      workflowState
      createdOn
      workflow {
        flowType
      }
      resourceLevelLog(distinct_on:[resourceId,resourceType]){
        resourceType
        resourceId
      }
      serviceLog {
        id
        logType
        emailAnalyticTag {
          logs {
            body
            eventCode
          }
        }
        status
        metadata
        createdOn
      }
      smsLog: serviceLog_aggregate(where: {logType: {_eq: "SMS_SEND"}, createdOn: {}}) {
        aggregate {
          count
        }
      }
       emailLog: serviceLog_aggregate(where: {logType: {_eq: "EMAIL_SEND"}, createdOn: {}}) {
        aggregate {
          count
        }
      }
       notificationLog: serviceLog_aggregate(where: {logType: {_eq: "PUSH_NOTIFICATION_SEND"}, createdOn: {}}) {
        aggregate {
          count
        }
      }
    }
  }

`;

const GET_CAMPAIGN_BY_ID = `
query getEmailCommunicationLog($status:String!,$serviceLogBoolExp: serviceLog_bool_exp!, $limit: Int, $offset: Int) {

  completed:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"COMPLETE"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }
  failed:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"ERROR"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }
  inProcess:aggregateServiceLog( where: {_and:[$serviceLogBoolExp,{status:{_eq:"IN_PROGRESS"}}]}, order_by: [{createdOn: desc}]) {
    aggregate {
      count
    }
  }

  serviceLogs(limit: $limit, offset: $offset, where:{_and:[$serviceLogBoolExp,{status:{_eq:$status}}]}, order_by: [{createdOn: desc}]) {
    id
    workflowExecutionId
    createdOn
    logType
    status
    workflowMasterId
    metadata
    emailAnalyticTag {
      logs {
        eventCode
      }
    }
    resourceLevelLog(where:{resourceType:{_in:["CONTACT", "USER"]}}) {
      resourceType
      resourceId
    }
  }
  aggregateServiceLog(order_by: [{createdOn: desc}], where: $serviceLogBoolExp) {
    aggregate {
      count
    }
  }
}
`

export const GET_ENABLED_WORKFLOW_LIST = `
  query searchWorkflow($boolExp: workflow_bool_exp) {
    workflows(order_by: [{isEnabled: desc}, {createdOn: desc}], where: $boolExp) {
      id
      name
      description
      isEnabled
      eventType
    }
  }
`;


export default {
  GET_NOTIFICATIONS,
  GET_WORKFLOW_LIST,

  GET_WORKFLOW_LIST_ONLY,
  GET_WORKFLOW_LIST_ONLY_WITH_PAGE,
  GET_WORKFLOW_LIST_WITH_PAGE,
  GET_WORKFLOW_BY_MASTER_ID,
  GET_WORKFLOW_BY_ID,
  GET_NOTIFICATION_COUNT,
  UPDATE_SINGLE_NOTIFICATION_READ_STATUS,
  UPDATE_ALL_NOTIFICATION_READ_STATUS,
  GET_WORKFLOW_EXECUTION_DATA_BY_WORKFLOW_MASTER_ID,
  GET_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID,
  GET_INCOMPLETE_WORKFLOW_EXECUTION_LOGS_BY_WORKFLOW_MASTER_ID,
  GET_WORKFLOW_INFO_BY_MASTER_ID,
  GET_ENABLED_WORKFLOW_LIST,
  GET_WORKFLOW_COUNT_BY_NAME_AND_FLOWTYPE,

};
