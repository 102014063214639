
import { HStack, Skeleton, Text, View } from "native-base";
import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { useParams, useSearchParams } from "react-router-dom";
import { Colors } from "../../../../../styles";
import { getAccountUUID } from "../../../../../utils/commonUtils";
import CircleIconView from "../../../../common/CircleIconView/CircleIconView";
import { getEmailCommunicationLogData } from "../../Workflow/AddOrUpdateWorkflow/WorkflowApi";
import ShowNodeLevelLogInfo from "./ShowNodeLevelLogInfo";




function ShowLogCountByLogType(props: { setIsHide: any, uiNodeId: string, iconInfo: { lib: string, icon: string }, logTypeList: string[] }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pathParams = useParams();
  const workflowMasterId = pathParams.workflowId;
  const [count, setCount] = useState<any>([])
  const [workflowExecutionId] = useState<any>((searchParams.get('workflowExecutionId') || ''));
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const accountUUID = getAccountUUID();
  useEffect(() => {
    const contactId = searchParams.get('contactId');
    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');
    const emailStatus = searchParams.get('emailStatus');
    const smsStatus = searchParams.get('smsStatus');
    const variables: any = {smsStatus, emailStatus, fromDate, toDate,logTypeList: props.logTypeList, limit: 1, offset: 0, workflowMasterId, workflowExecutionId, uiNodeId: props.uiNodeId, contactId }
    Object.keys(variables).forEach((key) => {
      if (variables[key] === undefined) {
        delete variables[key]
      }
    })
    getEmailCommunicationLogData(variables).then((response: any) => {
      const count = response?.aggregateServiceLog?.aggregate?.count || 0;
      setCount(response?.aggregateServiceLog?.aggregate?.count)

      if (!count) {
        props.setIsHide(true);
        setLoading(false)
      } else {
        props.setIsHide(false);
        setLoading(false)
      }
    })
  }, [workflowExecutionId, accountUUID, workflowMasterId, searchParams.get('contactId'),searchParams.get('emailStatus'), searchParams.get('smsStatus'), searchParams.get('fromDate'), searchParams.get('toDate')])

  return <>{(loading || !count) ? <Skeleton.Text lines={1}></Skeleton.Text> :
    <View justifyContent='center' alignItems={'center'} flex={1}>
      <Pressable
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => {
          setIsVisible(true);
        }}
      >
        <HStack width={"100%"} justifyContent='center' alignItems={'center'} >
          <CircleIconView
            borderWidth='5px'
            size={'28px'}
            iconInfo={props.iconInfo}
            backgroundColor={
              Colors.FoldPixel.TRANSPARENT
            }
            borderColor={
              Colors.primary[300]
            }
            iconColor={
              'white'
            }
          />
          <Text fontSize={14} style={{  zIndex: 1000, color: 'white'}}>
            {count}
          </Text>
        </HStack>
      </Pressable>
      <ShowNodeLevelLogInfo logTypeList={props.logTypeList} uiNodeId={props.uiNodeId} isVisible={isVisible && count} setVisible={setIsVisible} total={count}></ShowNodeLevelLogInfo>
    </View>
  }</>

}




export default ShowLogCountByLogType;
