import React from "react";
import {View} from "native-base";
import {StrCaseConverter} from "../../../../../../../../utils/CaseConverter";
import AppointmentActivity from "./CommonComponent/AppointmentDetails/AppointmentActivity";
import {FEED_RESOURCE_TYPE} from "../FeedConst";
import {IFeedData} from "../interface";
import ActivityFormResponseDrawer from "./CommonComponent/FormResponse/ActivityFormResponseDrawer";
import {ISubmittedFormResponse} from "./CommonComponent/FormResponse/interface";
import TaskActivity from "./CommonComponent/TaskInfo/TaskActivity";
import ActivityConversationMessages from "../FeedCollapse/ExpandComponents/ActivityConversationMessages";

const ActivityAdditionalInfo = (props: {
  resourceType: string,
  resourceData: IFeedData,
  onActionPerformed: (actionCode: string, actionData?: any) => void;
}) => {
  const {
    resourceType,
    resourceData,
    onActionPerformed
  } = props;



  const getFormResponseElem = () => {
    let formResponseData = {} as any;
    if (resourceType === FEED_RESOURCE_TYPE.FORM_RESPONSE) {
      try {
        formResponseData = JSON.parse(resourceData.data)?.crmData?.resourceData?.newData;
        if (formResponseData && Object.keys(formResponseData).length) {
          Object.keys(formResponseData).forEach((key) => {
            const camelCaseKey = StrCaseConverter.toCamelCase(key);
            formResponseData[camelCaseKey] = formResponseData[key];
          });
        }
      } catch (error) {
        formResponseData = {} as ISubmittedFormResponse;
      }
    }
    return (<>
      {
        <ActivityFormResponseDrawer
          formId={formResponseData.formId}
          isDrawerVisible={resourceType === FEED_RESOURCE_TYPE.FORM_RESPONSE}
          resourceData={formResponseData}
          onActionPerformed={onActionPerformed}
        />
      }
    </>);
  }

  const getAppointmentDetailElem = () => {
    return (
      <AppointmentActivity
        singleFeedData={resourceData}
        onActionPerformed={onActionPerformed}
      />);
  }

  const getTaskDetailElem = () => {
    return (
      <TaskActivity
        singleFeedData={resourceData}
        onActionPerformed={onActionPerformed}
      />);
  }

  const getMessageElem = () => {
    return (
      <ActivityConversationMessages
        singleFeedData = {resourceData}
        onActionPerformed={onActionPerformed}
      />
    )
  }

  const getAdditionalInfoElem = () => {
    if (resourceData?.id) {
      switch (resourceType) {
        case FEED_RESOURCE_TYPE.FORM_RESPONSE: {
          return getFormResponseElem();
        }
        case FEED_RESOURCE_TYPE.APPOINTMENT: {
          return getAppointmentDetailElem();
        }
        case FEED_RESOURCE_TYPE.TASK: {
          return getTaskDetailElem();
        }
        case FEED_RESOURCE_TYPE.MESSAGE: {
          return getMessageElem();
        }
      }
    }
  }


  return (
    <View>
      {
        getAdditionalInfoElem()
      }
    </View>
  );
};

export default React.memo(ActivityAdditionalInfo);
