import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GET_BILLABLE_TIME_MINUTES, GET_CONTACT_CARE_PROGRAM_ID, GET_PREVIOUS_RUNNING_TIME } from "../../services/CareProgram/CareProgramQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../constants/Configs";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../utils/mlovUtils";
import { MLOV_CATEGORY } from "../../constants";
import { CommonDataContext } from "../../context/CommonDataContext";
import { BILLING_ACTIVITY_STATUS, CARE_PROGRAM_TYPES } from "../../constants/MlovConst";
import { ToastType, showToast } from "../../utils/commonViewUtils";
import { useToast } from "native-base";

export interface IUseTimeLogForBilling {
  contactId: string;
}

export interface ITimeLogForBillingState {
  billableTimeInMinutes: number;
  contactCareProgramId: string;
  previousRecordedtime?: {
    minutes: number;
    seconds: number;
  }
}

const UseTimeLogForBilling = (params: IUseTimeLogForBilling) => {
  const toast = useToast();
  const [timeLogForBillingState, setTimeLogForBillingState] = useState<ITimeLogForBillingState>({
    billableTimeInMinutes: 0,
    contactCareProgramId: '',
  })
  const mlovData = useContext(CommonDataContext);
  const billableActivityStatusMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS
  ) || [];
  const careProgramDraftStatusId = getMlovIdFromCode(
    billableActivityStatusMlov,
    BILLING_ACTIVITY_STATUS.DRAFT
  )
  const careProgramTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];
  const careProgramTaskTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CCM_BILLING_TASK_TYPE
  ) || [];
  const careProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.CCM);
  const [getContactCareProgramId, { loading: loadingContactCareProgramId }] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      variables: {
        params: {
          contactId: {
            _eq: params.contactId
          },
          payerCareProgram: {
            careProgramTypeId: {
              _eq: careProgramTypeId
            }
          }
        }
      },
      onCompleted: (data: any) => {
        if (!data?.contactCarePrograms?.[0]?.id) {
          return
        }
        setTimeLogForBillingState((prev) => {
          return {
            ...prev,
            contactCareProgramId: data?.contactCarePrograms?.[0]?.id
          }
        })
        getBillableTimeMinutes({
          variables: {
            params: {
              contactCareProgramId: data?.contactCarePrograms?.[0]?.id,
              statusId: careProgramDraftStatusId,
            }
          }
        })
        getPreviousRunningTime({
          variables: {
            where: {
              contactCareProgramId: {
                _eq: data?.contactCarePrograms?.[0]?.id
              }
            }
          },
        })
      },
      onError: (err: any) => {
        showToast(
          toast,
          'Error in fetching patient care program',
          ToastType.error,
          4000
        );
      }
    }
  )

  const [getBillableTimeMinutes, { loading: loadingBillableTimeMinutes }] = useMutation(
    GET_BILLABLE_TIME_MINUTES,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (response) => {
        setTimeLogForBillingState((prev) => {
          return {
            ...prev,
            billableTimeInMinutes: response.getBillableMinutes.totalBillingMinutes
          }
        })
      },
      onError: (err: any) => {
        showToast(
          toast,
          'Error in fetching billable minutes',
          ToastType.error,
          4000
        );
      }
    }
  )

  const [getPreviousRunningTime, { loading: loadingPreviousRunningTime }] = useLazyQuery(
    GET_PREVIOUS_RUNNING_TIME,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (response) => {
        if(response?.contactCareProgramTimerLoggings) {
          const minutes = Math.floor(response?.contactCareProgramTimerLoggings?.[0]?.timerSeconds / 60);
          const seconds = response?.contactCareProgramTimerLoggings?.[0]?.timerSeconds % 60;
          setTimeLogForBillingState((prev) => {
            return {
              ...prev,
              previousRecordedtime: {
                minutes: minutes,
                seconds: seconds,
              },
            };
          });
        }
      },
      onError: (err: any) => {
        showToast(
          toast,
          'Error in fetching previous recorded time',
          ToastType.error,
          4000
        );
      }
    }
  )

  const onTimeLogged = (totalBillingMinutes: number) => {
    setTimeLogForBillingState((prev) => {
      return {
        ...prev,
        billableTimeInMinutes: totalBillingMinutes,
      }
    })
  }

  useEffect(() => {
    getContactCareProgramId()
  }, [])

  return {
    billableTimeInMinutes: timeLogForBillingState.billableTimeInMinutes,
    contactCareProgramId: timeLogForBillingState.contactCareProgramId,
    previousRecordedTime: timeLogForBillingState.previousRecordedtime,
    careProgramTaskTypeList: careProgramTaskTypeList,
    onTimeLogged,
    timeLoggingComponentLoaders: {
      billableMinutesLoading: loadingBillableTimeMinutes,
      previousRecordedTimeLoading: loadingPreviousRunningTime
    },
    loadingContactCareProgramId: loadingContactCareProgramId
  }
}

export default UseTimeLogForBilling;