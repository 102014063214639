import React from 'react';
import {Box, HStack, Text, VStack} from 'native-base';
import {IUnreadMsgProps} from './interface';
import {styles} from './UnreadMsgStyle';
import {DisplayText} from '../DisplayText/DisplayText';
import {isWeb} from '../../../utils/platformCheckUtils';
import { testID } from '../../../testUtils';

const UnreadMsg = (props: IUnreadMsgProps) => {
  const {unreadMsgCount} = props;

  return (
    <Box
      style={{
        alignItems: 'center',
      }}
    >
      <HStack style={styles.unreadMsgBox}>
        <VStack justifyContent={'flex-end'}>
          {unreadMsgCount >= 1 && (
            <HStack>
              <Text {...testID('UnreadMessagesCount')} mr={1} style={styles.txtColor}>
                {unreadMsgCount}
              </Text>
              {isWeb() ? (
                <DisplayText
                  textLocalId="unreadMessages"
                  extraStyles={styles.txtColor}
                />
              ) : (
                <Text {...testID('UnreadMessages')} mr={1} style={styles.txtColor}>
                  Unread Messages
                </Text>
              )}
            </HStack>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export default UnreadMsg;
