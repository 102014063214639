import {Popover, notification} from "antd"
import {HStack, Pressable, VStack, Text, Divider, Icon, View, Spinner, useToast} from "native-base"
import {useContext, useEffect, useState} from "react"
import {Colors} from "../../../../styles"
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useLazyQuery, useMutation} from "@apollo/client"
import {TaskQueries} from "../../../../services"
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../constants/Configs"
import {styles} from "../../../RightSideContainer/Contacts/Customer/CustomerView/AddOrUpdateCustomer/AddOrUpdateCustomerStyles"
import {getMlovListFromCategory} from "../../../../utils/mlovUtils"
import {MLOV_CATEGORY} from "../../../../constants"
import {CommonDataContext} from "../../../../context/CommonDataContext"
import {getCompletedTaskStatusId, getStatusIdByCode} from "../../CareDashboard/CareDashboardUtils/CareDashboardUtils"
import {ToastType, showToast} from "../../../../utils/commonViewUtils"
import {ITask} from "../../CareDashboard/CareDashboardInterfaces"
import {useIntl} from "react-intl"
import {isTaskWithType} from "../../../TaskCard/TaskCardHelper"
import {EntityType} from "../../../TaskCard/TaskEnum"
import {ITaskActions, ITaskDrawerAction} from "../interfaces"
import { isWeb } from "../../../../utils/platformCheckUtils";
import { EventBus } from "../../../../utils/EventBus";
import { TASK_EVENTS } from "../../CareDashboard/CareDashboardConstants";
import { DocStatus } from "../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces";
import useCarePlanStatus from "../../../PersonOmniView/MiddleContainer/CarePlan/useCarePlanStatus";
import {GET_CARE_PLAN_BY_ID} from "../../../../services/CarePlan/CarePlanQueries";


export const TaskDrawerAction = (props: ITaskDrawerAction)=> {
  const [stateData, setStateData] = useState({
    showAction: false,
    taskStatus: '',
    loading: false
  })
  const mlovData = useContext(CommonDataContext);
  const toast = useToast()
  const intl = useIntl()
  const eventBus = EventBus.getEventBusInstance();
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const carePlanStatus = useCarePlanStatus();
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const getTaskByCode = (statusCode: string)=> {
    let code = statusCode;
    if (statusCode === 'pending') {
      code = "accepted"
    }
    return getStatusIdByCode(taskStatusMlov,code)
  }

  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getCarePlan] = useLazyQuery(GET_CARE_PLAN_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [updateTaskStatus] = useMutation(
    TaskQueries.UPDATE_TASK_STATUS_AND_REFERENCE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onError: (error: any) => {
        setStateData((prev)=>{
          return {
            ...prev,
            loading: false
          }
        })
        showToast(
          toast,
          'Error in updating status',
          ToastType.error,
          1500
        );
      },
    }
  );

  const handleVisibleChange = ()=> {
    setStateData((prev)=>{
      return {
        ...prev,
        showAction: !prev?.showAction,
        loading: false
      }
    })
  }

  useEffect(()=> {
    setStateData((prev)=>{
      return {
        ...prev,
        taskStatus: props?.task?.status?.value || ''
      }
    })
  },[])

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if(!task.subTasks || task.subTasks?.length === 0){
      return true;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map(task => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.every(
          (subTask: any) => subTask.statusId === completedStatusId
        )
      ) {
        return true;
      } else {
          notification.destroy();
          notification.info({
            message: intl.formatMessage({
              id: 'subTaskPendingMsg',
            }),
            duration: 3.0,
          });
          return false;
        }
      } else {
        notification.destroy();
        notification.info({
          message: intl.formatMessage({
            id: 'apiErrorMsg',
          }),
          duration: 3.0,
        });
        return false;
      }
  };

  const isVitalTask = isTaskWithType(props?.task || {} as ITask, EntityType.VITAL);
  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);

  const onStatusChange = async (statusId: string) => {
    if (!props.task) {
      return;
    }
    const task = props.task;
    const statusMlov = taskStatusMlov.filter((item) => item.id === statusId)?.[0];
    eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
       task: {
        ...task,
        statusId: statusId,
        isCompleted: statusId === completedStatusId,
        status: {
          id: statusMlov?.id || '',
          code: statusMlov?.code || '',
          value: statusMlov?.value || '',
        }
       }
    });
  }

  const handleStatusChange = async (status: string, statusCode: string)=> {
    if(status !== stateData?.taskStatus) {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: true
        }
      })
      if (statusCode === 'completed') {
        const allSubmitted = await checkIfAllSubTasksAreCompleted(props?.task || {} as ITask);
        if (!allSubmitted) {
          handleVisibleChange()
          return;
        }
      }
      if ((isVitalTask || isFormTask) && statusCode === 'completed') {
        const message = isFormTask ? 'Please fill the form to complete the task' : 'Please enter requested values to complete the task';
        notification.destroy();
        notification.info({
          message: message,
          duration: 2.0,
          placement: 'top'
        });
        handleVisibleChange()
        return;
      }
      if (isFormTask && props?.task?.status?.code === 'completed') {
        const message = isFormTask ? 'Form already filled can not change status of completed task' : 'Vitals already recorded can not changes status of completed task';
        notification.destroy();
        notification.info({
          message: message,
          duration: 3.0,
          placement: 'top'
        });
        handleVisibleChange()
        return;
      }

      if (statusCode === 'completed' && props.linkedCarePlanId) {
        let isInReview = false;
        const carePlanResponse = await getCarePlan({ variables: { id: props.linkedCarePlanId } });
        const carePlan = carePlanResponse?.data?.contactCarePlans?.[0];
        isInReview = carePlan?.statusId === carePlanStatus.inReviewCarePlanStatusId;

        if (isInReview) {
          notification.destroy();
          notification.warning({
            message: intl.formatMessage({
              id: 'carePlanInReviewMsg',
            }),
            duration: 3.0,
            placement: 'top'
          });
          handleVisibleChange();
          return;
        }

      }

      if (statusCode === 'completed' && props?.linkPatientNote?.resourceId && props?.linkPatientNote?.status === DocStatus.PRELIMINARY) {
        notification.destroy();
        notification.warning({
          message: intl.formatMessage({
            id: 'completeTaskLinkNoteMessage',
          }),
          duration: 3.0,
          placement: 'top'
        });
        handleVisibleChange();
        return;
      }
      const statusId = getTaskByCode(statusCode)
      updateTaskStatus({
        variables: {
          params: {
            id: props?.task?.id,
            data: {
              statusId: statusId,
            },
          },
        },
      })
      .then((res)=>{
        if(res?.data?.updateTask?.statusId) {
          setStateData((prev)=>{
            return {
              ...prev,
              taskStatus: status || ''
            }
          })
          onStatusChange(res?.data?.updateTask?.statusId);
        }
        handleVisibleChange()
        if (isWeb()) {
          notification.destroy();
          notification.info({
            message: `Task status updated successfully`,
            duration: 3.0,
            placement: 'top',
          });
        } else {
        showToast(
          toast,
          'Task status updated successfully',
          ToastType.success,
          1500,
        );
        }
        props?.onComplete?.()
      })
      .catch((err)=>{
        handleVisibleChange()
        showToast(
          toast,
          'Error in updating status',
          ToastType.error,
          1500,
        );
      })
    } else {
      handleVisibleChange()
    }
  }

  const showAction = (action: ITaskActions)=> {
    return true
  }

  const content = (
    <VStack style={{borderRadius: 16, width: 120}}>
      <View>
        {props?.actions.map((action: any, index: any) => {
          if (showAction(action)) {
            return (
              <Pressable
                key={index}
                onPress={() => {
                  handleStatusChange(action.value, action.code)
                }}
              >
                <HStack>
                  <Text color={action.value === stateData?.taskStatus ? Colors.Custom.PurpleColor : 'gray.500'} py={2}>
                    {action?.value || ''}
                  </Text>
                </HStack>
                {props?.actions?.length - 1 > index && (
                  <Divider my={1} />
                )}
              </Pressable>
            );
          } else {
            return (<></>)
          }
        })}
        {stateData?.loading && <Spinner size="lg" style={styles.spinnerStyle} />}
      </View>
    </VStack>
  );
  return <>
    <HStack
      borderRadius={6}
      borderColor={Colors.Custom.PurpleColor}
      width={120}
      px={3}
      py={1}
      borderWidth={1}>
      <Popover
        key={'1'}
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'bottom'}
        trigger="click"
        visible={stateData?.showAction}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Pressable
          flex={1}
          onPress={()=>{
            setStateData((prev)=>{
              return {
                ...prev,
                showAction: true
              }
            })
          }}
        >
          <HStack flex={1} justifyItems={'center'} alignItems={'center'} justifyContent={'space-between'}>
            <Text color={Colors.Custom.PurpleColor}>
              {stateData?.taskStatus}
            </Text>
            <Icon color={Colors.Custom.PurpleColor} as={AntIcon} name={'down'} size="4" />
          </HStack>
          </Pressable>
      </Popover>
  </HStack>
  </>
}
