import React from 'react';
import { Path, Svg } from 'react-native-svg';

const SideCarBackSvg = () => {
  return (
    <Svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 12H4m0 0l6-6m-6 6l6 6"
      ></Path>
    </Svg>
  );
};

export default SideCarBackSvg;
